"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));
var _react = require("@chakra-ui/react");
var _api = require("@whitbread-eos/api");
var _atoms = require("@whitbread-eos/atoms");
var _utils = require("@whitbread-eos/utils");
var _dateFns = require("date-fns");
var _nextI18next = require("next-i18next");
var _router = require("next/router");
var _react2 = require("react");
var _constants = require("../utils/constants");
var _RegCard = require("./RegCard");
var _ScaCard = _interopRequireDefault(require("./ScaCard"));
var _SignatureSection = _interopRequireDefault(require("./Signature/SignatureSection"));
var _common = require("./common");
var _jsxRuntime = require("react/jsx-runtime");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function PreCheckInReviewModal(_ref) {
  var {
    isOpen,
    onClose,
    data,
    bookingConfirmation,
    handleScaSuccess,
    hotelAddress
  } = _ref;
  var {
    t
  } = (0, _nextI18next.useTranslation)();
  var {
    isPaymentComplete,
    transactionId,
    setIsPaymentComplete
  } = (0, _utils.useIPageSubmission)();
  var {
    language,
    country
  } = (0, _utils.useCustomLocale)();
  var {
    token,
    basketReference
  } = (0, _utils.getFindBookingToken)();
  var {
    push,
    pathname
  } = (0, _router.useRouter)();
  var [nationalities, setNationalities] = (0, _react2.useState)([]);
  var [signatureTabIndex, setSigatureTabIndex] = (0, _react2.useState)(0);
  var [scaOpen, setScaOpen] = (0, _react2.useState)(false);
  var canvasRef = (0, _react2.useRef)(null);
  var typedCanvasRef = (0, _react2.useRef)(null);
  var [signatureUrl, setSignatureUrl] = (0, _react2.useState)();
  var [showSignatureEmptyError, setShowSignatureEmptyError] = (0, _react2.useState)(false);
  var [paymentFailedError, setPaymentFailedError] = (0, _react2.useState)(false);
  var [scaErrorVisible, setScaErrorVisible] = (0, _react2.useState)(false);
  var {
    data: countriesData,
    isSuccess: countriesRequestSuccess
  } = (0, _utils.useQueryRequest)(['GetCountries', country, language, _api.SITE_LEISURE], _api.GET_COUNTRIES, {
    country,
    language,
    site: _api.SITE_LEISURE
  });
  var {
    mutation: initiatePaymentMutation,
    isSuccess,
    data: paymentData,
    isError: paymentError,
    isLoading: paymentLoading
  } = (0, _utils.useMutationRequest)(_api.INITIATE_PAYMENT_MUTATION, true);
  var {
    mutation: copyBookingMutation,
    isLoading: copyBookingLoading,
    isError: copyBookingError
  } = (0, _utils.useMutationRequest)(_api.COPY_BOOKING, true);
  (0, _react2.useEffect)(() => {
    if (showSignatureEmptyError) setTimeout(() => setShowSignatureEmptyError(false), 5000);
  }, [showSignatureEmptyError]);
  (0, _react2.useEffect)(() => {
    if (scaOpen && (paymentError || copyBookingError || paymentFailedError)) {
      setScaErrorVisible(true);
      setTimeout(() => setScaErrorVisible(false), 5000);
    }
  }, [paymentError, copyBookingError, paymentFailedError, scaOpen]);
  (0, _react2.useEffect)(() => {
    if (countriesRequestSuccess && !nationalities.length) {
      var _countriesData$countr;
      var sortedCountries = (0, _utils.getSortedCountriesByCurrentLang)(countriesData === null || countriesData === void 0 || (_countriesData$countr = countriesData.countries) === null || _countriesData$countr === void 0 ? void 0 : _countriesData$countr.countries, language);
      setNationalities(sortedCountries.map(_ref2 => {
        var {
          nationality,
          countryCode,
          countryName,
          flagSrc
        } = _ref2;
        return {
          value: countryCode,
          label: nationality || '',
          image: flagSrc,
          countryName
        };
      }));
    }
  }, [countriesRequestSuccess, countriesData, language, nationalities]);
  var handleClose = () => {
    setScaOpen(false);
    setSignatureUrl(undefined);
    onClose();
  };
  var handleFinalSubmit = function () {
    var _ref3 = (0, _asyncToGenerator2.default)(function* () {
      return handleScaSuccess(yield (0, _RegCard.generateRegCard)({
        t,
        transactionid: transactionId,
        data,
        signatureUrl,
        hotelAddress,
        nationalities
      }));
    });
    return function handleFinalSubmit() {
      return _ref3.apply(this, arguments);
    };
  }();
  var handlePaymentComplete = () => {
    handleFinalSubmit();
    handleClose();
  };
  (0, _react2.useEffect)(() => {
    (0, _common.handleIframeHeight)(isSuccess);
  }, [isSuccess]);
  if (!data) {
    return null;
  }
  var getFormattedDate = date => {
    return date && (0, _dateFns.isDate)(date) ? (0, _dateFns.format)(date, _constants.DATE_FORMAT) : '';
  };
  var generateBookingData = () => {
    var {
      bookingReference = '',
      hotelName = '',
      arrivalDate,
      departureDate
    } = data;
    return {
      title: t('precheckin.yourbooking'),
      rows: [{
        key: t('precheckin.yourbooking.details.bookingreference'),
        value: bookingReference
      }, {
        key: t('precheckin.yourbooking.details.hotelname'),
        value: hotelName
      }, {
        key: t('precheckin.regcard.hoteladdress'),
        value: hotelAddress
      }, {
        key: t('precheckin.yourbooking.details.arrivaldate'),
        value: getFormattedDate(arrivalDate)
      }, {
        key: t('precheckin.yourbooking.details.departuredate'),
        value: getFormattedDate(departureDate)
      }]
    };
  };
  var generateLeadGuest = () => {
    var _nationalities$find$l, _nationalities$find, _nationalities$find$c, _nationalities$find2;
    var {
      firstName = '',
      lastName = '',
      address = '',
      postalCode = '',
      city = '',
      dateOfBirth,
      nationality = {
        label: '',
        value: ''
      },
      passport = '',
      country = '',
      noOfRooms = 0,
      roomNo = 0
    } = data !== null && data !== void 0 ? data : {};
    var formattedDateOfBirth = getFormattedDate(dateOfBirth);
    var nationalityLabel = (_nationalities$find$l = (_nationalities$find = nationalities.find(_ref4 => {
      var {
        value
      } = _ref4;
      return value === (nationality === null || nationality === void 0 ? void 0 : nationality.value);
    })) === null || _nationalities$find === void 0 ? void 0 : _nationalities$find.label) !== null && _nationalities$find$l !== void 0 ? _nationalities$find$l : '';
    var countryLabel = (_nationalities$find$c = (_nationalities$find2 = nationalities.find(_ref5 => {
      var {
        value
      } = _ref5;
      return value === country;
    })) === null || _nationalities$find2 === void 0 ? void 0 : _nationalities$find2.countryName) !== null && _nationalities$find$c !== void 0 ? _nationalities$find$c : '';
    return {
      title: noOfRooms > 1 ? "".concat(t('precheckin.leadguest.title'), " ").concat(roomNo + 1) : t('precheckin.yourdetails.title'),
      rows: [{
        key: t('precheckin.regcard.firstname'),
        value: firstName
      }, {
        key: t('precheckin.regcard.lastname'),
        value: lastName
      }, {
        key: t('precheckin.regcard.homeaddress'),
        value: address
      }, {
        key: t('precheckin.regcard.postcode'),
        value: postalCode
      }, {
        key: t('precheckin.regcard.city'),
        value: city
      }, {
        key: t('precheckin.regcard.country'),
        value: countryLabel
      }, {
        key: t('precheckin.additionalfields.dateofbirth'),
        value: formattedDateOfBirth
      }, {
        key: t('precheckin.additionalfields.nationalities'),
        value: nationalityLabel
      }, {
        key: t('precheckin.details.passport'),
        value: passport
      }].filter(item => {
        if (item.key === t('precheckin.details.passport')) {
          return !!item.value;
        }
        return true;
      })
    };
  };
  var handlePayment = function () {
    var _ref6 = (0, _asyncToGenerator2.default)(function* () {
      var signRef = signatureTabIndex ? typedCanvasRef : canvasRef;
      if (signRef !== null && signRef !== void 0 && signRef.current && !(0, _common.isCanvasEmpty)(signRef.current) || signatureUrl) {
        var _signRef$current;
        var _signatureUrl = (_signRef$current = signRef.current) === null || _signRef$current === void 0 ? void 0 : _signRef$current.toDataURL();
        if (_signatureUrl) setSignatureUrl(_signatureUrl);
        if (showSignatureEmptyError) setShowSignatureEmptyError(false);
        setScaOpen(true);
        var channel = 'PI';
        var bookingChannel = {
          channel,
          subchannel: 'WEB',
          language: language === null || language === void 0 ? void 0 : language.toUpperCase()
        };
        if (token && basketReference) {
          try {
            var _bookingConfirmation$;
            var {
              copyBooking
            } = yield copyBookingMutation.mutateAsync({
              originalBasketReference: basketReference,
              bookingChannel,
              token
            });
            var address = bookingConfirmation === null || bookingConfirmation === void 0 || (_bookingConfirmation$ = bookingConfirmation.reservationByIdList[0]) === null || _bookingConfirmation$ === void 0 || (_bookingConfirmation$ = _bookingConfirmation$.billing) === null || _bookingConfirmation$ === void 0 ? void 0 : _bookingConfirmation$.address;
            if (address) initiatePaymentMutation.mutate({
              basketReference,
              createPaymentCriteria: (0, _common.getPaymentParams)({
                billingAddress: address,
                bookingConfirmation,
                country,
                language,
                copyBasketReference: copyBooking === null || copyBooking === void 0 ? void 0 : copyBooking.copyBasketReference
              })
            });
          } catch (e) {
            handleClose();
          }
        } else {
          handleClose();
          (0, _common.handleRedirect)("/".concat(country, "/").concat(language).concat(pathname, "/?status=EXPIRED"), push);
        }
      } else {
        setShowSignatureEmptyError(true);
      }
    });
    return function handlePayment() {
      return _ref6.apply(this, arguments);
    };
  }();
  var handleRetryPayment = () => {
    setIsPaymentComplete(false);
    handlePayment();
  };
  var reviewBookingData = {
    yourBooking: generateBookingData(),
    leadGuest: generateLeadGuest(),
    dependentsData: (0, _common.generateDependentsData)(nationalities, t, data === null || data === void 0 ? void 0 : data.dependents)
  };
  var baseTestId = 'PreCheckInReviewModal';
  return (0, _jsxRuntime.jsx)(_atoms.ModalVariants, {
    isOpen: isOpen,
    onClose: handleClose,
    variant: "data",
    variantProps: {
      title: scaOpen ? t('precheckin.SCA.title') : t('precheckin.regcard.title'),
      delimiter: false,
      sizeSm: 'lg',
      externalTitleStying: {
        textAlign: 'center',
        justifyContent: 'center',
        pt: 'md',
        pb: 'sm',
        fontSize: '2xl',
        fontWeight: 'bold'
      }
    },
    updatedWidth: {
      sm: '100%',
      md: '68%'
    },
    children: !scaOpen ? (0, _jsxRuntime.jsxs)(_react.Box, {
      alignItems: "left",
      flexWrap: "wrap",
      padding: "xl",
      paddingTop: "0",
      marginBottom: "0",
      "data-testid": (0, _utils.formatDataTestId)(baseTestId, 'wrapper'),
      children: [(0, _jsxRuntime.jsxs)(_react.Flex, {
        justifyContent: "left",
        flexDirection: "column",
        children: [(0, _jsxRuntime.jsx)(_react.Box, {
          style: itemStyle,
          "data-testid": (0, _utils.formatDataTestId)(baseTestId, 'yourBooking'),
          children: (0, _jsxRuntime.jsx)(_atoms.DetailsPanel, {
            data: reviewBookingData.yourBooking,
            stylesObject: stylesObject
          })
        }), (0, _jsxRuntime.jsx)(_react.Box, {
          style: itemStyle,
          "data-testid": (0, _utils.formatDataTestId)(baseTestId, 'leadGuest'),
          children: (0, _jsxRuntime.jsx)(_atoms.DetailsPanel, {
            data: reviewBookingData.leadGuest,
            stylesObject: extendedStylesObject
          })
        }), !!reviewBookingData.dependentsData.length && (0, _jsxRuntime.jsxs)(_react.Box, {
          style: itemStyle,
          "data-testid": (0, _utils.formatDataTestId)(baseTestId, 'additionalGuestWrapper'),
          children: [(0, _jsxRuntime.jsx)(_react.Box, _objectSpread(_objectSpread({}, titleWrapper), {}, {
            "data-testid": (0, _utils.formatDataTestId)(baseTestId, 'additionalGuestTitle'),
            children: (0, _jsxRuntime.jsx)(_react.Text, _objectSpread(_objectSpread({}, titleStyle), {}, {
              children: t('precheckin.additionalguests.title')
            }))
          })), reviewBookingData.dependentsData.map((additionalGuest, index) => (0, _jsxRuntime.jsx)(_atoms.DetailsPanel, {
            data: additionalGuest,
            stylesObject: guestStylesObject
          }, "additionalGuest".concat(index)))]
        }), (0, _jsxRuntime.jsx)(_SignatureSection.default, {
          tabIndex: signatureTabIndex,
          setTabIndex: setSigatureTabIndex,
          typedCanvasRef: typedCanvasRef,
          canvasRef: canvasRef,
          showSignatureEmptyError: showSignatureEmptyError,
          setShowSignatureEmptyError: setShowSignatureEmptyError
        })]
      }), (0, _jsxRuntime.jsx)(_react.Box, {
        children: (0, _jsxRuntime.jsxs)(_react.Flex, _objectSpread(_objectSpread({}, buttonsFlexWrapper), {}, {
          "data-testid": (0, _utils.formatDataTestId)(baseTestId, 'buttonsWrapper'),
          children: [(0, _jsxRuntime.jsx)(_react.Button, {
            size: "sm",
            variant: "outline",
            colorScheme: "teal",
            "data-testid": (0, _utils.formatDataTestId)(baseTestId, 'editButton'),
            onClick: handleClose,
            width: {
              base: '100%',
              md: '20%'
            },
            marginBottom: "md",
            children: t('precheckin.details.edit')
          }), (0, _jsxRuntime.jsx)(_react.Button, {
            size: "sm",
            variant: "primary",
            "data-testid": (0, _utils.formatDataTestId)(baseTestId, 'submitButton'),
            width: {
              base: '100%',
              md: '20%'
            },
            onClick: handlePayment,
            marginBottom: "md",
            children: t('precheckin.details.submit')
          })]
        }))
      })]
    }) : (0, _jsxRuntime.jsx)(_ScaCard.default, {
      scaErrorVisible: scaErrorVisible,
      basketReference: basketReference,
      isSuccess: isSuccess,
      copyBookingLoading: copyBookingLoading,
      paymentLoading: paymentLoading,
      isPaymentComplete: isPaymentComplete,
      handleRetryPayment: handleRetryPayment,
      handlePaymentComplete: handlePaymentComplete,
      setPaymentFailedError: setPaymentFailedError,
      paymentData: paymentData
    })
  });
}
var _default = exports.default = PreCheckInReviewModal;
var itemStyle = {
  borderBottom: '1px solid var(--chakra-colors-lightGrey4)',
  paddingTop: 'var(--chakra-sizes-8)',
  paddingBottom: 'var(--chakra-sizes-8)'
};
var stylesObject = {
  wrapper: {
    pos: 'relative',
    w: {
      base: '100%',
      md: '40em'
    }
  },
  title: {
    fontSize: 'xl',
    fontWeight: 'bold',
    mb: 'md',
    lineHeight: 'var(--chakra-sizes-8)'
  },
  rowKey: {
    fontSize: 'md',
    fontWeight: 'semibold',
    color: 'darkGrey1',
    lineHeight: 'var(--chakra-sizes-6)',
    width: '14.55rem'
  },
  rowValue: {
    fontSize: 'md',
    fontWeight: 'normal',
    color: 'darkGrey1',
    lineHeight: 'var(--chakra-sizes-6)'
  }
};
var extendedStylesObject = _objectSpread(_objectSpread({}, stylesObject), {}, {
  rowKey: _objectSpread(_objectSpread({}, stylesObject.rowKey), {}, {
    width: '17.5rem'
  })
});
var guestStylesObject = _objectSpread(_objectSpread({}, extendedStylesObject), {}, {
  title: {
    fontSize: 'xl',
    fontWeight: 'semibold',
    color: 'darkGrey1',
    lineHeight: 'var(--chakra-sizes-8)',
    pb: 'md',
    pt: 'sm'
  },
  wrapper: _objectSpread(_objectSpread({}, stylesObject.wrapper), {}, {
    pb: 'sm',
    pt: 'sm'
  })
});
var titleStyle = {
  fontSize: 'xl',
  fontWeight: 'bold'
};
var titleWrapper = {
  pos: 'relative'
};
var buttonsFlexWrapper = {
  justifyContent: {
    base: 'center',
    md: 'flex-end'
  },
  flexDirection: {
    base: 'column',
    md: 'row'
  },
  gap: {
    base: 2,
    md: 4
  },
  width: {
    base: '100%',
    md: 'auto'
  },
  mt: 'xl'
};