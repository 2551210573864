"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getEmployeesQuery = void 0;
var _taggedTemplateLiteral2 = _interopRequireDefault(require("@babel/runtime/helpers/taggedTemplateLiteral"));
var _graphqlRequest = require("graphql-request");
var _templateObject;
var getEmployeesQuery = () => (0, _graphqlRequest.gql)(_templateObject || (_templateObject = (0, _taggedTemplateLiteral2.default)(["\n  query getEmployees(\n    $companyId: String!\n    $searchCriteria: String\n    $bookingChannel: String\n    $awaitingApproval: Boolean\n    $size: Int!\n    $page: Int\n    $pageToken: String\n  ) {\n    getEmployees(\n      companyId: $companyId\n      searchCriteria: $searchCriteria\n      bookingChannel: $bookingChannel\n      awaitingApproval: $awaitingApproval\n      size: $size\n      page: $page\n      pageToken: $pageToken\n    ) {\n      success\n      employees {\n        id\n        title\n        firstName\n        lastName\n        emailAddress\n        accessLevel\n        employeeStatus\n      }\n      pageToken\n    }\n  }\n"])));
exports.getEmployeesQuery = getEmployeesQuery;