"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = EmailOptOut;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _react = require("@chakra-ui/react");
var _atoms = require("@whitbread-eos/atoms");
var _utils = require("@whitbread-eos/utils");
var _nextI18next = require("next-i18next");
var _react2 = require("react");
var _jsxRuntime = require("react/jsx-runtime");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function EmailOptOut(_ref) {
  var _formField$testid, _formField$props$curr, _formField$props;
  var {
    formField,
    field
  } = _ref;
  var testId = (_formField$testid = formField.testid) !== null && _formField$testid !== void 0 ? _formField$testid : 'EmailOptOut';
  var {
    t
  } = (0, _nextI18next.useTranslation)();
  var [isChecked, setIsChecked] = (0, _react2.useState)(false);
  var currentLang = (_formField$props$curr = (_formField$props = formField.props) === null || _formField$props === void 0 ? void 0 : _formField$props.currentLang) !== null && _formField$props$curr !== void 0 ? _formField$props$curr : _utils.GLOBALS.language.EN;
  var onChange = option => {
    var _option$target, _option$target2;
    setIsChecked(option === null || option === void 0 || (_option$target = option.target) === null || _option$target === void 0 ? void 0 : _option$target.checked);
    field.onChange(!(option !== null && option !== void 0 && (_option$target2 = option.target) !== null && _option$target2 !== void 0 && _option$target2.checked));
  };
  (0, _react2.useEffect)(() => {
    field.onChange(!isChecked);
  }, []);
  (0, _react2.useEffect)(() => {
    _utils.analytics.update({
      marketingOptInChoice: currentLang === _utils.GLOBALS.language.DE ? isChecked : !isChecked
    });
  }, [isChecked]);
  return (0, _jsxRuntime.jsxs)(_react.Box, {
    "data-testid": (0, _utils.formatDataTestId)(testId, 'Container'),
    children: [(0, _jsxRuntime.jsx)(_react.Text, _objectSpread(_objectSpread({}, headerStyle), {}, {
      "data-testid": (0, _utils.formatDataTestId)(testId, 'Header'),
      children: t('booking.emailTitle')
    })), (0, _jsxRuntime.jsx)(_react.Box, _objectSpread(_objectSpread({}, descriptionStyle), {}, {
      "data-testid": (0, _utils.formatDataTestId)(testId, 'Description'),
      className: "formatLinks",
      children: (0, _utils.renderSanitizedHtml)(t('booking.emailText'))
    })), (0, _jsxRuntime.jsx)(_atoms.Checkbox, {
      name: field.name,
      value: field.value,
      onChange: onChange,
      "data-testid": (0, _utils.formatDataTestId)(testId, 'CheckboxContainer'),
      children: (0, _jsxRuntime.jsx)(_react.Text, _objectSpread(_objectSpread({}, descriptionStyle), {}, {
        "data-testid": (0, _utils.formatDataTestId)(testId, 'CheckboxText'),
        children: t('booking.emailBoxText.premierinnhubzip')
      }))
    })]
  });
}
var headerStyle = {
  fontSize: '2xl',
  fontWeight: 'semibold',
  lineHeight: '4',
  color: 'darkGrey1',
  mb: 'md'
};
var descriptionStyle = {
  fontSize: 'md',
  fontWeight: 'normal',
  lineHeight: '3',
  color: 'darkGrey1',
  mb: 'md'
};