"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _jsxRuntime = require("react/jsx-runtime");
var RolesRequired = _ref => {
  var {
    userRole,
    requiredRoles,
    children
  } = _ref;
  if (requiredRoles.includes(userRole)) return (0, _jsxRuntime.jsx)(_jsxRuntime.Fragment, {
    children: children
  });
  return null;
};
var _default = exports.default = RolesRequired;