"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = LiveAssistScriptEmbed;
var _api = require("@whitbread-eos/api");
var _utils = require("@whitbread-eos/utils");
var _script = _interopRequireDefault(require("next/script"));
var _jsxRuntime = require("react/jsx-runtime");
function LiveAssistScriptEmbed() {
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_script.default, {
      src: (0, _utils.formatAssetsUrl)(_api.BB_LIVE_ASSIST),
      strategy: "lazyOnload",
      id: "liveassist-script"
    }), (0, _jsxRuntime.jsx)(_script.default, {
      src: (0, _utils.formatAssetsUrl)(_api.BB_CO_BROWSE),
      strategy: "lazyOnload",
      id: "cobrowse-script"
    })]
  });
}