"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = PaymentAuthorization;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _react = require("@chakra-ui/react");
var _atoms = require("@whitbread-eos/atoms");
var _utils = require("@whitbread-eos/utils");
var _nextI18next = require("next-i18next");
var _react2 = require("react");
var _jsxRuntime = require("react/jsx-runtime");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function PaymentAuthorization(_ref) {
  var {
    togglePaymentAuth,
    paymentAuth,
    password,
    onChangePassword,
    isPaymentAuthError
  } = _ref;
  var {
    t
  } = (0, _nextI18next.useTranslation)(['common']);
  var [showError, setShowError] = (0, _react2.useState)(false);
  var baseDataTestId = 'PaymentAuth';
  (0, _react2.useEffect)(() => {
    if (isPaymentAuthError) {
      setShowError(isPaymentAuthError);
    }
  }, [isPaymentAuthError]);
  return (0, _jsxRuntime.jsxs)(_react.Box, _objectSpread(_objectSpread({
    "data-testid": (0, _utils.formatDataTestId)(baseDataTestId, 'Container')
  }, paymentAuthWrapperStyle), {}, {
    children: [(0, _jsxRuntime.jsx)(_react.Text, _objectSpread(_objectSpread({
      "data-testid": (0, _utils.formatDataTestId)(baseDataTestId, 'PaymentAuthTitle')
    }, titleStyle), {}, {
      children: t('booking.bac.paymentAuthorisation')
    })), (0, _jsxRuntime.jsx)(_atoms.Checkbox, {
      "data-testid": (0, _utils.formatDataTestId)(baseDataTestId, 'CheckboxInfo'),
      onChange: togglePaymentAuth,
      isChecked: paymentAuth,
      children: (0, _jsxRuntime.jsx)(_react.Text, _objectSpread(_objectSpread({
        "data-testid": (0, _utils.formatDataTestId)(baseDataTestId, 'CheckboxLabel')
      }, checkboxLabelStyle), {}, {
        children: t('cardNotPresent.info')
      }))
    }), paymentAuth && (0, _jsxRuntime.jsx)(_react.Box, {
      maxW: "var(--chakra-space-breakpoint-m)",
      mt: "md",
      children: (0, _jsxRuntime.jsx)(_atoms.Input, {
        "data-testid": (0, _utils.formatDataTestId)(baseDataTestId, 'MemorableWord'),
        label: t('cardNotPresent.memorableWord'),
        placeholderText: t('cardNotPresent.memorableWord'),
        type: "password",
        name: "password",
        value: password,
        error: showError && t('cardNotPresent.memorableWordRequired'),
        useTooltip: showError,
        onChange: val => onChangePassword(val),
        onBlur: () => validatePassword()
      })
    })]
  }));
  function validatePassword() {
    if (password.length === 0) {
      setShowError(true);
    } else {
      setShowError(false);
    }
  }
}
var paymentAuthWrapperStyle = {
  mb: '5xl',
  w: {
    mobile: 'full',
    xs: 'full',
    md: '40.125rem'
  }
};
var titleStyle = {
  fontSize: 'xl',
  fontWeight: 'semibold',
  mb: 'md',
  lineHeight: '3',
  color: 'darkGrey1'
};
var checkboxLabelStyle = {
  lineHeight: '3',
  mx: ' sm',
  color: 'darkGrey1'
};