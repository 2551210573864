"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.SAVE_ANCILLARIES_BOOKING_INFO = void 0;
var _taggedTemplateLiteral2 = _interopRequireDefault(require("@babel/runtime/helpers/taggedTemplateLiteral"));
var _graphqlRequest = require("graphql-request");
var _templateObject;
var SAVE_ANCILLARIES_BOOKING_INFO = exports.SAVE_ANCILLARIES_BOOKING_INFO = (0, _graphqlRequest.gql)(_templateObject || (_templateObject = (0, _taggedTemplateLiteral2.default)(["\n  mutation saveBookingInformation($ancillariesCriteria: AncillariesCriteria!) {\n    saveReservation(ancillariesCriteria: $ancillariesCriteria)\n  }\n"])));