"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getYears = getYears;
exports.years = void 0;
function getYears(startYear) {
  var currentYear = new Date().getFullYear();
  return Array.from(Array(currentYear - startYear + 1).keys()).map(year => startYear + year);
}
var years = exports.years = getYears(new Date().getFullYear() - 100);