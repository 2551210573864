"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.BOOKING_CHANNEL = void 0;
var BOOKING_CHANNEL = exports.BOOKING_CHANNEL = function (BOOKING_CHANNEL) {
  BOOKING_CHANNEL["PI"] = "PI";
  BOOKING_CHANNEL["CCUI"] = "CCUI";
  BOOKING_CHANNEL["BB"] = "BB";
  return BOOKING_CHANNEL;
}({});