"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.addOrRemoveAllRoomsExtras = addOrRemoveAllRoomsExtras;
exports.addOrRemoveExtrasRoom = addOrRemoveExtrasRoom;
exports.extrasPackagesMapperSelector = exports.enhanceSelectedMeals = exports.enhancePrivacyPolicyMoreInfoItem = exports.enhanceMeal = exports.childrenMealsSelector = exports.checkIfExistPreselection = exports.calculateTotalCostRoomSelection = exports.autocompleteMeals = exports.adultsMealsSelector = exports.addReservationNumber = void 0;
exports.filterPackagesByAncillariesCloseOut = filterPackagesByAncillariesCloseOut;
exports.freeBreakfastMaxSelectedAllowance = exports.freeBreakfastMaxAllowance = exports.formatImportantNotes = void 0;
exports.getCurrentReservationStorageData = getCurrentReservationStorageData;
exports.roomInformationSelector = exports.numberOfSelectionsPerRoomSelector = exports.menusSelector = exports.mealsMapperSelector = exports.isCityTaxAvailable = exports.getUniqueRoomProperties = void 0;
exports.roomPackageSelection = roomPackageSelection;
exports.sortMealsByOrderOrName = exports.shouldDisplayAutocompleteNotification = exports.selectedMealsPerRoomSelector = exports.securityNoticeMoreInfoDataSelector = void 0;
exports.sortMealsByReservationId = sortMealsByReservationId;
exports.transformBartIdToOperaId = void 0;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _api = require("@whitbread-eos/api");
var _isAfter = _interopRequireDefault(require("date-fns/isAfter"));
var _isBefore = _interopRequireDefault(require("date-fns/isBefore"));
var _isWithinInterval = _interopRequireDefault(require("date-fns/isWithinInterval"));
var _parse = _interopRequireDefault(require("date-fns/parse"));
var _formatters = require("../formatters");
var _validators = require("../validators");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
var enhanceMeal = meal => {
  var _meal$allergyInfoSrc, _meal$imageSrc;
  return _objectSpread(_objectSpread({}, meal), {}, {
    allergyInfoSrc: (0, _validators.isStringValid)(meal.allergyInfoSrc) ? (0, _formatters.formatAssetsUrl)((_meal$allergyInfoSrc = meal.allergyInfoSrc) !== null && _meal$allergyInfoSrc !== void 0 ? _meal$allergyInfoSrc : '') : '',
    imageSrc: (0, _validators.isStringValid)(meal.imageSrc) ? (0, _formatters.formatAssetsUrl)((_meal$imageSrc = meal.imageSrc) !== null && _meal$imageSrc !== void 0 ? _meal$imageSrc : '') : ''
  });
};
exports.enhanceMeal = enhanceMeal;
var enhancePrivacyPolicyMoreInfoItem = moreInfo => moreInfo ? moreInfo.map(moreInfoItem => {
  var _moreInfoItem$image;
  return _objectSpread(_objectSpread({}, moreInfoItem), {}, {
    image: (0, _formatters.formatAssetsUrl)((_moreInfoItem$image = moreInfoItem.image) !== null && _moreInfoItem$image !== void 0 ? _moreInfoItem$image : '')
  });
}) : [];
exports.enhancePrivacyPolicyMoreInfoItem = enhancePrivacyPolicyMoreInfoItem;
var sortMealsByOrderOrName = (firstMeal, secondMeal) => {
  if (firstMeal.order === secondMeal.order || firstMeal.order === null || secondMeal.order === null) {
    return firstMeal.name && secondMeal.name ? firstMeal.name.toUpperCase() > secondMeal.name.toUpperCase() ? 1 : -1 : null;
  } else return firstMeal.order && secondMeal.order ? firstMeal.order - secondMeal.order : null;
};
exports.sortMealsByOrderOrName = sortMealsByOrderOrName;
var adultsMealsSelector = exports.adultsMealsSelector = function adultsMealsSelector(adultsMeals, noNights) {
  var noTotalAdults = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 0;
  return adultsMeals ? adultsMeals.map(meal => _objectSpread(_objectSpread(_objectSpread({}, meal), enhanceMeal(meal)), {}, {
    totalPrice: meal.price ? meal.price * noNights : 0,
    totalPriceForEntireStay: meal.price ? meal.price * noNights * noTotalAdults : 0
  })).sort(sortMealsByOrderOrName) : [];
};
var freeBreakfastMaxAllowance = adultsMeals => adultsMeals.reduce((acc, current) => current !== null && current !== void 0 && current.freeBreakfastOption && current !== null && current !== void 0 && current.freeBreakfastMaxPerMeal ? acc + (current === null || current === void 0 ? void 0 : current.freeBreakfastMaxPerMeal) : acc, 0);
exports.freeBreakfastMaxAllowance = freeBreakfastMaxAllowance;
var freeBreakfastMaxSelectedAllowance = (adultsMealsSelection, adultsMeals) => {
  var enhancedSelectedMeals = adultsMealsSelection ? adultsMealsSelection.map(meal => adultsMeals.filter(item => item.id === meal)[0]) : [];
  return freeBreakfastMaxAllowance(enhancedSelectedMeals);
};
exports.freeBreakfastMaxSelectedAllowance = freeBreakfastMaxSelectedAllowance;
var childrenMealsSelector = childrenMeals => childrenMeals ? childrenMeals.map(meal => _objectSpread(_objectSpread({}, meal), enhanceMeal(meal))).sort(sortMealsByOrderOrName) : [];
exports.childrenMealsSelector = childrenMealsSelector;
var menusSelector = (adultMeals, mealsKids) => {
  var formattedMenus = [...adultMeals.filter(meal => meal.menu !== null), ...mealsKids.filter(meal => meal.menu !== null)];
  return formattedMenus.filter((meal, index, arrOfMeals) => arrOfMeals.findIndex(item => {
    var _item$menu, _meal$menu;
    return (item === null || item === void 0 || (_item$menu = item.menu) === null || _item$menu === void 0 ? void 0 : _item$menu.menuSrc) === (meal === null || meal === void 0 || (_meal$menu = meal.menu) === null || _meal$menu === void 0 ? void 0 : _meal$menu.menuSrc);
  }) === index).map(item => {
    var _item$menu2, _item$menu$menuSrc, _item$menu3;
    return {
      name: item === null || item === void 0 || (_item$menu2 = item.menu) === null || _item$menu2 === void 0 ? void 0 : _item$menu2.name,
      menuSrc: (0, _formatters.formatAssetsUrl)((_item$menu$menuSrc = item === null || item === void 0 || (_item$menu3 = item.menu) === null || _item$menu3 === void 0 ? void 0 : _item$menu3.menuSrc) !== null && _item$menu$menuSrc !== void 0 ? _item$menu$menuSrc : '')
    };
  });
};
exports.menusSelector = menusSelector;
var securityNoticeMoreInfoDataSelector = privacyPolicy => {
  var _privacyPolicy$moreIn, _privacyPolicy$linkSr;
  return _objectSpread(_objectSpread({}, privacyPolicy), {}, {
    moreInfo: enhancePrivacyPolicyMoreInfoItem((_privacyPolicy$moreIn = privacyPolicy.moreInfo) !== null && _privacyPolicy$moreIn !== void 0 ? _privacyPolicy$moreIn : []),
    linkSrc: (0, _validators.isStringValid)(privacyPolicy === null || privacyPolicy === void 0 ? void 0 : privacyPolicy.linkSrc) ? (0, _formatters.formatAssetsUrl)((_privacyPolicy$linkSr = privacyPolicy.linkSrc) !== null && _privacyPolicy$linkSr !== void 0 ? _privacyPolicy$linkSr : '') : ''
  });
};
exports.securityNoticeMoreInfoDataSelector = securityNoticeMoreInfoDataSelector;
var enhanceSelectedMeals = (roomMealsSelections, adultsMeals, childrenMeals) => ({
  adultsMeals: adultsMeals ? adultsMeals.filter(meal => {
    var _meal$id;
    return roomMealsSelections === null || roomMealsSelections === void 0 ? void 0 : roomMealsSelections.adults.includes((_meal$id = meal.id) !== null && _meal$id !== void 0 ? _meal$id : '');
  }).map(meal => {
    var _meal$price;
    return {
      title: meal.name,
      id: meal.id,
      price: (_meal$price = meal === null || meal === void 0 ? void 0 : meal.price) !== null && _meal$price !== void 0 ? _meal$price : 0,
      noSelections: roomMealsSelections.adults.filter(sel => sel === meal.id).length
    };
  }) : [],
  childrenMeals: childrenMeals ? childrenMeals.filter(meal => {
    var _meal$id2;
    return roomMealsSelections === null || roomMealsSelections === void 0 ? void 0 : roomMealsSelections.children.includes((_meal$id2 = meal.id) !== null && _meal$id2 !== void 0 ? _meal$id2 : '');
  }).map(meal => ({
    title: meal.name,
    id: meal.id,
    noSelections: roomMealsSelections.children.filter(sel => sel === meal.id).length
  })) : []
});
exports.enhanceSelectedMeals = enhanceSelectedMeals;
var selectedMealsPerRoomSelector = (selectedMeals, adultsMeals, childrenMeals) => selectedMeals && selectedMeals.length > 0 ? selectedMeals.map(roomMealsSelection => enhanceSelectedMeals(roomMealsSelection, adultsMeals, childrenMeals)) : [];
exports.selectedMealsPerRoomSelector = selectedMealsPerRoomSelector;
var roomInformationSelector = (reservationListItems, selectedMeals, adultsMeals, childrenMeals, selectedExtrasList) => reservationListItems && reservationListItems.length > 0 ? reservationListItems.map((_ref, index) => {
  var _roomStay$adultsNumbe, _roomStay$childrenNum, _roomStay$roomExtraIn, _roomStay$roomExtraIn2, _roomStay$accessibleR, _roomStay$accessibleR2, _roomStay$accessibleR3;
  var {
    roomStay,
    reservationId
  } = _ref;
  var selectedExtrasFound = selectedExtrasList === null || selectedExtrasList === void 0 ? void 0 : selectedExtrasList.find(extrasItem => extrasItem.reservationId === reservationId);
  return _objectSpread({
    nrAdults: (_roomStay$adultsNumbe = roomStay === null || roomStay === void 0 ? void 0 : roomStay.adultsNumber) !== null && _roomStay$adultsNumbe !== void 0 ? _roomStay$adultsNumbe : 0,
    nrChildren: (_roomStay$childrenNum = roomStay === null || roomStay === void 0 ? void 0 : roomStay.childrenNumber) !== null && _roomStay$childrenNum !== void 0 ? _roomStay$childrenNum : 0,
    roomType: roomStay === null || roomStay === void 0 || (_roomStay$roomExtraIn = roomStay.roomExtraInfo) === null || _roomStay$roomExtraIn === void 0 ? void 0 : _roomStay$roomExtraIn.roomType,
    roomName: roomStay === null || roomStay === void 0 || (_roomStay$roomExtraIn2 = roomStay.roomExtraInfo) === null || _roomStay$roomExtraIn2 === void 0 ? void 0 : _roomStay$roomExtraIn2.roomName,
    selectedMeals: enhanceSelectedMeals(selectedMeals[index], adultsMeals, childrenMeals),
    selectedExtrasList: selectedExtrasFound,
    accessibleRoom: {
      isAccessible: !!(roomStay !== null && roomStay !== void 0 && (_roomStay$accessibleR = roomStay.accessibleRoom) !== null && _roomStay$accessibleR !== void 0 && _roomStay$accessibleR.isAccessible),
      phoneNumber: (_roomStay$accessibleR2 = roomStay === null || roomStay === void 0 || (_roomStay$accessibleR3 = roomStay.accessibleRoom) === null || _roomStay$accessibleR3 === void 0 ? void 0 : _roomStay$accessibleR3.phoneNumber) !== null && _roomStay$accessibleR2 !== void 0 ? _roomStay$accessibleR2 : ''
    }
  }, reservationId ? {
    reservationId
  } : {});
}) : [];
exports.roomInformationSelector = roomInformationSelector;
var numberOfSelectionsPerRoomSelector = selectedMeals => {
  return selectedMeals.map(room => {
    var adultsMeals = room.adults.reduce((acc, value) => {
      return _objectSpread(_objectSpread({}, acc), {}, {
        [value]: (acc[value] || 0) + 1
      });
    }, {});
    var childrenMeals = room.children.reduce((acc, value) => {
      return _objectSpread(_objectSpread({}, acc), {}, {
        [value]: (acc[value] || 0) + 1
      });
    }, {});
    var reservationId = room.reservationId;
    var adultsMealList = Object.keys(adultsMeals).map(key => ({
      id: key,
      noOfSelections: adultsMeals[key]
    }));
    var childrenMealsList = Object.keys(childrenMeals).map(key => ({
      id: key,
      noOfSelections: childrenMeals[key]
    }));
    return {
      reservationId: reservationId,
      packagesSelection: [...adultsMealList, ...childrenMealsList]
    };
  });
};
exports.numberOfSelectionsPerRoomSelector = numberOfSelectionsPerRoomSelector;
var mealsMapperSelector = (adultsMeals, childrenMeals, roomSelections) => {
  var finalList = [];
  var adultsIds = adultsMeals === null || adultsMeals === void 0 ? void 0 : adultsMeals.map(meal => meal.id);
  var childrenIds = childrenMeals === null || childrenMeals === void 0 ? void 0 : childrenMeals.map(meal => meal.id);
  roomSelections === null || roomSelections === void 0 || roomSelections.forEach(room => {
    var mealDistribution = {
      adults: [],
      children: [],
      reservationId: room === null || room === void 0 ? void 0 : room.reservationId
    };
    if (room.packagesSelection && room.packagesSelection.length > 0) {
      room === null || room === void 0 || room.packagesSelection.forEach(pcks => {
        if (adultsIds !== null && adultsIds !== void 0 && adultsIds.includes(pcks.id)) {
          mealDistribution.adults = Array(pcks.noOfSelections).fill(pcks.id).concat(mealDistribution.adults);
        } else if (childrenIds !== null && childrenIds !== void 0 && childrenIds.includes(pcks.id)) {
          mealDistribution.children = Array(pcks.noOfSelections).fill(pcks.id).concat(mealDistribution.children);
        }
      });
    } else {
      mealDistribution = {
        adults: [],
        children: [],
        reservationId: room === null || room === void 0 ? void 0 : room.reservationId
      };
    }
    finalList.push(mealDistribution);
  });
  return finalList;
};
exports.mealsMapperSelector = mealsMapperSelector;
var autocompleteMeals = (adultsMeals, childrenMeals, listGuests, prefUserMealId) => {
  var finalList = [];
  var prefMeal = adultsMeals.find(meal => meal.id === prefUserMealId);
  var associateChildMeal = childrenMeals.find(meal => meal.id === (prefMeal === null || prefMeal === void 0 ? void 0 : prefMeal.freeBreakfastCode));
  listGuests.adultsNumber.forEach((adults, index) => {
    finalList.push({
      adults: !prefMeal ? [] : Array(adults).fill(prefMeal === null || prefMeal === void 0 ? void 0 : prefMeal.id),
      children: prefMeal !== null && prefMeal !== void 0 && prefMeal.freeBreakfastOption && associateChildMeal ? Array(listGuests.childrenNumber[index]).fill(associateChildMeal.id) : []
    });
  });
  return finalList;
};
exports.autocompleteMeals = autocompleteMeals;
var calculateTotalCostRoomSelection = (adultsMeals, roomSelections, noOfNights) => {
  var totalCost = 0;
  roomSelections === null || roomSelections === void 0 || roomSelections.forEach(room => {
    var _room$packagesSelecti;
    if (room.packagesSelection && ((_room$packagesSelecti = room.packagesSelection) === null || _room$packagesSelecti === void 0 ? void 0 : _room$packagesSelecti.length) > 0) {
      room.packagesSelection.forEach(pcks => {
        var meal = adultsMeals === null || adultsMeals === void 0 ? void 0 : adultsMeals.find(meal => meal.id === pcks.id);
        var mealPrice = meal ? meal.price : 0;
        if (mealPrice && pcks !== null && pcks !== void 0 && pcks.noOfSelections) {
          totalCost += mealPrice * (pcks === null || pcks === void 0 ? void 0 : pcks.noOfSelections) * noOfNights;
        }
      });
    }
  });
  return Number(totalCost.toFixed(2));
};
exports.calculateTotalCostRoomSelection = calculateTotalCostRoomSelection;
var formatImportantNotes = (importantMessages, arrivalDate, departureDate) => {
  var sortedNotesByPriority = importantMessages === null || importantMessages === void 0 ? void 0 : importantMessages.sort((a, b) => a !== null && a !== void 0 && a.priority && b !== null && b !== void 0 && b.priority && (a === null || a === void 0 ? void 0 : a.priority) > (b === null || b === void 0 ? void 0 : b.priority) ? 1 : -1);
  return sortedNotesByPriority.filter(note => {
    var _note$startDate, _note$endDate;
    var newStartDate = (0, _parse.default)((_note$startDate = note === null || note === void 0 ? void 0 : note.startDate) !== null && _note$startDate !== void 0 ? _note$startDate : '', _api.DATE_TYPE.DD_MM_YYYY, new Date());
    var newEndDate = (0, _parse.default)((_note$endDate = note === null || note === void 0 ? void 0 : note.endDate) !== null && _note$endDate !== void 0 ? _note$endDate : '', _api.DATE_TYPE.DD_MM_YYYY, new Date());
    var arrival = (0, _parse.default)(arrivalDate, _api.DATE_TYPE.YEAR_MONTH_DAY, new Date());
    var departure = (0, _parse.default)(departureDate, _api.DATE_TYPE.YEAR_MONTH_DAY, new Date());
    return newStartDate <= arrival && arrival <= newEndDate || newStartDate <= departure && departure <= newEndDate || arrival <= newStartDate && departure >= newEndDate;
  }).map(item => item.text).join('<br/>');
};
exports.formatImportantNotes = formatImportantNotes;
var getUniqueRoomProperties = exports.getUniqueRoomProperties = function getUniqueRoomProperties(rooms) {
  var property = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'roomType';
  return rooms === null || rooms === void 0 ? void 0 : rooms.map(room => {
    var _room$roomStay;
    return room === null || room === void 0 || (_room$roomStay = room.roomStay) === null || _room$roomStay === void 0 || (_room$roomStay = _room$roomStay.roomExtraInfo) === null || _room$roomStay === void 0 ? void 0 : _room$roomStay[property];
  }).filter((value, index, self) => self.indexOf(value) === index).join();
};
var checkIfExistPreselection = roomSelections => {
  return roomSelections === null || roomSelections === void 0 ? void 0 : roomSelections.some(room => {
    var _room$packagesSelecti2;
    return (room === null || room === void 0 ? void 0 : room.packagesSelection) && (room === null || room === void 0 || (_room$packagesSelecti2 = room.packagesSelection) === null || _room$packagesSelecti2 === void 0 ? void 0 : _room$packagesSelecti2.length) > 0;
  });
};
exports.checkIfExistPreselection = checkIfExistPreselection;
var shouldDisplayAutocompleteNotification = (listGuests, selectedMeals, prefMealId) => {
  return selectedMeals.every((roomSelected, indexSel) => {
    var prefAdultMealForEntire = roomSelected.adults.filter(meal => meal === prefMealId.adultMeal).length === listGuests.adultsNumber[indexSel];
    var prefChildrenMealsForEntire = listGuests.childrenNumber[indexSel] > 0 && (0, _validators.isStringValid)(prefMealId.childMeal) ? roomSelected.children.filter(meal => meal === prefMealId.childMeal).length === listGuests.childrenNumber[indexSel] : true;
    return prefAdultMealForEntire && prefChildrenMealsForEntire;
  });
};
exports.shouldDisplayAutocompleteNotification = shouldDisplayAutocompleteNotification;
var transformBartIdToOperaId = (bartId, meals) => {
  var _meals$find$id, _meals$find;
  return (_meals$find$id = (_meals$find = meals.find(meal => meal.bartId === bartId)) === null || _meals$find === void 0 ? void 0 : _meals$find.id) !== null && _meals$find$id !== void 0 ? _meals$find$id : '';
};
exports.transformBartIdToOperaId = transformBartIdToOperaId;
var isCityTaxAvailable = exports.isCityTaxAvailable = function isCityTaxAvailable() {
  var roomSelection = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
  var isLeisure = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;
  return isLeisure && roomSelection && roomSelection.length > 0 ? roomSelection.some(room => room.packagesSelection ? room.packagesSelection.some(pck => (pck === null || pck === void 0 ? void 0 : pck.id) === _api.CITYTAX_PACKAGE && (pck === null || pck === void 0 ? void 0 : pck.noOfSelections) > 0) : false) : false;
};
function sortMealsByReservationId(mealsInfo, reservations) {
  var orderedMeals = [];
  reservations.forEach(reservation => {
    var mealPackage = mealsInfo.find(meal => meal.reservationId === reservation.reservationId);
    mealPackage && orderedMeals.push(mealPackage);
  });
  return orderedMeals;
}
function filterPackagesByAncillariesCloseOut(ancillaryFilterData) {
  var ancillaryData = (0, _formatters.filterAncillaryCloseOutData)(ancillaryFilterData.ancillaryCloseoutData);
  var packageCodeSet = new Set();
  ancillaryData === null || ancillaryData === void 0 || ancillaryData.map(ancillaryCloseoutItem => {
    var _ancillaryCloseoutIte, _ancillaryCloseoutIte2;
    var arrivalDate = (0, _parse.default)(ancillaryFilterData.arrivalDate, _api.DATE_TYPE.YEAR_MONTH_DAY, new Date());
    var departureDate = (0, _parse.default)(ancillaryFilterData.departureDate, _api.DATE_TYPE.YEAR_MONTH_DAY, new Date());
    var closeoutStartDate = (0, _parse.default)((_ancillaryCloseoutIte = ancillaryCloseoutItem.startDate) !== null && _ancillaryCloseoutIte !== void 0 ? _ancillaryCloseoutIte : '', _api.DATE_TYPE.DD_MM_YYYY, new Date());
    var closeoutEndDate = (0, _parse.default)((_ancillaryCloseoutIte2 = ancillaryCloseoutItem.endDate) !== null && _ancillaryCloseoutIte2 !== void 0 ? _ancillaryCloseoutIte2 : '', _api.DATE_TYPE.DD_MM_YYYY, new Date());
    var ischeckinDateBeweenCloseout = (0, _isWithinInterval.default)(arrivalDate, {
      start: closeoutStartDate,
      end: closeoutEndDate
    });
    var ischeckoutDateBeweenCloseout = (0, _isWithinInterval.default)(departureDate, {
      start: closeoutStartDate,
      end: closeoutEndDate
    });
    var isCloseoutBeweenBookingInterval = (0, _isAfter.default)(closeoutStartDate, arrivalDate) && (0, _isBefore.default)(closeoutEndDate, departureDate);
    if (ischeckinDateBeweenCloseout || ischeckoutDateBeweenCloseout || isCloseoutBeweenBookingInterval) {
      var _ancillaryCloseoutIte3;
      var packageCodes = ancillaryCloseoutItem === null || ancillaryCloseoutItem === void 0 || (_ancillaryCloseoutIte3 = ancillaryCloseoutItem.upsellCodes) === null || _ancillaryCloseoutIte3 === void 0 ? void 0 : _ancillaryCloseoutIte3.split(',');
      packageCodes === null || packageCodes === void 0 || packageCodes.forEach(item => {
        packageCodeSet.add(item.trim());
      });
    }
  });
  var filteredAdultsMeals = ancillaryFilterData.adultsMeals.filter(meal => !packageCodeSet.has(meal.id));
  var filteredChildrenMeals = ancillaryFilterData.childrenMeals.filter(meal => !packageCodeSet.has(meal.id));
  var filteredClosedOutMeals = ancillaryFilterData.adultsMeals.filter(meal => packageCodeSet.has(meal.id));
  return {
    filteredAdultsMeals,
    filteredChildrenMeals,
    filteredClosedOutMeals
  };
}
function getCurrentReservationStorageData(reservationId) {
  if (typeof window !== 'undefined') {
    var roomStorageData = window.localStorage.getItem(_api.SILENT_SUBSTITUTION_STORAGE_KEY);
    var parsedRoomStorageData = roomStorageData && JSON.parse(roomStorageData);
    return parsedRoomStorageData ? parsedRoomStorageData[reservationId] : null;
  }
  return null;
}
var addReservationNumber = (roomSelections, reservationByIdList, selectedExtrasList) => {
  var roomPackagesSelection = [];
  if (reservationByIdList.length) {
    roomSelections.forEach((selection, index) => {
      var _reservationByIdList$2, _reservationByIdList$3;
      selectedExtrasList === null || selectedExtrasList === void 0 || selectedExtrasList.map(extras => {
        var _reservationByIdList$;
        if ((extras === null || extras === void 0 ? void 0 : extras.reservationId) === ((_reservationByIdList$ = reservationByIdList[index]) === null || _reservationByIdList$ === void 0 ? void 0 : _reservationByIdList$.reservationId)) {
          var _extras$packagesList;
          extras === null || extras === void 0 || (_extras$packagesList = extras.packagesList) === null || _extras$packagesList === void 0 || _extras$packagesList.forEach(extrasPackage => {
            var _selection$packagesSe;
            selection === null || selection === void 0 || (_selection$packagesSe = selection.packagesSelection) === null || _selection$packagesSe === void 0 || _selection$packagesSe.push({
              id: extrasPackage,
              noOfSelections: 1
            });
          });
        }
      });
      var selectionPerRoom = _objectSpread(_objectSpread({}, selection), {}, {
        reservationId: (_reservationByIdList$2 = (_reservationByIdList$3 = reservationByIdList[index]) === null || _reservationByIdList$3 === void 0 ? void 0 : _reservationByIdList$3.reservationId) !== null && _reservationByIdList$2 !== void 0 ? _reservationByIdList$2 : ''
      });
      return roomPackagesSelection.push(selectionPerRoom);
    });
  }
  return roomPackagesSelection;
};
exports.addReservationNumber = addReservationNumber;
var extrasPackagesMapperSelector = roomSelections => {
  var extrasPackagesListPerRooms = roomSelections === null || roomSelections === void 0 ? void 0 : roomSelections.map(room => {
    var _room$packagesSelecti3;
    var extrasPackagesPerRoom = {
      packagesList: [],
      reservationId: room === null || room === void 0 ? void 0 : room.reservationId,
      price: 0,
      previousEciSelection: 0,
      previousLcoSelection: 0
    };
    room === null || room === void 0 || (_room$packagesSelecti3 = room.packagesSelection) === null || _room$packagesSelecti3 === void 0 || _room$packagesSelecti3.map(selectedPackage => {
      if ((selectedPackage === null || selectedPackage === void 0 ? void 0 : selectedPackage.id) === _api.ExtrasId.EARLY_CHECK_IN || (selectedPackage === null || selectedPackage === void 0 ? void 0 : selectedPackage.id) === _api.ExtrasId.LATE_CHECK_OUT || (selectedPackage === null || selectedPackage === void 0 ? void 0 : selectedPackage.id) === _api.ExtrasId.ULTIMATE_WIFI) {
        extrasPackagesPerRoom === null || extrasPackagesPerRoom === void 0 || extrasPackagesPerRoom.packagesList.push(selectedPackage.id);
        if (extrasPackagesPerRoom !== null && extrasPackagesPerRoom !== void 0 && extrasPackagesPerRoom.packagesList.some(extrasId => extrasId === _api.ExtrasId.EARLY_CHECK_IN)) extrasPackagesPerRoom.previousEciSelection = 1;
        if (extrasPackagesPerRoom !== null && extrasPackagesPerRoom !== void 0 && extrasPackagesPerRoom.packagesList.some(extrasId => extrasId === _api.ExtrasId.LATE_CHECK_OUT)) extrasPackagesPerRoom.previousLcoSelection = 1;
      }
    });
    return extrasPackagesPerRoom;
  });
  return extrasPackagesListPerRooms;
};
exports.extrasPackagesMapperSelector = extrasPackagesMapperSelector;
function addOrRemoveExtrasRoom(extrasId, price, selectedExtrasList, selectedRoom) {
  var _selectedExtrasList$s, _selectedExtrasList$s2;
  var canBeRemoved = selectedExtrasList === null || selectedExtrasList === void 0 || (_selectedExtrasList$s = selectedExtrasList[selectedRoom]) === null || _selectedExtrasList$s === void 0 ? void 0 : _selectedExtrasList$s.packagesList.includes(extrasId);
  var reservationIdPerRoom = selectedExtrasList === null || selectedExtrasList === void 0 || (_selectedExtrasList$s2 = selectedExtrasList[selectedRoom]) === null || _selectedExtrasList$s2 === void 0 || (_selectedExtrasList$s2 = _selectedExtrasList$s2.reservationId) === null || _selectedExtrasList$s2 === void 0 ? void 0 : _selectedExtrasList$s2.toString();
  var updatedExtrasItemsList = selectedExtrasList === null || selectedExtrasList === void 0 ? void 0 : selectedExtrasList.map(extrasItem => {
    if (extrasItem.reservationId === reservationIdPerRoom && !canBeRemoved) {
      extrasItem.packagesList.push(extrasId);
      extrasItem.price = extrasItem.price + price;
    }
    if (extrasItem.reservationId === reservationIdPerRoom && canBeRemoved) {
      var indexOfExtrasPackage = extrasItem.packagesList.indexOf(extrasId);
      extrasItem.packagesList.splice(indexOfExtrasPackage, 1);
      extrasItem.price = extrasItem.price - price;
    }
    return extrasItem;
  });
  return updatedExtrasItemsList;
}
function addOrRemoveAllRoomsExtras(extrasId, price, selectedExtrasList, isRemovable) {
  var updatedExtrasItemsList = [];
  selectedExtrasList === null || selectedExtrasList === void 0 || selectedExtrasList.forEach(extrasItem => {
    var canBeRemoved = extrasItem === null || extrasItem === void 0 ? void 0 : extrasItem.packagesList.includes(extrasId);
    if (!isRemovable && !canBeRemoved) {
      extrasItem.packagesList.push(extrasId);
      extrasItem.price = extrasItem.price + price;
    }
    if (isRemovable && canBeRemoved) {
      var indexOfExtrasPackage = extrasItem.packagesList.indexOf(extrasId);
      extrasItem.packagesList.splice(indexOfExtrasPackage, 1);
      extrasItem.price = extrasItem.price - price;
    }
    updatedExtrasItemsList.push(extrasItem);
  });
  return updatedExtrasItemsList;
}
function roomPackageSelection(selectedExtrasList) {
  var packageSelection = [];
  selectedExtrasList === null || selectedExtrasList === void 0 || selectedExtrasList.map(room => {
    var _room$packagesList;
    var roomExtras = room === null || room === void 0 || (_room$packagesList = room.packagesList) === null || _room$packagesList === void 0 ? void 0 : _room$packagesList.map(extras => {
      return {
        id: extras,
        noOfSelections: 1
      };
    });
    return packageSelection.push({
      packagesSelection: roomExtras,
      price: room === null || room === void 0 ? void 0 : room.price,
      reservationId: room === null || room === void 0 ? void 0 : room.reservationId
    });
  });
  return packageSelection;
}