"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _objectWithoutProperties2 = _interopRequireDefault(require("@babel/runtime/helpers/objectWithoutProperties"));
var _react = require("@chakra-ui/react");
var _react2 = require("react");
var _jsxRuntime = require("react/jsx-runtime");
var _excluded = ["formatAssetsUrl", "options", "placeholder", "onChange"];
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function CountriesList(_ref) {
  var {
      formatAssetsUrl,
      options,
      placeholder,
      onChange
    } = _ref,
    rest = (0, _objectWithoutProperties2.default)(_ref, _excluded);
  var [filteredList, setFilteredList] = (0, _react2.useState)(options);
  var handleSearch = (0, _react2.useCallback)(event => {
    var value = event.target.value.toLowerCase();
    var result = (options === null || options === void 0 ? void 0 : options.filter(item => {
      return item.countryName.toLowerCase().includes(value) || item.dialingCode.toLowerCase().includes(value);
    })) || [];
    setFilteredList(result);
  }, [options]);
  return (0, _jsxRuntime.jsxs)(_react.Box, _objectSpread(_objectSpread(_objectSpread({}, containerStyle), rest), {}, {
    children: [(0, _jsxRuntime.jsx)(_react.Box, _objectSpread(_objectSpread({}, searchContainerStyle), {}, {
      children: (0, _jsxRuntime.jsx)(_react.Input, _objectSpread(_objectSpread({
        autoComplete: "off",
        isInvalid: false,
        placeholder: placeholder
      }, searchInputStyle), {}, {
        onChange: handleSearch
      }))
    })), (0, _jsxRuntime.jsx)(_react.List, {
      children: filteredList === null || filteredList === void 0 ? void 0 : filteredList.map(item => (0, _jsxRuntime.jsx)(_react.ListItem, _objectSpread(_objectSpread({}, listItemStyle), {}, {
        onClick: () => {
          onChange(item);
        },
        children: (0, _jsxRuntime.jsxs)(_react.Flex, {
          children: [(0, _jsxRuntime.jsx)(_react.Image, {
            borderRadius: "full",
            boxSize: "1.5rem",
            src: formatAssetsUrl(item.flagSrc),
            title: item.countryName,
            alt: item.countryName
          }), (0, _jsxRuntime.jsx)(_react.Text, {
            as: "span",
            mx: 3,
            children: item.dialingCode
          }), (0, _jsxRuntime.jsx)(_react.Text, {
            as: "span",
            children: item.countryName
          })]
        })
      }), "".concat(item.countryCode, "-").concat(item.countryName)))
    })]
  }));
}
var containerStyle = {
  bg: 'white',
  borderRadius: 'lg',
  boxShadow: '0px 1px 30px rgba(0, 0, 0, 0.1)',
  height: 'auto',
  maxH: 'xs',
  my: 1,
  overflow: 'auto',
  position: 'absolute',
  width: 'full',
  zIndex: 999
};
var listItemStyle = {
  cursor: 'pointer',
  px: 4,
  py: 3,
  _hover: {
    bg: 'lightGrey5'
  }
};
var searchContainerStyle = {
  bg: 'white',
  p: 4,
  position: 'sticky',
  top: 0
};
var searchInputStyle = {
  borderRadius: 'md',
  size: 'md',
  _focusWithin: {
    borderColor: 'primary'
  }
};
var _default = exports.default = (0, _react2.memo)(CountriesList);