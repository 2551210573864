"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = BookingDatepickerComponent;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _react = require("@chakra-ui/react");
var _atoms = require("@whitbread-eos/atoms");
var _add = _interopRequireDefault(require("date-fns/add"));
var _differenceInDays = _interopRequireDefault(require("date-fns/differenceInDays"));
var _nextI18next = require("next-i18next");
var _react2 = require("react");
var _jsxRuntime = require("react/jsx-runtime");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function BookingDatepickerComponent(_ref) {
  var _inputPlaceholderDate, _partialTranslations$, _partialTranslations$2;
  var {
    maxNumberOfNights,
    maxArrivalDate,
    displayDateFormat,
    onSelectDates,
    inputPlaceholderDatepicker,
    defaultStartDate,
    datepickerStyles,
    defaultEndDate,
    partialTranslations,
    locale,
    showErrorMessage,
    errorMessage,
    isError,
    isLessThanSm,
    isDatePickerFocus,
    displayDatesNotification
  } = _ref;
  var [maxDate, setMaxDate] = (0, _react2.useState)((0, _add.default)(new Date(), {
    days: maxArrivalDate
  }));
  var displayNewDatesNotification = displayDatesNotification && !defaultStartDate;
  var {
    t
  } = (0, _nextI18next.useTranslation)();
  return (0, _jsxRuntime.jsxs)(_react.Box, _objectSpread(_objectSpread({
    position: "relative"
  }, datepickerStyles.bookingDatepickerSize), {}, {
    children: [(0, _jsxRuntime.jsx)(_atoms.Datepicker, _objectSpread(_objectSpread({}, locale === 'de' ? {
      locale: 'de'
    } : {}), {}, {
      minDate: new Date(),
      maxDate: maxDate,
      inputPlaceholder: (_inputPlaceholderDate = inputPlaceholderDatepicker === null || inputPlaceholderDatepicker === void 0 ? void 0 : inputPlaceholderDatepicker.toString()) !== null && _inputPlaceholderDate !== void 0 ? _inputPlaceholderDate : "".concat(partialTranslations === null || partialTranslations === void 0 || (_partialTranslations$ = partialTranslations.content) === null || _partialTranslations$ === void 0 || (_partialTranslations$ = _partialTranslations$.global) === null || _partialTranslations$ === void 0 ? void 0 : _partialTranslations$.today, " | ").concat(partialTranslations === null || partialTranslations === void 0 || (_partialTranslations$2 = partialTranslations.content) === null || _partialTranslations$2 === void 0 || (_partialTranslations$2 = _partialTranslations$2.global) === null || _partialTranslations$2 === void 0 ? void 0 : _partialTranslations$2.tomorrow),
      dateFormat: 'dd MMM yyyy',
      displayDateFormat: displayDateFormat,
      labels: {
        resetButtonLabel: partialTranslations.datePicker.reset,
        doneButtonLabel: partialTranslations.content.global.done,
        todayLabel: partialTranslations.content.global.today,
        tomorrowLabel: partialTranslations.content.global.tomorrow,
        checkoutLabel: partialTranslations.datePicker.checkOut,
        checkInLabel: t('dashboard.bookings.checkIn')
      },
      hasFooter: true,
      selectsRange: true,
      datepickerStyles: datepickerStyles,
      onSelectDates: handleSelectDates,
      onReset: handleReset,
      defaultStartDate: defaultStartDate,
      defaultEndDate: defaultEndDate,
      isError: isError,
      isDatePickerFocus: isDatePickerFocus,
      displayDatesNotification: displayDatesNotification
    })), !isLessThanSm && showErrorMessage && errorMessage && (0, _jsxRuntime.jsx)(_atoms.InfoMessage, {
      infoMessage: errorMessage,
      otherStyles: _objectSpread({}, alertStyles)
    }), displayNewDatesNotification && (0, _jsxRuntime.jsx)(_atoms.InfoMessage, {
      infoMessage: t('ccui.search.enterDates'),
      otherStyles: _objectSpread({}, newDatesNotifStyles),
      variant: 'Info'
    })]
  }));
  function handleSelectDates(dates) {
    var [arrivalDate, departureDate] = dates;
    onSelectDates === null || onSelectDates === void 0 || onSelectDates(dates);
    if (departureDate) {
      setMaxDate((0, _add.default)(new Date(), {
        days: maxArrivalDate
      }));
      return;
    }
    if (arrivalDate && (0, _differenceInDays.default)((0, _add.default)(new Date(), {
      days: maxArrivalDate
    }), arrivalDate) < maxNumberOfNights) {
      setMaxDate((0, _add.default)(new Date(maxDate), {
        days: 1
      }));
      return;
    }
    arrivalDate && setMaxDate((0, _add.default)(new Date(arrivalDate), {
      days: maxNumberOfNights
    }));
  }
  function handleReset() {
    setMaxDate((0, _add.default)(new Date(), {
      days: maxArrivalDate
    }));
    onSelectDates === null || onSelectDates === void 0 || onSelectDates([null, null]);
  }
}
var alertStyles = {
  w: {
    base: 'full',
    sm: '20rem',
    lg: 'full'
  },
  zIndex: 100
};
var newDatesNotifStyles = {
  backgroundColor: 'tooltipInfo',
  w: {
    base: 'full',
    sm: '20rem',
    lg: 'full'
  },
  zIndex: 100,
  _before: {
    content: '" "',
    position: 'absolute',
    top: '-0.15rem',
    left: 'xs',
    width: '1.5rem',
    height: '0.75rem',
    zIndex: '-1',
    transform: 'rotate(-45deg)',
    bgColor: 'tooltipInfo',
    borderRadius: '3'
  }
};