"use strict";
'use client';

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.SanitizedContent = void 0;
var _utils = require("@whitbread-eos/utils");
var _react = _interopRequireDefault(require("react"));
var _jsxRuntime = require("react/jsx-runtime");
var SanitizedContent = _ref => {
  var {
    children,
    replacements = {}
  } = _ref;
  var childrenArray = _react.default.Children.toArray(children);
  var htmlFragments = [];
  childrenArray.forEach(child => {
    if (typeof child === 'string') {
      var modifiedHtml = child;
      for (var [key, value] of Object.entries(replacements)) {
        modifiedHtml = modifiedHtml.replace(new RegExp(key, 'g'), value);
      }
      htmlFragments.push(modifiedHtml);
    } else {
      htmlFragments.push(String(child));
    }
  });
  var html = htmlFragments.join('');
  return (0, _jsxRuntime.jsx)(_jsxRuntime.Fragment, {
    children: (0, _utils.renderSanitizedHtml)(html)
  });
};
exports.SanitizedContent = SanitizedContent;