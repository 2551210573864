"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "FormSelect", {
  enumerable: true,
  get: function get() {
    return _formSelect.FormSelect;
  }
});
var _formSelect = require("./form-select");