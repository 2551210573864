"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = useElementVisited;
var _react = require("react");
function useElementVisited(elementId) {
  var [elementVisited, setElementVisited] = (0, _react.useState)(false);
  (0, _react.useEffect)(() => {
    var hasElementVisited = () => {
      var element = document.getElementById(elementId);
      if (element && window.scrollY > element.getBoundingClientRect().top + element.offsetHeight) {
        setElementVisited(true);
      }
    };
    document.addEventListener('scroll', hasElementVisited);
    return () => {
      document.removeEventListener('scroll', hasElementVisited);
    };
  }, []);
  return elementVisited;
}