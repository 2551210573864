"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = InfoMessage;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _react = require("@chakra-ui/react");
var _icons = require("../../assets/icons");
var _Icon = _interopRequireDefault(require("../Icon"));
var _jsxRuntime = require("react/jsx-runtime");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function InfoMessage(_ref) {
  var {
    infoMessage,
    otherStyles,
    variant = ''
  } = _ref;
  var setIconVariant = variant => {
    if (variant === 'Info') {
      return (0, _jsxRuntime.jsx)(_icons.Info, {});
    } else {
      return (0, _jsxRuntime.jsx)(_icons.Error, {});
    }
  };
  return (0, _jsxRuntime.jsxs)(_react.Alert, _objectSpread(_objectSpread({
    position: {
      base: 'relative',
      sm: 'absolute'
    }
  }, _objectSpread(_objectSpread({}, baseStyles), otherStyles)), {}, {
    children: [(0, _jsxRuntime.jsx)(_Icon.default, _objectSpread({
      svg: setIconVariant(variant)
    }, iconStyles)), (0, _jsxRuntime.jsx)(_react.Flex, {
      direction: "column",
      justifyContent: "space-evenly",
      children: (0, _jsxRuntime.jsx)(_react.Text, {
        ml: "sm",
        minW: "full",
        w: "full",
        children: infoMessage
      })
    })]
  }));
}
var baseStyles = {
  bgColor: 'tooltipError',
  pl: 'sm',
  marginTop: 'md',
  fontSize: 'sm',
  lineHeight: '2',
  fontWeight: 'normal',
  borderRadius: '3',
  overflow: 'none',
  zIndex: '999',
  _before: {
    content: '" "',
    position: 'absolute',
    top: '-0.15rem',
    left: 'xs',
    width: '1.5rem',
    height: '0.75rem',
    zIndex: '-1',
    transform: 'rotate(-45deg)',
    bgColor: 'tooltipError',
    borderRadius: '3'
  }
};
var iconStyles = {
  mt: '-0.15rem',
  ml: 'xs'
};