"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.TRIP_ADVISOR_SCHEMA_REVIEW_URL = exports.TRIP_ADVISOR_SCHEMA_RATING_URL = exports.TRIP_ADVISOR_SCHEMA_PERSON_URL = exports.TRIP_ADVISOR_SCHEMA_ORGANIZATION_URL = exports.TRIP_ADVISOR_RATING_IMAGE_URL_PART2 = exports.TRIP_ADVISOR_RATING_IMAGE_URL_PART1 = void 0;
var TRIP_ADVISOR_RATING_IMAGE_URL_PART1 = exports.TRIP_ADVISOR_RATING_IMAGE_URL_PART1 = 'https://static.tacdn.com/img2/ratings/traveler/ss';
var TRIP_ADVISOR_RATING_IMAGE_URL_PART2 = exports.TRIP_ADVISOR_RATING_IMAGE_URL_PART2 = '.svg';
var TRIP_ADVISOR_SCHEMA_REVIEW_URL = exports.TRIP_ADVISOR_SCHEMA_REVIEW_URL = 'http://schema.org/Review';
var TRIP_ADVISOR_SCHEMA_ORGANIZATION_URL = exports.TRIP_ADVISOR_SCHEMA_ORGANIZATION_URL = 'http://schema.org/Organization';
var TRIP_ADVISOR_SCHEMA_RATING_URL = exports.TRIP_ADVISOR_SCHEMA_RATING_URL = 'http://schema.org/Rating';
var TRIP_ADVISOR_SCHEMA_PERSON_URL = exports.TRIP_ADVISOR_SCHEMA_PERSON_URL = 'http://schema.org/Person';