"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Currency = void 0;
var Currency = exports.Currency = function (Currency) {
  Currency["EUR"] = "\u20AC";
  Currency["EUR_NAME"] = "EUR";
  Currency["GBP"] = "\xA3";
  Currency["GBP_NAME"] = "GBP";
  return Currency;
}({});