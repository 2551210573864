"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = HotelBreadcrumb;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _react = require("@chakra-ui/react");
var _atoms = require("@whitbread-eos/atoms");
var _nextI18next = require("next-i18next");
var _react2 = require("react");
var _jsxRuntime = require("react/jsx-runtime");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function HotelBreadcrumb(_ref) {
  var {
    breadcrumb,
    isLoading,
    isError,
    error,
    openInNewTab
  } = _ref;
  var {
    t
  } = (0, _nextI18next.useTranslation)(['common']);
  var [breadcrumbList, setBreadcrumbList] = (0, _react2.useState)([]);
  (0, _react2.useEffect)(() => {
    var breadcrumbListTemp = breadcrumb ? breadcrumb.map((breadcrumbData, index) => ({
      name: breadcrumbData.title,
      url: index === breadcrumb.length - 1 ? '#' : breadcrumbData.link,
      isCurrentPage: index === breadcrumb.length - 1
    })) : [];
    setBreadcrumbList(breadcrumbListTemp);
  }, [breadcrumb]);
  if (isLoading) {
    return (0, _jsxRuntime.jsx)(_react.Text, {
      children: t('searchresults.list.hotel.loading')
    });
  }
  if (isError) {
    return (0, _jsxRuntime.jsx)(_react.Text, {
      children: error === null || error === void 0 ? void 0 : error.message
    });
  }
  return breadcrumbList.length ? (0, _jsxRuntime.jsx)(_react.Box, _objectSpread(_objectSpread({
    "data-testid": "breadcrumbs-hdp"
  }, breadcrumbBoxStyles), {}, {
    children: (0, _jsxRuntime.jsx)(_atoms.Breadcrumb, {
      items: breadcrumbList,
      openInNewTab: openInNewTab
    })
  })) : null;
}
var breadcrumbBoxStyles = {
  mt: 'xl',
  mb: 'xl'
};