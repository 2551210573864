"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = useUpdateSearchParams;
var _navigation = require("next/navigation");
var _react = require("react");
function useUpdateSearchParams() {
  var searchParams = (0, _navigation.useSearchParams)();
  var updateSearchParams = (0, _react.useCallback)((name, value) => {
    var params = new URLSearchParams(searchParams === null || searchParams === void 0 ? void 0 : searchParams.toString());
    if (value) {
      params.set(name, value);
    } else {
      params.delete(name);
    }
    return "?".concat(params);
  }, [searchParams]);
  return {
    updateSearchParams
  };
}