"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = FormRelatedFields;
var _reactHookForm = require("react-hook-form");
var _FormField = _interopRequireDefault(require("../FormField"));
var _jsxRuntime = require("react/jsx-runtime");
function FormRelatedFields(_ref) {
  var {
    fieldName,
    relatedFields,
    control,
    errors,
    getValues,
    handleSetValue,
    handleResetField,
    handleSetError,
    handleClearErrors,
    reset
  } = _ref;
  var selectedValue = (0, _reactHookForm.useWatch)({
    name: fieldName,
    control
  });
  if (!!relatedFields && !!selectedValue) {
    var _relatedFields$select, _relatedFields$select2;
    return (0, _jsxRuntime.jsx)(_jsxRuntime.Fragment, {
      children: (_relatedFields$select = relatedFields[selectedValue]) === null || _relatedFields$select === void 0 || (_relatedFields$select2 = _relatedFields$select.map) === null || _relatedFields$select2 === void 0 ? void 0 : _relatedFields$select2.call(_relatedFields$select, field => {
        var _field$id;
        return (0, _jsxRuntime.jsx)(_FormField.default, {
          control: control,
          formField: field,
          errors: errors,
          handleSetValue: handleSetValue,
          handleResetField: handleResetField,
          handleSetError: handleSetError,
          handleClearErrors: handleClearErrors,
          getValues: getValues,
          reset: reset
        }, "".concat(field.name, "-").concat((_field$id = field.id) !== null && _field$id !== void 0 ? _field$id : ''));
      })
    });
  }
  return null;
}