"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.SEARCH_CUSTOMER_ACCOUNTS = void 0;
var _taggedTemplateLiteral2 = _interopRequireDefault(require("@babel/runtime/helpers/taggedTemplateLiteral"));
var _graphqlRequest = require("graphql-request");
var _templateObject;
var SEARCH_CUSTOMER_ACCOUNTS = exports.SEARCH_CUSTOMER_ACCOUNTS = (0, _graphqlRequest.gql)(_templateObject || (_templateObject = (0, _taggedTemplateLiteral2.default)(["\n  query SearchCustomerAccounts(\n    $firstName: String\n    $address: String\n    $companyName: String\n    $email: String\n    $landline: String\n    $lastName: String\n    $mobile: String\n    $postCode: String\n  ) {\n    customerAccounts(\n      customerAccountSearchCriteria: {\n        firstName: $firstName\n        address: $address\n        companyName: $companyName\n        email: $email\n        landline: $landline\n        lastName: $lastName\n        mobile: $mobile\n        postCode: $postCode\n      }\n    ) {\n      title\n      companyName\n      firstName\n      companyPostalCode\n      customerAccountId\n      email\n      homePostalCode\n      landline\n      lastName\n      mobile\n    }\n  }\n"])));