"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.PageName = void 0;
var PageName = exports.PageName = function (PageName) {
  PageName["HOME"] = "home";
  PageName["SRP"] = "SRP";
  PageName["HDP"] = "HDP";
  PageName["CYB"] = "CYB";
  PageName["CYT"] = "CYT";
  PageName["ANCILLARIES"] = "ancillaries";
  PageName["GUEST_DETAILS"] = "gdp";
  PageName["PAYMENT"] = "payment";
  PageName["CONFIRMATION"] = "confirmation";
  PageName["AMEND"] = "amend";
  PageName["DASHBOARD"] = "dashboard";
  PageName["REGISTER"] = "register";
  PageName["GROUP_BOOKINGS"] = "groupBookings";
  return PageName;
}({});