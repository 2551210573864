"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = useDebounce;
var _debounce = _interopRequireDefault(require("lodash/debounce"));
var _react = require("react");
function useDebounce(callback) {
  var delay = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 500;
  var ref = (0, _react.useRef)();
  (0, _react.useEffect)(() => {
    ref.current = callback;
  }, [callback]);
  var debouncedCallback = (0, _react.useMemo)(() => {
    var func = () => {
      var _ref$current;
      (_ref$current = ref.current) === null || _ref$current === void 0 || _ref$current.call(ref);
    };
    return (0, _debounce.default)(func, delay);
  }, []);
  return debouncedCallback;
}