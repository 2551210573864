"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.waitForElement = waitForElement;
function waitForElement(selector) {
  var maxTimeOut = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 5000;
  var TIME_OUT = 50;
  var elapsedTime = 0;
  return new Promise((resolve, reject) => {
    var timer = setInterval(() => {
      elapsedTime += TIME_OUT;
      if (elapsedTime > maxTimeOut) {
        reject(new Error('Something went wrong when waiting for element'));
        clearInterval(timer);
      }
      var el = document.querySelector(selector);
      if (el) {
        clearInterval(timer);
        resolve(el);
      }
    }, TIME_OUT);
  });
}