"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = BookersReferenceDetails;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _react = require("@chakra-ui/react");
var _api = require("@whitbread-eos/api");
var _atoms = require("@whitbread-eos/atoms");
var _nextI18next = require("next-i18next");
var _react2 = _interopRequireWildcard(require("react"));
var _styles = require("../styles");
var _jsxRuntime = require("react/jsx-runtime");
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && {}.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function BookersReferenceDetails(_ref) {
  var {
    bookerReferencesDetails,
    setBookerReferencesDetails,
    setHasError
  } = _ref;
  var [errorPurchaseOrderNumber, setErrorPurchaseOrderNumber] = (0, _react2.useState)('');
  var [errorCompanyReference, setErrorCompanyReference] = (0, _react2.useState)('');
  var {
    t
  } = (0, _nextI18next.useTranslation)(['common']);
  var baseTestId = 'bookersReferenceDetailsSection';
  return (0, _jsxRuntime.jsxs)(_react.Flex, {
    mb: '5xl',
    flexDir: "column",
    "data-testid": "".concat(baseTestId),
    mt: 16,
    children: [(0, _jsxRuntime.jsx)(_react.Box, {
      pb: 4,
      "data-testid": "".concat(baseTestId, "_purchaseOrderNumber_title"),
      children: (0, _jsxRuntime.jsx)(_react.Heading, _objectSpread(_objectSpread({
        as: "h3"
      }, _styles.subHeaderStyles), {}, {
        children: t('ccui.purchaseOrderNumber.title')
      }))
    }), (0, _jsxRuntime.jsx)(_react.Box, {
      pb: 8,
      "data-testid": "".concat(baseTestId, "_purchaseOrderNumber_info"),
      children: (0, _jsxRuntime.jsx)(_react.Heading, _objectSpread(_objectSpread({
        as: "h6"
      }, _styles.descriptionStyles), {}, {
        "data-testid": "".concat(baseTestId, "_purchaseOrderNumber_info-heading"),
        children: t('ccui.purchaseOrderNumber.description')
      }))
    }), (0, _jsxRuntime.jsx)(_react.Box, _objectSpread(_objectSpread({
      "data-testid": "".concat(baseTestId, "_purchaseOrderNumber_input")
    }, boxStyles), {}, {
      children: (0, _jsxRuntime.jsx)(_atoms.Input, {
        name: "purchaseOrderNumber",
        value: bookerReferencesDetails.purchaseOrderNumber,
        onChange: onPurchaseOrderNumberChange,
        placeholderText: t('ccui.purchaseOrderNumber.placeholder'),
        error: errorPurchaseOrderNumber
      })
    })), (0, _jsxRuntime.jsx)(_react.Box, {
      mt: 16,
      pb: 4,
      "data-testid": "".concat(baseTestId, "_companyReference_title"),
      children: (0, _jsxRuntime.jsx)(_react.Heading, _objectSpread(_objectSpread({
        as: "h3"
      }, _styles.subHeaderStyles), {}, {
        children: t('ccui.companyReference.title')
      }))
    }), (0, _jsxRuntime.jsx)(_react.Box, {
      pb: 8,
      "data-testid": "".concat(baseTestId, "_companyReference_info"),
      children: (0, _jsxRuntime.jsx)(_react.Heading, _objectSpread(_objectSpread({
        as: "h6"
      }, _styles.descriptionStyles), {}, {
        "data-testid": "".concat(baseTestId, "_companyReference_info-heading"),
        children: t('ccui.companyReference.description')
      }))
    }), (0, _jsxRuntime.jsx)(_react.Box, _objectSpread(_objectSpread({
      "data-testid": "".concat(baseTestId, "_companyReference_input"),
      pt: 6
    }, boxStyles), {}, {
      children: (0, _jsxRuntime.jsx)(_atoms.Input, {
        name: "companyReference",
        value: bookerReferencesDetails.companyReference,
        onChange: onCompanyReferenceChange,
        placeholderText: t('ccui.companyReference.placeholder'),
        error: errorCompanyReference
      })
    }))]
  });
  function onPurchaseOrderNumberChange(purchaseOrderNumber) {
    validateBookersReferenceDetails(purchaseOrderNumber, _api.BOOKERS_REFERENCE_MAX_LENGTH, t('ccui.purchaseOrderNumber.maxLengthMessage'), setErrorPurchaseOrderNumber);
    setBookerReferencesDetails(prevState => _objectSpread(_objectSpread({}, prevState), {}, {
      purchaseOrderNumber
    }));
  }
  function onCompanyReferenceChange(companyReference) {
    validateBookersReferenceDetails(companyReference, _api.BOOKERS_REFERENCE_MAX_LENGTH, t('ccui.companyReference.maxLengthMessage'), setErrorCompanyReference);
    setBookerReferencesDetails(prevState => _objectSpread(_objectSpread({}, prevState), {}, {
      companyReference
    }));
  }
  function validateBookersReferenceDetails(name, lengthLimit, limitMessage, handleError) {
    if (name.length > lengthLimit) {
      handleError(limitMessage);
      setHasError(true);
    } else {
      handleError('');
      setHasError(false);
    }
  }
}
var boxStyles = {
  w: {
    mobile: 'full',
    xs: 'full',
    sm: '25.063rem',
    md: '27.563rem',
    lg: '24.5rem',
    xl: '26.25rem'
  }
};