"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = TypeOfCaller;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _react = require("@chakra-ui/react");
var _api = require("@whitbread-eos/api");
var _atoms = require("@whitbread-eos/atoms");
var _nextI18next = require("next-i18next");
var _react2 = _interopRequireDefault(require("react"));
var _jsxRuntime = require("react/jsx-runtime");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
var baseTestId = 'typeOfCaller';
function TypeOfCaller(_ref) {
  var {
    value,
    setValue
  } = _ref;
  var {
    t
  } = (0, _nextI18next.useTranslation)(['common']);
  var typeOfCallerOptions = [{
    name: t("ccui.".concat(baseTestId, ".anyCustomer")),
    value: _api.TypeOfCaller.ANY_CUSTOMER,
    dataTestID: "".concat(baseTestId, "_anyCustomer"),
    type: "".concat(baseTestId, "_").concat(_api.TypeOfCaller.ANY_CUSTOMER)
  }, {
    name: t("ccui.".concat(baseTestId, ".accesibleCustomer")),
    value: _api.TypeOfCaller.ACCESIBLE_CUSTOMER,
    dataTestID: "".concat(baseTestId, "_accesibleCustomer"),
    type: "".concat(baseTestId, "_").concat(_api.TypeOfCaller.ACCESIBLE_CUSTOMER)
  }];
  return (0, _jsxRuntime.jsx)(_react.Flex, {
    flexDir: "column",
    "data-testid": "".concat(baseTestId),
    children: (0, _jsxRuntime.jsxs)(_react.Box, {
      "data-testid": "".concat(baseTestId, "_options"),
      children: [(0, _jsxRuntime.jsx)(_react.Box, {
        mb: 4,
        children: (0, _jsxRuntime.jsx)(_react.Heading, _objectSpread(_objectSpread({
          as: "h3"
        }, headerStyles), {}, {
          "data-testid": "".concat(baseTestId, "_titleHeader"),
          children: t("ccui.".concat(baseTestId, ".title"))
        }))
      }), (0, _jsxRuntime.jsx)(_react.Box, _objectSpread(_objectSpread({}, radioWrapperStyle), {}, {
        "data-testid": "".concat(baseTestId, "_options"),
        children: (0, _jsxRuntime.jsx)(_react.RadioGroup, {
          value: value,
          onChange: setValue,
          "data-testid": "".concat(baseTestId, "_radio-group"),
          children: typeOfCallerOptions.map(option => (0, _jsxRuntime.jsx)(_atoms.RadioButton, {
            value: option.value,
            "data-testid": option.dataTestID,
            type: option.type,
            children: (0, _jsxRuntime.jsx)(_react.Text, _objectSpread(_objectSpread({
              "data-testid": "".concat(option.dataTestID, "_text")
            }, textStyles), {}, {
              children: option.name
            }))
          }, option.type))
        })
      }))]
    })
  });
}
var radioWrapperStyle = {
  w: {
    mobile: 'full',
    sm: '25.063rem',
    md: '24.5rem',
    xl: '26.25rem'
  }
};
var headerStyles = {
  fontSize: 20,
  lineHeight: 3,
  fontStyle: 'normal',
  fontWeight: 'semibold',
  color: 'darkGrey1'
};
var textStyles = {
  color: 'darkGrey1'
};