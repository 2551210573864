"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.RATE_INFORMATION_CCUI_QUERY = exports.HOTEL_AVAILABILITY_CCUI_QUERY = void 0;
var _taggedTemplateLiteral2 = _interopRequireDefault(require("@babel/runtime/helpers/taggedTemplateLiteral"));
var _graphqlRequest = require("graphql-request");
var _templateObject, _templateObject2;
var HOTEL_AVAILABILITY_CCUI_QUERY = exports.HOTEL_AVAILABILITY_CCUI_QUERY = (0, _graphqlRequest.gql)(_templateObject || (_templateObject = (0, _taggedTemplateLiteral2.default)(["\n  query hotelAvailabilityCcui(\n    $hotelId: String!\n    $arrival: String!\n    $departure: String!\n    $rooms: [RoomSearch!]!\n    $companyId: String\n    $bookingChannel: BookingChannelCriteria!\n  ) {\n    hotelAvailability(\n      availabilitySearchCriteria: {\n        arrival: $arrival\n        departure: $departure\n        rooms: $rooms\n        hotel: { identifier: $hotelId }\n        bookingChannel: $bookingChannel\n        companyId: $companyId\n      }\n    ) {\n      hotelId\n      startDate\n      endDate\n      available\n      limitedAvailability\n      roomRates {\n        ratePlanCode\n        roomTypes {\n          roomType\n          adults\n          children\n          cotRequested\n          rooms {\n            pmsRoomType\n            silentSubstitution\n            cotAvailable\n            roomClass\n            specialRequests\n            roomPriceBreakdown {\n              totalNetAmount\n              currencyCode\n              packageCode\n              packageAmount\n              dailyPrices {\n                date\n                netPrice\n              }\n            }\n          }\n        }\n      }\n    }\n    hotelInventory(hotelId: $hotelId, dateRangeEnd: $departure, dateRangeStart: $arrival) {\n      roomTypeInventories {\n        availableCount\n        code\n      }\n    }\n  }\n"])));
var RATE_INFORMATION_CCUI_QUERY = exports.RATE_INFORMATION_CCUI_QUERY = (0, _graphqlRequest.gql)(_templateObject2 || (_templateObject2 = (0, _taggedTemplateLiteral2.default)(["\n  query ratesInformationCcui(\n    $brand: String!\n    $country: String!\n    $language: String!\n    $hotelId: String!\n    $channel: String!\n    $ratePlans: [String]\n  ) {\n    ratesInformation(\n      brand: $brand\n      country: $country\n      language: $language\n      hotelId: $hotelId\n      channel: $channel\n      ratePlans: $ratePlans\n    ) {\n      rateClassifications {\n        additionalDescription\n        ratePlanCode\n        rateOrder\n        rateNotes\n        rateName\n        rateLongDescription\n        rateDescription\n        rateClassification\n        rateCategory\n      }\n    }\n  }\n"])));