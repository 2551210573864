"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.INITIATE_ECKOH_IFRAME = void 0;
var _taggedTemplateLiteral2 = _interopRequireDefault(require("@babel/runtime/helpers/taggedTemplateLiteral"));
var _graphqlRequest = require("graphql-request");
var _templateObject;
var INITIATE_ECKOH_IFRAME = exports.INITIATE_ECKOH_IFRAME = (0, _graphqlRequest.gql)(_templateObject || (_templateObject = (0, _taggedTemplateLiteral2.default)(["\n  mutation eckohIframe(\n    $basketReference: String!\n    $agentEmail: String!\n    $agentName: String!\n    $country: String!\n    $identifier: String!\n    $location: String!\n    $hotelName: String!\n    $reservationType: String!\n    $channel: String!\n    $journey: String!\n    $language: String!\n    $paymentType: String!\n    $requestId: String!\n    $paymentMethod: String!\n  ) {\n    initiateEckohPayment(\n      basketReference: $basketReference\n      eckohPaymentRequest: {\n        booking: {\n          agent: { email: $agentEmail, name: $agentName }\n          businessSite: {\n            country: $country\n            identifier: $identifier\n            location: $location\n            name: $hotelName\n            type: $reservationType\n          }\n          channel: $channel\n          journey: $journey\n          language: $language\n          type: $paymentType\n        }\n        requestId: $requestId\n        payment: { type: $paymentMethod }\n      }\n    ) {\n      paymentId\n    }\n  }\n"])));