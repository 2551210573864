"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.hashString = hashString;
var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));
var _api = require("@whitbread-eos/api");
var crypto = _interopRequireWildcard(require("crypto"));
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && {}.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
function hashString(_x, _x2) {
  return _hashString.apply(this, arguments);
}
function _hashString() {
  _hashString = (0, _asyncToGenerator2.default)(function* (input, hashType) {
    try {
      var _window$crypto;
      if (typeof window !== 'undefined' && (_window$crypto = window.crypto) !== null && _window$crypto !== void 0 && _window$crypto.subtle) {
        var encoder = new TextEncoder();
        var data = encoder.encode(input);
        var hashBuffer = yield window.crypto.subtle.digest(hashType, data);
        var hashArray = Array.from(new Uint8Array(hashBuffer));
        return hashArray.map(byte => byte.toString(16).padStart(2, '0')).join('');
      } else {
        var nodeHash = crypto.createHash(convertHashTypeToNodeAlgorithm(hashType));
        nodeHash.update(input);
        return nodeHash.digest('hex');
      }
    } catch (error) {
      if (error instanceof Error) {
        console.error("Failed to hash the provided string: ".concat(error.message));
      } else {
        console.error("Failed to hash the provided string due to an 'Unknown Error'");
      }
    }
    return undefined;
  });
  return _hashString.apply(this, arguments);
}
function convertHashTypeToNodeAlgorithm(hashType) {
  switch (hashType) {
    case _api.HashType.SHA256:
      return 'sha256';
    case _api.HashType.SHA384:
      return 'sha384';
    case _api.HashType.SHA512:
      return 'sha512';
    default:
      throw new Error("Unsupported hash type: ".concat(hashType));
  }
}