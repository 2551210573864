"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _react = require("@chakra-ui/react");
var _atoms = require("@whitbread-eos/atoms");
var _nextI18next = require("next-i18next");
var _react2 = _interopRequireDefault(require("react"));
var _modalType = require("./modalType.enum");
var _tableConfig = require("./tableConfig");
var _jsxRuntime = require("react/jsx-runtime");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function CompanyProfile(_ref) {
  var _selectedCompany$corp, _selectedCompany$comp, _selectedCompany$arNu, _selectedCompany$rest;
  var {
    companyModalType,
    selectedCompany
  } = _ref;
  var {
    t
  } = (0, _nextI18next.useTranslation)();
  if (!selectedCompany) {
    return null;
  }
  return (0, _jsxRuntime.jsxs)(_react.Box, _objectSpread(_objectSpread({}, boxStyles), {}, {
    children: [(0, _jsxRuntime.jsxs)(_react.VStack, _objectSpread(_objectSpread({
      spacing: 4
    }, topSectionStyles), {}, {
      children: [(0, _jsxRuntime.jsx)(_react.Text, _objectSpread(_objectSpread({}, companyNameStyles), {}, {
        children: selectedCompany.name
      })), (0, _jsxRuntime.jsxs)(_react.Flex, {
        gap: 4,
        children: [(0, _jsxRuntime.jsx)(_react.Text, _objectSpread(_objectSpread({}, fieldValueStyles), {}, {
          children: (0, _tableConfig.getFormattedAddress)(selectedCompany.address)
        })), (0, _jsxRuntime.jsx)(_react.Text, _objectSpread(_objectSpread({}, fieldValueStyles), {}, {
          children: "".concat(t('ccui.companyModals.tel'), ": ").concat(selectedCompany.telephoneNumber)
        }))]
      })]
    })), (0, _jsxRuntime.jsxs)(_react.Grid, _objectSpread(_objectSpread({}, mainSectionStyles), {}, {
      children: [(0, _jsxRuntime.jsxs)(_react.VStack, _objectSpread(_objectSpread({
        spacing: 6
      }, columnStyles(companyModalType)), {}, {
        children: [createLabelValuePair(t('ccui.companyModals.profileType'), selectedCompany.profileType), createLabelValuePair(t('ccui.companyModals.corpID'), (_selectedCompany$corp = selectedCompany.corpId) !== null && _selectedCompany$corp !== void 0 ? _selectedCompany$corp : 'NA'), createLabelValuePair(t('ccui.companyModals.companyID'), (_selectedCompany$comp = selectedCompany.companyId) !== null && _selectedCompany$comp !== void 0 ? _selectedCompany$comp : '')]
      })), (0, _jsxRuntime.jsxs)(_react.VStack, _objectSpread(_objectSpread({
        spacing: 6
      }, columnStyles(companyModalType)), {}, {
        children: [createLabelValuePair(t('ccui.companyModals.language'), selectedCompany.language.toUpperCase()), createReadonlyCheckBox(t('ccui.companyModals.active'), selectedCompany.active), companyModalType === _modalType.COMPANY_MODAL_TYPE.ACCOUNT_TO_COMPANY && createLabelValuePair(t('ccui.companyModals.arNumber'), (_selectedCompany$arNu = selectedCompany.arNumber) !== null && _selectedCompany$arNu !== void 0 ? _selectedCompany$arNu : 'NA')]
      })), (0, _jsxRuntime.jsxs)(_react.VStack, _objectSpread(_objectSpread({}, columnStyles(companyModalType)), {}, {
        children: [companyModalType === _modalType.COMPANY_MODAL_TYPE.CONTRACT_RATE_CODE && createReadonlyCheckBox(t('ccui.companyModals.negotiatedRates'), selectedCompany.negotiatedRateEnabled), companyModalType === _modalType.COMPANY_MODAL_TYPE.ACCOUNT_TO_COMPANY && createReadonlyCheckBox(t('ccui.companyModals.restricted'), selectedCompany.restricted), companyModalType === _modalType.COMPANY_MODAL_TYPE.ACCOUNT_TO_COMPANY && selectedCompany.restricted && (0, _jsxRuntime.jsx)(_atoms.Notification, {
          maxWidth: "full",
          variant: "alert",
          status: "error",
          title: t('ccui.companyModals.restrictedReasonTitle'),
          description: (_selectedCompany$rest = selectedCompany.restrictedReason) !== null && _selectedCompany$rest !== void 0 ? _selectedCompany$rest : '',
          svg: (0, _jsxRuntime.jsx)(_atoms.Alert, {}),
          overflow: 'visible'
        })]
      }))]
    }))]
  }));
}
var createLabelValuePair = (label, value) => {
  return (0, _jsxRuntime.jsxs)(_react.VStack, {
    spacing: 2.5,
    align: "stretch",
    children: [(0, _jsxRuntime.jsxs)(_react.Text, _objectSpread(_objectSpread({}, fieldLabelStyles), {}, {
      children: [label, " "]
    })), (0, _jsxRuntime.jsx)(_react.Text, _objectSpread(_objectSpread({}, fieldValueStyles), {}, {
      children: value
    }))]
  });
};
var createReadonlyCheckBox = (label, isChecked) => {
  return (0, _jsxRuntime.jsx)(_atoms.Checkbox, {
    isChecked: isChecked,
    isReadOnly: true,
    checkboxWrapperStyles: {
      my: '0'
    },
    children: (0, _jsxRuntime.jsxs)(_react.Text, _objectSpread(_objectSpread({}, checkBoxLabelStyles), {}, {
      children: [label, " "]
    }))
  });
};
var boxStyles = {
  h: 'full',
  alignItems: 'stretch',
  marginInlineStart: 'var(--chakra-space-8)',
  marginInlineEnd: 'var(--chakra-space-8)',
  marginTop: 'var(--chakra-space-6)'
};
var topSectionStyles = {
  borderBottom: 'var(--chakra-borders-1px)',
  borderColor: 'var(--chakra-colors-lightGrey2)',
  paddingBottom: 'var(--chakra-space-4)',
  alignItems: 'stretch'
};
var mainSectionStyles = {
  paddingTop: 'var(--chakra-space-10)',
  flex: '1 0',
  alignItems: 'stretch',
  gridTemplateColumns: 'repeat(3, minmax(0, 1fr))'
};
var columnStyles = companyModalType => {
  return {
    paddingInlineStart: 'var(--chakra-space-8)',
    paddingInlineEnd: 'var(--chakra-space-4)',
    alignItems: 'stretch',
    _notLast: {
      borderRight: 'var(--chakra-borders-1px)',
      borderColor: 'var(--chakra-colors-lightGrey2)'
    },
    _last: companyModalType === _modalType.COMPANY_MODAL_TYPE.ACCOUNT_TO_COMPANY ? {
      borderRight: 'var(--chakra-borders-1px)',
      borderColor: 'var(--chakra-colors-lightGrey2)'
    } : {}
  };
};
var companyNameStyles = {
  color: 'darkGrey1',
  fontSize: 'xl',
  fontWeight: '700',
  lineHeight: '3',
  fontFamily: 'header'
};
var fieldLabelStyles = {
  color: 'darkGrey1',
  fontSize: 'md',
  fontWeight: '700',
  lineHeight: '3',
  fontFamily: 'header'
};
var fieldValueStyles = {
  color: 'darkGrey2',
  fontSize: 'lg',
  fontWeight: '400',
  lineHeight: '3',
  fontFamily: 'header'
};
var checkBoxLabelStyles = {
  color: 'darkGrey1',
  fontSize: 'md',
  fontWeight: '400',
  lineHeight: '3',
  fontFamily: 'header'
};
var _default = exports.default = CompanyProfile;