"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = TwinroomOptionsComponent;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _react = require("@chakra-ui/react");
var _api = require("@whitbread-eos/api");
var _atoms = require("@whitbread-eos/atoms");
var _utils = require("@whitbread-eos/utils");
var _nextI18next = require("next-i18next");
var _react2 = require("react");
var _jsxRuntime = require("react/jsx-runtime");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function TwinroomOptionsComponent(_ref) {
  var _twinRoomPrices$;
  var {
    rooms,
    onTwinroomSelection,
    twinroomSelections,
    twinRoomPrices
  } = _ref;
  var {
    t
  } = (0, _nextI18next.useTranslation)(['common']);
  var {
    language
  } = (0, _utils.useCustomLocale)();
  var currencyCode = twinRoomPrices === null || twinRoomPrices === void 0 || (_twinRoomPrices$ = twinRoomPrices[0]) === null || _twinRoomPrices$ === void 0 ? void 0 : _twinRoomPrices$.currencyCode;
  var [showImprovedTwinDescription, setShowImprovedTwinDescription] = (0, _react2.useState)({});
  var [showStandardTwinDescription, setShowStandardTwinDescription] = (0, _react2.useState)({});
  var improvedTwinToggle = (key, value) => {
    setShowImprovedTwinDescription(_objectSpread(_objectSpread({}, showImprovedTwinDescription), {}, {
      [key]: value
    }));
  };
  var standardTwinToggle = (key, value) => {
    setShowStandardTwinDescription(_objectSpread(_objectSpread({}, showStandardTwinDescription), {}, {
      [key]: value
    }));
  };
  function formatRatePrice(price) {
    return price - Math.floor(price) !== 0 ? price.toFixed(2) : price;
  }
  (0, _react2.useEffect)(() => {
    rooms.forEach((room, roomIndex) => {
      var isSelectionRequired = selectionRequired(room);
      var twinroomSelected = (twinroomSelections === null || twinroomSelections === void 0 ? void 0 : twinroomSelections[roomIndex]) === _api.ROOM_TYPE.TWIN_TWO_BEDS || (twinroomSelections === null || twinroomSelections === void 0 ? void 0 : twinroomSelections[roomIndex]) === _api.ROOM_TYPE.TWIN_DOUBLE_SOFA;
      if (isSelectionRequired) {
        var _room$twinroomTypes, _room$twinroomTypes2;
        var hasTwinTwoBeds = (_room$twinroomTypes = room.twinroomTypes) === null || _room$twinroomTypes === void 0 ? void 0 : _room$twinroomTypes.includes(_api.ROOM_TYPE.TWIN_TWO_BEDS);
        var hasTwinDoublePlusSofa = (_room$twinroomTypes2 = room.twinroomTypes) === null || _room$twinroomTypes2 === void 0 ? void 0 : _room$twinroomTypes2.includes(_api.ROOM_TYPE.TWIN_DOUBLE_SOFA);
        if (hasTwinTwoBeds && !twinroomSelected) {
          onTwinroomSelection(roomIndex, _api.ROOM_TYPE.TWIN_TWO_BEDS);
        } else if (hasTwinDoublePlusSofa && !twinroomSelected) {
          onTwinroomSelection(roomIndex, _api.ROOM_TYPE.TWIN_DOUBLE_SOFA);
        }
      }
    });
  }, [rooms, onTwinroomSelection, twinroomSelections]);
  var {
    isLessThanXs
  } = (0, _utils.useScreenSize)();
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_react.Heading, _objectSpread(_objectSpread({
      as: "h1",
      "data-testid": "twin-title"
    }, headingStyles), {}, {
      children: t('seo.chooseTwinRoom.title')
    })), rooms.map((room, roomIndex) => {
      var isSelectionRequired = selectionRequired(room);
      return (0, _jsxRuntime.jsxs)(_react.Box, {
        mt: {
          base: 'xl',
          xs: '3xl'
        },
        children: [(0, _jsxRuntime.jsx)(_react.Flex, _objectSpread(_objectSpread({}, roomAndGuestsFlexStyles), {}, {
          children: (0, _jsxRuntime.jsxs)(_react.Flex, {
            direction: "column",
            mr: "md",
            children: [(0, _jsxRuntime.jsxs)(_react.Heading, _objectSpread(_objectSpread({
              as: "h3",
              "data-testid": "twin-room-number"
            }, roomHeadingStyles), {}, {
              children: [t('booking.hotel.summary.room').replace('[roomNumber]', "".concat(roomIndex + 1, " ")), room.roomType]
            })), renderGuestNumbers(room)]
          })
        })), isSelectionRequired ? renderSelectionOptions(roomIndex) : (0, _jsxRuntime.jsx)(_react.Text, {
          mt: {
            base: '1',
            xs: '0'
          },
          "data-testid": "twin-no-selection-required",
          children: t('chooseyourtwin.no.selection.required')
        })]
      }, roomIndex);
    })]
  });
  function selectionRequired(room) {
    return room.roomType.startsWith('Twin') || room.roomType.startsWith(t('pihotelinfo.chooseTwinRoom.title'));
  }
  function renderGuestNumbers(room) {
    var adultsLabel = "".concat(room.adults, " ").concat(room.adults > 1 ? t('dashboard.bookings.adults') : t('dashboard.bookings.adult'));
    var childrenLabel = ", ".concat(room.children, " ").concat(room.children > 1 ? t('dashboard.bookings.children') : t('dashboard.bookings.child'));
    return (0, _jsxRuntime.jsxs)(_react.Text, {
      mt: "1",
      lineHeight: "3",
      "data-testid": "twin-room-guest-numbers",
      children: [adultsLabel, room.children ? childrenLabel : '']
    });
  }
  function renderSelectionOptions(roomIndex) {
    var _twinRoomPrices$2, _twinRoomPrices$3, _twinRoomPrices$4, _twinRoomPrices$5;
    return (0, _jsxRuntime.jsx)(_react.Box, {
      maxW: "3xl",
      mt: "lg",
      "data-testid": "twinroom-options",
      children: (0, _jsxRuntime.jsxs)(_react.VStack, {
        alignItems: "stretch",
        w: "full",
        children: [(0, _jsxRuntime.jsxs)(_react.Flex, _objectSpread(_objectSpread({
          flexDirection: "row"
        }, rateItemCardStyle), {}, {
          children: [(0, _jsxRuntime.jsx)(_atoms.RadioButton, {
            type: "improved-twin",
            value: "improved-twin",
            onChange: () => onTwinroomSelection(roomIndex, _api.ROOM_TYPE.TWIN_TWO_BEDS),
            isChecked: (twinroomSelections === null || twinroomSelections === void 0 ? void 0 : twinroomSelections[roomIndex]) === _api.ROOM_TYPE.TWIN_TWO_BEDS,
            width: "full",
            listIndex: "left",
            children: (0, _jsxRuntime.jsxs)(_react.Box, {
              children: [(0, _jsxRuntime.jsx)(_react.Text, {
                fontWeight: "bold",
                "data-testid": "improved-twin",
                children: t('twinroom.improvedTwin.title')
              }), (0, _jsxRuntime.jsx)(_react.Collapse, {
                in: showImprovedTwinDescription[roomIndex] || !isLessThanXs,
                "data-testid": "improvedTwin-description-collapse",
                children: (0, _jsxRuntime.jsx)(_react.Box, {
                  className: "formatLinks",
                  children: (0, _utils.renderSanitizedHtml)(t('twinroom.improvedTwin.description'))
                })
              }), (0, _jsxRuntime.jsx)(_react.Link, _objectSpread(_objectSpread({
                "data-testid": "improvedTwin-description-toggle"
              }, optionsStyles), {}, {
                onClick: () => improvedTwinToggle(roomIndex, !showImprovedTwinDescription[roomIndex]),
                children: showImprovedTwinDescription[roomIndex] ? t('hoteldetails.hide') : t('hoteldetails.show')
              }))]
            })
          }), twinRoomPrices && !!((_twinRoomPrices$2 = twinRoomPrices[0]) !== null && _twinRoomPrices$2 !== void 0 && _twinRoomPrices$2.price) && (0, _jsxRuntime.jsxs)(_react.Flex, _objectSpread(_objectSpread({}, _objectSpread(_objectSpread({}, radioWrapperStyles), (twinroomSelections === null || twinroomSelections === void 0 ? void 0 : twinroomSelections[roomIndex]) === _api.ROOM_TYPE.TWIN_TWO_BEDS ? checkedStyles : unCheckedStyles)), {}, {
            children: [(0, _jsxRuntime.jsx)(_react.Text, _objectSpread(_objectSpread({}, roomTotalPriceStyles), {}, {
              "data-testid": "improvedTwin-price",
              children: (0, _utils.formatPrice)(currencyCode && (0, _utils.formatCurrency)(currencyCode), formatRatePrice((_twinRoomPrices$3 = twinRoomPrices[0]) === null || _twinRoomPrices$3 === void 0 ? void 0 : _twinRoomPrices$3.price), language)
            })), (0, _jsxRuntime.jsx)(_react.Text, _objectSpread(_objectSpread({}, roomTotalPriceTitleStyles), {}, {
              children: t('hoteldetails.rates.total.price')
            }))]
          }))]
        })), (0, _jsxRuntime.jsxs)(_react.Flex, _objectSpread(_objectSpread({
          flexDirection: "row"
        }, rateItemCardStyle), {}, {
          children: [(0, _jsxRuntime.jsx)(_atoms.RadioButton, {
            type: "sofa-double-twin",
            value: "sofa-double-twin",
            onChange: () => onTwinroomSelection(roomIndex, _api.ROOM_TYPE.TWIN_DOUBLE_SOFA),
            isChecked: (twinroomSelections === null || twinroomSelections === void 0 ? void 0 : twinroomSelections[roomIndex]) === _api.ROOM_TYPE.TWIN_DOUBLE_SOFA,
            width: "full",
            listIndex: "left",
            children: (0, _jsxRuntime.jsxs)(_react.Box, {
              children: [(0, _jsxRuntime.jsx)(_react.Text, {
                fontWeight: "bold",
                "data-testid": "double-sofa-twin",
                children: t('twinroom.standardTwin.title')
              }), (0, _jsxRuntime.jsx)(_react.Collapse, {
                in: showStandardTwinDescription[roomIndex] || !isLessThanXs,
                "data-testid": "standardTwin-description-collapse",
                children: (0, _jsxRuntime.jsx)(_react.Box, {
                  className: "formatLinks",
                  children: (0, _utils.renderSanitizedHtml)(t('twinroom.standardTwin.description'))
                })
              }), (0, _jsxRuntime.jsx)(_react.Link, _objectSpread(_objectSpread({}, optionsStyles), {}, {
                "data-testid": "standardTwin-description-toggle",
                onClick: () => standardTwinToggle(roomIndex, !showStandardTwinDescription[roomIndex]),
                children: showStandardTwinDescription[roomIndex] ? t('hoteldetails.hide') : t('hoteldetails.show')
              }))]
            })
          }), twinRoomPrices && !!((_twinRoomPrices$4 = twinRoomPrices[1]) !== null && _twinRoomPrices$4 !== void 0 && _twinRoomPrices$4.price) && (0, _jsxRuntime.jsxs)(_react.Flex, _objectSpread(_objectSpread({}, _objectSpread(_objectSpread({}, radioWrapperStyles), (twinroomSelections === null || twinroomSelections === void 0 ? void 0 : twinroomSelections[roomIndex]) === _api.ROOM_TYPE.TWIN_DOUBLE_SOFA ? checkedStyles : unCheckedStyles)), {}, {
            children: [(0, _jsxRuntime.jsx)(_react.Text, _objectSpread(_objectSpread({}, roomTotalPriceStyles), {}, {
              "data-testid": "double-sofa-twin-price",
              children: (0, _utils.formatPrice)(currencyCode && (0, _utils.formatCurrency)(currencyCode), formatRatePrice((_twinRoomPrices$5 = twinRoomPrices[1]) === null || _twinRoomPrices$5 === void 0 ? void 0 : _twinRoomPrices$5.price), language)
            })), (0, _jsxRuntime.jsx)(_react.Text, _objectSpread(_objectSpread({}, roomTotalPriceTitleStyles), {}, {
              children: t('hoteldetails.rates.total.price')
            }))]
          }))]
        }))]
      })
    });
  }
}
var rateItemCardStyle = {
  maxWidth: '40.12rem'
};
var radioWrapperStyles = {
  maxWidth: '8rem',
  width: 'full',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center'
};
var checkedStyles = {
  border: 'solid',
  borderWidth: '2px 2px 2px 0',
  borderColor: 'primary',
  backgroundColor: 'infoTint',
  padding: '10px'
};
var unCheckedStyles = {
  borderLeft: 'solid',
  borderWidth: '1px 1px 1px 0',
  borderColor: 'lightGrey1',
  padding: '10px'
};
var roomTotalPriceStyles = {
  fontSize: {
    base: 'md',
    sm: 'lg'
  },
  lineHeight: '3',
  fontWeight: 'semibold'
};
var roomTotalPriceTitleStyles = {
  fontSize: 'xs',
  lineHeight: '2'
};
var headingStyles = {
  fontWeight: 'semibold',
  fontSize: {
    base: '3xl',
    sm: '3xxl'
  },
  lineHeight: {
    base: '4',
    sm: '5'
  }
};
var roomAndGuestsFlexStyles = {
  mb: 'sm',
  flexDirection: {
    base: 'column',
    xs: 'row'
  },
  w: {
    base: '100%',
    sm: '64%',
    md: '50%'
  }
};
var roomHeadingStyles = {
  fontWeight: 'semibold',
  fontSize: '2xl',
  lineHeight: '4'
};
var optionsStyles = {
  display: 'none',
  color: 'btnSecondaryEnabled',
  sx: {
    ':hover': {
      textDecoration: 'none'
    },
    '@media screen and (max-width: 374px)': {
      display: 'block'
    }
  }
};