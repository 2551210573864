"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.extrasPackagesAnalyticsMap = exports.default = void 0;
var _api = require("@whitbread-eos/api");
var _differenceInDays = _interopRequireDefault(require("date-fns/differenceInDays"));
var _format = _interopRequireDefault(require("date-fns/format"));
var _getters = require("../../getters");
var _selectors = require("../../selectors");
var _analytics = _interopRequireWildcard(require("./analytics"));
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && {}.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
var extrasPackagesAnalyticsMap = (roomIndex, noNights, packages) => {
  var _packages$extrasItems;
  return packages === null || packages === void 0 || (_packages$extrasItems = packages.extrasItems) === null || _packages$extrasItems === void 0 ? void 0 : _packages$extrasItems.map(extrasItem => {
    var _extrasItem$id, _extrasItem$name, _packages$roomSelecti, _packages$roomSelecti2, _extrasItem$currency;
    return {
      code: (_extrasItem$id = extrasItem.id) !== null && _extrasItem$id !== void 0 ? _extrasItem$id : '',
      legend: (_extrasItem$name = extrasItem.name) !== null && _extrasItem$name !== void 0 ? _extrasItem$name : '',
      quantity: (_packages$roomSelecti = packages === null || packages === void 0 || (_packages$roomSelecti2 = packages.roomSelection) === null || _packages$roomSelecti2 === void 0 || (_packages$roomSelecti2 = _packages$roomSelecti2[roomIndex]) === null || _packages$roomSelecti2 === void 0 || (_packages$roomSelecti2 = _packages$roomSelecti2.packagesSelection) === null || _packages$roomSelecti2 === void 0 || (_packages$roomSelecti2 = _packages$roomSelecti2.find(p => p.id === extrasItem.id)) === null || _packages$roomSelecti2 === void 0 ? void 0 : _packages$roomSelecti2.noOfSelections) !== null && _packages$roomSelecti !== void 0 ? _packages$roomSelecti : 0,
      price: extrasItem.price ? (extrasItem.price / noNights).toFixed(2) : '',
      currency: (_extrasItem$currency = extrasItem === null || extrasItem === void 0 ? void 0 : extrasItem.currency) !== null && _extrasItem$currency !== void 0 ? _extrasItem$currency : ''
    };
  });
};
exports.extrasPackagesAnalyticsMap = extrasPackagesAnalyticsMap;
var updateAncillariesAnalytics = (bookingInformation, packages, variant) => {
  var _bookingInformation$r, _firstRoom$arrivalDat, _firstRoom$departureD, _packages$roomSelecti3, _bookingInformation$r2, _bookingInformation$c, _firstRoom$ratePlanCo, _firstRoom$rateExtraI, _bookingInformation$r3, _bookingInformation$r4, _bookingInformation$t, _bookingInformation$h, _bookingInformation$r5, _bookingInformation$r6;
  var firstRoom = (bookingInformation === null || bookingInformation === void 0 || (_bookingInformation$r = bookingInformation.reservationByIdList) === null || _bookingInformation$r === void 0 || (_bookingInformation$r = _bookingInformation$r[0]) === null || _bookingInformation$r === void 0 ? void 0 : _bookingInformation$r.roomStay) || {};
  var noNights = (0, _getters.getNightsNumber)((_firstRoom$arrivalDat = firstRoom === null || firstRoom === void 0 ? void 0 : firstRoom.arrivalDate) !== null && _firstRoom$arrivalDat !== void 0 ? _firstRoom$arrivalDat : '', (_firstRoom$departureD = firstRoom === null || firstRoom === void 0 ? void 0 : firstRoom.departureDate) !== null && _firstRoom$departureD !== void 0 ? _firstRoom$departureD : '');
  var roomSelection = (_packages$roomSelecti3 = packages.roomSelection) !== null && _packages$roomSelecti3 !== void 0 ? _packages$roomSelecti3 : [];
  var adultsMeals = (0, _selectors.adultsMealsSelector)(packages === null || packages === void 0 ? void 0 : packages.meals, noNights);
  var childrenMeals = (0, _selectors.childrenMealsSelector)(packages === null || packages === void 0 ? void 0 : packages.mealsKids);
  var reasonForStay = bookingInformation === null || bookingInformation === void 0 || (_bookingInformation$r2 = bookingInformation.reservationByIdList) === null || _bookingInformation$r2 === void 0 || (_bookingInformation$r2 = _bookingInformation$r2[0]) === null || _bookingInformation$r2 === void 0 || (_bookingInformation$r2 = _bookingInformation$r2.additionalGuestInfo) === null || _bookingInformation$r2 === void 0 ? void 0 : _bookingInformation$r2.purposeOfStay;
  var totalAdults = 0;
  var totalChildren = 0;
  if (bookingInformation !== null && bookingInformation !== void 0 && bookingInformation.reservationByIdList) {
    bookingInformation.reservationByIdList.forEach(room => {
      if (room !== null && room !== void 0 && room.roomStay) {
        var _room$roomStay$adults, _room$roomStay$childr;
        totalAdults += (_room$roomStay$adults = room.roomStay.adultsNumber) !== null && _room$roomStay$adults !== void 0 ? _room$roomStay$adults : 0;
        totalChildren += (_room$roomStay$childr = room.roomStay.childrenNumber) !== null && _room$roomStay$childr !== void 0 ? _room$roomStay$childr : 0;
      }
    });
  }
  var arrivalDay = firstRoom !== null && firstRoom !== void 0 && firstRoom.arrivalDate ? (0, _format.default)(new Date(firstRoom === null || firstRoom === void 0 ? void 0 : firstRoom.arrivalDate), 'EEEE') : '';
  var departureDay = firstRoom !== null && firstRoom !== void 0 && firstRoom.departureDate ? (0, _format.default)(new Date(firstRoom === null || firstRoom === void 0 ? void 0 : firstRoom.departureDate), 'EEEE') : '';
  var ancPackagesSelection = [];
  if (bookingInformation !== null && bookingInformation !== void 0 && bookingInformation.reservationByIdList) {
    bookingInformation === null || bookingInformation === void 0 || bookingInformation.reservationByIdList.forEach((reservation, roomIndex) => {
      if (reservation) {
        var _extrasPackagesAnalyt;
        var packagesPerRoom = [...adultsMeals.map(meal => {
          var _meal$id, _meal$name, _meal$id2, _meal$price$toFixed, _meal$price;
          return {
            code: (_meal$id = meal === null || meal === void 0 ? void 0 : meal.id) !== null && _meal$id !== void 0 ? _meal$id : '',
            legend: (_meal$name = meal === null || meal === void 0 ? void 0 : meal.name) !== null && _meal$name !== void 0 ? _meal$name : '',
            quantity: (0, _getters.getMealQuantity)(packages, (_meal$id2 = meal === null || meal === void 0 ? void 0 : meal.id) !== null && _meal$id2 !== void 0 ? _meal$id2 : '', roomIndex),
            price: (_meal$price$toFixed = meal === null || meal === void 0 || (_meal$price = meal.price) === null || _meal$price === void 0 ? void 0 : _meal$price.toFixed(2)) !== null && _meal$price$toFixed !== void 0 ? _meal$price$toFixed : '',
            currency: meal === null || meal === void 0 ? void 0 : meal.currency,
            freeBreakfastCode: meal === null || meal === void 0 ? void 0 : meal.freeBreakfastCode,
            freeBreakfastOption: meal === null || meal === void 0 ? void 0 : meal.freeBreakfastOption
          };
        }), ...childrenMeals.map(meal => {
          var _meal$id3, _meal$name2, _meal$id4;
          return {
            code: (_meal$id3 = meal === null || meal === void 0 ? void 0 : meal.id) !== null && _meal$id3 !== void 0 ? _meal$id3 : '',
            legend: (_meal$name2 = meal === null || meal === void 0 ? void 0 : meal.name) !== null && _meal$name2 !== void 0 ? _meal$name2 : '',
            quantity: (0, _getters.getMealQuantity)(packages, (_meal$id4 = meal === null || meal === void 0 ? void 0 : meal.id) !== null && _meal$id4 !== void 0 ? _meal$id4 : '', roomIndex),
            price: '0.00'
          };
        }), ...((_extrasPackagesAnalyt = extrasPackagesAnalyticsMap(roomIndex, noNights, packages)) !== null && _extrasPackagesAnalyt !== void 0 ? _extrasPackagesAnalyt : [])];
        ancPackagesSelection.push([...packagesPerRoom]);
      }
    });
  }
  var roomTypes, roomNames;
  if (bookingInformation !== null && bookingInformation !== void 0 && bookingInformation.reservationByIdList) {
    roomTypes = (0, _selectors.getUniqueRoomProperties)(bookingInformation === null || bookingInformation === void 0 ? void 0 : bookingInformation.reservationByIdList);
    roomNames = (0, _selectors.getUniqueRoomProperties)(bookingInformation === null || bookingInformation === void 0 ? void 0 : bookingInformation.reservationByIdList, 'roomName');
  }
  _analytics.default.update({
    alcoholAllowed: false,
    dinnerAllowance: false,
    otherChargesAllowed: false,
    validation: '',
    bookingReasonForStay: reasonForStay,
    wifiAccessAllowed: false,
    wifiOption: '',
    currencyCode: (_bookingInformation$c = bookingInformation === null || bookingInformation === void 0 ? void 0 : bookingInformation.currencyCode) !== null && _bookingInformation$c !== void 0 ? _bookingInformation$c : '',
    FromToDate: {
      ArrivalDay: arrivalDay,
      DepartureDay: departureDay,
      FromToDay: "".concat(arrivalDay, "-").concat(departureDay)
    },
    rateCode: (_firstRoom$ratePlanCo = firstRoom === null || firstRoom === void 0 ? void 0 : firstRoom.ratePlanCode) !== null && _firstRoom$ratePlanCo !== void 0 ? _firstRoom$ratePlanCo : '',
    productSelectedRate: firstRoom === null || firstRoom === void 0 || (_firstRoom$rateExtraI = firstRoom.rateExtraInfo) === null || _firstRoom$rateExtraI === void 0 ? void 0 : _firstRoom$rateExtraI.rateName,
    RoomTypes: roomTypes,
    RoomNames: roomNames,
    productDetails: [{
      type: _analytics.analyticsTrackings.HOTEL,
      quantity: (_bookingInformation$r3 = bookingInformation === null || bookingInformation === void 0 || (_bookingInformation$r4 = bookingInformation.reservationByIdList) === null || _bookingInformation$r4 === void 0 ? void 0 : _bookingInformation$r4.length) !== null && _bookingInformation$r3 !== void 0 ? _bookingInformation$r3 : 0,
      price: {
        basePrice: (((_bookingInformation$t = bookingInformation === null || bookingInformation === void 0 ? void 0 : bookingInformation.totalCost) !== null && _bookingInformation$t !== void 0 ? _bookingInformation$t : 0) - (0, _selectors.calculateTotalCostRoomSelection)(adultsMeals, roomSelection, noNights)).toFixed(2)
      },
      productInfo: {
        sku: (_bookingInformation$h = bookingInformation.hotelId) !== null && _bookingInformation$h !== void 0 ? _bookingInformation$h : '',
        totalNumberOfRooms: (_bookingInformation$r5 = bookingInformation === null || bookingInformation === void 0 || (_bookingInformation$r6 = bookingInformation.reservationByIdList) === null || _bookingInformation$r6 === void 0 ? void 0 : _bookingInformation$r6.length.toString()) !== null && _bookingInformation$r5 !== void 0 ? _bookingInformation$r5 : '',
        roomAdults: totalAdults.toString(),
        roomChildren: totalChildren.toString(),
        numberOfGuests: (totalAdults + totalChildren).toString(),
        startDate: firstRoom !== null && firstRoom !== void 0 && firstRoom.arrivalDate ? (0, _format.default)(new Date(firstRoom === null || firstRoom === void 0 ? void 0 : firstRoom.arrivalDate), 'yyyy-MM-dd') : '',
        endDate: firstRoom !== null && firstRoom !== void 0 && firstRoom.departureDate ? (0, _format.default)(new Date(firstRoom === null || firstRoom === void 0 ? void 0 : firstRoom.departureDate), 'yyyy-MM-dd') : '',
        numberOfNights: noNights.toString(),
        daysToCheckIn: firstRoom !== null && firstRoom !== void 0 && firstRoom.arrivalDate ? (0, _differenceInDays.default)(new Date(firstRoom === null || firstRoom === void 0 ? void 0 : firstRoom.arrivalDate), new Date()).toString() : ''
      }
    }],
    upsells: {
      rooms: ancPackagesSelection
    }
  });
  if (variant === _api.Area.BB || variant === _api.Area.CCUI) {
    _analytics.default.update({
      siteType: '6.5',
      bookingPanelComponentsOrder: 'submitdetails,bookingoverview,bookingdonation,bookingdetails, infotext_FlexPID,infotext_FlexPI,infotext_FlexHub,infotext_Advance,infotext_standard,infotext, infotext_semiflex,submitdetails_0',
      contentComponentOrder: 'employees,upsell,contactpreference,wifi,submitdetails,contactdetails',
      loginFormComponentsOrder: 'bookingflowmessages,loginregisterauth0,'
    });
  }
  if (variant === _api.Area.BB) {
    var idTokenCookie = (0, _getters.getAuthCookie)();
    var {
      companyId,
      sessionId,
      accessLevel
    } = (0, _getters.getLoggedInUserInfo)(idTokenCookie);
    _analytics.default.update({
      companyID: companyId,
      sessionId: sessionId,
      userLevel: accessLevel
    });
  }
};
var _default = exports.default = updateAncillariesAnalytics;