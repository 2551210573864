"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.CREATE_RESERVATION_GUEST = void 0;
var _taggedTemplateLiteral2 = _interopRequireDefault(require("@babel/runtime/helpers/taggedTemplateLiteral"));
var _graphqlRequest = require("graphql-request");
var _templateObject;
var CREATE_RESERVATION_GUEST = exports.CREATE_RESERVATION_GUEST = (0, _graphqlRequest.gql)(_templateObject || (_templateObject = (0, _taggedTemplateLiteral2.default)(["\n  mutation createReservationGuest(\n    $hotelId: String!\n    $reasonForStay: String!\n    $companyName: String\n    $addressLine1: String\n    $addressLine2: String\n    $addressLine3: String\n    $addressLine4: String\n    $addressType: String\n    $countryCode: String\n    $postalCode: String\n    $cityName: String\n    $emailAddress: String!\n    $firstName: String!\n    $landline: String\n    $lastName: String!\n    $mobile: String\n    $title: String\n    $basketReference: String!\n    $acceptFutureMailing: Boolean\n    $language: String\n    $stayingGuests: [StayingGuest!]!\n    $sendEmailConfirmation: Boolean\n    $sendEmailInvoice: Boolean\n    $companyId: String\n  ) {\n    createReservationGuest(\n      createReservationGuestCriteria: {\n        basketReference: $basketReference\n        hotelId: $hotelId\n        reasonForStay: $reasonForStay\n        companyId: $companyId\n        booker: {\n          title: $title\n          firstName: $firstName\n          lastName: $lastName\n          emailAddress: $emailAddress\n          mobile: $mobile\n          landline: $landline\n          acceptFutureMailing: $acceptFutureMailing\n          language: $language\n          address: {\n            companyName: $companyName\n            addressLine1: $addressLine1\n            addressLine2: $addressLine2\n            addressLine3: $addressLine3\n            addressLine4: $addressLine4\n            addressType: $addressType\n            countryCode: $countryCode\n            postalCode: $postalCode\n            cityName: $cityName\n          }\n        }\n        stayingGuests: $stayingGuests\n        sendEmailConfirmation: $sendEmailConfirmation\n        sendEmailInvoice: $sendEmailInvoice\n      }\n    ) {\n      basketReference\n    }\n  }\n"])));