"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.GET_BOOKING_CHECK_IN_OUT_TIME = void 0;
var _taggedTemplateLiteral2 = _interopRequireDefault(require("@babel/runtime/helpers/taggedTemplateLiteral"));
var _graphqlRequest = require("graphql-request");
var _templateObject;
var GET_BOOKING_CHECK_IN_OUT_TIME = exports.GET_BOOKING_CHECK_IN_OUT_TIME = (0, _graphqlRequest.gql)(_templateObject || (_templateObject = (0, _taggedTemplateLiteral2.default)(["\n  query GetBookingCheckInOutTime($basketReference: String!, $country: String!, $language: String!) {\n    bookingConfirmation(basketReference: $basketReference, country: $country, language: $language) {\n      hotelId\n      reservationByIdList {\n        roomStay {\n          checkInTime\n          checkOutTime\n          arrivalDate\n          departureDate\n        }\n      }\n    }\n  }\n"])));