"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.FormWithAccordian = void 0;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _react = require("@chakra-ui/react");
var _yup = require("@hookform/resolvers/yup");
var _react2 = require("react");
var _reactHookForm = require("react-hook-form");
var _Accordion = _interopRequireDefault(require("../Accordion"));
var _FormField = _interopRequireDefault(require("./FormField"));
var _formConstants = require("./formConstants");
var _jsxRuntime = require("react/jsx-runtime");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
var FormWithAccordian = _ref => {
  var _customSubmitButtonWi;
  var {
    id,
    accordians,
    onChange = () => {},
    defaultValues,
    accordionIndex,
    handleAccordionToggle,
    validationSchema,
    getFormState,
    testid,
    autoComplete = 'on',
    fieldsetDisabled = false
  } = _ref;
  var {
    control,
    handleSubmit,
    getValues,
    formState: {
      errors
    },
    setError,
    clearErrors,
    setValue,
    watch,
    trigger
  } = (0, _reactHookForm.useForm)({
    mode: 'onBlur',
    defaultValues,
    resolver: validationSchema ? (0, _yup.yupResolver)(validationSchema) : undefined
  });
  var watchValuesForFormWithAccordian = (0, _reactHookForm.useWatch)({
    control
  });
  var errorsReference = (0, _react2.useRef)({});
  var accordianFields = accordians.flatMap(accordian => accordian.elements.fields);
  var customSubmitButtonWithAccordian = accordianFields === null || accordianFields === void 0 ? void 0 : accordianFields.find(field => (field === null || field === void 0 ? void 0 : field.type) === _formConstants.FORM_BUTTON_TYPES.SUBMIT);
  var noSubmitActionWithAccordian = () => console.error('Form does not have a submit button with an action set');
  var errorFnForAccordians = errors => {
    var firstElementName = Object.keys(errors)[0];
    var errorAccordianIndex = accordians.findIndex(accordian => accordian.elements.fields.some(field => field.name === firstElementName));
    handleAccordionToggle(errorAccordianIndex);
    setTimeout(() => {
      var _firstErrorElement$sc;
      var firstErrorElement = document.querySelector("[data-fieldname=\"".concat(firstElementName, "\"]"));
      firstErrorElement === null || firstErrorElement === void 0 || (_firstErrorElement$sc = firstErrorElement.scrollIntoView) === null || _firstErrorElement$sc === void 0 || _firstErrorElement$sc.call(firstErrorElement, {
        behavior: 'smooth'
      });
    }, 50);
  };
  (0, _react2.useEffect)(() => {
    var subscription = watch(() => {
      if (onChange) {
        onChange(getValues());
      }
    });
    return () => subscription.unsubscribe();
  }, [watchValuesForFormWithAccordian]);
  (0, _react2.useEffect)(() => {
    return () => {
      getFormState === null || getFormState === void 0 || getFormState(getValues(), errorsReference.current);
    };
  }, [getFormState, getValues]);
  (0, _react2.useEffect)(() => {
    errorsReference.current = errors;
  }, [errors]);
  var handleAccordianTriggerValidation = (0, _react2.useCallback)(fieldsName => {
    trigger(fieldsName);
  }, [trigger]);
  var handleSetValueWithAccordian = (0, _react2.useCallback)((fieldName, value) => {
    setValue(fieldName, value);
  }, [setValue]);
  var handleSetErrorWithAccordian = (0, _react2.useCallback)((fieldName, error, config) => {
    setError(fieldName, error, config);
  }, [setError]);
  var handleClearErrorsWithAccordian = (0, _react2.useCallback)(fieldName => {
    clearErrors(fieldName);
  }, [clearErrors]);
  var accordianSubmitFn = (_customSubmitButtonWi = customSubmitButtonWithAccordian === null || customSubmitButtonWithAccordian === void 0 ? void 0 : customSubmitButtonWithAccordian.action) !== null && _customSubmitButtonWi !== void 0 ? _customSubmitButtonWi : noSubmitActionWithAccordian;
  function renderAccordianContent(_ref2) {
    var {
      fields,
      formStyles,
      fieldsContainerStyles
    } = _ref2;
    return (0, _jsxRuntime.jsx)(_react.Box, _objectSpread(_objectSpread({}, formStyles), {}, {
      children: (0, _jsxRuntime.jsx)("fieldset", {
        disabled: fieldsetDisabled,
        style: styleFieldsetWithAccordian(fieldsetDisabled),
        children: (0, _jsxRuntime.jsx)(_react.Flex, _objectSpread(_objectSpread({}, _objectSpread(_objectSpread({}, defaultFieldsContainerStylesWithAccordian), fieldsContainerStyles)), {}, {
          children: fields.map(formField => {
            var _formField$id, _formField$props$isDi, _formField$props;
            return (0, _jsxRuntime.jsx)(_FormField.default, {
              formField: formField,
              control: control,
              getValues: getValues,
              handleSetValue: handleSetValueWithAccordian,
              errors: errors,
              handleClearErrors: handleClearErrorsWithAccordian,
              handleSetError: handleSetErrorWithAccordian,
              handleTriggerValidation: handleAccordianTriggerValidation,
              onChangeAction: formField === null || formField === void 0 ? void 0 : formField.onChangeAction,
              btnProps: {
                onClick: formField.type === _formConstants.FORM_BUTTON_TYPES.BUTTON ? () => formField.action(trigger) : undefined,
                varient: 'primary',
                isDisabled: (_formField$props$isDi = (_formField$props = formField.props) === null || _formField$props === void 0 ? void 0 : _formField$props.isDisabled) !== null && _formField$props$isDi !== void 0 ? _formField$props$isDi : false,
                label: formField.label,
                size: 'full',
                type: formField.type
              }
            }, "".concat(formField.name, "-").concat((_formField$id = formField.id) !== null && _formField$id !== void 0 ? _formField$id : ''));
          })
        }))
      })
    }));
  }
  function renderAccordionItems() {
    var accordionItems = accordians.map(accordian => ({
      title: accordian.title,
      content: renderAccordianContent(accordian.elements),
      onToggleSection: accordian.onToggleSection
    }));
    return accordionItems;
  }
  return (0, _jsxRuntime.jsx)("form", {
    id: id,
    onSubmit: handleSubmit(accordianSubmitFn, errorFnForAccordians),
    "data-testid": testid,
    autoComplete: autoComplete,
    children: (0, _jsxRuntime.jsx)(_Accordion.default, {
      allowMultiple: true,
      accordionItems: renderAccordionItems(),
      bgColor: 'baseWhite',
      accordionOverwriteStyles: _objectSpread(_objectSpread({}, accordionOverwriteStyles), {}, {
        container: _objectSpread(_objectSpread({}, accordionOverwriteStyles === null || accordionOverwriteStyles === void 0 ? void 0 : accordionOverwriteStyles.container), {}, {
          index: [accordionIndex]
        })
      })
    })
  });
};
exports.FormWithAccordian = FormWithAccordian;
var defaultFieldsContainerStylesWithAccordian = {
  direction: 'column',
  height: 'auto',
  justifyContent: 'flex-start',
  marginBottom: 'var(--chakra-space-lg)'
};
var defaultFieldsetStyleWithAccordian = {
  border: '0',
  padding: '0.01em 0 0 0',
  margin: '0',
  minWidth: '0'
};
var styleFieldsetWithAccordian = isDisabled => {
  return isDisabled ? _objectSpread(_objectSpread({}, {
    opacity: '0.4',
    pointerEvents: 'none'
  }), defaultFieldsetStyleWithAccordian) : defaultFieldsetStyleWithAccordian;
};
var accordionContainerStyle = {
  border: '1px solid var(--chakra-colors-lightGrey2)',
  borderTop: 0
};
var accordionItemButtonStyle = {
  sx: {
    div: {
      color: 'darkGrey2'
    }
  },
  _expanded: {
    borderBottom: '1px solid var(--chakra-colors-lightGrey3)'
  },
  pl: 'md',
  fontSize: '2xl',
  fontWeight: '600'
};
var accordionItemTextStyle = {
  fontWeight: 'semibold',
  fontSize: '2xl',
  borderTop: 0
};
var accordionItemPanelStyle = {
  padding: '0 var(--chakra-space-lg)'
};
var accordionItemStyle = {
  borderBottom: 0,
  borderTop: '1px solid var(--chakra-colors-lightGrey2)'
};
var accordionOverwriteStyles = {
  container: accordionContainerStyle,
  button: accordionItemButtonStyle,
  text: accordionItemTextStyle,
  panel: accordionItemPanelStyle,
  item: accordionItemStyle
};