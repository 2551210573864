"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
var _exportNames = {
  getTotalPeople: true,
  getPlaceholderString: true,
  getRoomsPlaceholder: true
};
exports.getPlaceholderString = getPlaceholderString;
exports.getRoomsPlaceholder = getRoomsPlaceholder;
exports.getTotalPeople = getTotalPeople;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _searchContainerHelpers = require("./searchContainerHelpers");
Object.keys(_searchContainerHelpers).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
  if (key in exports && exports[key] === _searchContainerHelpers[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _searchContainerHelpers[key];
    }
  });
});
var _singleHotelSearch = require("./singleHotelSearch");
Object.keys(_singleHotelSearch).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
  if (key in exports && exports[key] === _singleHotelSearch[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _singleHotelSearch[key];
    }
  });
});
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function getTotalPeople(rooms) {
  return rooms.reduce((acc, cur) => {
    acc.adults += +cur.adults;
    acc.children += +cur.children;
    return acc;
  }, {
    adults: 0,
    children: 0
  });
}
function getPlaceholderString(obj, labels) {
  var {
    adult,
    adults,
    child,
    children,
    room,
    rooms
  } = labels;
  var adultsLabel = obj.adults === 1 ? adult : adults;
  var childrenLabel;
  if (obj.children === 0) {
    childrenLabel = '';
  } else if (obj.children === 1) {
    childrenLabel = child;
  } else {
    childrenLabel = children;
  }
  var roomsLabel = obj.rooms === 1 ? room : rooms;
  return obj.children === 0 ? "".concat(obj.adults, " ").concat(adultsLabel, ", ").concat(obj.rooms, " ").concat(roomsLabel) : "".concat(obj.adults, " ").concat(adultsLabel, ", ").concat(obj.children, " ").concat(childrenLabel, ", ").concat(obj.rooms, " ").concat(roomsLabel);
}
function getRoomsPlaceholder(rooms, labels) {
  if (!(rooms !== null && rooms !== void 0 && rooms.length)) return;
  var totalRooms = rooms === null || rooms === void 0 ? void 0 : rooms.length;
  var totalPeople = getTotalPeople(rooms);
  var placeholderStr = getPlaceholderString(_objectSpread(_objectSpread({}, totalPeople), {}, {
    rooms: totalRooms
  }), labels);
  return placeholderStr;
}