"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = BookingSummaryContainer;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _react = require("@chakra-ui/react");
var _reactQuery = require("@tanstack/react-query");
var _api = require("@whitbread-eos/api");
var _atoms = require("@whitbread-eos/atoms");
var _utils = require("@whitbread-eos/utils");
var _react2 = require("react");
var _common = require("../../common");
var _jsxRuntime = require("react/jsx-runtime");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function BookingSummaryContainer(_ref) {
  var _bkngData$bookingInfo, _firstRoom$roomStay, _firstRoom$roomStay2, _firstRoom$roomStay3, _firstRoom$roomStay4, _bkngData$bookingInfo2, _bkngData$bookingInfo3, _bkngData$bookingInfo9, _bkngData$bookingInfo10, _firstRoom$roomStay7, _bkngData$bookingInfo11, _firstRoom$roomStay8, _firstRoom$roomStay9, _bkngData$bookingInfo12, _bkngData$bookingInfo13, _bkngData$bookingInfo14, _bkngData$bookingInfo15, _bkngData$bookingInfo16, _bkngData$bookingInfo17;
  var {
    packages,
    bkngData,
    hiData,
    biQueryInput,
    basketReferenceId,
    variant,
    t,
    language,
    taxesMessage,
    area,
    isExtrasDisplayed
  } = _ref;
  var queryClient = (0, _reactQuery.useQueryClient)();
  var firstRoom = (bkngData === null || bkngData === void 0 || (_bkngData$bookingInfo = bkngData.bookingInformation) === null || _bkngData$bookingInfo === void 0 ? void 0 : _bkngData$bookingInfo.reservationByIdList[0]) || {};
  var baseDataTestId = 'BookingSummaryContainer';
  var noNights = (0, _utils.getNightsNumber)(firstRoom === null || firstRoom === void 0 || (_firstRoom$roomStay = firstRoom.roomStay) === null || _firstRoom$roomStay === void 0 ? void 0 : _firstRoom$roomStay.arrivalDate, firstRoom === null || firstRoom === void 0 || (_firstRoom$roomStay2 = firstRoom.roomStay) === null || _firstRoom$roomStay2 === void 0 ? void 0 : _firstRoom$roomStay2.departureDate);
  var [selectedMeals, setSelectedMeals] = (0, _react2.useState)([]);
  var {
    roomSelection = []
  } = packages !== null && packages !== void 0 ? packages : {};
  var adultsMeals = (0, _utils.adultsMealsSelector)(packages === null || packages === void 0 ? void 0 : packages.meals, noNights);
  var childrenMeals = (0, _utils.childrenMealsSelector)(packages === null || packages === void 0 ? void 0 : packages.mealsKids);
  var reservationDetails = {
    arrivalDate: (firstRoom === null || firstRoom === void 0 || (_firstRoom$roomStay3 = firstRoom.roomStay) === null || _firstRoom$roomStay3 === void 0 ? void 0 : _firstRoom$roomStay3.arrivalDate) || null,
    departureDate: (firstRoom === null || firstRoom === void 0 || (_firstRoom$roomStay4 = firstRoom.roomStay) === null || _firstRoom$roomStay4 === void 0 ? void 0 : _firstRoom$roomStay4.departureDate) || null,
    currency: bkngData === null || bkngData === void 0 || (_bkngData$bookingInfo2 = bkngData.bookingInformation) === null || _bkngData$bookingInfo2 === void 0 ? void 0 : _bkngData$bookingInfo2.currencyCode,
    noRooms: bkngData === null || bkngData === void 0 || (_bkngData$bookingInfo3 = bkngData.bookingInformation) === null || _bkngData$bookingInfo3 === void 0 || (_bkngData$bookingInfo3 = _bkngData$bookingInfo3.reservationByIdList) === null || _bkngData$bookingInfo3 === void 0 ? void 0 : _bkngData$bookingInfo3.length,
    noNights
  };
  var {
    mutation: urcMutation,
    isSuccess: urcIsSuccess
  } = (0, _utils.useMutationRequest)(_api.UPDATE_ANCILLARIES_RATE_CODE, undefined, undefined, {
    onSuccess: () => {
      if (area === _api.Area.CCUI) {
        queryClient.invalidateQueries({
          queryKey: ['getPaymentMethodsCCUI', biQueryInput.language, biQueryInput.country, biQueryInput.basketReference]
        });
        return;
      }
      if (area === _api.Area.PI) {
        queryClient.invalidateQueries({
          queryKey: ['getPaymentMethods', biQueryInput.language, biQueryInput.country, biQueryInput.basketReference]
        });
      }
    }
  });
  var updateRateCode = (0, _react2.useCallback)(() => {
    var _bkngData$bookingInfo4, _bkngData$bookingInfo5, _bkngData$bookingInfo6, _bkngData$bookingInfo7, _firstRoom$roomStay5, _firstRoom$roomStay6, _bkngData$bookingInfo8;
    var roomTypes = bkngData === null || bkngData === void 0 || (_bkngData$bookingInfo4 = bkngData.bookingInformation) === null || _bkngData$bookingInfo4 === void 0 ? void 0 : _bkngData$bookingInfo4.reservationByIdList.map(room => {
      var _room$roomStay;
      return room === null || room === void 0 || (_room$roomStay = room.roomStay) === null || _room$roomStay === void 0 || (_room$roomStay = _room$roomStay.roomExtraInfo) === null || _room$roomStay === void 0 ? void 0 : _room$roomStay.roomType;
    });
    var guestCount = (0, _utils.bookingGuestCount)(bkngData === null || bkngData === void 0 || (_bkngData$bookingInfo5 = bkngData.bookingInformation) === null || _bkngData$bookingInfo5 === void 0 ? void 0 : _bkngData$bookingInfo5.reservationByIdList);
    urcMutation.mutate({
      basketReferenceId: basketReferenceId,
      rateCode: bkngData === null || bkngData === void 0 || (_bkngData$bookingInfo6 = bkngData.bookingInformation) === null || _bkngData$bookingInfo6 === void 0 || (_bkngData$bookingInfo6 = _bkngData$bookingInfo6.upgradeToFlex) === null || _bkngData$bookingInfo6 === void 0 ? void 0 : _bkngData$bookingInfo6.flexRateCode,
      hotelId: bkngData === null || bkngData === void 0 || (_bkngData$bookingInfo7 = bkngData.bookingInformation) === null || _bkngData$bookingInfo7 === void 0 ? void 0 : _bkngData$bookingInfo7.hotelId,
      startDate: (_firstRoom$roomStay5 = firstRoom.roomStay) === null || _firstRoom$roomStay5 === void 0 ? void 0 : _firstRoom$roomStay5.arrivalDate,
      endDate: (_firstRoom$roomStay6 = firstRoom.roomStay) === null || _firstRoom$roomStay6 === void 0 ? void 0 : _firstRoom$roomStay6.departureDate,
      roomType: roomTypes,
      currency: bkngData === null || bkngData === void 0 || (_bkngData$bookingInfo8 = bkngData.bookingInformation) === null || _bkngData$bookingInfo8 === void 0 || (_bkngData$bookingInfo8 = _bkngData$bookingInfo8.upgradeToFlex) === null || _bkngData$bookingInfo8 === void 0 ? void 0 : _bkngData$bookingInfo8.currency,
      adultsNumber: guestCount.adultsNumber,
      childrenNumber: guestCount.childrenNumber
    });
  }, [basketReferenceId]);
  var initialTotalCost = (bkngData === null || bkngData === void 0 || (_bkngData$bookingInfo9 = bkngData.bookingInformation) === null || _bkngData$bookingInfo9 === void 0 ? void 0 : _bkngData$bookingInfo9.totalCost) - (0, _utils.calculateTotalCostRoomSelection)(adultsMeals, roomSelection, noNights);
  var bookingSummaryData = {
    hotelInformation: (hiData === null || hiData === void 0 ? void 0 : hiData.hotelInformation) && (0, _utils.hotelInformationSelector)(hiData === null || hiData === void 0 ? void 0 : hiData.hotelInformation),
    totalCost: {
      showVATMessage: true,
      currency: bkngData === null || bkngData === void 0 || (_bkngData$bookingInfo10 = bkngData.bookingInformation) === null || _bkngData$bookingInfo10 === void 0 ? void 0 : _bkngData$bookingInfo10.currencyCode,
      initialTotalCost: initialTotalCost,
      meals: (0, _utils.selectedMealsPerRoomSelector)(selectedMeals, adultsMeals, childrenMeals)
    },
    rateInformation: {
      rate: (_firstRoom$roomStay7 = firstRoom.roomStay) === null || _firstRoom$roomStay7 === void 0 || (_firstRoom$roomStay7 = _firstRoom$roomStay7.rateExtraInfo) === null || _firstRoom$roomStay7 === void 0 ? void 0 : _firstRoom$roomStay7.rateName,
      noNights: noNights,
      noRooms: bkngData === null || bkngData === void 0 || (_bkngData$bookingInfo11 = bkngData.bookingInformation) === null || _bkngData$bookingInfo11 === void 0 || (_bkngData$bookingInfo11 = _bkngData$bookingInfo11.reservationByIdList) === null || _bkngData$bookingInfo11 === void 0 ? void 0 : _bkngData$bookingInfo11.length
    },
    stayDatesInformation: {
      arrivalDate: ((_firstRoom$roomStay8 = firstRoom.roomStay) === null || _firstRoom$roomStay8 === void 0 ? void 0 : _firstRoom$roomStay8.arrivalDate) || null,
      departureDate: ((_firstRoom$roomStay9 = firstRoom.roomStay) === null || _firstRoom$roomStay9 === void 0 ? void 0 : _firstRoom$roomStay9.departureDate) || null,
      noNights: noNights
    },
    updateToFlex: {
      showUpgradeToFlex: !!(bkngData !== null && bkngData !== void 0 && (_bkngData$bookingInfo12 = bkngData.bookingInformation) !== null && _bkngData$bookingInfo12 !== void 0 && (_bkngData$bookingInfo12 = _bkngData$bookingInfo12.upgradeToFlex) !== null && _bkngData$bookingInfo12 !== void 0 && _bkngData$bookingInfo12.flexRateCode),
      currency: bkngData === null || bkngData === void 0 || (_bkngData$bookingInfo13 = bkngData.bookingInformation) === null || _bkngData$bookingInfo13 === void 0 || (_bkngData$bookingInfo13 = _bkngData$bookingInfo13.upgradeToFlex) === null || _bkngData$bookingInfo13 === void 0 ? void 0 : _bkngData$bookingInfo13.currency,
      amount: (bkngData === null || bkngData === void 0 || (_bkngData$bookingInfo14 = bkngData.bookingInformation) === null || _bkngData$bookingInfo14 === void 0 || (_bkngData$bookingInfo14 = _bkngData$bookingInfo14.upgradeToFlex) === null || _bkngData$bookingInfo14 === void 0 ? void 0 : _bkngData$bookingInfo14.amount) - initialTotalCost,
      upgradeToFlexCallBack: updateRateCode,
      initialRate: bkngData === null || bkngData === void 0 || (_bkngData$bookingInfo15 = bkngData.bookingInformation) === null || _bkngData$bookingInfo15 === void 0 ? void 0 : _bkngData$bookingInfo15.totalCost
    },
    roomInformation: (0, _utils.roomInformationSelector)(bkngData === null || bkngData === void 0 || (_bkngData$bookingInfo16 = bkngData.bookingInformation) === null || _bkngData$bookingInfo16 === void 0 ? void 0 : _bkngData$bookingInfo16.reservationByIdList, selectedMeals, adultsMeals, childrenMeals, (0, _utils.roomPackageSelection)((0, _utils.extrasPackagesMapperSelector)(roomSelection)))
  };
  function getImportantMessages() {
    var _hiData$hotelInformat, _firstRoom$roomStay10, _firstRoom$roomStay11;
    var messages = (0, _utils.formatImportantNotes)((hiData === null || hiData === void 0 || (_hiData$hotelInformat = hiData.hotelInformation) === null || _hiData$hotelInformat === void 0 || (_hiData$hotelInformat = _hiData$hotelInformat.importantInfo) === null || _hiData$hotelInformat === void 0 ? void 0 : _hiData$hotelInformat.infoItems) || [], firstRoom === null || firstRoom === void 0 || (_firstRoom$roomStay10 = firstRoom.roomStay) === null || _firstRoom$roomStay10 === void 0 ? void 0 : _firstRoom$roomStay10.arrivalDate, firstRoom === null || firstRoom === void 0 || (_firstRoom$roomStay11 = firstRoom.roomStay) === null || _firstRoom$roomStay11 === void 0 ? void 0 : _firstRoom$roomStay11.departureDate);
    return messages.length > 0 ? [messages] : [];
  }
  var listOfImportantMessages = getImportantMessages();
  var infoMessages = [...((bkngData === null || bkngData === void 0 || (_bkngData$bookingInfo17 = bkngData.bookingInformation) === null || _bkngData$bookingInfo17 === void 0 || (_bkngData$bookingInfo17 = _bkngData$bookingInfo17.infoMessages) === null || _bkngData$bookingInfo17 === void 0 ? void 0 : _bkngData$bookingInfo17.length) > 0 ? bkngData.bookingInformation.infoMessages : []), ...((listOfImportantMessages === null || listOfImportantMessages === void 0 ? void 0 : listOfImportantMessages.length) > 0 ? [listOfImportantMessages] : [])];
  (0, _react2.useEffect)(() => {
    var _bkngData$bookingInfo18;
    if ((bkngData === null || bkngData === void 0 || (_bkngData$bookingInfo18 = bkngData.bookingInformation) === null || _bkngData$bookingInfo18 === void 0 ? void 0 : _bkngData$bookingInfo18.reservationByIdList.length) > 0) {
      var preselectedMeals = [];
      if (roomSelection !== null && roomSelection !== void 0 && roomSelection.length && packages !== null && packages !== void 0 && packages.meals && packages !== null && packages !== void 0 && packages.mealsKids) {
        preselectedMeals = (0, _utils.mealsMapperSelector)(packages === null || packages === void 0 ? void 0 : packages.meals, packages === null || packages === void 0 ? void 0 : packages.mealsKids, roomSelection);
        setSelectedMeals(preselectedMeals);
      }
    }
  }, [bkngData, roomSelection, packages]);
  (0, _react2.useEffect)(() => {
    if (urcIsSuccess) {
      queryClient.invalidateQueries({
        queryKey: ['GetBookingInformation', biQueryInput.language, biQueryInput.country, biQueryInput.basketReference]
      });
    }
  }, [urcIsSuccess, biQueryInput, queryClient]);
  return (0, _jsxRuntime.jsxs)(_react.Box, {
    "data-testid": baseDataTestId,
    children: [(0, _jsxRuntime.jsx)(_common.BookingSummary, {
      variant: variant,
      t: t,
      language: language,
      reservationDetails: reservationDetails,
      bookingSummaryData: bookingSummaryData,
      infoMessages: infoMessages,
      taxesMessage: taxesMessage,
      isExtrasDisplayed: isExtrasDisplayed
    }), variant == 'desktop' && (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
      children: [(0, _jsxRuntime.jsx)(_atoms.Button, {
        type: "submit",
        form: "guestDetailsForm",
        size: "full",
        mt: "lg",
        variant: "primary",
        "data-testid": "BookingSummary-ContinueButton",
        children: (0, _jsxRuntime.jsx)(_react.Text, _objectSpread(_objectSpread({}, continueTextStyle), {}, {
          children: t('booking.summary.continue')
        }))
      }), (0, _jsxRuntime.jsx)(_react.Box, {
        pt: "sm",
        "data-testid": (0, _utils.formatDataTestId)(baseDataTestId, 'BookingSummary-InfoMessages'),
        children: infoMessages === null || infoMessages === void 0 ? void 0 : infoMessages.map(notification => {
          if (notification.length) {
            return (0, _jsxRuntime.jsx)(_react.Box, {
              mt: "md",
              children: (0, _jsxRuntime.jsx)(_atoms.Notification, {
                maxWidth: "full",
                variant: "info",
                status: "info",
                description: (0, _jsxRuntime.jsx)(_react.Box, {
                  className: "formatLinks",
                  children: (0, _utils.renderSanitizedHtml)(notification)
                }),
                svg: (0, _jsxRuntime.jsx)(_atoms.Info, {})
              })
            }, notification);
          }
        })
      })]
    })]
  });
}
var continueTextStyle = {
  fontWeight: 'semibold',
  fontSize: 'lg',
  lineHeight: '3',
  color: 'baseWhite'
};