"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getPaypalOptionsParams = exports.getPaypalDeviceData = void 0;
var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));
var _api = require("@whitbread-eos/api");
var _braintreeWeb = require("braintree-web");
var getPaypalDeviceData = exports.getPaypalDeviceData = function () {
  var _ref = (0, _asyncToGenerator2.default)(function* (authorization) {
    if (authorization) {
      var brainTreeClient = yield _braintreeWeb.client.create({
        authorization
      });
      var collector = yield _braintreeWeb.dataCollector.create({
        client: brainTreeClient,
        paypal: true
      });
      var deviceData = yield collector.getDeviceData();
      return deviceData;
    }
  });
  return function getPaypalDeviceData(_x) {
    return _ref.apply(this, arguments);
  };
}();
var getPaypalOptionsParams = _ref2 => {
  var {
    currencyCode,
    approveCallBack,
    cancelCallBack,
    errorCallBack
  } = _ref2;
  var createBillingAgreement = (data, actions) => {
    var _actions$braintree;
    return actions === null || actions === void 0 || (_actions$braintree = actions.braintree) === null || _actions$braintree === void 0 ? void 0 : _actions$braintree.createPayment({
      flow: 'vault',
      currency: currencyCode,
      enableShippingAddress: false,
      shippingAddressEditable: false
    }).then(orderId => orderId);
  };
  var onApprove = (data, actions) => {
    var _actions$braintree2;
    return actions === null || actions === void 0 || (_actions$braintree2 = actions.braintree) === null || _actions$braintree2 === void 0 ? void 0 : _actions$braintree2.tokenizePayment(data).then(payload => {
      approveCallBack(payload.nonce);
    });
  };
  var onCancel = data => {
    console.error('Cancelled', JSON.stringify(data, null, 2));
    cancelCallBack === null || cancelCallBack === void 0 || cancelCallBack(_api.PAYMENT_FAILED_VALUE);
  };
  var onError = err => {
    console.error('PayPal error', err);
    errorCallBack === null || errorCallBack === void 0 || errorCallBack(_api.PAYMENT_FAILED_VALUE);
  };
  return {
    createBillingAgreement,
    onApprove,
    onCancel,
    onError
  };
};
exports.getPaypalOptionsParams = getPaypalOptionsParams;