"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = decodeIdToken;
var _jwtDecode = _interopRequireDefault(require("jwt-decode"));
function decodeIdToken(token) {
  var _unpackedJwt, _unpackedJwt2;
  var unpackedJwt = {
    email: '',
    name: '',
    exp: null
  };
  try {
    if (token !== null && token !== void 0 && token.length) {
      unpackedJwt = (0, _jwtDecode.default)(token);
    }
  } catch (e) {
    console.log(e);
  }
  if (unpackedJwt && !((_unpackedJwt = unpackedJwt) !== null && _unpackedJwt !== void 0 && (_unpackedJwt = _unpackedJwt.email) !== null && _unpackedJwt !== void 0 && _unpackedJwt.length) && (_unpackedJwt2 = unpackedJwt) !== null && _unpackedJwt2 !== void 0 && (_unpackedJwt2 = _unpackedJwt2.name) !== null && _unpackedJwt2 !== void 0 && _unpackedJwt2.length) {
    unpackedJwt.email = unpackedJwt.name;
  }
  return unpackedJwt;
}