"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = SoldOutNotificationComponent;
var _react = require("@chakra-ui/react");
var _atoms = require("@whitbread-eos/atoms");
var _nextI18next = require("next-i18next");
var _jsxRuntime = require("react/jsx-runtime");
function SoldOutNotificationComponent(_ref) {
  var _data$hotelAvailabili, _data$hotelAvailabili2;
  var {
    isLoading,
    isError,
    error,
    name,
    data,
    isHotelOpeningSoon
  } = _ref;
  var {
    t
  } = (0, _nextI18next.useTranslation)(['common']);
  var isAvailable = (data === null || data === void 0 || (_data$hotelAvailabili = data.hotelAvailability) === null || _data$hotelAvailabili === void 0 ? void 0 : _data$hotelAvailabili.available) && (data === null || data === void 0 || (_data$hotelAvailabili2 = data.hotelAvailability) === null || _data$hotelAvailabili2 === void 0 ? void 0 : _data$hotelAvailabili2.roomRates.length) > 0;
  if (isLoading) {
    return (0, _jsxRuntime.jsx)(_jsxRuntime.Fragment, {});
  }
  if (isError) {
    return (0, _jsxRuntime.jsx)(_react.Text, {
      children: error.message
    });
  }
  if (!isHotelOpeningSoon && isAvailable || isHotelOpeningSoon) {
    return null;
  }
  return (0, _jsxRuntime.jsx)(_react.Box, {
    mt: {
      base: 'md',
      lg: '6'
    },
    "data-testid": "soldout-notification",
    children: (0, _jsxRuntime.jsx)(_atoms.Notification, {
      title: "",
      description: "".concat(name, " ").concat(t('hoteldetails.unavailableText')),
      status: "info",
      variant: "alert",
      svg: (0, _jsxRuntime.jsx)(_atoms.Alert, {}),
      maxW: {
        base: 'full'
      },
      lineHeight: "2",
      prefixDataTestId: "soldout-notification"
    })
  });
}