"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = CookieModal;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _objectWithoutProperties2 = _interopRequireDefault(require("@babel/runtime/helpers/objectWithoutProperties"));
var _react = require("@chakra-ui/react");
var _utils = require("@whitbread-eos/utils");
var _jsxRuntime = require("react/jsx-runtime");
var _excluded = ["onClose", "isOpen", "children", "variantProps", "dataTestId", "hasAutoFocus", "headerStyles"];
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function CookieModal(_ref) {
  var {
      onClose,
      isOpen,
      children,
      variantProps,
      dataTestId,
      hasAutoFocus = true,
      headerStyles
    } = _ref,
    otherProps = (0, _objectWithoutProperties2.default)(_ref, _excluded);
  var prefix = dataTestId ? "".concat(dataTestId, "-") : '';
  var {
    isLessThanMobile
  } = (0, _utils.useScreenSize)();
  var compModalHeaderStyles = headerStyles ? _objectSpread(_objectSpread({}, modalHeaderStyle), headerStyles) : _objectSpread({}, modalHeaderStyle);
  return (0, _jsxRuntime.jsxs)(_react.Modal, _objectSpread(_objectSpread({
    "data-testid": "".concat(prefix, "ChakraModal"),
    preserveScrollBarGap: true,
    scrollBehavior: "inside",
    isOpen: isOpen,
    onClose: onClose
  }, otherProps), {}, {
    autoFocus: hasAutoFocus,
    closeOnEsc: variantProps === null || variantProps === void 0 ? void 0 : variantProps.closeOnOverlayClick,
    closeOnOverlayClick: variantProps === null || variantProps === void 0 ? void 0 : variantProps.closeOnOverlayClick,
    size: isLessThanMobile ? 'full' : 'md',
    children: [(0, _jsxRuntime.jsx)(_react.ModalOverlay, {}), (0, _jsxRuntime.jsxs)(_react.ModalContent, _objectSpread(_objectSpread({
      "data-testid": "".concat(prefix, "ModalContent")
    }, modalContentStyle(variantProps === null || variantProps === void 0 ? void 0 : variantProps.isCookieConsentModal)), {}, {
      width: otherProps.size ? 'auto' : {
        mobile: 'full',
        sm: 'auto'
      },
      children: [(0, _jsxRuntime.jsx)(_react.ModalHeader, _objectSpread(_objectSpread({}, compModalHeaderStyles), {}, {
        "data-testid": "".concat(prefix, "ModalHeader"),
        children: variantProps && (0, _jsxRuntime.jsx)(_react.Text, _objectSpread(_objectSpread({}, headerTitleStyle), {}, {
          "data-testid": "".concat(prefix, "ModalTitle"),
          children: variantProps.title
        }))
      })), (0, _jsxRuntime.jsx)(_react.ModalBody, _objectSpread(_objectSpread({
        "data-testid": "".concat(prefix, "ModalBody")
      }, modalBodyStyle), {}, {
        children: children
      }))]
    }))]
  }));
}
var modalContentStyle = isCookieConsentModal => {
  return {
    maxW: 'auto',
    maxH: 'auto',
    overflow: 'auto',
    height: {
      mobile: 'full',
      sm: 'fit-content'
    },
    maxHeight: {
      mobile: 'full',
      sm: 'calc(100% - 6rem)'
    },
    top: {
      mobile: 0,
      sm: '10%',
      lg: isCookieConsentModal && '10%'
    },
    m: '0',
    mt: {
      mobile: '0',
      sm: '2xl'
    },
    mb: {
      mobile: '0',
      sm: '2xl'
    },
    borderRadius: {
      mobile: '0',
      sm: '0.875rem'
    },
    boxShadow: '0 0.125rem 0.75rem var(--chakra-colors-darkGrey2)'
  };
};
var modalHeaderStyle = {
  pb: 0,
  pt: 'lg',
  px: 'lg'
};
var headerTitleStyle = {
  color: 'darkGrey1',
  justifyContent: 'center',
  fontSize: {
    mobile: '2xl',
    md: '3xl'
  },
  lineHeight: '4',
  fontWeight: 'semibold',
  fontFamily: 'header'
};
var modalBodyStyle = {
  p: '0'
};