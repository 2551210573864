"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getAllRoomTypesFromRates = getAllRoomTypesFromRates;
exports.getAvailabilityParamsFromUrl = getAvailabilityParamsFromUrl;
exports.getAvailabilityRoomClassIndexes = getAvailabilityRoomClassIndexes;
exports.getAvailableRoomTypes = getAvailableRoomTypes;
exports.getHotelAvailabilityQueryKey = getHotelAvailabilityQueryKey;
exports.getRateClassification = getRateClassification;
exports.getRoomClassByRoomClassCode = getRoomClassByRoomClassCode;
exports.getRoomRatesThatMatchRoomClassifications = getRoomRatesThatMatchRoomClassifications;
exports.getRoomTypesFromQuery = getRoomTypesFromQuery;
exports.getSelectedPMSRoomTypesAndSpecialRequests = getSelectedPMSRoomTypesAndSpecialRequests;
exports.getTwinRoomSelectionPrices = getTwinRoomSelectionPrices;
exports.isFaqValidForRender = isFaqValidForRender;
exports.isHotelOpeningSoon = isHotelOpeningSoon;
var _api = require("@whitbread-eos/api");
var _add = _interopRequireDefault(require("date-fns/add"));
var _differenceInDays = _interopRequireDefault(require("date-fns/differenceInDays"));
var _format = _interopRequireDefault(require("date-fns/format"));
var _validators = require("../validators");
function getAvailabilityParamsFromUrl(url) {
  var urlParams = new URLSearchParams(url);
  var year = Number(urlParams.get('ARRyyyy'));
  var month = Number(urlParams.get('ARRmm'));
  var day = Number(urlParams.get('ARRdd'));
  var numberOfNights = Number(urlParams.get('NIGHTS'));
  var numberOfRooms = Number(urlParams.get('ROOMS'));
  var cellCodes = urlParams.get('CELLCODES');
  var empOfferCodes = (cellCodes === null || cellCodes === void 0 ? void 0 : cellCodes.split(',')) || [];
  var corpId = urlParams.get('CORPID');
  var arrivalDate = (0, _validators.isDateValid)(day, month, year) ? new Date(year, month - 1, day) : new Date();
  var arrival = (0, _format.default)(arrivalDate, 'yyyy-MM-dd');
  var departure = (0, _format.default)((0, _add.default)(arrivalDate, {
    days: numberOfNights
  }), 'yyyy-MM-dd');
  var rooms = [];
  for (var idx = 0; idx < numberOfRooms; idx++) {
    rooms.push({
      adultsNumber: Number(urlParams.get("ADULT".concat(idx + 1))),
      childrenNumber: Number(urlParams.get("CHILD".concat(idx + 1))),
      roomType: urlParams.get("INTTYP".concat(idx + 1)),
      cotRequired: urlParams.get("COT".concat(idx + 1)) === '1'
    });
  }
  return {
    arrival,
    departure,
    rooms,
    empOfferCodes,
    corpId,
    numberOfNights
  };
}
function getHotelAvailabilityQueryKey(language, country, brand, hotelId, arrival, departure, rooms) {
  var empOfferCodes = arguments.length > 7 && arguments[7] !== undefined ? arguments[7] : [];
  return ['hotelAvailability', language, country, brand === null || brand === void 0 ? void 0 : brand.toLowerCase(), hotelId, arrival, departure, JSON.stringify(rooms), empOfferCodes];
}
function isHotelOpeningSoon(hotelOpeningDate, arrival) {
  if (!hotelOpeningDate || !arrival) {
    return false;
  }
  try {
    return (0, _differenceInDays.default)(new Date(hotelOpeningDate), new Date(arrival)) > 0;
  } catch (e) {
    return false;
  }
}
function getAvailableRoomTypes() {
  var roomTypes = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
  return Array.from(new Set(roomTypes === null || roomTypes === void 0 ? void 0 : roomTypes.map(type => type === null || type === void 0 ? void 0 : type.roomType)));
}
function getAllRoomTypesFromRates(roomRates) {
  var allRoomTypes = [];
  roomRates.forEach(roomRate => {
    var _roomRate$roomTypes;
    return roomRate === null || roomRate === void 0 || (_roomRate$roomTypes = roomRate.roomTypes) === null || _roomRate$roomTypes === void 0 ? void 0 : _roomRate$roomTypes.forEach(room => {
      allRoomTypes.push(room);
    });
  });
  return allRoomTypes;
}
function getRoomClassByRoomClassCode(roomClassCode, language) {
  return language === 'en' ? _api.RoomClass[roomClassCode] : _api.DeRoomClass[roomClassCode];
}
function getRateClassification(ratePlanCode) {
  var rateClassifications = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [];
  return rateClassifications.find(rate => rate.rateClassification === ratePlanCode || rate.ratePlanCode === ratePlanCode);
}
function getRoomRatesThatMatchRoomClassifications(rateClassifications) {
  var roomRates = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [];
  return [...roomRates].sort((roomRate1, roomRate2) => {
    var _rateClassifications$, _rateClassifications$2;
    var rate1Order = (_rateClassifications$ = rateClassifications.find(el => (el === null || el === void 0 ? void 0 : el.rateClassification) === (roomRate1 === null || roomRate1 === void 0 ? void 0 : roomRate1.ratePlanCode))) === null || _rateClassifications$ === void 0 ? void 0 : _rateClassifications$.rateOrder;
    var rate2Order = (_rateClassifications$2 = rateClassifications.find(el => (el === null || el === void 0 ? void 0 : el.rateClassification) === (roomRate2 === null || roomRate2 === void 0 ? void 0 : roomRate2.ratePlanCode))) === null || _rateClassifications$2 === void 0 ? void 0 : _rateClassifications$2.rateOrder;
    return rate1Order && rate2Order ? +rate1Order - +rate2Order : 0;
  });
}
function getAvailabilityRoomClassIndexes() {
  var _roomRates$;
  var roomRates = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
  var availableRoomTypes = getAvailableRoomTypes(getAllRoomTypesFromRates(roomRates));
  var {
    STANDARD_ROOM
  } = _api.RoomClassCodes;
  return (availableRoomTypes === null || availableRoomTypes === void 0 ? void 0 : availableRoomTypes.length) > 1 ? [STANDARD_ROOM] : (_roomRates$ = roomRates[0]) === null || _roomRates$ === void 0 ? void 0 : _roomRates$.roomTypes[0].rooms.map(room => room === null || room === void 0 ? void 0 : room.roomClass);
}
function getSelectedPMSRoomTypesAndSpecialRequests(twinroomSelections, basketDetailsState) {
  var _basketDetailsState$s;
  var selectedPMSRoomTypes = [];
  var selectedSpecialRequests = [];
  var roomIndex = 0;
  (_basketDetailsState$s = basketDetailsState.selectedRate) === null || _basketDetailsState$s === void 0 || (_basketDetailsState$s = _basketDetailsState$s.roomTypes) === null || _basketDetailsState$s === void 0 || _basketDetailsState$s.forEach(roomType => {
    var _roomType$rooms3, _roomType$rooms4, _isTwoBeds$specialReq, _isDoubleSofa$special;
    if ((roomType === null || roomType === void 0 ? void 0 : roomType.roomType) !== _api.ROOM_TYPE.TWIN) {
      var _roomType$rooms, _roomType$rooms2;
      roomIndex++;
      selectedSpecialRequests.push(roomType === null || roomType === void 0 || (_roomType$rooms = roomType.rooms) === null || _roomType$rooms === void 0 || (_roomType$rooms = _roomType$rooms[0]) === null || _roomType$rooms === void 0 ? void 0 : _roomType$rooms.specialRequests);
      return selectedPMSRoomTypes.push(roomType === null || roomType === void 0 || (_roomType$rooms2 = roomType.rooms) === null || _roomType$rooms2 === void 0 || (_roomType$rooms2 = _roomType$rooms2[0]) === null || _roomType$rooms2 === void 0 ? void 0 : _roomType$rooms2.pmsRoomType);
    }
    var matchTwinRoomSelection = twinroomSelections[roomIndex];
    var isTwoBeds = roomType === null || roomType === void 0 || (_roomType$rooms3 = roomType.rooms) === null || _roomType$rooms3 === void 0 ? void 0 : _roomType$rooms3.find(roomItem => _api.twinRoomImprovedSpecialRequests.includes(roomItem === null || roomItem === void 0 ? void 0 : roomItem.specialRequests[0]));
    var isDoubleSofa = roomType === null || roomType === void 0 || (_roomType$rooms4 = roomType.rooms) === null || _roomType$rooms4 === void 0 ? void 0 : _roomType$rooms4.find(roomItem => _api.twinRoomStandardSpecialRequests.includes(roomItem === null || roomItem === void 0 ? void 0 : roomItem.specialRequests[0]));
    if (matchTwinRoomSelection === _api.ROOM_TYPE.TWIN_TWO_BEDS && isTwoBeds !== null && isTwoBeds !== void 0 && (_isTwoBeds$specialReq = isTwoBeds.specialRequests) !== null && _isTwoBeds$specialReq !== void 0 && _isTwoBeds$specialReq.length) {
      selectedSpecialRequests.push(isTwoBeds === null || isTwoBeds === void 0 ? void 0 : isTwoBeds.specialRequests);
      selectedPMSRoomTypes.push(isTwoBeds === null || isTwoBeds === void 0 ? void 0 : isTwoBeds.pmsRoomType);
    }
    if (matchTwinRoomSelection === _api.ROOM_TYPE.TWIN_DOUBLE_SOFA && isDoubleSofa !== null && isDoubleSofa !== void 0 && (_isDoubleSofa$special = isDoubleSofa.specialRequests) !== null && _isDoubleSofa$special !== void 0 && _isDoubleSofa$special.length) {
      selectedSpecialRequests.push(isDoubleSofa === null || isDoubleSofa === void 0 ? void 0 : isDoubleSofa.specialRequests);
      selectedPMSRoomTypes.push(isDoubleSofa === null || isDoubleSofa === void 0 ? void 0 : isDoubleSofa.pmsRoomType);
    }
    roomIndex++;
  });
  return {
    selectedPMSRoomTypes,
    selectedSpecialRequests
  };
}
function getTwinRoomSelectionPrices(selectedRate) {
  var _selectedRate$roomTyp;
  return selectedRate === null || selectedRate === void 0 || (_selectedRate$roomTyp = selectedRate.roomTypes) === null || _selectedRate$roomTyp === void 0 || (_selectedRate$roomTyp = _selectedRate$roomTyp.find(selectedType => (selectedType === null || selectedType === void 0 ? void 0 : selectedType.roomType) === _api.ROOM_TYPE.TWIN)) === null || _selectedRate$roomTyp === void 0 || (_selectedRate$roomTyp = _selectedRate$roomTyp.rooms) === null || _selectedRate$roomTyp === void 0 || (_selectedRate$roomTyp = _selectedRate$roomTyp.map(room => {
    var hasTwinOptions = element => element === _api.twinRoomImprovedSpecialRequests[0] || element === _api.twinRoomStandardSpecialRequests[0];
    var typeIndex = room.specialRequests.findIndex(hasTwinOptions);
    return {
      twinRoomType: room.specialRequests[typeIndex],
      currencyCode: room.roomPriceBreakdown.currencyCode,
      price: room.roomPriceBreakdown.totalNetAmount
    };
  })) === null || _selectedRate$roomTyp === void 0 ? void 0 : _selectedRate$roomTyp.filter((value, index, self) => {
    return self.findIndex(room => (room === null || room === void 0 ? void 0 : room.twinRoomType) === (value === null || value === void 0 ? void 0 : value.twinRoomType)) === index;
  });
}
function getRoomTypesFromQuery(routerQuery, mapping, roomLabel) {
  var roomTypes = [];
  var numberOfRooms = parseInt(routerQuery['ROOMS']) || 1;
  for (var i = 1; i <= numberOfRooms; i++) {
    var inttypKey = "INTTYP".concat(i);
    var queryValue = routerQuery[inttypKey];
    roomTypes.push(mapping[queryValue] + ' ' + roomLabel);
  }
  return roomTypes;
}
function isFaqValidForRender(faq, isFaqEnabled) {
  var _faq$faqItems;
  return faq && !!Object.keys(faq).length && !!(faq !== null && faq !== void 0 && (_faq$faqItems = faq.faqItems) !== null && _faq$faqItems !== void 0 && _faq$faqItems.length) && isFaqEnabled;
}