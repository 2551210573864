"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = HubZipNoticeContainer;
var _utils = require("@whitbread-eos/utils");
var _HubZipNotice = _interopRequireDefault(require("./HubZipNotice.component"));
var _jsxRuntime = require("react/jsx-runtime");
function HubZipNoticeContainer(_ref) {
  var {
    srcHubLogo
  } = _ref;
  var {
    brand,
    isLoading,
    isError,
    error
  } = (0, _utils.useStaticHotelInformation)();
  return (0, _jsxRuntime.jsx)(_HubZipNotice.default, {
    isLoading,
    isError,
    error,
    data: brand,
    srcHubLogo
  });
}