"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.EckohCardPresence = void 0;
var EckohCardPresence = exports.EckohCardPresence = function (EckohCardPresence) {
  EckohCardPresence["CARD_PRESENT"] = "cp";
  EckohCardPresence["CARD_NOT_PRESENT"] = "cnp";
  return EckohCardPresence;
}({});