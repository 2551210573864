"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.SAVE_RESERVATION_GUEST = void 0;
var _taggedTemplateLiteral2 = _interopRequireDefault(require("@babel/runtime/helpers/taggedTemplateLiteral"));
var _graphqlRequest = require("graphql-request");
var _templateObject;
var SAVE_RESERVATION_GUEST = exports.SAVE_RESERVATION_GUEST = (0, _graphqlRequest.gql)(_templateObject || (_templateObject = (0, _taggedTemplateLiteral2.default)(["\n  input SaveReservationGuestCriteria {\n    basketReference: String!\n    hotelId: String!\n    reasonForStay: String!\n    booker: Booker!\n    stayingGuests: [StayingGuest!]!\n  }\n\n  input StayingGuest {\n    sameAsBooker: Boolean!\n    stayingGuestDetails: StayingGuestDetails\n  }\n\n  input StayingGuestDetails {\n    title: String!\n    firstName: String!\n    lastName: String!\n  }\n\n  input Booker {\n    title: String!\n    firstName: String!\n    lastName: String!\n    emailAddress: String!\n    mobile: String\n    landline: String\n    acceptFutureMailing: Boolean\n    address: GuestAddress\n  }\n\n  input GuestAddress {\n    addressType: String\n    postalCode: String\n    addressLine1: String\n    addressLine2: String\n    addressLine3: String\n    addressLine4: String\n    countryCode: String\n  }\n  mutation saveReservationGuest($saveReservationGuestCriteria: SaveReservationGuestCriteria!) {\n    saveReservationGuest(saveReservationGuestCriteria: $saveReservationGuestCriteria) {\n      basketReference\n    }\n  }\n"])));