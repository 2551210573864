"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ShortCountry = void 0;
var ShortCountry = exports.ShortCountry = function (ShortCountry) {
  ShortCountry["UK"] = "UK";
  ShortCountry["GB"] = "GB";
  ShortCountry["DE"] = "DE";
  return ShortCountry;
}({});