"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = ManageBookingCardWrapper;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _react = require("@chakra-ui/react");
var _api = require("@whitbread-eos/api");
var _atoms = require("@whitbread-eos/atoms");
var _utils = require("@whitbread-eos/utils");
var _nextI18next = require("next-i18next");
var _react2 = require("react");
var _authentication = require("../../../authentication");
var _BookingInfoCard = require("../BookingInfoCard");
var _BookingInfoCardHeader = _interopRequireDefault(require("../BookingInfoCardHeader"));
var _jsxRuntime = require("react/jsx-runtime");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function ManageBookingCardWrapper(_ref) {
  var {
    bookingReference,
    tempBookingReference,
    basketReference,
    operaConfNumber,
    area,
    inputValues,
    isAmendPage = false,
    amendBookingStatus,
    email
  } = _ref;
  var {
    t
  } = (0, _nextI18next.useTranslation)();
  var {
    isLoggedIn
  } = (0, _utils.useUserData)();
  var amendBaseTestId = 'amend-booking-confirmation';
  var [isLoginModalOpen, setIsLoginModalOpen] = (0, _react2.useState)(false);
  var {
    country,
    language
  } = (0, _utils.useCustomLocale)();
  var [confirmAmendErrorValue] = (0, _utils.useSessionStorage)(_api.INITIAL_CONFIRM_AMEND_ERROR_KEY, _api.INITIAL_CONFIRM_AMEND_ERROR_VALUE);
  var toggleLoginModal = () => {
    setIsLoginModalOpen(!isLoginModalOpen);
  };
  var failConfirmationData = {
    t,
    data: {
      emailAddress: email !== null && email !== void 0 ? email : '',
      bookingReference: bookingReference,
      notificationText: t('booking.confirmation.paymentMessage'),
      paymentOption: 'PAY-NOW'
    }
  };
  if (isAmendPage && amendBookingStatus === _api.CONFIRM_AMEND_STATUS.paymentError) {
    return (0, _jsxRuntime.jsx)(_react.Box, _objectSpread(_objectSpread({}, wrapperStyles), {}, {
      children: (0, _jsxRuntime.jsx)(_atoms.FailConfirmation, _objectSpread({}, failConfirmationData))
    }));
  }
  var {
    errorTitle,
    errorDescription
  } = getAmendErrorMessage();
  return (0, _jsxRuntime.jsxs)(_react.Box, _objectSpread(_objectSpread({}, wrapperStyles), {}, {
    children: [(0, _jsxRuntime.jsx)(_BookingInfoCardHeader.default, {
      basketReference: basketReference,
      bookingReference: bookingReference,
      isAmendPage: isAmendPage,
      area: area
    }), isAmendPage && (0, _jsxRuntime.jsxs)(_react.Box, {
      mb: "lg",
      children: [amendBookingStatus === _api.CONFIRM_AMEND_STATUS.success && (0, _jsxRuntime.jsx)(_atoms.Notification, {
        prefixDataTestId: "".concat(amendBaseTestId, "-success-notification"),
        description: t('amend.bookingHasUpdated'),
        variant: "success",
        status: "success",
        maxW: "full",
        svg: (0, _jsxRuntime.jsx)(_atoms.Success, {})
      }), amendBookingStatus === _api.CONFIRM_AMEND_STATUS.error && (0, _jsxRuntime.jsx)(_atoms.Notification, {
        prefixDataTestId: "".concat(amendBaseTestId, "-error-notification"),
        title: errorTitle,
        description: errorDescription,
        status: "error",
        variant: "error",
        maxW: "full",
        svg: (0, _jsxRuntime.jsx)(_atoms.Error, {})
      })]
    }), (0, _jsxRuntime.jsx)(_BookingInfoCard.BookingInfoCard, {
      area: area,
      basketReference: basketReference,
      tempBookingReference: tempBookingReference,
      operaConfNumber: operaConfNumber,
      bookingReference: bookingReference,
      inputValues: inputValues,
      isAmendPage: isAmendPage,
      isAmendSuccessful: amendBookingStatus === _api.CONFIRM_AMEND_STATUS.success
    }), area === _api.Area.PI && !isLoggedIn && (0, _jsxRuntime.jsxs)(_react.Flex, _objectSpread(_objectSpread({}, signUpLogInWrapperStyles), {}, {
      children: [(0, _jsxRuntime.jsx)(_react.Link, {
        href: "".concat((0, _utils.getSecureTwoURL)(), "/").concat(country, "/").concat(language, "/account/register.html"),
        children: (0, _jsxRuntime.jsx)(_react.Button, _objectSpread(_objectSpread({
          "data-testid": "".concat(amendBaseTestId, "-SignUpButton")
        }, signUpButtonStyle), {}, {
          children: t('dashboard.bookings.signUpButtonLabel')
        }))
      }), (0, _jsxRuntime.jsxs)(_react.Flex, _objectSpread(_objectSpread({}, loginLinkWrapperStyles), {}, {
        children: [(0, _jsxRuntime.jsx)(_react.Text, {
          color: "var(--chakra-colors-darkGrey1)",
          children: t('dashboard.bookings.alreadyHaveAccount')
        }), (0, _jsxRuntime.jsx)(_react.Link, _objectSpread(_objectSpread({
          "data-testid": "".concat(amendBaseTestId, "-LogInLink")
        }, loginLinkStyles), {}, {
          onClick: toggleLoginModal,
          children: t('dashboard.bookings.login')
        }))]
      })), (0, _jsxRuntime.jsx)(_authentication.AuthContentManagerPIVariant, {
        isLoginModalOpen: isLoginModalOpen,
        toggleLoginModal: toggleLoginModal
      })]
    }))]
  }));
  function getAmendErrorMessage() {
    switch (confirmAmendErrorValue) {
      case _api.AMEND_DEPOSIT_FOLIOS_EXCEPTION_VALUE:
      case _api.AMEND_CONFIRM_EXCEPTION_VALUE:
        return {
          errorTitle: t('amend.errors.somethingwrong.noChanges.title'),
          errorDescription: t('amend.errors.somethingwrong.noChanges.description')
        };
      case _api.AMEND_REFUND_EXCEPTION_VALUE:
        return {
          errorTitle: t('amend.errors.somethingwrong.refundError.title'),
          errorDescription: t('amend.errors.somethingwrong.refundError.description')
        };
      case _api.AMEND_REVERT_EXCEPTION_VALUE:
        return {
          errorTitle: t('amend.errors.somethingwrong.paymentReverted.title'),
          errorDescription: t('amend.errors.somethingwrong.paymentReverted.description')
        };
      default:
        return {
          errorTitle: t('amend.errors.somethingwrong.title'),
          errorDescription: t('amend.errors.somethingwrong.description')
        };
    }
  }
}
var wrapperStyles = {
  backgroundColor: 'lightGrey5',
  p: {
    mobile: 'sm',
    sm: 'md',
    lg: 'lg'
  },
  mt: {
    mobile: 'xl',
    sm: '3xl ',
    lg: '5xl'
  },
  mb: {
    mobile: 'xmd',
    sm: 'md',
    md: 'lg',
    lg: 'xl',
    xl: '5xl'
  }
};
var signUpLogInWrapperStyles = {
  flexDirection: {
    mobile: 'column',
    sm: 'row'
  },
  alignItems: {
    mobile: 'center',
    sm: 'left'
  }
};
var signUpButtonStyle = {
  variant: 'tertiary',
  mt: '2xl',
  width: {
    sm: '14.25rem',
    xs: '17.3125rem',
    mobile: '14rem'
  },
  height: '2.5rem'
};
var loginLinkWrapperStyles = {
  mt: {
    mobile: 'md',
    sm: '2xl'
  },
  ml: {
    mobile: '0px',
    sm: 'lg'
  },
  alignItems: 'center',
  flexDirection: {
    mobile: 'column',
    xs: 'row'
  }
};
var loginLinkStyles = {
  display: 'block',
  textDecoration: 'underline',
  pl: 'xs',
  color: 'var(--chakra-colors-tertiary)'
};