"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = SEO;
var _api = require("@whitbread-eos/api");
var _utils = require("@whitbread-eos/utils");
var _head = _interopRequireDefault(require("next/head"));
var _jsxRuntime = require("react/jsx-runtime");
function SEO(_ref) {
  var _faq$faqItems;
  var {
    data,
    isLoading,
    isError,
    displayMeta
  } = _ref;
  var {
    [_api.FT_PI_HREFLANG_ATTRIBUTE]: isHreflangAttributeEnabled
  } = (0, _utils.useFeatureToggle)();
  if (isLoading || isError) {
    return null;
  }
  var {
    pageTitle,
    pageDescription,
    cardImageUrl,
    faviconUrl,
    icons,
    msIcons,
    faq,
    hreflangs
  } = data;
  return (0, _jsxRuntime.jsxs)(_head.default, {
    children: [(0, _jsxRuntime.jsx)("title", {
      children: pageTitle
    }), (0, _jsxRuntime.jsx)("meta", {
      name: "viewport",
      content: "width=device-width, initial-scale=1, user-scalable=no"
    }), displayMeta && (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
      children: [(0, _jsxRuntime.jsx)("meta", {
        name: "description",
        content: pageDescription
      }), (0, _jsxRuntime.jsx)("meta", {
        property: "twitter:card",
        content: "summary"
      }), (0, _jsxRuntime.jsx)("meta", {
        property: "twitter:url",
        content: getCurrentPageURL()
      }), (0, _jsxRuntime.jsx)("meta", {
        property: "twitter:title",
        content: pageTitle
      }), (0, _jsxRuntime.jsx)("meta", {
        property: "twitter:description",
        content: pageDescription
      }), (0, _jsxRuntime.jsx)("meta", {
        property: "twitter:image",
        content: cardImageUrl
      }), (0, _jsxRuntime.jsx)("meta", {
        property: "twitter:site",
        content: "Premier Inn"
      }), (0, _jsxRuntime.jsx)("meta", {
        property: "twitter:creator"
      }), (0, _jsxRuntime.jsx)("meta", {
        property: "og:type",
        content: "website"
      }), (0, _jsxRuntime.jsx)("meta", {
        property: "og:url",
        content: getCurrentPageURL()
      }), (0, _jsxRuntime.jsx)("meta", {
        property: "og:title",
        content: pageTitle
      }), (0, _jsxRuntime.jsx)("meta", {
        property: "og:description",
        content: pageDescription
      }), (0, _jsxRuntime.jsx)("meta", {
        property: "og:image",
        content: cardImageUrl
      }), (0, _jsxRuntime.jsx)("meta", {
        property: "og:site_name",
        content: "Premier Inn"
      }), (0, _jsxRuntime.jsx)("meta", {
        property: "fb:admins"
      }), (0, _jsxRuntime.jsx)("meta", {
        name: "msapplication-TileColor",
        content: "#552462"
      }), renderItems(msIcons, icon => (0, _jsxRuntime.jsx)("meta", {
        name: icon.name,
        content: icon.content
      }, icon.name)), isHreflangAttributeEnabled && renderItems(hreflangs, _ref2 => {
        var {
          hreflang,
          href
        } = _ref2;
        return (0, _jsxRuntime.jsx)("link", {
          rel: "alternate",
          hrefLang: hreflang,
          href: href
        }, hreflang);
      }), renderItems(icons, icon => (0, _jsxRuntime.jsx)("link", {
        rel: icon.rel,
        sizes: icon.sizes,
        href: (0, _utils.formatAssetsUrl)(icon.href)
      }, icon.href))]
    }), (0, _jsxRuntime.jsx)("link", {
      rel: "icon",
      type: "image/x-icon",
      href: (0, _utils.formatAssetsUrl)(faviconUrl)
    }), faq && (faq === null || faq === void 0 || (_faq$faqItems = faq.faqItems) === null || _faq$faqItems === void 0 ? void 0 : _faq$faqItems.length) > 0 && (0, _jsxRuntime.jsx)("script", {
      type: "application/ld+json",
      dangerouslySetInnerHTML: {
        __html: "{\"@context\":\"http://schema.org\",\"@type\":\"FAQPage\",\"mainEntity\":[".concat(faqFormatter(faq.faqItems), "]}")
      }
    })]
  });
  function faqFormatter(faqItems) {
    var _faqItems$map;
    return (_faqItems$map = faqItems.map(faqItem => {
      var _faqItem$answer;
      return "{\"@type\":\"Question\",\"name\":\"".concat(faqItem.question, "\",\"acceptedAnswer\":{\"@type\":\"Answer\",\"text\":\"").concat((_faqItem$answer = faqItem.answer) === null || _faqItem$answer === void 0 ? void 0 : _faqItem$answer.replace(/(\r\n|\n|\r)/gm, '').replace(/"/g, '\\"'), "\"}}");
    })) === null || _faqItems$map === void 0 ? void 0 : _faqItems$map.join(',');
  }
  function getCurrentPageURL() {
    var _window$location;
    return typeof window !== 'undefined' ? (_window$location = window.location) === null || _window$location === void 0 ? void 0 : _window$location.href : '';
  }
  function renderItems(items, callbackFn) {
    return items && (items === null || items === void 0 ? void 0 : items.length) > 0 && items.map(callbackFn);
  }
}