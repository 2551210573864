"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getLocalStorageMock = void 0;
var getLocalStorageMock = () => {
  var store = {};
  return {
    getItem: key => store[key] || null,
    setItem: (key, value) => {
      store[key] = value.toString();
    },
    removeItem: key => {
      delete store[key];
    },
    clear: () => {
      store = {};
    }
  };
};
exports.getLocalStorageMock = getLocalStorageMock;