"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = BackButton;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _react = require("@chakra-ui/react");
var _atoms = require("@whitbread-eos/atoms");
var _utils = require("@whitbread-eos/utils");
var _nextI18next = require("next-i18next");
var _react2 = require("react");
var _constants = require("../../utils/constants");
var _jsxRuntime = require("react/jsx-runtime");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function BackButton(_ref) {
  var _formField$props5;
  var {
    handleSetValue,
    handleResetField,
    getValues,
    reset,
    formField
  } = _ref;
  var {
    language: currentLang
  } = (0, _utils.useCustomLocale)();
  var {
    t
  } = (0, _nextI18next.useTranslation)();
  var [formDetails, setFormDetails] = (0, _utils.useLocalStorage)('formDetails', _constants.INITIAL_GUEST_DETAILS_FORM_DATA);
  var idTokenCookie = (0, _utils.getAuthCookie)();
  var resetFormFields = (basketReferenceId, defaultValues) => {
    localStorage.removeItem('formDetails');
    var defaultSetValues = {};
    for (var key in defaultValues) {
      if (defaultValues[key] !== '') {
        defaultSetValues[key] = defaultValues[key];
      }
    }
    setFormDetails(_objectSpread(_objectSpread({}, _constants.INITIAL_GUEST_DETAILS_FORM_DATA), {}, {
      basketReferenceId
    }, defaultSetValues));
    if (formDetails['title'] && formDetails['title'].length > 0 && handleSetValue && handleResetField) {
      handleSetValue('title', '');
      handleResetField('title', {
        defaultValue: ''
      });
    }
  };
  (0, _react2.useEffect)(() => {
    if (formDetails && handleSetValue) {
      if (!idTokenCookie) {
        var _formField$props;
        if (formDetails['basketReferenceId'] !== (formField === null || formField === void 0 || (_formField$props = formField.props) === null || _formField$props === void 0 ? void 0 : _formField$props.basketReferenceId)) {
          var _formField$props2, _formField$props3;
          resetFormFields(formField === null || formField === void 0 || (_formField$props2 = formField.props) === null || _formField$props2 === void 0 ? void 0 : _formField$props2.basketReferenceId, formField === null || formField === void 0 || (_formField$props3 = formField.props) === null || _formField$props3 === void 0 ? void 0 : _formField$props3.defaultValues);
        } else {
          triggerReset(formDetails);
        }
      } else {
        var _formField$props4;
        formDetails.updated && formDetails['basketReferenceId'] === (formField === null || formField === void 0 || (_formField$props4 = formField.props) === null || _formField$props4 === void 0 ? void 0 : _formField$props4.basketReferenceId) && triggerReset(formDetails);
      }
    }
  }, [formDetails, formField === null || formField === void 0 || (_formField$props5 = formField.props) === null || _formField$props5 === void 0 ? void 0 : _formField$props5.basketReferenceId, handleSetValue, reset]);
  var triggerReset = formDetails => {
    var _formDetails$postalCo;
    var resetObject = {};
    var shouldReset = false;
    for (var key of Object.keys(formDetails)) {
      if (!['basketReferenceId', 'updated'].includes(key)) {
        if (formDetails[key] !== '') {
          shouldReset = true;
        }
        resetObject[key] = formDetails[key];
      }
    }
    if (shouldReset) {
      reset === null || reset === void 0 || reset(resetObject);
    }
    if ((_formDetails$postalCo = formDetails.postalCode) !== null && _formDetails$postalCo !== void 0 && _formDetails$postalCo.length || currentLang === 'de') {
      handleSetValue === null || handleSetValue === void 0 || handleSetValue('manualAddressToggle', 'manualAddress');
    }
  };
  var handleClick = () => {
    var _formField$props7, _formField$props7$goB;
    var formData = getValues();
    setFormDetails(formDetails => {
      if (formData) {
        var _formField$props6;
        formDetails.addressLine1 = formData.addressLine1;
        formDetails.addressLine2 = formData.addressLine2;
        formDetails.addressLine3 = formData.addressLine3;
        formDetails.addressLine4 = formData.addressLine4;
        formDetails.postcodeAddress = formData.postcodeAddress;
        formDetails.addressSelection = formData.addressSelection;
        formDetails.cityName = formData.cityName;
        formDetails.companyName = formData.companyName;
        formDetails.countryCode = formData.countryCode;
        formDetails.email = formData.email;
        formDetails.firstName = formData.firstName;
        formDetails.landline = formData.landline;
        formDetails.lastName = formData.lastName;
        formDetails.manualAddressToggle = formData.manualAddressToggle;
        formDetails.phone = formData.phone;
        formDetails.postalCode = formData.postalCode;
        formDetails.reasonForStay = formData.reasonForStay;
        formDetails.title = formData.title;
        formDetails.bookingForSomeoneElse = formData.bookingForSomeoneElse;
        formDetails.leadGuest = formData.leadGuest;
        formDetails.basketReferenceId = formField === null || formField === void 0 || (_formField$props6 = formField.props) === null || _formField$props6 === void 0 ? void 0 : _formField$props6.basketReferenceId;
        formDetails.updated = true;
        return formDetails;
      }
    });
    formField === null || formField === void 0 || (_formField$props7 = formField.props) === null || _formField$props7 === void 0 || (_formField$props7$goB = _formField$props7.goBack) === null || _formField$props7$goB === void 0 || _formField$props7$goB.call(_formField$props7);
  };
  return (0, _jsxRuntime.jsxs)(_react.Flex, _objectSpread(_objectSpread({
    onClick: () => handleClick()
  }, buttonWrapper), {}, {
    "data-testid": (0, _utils.formatDataTestId)('GuestDetails', 'BackToAncillariesButton'),
    children: [(0, _jsxRuntime.jsx)(_atoms.Icon, {
      svg: (0, _jsxRuntime.jsx)(_atoms.ChevronLeft24, {})
    }), (0, _jsxRuntime.jsx)(_react.Text, _objectSpread(_objectSpread({}, textStyle), {}, {
      children: t('booking.summary.back')
    }))]
  }));
}
var buttonWrapper = {
  alignItems: 'center',
  w: 'fit-content',
  justifyContent: 'flex-start',
  cursor: 'pointer'
};
var textStyle = {
  pl: 'md',
  fontWeight: 'semibold',
  fontSize: 'md',
  color: 'darkGrey1',
  lineHeight: '3'
};