"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "FormInput", {
  enumerable: true,
  get: function get() {
    return _FormInput.FormInput;
  }
});
Object.defineProperty(exports, "FormPage", {
  enumerable: true,
  get: function get() {
    return _FormPage.FormPage;
  }
});
Object.defineProperty(exports, "FormPhone", {
  enumerable: true,
  get: function get() {
    return _FormPhone.FormPhone;
  }
});
Object.defineProperty(exports, "FormSelect", {
  enumerable: true,
  get: function get() {
    return _FormSelect.FormSelect;
  }
});
var _FormInput = require("./FormInput");
var _FormPage = require("./FormPage");
var _FormPhone = require("./FormPhone");
var _FormSelect = require("./FormSelect");