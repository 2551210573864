"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.StandardRoomType = void 0;
var StandardRoomType = exports.StandardRoomType = function (StandardRoomType) {
  StandardRoomType["DB"] = "Double";
  StandardRoomType["FAM"] = "Family";
  StandardRoomType["DIS"] = "Accessible";
  StandardRoomType["SB"] = "Single";
  StandardRoomType["TWIN"] = "Twin";
  return StandardRoomType;
}({});