"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = CardHolderName;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _react = require("@chakra-ui/react");
var _atoms = require("@whitbread-eos/atoms");
var _nextI18next = require("next-i18next");
var _react2 = require("react");
var _styles = require("../styles");
var _jsxRuntime = require("react/jsx-runtime");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function CardHolderName(_ref) {
  var {
    cardHolderNames,
    setCardHolderNames,
    setHasError
  } = _ref;
  var [errorFirstName, setErrorFirstName] = (0, _react2.useState)('');
  var [errorLastName, setErrorLastName] = (0, _react2.useState)('');
  var {
    t
  } = (0, _nextI18next.useTranslation)(['common']);
  return (0, _jsxRuntime.jsxs)(_react.Flex, {
    mb: '5xl',
    flexDir: "column",
    "data-testid": "cardHolderNameSection",
    mt: 16,
    children: [(0, _jsxRuntime.jsx)(_react.Box, {
      pb: 4,
      children: (0, _jsxRuntime.jsx)(_react.Heading, _objectSpread(_objectSpread({
        as: "h3"
      }, _styles.headerStyles), {}, {
        children: t('ccui.cardHolder.title')
      }))
    }), (0, _jsxRuntime.jsx)(_react.Box, {
      w: {
        mobile: 'full',
        xs: 'full',
        sm: '25.063rem',
        md: '26.25rem',
        lg: '24.5rem',
        xl: '26.25rem'
      },
      children: (0, _jsxRuntime.jsx)(_atoms.Input, {
        name: "cardHolderFirstName",
        value: cardHolderNames.firstName,
        onChange: onCardHolderFirstNameChange,
        placeholderText: t('ccui.cardHolderName.firstName.placeholder'),
        error: errorFirstName
      })
    }), (0, _jsxRuntime.jsx)(_react.Box, {
      pt: 6,
      w: {
        mobile: 'full',
        xs: 'full',
        sm: '25.063rem',
        md: '26.25rem',
        lg: '24.5rem',
        xl: '26.25rem'
      },
      children: (0, _jsxRuntime.jsx)(_atoms.Input, {
        name: "cardHolderLastName",
        value: cardHolderNames.lastName,
        onChange: onCardHolderLastNameChange,
        placeholderText: t('ccui.cardHolderName.lastName.placeholder'),
        error: errorLastName
      })
    })]
  });
  function onCardHolderFirstNameChange(firstName) {
    validateCardHolder(firstName, 20, t('ccui.cardHolderName.firstName.requiredMessage'), t('ccui.cardHolderName.firstName.invalidMessage'), t('details.userForm.firstNameRequired'), setErrorFirstName);
    setCardHolderNames(prevState => _objectSpread(_objectSpread({}, prevState), {}, {
      firstName
    }));
  }
  function onCardHolderLastNameChange(lastName) {
    validateCardHolder(lastName, 30, t('ccui.cardHolderName.lastName.requiredMessage'), t('ccui.cardHolderName.lastName.invalidMessage'), t('details.userForm.lastNameRequired'), setErrorLastName);
    setCardHolderNames(prevState => _objectSpread(_objectSpread({}, prevState), {}, {
      lastName
    }));
  }
  function validateCardHolder(name, lengthLimit, requiredMessage, invalidMessage, limitMessage, handleError) {
    if (name === '') {
      handleError(requiredMessage);
      setHasError(true);
    } else if (!/^[-A-Za-zÀ-ÖØ-ʒͰ-ͳͶ-ͷͻ-ͽvΑ-Ͽἀ-ῼЀ-ӿễấ ']+-?[\sA-Za-zÀ-ÖØ-ʒͰ-ͳͶ-ͷͻ-ͽvΑ-Ͽἀ-ῼЀ-ӿễấ ']*$/.test(name)) {
      handleError(invalidMessage);
      setHasError(true);
    } else if (name.length > lengthLimit) {
      handleError(limitMessage);
      setHasError(true);
    } else {
      handleError('');
      setHasError(false);
    }
  }
}