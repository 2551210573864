"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.tableStyle = exports.resultRowCellStyle = exports.resultContainerStyle = exports.loadMoreStyles = exports.linkStyles = exports.headerTableStyle = exports.headerResultsTextStyle = exports.cardWrapperStyle = exports.cardRowStyle = void 0;
var resultRowCellStyle = isExpanded => {
  return {
    backgroundColor: isExpanded ? 'lightGrey5' : 'transparent',
    borderBottom: isExpanded ? 'none' : 'var(--chakra-borders-1px)',
    borderColor: 'var(--chakra-colors-lightGrey2)',
    whiteSpace: 'normal',
    fontSize: {
      lg: 'lg',
      md: 'md',
      mobile: 'sm'
    },
    lineHeight: {
      sm: '3',
      mobile: '2'
    },
    padding: {
      xl: 'var(--chakra-space-12) var(--chakra-space-5) var(--chakra-space-6)',
      lg: 'var(--chakra-space-12) var(--chakra-space-4) var(--chakra-space-6)',
      md: 'var(--chakra-space-12) var(--chakra-space-4) var(--chakra-space-6)',
      sm: 'var(--chakra-space-12) var(--chakra-space-1) var(--chakra-space-6)',
      xs: 'var(--chakra-space-8) var(--chakra-space-1) var(--chakra-space-6)',
      mobile: 'var(--chakra-space-10) var(--chakra-space-1) var(--chakra-space-6)'
    },
    verticalAlign: 'top'
  };
};
exports.resultRowCellStyle = resultRowCellStyle;
var resultContainerStyle = exports.resultContainerStyle = {
  mt: '3xl'
};
var tableStyle = isFixedLayout => {
  return {
    tableLayout: isFixedLayout ? 'fixed' : 'auto'
  };
};
exports.tableStyle = tableStyle;
var headerTableStyle = exports.headerTableStyle = {
  backgroundColor: 'lightGrey5'
};
var headerResultsTextStyle = exports.headerResultsTextStyle = {
  fontWeight: '500',
  fontSize: {
    lg: 'lg',
    md: 'md',
    mobile: 'sm'
  },
  lineHeight: {
    sm: '3',
    mobile: '2'
  },
  color: 'baseBlack',
  textTransform: 'none',
  fontFamily: 'header',
  letterSpacing: 'initial',
  padding: {
    xl: 'var(--chakra-space-10) var(--chakra-space-5) var(--chakra-space-4)',
    lg: 'var(--chakra-space-6) var(--chakra-space-4) var(--chakra-space-4)',
    md: 'var(--chakra-space-6) var(--chakra-space-4) var(--chakra-space-4)',
    sm: 'var(--chakra-space-6) var(--chakra-space-1) var(--chakra-space-4)',
    xs: 'var(--chakra-space-6) var(--chakra-space-1) var(--chakra-space-4)',
    mobile: 'var(--chakra-space-6) var(--chakra-space-1) var(--chakra-space-4)'
  },
  whiteSpace: 'normal',
  borderBottom: 'var(--chakra-borders-1px)',
  borderColor: 'var(--chakra-colors-lightGrey2)',
  verticalAlign: 'top'
};
var linkStyles = exports.linkStyles = {
  display: 'flex',
  alignItems: 'center',
  color: 'btnSecondaryEnabled',
  textDecoration: 'underline',
  fontSize: {
    md: 'md',
    mobile: 'sm'
  }
};
var loadMoreStyles = exports.loadMoreStyles = {
  w: '260px',
  margin: {
    xl: 'var(--chakra-space-12) auto var(--chakra-space-8)',
    lg: 'var(--chakra-space-12) auto var(--chakra-space-8)',
    md: 'var(--chakra-space-12) auto var(--chakra-space-8)',
    sm: 'var(--chakra-space-12) auto var(--chakra-space-8)',
    xs: 'var(--chakra-space-8) auto var(--chakra-space-8)',
    mobile: 'var(--chakra-space-8) auto var(--chakra-space-8)'
  }
};
var cardWrapperStyle = exports.cardWrapperStyle = {
  backgroundColor: 'white',
  padding: 'var(--chakra-space-8)'
};
var cardRowStyle = exports.cardRowStyle = {
  backgroundColor: 'lightGrey5',
  padding: {
    xl: 'var(--chakra-space-6) var(--chakra-space-5)',
    lg: 'var(--chakra-space-6) var(--chakra-space-4)',
    md: 'var(--chakra-space-6) var(--chakra-space-4)',
    mobile: 'var(--chakra-space-6) var(--chakra-space-1)'
  }
};