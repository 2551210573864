"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.DEFAULT_NUMBER_OF_NIGHTS = exports.DEFAULT_MULTI_SEARCH_ROOM = exports.DEFAULT_BOOKINGCHANNEL = void 0;
var DEFAULT_NUMBER_OF_NIGHTS = exports.DEFAULT_NUMBER_OF_NIGHTS = 1;
var DEFAULT_BOOKINGCHANNEL = exports.DEFAULT_BOOKINGCHANNEL = 'WEB';
var DEFAULT_MULTI_SEARCH_ROOM = exports.DEFAULT_MULTI_SEARCH_ROOM = {
  adultsNumber: 1,
  childrenNumber: 0,
  type: 'DB'
};