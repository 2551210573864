"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getFindBookingToken = exports.cleanupFindBookingToken = void 0;
var _api = require("@whitbread-eos/api");
var _cookies = require("../helpers/cookies");
var _validators = require("../validators");
var getFindBookingToken = () => {
  if (typeof window !== 'undefined') {
    var _window$localStorage$;
    var cookieName = (_window$localStorage$ = window.localStorage.getItem(_api.FIND_BOOKING_COOKIE_NAME_KEY)) !== null && _window$localStorage$ !== void 0 ? _window$localStorage$ : '';
    if ((0, _validators.isStringValid)(cookieName)) {
      var cookieTokenValue = (0, _cookies.getCookie)(cookieName);
      if (cookieTokenValue) {
        try {
          var cookieValue = JSON.parse(window.atob(cookieTokenValue));
          return {
            token: cookieValue.token,
            basketReference: cookieValue.basketReference,
            bookingReference: cookieValue.bookingReference,
            operaConfNumber: cookieValue.operaConfNumber
          };
        } catch (e) {
          return {};
        }
      }
    }
  }
  return {};
};
exports.getFindBookingToken = getFindBookingToken;
var cleanupFindBookingToken = () => {
  if (typeof window !== 'undefined') {
    var _window$localStorage$2;
    var cookieName = (_window$localStorage$2 = window.localStorage.getItem(_api.FIND_BOOKING_COOKIE_NAME_KEY)) !== null && _window$localStorage$2 !== void 0 ? _window$localStorage$2 : '';
    (0, _cookies.deleteCookie)(cookieName);
    window.localStorage.removeItem(_api.FIND_BOOKING_COOKIE_NAME_KEY);
  }
};
exports.cleanupFindBookingToken = cleanupFindBookingToken;