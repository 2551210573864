"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.DISPATCH_ACTION = void 0;
var DISPATCH_ACTION = exports.DISPATCH_ACTION = function (DISPATCH_ACTION) {
  DISPATCH_ACTION["LOADING_STATUS"] = "setLoadingStatus";
  DISPATCH_ACTION["RESET_OPTIONS"] = "resetOptions";
  DISPATCH_ACTION["SET_BRAINTREE_INSTANCE"] = "braintreeInstance";
  return DISPATCH_ACTION;
}({});