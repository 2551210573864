"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.inputStyles = exports.inputStyle = exports.inputIconStyles = exports.headingStyles = exports.handleRedirect = exports.handleNationalityChange = exports.handleIframeHeight = exports.handleAccordionToggle = exports.gridStyles = exports.getPaymentParams = exports.getNationality = exports.getGuestDetailsObject = exports.getFormattedDate = exports.generateDependentsData = exports.fieldProps = exports.dropdownStyles = exports.dependencyFields = exports.dataErrorText = exports.countryDropdownStyle = exports.contentPadding = exports.boxStyle = exports.bookingDetailsFields = exports.bookDetailboxStyle = exports.bookDetailGridStyles = exports.addScrollEvent = exports.accordionOverwriteStyles = exports.GuestDetailKeys = void 0;
exports.isCanvasEmpty = isCanvasEmpty;
exports.wrapperStyles = exports.wraperBoxStyle = exports.tomorrowLabel = exports.todayLabel = exports.subHeadingStyles = exports.showPassportField = exports.showDependencyPassportField = exports.searchBookingGridStyles = exports.scrollIntoViewOnError = exports.renderDropdownStyles = exports.personalDetailsFields = exports.onIframeLoad = exports.modalContentStyles = exports.modalButtonsStyles = exports.modalButtonsContainerStyles = exports.labelStyle = void 0;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _utils = require("@whitbread-eos/utils");
var _dateFns = require("date-fns");
var _uuid = require("uuid");
var _constants = require("../utils/constants");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
var GERMAN = 'de';
var fieldProps = field => {
  return {
    name: field.name,
    onBlur: field.onBlur,
    value: field.value,
    onChange: value => {
      field.onChange(value);
    }
  };
};
exports.fieldProps = fieldProps;
var boxStyle = exports.boxStyle = {
  px: 'sm',
  py: 'sm',
  pos: 'relative',
  mt: 'xl',
  mb: 'md'
};
var labelStyle = exports.labelStyle = {
  h: '1.25rem',
  w: 'fit-content',
  fontSize: 'xl',
  align: 'center',
  px: 'xs',
  fontWeight: 'normal',
  ml: '0.750rem',
  top: '-0.625rem',
  color: 'darkGrey1',
  backgroundColor: 'baseWhite',
  zIndex: '1',
  lineHeight: '1',
  pos: 'absolute'
};
var gridStyles = exports.gridStyles = {
  w: {
    base: '100%',
    md: '50%'
  },
  flex: '0 0 auto',
  alignItems: 'center'
};
var inputStyle = exports.inputStyle = {
  height: 'var(--chakra-space-4xl)',
  _placeholder: {
    opacity: 0.7,
    color: 'var(--chakra-darkGrey1)'
  },
  borderColor: 'lightGrey1',
  borderRadius: 'var(--chakra-radii-md)'
};
var countryDropdownStyle = exports.countryDropdownStyle = {
  menuButtonStyles: {
    borderColor: 'lightGrey1'
  }
};
var inputIconStyles = exports.inputIconStyles = {
  top: 'var(--chakra-space-sm)'
};
var renderDropdownStyles = hasError => {
  return {
    border: '1px solid',
    borderColor: hasError ? 'var(--chakra-colors-error)' : 'lightGrey1',
    borderRadius: 'var(--chakra-radii-md)'
  };
};
exports.renderDropdownStyles = renderDropdownStyles;
var dropdownStyles = exports.dropdownStyles = {
  menuButtonStyles: {
    borderColor: 'var(--chakra-colors-lightGrey1)'
  },
  menuListStyles: {
    py: 0,
    maxHeight: '12.5rem'
  }
};
var dataErrorText = exports.dataErrorText = {
  alignItems: 'center',
  color: 'var(--chakra-colors-error)',
  fontSize: 'var(--chakra-fontSizes-xs)',
  marginLeft: '15px'
};
var inputStyles = exports.inputStyles = {
  variant: 'unstyled',
  textOverflow: 'ellipsis',
  border: 'none',
  cursor: 'pointer',
  minH: '40px',
  _focus: {
    border: 'none'
  },
  _placeholder: {
    color: 'var(--chakra-colors-darkGrey1)'
  }
};
var wrapperStyles = exports.wrapperStyles = {
  minH: '56px',
  px: '4',
  border: '1px solid',
  borderColor: 'lightGrey1',
  borderRadius: 'md'
};
var todayLabel = exports.todayLabel = 'Today';
var tomorrowLabel = exports.tomorrowLabel = 'Tomorrow';
var contentPadding = exports.contentPadding = {
  base: '0',
  md: 'sm'
};
var personalDetailsFields = exports.personalDetailsFields = [{
  type: 'input',
  name: 'firstName',
  label: 'firstname',
  testId: 'FirstName'
}, {
  type: 'input',
  name: 'lastName',
  label: 'lastname',
  testId: 'Surname',
  disabled: true
}, {
  type: 'input',
  name: 'address',
  label: 'address',
  testId: 'Address'
}, {
  type: 'input',
  name: 'city',
  label: 'city',
  testId: 'City'
}, {
  type: 'input',
  name: 'postalCode',
  label: 'postalcode',
  testId: 'PostalCode'
}, {
  type: 'countryDropdown',
  name: 'country',
  label: 'country',
  testId: 'Country'
}, {
  type: 'datePicker',
  name: 'dateOfBirth',
  label: 'dateofbirth',
  testId: 'DateOfBirth'
}, {
  type: 'autoComplete',
  name: 'nationality',
  label: 'nationality',
  testId: 'Nationality'
}, {
  type: 'input',
  name: 'passport',
  label: 'mandatoryPassport',
  testId: 'Passport'
}];
var showPassportField = (values, field) => {
  var _values$field, _values$field2;
  return ((_values$field = values[field]) === null || _values$field === void 0 ? void 0 : _values$field.value) && ((_values$field2 = values[field]) === null || _values$field2 === void 0 ? void 0 : _values$field2.value) !== 'DE';
};
exports.showPassportField = showPassportField;
var showDependencyPassportField = (values, index) => {
  var _values$dependents, _values$dependents$in;
  return ((_values$dependents = values.dependents) === null || _values$dependents === void 0 ? void 0 : _values$dependents[index]) && isExist((_values$dependents$in = values.dependents[index]) === null || _values$dependents$in === void 0 ? void 0 : _values$dependents$in.nationality);
};
exports.showDependencyPassportField = showDependencyPassportField;
var isExist = fields => {
  var {
    value
  } = fields || {};
  if (value === undefined) return true;
  return value && (value === null || value === void 0 ? void 0 : value.toLowerCase()) === GERMAN;
};
var modalContentStyles = exports.modalContentStyles = {
  direction: 'column',
  alignItems: 'center',
  textAlign: 'center',
  mt: 'lg',
  mb: '2xl',
  px: {
    base: '1.5rem',
    xs: '1rem',
    sm: '1.06rem',
    lg: '1.5rem',
    xl: '1.53rem'
  }
};
var headingStyles = exports.headingStyles = {
  fontFamily: 'header',
  fontSize: 'xl',
  fontWeight: 'semibold',
  lineHeight: '3',
  mb: 'lg',
  color: 'darkGrey1'
};
var subHeadingStyles = exports.subHeadingStyles = {
  fontSize: 'lg',
  fontWeight: 'regular',
  lineHeight: '3',
  mb: 'lg',
  color: 'darkGrey2'
};
var modalButtonsContainerStyles = exports.modalButtonsContainerStyles = {
  w: '100%',
  flexDirection: {
    base: 'column',
    sm: 'row'
  },
  justifyContent: 'center',
  alignItems: 'center'
};
var modalButtonsStyles = exports.modalButtonsStyles = {
  w: {
    base: 'full',
    xs: '21.43rem',
    sm: '16.18rem',
    md: '15.93rem',
    lg: '16.5rem',
    xl: '17.87rem'
  },
  h: '3.5rem',
  borderRadius: '.25rem',
  p: 'md'
};
var bookingDetailsFields = exports.bookingDetailsFields = [{
  type: 'input',
  name: 'bookingNumber',
  label: 'bookingnumber',
  testId: 'bookingNumber',
  styles: {
    pr: contentPadding,
    mb: 'md'
  }
}, {
  type: 'input',
  name: 'surname',
  label: 'surname',
  testId: 'surname',
  styles: {
    pl: contentPadding,
    mb: 'md'
  }
}, {
  type: 'datePicker',
  name: 'arrivalDate',
  label: 'arrivaldate',
  testId: 'ArrivalDate',
  styles: {
    pl: contentPadding,
    mb: 'md'
  }
}];
var bookDetailGridStyles = exports.bookDetailGridStyles = {
  w: {
    base: '100%',
    md: '31.1875em'
  },
  flex: '0 0 auto',
  alignItems: 'left',
  pr: {
    base: '0',
    md: '0'
  },
  m: '0',
  p: '0',
  mt: 'xl',
  _first: {
    mt: '3xl'
  },
  _last: {
    mb: '0',
    pb: '0'
  },
  borderRadius: 0
};
var searchBookingGridStyles = exports.searchBookingGridStyles = _objectSpread(_objectSpread({}, bookDetailGridStyles), {}, {
  mt: '0',
  _first: {
    mt: '0'
  },
  mb: 'xl'
});
var bookDetailboxStyle = exports.bookDetailboxStyle = {
  pos: 'relative',
  w: {
    base: '100%',
    md: '70.625em'
  }
};
var handleAccordionToggle = (accordionIndex, setAccordionIndex) => {
  setAccordionIndex(Number(!accordionIndex));
};
exports.handleAccordionToggle = handleAccordionToggle;
var accordionOverwriteStyles = exports.accordionOverwriteStyles = {
  container: {
    w: {
      base: '100%',
      md: '100%'
    },
    px: 'xl',
    py: 'xl',
    borderWidth: '1px',
    borderColor: 'lightGrey1',
    borderRadius: '4px',
    mb: 'xl',
    _first: {
      mb: '3xl',
      mt: '0'
    },
    index: [0],
    allowToggle: true
  },
  panel: {
    padding: 0,
    border: 0
  },
  button: {
    borderWidth: 0,
    borderBottom: 0,
    borderTop: 0,
    fontSize: 'xl',
    fontWeight: 'semibold',
    color: 'baseBlack',
    marginBottom: '0',
    padding: '0',
    margin: '0'
  },
  item: {
    border: 0
  },
  text: {
    m: 0,
    p: 0
  }
};
var dependencyFields = exports.dependencyFields = [{
  type: 'input',
  name: 'firstname',
  label: 'firstname',
  testId: 'FirstName'
}, {
  type: 'input',
  name: 'lastname',
  label: 'lastname',
  testId: 'Surname'
}, {
  type: 'datePicker',
  name: 'dateofbirth',
  label: 'dateofbirth',
  testId: 'dateofbirth'
}, {
  type: 'autoComplete',
  name: 'nationality',
  label: 'nationality',
  testId: 'Nationality'
}, {
  type: 'input',
  name: 'passport',
  label: 'mandatoryPassport',
  testId: 'Passport'
}];
var wraperBoxStyle = exports.wraperBoxStyle = {
  px: 'sm',
  py: 'sm'
};
var getNationality = (field, nationalities) => {
  return nationalities.find(nationality => (nationality === null || nationality === void 0 ? void 0 : nationality.value) === (field === null || field === void 0 ? void 0 : field.value));
};
exports.getNationality = getNationality;
var scrollIntoViewOnError = () => {
  setTimeout(() => {
    var _document;
    var allErrorElements = (_document = document) === null || _document === void 0 ? void 0 : _document.querySelectorAll('[class*="error"], [style*="error"]');
    var errorDiv = allErrorElements[0];
    errorDiv === null || errorDiv === void 0 || errorDiv.scrollIntoView({
      behavior: 'smooth',
      block: 'center'
    });
  }, 300);
};
exports.scrollIntoViewOnError = scrollIntoViewOnError;
var addScrollEvent = () => {
  var reviewBtn = document.getElementById('reg-form-submit-btn');
  var saveBtn = document.getElementById('reg-form-save-btn');
  if (reviewBtn) reviewBtn === null || reviewBtn === void 0 || reviewBtn.addEventListener('click', () => {
    scrollIntoViewOnError();
  });
  if (saveBtn) saveBtn === null || saveBtn === void 0 || saveBtn.addEventListener('click', () => scrollIntoViewOnError());
};
exports.addScrollEvent = addScrollEvent;
var handleNationalityChange = (fieldProp, field, fieldToReset, handleSetValue) => {
  field.onChange(fieldProp);
  handleSetValue && handleSetValue(fieldToReset, '');
};
exports.handleNationalityChange = handleNationalityChange;
var getPaymentParams = _ref => {
  var {
    billingAddress,
    bookingConfirmation,
    language,
    country,
    copyBasketReference
  } = _ref;
  if (bookingConfirmation) {
    var {
      hotelId
    } = bookingConfirmation;
    var billingObj = _objectSpread(_objectSpread({}, bookingConfirmation.reservationByIdList[0].billing), {}, {
      landline: undefined,
      address: (0, _utils.getBillingAddress)({
        countryRouter: country,
        isBillingAddressDisplayed: false,
        billingAddress,
        billing: bookingConfirmation.reservationByIdList[0].billing
      }),
      differentBillingAddress: false,
      bookerIsNotGuest: false
    });
    var card;
    var rooms = bookingConfirmation.reservationByIdList.map(room => {
      var _room$roomStay;
      return {
        adultsNumber: room === null || room === void 0 || (_room$roomStay = room.roomStay) === null || _room$roomStay === void 0 ? void 0 : _room$roomStay.adultsNumber,
        rate: 'FLEXRATE',
        type: 'DOUBLE'
      };
    });
    var bookingRequest = {
      businessSite: {
        identifier: hotelId,
        name: bookingConfirmation.hotelName,
        type: 'HOTEL',
        location: hotelId
      },
      channel: 'PI',
      journey: 'BOOKING',
      language,
      rooms,
      arrivalDate: bookingConfirmation.reservationByIdList[0].roomStay.arrivalDate,
      departureDate: bookingConfirmation.reservationByIdList[0].roomStay.departureDate,
      type: 'PAY_ON_ARRIVAL'
    };
    var businessItemsRequest = {};
    var paymentRequest = _objectSpread({
      billing: billingObj,
      card,
      environment: window.location.origin,
      subType: 'ECOMM',
      type: 'CARD',
      pibaCardPresent: true
    }, businessItemsRequest);
    var createPaymentCriteria = {
      booking: bookingRequest,
      payment: paymentRequest,
      hotelId: hotelId,
      requestId: (0, _uuid.v4)(),
      tmpBasketRef: "".concat(copyBasketReference, "_preCheckIn")
    };
    return _objectSpread({}, createPaymentCriteria);
  }
};
exports.getPaymentParams = getPaymentParams;
var getGuestDetailsObject = (key, value, t) => {
  var detailsMap = {
    [GuestDetailKeys.firstName]: value,
    [GuestDetailKeys.lastName]: value,
    [GuestDetailKeys.passport]: value,
    [GuestDetailKeys.dateOfBirth]: getFormattedDate(value),
    [GuestDetailKeys.nationality]: value === null || value === void 0 ? void 0 : value.label
  };
  var label = '';
  switch (key) {
    case 'dateofbirth':
      label = t("precheckin.additionalfields.".concat(key));
      break;
    case 'passport':
      label = t("precheckin.details.".concat(key));
      break;
    case 'nationality':
      label = t('precheckin.additionalfields.nationalities');
      break;
    default:
      label = t("precheckin.regcard.".concat(key));
  }
  return {
    key: label,
    value: detailsMap[key] || ''
  };
};
exports.getGuestDetailsObject = getGuestDetailsObject;
var GuestDetailKeys = exports.GuestDetailKeys = function (GuestDetailKeys) {
  GuestDetailKeys["firstName"] = "firstname";
  GuestDetailKeys["lastName"] = "lastname";
  GuestDetailKeys["passport"] = "passport";
  GuestDetailKeys["dateOfBirth"] = "dateofbirth";
  GuestDetailKeys["nationality"] = "nationality";
  return GuestDetailKeys;
}({});
var getFormattedDate = date => {
  return date && (0, _dateFns.isDate)(date) ? (0, _dateFns.format)(date, _constants.DATE_FORMAT) : '';
};
exports.getFormattedDate = getFormattedDate;
var generateDependentsData = (nationalities, t, dependents) => {
  var reorderedData = dependents === null || dependents === void 0 ? void 0 : dependents.map(dependent => {
    var _dependent$nationalit;
    if (dependent !== null && dependent !== void 0 && (_dependent$nationalit = dependent.nationality) !== null && _dependent$nationalit !== void 0 && _dependent$nationalit.value && dependent.nationality.label === '') {
      var _nationality = nationalities.find(_ref2 => {
        var {
          value
        } = _ref2;
        return value === dependent.nationality.value;
      });
      if (_nationality) {
        dependent.nationality.label = _nationality.label;
      }
    }
    var {
      firstname,
      lastname,
      dateofbirth,
      nationality,
      passport
    } = dependent;
    return {
      firstname,
      lastname,
      dateofbirth,
      nationality,
      passport
    };
  });
  var dependentGuests = reorderedData || [];
  return dependentGuests.map((guest, index) => {
    var guestDetails = Object.entries(guest).filter(_ref3 => {
      var [, value] = _ref3;
      return value;
    }).map(_ref4 => {
      var [key, value] = _ref4;
      return getGuestDetailsObject(key, value, t);
    });
    return guestDetails.length ? {
      title: "".concat(t('precheckin.guest'), " ").concat(index + 1),
      rows: guestDetails
    } : null;
  }).filter(Boolean);
};
exports.generateDependentsData = generateDependentsData;
var onIframeLoad = time => {
  _utils.analytics.update({
    paymentLoadTime: time
  });
};
exports.onIframeLoad = onIframeLoad;
function isCanvasEmpty(canvas) {
  var context = canvas.getContext('2d');
  if (context) {
    var imageData = context.getImageData(0, 0, canvas.width, canvas.height);
    var data = imageData.data;
    for (var i = 0; i < data.length; i += 4) {
      if (data[i + 3] !== 0) return false;
    }
    return true;
  }
}
var handleIframeHeight = isSuccess => {
  if (isSuccess) {
    var _document2;
    var iframe = (_document2 = document) === null || _document2 === void 0 ? void 0 : _document2.getElementById('paymentFrame');
    if (iframe) {
      iframe.addEventListener('load', () => {
        var scrollHeight = iframe.scrollHeight;
        iframe.style.height = "".concat(scrollHeight + scrollHeight / 2 - 60, "px");
      });
    }
  }
};
exports.handleIframeHeight = handleIframeHeight;
var handleRedirect = (path, push) => push(path);
exports.handleRedirect = handleRedirect;