"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = BartBookingDetailsRoomInformationComponent;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _react = require("@chakra-ui/react");
var _utils = require("@whitbread-eos/utils");
var _react2 = require("react");
var _jsxRuntime = require("react/jsx-runtime");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function BartBookingDetailsRoomInformationComponent(_ref) {
  var {
    roomNumber,
    firstName,
    lastName,
    roomName,
    noAdults,
    noKids,
    price,
    currency,
    language,
    t
  } = _ref;
  var getAdultsPluralLabel = (0, _react2.useCallback)(value => value === 1 ? t('account.dashboard.adult') : t('account.dashboard.adults'), [t]);
  var getChildrenPluralLabel = (0, _react2.useCallback)(value => value === 1 ? t('booking.mealChoose.child') : t('account.dashboard.children'), [t]);
  return (0, _jsxRuntime.jsxs)(_react.Flex, {
    direction: "column",
    children: [(0, _jsxRuntime.jsx)(_react.Flex, {
      children: (0, _jsxRuntime.jsxs)(_react.Text, _objectSpread(_objectSpread({}, roomNumberStyle), {}, {
        children: [t('booking.summary.room'), " ", roomNumber, (0, _jsxRuntime.jsx)(_react.Text, {
          as: "span",
          fontWeight: "normal",
          children: " ".concat(firstName, " ").concat(lastName)
        })]
      }))
    }), (0, _jsxRuntime.jsxs)(_react.Flex, {
      justifyContent: "space-between",
      mb: "lg",
      children: [(0, _jsxRuntime.jsxs)(_react.Text, _objectSpread(_objectSpread({}, roomDetailsStyle), {}, {
        children: [roomName, noAdults > 0 && (0, _jsxRuntime.jsx)(_react.Text, {
          as: "span",
          children: " - ".concat(noAdults, " ").concat(getAdultsPluralLabel(noAdults))
        }), noKids > 0 && (0, _jsxRuntime.jsx)(_react.Text, {
          as: "span",
          children: " - ".concat(noKids, " ").concat(getChildrenPluralLabel(noKids))
        })]
      })), (0, _jsxRuntime.jsx)(_react.Text, _objectSpread(_objectSpread({}, priceStyle), {}, {
        children: (0, _utils.formatPrice)((0, _utils.formatCurrency)(currency), price, language)
      }))]
    })]
  });
}
var priceStyle = {
  fontWeight: 'medium',
  fontSize: 'md',
  color: 'darkGrey2',
  lineHeight: '3'
};
var roomDetailsStyle = {
  color: 'darkGrey2',
  fontSize: 'md',
  lineHeight: '3',
  fontWeight: 'normal',
  w: 'full'
};
var roomNumberStyle = {
  fontWeight: 'semibold',
  fontSize: 'md',
  lineHeight: '3',
  color: 'darkGrey1',
  as: 'span'
};