"use strict";
'use client';

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.TabsTrigger = exports.TabsList = exports.TabsContent = exports.Tabs = exports.StaticTabsTrigger = void 0;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _objectWithoutProperties2 = _interopRequireDefault(require("@babel/runtime/helpers/objectWithoutProperties"));
var TabsPrimitive = _interopRequireWildcard(require("@radix-ui/react-tabs"));
var _server = require("@whitbread-eos/utils/server");
var React = _interopRequireWildcard(require("react"));
var _jsxRuntime = require("react/jsx-runtime");
var _excluded = ["className"],
  _excluded2 = ["className"],
  _excluded3 = ["className"];
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && {}.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
var Tabs = exports.Tabs = TabsPrimitive.Root;
var TabsList = exports.TabsList = React.forwardRef((_ref, ref) => {
  var {
      className
    } = _ref,
    props = (0, _objectWithoutProperties2.default)(_ref, _excluded);
  return (0, _jsxRuntime.jsx)(TabsPrimitive.List, _objectSpread({
    ref: ref,
    className: (0, _server.cn)('inline-flex items-center justify-center', className)
  }, props));
});
TabsList.displayName = TabsPrimitive.List.displayName;
var tabsTriggerClassName = (0, _server.cn)('inline-flex items-center justify-center whitespace-nowrap text-base font-semibold', 'disabled:pointer-events-none disabled:opacity-50', 'min-w-[12.5rem] h-[3.125rem] border-b-2 border-lightGrey4', 'data-[state=active]:border-primaryColor');
var TabsTrigger = exports.TabsTrigger = React.forwardRef((_ref2, ref) => {
  var {
      className
    } = _ref2,
    props = (0, _objectWithoutProperties2.default)(_ref2, _excluded2);
  return (0, _jsxRuntime.jsx)(TabsPrimitive.Trigger, _objectSpread({
    ref: ref,
    className: (0, _server.cn)(tabsTriggerClassName, className)
  }, props));
});
TabsTrigger.displayName = TabsPrimitive.Trigger.displayName;
var StaticTabsTrigger = _ref3 => {
  var {
    children,
    className,
    active = false
  } = _ref3;
  return (0, _jsxRuntime.jsx)("span", {
    className: (0, _server.cn)(tabsTriggerClassName, className),
    "data-state": active ? 'active' : '',
    children: children
  });
};
exports.StaticTabsTrigger = StaticTabsTrigger;
var TabsContent = exports.TabsContent = React.forwardRef((_ref4, ref) => {
  var {
      className
    } = _ref4,
    props = (0, _objectWithoutProperties2.default)(_ref4, _excluded3);
  return (0, _jsxRuntime.jsx)(TabsPrimitive.Content, _objectSpread({
    ref: ref,
    className: (0, _server.cn)('mt-2', className)
  }, props));
});
TabsContent.displayName = TabsPrimitive.Content.displayName;