"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = useHotelBrands;
var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));
var _api = require("@whitbread-eos/api");
var _router = require("next/router");
var _react = require("react");
var _selectors = require("../selectors");
var _services = require("../services");
var _validators = require("../validators");
var _useCustomLocale = _interopRequireDefault(require("./use-custom-locale"));
var _useRequest = require("./use-request");
var BLACKLIST_ROUTES = ['/repeat-booking'];
function useHotelBrands(_ref) {
  var {
    basketReference,
    channel,
    queryClient
  } = _ref;
  var {
    country,
    language
  } = (0, _useCustomLocale.default)();
  var {
    route
  } = (0, _router.useRouter)() || {
    query: {},
    route: ''
  };
  var activePage = route === null || route === void 0 ? void 0 : route.split('/')[channel === 'BB' ? 3 : 1];
  var [brand, setBrand] = (0, _react.useState)(null);
  var [stepProgress, setStepProgress] = (0, _react.useState)({
    activeStep: 0,
    steps: []
  });
  var isBlacklistedRoute = BLACKLIST_ROUTES.some(blacklistedElem => blacklistedElem === route);
  (0, _react.useEffect)(() => {
    if ((0, _validators.isStringValid)(basketReference) && !isBlacklistedRoute) {
      getBookingFlowInformation(basketReference, channel);
    }
  }, [basketReference]);
  (0, _react.useEffect)(() => {
    var _stepProgress$steps;
    if ((stepProgress === null || stepProgress === void 0 || (_stepProgress$steps = stepProgress.steps) === null || _stepProgress$steps === void 0 ? void 0 : _stepProgress$steps.length) > 0) {
      _services.analytics.update({
        bookingStepsTotal: stepProgress.steps.length,
        bookingCurrentStep: stepProgress.activeStep
      });
    }
  }, [stepProgress]);
  var getBookingFlowInformation = (0, _react.useCallback)(function () {
    var _ref2 = (0, _asyncToGenerator2.default)(function* (basketReference, channel) {
      var biQueryInput = {
        basketReference: basketReference,
        country,
        language,
        bookingChannelCriteria: {
          channel: channel,
          subchannel: 'WEB',
          language: language === 'en' ? 'EN' : 'DE'
        }
      };
      var bookingInformationQuery = queryClient.fetchQuery({
        queryKey: ['GetBookingInformation', biQueryInput.language, biQueryInput.country, biQueryInput.basketReference],
        queryFn: () => (0, _useRequest.graphQLRequest)(_api.GET_HEADER_BOOKING_INFORMATION, biQueryInput)
      });
      yield bookingInformationQuery.then(function () {
        var _ref3 = (0, _asyncToGenerator2.default)(function* (result) {
          var bookingInfo = result === null || result === void 0 ? void 0 : result.bookingInformation;
          var {
            bookingFlowId = '',
            hotelId = ''
          } = bookingInfo !== null && bookingInfo !== void 0 ? bookingInfo : {};
          var bookingFlowInformationRequest = queryClient.fetchQuery({
            queryKey: ['GetBookingFlowInformation', bookingFlowId, hotelId, language, country],
            queryFn: () => (0, _useRequest.graphQLRequest)(_api.GET_HEADER_BOOKING_FLOW_INFORMATION, {
              bookingFlowId,
              hotelId,
              language,
              country
            })
          });
          yield bookingFlowInformationRequest.then(bfiDataResp => {
            var {
              bookingFlowSteps,
              brand
            } = (bfiDataResp === null || bfiDataResp === void 0 ? void 0 : bfiDataResp.bookingFlowInformation) || {
              bookingFlowSteps: [{}]
            };
            setBrand(brand);
            setStepProgress((0, _selectors.enhanceCheckoutStepList)(bookingFlowSteps, activePage));
          });
        });
        return function (_x3) {
          return _ref3.apply(this, arguments);
        };
      }());
    });
    return function (_x, _x2) {
      return _ref2.apply(this, arguments);
    };
  }(), []);
  return {
    brand,
    stepProgress
  };
}