"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = ChooseRoomContinueBtnContainer;
var _api = require("@whitbread-eos/api");
var _utils = require("@whitbread-eos/utils");
var _ChooseRoomContinueBtn = _interopRequireDefault(require("./ChooseRoomContinueBtn.component"));
var _jsxRuntime = require("react/jsx-runtime");
function ChooseRoomContinueBtnContainer(_ref) {
  var _basketDetailsState$s;
  var {
    dataTestId,
    selectedPMSRoomTypes,
    selectedSpecialRequests,
    bookRsvIsLoading,
    bookRsvIsError,
    bookRsvError,
    handleBooking,
    channel,
    isDisabledContinueBtn
  } = _ref;
  var {
    language
  } = (0, _utils.useCustomLocale)();
  var [basketDetailsState] = (0, _utils.useLocalStorage)(_api.BASKET_DETAILS_STORAGE_KEY, _api.BASKET_DETAILS_STATE_INITIAL_VALUE);
  if (!basketDetailsState.hotelId) {
    return null;
  }
  var {
    hotelId,
    arrival,
    departure,
    numberOfNights
  } = basketDetailsState;
  var ratePlanCode = setSelectedRatePlanCode();
  var reservations = (_basketDetailsState$s = basketDetailsState.selectedRate) === null || _basketDetailsState$s === void 0 || (_basketDetailsState$s = _basketDetailsState$s.roomTypes) === null || _basketDetailsState$s === void 0 ? void 0 : _basketDetailsState$s.map((roomType, roomTypeIndex) => {
    var _roomType$rooms, _selectedRoom$roomPri, _selectedRoom$roomPri2, _selectedRoom$roomPri3, _selectedRoom$roomPri4;
    var selectedRoom = roomType === null || roomType === void 0 || (_roomType$rooms = roomType.rooms) === null || _roomType$rooms === void 0 ? void 0 : _roomType$rooms.find(room => (room === null || room === void 0 ? void 0 : room.pmsRoomType) === (selectedPMSRoomTypes === null || selectedPMSRoomTypes === void 0 ? void 0 : selectedPMSRoomTypes[roomTypeIndex]));
    return {
      hotelId,
      arrival,
      departure,
      adultsNumber: roomType === null || roomType === void 0 ? void 0 : roomType.adults,
      childrenNumber: roomType === null || roomType === void 0 ? void 0 : roomType.children,
      cotRequired: selectedRoom === null || selectedRoom === void 0 ? void 0 : selectedRoom.cotAvailable,
      roomRates: {
        ratePlanCode,
        pmsRoomType: selectedPMSRoomTypes === null || selectedPMSRoomTypes === void 0 ? void 0 : selectedPMSRoomTypes[roomTypeIndex],
        specialRequests: selectedSpecialRequests === null || selectedSpecialRequests === void 0 ? void 0 : selectedSpecialRequests[roomTypeIndex],
        startDate: arrival,
        endDate: departure
      },
      reservationPackages: [{
        startDate: arrival,
        endDate: departure,
        quantity: numberOfNights,
        packageCode: (_selectedRoom$roomPri = selectedRoom === null || selectedRoom === void 0 || (_selectedRoom$roomPri2 = selectedRoom.roomPriceBreakdown) === null || _selectedRoom$roomPri2 === void 0 ? void 0 : _selectedRoom$roomPri2.packageCode) !== null && _selectedRoom$roomPri !== void 0 ? _selectedRoom$roomPri : '',
        unitPrice: (_selectedRoom$roomPri3 = selectedRoom === null || selectedRoom === void 0 || (_selectedRoom$roomPri4 = selectedRoom.roomPriceBreakdown) === null || _selectedRoom$roomPri4 === void 0 ? void 0 : _selectedRoom$roomPri4.packageAmount) !== null && _selectedRoom$roomPri3 !== void 0 ? _selectedRoom$roomPri3 : 0
      }]
    };
  });
  return (0, _jsxRuntime.jsx)(_ChooseRoomContinueBtn.default, {
    dataTestId,
    onBookReservation,
    bookRsvIsLoading,
    bookRsvIsError,
    bookRsvError,
    isDisabledContinueBtn
  });
  function onBookReservation() {
    var bookingChannel = {
      channel,
      subchannel: 'WEB',
      language: language === null || language === void 0 ? void 0 : language.toUpperCase()
    };
    handleBooking(reservations, bookingChannel);
  }
  function setSelectedRatePlanCode() {
    var _basketDetailsState$s2, _basketDetailsState$s3;
    if (basketDetailsState.selectedRate && ((_basketDetailsState$s2 = basketDetailsState.selectedRate) === null || _basketDetailsState$s2 === void 0 ? void 0 : _basketDetailsState$s2.cellCode) === _api.OfferEnum.EMPLOYEE) {
      return _api.OfferEnum.EMPLOYEE_RATE_CODE;
    }
    return (_basketDetailsState$s3 = basketDetailsState.selectedRate) === null || _basketDetailsState$s3 === void 0 ? void 0 : _basketDetailsState$s3.ratePlanCode;
  }
}