"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = PhoneSelector;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _api = require("@whitbread-eos/api");
var _atoms = require("@whitbread-eos/atoms");
var _utils = require("@whitbread-eos/utils");
var _react = require("react");
var _reactPhoneNumberInput = require("react-phone-number-input");
var _jsxRuntime = require("react/jsx-runtime");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function PhoneSelector(_ref) {
  var _formField$props, _defaultValues$curren, _defaultValues$curren2, _formField$props2, _formField$testid, _formField$props4, _errors$formField$nam, _formField$props5, _formField$props6, _formField$props7;
  var {
    formField,
    field,
    errors,
    getValues,
    handleTriggerValidation
  } = _ref;
  var [countries, setCountries] = (0, _react.useState)();
  var currentLang = ((_formField$props = formField.props) === null || _formField$props === void 0 ? void 0 : _formField$props.currentLang) || 'en';
  var country = currentLang && currentLang === 'en' ? 'gb' : 'de';
  var defaultValues = {
    en: {
      dialingCode: '+44',
      countryCode: 'GB'
    },
    de: {
      dialingCode: '+49',
      countryCode: 'DE'
    }
  };
  var [phoneNumber, setPhoneNumber] = (0, _react.useState)({
    countryCode: (_defaultValues$curren = defaultValues[currentLang]) === null || _defaultValues$curren === void 0 ? void 0 : _defaultValues$curren.countryCode,
    dialingCode: (_defaultValues$curren2 = defaultValues[currentLang]) === null || _defaultValues$curren2 === void 0 ? void 0 : _defaultValues$curren2.dialingCode,
    phone: field.value
  });
  var isBusinessBkng = (formField === null || formField === void 0 || (_formField$props2 = formField.props) === null || _formField$props2 === void 0 ? void 0 : _formField$props2.isBusinessBkng) || false;
  var site = isBusinessBkng ? 'business-booker' : 'leisure';
  var testId = (_formField$testid = formField.testid) !== null && _formField$testid !== void 0 ? _formField$testid : 'PhoneSelector';
  var {
    data: countriesData,
    isSuccess: countriesRequestSuccess
  } = (0, _utils.useQueryRequest)(['GetCountries', country, currentLang, site], _api.GET_COUNTRIES, {
    country: country,
    language: currentLang,
    site: 'leisure'
  });
  var findCountry = (countries, countryCode) => {
    var _countries$find, _countries$find2;
    return (_countries$find = countries === null || countries === void 0 || (_countries$find2 = countries.find) === null || _countries$find2 === void 0 ? void 0 : _countries$find2.call(countries, country => {
      return country.countryCode === countryCode;
    })) !== null && _countries$find !== void 0 ? _countries$find : {};
  };
  (0, _react.useEffect)(() => {
    var _field$value, _defaultValues$curren3;
    var userPhoneNumber = (_field$value = field.value) !== null && _field$value !== void 0 && _field$value.includes('+') ? field.value : ((_defaultValues$curren3 = defaultValues[currentLang]) === null || _defaultValues$curren3 === void 0 ? void 0 : _defaultValues$curren3.dialingCode) + field.value;
    var parsedNumber = (0, _reactPhoneNumberInput.parsePhoneNumber)(userPhoneNumber);
    var countryCallingCode = "+".concat(parsedNumber === null || parsedNumber === void 0 ? void 0 : parsedNumber.countryCallingCode);
    if (!(0, _reactPhoneNumberInput.isValidPhoneNumber)(userPhoneNumber)) {
      if (parsedNumber !== null && parsedNumber !== void 0 && parsedNumber.nationalNumber) {
        var _parsedNumber$country;
        setPhoneNumber({
          countryCode: (_parsedNumber$country = parsedNumber.country) !== null && _parsedNumber$country !== void 0 ? _parsedNumber$country : phoneNumber.countryCode,
          dialingCode: countryCallingCode !== null && countryCallingCode !== void 0 ? countryCallingCode : phoneNumber.dialingCode,
          phone: parsedNumber === null || parsedNumber === void 0 ? void 0 : parsedNumber.nationalNumber
        });
      }
      return;
    }
    if (parsedNumber !== null && parsedNumber !== void 0 && parsedNumber.country) {
      setPhoneNumber({
        countryCode: parsedNumber.country,
        dialingCode: countryCallingCode,
        phone: parsedNumber.nationalNumber
      });
    }
  }, [field.value]);
  (0, _react.useEffect)(() => {
    if (countriesRequestSuccess) {
      var _countriesData$countr;
      setCountries((countriesData === null || countriesData === void 0 || (_countriesData$countr = countriesData.countries) === null || _countriesData$countr === void 0 ? void 0 : _countriesData$countr.countries) || []);
      setPhoneNumber(latest => {
        if (latest.phone) {
          var _latest$countryCode;
          var {
            countryCode,
            dialingCode
          } = findCountry(countriesData.countries.countries, (_latest$countryCode = latest.countryCode) !== null && _latest$countryCode !== void 0 ? _latest$countryCode : getValues('countryCode'));
          return _objectSpread(_objectSpread({}, latest), {}, {
            countryCode: countryCode !== null && countryCode !== void 0 ? countryCode : latest.countryCode,
            dialingCode: dialingCode !== null && dialingCode !== void 0 ? dialingCode : latest.dialingCode
          });
        }
        return latest;
      });
    }
  }, [countriesRequestSuccess, countriesData]);
  (0, _react.useEffect)(() => {
    var _field$value2;
    if (phoneNumber.phone !== field.value && (_field$value2 = field.value) !== null && _field$value2 !== void 0 && _field$value2.includes('userProfile')) {
      var _countriesData$countr2;
      var {
        countryCode,
        dialingCode
      } = findCountry(countriesData === null || countriesData === void 0 || (_countriesData$countr2 = countriesData.countries) === null || _countriesData$countr2 === void 0 ? void 0 : _countriesData$countr2.countries, getValues('countryCode'));
      setPhoneNumber({
        phone: field.value.replace('userProfile', ''),
        countryCode: countryCode !== null && countryCode !== void 0 ? countryCode : phoneNumber.countryCode,
        dialingCode: dialingCode !== null && dialingCode !== void 0 ? dialingCode : phoneNumber.dialingCode
      });
    }
  }, [field]);
  (0, _react.useEffect)(() => {
    var _formField$props3;
    if ((_formField$props3 = formField.props) !== null && _formField$props3 !== void 0 && _formField$props3.clearField) {
      var _defaultValues$curren4, _defaultValues$curren5;
      setPhoneNumber({
        countryCode: (_defaultValues$curren4 = defaultValues[currentLang]) === null || _defaultValues$curren4 === void 0 ? void 0 : _defaultValues$curren4.countryCode,
        dialingCode: (_defaultValues$curren5 = defaultValues[currentLang]) === null || _defaultValues$curren5 === void 0 ? void 0 : _defaultValues$curren5.dialingCode,
        phone: ''
      });
    }
  }, [(_formField$props4 = formField.props) === null || _formField$props4 === void 0 ? void 0 : _formField$props4.clearField]);
  var onChange = phoneValue => {
    var _field$onChange;
    var value = "".concat(phoneValue.dialingCode).concat(phoneValue.phone);
    setPhoneNumber(phoneValue);
    if ((phoneValue === null || phoneValue === void 0 ? void 0 : phoneValue.phone.length) === 0) {
      return field.onChange('');
    }
    (_field$onChange = field.onChange) === null || _field$onChange === void 0 || _field$onChange.call(field, value);
  };
  return (0, _jsxRuntime.jsx)(_atoms.PhoneInput, {
    value: phoneNumber,
    label: formField.label,
    name: formField.name,
    dataTestId: testId,
    onChange: onChange,
    onBlur: field === null || field === void 0 ? void 0 : field.onBlur,
    handleTriggerValidation: handleTriggerValidation,
    dependantOn: formField === null || formField === void 0 ? void 0 : formField.dependantOn,
    countries: countries,
    formatAssetsUrl: _utils.formatAssetsUrl,
    placeholder: formField.label,
    currentLang: currentLang,
    error: errors === null || errors === void 0 || (_errors$formField$nam = errors[formField.name]) === null || _errors$formField$nam === void 0 ? void 0 : _errors$formField$nam.message,
    showIcon: formField === null || formField === void 0 || (_formField$props5 = formField.props) === null || _formField$props5 === void 0 ? void 0 : _formField$props5.showIcon,
    inputProps: {
      className: formField === null || formField === void 0 || (_formField$props6 = formField.props) === null || _formField$props6 === void 0 ? void 0 : _formField$props6.className
    },
    useTooltip: formField === null || formField === void 0 || (_formField$props7 = formField.props) === null || _formField$props7 === void 0 ? void 0 : _formField$props7.useTooltip
  });
}