"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.BASKET_STATUS = void 0;
var BASKET_STATUS = exports.BASKET_STATUS = function (BASKET_STATUS) {
  BASKET_STATUS["FAILED"] = "FAILED";
  BASKET_STATUS["COMPLETED"] = "COMPLETED";
  BASKET_STATUS["PROCESSING"] = "PROCESSING";
  BASKET_STATUS["OPEN"] = "OPEN";
  BASKET_STATUS["CANCELLED"] = "CANCELLED";
  BASKET_STATUS["PAY_PENDING"] = "PAY_PENDING";
  BASKET_STATUS["AMENDING"] = "AMENDING";
  BASKET_STATUS["AMENDED"] = "AMENDED";
  BASKET_STATUS["AMEND_FAILED"] = "AMEND_FAILED";
  BASKET_STATUS["PAYMENT_REQUIRED"] = "PAYMENT_REQUIRED";
  BASKET_STATUS["NOT_REQUIRED"] = "NOT_REQUIRED";
  return BASKET_STATUS;
}({});