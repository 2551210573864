"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = ColorModeSwitcher;
var _icons = require("@chakra-ui/icons");
var _react = require("@chakra-ui/react");
var _jsxRuntime = require("react/jsx-runtime");
function ColorModeSwitcher() {
  var {
    toggleColorMode
  } = (0, _react.useColorMode)();
  var text = (0, _react.useColorModeValue)('dark', 'light');
  var SwitchIcon = (0, _react.useColorModeValue)(_icons.MoonIcon, _icons.SunIcon);
  return (0, _jsxRuntime.jsx)(_react.IconButton, {
    "aria-label": "Switch to ".concat(text, " mode"),
    size: "md",
    fontSize: "lg",
    variant: "ghost",
    color: "current",
    marginLeft: "2",
    onClick: toggleColorMode,
    icon: (0, _jsxRuntime.jsx)(SwitchIcon, {})
  });
}