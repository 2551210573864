"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = HotelRoomsQueryWrapper;
var _reactQuery = require("@tanstack/react-query");
var _HotelRooms = _interopRequireDefault(require("./HotelRooms.container"));
var _jsxRuntime = require("react/jsx-runtime");
function HotelRoomsQueryWrapper(_ref) {
  var {
    queryClient,
    isPremierInn,
    isLessThanSm,
    isLessThanMd,
    isLessThanLg,
    arrival,
    departure,
    isDisplayRates
  } = _ref;
  return (0, _jsxRuntime.jsx)(_reactQuery.QueryClientProvider, {
    client: queryClient,
    children: (0, _jsxRuntime.jsx)(_HotelRooms.default, {
      isPremierInn,
      isLessThanSm,
      isLessThanMd,
      isLessThanLg,
      arrival,
      departure,
      isDisplayRates
    })
  });
}