"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
var _AppDataContext = require("./AppDataContext");
Object.keys(_AppDataContext).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _AppDataContext[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _AppDataContext[key];
    }
  });
});
var _AgentMemoContext = require("./AgentMemoContext");
Object.keys(_AgentMemoContext).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _AgentMemoContext[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _AgentMemoContext[key];
    }
  });
});
var _UserContext = require("./UserContext");
Object.keys(_UserContext).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _UserContext[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _UserContext[key];
    }
  });
});
var _FeatureToggleContext = require("./FeatureToggleContext");
Object.keys(_FeatureToggleContext).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _FeatureToggleContext[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _FeatureToggleContext[key];
    }
  });
});