"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = HotelParkingInformationContainer;
var _utils = require("@whitbread-eos/utils");
var _HotelParkingInformation = _interopRequireDefault(require("./HotelParkingInformation.component"));
var _jsxRuntime = require("react/jsx-runtime");
function HotelParkingInformationContainer() {
  var {
    parkingDescription,
    isLoading,
    isError,
    error
  } = (0, _utils.useStaticHotelInformation)();
  return (0, _jsxRuntime.jsx)(_HotelParkingInformation.default, {
    data: parkingDescription,
    isLoading,
    isError,
    error
  });
}