"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = ConsentNotificationModalComponent;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _react = require("@chakra-ui/react");
var _atoms = require("@whitbread-eos/atoms");
var _utils = require("@whitbread-eos/utils");
var _nextI18next = require("next-i18next");
var _ConsentNotificationModal = require("./ConsentNotificationModal.style");
var _jsxRuntime = require("react/jsx-runtime");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function ConsentNotificationModalComponent(_ref) {
  var {
    isModalVisible,
    onConsentModalClose,
    onConsentModalAllow,
    onConsentModalDeny
  } = _ref;
  var {
    t
  } = (0, _nextI18next.useTranslation)();
  var baseTestId = 'pi-notification-permission-popup';
  var prefix = baseTestId ? "".concat(baseTestId, "-") : '';
  function renderModalContent() {
    return (0, _jsxRuntime.jsxs)(_react.Flex, _objectSpread(_objectSpread({
      "data-testid": (0, _utils.formatDataTestId)(baseTestId, 'container')
    }, _ConsentNotificationModal.wrapperStyles), {}, {
      children: [(0, _jsxRuntime.jsx)(_react.Text, _objectSpread(_objectSpread({
        "data-testid": (0, _utils.formatDataTestId)(baseTestId, 'description')
      }, _ConsentNotificationModal.descriptionStyle), {}, {
        sx: {
          a: {
            fontWeight: 'var(--chakra-fontWeights-medium)',
            textDecoration: 'underline'
          }
        },
        children: (0, _utils.renderSanitizedHtml)(t('pushNotifications.popup.description'))
      })), (0, _jsxRuntime.jsxs)(_react.Flex, _objectSpread(_objectSpread({}, _ConsentNotificationModal.buttonsStyle), {}, {
        children: [(0, _jsxRuntime.jsx)(_atoms.Button, {
          "data-testid": (0, _utils.formatDataTestId)(baseTestId, 'allow-btn'),
          size: "sm",
          width: "186px",
          variant: "secondary",
          mr: "md",
          onClick: onConsentModalAllow,
          children: t('pushNotifications.popup.allow')
        }), (0, _jsxRuntime.jsx)(_atoms.Button, _objectSpread(_objectSpread({
          "data-testid": (0, _utils.formatDataTestId)(baseTestId, 'deny-btn'),
          size: "sm",
          width: "188px",
          variant: "tertiary",
          onClick: onConsentModalDeny
        }, _ConsentNotificationModal.closeButtonStyle), {}, {
          children: t('pushNotifications.popup.dontAllow')
        }))]
      }))]
    }));
  }
  return (0, _jsxRuntime.jsx)(_react.Modal, {
    "data-testid": "".concat(prefix, "chakra-modal"),
    scrollBehavior: "inside",
    isCentered: false,
    isOpen: isModalVisible,
    onClose: onConsentModalClose,
    closeOnOverlayClick: false,
    blockScrollOnMount: false,
    trapFocus: false,
    children: (0, _jsxRuntime.jsxs)(_react.ModalContent, _objectSpread(_objectSpread({
      "data-testid": "".concat(prefix, "modal-content")
    }, _ConsentNotificationModal.modalContentStyle), {}, {
      width: {
        mobile: '90%',
        sm: '27.5rem'
      },
      containerProps: {
        width: {
          mobile: '100%',
          sm: '0rem'
        },
        height: {
          mobile: '0rem'
        }
      },
      children: [(0, _jsxRuntime.jsx)(_react.ModalHeader, {
        "data-testid": "".concat(prefix, "modal-header"),
        width: '100%',
        children: (0, _jsxRuntime.jsxs)(_react.Flex, {
          flexDir: "column",
          children: [(0, _jsxRuntime.jsxs)(_react.Grid, _objectSpread(_objectSpread({}, _ConsentNotificationModal.headerContentStyle), {}, {
            children: [(0, _jsxRuntime.jsxs)(_react.Flex, _objectSpread(_objectSpread({}, _ConsentNotificationModal.headerTitleStyle), {}, {
              "data-testid": "".concat(prefix, "modal-title"),
              children: [(0, _jsxRuntime.jsx)(_atoms.Icon, {
                svg: (0, _jsxRuntime.jsx)(_atoms.Bell, {}),
                mr: "sm"
              }), t('pushNotifications.popup.title')]
            })), (0, _jsxRuntime.jsx)(_react.ModalCloseButton, _objectSpread(_objectSpread({
              "data-testid": "".concat(prefix, "close-btn")
            }, _ConsentNotificationModal.modalCloseButtonStyle), {}, {
              children: (0, _jsxRuntime.jsx)(_atoms.Icon, {
                svg: (0, _jsxRuntime.jsx)(_atoms.DismissDarkGrey, {
                  transform: "scale(1.1)"
                })
              })
            }))]
          })), (0, _jsxRuntime.jsx)(_react.Box, _objectSpread({
            "data-testid": "".concat(prefix, "delimiter")
          }, _ConsentNotificationModal.delimiterModalStyle))]
        })
      }), (0, _jsxRuntime.jsx)(_react.ModalBody, _objectSpread(_objectSpread({
        "data-testid": "".concat(prefix, "modal-body")
      }, _ConsentNotificationModal.modalBodyStyle), {}, {
        children: (0, _jsxRuntime.jsx)(_react.Box, _objectSpread(_objectSpread({
          overflowX: "hidden"
        }, _ConsentNotificationModal.modalBodyBoxStyle), {}, {
          children: renderModalContent()
        }))
      }))]
    }))
  });
}