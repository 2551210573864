"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.updateAccountFormConfig = void 0;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _api = require("@whitbread-eos/api");
var _atoms = require("@whitbread-eos/atoms");
var _utils = require("@whitbread-eos/utils");
var _PhoneSelector = _interopRequireDefault(require("../../../guest-details/PhoneSelector"));
var _validateUpdateAccountForm = _interopRequireDefault(require("./validateUpdateAccountForm"));
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
var updateAccountFormConfig = _ref => {
  var {
    getFormState,
    defaultValues,
    onSubmit,
    onGuestVerify,
    onUnlock,
    onResetPassword,
    onReuseDetails,
    baseDataTestId,
    t,
    language,
    fieldsetDisabled,
    reservationId
  } = _ref;
  var isSaveAccountEnabled = (0, _utils.useFeatureSwitch)({
    featureSwitchKey: _api.FS_ENABLE_SEARCH_ACCOUNT_SAVE_CHANGES
  });
  var {
    formValidationSchema
  } = (0, _validateUpdateAccountForm.default)(t, defaultValues);
  var fields = [{
    type: _atoms.FORM_FIELD_TYPES.INPUT_TEXT,
    id: 'firstName',
    name: 'firstName',
    label: t('ccui.guestAccounts.firstName'),
    testid: (0, _utils.formatDataTestId)(baseDataTestId, 'firstName'),
    styles: _objectSpread({}, inputStyle)
  }, {
    type: _atoms.FORM_FIELD_TYPES.INPUT_TEXT,
    id: 'lastName',
    name: 'lastName',
    label: t('ccui.guestAccounts.surname'),
    testid: (0, _utils.formatDataTestId)(baseDataTestId, 'lastName'),
    styles: _objectSpread({}, inputStyle)
  }, {
    type: _atoms.FORM_FIELD_TYPES.INPUT_TEXT,
    id: 'companyName',
    name: 'companyName',
    label: t('ccui.guestAccounts.companyName'),
    testid: (0, _utils.formatDataTestId)(baseDataTestId, 'companyName'),
    styles: _objectSpread({}, inputStyle)
  }, {
    type: _atoms.FORM_FIELD_TYPES.INPUT_TEXT,
    id: 'email',
    name: 'email',
    label: t('ccui.guestAccounts.emailAddress'),
    testid: (0, _utils.formatDataTestId)(baseDataTestId, 'email'),
    styles: _objectSpread({}, inputStyle)
  }, {
    type: _atoms.FORM_FIELD_TYPES.INPUT_TEXT,
    id: 'address',
    name: 'address',
    label: t('ccui.guestAccounts.address'),
    testid: (0, _utils.formatDataTestId)(baseDataTestId, 'address'),
    styles: _objectSpread({}, inputStyle)
  }, {
    type: _atoms.FORM_FIELD_TYPES.INPUT_TEXT,
    id: 'postalCode',
    name: 'postalCode',
    label: t('ccui.guestAccounts.postCode'),
    testid: (0, _utils.formatDataTestId)(baseDataTestId, 'postalCode'),
    styles: _objectSpread({}, inputStyle)
  }, {
    type: _atoms.FORM_FIELD_TYPES.DYNAMIC_FIELD,
    name: 'mobileNumber',
    id: 'mobileNumber',
    label: t('ccui.guestAccounts.mobile'),
    Component: _PhoneSelector.default,
    styles: _objectSpread(_objectSpread({}, inputStyle), inputDynamicStyle),
    props: {
      showIcon: false,
      currentLang: language,
      className: 'sessioncamhidetext'
    },
    testid: (0, _utils.formatDataTestId)(baseDataTestId, 'mobileNumber')
  }, {
    type: _atoms.FORM_FIELD_TYPES.DYNAMIC_FIELD,
    id: 'landlineNumber',
    name: 'landlineNumber',
    label: t('ccui.guestAccounts.landline'),
    Component: _PhoneSelector.default,
    styles: _objectSpread(_objectSpread({}, inputStyle), inputDynamicStyle),
    props: {
      showIcon: false,
      currentLang: language,
      className: 'sessioncamhidetext'
    },
    testid: (0, _utils.formatDataTestId)(baseDataTestId, 'landlineNumber')
  }];
  var commonButtons = [{
    type: _atoms.FORM_BUTTON_TYPES.SUBMIT,
    label: t('ccui.guestAccounts.cta.saveChanges'),
    action: !isSaveAccountEnabled ? null : onSubmit,
    props: {
      variant: 'primary',
      size: 'full',
      isDisabled: !isSaveAccountEnabled
    },
    testid: (0, _utils.formatDataTestId)(baseDataTestId, 'Save-Changes-Btn')
  }, {
    type: _atoms.FORM_BUTTON_TYPES.BUTTON,
    label: t('ccui.guestAccounts.cta.unlockAccount'),
    action: onUnlock,
    props: {
      variant: 'primary',
      size: 'full',
      isDisabled: true
    },
    testid: (0, _utils.formatDataTestId)(baseDataTestId, 'Unlock-Account-Btn')
  }, {
    type: _atoms.FORM_BUTTON_TYPES.BUTTON,
    label: t('ccui.guestAccounts.cta.resetPassword'),
    action: onResetPassword,
    props: {
      variant: 'primary',
      size: 'full',
      isDisabled: true
    },
    testid: (0, _utils.formatDataTestId)(baseDataTestId, 'Reset-Password-Btn')
  }];
  var buttons = fieldsetDisabled ? [{
    type: _atoms.FORM_BUTTON_TYPES.BUTTON,
    label: t('ccui.guestAccounts.cta.guestVerified'),
    action: onGuestVerify,
    props: {
      variant: 'primary',
      size: 'full'
    },
    testid: (0, _utils.formatDataTestId)(baseDataTestId, 'Guest-Verified-Btn')
  }] : reservationId ? [...commonButtons, {
    type: _atoms.FORM_BUTTON_TYPES.BUTTON,
    label: t('ccui.manageBooking.reuseDetails'),
    action: onReuseDetails,
    props: {
      variant: 'tertiary',
      size: 'full'
    },
    testid: (0, _utils.formatDataTestId)(baseDataTestId, 'Reuse-Details-Btn')
  }] : commonButtons;
  var config = {
    id: 'updateAccountForm',
    elements: {
      fieldsContainerStyles,
      buttonsContainerStyles,
      fields,
      buttons
    },
    defaultValues,
    validationSchema: formValidationSchema,
    getFormState,
    fieldsetDisabled
  };
  return config;
};
exports.updateAccountFormConfig = updateAccountFormConfig;
var fieldsContainerStyles = {
  display: 'grid',
  gridTemplateColumns: '1fr 1fr 1fr',
  gridTemplateRow: 'auto',
  justifyItems: 'stretch',
  columnGap: 'lg',
  rowGap: 'sm',
  height: 'auto',
  mb: 'md'
};
var buttonsContainerStyles = {
  display: 'grid',
  gridTemplateColumns: '1fr 1fr 1fr',
  gridTemplateRow: 'auto',
  justifyItems: 'stretch',
  columnGap: 'lg',
  rowGap: 'sm',
  height: 'auto',
  mb: 'md'
};
var inputStyle = {
  sx: {
    '& input': {
      backgroundColor: 'var(--chakra-colors-white)'
    }
  },
  w: {
    lg: '24.5rem',
    xl: '26.25rem'
  },
  p: 0
};
var inputDynamicStyle = {
  sx: {
    '& input': {
      backgroundColor: 'var(--chakra-colors-white)',
      borderColor: 'var(--chakra-colors-lightGrey1)',
      '&::placeholder': {
        color: 'var(--chakra-colors-darkGrey2) '
      }
    },
    '& label': {
      color: 'var(--chakra-colors-darkGrey2) '
    }
  }
};