"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.BOOKING_SUBCHANNEL = void 0;
var BOOKING_SUBCHANNEL = exports.BOOKING_SUBCHANNEL = function (BOOKING_SUBCHANNEL) {
  BOOKING_SUBCHANNEL["CBT"] = "CBT";
  BOOKING_SUBCHANNEL["MOBILE"] = "MOBILE";
  BOOKING_SUBCHANNEL["WEB"] = "WEB";
  BOOKING_SUBCHANNEL["WEB_DE"] = "WEB_DE";
  return BOOKING_SUBCHANNEL;
}({});