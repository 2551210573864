"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = Carousel;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _react = require("@chakra-ui/react");
var _react2 = require("react");
var _reactSlick = _interopRequireDefault(require("react-slick"));
var _icons = require("../../assets/icons");
var _CarouselGlobalStyles = _interopRequireDefault(require("../../theme/components/CarouselGlobalStyles"));
var _Icon = _interopRequireDefault(require("../Icon/Icon.component"));
var _jsxRuntime = require("react/jsx-runtime");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function Carousel(_ref) {
  var {
    children,
    activeSlide,
    setActiveSlide
  } = _ref;
  var slidesCount = children === null || children === void 0 ? void 0 : children.length;
  var prevRef = (0, _react2.useRef)(null);
  var nextRef = (0, _react2.useRef)(null);
  var sliderRef = (0, _react2.useRef)(null);
  (0, _react2.useEffect)(() => {
    if (activeSlide >= slidesCount - 1) {
      prevRef.current && prevRef.current.focus();
      focusSlider(sliderRef);
    }
    if (activeSlide <= 0) {
      nextRef.current && nextRef.current.focus();
      focusSlider(sliderRef);
    }
  }, [activeSlide, slidesCount]);
  (0, _react2.useEffect)(() => {
    focusSlider(sliderRef);
  }, []);
  return (0, _jsxRuntime.jsxs)(_react.Box, {
    "data-testid": "carousel-".concat(activeSlide + 1, "/").concat(slidesCount),
    sx: {
      '& .slick-list div': {
        '&:focus, a': {
          outline: 'none'
        }
      }
    },
    children: [(0, _jsxRuntime.jsx)(_CarouselGlobalStyles.default, {}), (0, _jsxRuntime.jsx)(_reactSlick.default, {
      infinite: true,
      initialSlide: activeSlide,
      speed: 300,
      afterChange: index => setActiveSlide(index),
      ref: sliderRef,
      nextArrow: activeSlide < slidesCount - 1 ? (0, _jsxRuntime.jsx)(ForwardedCustomNextArrow, {
        ref: nextRef
      }) : (0, _jsxRuntime.jsx)(NoArrow, {}),
      prevArrow: activeSlide > 0 ? (0, _jsxRuntime.jsx)(ForwardedCustomPrevArrow, {
        ref: prevRef
      }) : (0, _jsxRuntime.jsx)(NoArrow, {}),
      children: children
    })]
  });
}
var navButtonStyles = {
  borderRadius: 'full',
  border: '2px solid var(--chakra-colors-baseWhite)',
  h: '2.813rem',
  w: '2.813rem',
  opacity: '75%',
  background: 'darkGrey2',
  zIndex: 1,
  _hover: {
    opacity: 1
  },
  position: 'absolute',
  top: '40%',
  marginRight: '0.7rem',
  marginLeft: '0.7rem'
};
function CustomNextArrow(_ref2, ref) {
  var {
    onClick
  } = _ref2;
  return (0, _jsxRuntime.jsx)(_react.Box, _objectSpread(_objectSpread({
    ref: ref,
    as: "button",
    onClick: onClick,
    title: "Next",
    _focus: {
      outline: 'none'
    },
    right: "xl"
  }, navButtonStyles), {}, {
    children: (0, _jsxRuntime.jsx)(_Icon.default, {
      svg: (0, _jsxRuntime.jsx)(_icons.ChevronRight, {
        color: "var(--chakra-colors-baseWhite)"
      }),
      transform: "translateX(1.6rem) scale(1.5)"
    })
  }));
}
var ForwardedCustomNextArrow = (0, _react2.forwardRef)(CustomNextArrow);
function CustomPrevArrow(_ref3, ref) {
  var {
    onClick
  } = _ref3;
  return (0, _jsxRuntime.jsx)(_react.Box, _objectSpread(_objectSpread({
    ref: ref,
    as: "button",
    onClick: onClick,
    title: "Previous",
    _focus: {
      outline: 'none'
    },
    left: "xl"
  }, navButtonStyles), {}, {
    children: (0, _jsxRuntime.jsx)(_Icon.default, {
      svg: (0, _jsxRuntime.jsx)(_icons.ChevronLeft, {
        color: "var(--chakra-colors-baseWhite)"
      }),
      transform: "translateX(1.4rem) scale(1.5)"
    })
  }));
}
var ForwardedCustomPrevArrow = (0, _react2.forwardRef)(CustomPrevArrow);
function NoArrow() {
  return null;
}
function focusSlider(sliderRef) {
  var _sliderRef$current;
  if (sliderRef !== null && sliderRef !== void 0 && (_sliderRef$current = sliderRef.current) !== null && _sliderRef$current !== void 0 && _sliderRef$current.innerSlider) {
    var _sliderRef$current$in;
    var track = (_sliderRef$current$in = sliderRef.current.innerSlider.list) === null || _sliderRef$current$in === void 0 ? void 0 : _sliderRef$current$in.querySelector('.slick-track');
    var slide = track === null || track === void 0 ? void 0 : track.querySelector('.slick-slide');
    slide === null || slide === void 0 || slide.focus();
  }
}