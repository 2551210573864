"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.TEMPORARY_BASKET_KEY = exports.INITIAL_CONFIRM_AMEND_ERROR_VALUE = exports.INITIAL_CONFIRM_AMEND_ERROR_KEY = exports.AMEND_REVERT_EXCEPTION_VALUE = exports.AMEND_REFUND_EXCEPTION_VALUE = exports.AMEND_DEPOSIT_FOLIOS_EXCEPTION_VALUE = exports.AMEND_CONFIRM_EXCEPTION_VALUE = exports.AMEND_A2C_PAYMENT_PATH = exports.AMEND_3CP_VISITED_KEY = exports.AMEND_3CP_VISITED_INITIAL_VALUE = void 0;
var TEMPORARY_BASKET_KEY = exports.TEMPORARY_BASKET_KEY = 'tempBasketKey';
var INITIAL_CONFIRM_AMEND_ERROR_VALUE = exports.INITIAL_CONFIRM_AMEND_ERROR_VALUE = '';
var INITIAL_CONFIRM_AMEND_ERROR_KEY = exports.INITIAL_CONFIRM_AMEND_ERROR_KEY = 'ConfirmAmendError';
var AMEND_DEPOSIT_FOLIOS_EXCEPTION_VALUE = exports.AMEND_DEPOSIT_FOLIOS_EXCEPTION_VALUE = 'AMEND_DEPOSIT_FOLIOS_EXCEPTION';
var AMEND_CONFIRM_EXCEPTION_VALUE = exports.AMEND_CONFIRM_EXCEPTION_VALUE = 'AMEND_CONFIRM_EXCEPTION';
var AMEND_REFUND_EXCEPTION_VALUE = exports.AMEND_REFUND_EXCEPTION_VALUE = 'AMEND_REFUND_EXCEPTION';
var AMEND_REVERT_EXCEPTION_VALUE = exports.AMEND_REVERT_EXCEPTION_VALUE = 'AMEND_REVERT_EXCEPTION';
var AMEND_3CP_VISITED_KEY = exports.AMEND_3CP_VISITED_KEY = '3cpVisitedAmend';
var AMEND_3CP_VISITED_INITIAL_VALUE = exports.AMEND_3CP_VISITED_INITIAL_VALUE = '';
var AMEND_A2C_PAYMENT_PATH = exports.AMEND_A2C_PAYMENT_PATH = '/amend/payment';