"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = BookingSummaryMobile;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _react = require("@chakra-ui/react");
var _api = require("@whitbread-eos/api");
var _atoms = require("@whitbread-eos/atoms");
var _molecules = require("@whitbread-eos/molecules");
var _utils = require("@whitbread-eos/utils");
var _react2 = require("react");
var _BookingSummaryCard = _interopRequireDefault(require("../BookingSummaryCard"));
var _jsxRuntime = require("react/jsx-runtime");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function BookingSummaryMobile(_ref) {
  var {
    reservationDetails,
    bookingSummaryData,
    totalCostAmount,
    t,
    language,
    prefixDataTestId,
    infoMessages,
    announcement,
    taxesMessage,
    selectedPaymentType,
    paypalOptions,
    isExtrasDisplayed
  } = _ref;
  var {
    currency,
    noNights,
    departureDate,
    arrivalDate,
    noRooms
  } = reservationDetails;
  var [isExpanded, setIsExpanded] = (0, _react2.useState)(false);
  return (0, _jsxRuntime.jsxs)(_react.Box, _objectSpread(_objectSpread({}, bookingSummaryCardStyle), {}, {
    "data-testid": (0, _utils.formatDataTestId)(prefixDataTestId, 'SectionWrapper'),
    children: [renderSummary(), isExpanded && (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
      children: [(0, _jsxRuntime.jsx)(_BookingSummaryCard.default, {
        t: t,
        language: language,
        totalCostAmount: totalCostAmount,
        bookingSummaryData: bookingSummaryData,
        prefixDataTestId: prefixDataTestId,
        currencyCode: reservationDetails.currency,
        taxesMessage: taxesMessage,
        isExtrasDisplayed: isExtrasDisplayed
      }), announcement, (bookingSummaryData === null || bookingSummaryData === void 0 ? void 0 : bookingSummaryData.paymentStepState) !== _api.paymentSteps.CARD_DETAILS && (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
        children: [(bookingSummaryData === null || bookingSummaryData === void 0 ? void 0 : bookingSummaryData.termsAndConditionsText) && (0, _jsxRuntime.jsx)(_react.Box, {
          sx: termsAndConditionsStyle,
          "data-testid": "termsAndConditionsPayment",
          className: "formatLinks",
          children: (0, _utils.renderSanitizedHtml)((0, _utils.formatUrlTermsConditions)(bookingSummaryData === null || bookingSummaryData === void 0 ? void 0 : bookingSummaryData.termsAndConditionsText))
        }), bookingSummaryData !== null && bookingSummaryData !== void 0 && bookingSummaryData.onclickBillingFormHandler && _api.PAYPAL_PAYMENT === (selectedPaymentType === null || selectedPaymentType === void 0 ? void 0 : selectedPaymentType.name) ? (0, _jsxRuntime.jsx)(_atoms.PaypalWBButton, _objectSpread({}, paypalOptions)) : (0, _jsxRuntime.jsx)(_atoms.Button, _objectSpread(_objectSpread({}, buttonStyle), {}, {
          type: "submit",
          form: "billingAddressForm",
          size: "md",
          "data-testid": "submitButton",
          variant: "primary",
          onClick: bookingSummaryData === null || bookingSummaryData === void 0 ? void 0 : bookingSummaryData.onclickBillingFormHandler,
          children: bookingSummaryData === null || bookingSummaryData === void 0 ? void 0 : bookingSummaryData.onSubmitBtnText
        }))]
      }), (0, _jsxRuntime.jsx)(_molecules.BookingSummaryInfoMessages, {
        infoMessages: infoMessages,
        prefixDataTestId: prefixDataTestId
      })]
    })]
  }));
  function renderSummary() {
    var formatArrivalDate = (0, _utils.isStringValid)(arrivalDate) ? (0, _utils.formatDate)(arrivalDate, 'dd MMM', language) : '';
    var formatDepartureDate = (0, _utils.isStringValid)(departureDate) ? (0, _utils.formatDate)(departureDate, 'dd MMM', language) : '';
    var dateFormat = "".concat(formatArrivalDate, " - ").concat(formatDepartureDate);
    var amountFormat = (0, _utils.formatPrice)((0, _utils.formatCurrency)(currency), totalCostAmount.toFixed(2), language);
    var roomsFormat = noRooms === 1 ? "".concat(noRooms, " ").concat(t('hoteldetails.bookingsummary.room')) : "".concat(noRooms, " ").concat(t('hoteldetails.bookingsummary.rooms'));
    var nightsFormat = noNights === 1 ? "".concat(noNights, " ").concat(t('booking.summary.night')) : "".concat(noNights, " ").concat(t('booking.summary.nights'));
    return (0, _jsxRuntime.jsxs)(_react.Flex, _objectSpread(_objectSpread({}, summaryContainerStyle), {}, {
      onClick: () => setIsExpanded(!isExpanded),
      "data-testid": (0, _utils.formatDataTestId)(prefixDataTestId, 'SectionHeader'),
      children: [(0, _jsxRuntime.jsxs)(_react.Flex, _objectSpread(_objectSpread({
        direction: "column",
        display: {
          mobile: 'flex',
          sm: 'none'
        },
        "data-testid": (0, _utils.formatDataTestId)(prefixDataTestId, 'HeaderLine2Lines')
      }, bookDescriptionStyle), {}, {
        children: [(0, _jsxRuntime.jsx)(_react.Text, {
          "data-testid": (0, _utils.formatDataTestId)(prefixDataTestId, 'HeaderLine-StayInfo'),
          children: "".concat(roomsFormat, ", ").concat(nightsFormat, " | ").concat(dateFormat)
        }), (0, _jsxRuntime.jsx)(_react.Text, {
          "data-testid": (0, _utils.formatDataTestId)(prefixDataTestId, 'HeaderLine-Amount'),
          children: amountFormat
        })]
      })), (0, _jsxRuntime.jsx)(_react.Text, _objectSpread(_objectSpread({}, bookDescriptionStyle), {}, {
        display: {
          mobile: 'none',
          sm: 'block'
        },
        "data-testid": (0, _utils.formatDataTestId)(prefixDataTestId, 'HeaderLine1Line'),
        children: "".concat(roomsFormat, ", ").concat(nightsFormat, " | ").concat(dateFormat, " | ").concat(amountFormat)
      })), (0, _jsxRuntime.jsx)(_react.Box, {
        ml: "md",
        alignSelf: "flex-start",
        "data-testid": (0, _utils.formatDataTestId)(prefixDataTestId, 'ExpandButton'),
        children: isExpanded ? (0, _jsxRuntime.jsx)(_atoms.Icon, {
          svg: (0, _jsxRuntime.jsx)(_atoms.ChevronUp24, {
            "data-testid": "isExpanded"
          })
        }) : (0, _jsxRuntime.jsx)(_atoms.Icon, {
          svg: (0, _jsxRuntime.jsx)(_atoms.ChevronDown24, {
            "data-testid": "isNotExpanded"
          })
        })
      })]
    }));
  }
}
var bookingSummaryCardStyle = {
  bg: 'lightGrey5',
  p: 'md'
};
var bookDescriptionStyle = {
  as: 'h6',
  fontSize: 'md',
  lineHeight: '3',
  color: 'darkGrey1'
};
var summaryContainerStyle = {
  alignItems: 'center',
  justifyContent: {
    mobile: 'space-between',
    sm: 'center'
  },
  fontWeight: 'semibold'
};
var termsAndConditionsStyle = {
  mt: 'lg',
  p: {
    fontSize: 'md',
    fontWeight: 'normal',
    lineHeight: '3'
  },
  a: {
    textDecoration: 'underline',
    color: 'zipSecondary'
  }
};
var buttonStyle = {
  mt: 'lg',
  w: {
    mobile: 'full',
    lg: '18rem',
    xl: '19.3125rem'
  }
};