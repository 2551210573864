"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = BookingActions;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _react = require("@chakra-ui/react");
var _api = require("@whitbread-eos/api");
var _atoms = require("@whitbread-eos/atoms");
var _utils = require("@whitbread-eos/utils");
var _nextI18next = require("next-i18next");
var _jsxRuntime = require("react/jsx-runtime");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function BookingActions(_ref) {
  var {
    bookingReference,
    operaConfNumber,
    config,
    area,
    bookingStatus,
    dpaInfo,
    baseDataTestId,
    overridenUserInfo,
    hideBookingStatus,
    shouldRenderStatusAndActions
  } = _ref;
  var {
    t
  } = (0, _nextI18next.useTranslation)(['common']);
  var [idvStatus] = (0, _utils.useLocalStorage)(_api.IDV_STATUS_KEY, _api.IDV_INITIAL_DATA);
  var idvPassed = dpaInfo && (0, _utils.getIDVPassedStatus)(dpaInfo) || idvStatus.passed && idvStatus.bookingReference === bookingReference;
  var overriddenStatus = overridenUserInfo === null || overridenUserInfo === void 0 ? void 0 : overridenUserInfo.reservationOverridden;
  var isOperaConfNumberAvailable = operaConfNumber && operaConfNumber.length > 0;
  var operaConfOrBookingReferenceStyle = isOperaConfNumberAvailable ? operaConfNumberStyle : bookingReferenceStyle;
  return (0, _jsxRuntime.jsxs)(_react.Flex, {
    direction: "column",
    children: [(0, _jsxRuntime.jsxs)(_react.Box, {
      "data-testid": (0, _utils.formatDataTestId)(baseDataTestId, isOperaConfNumberAvailable ? 'OperaConfNumber' : 'BookingReference'),
      children: [(0, _jsxRuntime.jsx)(_react.Text, _objectSpread(_objectSpread({}, bookingReferenceTitleStyle), {}, {
        width: 'max-content',
        children: isOperaConfNumberAvailable ? displayOperaConfirmationLabel() : t('dashboard.bookings.bookingReference')
      })), (0, _jsxRuntime.jsx)(_react.Text, _objectSpread(_objectSpread({}, operaConfOrBookingReferenceStyle), {}, {
        children: isOperaConfNumberAvailable ? operaConfNumber : bookingReference
      }))]
    }), !shouldRenderStatusAndActions && (0, _jsxRuntime.jsx)(_react.Flex, _objectSpread(_objectSpread({}, bookingActionsStyle), {}, {
      "data-testid": (0, _utils.formatDataTestId)(baseDataTestId, 'Links'),
      children: renderBasketStatusAndActions(bookingStatus, area)
    }))]
  });
  function displayOperaConfirmationLabel() {
    return "".concat(area === _api.Area.CCUI ? t('ccui.managebooking.operaConfirmation') : t('account.dashboard.booking.confirmationNumber'), ":");
  }
  function returnBookingStatusLabel(status) {
    if (status === _api.BC_RESERVATION_STATUS.CANCELLED) {
      return t('dashboard.bookings.cancelled');
    }
    return t('dashboard.bookings.upcoming');
  }
  function displayOverridenStyleLink(idvPassed, overriden, key, type, isLinkEnabled) {
    var isOverridePoliciesSuccess = key === 'overridePolicies' && overriden && idvPassed;
    var nonHeaderStyles = isOverridePoliciesSuccess ? overridenPoliciesSuccess : textLinkStyles(idvPassed && isLinkEnabled);
    return type === 'header' ? textHeaderLinkStyles : nonHeaderStyles;
  }
  function renderBasketStatusAndActions(bookingStatus, area) {
    return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
      children: [area !== 'ccui' && (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
        children: [hideBookingStatus !== null && hideBookingStatus !== void 0 ? hideBookingStatus : (0, _jsxRuntime.jsxs)(_react.Text, _objectSpread(_objectSpread({}, bookingStatusStyle), {}, {
          children: [returnBookingStatusLabel(bookingStatus), " "]
        })), config.map(link => {
          var action = link.action ? {
            onClick: link.action
          } : {};
          return (0, _jsxRuntime.jsx)(_react.Flex, _objectSpread(_objectSpread({}, linkContainerStyles(overriddenStatus && link.key)), {}, {
            children: (0, _jsxRuntime.jsx)(_react.Text, _objectSpread(_objectSpread(_objectSpread({}, piTextLinkStyle), action), {}, {
              children: t(link.title)
            }))
          }), link.title);
        })]
      }), area === 'ccui' && (0, _jsxRuntime.jsx)(_jsxRuntime.Fragment, {
        children: config.map(link => {
          var action = link.action ? {
            onClick: link.action
          } : {};
          return (0, _jsxRuntime.jsxs)(_react.Flex, _objectSpread(_objectSpread({}, linkContainerStyles(overriddenStatus && link.key)), {}, {
            children: [(0, _jsxRuntime.jsx)(_react.Text, _objectSpread(_objectSpread(_objectSpread({}, displayOverridenStyleLink(idvPassed, overriddenStatus, link.key, link.type, link.isLinkEnabled)), action), {}, {
              children: t(link.title)
            }), link.title), idvPassed && (overridenUserInfo === null || overridenUserInfo === void 0 ? void 0 : overridenUserInfo.reservationOverridden) && link.key === 'overridePolicies' && (0, _jsxRuntime.jsx)(_react.Box, {
              "data-testid": (0, _utils.formatDataTestId)(baseDataTestId, 'OverridenSuccess'),
              children: (0, _jsxRuntime.jsx)(_atoms.Success, {})
            })]
          }));
        })
      })]
    });
  }
}
var bookingActionsStyle = {
  rowGap: 'sm',
  flexDirection: 'column',
  mt: 'md'
};
var bookingStatusStyle = {
  fontSize: 'md',
  fontWeight: 'semibold',
  color: 'darkGrey1',
  lineHeight: '3'
};
var bookingReferenceTitleStyle = {
  color: 'primary',
  lineHeight: '3',
  fontSize: 'md',
  fontWeight: 'normal'
};
var bookingReferenceStyle = {
  color: 'darkGrey2',
  fontSize: '3xl',
  fontWeight: 'normal',
  lineHeight: '4'
};
var operaConfNumberStyle = {
  color: 'darkGrey2',
  fontSize: 'lg',
  fontWeight: 'normal',
  lineHeight: '3'
};
var linkContainerStyles = overridenStatus => {
  return {
    direction: overridenStatus ? 'row' : 'column',
    alignItems: overridenStatus && 'center'
  };
};
var textLinkStyles = isEnabled => {
  return {
    color: isEnabled ? 'btnSecondaryEnabled' : 'darkGrey1',
    lineHeight: '3',
    fontWeight: 'medium',
    fontSize: 'md',
    cursor: isEnabled ? 'pointer' : 'not-allowed',
    pointerEvents: isEnabled ? 'auto' : 'none',
    opacity: isEnabled ? '1' : '0.5'
  };
};
var textHeaderLinkStyles = {
  color: 'darkGrey2',
  fontSize: 'md',
  fontWeight: 'bold',
  lineHeight: '4',
  marginTop: 'xs'
};
var overridenPoliciesSuccess = {
  color: 'success',
  lineHeight: '3',
  fontWeight: 'bold',
  fontSize: 'md',
  cursor: 'pointer',
  pointerEvents: 'auto',
  marginRight: 'sm'
};
var piTextLinkStyle = textLinkStyles(true);