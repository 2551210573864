"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = useScrollVisibility;
var _navigation = require("next/navigation");
var _react = require("react");
function useScrollVisibility() {
  var visible = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : true;
  var [isVisible, setIsVisible] = (0, _react.useState)(visible);
  var [lastScrollY, setLastScrollY] = (0, _react.useState)(0);
  var pathname = (0, _navigation.usePathname)();
  var searchParams = (0, _navigation.useSearchParams)();
  var tabParam = searchParams.get('tab');
  (0, _react.useEffect)(() => {
    window.scroll(0, 0);
    setLastScrollY(0);
  }, [pathname, tabParam]);
  (0, _react.useEffect)(() => {
    var handleScroll = () => {
      var scrollY = window.scrollY;
      if (scrollY > lastScrollY) {
        setIsVisible(false);
      } else {
        setIsVisible(true);
      }
      setLastScrollY(scrollY);
    };
    document.addEventListener('scroll', handleScroll);
    return () => {
      document.removeEventListener('scroll', handleScroll);
    };
  }, [lastScrollY]);
  return isVisible;
}