"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.CREATE_GROUP_BOOKING = void 0;
var _taggedTemplateLiteral2 = _interopRequireDefault(require("@babel/runtime/helpers/taggedTemplateLiteral"));
var _graphqlRequest = require("graphql-request");
var _templateObject;
var CREATE_GROUP_BOOKING = exports.CREATE_GROUP_BOOKING = (0, _graphqlRequest.gql)(_templateObject || (_templateObject = (0, _taggedTemplateLiteral2.default)(["\n  mutation createGroupBooking(\n    $hotelCode: String!\n    $createGroupBookingCriteria: CreateGroupBookingCriteria!\n  ) {\n    createGroupBooking(\n      hotelCode: $hotelCode\n      createGroupBookingCriteria: $createGroupBookingCriteria\n    ) {\n      ticketNumber\n      incidentId\n    }\n  }\n"])));