"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = IDVModal;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _react = require("@chakra-ui/react");
var _atoms = require("@whitbread-eos/atoms");
var _utils = require("@whitbread-eos/utils");
var _BookingInformation = _interopRequireDefault(require("./BookingInformation.component"));
var _DpaStatus = _interopRequireDefault(require("./DpaStatus.component"));
var _PersonalInformation = _interopRequireDefault(require("./PersonalInformation.component"));
var _jsxRuntime = require("react/jsx-runtime");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function IDVModal(_ref) {
  var {
    data,
    setData,
    isVisible,
    onClose,
    onReuseDetails,
    t,
    inputValues
  } = _ref;
  var baseDataTestId = 'IDVModal';
  var handleDpaPassed = dpaPassedParam => {
    setData(_objectSpread(_objectSpread({}, data), {}, {
      dpaStatus: _objectSpread(_objectSpread({}, data.dpaStatus), {}, {
        dpaPassed: dpaPassedParam,
        dpaOverride: false
      })
    }));
  };
  var handleDpaOverride = dpaOverrideParam => {
    setData(_objectSpread(_objectSpread({}, data), {}, {
      dpaStatus: _objectSpread(_objectSpread({}, data.dpaStatus), {}, {
        dpaOverride: dpaOverrideParam,
        dpaPassed: false
      })
    }));
  };
  var handleModalClose = () => {
    onClose(data.dpaStatus.dpaPassed, data.dpaStatus.dpaOverride);
  };
  return (0, _jsxRuntime.jsx)(_atoms.ModalVariants, {
    onClose: handleModalClose,
    variant: "gallery",
    isOpen: isVisible,
    variantProps: {
      title: '',
      delimiter: false
    },
    children: (0, _jsxRuntime.jsxs)(_react.Box, {
      "data-testid": baseDataTestId,
      mb: "1.5rem",
      ml: "1.5rem",
      mr: "1.5rem",
      children: [(0, _jsxRuntime.jsx)(_react.Divider, {}), (0, _jsxRuntime.jsx)(_react.Flex, {
        justifyContent: "center",
        children: (0, _jsxRuntime.jsx)(_react.Heading, _objectSpread(_objectSpread({}, titleStyle), {}, {
          "data-testid": (0, _utils.formatDataTestId)(baseDataTestId, 'ModalTitle'),
          children: t('ccui.idv.heading')
        }))
      }), (0, _jsxRuntime.jsxs)(_react.Flex, {
        children: [(0, _jsxRuntime.jsx)(_PersonalInformation.default, {
          personalInformation: data.personalInformation,
          t: t,
          inputValues: inputValues,
          baseDataTestId: (0, _utils.formatDataTestId)(baseDataTestId, 'PersonalInformation')
        }), (0, _jsxRuntime.jsx)(_BookingInformation.default, {
          bookingInformation: data.bookingInformation,
          t: t,
          inputValues: inputValues,
          baseDataTestId: (0, _utils.formatDataTestId)(baseDataTestId, 'BookingInfo')
        }), (0, _jsxRuntime.jsx)(_DpaStatus.default, {
          dpaStatus: data.dpaStatus,
          onDpaPassed: handleDpaPassed,
          onDpaOverride: handleDpaOverride,
          onReuseDetails: onReuseDetails,
          t: t,
          baseDataTestId: (0, _utils.formatDataTestId)(baseDataTestId, 'DpaStatus')
        })]
      }), (0, _jsxRuntime.jsx)(_react.Flex, {
        mt: "2rem",
        justifyContent: "flex-end",
        children: (0, _jsxRuntime.jsx)(_atoms.Button, _objectSpread(_objectSpread({
          "data-testid": (0, _utils.formatDataTestId)(baseDataTestId, 'CloseButton')
        }, closeButtonStyle), {}, {
          onClick: handleModalClose,
          children: (0, _jsxRuntime.jsx)(_react.Text, _objectSpread(_objectSpread({}, btnTextStyle), {}, {
            children: t('ccui.idv.closeButton')
          }))
        }))
      })]
    })
  });
}
var titleStyle = {
  color: 'darkGrey1',
  fontSize: '1.625rem',
  lineHeight: '2rem',
  fontWeight: '600',
  mt: '1.5rem',
  mb: '2.5rem',
  fontFamily: 'Proxima Nova Sans, helvetica, arial, sans-serif'
};
var closeButtonStyle = {
  size: 'sm',
  variant: 'tertiary',
  w: {
    mobile: 'full',
    xs: 'full',
    sm: 'full',
    md: 'full',
    lg: '311px',
    xl: '333px'
  },
  my: '1rem'
};
var btnTextStyle = {
  lineHeight: '1.5rem',
  fontFamily: 'Proxima Nova Sans, helvetica, arial, sans-serif',
  fontWeight: '600',
  fontSize: '1.125rem'
};