"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.akamaiImageLoader = akamaiImageLoader;
exports.isIVMEnabled = isIVMEnabled;
var _config = _interopRequireDefault(require("next/config"));
function akamaiImageLoader(_ref) {
  var {
    src,
    width
  } = _ref;
  return "".concat(src, "?imwidth=").concat(width);
}
function isIVMEnabled() {
  var {
    publicRuntimeConfig = {}
  } = (0, _config.default)() || {};
  var {
    NEXT_IMAGE_UNOPTIMIZED
  } = publicRuntimeConfig;
  return NEXT_IMAGE_UNOPTIMIZED === 'true';
}