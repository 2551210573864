"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.tracingCookie = exports.getDefaultSessionTracing = exports.getClientDefaultSessionTracing = void 0;
var _nanoid = require("nanoid");
var _cookies = require("../helpers/cookies");
var DEFAULT_TRACING_COOKIE_NAME = 'WB-SESSION-ID';
var tracingCookie = exports.tracingCookie = function tracingCookie(cookies) {
  var name = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : DEFAULT_TRACING_COOKIE_NAME;
  var tracingCookieValue = cookies.get(name);
  if (!tracingCookieValue) {
    var tracingId = (0, _nanoid.nanoid)();
    cookies.set(name, tracingId, {
      httpOnly: false,
      maxAge: 10 * 60 * 60 * 100
    });
    return tracingId;
  }
  return tracingCookieValue;
};
var getDefaultSessionTracing = cookies => {
  return {
    [DEFAULT_TRACING_COOKIE_NAME]: tracingCookie(cookies)
  };
};
exports.getDefaultSessionTracing = getDefaultSessionTracing;
var getClientDefaultSessionTracing = exports.getClientDefaultSessionTracing = function getClientDefaultSessionTracing() {
  var name = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : DEFAULT_TRACING_COOKIE_NAME;
  return {
    [DEFAULT_TRACING_COOKIE_NAME]: (0, _cookies.getCookie)(name)
  };
};