"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = BookingDetailsTotalCostComponent;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _react = require("@chakra-ui/react");
var _api = require("@whitbread-eos/api");
var _utils = require("@whitbread-eos/utils");
var _nextI18next = require("next-i18next");
var _jsxRuntime = require("react/jsx-runtime");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function BookingDetailsTotalCostComponent(_ref) {
  var {
    totalCost,
    previousTotal,
    balanceOutstanding,
    currency,
    paymentOption,
    newTotal,
    donationPkg,
    shouldDisplayCityTaxMessage,
    dinnerAllowance,
    paidWithPiba = false,
    bookingStatus = '',
    isAmendPage
  } = _ref;
  var {
    t
  } = (0, _nextI18next.useTranslation)(['common']);
  var {
    language
  } = (0, _utils.useCustomLocale)();
  var {
    country
  } = (0, _utils.useCustomLocale)();
  var displayDonation = donationPkg && country !== 'de';
  var status = bookingStatus === 'FUTURE' ? _api.BOOKING_TYPE.UPCOMING : _api.BOOKING_TYPE[bookingStatus];
  var displayPreAuthSpend = paidWithPiba && [_api.BOOKING_TYPE.UPCOMING, _api.BOOKING_TYPE.PAST].includes(status);
  if (paymentOption === _api.GuaranteeCodes.PAY_ON_ARRIVAL && Number(previousTotal) !== 0) {
    return payNowTotalCost();
  }
  switch (paymentOption) {
    case _api.paymentOptions.PAY_ON_ARRIVAL:
    case _api.GuaranteeCodes.PAY_ON_ARRIVAL:
    case _api.GuaranteeCodes.ACCOUNT_TO_COMPANY:
    case _api.paymentOptions.RESERVE_WITHOUT_CARD:
    case _api.GuaranteeCodes.RESERVE_WITHOUT_CARD:
      {
        return payOnArrivalTotalCost();
      }
    case _api.paymentOptions.PAY_NOW:
    case _api.GuaranteeCodes.PAY_NOW:
      {
        return payNowTotalCost();
      }
    default:
      {
        return (0, _jsxRuntime.jsx)(_jsxRuntime.Fragment, {});
      }
  }
  function payOnArrivalTotalCost() {
    var _dinnerAllowance$curr;
    return (0, _jsxRuntime.jsxs)(_react.Flex, {
      flexDir: "column",
      mt: "4xl",
      children: [displayDonation && (0, _jsxRuntime.jsx)(_react.Box, {
        mb: "4xl",
        children: donation(donationPkg)
      }), (0, _jsxRuntime.jsxs)(_react.Flex, {
        justifyContent: "space-between",
        children: [(0, _jsxRuntime.jsxs)(_react.Flex, {
          flexDir: "column",
          textAlign: "start",
          children: [(0, _jsxRuntime.jsx)(_react.Text, _objectSpread(_objectSpread({
            "data-testid": "pay-on-arrival-total-cost-label"
          }, priceTitleStyle), {}, {
            children: t('dashboard.bookings.totalCost')
          })), displayPreAuthSpend && (0, _jsxRuntime.jsx)(_react.Text, {
            "data-testid": "preauth-text",
            children: t('dashboard.bookings.preAuthorisedSpend')
          }), dinnerAllowance && (0, _jsxRuntime.jsx)(_react.Text, {
            "data-testid": "dinner-allowance-text",
            children: "".concat(t('dashboard.bookings.dinnerAllowance'), " - ").concat((0, _utils.formatPrice)((0, _utils.formatCurrency)((_dinnerAllowance$curr = dinnerAllowance.currency) !== null && _dinnerAllowance$curr !== void 0 ? _dinnerAllowance$curr : ''), Number(dinnerAllowance.amount).toFixed(2), language))
          })]
        }), (0, _jsxRuntime.jsxs)(_react.Flex, {
          flexDir: "column",
          textAlign: "end",
          children: [(0, _jsxRuntime.jsx)(_react.Text, _objectSpread(_objectSpread({
            "data-testid": "pay-on-arrival-total-cost"
          }, priceTitleStyle), {}, {
            children: (0, _utils.formatPrice)((0, _utils.formatCurrency)(currency), Number(totalCost).toFixed(2), language)
          })), (0, _jsxRuntime.jsx)(_react.Text, _objectSpread(_objectSpread({}, priceDescriptionStyle), {}, {
            children: t(getBalanceOutstandingSubLabel(+balanceOutstanding, isAmendPage))
          }))]
        })]
      }), shouldDisplayCityTaxMessage && cityTaxMessage()]
    });
  }
  function payNowTotalCost() {
    return (0, _jsxRuntime.jsxs)(_react.Flex, {
      flexDir: "column",
      children: [displayDonation && (0, _jsxRuntime.jsx)(_react.Box, {
        mt: "4xl",
        children: donation(donationPkg)
      }), (0, _jsxRuntime.jsxs)(_react.Flex, {
        justifyContent: "space-between",
        mt: "4xl",
        children: [(0, _jsxRuntime.jsx)(_react.Text, _objectSpread(_objectSpread({
          "data-testid": "previousTotal"
        }, priceTitleStyle), {}, {
          children: t('dashboard.bookings.previousTotal')
        })), (0, _jsxRuntime.jsx)(_react.Text, _objectSpread(_objectSpread({}, priceTitleStyle), {}, {
          "data-testid": "previousTotalSumLabel",
          children: (0, _utils.formatPrice)((0, _utils.formatCurrency)(currency), Number(previousTotal).toFixed(2), language)
        }))]
      }), (0, _jsxRuntime.jsxs)(_react.Flex, {
        justifyContent: "space-between",
        mt: 'xl',
        children: [(0, _jsxRuntime.jsxs)(_react.Flex, {
          flexDir: "column",
          textAlign: "left",
          children: [(0, _jsxRuntime.jsx)(_react.Text, _objectSpread(_objectSpread({
            "data-testid": "balanceOutstandingLabel"
          }, priceTitleStyle), {}, {
            children: t('dashboard.bookings.outstandingBalance')
          })), (0, _jsxRuntime.jsx)(_react.Text, _objectSpread(_objectSpread({}, priceDescriptionStyle), {}, {
            textAlign: "left",
            "data-testid": "balanceOutstandingDescriptionLabel",
            children: t(getBalanceOutstandingSubLabel(+balanceOutstanding, isAmendPage, true))
          }))]
        }), (0, _jsxRuntime.jsx)(_react.Text, _objectSpread(_objectSpread({}, priceTitleStyle), {}, {
          alignSelf: "flex-start",
          "data-testid": "balanceOutstandingSumLabel",
          children: (0, _utils.formatPrice)((0, _utils.formatCurrency)(currency), Number(balanceOutstanding).toFixed(2), language)
        }))]
      }), (0, _jsxRuntime.jsx)(_react.Flex, {
        flexDir: "column",
        mt: {
          mobile: '5xl',
          lg: '3xl'
        },
        children: (0, _jsxRuntime.jsxs)(_react.Flex, {
          justifyContent: "space-between",
          mt: displayDonation ? {
            mobile: 'xs',
            lg: '0',
            xl: 'xs'
          } : '0',
          children: [(0, _jsxRuntime.jsxs)(_react.Flex, {
            flexDir: "column",
            textAlign: "start",
            children: [(0, _jsxRuntime.jsx)(_react.Text, _objectSpread(_objectSpread({
              "data-testid": "newTotalLabel"
            }, priceTitleStyle), {}, {
              children: t('dashboard.bookings.newTotal')
            })), displayPreAuthSpend && (0, _jsxRuntime.jsx)(_react.Text, {
              "data-testid": "preauth-text",
              children: t('dashboard.bookings.preAuthorisedSpend')
            })]
          }), (0, _jsxRuntime.jsxs)(_react.Flex, {
            flexDir: "column",
            textAlign: "end",
            children: [(0, _jsxRuntime.jsx)(_react.Text, _objectSpread(_objectSpread({}, priceTitleStyle), {}, {
              "data-testid": "newTotalSumLabel",
              children: (0, _utils.formatPrice)((0, _utils.formatCurrency)(currency), Number(newTotal).toFixed(2), language)
            })), previousTotal > newTotal && (0, _jsxRuntime.jsx)(_react.Text, _objectSpread(_objectSpread({}, priceDescriptionStyle), {}, {
              "data-testid": "balanceStatus",
              children: t('dashboard.bookings.balancePaid')
            }))]
          })]
        })
      }), shouldDisplayCityTaxMessage && cityTaxMessage()]
    });
  }
  function donation(donationPkg) {
    return (0, _jsxRuntime.jsxs)(_react.Flex, {
      justifyContent: "space-between",
      children: [(0, _jsxRuntime.jsx)(_react.Text, _objectSpread(_objectSpread({}, donationTitleStyle), {}, {
        "data-testid": "charityLabel",
        children: t('account.dashboard.gosh')
      })), (0, _jsxRuntime.jsx)(_react.Text, _objectSpread(_objectSpread({}, donationPriceStyle), {}, {
        "data-testid": "charitySumLabel",
        children: (0, _utils.formatPrice)((0, _utils.formatCurrency)(donationPkg.currency), Number(donationPkg.unitPrice).toFixed(2), language)
      }))]
    });
  }
  function cityTaxMessage() {
    return (0, _jsxRuntime.jsx)(_react.Flex, {
      justifyContent: "flex-end",
      children: (0, _jsxRuntime.jsx)(_react.Text, _objectSpread(_objectSpread({}, priceDescriptionStyle), {}, {
        children: t('booking.overview.includeCityTax')
      }))
    });
  }
}
function getBalanceOutstandingSubLabel(balanceOutstanding, isAmendPage, isPayNow) {
  if (isAmendPage) {
    if (balanceOutstanding < 0) {
      return 'amend.refundTerms';
    }
    return 'dashboard.bookings.toPayOnArrival';
  }
  if (isPayNow) {
    return 'dashboard.bookings.toPayOnArrival';
  }
  return 'dashboard.bookings.paymentOnArrival';
}
var donationTitleStyle = {
  color: 'darkGrey2',
  fontSize: {
    mobile: 'md',
    sm: 'lg'
  },
  lineHeight: '3',
  fontWeight: 'normal',
  w: {
    mobile: '8.56rem',
    sm: 'auto'
  },
  as: 'h6'
};
var donationPriceStyle = {
  fontWeight: 'medium',
  fontSize: {
    mobile: 'md',
    sm: 'lg'
  },
  color: 'darkGrey2',
  lineHeight: '3',
  as: 'h6',
  alignSelf: 'flex-start'
};
var priceTitleStyle = {
  fontWeight: 'bold',
  fontSize: {
    mobile: 'lg',
    sm: 'xl'
  },
  lineHeight: '3',
  color: 'darkGrey1',
  as: 'h6'
};
var priceDescriptionStyle = {
  fontWeight: 'normal',
  fontSize: 'sm',
  lineHeight: '2',
  color: 'darkGrey2',
  textAlign: 'right'
};