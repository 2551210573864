"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.AnswerType = void 0;
var AnswerType = exports.AnswerType = function (AnswerType) {
  AnswerType["PRESET_ANSWER"] = "U";
  AnswerType["OWN_ANSWER"] = "F";
  return AnswerType;
}({});