"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.GET_BOOKING_HISTORY = void 0;
var _taggedTemplateLiteral2 = _interopRequireDefault(require("@babel/runtime/helpers/taggedTemplateLiteral"));
var _graphqlRequest = require("graphql-request");
var _templateObject;
var GET_BOOKING_HISTORY = exports.GET_BOOKING_HISTORY = (0, _graphqlRequest.gql)(_templateObject || (_templateObject = (0, _taggedTemplateLiteral2.default)(["\n  query getBookingHistory(\n    $business: Boolean!\n    $sortOrder: String!\n    $pageSize: Int\n    $pageIndex: Int\n    $filterValue: String\n    $filterType: String\n    $bookingChannel: BookingChannelCriteria!\n    $continuationToken: String\n  ) {\n    bookingHistory(\n      bookingHistoryRequest: {\n        business: $business\n        sortOrder: $sortOrder\n        pageSize: $pageSize\n        pageIndex: $pageIndex\n        filterValue: $filterValue\n        filterType: $filterType\n        bookingChannel: $bookingChannel\n        continuationToken: $continuationToken\n      }\n    ) {\n      continuationToken\n      pageIndex\n      pageSize\n      totalSize\n      totals {\n        cancelled\n        checkedIn\n        past\n        upcoming\n      }\n      bookings {\n        arrivalDate\n        bookedBy\n        leadGuest\n        bookingReference\n        historyRecordNumber\n        bookingStatus\n        hotelName\n        noOfNights\n        sourceSystem\n        totalCost {\n          amount\n          currency\n        }\n        leadGuestSurname\n        hotelCode\n      }\n    }\n  }\n"])));