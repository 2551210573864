"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _react = require("@chakra-ui/react");
var _jsxRuntime = require("react/jsx-runtime");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
var IdvKV = _ref => {
  var {
    text,
    value,
    dataTestId
  } = _ref;
  return (0, _jsxRuntime.jsxs)(_react.Box, {
    flex: "1",
    "data-testid": dataTestId,
    children: [(0, _jsxRuntime.jsx)(_react.Text, _objectSpread(_objectSpread({}, textStyle), {}, {
      children: text
    })), (0, _jsxRuntime.jsx)(_react.Text, _objectSpread(_objectSpread({}, valueStyle), {}, {
      children: value
    }))]
  });
};
var textStyle = {
  color: 'darkGrey1',
  fontSize: 'md',
  fontWeight: '500',
  lineHeight: '1.5rem',
  fontFamily: 'Proxima Nova Sans, helvetica, arial, sans-serif'
};
var valueStyle = {
  color: 'darkGrey2',
  fontSize: 'sm',
  fontWeight: '400',
  lineHeight: '1.25rem',
  fontFamily: 'Proxima Nova Sans, helvetica, arial, sans-serif'
};
var _default = exports.default = IdvKV;