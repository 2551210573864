"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = RateSelector;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _react = require("@chakra-ui/react");
var _api = require("@whitbread-eos/api");
var _atoms = require("@whitbread-eos/atoms");
var _molecules = require("@whitbread-eos/molecules");
var _utils = require("@whitbread-eos/utils");
var _nextI18next = require("next-i18next");
var _router = require("next/router");
var _react2 = require("react");
var _OurRates = _interopRequireDefault(require("./OurRates/OurRates.component"));
var _RateCards = _interopRequireDefault(require("./RateCards/RateCards.component"));
var _SilentSubstitutionNotificationWrapper = _interopRequireDefault(require("./SilentSubstitutionNotificationWrapper/SilentSubstitutionNotificationWrapper.component"));
var _jsxRuntime = require("react/jsx-runtime");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
var checkCorporateDiscount = rates => {
  return rates.every(rate => {
    return (rate === null || rate === void 0 ? void 0 : rate.isCorporateDiscountAvailable) !== true;
  });
};
function RateSelector(_ref) {
  var _data$hotelInventory, _data$hotelAvailabili, _data$ratesInformatio, _data$hotelAvailabili2, _data$hotelAvailabili3, _data$hotelAvailabili4, _bookingFlow$bookingF, _selectedRate$roomTyp, _selectedRate$roomTyp2, _selectedRate$roomTyp3, _data$ratesInformatio4, _accessibilityInfo$te, _data$hotelAvailabili5, _getRateClassificatio, _getRateClassificatio2, _roomRates$selectedRa, _data$ratesInformatio5;
  var {
    channel,
    variant,
    isLoading,
    isError,
    data,
    error,
    basketData,
    bookingFlow,
    queryClient,
    roomTypeInformationResponse,
    isParentAnalytics,
    brand,
    accessibilityInfo,
    phoneNumber,
    bookRsvIsLoading,
    bookRsvIsError,
    bookRsvIsSuccess,
    bookRsvData,
    bookRsvError,
    handleBooking,
    isHotelOpeningSoon,
    isLessThanSm,
    isLessThanMd,
    isLessThanLg,
    isDisabledContinueBtn,
    mappedRoomLabels,
    isSilentFeatureFlagEnabled,
    thirdParties,
    companyData,
    isPrePopulateBillingAddressEnabled,
    targetRatePlanCode
  } = _ref;
  var scrolledPastRates = (0, _utils.useScrolledPast)('rate-cards-list');
  var [selectedRoomClassAndRate, setSelectedRoomClassAndRate] = (0, _react2.useState)('');
  var [currentClassRoomTypes, setCurrentClassRoomTypes] = (0, _react2.useState)([]);
  var {
    t
  } = (0, _nextI18next.useTranslation)(['common']);
  var router = (0, _router.useRouter)();
  var {
    query
  } = router || {
    query: {}
  };
  var {
    language,
    country
  } = (0, _utils.useCustomLocale)();
  var [basketDetailsState, setBasketDetailsState] = (0, _utils.useLocalStorage)(_api.BASKET_DETAILS_STORAGE_KEY, _api.BASKET_DETAILS_STATE_INITIAL_VALUE);
  var isExternalSearch = (0, _utils.useIsExternalSearch)(thirdParties !== null && thirdParties !== void 0 ? thirdParties : '');
  var {
    [_api.FT_PI_META_CLASS_RATE_SEARCH]: isMetaSearchEnabled
  } = (0, _utils.useFeatureToggle)();
  var selectedRoomClassCode = getRoomClassCodeFromSelection();
  var selectedRateIndex = getPlanIndexFromSelection();
  var pmsRoomTypeInventories = (data === null || data === void 0 || (_data$hotelInventory = data.hotelInventory) === null || _data$hotelInventory === void 0 ? void 0 : _data$hotelInventory.roomTypeInventories) || [];
  var roomRates = (data === null || data === void 0 || (_data$hotelAvailabili = data.hotelAvailability) === null || _data$hotelAvailabili === void 0 ? void 0 : _data$hotelAvailabili.roomRates) || [];
  var allRoomTypes = (0, _utils.getAllRoomTypesFromRates)(roomRates);
  var selectedRate = roomRates === null || roomRates === void 0 ? void 0 : roomRates[selectedRateIndex];
  var selectedRateCategory = data === null || data === void 0 || (_data$ratesInformatio = data.ratesInformation) === null || _data$ratesInformatio === void 0 || (_data$ratesInformatio = _data$ratesInformatio.rateClassifications) === null || _data$ratesInformatio === void 0 ? void 0 : _data$ratesInformatio.filter(rate => rate.rateClassification === (selectedRate === null || selectedRate === void 0 ? void 0 : selectedRate.ratePlanCode) || rate.ratePlanCode === (selectedRate === null || selectedRate === void 0 ? void 0 : selectedRate.ratePlanCode))[0];
  var roomClassCodes = getSortedRoomClassCodes();
  var rateClassifications = roomRates.map(roomRate => {
    var _data$ratesInformatio2;
    return (0, _utils.getRateClassification)(roomRate === null || roomRate === void 0 ? void 0 : roomRate.ratePlanCode, data === null || data === void 0 || (_data$ratesInformatio2 = data.ratesInformation) === null || _data$ratesInformatio2 === void 0 ? void 0 : _data$ratesInformatio2.rateClassifications);
  });
  var arrivalDate = (data === null || data === void 0 || (_data$hotelAvailabili2 = data.hotelAvailability) === null || _data$hotelAvailabili2 === void 0 ? void 0 : _data$hotelAvailabili2.startDate) || '';
  var departureDate = (data === null || data === void 0 || (_data$hotelAvailabili3 = data.hotelAvailability) === null || _data$hotelAvailabili3 === void 0 ? void 0 : _data$hotelAvailabili3.endDate) || '';
  var roomsLabelsForSilentSubst = isSilentFeatureFlagEnabled ? (0, _utils.getRoomTypesFromQuery)(query, mappedRoomLabels, t('hoteldetails.bookingsummary.room')) : [];
  var isItDiscountedRateMicroSite = (0, _utils.useForDiscountedRateMicroSite)();
  (0, _react2.useEffect)(() => {
    if (data !== null && data !== void 0 && data.hotelAvailability && roomTypeInformationResponse !== null && roomTypeInformationResponse !== void 0 && roomTypeInformationResponse.dataRoomTypeInformation && bookingFlow) {
      saveBasketDetailsToLocalStorage();
    }
  }, [data, roomTypeInformationResponse === null || roomTypeInformationResponse === void 0 ? void 0 : roomTypeInformationResponse.dataRoomTypeInformation, bookingFlow, selectedRoomClassAndRate]);
  (0, _react2.useEffect)(() => {
    if (typeof window !== 'undefined' && (roomRates === null || roomRates === void 0 ? void 0 : roomRates.length) > 0 && isParentAnalytics) {
      var _window;
      var hotelRates = roomRates === null || roomRates === void 0 ? void 0 : roomRates.map(roomRate => {
        var _data$ratesInformatio3, _roomRate$roomTypes, _roomRate$cellCode, _roomRate$roomTypes2, _rateClassification$r, _roomData$roomPriceBr, _roomData$roomPriceBr2, _roomData$roomPriceBr3, _roomRate$ratePlanCod, _rateClassification$r2;
        var rateClassification = (0, _utils.getRateClassification)(roomRate === null || roomRate === void 0 ? void 0 : roomRate.ratePlanCode, data === null || data === void 0 || (_data$ratesInformatio3 = data.ratesInformation) === null || _data$ratesInformatio3 === void 0 ? void 0 : _data$ratesInformatio3.rateClassifications);
        var roomData = roomRate === null || roomRate === void 0 || (_roomRate$roomTypes = roomRate.roomTypes) === null || _roomRate$roomTypes === void 0 || (_roomRate$roomTypes = _roomRate$roomTypes[0]) === null || _roomRate$roomTypes === void 0 || (_roomRate$roomTypes = _roomRate$roomTypes.rooms) === null || _roomRate$roomTypes === void 0 ? void 0 : _roomRate$roomTypes.filter(room => room.roomClass === selectedRoomClassCode)[0];
        return {
          cellCode: (_roomRate$cellCode = roomRate === null || roomRate === void 0 ? void 0 : roomRate.cellCode) !== null && _roomRate$cellCode !== void 0 ? _roomRate$cellCode : '',
          currencyCode: roomRate === null || roomRate === void 0 || (_roomRate$roomTypes2 = roomRate.roomTypes) === null || _roomRate$roomTypes2 === void 0 || (_roomRate$roomTypes2 = _roomRate$roomTypes2[0]) === null || _roomRate$roomTypes2 === void 0 || (_roomRate$roomTypes2 = _roomRate$roomTypes2.rooms) === null || _roomRate$roomTypes2 === void 0 || (_roomRate$roomTypes2 = _roomRate$roomTypes2[0]) === null || _roomRate$roomTypes2 === void 0 || (_roomRate$roomTypes2 = _roomRate$roomTypes2.roomPriceBreakdown) === null || _roomRate$roomTypes2 === void 0 ? void 0 : _roomRate$roomTypes2.currencyCode,
          description: (_rateClassification$r = rateClassification === null || rateClassification === void 0 ? void 0 : rateClassification.rateName) !== null && _rateClassification$r !== void 0 ? _rateClassification$r : '',
          lettingType: roomData !== null && roomData !== void 0 && roomData.cotAvailable ? (roomData === null || roomData === void 0 ? void 0 : roomData.specialRequests.join('_').toLowerCase()) + '+c' : roomData === null || roomData === void 0 ? void 0 : roomData.specialRequests.join('_').toLowerCase(),
          price: (roomData === null || roomData === void 0 || (_roomData$roomPriceBr = roomData.roomPriceBreakdown) === null || _roomData$roomPriceBr === void 0 ? void 0 : _roomData$roomPriceBr.totalNetAmount) % 1 === 0 ? (roomData === null || roomData === void 0 || (_roomData$roomPriceBr2 = roomData.roomPriceBreakdown) === null || _roomData$roomPriceBr2 === void 0 ? void 0 : _roomData$roomPriceBr2.totalNetAmount.toString()) + '.00' : roomData === null || roomData === void 0 || (_roomData$roomPriceBr3 = roomData.roomPriceBreakdown) === null || _roomData$roomPriceBr3 === void 0 ? void 0 : _roomData$roomPriceBr3.totalNetAmount.toString(),
          rateCode: (_roomRate$ratePlanCod = roomRate === null || roomRate === void 0 ? void 0 : roomRate.ratePlanCode) !== null && _roomRate$ratePlanCod !== void 0 ? _roomRate$ratePlanCod : '',
          text: (_rateClassification$r2 = rateClassification === null || rateClassification === void 0 ? void 0 : rateClassification.rateDescription) !== null && _rateClassification$r2 !== void 0 ? _rateClassification$r2 : ''
        };
      });
      if ((_window = window) !== null && _window !== void 0 && (_window = _window.analyticsData) !== null && _window !== void 0 && (_window = _window.analyticsDataSearchResult) !== null && _window !== void 0 && _window.searchResultsDisplayed) {
        _utils.analytics.update({
          analyticsDataSearchResult: _objectSpread(_objectSpread({}, window.analyticsData.analyticsDataSearchResult), typeof window.analyticsData.analyticsDataSearchResult.searchResultsDisplayed !== 'number' ? {
            searchResultsDisplayed: [_objectSpread(_objectSpread({}, window.analyticsData.analyticsDataSearchResult.searchResultsDisplayed[0]), {}, {
              hotelRates: hotelRates !== null && hotelRates !== void 0 ? hotelRates : []
            })]
          } : {})
        });
      }
    }
  }, [data, selectedRoomClassAndRate, isParentAnalytics]);
  (0, _react2.useEffect)(() => {
    if (roomRates !== null && roomRates !== void 0 && roomRates.length) {
      setDefaultSelectedRoomClassRate();
    }
  }, [data, isMetaSearchEnabled]);
  if (isLoading) {
    return (0, _jsxRuntime.jsx)(_react.Box, {
      minHeight: 400,
      children: (0, _jsxRuntime.jsx)(_atoms.LoadingSpinner, {
        loadingText: t('searchresults.list.hotel.loading')
      })
    });
  }
  if (isError) {
    return (0, _jsxRuntime.jsx)(_react.Text, {
      children: error.message
    });
  }
  if (isHotelOpeningSoon || !(data !== null && data !== void 0 && (_data$hotelAvailabili4 = data.hotelAvailability) !== null && _data$hotelAvailabili4 !== void 0 && _data$hotelAvailabili4.available) || !(roomRates !== null && roomRates !== void 0 && roomRates.length)) {
    return null;
  }
  var cot = getCot();
  var bookingFlowId = bookingFlow === null || bookingFlow === void 0 || (_bookingFlow$bookingF = bookingFlow.bookingFlowItems) === null || _bookingFlow$bookingF === void 0 || (_bookingFlow$bookingF = _bookingFlow$bookingF.find(bfi => (bfi === null || bfi === void 0 ? void 0 : bfi.rateCategory) === (selectedRateCategory === null || selectedRateCategory === void 0 ? void 0 : selectedRateCategory.rateCategory))) === null || _bookingFlow$bookingF === void 0 ? void 0 : _bookingFlow$bookingF.bookingId;
  function saveLabelsForSilentSubstAndRedirect(reservationRoomTypes) {
    if (bookRsvIsSuccess && bookRsvData) {
      var _bookRsvData$createRe;
      var basketReference = (bookRsvData === null || bookRsvData === void 0 || (_bookRsvData$createRe = bookRsvData.createReservation) === null || _bookRsvData$createRe === void 0 ? void 0 : _bookRsvData$createRe.basketReference) || null;
      (0, _utils.setBasketDetailsCookie)(basketDetailsState, basketReference, variant === 'BB');
      if (basketReference && roomsLabelsForSilentSubst && !!isSilentFeatureFlagEnabled) {
        if (typeof window !== 'undefined') {
          (0, _utils.updateSilentSubstLocalStorage)(basketReference, reservationRoomTypes);
        }
      }
      router.push("/".concat(country, "/").concat(language).concat(variant === 'PI' && bookingFlowId ? "/".concat(bookingFlowId) : '', "/").concat(variant === 'BB' ? 'business-booker/booking-business/guest-details' : 'ancillaries', "?reservationId=").concat(basketReference));
    }
  }
  var reservationRoomTypes = (selectedRate === null || selectedRate === void 0 || (_selectedRate$roomTyp = selectedRate.roomTypes) === null || _selectedRate$roomTyp === void 0 ? void 0 : _selectedRate$roomTyp.map(room => {
    var _room$rooms$filter$;
    return room === null || room === void 0 || (_room$rooms$filter$ = room.rooms.filter(room => isRoomWithMatchingClassCode(room, selectedRoomClassCode))[0]) === null || _room$rooms$filter$ === void 0 ? void 0 : _room$rooms$filter$.pmsRoomType;
  })) || [];
  var twinRoomsWithBothChoiceCount = 0;
  var checkHasTwinRoomChoice = room => {
    var _room$rooms;
    var twinRoomSpecialRequestsPerRoomType = (room === null || room === void 0 || (_room$rooms = room.rooms) === null || _room$rooms === void 0 ? void 0 : _room$rooms.map(roomType => {
      return roomType === null || roomType === void 0 ? void 0 : roomType.specialRequests;
    }).flat()) || [];
    var uniqueTwinSpecialRequests = _api.twinRoomSpecialRequests.filter(specialRequest => twinRoomSpecialRequestsPerRoomType.includes(specialRequest));
    if (uniqueTwinSpecialRequests.length > 1) {
      twinRoomsWithBothChoiceCount++;
    }
    return (room === null || room === void 0 ? void 0 : room.roomType) === _api.ROOM_TYPE.TWIN && uniqueTwinSpecialRequests !== undefined && (uniqueTwinSpecialRequests === null || uniqueTwinSpecialRequests === void 0 ? void 0 : uniqueTwinSpecialRequests.length) > 1;
  };
  var twinRoomChoiceMatches = (selectedRate === null || selectedRate === void 0 || (_selectedRate$roomTyp2 = selectedRate.roomTypes) === null || _selectedRate$roomTyp2 === void 0 ? void 0 : _selectedRate$roomTyp2.filter(checkHasTwinRoomChoice)) || [];
  var hasTwinRoomChoiceMatch = !!twinRoomChoiceMatches.length;
  var roomTypes = (selectedRate === null || selectedRate === void 0 || (_selectedRate$roomTyp3 = selectedRate.roomTypes) === null || _selectedRate$roomTyp3 === void 0 ? void 0 : _selectedRate$roomTyp3.filter(roomTypeItem => {
    return roomTypeItem === null || roomTypeItem === void 0 ? void 0 : roomTypeItem.roomType;
  })) || [];
  var twinOnlyRoomTypes = (roomTypes === null || roomTypes === void 0 ? void 0 : roomTypes.filter(roomTypeItem => {
    return (roomTypeItem === null || roomTypeItem === void 0 ? void 0 : roomTypeItem.roomType) === _api.ROOM_TYPE.TWIN;
  })) || [];
  var hasAllTwinRoomsOfferingBothChoice = twinOnlyRoomTypes.length === twinRoomsWithBothChoiceCount;
  var twinPmsRoomTypes = (twinOnlyRoomTypes === null || twinOnlyRoomTypes === void 0 ? void 0 : twinOnlyRoomTypes.map(item => item === null || item === void 0 ? void 0 : item.rooms).flat()) || [];
  var uniqueTwinPmsRoomTypes = Array.from(new Set(twinPmsRoomTypes.map(item => item === null || item === void 0 ? void 0 : item.pmsRoomType)));
  var matchedTwinPmsRoomTypesAgainstInventory = [];
  var uniqueTwinPmsRoomTypeNotPresentInHotelInventory = false;
  var getInventoryCountPerTwinPmsRoomTypes = (uniqueTwinPmsRoomTypes, pmsRoomTypeInventories) => {
    pmsRoomTypeInventories === null || pmsRoomTypeInventories === void 0 || pmsRoomTypeInventories.filter(inventoryItem => {
      if (uniqueTwinPmsRoomTypes !== null && uniqueTwinPmsRoomTypes !== void 0 && uniqueTwinPmsRoomTypes.includes(inventoryItem === null || inventoryItem === void 0 ? void 0 : inventoryItem.code)) {
        matchedTwinPmsRoomTypesAgainstInventory.push(inventoryItem);
      }
    });
    return matchedTwinPmsRoomTypesAgainstInventory;
  };
  var inventoryCountPerMatchedTwinPmsRoomType = getInventoryCountPerTwinPmsRoomTypes(uniqueTwinPmsRoomTypes, pmsRoomTypeInventories);
  if (inventoryCountPerMatchedTwinPmsRoomType.length === 0 || inventoryCountPerMatchedTwinPmsRoomType.length < uniqueTwinPmsRoomTypes.length) {
    uniqueTwinPmsRoomTypeNotPresentInHotelInventory = true;
  }
  var hasInventoryCountPerTwinPmsRoomTypePerRoomCount = (inventoryCountPerMatchedTwinPmsRoomType === null || inventoryCountPerMatchedTwinPmsRoomType === void 0 ? void 0 : inventoryCountPerMatchedTwinPmsRoomType.length) > 0 && (inventoryCountPerMatchedTwinPmsRoomType === null || inventoryCountPerMatchedTwinPmsRoomType === void 0 ? void 0 : inventoryCountPerMatchedTwinPmsRoomType.every(item => (item === null || item === void 0 ? void 0 : item.availableCount) >= (roomTypes === null || roomTypes === void 0 ? void 0 : roomTypes.length))) && !uniqueTwinPmsRoomTypeNotPresentInHotelInventory;
  var hasTwinRoomChoice = hasInventoryCountPerTwinPmsRoomTypePerRoomCount && hasTwinRoomChoiceMatch && hasAllTwinRoomsOfferingBothChoice;
  var hasAccessibleRoom = hasDisabledRoom(roomTypeInformationResponse, reservationRoomTypes);
  var isDiscountRateNotAvailable = isItDiscountedRateMicroSite && checkCorporateDiscount(data === null || data === void 0 || (_data$ratesInformatio4 = data.ratesInformation) === null || _data$ratesInformatio4 === void 0 ? void 0 : _data$ratesInformatio4.rateClassifications) && brand === 'PID';
  var specialRoomLimitMessage = isDiscountRateNotAvailable && targetRatePlanCode && (0, _jsxRuntime.jsx)(_react.Box, _objectSpread(_objectSpread({
    mt: "lg",
    mb: "lg"
  }, notificationBoxStyles), {}, {
    children: (0, _jsxRuntime.jsx)(_atoms.Notification, {
      status: "info",
      variant: "info",
      prefixDataTestId: "hdp_specialratelimit",
      svg: (0, _jsxRuntime.jsx)(_atoms.Info, {}),
      description: t("promotion.discountrate.".concat(targetRatePlanCode, ".specialratelimit"))
    })
  }));
  return (0, _jsxRuntime.jsxs)(_atoms.Section, {
    dataTestId: "hdp_rateSelector",
    children: [(0, _jsxRuntime.jsxs)(_react.Box, {
      maxW: "full",
      children: [(0, _jsxRuntime.jsx)(_react.Heading, _objectSpread(_objectSpread({
        as: "h3",
        "data-testid": "hdp_rateSelectorTitle"
      }, headingStyles), {}, {
        children: t('hoteldetails.rates.grid.title')
      })), (0, _jsxRuntime.jsx)(_react.Text, {
        my: "md",
        "data-testid": "hdp_rateSelectorDescription",
        children: t('hoteldetails.rates.grid.description')
      }), variant === 'CCUI' && (0, _utils.isArrivalDateWithinSetHours)(arrivalDate, 72) && (0, _jsxRuntime.jsx)(_react.Box, {
        mt: "lg",
        mb: "md",
        children: (0, _jsxRuntime.jsx)(_atoms.Notification, {
          status: "info",
          variant: "info",
          prefixDataTestId: "hdp_payOnArrivalOnly",
          svg: (0, _jsxRuntime.jsx)(_atoms.Info, {}),
          description: t('ccui.payment.notification.payOnArrivalOnly')
        })
      }), !!rateClassifications.length && (0, _jsxRuntime.jsx)(_OurRates.default, {
        rateClassifications: rateClassifications,
        brand: brand
      }), !!currentClassRoomTypes.length && (0, _jsxRuntime.jsx)(_SilentSubstitutionNotificationWrapper.default, {
        brand: brand,
        roomTypeInformationResponse: roomTypeInformationResponse,
        currentClassRoomTypes: currentClassRoomTypes
      }), hasAccessibleRoom && (0, _jsxRuntime.jsx)(_react.Box, _objectSpread(_objectSpread({}, notificationBoxStyles), {}, {
        children: (0, _jsxRuntime.jsx)(_molecules.AccessibleRoomNotification, {
          data: (_accessibilityInfo$te = accessibilityInfo === null || accessibilityInfo === void 0 ? void 0 : accessibilityInfo.text) !== null && _accessibilityInfo$te !== void 0 ? _accessibilityInfo$te : ''
        })
      })), cot.requested && (0, _jsxRuntime.jsx)(_react.Box, _objectSpread(_objectSpread({}, notificationBoxStyles), {}, {
        children: (0, _jsxRuntime.jsx)(_molecules.CotNotification, {
          cotAvailable: cot.available
        })
      })), specialRoomLimitMessage, (0, _jsxRuntime.jsxs)(_react.Flex, {
        w: "full",
        alignItems: "flex-start",
        mb: "md",
        flexWrap: {
          base: 'wrap',
          lg: 'initial'
        },
        children: [(0, _jsxRuntime.jsx)(_react.Box, {
          id: "rate-cards-list",
          mr: {
            base: 0,
            lg: 'lg'
          },
          w: "full",
          children: (0, _jsxRuntime.jsx)(_RateCards.default, {
            roomClassCodes: roomClassCodes,
            data: data,
            brand: brand,
            channel: channel,
            selectedRoomClassAndRate: selectedRoomClassAndRate,
            setSelectedRoomClassAndRate: setSelectedRoomClassAndRate,
            roomTypeInformationResponse: roomTypeInformationResponse,
            isLessThanSm: isLessThanSm,
            isLessThanMd: isLessThanMd,
            basketData: basketData,
            allRoomTypes: allRoomTypes
          })
        }), (0, _jsxRuntime.jsx)(_react.Box, _objectSpread(_objectSpread({
          "data-testid": "basketWrapper"
        }, !isLessThanLg ? _objectSpread({}, basketDesktopScrollStyles) : {}), {}, {
          children: (0, _jsxRuntime.jsx)(_molecules.Basket, _objectSpread({
            channel: channel,
            variant: variant,
            roomClassIndexFromSelectedRate: selectedRate === null || selectedRate === void 0 ? void 0 : selectedRate.roomTypes[0].rooms.findIndex(room => isRoomWithMatchingClassCode(room, selectedRoomClassCode)),
            roomClassCode: selectedRoomClassCode,
            isCityTaxExempt: false,
            isLastFewRooms: data === null || data === void 0 || (_data$hotelAvailabili5 = data.hotelAvailability) === null || _data$hotelAvailabili5 === void 0 ? void 0 : _data$hotelAvailabili5.limitedAvailability,
            isHDPBasket: true,
            hasAccessibleRoom: hasAccessibleRoom,
            hasTwinRoomChoice: hasTwinRoomChoice,
            shouldDisplayMobileBasket: isLessThanLg && scrolledPastRates,
            roomClass: (0, _utils.getRoomClassByRoomClassCode)(selectedRoomClassCode, language),
            rateName: (_getRateClassificatio = (_getRateClassificatio2 = (0, _utils.getRateClassification)((_roomRates$selectedRa = roomRates[selectedRateIndex]) === null || _roomRates$selectedRa === void 0 ? void 0 : _roomRates$selectedRa.ratePlanCode, data === null || data === void 0 || (_data$ratesInformatio5 = data.ratesInformation) === null || _data$ratesInformatio5 === void 0 ? void 0 : _data$ratesInformatio5.rateClassifications)) === null || _getRateClassificatio2 === void 0 ? void 0 : _getRateClassificatio2.rateName) !== null && _getRateClassificatio !== void 0 ? _getRateClassificatio : ''
          }, _objectSpread(_objectSpread({
            bookRsvIsLoading,
            bookRsvIsError,
            bookRsvError,
            handleBooking,
            roomTypeInformationResponse,
            selectedRate,
            queryClient,
            isLessThanLg,
            brand
          }, basketData), {}, {
            isDisabledContinueBtn,
            roomsLabelsForSilentSubst,
            isSilentFeatureFlagEnabled,
            saveLabelsForSilentSubstAndRedirect,
            setCurrentClassRoomTypes,
            companyData,
            selectedRateCategory,
            isPrePopulateBillingAddressEnabled
          })))
        }))]
      }), (0, _jsxRuntime.jsx)(_molecules.ImportantNotification, {
        arrival: arrivalDate,
        departure: departureDate,
        queryClient: queryClient
      })]
    }), (0, _jsxRuntime.jsx)(_molecules.KeyHotelFacts, {
      queryClient: queryClient,
      channel: channel
    })]
  });
  function isRoomWithMatchingClassCode(room, roomClassCode) {
    return room.roomClass === roomClassCode;
  }
  function getPlanIndexFromSelection() {
    return parseInt(selectedRoomClassAndRate.split('-')[1]);
  }
  function getRoomClassCodeFromSelection() {
    return selectedRoomClassAndRate.split('-')[0];
  }
  function getAvailabilityOrderedRoomClassCodes() {
    return Array.from(new Set(allRoomTypes === null || allRoomTypes === void 0 ? void 0 : allRoomTypes.map(roomType => {
      var _roomType$rooms;
      return roomType === null || roomType === void 0 || (_roomType$rooms = roomType.rooms) === null || _roomType$rooms === void 0 ? void 0 : _roomType$rooms.map(room => room === null || room === void 0 ? void 0 : room.roomClass);
    }).flat()));
  }
  function getSortedRoomClassCodes() {
    var availableRoomTypes = (0, _utils.getAvailableRoomTypes)(allRoomTypes);
    var {
      PREMIER_PLUS_ROOM,
      STANDARD_ROOM,
      BIGGER_ROOM
    } = _api.RoomClassCodes;
    if ((availableRoomTypes === null || availableRoomTypes === void 0 ? void 0 : availableRoomTypes.length) > 1) {
      return [STANDARD_ROOM];
    }
    var availabilityOrderedRoomClassCodes = getAvailabilityOrderedRoomClassCodes();
    return availabilityOrderedRoomClassCodes.sort((roomClassCode1, roomClassCode2) => {
      if (brand.toLowerCase() === 'hub') {
        if (roomClassCode1 === BIGGER_ROOM && roomClassCode2 === STANDARD_ROOM) return -1;
        return 1;
      }
      if (roomClassCode1 === PREMIER_PLUS_ROOM && roomClassCode2 === STANDARD_ROOM) return -1;
      return 1;
    });
  }
  function getRoomRateTotalAmount(roomRate, roomClassCode) {
    var _roomRate$roomTypes3;
    return roomRate === null || roomRate === void 0 || (_roomRate$roomTypes3 = roomRate.roomTypes) === null || _roomRate$roomTypes3 === void 0 || (_roomRate$roomTypes3 = _roomRate$roomTypes3.map(roomType => {
      var _roomType$rooms$filte;
      return (_roomType$rooms$filte = roomType.rooms.filter(room => room.roomClass === roomClassCode)[0]) === null || _roomType$rooms$filte === void 0 || (_roomType$rooms$filte = _roomType$rooms$filte.roomPriceBreakdown) === null || _roomType$rooms$filte === void 0 ? void 0 : _roomType$rooms$filte.totalNetAmount;
    })) === null || _roomRate$roomTypes3 === void 0 ? void 0 : _roomRate$roomTypes3.reduce((sum, pricePerRoomType) => sum + pricePerRoomType, 0);
  }
  function hasRoomClassAvailability(roomRate, roomClassCode) {
    return roomRate.roomTypes.every(roomType => roomType.rooms.some(room => room.roomClass === roomClassCode));
  }
  function getDefaultRateSelection() {
    var ratesWithClassificationInformation = (0, _utils.getRoomRatesThatMatchRoomClassifications)(rateClassifications, roomRates);
    var defaultSelectedClassCode = roomClassCodes[0];
    var defaultSelectedRate = ratesWithClassificationInformation.filter(roomRate => hasRoomClassAvailability(roomRate, defaultSelectedClassCode))[0];
    var defaultSelectedRateIndex = roomRates.findIndex(rate => rate.ratePlanCode === defaultSelectedRate.ratePlanCode);
    return "".concat(defaultSelectedClassCode, "-").concat(defaultSelectedRateIndex);
  }
  function getLowestRateSelection() {
    var matchedRateClassificationsToRoomRates = (0, _utils.getRoomRatesThatMatchRoomClassifications)(rateClassifications, roomRates);
    var rateCodesWithRatesClassifications = matchedRateClassificationsToRoomRates.map(rate => rate.ratePlanCode);
    var prevAmount = 0;
    var defaultSelectedClassCode = '';
    var defaultSelectedRateIndex = 0;
    var {
      STANDARD_ROOM
    } = _api.RoomClassCodes;
    for (var i = 0; i < roomClassCodes.length; i++) {
      for (var j = 0; j < roomRates.length; j++) {
        if (!(rateCodesWithRatesClassifications.includes(roomRates[j].ratePlanCode) && hasRoomClassAvailability(roomRates[j], roomClassCodes[i]))) {
          continue;
        }
        var roomTotalAmount = getRoomRateTotalAmount(roomRates[j], roomClassCodes[i]);
        if (prevAmount === 0 || prevAmount > roomTotalAmount) {
          defaultSelectedRateIndex = j;
          defaultSelectedClassCode = roomClassCodes[i];
          prevAmount = roomTotalAmount;
        }
      }
      if (roomClassCodes.includes(STANDARD_ROOM)) {
        defaultSelectedClassCode = STANDARD_ROOM;
      }
    }
    return "".concat(defaultSelectedClassCode, "-").concat(defaultSelectedRateIndex);
  }
  function getMetaRateSelection() {
    var _split, _query$SELECT;
    var [roomClass, rateType] = (_split = query === null || query === void 0 || (_query$SELECT = query.SELECT) === null || _query$SELECT === void 0 ? void 0 : _query$SELECT.split('-')) !== null && _split !== void 0 ? _split : [];
    var roomClassCode = _api.RoomClassCodes[roomClass];
    var matchedRateClassifications = (0, _utils.getRoomRatesThatMatchRoomClassifications)(rateClassifications, roomRates);
    var matchedRateCodeIndex = matchedRateClassifications.findIndex(_ref2 => {
      var {
        ratePlanCode
      } = _ref2;
      return ratePlanCode === rateType;
    });
    return matchedRateCodeIndex !== -1 && roomClassCodes.includes(roomClassCode) ? "".concat(roomClassCode, "-").concat(matchedRateCodeIndex) : getLowestRateSelection();
  }
  function setDefaultSelectedRoomClassRate() {
    var _router$query;
    var metaSearch = router === null || router === void 0 || (_router$query = router.query) === null || _router$query === void 0 ? void 0 : _router$query.SELECT;
    if (roomRates !== null && roomRates !== void 0 && roomRates.length) {
      if (variant === 'PI' && isExternalSearch) {
        setSelectedRoomClassAndRate(metaSearch && isMetaSearchEnabled ? getMetaRateSelection() : getLowestRateSelection());
      } else {
        setSelectedRoomClassAndRate(getDefaultRateSelection());
      }
    }
  }
  function saveBasketDetailsToLocalStorage() {
    if (roomRates !== null && roomRates !== void 0 && roomRates.length) {
      var _getRateClassificatio3, _getRateClassificatio4, _roomRates$selectedRa2, _data$ratesInformatio6;
      var basketDetails = _objectSpread(_objectSpread({}, basketData), {}, {
        selectedRate: _objectSpread(_objectSpread({}, selectedRate), {}, {
          rateCategory: selectedRateCategory === null || selectedRateCategory === void 0 ? void 0 : selectedRateCategory.rateCategory
        }),
        roomClass: (0, _utils.getRoomClassByRoomClassCode)(selectedRoomClassCode, language),
        rateName: (_getRateClassificatio3 = (_getRateClassificatio4 = (0, _utils.getRateClassification)((_roomRates$selectedRa2 = roomRates[selectedRateIndex]) === null || _roomRates$selectedRa2 === void 0 ? void 0 : _roomRates$selectedRa2.ratePlanCode, data === null || data === void 0 || (_data$ratesInformatio6 = data.ratesInformation) === null || _data$ratesInformatio6 === void 0 ? void 0 : _data$ratesInformatio6.rateClassifications)) === null || _getRateClassificatio4 === void 0 ? void 0 : _getRateClassificatio4.rateName) !== null && _getRateClassificatio3 !== void 0 ? _getRateClassificatio3 : '',
        roomTypeInformationResponse,
        bookingFlow,
        phoneNumber: phoneNumber !== null && phoneNumber !== void 0 ? phoneNumber : '',
        brand,
        silentSubstitutionLabels: roomsLabelsForSilentSubst
      });
      setBasketDetailsState(basketDetails);
    }
  }
  function hasDisabledRoom(roomTypeInformationResponse, roomClassCodes) {
    var _roomClassCodes$map;
    return roomClassCodes === null || roomClassCodes === void 0 || (_roomClassCodes$map = roomClassCodes.map(roomCode => getRoomGroupId(roomTypeInformationResponse, roomCode))) === null || _roomClassCodes$map === void 0 ? void 0 : _roomClassCodes$map.some(item => item === null || item === void 0 ? void 0 : item.includes('accessible'));
  }
  function getRoomGroupId(roomTypeInformationResponse, roomCode) {
    var _roomTypeInformationR;
    return roomTypeInformationResponse === null || roomTypeInformationResponse === void 0 || (_roomTypeInformationR = roomTypeInformationResponse.dataRoomTypeInformation) === null || _roomTypeInformationR === void 0 || (_roomTypeInformationR = _roomTypeInformationR.roomTypeInformation) === null || _roomTypeInformationR === void 0 || (_roomTypeInformationR = _roomTypeInformationR.roomTypes) === null || _roomTypeInformationR === void 0 || (_roomTypeInformationR = _roomTypeInformationR.find(roomType => roomType.roomTypeCode === roomCode || roomType.roomTypeCode.includes(roomCode))) === null || _roomTypeInformationR === void 0 || (_roomTypeInformationR = _roomTypeInformationR.groupId) === null || _roomTypeInformationR === void 0 ? void 0 : _roomTypeInformationR.toLowerCase();
  }
  function getCot() {
    var cot = {
      requested: false,
      available: false
    };
    allRoomTypes.forEach(roomType => {
      if (roomType.cotRequested === true) {
        cot.requested = true;
      }
      roomType.rooms.some(room => {
        if (room.cotAvailable === true) {
          cot.available = true;
        }
      });
    });
    return cot;
  }
}
var notificationBoxStyles = {
  mb: 'lg',
  w: {
    base: 'full',
    lg: 'calc(100% - 333px)'
  }
};
var headingStyles = {
  fontSize: {
    base: 'xl',
    md: '2xl'
  },
  fontWeight: 'semibold',
  lineHeight: {
    base: '3',
    md: '4'
  },
  mb: 'lg',
  size: 'md'
};
var basketDesktopScrollStyles = {
  position: 'sticky',
  top: 0,
  marginBottom: '2xl'
};