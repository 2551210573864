"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.usePaymentPaypal = usePaymentPaypal;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _api = require("@whitbread-eos/api");
var _react = require("react");
var _getters = require("../getters");
var _useCustomLocale = _interopRequireDefault(require("./use-custom-locale"));
var _useFeatureToggle = _interopRequireDefault(require("./use-feature-toggle"));
var _useRequest = require("./use-request");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function usePaymentPaypal(basketReference, channel) {
  var {
    language,
    country
  } = (0, _useCustomLocale.default)();
  var idTokenCookie = (0, _getters.getAuthCookie)();
  var [injectPaypalProvider, setInjectPaypalProvider] = (0, _react.useState)(false);
  var userType = channel === _api.Area.BB.toUpperCase() ? _api.UserType.Business : _api.UserType.Leisure;
  var FT_ENABLE_PAYPAL = channel === _api.Area.BB.toUpperCase() ? _api.FT_BB_ENABLE_PAYPAL : _api.FT_PI_ENABLE_PAYPAL;
  var {
    [FT_ENABLE_PAYPAL]: isPaypalEnabled
  } = (0, _useFeatureToggle.default)();
  var paramsForQueryPaymentMethods = {
    language,
    country,
    basketReference,
    userType: idTokenCookie ? userType : undefined,
    clientChannel: channel
  };
  var {
    isLoading,
    data: paymentMethodData
  } = (0, _useRequest.useQueryRequest)(['getPaymentMethods', language, country, basketReference], channel === _api.Area.BB.toUpperCase() ? _api.GET_PAYMENT_METHODS_QUERY_BB : _api.GET_PAYMENT_METHODS_QUERY, _objectSpread({}, paramsForQueryPaymentMethods), undefined, idTokenCookie);
  var paypalPaymentData = (0, _react.useMemo)(() => {
    if (paymentMethodData !== null && paymentMethodData !== void 0 && paymentMethodData.paymentMethods) {
      return (paymentMethodData === null || paymentMethodData === void 0 ? void 0 : paymentMethodData.paymentMethods.find(item => _api.PAYPAL_PAYMENT === item.name)) || null;
    } else {
      return null;
    }
  }, [paymentMethodData]);
  (0, _react.useEffect)(() => {
    if (!isLoading && isPaypalEnabled && paypalPaymentData !== null && paypalPaymentData !== void 0 && paypalPaymentData.clientToken && paypalPaymentData !== null && paypalPaymentData !== void 0 && paypalPaymentData.enabled) {
      setInjectPaypalProvider(true);
    }
  }, [isLoading, isPaypalEnabled, paypalPaymentData]);
  return {
    injectPaypalProvider,
    paypalPaymentData
  };
}