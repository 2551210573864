"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.BUSINESS_BOOKER_USER_ROLES = void 0;
var BUSINESS_BOOKER_USER_ROLES = exports.BUSINESS_BOOKER_USER_ROLES = Object.freeze({
  SUPER: 'SUPER',
  BOOKER: 'BOOKER',
  SELF: 'SELF',
  STAYER: 'STAYER'
});