"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.handleTouchEvent = void 0;
var handleTouchEvent = _ref => {
  var {
    event,
    canvas,
    isDrawing,
    setIsDrawing
  } = _ref;
  event.preventDefault();
  if (!canvas) return;
  var ctx = canvas.getContext('2d');
  if (!ctx) return;
  var {
    clientX,
    clientY
  } = event.touches[0];
  var rect = canvas.getBoundingClientRect();
  var offsetX = clientX - rect.left;
  var offsetY = clientY - rect.top;
  if (event.type === 'touchstart') {
    ctx.beginPath();
    ctx.moveTo(offsetX, offsetY);
    setIsDrawing(true);
  } else if (event.type === 'touchmove' && isDrawing) {
    ctx.lineTo(offsetX, offsetY);
    ctx.stroke();
  }
};
exports.handleTouchEvent = handleTouchEvent;