"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.GET_SEO_INFORMATION = void 0;
var _taggedTemplateLiteral2 = _interopRequireDefault(require("@babel/runtime/helpers/taggedTemplateLiteral"));
var _graphqlRequest = require("graphql-request");
var _templateObject;
var GET_SEO_INFORMATION = exports.GET_SEO_INFORMATION = (0, _graphqlRequest.gql)(_templateObject || (_templateObject = (0, _taggedTemplateLiteral2.default)(["\n  query getSeoInformation(\n    $page: String!\n    $hotelId: String\n    $bookingFlowId: String\n    $language: String!\n    $country: String!\n  ) {\n    seoInformation(\n      page: $page\n      hotelId: $hotelId\n      bookingFlowId: $bookingFlowId\n      language: $language\n      country: $country\n    ) {\n      hreflangs {\n        hreflang\n        href\n      }\n      faq {\n        faqItems {\n          answer\n          question\n        }\n        title\n      }\n      faviconUrl\n      icons {\n        href\n        rel\n        sizes\n      }\n      msIcons {\n        content\n        name\n      }\n      pageDescription\n      pageTitle\n      cardImageUrl\n    }\n  }\n"])));