"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getGuestsPlaceholderString = getGuestsPlaceholderString;
function getGuestsPlaceholderString(adultsNumber, childrenNumber, labels) {
  var {
    adult,
    adults,
    child,
    children
  } = labels;
  var adultsLabel = adultsNumber === 1 ? adult : adults;
  var childrenLabel = children;
  if (childrenNumber === 0) {
    childrenLabel = '';
  } else if (childrenNumber === 1) {
    childrenLabel = child;
  }
  return childrenNumber === 0 ? "".concat(adultsNumber, " ").concat(adultsLabel, ",") : "".concat(adultsNumber, " ").concat(adultsLabel, ", ").concat(childrenNumber, " ").concat(childrenLabel, ",");
}