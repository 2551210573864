"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = DataSecuritySection;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _react = require("@chakra-ui/react");
var _atoms = require("@whitbread-eos/atoms");
var _utils = require("@whitbread-eos/utils");
var _react2 = require("react");
var _jsxRuntime = require("react/jsx-runtime");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function DataSecuritySection(_ref) {
  var _privacyPolicy$descri;
  var {
    privacyPolicy,
    prefixDataTestId,
    containerStyle
  } = _ref;
  var [isOpen, setIsOpen] = (0, _react2.useState)(false);
  var toggleSection = () => setIsOpen(!isOpen);
  var baseDataTestId = (0, _utils.formatDataTestId)(prefixDataTestId, 'PrivacyPolicy');
  return (0, _jsxRuntime.jsxs)(_react.Flex, _objectSpread(_objectSpread({
    "data-testid": (0, _utils.formatDataTestId)(baseDataTestId, 'Wrapper')
  }, _objectSpread(_objectSpread({}, currentContainerStyle), containerStyle)), {}, {
    children: [(0, _jsxRuntime.jsx)(_react.Box, _objectSpread(_objectSpread({}, iconWrapperStyle), {}, {
      children: (0, _jsxRuntime.jsx)(_atoms.Icon, {
        svg: (0, _jsxRuntime.jsx)(_atoms.Security16, {})
      })
    })), (0, _jsxRuntime.jsxs)(_react.Box, {
      children: [(0, _jsxRuntime.jsx)(_react.Flex, {
        children: (0, _jsxRuntime.jsx)(_react.Text, _objectSpread(_objectSpread({}, headingStyle), {}, {
          "data-testid": (0, _utils.formatDataTestId)(baseDataTestId, 'Main-Title'),
          children: privacyPolicy.name
        }))
      }), (0, _jsxRuntime.jsxs)(_react.Flex, _objectSpread(_objectSpread({
        direction: "column"
      }, mainTextStyle), {}, {
        children: [(0, _jsxRuntime.jsx)(_react.Box, {
          "data-testid": (0, _utils.formatDataTestId)(baseDataTestId, 'Main-Description'),
          className: "formatLinks",
          children: (0, _utils.renderSanitizedHtml)((_privacyPolicy$descri = privacyPolicy === null || privacyPolicy === void 0 ? void 0 : privacyPolicy.description) !== null && _privacyPolicy$descri !== void 0 ? _privacyPolicy$descri : '')
        }), (0, _jsxRuntime.jsx)(_react.Link, _objectSpread(_objectSpread({
          href: privacyPolicy.linkSrc,
          isExternal: true
        }, privacyNoticeText), {}, {
          "data-testid": (0, _utils.formatDataTestId)(baseDataTestId, 'PrivacyNotice'),
          children: privacyPolicy.linkLabel
        }))]
      })), (0, _jsxRuntime.jsxs)(_react.Flex, _objectSpread(_objectSpread({
        onClick: toggleSection
      }, expandableWrapperStyle(isOpen)), {}, {
        "data-testid": (0, _utils.formatDataTestId)(baseDataTestId, 'ExpandButton'),
        children: [(0, _jsxRuntime.jsx)(_react.Text, _objectSpread(_objectSpread({}, labelHeadingStyle), {}, {
          children: privacyPolicy.moreInfoLabel
        })), isOpen ? (0, _jsxRuntime.jsx)(_atoms.ChevronUp24, {}) : (0, _jsxRuntime.jsx)(_atoms.ChevronDown24, {})]
      })), isOpen && (0, _jsxRuntime.jsx)(_react.Flex, {
        direction: "column",
        "data-testid": (0, _utils.formatDataTestId)(baseDataTestId, 'Expanded-Wrapper'),
        children: privacyPolicy === null || privacyPolicy === void 0 ? void 0 : privacyPolicy.moreInfo.map(item => (0, _jsxRuntime.jsxs)(_react.Box, {
          "data-testid": (0, _utils.formatDataTestId)(baseDataTestId, 'Expanded-Item-Wrapper'),
          children: [item.image && (0, _jsxRuntime.jsx)(_react.Image, {
            src: item.image,
            py: "md",
            alt: ""
          }), item.description && (0, _jsxRuntime.jsx)(_react.Box, _objectSpread(_objectSpread({}, textStyle), {}, {
            className: "formatLinks",
            children: (0, _utils.renderSanitizedHtml)(item.description)
          }), item.description)]
        }, item.description))
      })]
    })]
  }));
}
var mainTextStyle = {
  lineHeight: '2',
  fontSize: 'sm',
  fontWeight: 'normal',
  color: 'darkGrey1'
};
var headingStyle = {
  fontSize: 'sm',
  fontWeight: 'semibold',
  lineHeight: '2',
  as: 'h3',
  color: 'darkGrey1'
};
var privacyNoticeText = {
  textDecoration: 'underline',
  cursor: 'pointer',
  color: 'btnSecondaryEnabled'
};
var iconWrapperStyle = {
  py: '0',
  paddingRight: 'sm'
};
var currentContainerStyle = {
  mt: {
    mobile: 'xl',
    md: '3xl'
  },
  padding: 'md',
  border: '1px solid var(--chakra-colors-lightGrey2)',
  borderRadius: 'var(--chakra-space-xs)',
  direction: 'row'
};
var labelHeadingStyle = {
  fontSize: 'sm',
  fontWeight: 'semibold',
  lineHeight: '2',
  as: 'h3'
};
var expandableWrapperStyle = isOpen => {
  return {
    display: 'inline-flex',
    alignItems: 'center',
    mt: 'sm',
    cursor: 'pointer',
    direction: 'row',
    pb: isOpen ? 'md' : '0'
  };
};
var textStyle = {
  lineHeight: '2',
  fontSize: 'sm',
  fontWeight: 'normal',
  pb: 'md'
};