"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = HotelBadgesComponent;
exports.getColor = getColor;
var _react = require("@chakra-ui/react");
var _atoms = require("@whitbread-eos/atoms");
var _utils = require("@whitbread-eos/utils");
var _nextI18next = require("next-i18next");
var _react2 = require("react");
var _constants = require("../../utils/constants");
var _jsxRuntime = require("react/jsx-runtime");
function HotelBadgesComponent(_ref) {
  var {
    brand,
    hotelFacilities,
    messagingFlag,
    isLoading,
    isError,
    error,
    hubBadge
  } = _ref;
  var {
    t
  } = (0, _nextI18next.useTranslation)(['common']);
  var badges = facility => _constants.PREMIER_PLUS_FACILITY_CODE === facility.code || _constants.STANDARD_EXTRA_FACILITY_CODE === facility.code;
  var visibleBadges = item => item.isVisible;
  var displayedBadges = (0, _react2.useMemo)(() => (hotelFacilities || []).filter(badges).filter(visibleBadges), [hotelFacilities]);
  if (isLoading) {
    return (0, _jsxRuntime.jsx)(_react.Text, {
      children: t('searchresults.list.hotel.loading')
    });
  }
  if (isError) {
    return (0, _jsxRuntime.jsx)(_react.Text, {
      children: error.message
    });
  }
  if (!displayedBadges.length && !(messagingFlag !== null && messagingFlag !== void 0 && messagingFlag.text) && !hubBadge) {
    return null;
  }
  return (0, _jsxRuntime.jsxs)(_react.Wrap, {
    "data-testid": "hdp_badgesList",
    align: "center",
    children: [(brand === null || brand === void 0 ? void 0 : brand.toLowerCase()) === 'hub' && !!hubBadge && (0, _jsxRuntime.jsx)(_react.WrapItem, {
      children: (0, _jsxRuntime.jsx)(_atoms.Icon, {
        src: (0, _utils.formatAssetsUrl)(hubBadge)
      })
    }), displayedBadges.map(badge => {
      var _badge$name, _badge$code, _badge$name2;
      return badge.code === _constants.PREMIER_PLUS_FACILITY_CODE ? (0, _jsxRuntime.jsx)(_react.WrapItem, {
        children: (0, _jsxRuntime.jsx)(_atoms.Tooltip, {
          description: t('hoteldetails.title.badge.ultimate.tooltip'),
          variant: "facilities",
          placement: "bottom-start",
          closeOnClick: false,
          ml: "2rem",
          colorScheme: getColor(badge.code),
          children: renderHotelBadge(badge.code, (_badge$name = badge.name) !== null && _badge$name !== void 0 ? _badge$name : '')
        })
      }, badge.name) : (0, _jsxRuntime.jsx)(_react.WrapItem, {
        children: renderHotelBadge((_badge$code = badge.code) !== null && _badge$code !== void 0 ? _badge$code : '', (_badge$name2 = badge.name) !== null && _badge$name2 !== void 0 ? _badge$name2 : '')
      }, badge.name);
    }), (messagingFlag === null || messagingFlag === void 0 ? void 0 : messagingFlag.text) && (messagingFlag === null || messagingFlag === void 0 ? void 0 : messagingFlag.text) !== 'hub' && (0, _jsxRuntime.jsx)(_react.WrapItem, {
      children: renderHotelBadge(_constants.NEW_HOTEL_MESSAGING_FLAG_TEXT, messagingFlag.text)
    })]
  });
  function renderHotelBadge(badgeColor, badgeText) {
    return (0, _jsxRuntime.jsx)(_react.Box, {
      children: (0, _jsxRuntime.jsx)(_atoms.Badge, {
        variant: "primary",
        badgecolor: getColor(badgeColor),
        cursor: "pointer",
        children: badgeText
      })
    });
  }
}
function getColor(label) {
  switch (label) {
    case _constants.BIGGER_ROOM_CODE:
      return 'lightPurple';
    case _constants.PREMIER_PLUS_FACILITY_CODE:
      return 'primary';
    case _constants.NEW_HOTEL_MESSAGING_FLAG_TEXT:
    case _constants.NEW_ROOMS_MESSAGING_FLAG_TEXT:
    case _constants.OPENING_SOON_MESSAGING_FLAG_TEXT:
      return 'maroon';
    case _constants.PREMIER_EXTRA_CODE:
      return 'blue';
    default:
      return 'darkPink';
  }
}