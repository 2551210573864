"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.FRAUD_SECURITY_CHECK = void 0;
var _taggedTemplateLiteral2 = _interopRequireDefault(require("@babel/runtime/helpers/taggedTemplateLiteral"));
var _graphqlRequest = require("graphql-request");
var _templateObject;
var FRAUD_SECURITY_CHECK = exports.FRAUD_SECURITY_CHECK = (0, _graphqlRequest.gql)(_templateObject || (_templateObject = (0, _taggedTemplateLiteral2.default)(["\n  mutation securityCheck($basketReference: String!, $card: CardCcui!) {\n    initiateSecurityCheck(\n      basketReference: $basketReference\n      securityCheckRequest: { card: $card }\n    ) {\n      fraudCheckDecision\n      fraudCheckResult\n    }\n  }\n"])));