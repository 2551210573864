"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.CardName = void 0;
var CardName = exports.CardName = function (CardName) {
  CardName["PIBA_UK"] = "PIBA UK";
  CardName["PIBA_EU"] = "PIBA EU";
  return CardName;
}({});