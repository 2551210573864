"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = ResultsListContainer;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _reactQuery = require("@tanstack/react-query");
var _atoms = require("@whitbread-eos/atoms");
var _molecules = require("@whitbread-eos/molecules");
var _utils = require("@whitbread-eos/utils");
var _account = require("../../account");
var _BartBookingInformationCard = _interopRequireDefault(require("../BartCard/BartBookingInformationCard.container"));
var _jsxRuntime = require("react/jsx-runtime");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function ResultsListContainer(_ref) {
  var _resultsData$searchDa;
  var {
    baseDataTestId,
    inputValues,
    t,
    resultsData,
    changePage,
    bartHotelName = '',
    updateStatusAfterCancel,
    setInputValuesInSessionStorage: _setInputValuesInSessionStorage,
    enhancedSearch = false
  } = _ref;
  var queryClient = (0, _reactQuery.useQueryClient)();
  var {
    language
  } = (0, _utils.useCustomLocale)();
  var headerTitles = [{
    id: 'BookedFor',
    text: t('ccui.manageBooking.resultList.bookedFor')
  }, {
    id: 'BookedBy',
    text: t('ccui.manageBooking.resultList.bookedBy')
  }, {
    id: 'Hotel',
    text: t('ccui.manageBooking.resultList.hotel')
  }, {
    id: 'Date',
    text: t('ccui.manageBooking.resultList.date')
  }, {
    id: 'Status',
    text: t('ccui.manageBooking.resultList.status')
  }, {
    id: 'SourcePms',
    text: t('ccui.manageBooking.resultList.sourcePms')
  }, {
    id: 'Expand',
    text: ''
  }];
  var operaConfNumber = resultsData === null || resultsData === void 0 || (_resultsData$searchDa = resultsData.searchData[0]) === null || _resultsData$searchDa === void 0 ? void 0 : _resultsData$searchDa.operaConfNumber;
  var bartCard = props => {
    return (0, _jsxRuntime.jsx)(_BartBookingInformationCard.default, _objectSpread({}, props));
  };
  var operaCard = props => {
    return (0, _jsxRuntime.jsx)(_account.BookingInfoCard, _objectSpread(_objectSpread({}, props), {}, {
      operaConfNumber: operaConfNumber,
      updateStatusAfterCancel: updateStatusAfterCancel,
      inputValues: inputValues,
      setInputValuesInSessionStorage: (inputValues, bookingReferenceRPB) => {
        _setInputValuesInSessionStorage === null || _setInputValuesInSessionStorage === void 0 || _setInputValuesInSessionStorage(inputValues, bookingReferenceRPB);
      }
    }));
  };
  var bartId = resultsData.bartId ? resultsData.bartId : null;
  var isCancelledError = (resultsData === null || resultsData === void 0 ? void 0 : resultsData.error) && 'silent' in resultsData.error;
  var loadingResultsError = (resultsData === null || resultsData === void 0 ? void 0 : resultsData.isError) && !isCancelledError;
  var onPageChanged = () => {
    enhancedSearch ? changePage(resultsData.pageNumber + 1) : changePage(resultsData.offset);
  };
  return (0, _jsxRuntime.jsx)(_atoms.ErrorBoundary, {
    children: (0, _jsxRuntime.jsx)(_molecules.ResultList, {
      queryClient: queryClient,
      loading: resultsData.isLoading,
      isSuccess: resultsData.isSuccess,
      baseDataTestId: baseDataTestId,
      rows: resultsData.searchData,
      t: t,
      headerTitles: headerTitles,
      bartHotelName: bartHotelName,
      bartCard: bartCard,
      operaCard: operaCard,
      bookerLastName: inputValues.bookerLastName,
      arrivalDate: inputValues.arrivalDate,
      bartId: bartId,
      error: resultsData.error,
      isError: loadingResultsError,
      hasMore: resultsData.hasMore,
      limitExceeded: resultsData.limitExceeded,
      changePage: onPageChanged,
      language: language,
      updateStatusAfterCancel: updateStatusAfterCancel,
      enhancedSearch: enhancedSearch
    })
  });
}