"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = HotelRestaurantContainer;
var _utils = require("@whitbread-eos/utils");
var _HotelRestaurant = _interopRequireDefault(require("./HotelRestaurant.component"));
var _jsxRuntime = require("react/jsx-runtime");
function HotelRestaurantContainer() {
  var {
    restaurant,
    isLoading,
    isError,
    error
  } = (0, _utils.useStaticHotelInformation)();
  return (0, _jsxRuntime.jsx)(_HotelRestaurant.default, {
    isLoading,
    isError,
    error,
    data: restaurant
  });
}