"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _api = require("@whitbread-eos/api");
var PRE_AUTHORISED_CHARGES = [{
  id: 1,
  label: _api.ACCOUNT_TO_COMPANY_ALLOWANCES.BREAKFAST,
  key: 'ccui.accountToCompanyCharges.option1'
}, {
  id: 2,
  label: _api.ACCOUNT_TO_COMPANY_ALLOWANCES.CAR_PARKING,
  key: 'ccui.accountToCompanyCharges.option2'
}];
var _default = exports.default = PRE_AUTHORISED_CHARGES;