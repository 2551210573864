"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _react = require("@chakra-ui/react");
var _utils = require("@whitbread-eos/utils");
var _nextI18next = require("next-i18next");
var _propTypes = _interopRequireDefault(require("prop-types"));
var _react2 = _interopRequireDefault(require("react"));
var _icons = require("../../assets/icons");
var _Notification = _interopRequireDefault(require("../Notification"));
var _StaticFooter = _interopRequireDefault(require("./StaticFooter"));
var _StaticHeader = _interopRequireDefault(require("./StaticHeader"));
var _jsxRuntime = require("react/jsx-runtime");
class ErrorBoundary extends _react2.default.Component {
  constructor(props) {
    var _this$onError, _props$isFooterBounda, _props$isHeaderBounda, _props$noContentBound, _props$errorMessage;
    super(props);
    (0, _defineProperty2.default)(this, "state", {
      error: undefined
    });
    (0, _defineProperty2.default)(this, "onError", console.error);
    this.onError = (_this$onError = this.onError) !== null && _this$onError !== void 0 ? _this$onError : props.onError;
    this.t = props.t;
    this.isFooterBoundary = (_props$isFooterBounda = props.isFooterBoundary) !== null && _props$isFooterBounda !== void 0 ? _props$isFooterBounda : false;
    this.isHeaderBoundary = (_props$isHeaderBounda = props.isHeaderBoundary) !== null && _props$isHeaderBounda !== void 0 ? _props$isHeaderBounda : false;
    this.noContentBoundary = (_props$noContentBound = props.noContentBoundary) !== null && _props$noContentBound !== void 0 ? _props$noContentBound : false;
    this.errorMessage = (_props$errorMessage = props.errorMessage) !== null && _props$errorMessage !== void 0 ? _props$errorMessage : this.t('errors.sorry');
  }
  static getDerivedStateFromError(error) {
    return {
      error
    };
  }
  componentDidCatch(error, errorInfo) {
    var sessionTracing = (0, _utils.getClientDefaultSessionTracing)();
    _utils.clientLogger.error({
      label: 'ERROR_BOUNDARY',
      err: error.message,
      errorInfo: errorInfo,
      sessionId: sessionTracing['WB-SESSION-ID']
    });
  }
  componentDidUpdate(prevProps, prevState) {
    if (this.state.error && !prevState.error) {
      this.onError(this.state.error);
    }
  }
  render() {
    if (this.state.error) {
      if (this.isFooterBoundary) {
        return (0, _jsxRuntime.jsx)(_StaticFooter.default, {});
      }
      if (this.isHeaderBoundary) {
        return (0, _jsxRuntime.jsx)(_StaticHeader.default, {});
      }
      if (this.noContentBoundary) {
        return null;
      }
      return (0, _jsxRuntime.jsx)(_react.Box, {
        mt: "lg",
        children: (0, _jsxRuntime.jsx)(_Notification.default, {
          status: "warning",
          description: this.errorMessage,
          variant: "alert",
          maxW: "full",
          svg: (0, _jsxRuntime.jsx)(_icons.Alert, {})
        })
      });
    }
    return this.props.children;
  }
}
(0, _defineProperty2.default)(ErrorBoundary, "propTypes", {
  children: _propTypes.default.any,
  onError: _propTypes.default.func,
  t: _propTypes.default.func,
  isFooterBoundary: _propTypes.default.bool,
  isHeaderBoundary: _propTypes.default.bool,
  noContentBoundary: _propTypes.default.bool,
  errorMessage: _propTypes.default.string
});
var _default = exports.default = (0, _nextI18next.withTranslation)(['common'])(ErrorBoundary);