"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.GET_DASHBOARD_BOOKING_CONFIRMATION = void 0;
var _taggedTemplateLiteral2 = _interopRequireDefault(require("@babel/runtime/helpers/taggedTemplateLiteral"));
var _graphqlRequest = require("graphql-request");
var _templateObject;
var GET_DASHBOARD_BOOKING_CONFIRMATION = exports.GET_DASHBOARD_BOOKING_CONFIRMATION = (0, _graphqlRequest.gql)(_templateObject || (_templateObject = (0, _taggedTemplateLiteral2.default)(["\n  query bookingConfirmation(\n    $basketReference: String!\n    $language: String!\n    $country: String!\n    $bookingChannel: String\n  ) {\n    bookingConfirmation(\n      basketReference: $basketReference\n      language: $language\n      country: $country\n      bookingChannel: $bookingChannel\n    ) {\n      reservationByIdList {\n        reservationId\n        billing {\n          address {\n            addressLine1\n            postalCode\n          }\n          title\n          telephone\n          firstName\n          lastName\n          email\n        }\n        reservationGuestList {\n          givenName\n          surName\n        }\n        gdsReferenceNumber\n        roomStay {\n          checkInTime\n          checkOutTime\n          ratePlanCode\n          arrivalDate\n          departureDate\n          bookingChannel\n          roomPrice\n          cot\n          adultsNumber\n          roomExtraInfo {\n            roomName\n          }\n          childrenNumber\n        }\n        paymentCard {\n          cardNumberMasked\n        }\n        reservationOverrideReasons {\n          reasonCode\n          callerName\n          managerName\n          reasonName\n        }\n        reservationOverridden\n        guaranteeCode\n        reservationStatus\n        additionalGuestInfo {\n          purposeOfStay\n        }\n      }\n      balanceOutstanding\n      currencyCode\n      newTotal\n      policyCode\n      previousTotal\n      totalCost\n      hotelId\n      hotelName\n      bookingFlowId\n      rateMessage\n      bookingReference\n      basketReference\n      bookingSpinnerConfig {\n        order\n        seconds\n        text\n      }\n    }\n  }\n"])));