"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.BookingSummaryCost = void 0;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _react = require("@chakra-ui/react");
var _utils = require("@whitbread-eos/utils");
var _jsxRuntime = require("react/jsx-runtime");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
var BookingSummaryCost = props => {
  var {
    language,
    baseDataTestId,
    currency,
    summaryOfPayments,
    summaryOfPaymentsLabels
  } = props;
  var showDonation = summaryOfPayments.charitable > 0;
  var shouldRenderPayOnArrival = summaryOfPayments.balancePaid == 0 && summaryOfPayments.payOnArrival > 0;
  var shouldRenderPIBA = summaryOfPayments.balanceAuthorised > 0;
  var showNonRefundable = summaryOfPayments.nonRefundable > 0;
  var showRefund = summaryOfPayments.refund < 0;
  var showArrivalPrice = summaryOfPayments.payOnArrival >= 0;
  var generatePriceLabel = (priceLabel, styles) => {
    return (0, _jsxRuntime.jsx)(_react.Text, _objectSpread(_objectSpread({}, styles), {}, {
      children: priceLabel
    }));
  };
  var generatePrice = (price, styles) => {
    return (0, _jsxRuntime.jsx)(_react.Text, _objectSpread(_objectSpread({
      "data-testid": (0, _utils.formatDataTestId)(baseDataTestId, "".concat(price))
    }, styles), {}, {
      children: price && price === 0 && price.toFixed(2) || (0, _utils.formatPriceWithDecimal)(language, (0, _utils.formatCurrency)(currency), price, true)
    }));
  };
  var renderPayNowPrices = () => {
    return (0, _jsxRuntime.jsxs)(_react.Flex, _objectSpread(_objectSpread({}, costWrapperStyles), {}, {
      children: [showDonation && (0, _jsxRuntime.jsxs)(_react.Flex, _objectSpread(_objectSpread({}, flexWrapperStyles), {}, {
        children: [(0, _jsxRuntime.jsx)(_react.Flex, _objectSpread(_objectSpread({}, labelWrapperStyles), {}, {
          children: generatePriceLabel(summaryOfPaymentsLabels.donation, _objectSpread({}, labelTextStyles))
        })), (0, _jsxRuntime.jsx)(_react.Flex, _objectSpread(_objectSpread({}, priceWrapperStyles), {}, {
          children: generatePrice(summaryOfPayments.charitable, _objectSpread({}, labelTextStyles))
        }))]
      })), showNonRefundable && (0, _jsxRuntime.jsxs)(_react.Flex, _objectSpread(_objectSpread({}, flexWrapperStyles), {}, {
        children: [(0, _jsxRuntime.jsx)(_react.Flex, _objectSpread(_objectSpread({}, labelWrapperStyles), {}, {
          children: generatePriceLabel(summaryOfPaymentsLabels.nonRefundable, _objectSpread({}, labelTextStyles))
        })), (0, _jsxRuntime.jsx)(_react.Flex, _objectSpread(_objectSpread({}, priceWrapperStyles), {}, {
          children: generatePrice(summaryOfPayments.nonRefundable, _objectSpread({}, labelTextStyles))
        }))]
      })), showRefund && (0, _jsxRuntime.jsxs)(_react.Flex, _objectSpread(_objectSpread({}, flexWrapperStyles), {}, {
        children: [(0, _jsxRuntime.jsxs)(_react.Flex, _objectSpread(_objectSpread({}, refundStyles), {}, {
          children: [generatePriceLabel(summaryOfPaymentsLabels.refund, _objectSpread(_objectSpread({}, labelTextStyles), {}, {
            mb: '0'
          })), generatePriceLabel(summaryOfPaymentsLabels.refundTerms, _objectSpread(_objectSpread({}, labelTextStyles), {}, {
            fontSize: 'sm',
            lineHeight: '2'
          }))]
        })), (0, _jsxRuntime.jsx)(_react.Flex, _objectSpread(_objectSpread({}, priceWrapperStyles), {}, {
          children: generatePrice(summaryOfPayments.refund, _objectSpread({}, labelTextStyles))
        }))]
      })), (0, _jsxRuntime.jsxs)(_react.Flex, _objectSpread(_objectSpread({}, flexWrapperStyles), {}, {
        children: [(0, _jsxRuntime.jsx)(_react.Flex, _objectSpread(_objectSpread({}, labelWrapperStyles), {}, {
          children: generatePriceLabel(summaryOfPaymentsLabels.balancePaid, _objectSpread({}, labelTextStyles))
        })), (0, _jsxRuntime.jsx)(_react.Flex, _objectSpread(_objectSpread({}, priceWrapperStyles), {}, {
          children: generatePrice(summaryOfPayments.balancePaid, _objectSpread({}, labelTextStyles))
        }))]
      })), showArrivalPrice && (0, _jsxRuntime.jsxs)(_react.Flex, _objectSpread(_objectSpread({}, flexWrapperStyles), {}, {
        children: [(0, _jsxRuntime.jsx)(_react.Flex, _objectSpread(_objectSpread({}, labelWrapperStyles), {}, {
          children: generatePriceLabel(summaryOfPaymentsLabels.additionalAmount, _objectSpread({}, labelTextStyles))
        })), (0, _jsxRuntime.jsx)(_react.Flex, _objectSpread(_objectSpread({}, priceWrapperStyles), {}, {
          children: generatePrice(summaryOfPayments.payOnArrival, _objectSpread({}, labelTextStyles))
        }))]
      })), (0, _jsxRuntime.jsxs)(_react.Flex, _objectSpread(_objectSpread({}, flexWrapperStyles), {}, {
        children: [(0, _jsxRuntime.jsx)(_react.Flex, _objectSpread(_objectSpread({}, labelWrapperStyles), {}, {
          children: generatePriceLabel(summaryOfPaymentsLabels.totalCost, _objectSpread(_objectSpread({}, labelTextStyles), totalPriceTextStyles))
        })), (0, _jsxRuntime.jsx)(_react.Flex, _objectSpread(_objectSpread({}, priceWrapperStyles), {}, {
          children: generatePrice(summaryOfPayments.totalCost, _objectSpread(_objectSpread({}, labelTextStyles), totalPriceTextStyles))
        }))]
      }))]
    }));
  };
  var renderPayOnArrivalPrices = () => {
    return (0, _jsxRuntime.jsxs)(_react.Flex, _objectSpread(_objectSpread({}, costWrapperStyles), {}, {
      children: [showDonation && (0, _jsxRuntime.jsxs)(_react.Flex, _objectSpread(_objectSpread({}, flexWrapperStyles), {}, {
        children: [(0, _jsxRuntime.jsx)(_react.Flex, _objectSpread(_objectSpread({}, labelWrapperStyles), {}, {
          children: generatePriceLabel(summaryOfPaymentsLabels.donation, _objectSpread({}, labelTextStyles))
        })), (0, _jsxRuntime.jsx)(_react.Flex, _objectSpread(_objectSpread({}, priceWrapperStyles), {}, {
          children: generatePrice(summaryOfPayments.charitable, _objectSpread({}, labelTextStyles))
        }))]
      })), showNonRefundable && (0, _jsxRuntime.jsxs)(_react.Flex, _objectSpread(_objectSpread({}, flexWrapperStyles), {}, {
        children: [(0, _jsxRuntime.jsx)(_react.Flex, _objectSpread(_objectSpread({}, labelWrapperStyles), {}, {
          children: generatePriceLabel(summaryOfPaymentsLabels.nonRefundable, _objectSpread({}, labelTextStyles))
        })), (0, _jsxRuntime.jsx)(_react.Flex, _objectSpread(_objectSpread({}, priceWrapperStyles), {}, {
          children: generatePrice(summaryOfPayments.nonRefundable, _objectSpread({}, labelTextStyles))
        }))]
      })), (0, _jsxRuntime.jsxs)(_react.Flex, _objectSpread(_objectSpread({}, flexWrapperStyles), {}, {
        children: [(0, _jsxRuntime.jsx)(_react.Flex, _objectSpread(_objectSpread({}, labelWrapperStyles), {}, {
          children: generatePriceLabel(summaryOfPaymentsLabels.payOnArrival, _objectSpread(_objectSpread({}, labelTextStyles), totalPriceTextStyles))
        })), (0, _jsxRuntime.jsx)(_react.Flex, _objectSpread(_objectSpread({}, priceWrapperStyles), {}, {
          children: generatePrice(summaryOfPayments.payOnArrival, _objectSpread(_objectSpread({}, labelTextStyles), totalPriceTextStyles))
        }))]
      }))]
    }));
  };
  var renderPIBAPrices = () => {
    return (0, _jsxRuntime.jsxs)(_react.Flex, _objectSpread(_objectSpread({}, costWrapperStyles), {}, {
      children: [showDonation && (0, _jsxRuntime.jsxs)(_react.Flex, _objectSpread(_objectSpread({}, flexWrapperStyles), {}, {
        children: [(0, _jsxRuntime.jsx)(_react.Flex, _objectSpread(_objectSpread({}, labelWrapperStyles), {}, {
          children: generatePriceLabel(summaryOfPaymentsLabels.donation, _objectSpread({}, labelTextStyles))
        })), (0, _jsxRuntime.jsx)(_react.Flex, _objectSpread(_objectSpread({}, priceWrapperStyles), {}, {
          children: generatePrice(summaryOfPayments.charitable, _objectSpread({}, labelTextStyles))
        }))]
      })), showNonRefundable && (0, _jsxRuntime.jsxs)(_react.Flex, _objectSpread(_objectSpread({}, flexWrapperStyles), {}, {
        children: [(0, _jsxRuntime.jsx)(_react.Flex, _objectSpread(_objectSpread({}, labelWrapperStyles), {}, {
          children: generatePriceLabel(summaryOfPaymentsLabels.nonRefundable, _objectSpread({}, labelTextStyles))
        })), (0, _jsxRuntime.jsx)(_react.Flex, _objectSpread(_objectSpread({}, priceWrapperStyles), {}, {
          children: generatePrice(summaryOfPayments.nonRefundable, _objectSpread({}, labelTextStyles))
        }))]
      })), (0, _jsxRuntime.jsxs)(_react.Flex, _objectSpread(_objectSpread({}, flexWrapperStyles), {}, {
        children: [(0, _jsxRuntime.jsx)(_react.Flex, _objectSpread(_objectSpread(_objectSpread({}, labelWrapperStyles), balanceAuthorisedLabelStyles), {}, {
          children: generatePriceLabel(summaryOfPaymentsLabels.balanceAuthorised, _objectSpread(_objectSpread({}, labelTextStyles), totalPriceTextStyles))
        })), (0, _jsxRuntime.jsx)(_react.Flex, _objectSpread(_objectSpread({}, priceWrapperStyles), {}, {
          children: generatePrice(summaryOfPayments.balanceAuthorised, _objectSpread(_objectSpread({}, labelTextStyles), totalPriceTextStyles))
        }))]
      }))]
    }));
  };
  var pibaOrPayNowPrices = shouldRenderPIBA ? renderPIBAPrices() : renderPayNowPrices();
  return (0, _jsxRuntime.jsx)(_react.Flex, _objectSpread(_objectSpread({}, containerStyles), {}, {
    "data-testid": "amend-booking-summary-of-payments",
    children: shouldRenderPayOnArrival ? renderPayOnArrivalPrices() : pibaOrPayNowPrices
  }));
};
exports.BookingSummaryCost = BookingSummaryCost;
var containerStyles = {
  direction: 'row',
  justifyContent: 'flex-end',
  mt: 'lg'
};
var costWrapperStyles = {
  width: '100%',
  direction: 'column',
  alignItems: 'end',
  justifyContent: 'space-between',
  ml: '.5rem',
  mr: '0'
};
var flexWrapperStyles = {
  width: '100%',
  justifyContent: 'flex-end'
};
var labelWrapperStyles = {
  justifyContent: 'flex-end',
  marginRight: 'xs'
};
var priceWrapperStyles = {
  justifyContent: 'flex-end',
  minWidth: '6rem'
};
var refundStyles = _objectSpread(_objectSpread({}, labelWrapperStyles), {}, {
  direction: 'column'
});
var labelTextStyles = {
  lineHeight: {
    mobile: '2',
    sm: '3'
  },
  fontSize: {
    mobile: 'sm',
    md: 'md'
  },
  color: 'darkGrey1',
  as: 'h6',
  fontWeight: 'normal',
  mb: {
    mobile: 'sm',
    sm: 'md'
  },
  textAlign: 'right'
};
var totalPriceTextStyles = {
  fontSize: 'xl',
  lineHeight: '3',
  marginBottom: {
    mobile: '0',
    sm: '0'
  }
};
var balanceAuthorisedLabelStyles = {
  whiteSpace: {
    xl: 'nowrap'
  }
};