"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = useIsExternalSearch;
var _router = require("next/router");
function useIsExternalSearch(thirdPartiesParam) {
  var _router$query;
  var router = (0, _router.useRouter)();
  if (((thirdPartiesParam === null || thirdPartiesParam === void 0 ? void 0 : thirdPartiesParam.trim()) || '') === '') {
    return false;
  }
  var thirdParties = thirdPartiesParam.split(',');
  var cIdParam = router === null || router === void 0 || (_router$query = router.query) === null || _router$query === void 0 ? void 0 : _router$query.CID;
  if (cIdParam) {
    var cId = cIdParam.split('_')[0];
    return thirdParties.includes(cId);
  }
  return false;
}