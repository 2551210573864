"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = Header;
var _reactQuery = require("@tanstack/react-query");
var _HeaderAgent = _interopRequireDefault(require("./HeaderAgent"));
var _HeaderBusiness = _interopRequireDefault(require("./HeaderBusiness"));
var _HeaderLeisure = _interopRequireDefault(require("./HeaderLeisure/HeaderLeisure.container"));
var _jsxRuntime = require("react/jsx-runtime");
function Header(_ref) {
  var {
    variant,
    isIcon,
    queryClient,
    user,
    roles,
    bb = false
  } = _ref;
  return renderHeader(variant);
  function renderHeader(variant) {
    switch (variant) {
      case 'business-step':
      case 'business-default':
        return (0, _jsxRuntime.jsx)(_reactQuery.QueryClientProvider, {
          client: queryClient,
          children: (0, _jsxRuntime.jsx)(_HeaderBusiness.default, {
            variant: variant,
            queryClient: queryClient
          })
        });
      case 'default':
      case 'step':
      case 'logo':
        return (0, _jsxRuntime.jsx)(_HeaderLeisure.default, {
          isIcon: isIcon,
          variant: variant,
          queryClient: queryClient,
          bb: bb
        });
      case 'agent':
      default:
        return (0, _jsxRuntime.jsx)(_HeaderAgent.default, {
          user: user,
          roles: roles,
          queryClient: queryClient
        });
    }
  }
}