"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.SEARCH_BOOKINGS_RESULTS = exports.ENHANCED_SEARCH_BOOKINGS_RESULTS = void 0;
var _taggedTemplateLiteral2 = _interopRequireDefault(require("@babel/runtime/helpers/taggedTemplateLiteral"));
var _graphqlRequest = require("graphql-request");
var _templateObject, _templateObject2;
var SEARCH_BOOKINGS_RESULTS = exports.SEARCH_BOOKINGS_RESULTS = (0, _graphqlRequest.gql)(_templateObject || (_templateObject = (0, _taggedTemplateLiteral2.default)(["\n  query GetSearchBookingsResults(\n    $bookingReference: String\n    $bookerLastName: String\n    $guestLastName: String\n    $bookerPostcode: String\n    $hotelId: String\n    $bookerEmail: String\n    $bookerPhone: String\n    $arrivalDate: String\n    $cancellationDate: String\n    $companyName: String\n    $thirdPartyBookingReferenceNumber: String\n    $limit: Int\n    $offset: Int\n  ) {\n    searchBookings(\n      searchBookingsCriteria: {\n        bookingReference: $bookingReference\n        bookerLastName: $bookerLastName\n        guestLastName: $guestLastName\n        bookerPostcode: $bookerPostcode\n        hotelId: $hotelId\n        bookerEmail: $bookerEmail\n        bookerPhone: $bookerPhone\n        arrivalDate: $arrivalDate\n        cancellationDate: $cancellationDate\n        companyName: $companyName\n        thirdPartyBookingReferenceNumber: $thirdPartyBookingReferenceNumber\n        limit: $limit\n        offset: $offset\n      }\n    ) {\n      bookings {\n        bookingReference\n        currencyCode\n        hotelId\n        sourcePms\n        status\n        hotelName\n        stayingGuests {\n          firstName\n          lastName\n          title\n        }\n        totalCost\n        booker {\n          firstName\n          lastName\n          title\n        }\n        arrivalDate\n        departureDate\n      }\n      limit\n      hasMore\n      offset\n      totalPages\n      totalResults\n      responseLimitExceeded\n    }\n  }\n"])));
var ENHANCED_SEARCH_BOOKINGS_RESULTS = exports.ENHANCED_SEARCH_BOOKINGS_RESULTS = (0, _graphqlRequest.gql)(_templateObject2 || (_templateObject2 = (0, _taggedTemplateLiteral2.default)(["\n  query GetEnhancedSearchBookingsResults(\n    $bookingReference: String\n    $bookerLastName: String\n    $guestLastName: String\n    $bookerPostcode: String\n    $hotelId: String\n    $bookerEmail: String\n    $bookerPhone: String\n    $arrivalDateFrom: String\n    $arrivalDateTo: String\n    $cancellationDate: String\n    $companyName: String\n    $thirdPartyBookingReferenceNumber: String\n    $pageSize: Int\n    $pageNumber: Int\n    $bookingsDatabaseSearch: Boolean\n  ) {\n    searchBookingsCcui(\n      searchBookingsCcuiCriteria: {\n        bookingReference: $bookingReference\n        bookerLastName: $bookerLastName\n        arrivalDateFrom: $arrivalDateFrom\n        arrivalDateTo: $arrivalDateTo\n        guestLastName: $guestLastName\n        bookerEmail: $bookerEmail\n        hotelId: $hotelId\n        bookerPostcode: $bookerPostcode\n        bookerPhone: $bookerPhone\n        cancellationDate: $cancellationDate\n        companyName: $companyName\n        thirdPartyBookingReferenceNumber: $thirdPartyBookingReferenceNumber\n        pageSize: $pageSize\n        pageNumber: $pageNumber\n        bookingsDatabaseSearch: $bookingsDatabaseSearch\n      }\n    ) {\n      results {\n        bookingReference\n        status\n        hotelId\n        hotelName\n        sourceSystem\n        arrivalDate\n        departureDate\n        totalCost\n        currencyCode\n        booker {\n          firstName\n          lastName\n          title\n        }\n        guests {\n          title\n          firstName\n          lastName\n        }\n      }\n      operaConfNumber\n      cdhSearchResults\n      searchResults\n      pageResults\n      hasMore\n      responseLimitExceeded\n    }\n  }\n"])));