"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getListOfLanguagesForSwitcher = getListOfLanguagesForSwitcher;
var _atoms = require("@whitbread-eos/atoms");
var _utils = require("@whitbread-eos/utils");
var _jsxRuntime = require("react/jsx-runtime");
function getListOfLanguagesForSwitcher(countries) {
  var countriesForSwitcher = countries.map(country => {
    var isEnglishLanguage = country.language === 'English';
    return {
      locale: isEnglishLanguage ? 'en' : 'de',
      languageName: country.language,
      icon: (0, _jsxRuntime.jsx)(_atoms.Icon, {
        src: (0, _utils.formatAssetsUrl)(country.flagUrl),
        cursor: "pointer",
        "data-testid": isEnglishLanguage ? 'britishFlag' : 'germanFlag'
      })
    };
  });
  return countriesForSwitcher;
}