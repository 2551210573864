"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = RoomChoiceGalleryComponent;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _react = require("@chakra-ui/react");
var _atoms = require("@whitbread-eos/atoms");
var _utils = require("@whitbread-eos/utils");
var _nextI18next = require("next-i18next");
var _image = _interopRequireDefault(require("next/image"));
var _react2 = require("react");
var _jsxRuntime = require("react/jsx-runtime");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
var thumbnailHeight = {
  xl: '19.687rem',
  lg: '18.312rem',
  md: '15rem',
  sm: '15rem',
  mobile: '11.25rem',
  base: '11.25rem'
};
var ACCESSIBLE_ROOM_TYPE = 'accessible';
function loadGalleryImages(roomType, t) {
  var getGalleryImage = (roomType, accessibleImage, twinImage, accessibleTitle, twinTitle) => {
    var imageSrc = roomType === ACCESSIBLE_ROOM_TYPE ? accessibleImage : twinImage;
    return {
      alt: roomType === ACCESSIBLE_ROOM_TYPE ? t(accessibleTitle) : t(twinTitle),
      caption: roomType === ACCESSIBLE_ROOM_TYPE ? t(accessibleTitle) : t(twinTitle),
      imageSrc,
      thumbnailSrc: imageSrc
    };
  };
  return [getGalleryImage(roomType, t('accessible.loweredBath.image.path1'), t('twinroom.improvedTwin.image.path1'), 'hoteldetails.accessibleLoweredBathroom', 'twinroom.improvedTwin.title'), getGalleryImage(roomType, t('accessible.wetRoom.image.path2'), t('twinroom.standardTwin.image.path2'), 'hoteldetails.accessibleWetRoom', 'twinroom.standardTwin.title')];
}
function RoomChoiceGalleryComponent(_ref) {
  var {
    isLessThanMd,
    isLessThanLg,
    roomType
  } = _ref;
  var {
    t
  } = (0, _nextI18next.useTranslation)(['common']);
  var [activeThumbnail, setActiveThumbnail] = (0, _react2.useState)(0);
  var [isModalVisible, setIsModalVisible] = (0, _react2.useState)(false);
  var [activeSlide, setActiveSlide] = (0, _react2.useState)(0);
  var imageLoader = (0, _utils.isIVMEnabled)() ? _utils.akamaiImageLoader : undefined;
  var galleryImages = loadGalleryImages(roomType, t);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [isLessThanLg ? renderSingleThumbnail(roomType) : renderThumbnailsGrid(roomType), renderModal()]
  });
  function renderSingleThumbnail(roomType) {
    var singleThumbnailStyles = {
      position: 'relative',
      padding: '0 !important',
      marginLeft: '-2rem !important',
      width: 'calc(100% + var(--chakra-space-3xl))',
      h: {
        sm: '25.313rem',
        xs: '18.938rem',
        mobile: '11.25rem',
        base: '11.25rem'
      },
      mb: 'sm',
      cursor: 'pointer'
    };
    var iconStyles = {
      bgColor: 'baseWhite',
      mr: 'sm'
    };
    var captionFlexStyles = {
      position: 'relative',
      alignItems: 'center',
      justify: 'center',
      minH: '2rem'
    };
    return (0, _jsxRuntime.jsxs)(_react.Box, {
      position: "relative",
      "data-testid": "hdp-".concat(roomType, "-singleThumbnails"),
      onClick: () => setIsModalVisible(true),
      children: [(0, _jsxRuntime.jsxs)(_react.Box, _objectSpread(_objectSpread({}, singleThumbnailStyles), {}, {
        children: [(0, _jsxRuntime.jsx)(_image.default, {
          src: (0, _utils.formatAssetsUrl)(galleryImages[0].thumbnailSrc),
          alt: galleryImages[0].alt,
          fill: true,
          style: {
            objectFit: 'cover',
            objectPosition: 'center'
          },
          loader: imageLoader
        }), renderSeeAllPhotosButton()]
      })), (0, _jsxRuntime.jsx)(_atoms.Section, {
        children: (0, _jsxRuntime.jsxs)(_react.Flex, _objectSpread(_objectSpread({}, captionFlexStyles), {}, {
          children: [roomType === ACCESSIBLE_ROOM_TYPE && (0, _jsxRuntime.jsx)(_atoms.Icon, _objectSpread({
            svg: (0, _jsxRuntime.jsx)(_atoms.Accessible24, {})
          }, iconStyles)), (0, _jsxRuntime.jsx)(_react.Text, {
            children: galleryImages[0].caption
          })]
        }))
      })]
    });
  }
  function renderThumbnailsGrid(roomType) {
    var thumbnailGridStyles = {
      templateRows: 'repeat(2, 1fr)',
      templateColumns: 'repeat(4, 1fr)',
      gap: 3,
      w: '100%',
      h: thumbnailHeight
    };
    var firstTwoThumbnails = galleryImages.map((item, index) => (0, _jsxRuntime.jsx)(Thumbnail, {
      src: (0, _utils.formatAssetsUrl)(item.thumbnailSrc),
      alt: item.alt,
      caption: item.caption,
      onClick: () => {
        setActiveThumbnail(index);
        setIsModalVisible(true);
        setActiveSlide(index);
      },
      loadWithPriority: index === 0,
      roomType: roomType
    }, index));
    return (0, _jsxRuntime.jsx)(_atoms.Section, {
      children: (0, _jsxRuntime.jsx)(_react.Box, {
        maxW: "full",
        position: "relative",
        mb: "5xl",
        "data-testid": "hdp-".concat(roomType, "-thumbnails"),
        children: (0, _jsxRuntime.jsxs)(_react.Box, {
          children: [(0, _jsxRuntime.jsxs)(_react.Grid, _objectSpread(_objectSpread({}, thumbnailGridStyles), {}, {
            children: [(0, _jsxRuntime.jsx)(_react.GridItem, {
              rowSpan: 3,
              colSpan: 2,
              children: firstTwoThumbnails[0]
            }), (0, _jsxRuntime.jsx)(_react.GridItem, {
              rowSpan: 3,
              colSpan: 2,
              children: firstTwoThumbnails[1]
            })]
          })), renderSeeAllPhotosButton()]
        })
      })
    });
  }
  function renderSeeAllPhotosButton() {
    var seeAllPhotosButtonStyles = {
      position: 'absolute',
      bottom: 'sm',
      right: 'sm',
      width: 'auto',
      height: 'var(--chakra-space-xl)',
      fontSize: 'sm'
    };
    return (0, _jsxRuntime.jsx)(_atoms.Button, _objectSpread(_objectSpread({
      variant: "tertiary",
      size: "sm",
      "data-testid": "hdp-".concat(roomType, "-GallerySeeAllPhotos")
    }, seeAllPhotosButtonStyles), {}, {
      onClick: () => {
        setActiveThumbnail(0);
        setIsModalVisible(true);
        setActiveSlide(0);
      },
      children: t('hoteldetails.seeallphotos')
    }));
  }
  function renderModal() {
    var mobileGalleryThumbnails = galleryImages.map(item => (0, _jsxRuntime.jsx)(_react.Image, {
      src: (0, _utils.formatAssetsUrl)(item.thumbnailSrc),
      alt: item.alt
    }, item.alt));
    return (0, _jsxRuntime.jsx)(_atoms.ModalVariants, {
      isOpen: isModalVisible,
      onClose: () => {
        setIsModalVisible(false);
        setActiveThumbnail(0);
        setActiveSlide(0);
      },
      dataTestId: "hdp-".concat(roomType, "-Gallery"),
      variant: "gallery",
      variantProps: {
        title: "".concat(activeSlide + 1, "/").concat(renderImages().length)
      },
      children: isLessThanLg ? (0, _jsxRuntime.jsx)(_atoms.MobileCarousel, {
        activeSlide: activeSlide,
        setActiveSlide: setActiveSlide,
        thumbnails: mobileGalleryThumbnails,
        children: renderImages()
      }, activeThumbnail) : (0, _jsxRuntime.jsx)(_react.Box, {
        w: "41.875rem",
        px: "4xl",
        children: (0, _jsxRuntime.jsx)(_atoms.Carousel, {
          activeSlide: activeSlide,
          setActiveSlide: setActiveSlide,
          children: renderImages()
        }, activeThumbnail)
      })
    });
  }
  function renderImages() {
    var iconStyles = isLessThanMd ? {
      position: 'absolute',
      left: 'sm',
      bottom: '6xl',
      bgColor: 'baseWhite',
      borderRadius: '4px',
      p: '6px 6px 6px 8px'
    } : {
      mr: 'sm'
    };
    var captionFlexStyles = {
      position: 'relative',
      alignItems: 'center',
      justify: 'center',
      minH: '4rem'
    };
    return galleryImages.map(item => (0, _jsxRuntime.jsxs)(_react.Box, {
      children: [(0, _jsxRuntime.jsx)(_react.Box, {
        position: "relative",
        w: "full",
        h: {
          sm: '25.313rem',
          xs: '18.938rem',
          mobile: '11.25rem',
          base: '11.25rem'
        },
        children: (0, _jsxRuntime.jsx)(_image.default, {
          src: (0, _utils.formatAssetsUrl)(item.imageSrc),
          alt: "Hotel Image",
          fill: true,
          style: {
            objectFit: 'cover'
          },
          priority: false,
          loader: imageLoader
        })
      }), roomType === ACCESSIBLE_ROOM_TYPE ? (0, _jsxRuntime.jsxs)(_react.Flex, _objectSpread(_objectSpread({}, captionFlexStyles), {}, {
        "data-testid": "carousel_accessible-bathroom-image-caption",
        children: [(0, _jsxRuntime.jsx)(_atoms.Icon, _objectSpread({
          svg: (0, _jsxRuntime.jsx)(_atoms.Accessible24, {})
        }, iconStyles)), (0, _jsxRuntime.jsx)(_react.Text, {
          children: item.caption
        })]
      })) : (0, _jsxRuntime.jsx)(_react.Flex, _objectSpread(_objectSpread({}, captionFlexStyles), {}, {
        "data-testid": "carousel_twin-image-caption",
        children: (0, _jsxRuntime.jsx)(_react.Text, {
          children: item.caption
        })
      }))]
    }, item.alt));
  }
}
function Thumbnail(_ref2) {
  var {
    src,
    alt,
    caption,
    onClick,
    loadWithPriority,
    roomType
  } = _ref2;
  var [isHovering, setIsHovering] = (0, _react2.useState)(false);
  var thumbnailStyles = {
    width: '100%',
    position: 'relative',
    cursor: 'pointer',
    h: thumbnailHeight
  };
  var iconStyles = {
    bgColor: 'baseWhite',
    mr: 'sm'
  };
  var captionFlexStyles = {
    position: 'relative',
    alignItems: 'center',
    justify: 'center',
    minH: '4rem'
  };
  return (0, _jsxRuntime.jsxs)(_react.Box, {
    children: [(0, _jsxRuntime.jsx)(_react.Box, _objectSpread(_objectSpread({}, thumbnailStyles), {}, {
      onMouseEnter: () => setIsHovering(true),
      onMouseLeave: () => setIsHovering(false),
      sx: {
        '& img': {
          transition: 'filter 0.3s ease-in-out',
          '&.darken': {
            filter: 'brightness(90%)'
          }
        }
      },
      children: (0, _jsxRuntime.jsx)(_image.default, {
        src: src,
        alt: alt,
        fill: true,
        style: {
          objectFit: 'cover'
        },
        onClick: onClick,
        className: isHovering ? 'darken' : '',
        priority: loadWithPriority,
        loader: (0, _utils.isIVMEnabled)() ? _utils.akamaiImageLoader : undefined
      })
    })), (0, _jsxRuntime.jsxs)(_react.Flex, _objectSpread(_objectSpread({}, captionFlexStyles), {}, {
      children: [roomType === ACCESSIBLE_ROOM_TYPE && (0, _jsxRuntime.jsx)(_atoms.Icon, _objectSpread({
        svg: (0, _jsxRuntime.jsx)(_atoms.Accessible24, {})
      }, iconStyles)), (0, _jsxRuntime.jsx)(_react.Text, {
        children: caption
      })]
    }))]
  });
}