"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.OfferEnum = void 0;
var OfferEnum = exports.OfferEnum = function (OfferEnum) {
  OfferEnum["EMPLOYEE"] = "EMP01";
  OfferEnum["EMPLOYEE_RATE_CODE"] = "EMPLOYEE";
  return OfferEnum;
}({});