"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.contentStyles = exports.containerWrapperStyles = exports.containerLogoStyle = void 0;
exports.default = StaticHeaderComponent;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _react = require("@chakra-ui/react");
var _utils = require("@whitbread-eos/utils");
var _Logo = _interopRequireDefault(require("../../Logo"));
var _jsxRuntime = require("react/jsx-runtime");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function StaticHeaderComponent() {
  var baseDataTestId = 'StaticHeader';
  return (0, _jsxRuntime.jsx)(_react.Box, _objectSpread(_objectSpread({}, customHeaderWrapperStyles), {}, {
    "data-testid": (0, _utils.formatDataTestId)(baseDataTestId, 'Wrapper-Error'),
    children: (0, _jsxRuntime.jsx)(_react.Container, _objectSpread(_objectSpread({}, containerWrapperStyles), {}, {
      children: (0, _jsxRuntime.jsx)(_react.Grid, _objectSpread(_objectSpread({}, contentStyles), {}, {
        sx: {
          '@media print': {
            py: 'sm'
          }
        },
        children: (0, _jsxRuntime.jsx)(_react.Flex, _objectSpread(_objectSpread({}, containerLogoStyle), {}, {
          "data-testid": (0, _utils.formatDataTestId)(baseDataTestId, 'Logo'),
          children: (0, _jsxRuntime.jsx)(_Logo.default, {})
        }))
      }))
    }))
  }));
}
var customHeaderWrapperStyles = {
  w: 'var(--chakra-sizes-full)',
  backgroundColor: 'var(--chakra-colors-baseWhite)',
  boxShadow: '0 0.125rem 0.75rem var(--chakra-colors-lightGrey2)',
  h: {
    mobile: 'var(--chakra-space-4xl)',
    lg: 'var(--chakra-space-6xl)'
  }
};
var contentStyles = exports.contentStyles = {
  w: 'var(--chakra-sizes-full)',
  templateColumns: 'auto 1fr',
  alignItems: 'center'
};
var containerWrapperStyles = exports.containerWrapperStyles = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  maxW: 'var(--chakra-space-breakpoint-xl)',
  h: 'var(--chakra-sizes-full)',
  pl: {
    mobile: 'md',
    xs: '5',
    sm: 'md',
    md: 'lg',
    lg: '7',
    xl: '4.125rem'
  },
  pr: {
    mobile: 'md',
    md: '1.688rem',
    xl: '5xl'
  }
};
var containerLogoStyle = exports.containerLogoStyle = {
  justifyContent: 'center',
  alignItems: 'center'
};