"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.TypeOfCaller = void 0;
var TypeOfCaller = exports.TypeOfCaller = function (TypeOfCaller) {
  TypeOfCaller["ANY_CUSTOMER"] = "ANY_CUSTOMER";
  TypeOfCaller["ACCESIBLE_CUSTOMER"] = "ACCESIBLE_CUSTOMER";
  return TypeOfCaller;
}({});