"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = useGetCountryLanguage;
var _navigation = require("next/navigation");
var _server = require("../server");
function useGetCountryLanguage() {
  var pathname = (0, _navigation.usePathname)();
  var locale = (0, _server.getLocaleByPathname)(pathname);
  return (0, _server.getCountryLanguageByLocale)(locale);
}