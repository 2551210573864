"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _react = require("@chakra-ui/react");
var _api = require("@whitbread-eos/api");
var _atoms = require("@whitbread-eos/atoms");
var _utils = require("@whitbread-eos/utils");
var _nextI18next = require("next-i18next");
var _react2 = require("react");
var _reactHookForm = require("react-hook-form");
var _DependentInfo = _interopRequireDefault(require("./DependentInfo"));
var _RemoveDependentConfirmationModal = _interopRequireDefault(require("./RemoveDependentConfirmationModal"));
var _common = require("./common");
var _jsxRuntime = require("react/jsx-runtime");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
var Dependents = _ref => {
  var {
    control,
    formField,
    errors,
    getValues,
    handleSetValue
  } = _ref;
  var {
    dependents
  } = formField.props;
  var [confirmationModalOpen, setConfirmationModalOpen] = (0, _react2.useState)(false);
  var [dependentIndexToBeRemoved, setDependentIndexToBeRemoved] = (0, _react2.useState)();
  var [dependentIdToBeUpdated, setDependentIdToBeUpdated] = (0, _react2.useState)();
  var [accordionIndex, setAccordionIndex] = (0, _react2.useState)(0);
  var [dropDownKey, setDropDownKey] = (0, _react2.useState)(1);
  var {
    field: dependentField
  } = (0, _reactHookForm.useController)({
    name: 'dependent',
    control
  });
  var {
    fields,
    remove,
    append
  } = (0, _reactHookForm.useFieldArray)({
    control,
    name: 'dependents'
  });
  var {
    t
  } = (0, _nextI18next.useTranslation)();
  var {
    language,
    country
  } = (0, _utils.useCustomLocale)();
  var [nationalities, setNationalities] = (0, _react2.useState)([]);
  var {
    data: countriesData,
    isSuccess: countriesRequestSuccess
  } = (0, _utils.useQueryRequest)(['GetCountries', country, language, _api.SITE_LEISURE], _api.GET_COUNTRIES, {
    country,
    language,
    site: _api.SITE_LEISURE
  });
  (0, _react2.useEffect)(() => {
    if (countriesRequestSuccess && !(nationalities !== null && nationalities !== void 0 && nationalities.length)) {
      var _countriesData$countr;
      var sortedCountries = (0, _utils.getSortedCountriesByCurrentLang)(countriesData === null || countriesData === void 0 || (_countriesData$countr = countriesData.countries) === null || _countriesData$countr === void 0 ? void 0 : _countriesData$countr.countries, language);
      setNationalities(sortedCountries === null || sortedCountries === void 0 ? void 0 : sortedCountries.map(_ref2 => {
        var {
          nationality = '',
          countryCode,
          flagSrc
        } = _ref2;
        return {
          value: countryCode,
          label: nationality || '',
          image: flagSrc
        };
      }));
    }
  }, [countriesRequestSuccess, countriesData]);
  var addDependent = fieldsToAdd => {
    for (var i = 0; i < fieldsToAdd; i++) {
      append({
        nationality: {},
        passport: '',
        firstname: '',
        lastname: '',
        dateofbirth: ''
      });
    }
  };
  var removeDependentConfirmation = index => {
    setDependentIndexToBeRemoved(index);
    setConfirmationModalOpen(true);
  };
  var handleDependentDelete = () => {
    if (dependentIndexToBeRemoved !== undefined) {
      remove(dependentIndexToBeRemoved);
      dependentField.onChange(String(dependentField.value - 1));
      setDependentIndexToBeRemoved(undefined);
    }
    if (dependentIdToBeUpdated) {
      removeDependentFields();
      addDependent(+dependentIdToBeUpdated);
      dependentField.onChange(dependentIdToBeUpdated);
      setDependentIdToBeUpdated(undefined);
    }
    setConfirmationModalOpen(false);
  };
  var removeDependentFields = () => {
    fields.forEach(() => remove(0));
    dependentField.onChange('0');
  };
  var handleDependentChange = count => {
    var fieldsToAdd = getFieldCount(count);
    if (fieldsToAdd > 0) {
      dependentField.onChange(count);
      addDependent(fieldsToAdd);
    } else if (fieldsToAdd < 0) openConfirmationModal(count);
  };
  var openConfirmationModal = count => {
    setDependentIdToBeUpdated(count);
    setConfirmationModalOpen(true);
  };
  var handleConfirmationModalClose = () => {
    setConfirmationModalOpen(false);
    setDependentIdToBeUpdated(undefined);
    setDependentIndexToBeRemoved(undefined);
    setDropDownKey(prevKey => prevKey + 1);
  };
  var getFieldCount = count => +count - fields.length;
  (0, _react2.useEffect)(() => {
    var _Object$keys;
    if (errors && (_Object$keys = Object.keys(errors)) !== null && _Object$keys !== void 0 && _Object$keys.length) {
      setAccordionIndex(0);
    }
  }, [errors]);
  return (0, _jsxRuntime.jsx)(_atoms.Accordion, {
    accordionItems: [{
      onToggleSection: () => (0, _common.handleAccordionToggle)(accordionIndex, setAccordionIndex),
      title: t('precheckin.additionalguests.title'),
      content: (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
        children: [(0, _jsxRuntime.jsxs)(_react.Box, {
          children: [(0, _jsxRuntime.jsx)(_react.Flex, {
            wrap: "wrap",
            justifyContent: 'space-between',
            flexDirection: "column",
            children: (0, _jsxRuntime.jsx)(_react.Box, _objectSpread(_objectSpread({}, _common.bookDetailGridStyles), {}, {
              "data-testid": (0, _utils.formatDataTestId)(formField.testid, 'Dependents'),
              children: (0, _jsxRuntime.jsx)(_reactHookForm.Controller, {
                name: "dependent",
                control: control,
                render: _ref3 => {
                  var _errors$dependent;
                  var {
                    field: {
                      value,
                      onBlur
                    }
                  } = _ref3;
                  return (0, _jsxRuntime.jsx)(_atoms.Dropdown, {
                    onChange: o => handleDependentChange(o === null || o === void 0 ? void 0 : o.id),
                    showStatusIcon: false,
                    options: dependents,
                    dataTestId: (0, _utils.formatDataTestId)(formField.testid, 'Dependents'),
                    label: t('precheckin.details.dependant'),
                    hasError: Boolean(errors === null || errors === void 0 || (_errors$dependent = errors.dependent) === null || _errors$dependent === void 0 ? void 0 : _errors$dependent.message),
                    selectedId: value,
                    matchWidth: true,
                    dropdownStyles: _objectSpread(_objectSpread({}, _common.dropdownStyles), {}, {
                      menuButtonStyles: (0, _common.renderDropdownStyles)(false)
                    }),
                    onBlur: onBlur
                  }, dropDownKey);
                }
              })
            }))
          }), Boolean(fields.length) && (0, _jsxRuntime.jsx)(_jsxRuntime.Fragment, {
            children: fields.map((dependent, i) => {
              return (0, _jsxRuntime.jsx)(_DependentInfo.default, {
                getValues: getValues,
                index: i,
                removeDependent: removeDependentConfirmation,
                control: control,
                nationalities: nationalities,
                formField: formField,
                errors: errors,
                handleSetValue: handleSetValue
              }, dependent.id);
            })
          })]
        }), (0, _jsxRuntime.jsx)(_RemoveDependentConfirmationModal.default, {
          isOpen: confirmationModalOpen,
          onClose: handleConfirmationModalClose,
          handleDependentDelete: handleDependentDelete
        })]
      })
    }],
    bgColor: "var(--chakra-colors-baseWhite)",
    allowMultiple: false,
    accordionOverwriteStyles: _objectSpread(_objectSpread({}, _common.accordionOverwriteStyles), {}, {
      container: _objectSpread(_objectSpread({}, _common.accordionOverwriteStyles === null || _common.accordionOverwriteStyles === void 0 ? void 0 : _common.accordionOverwriteStyles.container), {}, {
        index: [accordionIndex]
      })
    })
  });
};
var _default = exports.default = Dependents;