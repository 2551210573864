"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = ResultRow;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _react = require("@chakra-ui/react");
var _utils = require("@whitbread-eos/utils");
var _UpdateAccountForm = _interopRequireDefault(require("../ccuiFormConfig/updateForm/UpdateAccountForm.component"));
var _jsxRuntime = require("react/jsx-runtime");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function ResultRow(_ref) {
  var {
    handleClickRow,
    selectedRow,
    dataForUpdateForm,
    cells,
    accountId,
    baseDataTestId,
    rowNr
  } = _ref;
  var rowCount = cells.length + 1;
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_react.Tr, {
      cursor: "pointer",
      "data-testid": (0, _utils.formatDataTestId)(baseDataTestId, "Table-Row-".concat(rowNr)),
      onClick: () => {
        handleClickRow(_objectSpread(_objectSpread({}, cells), {}, {
          rowNr,
          accountId
        }));
      },
      children: cells.map(cell => (0, _jsxRuntime.jsx)(_react.Td, _objectSpread(_objectSpread({
        "data-testid": (0, _utils.formatDataTestId)(baseDataTestId, "Table-Cell-".concat(cell.id))
      }, resultRowCellStyle(selectedRow === null || selectedRow === void 0 ? void 0 : selectedRow.rowNr, rowNr)), {}, {
        children: cell.value
      }), cell.id))
    }), (selectedRow === null || selectedRow === void 0 ? void 0 : selectedRow.rowNr) === rowNr && (0, _jsxRuntime.jsx)(_react.Tr, {
      children: (0, _jsxRuntime.jsx)(_react.Td, _objectSpread(_objectSpread({
        colSpan: rowCount
      }, cardRowStyle), {}, {
        children: (0, _jsxRuntime.jsx)(_UpdateAccountForm.default, {
          dataForUpdateForm: dataForUpdateForm,
          selectedRow: selectedRow
        })
      }))
    })]
  });
}
var cardRowStyle = {
  borderTop: 'none',
  backgroundColor: 'lightGrey5'
};
var resultRowCellStyle = (SelectedRowNr, rowNr) => {
  return {
    borderBottom: SelectedRowNr === rowNr ? 'none' : 'var(--chakra-borders-1px)',
    backgroundColor: SelectedRowNr === rowNr ? 'lightGrey5' : 'inherit',
    _first: {
      maxWidth: '400px',
      whiteSpace: 'noWrap',
      overflow: 'hidden'
    },
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  };
};