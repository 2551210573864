"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = SingleDatePicker;
exports.getFormattedDate = getFormattedDate;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _objectWithoutProperties2 = _interopRequireDefault(require("@babel/runtime/helpers/objectWithoutProperties"));
var _icons = require("@chakra-ui/icons");
var _react = require("@chakra-ui/react");
var _utils = require("@whitbread-eos/utils");
var _classnames = _interopRequireDefault(require("classnames"));
var _add = _interopRequireDefault(require("date-fns/add"));
var _endOfMonth = _interopRequireDefault(require("date-fns/endOfMonth"));
var _endOfWeek = _interopRequireDefault(require("date-fns/endOfWeek"));
var _format = _interopRequireDefault(require("date-fns/format"));
var _de = _interopRequireDefault(require("date-fns/locale/de"));
var _startOfMonth = _interopRequireDefault(require("date-fns/startOfMonth"));
var _startOfWeek = _interopRequireDefault(require("date-fns/startOfWeek"));
var _react2 = require("react");
var _reactDatepicker = _interopRequireWildcard(require("react-datepicker"));
var _Calendar = _interopRequireDefault(require("../../assets/icons/Calendar"));
var _DatePickerGlobalStyles = _interopRequireDefault(require("../../theme/components/DatePickerGlobalStyles"));
var _formatters = require("../../utils/formatters");
var _Icon = _interopRequireDefault(require("../Icon"));
var _CustomHeader = _interopRequireDefault(require("./CustomHeader"));
var _jsxRuntime = require("react/jsx-runtime");
var _excluded = ["inputLabel", "name", "isDisabled", "inputPlaceholder", "defaultStartDate", "isError", "dataTestId", "isRightIcon", "onSelectDate", "locale", "popperPlacement", "skipFormatRules", "minDate", "maxDate", "dateFormat", "datepickerStyles", "displayDateFormat", "customHeader", "isClearable", "openToDate"];
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && {}.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function getFormattedDate(startDate, dateFormat, labels, skipFormatRules, locale) {
  if (skipFormatRules) {
    return startDate ? (0, _format.default)(startDate, dateFormat) : '';
  }
  var today = new Date();
  var tomorrow = (0, _add.default)(today, {
    days: 1
  });
  if ((0, _utils.isSameDate)(startDate, today)) {
    return "".concat(labels.todayLabel, " ");
  }
  if ((0, _utils.isSameDate)(startDate, tomorrow)) {
    return "".concat(labels.tomorrowLabel);
  }
  return startDate ? (0, _utils.formatDate)(startDate.toDateString(), dateFormat, locale) : '';
}
function SingleDatePicker(props) {
  var {
      inputLabel,
      name,
      isDisabled,
      inputPlaceholder,
      defaultStartDate,
      isError,
      dataTestId,
      isRightIcon,
      onSelectDate,
      locale,
      popperPlacement,
      skipFormatRules,
      minDate,
      maxDate,
      dateFormat,
      datepickerStyles,
      displayDateFormat: showDateFormat,
      customHeader,
      isClearable,
      openToDate
    } = props,
    rest = (0, _objectWithoutProperties2.default)(props, _excluded);
  var baseDatepickerStyles = (0, _react.useStyleConfig)('Datepicker');
  (0, _reactDatepicker.registerLocale)('de', _de.default);
  var [startDate, setStartDate] = (0, _react2.useState)(defaultStartDate instanceof Date ? defaultStartDate : null);
  var ref = (0, _react2.useRef)(null);
  var [isCalendarOpen, setIsCalendarOpen] = (0, _react2.useState)(false);
  var {
    inputBasicStyles,
    rightInputIconStyles,
    leftInputIconStyles,
    clearIconStyles
  } = getDatepickerStyles(datepickerStyles);
  var inputDisabledStyles = isDisabled ? disabledStyles : undefined;
  var inputStyles = _objectSpread(_objectSpread({}, inputBasicStyles(isError, isRightIcon)), inputDisabledStyles);
  var displayLabel = inputLabel;
  (0, _react2.useEffect)(() => {
    var checkIfClickedOutside = event => {
      if (isCalendarOpen && ref.current && !ref.current.contains(event.target)) {
        setIsCalendarOpen(false);
      }
    };
    document.addEventListener('mousedown', checkIfClickedOutside);
    return () => {
      document.removeEventListener('mousedown', checkIfClickedOutside);
    };
  }, [isCalendarOpen]);
  (0, _react2.useEffect)(() => {
    if (!defaultStartDate) {
      setStartDate(null);
    }
  }, [defaultStartDate]);
  return (0, _jsxRuntime.jsxs)(_react.Box, _objectSpread(_objectSpread({
    __css: baseDatepickerStyles,
    ref: ref
  }, datepickerStyles.datepickerInputElementStyles), {}, {
    children: [(0, _jsxRuntime.jsx)(_DatePickerGlobalStyles.default, {}), (0, _jsxRuntime.jsx)(_reactDatepicker.default, {
      locale: locale,
      popperPlacement: popperPlacement,
      selected: startDate,
      startDate: startDate,
      onChange: d => {
        handleDatepickerChange(d);
      },
      renderCustomHeader: customHeader ? renderCustomHeader : undefined,
      customInput: renderSingleDateInput(),
      minDate: minDate,
      maxDate: maxDate,
      useWeekdaysShort: true,
      calendarStartDay: 1,
      disabled: props.isDisabled,
      open: isCalendarOpen,
      renderDayContents: renderDayContents,
      dayClassName: getDayClassName,
      openToDate: startDate !== null && startDate !== void 0 ? startDate : openToDate || new Date()
    })]
  }));
  function getDayClassName(date) {
    var startWeek = (0, _startOfWeek.default)(date, {
      weekStartsOn: 1
    });
    var endWeek = (0, _endOfWeek.default)(date, {
      weekStartsOn: 1
    });
    var startMonth = (0, _startOfMonth.default)(date);
    var endMonth = (0, _endOfMonth.default)(date);
    var arr = [{
      'start-week': startWeek.getDate() === date.getDate()
    }, {
      'end-week': endWeek.getDate() === date.getDate()
    }, {
      'start-month': startMonth.getDate() === date.getDate()
    }, {
      'end-month': endMonth.getDate() === date.getDate()
    }, {
      'react-datepicker__day--disabled': isDayDisabled(date)
    }];
    return (0, _classnames.default)(arr);
  }
  function isDayDisabled(date) {
    if (!startDate) return false;
    var resetMinDate = minDate.setHours(12, 0, 0, 0);
    return date.setHours(12, 0, 0, 0) < resetMinDate;
  }
  function renderSingleDateInput() {
    var displayDateFormat = showDateFormat !== null && showDateFormat !== void 0 ? showDateFormat : dateFormat;
    var value = getFormattedDate(startDate, displayDateFormat, props.labels, skipFormatRules, locale);
    return (0, _jsxRuntime.jsx)(_react.FormControl, _objectSpread(_objectSpread({
      ref: ref
    }, rest), {}, {
      children: (0, _jsxRuntime.jsxs)(_react.InputGroup, {
        children: [isRightIcon ? (0, _jsxRuntime.jsx)(_react.InputRightElement, _objectSpread(_objectSpread({
          pointerEvents: "none"
        }, rightInputIconStyles(isDisabled)), {}, {
          children: (0, _jsxRuntime.jsx)(_Icon.default, {
            svg: (0, _jsxRuntime.jsx)(_Calendar.default, {})
          })
        })) : (0, _jsxRuntime.jsx)(_react.InputLeftElement, _objectSpread(_objectSpread({
          pointerEvents: "none"
        }, leftInputIconStyles(isDisabled)), {}, {
          children: (0, _jsxRuntime.jsx)(_Icon.default, {
            svg: (0, _jsxRuntime.jsx)(_Calendar.default, {})
          })
        })), isClearable && value && (0, _jsxRuntime.jsx)(_react.InputRightElement, _objectSpread(_objectSpread({}, clearIconStyles(isRightIcon)), {}, {
          onClick: () => {
            setStartDate(null);
            onSelectDate === null || onSelectDate === void 0 || onSelectDate(null);
          },
          children: (0, _jsxRuntime.jsx)(_Icon.default, {
            svg: (0, _jsxRuntime.jsx)(_icons.CloseIcon, {
              w: "3",
              color: "lightGrey1"
            })
          })
        })), displayLabel && (0, _jsxRuntime.jsx)(_react.FormLabel, _objectSpread(_objectSpread({
          "data-testid": (0, _formatters.formatDataTestId)(dataTestId, "".concat(name, "-label")),
          pos: "absolute"
        }, labelStyle(isError, value)), {}, {
          htmlFor: name,
          children: inputLabel
        })), (0, _jsxRuntime.jsx)(_react.Input, _objectSpread(_objectSpread({
          placeholder: inputPlaceholder || inputLabel,
          "aria-label": "datepicker-input",
          value: value,
          onChange: event => {
            var _props$onInputChange;
            props === null || props === void 0 || (_props$onInputChange = props.onInputChange) === null || _props$onInputChange === void 0 || _props$onInputChange.call(props, event.target.value);
          },
          isDisabled: isDisabled,
          onClick: () => {
            setIsCalendarOpen(true);
          }
        }, inputStyles), {}, {
          "data-testid": (0, _formatters.formatDataTestId)(dataTestId, 'SingleDatePicker')
        }))]
      })
    }));
  }
  function renderDayContents(day) {
    return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
      children: [(0, _jsxRuntime.jsx)("div", {
        className: "highlight"
      }), (0, _jsxRuntime.jsx)("div", {
        className: "day-number",
        children: day
      })]
    });
  }
  function handleDatepickerChange(d) {
    setIsCalendarOpen(false);
    setStartDate(d);
    onSelectDate === null || onSelectDate === void 0 || onSelectDate(d);
  }
}
function getDatepickerStyles(datepickerStyles) {
  var inputBasicStyles = (error, isRightIcon) => {
    return _objectSpread(_objectSpread({
      padding: 'var(--chakra-space-lg) var(--chakra-space-lg) var(--chakra-space-lg) 0',
      paddingLeft: isRightIcon ? 'var(--chakra-space-md)' : 'var(--chakra-space-2xl)',
      outline: 'none',
      cursor: 'pointer',
      border: '0.063rem solid var(--chakra-colors-lightGrey1)',
      borderRadius: 'var(--chakra-space-xs)',
      borderTopRightRadius: 'var(--chakra-space-xs)',
      borderBottomRightRadius: 'var(--chakra-space-xs)',
      textOverflow: 'ellipsis',
      _active: {
        border: '0.063rem solid var(--chakra-colors-primary)',
        borderRadius: 'var(--chakra-space-xs)',
        borderColor: 'var(--chakra-colors-primary)'
      },
      _target: {
        border: '0.063rem solid var(--chakra-colors-primary)',
        borderRadius: 'var(--chakra-space-xs)',
        borderColor: 'var(--chakra-colors-primary)'
      },
      _disabled: {
        border: 'none'
      },
      _hover: {
        borderColor: 'none'
      },
      _focus: {
        zIndex: '0',
        borderWidth: '2px',
        borderColor: error ? 'error' : 'primary'
      },
      _placeholder: {
        color: 'darkGrey2'
      }
    }, datepickerStyles.datepickerInputElementStyles), datepickerStyles === null || datepickerStyles === void 0 ? void 0 : datepickerStyles.errorInputElementStyles);
  };
  var rightInputIconStyles = isDisabled => {
    return _objectSpread(_objectSpread({
      top: '0.313rem',
      right: 'var(--chakra-space-sm)'
    }, datepickerStyles.iconStyles), {}, {
      opacity: isDisabled ? '0.4' : 'unset'
    });
  };
  var leftInputIconStyles = isDisabled => {
    return _objectSpread(_objectSpread({
      top: 'var(--chakra-space-md)'
    }, datepickerStyles.iconStyles), {}, {
      opacity: isDisabled ? '0.4' : 'unset'
    });
  };
  var clearIconStyles = isRightIcon => _objectSpread(_objectSpread({}, datepickerStyles.iconStyles), {}, {
    right: isRightIcon ? '2xl' : 'sm'
  });
  return {
    inputBasicStyles,
    rightInputIconStyles,
    leftInputIconStyles,
    clearIconStyles
  };
}
function renderCustomHeader(_ref) {
  var {
    date,
    changeYear,
    changeMonth,
    decreaseMonth,
    increaseMonth,
    prevMonthButtonDisabled,
    nextMonthButtonDisabled
  } = _ref;
  return (0, _jsxRuntime.jsx)(_CustomHeader.default, {
    date: date,
    changeYear: changeYear,
    changeMonth: changeMonth,
    decreaseMonth: decreaseMonth,
    increaseMonth: increaseMonth,
    prevMonthButtonDisabled: prevMonthButtonDisabled,
    nextMonthButtonDisabled: nextMonthButtonDisabled
  });
}
var labelStyle = (error, value) => ({
  h: '1.25rem',
  w: 'fit-content',
  fontSize: 'sm',
  fontWeight: 'normal',
  align: 'center',
  px: 'xs',
  ml: '0.750rem',
  top: '-0.625rem',
  backgroundColor: 'baseWhite',
  zIndex: '1',
  color: error ? 'error' : 'darkGrey1',
  display: value ? 'block' : 'none',
  _focus: {
    color: error ? 'error' : 'primary',
    display: 'block'
  }
});
var disabledStyles = {
  _disabled: {
    opacity: '0.4',
    cursor: 'not-allowed'
  },
  _active: {
    opacity: '0.4'
  }
};