"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = usePaymentMethod;
var _react = require("react");
function usePaymentMethod() {
  var [paymentMethod, setPaymentMethod] = (0, _react.useState)({
    name: '',
    type: '',
    subType: '',
    order: 0,
    paymentOptions: [{
      type: '',
      order: 0,
      enabled: true
    }],
    enabled: false,
    cnpPreSelected: false,
    cnpOptionAvailable: false,
    reasons: []
  });
  return [paymentMethod, setPaymentMethod];
}