"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = RoomsExtrasSelection;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _react = require("@chakra-ui/react");
var _atoms = require("@whitbread-eos/atoms");
var _utils = require("@whitbread-eos/utils");
var _nextI18next = require("next-i18next");
var _react2 = _interopRequireWildcard(require("react"));
var _ancillaries = require("../../ancillaries");
var _jsxRuntime = require("react/jsx-runtime");
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && {}.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function RoomsExtrasSelection(props) {
  var _firstRoom$roomStay, _firstRoom$roomStay2;
  var {
    rooms,
    mealPackagesData,
    currentRoom
  } = props;
  var {
    t
  } = (0, _nextI18next.useTranslation)();
  var baseDataTestId = 'RoomsExtrasSelection';
  var [selectedRoom, setSelectedRoom] = (0, _react2.useState)(currentRoom);
  var [selectedExtrasList, setSelectedExtrasList] = (0, _react2.useState)([]);
  var tabs = rooms.map((room, index) => {
    var _room$roomStay;
    return {
      index,
      label: "".concat(t('account.dashboard.room'), " ").concat(index + 1),
      description: room === null || room === void 0 || (_room$roomStay = room.roomStay) === null || _room$roomStay === void 0 || (_room$roomStay = _room$roomStay.roomExtraInfo) === null || _room$roomStay === void 0 ? void 0 : _room$roomStay.roomName
    };
  });
  (0, _react2.useEffect)(() => {
    if (mealPackagesData !== null && mealPackagesData !== void 0 && mealPackagesData.roomSelection) {
      setSelectedExtrasList((0, _utils.extrasPackagesMapperSelector)(mealPackagesData.roomSelection));
    }
  }, [mealPackagesData]);
  var firstRoom = rooms[0] || {};
  var noNights = (0, _utils.getNightsNumber)((_firstRoom$roomStay = firstRoom.roomStay) === null || _firstRoom$roomStay === void 0 ? void 0 : _firstRoom$roomStay.arrivalDate, (_firstRoom$roomStay2 = firstRoom.roomStay) === null || _firstRoom$roomStay2 === void 0 ? void 0 : _firstRoom$roomStay2.departureDate);
  return (0, _jsxRuntime.jsx)(_react.GridItem, _objectSpread(_objectSpread({}, extrasContainerStyle), {}, {
    "data-testid": (0, _utils.formatDataTestId)(baseDataTestId, 'Extras'),
    children: tabs.length > 1 && rooms.length > 1 ? (0, _jsxRuntime.jsx)(_react.Box, _objectSpread(_objectSpread({}, multiRoomsStyle), {}, {
      children: (0, _jsxRuntime.jsx)(_atoms.Tabs, {
        options: tabs,
        variant: "greyTabsGroup",
        index: selectedRoom,
        onChange: setSelectedRoom,
        prefixDataTestId: baseDataTestId,
        shortMobileLabels: true,
        singleContent: renderMultiRoomMealsSelection()
      })
    })) : (0, _jsxRuntime.jsx)(_react.Box, _objectSpread(_objectSpread({}, singleRoomStyle), {}, {
      children: renderExtrasSelection()
    }))
  }));
  function renderExtrasSelection() {
    return (0, _jsxRuntime.jsx)(_ancillaries.ExtrasSection, {
      extrasDetailsList: mealPackagesData === null || mealPackagesData === void 0 ? void 0 : mealPackagesData.extrasItems,
      selectedRoom: selectedRoom,
      selectedExtrasList: selectedExtrasList,
      noNights: noNights,
      isAmend: true
    });
  }
  function renderMultiRoomMealsSelection() {
    return (0, _jsxRuntime.jsx)(_react.Box, _objectSpread(_objectSpread({}, multiRoomExtrasSelectionStyle), {}, {
      "data-testid": "multipleRoom",
      children: renderExtrasSelection()
    }));
  }
}
var singleRoomStyle = {
  pt: 0,
  mt: 0
};
var multiRoomsStyle = {
  boxShadow: '0 0 var(--chakra-space-xmd) var(--chakra-colors-lightGrey4)',
  borderRadius: '0 0 3px 3px',
  borderTop: 'none',
  border: '1px solid var(--chakra-colors-lightGrey2)'
};
var multiRoomExtrasSelectionStyle = {
  pt: {
    mobile: 'lg',
    sm: 'xl',
    lg: '3xl'
  },
  px: {
    mobile: 'md',
    sm: 'lg'
  },
  pb: {
    mobile: 'md',
    sm: 'lg'
  }
};
var extrasContainerStyle = {
  py: 'var(--chakra-space-2xl)',
  px: 'var(--chakra-space-lg)'
};