"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = HotelBreadcrumbContainer;
var _utils = require("@whitbread-eos/utils");
var _HotelBreadcrumb = _interopRequireDefault(require("./HotelBreadcrumb.component"));
var _jsxRuntime = require("react/jsx-runtime");
function HotelBreadcrumbContainer(_ref) {
  var {
    openInNewTab
  } = _ref;
  var {
    breadcrumb,
    isLoading,
    isError,
    error
  } = (0, _utils.useStaticHotelInformation)();
  return (0, _jsxRuntime.jsx)(_HotelBreadcrumb.default, {
    breadcrumb,
    isLoading,
    isError,
    error,
    openInNewTab
  });
}