"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var React = _interopRequireWildcard(require("react"));
var _jsxRuntime = require("react/jsx-runtime");
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && {}.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
var SvgSmartTv40Inch = props => (0, _jsxRuntime.jsx)("svg", _objectSpread(_objectSpread({
  width: 22,
  height: 20,
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg"
}, props), {}, {
  children: (0, _jsxRuntime.jsx)("path", {
    fillRule: "evenodd",
    clipRule: "evenodd",
    d: "M14 19v1H8v-1h6ZM13.646.346l.708.708-1.947 1.945L21 3a1 1 0 0 1 1 1v13a1 1 0 0 1-1 1H1a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1h8.592L7.646 1.053l.708-.708 2.645 2.646L13.646.346ZM21 4H1v13h20V4Zm-8.302 3.388c1.998 0 2.818 1.934 2.818 3.716 0 1.782-.82 3.726-2.818 3.726-1.998 0-2.82-1.944-2.82-3.726s.822-3.716 2.82-3.716Zm-4.634.108v4.526h.994v.95h-.994V14.7h-1.08v-1.728H3.561v-.886l3.024-4.59h1.48Zm4.634.854c-1.275 0-1.718 1.35-1.718 2.754s.443 2.764 1.718 2.764c1.274 0 1.717-1.36 1.717-2.764 0-1.404-.443-2.754-1.717-2.754Zm-5.714.13L4.63 12.021h2.354V8.479ZM17.25 7v2h-1V7h1Zm1.5 0v2h-1V7h1Z",
    fill: props.color || '#58595B'
  })
}));
var _default = exports.default = SvgSmartTv40Inch;