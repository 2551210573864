"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.AdditionalInformation = void 0;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _react = require("@chakra-ui/react");
var _api = require("@whitbread-eos/api");
var _atoms = require("@whitbread-eos/atoms");
var _utils = require("@whitbread-eos/utils");
var _nextI18next = require("next-i18next");
var _react2 = require("react");
var _reactHookForm = require("react-hook-form");
var _common = require("./common");
var _field = _interopRequireDefault(require("./field"));
var _jsxRuntime = require("react/jsx-runtime");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
var AdditionalInformation = _ref => {
  var _getValues;
  var {
    control,
    formField,
    errors,
    getValues,
    handleSetValue
  } = _ref;
  var {
    language,
    country
  } = (0, _utils.useCustomLocale)();
  var [nationalities, setNationalities] = (0, _react2.useState)([]);
  var {
    t
  } = (0, _nextI18next.useTranslation)();
  var [showCheckInInfo, setShowCheckInInfo] = (0, _react2.useState)(false);
  var {
    data: countriesData,
    isSuccess: countriesRequestSuccess
  } = (0, _utils.useQueryRequest)(['GetCountries', country, language, _api.SITE_LEISURE], _api.GET_COUNTRIES, {
    country,
    language,
    site: _api.SITE_LEISURE
  });
  (0, _react2.useEffect)(() => {
    if (countriesRequestSuccess && !(nationalities !== null && nationalities !== void 0 && nationalities.length)) {
      var _countriesData$countr;
      var sortedCountries = (0, _utils.getSortedCountriesByCurrentLang)(countriesData === null || countriesData === void 0 || (_countriesData$countr = countriesData.countries) === null || _countriesData$countr === void 0 ? void 0 : _countriesData$countr.countries, language);
      setNationalities((sortedCountries === null || sortedCountries === void 0 ? void 0 : sortedCountries.map(_ref2 => {
        var {
          nationality,
          countryCode,
          flagSrc
        } = _ref2;
        return {
          value: countryCode,
          label: nationality !== null && nationality !== void 0 ? nationality : '',
          image: flagSrc
        };
      })) || []);
    }
  }, [countriesRequestSuccess, countriesData]);
  var nationality = (_getValues = getValues()) === null || _getValues === void 0 || (_getValues = _getValues.nationality) === null || _getValues === void 0 ? void 0 : _getValues.value;
  var toggleCheckInInfo = () => {
    setShowCheckInInfo(prev => !prev);
  };
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsxs)(_react.Box, {
      mb: "lg",
      "data-testid": (0, _utils.formatDataTestId)(formField.testid, 'guestDetailsMyselfHeadingOptional'),
      children: [(0, _jsxRuntime.jsx)(_react.Text, _objectSpread(_objectSpread({}, heading3), {}, {
        children: t('precheckin.guestdetails')
      })), (0, _jsxRuntime.jsx)(_react.Text, {
        mt: "md",
        children: t('precheckin.completedetails.statement')
      })]
    }), (0, _jsxRuntime.jsx)(_react.Box, {
      children: (0, _jsxRuntime.jsx)(_atoms.Button, {
        mb: "md",
        variant: "tertiary",
        size: "full",
        onClick: toggleCheckInInfo,
        "data-testId": (0, _utils.formatDataTestId)(formField.testid, 'CheckinButton'),
        children: showCheckInInfo ? (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
          children: [(0, _jsxRuntime.jsx)(_atoms.Icon, _objectSpread({
            svg: (0, _jsxRuntime.jsx)(_atoms.Rectangle, {
              color: 'var(--chakra-colors-darkGrey2)'
            })
          }, iconStyles)), " ".concat(t('booking.guestDetails.hideCheckIn'))]
        }) : (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
          children: [(0, _jsxRuntime.jsx)(_atoms.Icon, _objectSpread({
            svg: (0, _jsxRuntime.jsx)(_atoms.Path, {
              color: 'var(--chakra-colors-darkGrey2)'
            })
          }, iconStyles)), " ".concat(t('booking.guestDetails.addCheckIn'))]
        })
      })
    }), showCheckInInfo && (0, _jsxRuntime.jsx)(_react.Flex, {
      direction: "column",
      children: _common.additionalDetailsFieldsBooker.map(_ref3 => {
        var {
          label,
          name,
          styles,
          testId,
          type
        } = _ref3;
        return (name !== 'passport' || nationality && nationality !== 'DE') && (0, _react2.createElement)(_react.Box, _objectSpread(_objectSpread(_objectSpread({}, _common.gridStyles), styles), {}, {
          key: name,
          mb: "4",
          "data-testid": (0, _utils.formatDataTestId)(formField.testid, testId)
        }), (0, _jsxRuntime.jsx)(_reactHookForm.Controller, {
          name: name,
          control: control,
          render: _ref4 => {
            var _errors$name;
            var {
              field
            } = _ref4;
            return (0, _jsxRuntime.jsx)(_field.default, {
              field: field,
              type: type,
              label: label,
              formField: formField,
              nationalities: nationalities,
              errMsg: errors === null || errors === void 0 || (_errors$name = errors[name]) === null || _errors$name === void 0 ? void 0 : _errors$name.message,
              handleSetValue: handleSetValue,
              fieldToClear: "passport"
            });
          }
        }));
      })
    })]
  });
};
exports.AdditionalInformation = AdditionalInformation;
var _default = exports.default = AdditionalInformation;
var heading3 = {
  fontSize: 'lg',
  fontWeight: '700',
  lineHeight: '3',
  color: 'darkGrey1'
};
var iconStyles = {
  mr: '0.75rem',
  display: {
    base: 'none',
    xs: 'block'
  }
};