"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = AmendPayment;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _react = require("@chakra-ui/react");
var _atoms = require("@whitbread-eos/atoms");
var _molecules = require("@whitbread-eos/molecules");
var _isThisMonth = _interopRequireDefault(require("date-fns/isThisMonth"));
var _nextI18next = require("next-i18next");
var _PaymentType = _interopRequireDefault(require("../../booking/PaymentType/PaymentType.container"));
var _TotalCostPayment = _interopRequireDefault(require("../../payment/TotalCostPayment"));
var _constants = require("../constants");
var _jsxRuntime = require("react/jsx-runtime");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
var DISABLED_CARD_OPTIONS = [_constants.NEW_CARD, _constants.NEW_PIBA];
function AmendPayment(_ref) {
  var _bookingConfirmation$, _bookingConfirmation$2;
  var {
    continueToNextStep,
    selectedPaymentDetail,
    setSelectedPaymentDetail,
    selectedPaymentType,
    hotelName,
    errorMessagePayment,
    setSelectedPaymentType,
    basketReference,
    bookingConfirmation,
    variant,
    userType,
    amendSummary,
    shouldRetryPayment,
    additionalAmountLabel
  } = _ref;
  var baseDataTestId = 'amend-payment';
  var {
    t
  } = (0, _nextI18next.useTranslation)();
  var billingAddress = bookingConfirmation === null || bookingConfirmation === void 0 || (_bookingConfirmation$ = bookingConfirmation.reservationByIdList[0]) === null || _bookingConfirmation$ === void 0 ? void 0 : _bookingConfirmation$.billing.address;
  var billingAddressLabel = "".concat(billingAddress === null || billingAddress === void 0 ? void 0 : billingAddress.postalCode, " ").concat(billingAddress === null || billingAddress === void 0 ? void 0 : billingAddress.addressLine1);
  var {
    paymentOptions: amendPaymentOptions,
    payOnArrival,
    paymentCardDetails: {
      cardNumberMasked,
      token,
      expirationDate,
      cardType,
      cardHolderName,
      cardName,
      cardLogoSrc
    }
  } = amendSummary;
  var availablePaymentOptions = Object.entries(amendPaymentOptions).map((_ref2, i) => {
    var [key, value] = _ref2;
    return {
      type: key.replace(/([A-Z])/g, '_$1').toUpperCase(),
      order: i + 1,
      enabled: value
    };
  });
  var disabledCardOptions = (selectedPaymentDetail === null || selectedPaymentDetail === void 0 ? void 0 : selectedPaymentDetail.type) === _constants.PAY_ON_ARRIVAL ? [...DISABLED_CARD_OPTIONS, _constants.AMEND_CARD_NAME] : DISABLED_CARD_OPTIONS;
  var isCardAvailable = !(0, _isThisMonth.default)(new Date(expirationDate)) && amendPaymentOptions.payNow;
  var amendSavedCard = _objectSpread(_objectSpread({}, selectedPaymentType), {}, {
    name: cardName,
    type: _constants.AMEND_CARD_NAME,
    acceptedCardTypes: [],
    card: {
      token: token,
      cardNumber: cardNumberMasked,
      expiryMonth: new Date(expirationDate).toLocaleDateString('en-GB', {
        month: '2-digit'
      }),
      expiryYear: new Date(expirationDate).toLocaleDateString('en-GB', {
        year: '2-digit'
      }),
      logoSrc: cardLogoSrc,
      cardHolderName: cardHolderName,
      cnpRequired: false,
      type: cardType,
      cardType: ''
    },
    paymentOptions: availablePaymentOptions,
    enabled: isCardAvailable
  });
  return (0, _jsxRuntime.jsxs)(_react.Box, _objectSpread(_objectSpread({}, containerStyles), {}, {
    "data-testid": "".concat(baseDataTestId, "-container"),
    id: "".concat(baseDataTestId, "-container"),
    children: [renderPaymentNotifications(), (0, _jsxRuntime.jsx)(_molecules.PaymentDetails, {
      selectedPaymentType: selectedPaymentType,
      selectedPaymentDetail: selectedPaymentDetail,
      setSelectedPaymentDetail: setSelectedPaymentDetail,
      errorMessagePayment: errorMessagePayment,
      t: t
    }), (0, _jsxRuntime.jsx)(_PaymentType.default, {
      selectedPaymentDetail: selectedPaymentDetail,
      selectedPaymentType: selectedPaymentType,
      onPaymentTypeClick: setSelectedPaymentType,
      userType: userType,
      variant: variant,
      amendBasketReference: basketReference,
      disabledCardOptions: disabledCardOptions,
      amendPaymentCard: amendSavedCard
    }), (0, _jsxRuntime.jsx)(_molecules.AmendBillingAddress, {
      billingAddress: billingAddressLabel,
      selectedPaymentDetail: selectedPaymentDetail
    }), (0, _jsxRuntime.jsx)(_TotalCostPayment.default, {
      hotelName: hotelName,
      hotelId: bookingConfirmation.hotelId,
      rateCode: bookingConfirmation === null || bookingConfirmation === void 0 || (_bookingConfirmation$2 = bookingConfirmation.reservationByIdList) === null || _bookingConfirmation$2 === void 0 || (_bookingConfirmation$2 = _bookingConfirmation$2[0]) === null || _bookingConfirmation$2 === void 0 || (_bookingConfirmation$2 = _bookingConfirmation$2.roomStay) === null || _bookingConfirmation$2 === void 0 ? void 0 : _bookingConfirmation$2.ratePlanCode,
      ratePlan: {
        name: 'POA new',
        totalCost: {
          currency: bookingConfirmation.currencyCode,
          amount: "".concat(payOnArrival)
        }
      },
      isBillingAddressDisplayed: false,
      continueToNextStep: continueToNextStep,
      selectedPaymentDetail: selectedPaymentDetail,
      errorMessagePayment: errorMessagePayment,
      bookingChannel: variant,
      additionalAmountLabel: additionalAmountLabel,
      isAmendPage: true
    })]
  }));
  function renderPaymentNotifications() {
    return (0, _jsxRuntime.jsxs)(_react.Box, {
      mb: "2xl",
      children: [(0, _jsxRuntime.jsx)(_atoms.Notification, {
        prefixDataTestId: "".concat(baseDataTestId, "-options"),
        status: "info",
        variant: "info",
        svg: (0, _jsxRuntime.jsx)(_atoms.Info, {}),
        isInnerHTML: true,
        description: t('amend.notification.paymentOptions')
      }), (0, _jsxRuntime.jsx)(_atoms.Notification, {
        prefixDataTestId: "".concat(baseDataTestId, "-do-not-go-back"),
        status: "warning",
        variant: "alert",
        svg: (0, _jsxRuntime.jsx)(_atoms.Alert, {}),
        isInnerHTML: true,
        description: t('amend.notification.dontGoBack'),
        wrapperStyles: {
          mt: 'var(--chakra-space-lg)'
        }
      }), shouldRetryPayment && (0, _jsxRuntime.jsx)(_react.Box, _objectSpread(_objectSpread({}, containerPaymentErrorStyles), {}, {
        children: (0, _jsxRuntime.jsx)(_atoms.Notification, {
          prefixDataTestId: "".concat(baseDataTestId, "-payment-error-something-wrong"),
          status: "error",
          variant: "error",
          svg: (0, _jsxRuntime.jsx)(_atoms.Alert, {}),
          isInnerHTML: true,
          description: t('amend.error.tryAgain'),
          wrapperStyles: {
            mt: 'var(--chakra-space-lg)'
          }
        })
      }))]
    });
  }
}
var containerStyles = {
  marginTop: 'var(--chakra-space-3xl)',
  w: {
    base: 'full',
    lg: '50.5rem',
    xl: '54rem'
  }
};
var containerPaymentErrorStyles = {
  w: {
    base: 'full',
    lg: '50.5rem',
    xl: '54rem'
  }
};