"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = ThanksForBooking;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _react = require("@chakra-ui/react");
var _utils = require("@whitbread-eos/utils");
var _react2 = _interopRequireDefault(require("react"));
var _jsxRuntime = require("react/jsx-runtime");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function ThanksForBooking(_ref) {
  var {
    data,
    t,
    currentLang,
    sendMail = true
  } = _ref;
  var {
    thanksForBooking
  } = data;
  var baseDataTestId = 'ThanksForBooking';
  if (!thanksForBooking) {
    return null;
  }
  var {
    title,
    lastName,
    firstName,
    emailAddress
  } = thanksForBooking;
  return (0, _jsxRuntime.jsxs)(_react.Box, _objectSpread(_objectSpread({
    "data-testid": (0, _utils.formatDataTestId)(baseDataTestId, 'Container')
  }, contentWrapperStyle), {}, {
    sx: {
      '@media print': {
        display: 'none'
      }
    },
    children: [(0, _jsxRuntime.jsxs)(_react.Text, _objectSpread(_objectSpread({
      className: "sessioncamhidetext assist-no-show"
    }, titleStyle), {}, {
      "data-testid": (0, _utils.formatDataTestId)(baseDataTestId, 'Title-Name'),
      children: [t('booking.confirmation.thankyouForBookingWithoutCustomerName'), ' ', currentLang !== 'en' ? "".concat(title, " ").concat(lastName) : firstName]
    })), emailAddress && sendMail && (0, _jsxRuntime.jsx)(_react.Text, _objectSpread(_objectSpread({
      className: "sessioncamhidetext assist-no-show",
      "data-testid": (0, _utils.formatDataTestId)(baseDataTestId, 'Email')
    }, emailConfirmationStyle), {}, {
      children: (0, _utils.renderSanitizedHtml)(t('booking.confirmation.confirmationEmailMessage').replace('[emailAddress]', emailAddress))
    }))]
  }));
}
var contentWrapperStyle = {
  w: 'full'
};
var titleStyle = {
  fontSize: '3xxl',
  fontWeight: 'semibold',
  mb: 'md'
};
var emailConfirmationStyle = {
  fontSize: 'md'
};