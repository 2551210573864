"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = Textarea;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _react = require("@chakra-ui/react");
var _formatters = require("../../utils/formatters");
var _jsxRuntime = require("react/jsx-runtime");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
var baseDataTestId = 'textarea';
function Textarea(props) {
  var isError = (props === null || props === void 0 ? void 0 : props.error) !== undefined;
  return (0, _jsxRuntime.jsxs)(_react.FormControl, _objectSpread(_objectSpread({}, wrapperStyles), {}, {
    isInvalid: isError,
    children: [(0, _jsxRuntime.jsx)(_react.Textarea, _objectSpread({
      maxLength: props === null || props === void 0 ? void 0 : props.maxLength
    }, props)), (props === null || props === void 0 ? void 0 : props.error) && renderFieldErrorMessage(props)]
  }));
}
function renderFieldErrorMessage(props) {
  return (0, _jsxRuntime.jsx)(_react.FormErrorMessage, _objectSpread(_objectSpread({
    "data-testid": (0, _formatters.formatDataTestId)(baseDataTestId, "".concat(name, "-FormErrorMessage"))
  }, errorMsgStyle), {}, {
    children: props === null || props === void 0 ? void 0 : props.error
  }));
}
var errorMsgStyle = {
  fontSize: 'xs',
  color: 'error',
  ml: 'md',
  mt: 'sm'
};
var wrapperStyles = {
  borderRadius: 'xs',
  zIndex: 0,
  borderColor: 'lightGrey1',
  _hover: {
    borderColor: 'darkGrey1'
  },
  _focus: {
    zIndex: '0'
  },
  sx: {
    textarea: {
      ':focus': {
        borderColor: '#00798e'
      }
    }
  }
};