"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = DiscountSection;
var _taggedTemplateLiteral2 = _interopRequireDefault(require("@babel/runtime/helpers/taggedTemplateLiteral"));
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _react = require("@chakra-ui/react");
var _styled = _interopRequireDefault(require("@emotion/styled"));
var _api = require("@whitbread-eos/api");
var _atoms = require("@whitbread-eos/atoms");
var _nextI18next = require("next-i18next");
var _react2 = require("react");
var _styles = require("../styles");
var _jsxRuntime = require("react/jsx-runtime");
var _templateObject;
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function DiscountSection(_ref) {
  var {
    setValue,
    discountValue,
    currency,
    language,
    onDiscountUpdate,
    isDiscountServerError,
    discountServerError,
    isDisabled,
    validateDiscountValue,
    discountValidationError,
    setDiscountValidationError
  } = _ref;
  var refTextWidth = (0, _react2.useRef)(null);
  var [width, setWidth] = (0, _react2.useState)(0);
  var {
    t
  } = (0, _nextI18next.useTranslation)(['common']);
  var hasValidValue = discountValue !== '' && !isNaN(parseInt(discountValue, 10));
  var iseurohotelier = currency !== _api.Currency.GBP && language === 'en';
  (0, _react2.useEffect)(() => {
    if (refTextWidth.current) {
      setWidth(refTextWidth.current ? refTextWidth.current.offsetWidth : 0);
    }
  });
  (0, _react2.useEffect)(() => {
    var _JSON$parse;
    var discountErrorCode = (_JSON$parse = JSON.parse(JSON.stringify(discountServerError))) === null || _JSON$parse === void 0 || (_JSON$parse = _JSON$parse.response) === null || _JSON$parse === void 0 || (_JSON$parse = _JSON$parse.errors[0]) === null || _JSON$parse === void 0 || (_JSON$parse = _JSON$parse.errorInfo) === null || _JSON$parse === void 0 ? void 0 : _JSON$parse.errCode;
    if (discountErrorCode === 400) {
      setDiscountValidationError(isDiscountServerError ? t('ccui.payment.discount.errorMessage') : '');
    } else {
      setDiscountValidationError(isDiscountServerError ? t('ccui.payment.discount.genericErrorMessage') : '');
    }
  }, [isDiscountServerError]);
  return (0, _jsxRuntime.jsxs)(_react.Flex, {
    flexDir: "column",
    "data-testid": "discountSection",
    children: [(0, _jsxRuntime.jsx)(_react.Box, {
      pb: 4,
      children: (0, _jsxRuntime.jsx)(_react.Heading, _objectSpread(_objectSpread({
        as: "h3"
      }, _styles.headerStyles), {}, {
        children: t('ccui.payment.discount.title')
      }))
    }), (0, _jsxRuntime.jsx)(_react.Box, {
      pb: 4,
      children: (0, _jsxRuntime.jsx)(_react.Heading, _objectSpread(_objectSpread({
        as: "h6"
      }, _styles.descriptionStyles), {}, {
        children: t('ccui.payment.discount.description')
      }))
    }), (0, _jsxRuntime.jsx)(CustomBox, {
      innertextwidth: width,
      currency: currency,
      mb: "xl",
      hasvalue: hasValidValue ? 'true' : 'false',
      "data-testid": "discountInput-c".concat(currency),
      iseurohotelier: iseurohotelier ? 1 : 0,
      w: {
        mobile: 'full',
        xs: 'full',
        sm: '25.063rem',
        md: '26.25rem',
        lg: '24.5rem',
        xl: '26.25rem'
      },
      children: (0, _jsxRuntime.jsx)(_atoms.Input, {
        name: "discount",
        value: discountValue,
        onChange: onDiscountChange,
        placeholderText: t('ccui.payment.discount.placeHolder'),
        error: discountValidationError,
        onBlur: onDiscountUpdate,
        isDisabled: isDisabled,
        maxLength: 10
      })
    }), (0, _jsxRuntime.jsx)(_react.Box, _objectSpread(_objectSpread({
      as: "span",
      ref: refTextWidth
    }, hiddenSpanStyles), {}, {
      children: discountValue
    }))]
  });
  function onDiscountChange(value) {
    var isValid = validateDiscountValue(value);
    if (value === '') {
      setDiscountValidationError('');
    }
    setValue(value);
    if (isValid) {
      setValue(value);
    }
  }
}
var CustomBox = (0, _styled.default)(_react.Box)(_templateObject || (_templateObject = (0, _taggedTemplateLiteral2.default)(["\n  ", "\n"])), _ref2 => {
  var {
    hasvalue,
    innertextwidth,
    currency,
    iseurohotelier
  } = _ref2;
  return hasvalue === 'true' && "\n  & > div > div:first-of-type {\n    position: relative;\n    &:after {\n      content: '".concat(currency, "';\n      position: absolute;\n      left: ").concat(currency === _api.Currency.GBP || iseurohotelier ? '1.25rem' : "".concat(innertextwidth / 16, "rem"), ";\n      top: 0;\n      height: 100%;\n      padding-left: ").concat(currency === _api.Currency.GBP || iseurohotelier ? 0 : "1.25rem", ";\n      display: flex;\n      align-items: center;\n    },\n  }\n  & > div > div > input {\n    padding-left: ").concat(currency === _api.Currency.GBP || iseurohotelier ? '1.875rem' : '1rem', "\n  }\n  ");
});
var hiddenSpanStyles = {
  visibility: 'hidden',
  w: 'max-content'
};