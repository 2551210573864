"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = EmployeeQuestions;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _react = require("@chakra-ui/react");
var _api = require("@whitbread-eos/api");
var _atoms = require("@whitbread-eos/atoms");
var _utils = require("@whitbread-eos/utils");
var _nextI18next = require("next-i18next");
var _react2 = require("react");
var _jsxRuntime = require("react/jsx-runtime");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function EmployeeQuestions(_ref) {
  var {
    companyManagementDetails,
    setPurchaseOrder,
    purchaseOrder,
    setCustomerRef,
    customerRef,
    setUserDefinedQuestions,
    userDefinedQuestions,
    setValidateQuestions,
    validateQuestions,
    setHasEmployeeQuestionsErrors
  } = _ref;
  var {
    t
  } = (0, _nextI18next.useTranslation)();
  var baseDataTestId = 'EmployeeQuestions';
  var {
    userDefinedManagement,
    customerReferenceManagement,
    purchaseOrderManagement
  } = companyManagementDetails || {};
  var purchaseOrderQuestion = purchaseOrderManagement && purchaseOrderManagement.active && purchaseOrderManagement.location === _api.QuestionLocation.BOOKING;
  var customerRefQuestion = customerReferenceManagement && customerReferenceManagement.active && customerReferenceManagement.location === _api.QuestionLocation.BOOKING;
  var [errorStyling, setErrorStyling] = (0, _react2.useState)(false);
  var userDefinedQuestionsWithID = userDefinedManagement === null || userDefinedManagement === void 0 ? void 0 : userDefinedManagement.map((question, idx) => {
    return question['questionId'] ? _objectSpread({}, question) : _objectSpread(_objectSpread({}, question), {}, {
      questionId: idx
    });
  });
  var errorOnPage = () => {
    var _ref2;
    return (_ref2 = userDefinedQuestions.some(item => item.hasError) || (purchaseOrder === null || purchaseOrder === void 0 ? void 0 : purchaseOrder.hasError) || (customerRef === null || customerRef === void 0 ? void 0 : customerRef.hasError)) !== null && _ref2 !== void 0 ? _ref2 : false;
  };
  (0, _react2.useEffect)(() => {
    var defaultState = userDefinedQuestionsWithID.filter(item => item.active && item.location === _api.QuestionLocation.BOOKING).map(i => {
      return {
        answer: '',
        question: i === null || i === void 0 ? void 0 : i.label,
        questionId: i === null || i === void 0 ? void 0 : i.questionId,
        mandatory: i === null || i === void 0 ? void 0 : i.mandatory,
        hasError: false,
        managementHeader: i === null || i === void 0 ? void 0 : i.managementHeader
      };
    });
    setHasEmployeeQuestionsErrors(errorOnPage);
    setUserDefinedQuestions([...defaultState]);
  }, []);
  (0, _react2.useEffect)(() => {
    validateQuestionsHandler();
    setHasEmployeeQuestionsErrors(errorOnPage);
    purchaseOrder.dirty = questionIsDirty(purchaseOrder);
    customerRef.dirty = questionIsDirty(customerRef);
  }, [userDefinedQuestions, validateQuestions, purchaseOrder, customerRef]);
  var setUserDefinedQuestionsChange = value => {
    var filteredAnswers = userDefinedQuestions.filter(item => item.questionId !== value.questionId);
    var newQuestion = {
      answer: value === null || value === void 0 ? void 0 : value.label,
      question: value === null || value === void 0 ? void 0 : value.question,
      questionId: value === null || value === void 0 ? void 0 : value.questionId,
      mandatory: value === null || value === void 0 ? void 0 : value.mandatory,
      managementHeader: value === null || value === void 0 ? void 0 : value.managementHeader
    };
    var questionExist = userDefinedQuestions.some(item => item.questionId === value.questionId);
    var updatedQuestions = questionExist ? [...filteredAnswers, newQuestion] : [...userDefinedQuestions, newQuestion];
    setUserDefinedQuestions(updatedQuestions);
  };
  var setUserDefinedQuestionsChangeInput = (label, question, questionId, mandatory, managementHeader) => {
    setUserDefinedQuestionsChange({
      label,
      question,
      questionId,
      mandatory,
      managementHeader
    });
  };
  var validationSteps = errorItem => {
    if (validateQuestions) {
      scrollToError(errorItem);
      setErrorStyling(true);
      setValidateQuestions(false);
    }
  };
  var errorList = [];
  var purchaseOrderKeys = Object.keys(purchaseOrder);
  var customerRefKeys = Object.keys(customerRef);
  var answerHasError = item => {
    var {
      answer,
      mandatory
    } = item;
    return answer === '' && mandatory || !_atoms.FORM_VALIDATIONS.REFERENCES.MATCHES.test(answer);
  };
  if (purchaseOrderQuestion) {
    purchaseOrder.question = purchaseOrderManagement.label;
    purchaseOrder.hasError = answerHasError(purchaseOrder);
    purchaseOrder.mandatory = purchaseOrderManagement.mandatory;
    purchaseOrder.managementHeader = purchaseOrderManagement.managementHeader;
  }
  if (customerRefQuestion) {
    customerRef.question = customerReferenceManagement.label;
    customerRef.hasError = answerHasError(customerRef);
    customerRef.mandatory = customerReferenceManagement.mandatory;
    customerRef.managementHeader = customerReferenceManagement.managementHeader;
  }
  var questionIsDirty = item => {
    var {
      answer
    } = item;
    return answer !== '';
  };
  var errorMessage = item => {
    var {
      answer,
      question
    } = item || {};
    if (answer === '') {
      return t('config.errorMessages.refAnswer.required');
    } else if (question === purchaseOrderManagement.label && !_atoms.FORM_VALIDATIONS.REFERENCES.MATCHES.test(answer)) {
      return t('config.errorMessages.cardDetails.purchaseOrder.valid');
    } else if (question === customerReferenceManagement.label && !_atoms.FORM_VALIDATIONS.REFERENCES.MATCHES.test(answer)) {
      return t('paymentQuestions.refrenceValid');
    } else if (question !== purchaseOrderManagement.label && question !== customerReferenceManagement.label && !_atoms.FORM_VALIDATIONS.CUSTOM_QUESTION.MATCHES.test(answer)) {
      return t('employeeQuestions.customFreeTextRegex');
    }
    return;
  };
  var validateQuestionsHandler = () => {
    purchaseOrderQuestion && (purchaseOrderKeys === null || purchaseOrderKeys === void 0 ? void 0 : purchaseOrderKeys.forEach(() => {
      validationSteps(purchaseOrder);
    }));
    customerRefQuestion && (customerRefKeys === null || customerRefKeys === void 0 ? void 0 : customerRefKeys.forEach(() => {
      validationSteps(customerRef);
    }));
    userDefinedQuestions.sort((a, b) => a.questionId > b.questionId ? 1 : -1).forEach(item => {
      Object.keys(item).forEach(() => {
        var {
          answer
        } = item;
        item.hasError = item.answer === '' && item.mandatory || !_atoms.FORM_VALIDATIONS.CUSTOM_QUESTION.MATCHES.test(answer);
        validationSteps(item);
      });
    });
  };
  var scrollToError = item => {
    if (item !== null && item !== void 0 && item.hasError) {
      var _errorList$, _document$querySelect;
      errorList.push(item);
      var firstElementId = (_errorList$ = errorList[0]) === null || _errorList$ === void 0 ? void 0 : _errorList$.questionId;
      var firstErrorElement = (_document$querySelect = document.querySelector("[data-testid=\"input-".concat(baseDataTestId, "-").concat(firstElementId, "\"]"))) !== null && _document$querySelect !== void 0 ? _document$querySelect : document.querySelector("[data-testid=\"DropdownComp-".concat(baseDataTestId, "-").concat(firstElementId, "-menuButton\"]"));
      firstErrorElement === null || firstErrorElement === void 0 || firstErrorElement.scrollIntoView({
        behavior: 'smooth'
      });
    }
  };
  userDefinedQuestions.sort((a, b) => a.questionId > b.questionId ? 1 : -1).forEach(item => {
    Object.keys(item).forEach(() => {
      var {
        answer,
        mandatory
      } = item;
      item.hasError = answer === '' && mandatory || !_atoms.FORM_VALIDATIONS.CUSTOM_QUESTION.MATCHES.test(answer);
      item.touched = questionIsDirty(item);
    });
  });
  return (0, _jsxRuntime.jsxs)(_react.Box, {
    "data-testid": (0, _utils.formatDataTestId)(baseDataTestId, 'Container'),
    children: [(0, _jsxRuntime.jsx)(_react.Text, _objectSpread(_objectSpread({
      "data-testid": (0, _utils.formatDataTestId)(baseDataTestId, 'Title')
    }, titleStyle), {}, {
      children: t('booking.references')
    })), (0, _jsxRuntime.jsx)(_react.Text, {
      color: "darkGrey1",
      mb: "xl",
      "data-testid": (0, _utils.formatDataTestId)(baseDataTestId, 'Description'),
      children: t('employeeQuestions.subTitle')
    }), purchaseOrderQuestion && (0, _jsxRuntime.jsx)(_react.Box, _objectSpread(_objectSpread({}, employeeQuestionsWrapperStyle), {}, {
      children: (0, _jsxRuntime.jsx)(_atoms.Input, {
        "data-testid": (0, _utils.formatDataTestId)(baseDataTestId, 'PurchaseOrderNumber'),
        label: "".concat(purchaseOrderManagement === null || purchaseOrderManagement === void 0 ? void 0 : purchaseOrderManagement.label).concat(purchaseOrderManagement.mandatory ? '*' : ''),
        showLabel: true,
        name: "EmployeeQuestions-8",
        placeholderText: "".concat(purchaseOrderManagement === null || purchaseOrderManagement === void 0 ? void 0 : purchaseOrderManagement.label).concat(purchaseOrderManagement.mandatory ? '*' : ''),
        onChange: val => {
          setPurchaseOrder(_objectSpread(_objectSpread({}, purchaseOrder), {}, {
            answer: val,
            mandatory: purchaseOrderManagement.mandatory,
            managementHeader: purchaseOrderManagement.managementHeader
          }));
        },
        value: purchaseOrder.answer,
        styles: inputStyles,
        error: (answerHasError(purchaseOrder) && errorStyling || questionIsDirty(purchaseOrder)) && errorMessage(purchaseOrder)
      })
    })), customerRefQuestion && (0, _jsxRuntime.jsx)(_react.Box, _objectSpread(_objectSpread({}, employeeQuestionsWrapperStyle), {}, {
      children: (0, _jsxRuntime.jsx)(_atoms.Input, {
        "data-testid": (0, _utils.formatDataTestId)(baseDataTestId, 'CustomerOwnReference'),
        label: "".concat(customerReferenceManagement === null || customerReferenceManagement === void 0 ? void 0 : customerReferenceManagement.label).concat(customerReferenceManagement.mandatory ? '*' : ''),
        showLabel: true,
        name: "EmployeeQuestions-9",
        placeholderText: "".concat(customerReferenceManagement === null || customerReferenceManagement === void 0 ? void 0 : customerReferenceManagement.label).concat(customerReferenceManagement.mandatory ? '*' : ''),
        onChange: val => {
          setCustomerRef(_objectSpread(_objectSpread({}, customerRef), {}, {
            answer: val,
            mandatory: customerReferenceManagement.mandatory,
            managementHeader: customerReferenceManagement.managementHeader
          }));
        },
        value: customerRef.answer,
        styles: inputStyles,
        error: (answerHasError(customerRef) && errorStyling || questionIsDirty(customerRef)) && errorMessage(customerRef)
      })
    })), userDefinedQuestionsWithID.map(item => {
      var _item$managementInfor, _item$managementInfor2, _userDefinedQuestions, _userDefinedQuestions2, _userDefinedQuestions3, _item$managementInfor3, _userDefinedQuestions4, _userDefinedQuestions5;
      return (0, _jsxRuntime.jsx)(_jsxRuntime.Fragment, {
        children: item.active && (item === null || item === void 0 ? void 0 : item.location) === _api.QuestionLocation.BOOKING && (item === null || item === void 0 || (_item$managementInfor = item.managementInformationAnswer) === null || _item$managementInfor === void 0 ? void 0 : _item$managementInfor.answerType) === _api.AnswerType.PRESET_ANSWER && (0, _jsxRuntime.jsxs)(_react.Box, _objectSpread(_objectSpread({}, employeeQuestionsWrapperStyle), {}, {
          children: [(0, _jsxRuntime.jsx)(_atoms.Dropdown, {
            dataTestId: "EmployeeQuestions-".concat(item.questionId),
            options: item === null || item === void 0 || (_item$managementInfor2 = item.managementInformationAnswer) === null || _item$managementInfor2 === void 0 || (_item$managementInfor2 = _item$managementInfor2.answers) === null || _item$managementInfor2 === void 0 ? void 0 : _item$managementInfor2.map((answer, idx) => {
              return {
                label: answer,
                id: idx,
                question: item.label,
                questionId: item.questionId,
                mandatory: item.mandatory,
                managementHeader: item.managementHeader
              };
            }),
            onChange: val => {
              setUserDefinedQuestionsChange(val);
            },
            placeholder: "".concat(item === null || item === void 0 ? void 0 : item.label).concat(item.mandatory ? '*' : ''),
            dropdownStyles: {
              menuButtonStyles: renderDropdownStyles(Boolean(((_userDefinedQuestions = userDefinedQuestions.find(i => i.questionId === item.questionId)) === null || _userDefinedQuestions === void 0 ? void 0 : _userDefinedQuestions.hasError) && errorStyling)),
              menuListStyles: {
                zIndex: 999999
              }
            },
            hasError: Boolean(((_userDefinedQuestions2 = userDefinedQuestions.find(i => i.questionId === item.questionId)) === null || _userDefinedQuestions2 === void 0 ? void 0 : _userDefinedQuestions2.hasError) && errorStyling)
          }), ((_userDefinedQuestions3 = userDefinedQuestions.find(i => i.questionId === item.questionId)) === null || _userDefinedQuestions3 === void 0 ? void 0 : _userDefinedQuestions3.hasError) && errorStyling && (0, _jsxRuntime.jsx)(_react.Box, _objectSpread(_objectSpread({}, formErrorStyles), {}, {
            "data-testid": (0, _utils.formatDataTestId)(baseDataTestId, 'ErrorContainer'),
            children: (0, _jsxRuntime.jsx)(_react.Box, _objectSpread(_objectSpread({}, errorMessageStyles), {}, {
              children: (0, _jsxRuntime.jsx)(_react.Text, _objectSpread(_objectSpread({}, errorTextStyles), {}, {
                "data-testid": (0, _utils.formatDataTestId)(baseDataTestId, 'ErrorText'),
                children: t('employeeQuestions.customDropdownMessage')
              }))
            }))
          }))]
        }), item.questionId) || item.active && (item === null || item === void 0 ? void 0 : item.location) === _api.QuestionLocation.BOOKING && (item === null || item === void 0 || (_item$managementInfor3 = item.managementInformationAnswer) === null || _item$managementInfor3 === void 0 ? void 0 : _item$managementInfor3.answerType) === _api.AnswerType.OWN_ANSWER && (0, _jsxRuntime.jsx)(_react.Box, _objectSpread(_objectSpread({}, employeeQuestionsWrapperStyle), {}, {
          children: (0, _jsxRuntime.jsx)(_atoms.Input, {
            name: "EmployeeQuestions-".concat(item.questionId),
            label: "".concat(item === null || item === void 0 ? void 0 : item.label).concat(item.mandatory ? '*' : ''),
            placeholderText: "".concat(item === null || item === void 0 ? void 0 : item.label).concat(item.mandatory ? '*' : ''),
            styles: inputStyles,
            onChange: val => {
              setUserDefinedQuestionsChangeInput(val, item === null || item === void 0 ? void 0 : item.label, item.questionId, item.mandatory, item.managementHeader);
            },
            error: (((_userDefinedQuestions4 = userDefinedQuestions.find(i => i.questionId === item.questionId)) === null || _userDefinedQuestions4 === void 0 ? void 0 : _userDefinedQuestions4.hasError) && errorStyling || ((_userDefinedQuestions5 = userDefinedQuestions.find(i => i.questionId === item.questionId)) === null || _userDefinedQuestions5 === void 0 ? void 0 : _userDefinedQuestions5.touched)) && errorMessage(userDefinedQuestions.find(i => i.questionId === item.questionId))
          })
        }), item.questionId)
      });
    })]
  });
}
var titleStyle = {
  fontSize: 'xl',
  fontWeight: 'semibold',
  mb: 'xl'
};
var employeeQuestionsWrapperStyle = {
  w: {
    mobile: 'full',
    sm: '25.063rem',
    md: '27.563rem',
    lg: '24.5rem',
    xl: '26.25rem'
  },
  mb: 'md'
};
var inputStyles = {
  inputElementStyles: {
    borderColor: 'lightGrey1'
  }
};
var renderDropdownStyles = hasError => {
  return {
    border: hasError ? '2px solid' : '1px solid',
    borderColor: hasError ? 'var(--chakra-colors-error)' : 'lightGrey1',
    borderRadius: 'var(--chakra-radii-md)'
  };
};
var formErrorStyles = {
  height: 'var(--chakra-space-lg)',
  position: 'relative'
};
var errorMessageStyles = {
  position: 'absolute'
};
var errorTextStyles = {
  color: 'error',
  fontSize: 'xs',
  marginLeft: 'md',
  marginTop: 'sm',
  whiteSpace: 'nowrap'
};