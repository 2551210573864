"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.RoomClassLabelByCode = exports.RoomClassCodes = exports.RoomClass = exports.DeRoomClass = void 0;
var RoomClass = exports.RoomClass = function (RoomClass) {
  RoomClass["ST"] = "Standard Room";
  RoomClass["SE"] = "Standard Extra Room";
  RoomClass["PP"] = "Premier Plus Room";
  RoomClass["BG"] = "Bigger Room";
  RoomClass["PSE"] = "Pseudo Room";
  RoomClass["SU"] = "Premier Plus Suite";
  RoomClass["PV"] = "Premier Plus with View";
  RoomClass["SV"] = "Standard with View";
  RoomClass["SF"] = "Standard Family Room";
  RoomClass["PC"] = "Premier Plus room with City View";
  RoomClass["PS"] = "Premier Plus room with Sea View";
  RoomClass["SC"] = "Standard room with City View";
  RoomClass["SS"] = "Standard room with Sea View";
  return RoomClass;
}({});
var DeRoomClass = exports.DeRoomClass = function (DeRoomClass) {
  DeRoomClass["ST"] = "Standard Zimmer";
  DeRoomClass["SE"] = "Standard Extra Zimmer";
  DeRoomClass["PP"] = "Premier Plus Zimmer";
  DeRoomClass["BG"] = "Gr\xF6\xDFeres Zimmer";
  DeRoomClass["PSE"] = "Pseudo Room";
  DeRoomClass["SU"] = "Premier Plus Suite";
  DeRoomClass["PV"] = "Premier Plus mit Ausblick";
  DeRoomClass["SV"] = "Standard mit Ausblick";
  DeRoomClass["SF"] = "Standard Familienzimmer";
  DeRoomClass["PC"] = "Premier Plus Zimmer mit Stadtblick";
  DeRoomClass["PS"] = "Premier Plus Zimmer mit Meerblick";
  DeRoomClass["SC"] = "Standardzimmer mit Stadtblick";
  DeRoomClass["SS"] = "Standardzimmer mit Meerblick";
  return DeRoomClass;
}({});
var RoomClassLabelByCode = exports.RoomClassLabelByCode = function (RoomClassLabelByCode) {
  RoomClassLabelByCode["ST"] = "pihotelinfo.standardRoomTitle";
  RoomClassLabelByCode["SE"] = "pihotelinfo.businessTitle";
  RoomClassLabelByCode["PP"] = "pihotelinfo.premierPlusTitle";
  RoomClassLabelByCode["BG"] = "pihotelinfo.hubBiggerRoomTitle";
  RoomClassLabelByCode["PSE"] = "pihotelinfo.pseudoRoomTitle";
  RoomClassLabelByCode["SU"] = "pihotelinfo.su.title";
  RoomClassLabelByCode["PV"] = "pihotelinfo.pv.title";
  RoomClassLabelByCode["SV"] = "pihotelinfo.sv.title";
  RoomClassLabelByCode["SF"] = "pihotelinfo.sf.title";
  RoomClassLabelByCode["PC"] = "pihotelinfo.pc.title";
  RoomClassLabelByCode["PS"] = "pihotelinfo.ps.title";
  RoomClassLabelByCode["SC"] = "pihotelinfo.sc.title";
  RoomClassLabelByCode["SS"] = "pihotelinfo.ss.title";
  return RoomClassLabelByCode;
}({});
var RoomClassCodes = exports.RoomClassCodes = function (RoomClassCodes) {
  RoomClassCodes["STANDARD_ROOM"] = "ST";
  RoomClassCodes["STANDARD_EXTRA_ROOM"] = "SE";
  RoomClassCodes["PREMIER_PLUS_ROOM"] = "PP";
  RoomClassCodes["BIGGER_ROOM"] = "BG";
  RoomClassCodes["PSEUDO_ROOM"] = "PSE";
  RoomClassCodes["PREMIER_PLUS_SUITE"] = "SU";
  RoomClassCodes["PREMIER_PLUS_VIEW"] = "PV";
  RoomClassCodes["STANDARD_VIEW"] = "SV";
  RoomClassCodes["STANDARD_FAMILY_ROOM"] = "SF";
  RoomClassCodes["PREMIER_PLUS_CITY_VIEW"] = "PC";
  RoomClassCodes["PREMIER_PLUS_SEA_VIEW"] = "PS";
  RoomClassCodes["Standard_CITY_VIEW"] = "SC";
  RoomClassCodes["Standard_SEA_VIEW"] = "SS";
  return RoomClassCodes;
}({});