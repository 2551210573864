"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "FormInput", {
  enumerable: true,
  get: function get() {
    return _formInput.FormInput;
  }
});
var _formInput = require("./form-input");