"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = HeaderAgent;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _react = require("@chakra-ui/react");
var _api = require("@whitbread-eos/api");
var _atoms = require("@whitbread-eos/atoms");
var _utils = require("@whitbread-eos/utils");
var _nextI18next = require("next-i18next");
var _link = _interopRequireDefault(require("next/link"));
var _router = require("next/router");
var _react2 = require("react");
var _index = require("../../index");
var _helpers = require("../helpers/helpers");
var _jsxRuntime = require("react/jsx-runtime");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function HeaderAgent(_ref) {
  var _data$headerInformati, _data$headerInformati2, _data$headerInformati3, _data$headerInformati5, _data$headerInformati6, _data$headerInformati7, _data$headerInformati8, _data$headerInformati9, _data$headerInformati10;
  var {
    roles,
    queryClient
  } = _ref;
  var baseTestId = 'HeaderAgent';
  var {
    query,
    pathname,
    asPath
  } = (0, _router.useRouter)() || {
    query: {
      agent_country: '',
      reservationId: ''
    },
    pathname: '',
    asPath: ''
  };
  var isGuestAccountEnabled = (0, _utils.useFeatureSwitch)({
    featureSwitchKey: _api.FS_DISPLAY_GUEST_ACCOUNT
  });
  var isAgentMemoEnabled = !!query.reservationId;
  var {
    language,
    country
  } = (0, _utils.useCustomLocale)();
  var {
    t
  } = (0, _nextI18next.useTranslation)(['common']);
  var {
    openAgentMemo,
    isAgentMemoOpen,
    setAgentMemoReservationId,
    agentMemoCount,
    closeAgentMemo
  } = (0, _utils.useAgentMemo)();
  var agentCountry = query['agent_country'];
  var currentLang = agentCountry !== null && agentCountry !== void 0 ? agentCountry : language;
  var isWindowDefined = typeof window !== 'undefined';
  var basketReference = query !== null && query !== void 0 && query.reservationId ? String(query.reservationId) : '';
  var bookingReference = query !== null && query !== void 0 && query.bookingReference ? String(query.bookingReference) : '';
  var [origin, setOrigin] = (0, _react2.useState)('');
  (0, _react2.useEffect)(() => {
    setOrigin(window.location.origin);
  }, [isWindowDefined]);
  (0, _react2.useEffect)(() => {
    if (query.reservationId) {
      setAgentMemoReservationId(query.reservationId);
    } else {
      closeAgentMemo();
    }
  }, [query.reservationId]);
  var isManager = roles === null || roles === void 0 ? void 0 : roles.includes(_api.UserRoles.MANAGER);
  var {
    data
  } = (0, _utils.useQueryRequest)(['GetStaticContent', language, country], _api.GET_STATIC_CONTENT, {
    language,
    country,
    site: _api.SITE_LEISURE,
    businessBooker: false
  });
  var agentDisabledLinks = isGuestAccountEnabled ? [] : [data === null || data === void 0 || (_data$headerInformati = data.headerInformation) === null || _data$headerInformati === void 0 || (_data$headerInformati = _data$headerInformati.content) === null || _data$headerInformati === void 0 || (_data$headerInformati = _data$headerInformati.menu) === null || _data$headerInformati === void 0 ? void 0 : _data$headerInformati.guestAccount];
  var [showChangeLogModal, setShowChangeLogModal] = (0, _react2.useState)(false);
  var regex = /\/amend/;
  var isAmendPage = regex.test(pathname);
  var isChangeLogEnabled = isAmendPage && bookingReference;
  var enableModal = showChangeLogModal && bookingReference;
  var handleChangeLogClicked = (0, _react2.useCallback)(() => {
    setShowChangeLogModal(true);
  }, []);
  var countries = (_data$headerInformati2 = data === null || data === void 0 || (_data$headerInformati3 = data.headerInformation) === null || _data$headerInformati3 === void 0 || (_data$headerInformati3 = _data$headerInformati3.content) === null || _data$headerInformati3 === void 0 ? void 0 : _data$headerInformati3.countries) !== null && _data$headerInformati2 !== void 0 ? _data$headerInformati2 : [];
  var languagesList = (0, _helpers.getListOfLanguagesForSwitcher)(countries);
  var {
    brand
  } = (0, _utils.useHotelBrands)({
    basketReference,
    channel: _api.Channel.Ccui,
    queryClient
  });
  var routeManager = route => {
    switch (route) {
      case 'discoverPI':
        return '/';
      case 'findBooking':
        return "/".concat(country, "/").concat(currentLang, "/bookings");
      case 'guestAccount':
        return "/".concat(country, "/").concat(currentLang, "/search-account");
      case 'changeLogs':
        return '/';
      default:
        return '/';
    }
  };
  var navigationLinks = Object.keys(_api.HeaderLinks).map(item => {
    var _data$headerInformati4;
    var menuResponse = data === null || data === void 0 || (_data$headerInformati4 = data.headerInformation) === null || _data$headerInformati4 === void 0 || (_data$headerInformati4 = _data$headerInformati4.content) === null || _data$headerInformati4 === void 0 ? void 0 : _data$headerInformati4.menu;
    if (menuResponse && Object.keys(menuResponse).length > 0 && Object.keys(menuResponse).includes(item)) {
      return {
        key: item,
        text: menuResponse[item],
        url: routeManager(item)
      };
    }
  });
  var router = (0, _router.useRouter)() || {};
  (0, _react2.useEffect)(() => {
    if (agentCountry) {
      delete query['agent_country'];
      var copyAsPath;
      if (asPath.indexOf("&agent_country=".concat(agentCountry)) !== -1) {
        copyAsPath = asPath.replace("&agent_country=".concat(agentCountry), '');
      } else if (asPath.indexOf("agent_country=".concat(agentCountry, "&")) !== -1) {
        copyAsPath = asPath.replace("agent_country=".concat(agentCountry, "&"), '');
      } else {
        copyAsPath = asPath.replace("?agent_country=".concat(agentCountry), '');
      }
      router.push({
        pathname,
        query
      }, copyAsPath, {
        locale: agentCountry
      });
    }
  }, [agentCountry, router]);
  var navStyle = language === 'en' ? stackStyle : stackStyleDE;
  var textStyle = language === 'en' ? linkStyle : linkStyleDE;
  var tickIcon = (0, _jsxRuntime.jsx)(_atoms.Icon, {
    src: (0, _utils.formatAssetsUrl)(data === null || data === void 0 || (_data$headerInformati5 = data.headerInformation) === null || _data$headerInformati5 === void 0 || (_data$headerInformati5 = _data$headerInformati5.content) === null || _data$headerInformati5 === void 0 || (_data$headerInformati5 = _data$headerInformati5.menu) === null || _data$headerInformati5 === void 0 ? void 0 : _data$headerInformati5.tick),
    color: "var(--chakra-colors-primary)"
  });
  var containerWrapperStyles = () => {
    return {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      maxW: 'var(--chakra-space-breakpoint-xl)',
      h: 'full',
      pl: language === 'en' ? {
        mobile: 'md',
        xs: '5',
        sm: 'md',
        md: 'lg',
        lg: '4.125rem'
      } : {
        mobile: 'md',
        xs: '5',
        sm: 'md',
        md: 'lg',
        lg: '1.75rem',
        xl: '4.125rem'
      },
      pr: language === 'en' ? {
        mobile: 'md',
        md: '1.688rem',
        lg: '4.125rem'
      } : {
        mobile: 'md',
        md: '1.688rem',
        lg: '1.75rem',
        xl: '4.125rem'
      }
    };
  };
  var handleSessionItem = key => {
    if (pathname !== '/bookings' && key === 'findBooking') {
      if (typeof window !== 'undefined') {
        window.localStorage.setItem('SearchBookingFormBookingReference', JSON.stringify(''));
        window.sessionStorage.removeItem('ccuiPrevSearchCriteria');
      }
    }
  };
  function applyDisabledStyles(isDisabled) {
    return isDisabled ? textStyleDisabled : {};
  }
  var subNav = data === null || data === void 0 || (_data$headerInformati6 = data.headerInformation) === null || _data$headerInformati6 === void 0 || (_data$headerInformati6 = _data$headerInformati6.content) === null || _data$headerInformati6 === void 0 ? void 0 : _data$headerInformati6.subNav;
  return (0, _jsxRuntime.jsxs)(_react.Box, _objectSpread(_objectSpread({}, headerWrapperStyles), {}, {
    "data-testid": "common-header-wrapper",
    children: [(0, _jsxRuntime.jsx)(_react.Container, _objectSpread(_objectSpread({}, containerWrapperStyles()), {}, {
      children: (0, _jsxRuntime.jsxs)(_react.Grid, _objectSpread(_objectSpread({}, contentStyles), {}, {
        sx: {
          '@media print': {
            py: 'sm'
          }
        },
        children: [(0, _jsxRuntime.jsx)(_react.Flex, _objectSpread(_objectSpread({}, containerLogoStyle), {}, {
          "data-testid": "logo-container",
          children: (0, _jsxRuntime.jsx)(_atoms.Logo, {
            src: (0, _utils.formatAssetsUrl)(data === null || data === void 0 || (_data$headerInformati7 = data.headerInformation) === null || _data$headerInformati7 === void 0 ? void 0 : _data$headerInformati7.content.header.image),
            href: "".concat(origin, "/").concat(country, "/").concat(language),
            isHeaderLogo: true,
            variant: currentLang === 'de' && brand !== 'hub' && brand !== 'zip' ? 'pi-simple' : brand,
            transform: "scale(1)"
          })
        })), (0, _jsxRuntime.jsx)(_react.Grid, {
          templateColumns: "1fr auto",
          display: {
            mobile: 'none',
            lg: 'grid'
          },
          sx: {
            '@media print': {
              display: 'none'
            }
          },
          children: (0, _jsxRuntime.jsxs)(_react.Stack, _objectSpread(_objectSpread({
            as: "nav"
          }, navStyle), {}, {
            children: [(0, _jsxRuntime.jsx)(_react.Box, _objectSpread(_objectSpread({}, languageSelectorWrapperStyles), {}, {
              children: (0, _jsxRuntime.jsx)(_atoms.LanguageSelectorSwitcher, {
                currentLanguage: currentLang,
                tickIcon: tickIcon,
                languagesList: languagesList,
                area: _api.Area.CCUI
              })
            })), navigationLinks.filter(elem => elem).map(link => {
              var _link$url, _link$key, _link$key2, _subNav$1$navOptions, _subNav$, _subNav$2$navOptions, _subNav$2, _subNav$3$navOptions, _subNav$3;
              var isLinkDisabled = false;
              var isPopoverLinkEnabled = false;
              isLinkDisabled = agentDisabledLinks.includes(link === null || link === void 0 ? void 0 : link.text);
              var disabledLinkStyle = applyDisabledStyles(isLinkDisabled);
              isPopoverLinkEnabled = (link === null || link === void 0 ? void 0 : link.key) === 'discoverPI';
              return (0, _jsxRuntime.jsx)(_react.Box, {
                children: !isPopoverLinkEnabled ? (0, _jsxRuntime.jsx)(_link.default, {
                  href: (_link$url = link === null || link === void 0 ? void 0 : link.url) !== null && _link$url !== void 0 ? _link$url : '',
                  passHref: true,
                  legacyBehavior: true,
                  children: (0, _jsxRuntime.jsx)(_react.Link, _objectSpread(_objectSpread({
                    onClick: () => handleSessionItem(link === null || link === void 0 ? void 0 : link.key)
                  }, _objectSpread(_objectSpread({}, noBoxShadowStyle), disabledLinkStyle)), {}, {
                    children: (0, _jsxRuntime.jsx)(_react.Text, _objectSpread(_objectSpread({}, _objectSpread(_objectSpread({}, textStyle), disabledLinkStyle)), {}, {
                      "data-testid": (0, _utils.formatDataTestId)('navigation-link', link === null || link === void 0 || (_link$key = link.key) === null || _link$key === void 0 ? void 0 : _link$key.replace(/ /g, '_')),
                      children: link === null || link === void 0 ? void 0 : link.text
                    }))
                  }))
                }) : (0, _jsxRuntime.jsx)(_atoms.Popover, {
                  triggerItem: (0, _jsxRuntime.jsx)(_react.Box, _objectSpread(_objectSpread({}, textStyle), {}, {
                    children: (0, _jsxRuntime.jsx)(_react.Link, _objectSpread(_objectSpread({}, _objectSpread(_objectSpread({}, noBoxShadowStyle), disabledLinkStyle)), {}, {
                      children: (0, _jsxRuntime.jsx)(_react.Text, _objectSpread(_objectSpread(_objectSpread({}, textStyle), {}, {
                        "data-testid": (0, _utils.formatDataTestId)('popover-enabler-text', link === null || link === void 0 || (_link$key2 = link.key) === null || _link$key2 === void 0 ? void 0 : _link$key2.replace(/ /g, '_'))
                      }, disabledLinkStyle), {}, {
                        children: link === null || link === void 0 ? void 0 : link.text
                      }))
                    }))
                  })),
                  children: (0, _jsxRuntime.jsxs)(_react.Flex, {
                    "data-testid": (0, _utils.formatDataTestId)(baseTestId, 'entire-popover-DiscoverPI'),
                    justifyContent: "flex-end",
                    children: [renderListItems((_subNav$1$navOptions = (_subNav$ = subNav[1]) === null || _subNav$ === void 0 ? void 0 : _subNav$.navOptions) !== null && _subNav$1$navOptions !== void 0 ? _subNav$1$navOptions : [], subNav[1].title, 'discoverPIfirstColumn'), renderListItems((_subNav$2$navOptions = (_subNav$2 = subNav[2]) === null || _subNav$2 === void 0 ? void 0 : _subNav$2.navOptions) !== null && _subNav$2$navOptions !== void 0 ? _subNav$2$navOptions : [], subNav[2].title, 'discoverPISecondColumn'), renderListItems((_subNav$3$navOptions = (_subNav$3 = subNav[3]) === null || _subNav$3 === void 0 ? void 0 : _subNav$3.navOptions) !== null && _subNav$3$navOptions !== void 0 ? _subNav$3$navOptions : [], subNav[3].title, 'discoverPIThirdColumn')]
                  })
                })
              }, link === null || link === void 0 ? void 0 : link.text);
            }), (0, _jsxRuntime.jsx)(_react.Link, _objectSpread(_objectSpread({
              onClick: handleChangeLogClicked
            }, _objectSpread(_objectSpread({}, noBoxShadowStyle), applyDisabledStyles(!isChangeLogEnabled))), {}, {
              children: (0, _jsxRuntime.jsx)(_react.Text, _objectSpread(_objectSpread({}, _objectSpread(_objectSpread({}, textStyle), applyDisabledStyles(!isChangeLogEnabled))), {}, {
                "data-testid": (0, _utils.formatDataTestId)('navigation-link', 'changeLogs'),
                children: data === null || data === void 0 || (_data$headerInformati8 = data.headerInformation) === null || _data$headerInformati8 === void 0 || (_data$headerInformati8 = _data$headerInformati8.content) === null || _data$headerInformati8 === void 0 || (_data$headerInformati8 = _data$headerInformati8.menu) === null || _data$headerInformati8 === void 0 ? void 0 : _data$headerInformati8.changeLogs
              }))
            }), "changeLog"), (0, _jsxRuntime.jsx)(_react.Link, _objectSpread(_objectSpread({}, _objectSpread(_objectSpread({}, noBoxShadowStyle), applyDisabledStyles(!isAgentMemoEnabled))), {}, {
              onClick: () => {
                openAgentMemo(_api.MemoModalVariants.PAGE);
                setAgentMemoReservationId(query.reservationId);
              },
              children: (0, _jsxRuntime.jsxs)(_react.Box, _objectSpread(_objectSpread({}, agentLinkContainerStyles), {}, {
                children: [(0, _jsxRuntime.jsx)(_react.Text, _objectSpread(_objectSpread({}, _objectSpread(_objectSpread(_objectSpread({}, linkStyle), applyDisabledStyles(!isAgentMemoEnabled)), isAgentMemoOpen && _objectSpread({}, agentMemoOpenStyle))), {}, {
                  "data-testid": (0, _utils.formatDataTestId)('navigation-link', 'agentMemo'),
                  children: data === null || data === void 0 || (_data$headerInformati9 = data.headerInformation) === null || _data$headerInformati9 === void 0 || (_data$headerInformati9 = _data$headerInformati9.content) === null || _data$headerInformati9 === void 0 || (_data$headerInformati9 = _data$headerInformati9.menu) === null || _data$headerInformati9 === void 0 ? void 0 : _data$headerInformati9.agentMemo
                })), !!agentMemoCount && isAgentMemoEnabled && (0, _jsxRuntime.jsx)(_react.Circle, _objectSpread(_objectSpread({}, agentLinkMemoCountCircleStyles), {}, {
                  children: (0, _jsxRuntime.jsx)(_react.Text, _objectSpread(_objectSpread({}, agentLinkMemoCountTextStyles), {}, {
                    children: agentMemoCount
                  }))
                }))]
              }))
            }), "agentMemo"), (0, _jsxRuntime.jsx)(_react.Link, _objectSpread(_objectSpread({
              href: "/api/auth/logout"
            }, noBoxShadowStyle), {}, {
              children: (0, _jsxRuntime.jsx)(_react.Text, _objectSpread(_objectSpread({}, linkStyle), {}, {
                "data-testid": (0, _utils.formatDataTestId)('navigation-link', 'logout'),
                children: data === null || data === void 0 || (_data$headerInformati10 = data.headerInformation) === null || _data$headerInformati10 === void 0 || (_data$headerInformati10 = _data$headerInformati10.content) === null || _data$headerInformati10 === void 0 || (_data$headerInformati10 = _data$headerInformati10.authentication) === null || _data$headerInformati10 === void 0 ? void 0 : _data$headerInformati10.logoutButton
              }))
            }), "log-out")]
          }))
        }), (0, _jsxRuntime.jsx)(_react.Grid, {
          display: {
            mobile: 'grid',
            lg: 'none'
          },
          justifyContent: 'end',
          children: "Mobile navigation"
        })]
      }))
    })), enableModal && (0, _jsxRuntime.jsx)(_index.ChangeLog, {
      showChangeLogModal: showChangeLogModal,
      setShowChangeLogModal: setShowChangeLogModal,
      bookingReference: bookingReference
    })]
  }));
  function renderListItems(list, heading, dataTestId) {
    return (0, _jsxRuntime.jsxs)(_react.List, _objectSpread(_objectSpread({
      "data-testid": dataTestId
    }, listStyles), {}, {
      children: [heading && (0, _jsxRuntime.jsx)(_react.Heading, _objectSpread(_objectSpread({}, headingStyles), {}, {
        children: t(heading)
      })), list.map((item, index) => {
        var _item$url;
        return (0, _react2.createElement)(_react.ListItem, _objectSpread(_objectSpread({}, listItemStyles), {}, {
          key: index,
          "data-testid": "".concat(dataTestId, "_listItemPopover-").concat(index)
        }), (0, _jsxRuntime.jsx)(_react.Link, {
          href: (_item$url = item.url) !== null && _item$url !== void 0 ? _item$url : '/',
          target: "_blank",
          children: (0, _jsxRuntime.jsx)(_react.Text, _objectSpread(_objectSpread({}, linkPopoverStyles), {}, {
            children: t(item.title)
          }))
        }));
      })]
    }));
  }
}
var agentLinkContainerStyles = {
  display: 'flex',
  alignItems: 'center',
  gap: 'sm'
};
var agentLinkMemoCountTextStyles = {
  fontSize: 'lg',
  fontStyle: 'normal',
  fontWeight: 'semibold',
  lineHeight: 'lg'
};
var agentLinkMemoCountCircleStyles = {
  size: '32px',
  bg: 'darkGrey3',
  color: 'baseWhite'
};
var languageSelectorWrapperStyles = {
  mr: {
    xl: '4rem'
  }
};
var headerWrapperStyles = {
  w: 'full',
  bg: 'white',
  boxShadow: '0 0.125rem 0.75rem var(--chakra-colors-lightGrey2)',
  h: {
    mobile: 'var(--chakra-space-4xl)',
    lg: 'var(--chakra-space-6xl)'
  },
  zIndex: 1010
};
var headingStyles = {
  _focusVisible: {
    outline: 'none'
  },
  fontSize: 'md',
  lineHeight: 3,
  mb: 'sm',
  fontWeight: 'semibold'
};
var contentStyles = {
  w: 'full',
  templateColumns: 'auto 1fr',
  alignItems: 'center'
};
var containerLogoStyle = {
  justifyContent: 'center',
  alignItems: 'center'
};
var linkStyle = {
  fontSize: 'lg',
  lineHeight: 3,
  fontWeight: 'medium',
  textAlign: 'center',
  color: 'darkGrey1'
};
var linkPopoverStyles = {
  cursor: 'pointer',
  _hover: {
    textDecoration: 'underline'
  },
  _focusVisible: {
    outline: 'none'
  }
};
var linkStyleDE = {
  fontSize: 'lg',
  lineHeight: 3,
  fontWeight: 'medium',
  textAlign: 'center',
  maxWidth: '160px',
  color: 'darkGrey1'
};
var textStyleDisabled = {
  color: 'lightGrey3',
  pointerEvents: 'none',
  textDecoration: 'none'
};
var stackStyle = {
  direction: 'row',
  spacing: {
    lg: 'xl',
    xl: '3xl'
  },
  justifyContent: 'flex-end',
  alignItems: 'center'
};
var stackStyleDE = {
  flexDirection: 'row',
  justifyContent: 'flex-end',
  alignItems: 'center',
  gap: 7
};
var noBoxShadowStyle = {
  _focus: {
    boxShadow: 'none'
  }
};
var agentMemoOpenStyle = {
  fontWeight: '700'
};
var listItemStyles = {
  w: '48',
  mb: '1',
  _focusVisible: {
    outline: 'none'
  },
  _hover: {
    a: {
      textDecoration: 'underline'
    }
  }
};
var listStyles = {
  w: '48',
  mr: '5',
  color: 'darkGrey1'
};