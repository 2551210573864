"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.BOOK_MUTATION = void 0;
var _taggedTemplateLiteral2 = _interopRequireDefault(require("@babel/runtime/helpers/taggedTemplateLiteral"));
var _graphqlRequest = require("graphql-request");
var _templateObject;
var BOOK_MUTATION = exports.BOOK_MUTATION = (0, _graphqlRequest.gql)(_templateObject || (_templateObject = (0, _taggedTemplateLiteral2.default)(["\n  mutation createReservation(\n    $reservations: [RoomReservation!]!\n    $bookingChannel: BookingChannelCriteria!\n  ) {\n    createReservation(\n      createReservationCriteria: { reservations: $reservations, bookingChannel: $bookingChannel }\n    ) {\n      basketReference\n    }\n  }\n"])));