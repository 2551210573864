"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "AnnouncementComponent", {
  enumerable: true,
  get: function get() {
    return _AnnouncementNotification.default;
  }
});
exports.default = void 0;
var _AnnouncementNotification = _interopRequireDefault(require("./AnnouncementNotification.component"));
var _AnnouncementNotificationQueryWrapper = _interopRequireDefault(require("./AnnouncementNotificationQueryWrapper"));
var _default = exports.default = _AnnouncementNotificationQueryWrapper.default;