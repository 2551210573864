"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = LoginModal;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _objectWithoutProperties2 = _interopRequireDefault(require("@babel/runtime/helpers/objectWithoutProperties"));
var _react = require("@chakra-ui/react");
var _icons = require("../../../../assets/icons");
var _Icon = _interopRequireDefault(require("../../../Icon"));
var _jsxRuntime = require("react/jsx-runtime");
var _excluded = ["onClose", "isOpen", "closeOnOverlayClick", "children", "variantProps", "dataTestId", "hasAutoFocus", "headerStyles"];
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function LoginModal(_ref) {
  var {
      onClose,
      isOpen,
      closeOnOverlayClick,
      children,
      variantProps,
      dataTestId,
      hasAutoFocus = false,
      headerStyles
    } = _ref,
    otherProps = (0, _objectWithoutProperties2.default)(_ref, _excluded);
  var prefix = dataTestId ? "".concat(dataTestId, "-") : '';
  var compModalHeaderStyles = headerStyles ? _objectSpread(_objectSpread({}, modalHeaderStyle), headerStyles) : _objectSpread({}, modalHeaderStyle);
  var compModalFooterStyles = variantProps !== null && variantProps !== void 0 && variantProps.footerStyles ? _objectSpread(_objectSpread({}, modalFooterStyle), variantProps === null || variantProps === void 0 ? void 0 : variantProps.footerStyles) : _objectSpread({}, modalFooterStyle);
  return (0, _jsxRuntime.jsxs)(_react.Modal, _objectSpread(_objectSpread({
    "data-testid": "".concat(prefix, "ChakraModal"),
    scrollBehavior: "inside",
    isOpen: isOpen,
    onClose: onClose
  }, otherProps), {}, {
    autoFocus: hasAutoFocus,
    closeOnEsc: closeOnOverlayClick,
    closeOnOverlayClick: closeOnOverlayClick,
    children: [(0, _jsxRuntime.jsx)(_react.ModalOverlay, {}), (0, _jsxRuntime.jsxs)(_react.ModalContent, _objectSpread(_objectSpread({
      "data-testid": "".concat(prefix, "ModalContent")
    }, modalContentStyle), {}, {
      width: otherProps.size ? 'auto' : {
        mobile: 'full',
        sm: 'auto'
      },
      children: [(0, _jsxRuntime.jsx)(_react.ModalHeader, _objectSpread(_objectSpread({}, compModalHeaderStyles), {}, {
        "data-testid": "".concat(prefix, "ModalHeader"),
        children: variantProps && (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
          children: [(variantProps === null || variantProps === void 0 ? void 0 : variantProps.onGoBack) && (variantProps === null || variantProps === void 0 ? void 0 : variantProps.goBackButtonText) && (0, _jsxRuntime.jsxs)(_react.Flex, _objectSpread(_objectSpread({}, modalGoBackBtnStyles), {}, {
            onClick: variantProps.onGoBack,
            "data-testid": "".concat(prefix, "ModalGoBackButton"),
            children: [(0, _jsxRuntime.jsx)(_Icon.default, {
              svg: (0, _jsxRuntime.jsx)(_icons.ArrowLeft, {})
            }), (0, _jsxRuntime.jsx)(_react.Text, _objectSpread(_objectSpread({}, modalGoBackTextStyles), {}, {
              children: variantProps.goBackButtonText
            }))]
          })), (0, _jsxRuntime.jsx)(_react.Text, _objectSpread(_objectSpread({}, headerTitleStyle(variantProps === null || variantProps === void 0 ? void 0 : variantProps.title)), {}, {
            "data-testid": "".concat(prefix, "ModalTitle"),
            children: variantProps.title
          }))]
        })
      })), (0, _jsxRuntime.jsx)(_react.Box, _objectSpread({}, delimiterModalStyle)), (0, _jsxRuntime.jsx)(_react.ModalBody, _objectSpread(_objectSpread({
        "data-testid": "".concat(prefix, "ModalBody")
      }, modalBodyStyle), {}, {
        children: (0, _jsxRuntime.jsx)(_react.Box, {
          overflow: "auto",
          maxH: "calc(100vh - 9rem)",
          children: children
        })
      })), (variantProps === null || variantProps === void 0 ? void 0 : variantProps.footer) && (0, _jsxRuntime.jsx)(_react.ModalFooter, _objectSpread(_objectSpread({
        "data-testid": "".concat(prefix, "ModalFooter")
      }, compModalFooterStyles), {}, {
        children: variantProps === null || variantProps === void 0 ? void 0 : variantProps.footer
      }))]
    }))]
  }));
}
var modalBodyStyle = {
  overflow: 'auto',
  p: '0'
};
var modalContentStyle = {
  maxW: 'auto',
  maxH: 'auto',
  overflow: 'auto',
  height: {
    mobile: 'full',
    sm: 'fit-content'
  },
  top: {
    mobile: '0',
    sm: '2.938rem',
    md: '3.75rem',
    xl: '6.375rem'
  },
  my: '0',
  p: 0,
  borderRadius: {
    mobile: '0',
    sm: '0.875rem'
  },
  boxShadow: '0 0.125rem 0.75rem var(--chakra-colors-darkGrey2)'
};
var modalHeaderStyle = {
  minH: 'var(--chakra-space-xxl)',
  display: 'flex',
  alignItems: 'center',
  py: 'sm',
  px: {
    mobile: 'md',
    sm: 'lg'
  }
};
var delimiterModalStyle = {
  mx: {
    mobile: 'md',
    sm: 'lg'
  },
  h: '0.063rem',
  bgColor: 'lightGrey4'
};
var headerTitleStyle = title => {
  return {
    ml: 'xl',
    color: 'darkGrey1',
    justify: 'center',
    justifyContent: 'center',
    fontSize: 'lg',
    lineHeight: '3',
    fontFamily: 'header',
    padding: 0,
    minHeight: title ? 'initial' : '2rem'
  };
};
var modalFooterStyle = {
  justifyContent: 'flex-start',
  overflow: 'auto',
  py: 'sm',
  px: {
    mobile: 'md',
    sm: 'lg'
  }
};
var modalGoBackBtnStyles = {
  alignItems: 'center',
  w: 'fit-content',
  justifyContent: 'flex-start',
  cursor: 'pointer',
  py: '2'
};
var modalGoBackTextStyles = {
  pl: 'sm',
  fontWeight: 'normal',
  fontSize: 'md',
  color: 'darkGrey1',
  lineHeight: '3'
};