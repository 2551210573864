"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = TransportInformationContainer;
var _utils = require("@whitbread-eos/utils");
var _TransportInformation = _interopRequireDefault(require("./TransportInformation.component"));
var _jsxRuntime = require("react/jsx-runtime");
function TransportInformationContainer() {
  var {
    transportInformation,
    isLoading,
    isError,
    error
  } = (0, _utils.useStaticHotelInformation)();
  return (0, _jsxRuntime.jsx)(_TransportInformation.default, {
    data: transportInformation,
    isLoading,
    isError,
    error
  });
}