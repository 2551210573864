"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
var _getBasket = require("./getBasket");
Object.keys(_getBasket).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _getBasket[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _getBasket[key];
    }
  });
});
var _getBookingConfirmation = require("./getBookingConfirmation");
Object.keys(_getBookingConfirmation).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _getBookingConfirmation[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _getBookingConfirmation[key];
    }
  });
});
var _getPromotionPanel = require("./getPromotionPanel");
Object.keys(_getPromotionPanel).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _getPromotionPanel[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _getPromotionPanel[key];
    }
  });
});
var _getBookingCheckInOutTime = require("./getBookingCheckInOutTime");
Object.keys(_getBookingCheckInOutTime).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _getBookingCheckInOutTime[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _getBookingCheckInOutTime[key];
    }
  });
});