"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.WALLET_APPLE = exports.TOTAL_DINNER_BUDGET_REGEX = exports.THIRTY_MINUTES = exports.PIBA_NOT_ALLOWED_HOTEL_KEYS = exports.PAYPAL_PAYMENT = exports.PAYMENT_REDESIGN_OPTIONS = exports.PAYMENT_REDESIGN = exports.PAYMENT_FAILED_VALUE = exports.PAYMENT_FAILED_KEY = exports.PAYMENT_FAILED_INITIAL_VALUE = exports.PAYMENT_ANALYTICS_KEY = exports.PAYMENT_ANALYTICS_INITIAL_VALUE = exports.OTHER_ALLOWANCES_CCUI = exports.GOOGLE = exports.DEFAULT_SELECTED_PAYMENT_DETAIL = exports.BOOKERS_REFERENCE_MAX_LENGTH = exports.APPLE = exports.APGP_PLANET_MESSAGE_GP = exports.APGP_PLANET_MESSAGE_AP = exports.APGP_PAYMENT = void 0;
var BOOKERS_REFERENCE_MAX_LENGTH = exports.BOOKERS_REFERENCE_MAX_LENGTH = 50;
var TOTAL_DINNER_BUDGET_REGEX = exports.TOTAL_DINNER_BUDGET_REGEX = /^\d*([.,]\d{0,2})?$/;
var PAYMENT_FAILED_KEY = exports.PAYMENT_FAILED_KEY = 'paymentStatus';
var PAYMENT_ANALYTICS_KEY = exports.PAYMENT_ANALYTICS_KEY = 'paymentAnalytics';
var PAYMENT_ANALYTICS_INITIAL_VALUE = exports.PAYMENT_ANALYTICS_INITIAL_VALUE = {
  paymentSessionID: '',
  paymentTemplateID: '',
  cardNotPresent: false,
  paymentCardSelected: '',
  paymentTakenNow: '',
  basketReference: ''
};
var PAYMENT_FAILED_INITIAL_VALUE = exports.PAYMENT_FAILED_INITIAL_VALUE = '';
var PAYMENT_FAILED_VALUE = exports.PAYMENT_FAILED_VALUE = 'failed';
var PAYPAL_PAYMENT = exports.PAYPAL_PAYMENT = 'PAYPAL';
var APGP_PAYMENT = exports.APGP_PAYMENT = 'APGP';
var APGP_PLANET_MESSAGE_GP = exports.APGP_PLANET_MESSAGE_GP = 'Google pay button clicked';
var APGP_PLANET_MESSAGE_AP = exports.APGP_PLANET_MESSAGE_AP = 'Apple pay button clicked';
var APPLE = exports.APPLE = 'APPLE';
var GOOGLE = exports.GOOGLE = 'GOOGLE';
var WALLET_APPLE = exports.WALLET_APPLE = 'WALLET_APPLE';
var OTHER_ALLOWANCES_CCUI = exports.OTHER_ALLOWANCES_CCUI = {
  MEAL_DEAL: {
    type: 'mealDeal',
    name: 'Meal Deal',
    operaId: 'MDP'
  },
  PI_BREAKFAST: {
    type: 'premierInnBreakfast',
    name: 'Premier Inn Breakfast',
    operaId: 'BFADBF'
  },
  CONT_BREAKFAST: {
    type: 'continentalBreakfast',
    name: 'Continental Breakfast',
    operaId: 'BFADCT'
  },
  CAR_PARKING: {
    type: 'carParking',
    name: 'Car Parking',
    operaId: null
  },
  WIFI: {
    type: 'ultimateWifi',
    name: 'Ultimate Wifi',
    operaId: null
  }
};
var PIBA_NOT_ALLOWED_HOTEL_KEYS = exports.PIBA_NOT_ALLOWED_HOTEL_KEYS = {
  PIBA_EU_ALLOWED_ONLY_IN_EU: 'PIBA_EU_ALLOWED_ONLY_IN_EU',
  PIBA_UK_ALLOWED_ONLY_IN_UK: 'PIBA_UK_ALLOWED_ONLY_IN_UK'
};
var PAYMENT_REDESIGN_OPTIONS = exports.PAYMENT_REDESIGN_OPTIONS = {
  LED: 'led',
  TAB: 'tab'
};
var PAYMENT_REDESIGN = exports.PAYMENT_REDESIGN = 'payment_redesign';
var THIRTY_MINUTES = exports.THIRTY_MINUTES = 30;
var DEFAULT_SELECTED_PAYMENT_DETAIL = exports.DEFAULT_SELECTED_PAYMENT_DETAIL = {
  type: 'default',
  order: 1,
  enabled: false
};