"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = LocationQueryWrapper;
var _reactQuery = require("@tanstack/react-query");
var _Location = _interopRequireDefault(require("./Location.container"));
var _jsxRuntime = require("react/jsx-runtime");
function LocationQueryWrapper(_ref) {
  var {
    queryClient
  } = _ref;
  return (0, _jsxRuntime.jsx)(_reactQuery.QueryClientProvider, {
    client: queryClient,
    children: (0, _jsxRuntime.jsx)(_Location.default, {
      queryClient: queryClient
    })
  });
}