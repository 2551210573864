"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.GET_ROOM_TYPE_INFORMATION_QUERY = void 0;
var _taggedTemplateLiteral2 = _interopRequireDefault(require("@babel/runtime/helpers/taggedTemplateLiteral"));
var _graphqlRequest = require("graphql-request");
var _templateObject;
var GET_ROOM_TYPE_INFORMATION_QUERY = exports.GET_ROOM_TYPE_INFORMATION_QUERY = (0, _graphqlRequest.gql)(_templateObject || (_templateObject = (0, _taggedTemplateLiteral2.default)(["\n  query getRoomTypeInformation(\n    $brand: String!\n    $language: String!\n    $country: String!\n    $hotelId: String!\n  ) {\n    roomTypeInformation(brand: $brand, language: $language, country: $country, hotelId: $hotelId) {\n      roomTypes {\n        roomTypeCode\n        roomCategory\n        roomLabel\n        roomDescription\n        roomImage\n        groupId\n      }\n    }\n  }\n"])));