"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = HeaderLeisure;
var _react = require("@chakra-ui/react");
var _api = require("@whitbread-eos/api");
var _utils = require("@whitbread-eos/utils");
var _nextI18next = require("next-i18next");
var _react2 = require("react");
var _HeaderVariantDefault = _interopRequireDefault(require("./HeaderVariantDefault"));
var _HeaderVariantLogo = _interopRequireDefault(require("./HeaderVariantLogo"));
var _HeaderVariantStep = _interopRequireDefault(require("./HeaderVariantStep/HeaderVariantStep.container"));
var _jsxRuntime = require("react/jsx-runtime");
function HeaderLeisure(_ref) {
  var {
    variant,
    isIcon,
    queryClient,
    bb = false
  } = _ref;
  var {
    language: currentLanguage,
    country: currentCountry
  } = (0, _utils.useCustomLocale)();
  var {
    t
  } = (0, _nextI18next.useTranslation)();
  var {
    data,
    isLoading
  } = (0, _utils.useQueryRequest)(['GetStaticContent', currentLanguage, currentCountry], _api.GET_STATIC_CONTENT, {
    language: currentLanguage,
    country: currentCountry,
    site: _api.SITE_LEISURE,
    businessBooker: false
  });
  var [headerInfoData, setHeaderInfoData] = (0, _react2.useState)([]);
  (0, _react2.useEffect)(() => {
    var _data$headerInformati;
    if (data !== null && data !== void 0 && (_data$headerInformati = data.headerInformation) !== null && _data$headerInformati !== void 0 && (_data$headerInformati = _data$headerInformati.content) !== null && _data$headerInformati !== void 0 && (_data$headerInformati = _data$headerInformati.countries) !== null && _data$headerInformati !== void 0 && _data$headerInformati.length) {
      setHeaderInfoData(data === null || data === void 0 ? void 0 : data.headerInformation);
    }
  }, [data === null || data === void 0 ? void 0 : data.headerInformation]);
  if (isLoading) {
    return (0, _jsxRuntime.jsx)(_react.Text, {
      style: {
        display: 'none'
      },
      children: t('searchresults.list.hotel.loading')
    });
  }
  if (currentLanguage === 'de') {
    return renderHeader(headerInfoData, variant, bb, queryClient, isIcon, 'pi-simple');
  }
  return renderHeader(headerInfoData, variant, bb, queryClient, isIcon);
}
function renderHeader(headerInfoData, variant, bb, queryClient) {
  var isIcon = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : false;
  var hotelBrand = arguments.length > 5 && arguments[5] !== undefined ? arguments[5] : 'pi';
  switch (variant) {
    case 'default':
      {
        return (0, _jsxRuntime.jsx)(_HeaderVariantDefault.default, {
          headerInfoData: headerInfoData,
          hotelBrand: hotelBrand
        });
      }
    case 'step':
      {
        return (0, _jsxRuntime.jsx)(_HeaderVariantStep.default, {
          headerInfoData: headerInfoData,
          bb: bb,
          queryClient: queryClient
        });
      }
    case 'logo':
      {
        return (0, _jsxRuntime.jsx)(_HeaderVariantLogo.default, {
          headerInfoData: headerInfoData,
          hotelBrand: hotelBrand,
          isIcon: isIcon,
          bb: bb
        });
      }
    default:
      {
        return (0, _jsxRuntime.jsx)(_HeaderVariantDefault.default, {
          headerInfoData: headerInfoData,
          hotelBrand: hotelBrand
        });
      }
  }
}