"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.generateRegCard = void 0;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _objectWithoutProperties2 = _interopRequireDefault(require("@babel/runtime/helpers/objectWithoutProperties"));
var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));
var _dateFns = require("date-fns");
var _pdfLib = require("pdf-lib");
var _constants = require("../../utils/constants");
var _common = require("../common");
var _excluded = ["firstname", "lastname", "dateofbirth"];
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
var generateRegCard = exports.generateRegCard = function () {
  var _ref2 = (0, _asyncToGenerator2.default)(function* (_ref) {
    var {
      t,
      transactionid,
      data,
      hotelAddress,
      signatureUrl,
      nationalities
    } = _ref;
    var doc = yield _pdfLib.PDFDocument.create();
    var font = yield doc.embedFont(_pdfLib.StandardFonts.Helvetica);
    var fontBold = yield doc.embedFont(_pdfLib.StandardFonts.HelveticaBold);
    var {
      hotelName = '',
      arrivalDate,
      departureDate,
      firstName = '',
      lastName = '',
      address = '',
      postalCode = '',
      city = '',
      dateOfBirth,
      nationality = {
        label: '',
        value: ''
      },
      passport = '',
      country = '',
      noOfRooms = 0,
      roomNo = 0,
      dependents = []
    } = data || {};
    var page = doc.addPage([600, 800]);
    var {
      width,
      height
    } = page.getSize();
    var textWidth = font.widthOfTextAtSize(t('precheckin.regcard.title'), 14);
    font.heightAtSize(14);
    var textX = (width - textWidth) / 2;
    var textY = height - 30;
    page.drawText(t('precheckin.regcard.title'), {
      x: textX,
      y: textY,
      size: 14,
      font: fontBold
    });
    page.drawLine({
      start: {
        x: 50,
        y: height - 45
      },
      end: {
        x: 550,
        y: height - 45
      },
      color: (0, _pdfLib.rgb)(0.5, 0.5, 0.5),
      thickness: 0.6
    });
    var formatDate = (date, formatStr) => date ? (0, _dateFns.format)(date, formatStr) : '';
    var getLabel = (value, key) => {
      var nationality = nationalities.find(item => item.value === value);
      return nationality ? nationality[key] : '';
    };
    var nationalityLabel = getLabel(nationality === null || nationality === void 0 ? void 0 : nationality.value, 'label');
    var countryLabel = getLabel(country, 'countryName');
    var createLabel = (label, value) => ({
      label,
      value
    });
    var bookingDetailsLabels = [createLabel('precheckin.yourbooking.details.transactionid', transactionid), createLabel('precheckin.yourbooking.details.hotelname', hotelName), createLabel('precheckin.regcard.hoteladdress', hotelAddress), createLabel('precheckin.yourbooking.details.arrivaldate', arrivalDate ? formatDate(arrivalDate, 'do MMM yyyy zzz') : ''), createLabel('precheckin.yourbooking.details.departuredate', departureDate ? formatDate(departureDate, 'do MMM yyyy zzz') : '')];
    var leadGuestDetailsLabels = [createLabel('precheckin.regcard.firstname', firstName), createLabel('precheckin.regcard.lastname', lastName), createLabel('precheckin.regcard.homeaddress', address), createLabel('precheckin.regcard.postcode', postalCode), createLabel('precheckin.regcard.city', city), createLabel('precheckin.regcard.country', countryLabel), createLabel('precheckin.additionalfields.dateofbirth', dateOfBirth ? getFormattedDate(dateOfBirth) : ''), createLabel('precheckin.additionalfields.nationalities', nationalityLabel), createLabel('precheckin.details.passport', passport)];
    var fields = [{
      heading: t('precheckin.yourbooking'),
      labels: bookingDetailsLabels
    }, {
      heading: noOfRooms > 1 ? "".concat(t('precheckin.leadguest.title'), " ").concat(roomNo + 1) : t('precheckin.yourdetails.title'),
      labels: leadGuestDetailsLabels
    }];
    var calculateY = function calculateY() {
      var index = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 0;
      var startY = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 0;
      var lineHeight = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 0;
      return startY - index * lineHeight;
    };
    var startY = 760;
    var lineHeight = 10;
    var index = 0;
    var addNewPage = () => {
      if (index > 65) {
        page = doc.addPage([600, 800]);
        index = 0;
      }
    };
    if (dependents.length) {
      fields.push({
        heading: t('precheckin.additionalguests.title'),
        labels: []
      });
      fields.push(...generateDependentsData(t, dependents));
    }
    fields.forEach((_ref3, fieldIndex) => {
      var {
        heading,
        labels,
        subHeading
      } = _ref3;
      addNewPage();
      var drawText = _ref4 => {
        var {
          text,
          size,
          font,
          x,
          yOffset,
          color
        } = _ref4;
        page.drawText(text, {
          x,
          y: calculateY(index++, startY, lineHeight) + yOffset,
          size,
          font,
          color: color ? color : (0, _pdfLib.rgb)(0.1, 0.1, 0.1)
        });
      };
      var drawHeadingOrSubHeading = (text, size, yOffsetIncrement) => {
        if (text) {
          drawText({
            text,
            size,
            font: fontBold,
            x: 50,
            yOffset: 0
          });
          index += yOffsetIncrement;
        }
      };
      drawHeadingOrSubHeading(heading, 14, labels.length ? 2.2 : 0);
      drawHeadingOrSubHeading(subHeading, 14, 1.3);
      labels.forEach((_ref5, labelIndex) => {
        var {
          label,
          value
        } = _ref5;
        if (value) {
          drawText({
            text: t(label) + ':',
            size: 12,
            font,
            x: 50,
            yOffset: 0,
            color: (0, _pdfLib.rgb)(0.2, 0.2, 0.2)
          });
          drawText({
            text: value,
            size: 11,
            font,
            x: 200,
            yOffset: +lineHeight,
            color: (0, _pdfLib.rgb)(0.3, 0.3, 0.3)
          });
        }
        if (labelIndex === labels.length - 1) index += 1.2;
      });
      if (labels.length && (!subHeading || fieldIndex === fields.length - 1)) {
        var yPos = calculateY(index, startY, lineHeight) + 10;
        page.drawLine({
          start: {
            x: 50,
            y: yPos
          },
          end: {
            x: 550,
            y: yPos
          },
          thickness: 0.5,
          color: (0, _pdfLib.rgb)(0.8, 0.8, 0.8)
        });
      }
      if (subHeading) index += 0.7;else index += 1.7;
    });
    if (signatureUrl) {
      addNewPage();
      page.drawText(t('precheckin.yoursignature.title'), {
        x: 50,
        y: calculateY(index, startY, lineHeight),
        size: 13,
        font: fontBold
      });
      index += 1.5;
      var signatureImage = yield doc.embedPng(signatureUrl);
      var imageY = calculateY(index, startY, lineHeight) - signatureImage.height / 2;
      page.drawImage(signatureImage, {
        x: 50,
        y: imageY,
        width: signatureImage.width / 2,
        height: signatureImage.height / 2
      });
    }
    var pdfBytes = yield doc.save();
    return Buffer.from(pdfBytes).toString('base64');
  });
  return function generateRegCard(_x) {
    return _ref2.apply(this, arguments);
  };
}();
var generateDependentsData = (t, dependents) => dependents.map(_ref6 => {
  var {
      firstname,
      lastname,
      dateofbirth
    } = _ref6,
    args = (0, _objectWithoutProperties2.default)(_ref6, _excluded);
  return _objectSpread({
    firstname,
    lastname,
    dateofbirth
  }, args);
}).map((guest, index) => {
  var guestDetails = Object.entries(guest).filter(_ref7 => {
    var [, value] = _ref7;
    return value;
  }).map(_ref8 => {
    var [key, value] = _ref8;
    return getGuestDetailsObject(key, value);
  });
  return guestDetails.length ? {
    subHeading: "".concat(t('precheckin.guest'), " ").concat(index + 1),
    labels: guestDetails
  } : {
    heading: '',
    labels: []
  };
}).filter(Boolean);
var getGuestDetailsObject = (key, value) => {
  var detailsMap = {
    [_common.GuestDetailKeys.firstName]: value,
    [_common.GuestDetailKeys.lastName]: value,
    [_common.GuestDetailKeys.passport]: value,
    [_common.GuestDetailKeys.dateOfBirth]: getFormattedDate(value),
    [_common.GuestDetailKeys.nationality]: value === null || value === void 0 ? void 0 : value.label
  };
  var label = '';
  switch (key) {
    case 'dateofbirth':
      label = "precheckin.additionalfields.".concat(key);
      break;
    case 'passport':
      label = "precheckin.details.".concat(key);
      break;
    case 'nationality':
      label = 'precheckin.additionalfields.nationalities';
      break;
    default:
      label = "precheckin.regcard.".concat(key);
  }
  return {
    label,
    value: detailsMap[key] || ''
  };
};
var getFormattedDate = date => date && (0, _dateFns.isDate)(date) ? (0, _dateFns.format)(date, _constants.DATE_FORMAT) : '';