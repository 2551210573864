"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.billingAddressFormConfig = void 0;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _react = require("@chakra-ui/react");
var _atoms = require("@whitbread-eos/atoms");
var _utils = require("@whitbread-eos/utils");
var _react2 = require("react");
var _guestDetails = require("../../guest-details");
var _billingAddressFormValidation = _interopRequireDefault(require("./billingAddressFormValidation"));
var _jsxRuntime = require("react/jsx-runtime");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
var billingAddressFormConfig = _ref => {
  var {
    getFormState,
    defaultValues,
    onSubmitAction,
    baseDataTestId,
    t,
    currentLang,
    currentAddressLabel,
    resetForm,
    testid,
    isLocationRequired,
    setIsLocationRequired,
    companyProfile,
    isCompanyNameAdvanceEnabled
  } = _ref;
  var formValidationSchema = (0, _billingAddressFormValidation.default)({
    t,
    currentLang,
    isCompanyNameAdvanceEnabled
  });
  var CurrentAddressRadioButton = _ref2 => {
    var {
      isChecked
    } = _ref2;
    return (0, _jsxRuntime.jsxs)(_react.Flex, {
      direction: "column",
      children: [(0, _jsxRuntime.jsx)(_react.Text, _objectSpread(_objectSpread({
        "data-testid": (0, _utils.formatDataTestId)(baseDataTestId, 'BillingAddress-UseCurrentAdress-Title')
      }, addressStyle), {}, {
        fontWeight: isChecked ? 'semibold' : 'normal',
        children: t('billingAddress.current')
      })), currentAddressLabel && (0, _jsxRuntime.jsx)(_react.Text, _objectSpread(_objectSpread({
        "data-testid": (0, _utils.formatDataTestId)(baseDataTestId, 'BillingAddress-UseCurrentAdress-Label')
      }, addressStyle), {}, {
        children: currentAddressLabel
      }))]
    });
  };
  var ManualAddressToggle = _ref3 => {
    var {
      field,
      handleSetValue,
      errors
    } = _ref3;
    var {
      name
    } = field;
    (0, _react2.useEffect)(() => {
      if (!handleSetValue) {
        return;
      }
      if (errors !== null && errors !== void 0 && errors.addressLine1 || companyProfile) {
        handleSetValue(name, 'manualAddress');
      }
    }, [handleSetValue, name, errors]);
    if (field.value === 'manualAddress') {
      return null;
    }
    return (0, _jsxRuntime.jsx)(_react.Link, _objectSpread(_objectSpread({}, linkStyles), {}, {
      onClick: () => {
        if (!handleSetValue) {
          return;
        }
        handleSetValue(name, 'manualAddress');
      },
      children: t('booking.enterManuallAddress')
    }));
  };
  var homeAddressFields = [{
    type: _atoms.FORM_FIELD_TYPES.INPUT_TEXT,
    name: 'addressLine1',
    id: 'homeAddressLine1',
    label: t('booking.addressAddress1'),
    testid: (0, _utils.formatDataTestId)(baseDataTestId, 'AddressSelection-PersonalAddress-AddressLine1'),
    styles: inputStyle,
    props: {
      showIcon: true
    }
  }, {
    type: _atoms.FORM_FIELD_TYPES.INPUT_TEXT,
    name: 'addressLine2',
    id: 'homeAddressLine2',
    label: t('booking.addressAddress2'),
    testid: (0, _utils.formatDataTestId)(baseDataTestId, 'AddressSelection-PersonalAddress-AddressLine2'),
    styles: inputStyle,
    props: {
      showIcon: true
    }
  }, {
    type: _atoms.FORM_FIELD_TYPES.INPUT_TEXT,
    name: 'addressLine3',
    id: 'homeAddressLine3',
    label: t('booking.addressAddress3'),
    testid: (0, _utils.formatDataTestId)(baseDataTestId, 'AddressSelection-PersonalAddress-AddressLine3'),
    styles: inputStyle,
    props: {
      showIcon: true
    }
  }, {
    type: _atoms.FORM_FIELD_TYPES.INPUT_TEXT,
    name: 'addressLine4',
    id: 'homeAddressLine4',
    label: t('booking.addressAddress4'),
    testid: (0, _utils.formatDataTestId)(baseDataTestId, 'AddressSelection-PersonalAddress-AddressLine4'),
    styles: inputStyle,
    hidden: isLocationRequired,
    props: {
      showIcon: true
    }
  }, {
    type: _atoms.FORM_FIELD_TYPES.INPUT_TEXT,
    name: 'postalCode',
    id: 'homePostalCode',
    label: t('booking.addressPostCode'),
    testid: (0, _utils.formatDataTestId)(baseDataTestId, 'AddressSelection-PersonalAddress-PostalCode'),
    styles: inputStyle,
    props: {
      showIcon: true
    }
  }, {
    type: _atoms.FORM_FIELD_TYPES.INPUT_TEXT,
    name: 'cityName',
    id: 'homeAddressCityName',
    label: t('booking.addressCity'),
    testid: (0, _utils.formatDataTestId)(baseDataTestId, 'AddressSelection-PersonalAddress-CityName'),
    styles: inputStyle,
    hidden: !isLocationRequired,
    props: {
      showIcon: true
    }
  }, {
    type: _atoms.FORM_FIELD_TYPES.DYNAMIC_FIELD,
    name: 'countryCode',
    id: 'homeCountryCode',
    label: 'Country',
    Component: _guestDetails.CountriesDropdown,
    testid: (0, _utils.formatDataTestId)(baseDataTestId, 'CountrySelector'),
    styles: _objectSpread(_objectSpread({}, inputStyle), {}, {
      mt: 'xs'
    }),
    props: {
      setIsLocationRequired
    }
  }];
  var companyAddressFields = [{
    type: _atoms.FORM_FIELD_TYPES.INPUT_TEXT,
    name: 'companyName',
    id: 'businessCompanyName',
    label: t('booking.addressCompanyName'),
    testid: (0, _utils.formatDataTestId)(baseDataTestId, 'AddressSelection-CompanyAddress-CompanyName'),
    styles: inputStyle,
    props: {
      showIcon: true
    }
  }, {
    type: _atoms.FORM_FIELD_TYPES.INPUT_TEXT,
    name: 'addressLine1',
    id: 'businessAddressLine1',
    label: t('booking.addressAddress1'),
    testid: (0, _utils.formatDataTestId)(baseDataTestId, 'AddressSelection-CompanyAddress-AddressLine1'),
    styles: inputStyle,
    props: {
      showIcon: true
    }
  }, {
    type: _atoms.FORM_FIELD_TYPES.INPUT_TEXT,
    name: 'addressLine2',
    id: 'businessAddressLine2',
    label: t('booking.addressAddress2'),
    testid: (0, _utils.formatDataTestId)(baseDataTestId, 'AddressSelection-CompanyAddress-AddressLine2'),
    styles: inputStyle,
    props: {
      showIcon: true
    }
  }, {
    type: _atoms.FORM_FIELD_TYPES.INPUT_TEXT,
    name: 'addressLine3',
    id: 'businessAddressLine3',
    label: t('booking.addressAddress3'),
    testid: (0, _utils.formatDataTestId)(baseDataTestId, 'AddressSelection-CompanyAddress-AddressLine3'),
    styles: inputStyle,
    props: {
      showIcon: true
    }
  }, {
    type: _atoms.FORM_FIELD_TYPES.INPUT_TEXT,
    name: 'addressLine4',
    id: 'businessAddressLine4',
    label: t('booking.addressAddress4'),
    testid: (0, _utils.formatDataTestId)(baseDataTestId, 'AddressSelection-CompanyAddress-AddressLine4'),
    styles: inputStyle,
    hidden: isLocationRequired,
    props: {
      showIcon: true
    }
  }, {
    type: _atoms.FORM_FIELD_TYPES.INPUT_TEXT,
    name: 'postalCode',
    id: 'businessPostalCode',
    label: t('booking.addressPostCode'),
    testid: (0, _utils.formatDataTestId)(baseDataTestId, 'AddressSelection-CompanyAddress-PostalCode'),
    styles: inputStyle,
    props: {
      showIcon: true
    }
  }, {
    type: _atoms.FORM_FIELD_TYPES.INPUT_TEXT,
    name: 'cityName',
    id: 'businessAddressCityName',
    label: t('booking.addressCity'),
    testid: (0, _utils.formatDataTestId)(baseDataTestId, 'AddressSelection-PersonalAddress-CityName'),
    styles: inputStyle,
    hidden: !isLocationRequired,
    props: {
      showIcon: true
    }
  }, {
    type: _atoms.FORM_FIELD_TYPES.DYNAMIC_FIELD,
    name: 'countryCode',
    id: 'businessCountryCode',
    label: 'Country',
    Component: _guestDetails.CountriesDropdown,
    testid: (0, _utils.formatDataTestId)(baseDataTestId, 'CountrySelector'),
    styles: _objectSpread(_objectSpread({}, inputStyle), {}, {
      mt: 'xs'
    }),
    props: {
      setIsLocationRequired
    }
  }];
  var addressSelectionConfig = {
    manualAddress: [{
      type: _atoms.FORM_FIELD_TYPES.RADIO_GROUP,
      name: 'addressSelection',
      label: 'Details',
      options: [{
        value: 'HOME',
        label: t('booking.privateAddress'),
        testid: (0, _utils.formatDataTestId)(baseDataTestId, 'addressSelection-PersonalAddress')
      }, {
        value: 'BUSINESS',
        label: t('booking.businessAddress'),
        testid: (0, _utils.formatDataTestId)(baseDataTestId, 'addressSelection-CompanyAddress')
      }],
      testid: (0, _utils.formatDataTestId)(baseDataTestId, 'addressSelection'),
      styles: _objectSpread(_objectSpread({}, inputStyle), {}, {
        mt: 0
      }),
      relatedFields: {
        HOME: homeAddressFields,
        BUSINESS: companyAddressFields
      }
    }]
  };
  var differentBillingAddress = [{
    type: _atoms.FORM_FIELD_TYPES.NON_FIELD_CONTENT,
    name: 'addressTitle',
    content: (0, _jsxRuntime.jsxs)(_react.Text, _objectSpread(_objectSpread({
      "data-testid": (0, _utils.formatDataTestId)(baseDataTestId, 'BillingAddress-YourDifferentAdress-Title')
    }, titleStyle), {}, {
      children: [t('booking.addressTitle'), ' ']
    }))
  }, {
    type: _atoms.FORM_FIELD_TYPES.DYNAMIC_FIELD,
    name: 'postcodeAddress',
    label: t('booking.addressPostCode'),
    Component: _guestDetails.PostcodeAddress,
    styles: _objectSpread({}, postcodeAddressStyle),
    testid: (0, _utils.formatDataTestId)(baseDataTestId, 'PostcodeAddress')
  }, {
    type: _atoms.FORM_FIELD_TYPES.DYNAMIC_FIELD,
    name: 'manualAddressToggle',
    label: '',
    Component: ManualAddressToggle,
    relatedFields: addressSelectionConfig,
    testid: (0, _utils.formatDataTestId)(baseDataTestId, 'ManualAddressToggle')
  }];
  var config = {
    id: 'billingAddressForm',
    testid,
    elements: {
      fields: [{
        type: _atoms.FORM_FIELD_TYPES.NON_FIELD_CONTENT,
        name: 'addressTitle',
        content: (0, _jsxRuntime.jsx)(_react.Text, _objectSpread(_objectSpread({
          "data-testid": (0, _utils.formatDataTestId)(baseDataTestId, 'BillingAddress-Title')
        }, billingTitleStyle), {}, {
          children: t('billingAddress.title')
        }))
      }, {
        type: _atoms.FORM_FIELD_TYPES.RADIO_GROUP,
        name: 'billingAddressSelection',
        label: 'Details',
        options: [{
          value: 'CurrentAddress',
          label: t('billingAddress.current'),
          Component: CurrentAddressRadioButton,
          testid: (0, _utils.formatDataTestId)(baseDataTestId, 'BillingAddressSelection-SameAddress')
        }, {
          value: 'DifferentAddress',
          label: t('billingAddress.different'),
          testid: (0, _utils.formatDataTestId)(baseDataTestId, 'BillingAddressSelection-DifferentAddress')
        }],
        testid: (0, _utils.formatDataTestId)(baseDataTestId, 'BillingAddressSelection'),
        styles: _objectSpread(_objectSpread({}, inputStyle), {}, {
          mt: 0,
          mb: 'md'
        }),
        relatedFields: {
          DifferentAddress: differentBillingAddress
        }
      }],
      onSubmitAction: onSubmitAction,
      formStyles: _objectSpread({}, billingAddressStyle)
    },
    defaultValues,
    validationSchema: formValidationSchema,
    getFormState,
    resetForm
  };
  return config;
};
exports.billingAddressFormConfig = billingAddressFormConfig;
var inputStyle = {
  w: {
    mobile: 'full',
    xs: 'full',
    sm: '25.063rem',
    md: '27.563rem',
    lg: '24.5rem',
    xl: '26.25rem'
  },
  mb: 'lg'
};
var postcodeAddressStyle = {
  w: {
    mobile: 'full',
    xs: 'full',
    sm: '25.063rem',
    md: '27.563rem',
    lg: '24.5rem',
    xl: '26.25rem'
  },
  mb: 'sm'
};
var titleStyle = {
  my: 'md',
  fontSize: 'lg',
  fontWeight: 'semibold',
  lineHeight: '3'
};
var billingTitleStyle = {
  mb: 'xl',
  fontSize: '2xl',
  fontWeight: 'semibold',
  lineHeight: '4'
};
var linkStyles = {
  color: 'darkGrey1',
  textDecoration: 'underline',
  mb: 'md'
};
var billingAddressStyle = {
  w: {
    mobile: 'full',
    xs: 'full',
    sm: '25.063rem',
    md: '27.563rem',
    xl: '26.25rem'
  },
  mb: '5xl',
  color: 'darkGrey1'
};
var addressStyle = {
  fontSize: 'md',
  lineHeight: '3'
};