"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var Radio = {
  parts: ['label', 'control'],
  baseStyle: {
    control: {
      marginTop: 'xs',
      borderColor: 'var(--chakra-colors-lightGrey1)',
      borderRadius: 'full',
      _hover: {
        bg: 'unset'
      },
      _focus: {
        outline: '0 none',
        boxShadow: 'none'
      },
      _checked: {
        bg: 'baseWhite',
        borderColor: 'var(--chakra-colors-primary)',
        _focus: {
          outline: 'none'
        },
        _hover: {
          bg: 'unset'
        },
        _before: {
          bg: 'primary',
          width: '65%',
          height: '65%'
        }
      }
    }
  }
};
var _default = exports.default = Radio;