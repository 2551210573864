"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.TABLE_FIELDS = void 0;
var TABLE_FIELDS = exports.TABLE_FIELDS = function (TABLE_FIELDS) {
  TABLE_FIELDS["DATE"] = "date";
  TABLE_FIELDS["TIME"] = "time";
  TABLE_FIELDS["ACTION_TYPE"] = "actionType";
  TABLE_FIELDS["ACTION_DESCRIPTION"] = "actionDescription";
  TABLE_FIELDS["USER"] = "user";
  TABLE_FIELDS["CHANNEL"] = "channel";
  return TABLE_FIELDS;
}({});