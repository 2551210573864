"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = ManageBookingModalContainer;
var _api = require("@whitbread-eos/api");
var _utils = require("@whitbread-eos/utils");
var _ManageBookingModal = _interopRequireDefault(require("./ManageBookingModal.component"));
var _jsxRuntime = require("react/jsx-runtime");
function ManageBookingModalContainer(_ref) {
  var {
    onClose,
    isOpen
  } = _ref;
  var baseTestId = 'ManageBookingModal';
  var {
    language,
    country
  } = (0, _utils.useCustomLocale)();
  var {
    data: labels
  } = (0, _utils.useQueryRequest)(['GetStaticContent', language, country], _api.GET_STATIC_CONTENT, {
    country,
    language,
    site: _api.SITE_LEISURE,
    businessBooker: false
  });
  return (0, _jsxRuntime.jsx)(_ManageBookingModal.default, {
    labels: labels,
    onClose: onClose,
    isOpen: isOpen,
    baseTestId: baseTestId
  });
}