"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = AmendBookingConfirmationContainer;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _react = require("@chakra-ui/react");
var _api = require("@whitbread-eos/api");
var _atoms = require("@whitbread-eos/atoms");
var _utils = require("@whitbread-eos/utils");
var _nextI18next = require("next-i18next");
var _index = require("../../index");
var _jsxRuntime = require("react/jsx-runtime");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function AmendBookingConfirmationContainer(_ref) {
  var {
    queryClient,
    router,
    basketReference,
    bookingReference,
    tempBookingReference,
    variant,
    amendBookingStatus,
    bookingSpinnerConfig,
    email,
    showSearch = true
  } = _ref;
  var {
    country,
    language
  } = (0, _utils.useCustomLocale)();
  var {
    t
  } = (0, _nextI18next.useTranslation)();
  var baseDataTestId = 'booking-confirmation';
  var [, setConfirmAmendErrorValue] = (0, _utils.useSessionStorage)(_api.INITIAL_CONFIRM_AMEND_ERROR_KEY, _api.INITIAL_CONFIRM_AMEND_ERROR_VALUE);
  var amendConfirmationCommonURL = "/amend/booking-confirmation?bookingReference=".concat(bookingReference);
  var amendConfirmationURL = variant === _api.Area.BB ? "/".concat(country, "/").concat(language, "/business-booker").concat(amendConfirmationCommonURL) : "/".concat(country, "/").concat(language).concat(amendConfirmationCommonURL);
  var {
    pollingInProgress,
    pollingTimedOut,
    basketStatus,
    dynamicSpinnerLabel,
    errorCode
  } = (0, _utils.usePollBasketStatus)(tempBookingReference !== null && tempBookingReference !== void 0 ? tempBookingReference : '', bookingSpinnerConfig !== null && bookingSpinnerConfig !== void 0 ? bookingSpinnerConfig : [], amendBookingStatus === _api.CONFIRM_AMEND_STATUS.payment);
  if (country === 'de' && errorCode) {
    setConfirmAmendErrorValue(errorCode);
  }
  if (!pollingInProgress && amendBookingStatus === _api.CONFIRM_AMEND_STATUS.payment) {
    if (basketStatus === _api.BASKET_STATUS.AMENDED) {
      router.push("".concat(amendConfirmationURL, "&status=").concat(_api.CONFIRM_AMEND_STATUS.success, "&basketReference=").concat(basketReference, "&tempBookingReference=").concat(tempBookingReference));
    } else if (basketStatus === _api.BASKET_STATUS.AMEND_FAILED) {
      router.push("/".concat(country, "/").concat(language).concat(variant === _api.Area.BB ? '/business-booker' : '', "/amend/details?bookingReference=").concat(bookingReference, "&tempBookingReference=").concat(tempBookingReference, "&status=").concat(_api.CONFIRM_AMEND_STATUS.paymentError));
    } else if (basketStatus === _api.BASKET_STATUS.AMENDING && errorCode) {
      router.push("".concat(amendConfirmationURL, "&status=").concat(_api.CONFIRM_AMEND_STATUS.error, "&basketReference=").concat(basketReference, "&tempBookingReference=").concat(tempBookingReference));
    } else if (basketStatus !== _api.BASKET_STATUS.AMENDING || pollingTimedOut) {
      router.push("".concat(amendConfirmationURL, "&status=").concat(_api.CONFIRM_AMEND_STATUS.paymentError, "&basketReference=").concat(basketReference, "&tempBookingReference=").concat(tempBookingReference));
    }
  }
  if (pollingInProgress) {
    return (0, _jsxRuntime.jsx)(_react.Flex, _objectSpread(_objectSpread({}, loadingStyle), {}, {
      children: (0, _jsxRuntime.jsx)(_atoms.LoadingSpinner, {
        loadingText: dynamicSpinnerLabel
      })
    }));
  }
  return (0, _jsxRuntime.jsx)(_react.Box, {
    "data-testid": "".concat(baseDataTestId, "-wrapper"),
    children: amendBookingStatus !== _api.CONFIRM_AMEND_STATUS.success && amendBookingStatus !== _api.CONFIRM_AMEND_STATUS.error && amendBookingStatus !== _api.CONFIRM_AMEND_STATUS.payment && amendBookingStatus !== _api.CONFIRM_AMEND_STATUS.paymentError ? (0, _jsxRuntime.jsx)(_react.Box, {
      m: "lg",
      children: (0, _jsxRuntime.jsx)(_atoms.Notification, {
        status: "warning",
        description: t('errors.sorry'),
        variant: "alert",
        maxW: "full",
        svg: (0, _jsxRuntime.jsx)(_atoms.Alert, {})
      })
    }) : (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
      children: [showSearch && renderSearchComponent(variant, queryClient, router), (0, _jsxRuntime.jsx)(_index.BookingInfoCardWrapper, {
        area: variant,
        bookingReference: bookingReference,
        basketReference: basketReference,
        tempBookingReference: tempBookingReference,
        inputValues: {},
        isAmendPage: true,
        amendBookingStatus: amendBookingStatus,
        email: email !== null && email !== void 0 ? email : ''
      })]
    })
  });
}
function renderSearchComponent(variant, queryClient, router) {
  var {
    searchLocation,
    ARRdd,
    ARRmm,
    ARRyyyy,
    NIGHTS,
    ROOMS
  } = router.query;
  switch (variant) {
    case _api.Area.BB:
      return (0, _jsxRuntime.jsx)(_index.BBSearchContainer, {
        queryClient: queryClient,
        searchLocation: searchLocation === null || searchLocation === void 0 ? void 0 : searchLocation.toString(),
        ARRdd: Number(ARRdd),
        ARRmm: Number(ARRmm),
        ARRyyyy: Number(ARRyyyy),
        NIGHTS: Number(NIGHTS),
        ROOMS: Number(ROOMS),
        isSummaryActive: false,
        variant: "bb"
      });
    case _api.Area.CCUI:
      return (0, _jsxRuntime.jsx)(_index.CCUISearchContainer, {
        queryClient: queryClient,
        searchLocation: searchLocation === null || searchLocation === void 0 ? void 0 : searchLocation.toString(),
        ARRdd: Number(ARRdd),
        ARRmm: Number(ARRmm),
        ARRyyyy: Number(ARRyyyy),
        NIGHTS: Number(NIGHTS),
        ROOMS: Number(ROOMS),
        isSummaryActive: false
      });
    case _api.Area.PI:
    default:
      return (0, _jsxRuntime.jsx)(_index.PISearchContainer, {
        queryClient: queryClient,
        searchLocation: searchLocation === null || searchLocation === void 0 ? void 0 : searchLocation.toString(),
        ARRdd: Number(ARRdd),
        ARRmm: Number(ARRmm),
        ARRyyyy: Number(ARRyyyy),
        NIGHTS: Number(NIGHTS),
        ROOMS: Number(ROOMS),
        isSummaryActive: false
      });
  }
}
var loadingStyle = {
  height: '100%',
  width: '100%',
  left: 0,
  top: 0,
  position: 'fixed',
  zIndex: 1,
  backgroundColor: 'baseWhite',
  textAlign: 'center',
  lineHeight: 3,
  justifyContent: 'center',
  color: 'btnSecondaryEnabled',
  fontSize: 'xl',
  flex: 'none',
  flexGrow: 0,
  order: 1,
  alignSelf: 'stretch'
};