"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = Breadcrumb;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _objectWithoutProperties2 = _interopRequireDefault(require("@babel/runtime/helpers/objectWithoutProperties"));
var _react = require("@chakra-ui/react");
var _utils = require("@whitbread-eos/utils");
var _react2 = _interopRequireDefault(require("react"));
var _jsxRuntime = require("react/jsx-runtime");
var _excluded = ["linkComponent", "openInNewTab"];
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function Breadcrumb(_ref) {
  var _props$items;
  var {
      linkComponent: CustomLinkComponent,
      openInNewTab = false
    } = _ref,
    props = (0, _objectWithoutProperties2.default)(_ref, _excluded);
  return (0, _jsxRuntime.jsx)(_react.Breadcrumb, _objectSpread(_objectSpread({}, props), {}, {
    children: (_props$items = props.items) === null || _props$items === void 0 ? void 0 : _props$items.map(item => (0, _jsxRuntime.jsx)(_react.BreadcrumbItem, {
      isCurrentPage: item.isCurrentPage,
      children: CustomLinkComponent ? (0, _jsxRuntime.jsx)(CustomLinkComponent, {
        href: item.url,
        passHref: true,
        children: (0, _jsxRuntime.jsx)(_react.BreadcrumbLink, {
          children: item.name
        })
      }) : (0, _jsxRuntime.jsx)(_react.BreadcrumbLink, {
        as: _react.Link,
        isExternal: openInNewTab,
        href: openInNewTab ? (0, _utils.formatAssetsUrl)(item.url) : item.url,
        children: item.name
      })
    }, item.name))
  }));
}