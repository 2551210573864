"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.tomorrowLabel = exports.todayLabel = exports.modalButtonsStyles = exports.inputStyle = exports.inputIconStyles = exports.headingStyles = exports.handleNationalityChange = exports.gridStyles = exports.fieldProps = exports.dataErrorText = exports.additionalDetailsFieldsBooker = exports.additionalDetailsFields = void 0;
var gridStyles = exports.gridStyles = {
  w: {
    base: '100%',
    md: '50%'
  },
  flex: '0 0 auto',
  alignItems: 'center'
};
var inputStyle = exports.inputStyle = {
  height: 'var(--chakra-space-4xl)',
  _placeholder: {
    opacity: 0.7,
    color: 'var(--chakra-darkGrey1)'
  },
  borderColor: 'lightGrey1',
  borderRadius: 'var(--chakra-radii-md)'
};
var inputIconStyles = exports.inputIconStyles = {
  top: 'var(--chakra-space-sm)'
};
var dataErrorText = exports.dataErrorText = {
  alignItems: 'center',
  color: 'var(--chakra-colors-error)',
  fontSize: 'var(--chakra-fontSizes-xs)',
  marginLeft: '15px'
};
var todayLabel = exports.todayLabel = 'Today';
var tomorrowLabel = exports.tomorrowLabel = 'Tomorrow';
var additionalDetailsFields = exports.additionalDetailsFields = [{
  type: 'input',
  name: 'email',
  label: 'email',
  testId: 'Email',
  styles: {
    w: '100%'
  }
}, {
  type: 'datePicker',
  name: 'dateOfBirth',
  label: 'dateofbirth',
  testId: 'DateOfBirth',
  styles: {
    w: '100%'
  }
}, {
  type: 'autoComplete',
  name: 'nationality',
  label: 'nationality',
  testId: 'Nationality',
  styles: {
    w: '100%'
  }
}, {
  type: 'input',
  name: 'passport',
  label: 'passport',
  testId: 'Passport',
  styles: {
    w: '100%'
  }
}];
var additionalDetailsFieldsBooker = exports.additionalDetailsFieldsBooker = additionalDetailsFields.filter(_ref => {
  var {
    name
  } = _ref;
  return name !== 'email';
});
var headingStyles = exports.headingStyles = {
  fontFamily: 'header',
  fontSize: 'xl',
  fontWeight: 'semibold',
  lineHeight: '3',
  color: 'darkGrey1'
};
var modalButtonsStyles = exports.modalButtonsStyles = {
  w: {
    base: 'full',
    xs: '21.43rem',
    sm: '16.18rem',
    md: '15.93rem',
    lg: '16.5rem',
    xl: '17.87rem'
  },
  h: '3.5rem',
  borderRadius: '.25rem',
  p: 'md'
};
var fieldProps = _ref2 => {
  var {
    name,
    onBlur,
    value,
    onChange
  } = _ref2;
  return {
    name,
    onBlur,
    value,
    onChange
  };
};
exports.fieldProps = fieldProps;
var handleNationalityChange = (fieldProp, field, fieldToReset, handleSetValue) => {
  field.onChange(fieldProp);
  handleSetValue && handleSetValue(fieldToReset, '');
};
exports.handleNationalityChange = handleNationalityChange;