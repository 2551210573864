"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = HotelNameInput;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _react = require("@chakra-ui/react");
var _atoms = require("@whitbread-eos/atoms");
var _jsxRuntime = require("react/jsx-runtime");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function HotelNameInput(_ref) {
  var {
    hotelName,
    onInputChange
  } = _ref;
  return (0, _jsxRuntime.jsxs)(_react.InputGroup, _objectSpread(_objectSpread({}, inputGroupStyles), {}, {
    children: [(0, _jsxRuntime.jsx)(_react.InputLeftElement, {
      top: "50%",
      transform: "auto",
      translateY: "-50%",
      children: (0, _jsxRuntime.jsx)(_atoms.Location, {
        color: "var(--chakra-colors-lightGrey4)"
      })
    }), (0, _jsxRuntime.jsx)(_react.Input, _objectSpread(_objectSpread({
      placeholder: hotelName,
      value: hotelName,
      onChange: onInputChange,
      disabled: true,
      "data-testid": "Amend-StayDates-HotelNameInput"
    }, inputStyles), {}, {
      sx: {
        WebkitTextFillColor: 'var(--chakra-colors-lightGrey4)'
      }
    }))]
  }));
}
var inputGroupStyles = {
  minW: {
    mobile: '15rem',
    xs: '18.438rem',
    sm: '30.75rem',
    md: '20.25rem',
    lg: '24.75rem'
  }
};
var inputStyles = {
  w: 'full',
  maxW: {
    md: '20.25rem',
    lg: '24.75rem'
  },
  h: 'var(--chakra-space-4xl)',
  borderRadius: 'var(--chakra-space-radiusSmall)',
  borderColor: 'lightGrey1',
  color: 'lightGrey4',
  fontSize: 'var(--chakra-fontSizes-md)',
  lineHeight: 'var(--chakra-lineHeights-3)',
  fontWeight: 'var(--chakra-fontWeights-normal)',
  _placeholder: {
    color: 'lightGrey4'
  },
  _disabled: {
    borderColor: 'lightGrey4',
    _placeholder: {
      color: 'lightGrey4'
    }
  }
};