"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
var _hotelAvailabilitiesPI = require("./hotelAvailabilitiesPI");
Object.keys(_hotelAvailabilitiesPI).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _hotelAvailabilitiesPI[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _hotelAvailabilitiesPI[key];
    }
  });
});
var _searchInformation = require("./searchInformation");
Object.keys(_searchInformation).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _searchInformation[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _searchInformation[key];
    }
  });
});