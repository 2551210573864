"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _react = require("@chakra-ui/react");
var _atoms = require("@whitbread-eos/atoms");
var _utils = require("@whitbread-eos/utils");
var _nextI18next = require("next-i18next");
var _react2 = require("react");
var _jsxRuntime = require("react/jsx-runtime");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
var RoomCard = _ref => {
  var {
    testid,
    roomIndex = 0,
    room,
    roomCardContainerStyle = {},
    displayIcon = true,
    clickEventRequired = true,
    setURLParamToReservationId = () => (0, _jsxRuntime.jsx)(_jsxRuntime.Fragment, {}),
    preCheckInStatus = false
  } = _ref;
  var [preCheckInStatusDisplay, setPreCheckInStatusDisplay] = (0, _react2.useState)(preCheckInStatus);
  var {
    t
  } = (0, _nextI18next.useTranslation)();
  var roomNumber = number => number + 1;
  var formatText = (adults, children) => {
    var childStr = children > 1 ? 'account.dashboard.children' : 'account.dashboard.child';
    var adultStr = adults > 1 ? 'account.dashboard.adults' : 'account.dashboard.adult';
    var membersDetails = adults ? "".concat(adults, " ").concat(t(adultStr)) : '';
    membersDetails += children ? " ".concat(children, " ").concat(t(childStr)) : '';
    return membersDetails.trim();
  };
  var clickEvent = clickEventRequired ? {
    onClick: () => {
      !preCheckInStatus && setURLParamToReservationId(room.bookingReference, room.reservationId);
    }
  } : {};
  var parentWrapperStyle = preCheckInStatusDisplay ? disabledStyle : '';
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_react.Box, _objectSpread(_objectSpread(_objectSpread(_objectSpread(_objectSpread({}, boxParentStyle), roomCardContainerStyle === null || roomCardContainerStyle === void 0 ? void 0 : roomCardContainerStyle.boxParentStyle), {}, {
      "data-testid": (0, _utils.formatDataTestId)(testid, "Wrapper-".concat(roomNumber(roomIndex)))
    }, clickEvent), parentWrapperStyle), {}, {
      children: (0, _jsxRuntime.jsxs)(_react.Flex, {
        direction: 'row',
        children: [(0, _jsxRuntime.jsx)(_react.Box, {
          flex: "12",
          children: (0, _jsxRuntime.jsxs)(_react.Flex, _objectSpread(_objectSpread(_objectSpread({}, boxesContainerStyle), roomCardContainerStyle === null || roomCardContainerStyle === void 0 ? void 0 : roomCardContainerStyle.boxesContainerStyle), {}, {
            "data-testid": (0, _utils.formatDataTestId)(testid, "ContainerRoom-".concat(roomNumber(roomIndex))),
            children: [(0, _jsxRuntime.jsx)(_react.Box, _objectSpread(_objectSpread(_objectSpread({}, firstColumnStyle), roomCardContainerStyle === null || roomCardContainerStyle === void 0 ? void 0 : roomCardContainerStyle.firstColumnStyle), {}, {
              children: (0, _jsxRuntime.jsxs)(_react.Flex, {
                direction: "column",
                align: "flex-start",
                children: [(0, _jsxRuntime.jsx)(_react.Text, _objectSpread(_objectSpread(_objectSpread({}, headingStyle), roomCardContainerStyle === null || roomCardContainerStyle === void 0 ? void 0 : roomCardContainerStyle.headingStyle), {}, {
                  "data-testid": (0, _utils.formatDataTestId)(testid, "HeaderRoom-".concat(roomNumber(roomIndex))),
                  children: t('booking.leadGuest.ForRoom').replace('[roomNumber]', String(roomNumber(roomIndex)))
                })), (0, _jsxRuntime.jsx)(_react.Text, _objectSpread(_objectSpread({}, subHeadingStyle), {}, {
                  textTransform: "capitalize",
                  children: "".concat(room === null || room === void 0 ? void 0 : room.firstName, " ").concat(room === null || room === void 0 ? void 0 : room.lastName)
                }))]
              })
            })), (0, _jsxRuntime.jsx)(_react.Box, _objectSpread(_objectSpread(_objectSpread({}, secondColumnStyle), roomCardContainerStyle === null || roomCardContainerStyle === void 0 ? void 0 : roomCardContainerStyle.secondColumnStyle), {}, {
              children: (0, _jsxRuntime.jsx)(_react.Text, _objectSpread(_objectSpread(_objectSpread({}, textStyle), roomCardContainerStyle === null || roomCardContainerStyle === void 0 ? void 0 : roomCardContainerStyle.textStyle), {}, {
                children: "".concat(room === null || room === void 0 ? void 0 : room.roomName, " ").concat(formatText(room === null || room === void 0 ? void 0 : room.adultsNumber, room === null || room === void 0 ? void 0 : room.childrenNumber))
              }))
            })), (0, _jsxRuntime.jsx)(_react.Box, _objectSpread(_objectSpread(_objectSpread({}, thirdColumnStyle), roomCardContainerStyle === null || roomCardContainerStyle === void 0 ? void 0 : roomCardContainerStyle.iconStyle), {}, {
              display: {
                base: 'none',
                md: 'flex'
              },
              children: displayIcon && (0, _jsxRuntime.jsx)(_atoms.ChevronRight, {
                style: {
                  justifyContent: 'center'
                }
              })
            }))]
          }))
        }), (0, _jsxRuntime.jsx)(_react.Box, _objectSpread(_objectSpread(_objectSpread({}, thirdColumnStyle), roomCardContainerStyle === null || roomCardContainerStyle === void 0 ? void 0 : roomCardContainerStyle.iconStyle), {}, {
          display: {
            base: 'flex',
            md: 'none'
          },
          flex: 1,
          children: displayIcon && (0, _jsxRuntime.jsx)(_atoms.ChevronRight, {
            style: {
              justifyContent: 'center'
            }
          })
        }))]
      })
    })), !!preCheckInStatusDisplay && displayIcon && (0, _jsxRuntime.jsx)(_react.Box, {
      mb: "2xl",
      mt: "md",
      children: (0, _jsxRuntime.jsx)(_atoms.Notification, {
        status: "success",
        variant: "success",
        svg: (0, _jsxRuntime.jsx)(_atoms.Success, {
          style: {
            marginTop: 'var(--chakra-space-xs)'
          }
        }),
        prefixDataTestId: "room-checked-in-".concat(room.reservationId),
        showCloseButton: true,
        description: (0, _jsxRuntime.jsxs)(_react.Box, _objectSpread(_objectSpread({}, sxStyles), {}, {
          children: [(0, _jsxRuntime.jsx)(_react.Text, {
            fontWeight: 'semibold',
            children: t('precheckin.room.completetitle')
          }), (0, _jsxRuntime.jsx)(_react.Text, {
            children: t('precheckin.room.completemsg')
          })]
        })),
        wrapperStyles: {
          borderRadius: 'var(--chakra-space-sm)'
        },
        onClick: () => setPreCheckInStatusDisplay(false)
      })
    })]
  });
};
var _default = exports.default = RoomCard;
var sxStyles = {
  pt: 0,
  mt: 0,
  fontWeight: 'normal',
  lineHeight: 3,
  p: {
    pb: 'xs'
  },
  fontSize: 'md'
};
var boxParentStyle = {
  border: '1px solid var(--chakra-colors-lightGrey1)',
  mb: '3xl',
  px: {
    mobile: 'md',
    md: 'xl'
  },
  py: {
    mobile: 'md',
    md: 'lg'
  },
  cursor: 'pointer',
  borderRadius: 'var(--chakra-space-sm)',
  width: '100%',
  _hover: {
    borderColor: 'var(--chakra-colors-darkGrey1)'
  }
};
var disabledStyle = _objectSpread(_objectSpread({}, boxParentStyle), {}, {
  color: 'lightGrey1',
  opacity: 0.4,
  mb: 'sm',
  pointerEvents: 'none'
});
var boxesContainerStyle = {
  p: {
    mobile: 'md md',
    md: 'xl xl'
  },
  direction: {
    mobile: 'column',
    md: 'row'
  },
  cursor: 'pointer',
  alignItems: 'center',
  justifyContent: 'space-between',
  width: '100%'
};
var firstColumnStyle = {
  w: {
    mobile: '100%',
    md: 'auto'
  },
  minW: '15rem'
};
var secondColumnStyle = {
  w: {
    mobile: '100%',
    md: 'auto'
  },
  alignSelf: 'flex-end',
  pt: {
    mobile: 'lg'
  }
};
var thirdColumnStyle = {
  ml: 'auto',
  w: {
    mobile: '10px',
    md: 'auto'
  },
  alignSelf: 'center',
  textAlign: 'right',
  justifyContent: 'flex-end'
};
var headingStyle = {
  fontSize: 'xl',
  fontWeight: 'semibold',
  color: 'baseBlack',
  mb: 'md'
};
var subHeadingStyle = {
  fontSize: 'lg',
  fontWeight: 'medium',
  color: 'var(--chakra-colors-chakra-body-text)',
  textTransform: 'capitalize'
};
var textStyle = {
  color: 'var(--chakra-colors-darkGrey1)',
  fontWeight: 'sm',
  textTransform: 'capitalize'
};