"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = TableList;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _react = require("@chakra-ui/react");
var _utils = require("@whitbread-eos/utils");
var _react2 = require("react");
var _TableList = require("./TableList.style");
var _TableListRow = _interopRequireDefault(require("./TableListRow.component"));
var _jsxRuntime = require("react/jsx-runtime");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function TableList(_ref) {
  var {
    dataTestIdPrefix = '',
    columns,
    rows,
    isExpandable = true,
    expandBtnText = 'View',
    collapseBtnText = 'Close',
    renderExpandedContent,
    loadMoreText = '',
    onLoadMore,
    isLoadingMore = false,
    screenSize,
    containerStyles,
    externalHeaderStyles,
    rowStyles,
    handleRowClicked,
    rowHoverStyles,
    isFixedLayout = true
  } = _ref;
  var [expandedRowIndex, setExpandedRowIndex] = (0, _react2.useState)(null);
  var tableStyling = (0, _TableList.tableStyle)(isFixedLayout);
  (0, _react2.useEffect)(() => {
    rows.length === 1 ? setExpandedRowIndex(0) : setExpandedRowIndex(null);
  }, [rows.length]);
  var handleExpand = (index, shouldCollapse) => {
    if (!renderExpandedContent) {
      return;
    }
    setExpandedRowIndex(shouldCollapse ? null : index);
  };
  return (0, _jsxRuntime.jsxs)(_react.TableContainer, _objectSpread(_objectSpread(_objectSpread({
    "data-testid": (0, _utils.formatDataTestId)(dataTestIdPrefix, 'Table-Container')
  }, _TableList.resultContainerStyle), containerStyles), {}, {
    children: [(0, _jsxRuntime.jsxs)(_react.Table, {
      size: "sm",
      style: tableStyling,
      children: [(0, _jsxRuntime.jsx)(_react.Thead, _objectSpread(_objectSpread({}, _TableList.headerTableStyle), {}, {
        children: (0, _jsxRuntime.jsxs)(_react.Tr, {
          children: [columns.map(column => {
            return screenSize !== null && screenSize !== void 0 && screenSize.isLessThanSm && column !== null && column !== void 0 && column.hideOnMobile ? null : (0, _jsxRuntime.jsx)(_react.Th, _objectSpread(_objectSpread({}, _objectSpread(_objectSpread({}, _TableList.headerResultsTextStyle), externalHeaderStyles)), {}, {
              "data-testid": (0, _utils.formatDataTestId)(dataTestIdPrefix, "TableHeader-".concat(column.key)),
              children: column.title
            }), column.key);
          }), isExpandable && (0, _jsxRuntime.jsx)(_react.Th, _objectSpread(_objectSpread({}, _objectSpread(_objectSpread({}, _TableList.headerResultsTextStyle), externalHeaderStyles)), {}, {
            "data-testid": (0, _utils.formatDataTestId)(dataTestIdPrefix, "TableHeader-expand")
          }), 'expand')]
        })
      })), (0, _jsxRuntime.jsx)(_react.Tbody, {
        children: rows.map((row, rowIndex) => {
          return (0, _jsxRuntime.jsx)(_TableListRow.default, {
            row: row,
            rowIndex: rowIndex,
            columns: columns,
            dataTestIdPrefix: dataTestIdPrefix,
            isExpandable: isExpandable,
            expandBtnText: expandBtnText,
            collapseBtnText: collapseBtnText,
            screenSize: screenSize,
            isExpanded: expandedRowIndex === rowIndex,
            onExpand: handleExpand,
            renderExpandedContent: renderExpandedContent,
            rowStyles: rowStyles,
            rowHoverStyles: rowHoverStyles,
            handleRowClicked: handleRowClicked
          }, row.bookingReference);
        })
      })]
    }), onLoadMore && loadMoreText && (0, _jsxRuntime.jsx)(_react.Flex, {
      direction: "column",
      justify: "center",
      children: (0, _jsxRuntime.jsx)(_react.Button, _objectSpread(_objectSpread({
        variant: "tertiary",
        size: "sm",
        onClick: onLoadMore,
        "data-testid": (0, _utils.formatDataTestId)(dataTestIdPrefix, 'Table-LoadMore'),
        isLoading: isLoadingMore
      }, _TableList.loadMoreStyles), {}, {
        children: loadMoreText
      }))
    })]
  }));
}