"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = RoomFacilitiesComponent;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _react = require("@chakra-ui/react");
var _atoms = require("@whitbread-eos/atoms");
var _utils = require("@whitbread-eos/utils");
var _nextI18next = require("next-i18next");
var _react2 = require("react");
var _RoomFacilitiesModal = _interopRequireDefault(require("../RoomFacilitiesModal"));
var _jsxRuntime = require("react/jsx-runtime");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function RoomFacilitiesComponent(_ref) {
  var {
    facilities,
    isLessThanSm,
    isLessThanMd
  } = _ref;
  var {
    t
  } = (0, _nextI18next.useTranslation)(['common']);
  var [isModalVisible, setIsModalVisible] = (0, _react2.useState)(false);
  var visibleFacilities = facilities === null || facilities === void 0 ? void 0 : facilities.filter(item => item === null || item === void 0 ? void 0 : item.isVisible).slice(0, isLessThanMd && !isLessThanSm ? 3 : 5);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [renderItems(), renderModal()]
  });
  function renderItems() {
    var containerStyles = {
      flexDirection: {
        base: 'column',
        sm: 'row'
      },
      alignItems: {
        base: 'flex-start',
        sm: 'initial'
      },
      maxWidth: 'full',
      overflow: 'auto'
    };
    var linkStyles = {
      fontSize: 'sm',
      color: 'btnSecondaryEnabled',
      textDecoration: 'underline',
      textAlign: 'center'
    };
    var plusIconStyles = {
      w: 'var(--chakra-space-xl)',
      h: 'var(--chakra-space-xl)',
      justifyContent: 'center',
      alignItems: 'center',
      bgColor: 'lightGrey5',
      borderRadius: 'full'
    };
    var iconStyles = {
      h: {
        base: '6',
        sm: '8'
      },
      mr: {
        base: '3',
        sm: '0'
      }
    };
    return (0, _jsxRuntime.jsxs)(_react.Flex, _objectSpread(_objectSpread({}, containerStyles), {}, {
      children: [(0, _jsxRuntime.jsxs)(_react.Stack, {
        direction: {
          base: 'row'
        },
        spacing: {
          base: 'sm',
          sm: 'xl'
        },
        flexWrap: {
          base: 'wrap',
          sm: 'initial'
        },
        "data-testid": "room-facilities-wrap",
        children: [visibleFacilities === null || visibleFacilities === void 0 ? void 0 : visibleFacilities.map(roomFacility => (0, _jsxRuntime.jsx)(_react.Box, {
          children: (0, _jsxRuntime.jsxs)(_react.VStack, {
            spacing: "sm",
            children: [(0, _jsxRuntime.jsx)(_atoms.Icon, _objectSpread(_objectSpread({}, iconStyles), {}, {
              src: (0, _utils.formatAssetsUrl)(roomFacility === null || roomFacility === void 0 ? void 0 : roomFacility.icon)
            })), !isLessThanSm && (0, _jsxRuntime.jsx)(_react.Text, {
              fontSize: "xs",
              lineHeight: "2",
              textAlign: "center",
              children: roomFacility === null || roomFacility === void 0 ? void 0 : roomFacility.name
            })]
          })
        }, roomFacility === null || roomFacility === void 0 ? void 0 : roomFacility.code)), facilities && (facilities === null || facilities === void 0 ? void 0 : facilities.length) > 5 && !isLessThanSm && (0, _jsxRuntime.jsx)(_react.Box, {
          children: (0, _jsxRuntime.jsxs)(_react.VStack, {
            spacing: "sm",
            children: [(0, _jsxRuntime.jsx)(_react.Flex, _objectSpread(_objectSpread({}, plusIconStyles), {}, {
              children: (0, _jsxRuntime.jsx)(_atoms.Icon, {
                svg: (0, _jsxRuntime.jsx)(_atoms.Path, {
                  color: 'var(--chakra-colors-darkGrey2)'
                }),
                cursor: "pointer"
              })
            })), (0, _jsxRuntime.jsx)(_react.Link, _objectSpread(_objectSpread({}, linkStyles), {}, {
              onMouseDown: openModal,
              "data-testid": "facilities-desktop-link",
              children: t('hoteldetails.seeall.facilities')
            }))]
          })
        })]
      }), isLessThanSm && (0, _jsxRuntime.jsx)(_react.Box, {
        children: (0, _jsxRuntime.jsx)(_react.Link, _objectSpread(_objectSpread({}, linkStyles), {}, {
          mt: "md",
          display: "block",
          onMouseDown: openModal,
          "data-testid": "facilities-mobile-link",
          children: t('hoteldetails.seeall.facilities')
        }))
      })]
    }));
  }
  function renderModal() {
    return (0, _jsxRuntime.jsx)(_RoomFacilitiesModal.default, {
      facilities: facilities,
      isModalVisible: isModalVisible,
      onModalClose: () => setIsModalVisible(false)
    });
  }
  function openModal() {
    setIsModalVisible(true);
  }
}