"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = validateForm;
var _atoms = require("@whitbread-eos/atoms");
var yup = _interopRequireWildcard(require("yup"));
var _uniqueValidation = _interopRequireDefault(require("../../utils/common/uniqueValidation"));
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && {}.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
function validateForm(labels) {
  (0, _uniqueValidation.default)();
  var {
    titleError,
    firstNameRequiredError,
    firstNameMinError,
    lastNameRequiredError,
    firstNameInvalidError,
    lastNameInvalidError,
    emailInvalidError,
    checkUniqueError
  } = labels;
  var formValidationObject = {
    bbGuestDetails: yup.array().of(yup.object().shape({
      title: yup.string().required(titleError),
      firstName: yup.string().transform(value => value.trim()).required(firstNameRequiredError).matches(_atoms.FORM_VALIDATIONS.FIRST_NAME.MATCHES, firstNameInvalidError).min(_atoms.FORM_VALIDATIONS.FIRST_NAME.MIN, firstNameMinError).max(_atoms.FORM_VALIDATIONS.FIRST_NAME.MAX, firstNameRequiredError),
      lastName: yup.string().transform(value => value.trim()).required(lastNameRequiredError).matches(_atoms.FORM_VALIDATIONS.LAST_NAME_BB_GUEST_DETAILS.MATCHES, lastNameInvalidError).min(_atoms.FORM_VALIDATIONS.LAST_NAME_BB_GUEST_DETAILS.MIN, lastNameInvalidError).max(_atoms.FORM_VALIDATIONS.LAST_NAME_BB_GUEST_DETAILS.MAX, lastNameRequiredError),
      emailAddress: yup.string().transform(value => value.trim()).matches(_atoms.FORM_VALIDATIONS.BB_EMAIL_GD.MATCHES, emailInvalidError)
    })).unique('lastName', checkUniqueError),
    bbAccompanyingGuestDetails: yup.array().of(yup.object().shape({
      title: yup.string().optional(),
      firstName: yup.string().nullable().notRequired().transform(value => value.trim()).test('isEmptyOrValid', firstNameMinError, value => {
        return !value || (value === null || value === void 0 ? void 0 : value.length) >= _atoms.FORM_VALIDATIONS.FIRST_NAME.MIN;
      }).max(_atoms.FORM_VALIDATIONS.FIRST_NAME.MAX, firstNameRequiredError),
      lastName: yup.string().nullable().notRequired().transform(value => value.trim()).test('isEmptyOrValid', lastNameInvalidError, value => {
        return !value || (value === null || value === void 0 ? void 0 : value.length) >= _atoms.FORM_VALIDATIONS.LAST_NAME_BB_GUEST_DETAILS.MIN;
      }).max(_atoms.FORM_VALIDATIONS.LAST_NAME_BB_GUEST_DETAILS.MAX, lastNameRequiredError),
      emailAddress: yup.string().optional().transform(value => value.trim()).matches(_atoms.FORM_VALIDATIONS.BB_EMAIL_GD.MATCHES, emailInvalidError)
    })).optional().unique('lastName', checkUniqueError)
  };
  var formValidationSchema = yup.object().shape(formValidationObject);
  return {
    formValidationObject,
    formValidationSchema,
    checkIsUnique: _uniqueValidation.default
  };
}