"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _objectWithoutProperties2 = _interopRequireDefault(require("@babel/runtime/helpers/objectWithoutProperties"));
var _react = require("@chakra-ui/react");
var _api = require("@whitbread-eos/api");
var _react2 = require("react");
var _icons = require("../../assets/icons");
var _ChevronDown = _interopRequireDefault(require("../../assets/icons/ChevronDown"));
var _ChevronUp = _interopRequireDefault(require("../../assets/icons/ChevronUp"));
var _formatters = require("../../utils/formatters");
var _Icon = _interopRequireDefault(require("../Icon"));
var _Tooltip = _interopRequireDefault(require("../Tooltip"));
var _CountriesList = _interopRequireDefault(require("./CountriesList"));
var _jsxRuntime = require("react/jsx-runtime");
var _excluded = ["countries", "disabled", "error", "formatAssetsUrl", "showIcon", "inputProps", "label", "placeholder", "currentLang", "selectProps", "name", "value", "onChange", "onBlur", "handleTriggerValidation", "dependantOn", "dataTestId", "useTooltip"];
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function PhoneInput(_ref, forwardedRef) {
  var {
      countries = [],
      disabled,
      error,
      formatAssetsUrl = url => url,
      showIcon,
      inputProps,
      label,
      placeholder,
      currentLang,
      selectProps,
      name,
      value = {
        countryCode: '',
        dialingCode: '',
        phone: ''
      },
      onChange,
      onBlur,
      handleTriggerValidation,
      dependantOn,
      dataTestId,
      useTooltip = false
    } = _ref,
    rest = (0, _objectWithoutProperties2.default)(_ref, _excluded);
  var ref = (0, _react2.useRef)(null);
  var {
    isOpen,
    onToggle,
    onClose
  } = (0, _react.useDisclosure)();
  var displayIcon = showIcon && !disabled;
  var displayLabel = label && !disabled;
  var isValid = !error && value.phone;
  var baseDataTestId = dataTestId !== null && dataTestId !== void 0 ? dataTestId : 'PhoneInput';
  var currentCountrySite = currentLang === _api.CountryCode.DE ? _api.ShortCountry.DE : _api.ShortCountry.GB;
  (0, _react.useOutsideClick)({
    ref: ref,
    handler: () => onClose()
  });
  var sortedCountries = getSortedCountries(countries);
  var country = (0, _react2.useMemo)(() => countries.find(country => country.countryCode === value.countryCode && country.dialingCode === value.dialingCode), [countries, value.countryCode, value.dialingCode]);
  var handleCountryChange = (0, _react2.useCallback)(item => {
    onChange(_objectSpread(_objectSpread({}, value), {}, {
      countryCode: item.countryCode,
      dialingCode: item.dialingCode
    }));
    onClose();
  }, [value, onChange, onClose]);
  var handleNumberChange = (0, _react2.useCallback)(event => {
    onChange(_objectSpread(_objectSpread({}, value), {}, {
      phone: event.target.value
    }));
  }, [value, onChange]);
  var handleOnBlur = (0, _react2.useCallback)(() => {
    if (dependantOn && handleTriggerValidation) {
      handleTriggerValidation(dependantOn);
    }
    onBlur();
  }, [onBlur]);
  function getSortedCountries(data) {
    if (!data || (data === null || data === void 0 ? void 0 : data.length) === 0) {
      return [];
    }
    var sortedData = [...data].sort(function (a, b) {
      if (a.countryCode === currentCountrySite) {
        return -1;
      }
      if (b.countryCode === currentCountrySite) {
        return 0;
      }
      if (a.countryName < b.countryName) {
        return -1;
      }
      if (a.countryName > b.countryName) {
        return 1;
      }
      return 0;
    });
    return sortedData;
  }
  return (0, _jsxRuntime.jsxs)(_react.FormControl, _objectSpread(_objectSpread({
    isInvalid: !!error,
    ref: ref
  }, _objectSpread({}, rest)), {}, {
    children: [(0, _jsxRuntime.jsx)(_Tooltip.default, _objectSpread(_objectSpread({}, tooltipStyle), {}, {
      description: error !== null && error !== void 0 ? error : '',
      variant: "inlineError",
      placement: "bottom-start",
      isOpen: !!error && useTooltip,
      svg: (0, _jsxRuntime.jsx)(_icons.Error24, {}),
      children: (0, _jsxRuntime.jsxs)(_react.InputGroup, {
        children: [(0, _jsxRuntime.jsxs)(_react.InputLeftElement, _objectSpread(_objectSpread({
          "data-testid": (0, _formatters.formatDataTestId)(baseDataTestId, 'countrySelector')
        }, selectorStyle({
          disabled
        })), {}, {
          role: "button",
          onClick: onToggle,
          children: [country && (0, _jsxRuntime.jsxs)(_react.Flex, {
            "data-testid": (0, _formatters.formatDataTestId)(baseDataTestId, 'countryFlag'),
            children: [(0, _jsxRuntime.jsx)(_react.Image, {
              borderRadius: "full",
              boxSize: "1.5rem",
              src: formatAssetsUrl(country.flagSrc),
              title: country.countryName
            }), (0, _jsxRuntime.jsx)(_react.Text, {
              as: "span",
              ml: 1,
              whiteSpace: "nowrap",
              children: country.dialingCode
            })]
          }), (0, _jsxRuntime.jsx)(_Icon.default, {
            mx: "2",
            svg: isOpen ? (0, _jsxRuntime.jsx)(_ChevronUp.default, {
              "aria-label": "chevron-icon-up"
            }) : (0, _jsxRuntime.jsx)(_ChevronDown.default, {
              "aria-label": "chevron-icon-down"
            })
          })]
        })), displayLabel && (0, _jsxRuntime.jsx)(_react.FormLabel, _objectSpread(_objectSpread({
          "data-testid": (0, _formatters.formatDataTestId)(baseDataTestId, "".concat(name, "-label")),
          pos: "absolute"
        }, labelStyle(error, value)), {}, {
          htmlFor: name,
          children: label
        })), (0, _jsxRuntime.jsx)(_react.Input, _objectSpread(_objectSpread(_objectSpread({
          disabled: disabled,
          isInvalid: !!error,
          placeholder: placeholder !== null && placeholder !== void 0 ? placeholder : label,
          ref: forwardedRef,
          type: "tel",
          name: name,
          value: value.phone
        }, inputStyle(error)), {}, {
          onChange: handleNumberChange,
          onBlur: handleOnBlur
        }, inputProps), {}, {
          "data-testid": (0, _formatters.formatDataTestId)(baseDataTestId, 'phoneNumber')
        })), displayIcon && error && (0, _jsxRuntime.jsx)(_react.Box, _objectSpread(_objectSpread({}, iconStyle), {}, {
          "data-testid": (0, _formatters.formatDataTestId)(baseDataTestId, 'inputIconError'),
          children: (0, _jsxRuntime.jsx)(_icons.Error24, {})
        })), displayIcon && isValid && (0, _jsxRuntime.jsx)(_react.Box, _objectSpread(_objectSpread({}, iconStyle), {}, {
          "data-testid": (0, _formatters.formatDataTestId)(baseDataTestId, 'inputIconSuccess'),
          children: (0, _jsxRuntime.jsx)(_icons.Success24, {})
        }))]
      })
    })), error && !useTooltip && (0, _jsxRuntime.jsx)(_react.FormErrorMessage, {
      ml: "md",
      children: error
    }), isOpen ? (0, _jsxRuntime.jsx)(_CountriesList.default, _objectSpread({
      formatAssetsUrl: formatAssetsUrl,
      options: sortedCountries,
      onChange: handleCountryChange
    }, selectProps)) : null]
  }));
}
var inputStyle = error => {
  return {
    h: '3.5rem',
    focusBorderColor: 'primary',
    pl: '6.75em',
    borderColor: 'var(--chakra-colors-lightGrey1)',
    _placeholder: {
      color: 'var(--chakra-colors-darkGrey2)'
    },
    _hover: {
      borderColor: 'none'
    },
    _focus: {
      zIndex: '0',
      borderWidth: '2px',
      borderColor: error ? 'error' : 'primary'
    },
    _autofill: {
      boxShadow: '0 0 0 1000px #FFFFFF inset'
    }
  };
};
var selectorStyle = _ref2 => {
  var {
    disabled
  } = _ref2;
  return _objectSpread({
    cursor: 'pointer',
    h: 'full',
    justifyContent: 'flex-end',
    pl: '2',
    width: '6.5em'
  }, disabled ? {
    opacity: '0.4',
    pointerEvents: 'none'
  } : {});
};
var iconStyle = {
  ml: 'md',
  alignSelf: 'center'
};
var tooltipStyle = {
  h: '2.25rem',
  display: 'flex',
  alignContent: 'center'
};
var labelStyle = (error, value) => {
  return {
    h: '1.25rem',
    w: 'fit-content',
    fontSize: 'sm',
    align: 'center',
    px: 'xs',
    ml: '0.750rem',
    top: '-0.625rem',
    backgroundColor: 'baseWhite',
    zIndex: '1',
    color: error ? 'error' : 'darkGrey1',
    display: value !== null && value !== void 0 && value.phone ? 'block' : 'none',
    _focus: {
      color: error ? 'error' : 'primary',
      display: 'block'
    }
  };
};
var _default = exports.default = (0, _react2.memo)((0, _react2.forwardRef)(PhoneInput));