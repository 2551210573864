"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.breakpoints = void 0;
var breakpoints = exports.breakpoints = {
  mobile: '320px',
  xs: '375px',
  sm: '576px',
  md: '768px',
  lg: '1280px',
  xl: '1440px'
};