"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.SEARCH_A2C_COMPANIES = void 0;
var _taggedTemplateLiteral2 = _interopRequireDefault(require("@babel/runtime/helpers/taggedTemplateLiteral"));
var _graphqlRequest = require("graphql-request");
var _templateObject;
var SEARCH_A2C_COMPANIES = exports.SEARCH_A2C_COMPANIES = (0, _graphqlRequest.gql)(_templateObject || (_templateObject = (0, _taggedTemplateLiteral2.default)(["\n  query searchCompanies(\n    $companyName: String!\n    $arNumber: String!\n    $hotelId: String!\n    $limit: Int!\n  ) {\n    searchCompanies(\n      searchCompaniesCriteria: {\n        companyName: $companyName\n        arNumber: $arNumber\n        hotelId: $hotelId\n        limit: $limit\n      }\n    ) {\n      totalResults\n      hasMore\n      companies {\n        name\n        address {\n          addressLine1\n          addressLine2\n          addressLine3\n          addressLine4\n          country\n          postalCode\n        }\n        telephoneNumber\n        corpId\n        companyId\n        arNumber\n        profileType\n        language\n        active\n        restricted\n        restrictedReason\n      }\n    }\n  }\n"])));