"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = NavItem;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _objectWithoutProperties2 = _interopRequireDefault(require("@babel/runtime/helpers/objectWithoutProperties"));
var _react = require("@chakra-ui/react");
var _jsxRuntime = require("react/jsx-runtime");
var _excluded = ["title"];
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function NavItem(props) {
  var {
      title
    } = props,
    rest = (0, _objectWithoutProperties2.default)(props, _excluded);
  return (0, _jsxRuntime.jsx)(_react.Text, _objectSpread(_objectSpread(_objectSpread({}, rest), titleStyles), {}, {
    textAlign: "center",
    children: title
  }));
}
var titleStyles = {
  w: {
    lg: '11.5rem',
    xl: '12.375rem'
  },
  cursor: 'pointer',
  fontSize: 'lg',
  lineHeight: '3',
  color: 'darkGrey1',
  whiteSpace: 'nowrap',
  _focusVisible: {
    outline: 'none'
  },
  _hover: {
    color: 'primary'
  }
};