"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.handleManageBookingParamsCCUI = handleManageBookingParamsCCUI;
var REPEAT_BOOKING_PATH = 'repeat-booking';
var MANAGE_BOOKING_PATH = 'bookings';
function handleManageBookingParamsCCUI() {
  var _window, _window2;
  if (!((_window = window) !== null && _window !== void 0 && (_window = _window.location) !== null && _window !== void 0 && (_window = _window.pathname) !== null && _window !== void 0 && _window.includes(REPEAT_BOOKING_PATH)) && !((_window2 = window) !== null && _window2 !== void 0 && (_window2 = _window2.location) !== null && _window2 !== void 0 && (_window2 = _window2.pathname) !== null && _window2 !== void 0 && _window2.includes(MANAGE_BOOKING_PATH))) {
    var _sessionStorage;
    (_sessionStorage = sessionStorage) === null || _sessionStorage === void 0 || _sessionStorage.removeItem('ccuiPrevSearchCriteria');
  }
}