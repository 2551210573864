"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.EckohPayMethod = void 0;
var EckohPayMethod = exports.EckohPayMethod = function (EckohPayMethod) {
  EckohPayMethod["CREDIT_CARD_DEBIT_CARD"] = "ccdc";
  EckohPayMethod["PIBA_GB"] = "PIBAGB";
  EckohPayMethod["PIBA_DE"] = "PIBADE";
  return EckohPayMethod;
}({});