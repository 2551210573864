"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = AccountToCompanyContainer;
var _react = require("@chakra-ui/react");
var _AccountToCompany = _interopRequireDefault(require("./AccountToCompany.component"));
var _jsxRuntime = require("react/jsx-runtime");
function AccountToCompanyContainer(_ref) {
  var {
    hotelId,
    selectedPaymentDetail,
    setACCharges,
    setIsTotalCostVisible,
    setCompanyReferenceError,
    setCompanyNumber,
    setCompanyId,
    setACCompanyReference,
    setCompanyDetails,
    isFromChangePaymentBIC
  } = _ref;
  var setPreAuthorisedCharges = charges => {
    var newCharges = charges.map(charge => charge.label);
    setACCharges(newCharges);
  };
  return (0, _jsxRuntime.jsx)(_react.Box, {
    "data-testid": "accountToCompanyContainer",
    children: (0, _jsxRuntime.jsx)(_AccountToCompany.default, {
      hotelId: hotelId,
      selectedPaymentDetail: selectedPaymentDetail,
      setPreAuthorisedCharges: setPreAuthorisedCharges,
      setIsTotalCostVisible: setIsTotalCostVisible,
      setCompanyReferenceError: setCompanyReferenceError,
      setCompanyNumber: setCompanyNumber,
      setCompanyId: setCompanyId,
      setCompanyDetails: setCompanyDetails,
      setACCompanyReference: setACCompanyReference,
      isFromChangePaymentBIC: isFromChangePaymentBIC
    })
  });
}