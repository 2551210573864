"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.RESTAURANT_FACILITY_CODES = exports.FREE_PARKING_FACILITY_CODES = exports.AIR_CONDITIONING_FACILITY_CODES = exports.ACCESSIBLE_FACILITY_CODES = exports.ACCEPTED_HOTEL_CARD_FACILITIES = void 0;
var AIR_CONDITIONING_FACILITY_CODES = exports.AIR_CONDITIONING_FACILITY_CODES = ['ACO', 'HAC'];
var FREE_PARKING_FACILITY_CODES = exports.FREE_PARKING_FACILITY_CODES = ['PAF', 'COC', 'COP', 'CPF', 'CPP'];
var RESTAURANT_FACILITY_CODES = exports.RESTAURANT_FACILITY_CODES = ['HRS', 'DIN', 'RES', 'ZFB'];
var ACCESSIBLE_FACILITY_CODES = exports.ACCESSIBLE_FACILITY_CODES = ['HAR'];
var ACCEPTED_HOTEL_CARD_FACILITIES = exports.ACCEPTED_HOTEL_CARD_FACILITIES = [FREE_PARKING_FACILITY_CODES, RESTAURANT_FACILITY_CODES];