"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = FormCheckbox;
var _objectWithoutProperties2 = _interopRequireDefault(require("@babel/runtime/helpers/objectWithoutProperties"));
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _react = require("@chakra-ui/react");
var _reactHookForm = require("react-hook-form");
var _Checkbox = _interopRequireDefault(require("../../Checkbox"));
var _FormError = _interopRequireDefault(require("../FormError"));
var _jsxRuntime = require("react/jsx-runtime");
var _excluded = ["onChange", "ref"];
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function FormCheckbox(_ref) {
  var {
    control,
    formField,
    errors
  } = _ref;
  return (0, _jsxRuntime.jsx)(_react.Flex, _objectSpread(_objectSpread({
    direction: "column"
  }, _objectSpread(_objectSpread({}, defaultStyles), formField.styles)), {}, {
    "data-testid": formField.testid,
    children: (0, _jsxRuntime.jsx)(_reactHookForm.Controller, {
      name: formField.name,
      control: control,
      render: _ref2 => {
        var {
          field
        } = _ref2;
        var {
            onChange: _onChange,
            ref: _
          } = field,
          extraPropsField = (0, _objectWithoutProperties2.default)(field, _excluded);
        return (0, _jsxRuntime.jsxs)(_react.Box, {
          "data-testid": formField.testid,
          "data-fieldname": formField.name,
          children: [(0, _jsxRuntime.jsx)(_Checkbox.default, _objectSpread(_objectSpread(_objectSpread({}, formField.props), extraPropsField), {}, {
            onChange: o => {
              var _formField$onChange;
              (_formField$onChange = formField.onChange) === null || _formField$onChange === void 0 || _formField$onChange.call(formField, o.target.checked);
              _onChange(o.target.checked);
            },
            children: (0, _jsxRuntime.jsx)(_react.Text, {
              children: formField.label
            })
          })), (0, _jsxRuntime.jsx)(_FormError.default, {
            errors: errors,
            name: formField.name
          })]
        });
      }
    })
  }));
}
var defaultStyles = {
  marginBottom: 'var(--chakra-space-md)'
};