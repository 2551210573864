"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = HotelDetailsContainer;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));
var _react = require("@chakra-ui/react");
var _reactQuery = require("@tanstack/react-query");
var _api = require("@whitbread-eos/api");
var _atoms = require("@whitbread-eos/atoms");
var _utils = require("@whitbread-eos/utils");
var _nextI18next = require("next-i18next");
var _react2 = require("react");
var _HotelDetails = _interopRequireDefault(require("./HotelDetails.component"));
var _jsxRuntime = require("react/jsx-runtime");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function HotelDetailsContainer(_ref) {
  var {
    bookingReference,
    basketReference,
    hotelId,
    area
  } = _ref;
  var {
    t
  } = (0, _nextI18next.useTranslation)();
  var {
    language,
    country
  } = (0, _utils.useCustomLocale)();
  var [bookingState, setBookingState] = (0, _react2.useState)({
    isLoading: false,
    error: null
  });
  var idTokenCookie = (0, _utils.getAuthCookie)();
  var loggedOrCCUI = idTokenCookie || area === _api.Area.CCUI;
  var [data, setData] = (0, _react2.useState)({
    hotelInformation: {
      brand: '',
      galleryImages: [{
        alt: '',
        thumbnailSrc: ''
      }],
      links: {
        detailsPage: ''
      },
      parkingDescription: '',
      address: ''
    }
  });
  var getHotelDetails = function () {
    var _ref2 = (0, _asyncToGenerator2.default)(function* (hotelId) {
      var getHotelDetailsRequest = queryClient.fetchQuery({
        queryKey: ['GetHotelInformation', hotelId, country, language],
        queryFn: () => (0, _utils.graphQLRequest)(_api.GET_HOTEL_INFORMATION, {
          hotelId,
          language,
          country
        })
      });
      yield getHotelDetailsRequest.then(data => {
        var _data$hotelInformatio, _hotelInformationSele;
        if (typeof (data === null || data === void 0 || (_data$hotelInformatio = data.hotelInformation) === null || _data$hotelInformatio === void 0 ? void 0 : _data$hotelInformatio.address) === 'object') data.hotelInformation.address = (_hotelInformationSele = (0, _utils.hotelInformationSelector)(data === null || data === void 0 ? void 0 : data.hotelInformation)) === null || _hotelInformationSele === void 0 || (_hotelInformationSele = _hotelInformationSele.hotelAddress) === null || _hotelInformationSele === void 0 ? void 0 : _hotelInformationSele.join(', ');
        setData(data);
        setBookingState(_objectSpread(_objectSpread({}, bookingState), {}, {
          isLoading: false
        }));
      }).catch(error => {
        console.log(error);
      });
    });
    return function getHotelDetails(_x) {
      return _ref2.apply(this, arguments);
    };
  }();
  var queryClient = (0, _reactQuery.useQueryClient)();
  var getHotelIdAndDetails = (0, _react2.useCallback)(function () {
    var _ref3 = (0, _asyncToGenerator2.default)(function* (bookingReference, basketReference) {
      setBookingState(_objectSpread(_objectSpread({}, bookingState), {}, {
        isLoading: true
      }));
      var idTokenCookie = (0, _utils.getAuthCookie)();
      var bookingConfirmationQuery = queryClient.fetchQuery({
        queryKey: [loggedOrCCUI ? 'getBookingConfirmationAuthenticated' : 'getBookingConfirmation', loggedOrCCUI ? bookingReference : basketReference, language, country],
        queryFn: () => (0, _utils.graphQLRequest)(loggedOrCCUI ? _api.GET_DASHBOARD_BOOKING_CONFIRMATION_AUTHENTICATED : _api.GET_DASHBOARD_BOOKING_CONFIRMATION, loggedOrCCUI ? {
          bookingReference,
          language,
          country,
          bookingChannel: area === null || area === void 0 ? void 0 : area.toUpperCase()
        } : {
          basketReference,
          language,
          country,
          bookingChannel: area === null || area === void 0 ? void 0 : area.toUpperCase()
        }, loggedOrCCUI ? idTokenCookie : undefined)
      });
      yield bookingConfirmationQuery.then(function () {
        var _ref4 = (0, _asyncToGenerator2.default)(function* (bookingConfirmationData) {
          var bookingData = loggedOrCCUI ? bookingConfirmationData === null || bookingConfirmationData === void 0 ? void 0 : bookingConfirmationData.bookingConfirmationAuthenticated : bookingConfirmationData === null || bookingConfirmationData === void 0 ? void 0 : bookingConfirmationData.bookingConfirmation;
          var hotelId = bookingData === null || bookingData === void 0 ? void 0 : bookingData.hotelId;
          yield getHotelDetails(hotelId);
        });
        return function (_x4) {
          return _ref4.apply(this, arguments);
        };
      }()).catch(error => {
        setBookingState({
          isLoading: false,
          error: error
        });
        console.log(error);
      });
    });
    return function (_x2, _x3) {
      return _ref3.apply(this, arguments);
    };
  }(), []);
  (0, _react2.useEffect)(() => {
    if ((0, _utils.isStringValid)(bookingReference) || (0, _utils.isStringValid)(basketReference)) {
      if (hotelId && (0, _utils.isStringValid)(hotelId)) {
        getHotelDetails(hotelId);
        return;
      }
      getHotelIdAndDetails(bookingReference, basketReference);
    }
  }, [bookingReference, basketReference, hotelId]);
  if (bookingState.error) {
    return (0, _jsxRuntime.jsx)(_atoms.Notification, {
      status: "error",
      description: bookingState.error,
      variant: "alert",
      maxW: "full",
      svg: (0, _jsxRuntime.jsx)(_atoms.Alert, {})
    });
  }
  if (bookingState.isLoading) {
    return (0, _jsxRuntime.jsx)(_react.Flex, _objectSpread(_objectSpread({}, loadingStyle), {}, {
      "data-testid": "Loading-HotelDetails",
      children: (0, _jsxRuntime.jsx)(_atoms.LoadingSpinner, {
        loadingText: t('booking.loading')
      })
    }));
  }
  return (0, _jsxRuntime.jsx)(_HotelDetails.default, {
    data: data
  });
}
var loadingStyle = {
  height: '100%',
  width: '100%',
  left: 0,
  top: 0,
  position: 'fixed',
  zIndex: 999,
  backgroundColor: 'baseWhite',
  textAlign: 'center',
  lineHeight: 3,
  justifyContent: 'center',
  color: 'btnSecondaryEnabled',
  fontSize: 'xl',
  flex: 'none',
  flexGrow: 0,
  order: 1,
  alignSelf: 'stretch'
};