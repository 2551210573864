"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _react = require("@chakra-ui/react");
var _atoms = require("@whitbread-eos/atoms");
var _nextI18next = require("next-i18next");
var _react2 = _interopRequireWildcard(require("react"));
var _CompanyList = _interopRequireDefault(require("./CompanyList.component"));
var _CompanyProfile = _interopRequireDefault(require("./CompanyProfile.component"));
var _modalType = require("./modalType.enum");
var _selectionSteps = require("./selectionSteps.enum");
var _jsxRuntime = require("react/jsx-runtime");
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && {}.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
function CompanySelection(_ref) {
  var _selectedCompany$rest;
  var {
    showCompanySelectionModal,
    companyModalType,
    companies,
    tooManyResults = false,
    finalFocusRef,
    onClose,
    onCompanyVerified
  } = _ref;
  var {
    t
  } = (0, _nextI18next.useTranslation)();
  var [currentStep, setCurrentStep] = (0, _react2.useState)(_selectionSteps.SELECTION_STEP.LIST);
  var [selectedCompany, setSelectedCompany] = (0, _react2.useState)(null);
  var isVerifyAndConfirmDisabled = companyModalType === _modalType.COMPANY_MODAL_TYPE.ACCOUNT_TO_COMPANY ? (_selectedCompany$rest = selectedCompany === null || selectedCompany === void 0 ? void 0 : selectedCompany.restricted) !== null && _selectedCompany$rest !== void 0 ? _selectedCompany$rest : true : false;
  var baseDataTestId = 'CompanySelection';
  var finalRef;
  if (currentStep === _selectionSteps.SELECTION_STEP.LIST) {
    finalRef = finalFocusRef;
  }
  var handleCompanySelected = selectedCompany => {
    var company = companies.find(comp => comp.companyId === selectedCompany.companyId);
    if (company) {
      setSelectedCompany(company);
      setCurrentStep(_selectionSteps.SELECTION_STEP.PROFILE);
    }
  };
  var goBackToCompanyList = () => {
    setSelectedCompany(null);
    setCurrentStep(_selectionSteps.SELECTION_STEP.LIST);
  };
  var modalTitle = currentStep === _selectionSteps.SELECTION_STEP.LIST ? t('ccui.companyModals.selectCompany') : t('ccui.companyModals.companyProfile');
  var modalBody = currentStep === _selectionSteps.SELECTION_STEP.LIST ? (0, _jsxRuntime.jsx)(_CompanyList.default, {
    companyModalType: companyModalType,
    companies: companies,
    tooManyResults: tooManyResults,
    onCompanySelected: handleCompanySelected
  }) : (0, _jsxRuntime.jsx)(_CompanyProfile.default, {
    selectedCompany: selectedCompany,
    companyModalType: companyModalType
  });
  var modalFooter = currentStep === _selectionSteps.SELECTION_STEP.LIST ? (0, _jsxRuntime.jsx)(_atoms.Button, {
    variant: "tertiary",
    "data-testid": "".concat(baseDataTestId, "-ModalCancelButton"),
    onClick: onClose,
    size: 'md',
    children: t('ccui.companyModals.close')
  }) : (0, _jsxRuntime.jsxs)(_react.Flex, {
    gap: "4",
    children: [(0, _jsxRuntime.jsx)(_atoms.Button, {
      variant: "secondary",
      "data-testid": "".concat(baseDataTestId, "-ModalVerifyButton"),
      isDisabled: isVerifyAndConfirmDisabled,
      onClick: () => {
        selectedCompany && onCompanyVerified(selectedCompany);
      },
      size: 'md',
      children: t('ccui.companyModals.verifyConfirm')
    }), (0, _jsxRuntime.jsx)(_atoms.Button, {
      variant: "tertiary",
      "data-testid": "".concat(baseDataTestId, "-ModalCancelButton"),
      onClick: goBackToCompanyList,
      size: 'md',
      children: t('ccui.companyModals.close')
    })]
  });
  var variantProps = {
    title: modalTitle,
    finalFocusRef: finalRef,
    footer: modalFooter,
    modalHeight: modalHeight(currentStep),
    externalFooterStyling: footerStyles(currentStep)
  };
  return (0, _jsxRuntime.jsx)(_atoms.ModalVariants, {
    isOpen: showCompanySelectionModal,
    onClose: currentStep === _selectionSteps.SELECTION_STEP.LIST ? onClose : goBackToCompanyList,
    variant: "data",
    variantProps: variantProps,
    updatedWidth: modalWidth(currentStep),
    dataTestId: baseDataTestId,
    children: modalBody
  });
}
var modalWidth = currentStep => {
  var width = currentStep === _selectionSteps.SELECTION_STEP.LIST ? {
    lg: '1224px',
    xl: '1302px'
  } : {
    lg: '808px',
    xl: '864px'
  };
  return width;
};
var modalHeight = currentStep => {
  var height = currentStep === _selectionSteps.SELECTION_STEP.LIST ? {
    lg: '937px'
  } : {
    lg: 'fit-content'
  };
  return height;
};
var footerStyles = currentStep => {
  var styles = currentStep === _selectionSteps.SELECTION_STEP.LIST ? {
    paddingInlineStart: 'var(--chakra-space-4)',
    paddingInlineEnd: 'var(--chakra-space-4)',
    py: 'var(--chakra-space-6)'
  } : {
    paddingInlineStart: 'var(--chakra-space-8)',
    paddingInlineEnd: 'var(--chakra-space-8)',
    paddingTop: 'var(--chakra-space-10)',
    paddingBottom: 'var(--chakra-space-8)'
  };
  return styles;
};
var _default = exports.default = CompanySelection;