"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.isGuestDetailsPageAllowed = exports.isDataCollectionMsgAndFormVisible = void 0;
var _api = require("@whitbread-eos/api");
var _validators = require("../validators");
var isGuestDetailsPageAllowed = accessLevel => {
  if (!(0, _validators.isStringValid)(accessLevel)) {
    return false;
  }
  return [_api.UserAccessLevels.SUPER, _api.UserAccessLevels.BOOKER, _api.UserAccessLevels.SELF].includes(accessLevel);
};
exports.isGuestDetailsPageAllowed = isGuestDetailsPageAllowed;
var isDataCollectionMsgAndFormVisible = accessLevel => {
  if (!(0, _validators.isStringValid)(accessLevel)) {
    return false;
  }
  return [_api.UserAccessLevels.SUPER, _api.UserAccessLevels.BOOKER].includes(accessLevel);
};
exports.isDataCollectionMsgAndFormVisible = isDataCollectionMsgAndFormVisible;