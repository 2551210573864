"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "HotelMap", {
  enumerable: true,
  get: function get() {
    return _components.HotelMap;
  }
});
exports.default = void 0;
var _HotelDirections = _interopRequireDefault(require("./HotelDirections.component"));
var _components = require("./components");
var _default = exports.default = _HotelDirections.default;