"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = PaymentDetailsTabs;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _react = require("@chakra-ui/react");
var _api = require("@whitbread-eos/api");
var _atoms = require("@whitbread-eos/atoms");
var _utils = require("@whitbread-eos/utils");
var _router = require("next/router");
var _react2 = require("react");
var _PaymentType = _interopRequireDefault(require("../PaymentType"));
var _jsxRuntime = require("react/jsx-runtime");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
var baseDataTestId = 'PaymentDetailsTabs';
function PaymentDetailsTabs(_ref) {
  var _data$paymentMethods, _data$paymentMethods2;
  var {
    handlePaymentTypeSection,
    selectedPaymentDetail,
    selectedPaymentType,
    setSelectedPaymentDetail,
    t,
    userType,
    variant
  } = _ref;
  var router = (0, _router.useRouter)();
  var {
    query
  } = router;
  var {
    language,
    country
  } = (0, _utils.useCustomLocale)();
  var idTokenCookie = (0, _utils.getAuthCookie)();
  var setAsPayOnArrival = () => {
    var _selectedPaymentType$;
    var payOnArrivalOptionAvailable = selectedPaymentType === null || selectedPaymentType === void 0 || (_selectedPaymentType$ = selectedPaymentType.paymentOptions) === null || _selectedPaymentType$ === void 0 ? void 0 : _selectedPaymentType$.find(m => (m === null || m === void 0 ? void 0 : m.type) === _api.paymentOptions.PAY_ON_ARRIVAL && (m === null || m === void 0 ? void 0 : m.enabled));
    if (payOnArrivalOptionAvailable) {
      setSelectedPaymentDetail(payOnArrivalOptionAvailable);
    }
  };
  (0, _react2.useEffect)(() => {
    if (selectedPaymentDetail.type === 'default') {
      setAsPayOnArrival();
    }
  }, [selectedPaymentType]);
  var {
    data
  } = (0, _utils.useQueryRequest)(['getPaymentMethods', language, country, query.reservationId], _api.GET_PAYMENT_METHODS_QUERY, _objectSpread({
    basketReference: query.reservationId,
    language,
    country,
    userType: idTokenCookie ? userType : undefined
  }, variant && variant === _api.Area.PI && {
    clientChannel: variant.toUpperCase()
  }), {
    cacheTime: 0
  }, idTokenCookie);
  var onlyPaynowAvailable = data === null || data === void 0 || (_data$paymentMethods = data.paymentMethods) === null || _data$paymentMethods === void 0 ? void 0 : _data$paymentMethods.every(payment => {
    var _payment$paymentOptio;
    return (_payment$paymentOptio = payment.paymentOptions) === null || _payment$paymentOptio === void 0 ? void 0 : _payment$paymentOptio.every(option => !(option.type === _api.paymentOptions.PAY_ON_ARRIVAL && option.enabled));
  });
  (0, _react2.useEffect)(() => {
    if (onlyPaynowAvailable && (selectedPaymentDetail === null || selectedPaymentDetail === void 0 ? void 0 : selectedPaymentDetail.type) !== _api.paymentOptions.PAY_NOW) {
      var _selectedPaymentType$2;
      var payNow = (_selectedPaymentType$2 = selectedPaymentType.paymentOptions) === null || _selectedPaymentType$2 === void 0 ? void 0 : _selectedPaymentType$2.find(paymentOption => paymentOption.type === _api.paymentOptions.PAY_NOW);
      if (payNow) {
        setSelectedPaymentDetail(payNow);
      }
    }
  }, [onlyPaynowAvailable, selectedPaymentType]);
  if (onlyPaynowAvailable) {
    return (0, _jsxRuntime.jsx)(_PaymentType.default, {
      selectedPaymentDetail: selectedPaymentDetail,
      selectedPaymentType: selectedPaymentType,
      onPaymentTypeClick: handlePaymentTypeSection,
      userType: userType,
      variant: variant,
      isUsedWithTabs: true,
      onlyShowPayNow: onlyPaynowAvailable
    });
  }
  var paymentTypesImages = data ? data === null || data === void 0 || (_data$paymentMethods2 = data.paymentMethods) === null || _data$paymentMethods2 === void 0 ? void 0 : _data$paymentMethods2.reduce((acc, payment) => {
    var _payment$paymentOptio2;
    var payNowEnabled = false;
    var payOnArrivalEnabled = false;
    payment === null || payment === void 0 || (_payment$paymentOptio2 = payment.paymentOptions) === null || _payment$paymentOptio2 === void 0 || _payment$paymentOptio2.forEach(option => {
      if (option.type === _api.paymentOptions.PAY_NOW && option.enabled) {
        payNowEnabled = true;
      }
      if (option.type === _api.paymentOptions.PAY_ON_ARRIVAL && option.enabled) {
        payOnArrivalEnabled = true;
      }
    });
    if (payNowEnabled) {
      if (((payment === null || payment === void 0 ? void 0 : payment.name) === _api.PAYPAL_PAYMENT || (payment === null || payment === void 0 ? void 0 : payment.name) === _api.GOOGLE || (payment === null || payment === void 0 ? void 0 : payment.name) === _api.APPLE) && payment.type && payment.logoSrc) {
        acc.payNow[payment.type] = payment.logoSrc;
      } else {
        var _payment$acceptedCard;
        payment === null || payment === void 0 || (_payment$acceptedCard = payment.acceptedCardTypes) === null || _payment$acceptedCard === void 0 || _payment$acceptedCard.forEach(card => {
          if (!acc.payNow[card.type] && card.type && card.logoSrc) {
            acc.payNow[card.type] = card.logoSrc;
          }
        });
      }
    }
    if (payOnArrivalEnabled) {
      if (((payment === null || payment === void 0 ? void 0 : payment.name) === _api.PAYPAL_PAYMENT || (payment === null || payment === void 0 ? void 0 : payment.name) === _api.GOOGLE || (payment === null || payment === void 0 ? void 0 : payment.name) === _api.APPLE) && payment.type && payment.logoSrc) {
        acc.payNow[payment.type] = payment.logoSrc;
      } else {
        var _payment$acceptedCard2;
        payment === null || payment === void 0 || (_payment$acceptedCard2 = payment.acceptedCardTypes) === null || _payment$acceptedCard2 === void 0 || _payment$acceptedCard2.forEach(card => {
          if (!acc.payOnArrival[card.type] && card.type && card.logoSrc) {
            acc.payOnArrival[card.type] = card.logoSrc;
          }
        });
      }
    }
    return acc;
  }, {
    payNow: {},
    payOnArrival: {}
  }) : {
    payNow: {},
    payOnArrival: {}
  };
  var sortedImageList = {
    payNow: (0, _utils.sortImagesByOrder)(paymentTypesImages.payNow),
    payOnArrival: (0, _utils.sortImagesByOrder)(paymentTypesImages.payOnArrival)
  };
  return (0, _jsxRuntime.jsxs)(_react.Box, {
    "data-testid": (0, _utils.formatDataTestId)(baseDataTestId, 'Container'),
    children: [(0, _jsxRuntime.jsx)(_react.Text, _objectSpread(_objectSpread({}, titleStyle), {}, {
      "data-testid": (0, _utils.formatDataTestId)(baseDataTestId, 'Header'),
      children: t('paymentOptions.header')
    })), (0, _jsxRuntime.jsx)(_react.Text, _objectSpread(_objectSpread({}, textStyle), {}, {
      "data-testid": (0, _utils.formatDataTestId)(baseDataTestId, 'Title'),
      children: t('paymentOptions.title')
    })), (0, _jsxRuntime.jsx)(_atoms.Tabs, {
      variant: "greyTabsGroup",
      sx: _objectSpread({}, tabsStyles()),
      onChange: i => {
        var _selectedPaymentType$3, _selectedPaymentType$4;
        var paymentOption = (_selectedPaymentType$3 = selectedPaymentType === null || selectedPaymentType === void 0 || (_selectedPaymentType$4 = selectedPaymentType.paymentOptions) === null || _selectedPaymentType$4 === void 0 ? void 0 : _selectedPaymentType$4[i]) !== null && _selectedPaymentType$3 !== void 0 ? _selectedPaymentType$3 : _api.DEFAULT_SELECTED_PAYMENT_DETAIL;
        setSelectedPaymentDetail(paymentOption);
      },
      defaultIndex: 1,
      styles: {
        tab,
        tabList
      },
      options: [{
        index: 0,
        label: t("ccui.paymentOption.payNow"),
        images: renderImages(sortedImageList.payNow),
        content: (0, _jsxRuntime.jsx)(_PaymentType.default, {
          selectedPaymentDetail: selectedPaymentDetail,
          selectedPaymentType: selectedPaymentType,
          onPaymentTypeClick: handlePaymentTypeSection,
          userType: userType,
          variant: variant,
          isUsedWithTabs: true
        })
      }, {
        index: 1,
        label: t("ccui.paymentOption.payOnArrival"),
        images: renderImages(sortedImageList.payOnArrival),
        content: (0, _jsxRuntime.jsx)(_PaymentType.default, {
          selectedPaymentDetail: selectedPaymentDetail,
          selectedPaymentType: selectedPaymentType,
          onPaymentTypeClick: handlePaymentTypeSection,
          userType: userType,
          variant: variant,
          isUsedWithTabs: true
        })
      }]
    })]
  });
}
var renderImages = imageObject => {
  var hasMoreThanSix = false;
  var keys = Object.keys(imageObject);
  var numberOfColumns = keys.length;
  if (keys.length > 6) {
    hasMoreThanSix = true;
  }
  var images = keys.map(key => (0, _jsxRuntime.jsx)(_react.Image, _objectSpread(_objectSpread({}, imgStyle), {}, {
    src: (0, _utils.isUrl)(imageObject[key]) ? imageObject[key] : (0, _utils.formatAssetsUrl)(imageObject[key]),
    "data-testid": (0, _utils.formatDataTestId)(baseDataTestId, "Image-".concat(key))
  })));
  if (hasMoreThanSix) {
    images = images.slice(0, 5);
    numberOfColumns = 6;
  }
  return (0, _jsxRuntime.jsxs)(_react.Box, _objectSpread(_objectSpread({}, imgContainerStyles(numberOfColumns)), {}, {
    "data-testid": (0, _utils.formatDataTestId)(baseDataTestId, 'ImageContainer'),
    children: [images, hasMoreThanSix && (0, _jsxRuntime.jsxs)(_react.Box, _objectSpread(_objectSpread(_objectSpread({}, imgStyle), placeholderImage), {}, {
      children: ["+ ", keys.length - 5]
    }))]
  }));
};
var tabsStyles = () => ({
  border: '1px solid #D7D8D6',
  mb: '4rem',
  width: {
    mobile: '18rem',
    xs: '21.5rem',
    sm: '33.75rem',
    md: '45rem',
    lg: '50.5rem',
    xl: '54rem'
  },
  '.chakra-tabs__tab-panel': {
    px: {
      mobile: '1rem',
      xs: '1rem',
      sm: '1rem',
      md: '1.5rem',
      lg: '3rem',
      xl: '3rem'
    },
    py: {
      mobile: '1.5rem',
      xs: '1.5rem',
      sm: '1.5rem',
      md: '2rem',
      lg: '3rem',
      xl: '3rem'
    }
  }
});
var imgContainerStyles = columns => ({
  display: 'grid',
  gridAutoFlow: 'rows',
  gridTemplateColumns: {
    mobile: 'repeat(3, 1fr)',
    md: "repeat(".concat(columns, ", 1fr)")
  },
  columnGap: '0.75rem',
  rowGap: {
    mobile: '1rem',
    md: 'unset'
  },
  paddingTop: '1rem'
});
var imgStyle = {
  w: {
    mobile: '1.5rem',
    md: '2.5rem'
  },
  h: {
    mobile: '1rem',
    md: '1.5rem'
  }
};
var placeholderImage = {
  bg: 'darkGrey2',
  color: 'baseWhite',
  display: 'flex',
  justifyContent: 'center',
  fontSize: {
    mobile: '0.75rem',
    md: '1rem'
  }
};
var tabList = {
  height: {
    mobile: '7rem',
    md: '5.5rem'
  }
};
var tab = {
  height: {
    mobile: '7rem',
    md: '5.5rem'
  },
  paddingTop: '0.5rem',
  alignItems: 'start',
  _selected: {
    height: {
      mobile: '7rem',
      md: '5.5rem'
    },
    bg: 'baseWhite'
  }
};
var titleStyle = {
  fontSize: '3xxl',
  fontWeight: 'medium',
  mb: 'md',
  color: 'darkGrey1',
  lineHeight: '2rem'
};
var textStyle = {
  color: 'darkGrey1',
  mb: '4rem'
};