"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.UserRoles = exports.UserAccessLevels = void 0;
var UserRoles = exports.UserRoles = function (UserRoles) {
  UserRoles["MANAGER"] = "ROLE-G-SC-CCUI-Manager";
  UserRoles["AGENT"] = "ROLE-G-SC-CCUI-Agent";
  return UserRoles;
}({});
var UserAccessLevels = exports.UserAccessLevels = function (UserAccessLevels) {
  UserAccessLevels["SUPER"] = "SUPER";
  UserAccessLevels["BOOKER"] = "BOOKER";
  UserAccessLevels["SELF"] = "SELF";
  UserAccessLevels["STAYER"] = "STAYER";
  return UserAccessLevels;
}({});