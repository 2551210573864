"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.REMOVE_ROOM = void 0;
var _taggedTemplateLiteral2 = _interopRequireDefault(require("@babel/runtime/helpers/taggedTemplateLiteral"));
var _graphqlRequest = require("graphql-request");
var _templateObject;
var REMOVE_ROOM = exports.REMOVE_ROOM = (0, _graphqlRequest.gql)(_templateObject || (_templateObject = (0, _taggedTemplateLiteral2.default)(["\n  mutation removeRoom(\n    $tempBookingRef: String!\n    $reservationId: String!\n    $channel: Channel!\n    $subchannel: String!\n    $token: String!\n    $language: String\n  ) {\n    removeRoom(\n      tempBookingRef: $tempBookingRef\n      reservationId: $reservationId\n      bookingChannel: { channel: $channel, subchannel: $subchannel, language: $language }\n      token: $token\n    ) {\n      tempBookingRef\n    }\n  }\n"])));