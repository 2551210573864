"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var React = _interopRequireWildcard(require("react"));
var _jsxRuntime = require("react/jsx-runtime");
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && {}.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
var SvgTwitterLogo24 = props => (0, _jsxRuntime.jsxs)("svg", _objectSpread(_objectSpread({
  width: 24,
  height: 24,
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg"
}, props), {}, {
  children: [(0, _jsxRuntime.jsx)("circle", {
    cx: 12,
    cy: 12,
    r: 12,
    fill: props.color || '#58595B'
  }), (0, _jsxRuntime.jsx)("path", {
    fillRule: "evenodd",
    clipRule: "evenodd",
    d: "M9.804 18.33c5.322 0 8.232-4.41 8.232-8.232 0-.126 0-.252-.006-.372a5.927 5.927 0 0 0 1.446-1.5 5.872 5.872 0 0 1-1.662.456c.6-.36 1.056-.924 1.272-1.602-.558.33-1.176.57-1.836.702a2.894 2.894 0 0 0-4.926 2.64A8.208 8.208 0 0 1 6.36 7.398c-.246.426-.39.924-.39 1.452 0 1.002.51 1.89 1.29 2.406a2.842 2.842 0 0 1-1.308-.36v.036c0 1.404.996 2.568 2.322 2.838a2.884 2.884 0 0 1-1.308.048 2.889 2.889 0 0 0 2.7 2.01 5.816 5.816 0 0 1-4.284 1.194 8.06 8.06 0 0 0 4.422 1.308Z",
    fill: "#fff"
  })]
}));
var _default = exports.default = SvgTwitterLogo24;