"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.TABLE_FIELDS = void 0;
var TABLE_FIELDS = exports.TABLE_FIELDS = function (TABLE_FIELDS) {
  TABLE_FIELDS["COMPANY_NAME"] = "name";
  TABLE_FIELDS["ADDRESS"] = "address";
  TABLE_FIELDS["TELEPHONE_NUMBER"] = "telephoneNumber";
  TABLE_FIELDS["CORP_ID"] = "corpId";
  TABLE_FIELDS["COMPANY_ID"] = "companyId";
  TABLE_FIELDS["AR_NUMBER"] = "arNumber";
  TABLE_FIELDS["RESTRICTED"] = "restricted";
  return TABLE_FIELDS;
}({});