"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = HotelRoomImagesComponent;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _react = require("@chakra-ui/react");
var _atoms = require("@whitbread-eos/atoms");
var _utils = require("@whitbread-eos/utils");
var _nextI18next = require("next-i18next");
var _image = _interopRequireDefault(require("next/image"));
var _react2 = require("react");
var _jsxRuntime = require("react/jsx-runtime");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function HotelRoomImagesComponent(_ref) {
  var _images, _images$imageSrc, _images2, _images$alt, _images3;
  var {
    images,
    thumbnailHeight,
    isLessThanSm,
    isLessThanLg
  } = _ref;
  var {
    t
  } = (0, _nextI18next.useTranslation)(['common']);
  var [isModalVisible, setIsModalVisible] = (0, _react2.useState)(false);
  var [activeThumbnail, setActiveThumbnail] = (0, _react2.useState)(0);
  var [activeSlide, setActiveSlide] = (0, _react2.useState)(0);
  var thumbnailStyles = {
    position: 'relative',
    h: thumbnailHeight,
    w: '100%'
  };
  return (0, _jsxRuntime.jsxs)(_react.Box, {
    "data-testid": "room-images",
    children: [(0, _jsxRuntime.jsxs)(_react.Box, _objectSpread(_objectSpread({}, thumbnailStyles), {}, {
      children: [(0, _utils.isStringValid)(images === null || images === void 0 || (_images = images[(images === null || images === void 0 ? void 0 : images.length) - 1]) === null || _images === void 0 ? void 0 : _images.imageSrc) && (0, _jsxRuntime.jsx)(_image.default, {
        src: (0, _utils.formatAssetsUrl)((_images$imageSrc = images === null || images === void 0 || (_images2 = images[(images === null || images === void 0 ? void 0 : images.length) - 1]) === null || _images2 === void 0 ? void 0 : _images2.imageSrc) !== null && _images$imageSrc !== void 0 ? _images$imageSrc : ''),
        alt: (_images$alt = images === null || images === void 0 || (_images3 = images[images.length - 1]) === null || _images3 === void 0 ? void 0 : _images3.alt) !== null && _images$alt !== void 0 ? _images$alt : '',
        fill: true,
        style: {
          objectFit: 'cover',
          objectPosition: 'center'
        },
        priority: false,
        loader: (0, _utils.isIVMEnabled)() ? _utils.akamaiImageLoader : undefined,
        sizes: "640px"
      }), images && (images === null || images === void 0 ? void 0 : images.length) > 1 && renderCTAButton()]
    })), renderModal()]
  });
  function renderCTAButton() {
    var CTAButtonStyles = {
      position: 'absolute',
      bottom: 'sm',
      right: 'sm',
      width: 'auto',
      height: 'var(--chakra-space-xl)',
      fontSize: 'sm'
    };
    var CTAIconStyles = {
      position: 'absolute',
      top: 'sm',
      right: 'sm',
      width: 'var(--chakra-space-xl)',
      height: 'var(--chakra-space-xl)',
      cursor: 'pointer'
    };
    if (isLessThanLg) {
      return (0, _jsxRuntime.jsx)(_react.Box, _objectSpread(_objectSpread({}, CTAIconStyles), {}, {
        "data-testid": "room-images__expand",
        onClick: () => {
          setActiveThumbnail(0);
          setIsModalVisible(true);
          setActiveSlide(0);
        },
        children: (0, _jsxRuntime.jsx)(_atoms.Expand, {})
      }));
    } else {
      return (0, _jsxRuntime.jsx)(_atoms.Button, _objectSpread(_objectSpread({
        variant: "tertiary",
        size: "sm",
        "data-testid": "hdp_roomsViewGallery"
      }, CTAButtonStyles), {}, {
        onClick: () => {
          setActiveThumbnail(0);
          setIsModalVisible(true);
          setActiveSlide(0);
        },
        children: t('hoteldetails.viewgallery')
      }));
    }
  }
  function renderModal() {
    var mobileGalleryThumbnails = images === null || images === void 0 ? void 0 : images.map(item => {
      var _item$thumbnailSrc, _item$alt;
      return (0, _jsxRuntime.jsx)(_react.Image, {
        src: (0, _utils.formatAssetsUrl)((_item$thumbnailSrc = item === null || item === void 0 ? void 0 : item.thumbnailSrc) !== null && _item$thumbnailSrc !== void 0 ? _item$thumbnailSrc : ''),
        alt: (_item$alt = item === null || item === void 0 ? void 0 : item.alt) !== null && _item$alt !== void 0 ? _item$alt : ''
      }, item === null || item === void 0 ? void 0 : item.alt);
    });
    var sizes = isLessThanLg ? undefined : '640px';
    var imageCarousel = renderImages(sizes);
    return (0, _jsxRuntime.jsx)(_atoms.ModalVariants, {
      isOpen: isModalVisible,
      onClose: () => {
        setIsModalVisible(false);
        setActiveThumbnail(0);
        setActiveSlide(0);
      },
      variant: "gallery",
      variantProps: {
        title: "".concat(activeSlide + 1, "/").concat(imageCarousel === null || imageCarousel === void 0 ? void 0 : imageCarousel.length)
      },
      children: isLessThanLg ? (0, _jsxRuntime.jsx)(_atoms.MobileCarousel, {
        activeSlide: activeSlide,
        setActiveSlide: setActiveSlide,
        thumbnails: mobileGalleryThumbnails,
        children: imageCarousel
      }, activeThumbnail) : (0, _jsxRuntime.jsx)(_react.Box, {
        w: "41.875rem",
        px: "4xl",
        children: (0, _jsxRuntime.jsx)(_atoms.Carousel, {
          activeSlide: activeSlide,
          setActiveSlide: setActiveSlide,
          children: imageCarousel
        }, activeThumbnail)
      })
    });
  }
  function renderImages(sizes) {
    var iconStyles = isLessThanSm ? {
      position: 'absolute',
      left: 'sm',
      bottom: '6xl',
      bgColor: 'baseWhite',
      borderRadius: '4px',
      p: '6px 6px 6px 8px'
    } : {
      mr: 'sm'
    };
    var iconFlexStyles = {
      minH: '4rem',
      alignItems: 'center',
      justify: 'center',
      position: 'relative'
    };
    return images === null || images === void 0 ? void 0 : images.map(item => {
      var _item$imageSrc;
      return (0, _jsxRuntime.jsxs)(_react.Box, {
        children: [(0, _jsxRuntime.jsx)(_react.Box, {
          position: "relative",
          w: "full",
          h: {
            sm: '25.313rem',
            xs: '18.938rem',
            mobile: '11.25rem',
            base: '11.25rem'
          },
          children: (0, _jsxRuntime.jsx)(_image.default, {
            src: (0, _utils.formatAssetsUrl)((_item$imageSrc = item === null || item === void 0 ? void 0 : item.imageSrc) !== null && _item$imageSrc !== void 0 ? _item$imageSrc : ''),
            alt: "Hotel Image",
            fill: true,
            sizes: sizes,
            style: {
              objectFit: 'cover'
            },
            loader: (0, _utils.isIVMEnabled)() ? _utils.akamaiImageLoader : undefined
          })
        }), (0, _jsxRuntime.jsxs)(_react.Flex, _objectSpread(_objectSpread({}, iconFlexStyles), {}, {
          "data-testid": "carousel_room-image-caption",
          children: [(item === null || item === void 0 ? void 0 : item.iconSrc) && (0, _jsxRuntime.jsx)(_atoms.Icon, _objectSpread({
            src: (0, _utils.formatAssetsUrl)(item.iconSrc)
          }, iconStyles)), (0, _jsxRuntime.jsx)(_react.Text, {
            children: item === null || item === void 0 ? void 0 : item.caption
          })]
        }))]
      }, item === null || item === void 0 ? void 0 : item.alt);
    });
  }
}