"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = FormRadioGroup;
var _objectWithoutProperties2 = _interopRequireDefault(require("@babel/runtime/helpers/objectWithoutProperties"));
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _react = require("@chakra-ui/react");
var _reactHookForm = require("react-hook-form");
var _Radio = _interopRequireDefault(require("../../Radio"));
var _FormError = _interopRequireDefault(require("../FormError"));
var _formConstants = require("../formConstants");
var _jsxRuntime = require("react/jsx-runtime");
var _excluded = ["onChange", "ref"];
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function FormRadioGroup(_ref) {
  var _formField$props, _formField$props2;
  var {
    control,
    formField,
    errors,
    handleSetValue,
    onChangeAction
  } = _ref;
  return (0, _jsxRuntime.jsxs)(_react.Flex, _objectSpread(_objectSpread({
    direction: "column"
  }, _objectSpread(_objectSpread({}, defaultStyles), formField.styles)), {}, {
    "data-testid": formField.testid,
    "data-fieldname": formField.name,
    children: [(formField === null || formField === void 0 || (_formField$props = formField.props) === null || _formField$props === void 0 ? void 0 : _formField$props.errorLocation) === _formConstants.FORM_VALIDATIONS.FORM_FIELD_ERROR_LOCATION.ON_TOP && (0, _jsxRuntime.jsx)(_FormError.default, {
      errors: errors,
      name: formField.name,
      extraStyles: {
        containerStyle: {
          marginBottom: 'md'
        },
        textStyle: {
          marginTop: 0,
          marginLeft: 0
        }
      }
    }), (0, _jsxRuntime.jsx)(_reactHookForm.Controller, {
      name: formField.name,
      control: control,
      render: _ref2 => {
        var _formField$options;
        var {
          field
        } = _ref2;
        var {
            onChange: _onChange,
            ref: _
          } = field,
          extraPropsField = (0, _objectWithoutProperties2.default)(field, _excluded);
        return (0, _jsxRuntime.jsx)(_react.RadioGroup, _objectSpread(_objectSpread({
          onChange: value => {
            var _formField$onChange;
            (_formField$onChange = formField.onChange) === null || _formField$onChange === void 0 || _formField$onChange.call(formField, value);
            _onChange(value);
            onChangeAction === null || onChangeAction === void 0 || onChangeAction(value, handleSetValue);
          }
        }, extraPropsField), {}, {
          children: (0, _jsxRuntime.jsx)(_react.Stack, {
            spacing: "0",
            direction: "column",
            style: {
              borderBottomLeftRadius: 0,
              borderBottomRightRadius: 0
            },
            children: (_formField$options = formField.options) === null || _formField$options === void 0 ? void 0 : _formField$options.map((option, listIndex) => {
              var _formField$options2;
              var isLastInList = listIndex === Number((_formField$options2 = formField.options) === null || _formField$options2 === void 0 ? void 0 : _formField$options2.length) - 1;
              var isChecked = field.value === option.value;
              return (0, _jsxRuntime.jsx)(_Radio.default, {
                name: option.value,
                listIndex: isLastInList ? 'last' : 0,
                value: option.value,
                isChecked: isChecked,
                "data-testid": option.testid,
                children: option.Component ? (0, _jsxRuntime.jsx)(option.Component, {
                  isChecked: isChecked
                }) : (0, _jsxRuntime.jsx)(_react.Text, {
                  fontWeight: isChecked ? 'semibold' : 'normal',
                  children: option.label
                })
              }, "".concat(formField.name, "-").concat(listIndex));
            })
          })
        }));
      }
    }), !(formField !== null && formField !== void 0 && (_formField$props2 = formField.props) !== null && _formField$props2 !== void 0 && _formField$props2.errorLocation.length) && (0, _jsxRuntime.jsx)(_FormError.default, {
      errors: errors,
      name: formField.name,
      extraStyles: formField.errorStyles
    })]
  }));
}
var defaultStyles = {
  marginBottom: 'var(--chakra-space-md)'
};