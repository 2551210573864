"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = BBGuestDetailsForm;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _react = require("@chakra-ui/react");
var _utils = require("@whitbread-eos/utils");
var _nextI18next = require("next-i18next");
var _BBGuestDetailsRoom = _interopRequireDefault(require("./BBGuestDetailsRoom"));
var _jsxRuntime = require("react/jsx-runtime");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function BBGuestDetailsForm(_ref) {
  var {
    control,
    formField,
    errors,
    reset
  } = _ref;
  var {
    t
  } = (0, _nextI18next.useTranslation)();
  var {
    numberOfRooms = 1,
    labels,
    queryClient,
    guestList,
    setGuestUser,
    isDynamicSearchVisible,
    isAmendPage,
    bbEmployeeList,
    defaultGuest,
    onEditBbInput
  } = formField.props;
  var {
    testid,
    dropdownOptions
  } = formField;
  function renderContent() {
    return [...Array(numberOfRooms)].map((value, index) => {
      return (0, _jsxRuntime.jsx)(_BBGuestDetailsRoom.default, {
        t: t,
        roomNumber: index,
        numberOfRooms: numberOfRooms,
        labels: labels,
        testid: testid,
        control: control,
        errors: errors,
        dropdownOptions: dropdownOptions,
        formField: formField,
        queryClient: queryClient,
        guestList: guestList,
        setGuestUser: setGuestUser,
        index: index,
        reset: reset,
        isDynamicSearchVisible: isDynamicSearchVisible,
        isAmendPage: isAmendPage,
        bbEmployeeList: bbEmployeeList,
        defaultGuest: defaultGuest,
        onEditBbInput: onEditBbInput
      }, testid);
    });
  }
  return (0, _jsxRuntime.jsx)(_react.Flex, _objectSpread(_objectSpread({
    direction: "column"
  }, _objectSpread({}, formField.styles)), {}, {
    "data-testid": (0, _utils.formatDataTestId)(testid, 'Container'),
    children: renderContent()
  }));
}