"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = HotelDistance;
exports.getLabel = getLabel;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _react = require("@chakra-ui/react");
var _jsxRuntime = require("react/jsx-runtime");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function HotelDistance(_ref) {
  var {
    distance,
    unit,
    labels,
    testId,
    styles
  } = _ref;
  return (0, _jsxRuntime.jsx)(_react.Text, _objectSpread(_objectSpread({}, styles), {}, {
    "data-testid": testId,
    children: getLabel(distance, unit, labels)
  }));
}
function getLabel(distance, unit, labels) {
  var _labels$distanceUnitP;
  if (distance === 1 || unit === 'km') {
    return "".concat(distance, " ").concat(unit, " ").concat(labels.fromLocation);
  }
  return "".concat(distance, " ").concat((_labels$distanceUnitP = labels.distanceUnitPlural) === null || _labels$distanceUnitP === void 0 ? void 0 : _labels$distanceUnitP.toLowerCase(), " ").concat(labels.fromLocation);
}