"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = HeaderVariantStep;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _react = require("@chakra-ui/react");
var _atoms = require("@whitbread-eos/atoms");
var _utils = require("@whitbread-eos/utils");
var _react2 = require("react");
var _BookingFlowSteps = _interopRequireDefault(require("../../BookingFlowSteps"));
var _HeaderLeisure = require("../HeaderLeisure.style");
var _jsxRuntime = require("react/jsx-runtime");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function HeaderVariantStep(_ref) {
  var _headerInfoData$conte, _headerInfoData$conte2;
  var {
    currentLang = 'en',
    currentCountry = 'gb',
    headerInfoData,
    propsStepProgress,
    hotelBrand,
    bb
  } = _ref;
  var isWindowDefined = typeof window !== 'undefined';
  var [origin, setOrigin] = (0, _react2.useState)('');
  (0, _react2.useEffect)(() => {
    setOrigin(window.location.origin);
  }, [isWindowDefined]);
  var getHomePath = isBB => {
    return !isBB ? "".concat(origin, "/").concat(currentCountry, "/").concat(currentLang, "/home.html") : "".concat(origin, "/").concat(currentCountry, "/").concat(currentLang, "/business-booker/home.html");
  };
  return (0, _jsxRuntime.jsx)(_react.Box, _objectSpread(_objectSpread({}, customHeaderWrapperStyles), {}, {
    "data-testid": "common-header-wrapper",
    children: (0, _jsxRuntime.jsx)(_react.Container, _objectSpread(_objectSpread({}, customContainerWrapperStyles), {}, {
      children: (0, _jsxRuntime.jsxs)(_react.Grid, _objectSpread(_objectSpread({}, _HeaderLeisure.contentStyles), {}, {
        children: [(0, _jsxRuntime.jsx)(_react.Flex, _objectSpread(_objectSpread({}, customContainerLogoStyle), {}, {
          "data-testid": "logo-container",
          sx: {
            '@media print': {
              display: 'none'
            }
          },
          children: (0, _jsxRuntime.jsx)(_atoms.Logo, {
            href: getHomePath(bb),
            src: (0, _utils.formatAssetsUrl)(headerInfoData === null || headerInfoData === void 0 || (_headerInfoData$conte = headerInfoData.content) === null || _headerInfoData$conte === void 0 || (_headerInfoData$conte = _headerInfoData$conte.header) === null || _headerInfoData$conte === void 0 ? void 0 : _headerInfoData$conte.image),
            isHeaderLogo: true,
            variant: currentLang === 'de' && hotelBrand !== 'hub' && hotelBrand !== 'zip' ? 'pi-simple' : hotelBrand,
            transform: "scale(1)"
          })
        })), (0, _jsxRuntime.jsx)(_react.Container, _objectSpread(_objectSpread({}, containerLogoBookingFlow), {}, {
          "data-testid": "logo-container",
          sx: {
            '@media print': {
              mb: 'sm'
            }
          },
          children: (0, _jsxRuntime.jsx)(_atoms.Logo, {
            src: (0, _utils.formatAssetsUrl)(headerInfoData === null || headerInfoData === void 0 || (_headerInfoData$conte2 = headerInfoData.content) === null || _headerInfoData$conte2 === void 0 || (_headerInfoData$conte2 = _headerInfoData$conte2.header) === null || _headerInfoData$conte2 === void 0 ? void 0 : _headerInfoData$conte2.image),
            href: getHomePath(bb),
            variant: hotelBrand === 'pi' ? 'pi-icon' : "".concat(hotelBrand, "-simple")
          })
        })), (0, _jsxRuntime.jsx)(_BookingFlowSteps.default, {
          steps: propsStepProgress.steps,
          activeStep: propsStepProgress.activeStep
        })]
      }))
    }))
  }));
}
var customContainerWrapperStyles = _objectSpread(_objectSpread({}, _HeaderLeisure.containerWrapperStyles), {}, {
  pl: {
    mobile: 'md',
    md: 'lg',
    lg: 'xl',
    xl: '5xl'
  },
  pr: {
    mobile: 'md',
    md: 'lg',
    lg: 'xl',
    xl: '5xl'
  }
});
var customContainerLogoStyle = _objectSpread(_objectSpread({}, _HeaderLeisure.containerLogoStyle), {}, {
  display: {
    mobile: 'none',
    lg: 'flex'
  }
});
var customHeaderWrapperStyles = _objectSpread(_objectSpread({}, _HeaderLeisure.headerWrapperStyles), {}, {
  h: {
    mobile: 'var(--chakra-space-5xl)',
    sm: 'var(--chakra-space-7xl)',
    md: 'var(--chakra-space-8xl)'
  }
});
var containerLogoBookingFlow = _objectSpread(_objectSpread({}, _HeaderLeisure.containerLogoStyle), {}, {
  display: {
    mobile: 'flex',
    lg: 'none'
  },
  justifyContent: 'flex-start',
  p: 0,
  w: 'auto'
});