"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "AgentOverrideButton", {
  enumerable: true,
  get: function get() {
    return _dashboard.AgentOverrideButton;
  }
});
Object.defineProperty(exports, "BookingDetailsReservationInformation", {
  enumerable: true,
  get: function get() {
    return _dashboard.BookingDetailsReservationInformation;
  }
});
Object.defineProperty(exports, "BookingDetailsRoomInformation", {
  enumerable: true,
  get: function get() {
    return _dashboard.BookingDetailsRoomInformation;
  }
});
Object.defineProperty(exports, "BookingDetailsTotalCost", {
  enumerable: true,
  get: function get() {
    return _dashboard.BookingDetailsTotalCost;
  }
});
Object.defineProperty(exports, "BookingHistoryFilter", {
  enumerable: true,
  get: function get() {
    return _dashboard.BookingHistoryFilter;
  }
});
Object.defineProperty(exports, "HotelParking", {
  enumerable: true,
  get: function get() {
    return _dashboard.HotelParking;
  }
});
Object.defineProperty(exports, "HotelThumbnail", {
  enumerable: true,
  get: function get() {
    return _dashboard.HotelThumbnail;
  }
});
var _dashboard = require("./dashboard");