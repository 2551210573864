"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = BookingSummaryUpgradeToFlex;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _react = require("@chakra-ui/react");
var _atoms = require("@whitbread-eos/atoms");
var _utils = require("@whitbread-eos/utils");
var _jsxRuntime = require("react/jsx-runtime");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function BookingSummaryUpgradeToFlex(_ref) {
  var {
    currency,
    upgradeToFlexCallBack,
    amount,
    t,
    prefixDataTestId
  } = _ref;
  var baseDataTestId = (0, _utils.formatDataTestId)(prefixDataTestId, 'UpgradeToFlex');
  return (0, _jsxRuntime.jsxs)(_react.Flex, {
    flexDirection: "column",
    "data-testid": (0, _utils.formatDataTestId)(baseDataTestId, 'Wrapper'),
    children: [(0, _jsxRuntime.jsx)(_atoms.Button, _objectSpread(_objectSpread({
      variant: "secondary",
      size: "sm"
    }, buttonStyle), {}, {
      onClick: upgradeToFlexCallBack,
      "data-testid": (0, _utils.formatDataTestId)(baseDataTestId, 'Button'),
      children: (0, _jsxRuntime.jsx)(_react.Text, {
        children: t('booking.hotel.summary.upgradeToPremierFlexible')
      })
    })), (0, _jsxRuntime.jsx)(_react.Box, _objectSpread(_objectSpread({}, cancelBookingLabelStyle), {}, {
      children: (0, _jsxRuntime.jsxs)(_react.Text, {
        children: ["".concat(t('booking.hotel.summary.upgradeToPremierFlexibleFirst'), " "), (0, _jsxRuntime.jsxs)(_react.Text, {
          as: "span",
          fontWeight: "semibold",
          "data-testid": (0, _utils.formatDataTestId)(baseDataTestId, 'CostForCancel-Message'),
          children: [(0, _utils.formatCurrency)(currency), "".concat(amount.toFixed(2))]
        }), (0, _jsxRuntime.jsx)(_react.Text, {
          as: "span",
          fontWeight: "semibold",
          children: " ".concat(t('booking.hotel.summary.upgradeToPremierFlexibleSecond'))
        })]
      })
    }))]
  });
}
var buttonStyle = {
  width: {
    mobile: '100%',
    sm: '64',
    xl: '100%'
  },
  mt: 'md'
};
var cancelBookingLabelStyle = {
  width: {
    mobile: '64',
    lg: '100%'
  },
  mt: 'md',
  lineHeight: 2,
  color: 'darkGrey1',
  fontWeight: 'normal',
  fontSize: 'sm'
};