"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.FeatureToggleContextProvider = exports.FeatureToggleContext = void 0;
exports.useFeatureToggleData = useFeatureToggleData;
var _react = _interopRequireWildcard(require("react"));
var _noop = _interopRequireDefault(require("../utils/noop"));
var _jsxRuntime = require("react/jsx-runtime");
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && {}.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
var FeatureToggleContext = exports.FeatureToggleContext = (0, _react.createContext)({
  featureToggles: {},
  setFeatureToggles: _noop.default
});
function useFeatureToggleData() {
  return (0, _react.useContext)(FeatureToggleContext);
}
var FeatureToggleContextProvider = _ref => {
  var {
    children
  } = _ref;
  var [featureToggles, setFeatureToggles] = (0, _react.useState)({});
  (0, _react.useEffect)(() => {
    sessionStorage.setItem('featureToggles', JSON.stringify(featureToggles));
  }, [featureToggles]);
  return (0, _jsxRuntime.jsx)(FeatureToggleContext.Provider, {
    value: {
      featureToggles,
      setFeatureToggles
    },
    children: children
  });
};
exports.FeatureToggleContextProvider = FeatureToggleContextProvider;