"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = SearchComponent;
var _molecules = require("@whitbread-eos/molecules");
var _react = require("react");
var _jsxRuntime = require("react/jsx-runtime");
function SearchComponent(_ref) {
  var _AEMTranslations$numb, _AEMTranslations$prom;
  var {
    locale,
    onLocationInputChange,
    onInputLocationFocus,
    onSetErrorMessage,
    showErrorMessage,
    noOfNightsError,
    suggestions,
    defaultLocation,
    defaultRooms,
    ARRdd,
    ARRmm,
    ARRyyyy,
    ROOMS,
    NIGHTS,
    AEMTranslations,
    screenSize,
    dataStayRules,
    dataRoomOccupancyLimitations,
    partialTranslations,
    roomCodes,
    handleButtonClick,
    searchStyles,
    isSearchActive,
    onIsSearchActive,
    mappedRoomLabels,
    startDate,
    endDate,
    handleChangeNumberOfNights,
    onSelectDates,
    errorField,
    errorMessage,
    savedNights,
    isDatepickerError,
    onLocationInputClear,
    onOccupancyChange,
    channel,
    isDatePickerFocus,
    hideErrorForMinNights,
    displayDatesNotification,
    contractRateCompanyState,
    isNegotiatedRatesByCompIdEnabled = false
  } = _ref;
  var {
    isLessThanSm
  } = screenSize;
  var [searchDisabled, setSearchDisabled] = (0, _react.useState)(false);
  var companyNameField = (0, _jsxRuntime.jsx)(_molecules.CompanySearch, {
    showCompanyIdInput: isNegotiatedRatesByCompIdEnabled,
    setSearchDisabled: setSearchDisabled,
    contractRateCompanyState: contractRateCompanyState
  }, "CompanyName");
  return (0, _jsxRuntime.jsx)(_molecules.Search, {
    locale,
    defaultLocation,
    defaultRooms,
    ARRdd,
    ARRmm,
    ARRyyyy,
    ROOMS,
    NIGHTS,
    onLocationInputChange,
    onLocationInputClear,
    onInputLocationFocus,
    onSetErrorMessage,
    showErrorMessage,
    suggestions,
    dataStayRules,
    nrOfNights: savedNights,
    dataRoomOccupancyLimitations,
    partialTranslations,
    roomCodes,
    handleButtonClick,
    AEMTranslations,
    screenSize,
    searchStyles,
    isSearchActive,
    onIsSearchActive,
    mappedRoomLabels,
    startDate,
    endDate,
    onSelectDates,
    errorField,
    errorMessage,
    isDatepickerError,
    onOccupancyChange,
    channel,
    isDatePickerFocus,
    displayDatesNotification,
    searchDisabled,
    numberOfNightsComponent: (0, _jsxRuntime.jsx)(_molecules.NumberOfNights, {
      inputPlaceholder: (_AEMTranslations$numb = AEMTranslations.numberOfNightsPlaceholder) !== null && _AEMTranslations$numb !== void 0 ? _AEMTranslations$numb : '',
      inputValue: savedNights,
      handleOnChange: handleChangeNumberOfNights,
      noOfNightsError: noOfNightsError,
      isLessThanSm: isLessThanSm,
      maxNights: dataStayRules.maxNightsLimitation.maxNights,
      hideErrorForMinNights: hideErrorForMinNights
    }, "NumberOfNights"),
    companyNameComponent: companyNameField,
    promotionComponent: (0, _jsxRuntime.jsx)(_molecules.Promotion, {
      inputPlaceholder: (_AEMTranslations$prom = AEMTranslations.promotionCategoryPlaceholder) !== null && _AEMTranslations$prom !== void 0 ? _AEMTranslations$prom : ''
    }, "Promotion")
  });
}