"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.SEARCH_ROOM_PICKER = exports.ONE_MINUTE = exports.MARKETING_OPT_IN = exports.GDP_DIGI_REG_ADDITIONAL_INFO = exports.GDP_ACCOMPANYING_GUEST_DETAILS = exports.BILLING_ADDRESS_CAPTURE_VARIANT = exports.BILLING_ADDRESS_CAPTURE = exports.ANCILLARIES_TABS = void 0;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
var MARKETING_OPT_IN = exports.MARKETING_OPT_IN = 'marketingOptIn';
var BILLING_ADDRESS_CAPTURE = exports.BILLING_ADDRESS_CAPTURE = 'billing_address_capture';
var BILLING_ADDRESS_CAPTURE_VARIANT = exports.BILLING_ADDRESS_CAPTURE_VARIANT = 'variant';
var SEARCH_ROOM_PICKER = exports.SEARCH_ROOM_PICKER = 'search_room_picker';
var ONE_MINUTE = exports.ONE_MINUTE = 1;
var COMMON_COOKIE_CONFIGS = {
  mode: 'variant',
  expiryInMinutes: 30
};
var ANCILLARIES_TABS = exports.ANCILLARIES_TABS = _objectSpread(_objectSpread({}, COMMON_COOKIE_CONFIGS), {}, {
  cookieName: 'ancillaries_tabs',
  configName: 'ancillaries'
});
var GDP_ACCOMPANYING_GUEST_DETAILS = exports.GDP_ACCOMPANYING_GUEST_DETAILS = _objectSpread(_objectSpread({}, COMMON_COOKIE_CONFIGS), {}, {
  cookieName: 'guestDetailsAccompanyingGuest',
  configName: 'guestDetailsAccompanyingGuest'
});
var GDP_DIGI_REG_ADDITIONAL_INFO = exports.GDP_DIGI_REG_ADDITIONAL_INFO = _objectSpread(_objectSpread({}, COMMON_COOKIE_CONFIGS), {}, {
  cookieName: 'guestDetailsAdditionalInfo',
  configName: 'guestDetailsAdditionalInfo'
});