"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.HOTELS_BY_LOCATION = void 0;
var _taggedTemplateLiteral2 = _interopRequireDefault(require("@babel/runtime/helpers/taggedTemplateLiteral"));
var _graphqlRequest = require("graphql-request");
var _templateObject;
var HOTELS_BY_LOCATION = exports.HOTELS_BY_LOCATION = (0, _graphqlRequest.gql)(_templateObject || (_templateObject = (0, _taggedTemplateLiteral2.default)(["\n  query getHotelsByLocation(\n    $location: String!\n    $locationFormat: String!\n    $radius: String!\n    $radiusUnit: String!\n  ) {\n    hotelsLocations(\n      hotelsSearchCriteria: {\n        location: $location\n        locationFormat: $locationFormat\n        radius: $radius\n        radiusUnit: $radiusUnit\n      }\n    ) {\n      brand\n      distance\n      hotelId\n      name\n    }\n  }\n"])));