"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _react = require("@chakra-ui/react");
var _atoms = require("@whitbread-eos/atoms");
var _nextI18next = require("next-i18next");
var _common = require("./common");
var _jsxRuntime = require("react/jsx-runtime");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function RemoveDependentConfirmationModal(_ref) {
  var {
    isOpen,
    onClose,
    handleDependentDelete
  } = _ref;
  var {
    t
  } = (0, _nextI18next.useTranslation)();
  return (0, _jsxRuntime.jsx)(_atoms.ModalVariants, {
    isOpen: isOpen,
    onClose: onClose,
    variant: "default",
    variantProps: {
      title: '',
      delimiter: false
    },
    headerStyles: {
      textAlign: 'center',
      justifyContent: 'flex-end'
    },
    updatedWidth: {
      sm: '100%',
      md: '37.5rem',
      lg: '37.5rem',
      xl: '39.93rem'
    },
    children: (0, _jsxRuntime.jsxs)(_react.Flex, _objectSpread(_objectSpread({}, _common.modalContentStyles), {}, {
      children: [(0, _jsxRuntime.jsx)(_react.Heading, _objectSpread(_objectSpread({
        as: "h2"
      }, _common.headingStyles), {}, {
        children: t('precheckin.dependants.warningpopup')
      })), (0, _jsxRuntime.jsx)(_react.Heading, _objectSpread(_objectSpread({
        as: "h5"
      }, _common.subHeadingStyles), {}, {
        children: t('precheckin.dependants.warningpopup.desc')
      })), (0, _jsxRuntime.jsxs)(_react.Flex, _objectSpread(_objectSpread({}, _common.modalButtonsContainerStyles), {}, {
        marginTop: 'lg',
        gap: "lg",
        children: [(0, _jsxRuntime.jsx)(_react.Button, _objectSpread(_objectSpread({
          "data-testid": "PreCheckIn-ModalDenyButton",
          size: "xsm",
          variant: "tertiary",
          onClick: onClose
        }, _common.modalButtonsStyles), {}, {
          children: t('precheckin.details.cancelbtn')
        })), (0, _jsxRuntime.jsx)(_react.Button, _objectSpread(_objectSpread({
          "data-testid": "PreCheckIn-ModalConfirmationButton",
          size: "xsm",
          onClick: handleDependentDelete
        }, _common.modalButtonsStyles), {}, {
          children: t('precheckin.button.yes')
        }))]
      }))]
    }))
  });
}
var _default = exports.default = RemoveDependentConfirmationModal;