"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.LeadGuestAdditionalFields = void 0;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _react = require("@chakra-ui/react");
var _api = require("@whitbread-eos/api");
var _utils = require("@whitbread-eos/utils");
var _react2 = require("react");
var _reactHookForm = require("react-hook-form");
var _field = _interopRequireDefault(require("./field"));
var _jsxRuntime = require("react/jsx-runtime");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
var LeadGuestAdditionalFields = _ref => {
  var {
    control,
    formField,
    errMsg,
    styles,
    name,
    label,
    type,
    testId,
    isDisabled,
    handleSetValue,
    fieldToClear
  } = _ref;
  var {
    language,
    country
  } = (0, _utils.useCustomLocale)();
  var [nationalities, setNationalities] = (0, _react2.useState)([]);
  var {
    data: countriesData,
    isSuccess: countriesRequestSuccess
  } = (0, _utils.useQueryRequest)(['GetCountries', country, language, _api.SITE_LEISURE], _api.GET_COUNTRIES, {
    country,
    language,
    site: _api.SITE_LEISURE
  });
  (0, _react2.useEffect)(() => {
    if (countriesRequestSuccess && !(nationalities !== null && nationalities !== void 0 && nationalities.length)) {
      var _countriesData$countr;
      var sortedCountries = (0, _utils.getSortedCountriesByCurrentLang)(countriesData === null || countriesData === void 0 || (_countriesData$countr = countriesData.countries) === null || _countriesData$countr === void 0 ? void 0 : _countriesData$countr.countries, language);
      setNationalities((sortedCountries === null || sortedCountries === void 0 ? void 0 : sortedCountries.map(_ref2 => {
        var {
          nationality,
          countryCode,
          flagSrc
        } = _ref2;
        return {
          value: countryCode,
          label: nationality !== null && nationality !== void 0 ? nationality : '',
          image: flagSrc
        };
      })) || []);
    }
  }, [countriesRequestSuccess, countriesData]);
  return (0, _react2.createElement)(_react.Box, _objectSpread(_objectSpread({}, styles), {}, {
    key: name,
    "data-testid": (0, _utils.formatDataTestId)(formField.testid, testId)
  }), (0, _jsxRuntime.jsx)(_reactHookForm.Controller, {
    name: name,
    control: control,
    render: _ref3 => {
      var {
        field
      } = _ref3;
      return (0, _jsxRuntime.jsx)(_field.default, {
        field: field,
        type: type,
        label: label,
        name: name,
        isDisabled: isDisabled,
        formField: formField,
        nationalities: nationalities,
        errMsg: errMsg,
        handleSetValue: handleSetValue,
        fieldToClear: fieldToClear
      });
    }
  }));
};
exports.LeadGuestAdditionalFields = LeadGuestAdditionalFields;
var _default = exports.default = LeadGuestAdditionalFields;