"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.BASKET_CONFIRMATION_MUTATION = void 0;
var _taggedTemplateLiteral2 = _interopRequireDefault(require("@babel/runtime/helpers/taggedTemplateLiteral"));
var _graphqlRequest = require("graphql-request");
var _templateObject;
var BASKET_CONFIRMATION_MUTATION = exports.BASKET_CONFIRMATION_MUTATION = (0, _graphqlRequest.gql)(_templateObject || (_templateObject = (0, _taggedTemplateLiteral2.default)(["\n  mutation basketConfirmationMutation($basketReference: String!) {\n    basketConfirmation(basketReference: $basketReference) {\n      reference\n    }\n  }\n"])));