"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
var _searchBookings = require("./searchBookings");
Object.keys(_searchBookings).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _searchBookings[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _searchBookings[key];
    }
  });
});
var _bartBookingInformation = require("./bartBookingInformation");
Object.keys(_bartBookingInformation).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _bartBookingInformation[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _bartBookingInformation[key];
    }
  });
});
var _hotelsLocations = require("./hotelsLocations");
Object.keys(_hotelsLocations).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _hotelsLocations[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _hotelsLocations[key];
    }
  });
});