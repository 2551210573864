"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "LeadGuestDetails", {
  enumerable: true,
  get: function get() {
    return _LeadGuestDetails.LeadGuestDetails;
  }
});
Object.defineProperty(exports, "LeadGuestDetailsForm", {
  enumerable: true,
  get: function get() {
    return _LeadGuestDetails.LeadGuestDetailsForm;
  }
});
var _LeadGuestDetails = require("./LeadGuestDetails.component");