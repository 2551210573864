"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = TableListRow;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _react = require("@chakra-ui/react");
var _utils = require("@whitbread-eos/utils");
var _icons = require("../../assets/icons");
var _TableList = require("./TableList.style");
var _jsxRuntime = require("react/jsx-runtime");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function TableListRow(_ref) {
  var {
    dataTestIdPrefix = '',
    columns,
    row,
    rowIndex,
    isExpandable,
    expandBtnText,
    collapseBtnText,
    screenSize,
    isExpanded,
    onExpand,
    renderExpandedContent,
    rowStyles,
    rowHoverStyles,
    handleRowClicked
  } = _ref;
  var colSpan = screenSize !== null && screenSize !== void 0 && screenSize.isLessThanSm ? columns.length - columns.filter(col => !!(col !== null && col !== void 0 && col.hideOnMobile)).length + 1 : columns.length + 1;
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsxs)(_react.Tr, {
      "data-testid": (0, _utils.formatDataTestId)(dataTestIdPrefix, "Table-Row"),
      onClick: () => handleRowClicked && handleRowClicked(row),
      _hover: rowHoverStyles !== null && rowHoverStyles !== void 0 ? rowHoverStyles : {},
      children: [columns.map((column, colIndex) => {
        var _row$column$key;
        return screenSize !== null && screenSize !== void 0 && screenSize.isLessThanSm && column !== null && column !== void 0 && column.hideOnMobile ? null : (0, _jsxRuntime.jsx)(_react.Td, _objectSpread(_objectSpread({
          "data-testid": (0, _utils.formatDataTestId)(dataTestIdPrefix, "Table-Cell-".concat(rowIndex, "-").concat(colIndex))
        }, _objectSpread(_objectSpread({}, (0, _TableList.resultRowCellStyle)(isExpanded)), rowStyles)), {}, {
          children: column !== null && column !== void 0 && column.render ? column.render(row, rowIndex) : (_row$column$key = row[column === null || column === void 0 ? void 0 : column.key]) !== null && _row$column$key !== void 0 ? _row$column$key : ''
        }), column.key);
      }), isExpandable && (0, _jsxRuntime.jsx)(_react.Td, _objectSpread(_objectSpread({
        "data-testid": (0, _utils.formatDataTestId)(dataTestIdPrefix, "Table-Cell-".concat(rowIndex, "-").concat(isExpanded ? 'expanded' : 'collapsed'))
      }, _objectSpread(_objectSpread({}, (0, _TableList.resultRowCellStyle)(isExpanded)), rowStyles)), {}, {
        children: (0, _jsxRuntime.jsxs)(_react.Link, _objectSpread(_objectSpread({}, _TableList.linkStyles), {}, {
          "data-testid": (0, _utils.formatDataTestId)(dataTestIdPrefix, "Table-Cell-".concat(rowIndex, "-").concat(isExpanded ? 'collapse' : 'expand')),
          onClick: () => onExpand(rowIndex, isExpanded),
          children: [isExpanded ? collapseBtnText : expandBtnText, (0, _jsxRuntime.jsx)(_react.Box, {
            ml: "xmd",
            children: isExpanded ? (0, _jsxRuntime.jsx)(_icons.ChevronUp, {
              color: "var(--chakra-colors-btnSecondaryEnabled)"
            }) : (0, _jsxRuntime.jsx)(_icons.ChevronDown, {
              color: "var(--chakra-colors-btnSecondaryEnabled)"
            })
          })]
        }))
      }), 'expand')]
    }, rowIndex), isExpandable && isExpanded && (0, _jsxRuntime.jsx)(_react.Tr, {
      "data-testid": (0, _utils.formatDataTestId)(dataTestIdPrefix, "Table-Row-expanded"),
      children: (0, _jsxRuntime.jsx)(_react.Td, _objectSpread(_objectSpread({
        colSpan: colSpan
      }, _TableList.cardRowStyle), {}, {
        "data-testid": (0, _utils.formatDataTestId)(dataTestIdPrefix, "Table-Row-expanded-card"),
        children: (0, _jsxRuntime.jsx)(_react.Box, _objectSpread(_objectSpread({}, _TableList.cardWrapperStyle), {}, {
          children: renderExpandedContent === null || renderExpandedContent === void 0 ? void 0 : renderExpandedContent(row)
        }))
      }))
    })]
  });
}