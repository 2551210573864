"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = HotelMap;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _react = require("@chakra-ui/react");
var _jsApiLoader = require("@googlemaps/js-api-loader");
var _react2 = require("react");
var _theme = _interopRequireDefault(require("../../../theme"));
var _jsxRuntime = require("react/jsx-runtime");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function HotelMap(_ref) {
  var {
    latitude,
    longitude,
    brand,
    apiKey,
    fillColor
  } = _ref;
  var hotelMapRef = (0, _react2.useRef)(null);
  (0, _react2.useEffect)(() => {
    var loader = new _jsApiLoader.Loader({
      apiKey,
      version: 'weekly',
      libraries: []
    });
    loader.load().then(() => {
      if (hotelMapRef !== null && hotelMapRef !== void 0 && hotelMapRef.current && latitude && longitude) {
        var _google = window.google;
        var hotelMap = new _google.maps.Map(hotelMapRef.current, {
          center: {
            lat: latitude,
            lng: longitude
          },
          zoom: 13
        });
        new _google.maps.Marker({
          position: {
            lat: latitude,
            lng: longitude
          },
          map: hotelMap,
          icon: getMarkerIconByBrand()
        });
      }
    });
  }, [brand, latitude, longitude]);
  return (0, _jsxRuntime.jsx)(_react.Box, {
    "data-testid": "hotelDirections-map",
    h: "full",
    ref: hotelMapRef
  });
  function getMarkerIconByBrand() {
    return _objectSpread(_objectSpread({}, defaultPinStyles), {}, {
      fillColor,
      anchor: new google.maps.Point(15, 30)
    });
  }
}
var MapPinPath = 'M45.0585 21C45.0585 32.598 22.8232 63.5 22.8232 63.5C22.8232 63.5 0.587891 32.598 0.587891 21C0.587891 9.40202 10.543 0 22.8232 0C35.1034 0 45.0585 9.40202 45.0585 21Z';
var defaultPinStyles = {
  path: MapPinPath,
  fillOpacity: 1,
  strokeWeight: 2,
  strokeColor: _theme.default.colors.baseWhite,
  rotation: 0,
  scale: 0.5
};