"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
var _getPrivacyPolicy = require("./getPrivacyPolicy");
Object.keys(_getPrivacyPolicy).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _getPrivacyPolicy[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _getPrivacyPolicy[key];
    }
  });
});
var _copyBooking = require("./copyBooking");
Object.keys(_copyBooking).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _copyBooking[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _copyBooking[key];
    }
  });
});
var _amendStayDates = require("./amendStayDates");
Object.keys(_amendStayDates).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _amendStayDates[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _amendStayDates[key];
    }
  });
});
var _addNewRoom = require("./addNewRoom");
Object.keys(_addNewRoom).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _addNewRoom[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _addNewRoom[key];
    }
  });
});
var _bookingConfirmation = require("./bookingConfirmation");
Object.keys(_bookingConfirmation).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _bookingConfirmation[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _bookingConfirmation[key];
    }
  });
});
var _removeRoom = require("./removeRoom");
Object.keys(_removeRoom).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _removeRoom[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _removeRoom[key];
    }
  });
});
var _editRoom = require("./editRoom");
Object.keys(_editRoom).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _editRoom[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _editRoom[key];
    }
  });
});
var _summaryOfPayments = require("./summaryOfPayments");
Object.keys(_summaryOfPayments).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _summaryOfPayments[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _summaryOfPayments[key];
    }
  });
});
var _confirmAmend = require("./confirmAmend");
Object.keys(_confirmAmend).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _confirmAmend[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _confirmAmend[key];
    }
  });
});
var _saveReservation = require("./saveReservation");
Object.keys(_saveReservation).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _saveReservation[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _saveReservation[key];
    }
  });
});
var _amendFindBooking = require("./amendFindBooking");
Object.keys(_amendFindBooking).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _amendFindBooking[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _amendFindBooking[key];
    }
  });
});
var _stayEmployeeRules = require("./stayEmployeeRules");
Object.keys(_stayEmployeeRules).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _stayEmployeeRules[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _stayEmployeeRules[key];
    }
  });
});
var _amendConfirmationPrices = require("./amendConfirmationPrices");
Object.keys(_amendConfirmationPrices).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _amendConfirmationPrices[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _amendConfirmationPrices[key];
    }
  });
});
var _paymentOptions = require("./paymentOptions");
Object.keys(_paymentOptions).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _paymentOptions[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _paymentOptions[key];
    }
  });
});