"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = useCompanyDetails;
var _config = _interopRequireDefault(require("next/config"));
var _auth = require("../getters/auth");
var _useRequest = require("./use-request");
function useCompanyDetails(companyId, sessionId, employeeId, isLoggedIn) {
  var _data$requestedCompan;
  var {
    publicRuntimeConfig = {}
  } = (0, _config.default)() || {};
  var idTokenCookie = (0, _auth.getAuthCookie)();
  var params = {
    'session-id': sessionId,
    'employee-id': employeeId,
    'company-id': companyId,
    Authorization: "Bearer ".concat(idTokenCookie)
  };
  var {
    data
  } = (0, _useRequest.useRestQueryRequest)(['CompanyDetails', idTokenCookie], 'GET', "".concat(publicRuntimeConfig.NEXT_PUBLIC_REST_API, "/company/").concat(companyId), params, {
    enabled: isLoggedIn && !!companyId
  });
  if (data !== null && data !== void 0 && (_data$requestedCompan = data.requestedCompany) !== null && _data$requestedCompan !== void 0 && _data$requestedCompan.companyDetails) {
    var {
      companyName,
      alternateCompanyName
    } = data.requestedCompany.companyDetails;
    if (companyName !== null && companyName !== void 0 && companyName.length && alternateCompanyName !== null && alternateCompanyName !== void 0 && alternateCompanyName.length) {
      data.requestedCompany.companyDetails.companyName = alternateCompanyName;
    }
  }
  return data;
}