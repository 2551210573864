"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _react = require("@chakra-ui/react");
var _react2 = require("react");
var _icons = require("../../assets/icons");
var _ChevronDown = _interopRequireDefault(require("../../assets/icons/ChevronDown"));
var _ChevronUp = _interopRequireDefault(require("../../assets/icons/ChevronUp"));
var _formatters = require("../../utils/formatters");
var _Icon = _interopRequireDefault(require("../Icon"));
var _jsxRuntime = require("react/jsx-runtime");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function Dropdown(props) {
  var {
    variant = 'default',
    options = [],
    children: CustomContent,
    dropdownStyles,
    isOpenMenu = false,
    matchWidth,
    dataTestId,
    hasError,
    disabled,
    showStatusIcon,
    selectedId,
    onDisplayContent,
    onBlur,
    className
  } = props;
  var baseDataTestId = 'DropdownComp';
  var [selectedValue, setSelectedValue] = (0, _react2.useState)(undefined);
  var [isOpen, setIsOpen] = (0, _react2.useState)(false);
  var displayIcon = showStatusIcon && !disabled;
  var isValid = !hasError && selectedValue;
  var ref = (0, _react2.useRef)(null);
  (0, _react2.useEffect)(() => {
    if (isOpenMenu !== isOpen) {
      setIsOpen(isOpenMenu);
    }
  }, [isOpenMenu]);
  (0, _react.useOutsideClick)({
    ref: ref,
    handler: () => {
      setIsOpen(false);
      onDisplayContent === null || onDisplayContent === void 0 || onDisplayContent(false);
      if (isOpen) {
        onBlur === null || onBlur === void 0 || onBlur();
      }
    }
  });
  (0, _react2.useEffect)(() => {
    if (selectedId) {
      var o = getOptionById(selectedId);
      setSelectedValue(o);
    }
  }, [selectedId, options]);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [!showStatusIcon && renderDropdownField(), showStatusIcon && (0, _jsxRuntime.jsxs)(_react.Flex, _objectSpread(_objectSpread({}, dropdownWithStatusIconWrapperStyle), {}, {
      children: [renderDropdownField(), renderStatusIcons()]
    }))]
  });
  function renderStatusIcons() {
    return (0, _jsxRuntime.jsxs)(_react.Box, {
      children: [displayIcon && hasError && (0, _jsxRuntime.jsx)(_react.Box, _objectSpread(_objectSpread({}, errorIconStyle), {}, {
        "data-testid": "inputIconError",
        children: (0, _jsxRuntime.jsx)(_icons.Error24, {})
      })), displayIcon && isValid && (0, _jsxRuntime.jsx)(_react.Box, _objectSpread(_objectSpread({}, errorIconStyle), {}, {
        "data-testid": "inputIconSuccess",
        children: (0, _jsxRuntime.jsx)(_icons.Success24, {})
      }))]
    });
  }
  function renderDropdownField() {
    return (0, _jsxRuntime.jsx)(_react.Menu, _objectSpread(_objectSpread({
      gutter: 1,
      isOpen: isOpen
    }, dropdownStyles === null || dropdownStyles === void 0 ? void 0 : dropdownStyles.menuStyles), {}, {
      matchWidth: matchWidth,
      "data-testid": (0, _formatters.formatDataTestId)(baseDataTestId, 'Container'),
      children: (0, _jsxRuntime.jsxs)(_react.Box, _objectSpread(_objectSpread({
        w: "full",
        pos: "relative"
      }, dropdownStyles === null || dropdownStyles === void 0 ? void 0 : dropdownStyles.wrapperStyles), {}, {
        "data-testid": (0, _formatters.formatDataTestId)(baseDataTestId, 'Wrapper'),
        className: className,
        children: [props.label && (0, _jsxRuntime.jsx)(_react.Text, _objectSpread(_objectSpread({
          "data-testid": (0, _formatters.formatDataTestId)(baseDataTestId, 'Label'),
          pos: "absolute",
          color: getLabelColor(isOpen)
        }, labelStyles), {}, {
          children: props.label
        })), (0, _jsxRuntime.jsxs)(_react.Box, _objectSpread(_objectSpread({
          ref: ref,
          as: "span"
        }, dropdownStyles === null || dropdownStyles === void 0 ? void 0 : dropdownStyles.menuButtonWrapperStyles), {}, {
          children: [(0, _jsxRuntime.jsx)(_react.MenuButton, _objectSpread(_objectSpread({
            onClick: () => {
              setIsOpen(!isOpen);
              onDisplayContent === null || onDisplayContent === void 0 || onDisplayContent(!isOpen);
            },
            _hover: _objectSpread(_objectSpread({
              color: 'darkGrey2'
            }, dropdownStyles === null || dropdownStyles === void 0 ? void 0 : dropdownStyles.errorHoverMenuButtonStyles), isOpen ? {
              border: '2px solid var(--chakra-colors-primary)',
              borderColor: 'var(--chakra-colors-primary)',
              borderWidth: '2px'
            } : {
              border: '1px solid var(--chakra-colors-darkGrey1)',
              borderColor: 'var(--chakra-colors-darkGrey1)',
              borderWidth: '1px'
            }),
            _focus: _objectSpread({}, !isOpen && hasError && {
              border: '2px solid var(--chakra-colors-error)'
            })
          }, dropdownStyles === null || dropdownStyles === void 0 ? void 0 : dropdownStyles.menuButtonStyles), {}, {
            disabled: props.disabled,
            type: "button",
            "data-testid": (0, _formatters.formatDataTestId)(baseDataTestId, "".concat(dataTestId, "-menuButton")),
            children: (0, _jsxRuntime.jsxs)(_react.Flex, {
              align: "center",
              whiteSpace: "nowrap",
              "data-testid": (0, _formatters.formatDataTestId)(baseDataTestId, "".concat(dataTestId, "-insideMenuButton")),
              children: [!(selectedValue !== null && selectedValue !== void 0 && selectedValue.icon) && props.icon && (0, _jsxRuntime.jsx)(_react.Box, {
                "data-testid": (0, _formatters.formatDataTestId)(baseDataTestId, "".concat(dataTestId, "-menuButtonFlag")),
                mr: "md",
                children: props.icon
              }), selectedValue ? (0, _jsxRuntime.jsx)(_react.Flex, {
                w: "100%",
                textOverflow: "ellipsis",
                overflow: "hidden",
                "data-testid": (0, _formatters.formatDataTestId)(baseDataTestId, "".concat(dataTestId, "-menuButtonText")),
                children: renderDropdownOption(selectedValue, !!'buttonSelector')
              }) : props.placeholder && (0, _jsxRuntime.jsx)(_react.Box, _objectSpread(_objectSpread({
                as: "span"
              }, placeholderStyle), {}, {
                "data-testid": (0, _formatters.formatDataTestId)(baseDataTestId, "".concat(dataTestId, "-menuButtonOptionsText")),
                children: props.placeholder
              })), !props.skipChevron && (0, _jsxRuntime.jsx)(_Icon.default, {
                ml: "auto",
                "data-testid": (0, _formatters.formatDataTestId)(baseDataTestId, "".concat(dataTestId, "-menuButtonOptionsEndChevron")),
                svg: isOpen ? (0, _jsxRuntime.jsx)(_ChevronUp.default, {}) : (0, _jsxRuntime.jsx)(_ChevronDown.default, {})
              })]
            })
          })), CustomContent ? (0, _jsxRuntime.jsx)(CustomContent, {
            isOpen: isOpen,
            onChange: props.onChange,
            setSelectedValue: setSelectedValue,
            setIsOpen: setIsOpen,
            options: options,
            baseDataTestId: baseDataTestId,
            dataTestId: dataTestId
          }) : (0, _jsxRuntime.jsx)(_react.MenuList, _objectSpread(_objectSpread({
            css: {
              '&::-webkit-scrollbar': {
                width: '8px'
              },
              '&::-webkit-scrollbar-track': {
                width: '8px'
              },
              '&::-webkit-scrollbar-thumb': {
                background: 'var(--chakra-colors-lightGrey3)',
                borderRadius: '24px'
              }
            },
            "data-testid": (0, _formatters.formatDataTestId)(baseDataTestId, "".concat(dataTestId, "-entireList"))
          }, dropdownStyles === null || dropdownStyles === void 0 ? void 0 : dropdownStyles.menuListStyles), {}, {
            children: (0, _jsxRuntime.jsx)(_react.MenuGroup, {
              "data-testid": (0, _formatters.formatDataTestId)(baseDataTestId, "".concat(dataTestId, "-entireListGroup")),
              w: "full",
              children: options.map((option, index) => (0, _jsxRuntime.jsx)(_react.MenuItem, {
                "data-testid": (0, _formatters.formatDataTestId)(baseDataTestId, "".concat(dataTestId, "-").concat(index)),
                value: option.id,
                type: "button",
                onClick: event => {
                  var target = event.target;
                  var value = target === null || target === void 0 ? void 0 : target.value;
                  var o = getOptionById(value);
                  setIsOpen(false);
                  if ((o === null || o === void 0 ? void 0 : o.id) === (selectedValue === null || selectedValue === void 0 ? void 0 : selectedValue.id)) return;
                  if (props.onChange) props.onChange(o);
                  setSelectedValue(o);
                  onBlur === null || onBlur === void 0 || onBlur();
                },
                children: renderDropdownOption(option)
              }, option.id))
            })
          }))]
        }))]
      }))
    }));
  }
  function getLabelColor(isDropdownOpen) {
    var color = labelColorMap[variant];
    if (isDropdownOpen) {
      color = labelColorMap["".concat(variant, "Open")];
    }
    if (props.disabled) {
      color = labelColorMap["".concat(variant, "Disabled")];
    }
    return color;
  }
  function renderDropdownOption(option) {
    var buttonSelector = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
    return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
      children: [(option === null || option === void 0 ? void 0 : option.icon) && (0, _jsxRuntime.jsx)(_react.Box, {
        pr: "md",
        children: option.icon
      }), buttonSelector ? (0, _jsxRuntime.jsx)(_react.Box, {
        overflow: "hidden",
        "data-testid": (0, _formatters.formatDataTestId)(baseDataTestId, "".concat(dataTestId, "-buttonSelectorLabel")),
        children: option === null || option === void 0 ? void 0 : option.label
      }) : option === null || option === void 0 ? void 0 : option.label]
    });
  }
  function getOptionById(option) {
    return options.find(o => String(o.id) === option);
  }
}
var labelStyles = {
  top: '-7px',
  left: 'var(--chakra-space-xmd)',
  bgColor: 'var(--chakra-colors-baseWhite)',
  fontSize: 'var(--chakra-fontSizes-sm)',
  lineHeight: 'var(--chakra-lineHeights-1)',
  pr: 'xs',
  pl: 'xs',
  m: '0',
  _hover: {
    color: 'darkGrey2'
  }
};
var labelColorMap = {
  default: 'var(chakra-colors-darkGrey1)',
  defaultOpen: 'var(--chakra-colors-primary)',
  defaultDisabled: 'var(--chakra-colors-lightGrey2)',
  error: 'var(--chakra-colors-error)',
  errorOpen: 'var(--chakra-colors-error)',
  errorDisabled: 'var(--chakra-colors-error)'
};
var placeholderStyle = {
  w: '100%',
  textOverflow: 'ellipsis',
  overflow: 'hidden'
};
var errorIconStyle = {
  ml: 'md',
  alignSelf: 'center'
};
var dropdownWithStatusIconWrapperStyle = {
  align: 'center',
  w: 'full'
};
var _default = exports.default = (0, _react2.memo)(Dropdown);