"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.SOURCE_SYSTEM = void 0;
var SOURCE_SYSTEM = exports.SOURCE_SYSTEM = function (SOURCE_SYSTEM) {
  SOURCE_SYSTEM["BART"] = "BART";
  SOURCE_SYSTEM["OPERA"] = "OPERA";
  return SOURCE_SYSTEM;
}({});