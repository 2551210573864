"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = SilentSubstitutionNotification;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _react = require("@chakra-ui/react");
var _api = require("@whitbread-eos/api");
var _atoms = require("@whitbread-eos/atoms");
var _nextI18next = require("next-i18next");
var _jsxRuntime = require("react/jsx-runtime");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function SilentSubstitutionNotification(_ref) {
  var {
    brand,
    substitutedRooms,
    roomTypeInformationResponse
  } = _ref;
  var {
    t
  } = (0, _nextI18next.useTranslation)(['common']);
  var substitutedRoomLabels = substitutedRooms === null || substitutedRooms === void 0 ? void 0 : substitutedRooms.map(pmsRoomType => {
    var _roomTypeInformationR;
    return roomTypeInformationResponse === null || roomTypeInformationResponse === void 0 || (_roomTypeInformationR = roomTypeInformationResponse.dataRoomTypeInformation) === null || _roomTypeInformationR === void 0 || (_roomTypeInformationR = _roomTypeInformationR.roomTypeInformation) === null || _roomTypeInformationR === void 0 || (_roomTypeInformationR = _roomTypeInformationR.roomTypes) === null || _roomTypeInformationR === void 0 ? void 0 : _roomTypeInformationR.reduce((labels, roomInfo) => {
      if ((roomInfo === null || roomInfo === void 0 ? void 0 : roomInfo.roomTypeCode) === pmsRoomType || roomInfo !== null && roomInfo !== void 0 && roomInfo.roomTypeCode.includes(pmsRoomType)) {
        labels.push(roomInfo === null || roomInfo === void 0 ? void 0 : roomInfo.roomLabel);
      }
      return labels;
    }, []);
  }).flat().join(', ');
  if (!substitutedRoomLabels) {
    return null;
  }
  return (0, _jsxRuntime.jsx)(_react.Box, {
    mb: "md",
    "data-testid": "substitution-notification",
    children: (0, _jsxRuntime.jsx)(_atoms.Notification, _objectSpread(_objectSpread({}, getNotificationPropsByType(substitutedRoomLabels)), {}, {
      prefixDataTestId: "substitution-notification",
      isInnerHTML: true
    }, notificationStyles))
  });
  function getNotificationPropsByType(substitutedRoomLabels) {
    var unAvailableBrandContent = brand.toLowerCase() === _api.HUB ? t('searchresults.list.restrictions.hub.available') : t('searchresults.list.restrictions.available');
    return {
      description: "".concat(unAvailableBrandContent, " ").concat(t('searchresults.list.restrictions.offer'), " ").concat(substitutedRoomLabels, " "),
      variant: 'alert',
      status: 'warning',
      svg: (0, _jsxRuntime.jsx)(_atoms.Alert, {})
    };
  }
}
var notificationStyles = {
  maxW: {
    base: 'full',
    md: '100%'
  },
  lineHeight: '2'
};