"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = BookingInfoCardHeader;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _react = require("@chakra-ui/react");
var _api = require("@whitbread-eos/api");
var _utils = require("@whitbread-eos/utils");
var _nextI18next = require("next-i18next");
var _jsxRuntime = require("react/jsx-runtime");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function BookingInfoCardHeader(_ref) {
  var {
    checkInLabel,
    checkOutLabel,
    hotelName,
    shouldRenderDashboardButton,
    area
  } = _ref;
  var baseDataTestId = 'BookingInfoCardHeader';
  var {
    t
  } = (0, _nextI18next.useTranslation)(['common']);
  var {
    isLoggedIn
  } = (0, _utils.useUserData)();
  var {
    language,
    country
  } = (0, _utils.useCustomLocale)();
  return (0, _jsxRuntime.jsxs)(_react.Box, {
    "data-testid": (0, _utils.formatDataTestId)(baseDataTestId, 'Wrapper'),
    children: [(0, _jsxRuntime.jsx)(_react.Text, _objectSpread(_objectSpread({
      as: "h1"
    }, titleStyles), {}, {
      "data-testid": (0, _utils.formatDataTestId)(baseDataTestId, 'Title'),
      children: t('dashboard.bookings.bookingInformation')
    })), (0, _jsxRuntime.jsx)(_react.Text, _objectSpread(_objectSpread({
      as: "h6"
    }, hotelNameStyles), {}, {
      "data-testid": (0, _utils.formatDataTestId)(baseDataTestId, 'HotelName'),
      children: hotelName
    })), (0, _jsxRuntime.jsxs)(_react.Flex, _objectSpread(_objectSpread({}, datesWrapperStyles), {}, {
      children: [(0, _jsxRuntime.jsxs)(_react.Flex, _objectSpread(_objectSpread({}, checkInOutDatesStyles), {}, {
        children: [(0, _jsxRuntime.jsxs)(_react.Box, {
          children: [(0, _jsxRuntime.jsx)(_react.Text, _objectSpread(_objectSpread({
            as: "h6"
          }, dateLabelStyles), {}, {
            children: t('dashboard.bookings.checkIn')
          })), (0, _jsxRuntime.jsx)(_react.Text, _objectSpread(_objectSpread({
            as: "h6"
          }, dateStyles), {}, {
            "data-testid": (0, _utils.formatDataTestId)(baseDataTestId, 'CheckInDate'),
            children: checkInLabel
          }))]
        }), (0, _jsxRuntime.jsxs)(_react.Box, {
          ml: "2xl",
          children: [(0, _jsxRuntime.jsx)(_react.Text, _objectSpread(_objectSpread({
            as: "h6"
          }, dateLabelStyles), {}, {
            children: t('dashboard.bookings.checkOut')
          })), (0, _jsxRuntime.jsx)(_react.Text, _objectSpread(_objectSpread({
            as: "h6"
          }, dateStyles), {}, {
            "data-testid": (0, _utils.formatDataTestId)(baseDataTestId, 'CheckOutDate'),
            children: checkOutLabel
          }))]
        })]
      })), [_api.Area.PI, _api.Area.BB].includes(area) && shouldRenderDashboardButton && renderPIBBButton(), area === _api.Area.CCUI && shouldRenderDashboardButton && renderCCUIButtons()]
    }))]
  });
  function renderCCUIButtons() {
    var handleBackToDashboard = () => {
      window.location.href = "/".concat(country, "/").concat(language, "/bookings");
    };
    var handleBackToHomepage = () => {
      window.location.href = "/".concat(country, "/").concat(language);
    };
    return (0, _jsxRuntime.jsxs)(_react.Box, {
      children: [(0, _jsxRuntime.jsx)(_react.Button, {
        "data-testid": (0, _utils.formatDataTestId)(baseDataTestId, 'CCUIBackToDashboardButton'),
        variant: "tertiary",
        marginRight: "lg",
        onClick: handleBackToDashboard,
        children: t('amend.backButtonText')
      }), (0, _jsxRuntime.jsx)(_react.Button, {
        "data-testid": (0, _utils.formatDataTestId)(baseDataTestId, 'CCUIBackToHomepageButton'),
        variant: "secondary",
        onClick: handleBackToHomepage,
        children: t('amend.anonymousBackButtonText')
      })]
    });
  }
  function renderPIBBButton() {
    return (0, _jsxRuntime.jsx)(_react.Box, {
      children: (0, _jsxRuntime.jsx)(_react.Button, _objectSpread(_objectSpread({
        "data-testid": (0, _utils.formatDataTestId)(baseDataTestId, 'BackToDashboardButton'),
        onClick: handleBackButton
      }, backToDashboardButton), {}, {
        children: isLoggedIn ? t('amend.backButtonText') : t('amend.anonymousBackButtonText')
      }))
    });
  }
  function handleBackButton() {
    sessionStorage.removeItem(_api.AMEND_3CP_VISITED_KEY);
    var isBB = area === _api.Area.BB ? '/business-booker' : '';
    window.location.href = isLoggedIn ? "/".concat(country, "/").concat(language).concat(isBB, "/account/dashboard.html") : "/".concat(country, "/").concat(language).concat(isBB, "/home.html");
  }
}
var titleStyles = {
  color: 'baseBlack',
  fontWeight: 'semibold',
  lineHeight: 5,
  fontSize: {
    mobile: '3xl',
    xs: '3xxl'
  }
};
var hotelNameStyles = {
  mt: 'lg',
  fontWeight: 'medium',
  lineHeight: 3,
  fontSize: 'md',
  color: 'darkGrey1'
};
var checkInOutDatesStyles = {
  flexDirection: 'row',
  mt: 'lg',
  mb: {
    mobile: 'lg',
    md: '2xl'
  },
  justifyContent: {
    mobile: 'space-between',
    sm: 'start'
  }
};
var dateLabelStyles = {
  color: 'darkGrey1',
  fontWeight: 'normal',
  lineHeight: 3,
  fontSize: 'md'
};
var dateStyles = {
  color: 'darkGrey1',
  fontWeight: 'semibold',
  lineHeight: 3,
  fontSize: 'md'
};
var datesWrapperStyles = {
  justifyContent: {
    md: 'space-between'
  },
  flexDirection: {
    mobile: 'column',
    md: 'row'
  }
};
var backToDashboardButton = {
  mt: {
    md: 'lg'
  },
  mb: '2xl',
  width: {
    mobile: '17rem',
    xs: '18rem'
  },
  variant: 'secondary'
};