"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.AppDataProvider = AppDataProvider;
exports.useAppData = useAppData;
exports.useAppDataDispatch = useAppDataDispatch;
var _react = require("react");
var _useImmer = require("use-immer");
var _jsxRuntime = require("react/jsx-runtime");
var defaultInitialAppData = {
  screenSize: undefined,
  orientation: undefined
};
var AppDataContext = (0, _react.createContext)(null);
var AppDataDispatchContext = (0, _react.createContext)(() => null);
function AppDataProvider(_ref) {
  var {
    children,
    initialAppData
  } = _ref;
  var [appData, dispatch] = (0, _useImmer.useImmerReducer)(appDataReducer, initialAppData !== null && initialAppData !== void 0 ? initialAppData : defaultInitialAppData);
  return (0, _jsxRuntime.jsx)(AppDataContext.Provider, {
    value: appData,
    children: (0, _jsxRuntime.jsx)(AppDataDispatchContext.Provider, {
      value: dispatch,
      children: children
    })
  });
}
function useAppData() {
  return (0, _react.useContext)(AppDataContext);
}
function useAppDataDispatch() {
  return (0, _react.useContext)(AppDataDispatchContext);
}
function appDataReducer(appData, action) {
  switch (action.type) {
    case 'ui/screenSize':
      {
        appData.screenSize = action.payload;
        break;
      }
    case 'ui/orientation':
      {
        appData.orientation = action.payload;
        break;
      }
    default:
      {
        throw Error('Unknown action: ' + action.type);
      }
  }
}