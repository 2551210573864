"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = HotelLastFewRooms;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _react = require("@chakra-ui/react");
var _jsxRuntime = require("react/jsx-runtime");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function HotelLastFewRooms(_ref) {
  var {
    label,
    testId,
    styles
  } = _ref;
  return (0, _jsxRuntime.jsx)(_react.Box, _objectSpread(_objectSpread({}, styles), {}, {
    children: (0, _jsxRuntime.jsx)(_react.Text, _objectSpread(_objectSpread({}, lastFewRoomsStyles), {}, {
      "data-testid": testId,
      children: label
    }))
  }));
}
var lastFewRoomsStyles = {
  width: 'auto',
  verticalAlign: 'top',
  color: 'alert',
  fontSize: 'sm',
  fontWeight: 'semibold',
  lineHeight: 'normal',
  margin: '0'
};