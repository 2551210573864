"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = ExpandText;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _react = require("@chakra-ui/react");
var _Button = _interopRequireDefault(require("../Button"));
var _jsxRuntime = require("react/jsx-runtime");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function ExpandText(props) {
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_react.Collapse, _objectSpread(_objectSpread({
      in: props.show
    }, props), {}, {
      "data-testid": "wrapper",
      children: props.children
    })), (0, _jsxRuntime.jsx)(_react.Box, {
      mt: "-5rem",
      height: "5rem",
      position: "relative",
      bg: "".concat(!props.show && 'linear-gradient(to bottom, var(--chakra-colors-fadedBaseWhite) 0%, var(--chakra-colors-baseWhite) 75%)')
    }), (0, _jsxRuntime.jsx)(_Button.default, {
      size: "sm",
      variant: "tertiary",
      onClick: props.onClick,
      children: props.buttonText
    })]
  });
}