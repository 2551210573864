"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.resetPasswordFormConfig = void 0;
var _atoms = require("@whitbread-eos/atoms");
var _utils = require("@whitbread-eos/utils");
var yup = _interopRequireWildcard(require("yup"));
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && {}.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
var resetPasswordFormConfig = _ref => {
  var _labels$login, _labels$login2, _labels$login3, _labels$login4, _labels$login5, _labels$login6;
  var {
    getFormState,
    defaultValues,
    defaultErrors,
    onSubmit,
    baseDataTestId,
    labels,
    resetForm
  } = _ref;
  var resetPasswordValidationSchema = yup.object().shape({
    email: yup.string().required((labels === null || labels === void 0 || (_labels$login = labels.login) === null || _labels$login === void 0 || (_labels$login = _labels$login.business) === null || _labels$login === void 0 ? void 0 : _labels$login.bookingLoginRequiredText) || (labels === null || labels === void 0 || (_labels$login2 = labels.login) === null || _labels$login2 === void 0 ? void 0 : _labels$login2.invalidEmail)).matches(_atoms.FORM_VALIDATIONS.EMAIL.MATCHES, (labels === null || labels === void 0 || (_labels$login3 = labels.login) === null || _labels$login3 === void 0 || (_labels$login3 = _labels$login3.business) === null || _labels$login3 === void 0 ? void 0 : _labels$login3.bookingsInvalidEmailMsg) || (labels === null || labels === void 0 || (_labels$login4 = labels.login) === null || _labels$login4 === void 0 ? void 0 : _labels$login4.invalidEmail)).max(_atoms.FORM_VALIDATIONS.EMAIL.MAX, (labels === null || labels === void 0 || (_labels$login5 = labels.login) === null || _labels$login5 === void 0 || (_labels$login5 = _labels$login5.business) === null || _labels$login5 === void 0 ? void 0 : _labels$login5.bookingEmailMaxLengthMsg) || (labels === null || labels === void 0 || (_labels$login6 = labels.login) === null || _labels$login6 === void 0 ? void 0 : _labels$login6.invalidEmail))
  });
  var config = {
    elements: {
      fieldsContainerStyles: {
        marginBottom: '1rem'
      },
      fields: [{
        type: _atoms.FORM_FIELD_TYPES.INPUT_TEXT,
        name: 'email',
        label: labels.forgottenPassword.leisure.emailPlaceholder,
        props: {
          useTooltip: true
        },
        testid: (0, _utils.formatDataTestId)(baseDataTestId, 'Email')
      }],
      buttons: [{
        type: _atoms.FORM_BUTTON_TYPES.SUBMIT,
        label: labels.forgottenPassword.leisure.submitButton,
        action: onSubmit,
        styles: {
          marginBottom: '0'
        },
        props: {
          variant: 'primary',
          size: 'full'
        },
        testid: (0, _utils.formatDataTestId)(baseDataTestId, 'Submit')
      }]
    },
    defaultValues,
    validationSchema: resetPasswordValidationSchema,
    getFormState,
    defaultErrors,
    resetForm
  };
  return config;
};
exports.resetPasswordFormConfig = resetPasswordFormConfig;