"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _react = require("@chakra-ui/react");
var _atoms = require("@whitbread-eos/atoms");
var _nextI18next = require("next-i18next");
var _router = require("next/router");
var _react2 = require("react");
var _PageLoader = _interopRequireDefault(require("../../amend/PageLoader"));
var _jsxRuntime = require("react/jsx-runtime");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
var BackButton = () => {
  var _Object$keys;
  var {
    t
  } = (0, _nextI18next.useTranslation)();
  var {
    query,
    events,
    back
  } = (0, _router.useRouter)();
  var [pageLoaderStatus, setPageLoaderStatus] = (0, _react2.useState)(false);
  var queryLength = (_Object$keys = Object.keys(query)) === null || _Object$keys === void 0 ? void 0 : _Object$keys.length;
  var handleBackButtonClick = () => {
    back();
  };
  (0, _react2.useEffect)(() => {
    var handleRouteChangeStart = () => {
      setPageLoaderStatus(true);
    };
    var handleRouteChangeComplete = () => {
      setPageLoaderStatus(false);
    };
    events.on('routeChangeStart', handleRouteChangeStart);
    events.on('routeChangeComplete', handleRouteChangeComplete);
    events.on('routeChangeError', handleRouteChangeComplete);
    return () => {
      events.off('routeChangeStart', handleRouteChangeStart);
      events.off('routeChangeComplete', handleRouteChangeComplete);
      events.off('routeChangeError', handleRouteChangeComplete);
    };
  }, [events]);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [pageLoaderStatus && (0, _jsxRuntime.jsx)(_PageLoader.default, {
      text: t('searchresults.list.hotel.loading')
    }), queryLength > 0 && (0, _jsxRuntime.jsx)(_react.Box, {
      w: "fit-content",
      mb: 'lg',
      onClick: handleBackButtonClick,
      children: (0, _jsxRuntime.jsxs)(_react.Flex, _objectSpread(_objectSpread({}, backBtnStyles), {}, {
        children: [(0, _jsxRuntime.jsx)(_atoms.ChevronLeft, {}), (0, _jsxRuntime.jsx)(_react.Text, {
          "data-testid": "reg-form-back-btn",
          children: t('precheckin.backbutton')
        })]
      }))
    })]
  });
};
var _default = exports.default = BackButton;
var backBtnStyles = {
  cursor: 'pointer',
  alignItems: 'center',
  gap: '3',
  w: 'fit-content'
};