"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = AgentOverrideModal;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _react = require("@chakra-ui/react");
var _atoms = require("@whitbread-eos/atoms");
var _utils = require("@whitbread-eos/utils");
var _react2 = require("react");
var _agentOverrideFormConfig = require("./modalFormConfig/agentOverrideFormConfig");
var _jsxRuntime = require("react/jsx-runtime");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function AgentOverrideModal(_ref) {
  var {
    getFormState,
    defaultValues,
    onSubmit,
    reasons,
    error,
    isVisible,
    onClose,
    t
  } = _ref;
  var baseDataTestId = 'AgentOverrideModal';
  var [isManagerApprovalNeeded, setIsManagerApprovalNeeded] = (0, _react2.useState)(false);
  (0, _react2.useEffect)(() => {
    if (defaultValues.managerName.length > 0) {
      setIsManagerApprovalNeeded(true);
    }
  }, [defaultValues]);
  if (error) {
    return (0, _jsxRuntime.jsx)(_atoms.Notification, {
      status: "error",
      description: error,
      variant: "alert",
      maxW: "full",
      svg: (0, _jsxRuntime.jsx)(_atoms.Alert, {})
    });
  }
  return (0, _jsxRuntime.jsx)(_atoms.ModalVariants, {
    onClose: onClose,
    variant: "gallery",
    isOpen: isVisible,
    variantProps: {
      title: t('ccui.manageBooking.options.agentOverrideModal.title'),
      delimiter: false
    },
    children: (0, _jsxRuntime.jsx)(_react.Box, _objectSpread(_objectSpread({
      "data-testid": (0, _utils.formatDataTestId)(baseDataTestId, 'Container')
    }, containerStyles), {}, {
      children: (0, _jsxRuntime.jsx)(_react.Flex, {
        mt: "2rem",
        justifyContent: "flex-end",
        children: (0, _jsxRuntime.jsx)(_atoms.Form, _objectSpread({}, (0, _agentOverrideFormConfig.agentOverrideFormConfig)({
          getFormState,
          defaultValues,
          onSubmit,
          onClose,
          baseDataTestId,
          t,
          reasons,
          isManagerApprovalNeeded,
          setIsManagerApprovalNeeded
        })))
      })
    }))
  });
}
var containerStyles = {
  mb: '1.5rem',
  ml: '1.5rem',
  mr: '1.5rem'
};