"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = RoomDetails;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _react = require("@chakra-ui/react");
var _api = require("@whitbread-eos/api");
var _utils = require("@whitbread-eos/utils");
var _react2 = require("react");
var _RoomCard = _interopRequireDefault(require("./RoomCard.component"));
var _jsxRuntime = require("react/jsx-runtime");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function RoomDetails(_ref) {
  var {
    data,
    basketReference,
    currentLang,
    t
  } = _ref;
  var {
    roomDetails,
    donations = null,
    bookingTotalCost,
    currency,
    selectedPaymentOption,
    taxesMessage,
    brand
  } = data;
  var baseDataTestId = 'RoomDetailsSection';
  var [cardsExpanded, setCardsExpanded] = (0, _react2.useState)([]);
  var [showExpanded, setShowExpanded] = (0, _react2.useState)(true);
  var showExpandCollapseButton = roomDetails.length !== 1;
  var isCardsExpandedIndividually = cardsExpanded.every(element => !element.expanded);
  var isCardsCollapsedIndividually = cardsExpanded.every(element => element.expanded);
  var isSilentFeatureFlagEnabled = (0, _utils.useFeatureSwitch)({
    featureSwitchKey: _api.FS_SILENT_SUBSTITUTION
  });
  var currentReservationRoomData = isSilentFeatureFlagEnabled ? (0, _utils.getCurrentReservationStorageData)(basketReference) : {};
  (0, _react2.useEffect)(() => {
    if (isCardsExpandedIndividually) {
      setShowExpanded(true);
    }
    if (isCardsCollapsedIndividually) {
      setShowExpanded(false);
    }
  }, [isCardsExpandedIndividually, isCardsCollapsedIndividually]);
  if (!roomDetails.length) {
    return null;
  }
  var calcRoomsTotal = () => {
    var roomsTotal = 0;
    roomDetails.forEach(room => roomsTotal += room.roomPrice);
    return roomsTotal;
  };
  var calcExtrasTotal = () => {
    var extrasTotal = 0;
    roomDetails.forEach(room => {
      var _room$packages;
      room === null || room === void 0 || (_room$packages = room.packages) === null || _room$packages === void 0 || _room$packages.forEach(pkg => {
        var _room$ratesPerNight;
        return extrasTotal += !pkg.description.includes('Charity') ? pkg.computedPrice * (room === null || room === void 0 || (_room$ratesPerNight = room.ratesPerNight) === null || _room$ratesPerNight === void 0 ? void 0 : _room$ratesPerNight.length) : 0;
      });
    });
    return extrasTotal;
  };
  return (0, _jsxRuntime.jsxs)(_react.Box, _objectSpread(_objectSpread({}, contentWrapperStyle), {}, {
    "data-testid": baseDataTestId,
    children: [(0, _jsxRuntime.jsxs)(_react.Box, _objectSpread(_objectSpread({}, roomDetailsTitleContainer), {}, {
      children: [(0, _jsxRuntime.jsx)(_react.Text, _objectSpread(_objectSpread({}, roomDetailsTitleStyle), {}, {
        sx: {
          '@media print': {
            display: 'none'
          }
        },
        children: t('booking.roomdetails.title')
      })), showExpandCollapseButton && (0, _jsxRuntime.jsx)(_react.Link, _objectSpread(_objectSpread({
        onClick: () => handleExpandCollapse()
      }, expandCollapseButtonStyle), {}, {
        children: showExpanded ? t('booking.roomdetails.expandAll') : t('booking.roomdetails.collapseAll')
      }))]
    })), renderRoomCards(), (0, _jsxRuntime.jsxs)(_react.Box, {
      display: "none",
      sx: {
        '@media print': {
          display: 'block'
        }
      },
      children: [(0, _jsxRuntime.jsx)(_react.Divider, {
        my: "lg",
        borderBottomWidth: "2px"
      }), (0, _jsxRuntime.jsxs)(_react.Flex, {
        justify: "space-between",
        mb: "md",
        children: [(0, _jsxRuntime.jsx)(_react.Text, {
          children: t('account.dashboard.roomsTotal')
        }), (0, _jsxRuntime.jsx)(_react.Text, {
          fontWeight: "bold",
          children: (0, _utils.formatPrice)((0, _utils.formatCurrency)(currency), calcRoomsTotal().toFixed(2), currentLang)
        })]
      }), (0, _jsxRuntime.jsxs)(_react.Flex, {
        justify: "space-between",
        mb: "md",
        children: [(0, _jsxRuntime.jsx)(_react.Text, {
          children: t('account.dashboard.extrasTotal')
        }), (0, _jsxRuntime.jsx)(_react.Text, {
          fontWeight: "bold",
          children: (0, _utils.formatPrice)((0, _utils.formatCurrency)(currency), calcExtrasTotal().toFixed(2), currentLang)
        })]
      }), (donations === null || donations === void 0 ? void 0 : donations.amount) && (0, _jsxRuntime.jsxs)(_react.Flex, {
        justify: "space-between",
        mb: "md",
        children: [(0, _jsxRuntime.jsx)(_react.Text, {
          children: t('booking.confirmation.donationMessage')
        }), (0, _jsxRuntime.jsx)(_react.Text, {
          fontWeight: "bold",
          children: (0, _utils.formatPrice)((0, _utils.formatCurrency)(currency), donations === null || donations === void 0 ? void 0 : donations.amount, currentLang)
        })]
      }), (0, _jsxRuntime.jsxs)(_react.Grid, {
        templateColumns: "repeat(2, 1fr)",
        mb: "md",
        children: [(0, _jsxRuntime.jsxs)(_react.GridItem, {
          children: [(0, _jsxRuntime.jsxs)(_react.Text, {
            fontWeight: "bold",
            mb: "md",
            children: [t('booking.confirmation.totalCost'), ":"]
          }), (0, _jsxRuntime.jsx)(_react.Text, {
            children: t(renderPaymentConfirmationText(selectedPaymentOption))
          })]
        }), (0, _jsxRuntime.jsxs)(_react.GridItem, {
          textAlign: "right",
          children: [(0, _jsxRuntime.jsx)(_react.Text, {
            fontWeight: "bold",
            fontSize: "4xl",
            children: (0, _utils.formatPrice)((0, _utils.formatCurrency)(currency), bookingTotalCost.toFixed(2), currentLang)
          }), !!(taxesMessage !== null && taxesMessage !== void 0 && taxesMessage.length) && (0, _jsxRuntime.jsx)(_react.Text, {
            children: taxesMessage
          })]
        })]
      }), (0, _jsxRuntime.jsx)(_react.Divider, {
        my: "lg",
        borderBottomWidth: "2px"
      })]
    })]
  }));
  function renderPaymentConfirmationText(paymentOption) {
    switch (paymentOption) {
      case _api.paymentOptions.PAY_NOW:
        return 'booking.confirmation.paymentTaken';
      case _api.paymentOptions.PAY_ON_ARRIVAL:
        return 'booking.confirmation.reservedWithCard';
      case _api.paymentOptions.RESERVE_WITHOUT_CARD:
        return 'booking.confirmation.reservedWithoutCard';
      default:
        return paymentOption;
    }
  }
  function handleExpandCollapse(roomNumber) {
    setCardsExpanded(prevState => prevState.map(cardItem => {
      var expanded = !cardItem.expanded;
      if (roomNumber && cardItem.roomNumber !== roomNumber) {
        expanded = cardItem.expanded;
      }
      return _objectSpread(_objectSpread({}, cardItem), {}, {
        expanded
      });
    }));
  }
  function renderRoomCards() {
    return roomDetails.map((room, index) => {
      var _currentReservationRo, _currentReservationRo2, _currentReservationRo3, _cardsExpanded$, _cardsExpanded$index;
      var roomData = isSilentFeatureFlagEnabled && (currentReservationRoomData === null || currentReservationRoomData === void 0 || (_currentReservationRo = currentReservationRoomData.value[index]) === null || _currentReservationRo === void 0 ? void 0 : _currentReservationRo.silentSubstitution) && _objectSpread({}, _objectSpread(_objectSpread({}, room), {}, {
        roomType: (_currentReservationRo2 = currentReservationRoomData === null || currentReservationRoomData === void 0 || (_currentReservationRo3 = currentReservationRoomData.value[index]) === null || _currentReservationRo3 === void 0 ? void 0 : _currentReservationRo3.roomLabelCode) !== null && _currentReservationRo2 !== void 0 ? _currentReservationRo2 : '',
        roomTypeDescription: null
      })) || room;
      return (0, _jsxRuntime.jsx)(_RoomCard.default, {
        t: t,
        room: roomData,
        currency: currency,
        roomNumber: index + 1,
        currentLang: currentLang,
        taxesMessage: taxesMessage,
        brand: brand,
        isCardExpanded: roomDetails.length === 1 ? !((_cardsExpanded$ = cardsExpanded[0]) !== null && _cardsExpanded$ !== void 0 && _cardsExpanded$.expanded) : (_cardsExpanded$index = cardsExpanded[index]) === null || _cardsExpanded$index === void 0 ? void 0 : _cardsExpanded$index.expanded,
        cardsExpanded: cardsExpanded,
        setCardsExpanded: setCardsExpanded,
        handleExpandCollapse: handleExpandCollapse
      }, "room-".concat(index + 1, "-key"));
    });
  }
}
var contentWrapperStyle = {
  w: 'full',
  mb: '2xl'
};
var roomDetailsTitleStyle = {
  fontSize: '2xl',
  fontWeight: 'semibold',
  mb: 'md'
};
var expandCollapseButtonStyle = {
  fontSize: '0.875rem',
  fontWeight: 'semibold',
  textDecoration: 'underline',
  lineHeight: '1.25rem',
  color: '#511E62'
};
var roomDetailsTitleContainer = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between'
};