"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.MEMO_TYPE = void 0;
var MEMO_TYPE = exports.MEMO_TYPE = function (MEMO_TYPE) {
  MEMO_TYPE["AGENT"] = "AGENT";
  MEMO_TYPE["SYSTEM"] = "SYSTEM";
  MEMO_TYPE["OPERA"] = "OPERA";
  return MEMO_TYPE;
}({});