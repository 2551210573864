"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.GET_COUNTRIES = void 0;
var _taggedTemplateLiteral2 = _interopRequireDefault(require("@babel/runtime/helpers/taggedTemplateLiteral"));
var _graphqlRequest = require("graphql-request");
var _templateObject;
var GET_COUNTRIES = exports.GET_COUNTRIES = (0, _graphqlRequest.gql)(_templateObject || (_templateObject = (0, _taggedTemplateLiteral2.default)(["\n  query getCountries($country: String!, $language: String!, $site: String!) {\n    countries(country: $country, language: $language, site: $site) {\n      countries {\n        countryCode\n        countryCodeLegacy\n        countryName\n        dialingCode\n        flagSrc\n        passportRequired\n        nationality\n      }\n    }\n  }\n"])));