"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ACCOUNT_TO_COMPANY_ALLOWANCES = void 0;
var ACCOUNT_TO_COMPANY_ALLOWANCES = exports.ACCOUNT_TO_COMPANY_ALLOWANCES = function (ACCOUNT_TO_COMPANY_ALLOWANCES) {
  ACCOUNT_TO_COMPANY_ALLOWANCES["BREAKFAST"] = "premierInnBreakfast";
  ACCOUNT_TO_COMPANY_ALLOWANCES["CAR_PARKING"] = "carParking";
  return ACCOUNT_TO_COMPANY_ALLOWANCES;
}({});