"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.GET_HOTEL_AVAILABILITY_QUERY = void 0;
var _taggedTemplateLiteral2 = _interopRequireDefault(require("@babel/runtime/helpers/taggedTemplateLiteral"));
var _graphqlRequest = require("graphql-request");
var _templateObject;
var GET_HOTEL_AVAILABILITY_QUERY = exports.GET_HOTEL_AVAILABILITY_QUERY = (0, _graphqlRequest.gql)(_templateObject || (_templateObject = (0, _taggedTemplateLiteral2.default)(["\n  query hotelAvailability(\n    $hotelId: String!\n    $arrival: String!\n    $departure: String!\n    $rooms: [RoomSearch!]!\n    $bookingChannel: BookingChannelCriteria!\n    $ratePlanCodes: [String]\n  ) {\n    hotelAvailability(\n      availabilitySearchCriteria: {\n        arrival: $arrival\n        departure: $departure\n        rooms: $rooms\n        hotel: { identifier: $hotelId }\n        bookingChannel: $bookingChannel\n        ratePlanCodes: $ratePlanCodes\n      }\n    ) {\n      hotelId\n      available\n      roomRates {\n        ratePlanCode\n        cellCode\n        roomTypes {\n          roomType\n          adults\n          children\n          rooms {\n            pmsRoomType\n            specialRequests\n            roomPriceBreakdown {\n              totalNetAmount\n              currencyCode\n            }\n          }\n        }\n      }\n    }\n  }\n"])));