"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.formatCurrency = formatCurrency;
exports.formatDataTestId = formatDataTestId;
exports.formatPrice = formatPrice;
var _validators = require("./validators");
function formatCurrency(currencyCode) {
  switch (currencyCode) {
    case 'GBP':
      return '£';
    case 'EUR':
      return '€';
    default:
      return '';
  }
}
function formatPrice(currency, price) {
  var language = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 'en';
  if (language === 'de' && currency === '€') {
    return "".concat(price).concat(currency);
  }
  return "".concat(currency).concat(price);
}
function formatDataTestId() {
  var prefix = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
  var dataTestId = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '';
  var previousString = (0, _validators.isStringValid)(prefix) ? "".concat(prefix, "-") : '';
  var testId = (0, _validators.isStringValid)(dataTestId) ? dataTestId : '';
  return "".concat(previousString).concat(testId);
}