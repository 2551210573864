"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "AgentMemo", {
  enumerable: true,
  get: function get() {
    return _AgentMemo.default;
  }
});
var _AgentMemo = _interopRequireDefault(require("./AgentMemo.component"));