"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.HeaderLinks = exports.BB_MENU_IDS = void 0;
var HeaderLinks = exports.HeaderLinks = function (HeaderLinks) {
  HeaderLinks[HeaderLinks["discoverPI"] = 0] = "discoverPI";
  HeaderLinks[HeaderLinks["findBooking"] = 1] = "findBooking";
  HeaderLinks[HeaderLinks["guestAccount"] = 2] = "guestAccount";
  return HeaderLinks;
}({});
var BB_MENU_IDS = exports.BB_MENU_IDS = function (BB_MENU_IDS) {
  BB_MENU_IDS["ABOUT"] = "About";
  BB_MENU_IDS["ACCOUNT_DASHBOARD"] = "Account Dashboard";
  BB_MENU_IDS["BUSINESS"] = "Business";
  BB_MENU_IDS["BUSINESS_ACCOUNT"] = "Business account";
  BB_MENU_IDS["BUSINESS_CUSTOMERS"] = "Business Customers";
  BB_MENU_IDS["COMPANY"] = "Company";
  BB_MENU_IDS["COMPANY_DETAILS"] = "Company Details";
  BB_MENU_IDS["COMPANY_NAME"] = "Company name";
  return BB_MENU_IDS;
}({});