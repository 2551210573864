"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = HeaderVariantLogo;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _react = require("@chakra-ui/react");
var _atoms = require("@whitbread-eos/atoms");
var _utils = require("@whitbread-eos/utils");
var _react2 = require("react");
var _HeaderLeisure = require("../HeaderLeisure.style");
var _jsxRuntime = require("react/jsx-runtime");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function HeaderVariantLogo(_ref) {
  var _headerInfoData$conte;
  var {
    headerInfoData,
    hotelBrand,
    isIcon,
    bb = false
  } = _ref;
  var {
    language: currentLanguage,
    country: currentCountry
  } = (0, _utils.useCustomLocale)();
  var isWindowDefined = typeof window !== 'undefined';
  var [origin, setOrigin] = (0, _react2.useState)('');
  (0, _react2.useEffect)(() => {
    setOrigin(window.location.origin);
  }, [isWindowDefined]);
  return (0, _jsxRuntime.jsx)(_react.Box, _objectSpread(_objectSpread({}, customHeaderWrapperStyles), {}, {
    "data-testid": "common-header-wrapper",
    children: (0, _jsxRuntime.jsx)(_react.Container, _objectSpread(_objectSpread({}, _HeaderLeisure.containerWrapperStyles), {}, {
      children: (0, _jsxRuntime.jsx)(_react.Grid, _objectSpread(_objectSpread({}, _HeaderLeisure.contentStyles), {}, {
        sx: {
          '@media print': {
            py: 'sm'
          }
        },
        children: (0, _jsxRuntime.jsx)(_react.Flex, _objectSpread(_objectSpread({}, _HeaderLeisure.containerLogoStyle), {}, {
          "data-testid": "logo-container",
          children: (0, _jsxRuntime.jsx)(_atoms.Logo, {
            src: (0, _utils.formatAssetsUrl)(headerInfoData === null || headerInfoData === void 0 || (_headerInfoData$conte = headerInfoData.content) === null || _headerInfoData$conte === void 0 || (_headerInfoData$conte = _headerInfoData$conte.header) === null || _headerInfoData$conte === void 0 ? void 0 : _headerInfoData$conte.image),
            href: "".concat(origin, "/").concat(currentCountry, "/").concat(currentLanguage, "/").concat(bb ? 'business-booker/home.html' : 'home.html'),
            variant: isIcon && hotelBrand === 'pi' ? 'pi-icon' : hotelBrand
          })
        }))
      }))
    }))
  }));
}
var customHeaderWrapperStyles = _objectSpread(_objectSpread({}, _HeaderLeisure.headerWrapperStyles), {}, {
  h: {
    mobile: 'var(--chakra-space-4xl)',
    lg: 'var(--chakra-space-6xl)'
  }
});