"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.leadGuestFormConfig = void 0;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _ = require(".");
var _atoms = require("@whitbread-eos/atoms");
var _utils = require("@whitbread-eos/utils");
var _formValidation = _interopRequireDefault(require("./formValidation"));
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
var leadGuestFormConfig = _ref => {
  var {
    getFormState,
    defaultValues,
    onSubmit,
    baseTestId,
    labels,
    validationLabels,
    isEdit,
    setGuestDetails,
    setIsLocationRequired,
    hotelCountry,
    language
  } = _ref;
  var formValidationSchema = (0, _formValidation.default)(validationLabels);
  var config = {
    id: 'leadGuestDetailsForm',
    elements: {
      fields: [{
        type: _atoms.FORM_FIELD_TYPES.DYNAMIC_FIELD,
        dropdownOptions: (0, _utils.getTitleDropdownValues)((0, _utils.formatGuestTitleOptions)(labels.guestTitle), defaultValues.title),
        name: 'leadGuest',
        label: '',
        testid: (0, _utils.formatDataTestId)(baseTestId, 'Form'),
        Component: _.LeadGuestDetailsForm,
        props: {
          baseTestId,
          labels,
          isEdit,
          setGuestDetails,
          setIsLocationRequired,
          hotelCountry,
          language
        },
        styles: {
          marginBottom: '0px'
        }
      }],
      fieldsContainerStyles: _objectSpread({}, formStyles),
      onSubmitAction: onSubmit
    },
    defaultValues,
    validationSchema: formValidationSchema,
    getFormState
  };
  return config;
};
exports.leadGuestFormConfig = leadGuestFormConfig;
var formStyles = {
  marginBottom: '0px'
};