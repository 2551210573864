"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = TwinroomOptionsContainer;
var _api = require("@whitbread-eos/api");
var _nextI18next = require("next-i18next");
var _TwinroomOptions = _interopRequireDefault(require("./TwinroomOptions.component"));
var _jsxRuntime = require("react/jsx-runtime");
function TwinroomOptionsContainer(_ref) {
  var {
    data,
    twinroomSelections,
    onTwinroomSelection,
    twinRoomPrices
  } = _ref;
  var {
    t
  } = (0, _nextI18next.useTranslation)(['common']);
  if (!data) {
    return null;
  }
  var rooms = [];
  rooms = data.map(room => {
    var twinrooms = [];
    if ((room === null || room === void 0 ? void 0 : room.roomType) === _api.ROOM_TYPE.TWIN) {
      twinrooms = room.rooms.map(roomInfo => {
        var specialRequests = roomInfo.specialRequests.toString();
        if (_api.twinRoomStandardSpecialRequests.includes(specialRequests)) {
          return _api.ROOM_TYPE.TWIN_DOUBLE_SOFA;
        } else if (_api.twinRoomImprovedSpecialRequests.includes(specialRequests)) {
          return _api.ROOM_TYPE.TWIN_TWO_BEDS;
        } else {
          return '';
        }
      });
    }
    return {
      roomType: getRoomTypeLabel(room === null || room === void 0 ? void 0 : room.roomType),
      adults: room.adults,
      children: room.children,
      twinroomTypes: twinrooms.filter(item => item !== undefined).length > 0 ? twinrooms : undefined
    };
  });
  return (0, _jsxRuntime.jsx)(_TwinroomOptions.default, {
    rooms: rooms,
    twinroomSelections: twinroomSelections,
    onTwinroomSelection: onTwinroomSelection,
    twinRoomPrices: twinRoomPrices
  });
  function getRoomTypeLabel(roomType) {
    switch (roomType) {
      case 'SB':
        return t('dashboard.bookings.singleRoom');
      case 'DB':
        return t('dashboard.bookings.doubleRoom');
      case 'FAM':
        return t('pihotelinfo.familyTitle');
      case 'TWIN':
        return t('pihotelinfo.chooseTwinRoom.title');
      default:
        return '';
    }
  }
}