"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = ExtrasItemComponent;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _react = require("@chakra-ui/react");
var _api = require("@whitbread-eos/api");
var _atoms = require("@whitbread-eos/atoms");
var _utils = require("@whitbread-eos/utils");
var _nextI18next = require("next-i18next");
var _image = _interopRequireDefault(require("next/image"));
var _jsxRuntime = require("react/jsx-runtime");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function ExtrasItemComponent(_ref) {
  var {
    currency,
    description,
    id,
    imageSrc,
    name,
    price,
    handleSelectedExtrasList,
    isRemovable,
    isAvailable,
    selectedExtrasList,
    selectedRoom,
    noNights,
    allRooms,
    numberOfRooms
  } = _ref;
  var {
    language
  } = (0, _utils.useCustomLocale)();
  var {
    t
  } = (0, _nextI18next.useTranslation)();
  var prefixDataTestId = "Extras-Item".concat(allRooms ? '-allRooms' : '');
  var allRoomsExtrasPrice = allRooms && price && numberOfRooms ? price * numberOfRooms : price;
  var formattedCurrency = (0, _utils.formatCurrency)(currency);
  var formattedPrice = (0, _utils.formatPrice)(formattedCurrency, allRoomsExtrasPrice === null || allRoomsExtrasPrice === void 0 ? void 0 : allRoomsExtrasPrice.toFixed(2), language);
  var formattedDayPrice = (0, _utils.formatPrice)(formattedCurrency, (price / noNights).toFixed(0));
  var formattedName = name === null || name === void 0 ? void 0 : name.replace(' ', '-');
  var isUnavailable = allRooms ? !isAvailable : !isRemovable && !isAvailable;
  var buttonType = isRemovable ? t('upsell.extras.remove') : t('upsell.extras.add');
  var buttonName = isUnavailable ? t('hoteldetails.rates.notavailable') : buttonType;
  var buttonVariant = isRemovable && !isUnavailable ? 'tertiary' : 'secondary';
  var extrasImage = (0, _utils.isStringValid)(imageSrc) ? (0, _utils.formatAssetsUrl)(imageSrc) : '';
  var unavailableColor = isUnavailable ? 'lightGrey1' : 'darkGrey1';
  return (0, _jsxRuntime.jsxs)(_react.Flex, _objectSpread(_objectSpread({}, containerStyle), {}, {
    "data-testid": (0, _utils.formatDataTestId)(prefixDataTestId, "Wrapper-".concat(formattedName)),
    children: [(0, _jsxRuntime.jsxs)(_react.Flex, _objectSpread(_objectSpread({}, wrapperImageTitle), {}, {
      children: [imageSrc && (0, _jsxRuntime.jsx)(_react.Box, _objectSpread(_objectSpread({}, containerImageStyle), {}, {
        "data-testid": (0, _utils.formatDataTestId)(prefixDataTestId, "Image-".concat(formattedName)),
        children: (0, _jsxRuntime.jsx)(_image.default, {
          style: {
            height: 48
          },
          width: 48,
          height: 48,
          alt: "Extras image",
          src: extrasImage
        })
      })), (0, _jsxRuntime.jsxs)(_react.Flex, _objectSpread(_objectSpread({}, containerDescriptionStyle), {}, {
        children: [(0, _jsxRuntime.jsx)(_react.Text, _objectSpread(_objectSpread({}, titleStyle), {}, {
          "data-testid": (0, _utils.formatDataTestId)(prefixDataTestId, "Title-".concat(formattedName)),
          children: name
        })), id === _api.ExtrasId.ULTIMATE_WIFI && (0, _jsxRuntime.jsxs)(_react.Text, _objectSpread(_objectSpread({}, subtitleStyle), {}, {
          "data-testid": (0, _utils.formatDataTestId)(prefixDataTestId, "Subtitle-".concat(formattedName)),
          children: [formattedDayPrice, " ", t('ancillaries.extras.perRoomPerDay')]
        })), (0, _jsxRuntime.jsx)(_react.Box, _objectSpread(_objectSpread({}, descriptionTextStyle), {}, {
          pt: "xs",
          "data-testid": (0, _utils.formatDataTestId)(prefixDataTestId, "Description-".concat(formattedName)),
          children: (0, _utils.renderSanitizedHtml)(description !== null && description !== void 0 ? description : '')
        }))]
      }))]
    })), (0, _jsxRuntime.jsxs)(_react.Flex, _objectSpread(_objectSpread({}, buttonAndPriceContainerStyle), {}, {
      children: [(0, _jsxRuntime.jsx)(_react.Text, _objectSpread(_objectSpread({}, _objectSpread(_objectSpread({}, priceTextStyle), {
        color: unavailableColor,
        mb: allRooms ? '0' : 'sm'
      })), {}, {
        "data-testid": (0, _utils.formatDataTestId)(prefixDataTestId, "Price-".concat(formattedName)),
        children: formattedPrice
      })), allRooms && (0, _jsxRuntime.jsx)(_react.Text, _objectSpread(_objectSpread({}, allRoomsTextStyle), {}, {
        "data-testid": (0, _utils.formatDataTestId)(prefixDataTestId, "TotalAllRoomsLabel-".concat(formattedName)),
        children: t('ancillaries.extras.total.text')
      })), handleSelectedExtrasList && (0, _jsxRuntime.jsx)(_atoms.Button, _objectSpread(_objectSpread({}, buttonAddStyle), {}, {
        variant: buttonVariant,
        "data-testid": (0, _utils.formatDataTestId)(prefixDataTestId, "ButtonAdd-".concat(formattedName)),
        onClick: () => handleSelectedExtrasList && handleSelectedExtrasList(allRooms ? (0, _utils.addOrRemoveAllRoomsExtras)(id, price, selectedExtrasList, isRemovable) : (0, _utils.addOrRemoveExtrasRoom)(id, price, selectedExtrasList, selectedRoom)),
        isDisabled: isUnavailable,
        children: buttonName
      }))]
    }))]
  }));
}
var containerDescriptionStyle = {
  pl: {
    mobile: 0,
    md: 'lg'
  },
  pr: {
    mobile: 0,
    md: '3xl',
    sm: 'xs'
  },
  flexDir: 'column',
  justifyContent: 'center'
};
var descriptionTextStyle = {
  fontSize: 'sm',
  lineHeight: '2',
  fontWeight: 'normal',
  color: 'darkGrey2'
};
var containerStyle = {
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: {
    mobile: 'column',
    sm: 'row'
  },
  border: '1px solid var(--chakra-colors-lightGrey3)',
  padding: 'md',
  mt: 'xl'
};
var priceTextStyle = {
  fontSize: 'md',
  lineHeight: '1.375rem',
  fontWeight: 'semibold'
};
var titleStyle = {
  fontSize: '1.25rem',
  lineHeight: '1.5rem',
  fontWeight: 'semibold',
  color: 'darkGrey1'
};
var subtitleStyle = _objectSpread(_objectSpread({}, titleStyle), {}, {
  fontSize: '0.875rem',
  lineHeight: '1.05rem'
});
var buttonAndPriceContainerStyle = {
  flexDirection: {
    mobile: 'row',
    sm: 'column'
  },
  alignItems: {
    mobile: 'center',
    sm: 'flex-end'
  },
  alignSelf: 'stretch',
  justifyContent: {
    mobile: 'space-between',
    sm: 'center'
  },
  mt: {
    mobile: '.5rem',
    sm: 'xs'
  },
  paddingLeft: {
    mobile: '1rem',
    sm: 0
  },
  paddingRight: {
    mobile: '1rem',
    sm: 0
  }
};
var buttonAddStyle = {
  width: '8rem',
  height: '2.5rem'
};
var wrapperImageTitle = {
  w: {
    mobile: '100%'
  },
  gap: 'lg',
  flex: '1 0 0',
  flexDirection: {
    mobile: 'column',
    sm: 'row'
  }
};
var containerImageStyle = {
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  w: {
    mobile: 'auto',
    sm: '6.5rem'
  },
  h: {
    mobile: '6rem',
    sm: '6.5rem'
  },
  backgroundColor: 'lightGrey5'
};
var allRoomsTextStyle = {
  fontSize: 'xxs',
  lineHeight: 'lg',
  mb: 'sm'
};