"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = BackToPage;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _react = require("@chakra-ui/react");
var _atoms = require("@whitbread-eos/atoms");
var _jsxRuntime = require("react/jsx-runtime");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function BackToPage(_ref) {
  var {
    goBack,
    linkText = '',
    hasNegativeMargin = false
  } = _ref;
  return (0, _jsxRuntime.jsx)(_react.Box, _objectSpread(_objectSpread({}, getBoxStyles(hasNegativeMargin)), {}, {
    "data-testid": "backToPage",
    children: (0, _jsxRuntime.jsxs)(_react.Flex, _objectSpread(_objectSpread({
      "data-testid": "backToPageContainer",
      onClick: handleClick
    }, backToPageContainerStyle), {}, {
      children: [(0, _jsxRuntime.jsx)(_react.Center, _objectSpread(_objectSpread({}, chevronLeftStyle), {}, {
        children: (0, _jsxRuntime.jsx)(_atoms.ChevronLeft, {})
      })), (0, _jsxRuntime.jsx)(_react.Text, _objectSpread(_objectSpread({}, backToPageTextStyle), {}, {
        children: linkText
      }))]
    }))
  }));
  function handleClick() {
    goBack();
  }
}
var getBoxStyles = hasNegativeMargin => {
  return {
    mt: hasNegativeMargin ? 'calc(var(--chakra-space-9xl) * -1)' : 'md',
    mb: 'md'
  };
};
var backToPageContainerStyle = {
  w: 'max-content',
  cursor: 'pointer',
  mw: '11.25rem',
  alignItems: 'flex-start'
};
var chevronLeftStyle = {
  mr: 'sm',
  h: '1.5rem',
  w: '1.5rem'
};
var backToPageTextStyle = {
  lineHeight: '3',
  fontWeight: 'semibold',
  fontSize: 'md'
};