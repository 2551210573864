"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = MapHotelCard;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _react = require("@chakra-ui/react");
var _api = require("@whitbread-eos/api");
var _atoms = require("@whitbread-eos/atoms");
var _molecules = require("@whitbread-eos/molecules");
var _utils = require("@whitbread-eos/utils");
var _router = require("next/router");
var _react2 = require("react");
var _constants = require("../constants");
var _utilities = require("../utilities");
var _jsxRuntime = require("react/jsx-runtime");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function MapHotelCard(_ref) {
  var _hotelInformation$lin, _hotelInformation$bra, _hotelInformation$thu, _hotelInformation$thu2, _hotelInformation$thu3, _hotelInformation$thu4, _partialTranslations$, _partialTranslations$2, _partialTranslations$3, _partialTranslations$4, _partialTranslations$5, _partialTranslations$6, _partialTranslations$7, _partialTranslations$8, _partialTranslations$9, _partialTranslations$10, _partialTranslations$11, _partialTranslations$12, _partialTranslations$13, _partialTranslations$14, _hotelInformation$hot, _partialTranslations$15, _partialTranslations$16, _partialTranslations$17, _partialTranslations$18, _partialTranslations$19, _partialTranslations$20, _hotelAvailability$di, _hotelAvailability$di2, _hotelAvailability$un, _hotelInformation$hot2, _hotelInformation$mes;
  var {
    data,
    partialTranslations,
    multiSearchParams,
    locale,
    variant
  } = _ref;
  var router = (0, _router.useRouter)();
  var {
    language,
    country
  } = (0, _utils.useCustomLocale)();
  var {
    reservationId
  } = router.query;
  var [, setDistanceFromSearch] = (0, _utils.useLocalStorage)('DistanceFromSearch', _molecules.DISTANCE_FROM_SEARCH_INITIAL_VALUE);
  var [, setSearchReferrer] = (0, _utils.useLocalStorage)('SearchReferrer', _molecules.SEARCH_REFERRER_INITIAL_VALUE);
  var hotelInformation = data === null || data === void 0 ? void 0 : data.hotelInformation;
  var hotelAvailability = data === null || data === void 0 ? void 0 : data.hotelAvailability;
  var hotelSlug = hotelInformation === null || hotelInformation === void 0 || (_hotelInformation$lin = hotelInformation.links) === null || _hotelInformation$lin === void 0 ? void 0 : _hotelInformation$lin.detailsPage;
  var hotelBrand = (_hotelInformation$bra = hotelInformation === null || hotelInformation === void 0 ? void 0 : hotelInformation.brand) !== null && _hotelInformation$bra !== void 0 ? _hotelInformation$bra : '';
  var queryParamsUrl = (0, _utilities.getSearchQueryUrl)(router, _constants.IGNORED_KEYS_FOR_HDP_URL, _utilities.formatDateSearchQueryUrl);
  var hotelPageUrl = "/".concat(country, "/").concat(language).concat(variant === 'bb' ? '/business-booker' : '', "/hotels").concat(hotelSlug, ".html?").concat(queryParamsUrl).concat(reservationId && variant === _constants.APP_VARIANT.CCUI ? "&reservationId=".concat(reservationId) : '', "&BRAND=").concat(hotelBrand);
  var imageURLHotelExterior = "".concat((_hotelInformation$thu = hotelInformation === null || hotelInformation === void 0 || (_hotelInformation$thu2 = hotelInformation.thumbnailImages) === null || _hotelInformation$thu2 === void 0 || (_hotelInformation$thu2 = _hotelInformation$thu2[0]) === null || _hotelInformation$thu2 === void 0 ? void 0 : _hotelInformation$thu2.imageSrc) !== null && _hotelInformation$thu !== void 0 ? _hotelInformation$thu : '/');
  var imageAltText = "".concat(data === null || data === void 0 ? void 0 : data.name, " ").concat((_hotelInformation$thu3 = hotelInformation === null || hotelInformation === void 0 || (_hotelInformation$thu4 = hotelInformation.thumbnailImages) === null || _hotelInformation$thu4 === void 0 || (_hotelInformation$thu4 = _hotelInformation$thu4[0]) === null || _hotelInformation$thu4 === void 0 ? void 0 : _hotelInformation$thu4.tags[0]) !== null && _hotelInformation$thu3 !== void 0 ? _hotelInformation$thu3 : 'exterior');
  var baseDataTestId = 'SRPMapView';
  var hotelBrandLogos = {
    hubLogo: (_partialTranslations$ = partialTranslations === null || partialTranslations === void 0 || (_partialTranslations$2 = partialTranslations.searchInformation) === null || _partialTranslations$2 === void 0 || (_partialTranslations$2 = _partialTranslations$2.content) === null || _partialTranslations$2 === void 0 || (_partialTranslations$2 = _partialTranslations$2.global) === null || _partialTranslations$2 === void 0 || (_partialTranslations$2 = _partialTranslations$2.brand) === null || _partialTranslations$2 === void 0 ? void 0 : _partialTranslations$2.hubBadge) !== null && _partialTranslations$ !== void 0 ? _partialTranslations$ : '',
    zipLogo: (_partialTranslations$3 = partialTranslations === null || partialTranslations === void 0 || (_partialTranslations$4 = partialTranslations.searchInformation) === null || _partialTranslations$4 === void 0 || (_partialTranslations$4 = _partialTranslations$4.content) === null || _partialTranslations$4 === void 0 || (_partialTranslations$4 = _partialTranslations$4.global) === null || _partialTranslations$4 === void 0 || (_partialTranslations$4 = _partialTranslations$4.brand) === null || _partialTranslations$4 === void 0 ? void 0 : _partialTranslations$4.zipBadge) !== null && _partialTranslations$3 !== void 0 ? _partialTranslations$3 : ''
  };
  var soldOut = !(hotelAvailability !== null && hotelAvailability !== void 0 && hotelAvailability.available);
  var hotelLimitedAvailability = hotelAvailability === null || hotelAvailability === void 0 ? void 0 : hotelAvailability.limitedAvailability;
  var hotelDistanceLabels = {
    distanceUnitPlural: (_partialTranslations$5 = partialTranslations === null || partialTranslations === void 0 || (_partialTranslations$6 = partialTranslations.searchInformation) === null || _partialTranslations$6 === void 0 || (_partialTranslations$6 = _partialTranslations$6.content) === null || _partialTranslations$6 === void 0 || (_partialTranslations$6 = _partialTranslations$6.results) === null || _partialTranslations$6 === void 0 || (_partialTranslations$6 = _partialTranslations$6.result) === null || _partialTranslations$6 === void 0 ? void 0 : _partialTranslations$6.distanceUnitPlural) !== null && _partialTranslations$5 !== void 0 ? _partialTranslations$5 : '',
    fromLocation: (_partialTranslations$7 = partialTranslations === null || partialTranslations === void 0 || (_partialTranslations$8 = partialTranslations.searchInformation) === null || _partialTranslations$8 === void 0 || (_partialTranslations$8 = _partialTranslations$8.content) === null || _partialTranslations$8 === void 0 || (_partialTranslations$8 = _partialTranslations$8.results) === null || _partialTranslations$8 === void 0 || (_partialTranslations$8 = _partialTranslations$8.result) === null || _partialTranslations$8 === void 0 ? void 0 : _partialTranslations$8.fromLocation) !== null && _partialTranslations$7 !== void 0 ? _partialTranslations$7 : ''
  };
  var hotelBadgesLabels = {
    premierPlus: (_partialTranslations$9 = partialTranslations === null || partialTranslations === void 0 || (_partialTranslations$10 = partialTranslations.searchInformation) === null || _partialTranslations$10 === void 0 || (_partialTranslations$10 = _partialTranslations$10.content) === null || _partialTranslations$10 === void 0 || (_partialTranslations$10 = _partialTranslations$10.results) === null || _partialTranslations$10 === void 0 || (_partialTranslations$10 = _partialTranslations$10.result) === null || _partialTranslations$10 === void 0 || (_partialTranslations$10 = _partialTranslations$10.facilities) === null || _partialTranslations$10 === void 0 ? void 0 : _partialTranslations$10.premierPlusRoom) !== null && _partialTranslations$9 !== void 0 ? _partialTranslations$9 : '',
    openingSoon: (_partialTranslations$11 = partialTranslations === null || partialTranslations === void 0 || (_partialTranslations$12 = partialTranslations.searchInformation) === null || _partialTranslations$12 === void 0 || (_partialTranslations$12 = _partialTranslations$12.content) === null || _partialTranslations$12 === void 0 || (_partialTranslations$12 = _partialTranslations$12.results) === null || _partialTranslations$12 === void 0 || (_partialTranslations$12 = _partialTranslations$12.result) === null || _partialTranslations$12 === void 0 ? void 0 : _partialTranslations$12.openingSoon) !== null && _partialTranslations$11 !== void 0 ? _partialTranslations$11 : ''
  };
  var hotelLastFewRoomsLabel = (_partialTranslations$13 = partialTranslations === null || partialTranslations === void 0 || (_partialTranslations$14 = partialTranslations.searchInformation) === null || _partialTranslations$14 === void 0 || (_partialTranslations$14 = _partialTranslations$14.content) === null || _partialTranslations$14 === void 0 || (_partialTranslations$14 = _partialTranslations$14.results) === null || _partialTranslations$14 === void 0 || (_partialTranslations$14 = _partialTranslations$14.result) === null || _partialTranslations$14 === void 0 ? void 0 : _partialTranslations$14.availabilityWarning) !== null && _partialTranslations$13 !== void 0 ? _partialTranslations$13 : '';
  var lowestRoomRate = hotelAvailability === null || hotelAvailability === void 0 ? void 0 : hotelAvailability.lowestRoomRate;
  var hotelOpeningDate = (_hotelInformation$hot = hotelInformation === null || hotelInformation === void 0 ? void 0 : hotelInformation.hotelOpeningDate) !== null && _hotelInformation$hot !== void 0 ? _hotelInformation$hot : '';
  var isHotelOpeningSoon = (0, _react2.useMemo)(() => (0, _utilities.hotelOpensSoon)(hotelOpeningDate, multiSearchParams), [hotelOpeningDate, multiSearchParams]);
  var cellCodesArr = multiSearchParams === null || multiSearchParams === void 0 ? void 0 : multiSearchParams.cellCodes;
  var isCellCodeMatched = cellCodesArr === null || cellCodesArr === void 0 ? void 0 : cellCodesArr.some(item => {
    var _data$hotelAvailabili;
    return item === (data === null || data === void 0 || (_data$hotelAvailabili = data.hotelAvailability) === null || _data$hotelAvailabili === void 0 ? void 0 : _data$hotelAvailabili.cellCode);
  });
  var isDiscountApplied = variant === _api.Area.PI && isCellCodeMatched && (hotelAvailability === null || hotelAvailability === void 0 ? void 0 : hotelAvailability.available) && !isHotelOpeningSoon;
  var hotelSoldOutLabel = (_partialTranslations$15 = partialTranslations === null || partialTranslations === void 0 || (_partialTranslations$16 = partialTranslations.searchInformation) === null || _partialTranslations$16 === void 0 || (_partialTranslations$16 = _partialTranslations$16.content) === null || _partialTranslations$16 === void 0 || (_partialTranslations$16 = _partialTranslations$16.results) === null || _partialTranslations$16 === void 0 || (_partialTranslations$16 = _partialTranslations$16.result) === null || _partialTranslations$16 === void 0 ? void 0 : _partialTranslations$16.fullyBooked) !== null && _partialTranslations$15 !== void 0 ? _partialTranslations$15 : '';
  var lowestRoomLabels = {
    priceFrom: (_partialTranslations$17 = partialTranslations === null || partialTranslations === void 0 || (_partialTranslations$18 = partialTranslations.searchInformation) === null || _partialTranslations$18 === void 0 || (_partialTranslations$18 = _partialTranslations$18.content) === null || _partialTranslations$18 === void 0 || (_partialTranslations$18 = _partialTranslations$18.results) === null || _partialTranslations$18 === void 0 || (_partialTranslations$18 = _partialTranslations$18.result) === null || _partialTranslations$18 === void 0 ? void 0 : _partialTranslations$18.priceFrom) !== null && _partialTranslations$17 !== void 0 ? _partialTranslations$17 : ''
  };
  var hotelOfferLabel = (_partialTranslations$19 = partialTranslations === null || partialTranslations === void 0 || (_partialTranslations$20 = partialTranslations.searchInformation) === null || _partialTranslations$20 === void 0 || (_partialTranslations$20 = _partialTranslations$20.content) === null || _partialTranslations$20 === void 0 || (_partialTranslations$20 = _partialTranslations$20.results) === null || _partialTranslations$20 === void 0 || (_partialTranslations$20 = _partialTranslations$20.result) === null || _partialTranslations$20 === void 0 || (_partialTranslations$20 = _partialTranslations$20.promotions) === null || _partialTranslations$20 === void 0 ? void 0 : _partialTranslations$20.discountApplied) !== null && _partialTranslations$19 !== void 0 ? _partialTranslations$19 : '';
  var distance = (_hotelAvailability$di = hotelAvailability === null || hotelAvailability === void 0 ? void 0 : hotelAvailability.distance) !== null && _hotelAvailability$di !== void 0 ? _hotelAvailability$di : 0;
  var srchReferrer = router === null || router === void 0 ? void 0 : router.asPath;
  return (0, _jsxRuntime.jsx)(_react.Link, _objectSpread(_objectSpread({
    href: hotelPageUrl
  }, linkStyles), {}, {
    onClick: () => handleCardClick(distance, srchReferrer),
    children: (0, _jsxRuntime.jsx)(_react.Box, _objectSpread(_objectSpread({}, wrapperStyles), {}, {
      children: (0, _jsxRuntime.jsx)(_atoms.Card, {
        padding: "sm",
        borderRadius: "base",
        border: "0",
        children: (0, _jsxRuntime.jsxs)(_react.Flex, {
          direction: "column",
          children: [(0, _jsxRuntime.jsxs)(_react.Flex, {
            direction: "row",
            children: [(0, _jsxRuntime.jsx)(_molecules.SRHotelThumbnail, {
              imageData: {
                imageSrc: imageURLHotelExterior,
                imageAlt: imageAltText
              },
              brand: hotelBrand,
              brandLogos: hotelBrandLogos,
              testId: (0, _utils.formatDataTestId)(baseDataTestId, 'hotel-thumbnail'),
              styles: thumbnailStyles
            }), (0, _jsxRuntime.jsxs)(_react.Flex, {
              direction: "column",
              children: [(0, _jsxRuntime.jsx)(_molecules.SRHotelTitle, {
                styles: hotelTitleStyles,
                title: data.name,
                testId: (0, _utils.formatDataTestId)(baseDataTestId, 'hotel-title')
              }), (0, _jsxRuntime.jsx)(_molecules.SRHotelDistance, {
                distance: (_hotelAvailability$di2 = hotelAvailability === null || hotelAvailability === void 0 ? void 0 : hotelAvailability.distance) !== null && _hotelAvailability$di2 !== void 0 ? _hotelAvailability$di2 : 0,
                unit: (_hotelAvailability$un = hotelAvailability === null || hotelAvailability === void 0 ? void 0 : hotelAvailability.unit) !== null && _hotelAvailability$un !== void 0 ? _hotelAvailability$un : '',
                labels: hotelDistanceLabels,
                testId: (0, _utils.formatDataTestId)(baseDataTestId, 'hotel-distance'),
                styles: hotelDistanceStyles
              })]
            })]
          }), variant === _constants.APP_VARIANT.CCUI && (0, _jsxRuntime.jsx)(_molecules.SRHotelBadges, {
            testId: (0, _utils.formatDataTestId)(baseDataTestId, 'hotel-badge'),
            styles: hotelBadgeStyles
          }), (0, _jsxRuntime.jsx)(_molecules.SRHotelBadges, {
            hotelOpeningDate: hotelOpeningDate,
            labels: hotelBadgesLabels,
            testId: (0, _utils.formatDataTestId)(baseDataTestId, 'hotel-badges'),
            isHotelOpeningSoon: isHotelOpeningSoon,
            hotelFacilities: (_hotelInformation$hot2 = hotelInformation === null || hotelInformation === void 0 ? void 0 : hotelInformation.hotelFacilities) !== null && _hotelInformation$hot2 !== void 0 ? _hotelInformation$hot2 : [],
            isColumnDisplay: variant === _constants.APP_VARIANT.CCUI,
            styles: hotelBadgesStyles,
            messagingFlag: (_hotelInformation$mes = hotelInformation === null || hotelInformation === void 0 ? void 0 : hotelInformation.messagingFlag) !== null && _hotelInformation$mes !== void 0 ? _hotelInformation$mes : {}
          }), (0, _jsxRuntime.jsx)(_react.Box, {
            border: "1px solid var(--chakra-colors-lightGrey4)"
          }), (0, _jsxRuntime.jsxs)(_react.Flex, _objectSpread(_objectSpread({
            direction: "row",
            justifyContent: "space-between",
            alignItems: "center"
          }, bottomCardStyles), {}, {
            children: [(0, _jsxRuntime.jsxs)(_react.Box, _objectSpread(_objectSpread({
              justifyContent: "flex-start",
              alignItems: "baseline"
            }, hotelLabelsStyles), {}, {
              children: [isDiscountApplied && (0, _jsxRuntime.jsx)(_molecules.SRHotelDiscountApplied, {
                label: hotelOfferLabel,
                testId: (0, _utils.formatDataTestId)(baseDataTestId, 'hotel-offer-discount')
              }), hotelLimitedAvailability && (0, _jsxRuntime.jsx)(_molecules.SRHotelLastFewRooms, {
                label: hotelLastFewRoomsLabel,
                testId: (0, _utils.formatDataTestId)(baseDataTestId, 'hotel-last-few-rooms'),
                styles: hotelLastFewRoomsStyles
              }), soldOut && !hotelLimitedAvailability && (0, _jsxRuntime.jsx)(_molecules.SRHotelSoldOut, {
                label: hotelSoldOutLabel,
                testId: (0, _utils.formatDataTestId)(baseDataTestId, 'hotel-soldout')
              })]
            })), (0, _jsxRuntime.jsxs)(_react.Flex, {
              justifyContent: "flex-end",
              alignItems: "center",
              children: [lowestRoomRate && !isHotelOpeningSoon && (0, _jsxRuntime.jsx)(_molecules.SRHotelLowestRate, {
                data: lowestRoomRate,
                locale: locale,
                labels: lowestRoomLabels,
                testId: (0, _utils.formatDataTestId)(baseDataTestId, 'lowest-rate'),
                styles: hotelLowestRateStyles
              }), (0, _jsxRuntime.jsx)(_react.Box, {
                children: (0, _jsxRuntime.jsx)(_atoms.Button, _objectSpread(_objectSpread({
                  size: "xxs",
                  variant: "circle"
                }, buttonStyles), {}, {
                  children: (0, _jsxRuntime.jsx)(_atoms.Icon, {
                    svg: (0, _jsxRuntime.jsx)(_atoms.ChevronRight, {
                      color: "var(--chakra-colors-baseWhite)"
                    })
                  })
                }))
              })]
            })]
          }))]
        })
      })
    }))
  }));
  function handleCardClick(distance, srchReferrer) {
    var distanceFromSearch = {
      data: {
        distance: distance
      }
    };
    setDistanceFromSearch(distanceFromSearch);
    var referrer = {
      data: {
        referrer: srchReferrer
      }
    };
    setSearchReferrer(referrer);
  }
}
var wrapperStyles = {
  m: 'auto',
  w: '18.75rem',
  h: 'auto',
  cursor: 'pointer'
};
var thumbnailStyles = {
  w: '6.813rem',
  h: '4.625rem',
  mr: 'sm',
  mb: 'sm'
};
var hotelTitleStyles = {
  w: {
    mobile: '9.813rem',
    md: '10.5rem'
  },
  fontSize: 'sm',
  fontWeight: 'semibold'
};
var hotelDistanceStyles = {
  w: {
    mobile: '8rem',
    md: '10.5rem'
  },
  fontSize: 'sm',
  mt: 'xs',
  fontWeight: 'normal'
};
var bottomCardStyles = {
  paddingRight: {
    mobile: 'sm',
    md: '0'
  },
  mt: 'sm'
};
var hotelLabelsStyles = {
  left: '0'
};
var hotelLowestRateStyles = {
  containerStyles: {
    p: '0'
  },
  labelStyles: {
    fontSize: 'sm',
    alignSelf: 'center',
    mr: 'sm',
    fontWeight: 'normal'
  },
  priceStyles: {
    fontSize: 'md',
    fontWeight: 'bold',
    alignSelf: 'center'
  }
};
var buttonStyles = {
  bgColor: 'primary',
  ml: 'sm',
  alignSelf: 'center',
  _hover: {
    bg: 'var(--chakra-colors-btnPrimaryHoverBg) radial-gradient(circle, transparent 1%, var(--chakra-colors-btnPrimaryHoverBg) 1%) center/15000%',
    boxShadow: '0 4px 8px var(--chakra-colors-lightGrey1)',
    _disabled: {
      bg: 'lightGrey3'
    }
  }
};
var linkStyles = {
  cursor: 'default',
  _hover: {
    textDecoration: 'none'
  },
  _focus: {
    textDecoration: 'none',
    boxShadow: 'none'
  },
  _visited: {
    textDecoration: 'none'
  },
  _link: {
    textDecoration: 'none'
  },
  _active: {
    textDecoration: 'none'
  }
};
var hotelLastFewRoomsStyles = {
  mr: 'sm'
};
var hotelBadgesStyles = {
  mb: 'sm'
};
var hotelBadgeStyles = {
  mb: 'sm'
};