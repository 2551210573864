"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.HDPUrlToRedirectFormatter = HDPUrlToRedirectFormatter;
exports.getHotelSlugById = getHotelSlugById;
exports.getSearchRedirectURL = getSearchRedirectURL;
exports.isHotelAvailable = isHotelAvailable;
exports.isHotelOpeningSoon = isHotelOpeningSoon;
exports.isOperaHotelAvailable = isOperaHotelAvailable;
exports.mapMultiSearchRoomsToSearchRooms = mapMultiSearchRoomsToSearchRooms;
exports.mapMultiSearchToQueryParams = mapMultiSearchToQueryParams;
exports.singleHotelSearchBB = singleHotelSearchBB;
exports.singleHotelSearchCCUI = singleHotelSearchCCUI;
exports.singleHotelSearchPI = singleHotelSearchPI;
var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));
var _api = require("@whitbread-eos/api");
var _molecules = require("@whitbread-eos/molecules");
var _utils = require("@whitbread-eos/utils");
var _add = _interopRequireDefault(require("date-fns/add"));
var _differenceInDays = _interopRequireDefault(require("date-fns/differenceInDays"));
var _format = _interopRequireDefault(require("date-fns/format"));
var DEFAULT_SORT_VALUE = '1';
function singleHotelSearchCCUI(_x, _x2, _x3, _x4, _x5, _x6, _x7, _x8) {
  return _singleHotelSearchCCUI.apply(this, arguments);
}
function _singleHotelSearchCCUI() {
  _singleHotelSearchCCUI = (0, _asyncToGenerator2.default)(function* (stayDetailsState, paramsMappedForURL, labels, URLToRedirect, queryParams, country, language, channel) {
    var {
      latitude,
      longitude
    } = stayDetailsState.data.info.suggestion.location;
    var location = "".concat(latitude, ",").concat(longitude);
    var {
      listView
    } = _molecules.VIEW_TYPE_CONSTANTS;
    var redirectToSrpLink = "/".concat(country, "/").concat(language, "/search.html?searchModel.searchTerm=").concat(paramsMappedForURL.searchTerm, "&LOCATION=").concat(location, "&").concat(URLToRedirect, "&BOOKINGCHANNEL=").concat(labels.bookingChannel, "&SORT=").concat(DEFAULT_SORT_VALUE, "&VIEW=").concat(listView);
    var hotelId = queryParams.code;
    if (!hotelId) {
      return "/".concat(country, "/").concat(language, "/search.html?searchModel.searchTerm=").concat(paramsMappedForURL.searchTerm, "&PLACEID=").concat(queryParams.placeId, "&").concat(URLToRedirect, "&BOOKINGCHANNEL=").concat(labels.bookingChannel, "&SORT=").concat(DEFAULT_SORT_VALUE, "&VIEW=").concat(listView);
    }
    var {
      isAvailable,
      isOpeningSoon
    } = yield isHotelAvailable({
      ARRyyyy: paramsMappedForURL.ARRyyyy,
      ARRmm: paramsMappedForURL.ARRmm,
      ARRdd: paramsMappedForURL.ARRdd,
      nights: paramsMappedForURL.nights,
      rooms: paramsMappedForURL.rooms,
      code: paramsMappedForURL.code
    }, country, language, labels.mappedRoomLabels, channel);
    URLToRedirect += "&BRAND=".concat(paramsMappedForURL.brand);
    return yield getSearchRedirectURL({
      isAvailable,
      isOpeningSoon,
      redirectToSrpLink,
      queryParams,
      country,
      language,
      URLToRedirect
    });
  });
  return _singleHotelSearchCCUI.apply(this, arguments);
}
function singleHotelSearchPI(_x9, _x10, _x11, _x12, _x13, _x14, _x15, _x16) {
  return _singleHotelSearchPI.apply(this, arguments);
}
function _singleHotelSearchPI() {
  _singleHotelSearchPI = (0, _asyncToGenerator2.default)(function* (stayDetailsState, paramsMappedForURL, labels, URLToRedirect, queryParams, country, language, channel) {
    var {
      latitude,
      longitude
    } = stayDetailsState.data.info.suggestion.location;
    var location = "".concat(latitude, ",").concat(longitude);
    var {
      listView
    } = _molecules.VIEW_TYPE_CONSTANTS;
    var redirectToSrpLink = "/".concat(country, "/").concat(language, "/search.html?searchModel.searchTerm=").concat(paramsMappedForURL.searchTerm, "&LOCATION=").concat(location, "&").concat(URLToRedirect, "&BOOKINGCHANNEL=").concat(labels.bookingChannel, "&SORT=").concat(DEFAULT_SORT_VALUE, "&VIEW=").concat(listView);
    var hotelId = queryParams.code;
    if (!hotelId) {
      return "/".concat(country, "/").concat(language, "/search.html?searchModel.searchTerm=").concat(paramsMappedForURL.searchTerm, "&PLACEID=").concat(queryParams.placeId, "&").concat(URLToRedirect, "&BOOKINGCHANNEL=").concat(labels.bookingChannel, "&SORT=").concat(DEFAULT_SORT_VALUE, "&VIEW=").concat(listView);
    }
    var {
      isAvailable,
      isOpeningSoon
    } = yield isHotelAvailable({
      ARRyyyy: paramsMappedForURL.ARRyyyy,
      ARRmm: paramsMappedForURL.ARRmm,
      ARRdd: paramsMappedForURL.ARRdd,
      nights: paramsMappedForURL.nights,
      rooms: paramsMappedForURL.rooms,
      code: paramsMappedForURL.code
    }, country, language, labels.mappedRoomLabels, channel);
    return yield getSearchRedirectURL({
      isAvailable,
      isOpeningSoon,
      redirectToSrpLink,
      queryParams,
      country,
      language,
      URLToRedirect
    });
  });
  return _singleHotelSearchPI.apply(this, arguments);
}
function singleHotelSearchBB(_x17, _x18, _x19, _x20, _x21, _x22, _x23, _x24, _x25, _x26) {
  return _singleHotelSearchBB.apply(this, arguments);
}
function _singleHotelSearchBB() {
  _singleHotelSearchBB = (0, _asyncToGenerator2.default)(function* (stayDetailsState, paramsMappedForURL, labels, URLToRedirect, queryParams, country, language, channel, variant, token) {
    var {
      latitude,
      longitude
    } = stayDetailsState.data.info.suggestion.location;
    var location = "".concat(latitude, ",").concat(longitude);
    var {
      listView
    } = _molecules.VIEW_TYPE_CONSTANTS;
    var redirectToSrpLink = "/".concat(country, "/").concat(language).concat(variant === 'bb' ? '/business-booker' : '', "/search.html?searchModel.searchTerm=").concat(paramsMappedForURL.searchTerm, "&LOCATION=").concat(location, "&").concat(URLToRedirect, "&BOOKINGCHANNEL=").concat(labels.bookingChannel, "&SORT=").concat(DEFAULT_SORT_VALUE, "&VIEW=").concat(listView);
    var hotelId = queryParams.code;
    if (!hotelId) {
      return "/".concat(country, "/").concat(language).concat(variant === 'bb' ? '/business-booker' : '', "/search.html?searchModel.searchTerm=").concat(paramsMappedForURL.searchTerm, "&PLACEID=").concat(queryParams.placeId, "&").concat(URLToRedirect, "&BOOKINGCHANNEL=").concat(labels.bookingChannel, "&SORT=").concat(DEFAULT_SORT_VALUE, "&VIEW=").concat(listView);
    }
    var {
      isAvailable,
      isOpeningSoon
    } = yield isHotelAvailable({
      ARRyyyy: paramsMappedForURL.ARRyyyy,
      ARRmm: paramsMappedForURL.ARRmm,
      ARRdd: paramsMappedForURL.ARRdd,
      nights: paramsMappedForURL.nights,
      rooms: paramsMappedForURL.rooms,
      code: paramsMappedForURL.code
    }, country, language, labels.mappedRoomLabels, channel, token);
    return yield getSearchRedirectURL({
      isAvailable,
      isOpeningSoon,
      redirectToSrpLink,
      queryParams,
      country,
      language,
      URLToRedirect,
      variant
    });
  });
  return _singleHotelSearchBB.apply(this, arguments);
}
function isHotelAvailable(_x27, _x28, _x29, _x30, _x31, _x32) {
  return _isHotelAvailable.apply(this, arguments);
}
function _isHotelAvailable() {
  _isHotelAvailable = (0, _asyncToGenerator2.default)(function* (_ref, country, language, mappedRoomLabels, channel, token) {
    var {
      ARRyyyy,
      ARRmm,
      ARRdd,
      nights,
      rooms,
      code
    } = _ref;
    if (!code) {
      return {
        isOpeningSoon: false,
        isAvailable: true
      };
    }
    var isAvailable = false;
    try {
      var hotelAvailabilityOpera = yield isOperaHotelAvailable({
        ARRyyyy,
        ARRmm,
        ARRdd,
        nights,
        rooms,
        code
      }, language, country, mappedRoomLabels, channel, token);
      isAvailable = hotelAvailabilityOpera;
    } catch (e) {
      console.log(e);
    }
    var isOpeningSoon = yield isHotelOpeningSoon({
      ARRdd,
      ARRmm,
      ARRyyyy
    }, code, country, language);
    return {
      isOpeningSoon,
      isAvailable
    };
  });
  return _isHotelAvailable.apply(this, arguments);
}
function HDPUrlToRedirectFormatter(URLToRedirect) {
  if (!URLToRedirect || URLToRedirect.indexOf('&') === -1) {
    return URLToRedirect;
  }
  var checkNoOfPairs = URLToRedirect.match(/&/g) || [];
  var checkNoOfParams = URLToRedirect.match(/=/g) || [];
  if ((checkNoOfPairs === null || checkNoOfPairs === void 0 ? void 0 : checkNoOfPairs.length) === 0 || (checkNoOfParams === null || checkNoOfParams === void 0 ? void 0 : checkNoOfParams.length) !== (checkNoOfPairs === null || checkNoOfPairs === void 0 ? void 0 : checkNoOfPairs.length) + 1) {
    return URLToRedirect;
  }
  return URLToRedirect.split('&').map(kvPair => {
    if (kvPair.indexOf('ARRdd') > -1 || kvPair.indexOf('ARRmm') > -1) {
      var [key, value] = kvPair.split('=');
      return "".concat(key, "=").concat(('0' + value).slice(-2));
    }
    return kvPair;
  }).join('&');
}
function getSearchRedirectURL(_x33) {
  return _getSearchRedirectURL.apply(this, arguments);
}
function _getSearchRedirectURL() {
  _getSearchRedirectURL = (0, _asyncToGenerator2.default)(function* (_ref2) {
    var {
      isAvailable,
      isOpeningSoon,
      redirectToSrpLink,
      queryParams,
      country,
      language,
      URLToRedirect,
      variant
    } = _ref2;
    if (!isAvailable || isOpeningSoon) {
      return redirectToSrpLink;
    }
    var hotelSlug = yield getHotelSlugById(queryParams.code, country, language);
    if (hotelSlug) {
      return "/".concat(country, "/").concat(language).concat(variant === 'bb' ? '/business-booker' : '', "/hotels").concat(hotelSlug, ".html?").concat(HDPUrlToRedirectFormatter(URLToRedirect));
    }
  });
  return _getSearchRedirectURL.apply(this, arguments);
}
function isOperaHotelAvailable(_x34, _x35, _x36, _x37, _x38, _x39) {
  return _isOperaHotelAvailable.apply(this, arguments);
}
function _isOperaHotelAvailable() {
  _isOperaHotelAvailable = (0, _asyncToGenerator2.default)(function* (_ref3, language, country, mappedRoomLabels, bookingChannel, token) {
    var {
      ARRyyyy,
      ARRmm,
      ARRdd,
      nights,
      rooms,
      code
    } = _ref3;
    var startDate = new Date(Number(ARRyyyy), Number(ARRmm && ARRmm - 1), Number(ARRdd));
    var endDate = (0, _add.default)(startDate, {
      days: Number(nights)
    });
    var availabilityQueryParams = {
      hotelId: code,
      arrival: (0, _format.default)(startDate, 'yyyy-MM-dd'),
      departure: (0, _format.default)(endDate, 'yyyy-MM-dd'),
      brand: _api.Area.PI,
      country: country,
      rooms: rooms.map(room => ({
        adultsNumber: room.adults,
        childrenNumber: room.children,
        cotRequired: room.shouldIncludeCot,
        roomType: mappedRoomLabels[room.roomType]
      })),
      language: language,
      bookingChannel: {
        channel: bookingChannel,
        language: language === null || language === void 0 ? void 0 : language.toUpperCase(),
        subchannel: 'WEB'
      },
      channel: bookingChannel,
      promotionCode: (0, _utils.getCookie)(_api.PROMO_CODE_COOKIE)
    };
    var hotelAvailabilityResponse = yield (0, _utils.graphQLRequest)(_api.HOTEL_AVAILABILITY_QUERY, availabilityQueryParams, token);
    var isHotelAvailable = hotelAvailabilityResponse.hotelAvailability.roomRates.length > 0 && hotelAvailabilityResponse.hotelAvailability.available;
    return isHotelAvailable;
  });
  return _isOperaHotelAvailable.apply(this, arguments);
}
function isHotelOpeningSoon(_x40, _x41, _x42, _x43) {
  return _isHotelOpeningSoon.apply(this, arguments);
}
function _isHotelOpeningSoon() {
  _isHotelOpeningSoon = (0, _asyncToGenerator2.default)(function* (_ref4, hotelId, country, language) {
    var {
      ARRdd,
      ARRmm,
      ARRyyyy
    } = _ref4;
    var hotelResponse = yield (0, _utils.graphQLRequest)(_api.GET_HOTEL_INFORMATION, {
      hotelId,
      country,
      language
    });
    var hotelOpeningDate = hotelResponse.hotelInformation.hotelOpeningDate;
    var selectedDate = new Date("".concat(ARRmm, "/\n      ").concat(ARRdd, "/\n      ").concat(ARRyyyy));
    var openingSoonDate = hotelOpeningDate !== '' && new Date(JSON.parse(JSON.stringify(hotelOpeningDate)));
    var isOpeningSoon = openingSoonDate && (0, _differenceInDays.default)(openingSoonDate, selectedDate) > 0;
    return isOpeningSoon;
  });
  return _isHotelOpeningSoon.apply(this, arguments);
}
function getHotelSlugById(_x44, _x45, _x46) {
  return _getHotelSlugById.apply(this, arguments);
}
function _getHotelSlugById() {
  _getHotelSlugById = (0, _asyncToGenerator2.default)(function* (hotelId, country, language) {
    var hotelSlugByIdResponse = yield (0, _utils.graphQLRequest)(_api.GET_HOTEL_INFORMATION, {
      hotelId,
      country,
      language
    });
    return hotelSlugByIdResponse.hotelInformation.links.detailsPage;
  });
  return _getHotelSlugById.apply(this, arguments);
}
function mapMultiSearchToQueryParams(multiSearchParams) {
  return {
    ARRdd: multiSearchParams.arrivalDay,
    ARRmm: multiSearchParams.arrivalMonth,
    ARRyyyy: multiSearchParams.arrivalYear,
    searchTerm: multiSearchParams.location,
    nights: multiSearchParams.numberOfNights,
    rooms: mapMultiSearchRoomsToSearchRooms(multiSearchParams.rooms),
    roomsNumber: multiSearchParams.rooms.length,
    code: multiSearchParams.code
  };
}
function mapMultiSearchRoomsToSearchRooms(rooms) {
  return rooms.map(room => {
    return {
      adults: room.adultsNumber,
      children: room.childrenNumber,
      roomType: room.type,
      shouldIncludeCot: false
    };
  });
}