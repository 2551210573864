"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.BOOKING_TYPE = void 0;
var BOOKING_TYPE = exports.BOOKING_TYPE = function (BOOKING_TYPE) {
  BOOKING_TYPE["PAST"] = "Past";
  BOOKING_TYPE["CANCELLED"] = "Cancelled";
  BOOKING_TYPE["UPCOMING"] = "Upcoming";
  BOOKING_TYPE["CHECKED_IN"] = "Checked-in";
  return BOOKING_TYPE;
}({});