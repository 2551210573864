"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.PibaType = void 0;
var PibaType = exports.PibaType = function (PibaType) {
  PibaType["PIBA_UK"] = "PIBA";
  PibaType["PIBA_EU"] = "PIBA_EU";
  return PibaType;
}({});