"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = SearchResultsCCUIVariantQueryWrapper;
var _reactQuery = require("@tanstack/react-query");
var _SearchResultsCCUIVariant = _interopRequireDefault(require("./SearchResultsCCUIVariant.container"));
var _jsxRuntime = require("react/jsx-runtime");
function SearchResultsCCUIVariantQueryWrapper(_ref) {
  var {
    queryClient,
    multiSearchParams,
    onNoHotelsWarning,
    isSearchError
  } = _ref;
  return (0, _jsxRuntime.jsx)(_reactQuery.QueryClientProvider, {
    client: queryClient,
    children: (0, _jsxRuntime.jsx)(_SearchResultsCCUIVariant.default, {
      multiSearchParams: multiSearchParams,
      onNoHotelsWarning: onNoHotelsWarning,
      queryClient: queryClient,
      isSearchError: isSearchError
    })
  });
}