"use strict";
'use client';

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.PaginationPrevious = exports.PaginationNext = exports.PaginationItem = exports.PaginationEllipsis = exports.PaginationContent = exports.PaginationButton = exports.Pagination = void 0;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _objectWithoutProperties2 = _interopRequireDefault(require("@babel/runtime/helpers/objectWithoutProperties"));
var _server = require("@whitbread-eos/utils/server");
var _lucideReact = require("lucide-react");
var _image = _interopRequireDefault(require("next/image"));
var React = _interopRequireWildcard(require("react"));
var _Button = require("../Button");
var _jsxRuntime = require("react/jsx-runtime");
var _excluded = ["className"],
  _excluded2 = ["className"],
  _excluded3 = ["className"],
  _excluded4 = ["className", "isActive", "children"],
  _excluded5 = ["className", "icon"],
  _excluded6 = ["className", "icon"],
  _excluded7 = ["className"];
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && {}.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
var Pagination = _ref => {
  var {
      className
    } = _ref,
    props = (0, _objectWithoutProperties2.default)(_ref, _excluded);
  return (0, _jsxRuntime.jsx)("nav", _objectSpread({
    role: "navigation",
    className: (0, _server.cn)('mx-auto flex w-full justify-center', className)
  }, props));
};
exports.Pagination = Pagination;
Pagination.displayName = 'Pagination';
var PaginationContent = exports.PaginationContent = React.forwardRef((_ref2, ref) => {
  var {
      className
    } = _ref2,
    props = (0, _objectWithoutProperties2.default)(_ref2, _excluded2);
  return (0, _jsxRuntime.jsx)("ul", _objectSpread({
    ref: ref,
    className: (0, _server.cn)('flex flex-row items-center gap-1', className)
  }, props));
});
PaginationContent.displayName = 'PaginationContent';
var PaginationItem = exports.PaginationItem = React.forwardRef((_ref3, ref) => {
  var {
      className
    } = _ref3,
    props = (0, _objectWithoutProperties2.default)(_ref3, _excluded3);
  return (0, _jsxRuntime.jsx)("li", _objectSpread({
    ref: ref,
    className: (0, _server.cn)('', className)
  }, props));
});
PaginationItem.displayName = 'PaginationItem';
var PaginationButton = _ref4 => {
  var {
      className,
      isActive,
      children
    } = _ref4,
    props = (0, _objectWithoutProperties2.default)(_ref4, _excluded4);
  return (0, _jsxRuntime.jsx)(_Button.Button, _objectSpread(_objectSpread({
    variant: isActive ? 'default' : 'ghost',
    className: (0, _server.cn)('h-7 w-7 p-0 text-lg font-semibold', isActive ? 'text-white' : 'text-secondaryColor hover:text-secondaryColor', className)
  }, props), {}, {
    children: children
  }));
};
exports.PaginationButton = PaginationButton;
PaginationButton.displayName = 'PaginationButton';
var PaginationPrevious = _ref5 => {
  var {
      className,
      icon
    } = _ref5,
    props = (0, _objectWithoutProperties2.default)(_ref5, _excluded5);
  return (0, _jsxRuntime.jsx)(PaginationButton, _objectSpread(_objectSpread({
    className: (0, _server.cn)('flex gap-1', className)
  }, props), {}, {
    children: (0, _jsxRuntime.jsx)(_image.default, {
      src: (0, _server.formatIBAssetsUrl)(icon || ''),
      alt: 'pagination-previous',
      width: 28,
      height: 28
    })
  }));
};
exports.PaginationPrevious = PaginationPrevious;
PaginationPrevious.displayName = 'PaginationPrevious';
var PaginationNext = _ref6 => {
  var {
      className,
      icon
    } = _ref6,
    props = (0, _objectWithoutProperties2.default)(_ref6, _excluded6);
  return (0, _jsxRuntime.jsx)(PaginationButton, _objectSpread(_objectSpread({
    className: (0, _server.cn)('flex gap-1', className)
  }, props), {}, {
    children: (0, _jsxRuntime.jsx)(_image.default, {
      src: (0, _server.formatIBAssetsUrl)(icon || ''),
      alt: 'pagination-next',
      width: 28,
      height: 28
    })
  }));
};
exports.PaginationNext = PaginationNext;
PaginationNext.displayName = 'PaginationNext';
var PaginationEllipsis = _ref7 => {
  var {
      className
    } = _ref7,
    props = (0, _objectWithoutProperties2.default)(_ref7, _excluded7);
  return (0, _jsxRuntime.jsx)("span", _objectSpread(_objectSpread({
    className: (0, _server.cn)('flex h-7 w-7 pb-px items-end justify-center', className)
  }, props), {}, {
    children: (0, _jsxRuntime.jsx)(_lucideReact.MoreHorizontal, {
      className: "h-4 w-4"
    })
  }));
};
exports.PaginationEllipsis = PaginationEllipsis;
PaginationEllipsis.displayName = 'PaginationEllipsis';