"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
var _auth = require("./auth");
Object.keys(_auth).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _auth[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _auth[key];
    }
  });
});
var _confirmation = require("./confirmation");
Object.keys(_confirmation).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _confirmation[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _confirmation[key];
    }
  });
});
var _findBookingToken = require("./findBookingToken");
Object.keys(_findBookingToken).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _findBookingToken[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _findBookingToken[key];
    }
  });
});
var _getters = require("./getters");
Object.keys(_getters).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _getters[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _getters[key];
    }
  });
});
var _guestDetails = require("./guest-details");
Object.keys(_guestDetails).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _guestDetails[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _guestDetails[key];
    }
  });
});
var _hotelDetails = require("./hotel-details");
Object.keys(_hotelDetails).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _hotelDetails[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _hotelDetails[key];
    }
  });
});
var _labels = require("./labels");
Object.keys(_labels).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _labels[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _labels[key];
    }
  });
});
var _payment = require("./payment");
Object.keys(_payment).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _payment[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _payment[key];
    }
  });
});
var _searchEmployees = require("./search-employees");
Object.keys(_searchEmployees).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _searchEmployees[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _searchEmployees[key];
    }
  });
});
var _searchResults = require("./search-results");
Object.keys(_searchResults).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _searchResults[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _searchResults[key];
    }
  });
});
var _paypal = require("./paypal");
Object.keys(_paypal).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _paypal[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _paypal[key];
    }
  });
});
var _getLocalStorageMock = require("./getLocalStorageMock");
Object.keys(_getLocalStorageMock).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _getLocalStorageMock[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _getLocalStorageMock[key];
    }
  });
});
var _amend = require("./amend");
Object.keys(_amend).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _amend[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _amend[key];
    }
  });
});