"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = HotelLocationInformationContainer;
var _utils = require("@whitbread-eos/utils");
var _HotelLocationInformation = _interopRequireDefault(require("./HotelLocationInformation.component"));
var _jsxRuntime = require("react/jsx-runtime");
function HotelLocationInformationContainer() {
  var {
    address,
    satNavDirections,
    whatThreeWords,
    isLoading,
    isError,
    error
  } = (0, _utils.useStaticHotelInformation)();
  return (0, _jsxRuntime.jsx)(_HotelLocationInformation.default, {
    isLoading,
    isError,
    error,
    address,
    satNavDirections,
    whatThreeWords
  });
}