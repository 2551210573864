"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getAmendSectionTranslations = getAmendSectionTranslations;
exports.getTitleDropdownValues = void 0;
function getAmendSectionTranslations(t) {
  var bookingSummaryLabels = {
    title: t('booking.header.summary'),
    confirmChangesLabel: t('amend.confirmChanges'),
    mealsLabel: t('booking.hotel.summary.meals'),
    extrasLabel: t('amend.extras.extras'),
    previousTotalLabel: t('dashboard.bookings.previousTotal'),
    totalCostLabel: t('booking.confirmation.totalCost'),
    continueToPaymentLabel: t('ccui.amend.continue.to.payment'),
    expandDetail: t('amend.expandDetail')
  };
  var leadGuestLabels = {
    guestTitle: t('amend.nameTitles'),
    title: t('booking.contactDetails.title'),
    firstName: t('booking.leadGuest.FirstName'),
    lastName: t('booking.leadGuest.LastName'),
    email: t('dashboard.bookings.emailPlaceholder'),
    emailAddress: t('dashboard.bookings.emailPlaceholder'),
    addressLine1: t('booking.guest.addressAddress1'),
    addressLine2: t('booking.guest.addressAddress2'),
    addressLine3: t('booking.guest.addressAddress3'),
    postalCode: t('booking.guest.addressPostCode'),
    city: t('booking.guest.addressCity'),
    country: 'Choose a country'
  };
  var leadGuestValidationLabels = {
    titleError: t('config.errorMessages.yourDetails.title.required'),
    firstNameRequiredError: t('config.errorMessages.yourDetails.firstName.required'),
    firstNameMinError: t('config.errorMessages.yourDetails.firstName.min'),
    lastNameRequiredError: t('config.errorMessages.yourDetails.lastName.required'),
    firstNameInvalidError: t('config.errorMessages.yourDetails.firstName.invalid'),
    lastNameInvalidError: t('config.errorMessages.yourDetails.lastName.invalid'),
    emailInvalidError: t('config.errorMessages.yourDetails.email.max')
  };
  var notificationLabels = {
    title: t('amend.notification.reset.header'),
    description: t('amend.notification.reset.message')
  };
  var summaryOfPaymentsLabels = {
    balancePaid: t('amend.prepaid'),
    payOnArrival: t('booking.payOnArrival'),
    totalCost: t('booking.confirmation.totalCost'),
    refund: t('amend.refund'),
    refundTerms: t('amend.refundTerms'),
    nonRefundable: 'Non-refundable',
    balanceAuthorised: 'Balance authorised',
    donation: t('amend.goshDonation')
  };
  var removeRoomModalLabels = {
    title: t('amend.removeRoom'),
    confirmLabel: t('amend.removeRoom.confirmation'),
    notificationLabel: t('amend.roomMayNotBeAvailable'),
    removeModalRoom: t('amend.removeRoom'),
    cancelModalRoom: t('ccui.manageBooking.cancelButton'),
    roomSuccessfullRemoved: t('amend.roomSuccessfullRemoved')
  };
  var stayDatesLabels = {
    arrivalDate: t('amend.arrivalDate'),
    nightsLabel: t('search.nights'),
    nightOption: t('amend.night'),
    nightsOption: t('amend.nights'),
    checkOut: t('search.checkOut'),
    hotel: t('numberList.hotel'),
    yourStayDatesTitle: t('amend.yourStayDates'),
    numberOfNightsErrorMessage: t('ccui.search.nrOfnights.errormessage')
  };
  var roomAvailabilityLabels = {
    addRoom: t('amend.addRoom'),
    roomAvailable: t('amend.roomAvailable'),
    roomsUnavailable: t('amend.roomsUnavailable'),
    roomsUnavailableDescription: t('amend.roomsUnavailableDescription'),
    checkRoomAvailability: t('amend.checkRoomAvailability'),
    leadGuest: t('amend.leadGuest'),
    cancelBtn: t('ccui.manageBooking.cancelButton'),
    guests: t('amend.guests'),
    update: t('amend.update'),
    roomSuccessfullyAdded: t('amend.roomSuccessfullyAdded'),
    roomSuccessfullyUpdated: t('amend.roomSuccessfullyUpdated'),
    cityTaxNotIncluded: t('amend.cityTax.notIncluded')
  };
  return {
    bookingSummaryLabels,
    leadGuestLabels,
    leadGuestValidationLabels,
    notificationLabels,
    summaryOfPaymentsLabels,
    removeRoomModalLabels,
    stayDatesLabels,
    roomAvailabilityLabels
  };
}
var getTitleDropdownValues = (titles, currentTitle) => {
  var optionExists = titles.find(title => title.id === currentTitle);
  if (!optionExists && currentTitle !== '') {
    return [{
      id: currentTitle,
      label: currentTitle
    }, ...titles];
  }
  return titles;
};
exports.getTitleDropdownValues = getTitleDropdownValues;