"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var React = _interopRequireWildcard(require("react"));
var _jsxRuntime = require("react/jsx-runtime");
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && {}.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
var SvgUltimateWifi32 = props => (0, _jsxRuntime.jsx)("svg", _objectSpread(_objectSpread({
  width: 28,
  height: 25,
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg"
}, props), {}, {
  children: (0, _jsxRuntime.jsx)("path", {
    fillRule: "evenodd",
    clipRule: "evenodd",
    d: "M25 18v3h3v1h-3v3h-1v-3.001L21 22v-1l3-.001V18h1Zm-11 0a1 1 0 1 1 0 2 1 1 0 0 1 0-2Zm0-6c2.01 0 3.79.989 4.879 2.506l-.718.72A4.995 4.995 0 0 0 14 13a4.995 4.995 0 0 0-4.16 2.224l-.719-.718A5.992 5.992 0 0 1 14.001 12Zm0-6a11.48 11.48 0 0 1 9.02 4.365l-.712.713A10.483 10.483 0 0 0 14.001 7c-3.38 0-6.387 1.597-8.307 4.077l-.712-.713A11.48 11.48 0 0 1 14 6Zm0-6a17.46 17.46 0 0 1 13.282 6.104l-.71.71A16.464 16.464 0 0 0 14.002 1 16.464 16.464 0 0 0 1.429 6.813l-.71-.71A17.46 17.46 0 0 1 14 0Z",
    fill: props.color || '#58595B'
  })
}));
var _default = exports.default = SvgUltimateWifi32;