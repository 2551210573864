"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = HotelFacilities;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _react = require("@chakra-ui/react");
var _atoms = require("@whitbread-eos/atoms");
var _utils = require("@whitbread-eos/utils");
var _react2 = require("react");
var _constants = require("./constants");
var _jsxRuntime = require("react/jsx-runtime");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function HotelFacilities(_ref) {
  var {
    facilities,
    roomTypes
  } = _ref;
  var visibleFacilities = (0, _react2.useMemo)(() => {
    var airConditioningFacility = facilities.filter(facility => {
      var _facility$code;
      return _constants.AIR_CONDITIONING_FACILITY_CODES.flat().includes((_facility$code = facility === null || facility === void 0 ? void 0 : facility.code) !== null && _facility$code !== void 0 ? _facility$code : '');
    });
    var accessibleFacility = facilities.filter(facility => {
      var _facility$code2;
      return _constants.ACCESSIBLE_FACILITY_CODES.flat().includes((_facility$code2 = facility === null || facility === void 0 ? void 0 : facility.code) !== null && _facility$code2 !== void 0 ? _facility$code2 : '');
    });
    var otherFacilities = facilities.filter(facility => {
      var _facility$code3;
      return _constants.ACCEPTED_HOTEL_CARD_FACILITIES.flat().includes((_facility$code3 = facility === null || facility === void 0 ? void 0 : facility.code) !== null && _facility$code3 !== void 0 ? _facility$code3 : '');
    });
    var uniqueFacilities = [...airConditioningFacility, ...otherFacilities];
    if (roomTypes.includes('DIS')) {
      return uniqueFacilities.concat(...accessibleFacility);
    }
    return uniqueFacilities;
  }, [facilities, roomTypes]);
  return (0, _jsxRuntime.jsx)(_react.Flex, {
    children: visibleFacilities.map(facility => {
      var _facility$name, _facility$icon;
      return (0, _jsxRuntime.jsx)(_atoms.Tooltip, _objectSpread(_objectSpread({
        description: (_facility$name = facility === null || facility === void 0 ? void 0 : facility.name) !== null && _facility$name !== void 0 ? _facility$name : '',
        variant: "facilities",
        placement: "bottom-start",
        position: "relative",
        alertElementStyles: alertStyles
      }, tooltipStyles), {}, {
        children: (0, _jsxRuntime.jsx)(_react.Box, {
          as: "span",
          mr: "xmd",
          "data-testid": facility.name,
          children: (0, _jsxRuntime.jsx)(_atoms.Icon, {
            src: (0, _utils.formatAssetsUrl)((_facility$icon = facility === null || facility === void 0 ? void 0 : facility.icon) !== null && _facility$icon !== void 0 ? _facility$icon : '')
          })
        })
      }), facility.code);
    })
  });
}
var alertStyles = {
  py: 'sm',
  padding: 0,
  h: '2.125rem'
};
var tooltipStyles = {
  marginLeft: '-1.2rem',
  fontSize: 'sm',
  lineHeight: '3',
  paddingLeft: 0,
  fontWeight: 'normal',
  top: 'xs'
};