"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var React = _interopRequireWildcard(require("react"));
var _jsxRuntime = require("react/jsx-runtime");
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && {}.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
var SvgMastercardLogo = props => (0, _jsxRuntime.jsxs)("svg", _objectSpread(_objectSpread({
  width: 39,
  height: 25,
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg"
}, props), {}, {
  children: [(0, _jsxRuntime.jsx)("path", {
    fillRule: "evenodd",
    clipRule: "evenodd",
    d: "M37.477 19.329v.381h-.072v-.381h-.155v-.079h.391v.079h-.164Zm.76-.079v.46h-.083v-.348l-.128.299h-.089l-.128-.3v.35h-.086v-.461h.119l.138.329.138-.329h.118Z",
    fill: "#F79E1B"
  }), (0, _jsxRuntime.jsx)("path", {
    d: "M14 2.57h11v18.86H14V2.57Z",
    fill: "#FF5F00"
  }), (0, _jsxRuntime.jsx)("path", {
    d: "M14.831 12.003a11.98 11.98 0 0 1 4.584-9.433 12 12 0 1 0 0 18.87 11.98 11.98 0 0 1-4.584-9.437Z",
    fill: "#EB001B"
  }), (0, _jsxRuntime.jsx)("path", {
    d: "M32.074 22.794a11.999 11.999 0 0 1-12.659-1.36 11.998 11.998 0 0 0 0-18.868 11.999 11.999 0 1 1 12.66 20.228Z",
    fill: "#F79E1B"
  })]
}));
var _default = exports.default = SvgMastercardLogo;