"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.isObjectNotEmpty = isObjectNotEmpty;
function isObjectNotEmpty(obj) {
  for (var property in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, property) && obj[property] !== '') {
      return true;
    }
  }
  return false;
}