"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = usePollBasketStatus;
var _api = require("@whitbread-eos/api");
var _nextI18next = require("next-i18next");
var _react = require("react");
var _useRequest = require("./use-request");
var _useSessionStorage = require("./use-session-storage");
var INTERVAL_DELAY = 1;
function usePollBasketStatus(basketReference, spinnerConfig) {
  var _basketData$basketSta, _basketData$basketSta2, _basketData$basketSta3, _basketData$basketSta4;
  var startPolling = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : true;
  var {
    t
  } = (0, _nextI18next.useTranslation)(['common']);
  var [dynamicSpinnerLabel, setDynamicSpinnerLabel] = (0, _react.useState)(t('booking.loading'));
  var [pollingInProgress, setPollingInProgress] = (0, _react.useState)(startPolling);
  var [pollingTimedOut, setPollingTimedOut] = (0, _react.useState)(false);
  var intervalRef = (0, _react.useRef)();
  var timeoutRef = (0, _react.useRef)();
  var startTimeRef = (0, _react.useRef)(0);
  var errorCode = (0, _react.useRef)('');
  var [, setConfirmAmendErrorValue] = (0, _useSessionStorage.useSessionStorage)(_api.INITIAL_CONFIRM_AMEND_ERROR_KEY, _api.INITIAL_CONFIRM_AMEND_ERROR_VALUE);
  var {
    isLoading: isLoadingBasketData,
    data: basketData
  } = (0, _useRequest.useQueryRequest)(['BasketStatus', basketReference], _api.CHECK_BASKET_STATUS, {
    basketReference
  }, {
    enabled: pollingInProgress && !!basketReference,
    gcTime: 0,
    staleTime: 0,
    refetchInterval: INTERVAL_DELAY * 1000,
    refetchIntervalInBackground: true
  });
  var getDynamicSpinnerLabel = (0, _react.useCallback)(() => {
    var currentTime = Math.round(Date.now() / 1000);
    var elapsedTime = currentTime - startTimeRef.current;
    if (spinnerConfig && (spinnerConfig === null || spinnerConfig === void 0 ? void 0 : spinnerConfig.length) === 3) {
      var [spinnerOneData, spinnerTwoData, spinnerThreeData] = spinnerConfig;
      if (elapsedTime <= +spinnerOneData.seconds) {
        setDynamicSpinnerLabel(spinnerOneData.text);
      } else if (elapsedTime <= +spinnerOneData.seconds + +spinnerTwoData.seconds) {
        setDynamicSpinnerLabel(spinnerTwoData.text);
      } else {
        setDynamicSpinnerLabel(spinnerThreeData.text);
      }
    }
  }, [spinnerConfig]);
  (0, _react.useEffect)(() => {
    if (pollingInProgress) {
      if (!intervalRef.current) {
        startTimeRef.current = Math.round(Date.now() / 1000);
        setPollingTimedOut(false);
        getDynamicSpinnerLabel();
        intervalRef.current = setInterval(getDynamicSpinnerLabel, INTERVAL_DELAY * 1000);
      }
    } else {
      var _intervalRef$current, _timeoutRef$current;
      startTimeRef.current = 0;
      clearInterval((_intervalRef$current = intervalRef.current) !== null && _intervalRef$current !== void 0 ? _intervalRef$current : '');
      clearTimeout((_timeoutRef$current = timeoutRef.current) !== null && _timeoutRef$current !== void 0 ? _timeoutRef$current : '');
      timeoutRef.current = null;
      intervalRef.current = null;
    }
    return () => {
      var _intervalRef$current2;
      clearInterval((_intervalRef$current2 = intervalRef.current) !== null && _intervalRef$current2 !== void 0 ? _intervalRef$current2 : '');
    };
  }, [getDynamicSpinnerLabel, pollingInProgress]);
  (0, _react.useEffect)(() => {
    if (!isLoadingBasketData && basketData) {
      var {
        basketStatus = '',
        basketError = null
      } = basketData.basketStatus;
      if (basketStatus === _api.BASKET_STATUS.PROCESSING || basketStatus === _api.BASKET_STATUS.AMENDING) {
        if (!timeoutRef.current && spinnerConfig && (spinnerConfig === null || spinnerConfig === void 0 ? void 0 : spinnerConfig.length) === 3) {
          var [spinnerOneData, spinnerTwoData, spinnerThreeData] = spinnerConfig;
          var timeoutPeriod = +spinnerOneData.seconds + +spinnerTwoData.seconds + +spinnerThreeData.seconds;
          timeoutRef.current = setTimeout(() => {
            setPollingTimedOut(true);
            setPollingInProgress(false);
          }, timeoutPeriod * 1000);
        }
        if (basketStatus === _api.BASKET_STATUS.AMENDING && basketError !== null && basketError !== void 0 && basketError.code) {
          var _basketError$code;
          setConfirmAmendErrorValue(basketError.code);
          errorCode.current = (_basketError$code = basketError.code) !== null && _basketError$code !== void 0 ? _basketError$code : _api.AMEND_REVERT_EXCEPTION_VALUE;
          setPollingInProgress(false);
        }
      }
      if (basketStatus === _api.BASKET_STATUS.COMPLETED || basketStatus === _api.BASKET_STATUS.FAILED || basketStatus === _api.BASKET_STATUS.AMENDED || basketStatus === _api.BASKET_STATUS.AMEND_FAILED) {
        setPollingInProgress(false);
      }
    }
    return () => {
      var _timeoutRef$current2;
      clearTimeout((_timeoutRef$current2 = timeoutRef.current) !== null && _timeoutRef$current2 !== void 0 ? _timeoutRef$current2 : '');
    };
  }, [isLoadingBasketData, basketData]);
  return {
    pollingInProgress,
    basketStatus: (_basketData$basketSta = basketData === null || basketData === void 0 || (_basketData$basketSta2 = basketData.basketStatus) === null || _basketData$basketSta2 === void 0 ? void 0 : _basketData$basketSta2.basketStatus) !== null && _basketData$basketSta !== void 0 ? _basketData$basketSta : '',
    retryPayment: (_basketData$basketSta3 = basketData === null || basketData === void 0 || (_basketData$basketSta4 = basketData.basketStatus) === null || _basketData$basketSta4 === void 0 ? void 0 : _basketData$basketSta4.retryPayment) !== null && _basketData$basketSta3 !== void 0 ? _basketData$basketSta3 : null,
    dynamicSpinnerLabel,
    pollingTimedOut,
    errorCode: errorCode.current
  };
}