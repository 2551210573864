"use strict";
'use client';

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.DrawerTrigger = exports.DrawerTitle = exports.DrawerPortal = exports.DrawerOverlay = exports.DrawerHeader = exports.DrawerFooter = exports.DrawerDescription = exports.DrawerContent = exports.DrawerClose = exports.Drawer = void 0;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _objectWithoutProperties2 = _interopRequireDefault(require("@babel/runtime/helpers/objectWithoutProperties"));
var _server = require("@whitbread-eos/utils/server");
var _lucideReact = require("lucide-react");
var React = _interopRequireWildcard(require("react"));
var _vaul = require("vaul");
var _jsxRuntime = require("react/jsx-runtime");
var _excluded = ["shouldScaleBackground"],
  _excluded2 = ["className"],
  _excluded3 = ["className", "children", "hasCloseButton"],
  _excluded4 = ["className"],
  _excluded5 = ["className"],
  _excluded6 = ["className"],
  _excluded7 = ["className"];
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && {}.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
var Drawer = _ref => {
  var {
      shouldScaleBackground = true
    } = _ref,
    props = (0, _objectWithoutProperties2.default)(_ref, _excluded);
  return (0, _jsxRuntime.jsx)(_vaul.Drawer.Root, _objectSpread({
    shouldScaleBackground: shouldScaleBackground
  }, props));
};
exports.Drawer = Drawer;
Drawer.displayName = 'Drawer';
var DrawerTrigger = exports.DrawerTrigger = _vaul.Drawer.Trigger;
var DrawerPortal = exports.DrawerPortal = _vaul.Drawer.Portal;
var DrawerClose = exports.DrawerClose = _vaul.Drawer.Close;
var DrawerOverlay = exports.DrawerOverlay = React.forwardRef((_ref2, ref) => {
  var {
      className
    } = _ref2,
    props = (0, _objectWithoutProperties2.default)(_ref2, _excluded2);
  return (0, _jsxRuntime.jsx)(_vaul.Drawer.Overlay, _objectSpread({
    ref: ref,
    className: (0, _server.cn)('fixed inset-0 z-50 bg-black/50', className)
  }, props));
});
DrawerOverlay.displayName = _vaul.Drawer.Overlay.displayName;
var DrawerContent = exports.DrawerContent = React.forwardRef((_ref3, ref) => {
  var {
      className,
      children,
      hasCloseButton = true
    } = _ref3,
    props = (0, _objectWithoutProperties2.default)(_ref3, _excluded3);
  return (0, _jsxRuntime.jsxs)(DrawerPortal, {
    children: [(0, _jsxRuntime.jsx)(DrawerOverlay, {}), (0, _jsxRuntime.jsxs)(_vaul.Drawer.Content, _objectSpread(_objectSpread({
      ref: ref,
      className: (0, _server.cn)('fixed inset-x-0 bottom-0 z-50 mt-24 flex h-auto flex-col rounded-t-lg border-lightGrey3 bg-background pt-12 pb-6 outline-none', className)
    }, props), {}, {
      children: [hasCloseButton && (0, _jsxRuntime.jsx)(_vaul.Drawer.Close, {
        className: "absolute top-[18px] right-[18px]",
        children: (0, _jsxRuntime.jsx)(_lucideReact.X, {
          className: "stroke-secondaryColor"
        })
      }), children]
    }))]
  });
});
DrawerContent.displayName = 'DrawerContent';
var DrawerHeader = _ref4 => {
  var {
      className
    } = _ref4,
    props = (0, _objectWithoutProperties2.default)(_ref4, _excluded4);
  return (0, _jsxRuntime.jsx)("div", _objectSpread({
    className: (0, _server.cn)('grid gap-1.5 p-4 text-center sm:text-left', className)
  }, props));
};
exports.DrawerHeader = DrawerHeader;
DrawerHeader.displayName = 'DrawerHeader';
var DrawerFooter = _ref5 => {
  var {
      className
    } = _ref5,
    props = (0, _objectWithoutProperties2.default)(_ref5, _excluded5);
  return (0, _jsxRuntime.jsx)("div", _objectSpread({
    className: (0, _server.cn)('mt-auto flex flex-col gap-2 p-4', className)
  }, props));
};
exports.DrawerFooter = DrawerFooter;
DrawerFooter.displayName = 'DrawerFooter';
var DrawerTitle = exports.DrawerTitle = React.forwardRef((_ref6, ref) => {
  var {
      className
    } = _ref6,
    props = (0, _objectWithoutProperties2.default)(_ref6, _excluded6);
  return (0, _jsxRuntime.jsx)(_vaul.Drawer.Title, _objectSpread({
    ref: ref,
    className: (0, _server.cn)('text-lg font-semibold leading-none tracking-tight', className)
  }, props));
});
DrawerTitle.displayName = _vaul.Drawer.Title.displayName;
var DrawerDescription = exports.DrawerDescription = React.forwardRef((_ref7, ref) => {
  var {
      className
    } = _ref7,
    props = (0, _objectWithoutProperties2.default)(_ref7, _excluded7);
  return (0, _jsxRuntime.jsx)(_vaul.Drawer.Description, _objectSpread({
    ref: ref,
    className: (0, _server.cn)('text-sm text-muted-foreground', className)
  }, props));
});
DrawerDescription.displayName = _vaul.Drawer.Description.displayName;