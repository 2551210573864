"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = BookingSummaryHotelDetailsInfo;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _react = require("@chakra-ui/react");
var _utils = require("@whitbread-eos/utils");
var _jsxRuntime = require("react/jsx-runtime");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function BookingSummaryHotelDetailsInfo(_ref) {
  var {
    hotelName,
    hotelAddress,
    prefixDataTestId
  } = _ref;
  var baseDataTestId = (0, _utils.formatDataTestId)(prefixDataTestId, 'HotelInformation');
  return (0, _jsxRuntime.jsxs)(_react.Flex, _objectSpread(_objectSpread({}, detailsWrapperStyle), {}, {
    "data-testid": (0, _utils.formatDataTestId)(baseDataTestId, 'Wrapper'),
    children: [(0, _jsxRuntime.jsx)(_react.Text, _objectSpread(_objectSpread({}, hotelNameTextStyle), {}, {
      "data-testid": (0, _utils.formatDataTestId)(baseDataTestId, 'HotelName'),
      children: hotelName
    })), (0, _jsxRuntime.jsxs)(_react.Box, {
      mt: "sm",
      "data-testid": (0, _utils.formatDataTestId)(baseDataTestId, 'HotelAddress'),
      children: [(0, _jsxRuntime.jsx)(_react.Flex, _objectSpread(_objectSpread({}, addressWrapperStyle), {}, {
        children: hotelAddress.map(address => (0, _jsxRuntime.jsx)(_react.Text, _objectSpread(_objectSpread({}, infoTextStyle), {}, {
          children: address
        }), address))
      })), (0, _jsxRuntime.jsx)(_react.Text, _objectSpread(_objectSpread({}, addressWrapperMobile), {}, {
        children: hotelAddress.join(', ')
      }))]
    })]
  }));
}
var detailsWrapperStyle = {
  direction: 'column',
  pb: {
    mobile: 'md',
    lg: 'lg'
  }
};
var addressWrapperStyle = {
  direction: 'column',
  display: {
    mobile: 'none',
    lg: 'flex'
  }
};
var infoTextStyle = {
  lineHeight: '3',
  fontSize: 'md',
  color: 'darkGrey1',
  as: 'h6',
  fontWeight: 'normal'
};
var hotelNameTextStyle = _objectSpread(_objectSpread({}, infoTextStyle), {}, {
  fontSize: 'lg',
  as: 'h5',
  fontWeight: 'semibold'
});
var addressWrapperMobile = _objectSpread(_objectSpread({}, infoTextStyle), {}, {
  display: {
    mobile: 'flex',
    lg: 'none'
  }
});