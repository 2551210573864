"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = BookingInfoCardComponent;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _react = require("@chakra-ui/react");
var _api = require("@whitbread-eos/api");
var _atoms = require("@whitbread-eos/atoms");
var _nextI18next = require("next-i18next");
var _react2 = _interopRequireDefault(require("react"));
var _BookingActions = _interopRequireDefault(require("./BookingActions"));
var _BookingDetails = require("./BookingDetails");
var _HotelDetails = _interopRequireDefault(require("./HotelDetails"));
var _jsxRuntime = require("react/jsx-runtime");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function BookingInfoCardComponent(props) {
  var {
    t
  } = (0, _nextI18next.useTranslation)();
  var {
    isAmendPage,
    area,
    operaConfNumber,
    manageBookingParams,
    isErrorFindOrCopyBooking
  } = props;
  var {
    isCancellable,
    isAmendable
  } = manageBookingParams.data.manageBooking;
  var displayOperaConfNumberCCUI = [_api.Area.CCUI].includes(area) && !!operaConfNumber && (!isAmendable || !isCancellable);
  return (0, _jsxRuntime.jsx)(_jsxRuntime.Fragment, {
    children: (0, _jsxRuntime.jsxs)(_atoms.Card, _objectSpread(_objectSpread({}, cardWrapperStyle), {}, {
      children: [(0, _jsxRuntime.jsx)(_BookingActions.default, _objectSpread(_objectSpread({}, props), {}, {
        shouldRenderStatusAndActions: isAmendPage && [_api.Area.PI, _api.Area.BB].includes(area)
      })), (0, _jsxRuntime.jsxs)(_react.Box, _objectSpread(_objectSpread({}, _objectSpread({}, containerStyle(props.bookingStatus))), {}, {
        children: [(isErrorFindOrCopyBooking || displayOperaConfNumberCCUI && props.bookingType === _api.BOOKING_TYPE.UPCOMING) && (0, _jsxRuntime.jsx)(_react.Flex, _objectSpread(_objectSpread({}, notificationFlexStyle), {}, {
          children: (0, _jsxRuntime.jsx)(_react.Box, _objectSpread(_objectSpread({}, boxNotificationStyle), {}, {
            children: (0, _jsxRuntime.jsx)(_atoms.Notification, {
              status: "warning",
              description: isErrorFindOrCopyBooking ? t('errors.sorry') : t('ccui.managebooking.notification.goToOpera'),
              variant: "alert",
              maxW: "full",
              svg: (0, _jsxRuntime.jsx)(_atoms.Alert, {})
            })
          }))
        })), props.isChangedPaymentApplied && (props.basketStatus === _api.BASKET_STATUS.PAY_PENDING || props.basketStatus === _api.BASKET_STATUS.COMPLETED) && props.paymentOption !== _api.paymentOptions.RESERVE_WITHOUT_CARD && props.bookingType === _api.BOOKING_TYPE.UPCOMING && (0, _jsxRuntime.jsx)(_react.Flex, _objectSpread(_objectSpread({}, notificationFlexStyle), {}, {
          children: (0, _jsxRuntime.jsx)(_react.Box, _objectSpread(_objectSpread({}, boxNotificationStyle), {}, {
            children: (0, _jsxRuntime.jsx)(_atoms.Notification, {
              status: "success",
              description: t('ccui.manageBooking.notification.paymentMethodUpdated'),
              variant: "success",
              maxW: "full",
              svg: (0, _jsxRuntime.jsx)(_atoms.Success, {})
            })
          }))
        })), (0, _jsxRuntime.jsxs)(_react.Flex, _objectSpread(_objectSpread({}, _objectSpread({}, containerFlexStyle(props.bookingStatus))), {}, {
          children: [props.bookingStatus !== _api.BC_RESERVATION_STATUS.CANCELLED && (0, _jsxRuntime.jsx)(_HotelDetails.default, _objectSpread({}, props)), (0, _jsxRuntime.jsx)(_BookingDetails.BookingDetails, _objectSpread({}, props))]
        }))]
      }))]
    }))
  });
}
var cardWrapperStyle = {
  display: 'flex',
  boxShadow: 'none',
  flexDirection: {
    mobile: 'column',
    lg: 'row'
  },
  padding: 'lg'
};
var boxNotificationStyle = {
  marginBottom: 'var(--chakra-space-lg)',
  width: '100%'
};
var notificationFlexStyle = {
  display: 'flex',
  flexDirection: 'row'
};
var containerFlexStyle = bookingStatus => {
  return {
    display: bookingStatus === _api.BC_RESERVATION_STATUS.CANCELLED ? {
      mobile: 'flex',
      lg: 'grid'
    } : 'flex',
    gridTemplateColumns: {
      lg: '1fr 1fr'
    },
    flexDirection: {
      mobile: 'column',
      lg: 'row'
    },
    justifyContent: 'space-between'
  };
};
var containerStyle = bookingStatus => {
  return {
    marginTop: {
      mobile: 'var(--chakra-space-4)',
      lg: 0
    },
    ml: {
      mobile: 0,
      lg: bookingStatus === _api.BC_RESERVATION_STATUS.CANCELLED ? 0 : '5xl'
    },
    width: '100%'
  };
};