"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = CotNotification;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _react = require("@chakra-ui/react");
var _atoms = require("@whitbread-eos/atoms");
var _nextI18next = require("next-i18next");
var _jsxRuntime = require("react/jsx-runtime");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function CotNotification(_ref) {
  var {
    cotAvailable
  } = _ref;
  var {
    t
  } = (0, _nextI18next.useTranslation)(['common']);
  return (0, _jsxRuntime.jsx)(_react.Box, {
    mb: "md",
    "data-testid": "cot-notification",
    children: (0, _jsxRuntime.jsx)(_atoms.Notification, _objectSpread(_objectSpread({}, getNotificationPropsByType()), {}, {
      prefixDataTestId: "cot-notification",
      isInnerHTML: true
    }, notificationStyles))
  });
  function getNotificationPropsByType() {
    if (!cotAvailable) {
      return {
        description: t('booking.cot.notAvailable'),
        variant: 'alert',
        status: 'warning',
        svg: (0, _jsxRuntime.jsx)(_atoms.Alert, {})
      };
    }
    return {
      description: t('booking.cot.available'),
      variant: 'info',
      status: 'info',
      svg: (0, _jsxRuntime.jsx)(_atoms.Info, {})
    };
  }
}
var notificationStyles = {
  maxW: {
    base: 'full',
    md: '100%'
  },
  lineHeight: '2'
};