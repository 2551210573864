import SecondaryHDPLayout from '~components/layouts/SecondaryHDPLayout';

import AncillariesLayout from './layouts/AncillariesLayout';
import ConfirmationLayout from './layouts/ConfirmationLayout';
import DefaultLayout from './layouts/DefaultLayout';
import GraphQLPageLayout from './layouts/GraphQLPageLayout';
import GuestDetailsLayout from './layouts/GuestDetailsLayout';
import PaymentLayout from './layouts/PaymentLayout';

export { AncillariesLayout };
export { DefaultLayout };
export { GraphQLPageLayout };
export { PaymentLayout };
export { SecondaryHDPLayout };
export { ConfirmationLayout };
export { GuestDetailsLayout };
