"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = RadioButton;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _react = require("@chakra-ui/react");
var _jsxRuntime = require("react/jsx-runtime");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function getBorderRadius(listIndex) {
  switch (listIndex) {
    case 0:
      return 'var(--chakra-space-1) var(--chakra-space-1) 0 0';
    case 'last':
      return '0 0 var(--chakra-space-1) var(--chakra-space-1)';
    case 'left':
      return 'var(--chakra-space-1) 0 0 var(--chakra-space-1)';
    case undefined:
      return '4';
    default:
      return '0';
  }
}
function getBorderWidth(isBorderless, isChecked) {
  if (isBorderless) {
    return 0;
  }
  if (isChecked) {
    return '2px';
  }
  return '1px';
}
function getBorderBottomWidth(isBorderless, isChecked, listIndex) {
  if (isBorderless) {
    return 0;
  }
  if (isChecked) {
    return '2px';
  }
  if ([undefined, 0, 'last', 'left'].includes(listIndex)) {
    return '1px';
  }
  return 0;
}
var radioButtonStyle = (isBorderless, isChecked, listIndex) => ({
  borderWidth: getBorderWidth(isBorderless, isChecked),
  borderBottomWidth: getBorderBottomWidth(isBorderless, isChecked, listIndex),
  borderStyle: 'solid',
  borderColor: isChecked ? 'primary' : 'lightGrey1',
  borderRadius: getBorderRadius(listIndex),
  p: isBorderless ? '0' : 'md'
});
function RadioButton(props) {
  return (0, _jsxRuntime.jsx)(_react.Box, _objectSpread(_objectSpread({
    "data-testid": props.type ? "radio-box-wrapper_".concat(props.type) : 'radio-box-wrapper'
  }, radioButtonStyle(props.variant === 'borderless', props.isChecked, props.listIndex)), {}, {
    width: props.width,
    children: (0, _jsxRuntime.jsx)(_react.Radio, _objectSpread(_objectSpread({
      "data-testid": props.type ? "radio-box-inside_".concat(props.type) : 'radio-box-inside',
      alignItems: "flex-start",
      width: "full"
    }, props), {}, {
      children: props.children
    }))
  }));
}