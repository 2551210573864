"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.DASHBOARD_MANAGE_BOOKING = void 0;
var _taggedTemplateLiteral2 = _interopRequireDefault(require("@babel/runtime/helpers/taggedTemplateLiteral"));
var _graphqlRequest = require("graphql-request");
var _templateObject;
var DASHBOARD_MANAGE_BOOKING = exports.DASHBOARD_MANAGE_BOOKING = (0, _graphqlRequest.gql)(_templateObject || (_templateObject = (0, _taggedTemplateLiteral2.default)(["\n  query manageBooking($cancelInformationCriteria: CancelInformationCriteria!) {\n    manageBooking(cancelInformationCriteria: $cancelInformationCriteria) {\n      isCancellable\n      isAmendable\n      isRuleCompliant\n      aemLabelKey\n    }\n  }\n"])));