"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.SUBCHANNEL = exports.PAY_ON_ARRIVAL = exports.NEW_PIBA = exports.NEW_CARD = exports.ANALYTICS_TRACKING_AMEND_SEARCH = exports.ANALYTICS_TRACKING_AMEND_CONFIRMATION = exports.AMEND_CARD_NAME = void 0;
var SUBCHANNEL = exports.SUBCHANNEL = 'WEB';
var ANALYTICS_TRACKING_AMEND_SEARCH = exports.ANALYTICS_TRACKING_AMEND_SEARCH = 'amendBookingSearch';
var ANALYTICS_TRACKING_AMEND_CONFIRMATION = exports.ANALYTICS_TRACKING_AMEND_CONFIRMATION = 'lamendBookingConfirmation';
var AMEND_CARD_NAME = exports.AMEND_CARD_NAME = 'AMEND_SAVED_CARD';
var PAY_ON_ARRIVAL = exports.PAY_ON_ARRIVAL = 'PAY_ON_ARRIVAL';
var NEW_CARD = exports.NEW_CARD = 'NEW_CARD';
var NEW_PIBA = exports.NEW_PIBA = 'NEW_PIBA';