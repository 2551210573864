"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.setBookingCookie = void 0;
var _api = require("@whitbread-eos/api");
var _cookies = require("./cookies");
var setBookingCookie = (cookieName, cookieValue, minutesTillExpiry) => {
  if (typeof window !== 'undefined' && cookieName) {
    window.localStorage.setItem(_api.FIND_BOOKING_COOKIE_NAME_KEY, cookieName);
    (0, _cookies.setCookie)(cookieName, window.btoa(JSON.stringify(cookieValue)), Number(minutesTillExpiry));
  }
};
exports.setBookingCookie = setBookingCookie;