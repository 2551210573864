"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _react = require("@chakra-ui/react");
var _api = require("@whitbread-eos/api");
var _atoms = require("@whitbread-eos/atoms");
var _utils = require("@whitbread-eos/utils");
var _react2 = _interopRequireWildcard(require("react"));
var _jsxRuntime = require("react/jsx-runtime");
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && {}.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
var PaymentStatus = _ref => {
  var _bkngData$bookingConf, _bkngData$bookingConf2;
  var {
    basketReference,
    setPaymentFailedError,
    handleRetryPayment,
    handlePaymentComplete
  } = _ref;
  var {
    language,
    country
  } = (0, _utils.useCustomLocale)();
  var {
    isLoading: isLoadingBookingConfirmation,
    data: bkngData
  } = (0, _utils.useQueryRequest)(['GetBookingConfirmation', language, country, basketReference, _api.BOOKING_CHANNEL.PI], _api.GET_BOOKING_CONFIRMATION, {
    basketReference,
    language,
    country,
    bookingChannel: _api.BOOKING_CHANNEL.PI
  });
  var {
    pollingInProgress,
    basketStatus,
    dynamicSpinnerLabel,
    retryPayment
  } = (0, _utils.usePollBasketStatus)(basketReference !== null && basketReference !== void 0 ? basketReference : '', (_bkngData$bookingConf = bkngData === null || bkngData === void 0 || (_bkngData$bookingConf2 = bkngData.bookingConfirmation) === null || _bkngData$bookingConf2 === void 0 ? void 0 : _bkngData$bookingConf2.bookingSpinnerConfig) !== null && _bkngData$bookingConf !== void 0 ? _bkngData$bookingConf : []);
  (0, _react2.useEffect)(() => {
    if (basketStatus === _api.BASKET_STATUS.AMEND_FAILED || basketStatus === _api.BASKET_STATUS.FAILED) {
      setPaymentFailedError(true);
      if (retryPayment) handleRetryPayment();
    } else if (basketStatus === _api.BASKET_STATUS.AMENDED || basketStatus === _api.BASKET_STATUS.COMPLETED) handlePaymentComplete();
  }, [basketStatus, setPaymentFailedError, retryPayment]);
  if (!(isLoadingBookingConfirmation || pollingInProgress)) return null;
  return (0, _jsxRuntime.jsx)(_react.Flex, {
    height: "450px",
    justifyContent: "center",
    alignItems: "center",
    "data-testid": "Loading",
    children: (0, _jsxRuntime.jsx)(_atoms.LoadingSpinner, {
      loadingText: dynamicSpinnerLabel
    })
  });
};
var _default = exports.default = PaymentStatus;