"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = DonationsContainer;
var _api = require("@whitbread-eos/api");
var _molecules = require("@whitbread-eos/molecules");
var _utils = require("@whitbread-eos/utils");
var _jsxRuntime = require("react/jsx-runtime");
function DonationsContainer(_ref) {
  var {
    bookingInformation,
    onDonationChange,
    selectedDonation,
    bookingChannel
  } = _ref;
  var {
    language,
    country
  } = (0, _utils.useCustomLocale)();
  var {
    data,
    isError,
    error,
    isLoading
  } = (0, _utils.useQueryRequest)(['GetDonations', bookingInformation.hotelId, country, language, bookingInformation.ratePlanCode, bookingChannel], _api.GET_DONATIONS_QUERY, {
    country,
    language,
    hotelId: bookingInformation.hotelId,
    rateCode: bookingInformation.ratePlanCode,
    bookingChannel
  });
  return (0, _jsxRuntime.jsx)(_molecules.Donations, {
    data: data,
    isError,
    error,
    isLoading,
    onDonationChange: onDonationChange,
    selectedDonation: selectedDonation
  });
}