"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = ExtrasSection;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _react = require("@chakra-ui/react");
var _api = require("@whitbread-eos/api");
var _atoms = require("@whitbread-eos/atoms");
var _molecules = require("@whitbread-eos/molecules");
var _utils = require("@whitbread-eos/utils");
var _nextI18next = require("next-i18next");
var _jsxRuntime = require("react/jsx-runtime");
var _react2 = require("react");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function ExtrasSection(_ref) {
  var {
    extrasDetailsList,
    selectedRoom,
    selectedExtrasList,
    handleSelectedExtrasList,
    noNights,
    allRooms,
    isAmend = false
  } = _ref;
  var baseDataTestId = "ExtrasSection".concat(allRooms ? '-allRooms' : '');
  var {
    t
  } = (0, _nextI18next.useTranslation)();
  var extrasDetailsListSorted = extrasDetailsList === null || extrasDetailsList === void 0 ? void 0 : extrasDetailsList.sort((firstItem, secondItem) => {
    var _firstItem$order, _secondItem$order;
    return ((_firstItem$order = firstItem === null || firstItem === void 0 ? void 0 : firstItem.order) !== null && _firstItem$order !== void 0 ? _firstItem$order : 0) - ((_secondItem$order = secondItem === null || secondItem === void 0 ? void 0 : secondItem.order) !== null && _secondItem$order !== void 0 ? _secondItem$order : 0);
  });
  var countSelectedExtras = (extrasList, extrasId) => {
    var count = 0;
    extrasList === null || extrasList === void 0 || extrasList.forEach(room => {
      var _room$packagesList;
      if (room !== null && room !== void 0 && (_room$packagesList = room.packagesList) !== null && _room$packagesList !== void 0 && _room$packagesList.includes(extrasId)) {
        count++;
      }
    });
    return count;
  };
  var numberOfRooms = selectedExtrasList === null || selectedExtrasList === void 0 ? void 0 : selectedExtrasList.length;
  var sumPreviousEciSelection = selectedExtrasList === null || selectedExtrasList === void 0 ? void 0 : selectedExtrasList.reduce((sum, item) => sum + (item === null || item === void 0 ? void 0 : item.previousEciSelection), 0);
  var sumPreviousLcoSelection = selectedExtrasList === null || selectedExtrasList === void 0 ? void 0 : selectedExtrasList.reduce((sum, item) => sum + (item === null || item === void 0 ? void 0 : item.previousLcoSelection), 0);
  var totalInventoryEarlyCheckIn = countSelectedExtras(selectedExtrasList, _api.ExtrasId.EARLY_CHECK_IN);
  var totalInventoryLateCheckOut = countSelectedExtras(selectedExtrasList, _api.ExtrasId.LATE_CHECK_OUT);
  var checkInventoryAvailability = (extrasItemParam, allRooms) => {
    var {
      available,
      id
    } = extrasItemParam;
    var eciAvailibility = allRooms && numberOfRooms ? available + sumPreviousEciSelection >= numberOfRooms : available + sumPreviousEciSelection > totalInventoryEarlyCheckIn;
    var lcoAvailibility = allRooms && numberOfRooms ? available + sumPreviousLcoSelection >= numberOfRooms : available + sumPreviousLcoSelection > totalInventoryLateCheckOut;
    if (id === _api.ExtrasId.EARLY_CHECK_IN) {
      return eciAvailibility;
    }
    if (id === _api.ExtrasId.LATE_CHECK_OUT) {
      return lcoAvailibility;
    }
    return available === null;
  };
  var checkSelectedInventory = id => {
    switch (id) {
      case _api.ExtrasId.EARLY_CHECK_IN:
        return totalInventoryEarlyCheckIn;
      case _api.ExtrasId.LATE_CHECK_OUT:
        return totalInventoryLateCheckOut;
      default:
        return 1;
    }
  };
  var allRoomsAvailibility = extrasDetailsListSorted === null || extrasDetailsListSorted === void 0 ? void 0 : extrasDetailsListSorted.some(extrasItem => {
    if (extrasItem !== null && extrasItem !== void 0 && extrasItem.available && numberOfRooms) {
      if ((extrasItem === null || extrasItem === void 0 ? void 0 : extrasItem.id) === _api.ExtrasId.EARLY_CHECK_IN) return (extrasItem === null || extrasItem === void 0 ? void 0 : extrasItem.available) + sumPreviousEciSelection >= numberOfRooms;
      if ((extrasItem === null || extrasItem === void 0 ? void 0 : extrasItem.id) === _api.ExtrasId.LATE_CHECK_OUT) return (extrasItem === null || extrasItem === void 0 ? void 0 : extrasItem.available) + sumPreviousLcoSelection >= numberOfRooms;
    }
  });
  var oneExtrasNotAvailable = extrasDetailsListSorted === null || extrasDetailsListSorted === void 0 ? void 0 : extrasDetailsListSorted.some(extrasItem => (extrasItem === null || extrasItem === void 0 ? void 0 : extrasItem.id) === _api.ExtrasId.EARLY_CHECK_IN && (extrasItem === null || extrasItem === void 0 ? void 0 : extrasItem.available) + sumPreviousEciSelection < 1 || (extrasItem === null || extrasItem === void 0 ? void 0 : extrasItem.id) === _api.ExtrasId.LATE_CHECK_OUT && (extrasItem === null || extrasItem === void 0 ? void 0 : extrasItem.available) + sumPreviousLcoSelection < 1);
  var getExtrasNotificationLabel = extrasItem => {
    var {
      available,
      id
    } = extrasItem;
    var totalAvailable = available;
    if (id === _api.ExtrasId.EARLY_CHECK_IN) {
      totalAvailable = totalAvailable + sumPreviousEciSelection;
    }
    if (id === _api.ExtrasId.LATE_CHECK_OUT) {
      totalAvailable = totalAvailable + sumPreviousLcoSelection;
    }
    var extrasLabelsCheck = "".concat((0, _utils.extrasNamingCheck)(t, id));
    var minRoomsExtrasLabel = (0, _utils.renderSanitizedHtml)(t('ancillaries.extras.notification.min').replace('[packageName]', "".concat(extrasLabelsCheck)).replace('[numberOfPackages]', "".concat(totalAvailable)));
    var minAllRoomsExtrasLabel = (0, _utils.renderSanitizedHtml)(t('ancillaries.extras.notification.allRooms').replace('[packageName]', "".concat(extrasLabelsCheck)).replace('[numberOfPackages]', "".concat(totalAvailable)));
    var maxRoomsExtrasLabel = (0, _utils.renderSanitizedHtml)(t('ancillaries.extras.notification.max').replace('[packageName]', "".concat(extrasLabelsCheck)).replace('[packageName]', "".concat(extrasLabelsCheck).toLocaleLowerCase()).replace('[numberOfPackages]', "".concat(totalAvailable)));
    var displayAllRoomsNotification = totalAvailable && numberOfRooms && allRooms && totalAvailable < numberOfRooms && (allRoomsAvailibility || oneExtrasNotAvailable);
    var higherNumberOfRooms = totalAvailable && numberOfRooms && totalAvailable < numberOfRooms && !allRooms;
    if (totalAvailable && selectedExtrasList && numberOfRooms) {
      if (totalAvailable === checkSelectedInventory(id)) {
        if (numberOfRooms === 1) {
          return '';
        } else if (higherNumberOfRooms) {
          return maxRoomsExtrasLabel;
        }
      } else if (higherNumberOfRooms) {
        return minRoomsExtrasLabel;
      } else if (displayAllRoomsNotification) {
        return minAllRoomsExtrasLabel;
      }
    }
  };
  var getAllRoomsUnavailableNotification = extrasDetailsListSorted => {
    var extrasAvailable = {
      eci: 0,
      lco: 0
    };
    extrasDetailsListSorted === null || extrasDetailsListSorted === void 0 || extrasDetailsListSorted.forEach(extrasItem => {
      var {
        available,
        id
      } = extrasItem;
      if (available || sumPreviousEciSelection || sumPreviousLcoSelection) {
        if (id === _api.ExtrasId.EARLY_CHECK_IN) {
          extrasAvailable.eci = available + sumPreviousEciSelection;
        } else if (id === _api.ExtrasId.LATE_CHECK_OUT) {
          extrasAvailable.lco = available + sumPreviousLcoSelection;
        }
      }
    });
    var unavailableAllRoomsExtrasLabel = (0, _utils.renderSanitizedHtml)(t('ancillaries.extras.notification.packages.allRooms').replace('[numberOfPackages]', "".concat(extrasAvailable.eci)).replace('[numberOfPackages]', "".concat(extrasAvailable.lco)));
    var isAllRoomsNotificationAvailable = extrasAvailable.eci > 0 && extrasAvailable.lco > 0 && (numberOfRooms > extrasAvailable.eci || numberOfRooms > extrasAvailable.lco);
    return isAllRoomsNotificationAvailable && (0, _jsxRuntime.jsx)(_atoms.Notification, {
      maxWidth: "full",
      variant: "info",
      status: "info",
      title: '',
      description: unavailableAllRoomsExtrasLabel,
      svg: (0, _jsxRuntime.jsx)(_atoms.Info, {}),
      wrapperStyles: {
        mt: 'xl',
        mb: 'xl'
      }
    });
  };
  return (0, _jsxRuntime.jsxs)(_react.Box, _objectSpread(_objectSpread({}, wrapperStyle), {}, {
    "data-testid": (0, _utils.formatDataTestId)(baseDataTestId, 'Wrapper'),
    children: [!isAmend && (0, _jsxRuntime.jsx)(_react.Text, _objectSpread(_objectSpread({}, extrasTitleStyle), {}, {
      "data-testid": (0, _utils.formatDataTestId)(baseDataTestId, 'Heading-Title'),
      children: allRooms ? t('ancillaries.extras.allRooms.title') : t('upsell.extras.heading')
    })), !allRoomsAvailibility && allRooms && getAllRoomsUnavailableNotification(extrasDetailsListSorted), extrasDetailsListSorted === null || extrasDetailsListSorted === void 0 ? void 0 : extrasDetailsListSorted.map(extrasItem => {
      var _selectedExtrasList$s;
      var isRemovable = selectedExtrasList === null || selectedExtrasList === void 0 || (_selectedExtrasList$s = selectedExtrasList[selectedRoom]) === null || _selectedExtrasList$s === void 0 || (_selectedExtrasList$s = _selectedExtrasList$s.packagesList) === null || _selectedExtrasList$s === void 0 ? void 0 : _selectedExtrasList$s.includes(extrasItem === null || extrasItem === void 0 ? void 0 : extrasItem.id);
      var isExtrasAvailablePerItem = (extrasItem === null || extrasItem === void 0 ? void 0 : extrasItem.available) > 0 || (extrasItem === null || extrasItem === void 0 ? void 0 : extrasItem.available) === null || sumPreviousLcoSelection > 0 || sumPreviousEciSelection > 0;
      var displayExtra = isAmend ? isRemovable && extrasItem.id === _api.ExtrasId.ULTIMATE_WIFI : true;
      return (0, _jsxRuntime.jsx)(_jsxRuntime.Fragment, {
        children: isExtrasAvailablePerItem && displayExtra && (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
          children: [(0, _jsxRuntime.jsx)(_atoms.Notification, {
            maxWidth: "full",
            variant: "info",
            status: "info",
            title: '',
            description: getExtrasNotificationLabel(extrasItem),
            svg: (0, _jsxRuntime.jsx)(_atoms.Info, {}),
            wrapperStyles: {
              mt: 'xl',
              mb: 'xl'
            }
          }), (0, _react2.createElement)(_molecules.ExtrasItemComponent, _objectSpread(_objectSpread({}, extrasItem), {}, {
            key: "Room-".concat(selectedRoom, "-").concat(extrasItem.id),
            isRemovable: isRemovable,
            isAvailable: checkInventoryAvailability(extrasItem, allRooms),
            selectedRoom: selectedRoom,
            selectedExtrasList: selectedExtrasList,
            handleSelectedExtrasList: handleSelectedExtrasList,
            noNights: noNights,
            price: extrasItem === null || extrasItem === void 0 ? void 0 : extrasItem.price,
            allRooms: allRooms,
            numberOfRooms: numberOfRooms
          }))]
        })
      });
    })]
  }));
}
var extrasTitleStyle = {
  fontWeight: 'semibold',
  fontSize: {
    mobile: 'xl',
    sm: '2xl'
  },
  lineHeight: {
    mobile: '3',
    sm: '4'
  },
  color: 'darkGrey1'
};
var wrapperStyle = {
  flexDirection: 'column',
  alignItems: 'flex-start',
  gap: 2
};