"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.BBLeadGuestDetailsFormConfig = void 0;
var _atoms = require("@whitbread-eos/atoms");
var _utils = require("@whitbread-eos/utils");
var _BBGuestDetailsForm = _interopRequireDefault(require("../../guest-details/BBGuestDetailsForm"));
var _formValidation = _interopRequireDefault(require("./formValidation"));
var BBLeadGuestDetailsFormConfig = _ref => {
  var {
    getFormState,
    defaultValues,
    onSubmit,
    baseTestId,
    t,
    labels,
    validationLabels,
    numberOfRooms,
    queryClient,
    guestList,
    setGuestUser,
    isDynamicSearchVisible,
    isEdit,
    bbEmployeeList,
    defaultGuest,
    onEditBbInput
  } = _ref;
  var {
    formValidationSchema
  } = (0, _formValidation.default)(validationLabels);
  var config = {
    id: 'leadGuestDetailsForm',
    elements: {
      fields: [{
        type: _atoms.FORM_FIELD_TYPES.DYNAMIC_FIELD,
        dropdownOptions: (0, _utils.getTitleDropdownValues)((0, _utils.formatGuestTitleOptions)(t('booking.guest.nameTitles')), defaultGuest !== null && defaultGuest !== void 0 && defaultGuest.title ? defaultGuest.title : ''),
        name: 'guestDetailsBBForm',
        label: '',
        testid: (0, _utils.formatDataTestId)(baseTestId, 'Form'),
        Component: _BBGuestDetailsForm.default,
        props: {
          numberOfRooms,
          labels,
          defaultValues,
          queryClient,
          guestList,
          setGuestUser,
          isDynamicSearchVisible,
          isEdit,
          isAmendPage: true,
          bbEmployeeList,
          defaultGuest,
          onEditBbInput
        }
      }],
      onSubmitAction: onSubmit
    },
    defaultValues,
    validationSchema: formValidationSchema,
    getFormState
  };
  return config;
};
exports.BBLeadGuestDetailsFormConfig = BBLeadGuestDetailsFormConfig;