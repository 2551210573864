"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ATTACH_FILE_TO_RESERVATION = void 0;
var _taggedTemplateLiteral2 = _interopRequireDefault(require("@babel/runtime/helpers/taggedTemplateLiteral"));
var _graphqlRequest = require("graphql-request");
var _templateObject;
var ATTACH_FILE_TO_RESERVATION = exports.ATTACH_FILE_TO_RESERVATION = (0, _graphqlRequest.gql)(_templateObject || (_templateObject = (0, _taggedTemplateLiteral2.default)(["\n  mutation attachFileToReservation(\n    $fileName: String!\n    $reservationId: String!\n    $overwriteExistingFile: Boolean!\n    $description: String!\n    $hotelId: String!\n    $global: Boolean!\n    $fileAttachment: String!\n  ) {\n    attachFileToReservation(\n      fileAttachmentCriteria: {\n        fileName: $fileName\n        reservationId: $reservationId\n        overwriteExistingFile: $overwriteExistingFile\n        description: $description\n        hotelId: $hotelId\n        global: $global\n        fileAttachment: $fileAttachment\n      }\n    ) {\n      status\n      message\n    }\n  }\n"])));