"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.PurposeOfStay = void 0;
var PurposeOfStay = exports.PurposeOfStay = function (PurposeOfStay) {
  PurposeOfStay["LEISURE"] = "LEI";
  PurposeOfStay["BUSINESS"] = "BUS";
  return PurposeOfStay;
}({});