"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = SoldOutNotificationContainer;
var _utils = require("@whitbread-eos/utils");
var _SoldOutNotification = _interopRequireDefault(require("./SoldOutNotification.component"));
var _jsxRuntime = require("react/jsx-runtime");
function SoldOutNotificationContainer(_ref) {
  var {
    hotelAvailabilityResponse,
    isHotelOpeningSoon
  } = _ref;
  var {
    name
  } = (0, _utils.useStaticHotelInformation)();
  return (0, _jsxRuntime.jsx)(_SoldOutNotification.default, {
    isLoading: hotelAvailabilityResponse.isLoadingHotelAvailability,
    isError: hotelAvailabilityResponse.isErrorHotelAvailability,
    error: hotelAvailabilityResponse.errorHotelAvailability,
    data: hotelAvailabilityResponse.dataHotelAvailability,
    isHotelOpeningSoon,
    name
  });
}