"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = useBookingSpinner;
var _api = require("@whitbread-eos/api");
var _react = require("react");
function useBookingSpinner(basketStatus, bkngData, refetchBasketDataFn, t) {
  var _bkngData$bookingConf2;
  var [dynamicSpinnerLabel, setDynamicSpinnerLabel] = (0, _react.useState)(t('booking.loading'));
  var [timerAchieved, setTimerAchieved] = (0, _react.useState)(false);
  var [transactionComplete, setTransactionComplete] = (0, _react.useState)(false);
  (0, _react.useEffect)(() => {
    var _bkngData$bookingConf;
    var intervalId;
    if (basketStatus !== _api.BASKET_STATUS.COMPLETED && basketStatus !== _api.BASKET_STATUS.FAILED) {
      refetchBasketDataFn();
    } else {
      clearInterval(intervalId);
      return;
    }
    var bookingSpinnerConfig = bkngData === null || bkngData === void 0 || (_bkngData$bookingConf = bkngData.bookingConfirmation) === null || _bkngData$bookingConf === void 0 ? void 0 : _bkngData$bookingConf.bookingSpinnerConfig;
    if (bookingSpinnerConfig && (bookingSpinnerConfig === null || bookingSpinnerConfig === void 0 ? void 0 : bookingSpinnerConfig.length) > 0 && basketStatus !== _api.BASKET_STATUS.COMPLETED) {
      var [spinnerOneData, spinnerTwoData, spinnerThreeData] = bookingSpinnerConfig;
      var totalSpinningTime = +spinnerOneData.seconds + +spinnerTwoData.seconds + +spinnerThreeData.seconds;
      var startTime = new Date().getMilliseconds();
      var initialWaitTime = +spinnerOneData.seconds;
      var currentSecondTick = new Date(startTime).getSeconds();
      if (basketStatus === _api.BASKET_STATUS.PROCESSING && initialWaitTime > 0) {
        intervalId = setInterval(() => {
          refetchBasketDataFn();
          currentSecondTick++;
          if (basketStatus === _api.BASKET_STATUS.COMPLETED && currentSecondTick <= totalSpinningTime) {
            setTimerAchieved(false);
            setTransactionComplete(true);
            basketStatus = _api.BASKET_STATUS.COMPLETED;
            clearInterval(intervalId);
          } else if (basketStatus === _api.BASKET_STATUS.PROCESSING) {
            if (currentSecondTick <= +spinnerOneData.seconds) {
              setDynamicSpinnerLabel(spinnerOneData.text);
            } else if (currentSecondTick <= +spinnerTwoData.seconds) {
              setDynamicSpinnerLabel(spinnerTwoData.text);
            } else if (currentSecondTick <= +spinnerThreeData.seconds) {
              setDynamicSpinnerLabel(spinnerThreeData.text);
            } else if (currentSecondTick >= +spinnerOneData.seconds + +spinnerTwoData.seconds + +spinnerThreeData.seconds) {
              setTimerAchieved(true);
              basketStatus = _api.BASKET_STATUS.COMPLETED;
              clearInterval(intervalId);
            }
          }
        }, 1000);
      }
    }
    return () => {
      clearInterval(intervalId);
    };
  }, [!(bkngData !== null && bkngData !== void 0 && (_bkngData$bookingConf2 = bkngData.bookingConfirmation) !== null && _bkngData$bookingConf2 !== void 0 && _bkngData$bookingConf2.bookingSpinnerConfig), basketStatus]);
  return {
    isTimerAchieved: timerAchieved,
    isTransactionComplete: transactionComplete,
    dynamicSpinnerLabel
  };
}