"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = BartBookingDetailsReservationInformationComponent;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _react = require("@chakra-ui/react");
var _atoms = require("@whitbread-eos/atoms");
var _utils = require("@whitbread-eos/utils");
var _upperFirst = _interopRequireDefault(require("lodash/upperFirst"));
var _jsxRuntime = require("react/jsx-runtime");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function BartBookingDetailsReservationInformationComponent(_ref) {
  var {
    bookingType,
    baseDataTestId,
    sourcePms,
    rateType,
    isBart,
    t
  } = _ref;
  var badgeColorByPMS = isBart ? 'zipSecondary' : 'primary';
  return (0, _jsxRuntime.jsxs)(_react.Flex, {
    direction: "column",
    mb: "lg",
    "data-testid": (0, _utils.formatDataTestId)(baseDataTestId, 'BartBookingdDetailsReservationInformation'),
    children: [(0, _jsxRuntime.jsx)(_react.Flex, _objectSpread(_objectSpread({}, divStyles), {}, {
      children: (0, _jsxRuntime.jsx)(_atoms.Badge, _objectSpread(_objectSpread({
        variant: "primary",
        badgecolor: badgeColorByPMS
      }, getBadgeStyles), {}, {
        children: (0, _upperFirst.default)(sourcePms.toLowerCase())
      }))
    })), (0, _jsxRuntime.jsx)(_react.Flex, {
      justifyContent: "space-between",
      mt: "lg",
      children: (0, _jsxRuntime.jsxs)(_react.Text, _objectSpread(_objectSpread({}, labelStyle), {}, {
        children: [t('ccui.manageBooking.typeOfBooking'), (0, _jsxRuntime.jsx)(_react.Text, {
          as: "span",
          fontWeight: "normal",
          children: ": ".concat(bookingType)
        })]
      }))
    }), (0, _jsxRuntime.jsx)(_react.Flex, {
      justifyContent: "space-between",
      children: (0, _jsxRuntime.jsxs)(_react.Text, _objectSpread(_objectSpread({}, labelStyle), {}, {
        children: [t('ccui.manageBooking.rateType'), (0, _jsxRuntime.jsx)(_react.Text, {
          as: "span",
          fontWeight: "normal",
          children: ": ".concat(rateType)
        })]
      }))
    })]
  });
}
var getBadgeStyles = {
  lineHeight: 'var(--chakra-lineHeights-2)'
};
var divStyles = {
  width: '3.563rem'
};
var labelStyle = {
  fontWeight: 'semibold',
  fontSize: 'md',
  lineHeight: '3',
  color: 'darkGrey1',
  as: 'span'
};