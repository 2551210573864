"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = HotelFacilitiesListComponent;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _react = require("@chakra-ui/react");
var _atoms = require("@whitbread-eos/atoms");
var _utils = require("@whitbread-eos/utils");
var _nextI18next = require("next-i18next");
var _react2 = require("react");
var _HotelFacilitiesModal = _interopRequireDefault(require("../HotelFacilitiesModal"));
var _jsxRuntime = require("react/jsx-runtime");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function HotelFacilitiesListComponent(_ref) {
  var {
    data,
    isLoading,
    isError,
    error,
    isLessThanSm
  } = _ref;
  var {
    t
  } = (0, _nextI18next.useTranslation)(['common']);
  var [isModalVisible, setIsModalVisible] = (0, _react2.useState)(false);
  if (isLoading) {
    return (0, _jsxRuntime.jsx)(_react.Text, {
      children: t('searchresults.list.hotel.loading')
    });
  }
  if (isError) {
    return (0, _jsxRuntime.jsx)(_react.Text, {
      children: error.message
    });
  }
  if (!(data !== null && data !== void 0 && data.length)) {
    return null;
  }
  var visibleFacilities = data === null || data === void 0 ? void 0 : data.filter(item => item.isVisible).slice(0, isLessThanSm ? 4 : 6);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [renderHeading(), renderItems(), renderModal()]
  });
  function renderHeading() {
    var headingStyles = {
      my: 'md',
      fontSize: {
        base: 'xl',
        sm: 'lg'
      },
      lineHeight: '3'
    };
    var linkStyles = {
      fontSize: 'sm',
      color: 'btnSecondaryEnabled',
      textDecoration: 'underline'
    };
    return (0, _jsxRuntime.jsxs)(_react.Box, _objectSpread(_objectSpread({}, headingStyles), {}, {
      "data-testid": "hdp_hotelFacilitiesText",
      children: [(0, _jsxRuntime.jsx)("strong", {
        children: t('hoteldetails.hotel.facilities')
      }), !isLessThanSm && (0, _jsxRuntime.jsx)(_react.Link, _objectSpread(_objectSpread({}, linkStyles), {}, {
        onClick: openModal,
        pl: "sm",
        children: t('hoteldetails.seeall')
      }))]
    }));
  }
  function renderItems() {
    var linkStyles = {
      fontSize: 'sm',
      color: 'btnSecondaryEnabled',
      textDecoration: 'underline'
    };
    return (0, _jsxRuntime.jsxs)(_react.Stack, {
      direction: {
        base: 'column',
        sm: 'row'
      },
      spacing: '0',
      "data-testid": "hdp_hotelFacilities",
      children: [visibleFacilities === null || visibleFacilities === void 0 ? void 0 : visibleFacilities.map((hotelFacility, index) => {
        var _hotelFacility$name, _hotelFacility$name2;
        return (0, _jsxRuntime.jsx)(_react.Box, {
          children: isLessThanSm ? (0, _jsxRuntime.jsxs)(_react.HStack, {
            cursor: "pointer",
            onClick: openModal,
            "data-testid": (0, _utils.formatDataTestId)(hotelFacility.name, 'HotelFacilitiesHStack'),
            children: [(0, _jsxRuntime.jsx)(_atoms.Icon, {
              src: (0, _utils.formatAssetsUrl)(hotelFacility.icon),
              w: "6"
            }), (0, _jsxRuntime.jsx)(_react.Text, {
              children: hotelFacility === null || hotelFacility === void 0 || (_hotelFacility$name = hotelFacility.name) === null || _hotelFacility$name === void 0 ? void 0 : _hotelFacility$name.replace(/-/gm, '\u2011')
            })]
          }) : (0, _jsxRuntime.jsxs)(_react.VStack, {
            cursor: "pointer",
            onClick: openModal,
            "data-testid": (0, _utils.formatDataTestId)(hotelFacility.name, 'HotelFacilitiesVStack'),
            m: ['0 16px'],
            ml: index === 0 ? 0 : undefined,
            children: [(0, _jsxRuntime.jsx)(_atoms.Icon, {
              src: (0, _utils.formatAssetsUrl)(hotelFacility.icon),
              cursor: "pointer",
              h: "6",
              onClick: openModal
            }), (0, _jsxRuntime.jsx)(_react.Text, {
              fontSize: "xs",
              lineHeight: "2",
              textAlign: "center",
              children: hotelFacility === null || hotelFacility === void 0 || (_hotelFacility$name2 = hotelFacility.name) === null || _hotelFacility$name2 === void 0 ? void 0 : _hotelFacility$name2.replace(/-/gm, '\u2011')
            })]
          })
        }, hotelFacility.code);
      }), isLessThanSm && (0, _jsxRuntime.jsx)(_react.Box, {
        children: (0, _jsxRuntime.jsx)(_react.Link, _objectSpread(_objectSpread({}, linkStyles), {}, {
          mt: "md",
          display: "block",
          onClick: openModal,
          "data-testid": "hdp_mobileSeeAllHotelFacilities",
          children: t('hoteldetails.seeall')
        }))
      })]
    });
  }
  function renderModal() {
    return (0, _jsxRuntime.jsx)(_HotelFacilitiesModal.default, {
      facilities: data,
      isModalVisible: isModalVisible,
      onModalClose: () => setIsModalVisible(false)
    });
  }
  function openModal() {
    setIsModalVisible(true);
  }
}