"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var React = _interopRequireWildcard(require("react"));
var _jsxRuntime = require("react/jsx-runtime");
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && {}.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
var SvgPlayArea = props => (0, _jsxRuntime.jsx)("svg", _objectSpread(_objectSpread({
  width: 22,
  height: 18,
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg"
}, props), {}, {
  children: (0, _jsxRuntime.jsx)("path", {
    fillRule: "evenodd",
    clipRule: "evenodd",
    d: "M21.372 13.665a.5.5 0 0 1-.038.707C18.646 16.792 15.193 18 11 18S3.354 16.791.666 14.372a.5.5 0 1 1 .668-.744C3.831 15.875 7.044 17 11 17c3.955 0 7.169-1.125 9.666-3.372a.5.5 0 0 1 .706.037ZM11 10c3.085 0 5.11 1.657 5.983 4.869a.5.5 0 0 1-.966.262C15.26 12.343 13.62 11 11 11c-2.62 0-4.26 1.343-5.017 4.131a.5.5 0 0 1-.966-.262C5.89 11.657 7.915 10 11 10Zm7-10v1.639l1.121.376c1.463.366 2.297 1.147 2.373 2.308l.006.177c0 .946-.683 1.448-1.817 1.496L19.5 6h-1.308a.5.5 0 0 0-.5.497l.008.09 1.292 7.326a.5.5 0 0 1-.96.261l-.024-.087-1.293-7.326a1.5 1.5 0 0 1 1.216-1.738l.13-.017.13-.006H19.5c.756 0 1-.162 1-.5 0-.698-.435-1.174-1.46-1.472l-.198-.054L17 2.36V1.015l-.136.01c-1.237.107-1.806.554-1.86 1.347L15 2.5V6c0 1.965-1.242 3-3.5 3-2.18 0-3.413-.965-3.496-2.8L8 6H5.5c-.676 0-.961.24-.996.861L4.5 7c0 1.851-.932 3.342-2.743 4.429a.5.5 0 1 1-.514-.858C2.689 9.703 3.425 8.6 3.494 7.22L3.5 7c0-1.218.66-1.933 1.83-1.996L5.5 5H14V2.5C14 .897 15.168.06 17.263.003L17.5 0h.5ZM5.658 7.526a.5.5 0 0 1 .337.544l-.02.088-2 6a.5.5 0 0 1-.97-.228l.02-.088 2-6a.5.5 0 0 1 .633-.316ZM14 6H9c0 1.368.758 2 2.5 2 1.672 0 2.438-.582 2.496-1.84L14 6Zm3.5-3a.5.5 0 1 1 0 1 .5.5 0 0 1 0-1Z",
    fill: props.color || '#58595B'
  })
}));
var _default = exports.default = SvgPlayArea;