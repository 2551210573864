"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = useCookieForABTesting;
var _react = require("react");
var _helpers = require("../helpers");
function useCookieForABTesting(cookieName, expectedMode) {
  var [hasCookie, setHasCookie] = (0, _react.useState)(false);
  (0, _react.useEffect)(() => {
    var cookieValue = (0, _helpers.getCookie)(cookieName);
    setHasCookie(cookieValue && cookieValue === expectedMode);
  }, [cookieName, expectedMode]);
  return hasCookie;
}