"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = TotalCost;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _react = require("@chakra-ui/react");
var _api = require("@whitbread-eos/api");
var _atoms = require("@whitbread-eos/atoms");
var _utils = require("@whitbread-eos/utils");
var _nextI18next = require("next-i18next");
var _router = require("next/router");
var _react2 = _interopRequireWildcard(require("react"));
var yup = _interopRequireWildcard(require("yup"));
var _RoomRatePolicies = _interopRequireDefault(require("../RoomRatePolicies"));
var _jsxRuntime = require("react/jsx-runtime");
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && {}.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function TotalCost(_ref) {
  var {
    discount = 0,
    totalCost,
    hotelName,
    hotelId,
    hotelBrand,
    currencyCode = 'GBP',
    language = 'en',
    disabledOption = '',
    country,
    isSectionVisible = true,
    hasAllChecks,
    setSendEmail,
    isCompWithoutEckoh,
    onConfirmClick,
    isDiscountApplied,
    previousTotalCost,
    emailSection,
    sendEmail,
    continueWithPayment,
    isFromChangePaymentBIC = false
  } = _ref;
  var baseTestId = 'totalCostSection';
  var [isRoomRatePoliciesChecked, setIsRoomRatePoliciesChecked] = (0, _react2.useState)(false);
  var hasEmailAddress = emailSection !== null && emailSection !== void 0 && emailSection.emailAddress ? !(emailSection !== null && emailSection !== void 0 && emailSection.emailError) : true;
  var isButtonEnabled = !sendEmail ? isRoomRatePoliciesChecked && hasAllChecks && hasEmailAddress : isRoomRatePoliciesChecked && hasAllChecks && sendEmail && (emailSection === null || emailSection === void 0 ? void 0 : emailSection.emailAddress) && !(emailSection !== null && emailSection !== void 0 && emailSection.emailError);
  var [typeOfConfirmation, setTypeOfConfirmation] = (0, _react2.useState)(_api.EmailConfirmation.SEND_EMAIL);
  var router = (0, _router.useRouter)();
  (0, _react2.useEffect)(() => {
    if (disabledOption === _api.EmailConfirmation.SEND_EMAIL) {
      setTypeOfConfirmation(_api.EmailConfirmation.NO_SEND_EMAIL);
      setSendEmail(false);
    }
    if (disabledOption === _api.EmailConfirmation.NO_SEND_EMAIL) {
      setTypeOfConfirmation(_api.EmailConfirmation.SEND_EMAIL);
      setSendEmail(true);
    }
  }, [disabledOption]);
  (0, _react2.useEffect)(() => {
    if (!sendEmail && !(emailSection !== null && emailSection !== void 0 && emailSection.emailAddress)) {
      emailSection === null || emailSection === void 0 || emailSection.setEmailError('');
    }
  }, [sendEmail]);
  (0, _react2.useEffect)(() => {
    if (isCompWithoutEckoh && router.pathname === _api.AMEND_A2C_PAYMENT_PATH && !isFromChangePaymentBIC) {
      setIsRoomRatePoliciesChecked(true);
    }
  }, [isCompWithoutEckoh]);
  var {
    t
  } = (0, _nextI18next.useTranslation)(['common']);
  var yupSchema = getYupSchema(sendEmail, t('config.errorMessages.spf.email.invalid'), t('booking.login.required.text'));
  var {
    bookingSummaryLabels
  } = (0, _utils.getAmendSectionTranslations)(t);
  if (!isSectionVisible) return (0, _jsxRuntime.jsx)(_jsxRuntime.Fragment, {});
  return (0, _jsxRuntime.jsxs)(_react.Flex, _objectSpread(_objectSpread({}, wrapperStyles), {}, {
    "data-testid": baseTestId,
    children: [isDiscountApplied && renderDiscountSection(discount), (0, _jsxRuntime.jsxs)(_react.Flex, _objectSpread(_objectSpread({}, totalCostSectionStyles.wrapper), {}, {
      "data-testid": "".concat(baseTestId, "_").concat(isDiscountApplied ? 'new-total-cost' : 'total-cost'),
      children: [(0, _jsxRuntime.jsx)(_react.Flex, _objectSpread(_objectSpread({}, textWrapperStyles), {}, {
        mb: 1,
        "data-testid": "".concat(baseTestId, "_").concat(isDiscountApplied ? 'new-total-cost-title' : 'total-cost-title'),
        children: (0, _jsxRuntime.jsx)(_react.Heading, _objectSpread(_objectSpread({
          as: "h6"
        }, totalCostSectionStyles.title), {}, {
          "data-testid": "".concat(baseTestId, "_").concat(isDiscountApplied ? 'new-total-cost-title-heading' : 'total-cost-title-heading'),
          children: isDiscountApplied ? t('ccui.payment.confirmBooking.newTotalCost') : t('booking.summary.totalPrice')
        }))
      })), (0, _jsxRuntime.jsx)(_react.Flex, _objectSpread(_objectSpread({}, textWrapperStyles), {}, {
        mb: 2,
        h: 8,
        "data-testid": "".concat(baseTestId, "_total-cost-price"),
        children: (0, _jsxRuntime.jsx)(_react.Heading, _objectSpread(_objectSpread({
          as: "h2"
        }, totalCostSectionStyles.amount), {}, {
          "data-testid": "".concat(baseTestId, "_total-cost-heading"),
          children: renderPrice(totalCost, currencyCode)
        }))
      })), (0, _jsxRuntime.jsx)(_react.Flex, _objectSpread(_objectSpread({}, textWrapperStyles), {}, {
        "data-testid": "".concat(baseTestId, "_total-cost-hotel-name"),
        children: (0, _jsxRuntime.jsx)(_react.Heading, _objectSpread(_objectSpread({
          as: "h5"
        }, totalCostSectionStyles.hotel), {}, {
          "data-testid": "".concat(baseTestId, "_total-cost-hotel-name-heading"),
          children: hotelName
        }))
      }))]
    })), (0, _jsxRuntime.jsx)(_react.Box, _objectSpread(_objectSpread({}, policiesWrapperStyles), {}, {
      "data-testid": "".concat(baseTestId, "_room-rate-policies-wrapper"),
      children: (0, _jsxRuntime.jsx)(_RoomRatePolicies.default, {
        isRoomRatePoliciesChecked: isRoomRatePoliciesChecked,
        onChange: () => setIsRoomRatePoliciesChecked(!isRoomRatePoliciesChecked),
        language: language,
        country: country,
        hotelId: hotelId,
        hotelBrand: hotelBrand
      })
    })), (0, _jsxRuntime.jsx)(_react.Box, {
      mb: 8,
      "data-testid": "".concat(baseTestId, "_options"),
      children: (0, _jsxRuntime.jsxs)(_react.RadioGroup, {
        value: typeOfConfirmation,
        onChange: option => {
          setTypeOfConfirmation(option);
          if (option === _api.EmailConfirmation.SEND_EMAIL) {
            setSendEmail(true);
          } else {
            setSendEmail(false);
          }
        },
        "data-testid": "".concat(baseTestId, "_radio-group"),
        children: [(0, _jsxRuntime.jsx)(_atoms.RadioButton, {
          value: _api.EmailConfirmation.SEND_EMAIL,
          variant: "borderless",
          mb: 6,
          "data-testid": "".concat(baseTestId, "_send-mail"),
          isDisabled: _api.EmailConfirmation.SEND_EMAIL === disabledOption,
          type: _api.EmailConfirmation.SEND_EMAIL || '',
          children: (0, _jsxRuntime.jsx)(_react.Text, {
            "data-testid": "".concat(baseTestId, "_text-send-mail"),
            fontWeight: _api.EmailConfirmation.SEND_EMAIL === typeOfConfirmation ? '600' : '400',
            children: t('ccui.payment.confirmBooking.textSendMail')
          })
        }), (0, _jsxRuntime.jsx)(_atoms.RadioButton, {
          value: _api.EmailConfirmation.NO_SEND_EMAIL,
          variant: "borderless",
          "data-testid": "".concat(baseTestId, "_no-send-mail"),
          isDisabled: _api.EmailConfirmation.NO_SEND_EMAIL === disabledOption,
          type: _api.EmailConfirmation.NO_SEND_EMAIL || '',
          children: (0, _jsxRuntime.jsx)(_react.Text, {
            "data-testid": "".concat(baseTestId, "_text-no-send-mail"),
            fontWeight: _api.EmailConfirmation.NO_SEND_EMAIL === typeOfConfirmation ? '600' : '400',
            children: t('ccui.payment.confirmBooking.textNoMail')
          })
        })]
      })
    }), (0, _jsxRuntime.jsx)(_react.Box, {
      mb: 8,
      w: {
        mobile: 'full',
        xs: 'full',
        sm: '25.063rem',
        md: '26.25rem',
        lg: '24.5rem',
        xl: '26.25rem'
      },
      children: (0, _jsxRuntime.jsx)(_atoms.Input, {
        name: "emailAddress",
        value: emailSection === null || emailSection === void 0 ? void 0 : emailSection.emailAddress,
        onChange: email => {
          emailSection === null || emailSection === void 0 || emailSection.setEmailAddress(email);
          emailSection === null || emailSection === void 0 || emailSection.setEmailError('');
          yupSchema.validate({
            email: email
          }).catch(err => {
            var _err$errors;
            emailSection === null || emailSection === void 0 || emailSection.setEmailError(err === null || err === void 0 || (_err$errors = err.errors) === null || _err$errors === void 0 ? void 0 : _err$errors[0]);
          });
        },
        isInputAriaRequired: sendEmail,
        type: "email",
        placeholderText: sendEmail ? t('ccui.payment.emailAddress.placeholder.required') : t('ccui.payment.emailAddress.placeholder'),
        label: sendEmail ? t('ccui.payment.emailAddress.label') : t('ccui.idv.bookingInformation.emailAddress'),
        error: emailSection === null || emailSection === void 0 ? void 0 : emailSection.emailError
      })
    }), isCompWithoutEckoh ? (0, _jsxRuntime.jsx)(_react.Button, {
      w: {
        mobile: 'full',
        xs: 'full',
        sm: '25.063rem',
        md: '18rem',
        lg: '18rem',
        xl: '19.313rem'
      },
      variant: "primary",
      onClick: onConfirmClick,
      isDisabled: !isButtonEnabled,
      "data-testid": "".concat(baseTestId, "_confirm-booking-total-cost"),
      children: isFromChangePaymentBIC ? bookingSummaryLabels.confirmChangesLabel : t('ccui.payment.confirmBooking.button')
    }) : (0, _jsxRuntime.jsx)(_react.Button, {
      form: "billingAddressForm",
      w: {
        mobile: 'full',
        xs: 'full',
        sm: '25.063rem',
        md: '18.5rem',
        lg: '18rem',
        xl: '19.313rem'
      },
      type: "submit",
      onClick: continueWithPayment,
      variant: "primary",
      isDisabled: !isButtonEnabled,
      "data-testid": "".concat(baseTestId, "_confirm-booking-total-cost"),
      children: isFromChangePaymentBIC ? bookingSummaryLabels.confirmChangesLabel : t('ccui.payment.confirmBooking.button')
    })]
  }));
  function getYupSchema(isRequired, invalidEmailError, requiredError) {
    return isRequired ? yup.object().shape({
      email: yup.string().email(invalidEmailError).max(_atoms.FORM_VALIDATIONS.EMAIL.MAX, invalidEmailError).required(requiredError)
    }) : yup.object().shape({
      email: yup.string().email(invalidEmailError).max(_atoms.FORM_VALIDATIONS.EMAIL.MAX, invalidEmailError)
    });
  }
  function renderDiscountSection(discount) {
    return (0, _jsxRuntime.jsxs)(_react.Box, {
      "data-testid": "".concat(baseTestId, "_discount"),
      w: 64,
      mb: 8,
      children: [(0, _jsxRuntime.jsxs)(_react.Text, _objectSpread(_objectSpread({
        as: "p"
      }, discountSectionStyles.title), {}, {
        children: ["".concat(t('ccui.payment.confirmBooking.discount.title'), " "), (0, _jsxRuntime.jsx)(_react.Text, {
          as: "span",
          fontWeight: 700,
          children: renderPrice(discount, currencyCode)
        })]
      })), (0, _jsxRuntime.jsx)(_react.Text, _objectSpread(_objectSpread({
        as: "p"
      }, discountSectionStyles.previousTotalCost), {}, {
        children: t('ccui.payment.confirmBooking.discount.previousTotalCostMessage')
      })), (0, _jsxRuntime.jsx)(_react.Text, _objectSpread(_objectSpread({
        as: "p"
      }, discountSectionStyles.previousTotalCostValue), {}, {
        children: renderPrice(previousTotalCost, currencyCode)
      }))]
    });
  }
  function renderPrice(amount, currencyCode) {
    var formattedAmount = amount.toFixed(2);
    return (0, _utils.formatPrice)((0, _utils.formatCurrency)(currencyCode), formattedAmount, language);
  }
}
var policiesWrapperStyles = {
  overflow: 'hidden',
  w: 'full',
  maxW: 'full',
  mb: 6
};
var textWrapperStyles = {
  w: 'full',
  h: 6,
  alignItems: 'center'
};
var wrapperStyles = {
  boxShadow: '0px 0px 12px var(--chakra-colors-lightGrey4)',
  mt: 16,
  alignItems: 'flex-start',
  flexDirection: 'column',
  p: 8,
  w: {
    mobile: 'full',
    xs: 'full',
    sm: '25.063rem',
    md: '54.5rem',
    lg: '54rem',
    xl: '50.5rem'
  }
};
var discountSectionStyles = {
  title: {
    fontSize: 'xs',
    lineHeight: 2,
    fontWeight: 'normal',
    color: 'darkGrey1',
    h: 5
  },
  previousTotalCost: {
    fontSize: 'md',
    lineHeight: 3,
    fontWeight: 'normal',
    color: 'lightGrey1',
    textDecorationLine: 'line-through',
    w: '13rem',
    h: '1.188rem'
  },
  previousTotalCostValue: {
    fontSize: 'md',
    lineHeight: 3,
    fontWeight: 'normal',
    color: 'lightGrey1',
    textDecorationLine: 'line-through',
    w: 20,
    h: '1.063rem'
  }
};
var totalCostSectionStyles = {
  wrapper: {
    flexDir: 'column',
    w: 'full',
    mb: 6
  },
  title: {
    fontSize: 'md',
    lineHeight: 3,
    fontWeight: 'semibold',
    color: 'darkGrey1',
    w: 'full'
  },
  amount: {
    fontSize: '3xl',
    lineHeight: 4,
    fontWeight: 'bold',
    color: 'darkGrey1',
    w: 'full'
  },
  hotel: {
    fontSize: 'lg',
    lineHeight: 3,
    fontWeight: 'semibold',
    color: 'darkGrey1',
    w: 'full'
  }
};