"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = HotelFacilitiesListContainer;
var _utils = require("@whitbread-eos/utils");
var _HotelFacilitiesList = _interopRequireDefault(require("./HotelFacilitiesList.component"));
var _jsxRuntime = require("react/jsx-runtime");
function HotelFacilitiesListContainer(_ref) {
  var {
    isLessThanSm
  } = _ref;
  var {
    hotelFacilities,
    isLoading,
    isError,
    error
  } = (0, _utils.useStaticHotelInformation)();
  return (0, _jsxRuntime.jsx)(_HotelFacilitiesList.default, {
    isLoading,
    isError,
    data: hotelFacilities,
    error,
    isLessThanSm
  });
}