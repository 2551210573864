"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.GET_OVERRIDE_REASONS = void 0;
var _taggedTemplateLiteral2 = _interopRequireDefault(require("@babel/runtime/helpers/taggedTemplateLiteral"));
var _graphqlRequest = require("graphql-request");
var _templateObject;
var GET_OVERRIDE_REASONS = exports.GET_OVERRIDE_REASONS = (0, _graphqlRequest.gql)(_templateObject || (_templateObject = (0, _taggedTemplateLiteral2.default)(["\n  query GetOverrideReasons($hotelId: String!) {\n    cancellationReasons(hotelId: $hotelId) {\n      cancellationReasons {\n        active\n        code\n        description\n        name\n        managerApprovalNeeded\n      }\n    }\n  }\n"])));