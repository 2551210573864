"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "BookingDetailsController", {
  enumerable: true,
  get: function get() {
    return _BookingDetailsController2.default;
  }
});
Object.defineProperty(exports, "BookingDetailsControllerComponent", {
  enumerable: true,
  get: function get() {
    return _BookingDetailsController.default;
  }
});
var _BookingDetailsController = _interopRequireDefault(require("./BookingDetailsController.component"));
var _BookingDetailsController2 = _interopRequireDefault(require("./BookingDetailsController.container"));