"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = ResultRow;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _react = require("@chakra-ui/react");
var _api = require("@whitbread-eos/api");
var _atoms = require("@whitbread-eos/atoms");
var _utils = require("@whitbread-eos/utils");
var _react2 = require("react");
var _jsxRuntime = require("react/jsx-runtime");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function ResultRow(_ref) {
  var _cells$find, _cells$find2;
  var {
    cells,
    bookerLastName,
    bookingReference,
    arrivalDate,
    bartId,
    baseDataTestId,
    t,
    rowNr,
    isExpandedByDefault,
    bartCard,
    operaCard,
    bartHotelName
  } = _ref;
  var [isExpanded, setIsExpanded] = (0, _react2.useState)(isExpandedByDefault);
  var sourcePms = (_cells$find = cells.find(cell => cell.id === 'SourcePms')) === null || _cells$find === void 0 ? void 0 : _cells$find.value;
  var rowCount = cells.length + 1;
  var bookingType = (_cells$find2 = cells.find(cell => cell.id === 'Status')) === null || _cells$find2 === void 0 ? void 0 : _cells$find2.value;
  var {
    language
  } = (0, _utils.useCustomLocale)();
  var formatHeader = (headerName, headerValue) => {
    if (headerName === 'Hotel' && headerValue === '') {
      return bartHotelName;
    }
    if (headerName === 'Date') {
      return (0, _utils.formatDate)(headerValue, 'EEE d LLL yyyy', language);
    }
    return headerValue;
  };
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsxs)(_react.Tr, {
      "data-testid": (0, _utils.formatDataTestId)(baseDataTestId, "Table-Row-".concat(rowNr)),
      children: [cells.map(cell => {
        if (cell.id === 'Status') {
          return (0, _jsxRuntime.jsx)(_react.Td, _objectSpread(_objectSpread({
            "data-testid": (0, _utils.formatDataTestId)(baseDataTestId, "Table-Cell-".concat(cell.id))
          }, resultRowCellStyle(isExpanded)), {}, {
            children: cell.label
          }), cell.id);
        }
        return (0, _jsxRuntime.jsx)(_react.Td, _objectSpread(_objectSpread({
          "data-testid": (0, _utils.formatDataTestId)(baseDataTestId, "Table-Cell-".concat(cell.id))
        }, resultRowCellStyle(isExpanded)), {}, {
          children: formatHeader(cell.id, cell.value)
        }), cell.id);
      }), (0, _jsxRuntime.jsx)(_react.Td, _objectSpread(_objectSpread({}, resultRowCellStyle(isExpanded)), {}, {
        children: (0, _jsxRuntime.jsxs)(_react.Link, _objectSpread(_objectSpread({}, linkStyles), {}, {
          "data-testid": "hdp_basketHideBreakdownLink",
          onClick: () => setIsExpanded(!isExpanded),
          children: [isExpanded ? 'Close' : 'Open', (0, _jsxRuntime.jsx)(_react.Box, {
            ml: "xmd",
            children: !isExpanded ? (0, _jsxRuntime.jsx)(_atoms.ChevronDown, {
              color: "var(--chakra-colors-btnSecondaryEnabled)"
            }) : (0, _jsxRuntime.jsx)(_atoms.ChevronUp, {
              color: "var(--chakra-colors-btnSecondaryEnabled)"
            })
          })]
        }))
      }))]
    }), isExpanded && (0, _jsxRuntime.jsx)(_react.Tr, {
      children: (0, _jsxRuntime.jsx)(_react.Td, _objectSpread(_objectSpread({
        colSpan: rowCount
      }, cardRowStyle), {}, {
        children: (0, _jsxRuntime.jsx)(_atoms.ErrorBoundary, {
          errorMessage: getBICErrorBoundaryMessage(t, bookingReference, !bartId),
          children: bartId ? bartCard({
            bookerLastName,
            arrivalDate,
            bartId,
            bookingReference: bookingReference,
            sourcePms,
            t
          }) : operaCard({
            area: _api.Area.CCUI,
            bookingReference: bookingReference,
            basketReference: null,
            sourcePms,
            bookingType
          })
        })
      }))
    })]
  });
}
function getBICErrorBoundaryMessage(t, bookingReference) {
  var isOperaReservation = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : true;
  var errorMessage = isOperaReservation ? t('ccui.manageBooking.resultList.bic.operaErrorMessage') : t('ccui.manageBooking.resultList.bic.bartErrorMessage');
  return errorMessage === null || errorMessage === void 0 ? void 0 : errorMessage.replace('<bookingReference>', bookingReference);
}
var cardRowStyle = {
  borderTop: 'none'
};
var resultRowCellStyle = isExpanded => {
  return {
    borderBottom: isExpanded ? 'none' : 'var(--chakra-borders-1px)'
  };
};
var linkStyles = {
  display: 'flex',
  alignItems: 'center',
  color: 'btnSecondaryEnabled',
  textDecoration: 'underline'
};