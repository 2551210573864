"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = BasketQueryWrapper;
exports.getStandardRoomLabel = getStandardRoomLabel;
var _reactQuery = require("@tanstack/react-query");
var _Basket = _interopRequireDefault(require("./Basket.container"));
var _jsxRuntime = require("react/jsx-runtime");
function BasketQueryWrapper(_ref) {
  var {
    channel,
    variant,
    roomClassIndexFromSelectedRate,
    roomClassCode = '',
    queryClient,
    roomClass,
    isCityTaxExempt,
    isLastFewRooms,
    isHDPBasket,
    isLessThanLg,
    hasAccessibleRoom,
    hasTwinRoomChoice,
    shouldDisplayMobileBasket,
    hotelId,
    arrival,
    departure,
    numberOfUnits,
    selectedRate,
    numberOfNights,
    rateName,
    roomTypeInformationResponse,
    selectedPMSRoomTypes,
    selectedSpecialRequests,
    bookRsvIsLoading,
    bookRsvIsError,
    bookRsvError,
    handleBooking,
    brand,
    isDisabledContinueBtn,
    twinroomSelections,
    roomsLabelsForSilentSubst,
    isSilentFeatureFlagEnabled,
    saveLabelsForSilentSubstAndRedirect,
    setResRoomTypes,
    setCurrentClassRoomTypes,
    companyData,
    selectedRateCategory,
    isPrePopulateBillingAddressEnabled
  } = _ref;
  return (0, _jsxRuntime.jsx)(_reactQuery.QueryClientProvider, {
    client: queryClient,
    children: (0, _jsxRuntime.jsx)(_Basket.default, {
      channel,
      variant,
      roomClassIndexFromSelectedRate,
      roomClassCode,
      queryClient,
      roomClass,
      isCityTaxExempt,
      isLastFewRooms,
      isHDPBasket,
      isLessThanLg,
      hasAccessibleRoom,
      hasTwinRoomChoice,
      shouldDisplayMobileBasket,
      hotelId,
      arrival,
      departure,
      numberOfUnits,
      selectedRate,
      numberOfNights,
      rateName,
      roomTypeInformationResponse,
      selectedPMSRoomTypes,
      selectedSpecialRequests,
      bookRsvIsLoading,
      bookRsvIsError,
      bookRsvError,
      handleBooking,
      brand,
      isDisabledContinueBtn,
      twinroomSelections,
      roomsLabelsForSilentSubst,
      isSilentFeatureFlagEnabled,
      saveLabelsForSilentSubstAndRedirect,
      setResRoomTypes,
      setCurrentClassRoomTypes,
      companyData,
      selectedRateCategory,
      isPrePopulateBillingAddressEnabled
    })
  });
}
function getStandardRoomLabel(roomTypeInformationResponse, roomCode, twinRoomLabels, twinroomSelection) {
  var _roomTypeInformationR;
  if (!roomCode) {
    return null;
  }
  if (typeof twinroomSelection !== 'undefined' && !roomCode.silentSubstitution) {
    return twinroomSelection === 'twobeds' && twinRoomLabels !== null && twinRoomLabels !== void 0 && twinRoomLabels.length ? twinRoomLabels === null || twinRoomLabels === void 0 ? void 0 : twinRoomLabels[0] : twinRoomLabels === null || twinRoomLabels === void 0 ? void 0 : twinRoomLabels[1];
  }
  if (roomCode.silentSubstitution) {
    return roomCode.roomLabelCode;
  }
  return roomTypeInformationResponse === null || roomTypeInformationResponse === void 0 || (_roomTypeInformationR = roomTypeInformationResponse.dataRoomTypeInformation) === null || _roomTypeInformationR === void 0 || (_roomTypeInformationR = _roomTypeInformationR.roomTypeInformation) === null || _roomTypeInformationR === void 0 || (_roomTypeInformationR = _roomTypeInformationR.roomTypes.find(roomType => roomType.roomTypeCode === roomCode.roomLabelCode || roomType.roomTypeCode.includes(roomCode.roomLabelCode))) === null || _roomTypeInformationR === void 0 ? void 0 : _roomTypeInformationR.roomLabel;
}