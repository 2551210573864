"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.BusinessCardType = void 0;
var BusinessCardType = exports.BusinessCardType = function (BusinessCardType) {
  BusinessCardType["BUSINESS_PERSONAL_STORED_CARD"] = "BUSINESS_PERSONAL_STORED_CARD";
  BusinessCardType["BUSINESS_CENTRALLY_STORED_CARD"] = "BUSINESS_CENTRALLY_STORED_CARD";
  return BusinessCardType;
}({});