"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = AgentOverrideModalContainer;
var _react = require("@chakra-ui/react");
var _api = require("@whitbread-eos/api");
var _utils = require("@whitbread-eos/utils");
var _nextI18next = require("next-i18next");
var _react2 = require("react");
var _AgentOverrideModal = _interopRequireDefault(require("./AgentOverrideModal.component"));
var _jsxRuntime = require("react/jsx-runtime");
function AgentOverrideModalContainer(_ref) {
  var {
    basketReference,
    hotelId,
    reasons,
    error: reasonsError,
    isVisible,
    onClose,
    getBookingInfo,
    overridenUserInfo
  } = _ref;
  var {
    t
  } = (0, _nextI18next.useTranslation)(['common']);
  var reservationOverrideReasons = overridenUserInfo.reservationOverrideReasons;
  var [defaultValues, setDefaultValues] = (0, _react2.useState)({
    selectReason: '',
    managerName: '',
    callerName: ''
  });
  var getFormState = (0, _react2.useCallback)(state => {
    setDefaultValues(state);
  }, [setDefaultValues]);
  var {
    mutation,
    error: mutationError,
    isSuccess
  } = (0, _utils.useMutationRequest)(_api.DASHBOARD_UPDATE_OVERRIDE_RESERVATION);
  var onSubmit = data => {
    var _reasons$find;
    var updateReservationOverrideReasonsCriteria = {
      basketReference,
      hotelId,
      reasonCode: data.selectReason,
      reasonName: (_reasons$find = reasons.find(reason => reason.code === data.selectReason)) === null || _reasons$find === void 0 ? void 0 : _reasons$find.name,
      callerName: data.callerName,
      managerName: data.managerName || ''
    };
    mutation.mutate(updateReservationOverrideReasonsCriteria);
  };
  (0, _react2.useEffect)(() => {
    if (isSuccess && getBookingInfo) {
      getBookingInfo();
      onClose();
    }
  }, [isSuccess]);
  (0, _react2.useEffect)(() => {
    if (overridenUserInfo.reservationOverridden) {
      setDefaultValues({
        selectReason: reservationOverrideReasons.reasonCode,
        managerName: reservationOverrideReasons.managerName,
        callerName: reservationOverrideReasons.callerName
      });
    }
  }, [overridenUserInfo.reservationOverridden]);
  return (0, _jsxRuntime.jsx)(_react.Box, {
    "data-testid": (0, _utils.formatDataTestId)('AgentOverride', 'Container'),
    children: (0, _jsxRuntime.jsx)(_AgentOverrideModal.default, {
      getFormState: getFormState,
      defaultValues: defaultValues,
      reasons: reasons,
      error: mutationError || reasonsError,
      isVisible: isVisible,
      onClose: onClose,
      onSubmit: onSubmit,
      t: t
    })
  });
}