"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = Notice;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _react = require("@chakra-ui/react");
var _atoms = require("@whitbread-eos/atoms");
var _utils = require("@whitbread-eos/utils");
var _jsxRuntime = require("react/jsx-runtime");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function Notice(_ref) {
  var _formField$props5;
  var {
    formField,
    description,
    styles,
    linkLabel,
    linkPath
  } = _ref;
  var notificationDescription = () => {
    var _formField$props, _formField$props2, _formField$props3, _formField$props4;
    return (0, _jsxRuntime.jsxs)(_react.Box, {
      "data-testid": formField === null || formField === void 0 ? void 0 : formField.testid,
      children: [(0, _jsxRuntime.jsx)(_react.Text, _objectSpread(_objectSpread({}, textStyle), {}, {
        className: "formatLinks",
        children: (0, _utils.renderSanitizedHtml)(formField !== null && formField !== void 0 && (_formField$props = formField.props) !== null && _formField$props !== void 0 && _formField$props.description ? formField.props.description : description)
      })), (linkPath || (formField === null || formField === void 0 || (_formField$props2 = formField.props) === null || _formField$props2 === void 0 ? void 0 : _formField$props2.linkPath)) && (0, _jsxRuntime.jsx)(_react.Link, {
        ml: "2px",
        textDecoration: "underline",
        color: 'darkGrey3',
        isExternal: true,
        href: linkPath !== null && linkPath !== void 0 ? linkPath : formField === null || formField === void 0 || (_formField$props3 = formField.props) === null || _formField$props3 === void 0 ? void 0 : _formField$props3.linkPath,
        children: linkLabel !== null && linkLabel !== void 0 ? linkLabel : formField === null || formField === void 0 || (_formField$props4 = formField.props) === null || _formField$props4 === void 0 ? void 0 : _formField$props4.linkLabel
      })]
    });
  };
  return (0, _jsxRuntime.jsx)(_react.Box, _objectSpread(_objectSpread({}, styles), {}, {
    children: (0, _jsxRuntime.jsx)(_atoms.Notification, {
      status: "info",
      svg: (0, _jsxRuntime.jsx)(_atoms.Info, {}),
      variant: (formField === null || formField === void 0 || (_formField$props5 = formField.props) === null || _formField$props5 === void 0 ? void 0 : _formField$props5.variant) || 'infoGrey',
      maxW: "full",
      description: notificationDescription()
    })
  }));
}
var textStyle = {
  d: 'inline-block',
  mr: 'xs'
};