"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getLogoByBrand = getLogoByBrand;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _react = require("@chakra-ui/react");
var _icons = require("../assets/icons");
var _Icon = _interopRequireDefault(require("../components/Icon"));
var _jsxRuntime = require("react/jsx-runtime");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function getLogoByBrand(brand, code) {
  var logoStyle = {
    position: 'relative',
    transform: 'scale(0.45)'
  };
  var iconStyle = {
    width: 'var(--chakra-space-lg)',
    height: 'var(--chakra-space-lg)',
    marginRight: 'var(--chakra-space-md)'
  };
  if (brand === 'PI' || brand === 'PID') {
    return (0, _jsxRuntime.jsx)(_react.Box, {
      mr: "md",
      style: {
        position: 'relative',
        top: '-0.125rem'
      },
      children: (0, _jsxRuntime.jsx)(_icons.PremierInnLogo, {})
    }, code);
  }
  if (brand === 'HUB') {
    return (0, _jsxRuntime.jsx)(_Icon.default, {
      style: iconStyle,
      svg: (0, _jsxRuntime.jsx)(_icons.LogoHubSimple, {
        style: _objectSpread(_objectSpread({}, logoStyle), {}, {
          top: '-1.25rem',
          left: '-1.125rem'
        })
      })
    }, code);
  }
  if (brand === 'ZIP') {
    return (0, _jsxRuntime.jsx)(_Icon.default, {
      style: iconStyle,
      svg: (0, _jsxRuntime.jsx)(_icons.LogoZipSimple, {
        style: _objectSpread(_objectSpread({}, logoStyle), {}, {
          top: '-0.375rem',
          left: '-1.5rem'
        })
      })
    }, code);
  }
}