"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = RoomInfoCard;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _react = require("@chakra-ui/react");
var _api = require("@whitbread-eos/api");
var _atoms = require("@whitbread-eos/atoms");
var _utils = require("@whitbread-eos/utils");
var _react2 = require("react");
var _RemoveRoomModal = _interopRequireDefault(require("../RemoveRoomModal"));
var _RoomModal = _interopRequireDefault(require("../RoomModal"));
var _utilities = require("../utilities");
var _jsxRuntime = require("react/jsx-runtime");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function RoomInfoCard(_ref) {
  var _roomStay$roomExtraIn, _roomStay$roomExtraIn2, _roomStay$roomExtraIn3, _roomStay$roomExtraIn4, _userData$business;
  var {
    data,
    currencyCode,
    index,
    styles,
    labels,
    language,
    reservationsNumber,
    baseDataTestId,
    roomRules,
    hotelAvailabilityParams,
    onUpdateRoom,
    onRemoveRoom,
    reservationId,
    variant,
    isCancellable,
    brand,
    hotelCountry
  } = _ref;
  var {
    roomStay,
    reservationGuestList
  } = data;
  var {
    edit,
    roomLabel,
    remove,
    roomModalLabels
  } = labels;
  var {
    roomDropdownRoomCodes
  } = roomModalLabels;
  var {
    givenName,
    surName,
    nameTitle
  } = reservationGuestList[0];
  var cardStyles = _objectSpread(_objectSpread({}, wrapperStyles), styles === null || styles === void 0 ? void 0 : styles.roomWrapper);
  var [isModalOpen, setIsModalOpen] = (0, _react2.useState)(false);
  var [isModalOpenEdit, setIsModalOpenEdit] = (0, _react2.useState)({
    roomNumber: 0,
    isOpen: false
  });
  var roomNameLabel = "".concat(roomLabel, " ").concat(index + 1);
  var price = (0, _utils.formatPriceWithDecimal)(language, (0, _utils.formatCurrency)(currencyCode), roomStay.roomPrice, true);
  var groupId = roomStay === null || roomStay === void 0 || (_roomStay$roomExtraIn = roomStay.roomExtraInfo) === null || _roomStay$roomExtraIn === void 0 ? void 0 : _roomStay$roomExtraIn.groupId;
  var roomDetailsEdit = {
    adults: roomStay === null || roomStay === void 0 ? void 0 : roomStay.adultsNumber,
    children: roomStay === null || roomStay === void 0 ? void 0 : roomStay.childrenNumber,
    roomType: roomStay === null || roomStay === void 0 || (_roomStay$roomExtraIn2 = roomStay.roomExtraInfo) === null || _roomStay$roomExtraIn2 === void 0 ? void 0 : _roomStay$roomExtraIn2.roomName.split(' ')[0],
    operaRoomType: roomStay === null || roomStay === void 0 || (_roomStay$roomExtraIn3 = roomStay.roomExtraInfo) === null || _roomStay$roomExtraIn3 === void 0 ? void 0 : _roomStay$roomExtraIn3.roomType,
    roomTypeCode: roomDropdownRoomCodes[groupId]
  };
  var {
    isLoggedIn
  } = (0, _utils.useUserData)();
  var userData = (0, _utils.useUserDetails)(true, isLoggedIn);
  return (0, _jsxRuntime.jsxs)(_react.Flex, _objectSpread(_objectSpread({
    "data-testid": "room-info-card-amend-".concat(index + 1)
  }, cardStyles), {}, {
    children: [(0, _jsxRuntime.jsx)(_react.Text, _objectSpread(_objectSpread({}, roomIndexStyles), {}, {
      children: roomNameLabel
    })), (0, _jsxRuntime.jsx)(_react.Text, _objectSpread(_objectSpread({}, roomDetailsStyles), {}, {
      className: "sessioncamhidetext assist-no-show",
      children: "".concat((0, _utils.upperOnlyFirst)(nameTitle || ''), " ").concat(givenName, " ").concat(surName)
    })), (0, _jsxRuntime.jsx)(_react.Text, _objectSpread(_objectSpread({}, roomDetailsStyles), {}, {
      pb: "0",
      children: (0, _utilities.getGuestsPlaceholderString)(roomStay.adultsNumber, roomStay.childrenNumber, labels.roomModalLabels.roomAvailabilityLabels)
    })), (0, _jsxRuntime.jsx)(_react.Text, _objectSpread(_objectSpread({}, roomDetailsStyles), {}, {
      children: roomStay === null || roomStay === void 0 || (_roomStay$roomExtraIn4 = roomStay.roomExtraInfo) === null || _roomStay$roomExtraIn4 === void 0 ? void 0 : _roomStay$roomExtraIn4.roomName
    })), (0, _jsxRuntime.jsx)(_react.Text, _objectSpread(_objectSpread({}, priceDetailsStyles), {}, {
      children: price
    })), (0, _jsxRuntime.jsx)(_atoms.Button, _objectSpread(_objectSpread({
      size: "xsm",
      variant: "secondary",
      marginTop: "auto",
      "data-testid": "room-info-card-amend-edit-button-".concat(index + 1)
    }, amendButtonsStyles), {}, {
      onClick: e => {
        setIsModalOpenEdit({
          roomNumber: index,
          isOpen: true
        });
        e === null || e === void 0 || e.currentTarget.blur();
      },
      isDisabled: !isCancellable,
      children: edit
    })), (0, _jsxRuntime.jsx)(_RoomModal.default, {
      isOpen: isModalOpenEdit.isOpen,
      onClose: () => setIsModalOpenEdit({
        roomNumber: index,
        isOpen: false
      }),
      title: roomNameLabel,
      roomRules: roomRules,
      hotelAvailabilityParams: hotelAvailabilityParams,
      labels: labels.roomModalLabels,
      baseDataTestId: baseDataTestId,
      isEdit: true,
      roomDetailsEdit: roomDetailsEdit,
      price: price,
      reservationGuestList: reservationGuestList[0],
      roomNumber: index + 1,
      onUpdateRoom: onUpdateRoom,
      reservationId: reservationId,
      variant: variant,
      brand: brand,
      hotelCountry: hotelCountry,
      language: language
    }), reservationsNumber > 1 && (userData === null || userData === void 0 || (_userData$business = userData.business) === null || _userData$business === void 0 ? void 0 : _userData$business.accessLevel) !== _api.BUSINESS_BOOKER_USER_ROLES.SELF && (0, _jsxRuntime.jsx)(_atoms.Button, _objectSpread(_objectSpread({
      size: "xsm",
      variant: isCancellable ? 'tertiary' : 'primary',
      "data-testid": "room-info-card-amend-remove-button-".concat(index + 1),
      marginTop: ".5rem",
      transition: "none"
    }, amendButtonsStyles), {}, {
      onClick: e => {
        setIsModalOpen(true);
        e === null || e === void 0 || e.currentTarget.blur();
      },
      isDisabled: !isCancellable,
      children: remove
    })), (0, _jsxRuntime.jsx)(_RemoveRoomModal.default, {
      isOpen: isModalOpen,
      onClose: () => setIsModalOpen(false),
      labels: labels.removeRoomModalLabels,
      roomNumber: index + 1,
      onRemoveRoom: onRemoveRoom,
      reservationId: reservationId
    })]
  }));
}
var wrapperStyles = {
  w: {
    mobile: '100%',
    sm: '14.8125rem',
    md: '20.4375rem',
    lg: '11.125rem',
    xl: '12rem'
  },
  h: '100%',
  minHeight: {
    base: '15.25rem',
    sm: '16.75rem',
    lg: '18.25rem',
    xl: '16.75rem'
  },
  border: '1px solid var(--chakra-colors-tertiary)',
  borderRadius: 'var(--chakra-space-xs)',
  bg: 'baseWhite',
  padding: 'var(--chakra-space-xl) 0.875rem',
  flexDirection: 'column'
};
var roomIndexStyles = {
  color: 'darkGrey2',
  fontSize: 'md',
  fontWeight: 'bold',
  lineHeight: 'var(--chakra-lineHeights-2)',
  paddingBottom: 'sm'
};
var roomDetailsStyles = {
  color: 'darkGrey2',
  fontSize: 'md',
  lineHeight: 'var(--chakra-lineHeights-3)',
  fontWeight: 'normal',
  paddingBottom: 'sm'
};
var priceDetailsStyles = _objectSpread(_objectSpread({}, roomDetailsStyles), {}, {
  color: 'darkGrey1'
});
var amendButtonsStyles = {
  minW: {
    mobile: '12.9375rem',
    xs: '16.375rem',
    sm: '12.875rem',
    md: '18.5rem',
    lg: '9.125rem',
    xl: '10rem'
  },
  w: '100%'
};