"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = CookiePoliciesModalContainer;
var _api = require("@whitbread-eos/api");
var _atoms = require("@whitbread-eos/atoms");
var _utils = require("@whitbread-eos/utils");
var _react = require("react");
var _CookiePolicies = require("./CookiePolicies.constants");
var _CookiePolicies2 = _interopRequireDefault(require("./CookiePolicies/CookiePolicies.component"));
var _ManageCookies = _interopRequireDefault(require("./ManageCookies/ManageCookies.component"));
var _jsxRuntime = require("react/jsx-runtime");
function CookiePoliciesModalContainer(_ref) {
  var _cookieConsent, _cookiePolicies$intro;
  var {
    onClose,
    isOpen,
    brand
  } = _ref;
  var {
    language,
    country
  } = (0, _utils.useCustomLocale)();
  var [isManageCookieModalOpen, setIsManageCookieModalOpen] = (0, _react.useState)(false);
  var {
    data
  } = (0, _utils.useQueryRequest)(['getCookieConsentInfoQuery', country, language, brand], _api.GET_COOKIE_CONSENT_INFO, {
    country,
    language,
    brand
  }, {
    select: data => {
      var _data$cookieConsent;
      if (data !== null && data !== void 0 && (_data$cookieConsent = data.cookieConsent) !== null && _data$cookieConsent !== void 0 && (_data$cookieConsent = _data$cookieConsent.cookiePolicies) !== null && _data$cookieConsent !== void 0 && (_data$cookieConsent = _data$cookieConsent.manageView) !== null && _data$cookieConsent !== void 0 && (_data$cookieConsent = _data$cookieConsent.cookieGroup) !== null && _data$cookieConsent !== void 0 && _data$cookieConsent.map) {
        data.cookieConsent.cookiePolicies.manageView.cookieGroup.forEach(group => {
          var isAlwaysActive = country === 'gb';
          if (group.cookieName === 'permissionEssential') {
            group.isAlwaysActive = true;
          } else {
            group.isAlwaysActive = isAlwaysActive;
          }
        });
      }
      return data;
    }
  });
  var cookiePolicies = data === null || data === void 0 || (_cookieConsent = data.cookieConsent) === null || _cookieConsent === void 0 ? void 0 : _cookieConsent.cookiePolicies;
  var manageCookies = () => {
    setIsManageCookieModalOpen(true);
  };
  var defaultCookiesPermissions = cookiePolicies === null || cookiePolicies === void 0 ? void 0 : cookiePolicies.manageView.cookieGroup.map((cookie, index) => {
    return {
      id: index,
      name: cookie.cookieName,
      value: cookie.isAlwaysActive
    };
  });
  var [cookiePermissions, setCookiePermissions] = (0, _react.useState)([]);
  (0, _react.useEffect)(() => {
    if (data) {
      setCookiePermissions(defaultCookiesPermissions);
    }
  }, [data]);
  var setCookiePolicies = cookiePermissionsParam => {
    var permissions = cookiePermissionsParam !== null && cookiePermissionsParam !== void 0 ? cookiePermissionsParam : cookiePermissions;
    permissions === null || permissions === void 0 || permissions.forEach(permission => {
      cookiePolicies === null || cookiePolicies === void 0 || cookiePolicies.manageView.cookieGroup.forEach(cookie => {
        if (cookie.cookieName !== 'permissionEssential' && cookie.cookieName === permission.name) {
          (0, _utils.setCookie)(cookie.cookieName, permission.value, _CookiePolicies.ONE_YEAR_IN_MINUTES, "/".concat(country));
        }
      });
    });
    (0, _utils.setCookie)(_CookiePolicies.CONSENT_COOKIE, 1, _CookiePolicies.ONE_YEAR_IN_MINUTES, "/".concat(country));
    onClose();
  };
  return (0, _jsxRuntime.jsx)(_jsxRuntime.Fragment, {
    children: data && (0, _jsxRuntime.jsx)(_atoms.ModalVariants, {
      isOpen: isOpen,
      onClose: onClose,
      dataTestId: 'CookiePolicyContainer',
      variant: "cookie",
      variantProps: {
        title: !isManageCookieModalOpen ? cookiePolicies === null || cookiePolicies === void 0 || (_cookiePolicies$intro = cookiePolicies.introView) === null || _cookiePolicies$intro === void 0 ? void 0 : _cookiePolicies$intro.title : cookiePolicies === null || cookiePolicies === void 0 ? void 0 : cookiePolicies.manageView.title,
        closeOnOverlayClick: false,
        overflowVisible: false,
        isCentered: true,
        isCookieConsentModal: !isManageCookieModalOpen
      },
      children: !isManageCookieModalOpen ? (0, _jsxRuntime.jsx)(_CookiePolicies2.default, {
        labels: data,
        cookiePermissions: cookiePermissions,
        setCookiePolicies: setCookiePolicies,
        manageCookies: manageCookies,
        modalStyles: {
          wrapperStyles,
          buttonsStyle,
          textStyles
        }
      }) : (0, _jsxRuntime.jsx)(_ManageCookies.default, {
        labels: data,
        cookiePermissions: cookiePermissions,
        setCookiePermissions: setCookiePermissions,
        setCookiePolicies: setCookiePolicies,
        modalStyles: {
          wrapperStyles,
          textStyles,
          buttonsStyle
        }
      })
    })
  });
}
var wrapperStyles = {
  alignItems: 'center',
  justifyContent: 'center',
  direction: 'column',
  padding: 'lg'
};
var buttonsStyle = {
  display: 'flex',
  height: 'auto',
  alignContent: 'baseline',
  flexDirection: {
    mobile: 'column',
    xs: 'column',
    md: 'row',
    sm: 'row'
  },
  width: '100%'
};
var textStyles = {
  title: {
    color: 'darkGrey1',
    fontWeight: 'semibold',
    fontSize: 'xl',
    lineHeight: '3'
  },
  description: {
    color: 'darkGrey1',
    textAlign: 'left',
    fontWeight: 'normal',
    fontSize: 'md',
    lineHeight: '3'
  }
};