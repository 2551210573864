"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = RoomFacilitiesModalContainer;
var _FacilitiesModal = _interopRequireDefault(require("../../FacilitiesModal"));
var _jsxRuntime = require("react/jsx-runtime");
function RoomFacilitiesModalContainer(_ref) {
  var {
    facilities,
    isModalVisible,
    onModalClose
  } = _ref;
  return (0, _jsxRuntime.jsx)(_FacilitiesModal.default, {
    facilities,
    isModalVisible,
    onModalClose
  });
}