"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = DirectionsInformationContainer;
var _utils = require("@whitbread-eos/utils");
var _DirectionsInformation = _interopRequireDefault(require("./DirectionsInformation.component"));
var _jsxRuntime = require("react/jsx-runtime");
function DirectionsInformationContainer() {
  var {
    directions,
    isLoading,
    isError,
    error
  } = (0, _utils.useStaticHotelInformation)();
  return (0, _jsxRuntime.jsx)(_DirectionsInformation.default, {
    data: directions,
    isLoading,
    isError,
    error
  });
}