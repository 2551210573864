"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.FS_SILENT_SUBSTITUTION = exports.FS_SHOW_AMEND_PAYMENT_PAGE = exports.FS_MEAL_RESTRICTIONS = exports.FS_ENABLE_SEARCH_ACCOUNT_SAVE_CHANGES = exports.FS_ENABLE_SCRIPTS_EMBED_APP_DYNAMICS = exports.FS_ENABLE_APPLEPAY_GOOGLEPAY = exports.FS_ENABLE_AMEND_PAY_NOW_PI_BB = exports.FS_DISPLAY_REGISTER_GDP = exports.FS_DISPLAY_GUEST_ACCOUNT = exports.FS_CONFIRM_EMAIL_ADDRESS_CCUI = void 0;
var FS_MEAL_RESTRICTIONS = exports.FS_MEAL_RESTRICTIONS = 'NEXT_FS_MEAL_RESTRICTIONS';
var FS_ENABLE_SEARCH_ACCOUNT_SAVE_CHANGES = exports.FS_ENABLE_SEARCH_ACCOUNT_SAVE_CHANGES = 'NEXT_FS_ENABLE_SEARCH_ACCOUNT_SAVE_CHANGES';
var FS_DISPLAY_GUEST_ACCOUNT = exports.FS_DISPLAY_GUEST_ACCOUNT = 'NEXT_FS_DISPLAY_GUEST_ACCOUNT';
var FS_CONFIRM_EMAIL_ADDRESS_CCUI = exports.FS_CONFIRM_EMAIL_ADDRESS_CCUI = 'NEXT_FS_CONFIRM_EMAIL_ADDRESS_CCUI';
var FS_SHOW_AMEND_PAYMENT_PAGE = exports.FS_SHOW_AMEND_PAYMENT_PAGE = 'NEXT_FS_SHOW_AMEND_PAYMENT_PAGE';
var FS_DISPLAY_REGISTER_GDP = exports.FS_DISPLAY_REGISTER_GDP = 'NEXT_PUBLIC_DISPLAY_REGISTER_GDP';
var FS_ENABLE_AMEND_PAY_NOW_PI_BB = exports.FS_ENABLE_AMEND_PAY_NOW_PI_BB = 'NEXT_ENABLE_AMEND_PAY_NOW_PI_BB';
var FS_ENABLE_APPLEPAY_GOOGLEPAY = exports.FS_ENABLE_APPLEPAY_GOOGLEPAY = 'NEXT_PUBLIC_APPLEPAY_GOOGLEPAY_ENABLE';
var FS_SILENT_SUBSTITUTION = exports.FS_SILENT_SUBSTITUTION = 'NEXT_FS_SILENT_SUBSTITUTION';
var FS_ENABLE_SCRIPTS_EMBED_APP_DYNAMICS = exports.FS_ENABLE_SCRIPTS_EMBED_APP_DYNAMICS = 'NEXT_FS_ENABLE_SCRIPTS_EMBED_APP_DYNAMICS';