"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = AddSubtract;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _react = require("@chakra-ui/react");
var _icons = require("../../assets/icons");
var _formatters = require("../../utils/formatters");
var _Button = _interopRequireDefault(require("../Button"));
var _Icon = _interopRequireDefault(require("../Icon"));
var _jsxRuntime = require("react/jsx-runtime");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function AddSubtract(_ref) {
  var {
    onSubtract,
    onPlus,
    value,
    label,
    isSubtractDisable,
    prefixDataTestId,
    isPlusDisable,
    isPlusHidden = false,
    isSubtractHidden = false,
    isEditable = false,
    handleInputChange,
    maxLength
  } = _ref;
  return (0, _jsxRuntime.jsxs)(_react.Flex, _objectSpread(_objectSpread({}, wrapperContainer), {}, {
    children: [(0, _jsxRuntime.jsxs)(_react.Flex, _objectSpread(_objectSpread({}, wrapperController), {}, {
      children: [!isSubtractHidden && (0, _jsxRuntime.jsx)(_Button.default, {
        size: "xs",
        variant: "circle",
        onClick: onSubtract,
        isDisabled: isSubtractDisable,
        "data-testid": (0, _formatters.formatDataTestId)(prefixDataTestId, 'SubtractButton'),
        sx: onActiveIconStyle(isSubtractDisable),
        children: (0, _jsxRuntime.jsx)(_Icon.default, {
          svg: (0, _jsxRuntime.jsx)(_icons.Rectangle, {
            color: choseIconColor(isSubtractDisable)
          })
        })
      }), isEditable ? (0, _jsxRuntime.jsx)(_react.Input, {
        value: isNaN(value) ? 0 : value,
        onChange: e => {
          var _e$target;
          handleInputChange === null || handleInputChange === void 0 || handleInputChange(parseInt(e === null || e === void 0 || (_e$target = e.target) === null || _e$target === void 0 ? void 0 : _e$target.value));
        },
        maxLength: maxLength,
        "data-testid": (0, _formatters.formatDataTestId)(prefixDataTestId, 'inputValue')
      }) : (0, _jsxRuntime.jsx)(_react.Text, _objectSpread(_objectSpread({}, valueStyle), {}, {
        "data-testid": (0, _formatters.formatDataTestId)(prefixDataTestId, 'Value'),
        children: value
      })), !isPlusHidden && (0, _jsxRuntime.jsx)(_Button.default, {
        size: "xs",
        variant: "circle",
        onClick: onPlus,
        isDisabled: isPlusDisable,
        "data-testid": (0, _formatters.formatDataTestId)(prefixDataTestId, 'AddButton'),
        sx: onActiveIconStyle(isPlusDisable),
        children: (0, _jsxRuntime.jsx)(_Icon.default, {
          svg: (0, _jsxRuntime.jsx)(_icons.Path, {
            color: choseIconColor(isPlusDisable)
          })
        })
      })]
    })), (0, _jsxRuntime.jsx)(_react.Text, _objectSpread(_objectSpread({}, labelStyle), {}, {
      "data-testid": (0, _formatters.formatDataTestId)(prefixDataTestId, 'Label'),
      children: label
    }))]
  }));
}
var onActiveIconStyle = isDisabled => isDisabled ? '' : {
  '&:active > div > svg': {
    path: {
      fill: 'white'
    }
  }
};
var choseIconColor = isDisable => isDisable ? 'var(--chakra-colors-darkGrey2)' : 'var(--chakra-colors-primary)';
var valueStyle = {
  width: 'var(--chakra-space-3xl)',
  lineHeight: '4',
  px: '1.063rem',
  color: 'darkGrey1',
  fontSize: '2xl',
  fontWeight: 'medium',
  align: 'center'
};
var labelStyle = {
  fontSize: 'md',
  lineHeight: '3',
  fontWeight: 'medium',
  color: 'darkGrey1'
};
var wrapperContainer = {
  flexDir: 'column',
  alignItems: 'center',
  justifyContent: 'center'
};
var wrapperController = {
  alignItems: 'center',
  justifyContent: 'center'
};