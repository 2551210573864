"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = DetailsPanel;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _react = require("@chakra-ui/react");
var _utils = require("@whitbread-eos/utils");
var _react2 = require("react");
var _jsxRuntime = require("react/jsx-runtime");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function DetailsPanel(_ref) {
  var _data$rows;
  var {
    data,
    stylesObject
  } = _ref;
  var baseDataTestId = 'BookingDetailsTable';
  return (0, _jsxRuntime.jsxs)(_react.Box, _objectSpread(_objectSpread({}, stylesObject === null || stylesObject === void 0 ? void 0 : stylesObject.wrapper), {}, {
    "data-testid": (0, _utils.formatDataTestId)(baseDataTestId, 'Container'),
    children: [(0, _jsxRuntime.jsx)(_react.Text, _objectSpread(_objectSpread({}, stylesObject === null || stylesObject === void 0 ? void 0 : stylesObject.title), {}, {
      "data-testid": data === null || data === void 0 ? void 0 : data.title,
      children: data === null || data === void 0 ? void 0 : data.title
    })), (0, _jsxRuntime.jsx)(_react.Grid, {
      templateColumns: "1fr 2fr",
      gap: 2,
      children: data === null || data === void 0 || (_data$rows = data.rows) === null || _data$rows === void 0 ? void 0 : _data$rows.map(row => {
        return (0, _jsxRuntime.jsxs)(_react2.Fragment, {
          children: [(0, _jsxRuntime.jsx)(_react.Text, _objectSpread(_objectSpread({}, stylesObject === null || stylesObject === void 0 ? void 0 : stylesObject.rowKey), {}, {
            "data-testid": row.key,
            children: row.key
          })), (0, _jsxRuntime.jsx)(_react.Text, _objectSpread(_objectSpread({}, stylesObject === null || stylesObject === void 0 ? void 0 : stylesObject.rowValue), {}, {
            children: row.value
          }))]
        }, "row-".concat(row.key));
      })
    })]
  }));
}