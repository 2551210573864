"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = NumberOfNights;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _react = require("@chakra-ui/react");
var _atoms = require("@whitbread-eos/atoms");
var _jsxRuntime = require("react/jsx-runtime");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function NumberOfNights(_ref) {
  var {
    inputPlaceholder,
    inputValue,
    handleOnChange,
    maxNights,
    noOfNightsError,
    isLessThanSm,
    styles,
    handleOnBlur,
    hideErrorForMinNights = false,
    isDisabled = false
  } = _ref;
  var isError = inputValue > maxNights || inputValue === 0 && !hideErrorForMinNights;
  var {
    noOfNightsInputElementStyles,
    alertStyles,
    iconStyles,
    tooltipStyles
  } = getNumberOfNightsStyles(isLessThanSm, isError, styles);
  return (0, _jsxRuntime.jsxs)(_react.NumberInput, {
    max: maxNights,
    keepWithinRange: false,
    clampValueOnBlur: false,
    value: inputValue,
    defaultValue: hideErrorForMinNights ? 0 : 1,
    "aria-label": "datepicker-input",
    placeholder: inputPlaceholder,
    children: [isError && (0, _jsxRuntime.jsx)(_atoms.Tooltip, _objectSpread(_objectSpread({
      description: noOfNightsError,
      variant: "inlineError",
      defaultIsOpen: true,
      svg: (0, _jsxRuntime.jsx)(_atoms.Error, {}),
      placement: "bottom-start",
      position: "relative",
      alertElementStyles: alertStyles
    }, tooltipStyles), {}, {
      children: ''
    })), (0, _jsxRuntime.jsxs)(_react.InputGroup, {
      children: [(0, _jsxRuntime.jsxs)(_react.InputLeftElement, _objectSpread(_objectSpread({
        pointerEvents: "none",
        justifyContent: "space-around",
        color: isDisabled ? 'lightGrey1' : ''
      }, iconStyles), {}, {
        children: [(0, _jsxRuntime.jsx)(_atoms.Icon, {
          svg: (0, _jsxRuntime.jsx)(_atoms.NoOfNights, {
            color: isDisabled ? 'var(--chakra-colors-lightGrey1)' : ''
          })
        }), (0, _jsxRuntime.jsx)(_react.Text, {
          ml: "md",
          as: "span",
          children: "|"
        })]
      })), (0, _jsxRuntime.jsx)(_react.NumberInputField, _objectSpread({
        disabled: isDisabled,
        onChange: handleOnChange,
        onBlur: handleOnBlur,
        maxLength: 3,
        style: _objectSpread({}, isError && {
          border: '2px solid var(--chakra-colors-error)',
          borderRadius: 'var(--chakra-radii-base)'
        })
      }, noOfNightsInputElementStyles))]
    })]
  });
}
var getNumberOfNightsStyles = (isLessThanSm, isError, otherStyles) => {
  var _otherStyles$inputEle, _otherStyles$iconStyl, _otherStyles$tooltipS;
  var alertStyles = {
    py: 'sm',
    paddingRight: '0'
  };
  var inputElementStyles = {
    borderRight: '1px solid var(--chakra-colors-lightGrey4)',
    borderTopRightRadius: isLessThanSm ? 'var(--chakra-space-xs)' : 0,
    borderBottomRightRadius: isLessThanSm ? 'var(--chakra-space-xs)' : 0,
    color: 'darkGrey1',
    sx: {
      '::placeholder': {
        color: 'darkGrey2'
      }
    }
  };
  var noOfNightsInputElementStyles = _objectSpread(_objectSpread({}, (_otherStyles$inputEle = otherStyles === null || otherStyles === void 0 ? void 0 : otherStyles.inputElementStyles) !== null && _otherStyles$inputEle !== void 0 ? _otherStyles$inputEle : _objectSpread(_objectSpread({}, inputElementStyles), {}, {
    border: {
      mobile: '1px solid var(--chakra-colors-lightGrey1)',
      xs: '1px solid var(--chakra-colors-lightGrey1)',
      sm: '0'
    },
    borderRight: {
      md: '1px solid var(--chakra-colors-lightGrey4)'
    },
    borderRadius: {
      mobile: 'base',
      sm: '0'
    },
    _hover: {
      border: '1px solid var(--chakra-colors-darkGrey1)',
      borderRadius: 'base'
    },
    _focus: {
      border: '2px solid var(--chakra-colors-primary)',
      cursor: 'auto',
      borderRadius: 'base'
    },
    padding: 'var(--chakra-space-lg) var(--chakra-space-lg) var(--chakra-space-lg) 3.7rem',
    w: {
      mobile: '8.75rem',
      xs: '10.25rem',
      sm: '6.75rem',
      md: '6.313rem',
      lg: '9.688rem'
    },
    h: {
      mobile: 'var(--chakra-space-4xl)',
      xs: 'var(--chakra-space-4xl)',
      sm: 'var(--chakra-space-4xl)',
      md: 'var(--chakra-space-4xl)',
      lg: 'var(--chakra-space-6xl)'
    }
  })), isError ? {
    border: 'none',
    _hover: {
      border: 'none'
    },
    _focus: {
      border: 'none'
    }
  } : {});
  var iconStyles = (_otherStyles$iconStyl = otherStyles === null || otherStyles === void 0 ? void 0 : otherStyles.iconStyles) !== null && _otherStyles$iconStyl !== void 0 ? _otherStyles$iconStyl : {
    top: {
      mobile: 'var(--chakra-space-sm)',
      xs: 'var(--chakra-space-sm)',
      sm: 'var(--chakra-space-md)',
      md: 'var(--chakra-space-sm)',
      lg: 'var(--chakra-space-md)'
    },
    left: ' var(--chakra-space-md)'
  };
  var tooltipStyles = (_otherStyles$tooltipS = otherStyles === null || otherStyles === void 0 ? void 0 : otherStyles.tooltipStyles) !== null && _otherStyles$tooltipS !== void 0 ? _otherStyles$tooltipS : {
    w: {
      xs: '10.25rem',
      md: '6.313rem',
      lg: '9.688rem'
    },
    marginLeft: {
      xs: 'var(--chakra-space-xmd)',
      sm: '0'
    },
    top: {
      xs: 'xl',
      sm: '2xl',
      md: '4xl'
    },
    fontSize: 'sm',
    lineHeight: '3',
    fontWeight: 'normal'
  };
  return {
    alertStyles,
    noOfNightsInputElementStyles,
    iconStyles,
    tooltipStyles
  };
};