"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = ResetPasswordPIVariant;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _react = require("@chakra-ui/react");
var _atoms = require("@whitbread-eos/atoms");
var _utils = require("@whitbread-eos/utils");
var _config = _interopRequireDefault(require("next/config"));
var _react2 = require("react");
var _resetPasswordFormConfig = require("./resetPasswordFormConfig");
var _jsxRuntime = require("react/jsx-runtime");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function ResetPasswordPIVariant(_ref) {
  var _labels$forgottenPass, _labels$forgottenPass2;
  var {
    getFormState,
    defaultValues,
    defaultErrors,
    setIsLoginForm,
    onClose,
    labels,
    isBookingFlow
  } = _ref;
  var baseDataTestId = 'ResetPassword';
  var {
    publicRuntimeConfig = {}
  } = (0, _config.default)() || {};
  var {
    mutation,
    data,
    isError
  } = (0, _utils.useRestMutationRequest)("".concat(publicRuntimeConfig.NEXT_PUBLIC_REST_API, "/auth/hotels/forgot-password?business=false"), 'POST');
  var [resetForm, setResetForm] = (0, _react2.useState)(0);
  var getEmailRedirectUrl = () => {
    var path = '/gb/en/reset-password.html?token=';
    if (process.env.NODE_ENV === 'development') {
      return "".concat(process.env.NEXT_PUBLIC_POC_URL).concat(path);
    }
    return "".concat(window.location.protocol, "//").concat(window.location.hostname).concat(path);
  };
  (0, _react2.useEffect)(() => {
    setResetForm(prev => prev + 1);
  }, []);
  var onCancel = () => {
    if (setIsLoginForm) {
      setIsLoginForm(true);
    } else {
      onClose === null || onClose === void 0 || onClose();
    }
  };
  var onSubmit = data => {
    mutation.mutate({
      url: getEmailRedirectUrl(),
      username: data.email
    });
  };
  return (0, _jsxRuntime.jsx)(_react.Flex, _objectSpread(_objectSpread({
    "data-testid": (0, _utils.formatDataTestId)(baseDataTestId, 'Container'),
    direction: "column"
  }, containerStyles), {}, {
    children: (data === null || data === void 0 ? void 0 : data.success) === true ? (0, _jsxRuntime.jsxs)(_react.Flex, _objectSpread(_objectSpread({}, textContainerStyles), {}, {
      children: [(0, _jsxRuntime.jsx)(_react.Text, _objectSpread(_objectSpread({}, resetPassTitleStyles), {}, {
        children: labels === null || labels === void 0 || (_labels$forgottenPass = labels.forgottenPassword) === null || _labels$forgottenPass === void 0 ? void 0 : _labels$forgottenPass.emailSentHeader
      })), (0, _jsxRuntime.jsx)(_react.Text, _objectSpread(_objectSpread({}, resetPassDescStyles), {}, {
        children: labels === null || labels === void 0 || (_labels$forgottenPass2 = labels.forgottenPassword) === null || _labels$forgottenPass2 === void 0 ? void 0 : _labels$forgottenPass2.emailSentMessage
      })), (0, _jsxRuntime.jsx)(_react.Flex, {
        direction: "row",
        justifyContent: "center",
        children: (0, _jsxRuntime.jsx)(_react.Link, _objectSpread(_objectSpread({}, linkStyles), {}, {
          onClick: onCancel,
          "data-testid": (0, _utils.formatDataTestId)(baseDataTestId, isBookingFlow ? 'BackToYourDetails' : 'BackToLogin'),
          children: isBookingFlow ? labels.forgottenPassword.backToYourDetails : labels.forgottenPassword.backToLogin
        }))
      })]
    })) : (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
      children: [(0, _jsxRuntime.jsxs)(_react.Flex, _objectSpread(_objectSpread({}, textContainerStyles), {}, {
        children: [(0, _jsxRuntime.jsx)(_react.Text, _objectSpread(_objectSpread({}, resetPassTitleStyles), {}, {
          children: labels.forgottenPassword.leisure.formTitle
        })), (0, _jsxRuntime.jsx)(_react.Text, _objectSpread(_objectSpread({}, resetPassDescStyles), {}, {
          children: labels.forgottenPassword.leisure.formLabel
        }))]
      })), isError && (0, _jsxRuntime.jsx)(_react.Flex, _objectSpread(_objectSpread({}, errorContainerStyles), {}, {
        "data-testid": (0, _utils.formatDataTestId)(baseDataTestId, 'Error'),
        children: (0, _jsxRuntime.jsx)(_atoms.Notification, {
          description: labels.forgottenPassword.genericError,
          variant: "alert",
          status: "warning",
          svg: (0, _jsxRuntime.jsx)(_atoms.Alert, {})
        })
      })), (0, _jsxRuntime.jsx)(_atoms.Form, _objectSpread({}, (0, _resetPasswordFormConfig.resetPasswordFormConfig)({
        getFormState,
        defaultValues,
        defaultErrors,
        onSubmit,
        baseDataTestId,
        labels,
        resetForm
      }))), (0, _jsxRuntime.jsx)(_react.Flex, {
        direction: "row",
        justifyContent: "center",
        children: (0, _jsxRuntime.jsx)(_react.Link, _objectSpread(_objectSpread({}, linkStyles), {}, {
          onClick: onCancel,
          "data-testid": (0, _utils.formatDataTestId)(baseDataTestId, 'Cancel'),
          children: labels.forgottenPassword.cancel
        }))
      })]
    })
  }));
}
var containerStyles = {
  margin: {
    mobile: '1.5rem 1rem',
    sm: '1.5rem 2rem',
    md: '1.5rem 6.875rem'
  },
  width: {
    mobile: 'auto',
    xs: 'auto',
    sm: '25rem',
    md: '26rem'
  }
};
var textContainerStyles = {
  alignItems: 'center',
  direction: 'column',
  padding: 0
};
var resetPassTitleStyles = {
  color: 'darkGrey1',
  fontSize: 'md',
  fontWeight: 'semibold',
  marginBottom: 'md'
};
var resetPassDescStyles = {
  align: 'center',
  color: 'darkGrey1',
  fontSize: 'md',
  fontWeight: 'normal',
  marginBottom: '2xl'
};
var linkStyles = {
  color: 'btnSecondaryEnabled',
  textDecoration: 'underline',
  fontSize: 'md'
};
var errorContainerStyles = {
  marginBottom: '2xl'
};