"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = TopRatingSection;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _react = require("@chakra-ui/react");
var _api = require("@whitbread-eos/api");
var _atoms = require("@whitbread-eos/atoms");
var _utils = require("@whitbread-eos/utils");
var _nextI18next = require("next-i18next");
var _react2 = require("react");
var _constants = require("../../../utils/constants");
var _jsxRuntime = require("react/jsx-runtime");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function TopRatingSection(_ref) {
  var _tripAdvisorData$awar;
  var {
    tripAdvisorData
  } = _ref;
  var {
    t
  } = (0, _nextI18next.useTranslation)();
  var [isModalOpen, setIsModalOpen] = (0, _react2.useState)(false);
  var defaultAwardModalInfo = {
    description: '',
    title: '',
    image: '',
    modalTitle: 'Hotel award'
  };
  var [awardModalData, setAwardModalData] = (0, _react2.useState)(defaultAwardModalInfo);
  if (!tripAdvisorData || !Object.keys(tripAdvisorData).length) {
    return null;
  }
  function toggleModal() {
    setIsModalOpen(!isModalOpen);
  }
  function awardModalClickHandler(awardType) {
    var selectedAwardType = awardType.toLowerCase() === 'travelers choice' ? _api.tripadvisorAwardtype.CHOICE : _api.tripadvisorAwardtype.BEST;
    setAwardModalData({
      description: (0, _utils.formatInnerHTMLAssetUrls)(t("tripadvisor.award.".concat(selectedAwardType, ".text"))),
      title: t('tripadvisor.award.choice.title'),
      image: (0, _utils.formatAssetsUrl)(t("tripadvisor.award.".concat(selectedAwardType, ".imageSrc"))),
      modalTitle: t("tripadvisor.award.".concat(selectedAwardType, ".modalTitle"))
    });
    toggleModal();
  }
  var scrollToUserReviewSection = () => {
    var element = document.getElementById('tripadvisor-user-review-section');
    if (element) {
      element.scrollIntoView({
        block: 'start',
        behavior: 'smooth'
      });
    }
  };
  return (0, _jsxRuntime.jsxs)(_react.Box, {
    "data-testid": "tripadvisor-top-rating-section-hdp",
    display: "flex",
    children: [tripAdvisorData !== null && tripAdvisorData !== void 0 && tripAdvisorData.rating ? (0, _jsxRuntime.jsxs)(_react.Flex, {
      children: [(0, _jsxRuntime.jsx)(_react.Image, {
        w: "35px",
        h: "40px",
        src: t('tripadvisor.icon'),
        alt: "ta-ratings-icon"
      }), (0, _jsxRuntime.jsx)(_react.Image, {
        marginLeft: 2,
        src: "".concat(_constants.TRIP_ADVISOR_RATING_IMAGE_URL_PART1).concat(tripAdvisorData === null || tripAdvisorData === void 0 ? void 0 : tripAdvisorData.rating.toFixed(1)).concat(_constants.TRIP_ADVISOR_RATING_IMAGE_URL_PART2),
        alt: "ta-ratings-img"
      })]
    }) : '', tripAdvisorData !== null && tripAdvisorData !== void 0 && tripAdvisorData.numberOfReviews ? (0, _jsxRuntime.jsx)(_react.Text, _objectSpread(_objectSpread({
      as: "u",
      ml: "2px",
      mt: "8px"
    }, cursorStyle), {}, {
      onClick: scrollToUserReviewSection,
      children: "(".concat(tripAdvisorData.numberOfReviews, " ").concat(t('hoteldetails.tripadvisorreviews'), ")")
    })) : '', tripAdvisorData === null || tripAdvisorData === void 0 || (_tripAdvisorData$awar = tripAdvisorData.awards) === null || _tripAdvisorData$awar === void 0 ? void 0 : _tripAdvisorData$awar.map(award => {
      var _award$awardType, _award$awardType3;
      return (0, _react2.createElement)(_react.Image, _objectSpread(_objectSpread({
        src: (award === null || award === void 0 || (_award$awardType = award.awardType) === null || _award$awardType === void 0 ? void 0 : _award$awardType.toLowerCase()) === 'travelers choice' ? (0, _utils.formatAssetsUrl)(t('tripadvisor.award.choice.imageSrc')) : (0, _utils.formatAssetsUrl)(t('tripadvisor.award.best.imageSrc')),
        onClick: () => {
          var _award$awardType2;
          return awardModalClickHandler((_award$awardType2 = award === null || award === void 0 ? void 0 : award.awardType) !== null && _award$awardType2 !== void 0 ? _award$awardType2 : '');
        },
        maxWidth: "32px"
      }, cursorStyle), {}, {
        ml: 3,
        key: award === null || award === void 0 || (_award$awardType3 = award.awardType) === null || _award$awardType3 === void 0 ? void 0 : _award$awardType3.toLowerCase()
      }));
    }), (0, _jsxRuntime.jsx)(_atoms.ModalVariants, {
      onClose: toggleModal,
      isOpen: isModalOpen,
      variant: "info",
      children: (0, _jsxRuntime.jsx)(_react.Box, _objectSpread(_objectSpread({}, modalStyles), {}, {
        children: (0, _jsxRuntime.jsxs)(_react.Box, _objectSpread(_objectSpread({
          maxW: "full"
        }, containerStyles), {}, {
          children: [(0, _jsxRuntime.jsx)(_react.Text, _objectSpread(_objectSpread({}, awardModaltitle), {}, {
            children: awardModalData.modalTitle
          })), (0, _jsxRuntime.jsx)(_react.Center, {
            children: (0, _jsxRuntime.jsx)(_react.Image, _objectSpread({
              src: awardModalData.image
            }, modalAwardIcon))
          }), (0, _jsxRuntime.jsx)(_react.Text, {
            mt: "15px",
            mb: "15px",
            children: awardModalData.title
          }), (0, _jsxRuntime.jsx)(_react.Text, {
            mt: 3,
            children: (0, _utils.renderSanitizedHtml)(awardModalData.description)
          })]
        }))
      }))
    })]
  });
}
var modalStyles = {
  w: {
    md: '35.125rem'
  },
  px: 'md',
  pb: 'lg'
};
var containerStyles = {
  mb: {
    base: 'lg',
    sm: '2xl'
  }
};
var cursorStyle = {
  cursor: 'pointer'
};
var awardModaltitle = {
  fontWeight: 'bold'
};
var modalAwardIcon = {
  w: '200px',
  h: '230px'
};