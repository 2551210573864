"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getSuggestions = getSuggestions;
var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));
var _config = _interopRequireDefault(require("next/config"));
var {
  publicRuntimeConfig = {}
} = (0, _config.default)() || {};
function getSuggestions(_x) {
  return _getSuggestions.apply(this, arguments);
}
function _getSuggestions() {
  _getSuggestions = (0, _asyncToGenerator2.default)(function* (value) {
    var response = yield fetch("".concat(publicRuntimeConfig.NEXT_PUBLIC_SNOWDROP_BASE_URL, "v1/autocomplete?input=/").concat(value, "&gplaces[components]=country:uk|country:de"));
    return response.json();
  });
  return _getSuggestions.apply(this, arguments);
}