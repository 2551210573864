"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getNodeFetch = void 0;
var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));
var getFetchWithAgent = function () {
  var _ref = (0, _asyncToGenerator2.default)(function* (url, options) {
    return globalThis.fetch(url, options);
  });
  return function getFetchWithAgent(_x, _x2) {
    return _ref.apply(this, arguments);
  };
}();
var getNodeFetch = () => {
  return getFetchWithAgent;
};
exports.getNodeFetch = getNodeFetch;