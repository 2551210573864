"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var React = _interopRequireWildcard(require("react"));
var _jsxRuntime = require("react/jsx-runtime");
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && {}.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
var SvgInterconnectingRooms = props => (0, _jsxRuntime.jsx)("svg", _objectSpread(_objectSpread({
  width: 24,
  height: 24,
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg"
}, props), {}, {
  children: (0, _jsxRuntime.jsx)("path", {
    fillRule: "evenodd",
    clipRule: "evenodd",
    d: "M5 12a1 1 0 0 1 1 1v5H5v6H1v-6H0v-5a1 1 0 0 1 1-1h4Zm18 0a1 1 0 0 1 1 1v5h-1v6h-4v-6h-1v-5a1 1 0 0 1 1-1h4ZM17 0v24H7V4h9V0h1ZM5 13H1v4h1v6h2v-6h1v-4Zm18 0h-4v4h1v6h2v-6h1v-4Zm-7-8H8v18h8V5Zm-4.5 10a.5.5 0 0 1 0 1h-2a.5.5 0 0 1 0-1h2ZM3 6a2.5 2.5 0 1 1 0 5 2.5 2.5 0 0 1 0-5Zm18 0a2.5 2.5 0 1 1 0 5 2.5 2.5 0 0 1 0-5ZM3 7a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3Zm18 0a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3Z",
    fill: props.color || '#58595B'
  })
}));
var _default = exports.default = SvgInterconnectingRooms;