"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = setAnalyticsPageNameAndType;
var _api = require("@whitbread-eos/api");
var _analytics = _interopRequireDefault(require("./analytics"));
function setAnalyticsPageNameAndType(pathName) {
  var pageDetails = _api.ANALYTICS_PAGE_MAPPER[pathName === null || pathName === void 0 ? void 0 : pathName.replace(_api.BB_PATHNAME_PREFIX, '')] || {
    pageName: '',
    pageType: 'look to book'
  };
  _analytics.default.update({
    pageName: pageDetails.pageName,
    pageType: pageDetails.pageType
  });
}