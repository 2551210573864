"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = useSetOrientation;
exports.useOrientation = useOrientation;
var _react = require("@chakra-ui/react");
var _react2 = require("react");
var _AppDataContext = require("../store/AppDataContext");
function useSetOrientation() {
  var dispatch = (0, _AppDataContext.useAppDataDispatch)();
  var [portrait, landscape] = (0, _react.useMediaQuery)(['(orientation: portrait)', '(orientation: landscape)']);
  (0, _react2.useEffect)(() => {
    switch (true) {
      case portrait:
        dispatch({
          type: 'ui/orientation',
          payload: 'portrait'
        });
        break;
      case landscape:
        dispatch({
          type: 'ui/orientation',
          payload: 'landscape'
        });
    }
  }, [portrait, landscape, dispatch]);
}
function useOrientation() {
  var orientations = {
    isPortrait: undefined,
    isLandscape: undefined
  };
  var appData = (0, _AppDataContext.useAppData)();
  switch (appData === null || appData === void 0 ? void 0 : appData.orientation) {
    case 'portrait':
      return {
        isPortrait: true,
        isLandscape: false
      };
    case 'landscape':
      return {
        isPortrait: false,
        isLandscape: true
      };
    default:
      return orientations;
  }
}