"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = HotelFacilitiesQueryWrapper;
var _reactQuery = require("@tanstack/react-query");
var _HotelFacilitiesList = _interopRequireDefault(require("./HotelFacilitiesList.container"));
var _jsxRuntime = require("react/jsx-runtime");
function HotelFacilitiesQueryWrapper(_ref) {
  var {
    queryClient,
    isLessThanSm
  } = _ref;
  return (0, _jsxRuntime.jsx)(_reactQuery.QueryClientProvider, {
    client: queryClient,
    children: (0, _jsxRuntime.jsx)(_HotelFacilitiesList.default, {
      isLessThanSm: isLessThanSm
    })
  });
}