"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = AgentOverrideButton;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _react = require("@chakra-ui/react");
var _atoms = require("@whitbread-eos/atoms");
var _utils = require("@whitbread-eos/utils");
var _react2 = require("react");
var _reactHookForm = require("react-hook-form");
var _jsxRuntime = require("react/jsx-runtime");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function AgentOverrideButton(_ref) {
  var {
    formField,
    control,
    errors,
    handleResetField
  } = _ref;
  var [disabled, setDisabled] = (0, _react2.useState)(true);
  var selectReason = (0, _reactHookForm.useWatch)({
    name: 'selectReason',
    control
  });
  var callerName = (0, _reactHookForm.useWatch)({
    name: 'callerName',
    control
  });
  var managerName = (0, _reactHookForm.useWatch)({
    name: 'managerName',
    control
  });
  var errorValues = [...Object.values(errors)];
  var manageBtnBehaviour = () => {
    if (selectReason !== '' && callerName !== '' && (formField.props.isManagerApprovalNeeded && managerName !== '' || !formField.props.isManagerApprovalNeeded) && !Object.values(errors).some(error => {
      var _error$message;
      return ((_error$message = error.message) === null || _error$message === void 0 ? void 0 : _error$message.length) > 0;
    }) && disabled) {
      setDisabled(false);
    }
    if ((selectReason === '' || callerName === '' || formField.props.isManagerApprovalNeeded && managerName === '' || Object.values(errors).some(error => {
      var _error$message2;
      return ((_error$message2 = error.message) === null || _error$message2 === void 0 ? void 0 : _error$message2.length) > 0;
    })) && !disabled) {
      setDisabled(true);
    }
  };
  (0, _react2.useEffect)(() => {
    if (!formField.props.isManagerApprovalNeeded) {
      handleResetField('managerName', {
        defaultValue: ''
      });
    }
  }, [formField.props.isManagerApprovalNeeded, handleResetField]);
  (0, _react2.useEffect)(() => {
    manageBtnBehaviour();
  }, [selectReason, callerName, managerName, errorValues]);
  var testId = formField.testid || 'Submit';
  return (0, _jsxRuntime.jsx)(_react.Flex, {
    children: (0, _jsxRuntime.jsx)(_atoms.Button, _objectSpread(_objectSpread(_objectSpread(_objectSpread({
      type: "submit",
      "data-testid": (0, _utils.formatDataTestId)(testId, 'Button')
    }, formField.props), {}, {
      isDisabled: disabled
    }, searchButtonStyle), searchBtnTextStyle), {}, {
      onClick: formField.props.action,
      children: formField.label
    }))
  });
}
var searchButtonStyle = {
  mt: 'lg',
  background: 'primary',
  width: 'full'
};
var searchBtnTextStyle = {
  fontWeight: 'semibold',
  fontSize: 'lg',
  lineHeight: '3',
  color: 'baseWhite'
};