"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.displayMethodType = displayMethodType;
exports.displayPaymentHelpText = displayPaymentHelpText;
exports.getDonationForBooking = getDonationForBooking;
exports.getTotalCost = getTotalCost;
exports.isApplePayConfigured = isApplePayConfigured;
exports.sortImagesByOrder = sortImagesByOrder;
var _api = require("@whitbread-eos/api");
function displayMethodType(method, variant) {
  var {
    type,
    name,
    card,
    subType
  } = method;
  switch (true) {
    case type === _api.PiCardType.NEW_PIBA && name === _api.CardName.PIBA_UK:
      return 'cc.NEW_PIBA_UK';
    case type === _api.PiCardType.NEW_PIBA && name === _api.CardName.PIBA_EU:
      return 'cc.NEW_PIBA_EURO';
    case type === _api.PiCardType.NEW_PIBA && subType === _api.PiCardSubType.PIBA_UK:
      return 'cc.NEW_PIBA';
    case type === _api.PiCardType.NEW_PIBA && subType === _api.PiCardSubType.PIBA_EU:
      return 'cc.NEW_PIBA_EURO';
    case type === _api.PiCardType.ACCOUNT_COMPANY:
      return 'cc.ACCOUNT_TO_COMPANY';
    case type === _api.PiCardType.RESERVE_WITHOUT_CARD:
      return 'paymentOptions.RESERVE_WITHOUT_CARD';
    case type === _api.PiCardType.AMEND_SAVED_CARD:
      return name;
    case type === _api.PiCardType.SAVED_CARD:
      if (variant === _api.Area.PI) {
        return card === null || card === void 0 ? void 0 : card.cardName;
      }
      return (card === null || card === void 0 ? void 0 : card.cardType) === _api.BusinessCardType.BUSINESS_PERSONAL_STORED_CARD ? 'booking.payment.storedcard.personal' : 'booking.payment.storedcard.central';
    case type === _api.PiCardType.PAYPAL_PAYMENT:
      return "booking.payment.paypal";
    default:
      return "cc.".concat(type);
  }
}
function displayPaymentHelpText(method) {
  var {
    type,
    card
  } = method;
  switch (type) {
    case _api.PiCardType.NEW_CARD:
    case _api.PiCardType.NEW_PIBA:
      return 'terms.infoNewCard';
    case _api.PiCardType.SAVED_CARD:
      return (card === null || card === void 0 ? void 0 : card.cardType) === _api.BusinessCardType.BUSINESS_CENTRALLY_STORED_CARD ? 'terms.infoSavedPibaCard' : 'cc.cvvRequired.info';
    case _api.PiCardType.APGP:
      return "terms.infoAPGP";
    case _api.PiCardType.PAYPAL_PAYMENT:
      return "terms.infoPayPal";
    default:
      return "cc.cvvRequired.info";
  }
}
function isApplePayConfigured() {
  var _ApplePaySession;
  return window !== undefined && ((_ApplePaySession = window.ApplePaySession) === null || _ApplePaySession === void 0 ? void 0 : _ApplePaySession.canMakePayments());
}
function getDonationForBooking(packages, donationPackages) {
  var _donationPackages$fin;
  var defaultDonation = {
    unitPrice: 0,
    code: ''
  };
  if (!packages || !donationPackages) {
    return defaultDonation;
  }
  return (_donationPackages$fin = donationPackages.find(pckg => (pckg === null || pckg === void 0 ? void 0 : pckg.code) === (packages === null || packages === void 0 ? void 0 : packages.id))) !== null && _donationPackages$fin !== void 0 ? _donationPackages$fin : defaultDonation;
}
function getTotalCost(ratePlanCode, totalCost, currency, packages, donationPackages, selectedDonation, variant) {
  var defaultTotalCost = {
    name: ratePlanCode,
    totalCost: {
      amount: totalCost,
      currency: currency
    }
  };
  if (variant === _api.Area.PI) {
    var bookingDonation = getDonationForBooking(packages, donationPackages);
    if (packages && (packages === null || packages === void 0 ? void 0 : packages.id) !== '' && (packages === null || packages === void 0 ? void 0 : packages.id) === (selectedDonation === null || selectedDonation === void 0 ? void 0 : selectedDonation.code)) {
      return defaultTotalCost;
    } else {
      var _bookingDonation$unit, _selectedDonation$uni;
      return {
        name: ratePlanCode,
        totalCost: {
          amount: String(+totalCost - ((_bookingDonation$unit = bookingDonation === null || bookingDonation === void 0 ? void 0 : bookingDonation.unitPrice) !== null && _bookingDonation$unit !== void 0 ? _bookingDonation$unit : 0) + ((_selectedDonation$uni = selectedDonation === null || selectedDonation === void 0 ? void 0 : selectedDonation.unitPrice) !== null && _selectedDonation$uni !== void 0 ? _selectedDonation$uni : 0)),
          currency: currency
        }
      };
    }
  }
  return defaultTotalCost;
}
function sortImagesByOrder(imageList) {
  var sortOrder = ['MC', 'VS', 'PP', 'AP', 'GP', 'AX'];
  var sortedImages = {};
  sortOrder.forEach(key => {
    if (imageList[key] !== undefined) {
      sortedImages[key] = imageList[key];
    }
  });
  for (var key in imageList) {
    if (sortedImages[key] === undefined) {
      sortedImages[key] = imageList[key];
    }
  }
  return sortedImages;
}