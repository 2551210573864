"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = Newsletter;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _react = require("@chakra-ui/react");
var _utils = require("@whitbread-eos/utils");
var _react2 = _interopRequireWildcard(require("react"));
var _jsxRuntime = require("react/jsx-runtime");
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && {}.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function Newsletter(_ref) {
  var {
    data,
    routerPush
  } = _ref;
  var hasNewsLetter = (0, _react2.useMemo)(() => {
    var {
      newsletterSignup
    } = data;
    return hasNewsLetterSignupContent(newsletterSignup);
  }, [data]);
  if (!hasNewsLetter) {
    return null;
  }
  var {
    introViewTitle,
    introViewText,
    signUpButtonText
  } = data.newsletterSignup;
  return (0, _jsxRuntime.jsx)(_react.Flex, _objectSpread(_objectSpread({}, newsletterWrapperStyle), {}, {
    children: (0, _jsxRuntime.jsx)(_react.Box, {
      p: "lg",
      children: (0, _jsxRuntime.jsxs)(_react.Flex, {
        direction: "column",
        children: [(0, _jsxRuntime.jsx)(_react.Text, _objectSpread(_objectSpread({}, newsletterTitleStyle), {}, {
          "data-testid": 'newsletter-title',
          children: introViewTitle
        })), (0, _jsxRuntime.jsx)(_react.Text, _objectSpread(_objectSpread({}, newsletterDescriptionStyle), {}, {
          "data-testid": 'newsletter-description',
          children: (0, _utils.renderSanitizedHtml)(introViewText)
        })), (0, _jsxRuntime.jsx)(_react.Button, _objectSpread(_objectSpread({}, newsletterButtonStyle), {}, {
          "data-testid": 'newsletter-link',
          onClick: () => {
            routerPush('https://www.premierinn.com/de/de/presse.html');
          },
          children: signUpButtonText
        }))]
      })
    })
  }));
}
function hasNewsLetterSignupContent(newsletterSignup) {
  if (!newsletterSignup) {
    return false;
  }
  return Object.values(newsletterSignup).every(value => {
    if (value !== null) {
      return true;
    }
    return false;
  });
}
var newsletterWrapperStyle = {
  direction: 'column',
  w: 'full',
  bgColor: 'lightPurple'
};
var newsletterTitleStyle = {
  fontSize: 'md',
  color: 'baseWhite',
  fontWeight: 'medium'
};
var newsletterDescriptionStyle = {
  fontSize: 'sm',
  color: 'baseWhite',
  fontWeight: 'normal'
};
var newsletterButtonStyle = {
  size: 'full',
  variant: 'tertiary',
  mt: 'lg'
};