"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.GET_BOOKING_CONFIRMATION_AMEND = void 0;
var _taggedTemplateLiteral2 = _interopRequireDefault(require("@babel/runtime/helpers/taggedTemplateLiteral"));
var _graphqlRequest = require("graphql-request");
var _templateObject;
var GET_BOOKING_CONFIRMATION_AMEND = exports.GET_BOOKING_CONFIRMATION_AMEND = (0, _graphqlRequest.gql)(_templateObject || (_templateObject = (0, _taggedTemplateLiteral2.default)(["\n  query getBookingConfirmationAmend(\n    $basketReference: String!\n    $country: String!\n    $language: String!\n  ) {\n    bookingConfirmation(basketReference: $basketReference, country: $country, language: $language) {\n      bookingFlowId\n      hotelId\n      hotelName\n      infoMessages\n      currencyCode\n      totalCost\n      previousTotal\n      newTotal\n      channel\n      companyId\n      reservationByIdList {\n        reservationId\n        preCheckInStatus\n        reservationGuestList {\n          givenName\n          surName\n          nameTitle\n          email\n          address {\n            addressLine1\n            addressLine2\n            addressLine3\n            addressLine4\n            cityName\n            postalCode\n            countryCode\n          }\n        }\n        billing {\n          address {\n            addressLine1\n            addressLine2\n            addressLine3\n            addressLine4\n            companyName\n            country\n            postalCode\n          }\n          email\n        }\n        roomStay {\n          adultsNumber\n          childrenNumber\n          arrivalDate\n          departureDate\n          ratePlanCode\n          roomExtraInfo {\n            roomType\n            roomName\n            groupId\n          }\n          accessibleRoom {\n            phoneNumber\n            isAccessible\n          }\n          roomPrice\n        }\n      }\n    }\n  }\n"])));