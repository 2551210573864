"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getTableRows = exports.getTableColumns = exports.getFormattedAddress = void 0;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _atoms = require("@whitbread-eos/atoms");
var _modalType = require("./modalType.enum");
var _tableFields = require("./tableFields.enum");
var _jsxRuntime = require("react/jsx-runtime");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
var getTableColumns = (t, companyModalType) => {
  var tableListColumns = [{
    key: _tableFields.TABLE_FIELDS.COMPANY_NAME,
    title: t('ccui.companyModals.companyName')
  }, {
    key: _tableFields.TABLE_FIELDS.ADDRESS,
    title: t('ccui.companyModals.address')
  }, {
    key: _tableFields.TABLE_FIELDS.TELEPHONE_NUMBER,
    title: t('ccui.companyModals.tel')
  }, {
    key: _tableFields.TABLE_FIELDS.CORP_ID,
    title: t('ccui.companyModals.corpID')
  }, {
    key: _tableFields.TABLE_FIELDS.COMPANY_ID,
    title: t('ccui.companyModals.companyID')
  }];
  var accountToCompanyColumns = [{
    key: _tableFields.TABLE_FIELDS.AR_NUMBER,
    title: t('ccui.companyModals.arNumber')
  }, {
    key: _tableFields.TABLE_FIELDS.RESTRICTED,
    title: t('ccui.companyModals.restricted'),
    render: row => {
      var rowValue = row[_tableFields.TABLE_FIELDS.RESTRICTED];
      var restricted = typeof rowValue === 'string' && rowValue.toLowerCase() === 'true';
      return (0, _jsxRuntime.jsx)(_atoms.Checkbox, {
        isChecked: restricted,
        isReadOnly: true,
        checkboxWrapperStyles: {
          my: '0'
        }
      });
    }
  }];
  return companyModalType === _modalType.COMPANY_MODAL_TYPE.ACCOUNT_TO_COMPANY ? [...tableListColumns, ...accountToCompanyColumns] : tableListColumns;
};
exports.getTableColumns = getTableColumns;
var getTableRows = (companies, companyModalType) => {
  return companies.map(company => {
    var {
      [_tableFields.TABLE_FIELDS.COMPANY_NAME]: name,
      [_tableFields.TABLE_FIELDS.ADDRESS]: address,
      [_tableFields.TABLE_FIELDS.TELEPHONE_NUMBER]: telephoneNumber,
      [_tableFields.TABLE_FIELDS.CORP_ID]: corpId,
      [_tableFields.TABLE_FIELDS.COMPANY_ID]: compId,
      [_tableFields.TABLE_FIELDS.AR_NUMBER]: arNumber,
      [_tableFields.TABLE_FIELDS.RESTRICTED]: restricted
    } = company;
    var formattedAddress = getFormattedAddress(address);
    var row = {
      [_tableFields.TABLE_FIELDS.COMPANY_NAME]: name,
      [_tableFields.TABLE_FIELDS.ADDRESS]: formattedAddress,
      [_tableFields.TABLE_FIELDS.TELEPHONE_NUMBER]: telephoneNumber,
      [_tableFields.TABLE_FIELDS.CORP_ID]: corpId !== null && corpId !== void 0 ? corpId : 'NA',
      [_tableFields.TABLE_FIELDS.COMPANY_ID]: compId
    };
    var accountToCompanyFields = {
      [_tableFields.TABLE_FIELDS.AR_NUMBER]: arNumber,
      [_tableFields.TABLE_FIELDS.RESTRICTED]: String(restricted)
    };
    return companyModalType === _modalType.COMPANY_MODAL_TYPE.ACCOUNT_TO_COMPANY ? _objectSpread(_objectSpread({}, row), accountToCompanyFields) : row;
  });
};
exports.getTableRows = getTableRows;
var getFormattedAddress = address => {
  var {
    addressLine1,
    addressLine2,
    addressLine3,
    addressLine4 = '',
    country,
    postalCode
  } = address;
  return [addressLine1, addressLine2, addressLine3, addressLine4, country, postalCode].filter(Boolean).join(', ');
};
exports.getFormattedAddress = getFormattedAddress;