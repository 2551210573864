"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
var _getPreCheckInBookingInformation = require("./getPreCheckInBookingInformation");
Object.keys(_getPreCheckInBookingInformation).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _getPreCheckInBookingInformation[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _getPreCheckInBookingInformation[key];
    }
  });
});
var _createPreCheckInReservationGuest = require("./createPreCheckInReservationGuest");
Object.keys(_createPreCheckInReservationGuest).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _createPreCheckInReservationGuest[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _createPreCheckInReservationGuest[key];
    }
  });
});
var _attachFileToReservation = require("./attachFileToReservation");
Object.keys(_attachFileToReservation).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _attachFileToReservation[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _attachFileToReservation[key];
    }
  });
});
var _preCheckInStaus = require("./preCheckInStaus");
Object.keys(_preCheckInStaus).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _preCheckInStaus[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _preCheckInStaus[key];
    }
  });
});