"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.LanguageEnum = void 0;
var LanguageEnum = exports.LanguageEnum = function (LanguageEnum) {
  LanguageEnum["ENGLISH"] = "en";
  LanguageEnum["GERMAN"] = "de";
  return LanguageEnum;
}({});