"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = ImportantNotificationComponent;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _react = require("@chakra-ui/react");
var _atoms = require("@whitbread-eos/atoms");
var _parse = _interopRequireDefault(require("date-fns/parse"));
var _nextI18next = require("next-i18next");
var _jsxRuntime = require("react/jsx-runtime");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function ImportantNotificationComponent(_ref) {
  var _importantInfo$infoIt, _importantInfo$title;
  var {
    isLoading,
    isError,
    error,
    importantInfo,
    arrival,
    departure
  } = _ref;
  var {
    t
  } = (0, _nextI18next.useTranslation)(['common']);
  if (isLoading) {
    return (0, _jsxRuntime.jsx)(_react.Text, {
      children: t('searchresults.list.hotel.loading')
    });
  }
  if (isError) {
    return (0, _jsxRuntime.jsx)(_react.Text, {
      children: error.message
    });
  }
  var importantNotification = infoForTimePeriod();
  if (!(importantInfo !== null && importantInfo !== void 0 && (_importantInfo$infoIt = importantInfo.infoItems) !== null && _importantInfo$infoIt !== void 0 && _importantInfo$infoIt.length) || !(importantNotification !== null && importantNotification !== void 0 && importantNotification.length)) {
    return null;
  }
  return (0, _jsxRuntime.jsx)(_react.Box, {
    mb: "md",
    "data-testid": "important-notification",
    children: (0, _jsxRuntime.jsx)(_atoms.Notification, _objectSpread({
      title: (_importantInfo$title = importantInfo === null || importantInfo === void 0 ? void 0 : importantInfo.title) !== null && _importantInfo$title !== void 0 ? _importantInfo$title : '',
      description: importantNotification,
      variant: "info",
      status: "info",
      svg: (0, _jsxRuntime.jsx)(_atoms.Info, {}),
      prefixDataTestId: "important-notification"
    }, notificationStyles))
  });
  function infoForTimePeriod() {
    var _importantInfo$infoIt2;
    var sortByPriority = (itemA, itemB) => Number(itemA === null || itemA === void 0 ? void 0 : itemA.priority) - Number(itemB === null || itemB === void 0 ? void 0 : itemB.priority);
    return importantInfo === null || importantInfo === void 0 || (_importantInfo$infoIt2 = importantInfo.infoItems) === null || _importantInfo$infoIt2 === void 0 ? void 0 : _importantInfo$infoIt2.filter(infoItem => {
      var _infoItem$startDate, _infoItem$endDate;
      var startTime = (0, _parse.default)((_infoItem$startDate = infoItem === null || infoItem === void 0 ? void 0 : infoItem.startDate) !== null && _infoItem$startDate !== void 0 ? _infoItem$startDate : '', 'dd/MM/yyyy', new Date());
      var endTime = (0, _parse.default)((_infoItem$endDate = infoItem === null || infoItem === void 0 ? void 0 : infoItem.endDate) !== null && _infoItem$endDate !== void 0 ? _infoItem$endDate : '', 'dd/MM/yyyy', new Date());
      var arrivalTime = new Date(arrival);
      var departureTime = new Date(departure);
      return startTime >= arrivalTime && startTime <= departureTime || arrivalTime >= startTime && arrivalTime <= endTime;
    }).sort(sortByPriority).map(infoItem => {
      var _infoItem$text;
      return (_infoItem$text = infoItem === null || infoItem === void 0 ? void 0 : infoItem.text) !== null && _infoItem$text !== void 0 ? _infoItem$text : '';
    });
  }
}
var notificationStyles = {
  maxW: {
    base: 'full',
    md: 'full'
  },
  lineHeight: '2',
  backgroundColor: 'baseWhite',
  border: 'none'
};