"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.setDisplayedSectionsForStoredCard = exports.setDisplayedSectionsForPersonalCard = exports.setDisplayedSectionsForNormalCard = exports.setBusinessAllowancesSections = exports.getPaymentError = exports.getIsDonationsDisplayed = exports.getIsDonationInfoBoxDisplayed = exports.getIsBillingAddressDisplayed = exports.getIsBBCardDetailsDisplayed = exports.getBookingSummaryData = exports.getBillingAddress = exports.getA2CBusinessAllowances = void 0;
var _api = require("@whitbread-eos/api");
var _selectors = require("../selectors");
var _analyticsService = require("../services/analyticsService");
var getBookingSummaryData = _ref => {
  var _bkngData$bookingInfo, _updatedTotalCost$tot, _firstRoom$roomStay, _bkngData$bookingInfo2, _firstRoom$roomStay2, _firstRoom$roomStay3, _bkngData$bookingInfo3, _termsAndConditionsDa;
  var {
    hiData,
    bkngData,
    selectedDonation,
    termsAndConditionsData,
    onclickBillingFormHandler,
    onSubmitBtnText,
    firstRoom,
    noNights,
    selectedMeals,
    adultsMeals,
    childrenMeals,
    roomSelection,
    paymentStepState,
    updatedTotalCost
  } = _ref;
  var bookingSummaryData = {
    hotelInformation: (hiData === null || hiData === void 0 ? void 0 : hiData.hotelInformation) && (0, _selectors.hotelInformationSelector)(hiData === null || hiData === void 0 ? void 0 : hiData.hotelInformation),
    totalCost: {
      currency: bkngData === null || bkngData === void 0 || (_bkngData$bookingInfo = bkngData.bookingInformation) === null || _bkngData$bookingInfo === void 0 ? void 0 : _bkngData$bookingInfo.currencyCode,
      initialTotalCost: (updatedTotalCost === null || updatedTotalCost === void 0 || (_updatedTotalCost$tot = updatedTotalCost.totalCost) === null || _updatedTotalCost$tot === void 0 ? void 0 : _updatedTotalCost$tot.amount) - (0, _selectors.calculateTotalCostRoomSelection)(adultsMeals, roomSelection, noNights),
      meals: (0, _selectors.selectedMealsPerRoomSelector)(selectedMeals, adultsMeals, childrenMeals),
      donations: selectedDonation === null || selectedDonation === void 0 ? void 0 : selectedDonation.unitPrice
    },
    rateInformation: {
      rate: (_firstRoom$roomStay = firstRoom.roomStay) === null || _firstRoom$roomStay === void 0 || (_firstRoom$roomStay = _firstRoom$roomStay.rateExtraInfo) === null || _firstRoom$roomStay === void 0 ? void 0 : _firstRoom$roomStay.rateName,
      noNights: noNights,
      noRooms: bkngData === null || bkngData === void 0 || (_bkngData$bookingInfo2 = bkngData.bookingInformation) === null || _bkngData$bookingInfo2 === void 0 || (_bkngData$bookingInfo2 = _bkngData$bookingInfo2.reservationByIdList) === null || _bkngData$bookingInfo2 === void 0 ? void 0 : _bkngData$bookingInfo2.length
    },
    stayDatesInformation: {
      arrivalDate: ((_firstRoom$roomStay2 = firstRoom.roomStay) === null || _firstRoom$roomStay2 === void 0 ? void 0 : _firstRoom$roomStay2.arrivalDate) || null,
      departureDate: ((_firstRoom$roomStay3 = firstRoom.roomStay) === null || _firstRoom$roomStay3 === void 0 ? void 0 : _firstRoom$roomStay3.departureDate) || null,
      noNights: noNights
    },
    roomInformation: (0, _selectors.roomInformationSelector)(bkngData === null || bkngData === void 0 || (_bkngData$bookingInfo3 = bkngData.bookingInformation) === null || _bkngData$bookingInfo3 === void 0 ? void 0 : _bkngData$bookingInfo3.reservationByIdList, selectedMeals, adultsMeals, childrenMeals, (0, _selectors.roomPackageSelection)((0, _selectors.extrasPackagesMapperSelector)(roomSelection))),
    termsAndConditionsText: termsAndConditionsData === null || termsAndConditionsData === void 0 || (_termsAndConditionsDa = termsAndConditionsData.termsAndConditions) === null || _termsAndConditionsDa === void 0 ? void 0 : _termsAndConditionsDa.text,
    onclickBillingFormHandler: onclickBillingFormHandler,
    onSubmitBtnText: onSubmitBtnText,
    paymentStepState
  };
  return bookingSummaryData;
};
exports.getBookingSummaryData = getBookingSummaryData;
var getIsBillingAddressDisplayed = _ref2 => {
  var {
    selectedPaymentType,
    selectedPaymentDetail,
    isBb = false,
    formData
  } = _ref2;
  return (selectedPaymentType === null || selectedPaymentType === void 0 ? void 0 : selectedPaymentType.type) !== 'SAVED_CARD' && (selectedPaymentDetail === null || selectedPaymentDetail === void 0 ? void 0 : selectedPaymentDetail.type) !== 'RESERVE_WITHOUT_CARD' && (isBb || !(formData !== null && formData !== void 0 && formData.isBillingAddressDisplayed));
};
exports.getIsBillingAddressDisplayed = getIsBillingAddressDisplayed;
var getIsDonationsDisplayed = _ref3 => {
  var _hiData$hotelInformat;
  var {
    currentLang,
    hiData
  } = _ref3;
  return currentLang === 'en' && (hiData === null || hiData === void 0 || (_hiData$hotelInformat = hiData.hotelInformation) === null || _hiData$hotelInformat === void 0 || (_hiData$hotelInformat = _hiData$hotelInformat.address) === null || _hiData$hotelInformat === void 0 ? void 0 : _hiData$hotelInformat.country) !== 'Ireland';
};
exports.getIsDonationsDisplayed = getIsDonationsDisplayed;
var getIsDonationInfoBoxDisplayed = _ref4 => {
  var _hiData$hotelInformat2, _donationsData$donati;
  var {
    currentLang,
    paymentStepState,
    hiData,
    paymentSteps,
    donationsData
  } = _ref4;
  return paymentStepState === paymentSteps.PAYMENT_DETAILS && currentLang === 'en' && (hiData === null || hiData === void 0 || (_hiData$hotelInformat2 = hiData.hotelInformation) === null || _hiData$hotelInformat2 === void 0 || (_hiData$hotelInformat2 = _hiData$hotelInformat2.address) === null || _hiData$hotelInformat2 === void 0 ? void 0 : _hiData$hotelInformat2.country) !== 'Ireland' && (donationsData === null || donationsData === void 0 || (_donationsData$donati = donationsData.donations) === null || _donationsData$donati === void 0 ? void 0 : _donationsData$donati.informationBox);
};
exports.getIsDonationInfoBoxDisplayed = getIsDonationInfoBoxDisplayed;
var GREATER_LONDON_COUNTY = 'greater-london';
var setBusinessAllowancesSections = _ref5 => {
  var _hiData$hotelInformat3, _selectedPaymentType$4, _selectedPaymentType$5;
  var {
    displayedSections,
    selectedPaymentType,
    hotelCounty,
    hiData
  } = _ref5;
  var country = hiData === null || hiData === void 0 || (_hiData$hotelInformat3 = hiData.hotelInformation) === null || _hiData$hotelInformat3 === void 0 || (_hiData$hotelInformat3 = _hiData$hotelInformat3.address) === null || _hiData$hotelInformat3 === void 0 ? void 0 : _hiData$hotelInformat3.country;
  var isGreaterLondonCountyHotel = hotelCounty === GREATER_LONDON_COUNTY;
  var amount;
  if (country !== _api.CountryEnum.GB) {
    var _selectedPaymentType$;
    amount = selectedPaymentType === null || selectedPaymentType === void 0 || (_selectedPaymentType$ = selectedPaymentType.bookingAllowances) === null || _selectedPaymentType$ === void 0 || (_selectedPaymentType$ = _selectedPaymentType$.maxDinnerBudgets) === null || _selectedPaymentType$ === void 0 || (_selectedPaymentType$ = _selectedPaymentType$.ireland) === null || _selectedPaymentType$ === void 0 ? void 0 : _selectedPaymentType$.amount;
  } else if (isGreaterLondonCountyHotel) {
    var _selectedPaymentType$2;
    amount = selectedPaymentType === null || selectedPaymentType === void 0 || (_selectedPaymentType$2 = selectedPaymentType.bookingAllowances) === null || _selectedPaymentType$2 === void 0 || (_selectedPaymentType$2 = _selectedPaymentType$2.maxDinnerBudgets) === null || _selectedPaymentType$2 === void 0 || (_selectedPaymentType$2 = _selectedPaymentType$2.greaterLondon) === null || _selectedPaymentType$2 === void 0 ? void 0 : _selectedPaymentType$2.amount;
  } else {
    var _selectedPaymentType$3;
    amount = selectedPaymentType === null || selectedPaymentType === void 0 || (_selectedPaymentType$3 = selectedPaymentType.bookingAllowances) === null || _selectedPaymentType$3 === void 0 || (_selectedPaymentType$3 = _selectedPaymentType$3.maxDinnerBudgets) === null || _selectedPaymentType$3 === void 0 || (_selectedPaymentType$3 = _selectedPaymentType$3.ukWide) === null || _selectedPaymentType$3 === void 0 ? void 0 : _selectedPaymentType$3.amount;
  }
  displayedSections.businessAllowancesSections.amount = amount || 0;
  displayedSections.businessAllowancesSections.allowAlcohol = (selectedPaymentType === null || selectedPaymentType === void 0 || (_selectedPaymentType$4 = selectedPaymentType.bookingAllowances) === null || _selectedPaymentType$4 === void 0 ? void 0 : _selectedPaymentType$4.allowAlcohol) || false;
  displayedSections.businessAllowancesSections.allowCarParking = (selectedPaymentType === null || selectedPaymentType === void 0 || (_selectedPaymentType$5 = selectedPaymentType.bookingAllowances) === null || _selectedPaymentType$5 === void 0 ? void 0 : _selectedPaymentType$5.allowCarParking) || false;
  if (!amount) {
    displayedSections.businessAllowancesSections.allowDinner = false;
  } else {
    displayedSections.businessAllowancesSections.allowDinner = true;
  }
};
exports.setBusinessAllowancesSections = setBusinessAllowancesSections;
var setDisplayedSectionsForStoredCard = _ref6 => {
  var _selectedPaymentType$6, _selectedPaymentType$7, _selectedPaymentType$8;
  var {
    selectedPaymentType,
    displayedSections,
    hotelCounty
  } = _ref6;
  if ((selectedPaymentType === null || selectedPaymentType === void 0 || (_selectedPaymentType$6 = selectedPaymentType.card) === null || _selectedPaymentType$6 === void 0 ? void 0 : _selectedPaymentType$6.cardType) === 'BUSINESS_CENTRALLY_STORED_CARD' && ((selectedPaymentType === null || selectedPaymentType === void 0 || (_selectedPaymentType$7 = selectedPaymentType.card) === null || _selectedPaymentType$7 === void 0 ? void 0 : _selectedPaymentType$7.type) === 'AT' || (selectedPaymentType === null || selectedPaymentType === void 0 || (_selectedPaymentType$8 = selectedPaymentType.card) === null || _selectedPaymentType$8 === void 0 ? void 0 : _selectedPaymentType$8.type) === 'PI')) {
    var _selectedPaymentType$9;
    displayedSections.paymentAuth = false;
    if ((selectedPaymentType === null || selectedPaymentType === void 0 || (_selectedPaymentType$9 = selectedPaymentType.card) === null || _selectedPaymentType$9 === void 0 ? void 0 : _selectedPaymentType$9.cnpRequired) === true) {
      displayedSections.businessAllowances = true;
      setBusinessAllowancesSections({
        displayedSections,
        selectedPaymentType,
        hotelCounty
      });
    } else {
      displayedSections.businessAllowances = false;
    }
    return displayedSections;
  }
};
exports.setDisplayedSectionsForStoredCard = setDisplayedSectionsForStoredCard;
var setDisplayedSectionsForPersonalCard = _ref7 => {
  var _selectedPaymentType$10, _selectedPaymentType$11;
  var {
    selectedPaymentType,
    displayedSections,
    hotelCounty
  } = _ref7;
  if ((selectedPaymentType === null || selectedPaymentType === void 0 || (_selectedPaymentType$10 = selectedPaymentType.card) === null || _selectedPaymentType$10 === void 0 ? void 0 : _selectedPaymentType$10.cardType) === 'BUSINESS_PERSONAL_STORED_CARD' && _api.CARD_TYPES.includes(selectedPaymentType === null || selectedPaymentType === void 0 || (_selectedPaymentType$11 = selectedPaymentType.card) === null || _selectedPaymentType$11 === void 0 ? void 0 : _selectedPaymentType$11.type)) {
    var _selectedPaymentType$12;
    if ((selectedPaymentType === null || selectedPaymentType === void 0 || (_selectedPaymentType$12 = selectedPaymentType.card) === null || _selectedPaymentType$12 === void 0 ? void 0 : _selectedPaymentType$12.cnpRequired) === false) {
      displayedSections.paymentAuth = true;
    }
    displayedSections.businessAllowances = true;
    setBusinessAllowancesSections({
      displayedSections,
      selectedPaymentType,
      hotelCounty
    });
    return displayedSections;
  }
};
exports.setDisplayedSectionsForPersonalCard = setDisplayedSectionsForPersonalCard;
var setDisplayedSectionsForNormalCard = _ref8 => {
  var _selectedPaymentType$13, _selectedPaymentType$14, _selectedPaymentType$15;
  var {
    displayedSections,
    selectedPaymentType,
    hotelCounty
  } = _ref8;
  if ((selectedPaymentType === null || selectedPaymentType === void 0 || (_selectedPaymentType$13 = selectedPaymentType.card) === null || _selectedPaymentType$13 === void 0 ? void 0 : _selectedPaymentType$13.cardType) === 'BUSINESS_CENTRALLY_STORED_CARD' && (selectedPaymentType === null || selectedPaymentType === void 0 || (_selectedPaymentType$14 = selectedPaymentType.card) === null || _selectedPaymentType$14 === void 0 ? void 0 : _selectedPaymentType$14.type) !== 'AT' && (selectedPaymentType === null || selectedPaymentType === void 0 || (_selectedPaymentType$15 = selectedPaymentType.card) === null || _selectedPaymentType$15 === void 0 ? void 0 : _selectedPaymentType$15.type) !== 'PI') {
    var _selectedPaymentType$16;
    displayedSections.paymentAuth = false;
    if ((selectedPaymentType === null || selectedPaymentType === void 0 || (_selectedPaymentType$16 = selectedPaymentType.card) === null || _selectedPaymentType$16 === void 0 ? void 0 : _selectedPaymentType$16.cnpRequired) === true) {
      displayedSections.businessAllowances = true;
      setBusinessAllowancesSections({
        displayedSections,
        selectedPaymentType,
        hotelCounty
      });
    } else {
      displayedSections.businessAllowances = false;
    }
    return displayedSections;
  }
};
exports.setDisplayedSectionsForNormalCard = setDisplayedSectionsForNormalCard;
var getIsBBCardDetailsDisplayed = _ref9 => {
  var {
    selectedPaymentType,
    hotelCounty,
    accessLevel
  } = _ref9;
  var displayedSections = {
    referenceDetails: false,
    paymentAuth: false,
    businessAllowances: false,
    businessAllowancesSections: {
      amountDisabled: true,
      amount: 0,
      allowDinner: true,
      allowAlcohol: false,
      allowCarParking: false,
      allowWiFi: false
    }
  };
  if (accessLevel === _api.UserAccessLevels.SUPER || accessLevel === _api.UserAccessLevels.BOOKER) {
    displayedSections.businessAllowancesSections.amountDisabled = false;
  }
  if (!(selectedPaymentType !== null && selectedPaymentType !== void 0 && selectedPaymentType.bookingAllowances)) {
    return displayedSections;
  }
  if ((selectedPaymentType === null || selectedPaymentType === void 0 ? void 0 : selectedPaymentType.type) === 'NEW_PIBA') {
    displayedSections.referenceDetails = true;
    displayedSections.paymentAuth = true;
    displayedSections.businessAllowances = true;
    setBusinessAllowancesSections({
      displayedSections,
      selectedPaymentType,
      hotelCounty
    });
    return displayedSections;
  } else if ((selectedPaymentType === null || selectedPaymentType === void 0 ? void 0 : selectedPaymentType.type) === 'SAVED_CARD') {
    displayedSections.referenceDetails = true;
    setDisplayedSectionsForStoredCard({
      selectedPaymentType,
      displayedSections,
      hotelCounty
    });
    setDisplayedSectionsForPersonalCard({
      selectedPaymentType,
      displayedSections,
      hotelCounty
    });
    setDisplayedSectionsForNormalCard({
      selectedPaymentType,
      displayedSections,
      hotelCounty
    });
  }
  return displayedSections;
};
exports.getIsBBCardDetailsDisplayed = getIsBBCardDetailsDisplayed;
var getBillingAddress = _ref10 => {
  var _billingAddress$addre, _billing$address, _billing$address2, _billing$address3, _billing$address4, _billing$address5, _billing$address6, _billing$address7, _billing$address8, _billing$address$addr;
  var {
    countryRouter,
    isBillingAddressDisplayed,
    billingAddress,
    billing
  } = _ref10;
  return countryRouter === 'gb' && isBillingAddressDisplayed ? {
    addressLine1: billingAddress === null || billingAddress === void 0 ? void 0 : billingAddress.addressLine1,
    addressLine2: billingAddress === null || billingAddress === void 0 ? void 0 : billingAddress.addressLine2,
    addressLine3: billingAddress === null || billingAddress === void 0 ? void 0 : billingAddress.addressLine3,
    addressLine4: billingAddress === null || billingAddress === void 0 ? void 0 : billingAddress.addressLine4,
    postalCode: billingAddress === null || billingAddress === void 0 ? void 0 : billingAddress.postalCode,
    country: billingAddress !== null && billingAddress !== void 0 && billingAddress.countryCode ? billingAddress === null || billingAddress === void 0 ? void 0 : billingAddress.countryCode : billingAddress === null || billingAddress === void 0 ? void 0 : billingAddress.country,
    countryCode: undefined,
    companyName: undefined,
    addressType: (_billingAddress$addre = billingAddress.addressType) !== null && _billingAddress$addre !== void 0 ? _billingAddress$addre : 'BUSINESS'
  } : {
    addressLine1: billing === null || billing === void 0 || (_billing$address = billing.address) === null || _billing$address === void 0 ? void 0 : _billing$address.addressLine1,
    addressLine2: billing === null || billing === void 0 || (_billing$address2 = billing.address) === null || _billing$address2 === void 0 ? void 0 : _billing$address2.addressLine2,
    addressLine3: billing === null || billing === void 0 || (_billing$address3 = billing.address) === null || _billing$address3 === void 0 ? void 0 : _billing$address3.addressLine3,
    addressLine4: billing === null || billing === void 0 || (_billing$address4 = billing.address) === null || _billing$address4 === void 0 ? void 0 : _billing$address4.addressLine4,
    cityName: billing === null || billing === void 0 || (_billing$address5 = billing.address) === null || _billing$address5 === void 0 ? void 0 : _billing$address5.cityName,
    country: billing === null || billing === void 0 || (_billing$address6 = billing.address) === null || _billing$address6 === void 0 ? void 0 : _billing$address6.countryCode,
    postalCode: billing === null || billing === void 0 || (_billing$address7 = billing.address) === null || _billing$address7 === void 0 ? void 0 : _billing$address7.postalCode,
    companyName: billing === null || billing === void 0 || (_billing$address8 = billing.address) === null || _billing$address8 === void 0 ? void 0 : _billing$address8.companyName,
    addressType: (_billing$address$addr = billing.address.addressType) !== null && _billing$address$addr !== void 0 ? _billing$address$addr : 'BUSINESS'
  };
};
exports.getBillingAddress = getBillingAddress;
var getPaymentError = _ref11 => {
  var {
    isErrorInitiatePaymentMutation,
    isErrorInitiatePaypalPaymentMutation,
    initiatePaymentMutationError,
    initiatePaypalPaymentMutationError,
    currentReasonForStay,
    hotelBrand,
    t
  } = _ref11;
  if (isErrorInitiatePaymentMutation || isErrorInitiatePaypalPaymentMutation) {
    var _errors$0$errorInfo;
    var errorMessage = null;
    var error = (initiatePaymentMutationError === null || initiatePaymentMutationError === void 0 ? void 0 : initiatePaymentMutationError.response) || (initiatePaypalPaymentMutationError === null || initiatePaypalPaymentMutationError === void 0 ? void 0 : initiatePaypalPaymentMutationError.response);
    var errorType = (_errors$0$errorInfo = error.errors[0].errorInfo) === null || _errors$0$errorInfo === void 0 ? void 0 : _errors$0$errorInfo.globalErrTextTemplate;
    _analyticsService.analytics.update({
      validation: errorType
    });
    switch (errorType) {
      case 'fraud_check_failed':
        {
          var reasonForStayLongForm = currentReasonForStay === 'LEI' ? 'leisure' : 'business';
          var errorKey = "errors.booking.fraud.".concat(reasonForStayLongForm, ".").concat(hotelBrand === null || hotelBrand === void 0 ? void 0 : hotelBrand.toLowerCase());
          errorMessage = t(errorKey);
          break;
        }
      case 'errors.payment.generic':
        errorMessage = t('errors.payment.generic');
        break;
      default:
        errorMessage = t('errors.sorry');
        break;
    }
    return errorMessage;
  }
};
exports.getPaymentError = getPaymentError;
var getA2CBusinessAllowances = charges => [{
  allowance: _api.ACCOUNT_TO_COMPANY_ALLOWANCES.BREAKFAST,
  budget: 0,
  isAuthorised: charges.includes(_api.ACCOUNT_TO_COMPANY_ALLOWANCES.BREAKFAST)
}, {
  allowance: _api.ACCOUNT_TO_COMPANY_ALLOWANCES.CAR_PARKING,
  budget: 0,
  isAuthorised: charges.includes(_api.ACCOUNT_TO_COMPANY_ALLOWANCES.CAR_PARKING)
}];
exports.getA2CBusinessAllowances = getA2CBusinessAllowances;