"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.space = void 0;
var space = exports.space = {
  xs: '4px',
  sm: '8px',
  xmd: '12px',
  md: '16px',
  xlg: '20px',
  lg: '24px',
  xl: '32px',
  '2xl': '40px',
  '3xl': '48px',
  '4xl': '56px',
  '5xl': '64px',
  '6xl': '72px',
  '7xl': '88px',
  '8xl': '96px',
  '9xl': '120px',
  'breakpoint-m': '320px',
  'breakpoint-xs': '375px',
  'breakpoint-sm': '576px',
  'breakpoint-md': '768px',
  'breakpoint-lg': '1280px',
  'breakpoint-xl': '1440px',
  radiusSmall: '4px'
};