"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = BartBookingInformationCard;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _react = require("@chakra-ui/react");
var _api = require("@whitbread-eos/api");
var _atoms = require("@whitbread-eos/atoms");
var _molecules = require("@whitbread-eos/molecules");
var _utils = require("@whitbread-eos/utils");
var _react2 = require("react");
var _BartBookingInformationCard = _interopRequireDefault(require("./BartBookingInformationCard.component"));
var _jsxRuntime = require("react/jsx-runtime");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function BartBookingInformationCard(_ref) {
  var {
    bookingReference,
    bookerLastName,
    arrivalDate,
    sourcePms,
    bartId,
    t
  } = _ref;
  var {
    language,
    country
  } = (0, _utils.useCustomLocale)();
  var baseDataTestId = 'BartCard-Container';
  var [roomTypes, setRoomTypes] = (0, _react2.useState)([]);
  var [hotelData, setHotelData] = (0, _react2.useState)({
    hotelInformation: {
      brand: '',
      galleryImages: [{
        alt: '',
        thumbnailSrc: ''
      }],
      links: {
        detailsPage: ''
      },
      parkingDescription: '',
      address: ''
    }
  });
  var [bookingData, setBookingData] = (0, _react2.useState)({
    bookingType: '',
    rateName: '',
    rooms: [],
    rateMessage: '',
    totalCost: {
      amount: 0,
      currencyCode: ''
    },
    paymentOption: '',
    balanceOutstanding: {
      amount: 0,
      currencyCode: ''
    },
    donations: {
      amount: 0,
      currencyCode: ''
    },
    prepaidAmount: {
      amount: 0,
      currencyCode: ''
    },
    prepaid: false,
    rateDescription: '',
    hasCityTax: false
  });
  var getExtrasBart = (packages, id) => {
    var sorted = [];
    packages.forEach(pack => {
      if (sorted.some(val => {
        return val[id] == pack[id];
      })) {
        sorted.forEach(sp => {
          if (sp[id] === pack[id]) {
            sp['days']++;
          }
        });
      } else {
        var obj = {
          id: '',
          qty: 0,
          name: '',
          cost: {
            amount: 0,
            currencyCode: ''
          },
          days: 0
        };
        obj.id = pack[id];
        obj.qty = pack.quantity;
        obj.name = pack.name;
        obj.cost = pack.unitCost;
        obj.days = 1;
        sorted.push(obj);
      }
    });
    return sorted;
  };
  var {
    isLoading: roomInformationLoading,
    isError: roomInformationIsError,
    error: roomInformationError,
    data: roomInformationData
  } = (0, _utils.useQueryRequest)(['GetRoomTypeInformation', country, language], _api.GET_ROOM_TYPE_INFORMATION_QUERY, {
    brand: 'PI',
    country,
    language
  });
  var {
    isLoading: hotelIsLoading,
    isError: hotelIsError,
    error: hotelError,
    data: hotelDataRaw
  } = (0, _utils.useQueryRequest)(['GetHotelInformation', bartId, country, language], _api.GET_HOTEL_INFORMATION, {
    hotelId: bartId,
    country,
    language
  });
  var {
    isLoading: bookingDataIsLoading,
    isError: bookingDataIsError,
    error: bookingDataError,
    data: bookingDataRaw
  } = (0, _utils.useQueryRequest)(['GetBartBookingInformation', bookingReference, bookerLastName, arrivalDate, country, language], _api.BART_BOOKING_INFORMATION, {
    bookingReference: bookingReference,
    bookerLastName,
    arrivalDate,
    country,
    language
  }, {
    enabled: !!roomTypes
  });
  (0, _react2.useEffect)(() => {
    if (roomInformationData) {
      setRoomTypes(roomInformationData.roomTypeInformation.roomTypes);
    }
  }, [roomInformationData]);
  (0, _react2.useEffect)(() => {
    if (hotelDataRaw) {
      var _hotelInformationSele;
      var data = _objectSpread(_objectSpread({}, hotelDataRaw), {}, {
        hotelInformation: _objectSpread(_objectSpread({}, hotelDataRaw.hotelInformation), {}, {
          address: (_hotelInformationSele = (0, _utils.hotelInformationSelector)(hotelDataRaw === null || hotelDataRaw === void 0 ? void 0 : hotelDataRaw.hotelInformation)) === null || _hotelInformationSele === void 0 ? void 0 : _hotelInformationSele.hotelAddress.join(', ')
        })
      });
      setHotelData(data);
    }
  }, [hotelDataRaw]);
  (0, _react2.useEffect)(() => {
    if (bookingDataRaw) {
      var _data$prepaid, _data$rateDescription;
      var data = bookingDataRaw.bartBookingInformation;
      setBookingData({
        bookingType: data.bookingType,
        rateName: data.rateName,
        rooms: data.rooms,
        rateMessage: data.rateMessage,
        totalCost: data.totalCost,
        donations: data.donations,
        balanceOutstanding: data.balanceOutstanding,
        prepaidAmount: data.prepaidAmount,
        paymentOption: data.paymentOption,
        prepaid: (_data$prepaid = data === null || data === void 0 ? void 0 : data.prepaid) !== null && _data$prepaid !== void 0 ? _data$prepaid : false,
        rateDescription: (_data$rateDescription = data === null || data === void 0 ? void 0 : data.rateDescription) !== null && _data$rateDescription !== void 0 ? _data$rateDescription : '',
        hasCityTax: data.hasCityTax
      });
    }
  }, [bookingDataRaw]);
  return (0, _jsxRuntime.jsxs)(_atoms.Card, _objectSpread(_objectSpread({}, cardWrapperStyle), {}, {
    children: [(0, _jsxRuntime.jsxs)(_react.Flex, _objectSpread(_objectSpread({}, hotelDetailsWrapperStyle), {}, {
      children: [(0, _jsxRuntime.jsxs)(_react.Flex, _objectSpread(_objectSpread({}, bookingReferenceContainerStyle), {}, {
        children: [(0, _jsxRuntime.jsx)(_react.Text, _objectSpread(_objectSpread({}, bookingReferenceTitleStyle), {}, {
          children: t('booking.confirmation.bookingReference')
        })), (0, _jsxRuntime.jsx)(_react.Text, _objectSpread(_objectSpread({}, bookingReferenceStyle), {}, {
          children: bookingReference
        }))]
      })), (0, _jsxRuntime.jsx)(_molecules.BartHotelDetailsComponent, {
        data: hotelData,
        error: hotelError,
        isError: hotelIsError,
        isLoading: hotelIsLoading,
        t: t
      })]
    })), (0, _jsxRuntime.jsx)(_BartBookingInformationCard.default, {
      roomTypes: roomTypes,
      getExtrasBart: getExtrasBart,
      bookingData: bookingData,
      isLoading: roomInformationLoading || bookingDataIsLoading,
      isError: roomInformationIsError || bookingDataIsError,
      error: roomInformationError || bookingDataError,
      sourcePms: sourcePms,
      baseDataTestId: baseDataTestId,
      bartId: bartId
    })]
  }));
}
var hotelDetailsWrapperStyle = {
  flexDirection: 'row'
};
var cardWrapperStyle = {
  boxShadow: 'none',
  justifyContent: 'space-between',
  flexDirection: 'row',
  padding: 'lg'
};
var bookingReferenceStyle = {
  color: 'darkGrey2',
  fontSize: '3xl',
  fontWeight: 'normal',
  lineHeight: '1'
};
var bookingReferenceTitleStyle = {
  color: 'primary',
  lineHeight: '3',
  fontSize: 'md',
  fontWeight: 'normal',
  mt: 'sm'
};
var bookingReferenceContainerStyle = {
  rowGap: 'sm',
  flexDirection: 'column',
  mt: 'md'
};