"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var React = _interopRequireWildcard(require("react"));
var _jsxRuntime = require("react/jsx-runtime");
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && {}.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
var SvgPremierInnLogo = props => (0, _jsxRuntime.jsxs)("svg", _objectSpread(_objectSpread({
  width: 24,
  height: 24,
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg"
}, props), {}, {
  children: [(0, _jsxRuntime.jsx)("path", {
    d: "M12 24c6.63 0 12-5.376 12-12 0-6.63-5.376-12-12-12S0 5.37 0 12s5.37 12 12 12Z",
    fill: "#511E62"
  }), (0, _jsxRuntime.jsx)("path", {
    fillRule: "evenodd",
    clipRule: "evenodd",
    d: "M12.108 5.902s-.357.074-.946.096c.074.25.153.555.227.9 0 0-.345-.305-.674-.645-.35.459-.571.798-.571.798.04-.34.101-.634.17-.911a7.41 7.41 0 0 1-.245.032c-.167.02-.33.04-.503.087 0 0 .272-.255.668-.498 0 0-.198-.221-.362-.453 0 0 .243.034.64.119.13-.38.232-.6.232-.6.107.249.198.532.198.532.322-.187.747-.312.747-.312-.17.187-.3.351-.459.56.278.08.56.17.878.295ZM6.696 8.11c1.087-.006 1.75-.13 1.75-.13a20.468 20.468 0 0 0-1.608-.59c.305-.373.56-.678.872-1.007 0 0-.782.204-1.399.532 0 0-.153-.526-.34-.99 0 0-.203.402-.458 1.092-.719-.18-1.177-.254-1.177-.254.3.441.64.854.64.854-.748.425-1.257.878-1.257.878.313-.071.61-.1.91-.13.155-.015.311-.03.471-.051-.142.51-.266 1.053-.368 1.675 0 0 .425-.606 1.093-1.438.588.64 1.205 1.229 1.205 1.229a13.533 13.533 0 0 0-.334-1.67Zm.43 4.817c.799-.13 1.268-.294 1.268-.294-.452-.102-.86-.17-1.256-.221.175-.3.322-.538.51-.81 0 0-.55.233-.957.538 0 0-.182-.35-.38-.656 0 0-.096.305-.192.82-.55-.04-.9-.04-.9-.04.277.278.577.527.577.527-.492.385-.815.764-.815.764.226-.088.447-.145.67-.202.106-.028.213-.055.32-.086-.039.373-.056.77-.05 1.217 0 0 .232-.476.617-1.138.515.38 1.047.719 1.047.719a8.58 8.58 0 0 0-.459-1.138Z",
    fill: "#FDB913"
  }), (0, _jsxRuntime.jsx)("path", {
    fillRule: "evenodd",
    clipRule: "evenodd",
    d: "M14.916 10.708c.045-.147.09-.34.13-.572l-.005-.017c.045-.31.068-.628.068-.95 0-2.021-.872-3.855-2.293-5.186l-.011-.011a.563.563 0 0 1-.181-.419c0-.323.232-.64.69-.572h.017c4.127.793 7.24 4.393 7.24 8.718 0 4.902-4.002 8.876-8.944 8.876a8.947 8.947 0 0 1-7.002-3.351l-.017-.023a.57.57 0 0 1 .453-.917c.045 0 .085 0 .125.011a8.683 8.683 0 0 0 2.01.244c1.227 0 2.388-.26 3.424-.725l.022-.011.009-.004c.052-.024.33-.152.552-.002 0 0 .04.028.096.08.328.3 1.511 1.34 2.91.622 0 0 .226-.142-.023-.136h-.198a2.69 2.69 0 0 1-.912-.198 3.482 3.482 0 0 1-.97-.583c-.124-.1-.24-.197-.23-.374.017-.079.051-.136.17-.215.055-.038.125-.092.215-.163l.199-.154.05-.045c.12-.119.12-.244.068-.345-.058-.125-.202-.236-.312-.321a1.113 1.113 0 0 1-.118-.098c-.113-.108-.21-.255-.192-.42a.444.444 0 0 1 .249-.333c.076-.045.185-.097.314-.16a8.595 8.595 0 0 0 .506-.248c.25-.13.521-.294.793-.51a3.056 3.056 0 0 1 .159-.13c.186-.158.271-.248.39-.379.164-.175.317-.407.43-.64 0 0 .057-.118.12-.339Zm.351 1.75s.045.48.51.752c.458.272.9.074.9.074s.6-.272.13-.232c-.47.04-.827-.193-.827-.193h-.005s-.38-.192-.572-.628c-.193-.436-.136.226-.136.226Z",
    fill: "#fff"
  })]
}));
var _default = exports.default = SvgPremierInnLogo;