"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = BookingSummary;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _react = require("@chakra-ui/react");
var _utils = require("@whitbread-eos/utils");
var _BookingSummaryRoomDetails = _interopRequireDefault(require("./BookingSummaryRoomDetails"));
var _BookingSummaryStayDates = _interopRequireDefault(require("./BookingSummaryStayDates"));
var _jsxRuntime = require("react/jsx-runtime");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function BookingSummary(props) {
  var _bookingInformation$r, _bookingInformation$r2, _bookingInformation$r3, _bookingInformation$r4, _bookingInformation$r5;
  var {
    language,
    baseDataTestId,
    bookingInformation,
    roomsPackages,
    originalArrivalDate,
    originalDepartureDate,
    stayDatesLabels,
    bookingSummaryLabels,
    roomsAndGuestsLabels,
    extrasItemsPrices
  } = props;
  var noNights = (0, _utils.getNightsNumber)(bookingInformation === null || bookingInformation === void 0 || (_bookingInformation$r = bookingInformation.reservationByIdList[0]) === null || _bookingInformation$r === void 0 || (_bookingInformation$r = _bookingInformation$r.roomStay) === null || _bookingInformation$r === void 0 ? void 0 : _bookingInformation$r.arrivalDate, bookingInformation === null || bookingInformation === void 0 || (_bookingInformation$r2 = bookingInformation.reservationByIdList[0]) === null || _bookingInformation$r2 === void 0 || (_bookingInformation$r2 = _bookingInformation$r2.roomStay) === null || _bookingInformation$r2 === void 0 ? void 0 : _bookingInformation$r2.departureDate);
  return (0, _jsxRuntime.jsxs)(_react.Box, {
    children: [(0, _jsxRuntime.jsx)(_BookingSummaryStayDates.default, {
      language: language,
      baseDataTestId: baseDataTestId,
      labels: stayDatesLabels,
      arrivalDate: bookingInformation === null || bookingInformation === void 0 || (_bookingInformation$r3 = bookingInformation.reservationByIdList[0]) === null || _bookingInformation$r3 === void 0 || (_bookingInformation$r3 = _bookingInformation$r3.roomStay) === null || _bookingInformation$r3 === void 0 ? void 0 : _bookingInformation$r3.arrivalDate,
      departureDate: bookingInformation === null || bookingInformation === void 0 || (_bookingInformation$r4 = bookingInformation.reservationByIdList[0]) === null || _bookingInformation$r4 === void 0 || (_bookingInformation$r4 = _bookingInformation$r4.roomStay) === null || _bookingInformation$r4 === void 0 ? void 0 : _bookingInformation$r4.departureDate,
      originalArrivalDate: originalArrivalDate,
      originalDepartureDate: originalDepartureDate
    }), (0, _jsxRuntime.jsx)(_react.Divider, _objectSpread({}, dividerStyles)), bookingInformation === null || bookingInformation === void 0 || (_bookingInformation$r5 = bookingInformation.reservationByIdList) === null || _bookingInformation$r5 === void 0 ? void 0 : _bookingInformation$r5.map((reservation, index) => (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
      children: [(0, _jsxRuntime.jsx)(_BookingSummaryRoomDetails.default, {
        language: language,
        baseDataTestId: baseDataTestId,
        bookingSummaryLabels: bookingSummaryLabels,
        roomsAndGuestsLabels: roomsAndGuestsLabels,
        reservation: reservation,
        roomNumber: index,
        currency: bookingInformation.currencyCode,
        roomPackages: roomsPackages[index],
        noNights: noNights,
        extrasItemsPrices: extrasItemsPrices
      }, reservation.reservationId), (0, _jsxRuntime.jsx)(_react.Divider, _objectSpread({}, dividerStyles))]
    }))]
  });
}
var dividerStyles = {
  borderColor: 'lightGrey4',
  opacity: '1'
};