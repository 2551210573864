"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.AMEND_PAYMENT_OPTIONS = void 0;
var _taggedTemplateLiteral2 = _interopRequireDefault(require("@babel/runtime/helpers/taggedTemplateLiteral"));
var _graphqlRequest = require("graphql-request");
var _templateObject;
var AMEND_PAYMENT_OPTIONS = exports.AMEND_PAYMENT_OPTIONS = (0, _graphqlRequest.gql)(_templateObject || (_templateObject = (0, _taggedTemplateLiteral2.default)(["\n  query GetPaymentOptions(\n    $bookingChannel: BookingChannelCriteria!\n    $originalBookingRef: String!\n    $tempBookingRef: String!\n    $token: String!\n    $country: String!\n  ) {\n    paymentOptions(\n      paymentOptionsCriteria: {\n        bookingChannel: $bookingChannel\n        originalBookingRef: $originalBookingRef\n        tempBookingRef: $tempBookingRef\n        token: $token\n        country: $country\n      }\n    ) {\n      discount\n      paymentOption {\n        payNow\n        payOnArrival\n      }\n      paymentType\n      cardPresent {\n        display\n        value\n      }\n      eckoh {\n        display\n        enabled\n      }\n      cardHolderName {\n        display\n        name\n        surname\n      }\n      billingAddress {\n        display\n      }\n      emailPreference {\n        display\n        send\n        emailAddress\n      }\n      allowances {\n        display\n        values {\n          allowance\n          budget\n        }\n      }\n      purchaseOrder {\n        display\n        value\n      }\n      companyRef {\n        display\n        value\n      }\n      a2cDetails {\n        display\n        number\n        name\n        address\n        postcode\n      }\n      preAuthCharges {\n        display\n        charges\n      }\n      hotelName\n      hotelCode\n      brand\n      companyId\n    }\n  }\n"])));