"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getTableColumns = void 0;
var _utils = require("@whitbread-eos/utils");
var _tableFields = require("./tableFields.enum");
var getTableColumns = t => {
  return [{
    key: _tableFields.TABLE_FIELDS.DATE,
    title: t('ccui.changeLogModal.date'),
    isFilterable: false,
    render: row => {
      var rowValue = row[_tableFields.TABLE_FIELDS.DATE];
      return (0, _utils.formatDate)(rowValue, 'dd.MM.yy');
    }
  }, {
    key: _tableFields.TABLE_FIELDS.TIME,
    title: t('ccui.changeLogModal.time'),
    isFilterable: false
  }, {
    key: _tableFields.TABLE_FIELDS.ACTION_TYPE,
    title: t('ccui.changeLogModal.actionType'),
    isFilterable: true
  }, {
    key: _tableFields.TABLE_FIELDS.ACTION_DESCRIPTION,
    title: t('ccui.changeLogModal.actionDescription'),
    isFilterable: false,
    clampOverflowingText: true
  }, {
    key: _tableFields.TABLE_FIELDS.USER,
    title: t('ccui.changeLogModal.user'),
    isFilterable: true
  }];
};
exports.getTableColumns = getTableColumns;