"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.emptyCountryMapCoordinates = void 0;
var emptyCountryMapCoordinates = exports.emptyCountryMapCoordinates = {
  en: {
    lat: 55.378051,
    long: -3.435973
  },
  de: {
    lat: 51.165691,
    long: 10.451526
  }
};