"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _react = require("@chakra-ui/react");
var _utils = require("@whitbread-eos/utils");
var _components = require("./components");
var _jsxRuntime = require("react/jsx-runtime");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
var PersonalInformation = _ref => {
  var {
    personalInformation,
    t,
    inputValues,
    baseDataTestId
  } = _ref;
  return (0, _jsxRuntime.jsx)(_react.Box, _objectSpread(_objectSpread({}, cardStyle), {}, {
    children: (0, _jsxRuntime.jsxs)(_react.Flex, {
      flexDir: "column",
      children: [(0, _jsxRuntime.jsx)(_react.Heading, _objectSpread(_objectSpread({}, headingStyle), {}, {
        "data-testid": (0, _utils.formatDataTestId)(baseDataTestId, 'Heading'),
        children: t('ccui.idv.personalInformation.heading')
      })), (0, _jsxRuntime.jsx)(_react.Divider, {
        mt: "1rem",
        mb: "2rem"
      }), (0, _jsxRuntime.jsx)(_components.IdvCheckbox, {
        value: personalInformation.bookerName,
        partOfSearch: inputValues.bookerLastName ? inputValues.bookerLastName !== '' : false,
        text: t('ccui.idv.personalInformation.bookerName'),
        dataTestId: (0, _utils.formatDataTestId)(baseDataTestId, 'BookerName')
      }), (0, _jsxRuntime.jsx)(_react.Divider, {
        my: "1rem"
      }), (0, _jsxRuntime.jsx)(_components.IdvCheckbox, {
        value: personalInformation.guestName,
        partOfSearch: inputValues.guestLastName ? inputValues.guestLastName !== '' : false,
        text: t('ccui.idv.personalInformation.guestName'),
        dataTestId: (0, _utils.formatDataTestId)(baseDataTestId, 'GuestName')
      }), (0, _jsxRuntime.jsx)(_react.Divider, {
        my: "1rem"
      }), (0, _jsxRuntime.jsx)(_components.IdvCheckbox, {
        value: personalInformation.address,
        partOfSearch: inputValues.bookerAddress ? inputValues.bookerAddress !== '' : false,
        text: t('ccui.idv.personalInformation.address'),
        dataTestId: (0, _utils.formatDataTestId)(baseDataTestId, 'Address')
      }), (0, _jsxRuntime.jsx)(_react.Divider, {
        my: "1rem"
      }), (0, _jsxRuntime.jsx)(_components.IdvCheckbox, {
        value: personalInformation.postcode,
        partOfSearch: inputValues.bookerPostcode ? inputValues.bookerPostcode !== '' : false,
        text: t('ccui.idv.personalInformation.postcode'),
        dataTestId: (0, _utils.formatDataTestId)(baseDataTestId, 'Postcode')
      }), (0, _jsxRuntime.jsx)(_react.Divider, {
        my: "1rem"
      }), (0, _jsxRuntime.jsx)(_components.IdvCheckbox, {
        value: personalInformation.telephoneNumber,
        partOfSearch: inputValues.bookerPhone ? inputValues.bookerPhone !== '' : false,
        text: t('ccui.idv.personalInformation.telephoneNumber'),
        dataTestId: (0, _utils.formatDataTestId)(baseDataTestId, 'TelephoneNumber')
      }), (0, _jsxRuntime.jsx)(_react.Divider, {
        my: "1rem"
      }), (0, _jsxRuntime.jsx)(_components.IdvCheckbox, {
        value: personalInformation.cardUsedToMakeBooking,
        partOfSearch: inputValues.bookerCard ? inputValues.bookerLastName !== '' : false,
        text: t('ccui.idv.personalInformation.cardUsedToMakeBooking'),
        dataTestId: (0, _utils.formatDataTestId)(baseDataTestId, 'CardUsedToMakeBooking')
      })]
    })
  }));
};
var cardStyle = {
  w: {
    mobile: 'full',
    xs: 'full',
    sm: 'full',
    md: 'full',
    lg: '311px',
    xl: '333px'
  },
  border: '1px solid var(--chakra-colors-lightGrey4)',
  p: '1.5rem',
  mr: {
    md: '17px',
    lg: '19px'
  }
};
var headingStyle = {
  color: 'darkGrey2',
  fontSize: '1.25rem',
  lineHeight: '1.5rem',
  fontWeight: '700',
  fontFamily: 'Proxima Nova Sans, helvetica, arial, sans-serif'
};
var _default = exports.default = PersonalInformation;