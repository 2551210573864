"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.agentOverrideFormConfig = void 0;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _react = require("@chakra-ui/react");
var _atoms = require("@whitbread-eos/atoms");
var _molecules = require("@whitbread-eos/molecules");
var _utils = require("@whitbread-eos/utils");
var _validateAgentOverrideForm = _interopRequireDefault(require("./validateAgentOverrideForm"));
var _jsxRuntime = require("react/jsx-runtime");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
var agentOverrideFormConfig = _ref => {
  var {
    getFormState,
    defaultValues,
    onSubmit,
    onClose,
    baseDataTestId,
    resetForm,
    reasons,
    isManagerApprovalNeeded,
    setIsManagerApprovalNeeded,
    t
  } = _ref;
  var {
    formValidationSchema
  } = (0, _validateAgentOverrideForm.default)({
    t
  });
  var mappedReasons = reasons.filter(reason => reason.active).map(reason => {
    return {
      id: reason.code,
      label: reason.description
    };
  });
  var config = {
    id: 'agentOverrideForm',
    elements: {
      fields: [{
        type: _atoms.FORM_FIELD_TYPES.NON_FIELD_CONTENT,
        name: 'selectReasonTitle',
        content: (0, _jsxRuntime.jsx)(_react.Text, _objectSpread(_objectSpread({}, selectReasonStyle), {}, {
          children: t('ccui.manageBooking.options.agentOverrideModal.selectReason')
        }))
      }, {
        type: _atoms.FORM_FIELD_TYPES.DROPDOWN,
        name: 'selectReason',
        dropdownOptions: mappedReasons,
        testid: (0, _utils.formatDataTestId)(baseDataTestId, 'SelectReason'),
        styles: {
          mt: 'lg'
        },
        props: {
          showStatusIcon: false,
          placeholder: t('ccui.manageBooking.options.agentOverrideModal.selectReason'),
          dropdownStyles: {
            menuListStyles: {
              overflowY: 'auto',
              height: '12.5rem'
            }
          }
        },
        onChange: value => {
          var _selectedReason$manag;
          var selectedReason = reasons.find(reason => reason.code === value);
          setIsManagerApprovalNeeded((_selectedReason$manag = selectedReason === null || selectedReason === void 0 ? void 0 : selectedReason.managerApprovalNeeded) !== null && _selectedReason$manag !== void 0 ? _selectedReason$manag : false);
        }
      }, {
        type: _atoms.FORM_FIELD_TYPES.INPUT_TEXT,
        hidden: !isManagerApprovalNeeded,
        id: 'managerName',
        name: 'managerName',
        label: t('ccui.manageBooking.options.agentOverrideModal.managerName'),
        testid: (0, _utils.formatDataTestId)(baseDataTestId, 'ManagerName'),
        styles: _objectSpread({}, inputStyle)
      }, {
        type: _atoms.FORM_FIELD_TYPES.INPUT_TEXT,
        id: 'callerName',
        name: 'callerName',
        label: t('ccui.manageBooking.options.agentOverrideModal.callerName'),
        testid: (0, _utils.formatDataTestId)(baseDataTestId, 'CallerName'),
        styles: _objectSpread({}, inputStyle)
      }, {
        type: _atoms.FORM_FIELD_TYPES.DYNAMIC_FIELD,
        label: t('ccui.manageBooking.options.agentOverrideModal.saveButton'),
        name: 'agentOverrideSubmitButton',
        action: onSubmit,
        testid: (0, _utils.formatDataTestId)(baseDataTestId, 'Submit'),
        Component: _molecules.AgentOverrideButton,
        styles: {
          mb: '0'
        },
        props: {
          type: 'submit',
          isManagerApprovalNeeded: isManagerApprovalNeeded
        }
      }],
      buttons: [{
        type: 'button',
        label: t('ccui.manageBooking.options.agentOverrideModal.cancelButton'),
        action: onClose,
        props: {
          variant: 'tertiary',
          size: 'full'
        },
        styles: _objectSpread(_objectSpread({}, continueTextStyle), continueButtonSectionStyle),
        testid: (0, _utils.formatDataTestId)(baseDataTestId, 'Close')
      }]
    },
    defaultValues,
    validationSchema: formValidationSchema,
    getFormState,
    resetForm
  };
  return config;
};
exports.agentOverrideFormConfig = agentOverrideFormConfig;
var continueButtonSectionStyle = {
  background: 'primary'
};
var continueTextStyle = {
  fontWeight: 'semibold',
  fontSize: 'lg',
  lineHeight: '3',
  color: 'baseWhite'
};
var inputStyle = {
  w: {
    lg: '24.5rem',
    xl: '26.25rem'
  },
  p: 0
};
var selectReasonStyle = {
  fontSize: 'lg',
  fontWeight: 'semibold',
  lineHeight: '4',
  color: 'darkGrey1'
};