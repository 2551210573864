"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var React = _interopRequireWildcard(require("react"));
var _jsxRuntime = require("react/jsx-runtime");
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && {}.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
var SvgLogoPiIcon = props => (0, _jsxRuntime.jsxs)("svg", _objectSpread(_objectSpread({
  width: 48,
  height: 48,
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg"
}, props), {}, {
  children: [(0, _jsxRuntime.jsx)("path", {
    d: "M24 48c13.26 0 24-10.751 24-24C48 10.74 37.249 0 24 0S0 10.74 0 24s10.74 24 24 24Z",
    fill: "#511E62"
  }), (0, _jsxRuntime.jsx)("path", {
    d: "M14.423 25.96c1.615-.264 2.566-.596 2.566-.596a27.17 27.17 0 0 0-2.543-.447c.355-.607.653-1.088 1.031-1.638 0 0-1.111.47-1.936 1.088 0 0-.367-.71-.767-1.329 0 0-.195.619-.39 1.661-1.111-.08-1.822-.08-1.822-.08a19.564 19.564 0 0 0 1.17 1.066c-.998.779-1.65 1.546-1.65 1.546.675-.263 1.328-.39 2.004-.584a20.68 20.68 0 0 0-.103 2.463s.47-.962 1.249-2.303c1.042.768 2.12 1.455 2.12 1.455a17.366 17.366 0 0 0-.929-2.303ZM13.553 16.21c2.2-.011 3.54-.263 3.54-.263a41.406 41.406 0 0 0-3.254-1.191c.619-.757 1.134-1.375 1.764-2.04 0 0-1.58.413-2.83 1.077 0 0-.309-1.065-.687-2.005 0 0-.412.814-.928 2.211-1.455-.366-2.383-.515-2.383-.515.608.893 1.295 1.73 1.295 1.73a16.448 16.448 0 0 0-2.543 1.775c.962-.217 1.844-.24 2.795-.366a31.478 31.478 0 0 0-.745 3.39s.86-1.225 2.211-2.909a61.318 61.318 0 0 0 2.44 2.486 27.411 27.411 0 0 0-.675-3.38ZM22.591 11.937c1.192-.046 1.914-.194 1.914-.194a19.005 19.005 0 0 0-1.776-.596c.32-.424.584-.756.928-1.134 0 0-.86.252-1.512.63 0 0-.184-.573-.401-1.077 0 0-.206.447-.47 1.214a20.141 20.141 0 0 0-1.295-.24c.333.47.734.916.734.916-.802.493-1.352 1.008-1.352 1.008.515-.137.997-.16 1.512-.24a14.763 14.763 0 0 0-.344 1.844s.447-.687 1.157-1.615a27.715 27.715 0 0 0 1.364 1.306 24.622 24.622 0 0 0-.459-1.822Z",
    fill: "#FDB913"
  }), (0, _jsxRuntime.jsx)("path", {
    fillRule: "evenodd",
    clipRule: "evenodd",
    d: "M30.187 21.469c.091-.298.183-.688.263-1.157l-.011-.035c.091-.63.137-1.271.137-1.924 0-4.09-1.764-7.802-4.64-10.494l-.022-.023a1.14 1.14 0 0 1-.367-.848c0-.653.47-1.294 1.398-1.157h.034c8.352 1.604 14.652 8.89 14.652 17.642 0 9.922-8.099 17.964-18.1 17.964-5.74 0-10.86-2.647-14.171-6.782l-.035-.046a1.154 1.154 0 0 1 .916-1.856c.092 0 .173 0 .253.023 1.283.31 2.68.492 4.067.492 2.486 0 4.834-.526 6.93-1.466l.046-.023.018-.007c.104-.048.668-.307 1.117-.004 0 0 .08.057.194.16.665.607 3.06 2.715 5.889 1.26 0 0 .458-.286-.046-.275h-.401a5.445 5.445 0 0 1-1.844-.4 7.053 7.053 0 0 1-1.966-1.18c-.25-.205-.485-.398-.463-.757.034-.16.103-.275.344-.435.111-.077.252-.188.434-.33l.402-.312.103-.091c.24-.24.24-.493.137-.699-.118-.252-.41-.477-.632-.65a2.243 2.243 0 0 1-.238-.198c-.23-.218-.424-.515-.39-.848.035-.297.24-.538.504-.676.155-.09.375-.196.636-.322l.212-.101c.252-.115.527-.252.813-.401a10.119 10.119 0 0 0 1.604-1.031 6.19 6.19 0 0 1 .264-.218l.057-.046c.378-.32.55-.504.79-.767.333-.355.642-.825.871-1.295 0 0 .115-.24.24-.687Zm.71 3.54s.092.973 1.031 1.523c.928.55 1.822.15 1.822.15s1.214-.55.263-.47c-.95.08-1.672-.39-1.672-.39h-.012s-.768-.39-1.157-1.272c-.39-.882-.275.459-.275.459Z",
    fill: "#fff"
  })]
}));
var _default = exports.default = SvgLogoPiIcon;