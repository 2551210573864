"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = ReferenceDetails;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _react = require("@chakra-ui/react");
var _atoms = require("@whitbread-eos/atoms");
var _utils = require("@whitbread-eos/utils");
var _nextI18next = require("next-i18next");
var _react2 = require("react");
var _jsxRuntime = require("react/jsx-runtime");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function ReferenceDetails(_ref) {
  var {
    referenceDetails,
    updateReference,
    setReferenceDetailsErrors
  } = _ref;
  var baseDataTestId = 'ReferenceDetails';
  var {
    t
  } = (0, _nextI18next.useTranslation)();
  var [fieldsErrors, setFieldsErrors] = (0, _react2.useState)({
    reference: '',
    purchaseOrderNumber: ''
  });
  (0, _react2.useEffect)(() => {
    var newState = {};
    var refDetailsError = false;
    Object.keys(referenceDetails).forEach(key => {
      if (referenceDetails[key].length > 50) {
        if (fieldsErrors[key] === '') {
          newState[key] = key === 'reference' ? t('booking.bac.referenceDetails.maxLengthMessage') : t('booking.bac.purchaseOrder.maxLengthMessage');
          refDetailsError = true;
        }
      }
      newState[key] = '';
    });
    if (Object.keys(newState).length) {
      setFieldsErrors(data => _objectSpread(_objectSpread({}, data), newState));
      if (setReferenceDetailsErrors) {
        setReferenceDetailsErrors(refDetailsError);
      }
    }
  }, [referenceDetails]);
  return (0, _jsxRuntime.jsxs)(_react.Box, {
    "data-testid": (0, _utils.formatDataTestId)(baseDataTestId, 'Container'),
    mb: "2xl",
    children: [(0, _jsxRuntime.jsx)(_react.Text, _objectSpread(_objectSpread({
      "data-testid": (0, _utils.formatDataTestId)(baseDataTestId, 'Title')
    }, titleStyle), {}, {
      children: t('booking.bac.referenceDetails')
    })), (0, _jsxRuntime.jsx)(_react.Box, {
      maxW: "var(--chakra-space-breakpoint-m)",
      mb: "md",
      children: (0, _jsxRuntime.jsx)(_atoms.Input, {
        "data-testid": (0, _utils.formatDataTestId)(baseDataTestId, 'CustomerReference'),
        type: "text",
        placeholderText: "".concat(t('booking.bac.customerReference'), " ").concat(t('booking.login.labelOptional')),
        name: "reference",
        error: fieldsErrors.reference,
        value: referenceDetails.reference,
        onChange: val => updateReference(_objectSpread(_objectSpread({}, referenceDetails), {}, {
          reference: val
        }))
      })
    }), (0, _jsxRuntime.jsx)(_react.Box, {
      maxW: "var(--chakra-space-breakpoint-m)",
      children: (0, _jsxRuntime.jsx)(_atoms.Input, {
        "data-testid": (0, _utils.formatDataTestId)(baseDataTestId, 'PurchaseOrderNumber'),
        type: "text",
        placeholderText: "".concat(t('booking.bac.purchaseOrder'), " ").concat(t('booking.login.labelOptional')),
        name: "purchaseOrderNumber",
        error: fieldsErrors.purchaseOrderNumber,
        value: referenceDetails.purchaseOrderNumber,
        onChange: val => updateReference(_objectSpread(_objectSpread({}, referenceDetails), {}, {
          purchaseOrderNumber: val
        }))
      })
    })]
  });
}
var titleStyle = {
  fontSize: 'xl',
  fontWeight: 'semibold',
  mb: 'md',
  lineHeight: '3',
  color: 'darkGrey1'
};