"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = CountriesDropdown;
var _CountrySelector = _interopRequireDefault(require("./CountrySelector"));
var _jsxRuntime = require("react/jsx-runtime");
function CountriesDropdown(_ref) {
  var _formField$props, _formField$props2, _formField$props3, _errors$formField$nam, _formField$isDisabled;
  var {
    formField,
    field,
    errors
  } = _ref;
  var showIcon = (_formField$props = formField.props) === null || _formField$props === void 0 ? void 0 : _formField$props.showIcon;
  var setIsLocationRequired = (_formField$props2 = formField.props) === null || _formField$props2 === void 0 ? void 0 : _formField$props2.setIsLocationRequired;
  var styles = (_formField$props3 = formField.props) === null || _formField$props3 === void 0 ? void 0 : _formField$props3.styles;
  var onChange = countryId => {
    if (typeof setIsLocationRequired === 'function') {
      setIsLocationRequired(countryId === 'DE');
    }
    field.onChange(countryId);
  };
  return (0, _jsxRuntime.jsx)(_CountrySelector.default, {
    onChange: onChange,
    hasError: !!(errors !== null && errors !== void 0 && (_errors$formField$nam = errors[formField.name]) !== null && _errors$formField$nam !== void 0 && (_errors$formField$nam = _errors$formField$nam.message) !== null && _errors$formField$nam !== void 0 && _errors$formField$nam.length),
    selectedId: field.value,
    showIcon: showIcon,
    styles: styles,
    isDisabled: (_formField$isDisabled = formField.isDisabled) !== null && _formField$isDisabled !== void 0 ? _formField$isDisabled : false
  });
}