"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = CardPresentSection;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _react = require("@chakra-ui/react");
var _api = require("@whitbread-eos/api");
var _atoms = require("@whitbread-eos/atoms");
var _nextI18next = require("next-i18next");
var _react2 = _interopRequireWildcard(require("react"));
var _styles = require("../styles");
var _PasswordCheckOverlay = _interopRequireDefault(require("./component/PasswordCheckOverlay"));
var _jsxRuntime = require("react/jsx-runtime");
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && {}.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
var baseTestId = 'cardStatusSection';
function CardPresentSection(_ref) {
  var {
    value,
    setValue,
    disabledOption,
    cardType
  } = _ref;
  var {
    t
  } = (0, _nextI18next.useTranslation)(['common']);
  var [isOverlayOpen, setIsOverlayOpen] = (0, _react2.useState)(false);
  var isCNP = value === _api.CardStatus.CARD_NOT_PRESENT && cardType === _api.CcuiCardType.NEW_PIBA;
  (0, _react2.useEffect)(() => {
    if (isCNP) {
      setIsOverlayOpen(true);
    }
  }, [isCNP]);
  var cardStatus = [{
    name: t('ccui.cardPresentStatus.cardPresent'),
    dataTestID: 'Card present',
    type: _api.CardStatus.CARD_PRESENT
  }, {
    name: t('ccui.cardPresentStatus.CNP'),
    dataTestID: 'CNP (Card not present)',
    type: _api.CardStatus.CARD_NOT_PRESENT
  }];
  return (0, _jsxRuntime.jsxs)(_react.Flex, {
    flexDir: "column",
    "data-testid": baseTestId,
    children: [(0, _jsxRuntime.jsx)(_react.Box, {
      pb: 4,
      "data-testid": "".concat(baseTestId, "_title"),
      children: (0, _jsxRuntime.jsx)(_react.Heading, _objectSpread(_objectSpread({
        as: "h3"
      }, _styles.headerStyles), {}, {
        mb: "md",
        "data-testid": "".concat(baseTestId, "_title-heading"),
        children: t('ccui.cardPresentStatus.title')
      }))
    }), (0, _jsxRuntime.jsx)(_react.Box, {
      pb: 4,
      "data-testid": "".concat(baseTestId, "_info"),
      children: (0, _jsxRuntime.jsx)(_react.Heading, _objectSpread(_objectSpread({
        as: "h6"
      }, _styles.descriptionStyles), {}, {
        "data-testid": "".concat(baseTestId, "_info-heading"),
        children: t('ccui.cardPresentStatus.info')
      }))
    }), (0, _jsxRuntime.jsxs)(_react.Box, _objectSpread(_objectSpread({
      pb: 4
    }, radioWrapperStyle), {}, {
      "data-testid": "".concat(baseTestId, "_options"),
      children: [(0, _jsxRuntime.jsx)(_react.RadioGroup, {
        value: value,
        onChange: setValue,
        "data-testid": "".concat(baseTestId, "_radio-group"),
        children: cardStatus.map((option, index) => (0, _jsxRuntime.jsx)(_atoms.RadioButton, {
          value: option.type,
          listIndex: index === cardStatus.length - 1 ? 'last' : index,
          isDisabled: option.type === disabledOption,
          "data-testid": "".concat(baseTestId, "-").concat(option.dataTestID),
          type: option.dataTestID,
          children: (0, _jsxRuntime.jsx)(_react.Text, {
            "data-testid": "".concat(baseTestId, "_text-").concat(option.dataTestID),
            children: option.name
          })
        }, option.type))
      }), isCNP && (0, _jsxRuntime.jsx)(_PasswordCheckOverlay.default, {
        isOpenOverlay: isOverlayOpen,
        setIsOpenOverlay: setIsOverlayOpen
      })]
    }))]
  });
}
var radioWrapperStyle = {
  w: {
    mobile: 'full',
    sm: '25.063rem',
    md: '27.563rem',
    lg: '24.5rem',
    xl: '26.25rem'
  }
};