"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.BookingSummaryStayDates = void 0;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _react = require("@chakra-ui/react");
var _utils = require("@whitbread-eos/utils");
var _helpers = require("../../helpers");
var _jsxRuntime = require("react/jsx-runtime");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
var BookingSummaryStayDates = props => {
  var {
    language,
    baseDataTestId,
    labels,
    arrivalDate,
    departureDate,
    originalArrivalDate,
    originalDepartureDate
  } = props;
  var dateFormat = 'EEE dd MMM yyyy';
  var noNights = (0, _utils.getNightsNumber)(arrivalDate, departureDate);
  var originalNoNights = (0, _utils.getNightsNumber)(originalArrivalDate, originalDepartureDate);
  var stayDatesSectionHasUpdates = (0, _helpers.isStayDatesSectionUpdated)(originalArrivalDate, originalDepartureDate, arrivalDate, departureDate);
  return (0, _jsxRuntime.jsxs)(_react.Flex, _objectSpread(_objectSpread({
    "data-testid": (0, _utils.formatDataTestId)(baseDataTestId, 'stay-dates-wrapper')
  }, detailsWrapperStyle), {}, {
    children: [(0, _jsxRuntime.jsx)(_react.Text, _objectSpread(_objectSpread({
      "data-testid": (0, _utils.formatDataTestId)(baseDataTestId, 'stay-dates-title')
    }, subtitleTextStyle), {}, {
      children: labels.yourStayDatesTitle
    })), stayDatesSectionHasUpdates && displayDates(originalArrivalDate, originalDepartureDate, originalNoNights, true), displayDates(arrivalDate, departureDate, noNights)]
  }));
  function displayDates(arrival, departure, numberOfNights, strikeOut) {
    var textStyles = _objectSpread(_objectSpread({}, infoTextStyle), strikeOut && {
      textDecoration: 'line-through',
      color: 'lightGrey1'
    });
    return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
      children: [(0, _jsxRuntime.jsx)(_react.Text, _objectSpread(_objectSpread({
        "data-testid": (0, _utils.formatDataTestId)(baseDataTestId, 'stay-dates')
      }, textStyles), {}, {
        children: "".concat((0, _utils.formatDate)(arrival, dateFormat, language), " - ").concat((0, _utils.formatDate)(departure, dateFormat, language))
      })), (0, _jsxRuntime.jsxs)(_react.Text, _objectSpread(_objectSpread({
        "data-testid": (0, _utils.formatDataTestId)(baseDataTestId, 'number-of-nights')
      }, textStyles), {}, {
        children: [numberOfNights, " ", numberOfNights > 1 ? labels.nightsOption : labels.nightOption]
      }))]
    });
  }
};
exports.BookingSummaryStayDates = BookingSummaryStayDates;
var detailsWrapperStyle = {
  direction: 'column',
  pb: {
    mobile: 'md',
    lg: 'lg'
  }
};
var subtitleTextStyle = {
  pt: 'lg',
  pb: 'sm',
  fontSize: 'xl',
  lineHeight: '3',
  fontWeight: 'semibold'
};
var infoTextStyle = {
  lineHeight: '3',
  fontSize: 'md',
  color: 'darkGrey1',
  as: 'h6',
  fontWeight: 'normal'
};