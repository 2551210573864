"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.QuestionLocation = void 0;
var QuestionLocation = exports.QuestionLocation = function (QuestionLocation) {
  QuestionLocation["REGISTERING"] = "R";
  QuestionLocation["BOOKING"] = "B";
  return QuestionLocation;
}({});