"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = FailConfirmation;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _react = require("@chakra-ui/react");
var _utils = require("@whitbread-eos/utils");
var _react2 = _interopRequireDefault(require("react"));
var _Icon = _interopRequireDefault(require("../Icon"));
var _Notification = _interopRequireDefault(require("../Notification"));
var _jsxRuntime = require("react/jsx-runtime");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function FailConfirmation(_ref) {
  var {
    data,
    t
  } = _ref;
  var {
    language
  } = (0, _utils.useCustomLocale)();
  var baseDataTestId = 'FailConfirmation';
  if (!data) {
    return null;
  }
  var {
    emailAddress,
    bookingReference,
    notificationText,
    paymentOption,
    sendMail = true,
    isCcui = false,
    isGermanHotel = false
  } = data;
  var updateEmailMessage = (0, _utils.ternaryCondition)(isCcui, (0, _utils.ternaryCondition)(sendMail, t('booking.confirmation.updateEmailMessage').replace('[emailAddress]', emailAddress), (0, _utils.ternaryCondition)(language !== 'de', t('ccui.booking.confirmation.bookingErrorMessage.reservedWithCard.PI'), '')), t('booking.confirmation.updateEmailMessage').replace('[emailAddress]', emailAddress));
  var payNowUpdateEmailMsg = (0, _utils.ternaryCondition)(paymentOption === 'PAY_NOW', (0, _utils.ternaryCondition)(isCcui, t('ccui.booking.confirmation.bookingErrorMessage.payNow'), t('booking.confirmation.paymentMessage')), '');
  var bookingErrorMsgPOA = (0, _utils.ternaryCondition)(isGermanHotel, t('ccui.booking.confirmation.bookingErrorMessage.reservedWithCard.PID'), t('ccui.booking.confirmation.bookingErrorMessage.reservedWithCard.PI'));
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_react.Text, _objectSpread(_objectSpread({}, titleStyle), {}, {
      "data-testid": (0, _utils.formatDataTestId)(baseDataTestId, 'Title-Name'),
      children: t('booking.confirmation.sorry')
    })), sendMail ? (0, _jsxRuntime.jsx)(_react.Text, {
      "data-testid": (0, _utils.formatDataTestId)(baseDataTestId, 'Email'),
      fontSize: "md",
      children: (0, _utils.renderSanitizedHtml)(updateEmailMessage + ' ' + payNowUpdateEmailMsg)
    }) : (0, _jsxRuntime.jsx)(_react.Text, {
      "data-testid": (0, _utils.formatDataTestId)(baseDataTestId, 'BookingErrorMsgPOA'),
      fontSize: "md",
      children: bookingErrorMsgPOA
    }), (0, _jsxRuntime.jsx)(_react.Divider, {
      my: "2xl"
    }), (0, _jsxRuntime.jsx)(_react.Text, _objectSpread(_objectSpread({
      "data-testid": (0, _utils.formatDataTestId)(baseDataTestId, 'Label')
    }, bookingRefLabelStyle), {}, {
      children: t('ccui.booking.confirmation.reference')
    })), (0, _jsxRuntime.jsx)(_react.Text, _objectSpread(_objectSpread({
      "data-testid": (0, _utils.formatDataTestId)(baseDataTestId, 'Id')
    }, bookingRefStyle), {}, {
      children: bookingReference
    })), (0, _jsxRuntime.jsx)(_react.Box, {
      mb: "lg",
      "data-testid": (0, _utils.formatDataTestId)(baseDataTestId, 'Notification'),
      children: (0, _jsxRuntime.jsx)(_Notification.default, {
        maxWidth: "full",
        variant: "info",
        status: "info",
        description: notificationText,
        svg: (0, _jsxRuntime.jsx)(_Icon.default, {})
      })
    })]
  });
}
var titleStyle = {
  fontSize: '3xxl',
  fontWeight: 'semibold',
  mb: 'md'
};
var bookingRefLabelStyle = {
  mb: 'xs',
  fontSize: 'lg',
  fontWeight: 'bold'
};
var bookingRefStyle = {
  mb: 'lg',
  fontSize: '2xl'
};