"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ZIP = exports.PI = exports.LEISURE_BRANDS = exports.HUB = exports.BUSINESS_BOOKER = void 0;
var PI = exports.PI = 'pi';
var HUB = exports.HUB = 'hub';
var ZIP = exports.ZIP = 'zip';
var BUSINESS_BOOKER = exports.BUSINESS_BOOKER = 'business-booker';
var LEISURE_BRANDS = exports.LEISURE_BRANDS = ['pi', 'pid', 'hub', 'zip'];