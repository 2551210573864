"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = Menus;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _react = require("@chakra-ui/react");
var _atoms = require("@whitbread-eos/atoms");
var _utils = require("@whitbread-eos/utils");
var _nextI18next = require("next-i18next");
var _jsxRuntime = require("react/jsx-runtime");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function Menus(_ref) {
  var {
    availableMenus,
    prefixDataTestId
  } = _ref;
  var {
    t
  } = (0, _nextI18next.useTranslation)(['common']);
  var baseDataTestId = (0, _utils.formatDataTestId)(prefixDataTestId, 'Menus');
  return (0, _jsxRuntime.jsxs)(_react.Box, _objectSpread(_objectSpread({
    id: "menus"
  }, menusContainerStyle), {}, {
    "data-testid": (0, _utils.formatDataTestId)(baseDataTestId, 'Wrapper'),
    children: [(0, _jsxRuntime.jsx)(_react.Heading, _objectSpread(_objectSpread({
      as: "h5"
    }, headingStyle), {}, {
      "data-testid": (0, _utils.formatDataTestId)(baseDataTestId, 'Heading-Title'),
      children: t('upsell.meals.menu')
    })), (0, _jsxRuntime.jsxs)(_react.Flex, _objectSpread(_objectSpread({}, menusWrapperStyle), {}, {
      children: [(0, _jsxRuntime.jsx)(_atoms.Icon, _objectSpread(_objectSpread({}, iconStyle), {}, {
        svg: (0, _jsxRuntime.jsx)(_atoms.Restaurant24, {})
      })), availableMenus.length > 0 && availableMenus.map((menu, index) => (0, _jsxRuntime.jsx)(_react.Link, _objectSpread(_objectSpread({
        "data-testid": (0, _utils.formatDataTestId)(baseDataTestId, 'Item'),
        isExternal: true,
        href: menu.menuSrc
      }, linkStyles(index)), {}, {
        children: menu.name
      }), menu.name))]
    }))]
  }));
}
var menusContainerStyle = {
  border: '1px solid var(--chakra-colors-lightGrey3)',
  p: 'var(--chakra-space-md) var(--chakra-space-lg)',
  mt: 'xl'
};
var menusWrapperStyle = {
  pt: 'md',
  flexDirection: {
    mobile: 'column',
    sm: 'row'
  },
  alignItems: {
    mobile: 'flex-start',
    sm: 'center'
  },
  rowGap: 'sm'
};
var iconStyle = {
  display: {
    mobile: 'none',
    sm: 'block'
  }
};
var linkStyles = index => ({
  textDecoration: 'underline',
  color: 'btnSecondaryEnabled',
  ml: {
    mobile: 0,
    sm: index === 0 ? 'md' : 'lg'
  },
  _focus: {
    outline: 'none'
  },
  fontSize: 'md',
  fontWeight: 'normal'
});
var headingStyle = {
  fontSize: 'lg',
  lineHeight: '3',
  fontWeight: 'semibold',
  color: 'darkGrey1'
};