"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getListOfEmployees = getListOfEmployees;
var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));
var _config = _interopRequireDefault(require("next/config"));
var _useRequest = require("../hooks/use-request");
var _auth = require("./auth");
function getListOfEmployees(_x) {
  return _getListOfEmployees.apply(this, arguments);
}
function _getListOfEmployees() {
  _getListOfEmployees = (0, _asyncToGenerator2.default)(function* (_ref) {
    var _listEmployees$employ, _listEmployees;
    var {
      awaitingApproval,
      bookingChannel,
      page,
      searchCriteria,
      size,
      queryClient
    } = _ref;
    var {
      publicRuntimeConfig = {}
    } = (0, _config.default)() || {};
    var idTokenCookie = (0, _auth.getAuthCookie)();
    var listEmployees = [];
    if (idTokenCookie) {
      var {
        cdhEmployeeId,
        sessionId,
        cdhCompanyId
      } = (0, _auth.getLoggedInUserInfo)(idTokenCookie);
      if (cdhCompanyId && cdhEmployeeId && sessionId) {
        listEmployees = yield queryClient.fetchQuery({
          queryKey: ['SearchEmployees', cdhCompanyId, searchCriteria],
          queryFn: () => (0, _useRequest.axiosRequest)({
            method: 'GET',
            url: "".concat(publicRuntimeConfig.NEXT_PUBLIC_REST_API, "/companies/").concat(cdhCompanyId, "/employees?awaitingApproval=").concat(awaitingApproval, "&bookingChannel=").concat(bookingChannel, "&page=").concat(page, "&searchCriteria=").concat(searchCriteria, "&size=").concat(size),
            headers: {
              Authorization: "Bearer ".concat(idTokenCookie),
              'session-id': sessionId,
              'employee-id': cdhEmployeeId,
              'company-id': cdhCompanyId,
              bookingchannel: bookingChannel
            }
          })
        });
      }
    }
    return (_listEmployees$employ = (_listEmployees = listEmployees) === null || _listEmployees === void 0 ? void 0 : _listEmployees.employees) !== null && _listEmployees$employ !== void 0 ? _listEmployees$employ : listEmployees;
  });
  return _getListOfEmployees.apply(this, arguments);
}