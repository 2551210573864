"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = useBookingConfimationData;
var _api = require("@whitbread-eos/api");
var _getters = require("../getters");
var _useRequest = require("./use-request");
function useBookingConfimationData(area, basketReference, bookingReference, language, country) {
  var idTokenCookie = (0, _getters.getAuthCookie)();
  var {
    data: BCAuthData,
    error: BCAuthError,
    isError: BCAuthIsError,
    isLoading: BCAuthIsLoading,
    isSuccess: BCAuthIsSuccess,
    refetch: BCAuthRefetch
  } = (0, _useRequest.useQueryRequest)(['getBookingConfirmationAuthenticated', bookingReference, language, country, area === null || area === void 0 ? void 0 : area.toUpperCase()], _api.GET_DASHBOARD_BOOKING_CONFIRMATION_AUTHENTICATED, {
    language,
    country,
    bookingReference: bookingReference,
    bookingChannel: area === null || area === void 0 ? void 0 : area.toUpperCase()
  }, {
    enabled: !!idTokenCookie || area === _api.Area.CCUI
  }, idTokenCookie);
  var {
    data: BCData,
    error: BCError,
    isError: BCIsError,
    isLoading: BCIsLoading,
    isSuccess: BCIsSuccess,
    refetch: BCRefetch
  } = (0, _useRequest.useQueryRequest)(['getBookingConfirmation', basketReference, language, country, area === null || area === void 0 ? void 0 : area.toUpperCase()], _api.GET_DASHBOARD_BOOKING_CONFIRMATION, {
    language,
    country,
    basketReference: basketReference,
    bookingChannel: area === null || area === void 0 ? void 0 : area.toUpperCase()
  }, {
    enabled: !idTokenCookie && area === _api.Area.PI
  });
  var isTokenCookieOrCCUIChannelSet = idTokenCookie || area === _api.Area.CCUI;
  return {
    bookingData: isTokenCookieOrCCUIChannelSet ? BCAuthData === null || BCAuthData === void 0 ? void 0 : BCAuthData.bookingConfirmationAuthenticated : BCData === null || BCData === void 0 ? void 0 : BCData.bookingConfirmation,
    bookingError: isTokenCookieOrCCUIChannelSet ? BCAuthError : BCError,
    bookingIsError: isTokenCookieOrCCUIChannelSet ? BCAuthIsError : BCIsError,
    bookingIsLoading: isTokenCookieOrCCUIChannelSet ? BCAuthIsLoading : BCIsLoading,
    bookingIsSuccess: isTokenCookieOrCCUIChannelSet ? BCAuthIsSuccess : BCIsSuccess,
    bookingRefetch: isTokenCookieOrCCUIChannelSet ? BCAuthRefetch : BCRefetch
  };
}