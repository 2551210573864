"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _react = require("@chakra-ui/react");
var _dateFns = require("date-fns");
var _nextI18next = require("next-i18next");
var _common = require("./common");
var _jsxRuntime = require("react/jsx-runtime");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function CustomHeader(_ref) {
  var {
    date,
    changeYear,
    changeMonth,
    decreaseMonth,
    increaseMonth,
    prevMonthButtonDisabled,
    nextMonthButtonDisabled
  } = _ref;
  var {
    t
  } = (0, _nextI18next.useTranslation)();
  var months = t('precheckin.calendar.months').split('|');
  return (0, _jsxRuntime.jsxs)(_react.Flex, {
    alignItems: "center",
    justifyContent: "center",
    gap: "sm",
    children: [(0, _jsxRuntime.jsx)(_react.Button, _objectSpread(_objectSpread({}, navBtn), {}, {
      onClick: decreaseMonth,
      disabled: prevMonthButtonDisabled,
      className: "navigation-btn-left",
      children: (0, _jsxRuntime.jsx)("span", {
        className: "react-datepicker__navigation-icon--previous react-datepicker__navigation-icon"
      })
    })), (0, _jsxRuntime.jsx)(HeaderSelect, {
      value: months[(0, _dateFns.getMonth)(date)],
      onChange: value => changeMonth(months.indexOf(value)),
      options: months
    }), (0, _jsxRuntime.jsx)(HeaderSelect, {
      value: (0, _dateFns.getYear)(date),
      onChange: value => changeYear(+value),
      options: _common.years
    }), (0, _jsxRuntime.jsx)(_react.Button, _objectSpread(_objectSpread({}, navBtn), {}, {
      onClick: increaseMonth,
      disabled: nextMonthButtonDisabled,
      className: "navigation-btn-right",
      children: (0, _jsxRuntime.jsx)("span", {
        className: "react-datepicker__navigation-icon--next react-datepicker__navigation-icon"
      })
    }))]
  });
}
var _default = exports.default = CustomHeader;
var HeaderSelect = _ref2 => {
  var {
    value,
    onChange: _onChange,
    options
  } = _ref2;
  return (0, _jsxRuntime.jsx)(_react.Select, {
    value: value,
    onChange: _ref3 => {
      var {
        target: {
          value
        }
      } = _ref3;
      return _onChange(value);
    },
    size: "sm",
    width: "120px",
    children: options.map(option => (0, _jsxRuntime.jsx)("option", {
      value: option,
      children: option
    }, option))
  });
};
var navBtn = {
  type: 'button',
  variant: 'unstyled',
  size: 'xss',
  style: {
    outline: 'none',
    boxShadow: 'none'
  }
};