"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = LanguageSelector;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _react = require("@chakra-ui/react");
var _api = require("@whitbread-eos/api");
var _utils = require("@whitbread-eos/utils");
var _nextI18next = require("next-i18next");
var _router = require("next/router");
var _formatters = require("../../utils/formatters");
var _Popover = _interopRequireDefault(require("../Popover"));
var _jsxRuntime = require("react/jsx-runtime");
var _react2 = require("react");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function LanguageSelector(_ref) {
  var {
    currentLanguage = '',
    prefixDataTestId,
    tickIcon,
    languagesList,
    area
  } = _ref;
  var baseTestId = (0, _formatters.formatDataTestId)(prefixDataTestId, 'languageSelectorContainer');
  var router = (0, _router.useRouter)();
  var path = router === null || router === void 0 ? void 0 : router.asPath;
  var {
    t
  } = (0, _nextI18next.useTranslation)(['common']);
  return (0, _jsxRuntime.jsx)(_react.Flex, {
    justifyContent: "flex-end",
    mr: {
      lg: '2.125rem ',
      xl: '1.875rem'
    },
    "data-testid": baseTestId,
    children: (0, _jsxRuntime.jsx)(_Popover.default, {
      triggerItem: renderFlag(currentLanguage),
      styles: {
        arrowStyles: popoverArrowStyles
      },
      children: (0, _jsxRuntime.jsx)(_react.Flex, _objectSpread(_objectSpread({}, childrenWrapperStyle), {}, {
        cursor: "pointer",
        children: languagesList.map((language, index) => {
          var link = (0, _utils.formatNextLocaleLink)(path, language.locale, currentLanguage, area);
          return (0, _react2.createElement)(_react.Flex, _objectSpread(_objectSpread({}, childrenContainerStyle(index, languagesList.length - 1)), {}, {
            key: language.locale,
            onClick: () => {
              document.cookie = "".concat(_api.CCUI_LOCALE_COOKIE, "=").concat(language.locale === 'de' ? 'de' : 'gb', "; max-age=31536000; path=/");
              window.location = link;
            }
          }), (0, _jsxRuntime.jsxs)(_react.Flex, {
            children: [renderFlag(language.locale), (0, _jsxRuntime.jsx)(_react.Text, {
              color: "darkGrey1",
              ml: "sm",
              fontWeight: currentLanguage === language.locale ? 'medium' : '',
              children: t(language.languageName)
            })]
          }), currentLanguage === language.locale && tickIcon);
        })
      }))
    })
  });
  function renderFlag(language) {
    var country = languagesList.find(languageObject => languageObject.locale == language);
    if (country) {
      return country.icon;
    }
  }
}
var popoverArrowStyles = {
  boxShadow: '-2px 2px 1px 0 var(--chakra-colors-lightGrey4) !important',
  width: '140% !important;',
  height: '143% !important;',
  top: '-2.5px !important;',
  left: '-1px !important;'
};
var childrenWrapperStyle = {
  flexDir: 'column',
  justifyContent: 'center',
  w: '11.375rem'
};
var childrenContainerStyle = (index, listLength) => {
  return {
    h: 'var(--chakra-space-lg)',
    mb: index != listLength && 'var(--chakra-space-md)',
    justifyContent: 'space-between',
    _hover: {
      p: {
        textDecoration: 'underline'
      }
    }
  };
};