"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = List;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _react = require("@chakra-ui/react");
var _react2 = require("react");
var _jsxRuntime = require("react/jsx-runtime");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function List(props) {
  var listItems = _react2.Children.toArray(props.children);
  var map = (listItem, idx) => {
    return (0, _jsxRuntime.jsx)(_react.GridItem, {
      children: (0, _jsxRuntime.jsxs)(_react.HStack, {
        spacing: "1rem",
        children: [props.icon, (0, _jsxRuntime.jsx)(_react.Box, {
          children: listItem
        })]
      })
    }, idx);
  };
  return (0, _jsxRuntime.jsx)(_react.Grid, _objectSpread(_objectSpread({
    templateColumns: "repeat(".concat(props.cols, ", 1fr)"),
    gap: 4
  }, props), {}, {
    children: listItems.map(map)
  }));
}
List.defaultProps = {
  cols: 1
};