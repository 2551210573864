"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "getGuestsPlaceholderString", {
  enumerable: true,
  get: function get() {
    return _helpers.getGuestsPlaceholderString;
  }
});
var _helpers = require("./helpers");