"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = ManageBookingModal;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _react = require("@chakra-ui/react");
var _reactQuery = require("@tanstack/react-query");
var _api = require("@whitbread-eos/api");
var _atoms = require("@whitbread-eos/atoms");
var _utils = require("@whitbread-eos/utils");
var _format = _interopRequireDefault(require("date-fns/format"));
var _react2 = require("react");
var _manageBookingFormConfig = require("./manageBookingFormConfig");
var _jsxRuntime = require("react/jsx-runtime");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function ManageBookingModal(_ref) {
  var _labels$headerInforma5, _labels$headerInforma6;
  var {
    labels,
    onClose,
    isOpen,
    baseTestId
  } = _ref;
  var {
    language,
    country
  } = (0, _utils.useCustomLocale)();
  var [isSubmitDisabled, setIsSubmitDisabled] = (0, _react2.useState)(false);
  var [resetForm, setResetForm] = (0, _react2.useState)(0);
  var [findBookingError, setFindBookingError] = (0, _react2.useState)('');
  var [defaultValues, setDefaultValues] = (0, _react2.useState)({
    bookingReference: '',
    bookingSurname: '',
    arrivalDate: new Date().toString()
  });
  var onFindBookingSuccess = (response, findBookingCriteria) => {
    if (!(response !== null && response !== void 0 && response.findBooking)) {
      return;
    }
    setIsSubmitDisabled(false);
    var {
      ref,
      basketReference,
      cookieName,
      token,
      sourcePms,
      redirectBase,
      minutesTillExpiry,
      operaConfNumber
    } = response.findBooking;
    if (sourcePms === _api.FIND_BOOKING_SOURCE_PMS.OPERA && ref && basketReference || sourcePms === _api.FIND_BOOKING_SOURCE_PMS.BART && ref) {
      var cookieValue = sourcePms === _api.FIND_BOOKING_SOURCE_PMS.OPERA ? {
        token,
        basketReference,
        bookingReference: ref,
        operaConfNumber: operaConfNumber !== null && operaConfNumber !== void 0 ? operaConfNumber : ''
      } : {
        arrivalDate: findBookingCriteria.arrivalDate,
        surname: findBookingCriteria.lastName
      };
      if (typeof window !== 'undefined') {
        window.localStorage.setItem(_api.FIND_BOOKING_COOKIE_NAME_KEY, cookieName !== null && cookieName !== void 0 ? cookieName : '');
      }
      (0, _utils.setCookie)(cookieName !== null && cookieName !== void 0 ? cookieName : '', window.btoa(JSON.stringify(cookieValue)), Number(minutesTillExpiry));
      if ((0, _utils.isStringValid)(redirectBase)) {
        window.location.href = "".concat(redirectBase, "?bookingReference=").concat(ref);
      }
      onCloseModal();
    } else {
      var _labels$headerInforma, _labels$headerInforma2;
      setFindBookingError((_labels$headerInforma = labels === null || labels === void 0 || (_labels$headerInforma2 = labels.headerInformation) === null || _labels$headerInforma2 === void 0 || (_labels$headerInforma2 = _labels$headerInforma2.form) === null || _labels$headerInforma2 === void 0 ? void 0 : _labels$headerInforma2.bookingInvalid) !== null && _labels$headerInforma !== void 0 ? _labels$headerInforma : '');
    }
  };
  var onFindBookingError = () => {
    var _labels$headerInforma3, _labels$headerInforma4;
    setIsSubmitDisabled(false);
    setFindBookingError((_labels$headerInforma3 = labels === null || labels === void 0 || (_labels$headerInforma4 = labels.headerInformation) === null || _labels$headerInforma4 === void 0 || (_labels$headerInforma4 = _labels$headerInforma4.form) === null || _labels$headerInforma4 === void 0 ? void 0 : _labels$headerInforma4.searchBookingError) !== null && _labels$headerInforma3 !== void 0 ? _labels$headerInforma3 : '');
  };
  var onSubmit = data => {
    (0, _utils.cleanupFindBookingToken)();
    var queryClient = new _reactQuery.QueryClient();
    setIsSubmitDisabled(true);
    var findBookingCriteria = {
      country,
      language,
      arrivalDate: (0, _format.default)(new Date(data.arrivalDate), 'yyyy-MM-dd'),
      lastName: data.bookingSurname,
      resNo: String(data.bookingReference).toUpperCase()
    };
    queryClient.fetchQuery({
      queryKey: ['FindBooking', findBookingCriteria.country, findBookingCriteria.language, findBookingCriteria.arrivalDate, findBookingCriteria.lastName, findBookingCriteria.resNo],
      queryFn: () => (0, _utils.graphQLRequest)(_api.FIND_BOOKING, {
        findBookingCriteria
      })
    }).then(response => onFindBookingSuccess(response, findBookingCriteria)).catch(onFindBookingError);
  };
  var getFormState = (0, _react2.useCallback)(state => {
    setDefaultValues(state);
  }, [setDefaultValues]);
  var onCloseModal = () => {
    setResetForm(prev => prev + 1);
    setFindBookingError('');
    onClose();
  };
  return (0, _jsxRuntime.jsx)(_atoms.ModalVariants, {
    isOpen: isOpen,
    onClose: onCloseModal,
    dataTestId: baseTestId,
    variant: "default",
    variantProps: {
      title: '',
      delimiter: true,
      overflowVisible: true,
      isCentered: false
    },
    children: (0, _jsxRuntime.jsxs)(_react.Flex, _objectSpread(_objectSpread({}, wrapperStyles), {}, {
      children: [(0, _jsxRuntime.jsx)(_react.Text, _objectSpread(_objectSpread({
        as: "h4"
      }, textStyles.title), {}, {
        "data-testid": "".concat(baseTestId, "_Title"),
        children: labels === null || labels === void 0 || (_labels$headerInforma5 = labels.headerInformation) === null || _labels$headerInforma5 === void 0 || (_labels$headerInforma5 = _labels$headerInforma5.form) === null || _labels$headerInforma5 === void 0 ? void 0 : _labels$headerInforma5.findBookingTitle
      })), (0, _jsxRuntime.jsx)(_react.Text, _objectSpread(_objectSpread({
        as: "h6"
      }, textStyles.description), {}, {
        "data-testid": "".concat(baseTestId, "_Description"),
        mb: "xl",
        children: labels === null || labels === void 0 || (_labels$headerInforma6 = labels.headerInformation) === null || _labels$headerInforma6 === void 0 || (_labels$headerInforma6 = _labels$headerInforma6.form) === null || _labels$headerInforma6 === void 0 ? void 0 : _labels$headerInforma6.findBookingDescription
      })), (0, _utils.isStringValid)(findBookingError) && (0, _jsxRuntime.jsx)(_react.Box, {
        mb: "xl",
        children: (0, _jsxRuntime.jsx)(_atoms.Notification, {
          svg: (0, _jsxRuntime.jsx)(_atoms.Error, {}),
          status: "error",
          description: findBookingError,
          variant: "error",
          "data-testid": "".concat(baseTestId, "-Error-Message"),
          maxW: "full"
        })
      }), (0, _jsxRuntime.jsx)(_atoms.Form, _objectSpread({}, (0, _manageBookingFormConfig.manageBookingFormConfig)({
        getFormState,
        defaultValues,
        onSubmit,
        baseTestId,
        resetForm,
        labels,
        isSubmitDisabled
      })))]
    }))
  });
}
var textStyles = {
  title: {
    textAlign: 'center',
    fontWeight: 'semibold',
    fontSize: 'xl',
    lineHeight: '3'
  },
  description: {
    textAlign: 'center',
    pt: 'md',
    fontWeight: 'normal',
    fontSize: 'md',
    lineHeight: '3'
  }
};
var wrapperStyles = {
  alignItems: 'center',
  justifyContent: 'center',
  direction: 'column',
  width: {
    sm: '22.063rem',
    md: '21.75rem',
    lg: '24.5rem',
    xl: '26.25rem'
  },
  mt: 'lg',
  mb: 'md',
  mx: {
    mobile: 'md',
    sm: 'lg',
    md: '8xl'
  }
};