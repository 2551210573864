"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.GET_SUMMARY_OF_PAYMENTS = void 0;
var _taggedTemplateLiteral2 = _interopRequireDefault(require("@babel/runtime/helpers/taggedTemplateLiteral"));
var _graphqlRequest = require("graphql-request");
var _templateObject;
var GET_SUMMARY_OF_PAYMENTS = exports.GET_SUMMARY_OF_PAYMENTS = (0, _graphqlRequest.gql)(_templateObject || (_templateObject = (0, _taggedTemplateLiteral2.default)(["\n  query AmendSummary(\n    $originalBasketRef: String!\n    $copyBasketRef: String!\n    $token: String!\n    $bookingChannel: BookingChannelCriteria!\n    $country: String!\n  ) {\n    amendSummary(\n      originalBasketRef: $originalBasketRef\n      copyBasketRef: $copyBasketRef\n      token: $token\n      bookingChannel: $bookingChannel\n      country: $country\n    ) {\n      charitable\n      previousTotal\n      balancePaid\n      payOnArrival\n      refund\n      nonRefundable\n      totalCost\n      balanceAuthorised\n      navigationOptions {\n        amendPaymentPage\n      }\n      paymentOptions {\n        payNow\n        payOnArrival\n      }\n      paymentCardDetails {\n        cardNumberMasked\n        token\n        expirationDate\n        cardType\n        cardHolderName\n        cardNumberLast4Digits\n        cardLogoSrc\n        cardName\n      }\n    }\n  }\n"])));