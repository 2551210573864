"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = NavigationMenu;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _react = require("@chakra-ui/react");
var _atoms = require("@whitbread-eos/atoms");
var _molecules = require("@whitbread-eos/molecules");
var _utils = require("@whitbread-eos/utils");
var _config = _interopRequireDefault(require("next/config"));
var _link = _interopRequireDefault(require("next/link"));
var _react2 = require("react");
var _index = require("../../../../index");
var _NavItem = _interopRequireDefault(require("../NavItem"));
var _AuthSubNav = _interopRequireDefault(require("./AuthSubNav"));
var _NavigationMenu = require("./NavigationMenu.style");
var _jsxRuntime = require("react/jsx-runtime");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function NavigationMenu(_ref) {
  var _data$contactDetail, _data$contactDetail2;
  var {
    labels
  } = _ref;
  var {
    publicRuntimeConfig = {}
  } = (0, _config.default)() || {};
  var [isManageBookingModalOpen, setIsManageBookingModalOpen] = (0, _react2.useState)(false);
  var [isLoginModalOpen, setIsLoginModalOpen] = (0, _react2.useState)(false);
  var {
    isLoggedIn
  } = (0, _utils.useUserData)();
  var idTokenCookie = (0, _utils.getAuthCookie)();
  var {
    email
  } = (0, _utils.decodeIdToken)(idTokenCookie);
  var {
    data
  } = (0, _utils.useRestQueryRequest)(['userDetails', idTokenCookie], 'GET', "".concat(publicRuntimeConfig.NEXT_PUBLIC_REST_API, "/customers/hotels/").concat(email, "?business=false"), {
    Authorization: "Bearer ".concat(idTokenCookie)
  }, {
    enabled: isLoggedIn
  });
  var logout = () => {
    var authIframe = document.getElementById('authIframe');
    if (authIframe !== null && authIframe !== void 0 && authIframe.contentWindow) {
      var message = JSON.stringify({
        action: 'logout'
      });
      authIframe.contentWindow.postMessage(message, (0, _utils.getSecureTwoURL)());
    }
  };
  var toggleManageModal = () => {
    setIsManageBookingModalOpen(!isManageBookingModalOpen);
  };
  var toggleLoginModal = () => {
    setIsLoginModalOpen(!isLoginModalOpen);
  };
  return (0, _jsxRuntime.jsxs)(_react.Flex, {
    justifyContent: "flex-end",
    "data-testid": "menuWrapperId",
    children: [(0, _jsxRuntime.jsx)(_react.Box, _objectSpread(_objectSpread({}, _NavigationMenu.boxStyles), {}, {
      "data-testid": "listId",
      children: (0, _jsxRuntime.jsx)(_atoms.Popover, {
        triggerItem: (0, _jsxRuntime.jsx)(_NavItem.default, {
          title: labels.discoverPI
        }),
        children: labels.subNav && (0, _jsxRuntime.jsx)(_react.Flex, {
          children: renderSubNavItems(labels.subNav, true)
        })
      })
    })), (0, _jsxRuntime.jsx)(_react.Box, _objectSpread(_objectSpread({}, _NavigationMenu.boxStyles), {}, {
      "data-testid": "listId",
      children: (0, _jsxRuntime.jsx)(_atoms.Popover, {
        triggerItem: (0, _jsxRuntime.jsx)(_NavItem.default, {
          title: labels.business
        }),
        children: (0, _jsxRuntime.jsx)(_react.Flex, _objectSpread(_objectSpread({}, _NavigationMenu.businessListStyles), {}, {
          children: (labels === null || labels === void 0 ? void 0 : labels.businessSubNav) && renderSubNavItems([labels.businessSubNav], false)
        }))
      })
    })), (0, _jsxRuntime.jsxs)(_react.Box, _objectSpread(_objectSpread({}, _NavigationMenu.boxStyles), {}, {
      "data-testid": "listId",
      children: [(0, _jsxRuntime.jsx)(_NavItem.default, {
        onClick: toggleManageModal,
        title: labels.findBooking,
        "data-testid": "ManageBookingButton"
      }), (0, _jsxRuntime.jsx)(_molecules.ManageBookingContainer, {
        onClose: toggleManageModal,
        isOpen: isManageBookingModalOpen
      })]
    })), (0, _jsxRuntime.jsxs)(_react.Box, _objectSpread(_objectSpread({}, _NavigationMenu.boxStyles), {}, {
      mr: "0",
      "data-testid": "listId",
      children: [!isLoggedIn ? (0, _jsxRuntime.jsx)(_NavItem.default, {
        "data-testid": "Global-Login-Desktop",
        onClick: toggleLoginModal,
        title: labels.logIn
      }) : !!(data !== null && data !== void 0 && data.contactDetail) && (0, _jsxRuntime.jsx)(_atoms.Popover, {
        triggerItem: (0, _jsxRuntime.jsx)(_NavItem.default, {
          title: "".concat(data === null || data === void 0 || (_data$contactDetail = data.contactDetail) === null || _data$contactDetail === void 0 ? void 0 : _data$contactDetail.firstName, " ").concat(data === null || data === void 0 || (_data$contactDetail2 = data.contactDetail) === null || _data$contactDetail2 === void 0 ? void 0 : _data$contactDetail2.lastName),
          "date-testid": "Global-UserName-Desktop"
        }),
        children: (0, _jsxRuntime.jsx)(_AuthSubNav.default, {
          labels: labels,
          onLogout: logout
        })
      }), (0, _jsxRuntime.jsx)(_index.AuthContentManagerPIVariant, {
        isLoginModalOpen: isLoginModalOpen,
        toggleLoginModal: toggleLoginModal
      })]
    }))]
  });
  function renderSubNavItems(list, showHeading) {
    return list.map(item => {
      return (0, _jsxRuntime.jsxs)(_react.List, _objectSpread(_objectSpread({}, _NavigationMenu.listStyles), {}, {
        children: [showHeading && item.title && (0, _jsxRuntime.jsx)(_react.Heading, _objectSpread(_objectSpread({}, _NavigationMenu.headingStyles), {}, {
          children: item.title
        })), item.navOptions.map(option => {
          var _option$url;
          return (0, _react2.createElement)(_react.ListItem, _objectSpread(_objectSpread({}, _NavigationMenu.listItemStyles), {}, {
            key: option.title,
            "data-testid": "listItem"
          }), (0, _jsxRuntime.jsx)(_link.default, {
            href: (_option$url = option.url) !== null && _option$url !== void 0 ? _option$url : '/',
            passHref: true,
            legacyBehavior: true,
            children: (0, _jsxRuntime.jsx)(_react.Text, _objectSpread(_objectSpread({}, _NavigationMenu.linkStyles), {}, {
              children: option.title
            }))
          }));
        })]
      }), item.title);
    });
  }
}