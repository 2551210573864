"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useSessionStorage = useSessionStorage;
var _react = require("react");
function useSessionStorage(key, initialValue) {
  var [storedValue, setStoredValue] = (0, _react.useState)(() => {
    if (typeof window === 'undefined') {
      return initialValue;
    }
    try {
      var item = window.sessionStorage.getItem(key);
      return item ? JSON.parse(item) : initialValue;
    } catch (error) {
      console.log(error);
      return initialValue;
    }
  });
  var setValue = value => {
    try {
      var valueToStore = value instanceof Function ? value(storedValue) : value;
      setStoredValue(valueToStore);
      if (typeof window !== 'undefined') {
        window.sessionStorage.setItem(key, JSON.stringify(valueToStore));
      }
    } catch (error) {
      console.log(error);
    }
  };
  return [storedValue, setValue];
}