"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.PopoverTrigger = void 0;
exports.default = PopoverComponent;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _react = require("@chakra-ui/react");
var _jsxRuntime = require("react/jsx-runtime");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
var PopoverTrigger = exports.PopoverTrigger = _react.PopoverTrigger;
var popoverStyleProps = {
  _focus: {
    boxShadow: '0 2px 8px 0px var(--chakra-colors-lightGrey1)'
  },
  _focusVisible: {
    outline: 'none'
  },
  inset: '10px auto auto 0px',
  boxShadow: '0 2px 8px 0px var(--chakra-colors-lightGrey1)',
  border: '1px solid var(--chakra-colors-lightGrey4)',
  padding: '24px',
  borderRadius: '3px',
  width: 'fit-content'
};
var popoverArrowStyles = {
  boxShadow: '-2px 2px 1px 0 var(--chakra-colors-lightGrey4)!important',
  width: '200% !important;',
  height: '200% !important;',
  top: '-4px !important;',
  left: '-4px !important;',
  transform: 'rotate(135deg) !important'
};
function PopoverComponent(_ref) {
  var {
    triggerItem,
    children,
    styles
  } = _ref;
  var hasArrowStyles = styles === null || styles === void 0 ? void 0 : styles.arrowStyles;
  var customPopoverArrowStyles = hasArrowStyles ? _objectSpread(_objectSpread({}, popoverArrowStyles), hasArrowStyles) : _objectSpread({}, popoverArrowStyles);
  return (0, _jsxRuntime.jsxs)(_react.Popover, {
    closeOnBlur: true,
    children: [(0, _jsxRuntime.jsx)(PopoverTrigger, {
      children: (0, _jsxRuntime.jsx)(_react.Box, {
        _focusVisible: {
          outline: 'none'
        },
        children: triggerItem
      })
    }), (0, _jsxRuntime.jsxs)(_react.PopoverContent, _objectSpread(_objectSpread({}, popoverStyleProps), {}, {
      children: [(0, _jsxRuntime.jsx)(_react.PopoverArrow, _objectSpread({}, customPopoverArrowStyles)), (0, _jsxRuntime.jsx)(_react.PopoverBody, {
        p: "0",
        children: children
      })]
    }))]
  });
}