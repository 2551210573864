"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.OPERA_COMPANY_ID = exports.CDH_EMPLOYEE_ID = exports.CDH_COMPANY_ID = void 0;
exports.default = getLoggedInUserInfo;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _jwtDecode = _interopRequireDefault(require("jwt-decode"));
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
var OPERA_COMPANY_ID = exports.OPERA_COMPANY_ID = 'https://premierinn.com/operaCompanyId';
var CDH_COMPANY_ID = exports.CDH_COMPANY_ID = 'https://premierinn.com/companyAccountId';
var CDH_EMPLOYEE_ID = exports.CDH_EMPLOYEE_ID = 'https://premierinn.com/employeeAccountId';
function getLoggedInUserInfo(token) {
  var decodedToken = {
    name: '',
    profile: {
      accessLevel: '',
      companyId: '',
      employeeId: '',
      isBusiness: '',
      sessionId: ''
    },
    [CDH_COMPANY_ID]: '',
    [CDH_EMPLOYEE_ID]: '',
    [OPERA_COMPANY_ID]: ''
  };
  var cdhCompanyId = '';
  var cdhEmployeeId = '';
  var operaCompanyId = '';
  try {
    if (token !== null && token !== void 0 && token.length) {
      decodedToken = (0, _jwtDecode.default)(token);
      cdhCompanyId = decodedToken[CDH_COMPANY_ID];
      cdhEmployeeId = decodedToken[CDH_EMPLOYEE_ID];
      operaCompanyId = decodedToken[OPERA_COMPANY_ID];
    }
  } catch (e) {
    console.log(e);
  }
  return _objectSpread(_objectSpread(_objectSpread({}, decodedToken), decodedToken.profile), {}, {
    cdhCompanyId,
    cdhEmployeeId,
    operaCompanyId
  });
}