"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.LeadGuestDetails = LeadGuestDetails;
exports.LeadGuestDetailsForm = LeadGuestDetailsForm;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _react = require("@chakra-ui/react");
var _api = require("@whitbread-eos/api");
var _atoms = require("@whitbread-eos/atoms");
var _utils = require("@whitbread-eos/utils");
var _reactHookForm = require("react-hook-form");
var _guestDetails = require("../../guest-details");
var _constants = require("../RoomModal/constants");
var _leadGuestFormConfig = require("./leadGuestFormConfig");
var _jsxRuntime = require("react/jsx-runtime");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function LeadGuestDetailsForm(_ref) {
  var {
    control,
    formField,
    errors
  } = _ref;
  var {
    [_api.FT_PI_AMEND_GUEST_ADDRESS]: isAmendGuestAddressEnabledForPI,
    [_api.FT_CCUI_AMEND_GUEST_ADDRESS]: isAmendGuestAddressEnabledForCCUI
  } = (0, _utils.useFeatureToggle)();
  var {
    useTooltip,
    setGuestDetails,
    setIsLocationRequired,
    hotelCountry,
    language
  } = formField.props;
  var isFeatureEnabled = (featureFlagEnabled, countriesIncluded) => {
    return featureFlagEnabled && (countriesIncluded === null || countriesIncluded === void 0 ? void 0 : countriesIncluded.includes(hotelCountry));
  };
  var formFieldControls = [{
    name: 'title',
    label: 'title',
    type: 'dropdown'
  }, {
    name: 'firstName',
    label: 'firstName',
    type: 'input'
  }, {
    name: 'lastName',
    label: 'lastName',
    type: 'input'
  }, {
    name: 'emailAddress',
    label: 'emailAddress',
    type: 'input'
  }, {
    name: 'addressLine1',
    label: 'addressLine1',
    type: 'input',
    featureFlagEnabled: isAmendGuestAddressEnabledForPI || isAmendGuestAddressEnabledForCCUI,
    countriesIncluded: ['Germany', 'Deutschland']
  }, {
    name: 'addressLine2',
    label: 'addressLine2',
    type: 'input',
    featureFlagEnabled: isAmendGuestAddressEnabledForPI || isAmendGuestAddressEnabledForCCUI,
    countriesIncluded: ['Germany', 'Deutschland']
  }, {
    name: 'addressLine3',
    label: 'addressLine3',
    type: 'input',
    featureFlagEnabled: isAmendGuestAddressEnabledForPI || isAmendGuestAddressEnabledForCCUI,
    countriesIncluded: ['Germany', 'Deutschland']
  }, {
    name: 'cityName',
    label: 'city',
    type: 'input',
    featureFlagEnabled: isAmendGuestAddressEnabledForPI || isAmendGuestAddressEnabledForCCUI,
    countriesIncluded: ['Germany', 'Deutschland']
  }, {
    name: 'postalCode',
    label: 'postalCode',
    type: 'input',
    featureFlagEnabled: isAmendGuestAddressEnabledForPI || isAmendGuestAddressEnabledForCCUI,
    countriesIncluded: ['Germany', 'Deutschland']
  }];
  var errorStyles = fieldHasError => useTooltip && fieldHasError ? tooltipErrorStyles : {};
  var fieldProps = field => {
    return {
      name: field.name,
      onBlur: field.onBlur,
      value: field.value,
      onChange: value => {
        field.onChange(value);
        setGuestDetails(prevValue => _objectSpread(_objectSpread({}, prevValue), {}, {
          [field.name]: value
        }));
      }
    };
  };
  return (0, _jsxRuntime.jsxs)(_react.Box, {
    children: [formFieldControls.map(_ref2 => {
      var {
        name,
        label,
        type,
        featureFlagEnabled,
        countriesIncluded
      } = _ref2;
      return (isFeatureEnabled(featureFlagEnabled, countriesIncluded) || !featureFlagEnabled && !countriesIncluded) && (0, _jsxRuntime.jsx)(_react.Box, _objectSpread(_objectSpread({}, type === 'input' && errorStyles(errors)), {}, {
        children: (0, _jsxRuntime.jsx)(_reactHookForm.Controller, {
          name: name,
          control: control,
          render: _ref3 => {
            var _errors$name, _errors$name2, _errors$name3;
            var {
              field
            } = _ref3;
            return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
              children: [type === 'input' && (0, _jsxRuntime.jsx)(_atoms.Input, _objectSpread(_objectSpread(_objectSpread({}, formField.props), fieldProps(field)), {}, {
                "data-testid": (0, _utils.formatDataTestId)(formField.testid, name),
                styles: inputStyles,
                type: "text",
                showIcon: false,
                placeholderText: formField.props.labels[label],
                label: formField.props.labels[label],
                error: (_errors$name = errors[name]) === null || _errors$name === void 0 ? void 0 : _errors$name.message,
                className: "sessioncamhidetext assist-no-show"
              })), type === 'dropdown' && (0, _jsxRuntime.jsx)(_atoms.Dropdown, _objectSpread(_objectSpread(_objectSpread({}, formField.props), fieldProps(field)), {}, {
                dataTestId: (0, _utils.formatDataTestId)(formField.testid, "".concat(label, "Dropdown")),
                onChange: title => {
                  setGuestDetails(prevValue => _objectSpread(_objectSpread({}, prevValue), {}, {
                    [name]: title === null || title === void 0 ? void 0 : title.id
                  }));
                  field.onChange(title === null || title === void 0 ? void 0 : title.id);
                },
                showStatusIcon: false,
                options: formField.dropdownOptions,
                placeholder: formField.props.labels[name],
                hasError: Boolean((_errors$name2 = errors[name]) === null || _errors$name2 === void 0 ? void 0 : _errors$name2.message),
                selectedId: field.value,
                matchWidth: true,
                dropdownStyles: _objectSpread(_objectSpread({}, dropdownStyles), {}, {
                  menuButtonStyles: renderDropdownStyles(Boolean((_errors$name3 = errors[name]) === null || _errors$name3 === void 0 ? void 0 : _errors$name3.message))
                }),
                onBlur: field.onBlur
              }))]
            });
          }
        })
      }), name);
    }), (isAmendGuestAddressEnabledForPI || isAmendGuestAddressEnabledForCCUI) && ['Germany', 'Deutschland'].includes(hotelCountry) && (0, _jsxRuntime.jsx)(_react.Box, {
      pt: '1rem',
      children: (0, _jsxRuntime.jsx)(_reactHookForm.Controller, {
        name: "countryCode",
        control: control,
        defaultValue: language === _utils.GLOBALS.language.EN ? _utils.GLOBALS.localeUpper.GB : _utils.GLOBALS.localeUpper.DE,
        render: _ref4 => {
          var {
            field
          } = _ref4;
          return (0, _jsxRuntime.jsx)(_guestDetails.CountriesDropdown, {
            formField: {
              label: 'Country',
              type: _atoms.FORM_FIELD_TYPES.DYNAMIC_FIELD,
              name: 'countryCode',
              testid: (0, _utils.formatDataTestId)("leadGuest", 'CountrySelector'),
              props: {
                showIcon: false,
                setIsLocationRequired
              }
            },
            getValues: () => '',
            field: field
          });
        }
      })
    })]
  });
}
function LeadGuestDetails(_ref5) {
  var {
    labels,
    validationLabels,
    baseDataTestId,
    leadGuestDetails,
    getFormState,
    isEdit,
    onSubmit,
    setGuestDetails,
    setIsLocationRequired,
    hotelCountry,
    language
  } = _ref5;
  return (0, _jsxRuntime.jsx)(_react.Box, {
    "data-testid": (0, _utils.formatDataTestId)(baseDataTestId, 'lg-section'),
    children: (0, _jsxRuntime.jsx)(_atoms.Form, _objectSpread({}, (0, _leadGuestFormConfig.leadGuestFormConfig)({
      getFormState,
      defaultValues: isEdit ? leadGuestDetails : _constants.INITIAL_LEAD_GUEST_DETAILS,
      onSubmit,
      baseTestId: baseDataTestId,
      labels,
      validationLabels,
      isEdit,
      setGuestDetails,
      setIsLocationRequired,
      hotelCountry,
      language
    })))
  });
}
var inputWrapperStyles = {
  marginTop: 'md'
};
var inputElementStyles = {
  borderRadius: 'var(--chakra-space-xs)',
  border: '1px solid',
  borderColor: 'var(--chakra-colors-lightGrey1)',
  _hover: {
    borderColor: 'var(--chakra-colors-darkGrey1)'
  },
  _invalid: {
    boxShadow: 'none',
    borderColor: 'var(--chakra-colors-alert)'
  }
};
var inputStyles = {
  inputWrapperStyles,
  inputElementStyles
};
var dropdownStyles = {
  menuButtonStyles: {
    borderColor: 'var(--chakra-colors-lightGrey1)'
  },
  menuListStyles: {
    py: 0,
    maxHeight: '12.5rem'
  }
};
var tooltipErrorStyles = {
  marginBottom: 'var(--chakra-space-4xl)'
};
var renderDropdownStyles = hasError => {
  return {
    border: '1px solid',
    borderColor: hasError ? 'var(--chakra-colors-error)' : 'lightGrey1',
    borderRadius: 'var(--chakra-radii-md)'
  };
};