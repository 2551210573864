"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = RemoveRoomModal;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _react = require("@chakra-ui/react");
var _atoms = require("@whitbread-eos/atoms");
var _jsxRuntime = require("react/jsx-runtime");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function RemoveRoomModal(_ref) {
  var {
    isOpen,
    onClose,
    labels,
    roomNumber,
    onRemoveRoom,
    reservationId
  } = _ref;
  var {
    title,
    confirmLabel,
    notificationLabel,
    removeModalRoom,
    cancelModalRoom
  } = labels;
  return (0, _jsxRuntime.jsx)(_atoms.ModalVariants, {
    isOpen: isOpen,
    onClose: onClose,
    variant: "default",
    variantProps: {
      title: '',
      delimiter: true
    },
    headerStyles: {
      textAlign: 'center',
      justifyContent: 'flex-end'
    },
    updatedWidth: {
      sm: '100%',
      md: '37.5rem',
      lg: '37.5rem',
      xl: '39.93rem'
    },
    children: (0, _jsxRuntime.jsxs)(_react.Flex, _objectSpread(_objectSpread({}, modalContentStyles), {}, {
      children: [(0, _jsxRuntime.jsx)(_react.Heading, _objectSpread(_objectSpread({
        as: "h2"
      }, headingStyles), {}, {
        children: "".concat(title, " ").concat(roomNumber)
      })), (0, _jsxRuntime.jsxs)(_react.Flex, _objectSpread(_objectSpread({
        direction: "column"
      }, modalTextStyles), {}, {
        children: [(0, _jsxRuntime.jsx)(_react.Text, {
          marginBottom: "sm",
          fontWeight: "semibold",
          color: "darkGrey1",
          children: confirmLabel
        }), (0, _jsxRuntime.jsx)(_react.Text, {
          fontWeight: "400",
          color: "darkGrey2",
          children: notificationLabel
        })]
      })), (0, _jsxRuntime.jsxs)(_react.Flex, _objectSpread(_objectSpread({}, modalButtonsContainerStyles), {}, {
        children: [(0, _jsxRuntime.jsx)(_react.Button, _objectSpread(_objectSpread({
          "data-testid": "RemoveRoomModal-removeRoom",
          size: "xsm",
          variant: "secondary",
          marginRight: {
            sm: '1.5rem',
            md: '1.46rem',
            lg: '1.5rem'
          },
          onClick: () => onRemoveRoom(reservationId, roomNumber)
        }, modalButtonsStyles), {}, {
          children: removeModalRoom
        })), (0, _jsxRuntime.jsx)(_react.Button, _objectSpread(_objectSpread({
          "data-testid": "RemoveRoomModal-cancelRemove",
          size: "xsm",
          variant: "tertiary",
          transition: "none",
          marginTop: {
            base: 'md',
            sm: '0'
          },
          onClick: onClose
        }, modalButtonsStyles), {}, {
          children: cancelModalRoom
        }))]
      }))]
    }))
  });
}
var modalContentStyles = {
  direction: 'column',
  alignItems: 'center',
  textAlign: 'center',
  mt: 'lg',
  mb: '2xl',
  px: {
    base: '1.5rem',
    xs: '1rem',
    sm: '1.06rem',
    lg: '1.5rem',
    xl: '1.53rem'
  }
};
var headingStyles = {
  fontFamily: 'header',
  fontSize: 'xl',
  fontWeight: '600',
  lineHeight: '3',
  mb: 'sm',
  color: 'darkGrey1'
};
var modalTextStyles = {
  fontSize: 'sm',
  lineHeight: '2',
  mb: {
    base: 'xl',
    lg: 'lg'
  }
};
var modalButtonsContainerStyles = {
  w: '100%',
  flexDirection: {
    base: 'column',
    sm: 'row'
  },
  justifyContent: 'center',
  alignItems: 'center'
};
var modalButtonsStyles = {
  w: {
    base: 'full',
    xs: '21.43rem',
    sm: '16.18rem',
    md: '15.93rem',
    lg: '16.5rem',
    xl: '17.87rem'
  },
  h: '3.5rem',
  borderRadius: '.25rem',
  p: 'md'
};