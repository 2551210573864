"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = AuthContentManagerPIVariantContainer;
var _api = require("@whitbread-eos/api");
var _utils = require("@whitbread-eos/utils");
var _react = require("react");
var _AuthContentManagerPIVariant = _interopRequireDefault(require("./AuthContentManagerPIVariant.component"));
var _jsxRuntime = require("react/jsx-runtime");
function AuthContentManagerPIVariantContainer(_ref) {
  var _data$headerInformati3;
  var {
    isLoginModalOpen,
    toggleLoginModal
  } = _ref;
  var {
    language: currentLanguage,
    country: currentCountry
  } = (0, _utils.useCustomLocale)();
  var {
    data
  } = (0, _utils.useQueryRequest)(['GetStaticContent', currentLanguage, currentCountry], _api.GET_STATIC_CONTENT, {
    language: currentLanguage,
    country: currentCountry,
    site: _api.SITE_LEISURE,
    businessBooker: false
  });
  var [labels, setLabels] = (0, _react.useState)(_api.DEFAULT_LOGIN_LABELS);
  (0, _react.useEffect)(() => {
    var _data$headerInformati;
    if (data !== null && data !== void 0 && (_data$headerInformati = data.headerInformation) !== null && _data$headerInformati !== void 0 && (_data$headerInformati = _data$headerInformati.content) !== null && _data$headerInformati !== void 0 && _data$headerInformati.authentication) {
      var _data$headerInformati2;
      setLabels(data === null || data === void 0 || (_data$headerInformati2 = data.headerInformation.content) === null || _data$headerInformati2 === void 0 ? void 0 : _data$headerInformati2.authentication);
    }
  }, [data === null || data === void 0 || (_data$headerInformati3 = data.headerInformation) === null || _data$headerInformati3 === void 0 || (_data$headerInformati3 = _data$headerInformati3.content) === null || _data$headerInformati3 === void 0 ? void 0 : _data$headerInformati3.authentication]);
  return (0, _jsxRuntime.jsx)(_AuthContentManagerPIVariant.default, {
    isLoginModalOpen: isLoginModalOpen,
    toggleLoginModal: toggleLoginModal,
    labels: labels
  });
}