"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = AutoCompleteStaticHotels;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _react = _interopRequireWildcard(require("react"));
var _AutocompleteLocation = _interopRequireDefault(require("../AutocompleteLocation"));
var _FormError = _interopRequireDefault(require("../Form/FormError"));
var _jsxRuntime = require("react/jsx-runtime");
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && {}.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function AutoCompleteStaticHotels(_ref) {
  var {
    formField: {
      props,
      errorStyles
    },
    field,
    handleSetValue,
    errors = {},
    handleClearErrors
  } = _ref;
  var [inputValue, setInputValue] = (0, _react.useState)('');
  var [isError, setIsError] = (0, _react.useState)(false);
  (0, _react.useEffect)(() => {
    setIsError(!!errors[field.name]);
  }, [errors[field.name]]);
  var handleClearInput = () => setInputValue('');
  var handleLocationInputChange = value => {
    setInputValue(value || '');
  };
  if (props !== null && props !== void 0 && props.hotels && props.hotels.length > 0) {
    return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
      children: [(0, _jsxRuntime.jsx)(_AutocompleteLocation.default, {
        dataTestId: "HotelsDropdownPicker",
        items: inputValue ? props.hotels : [],
        inputPlaceholder: props.placeholder,
        showElements: true,
        openListOnFocus: false,
        disableInternalFilter: false,
        autocompleteStyles: _objectSpread(_objectSpread({
          errorMarginBottom
        }, isError && {
          errorInputGroupStyles,
          errorInputElementStyles
        }), {}, {
          inputGroupStyles: wrapperStyles,
          inputElementStyles: inputStyles,
          listStyles
        }),
        onInputChange: handleLocationInputChange,
        onChange: value => {
          handleSetValue && handleSetValue(field.name, value);
          setInputValue(value);
          setIsError(false);
          handleClearErrors && handleClearErrors(field.name);
        },
        inputSelectedValue: inputValue,
        hasClearIcon: true,
        onClearInput: handleClearInput,
        onSelectOption: props === null || props === void 0 ? void 0 : props.handleSelectOption,
        hasItemObject: true
      }), isError && (0, _jsxRuntime.jsx)(_FormError.default, {
        errors: errors,
        name: field.name,
        extraStyles: errorStyles
      })]
    });
  }
}
var listStyles = {
  maxHeight: '16rem',
  overflow: 'scroll'
};
var wrapperStyles = {
  w: '100%',
  p: 0,
  height: 'var(--chakra-space-4xl)',
  _placeholder: {
    opacity: 0.7,
    color: 'var(--chakra-darkGrey1)'
  }
};
var inputStyles = {
  border: '0.063rem solid var(--chakra-colors-lightGrey1)'
};
var errorMarginBottom = {
  mobile: 'sm',
  xs: 'md',
  sm: '0'
};
var errorInputGroupStyles = {
  borderRadius: 'var(--chakra-radii-base)',
  border: '2px solid var(--chakra-colors-error)'
};
var errorInputElementStyles = {
  border: 'none',
  borderRight: 'none',
  _hover: {
    border: 'none'
  },
  _focus: {
    border: 'none'
  },
  _active: {
    border: 'none'
  }
};