"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _api = require("@whitbread-eos/api");
var createLink = (title, key) => ({
  action: handleGenericAction,
  type: 'link',
  isLinkEnabled: true,
  title,
  key
});
var allLinks = [createLink('ccui.manageBooking.options.resendConfirmation', 'resendConfirmation'), createLink('dashboard.bookings.sendInvoice', 'resendInvoice'), {
  title: 'ccui.manageBooking.options.otherOptions',
  key: 'otherOptions',
  type: 'header'
}, createLink('ccui.manageBooking.options.changeLog', 'changeLog'), createLink('ccui.manageBooking.options.agentNotes', 'agentNotes'), createLink('ccui.manageBooking.options.splitBooking', 'splitBooking'), createLink('ccui.manageBooking.options.repeatBooking', 'repeatBooking'), createLink('ccui.manageBooking.options.cancelRebook', 'cancelRebook'), createLink('ccui.manageBooking.options.overridePolicies', 'overridePolicies'), createLink('ccui.manageBooking.options.changePIPBACNPStatus', 'changePiba'), createLink('ccui.manageBooking.options.updateDiscountAmount', 'updateDiscountAmount'), createLink('ccui.manageBooking.options.updateBookerDetails', 'bookerDetails'), createLink('ccui.manageBooking.option.changePaymentMethod', 'changePaymentMethod')];
var getActionsByCriteria = (criteria, basketReference, paymentOption, setIsAgentOverrideModalVisible, handleAgentMemo, handleResendInvoiceAction, handleResendConfirmationAction, handleRepeatBooking, handleChangeLogClicked, handleChangePayment) => {
  return allLinks.filter(link => isAvailable(link.key, criteria)).map(link => {
    switch (link.key) {
      case 'overridePolicies':
        link.action = () => setIsAgentOverrideModalVisible && setIsAgentOverrideModalVisible(true);
        break;
      case 'agentNotes':
        link.action = () => handleAgentMemo && handleAgentMemo({
          reservationId: basketReference,
          variant: _api.MemoModalVariants.CARD
        });
        break;
      case 'resendInvoice':
        link.action = () => handleResendInvoiceAction && handleResendInvoiceAction();
        break;
      case 'resendConfirmation':
        link.action = () => handleResendConfirmationAction && handleResendConfirmationAction();
        break;
      case 'repeatBooking':
        link.action = () => handleRepeatBooking && handleRepeatBooking();
        break;
      case 'changeLog':
        link.action = () => handleChangeLogClicked === null || handleChangeLogClicked === void 0 ? void 0 : handleChangeLogClicked();
        break;
      case 'changePaymentMethod':
        link.action = () => handleChangePayment === null || handleChangePayment === void 0 ? void 0 : handleChangePayment();
        link.isLinkEnabled = paymentOption === _api.paymentOptions.RESERVE_WITHOUT_CARD;
        break;
      default:
        link.action = handleGenericAction;
    }
    return link;
  });
};
var linksForCCUI = ['otherOptions', 'changeLog', 'agentNotes', 'splitBooking', 'repeatBooking', 'cancelRebook', 'overridePolicies', 'changePiba', 'updateDiscountAmount', 'bookerDetails', 'changePaymentMethod'];
var linksForManager = ['resendConfirmation', 'resendInvoice', ...linksForCCUI];
var linksForNormalUser = linksForManager;
var linksForUpcomingBookings = ['resendConfirmation', ...linksForCCUI];
var linksForCancelledBookings = ['otherOptions', 'changeLog', 'agentNotes', 'repeatBooking'];
var linksForPastBookings = ['resendInvoice', ...linksForCancelledBookings];
var linksForPIByStatus = {
  [_api.BOOKING_TYPE.UPCOMING]: [],
  [_api.BOOKING_TYPE.PAST]: []
};
linksForPIByStatus[_api.BOOKING_TYPE.UPCOMING].push('resendConfirmation');
linksForPIByStatus[_api.BOOKING_TYPE.PAST].push('resendInvoice');
function isAvailable(key, criteria) {
  return isAvailableByRole(key, criteria.role) && isAvailableByArea(key, criteria.area) && (criteria.area === _api.Area.CCUI || [_api.Area.PI, _api.Area.BB].includes(criteria.area) && criteria.bookingType ? isAvailableByBookingType(key, criteria.bookingType) && isAvailableByFeatureFlag(key, criteria.isChangePaymentFeatureEnabled) : isAvailableByStatus(key, criteria.bookingStatus));
}
function isAvailableByFeatureFlag(key, featureFlag) {
  if (key === 'changePaymentMethod') {
    return featureFlag && linksForCCUI.includes(key);
  }
  return true;
}
function isAvailableByRole(key, role) {
  switch (role) {
    case 'manager':
      return linksForManager.includes(key);
    case 'all':
      return linksForNormalUser.includes(key);
    default:
      return true;
  }
}
function isAvailableByArea(key, area) {
  var mergedPILinksByStatus = [...linksForPIByStatus[_api.BOOKING_TYPE.UPCOMING], ...linksForPIByStatus[_api.BOOKING_TYPE.PAST]];
  switch (area) {
    case _api.Area.CCUI:
      return linksForCCUI.includes(key);
    case _api.Area.PI:
      return mergedPILinksByStatus.includes(key);
    case _api.Area.BB:
      return mergedPILinksByStatus.includes(key);
    default:
      return true;
  }
}
function isAvailableByStatus(key, bookingStatus) {
  switch (bookingStatus) {
    case _api.BASKET_STATUS.CANCELLED:
      return linksForCancelledBookings.includes(key);
    case _api.BASKET_STATUS.COMPLETED:
      return linksForPastBookings.includes(key);
    default:
      return true;
  }
}
function isAvailableByBookingType(key, bookingType) {
  switch (bookingType) {
    case _api.BOOKING_TYPE.UPCOMING:
      return linksForUpcomingBookings.includes(key) || linksForPIByStatus[_api.BOOKING_TYPE.UPCOMING].includes(key);
    case _api.BOOKING_TYPE.PAST:
      return linksForPastBookings.includes(key) || linksForPIByStatus[_api.BOOKING_TYPE.PAST].includes(key);
    case _api.BOOKING_TYPE.CANCELLED:
      return linksForCancelledBookings.includes(key);
    default:
      return true;
  }
}
function handleGenericAction() {
  console.log("Handle action");
}
var _default = exports.default = getActionsByCriteria;