"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.BookingHistoryInfoCardContainer = void 0;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _react = require("@chakra-ui/react");
var _api = require("@whitbread-eos/api");
var _atoms = require("@whitbread-eos/atoms");
var _utils = require("@whitbread-eos/utils");
var _nextI18next = require("next-i18next");
var _react2 = require("react");
var _BookingHistoryInfoCard = _interopRequireDefault(require("./BookingHistoryInfoCard.component"));
var _ResendConfirmationModal = require("./ResendConfirmationModal");
var _jsxRuntime = require("react/jsx-runtime");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
var BookingHistoryInfoCardContainer = _ref => {
  var _data$bookingInfoCard2, _reservationDetails$b, _reservationDetails$b2, _reservationDetails$p, _reservationDetails$s, _data$bookingInfoCard3;
  var {
    bookingReference,
    bookingStatus,
    hotelId,
    arrival,
    guestSurname,
    bookedBy,
    onCancel,
    hotelName,
    invoiceRecordNumber,
    invoiceItems,
    bookingChannel,
    sourceSystem,
    roomTypeLabels,
    area,
    isReadOnly,
    shouldShowTypeOfBooking,
    leadGuestName
  } = _ref;
  var baseDataTestId = 'BookingHistoryDetails';
  var {
    t
  } = (0, _nextI18next.useTranslation)();
  var {
    language,
    country
  } = (0, _utils.useCustomLocale)();
  var [isModalVisible, setIsModalVisible] = (0, _react2.useState)(false);
  var onModalClose = () => setIsModalVisible(prevState => !prevState);
  var surname = encodeURIComponent(guestSurname);
  var idTokenCookie = (0, _utils.getAuthCookie)();
  var {
    email: emailAddress
  } = (0, _utils.decodeIdToken)(idTokenCookie);
  var {
    data,
    isLoading,
    isError,
    error,
    refetch
  } = (0, _utils.useQueryRequest)(['getBookingHistoryCard', bookingReference, hotelId, arrival, surname, country, language, bookingChannel, ...(sourceSystem ? [sourceSystem] : [])], _api.GET_BOOKING_HISTORY_DETAILS, _objectSpread({
    bookingReference,
    hotelId,
    arrival,
    surname,
    country,
    language,
    bookingChannel
  }, sourceSystem ? {
    sourceSystem
  } : {}), null, idTokenCookie);
  var {
    mutation: mutationResendInvoice,
    isSuccess
  } = (0, _utils.useMutationRequest)(_api.RESEND_INVOICE_BOOKING_INFORMATION_CARD, true, idTokenCookie);
  (0, _react2.useEffect)(() => {
    if (isSuccess) {
      invoiceItems === null || invoiceItems === void 0 || invoiceItems.setInvoiceItems([...invoiceItems.sentInvoiceItems, bookingReference]);
    }
  }, [isSuccess]);
  var handleCancelBooking = () => {
    if (!isReadOnly) {
      refetch();
      onCancel === null || onCancel === void 0 || onCancel(bookingReference);
    }
  };
  var handleResendInvoice = () => {
    var _data$bookingInfoCard;
    mutationResendInvoice.mutate({
      hotelId: hotelId,
      email: emailAddress,
      bookingReference: data === null || data === void 0 || (_data$bookingInfoCard = data.bookingInfoCardDetails) === null || _data$bookingInfoCard === void 0 || (_data$bookingInfoCard = _data$bookingInfoCard.reservationDetails) === null || _data$bookingInfoCard === void 0 ? void 0 : _data$bookingInfoCard.basketReference,
      invoiceRecordNumber: invoiceRecordNumber,
      bookingChannel: bookingChannel
    });
  };
  if (isLoading) {
    return (0, _jsxRuntime.jsx)(_react.Flex, _objectSpread(_objectSpread({}, loadingStyle), {}, {
      "data-testid": "Loading-BookingHistoryInfoCard",
      children: (0, _jsxRuntime.jsx)(_atoms.LoadingSpinner, {
        loadingText: 'Loading'
      })
    }));
  }
  if (isError) {
    return (0, _jsxRuntime.jsx)(_react.Text, {
      children: error.message
    });
  }
  var reservationDetails = data === null || data === void 0 || (_data$bookingInfoCard2 = data.bookingInfoCardDetails) === null || _data$bookingInfoCard2 === void 0 ? void 0 : _data$bookingInfoCard2.reservationDetails;
  var bookingDetails = (0, _utils.getBookingDetailsForCard)(reservationDetails, hotelName, bookedBy, guestSurname, roomTypeLabels, t);
  return (0, _jsxRuntime.jsxs)(_react.Box, {
    "data-testid": 'BookingInfoCardContainer',
    children: [hotelId && (0, _jsxRuntime.jsx)(_BookingHistoryInfoCard.default, {
      area: area,
      bookingDetails: bookingDetails,
      bookingReference: (_reservationDetails$b = reservationDetails === null || reservationDetails === void 0 ? void 0 : reservationDetails.bookingReference) !== null && _reservationDetails$b !== void 0 ? _reservationDetails$b : '',
      basketReference: (_reservationDetails$b2 = reservationDetails === null || reservationDetails === void 0 ? void 0 : reservationDetails.basketReference) !== null && _reservationDetails$b2 !== void 0 ? _reservationDetails$b2 : '',
      noOfRooms: reservationDetails === null || reservationDetails === void 0 ? void 0 : reservationDetails.noOfRooms,
      skipContainerRendering: true,
      basketStatus: bookingStatus.toUpperCase(),
      paymentOption: (_reservationDetails$p = reservationDetails === null || reservationDetails === void 0 ? void 0 : reservationDetails.paymentOption) !== null && _reservationDetails$p !== void 0 ? _reservationDetails$p : '',
      baseDataTestId: baseDataTestId,
      hotelId: hotelId,
      onCancelBooking: handleCancelBooking,
      invoiceSentMsg: {
        displaySentInvoiceMsg: isSuccess || (invoiceItems === null || invoiceItems === void 0 ? void 0 : invoiceItems.sentInvoiceItems.includes(bookingReference)),
        notificationMessage: t('dashboard.bookings.sendInvoiceNotification')
      },
      handleResendInvoiceAction: handleResendInvoice,
      handleResendConfirmationAction: onModalClose,
      bookingChannel: bookingChannel,
      sourceSystem: (_reservationDetails$s = reservationDetails === null || reservationDetails === void 0 ? void 0 : reservationDetails.sourceSystem) !== null && _reservationDetails$s !== void 0 ? _reservationDetails$s : _api.SOURCE_SYSTEM.OPERA,
      bookingSurname: guestSurname,
      isReadOnly: isReadOnly,
      shouldShowTypeOfBooking: shouldShowTypeOfBooking,
      hotelName: hotelName,
      leadGuestName: leadGuestName
    }), (reservationDetails === null || reservationDetails === void 0 ? void 0 : reservationDetails.bookingReference) && (0, _jsxRuntime.jsx)(_ResendConfirmationModal.ResendConfirmationModal, {
      isModalVisible: isModalVisible,
      onModalClose: onModalClose,
      basketReference: data === null || data === void 0 || (_data$bookingInfoCard3 = data.bookingInfoCardDetails) === null || _data$bookingInfoCard3 === void 0 || (_data$bookingInfoCard3 = _data$bookingInfoCard3.reservationDetails) === null || _data$bookingInfoCard3 === void 0 ? void 0 : _data$bookingInfoCard3.basketReference,
      hotelId: hotelId
    })]
  });
};
exports.BookingHistoryInfoCardContainer = BookingHistoryInfoCardContainer;
var loadingStyle = {
  height: '100%',
  width: '100%',
  left: 0,
  top: 0,
  position: 'relative',
  zIndex: 999,
  backgroundColor: 'baseWhite',
  textAlign: 'center',
  lineHeight: 3,
  justifyContent: 'center',
  color: 'btnSecondaryEnabled',
  fontSize: 'xl',
  flex: 'none',
  flexGrow: 0,
  order: 1,
  alignSelf: 'stretch'
};
var _default = exports.default = BookingHistoryInfoCardContainer;