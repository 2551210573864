"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = CompanyBillingProfile;
var _utils = require("@whitbread-eos/utils");
var _react = require("react");
function CompanyBillingProfile(_ref) {
  var _formField$props2;
  var {
    formField,
    handleSetValue
  } = _ref;
  var [triggerSetValues, setTriggerSetValues] = (0, _react.useState)(0);
  (0, _react.useEffect)(() => {
    if (handleSetValue && triggerSetValues) {
      var _formField$props;
      var companyProfile = formField === null || formField === void 0 || (_formField$props = formField.props) === null || _formField$props === void 0 ? void 0 : _formField$props.companyProfile;
      if (companyProfile) {
        var _companyProfile$addre, _companyProfile$addre2, _companyProfile$addre3, _companyProfile$addre4, _companyProfile$addre5, _companyProfile$addre6;
        handleSetValue("billing_addressSelection", _utils.GLOBALS.addressType.BUSINESS);
        handleSetValue("billing_companyName", companyProfile === null || companyProfile === void 0 ? void 0 : companyProfile.name);
        handleSetValue("billing_addressLine1", companyProfile === null || companyProfile === void 0 || (_companyProfile$addre = companyProfile.address) === null || _companyProfile$addre === void 0 ? void 0 : _companyProfile$addre.addressLine1);
        handleSetValue("billing_addressLine2", companyProfile === null || companyProfile === void 0 || (_companyProfile$addre2 = companyProfile.address) === null || _companyProfile$addre2 === void 0 ? void 0 : _companyProfile$addre2.addressLine2);
        handleSetValue("billing_addressLine3", companyProfile === null || companyProfile === void 0 || (_companyProfile$addre3 = companyProfile.address) === null || _companyProfile$addre3 === void 0 ? void 0 : _companyProfile$addre3.addressLine3);
        handleSetValue("billing_addressLine4", companyProfile === null || companyProfile === void 0 || (_companyProfile$addre4 = companyProfile.address) === null || _companyProfile$addre4 === void 0 ? void 0 : _companyProfile$addre4.addressLine4);
        handleSetValue("billing_cityName", companyProfile === null || companyProfile === void 0 || (_companyProfile$addre5 = companyProfile.address) === null || _companyProfile$addre5 === void 0 ? void 0 : _companyProfile$addre5.addressLine4);
        handleSetValue("billing_postalCode", companyProfile === null || companyProfile === void 0 || (_companyProfile$addre6 = companyProfile.address) === null || _companyProfile$addre6 === void 0 ? void 0 : _companyProfile$addre6.postalCode);
      }
    }
  }, [handleSetValue, triggerSetValues, formField === null || formField === void 0 || (_formField$props2 = formField.props) === null || _formField$props2 === void 0 ? void 0 : _formField$props2.companyProfile]);
  (0, _react.useEffect)(() => {
    setTriggerSetValues(prev => prev + 1);
  }, []);
  return null;
}