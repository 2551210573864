"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _analytics = _interopRequireDefault(require("./analytics"));
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
var updateAmendPageAnalytics = (_ref, originalBookingReference) => {
  var {
    change,
    revenue,
    extrasRevenueChange,
    foodRevenueChange,
    nightsChange,
    roomTypeChange,
    roomsChange,
    totalRevenueChange
  } = _ref;
  var amendAnalytics = {
    change: change,
    revenue: revenue,
    extrasRevenueChange: extrasRevenueChange,
    foodRevenueChange: foodRevenueChange,
    nightsChange: nightsChange,
    roomTypeChange: roomTypeChange,
    roomsChange: roomsChange,
    totalRevenueChange: totalRevenueChange
  };
  window.sessionStorage.setItem('AmendAnalytics', JSON.stringify(_objectSpread(_objectSpread({}, amendAnalytics), {}, {
    originalBookingReference: originalBookingReference
  })));
  return _analytics.default.update(amendAnalytics);
};
var _default = exports.default = updateAmendPageAnalytics;