"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "BookingDetails", {
  enumerable: true,
  get: function get() {
    return _BookingDetails2.default;
  }
});
Object.defineProperty(exports, "BookingDetailsComponent", {
  enumerable: true,
  get: function get() {
    return _BookingDetails.default;
  }
});
Object.defineProperty(exports, "BookingDetailsController", {
  enumerable: true,
  get: function get() {
    return _BookingDetailsController.BookingDetailsController;
  }
});
Object.defineProperty(exports, "BookingDetailsControllerComponent", {
  enumerable: true,
  get: function get() {
    return _BookingDetailsController.BookingDetailsControllerComponent;
  }
});
var _BookingDetails = _interopRequireDefault(require("./BookingDetails.component"));
var _BookingDetails2 = _interopRequireDefault(require("./BookingDetails.container"));
var _BookingDetailsController = require("./BookingDetailsController");