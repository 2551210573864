"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = ImportantNotificationQueryWrapper;
var _reactQuery = require("@tanstack/react-query");
var _ImportantNotification = _interopRequireDefault(require("./ImportantNotification.container"));
var _jsxRuntime = require("react/jsx-runtime");
function ImportantNotificationQueryWrapper(_ref) {
  var {
    queryClient,
    arrival,
    departure
  } = _ref;
  return (0, _jsxRuntime.jsx)(_reactQuery.QueryClientProvider, {
    client: queryClient,
    children: (0, _jsxRuntime.jsx)(_ImportantNotification.default, {
      arrival,
      departure
    })
  });
}