"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = SilentSubstitutionNotificationWrapper;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _react = require("@chakra-ui/react");
var _molecules = require("@whitbread-eos/molecules");
var _jsxRuntime = require("react/jsx-runtime");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function SilentSubstitutionNotificationWrapper(_ref) {
  var {
    brand,
    roomTypeInformationResponse,
    currentClassRoomTypes
  } = _ref;
  var substitutedRooms = getSilentSubstitutedRooms();
  var hasNonSilentSubstitution = (substitutedRooms === null || substitutedRooms === void 0 ? void 0 : substitutedRooms.length) > 0;
  function getSilentSubstitutedRooms() {
    var silentSubstitutedRoom = [];
    currentClassRoomTypes === null || currentClassRoomTypes === void 0 || currentClassRoomTypes.forEach(room => {
      if ((room === null || room === void 0 ? void 0 : room.silentSubstitution) === false && !silentSubstitutedRoom.includes(room === null || room === void 0 ? void 0 : room.roomLabelCode)) {
        silentSubstitutedRoom.push(room === null || room === void 0 ? void 0 : room.roomLabelCode);
      }
    });
    return silentSubstitutedRoom;
  }
  return (0, _jsxRuntime.jsx)(_jsxRuntime.Fragment, {
    children: hasNonSilentSubstitution && (0, _jsxRuntime.jsx)(_react.Box, _objectSpread(_objectSpread({}, notificationBoxStyles), {}, {
      children: (0, _jsxRuntime.jsx)(_molecules.SilentSubstitutionNotification, {
        brand: brand,
        substitutedRooms: substitutedRooms,
        roomTypeInformationResponse: roomTypeInformationResponse
      })
    }))
  });
}
var notificationBoxStyles = {
  mb: 'lg',
  w: {
    base: 'full',
    lg: 'calc(100% - 333px)'
  }
};