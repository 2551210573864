"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.STAY_DETAILS_STATE_INITIAL_VALUE = exports.SEARCH_REFERRER_INITIAL_VALUE = exports.DISTANCE_FROM_SEARCH_INITIAL_VALUE = void 0;
var STAY_DETAILS_STATE_INITIAL_VALUE = exports.STAY_DETAILS_STATE_INITIAL_VALUE = {
  data: {
    info: {
      suggestion: {
        location: {
          latitude: '',
          longitude: ''
        },
        placeId: '',
        hotelId: ''
      }
    }
  }
};
var SEARCH_REFERRER_INITIAL_VALUE = exports.SEARCH_REFERRER_INITIAL_VALUE = {
  data: {
    referrer: ''
  }
};
var DISTANCE_FROM_SEARCH_INITIAL_VALUE = exports.DISTANCE_FROM_SEARCH_INITIAL_VALUE = {
  data: {
    distance: 0
  }
};