"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = Basket;
exports.setCookieForABTesting = setCookieForABTesting;
var _api = require("@whitbread-eos/api");
var _utils = require("@whitbread-eos/utils");
var _react = require("react");
var _Basket = _interopRequireDefault(require("./Basket.component"));
var _jsxRuntime = require("react/jsx-runtime");
function Basket(_ref) {
  var _selectedRate$roomTyp, _selectedRate$roomTyp2, _selectedRate$roomTyp3, _selectedRateRoomType, _selectedRate$roomTyp4, _selectedRate$roomTyp5, _selectedRate$roomTyp6;
  var {
    channel,
    variant,
    roomClassIndexFromSelectedRate,
    roomClass,
    roomClassCode = '',
    isCityTaxExempt,
    isLastFewRooms,
    isHDPBasket,
    hasAccessibleRoom,
    hasTwinRoomChoice,
    shouldDisplayMobileBasket,
    hotelId,
    arrival,
    departure,
    numberOfUnits,
    selectedRate,
    numberOfNights,
    rateName,
    roomTypeInformationResponse,
    selectedPMSRoomTypes,
    selectedSpecialRequests,
    isLessThanLg,
    bookRsvIsLoading,
    bookRsvIsError,
    bookRsvError,
    handleBooking,
    brand,
    isDisabledContinueBtn,
    twinroomSelections,
    roomsLabelsForSilentSubst,
    isSilentFeatureFlagEnabled,
    saveLabelsForSilentSubstAndRedirect,
    setResRoomTypes,
    setCurrentClassRoomTypes,
    companyData,
    selectedRateCategory,
    isPrePopulateBillingAddressEnabled
  } = _ref;
  var {
    language
  } = (0, _utils.useCustomLocale)();
  var ratePlanCode = setSelectedRatePlanCode();
  var {
    rooms: selectedRateRoomTypeRooms
  } = (selectedRate === null || selectedRate === void 0 || (_selectedRate$roomTyp = selectedRate.roomTypes) === null || _selectedRate$roomTyp === void 0 ? void 0 : _selectedRate$roomTyp[0]) || {};
  var roomsIndexes = selectedRate === null || selectedRate === void 0 || (_selectedRate$roomTyp2 = selectedRate.roomTypes) === null || _selectedRate$roomTyp2 === void 0 ? void 0 : _selectedRate$roomTyp2.map((roomType, roomTypeIndex) => {
    var rooms = (roomType === null || roomType === void 0 ? void 0 : roomType.rooms) || [];
    if (!isHDPBasket) {
      for (var roomIndex = 0; roomIndex < rooms.length; roomIndex++) {
        var _rooms$roomIndex;
        if (((_rooms$roomIndex = rooms[roomIndex]) === null || _rooms$roomIndex === void 0 ? void 0 : _rooms$roomIndex.pmsRoomType) === (selectedPMSRoomTypes === null || selectedPMSRoomTypes === void 0 ? void 0 : selectedPMSRoomTypes[roomTypeIndex])) {
          return roomIndex;
        }
      }
    }
    return rooms.findIndex(room => room.roomClass === roomClassCode);
  });
  var reservationRoomTypes = !isSilentFeatureFlagEnabled && selectedPMSRoomTypes !== null && selectedPMSRoomTypes !== void 0 && selectedPMSRoomTypes.length ? selectedPMSRoomTypes.map(pmsRoomType => {
    return {
      roomLabelCode: pmsRoomType,
      silentSubstitution: false,
      adults: 0,
      children: 0,
      pmsRoomType: ''
    };
  }) : selectedRate === null || selectedRate === void 0 || (_selectedRate$roomTyp3 = selectedRate.roomTypes) === null || _selectedRate$roomTyp3 === void 0 ? void 0 : _selectedRate$roomTyp3.map((roomType, roomTypeIndex) => {
    var _ref2, _roomType$rooms$rooms;
    var {
      pmsRoomType,
      silentSubstitution
    } = (roomType === null || roomType === void 0 ? void 0 : roomType.rooms[roomsIndexes === null || roomsIndexes === void 0 ? void 0 : roomsIndexes[roomTypeIndex]]) || {};
    var shouldSilentSubstitute = !!isSilentFeatureFlagEnabled && silentSubstitution;
    return {
      roomLabelCode: shouldSilentSubstitute ? (_ref2 = roomsLabelsForSilentSubst === null || roomsLabelsForSilentSubst === void 0 ? void 0 : roomsLabelsForSilentSubst[roomTypeIndex]) !== null && _ref2 !== void 0 ? _ref2 : '' : (selectedPMSRoomTypes === null || selectedPMSRoomTypes === void 0 ? void 0 : selectedPMSRoomTypes.length) && selectedPMSRoomTypes[roomTypeIndex] || pmsRoomType,
      silentSubstitution: shouldSilentSubstitute,
      adults: roomType === null || roomType === void 0 ? void 0 : roomType.adults,
      children: roomType === null || roomType === void 0 ? void 0 : roomType.children,
      pmsRoomType: roomType === null || roomType === void 0 || (_roomType$rooms$rooms = roomType.rooms[roomsIndexes === null || roomsIndexes === void 0 ? void 0 : roomsIndexes[roomTypeIndex]]) === null || _roomType$rooms$rooms === void 0 ? void 0 : _roomType$rooms$rooms.pmsRoomType
    };
  });
  (0, _react.useEffect)(() => {
    setResRoomTypes && isSilentFeatureFlagEnabled && reservationRoomTypes && setResRoomTypes(reservationRoomTypes);
  }, [selectedPMSRoomTypes]);
  (0, _react.useEffect)(() => {
    setCurrentClassRoomTypes && reservationRoomTypes && setCurrentClassRoomTypes(reservationRoomTypes);
  }, [roomClass]);
  var {
    currencyCode
  } = (selectedRateRoomTypeRooms === null || selectedRateRoomTypeRooms === void 0 || (_selectedRateRoomType = selectedRateRoomTypeRooms[0]) === null || _selectedRateRoomType === void 0 ? void 0 : _selectedRateRoomType.roomPriceBreakdown) || {};
  var dailyPricesPerRoom = selectedRate === null || selectedRate === void 0 || (_selectedRate$roomTyp4 = selectedRate.roomTypes) === null || _selectedRate$roomTyp4 === void 0 ? void 0 : _selectedRate$roomTyp4.map((roomType, roomTypeIndex) => {
    var _roomType$rooms;
    return roomType === null || roomType === void 0 || (_roomType$rooms = roomType.rooms) === null || _roomType$rooms === void 0 || (_roomType$rooms = _roomType$rooms[roomsIndexes === null || roomsIndexes === void 0 ? void 0 : roomsIndexes[roomTypeIndex]]) === null || _roomType$rooms === void 0 || (_roomType$rooms = _roomType$rooms.roomPriceBreakdown) === null || _roomType$rooms === void 0 ? void 0 : _roomType$rooms.dailyPrices;
  });
  var [, setCompanyProfile] = (0, _utils.useLocalStorage)('CompanyProfile', undefined);
  isHDPBasket && saveLabelsForSilentSubstAndRedirect && saveLabelsForSilentSubstAndRedirect(reservationRoomTypes);
  var totalReservationAmount = selectedRate === null || selectedRate === void 0 || (_selectedRate$roomTyp5 = selectedRate.roomTypes) === null || _selectedRate$roomTyp5 === void 0 || (_selectedRate$roomTyp5 = _selectedRate$roomTyp5.map((roomType, roomTypeIndex) => {
    var _roomType$rooms$rooms2;
    return roomType === null || roomType === void 0 || (_roomType$rooms$rooms2 = roomType.rooms[roomsIndexes === null || roomsIndexes === void 0 ? void 0 : roomsIndexes[roomTypeIndex]]) === null || _roomType$rooms$rooms2 === void 0 || (_roomType$rooms$rooms2 = _roomType$rooms$rooms2.roomPriceBreakdown) === null || _roomType$rooms$rooms2 === void 0 ? void 0 : _roomType$rooms$rooms2.totalNetAmount;
  })) === null || _selectedRate$roomTyp5 === void 0 ? void 0 : _selectedRate$roomTyp5.reduce((sum, pricePerRoomType) => sum + pricePerRoomType, 0);
  var reservations = selectedRate === null || selectedRate === void 0 || (_selectedRate$roomTyp6 = selectedRate.roomTypes) === null || _selectedRate$roomTyp6 === void 0 ? void 0 : _selectedRate$roomTyp6.map((roomType, roomTypeIndex) => {
    var _roomType$rooms2, _roomType$rooms3, _roomType$rooms4, _roomType$rooms5, _roomType$rooms$rooms3, _roomType$rooms6, _roomType$rooms$rooms4, _roomType$rooms7;
    return {
      hotelId,
      arrival,
      departure,
      adultsNumber: roomType === null || roomType === void 0 ? void 0 : roomType.adults,
      childrenNumber: roomType === null || roomType === void 0 ? void 0 : roomType.children,
      cotRequired: selectedPMSRoomTypes !== null && selectedPMSRoomTypes !== void 0 && selectedPMSRoomTypes.length ? roomType === null || roomType === void 0 || (_roomType$rooms2 = roomType.rooms) === null || _roomType$rooms2 === void 0 || (_roomType$rooms2 = _roomType$rooms2.find(room => (room === null || room === void 0 ? void 0 : room.pmsRoomType) === (selectedPMSRoomTypes === null || selectedPMSRoomTypes === void 0 ? void 0 : selectedPMSRoomTypes[roomTypeIndex]))) === null || _roomType$rooms2 === void 0 ? void 0 : _roomType$rooms2.cotAvailable : roomType === null || roomType === void 0 || (_roomType$rooms3 = roomType.rooms) === null || _roomType$rooms3 === void 0 || (_roomType$rooms3 = _roomType$rooms3[0]) === null || _roomType$rooms3 === void 0 ? void 0 : _roomType$rooms3.cotAvailable,
      roomRates: {
        ratePlanCode,
        pmsRoomType: selectedPMSRoomTypes !== null && selectedPMSRoomTypes !== void 0 && selectedPMSRoomTypes.length ? selectedPMSRoomTypes === null || selectedPMSRoomTypes === void 0 ? void 0 : selectedPMSRoomTypes[roomTypeIndex] : roomType === null || roomType === void 0 || (_roomType$rooms4 = roomType.rooms) === null || _roomType$rooms4 === void 0 || (_roomType$rooms4 = _roomType$rooms4[roomsIndexes === null || roomsIndexes === void 0 ? void 0 : roomsIndexes[roomTypeIndex]]) === null || _roomType$rooms4 === void 0 ? void 0 : _roomType$rooms4.pmsRoomType,
        specialRequests: selectedSpecialRequests !== null && selectedSpecialRequests !== void 0 && selectedSpecialRequests.length ? selectedSpecialRequests === null || selectedSpecialRequests === void 0 ? void 0 : selectedSpecialRequests[roomTypeIndex] : roomType === null || roomType === void 0 || (_roomType$rooms5 = roomType.rooms) === null || _roomType$rooms5 === void 0 || (_roomType$rooms5 = _roomType$rooms5[roomsIndexes === null || roomsIndexes === void 0 ? void 0 : roomsIndexes[roomTypeIndex]]) === null || _roomType$rooms5 === void 0 ? void 0 : _roomType$rooms5.specialRequests,
        startDate: arrival,
        endDate: departure,
        promotionCode: selectedRate.promotionCode
      },
      reservationPackages: [{
        startDate: arrival,
        endDate: departure,
        quantity: numberOfNights,
        packageCode: (_roomType$rooms$rooms3 = roomType === null || roomType === void 0 || (_roomType$rooms6 = roomType.rooms) === null || _roomType$rooms6 === void 0 || (_roomType$rooms6 = _roomType$rooms6[roomsIndexes === null || roomsIndexes === void 0 ? void 0 : roomsIndexes[roomTypeIndex]]) === null || _roomType$rooms6 === void 0 || (_roomType$rooms6 = _roomType$rooms6.roomPriceBreakdown) === null || _roomType$rooms6 === void 0 ? void 0 : _roomType$rooms6.packageCode) !== null && _roomType$rooms$rooms3 !== void 0 ? _roomType$rooms$rooms3 : '',
        unitPrice: (_roomType$rooms$rooms4 = roomType === null || roomType === void 0 || (_roomType$rooms7 = roomType.rooms) === null || _roomType$rooms7 === void 0 || (_roomType$rooms7 = _roomType$rooms7[roomsIndexes === null || roomsIndexes === void 0 ? void 0 : roomsIndexes[roomTypeIndex]]) === null || _roomType$rooms7 === void 0 || (_roomType$rooms7 = _roomType$rooms7.roomPriceBreakdown) === null || _roomType$rooms7 === void 0 ? void 0 : _roomType$rooms7.packageAmount) !== null && _roomType$rooms$rooms4 !== void 0 ? _roomType$rooms$rooms4 : 0
      }]
    };
  });
  return (0, _jsxRuntime.jsx)(_Basket.default, {
    variant,
    channel,
    roomClassIndexFromSelectedRate,
    roomCodes: reservationRoomTypes,
    roomClass,
    isCityTaxExempt,
    isLastFewRooms,
    isHDPBasket,
    hasAccessibleRoom,
    hasTwinRoomChoice,
    shouldDisplayMobileBasket,
    bookRsvIsLoading,
    bookRsvIsError,
    bookRsvError,
    onBookReservation,
    hotelId,
    arrival,
    departure,
    ratePlanCode,
    numberOfUnits,
    currencyCode,
    numberOfNights,
    totalReservationAmount,
    dailyPricesPerRoom,
    isLessThanLg,
    rateName,
    roomTypeInformationResponse,
    brand,
    isDisabledContinueBtn,
    twinroomSelections,
    companyData
  });
  function setScrollableAncillariesTabsCookie() {
    setCookieForABTesting(_api.ANCILLARIES_TABS.configName, _api.ANCILLARIES_TABS.cookieName, _api.ANCILLARIES_TABS.expiryInMinutes, channel);
  }
  function setAccompanyingGuestCookie() {
    setCookieForABTesting(_api.GDP_ACCOMPANYING_GUEST_DETAILS.configName, _api.GDP_ACCOMPANYING_GUEST_DETAILS.cookieName, _api.GDP_ACCOMPANYING_GUEST_DETAILS.expiryInMinutes, channel);
  }
  function setAdditionalInfoCookie() {
    setCookieForABTesting(_api.GDP_DIGI_REG_ADDITIONAL_INFO.configName, _api.GDP_DIGI_REG_ADDITIONAL_INFO.cookieName, _api.GDP_DIGI_REG_ADDITIONAL_INFO.expiryInMinutes, channel);
  }
  function onBookReservation() {
    setScrollableAncillariesTabsCookie();
    setAccompanyingGuestCookie();
    setAdditionalInfoCookie();
    var bookingChannel = {
      channel,
      subchannel: 'WEB',
      language: language === null || language === void 0 ? void 0 : language.toUpperCase()
    };
    handleBooking(reservations, bookingChannel);
    if (isPrePopulateBillingAddressEnabled) {
      if ((selectedRateCategory === null || selectedRateCategory === void 0 ? void 0 : selectedRateCategory.rateOrder) === '1') {
        var _companyData$companyP, _companyData$companyP2;
        var selectedCompanyData = {
          address: companyData === null || companyData === void 0 || (_companyData$companyP = companyData.companyProfile) === null || _companyData$companyP === void 0 ? void 0 : _companyData$companyP.address,
          name: companyData === null || companyData === void 0 || (_companyData$companyP2 = companyData.companyProfile) === null || _companyData$companyP2 === void 0 ? void 0 : _companyData$companyP2.name
        };
        setCompanyProfile(selectedCompanyData);
      } else {
        localStorage.removeItem('CompanyProfile');
      }
    }
  }
  function setSelectedRatePlanCode() {
    if (selectedRate !== null && selectedRate !== void 0 && selectedRate.cellCode && (selectedRate === null || selectedRate === void 0 ? void 0 : selectedRate.cellCode) === _api.OfferEnum.EMPLOYEE) {
      return _api.OfferEnum.EMPLOYEE_RATE_CODE;
    }
    return selectedRate === null || selectedRate === void 0 ? void 0 : selectedRate.ratePlanCode;
  }
}
function setCookieForABTesting(configName, cookieName, expiry, channel) {
  if (typeof window !== 'undefined') {
    var _window;
    var hasCookie = (0, _utils.getCookie)(cookieName);
    if ((_window = window) !== null && _window !== void 0 && (_window = _window.piConfig) !== null && _window !== void 0 && _window[configName] && !hasCookie && (channel === null || channel === void 0 ? void 0 : channel.toLowerCase()) !== _api.Area.CCUI) {
      var {
        mode
      } = window.piConfig[configName];
      if (mode !== null && mode !== void 0 && mode.length) {
        (0, _utils.setCookie)(cookieName, mode, expiry);
      }
    }
  }
}