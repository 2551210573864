"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = BookingSummaryTotalCost;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _react = require("@chakra-ui/react");
var _utils = require("@whitbread-eos/utils");
var _jsxRuntime = require("react/jsx-runtime");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function BookingSummaryTotalCost(_ref) {
  var {
    currency,
    showVATMessage = true,
    newTotalCost,
    totalCostAmount,
    discount = 0,
    t,
    language,
    prefixDataTestId,
    previousTotalCost,
    isDiscountApplied,
    taxesMessage
  } = _ref;
  var baseDataTestId = (0, _utils.formatDataTestId)(prefixDataTestId, 'TotalCost');
  var showDiscountFields = isDiscountApplied || discount > 0;
  return (0, _jsxRuntime.jsx)(_react.Flex, _objectSpread(_objectSpread({}, detailsWrapperStyle), {}, {
    "data-testid": (0, _utils.formatDataTestId)(baseDataTestId, 'Wrapper'),
    children: (0, _jsxRuntime.jsxs)(_react.Box, {
      children: [showDiscountFields ? (0, _jsxRuntime.jsxs)(_react.Box, {
        children: [(0, _jsxRuntime.jsxs)(_react.Text, _objectSpread(_objectSpread({
          as: "p"
        }, discountSectionStyles.title), {}, {
          "data-testid": (0, _utils.formatDataTestId)(baseDataTestId, 'DiscountName'),
          children: ["".concat(t('ccui.payment.confirmBooking.discount.title'), " "), (0, _jsxRuntime.jsx)(_react.Text, {
            as: "span",
            fontWeight: 700,
            "data-testid": (0, _utils.formatDataTestId)(baseDataTestId, 'DiscountPrice'),
            children: (0, _utils.formatPrice)((0, _utils.formatCurrency)(currency), discount.toFixed(2), language)
          })]
        })), (0, _jsxRuntime.jsxs)(_react.Flex, {
          children: [(0, _jsxRuntime.jsx)(_react.Text, _objectSpread(_objectSpread({}, infoTextStyle), {}, {
            as: "s",
            "data-testid": (0, _utils.formatDataTestId)(baseDataTestId, 'PreviousTotalCostName'),
            children: t('ccui.payment.confirmBooking.discount.previousTotalCostMessage')
          })), (0, _jsxRuntime.jsx)(_react.Spacer, {}), (0, _jsxRuntime.jsx)(_react.Text, _objectSpread(_objectSpread({
            as: "s"
          }, previousDiscountAmount), {}, {
            "data-testid": (0, _utils.formatDataTestId)(baseDataTestId, 'PreviousTotalCostPrice'),
            children: (0, _utils.formatPrice)((0, _utils.formatCurrency)(currency), previousTotalCost === null || previousTotalCost === void 0 ? void 0 : previousTotalCost.toFixed(2), language)
          }))]
        })]
      }) : null, showDiscountFields ? (0, _jsxRuntime.jsx)(_react.Box, {
        mt: "4",
        children: (0, _jsxRuntime.jsx)(_react.Heading, _objectSpread(_objectSpread({}, totalCostSectionStyles.title), {}, {
          as: "h6",
          "data-testid": (0, _utils.formatDataTestId)(baseDataTestId, 'NewTotalCostName'),
          children: t('ccui.payment.confirmBooking.newTotalCost')
        }))
      }) : (0, _jsxRuntime.jsx)(_react.Box, {
        children: (0, _jsxRuntime.jsx)(_react.Heading, _objectSpread(_objectSpread({
          as: "h2"
        }, totalCostSectionStyles.amount), {}, {
          "data-testid": (0, _utils.formatDataTestId)(baseDataTestId, 'TotalCostPrice'),
          children: t('booking.summary.totalPrice')
        }))
      }), showDiscountFields ? (0, _jsxRuntime.jsx)(_react.Text, _objectSpread(_objectSpread({}, priceTextStyle), {}, {
        as: "h2",
        "data-testid": (0, _utils.formatDataTestId)(baseDataTestId, 'TotalCostValue'),
        children: (0, _utils.formatPrice)((0, _utils.formatCurrency)(currency), (newTotalCost !== null && newTotalCost !== void 0 ? newTotalCost : 0).toFixed(2), language)
      })) : (0, _jsxRuntime.jsx)(_react.Text, _objectSpread(_objectSpread({
        as: "h2"
      }, priceTextStyle), {}, {
        "data-testid": (0, _utils.formatDataTestId)(baseDataTestId, 'CostAmount'),
        children: (0, _utils.formatPrice)((0, _utils.formatCurrency)(currency), totalCostAmount.toFixed(2), language)
      })), showVATMessage && (0, _jsxRuntime.jsx)(_react.Text, _objectSpread(_objectSpread({}, infoTextStyle), {}, {
        "data-testid": (0, _utils.formatDataTestId)(baseDataTestId, 'VATMessage'),
        children: t('booking.summary.vat-included')
      })), !!(taxesMessage !== null && taxesMessage !== void 0 && taxesMessage.length) && (0, _jsxRuntime.jsx)(_react.Text, _objectSpread(_objectSpread({}, infoTextStyle), {}, {
        "data-testid": (0, _utils.formatDataTestId)(baseDataTestId, 'TaxesMessage'),
        children: taxesMessage
      }))]
    })
  }));
}
var detailsWrapperStyle = {
  direction: 'column'
};
var previousDiscountAmount = {
  lineHeight: '3',
  color: 'darkGrey1',
  fontWeight: 'semibold',
  fontFamily: 'body'
};
var infoTextStyle = {
  fontWeight: 'normal',
  lineHeight: '2',
  color: 'darkGrey1',
  fontSize: 'xs'
};
var priceTextStyle = {
  mt: {
    mobile: '0',
    sm: 'xs'
  },
  fontWeight: 'bold',
  fontSize: '3xl',
  lineHeight: '4',
  color: 'darkGrey1',
  fontFamily: 'body'
};
var discountSectionStyles = {
  title: {
    fontSize: 'xs',
    lineHeight: 2,
    fontWeight: 'normal',
    fontFamily: 'body',
    color: 'darkGrey1'
  },
  previousTotalCost: {
    fontSize: 'md',
    lineHeight: 3,
    fontWeight: 'normal',
    color: 'lightGrey1',
    fontFamily: 'body',
    textDecorationLine: 'line-through'
  }
};
var totalCostSectionStyles = {
  title: {
    fontSize: 'md',
    lineHeight: 3,
    fontWeight: 'semibold',
    color: 'darkGrey1',
    fontFamily: 'body'
  },
  amount: {
    fontSize: 'md',
    lineHeight: 3,
    fontWeight: 'semibold',
    color: 'darkGrey1',
    fontFamily: 'body'
  },
  hotel: {
    fontSize: 'lg',
    lineHeight: 3,
    fontWeight: 'semibold',
    color: 'darkGrey1',
    fontFamily: 'body'
  }
};