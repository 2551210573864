"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = OptionalAuthenticationContainer;
var _reactQuery = require("@tanstack/react-query");
var _api = require("@whitbread-eos/api");
var _utils = require("@whitbread-eos/utils");
var _react = require("react");
var _OptionalAuthentication = _interopRequireDefault(require("./OptionalAuthentication.component"));
var _jsxRuntime = require("react/jsx-runtime");
function OptionalAuthenticationContainer(_ref) {
  var _headerLabels$data3;
  var {
    queryClient,
    showIcon = true,
    isRegisterSelected,
    setRegisterSectionSelected
  } = _ref;
  var {
    country: currentCountry,
    language: currentLang
  } = (0, _utils.useCustomLocale)();
  var headerLabels = (0, _utils.useQueryRequest)(['GetStaticContent', currentLang, currentCountry], _api.GET_STATIC_CONTENT, {
    language: currentLang,
    country: currentCountry,
    site: _api.SITE_LEISURE,
    businessBooker: false
  });
  var [labels, setLabels] = (0, _react.useState)(_api.DEFAULT_LOGIN_LABELS);
  (0, _react.useEffect)(() => {
    var _headerLabels$data;
    if (headerLabels !== null && headerLabels !== void 0 && (_headerLabels$data = headerLabels.data) !== null && _headerLabels$data !== void 0 && (_headerLabels$data = _headerLabels$data.headerInformation) !== null && _headerLabels$data !== void 0 && (_headerLabels$data = _headerLabels$data.content) !== null && _headerLabels$data !== void 0 && _headerLabels$data.authentication) {
      var _headerLabels$data2;
      setLabels(headerLabels === null || headerLabels === void 0 || (_headerLabels$data2 = headerLabels.data) === null || _headerLabels$data2 === void 0 || (_headerLabels$data2 = _headerLabels$data2.headerInformation.content) === null || _headerLabels$data2 === void 0 ? void 0 : _headerLabels$data2.authentication);
    }
  }, [headerLabels === null || headerLabels === void 0 || (_headerLabels$data3 = headerLabels.data) === null || _headerLabels$data3 === void 0 || (_headerLabels$data3 = _headerLabels$data3.headerInformation) === null || _headerLabels$data3 === void 0 || (_headerLabels$data3 = _headerLabels$data3.content) === null || _headerLabels$data3 === void 0 ? void 0 : _headerLabels$data3.authentication]);
  return (0, _jsxRuntime.jsx)(_reactQuery.QueryClientProvider, {
    client: queryClient,
    children: (0, _jsxRuntime.jsx)(_OptionalAuthentication.default, {
      labels: labels,
      showIcon: showIcon,
      isRegisterSelected: isRegisterSelected,
      setRegisterSectionSelected: setRegisterSectionSelected
    })
  });
}