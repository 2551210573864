"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.REGISTER_USER_MARKETING = void 0;
var _taggedTemplateLiteral2 = _interopRequireDefault(require("@babel/runtime/helpers/taggedTemplateLiteral"));
var _graphqlRequest = require("graphql-request");
var _templateObject;
var REGISTER_USER_MARKETING = exports.REGISTER_USER_MARKETING = (0, _graphqlRequest.gql)(_templateObject || (_templateObject = (0, _taggedTemplateLiteral2.default)(["\n  mutation updateMarketingPreferences(\n    $brandCodes: [String!]\n    $optIn: Boolean!\n    $doubleOptIn: Boolean!\n    $language: String\n    $title: String\n    $customerId: String\n    $countryOfResidence: String\n    $firstName: String\n    $lastName: String\n    $channel: String!\n    $journey: String\n    $locale: String\n  ) {\n    updateMarketingPreferences(\n      updateMarketingPreferencesRequest: {\n        brandCodes: $brandCodes\n        optIn: $optIn\n        doubleOptIn: $doubleOptIn\n        customer: {\n          title: $title\n          firstName: $firstName\n          lastName: $lastName\n          customerId: $customerId\n          countryOfResidence: $countryOfResidence\n          language: $language\n        }\n        sourceDetails: { channel: $channel, journey: $journey, locale: $locale }\n      }\n    )\n  }\n"])));