"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = BookingSummaryCard;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _react = require("@chakra-ui/react");
var _atoms = require("@whitbread-eos/atoms");
var _molecules = require("@whitbread-eos/molecules");
var _utils = require("@whitbread-eos/utils");
var _jsxRuntime = require("react/jsx-runtime");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function BookingSummaryCard(_ref) {
  var _totalCost$donations;
  var {
    bookingSummaryData,
    totalCostAmount,
    t,
    language,
    prefixDataTestId,
    currencyCode,
    isDiscountApplied,
    taxesMessage,
    isExtrasDisplayed
  } = _ref;
  var {
    hotelInformation = null,
    totalCost = null,
    rateInformation = null,
    stayDatesInformation = null,
    roomInformation = null,
    updateToFlex = null
  } = bookingSummaryData;
  return (0, _jsxRuntime.jsxs)(_atoms.Card, _objectSpread(_objectSpread({}, bookingSummaryCardStyle), {}, {
    "data-testid": (0, _utils.formatDataTestId)(prefixDataTestId, 'Wrapper'),
    children: [hotelInformation && (0, _jsxRuntime.jsx)(_molecules.BookingSummaryHotelDetailsInfo, _objectSpread({
      prefixDataTestId: prefixDataTestId
    }, hotelInformation)), (totalCost || rateInformation) && (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
      children: [(0, _jsxRuntime.jsx)(_react.Divider, _objectSpread({}, dividerStyles)), (0, _jsxRuntime.jsxs)(_react.Grid, _objectSpread(_objectSpread({}, costsWrapperStyle), {}, {
        children: [totalCost && (0, _jsxRuntime.jsx)(_molecules.BookingSummaryTotalCost, _objectSpread(_objectSpread({}, totalCost), {}, {
          t: t,
          language: language,
          totalCostAmount: totalCostAmount,
          prefixDataTestId: prefixDataTestId,
          currency: currencyCode,
          isDiscountApplied: isDiscountApplied,
          taxesMessage: taxesMessage
        })), totalCost && Boolean(totalCost === null || totalCost === void 0 ? void 0 : totalCost.donations) && language === 'en' && (0, _jsxRuntime.jsxs)(_react.Text, {
          mt: "md",
          fontSize: "xs",
          fontWeight: "normal",
          "data-testid": (0, _utils.formatDataTestId)(prefixDataTestId, 'Donations'),
          children: [t('booking.summary.donationTextStart'), ' ', (0, _jsxRuntime.jsxs)("strong", {
            children: [(0, _utils.formatPrice)((0, _utils.formatCurrency)(totalCost === null || totalCost === void 0 ? void 0 : totalCost.currency), totalCost === null || totalCost === void 0 || (_totalCost$donations = totalCost.donations) === null || _totalCost$donations === void 0 ? void 0 : _totalCost$donations.toFixed(2), language), ' ', t('booking.summary.donationTextMiddle')]
          }), ' ', t('booking.summary.donationTextEnd')]
        })]
      })), (0, _jsxRuntime.jsx)(_react.Grid, {
        children: rateInformation && (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
          children: [(0, _jsxRuntime.jsx)(_react.Divider, _objectSpread({}, dividerStyles)), (0, _jsxRuntime.jsxs)(_react.Grid, _objectSpread(_objectSpread({}, rateWrapperStyle), {}, {
            children: [(0, _jsxRuntime.jsx)(_molecules.BookingSummaryRateInformation, _objectSpread({
              prefixDataTestId: prefixDataTestId,
              t: t,
              brand: hotelInformation === null || hotelInformation === void 0 ? void 0 : hotelInformation.hotelBrand
            }, rateInformation)), (updateToFlex === null || updateToFlex === void 0 ? void 0 : updateToFlex.showUpgradeToFlex) && (0, _jsxRuntime.jsx)(_molecules.BookingSummaryUpgradeToFlex, _objectSpread(_objectSpread({}, updateToFlex), {}, {
              t: t
            }))]
          }))]
        })
      }), stayDatesInformation && (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
        children: [(0, _jsxRuntime.jsx)(_react.Divider, _objectSpread({}, dividerStyles)), (0, _jsxRuntime.jsx)(_molecules.BookingSummaryStayDatesInformation, _objectSpread({
          language: language,
          prefixDataTestId: prefixDataTestId,
          t: t
        }, stayDatesInformation))]
      }), roomInformation && (0, _jsxRuntime.jsx)(_molecules.BookingSummaryRoomInformation, {
        t: t,
        roomInformation: roomInformation,
        prefixDataTestId: prefixDataTestId,
        isExtrasDisplayed: isExtrasDisplayed
      }), bookingSummaryData.showAutocompleteMealsNotification && (0, _jsxRuntime.jsx)(_react.Box, {
        mt: "lg",
        "data-testid": (0, _utils.formatDataTestId)(prefixDataTestId, 'PreselectedMealNotification'),
        children: (0, _jsxRuntime.jsx)(_atoms.Notification, {
          status: "info",
          description: t('upsell.notification.preselectedMeal'),
          variant: "info",
          maxW: "full",
          svg: (0, _jsxRuntime.jsx)(_atoms.Info, {})
        })
      })]
    })]
  }));
}
var bookingSummaryCardStyle = {
  direction: 'column',
  mt: {
    mobile: 'md',
    lg: 0
  },
  padding: 'md',
  py: {
    lg: 'lg'
  }
};
var costsWrapperStyle = {
  gridTemplateColumns: 'auto',
  direction: 'column',
  py: {
    mobile: 'md',
    lg: 'lg'
  }
};
var dividerStyles = {
  borderColor: 'lightGrey4',
  opacity: '1'
};
var rateWrapperStyle = {
  gridTemplateColumns: 'auto',
  py: {
    mobile: 'md',
    lg: 'lg'
  }
};