"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.setCookieWithDefaultDomain = exports.setCookie = exports.getCookie = exports.deleteCookie = void 0;
var getCookie = cookieName => {
  var val = null;
  try {
    var _document, _document$find;
    val = (_document = document) === null || _document === void 0 || (_document = _document.cookie) === null || _document === void 0 || (_document = _document.split('; ')) === null || _document === void 0 || (_document = _document.reverse()) === null || _document === void 0 || (_document$find = _document.find) === null || _document$find === void 0 || (_document$find = _document$find.call(_document, row => {
      var _row$startsWith;
      return row === null || row === void 0 || (_row$startsWith = row.startsWith) === null || _row$startsWith === void 0 ? void 0 : _row$startsWith.call(row, cookieName);
    })) === null || _document$find === void 0 ? void 0 : _document$find.split('=')[1];
  } catch (e) {
    return e;
  }
  return val;
};
exports.getCookie = getCookie;
var setCookie = exports.setCookie = function setCookie(name, value, time) {
  var path = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : '/';
  var overrideDomain = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : 'www.';
  var expires = '';
  if (time) {
    var d = new Date();
    d.setTime(d.getTime() + time * 60 * 1000);
    expires = 'expires='.concat(d.toUTCString());
  }
  var cookiePath = "path=".concat(path);
  var domain = 'domain='.concat(window.location.hostname.replace('www.', overrideDomain));
  document.cookie = "".concat(name, "=").concat(value, ";").concat(expires, ";").concat(cookiePath, ";").concat(domain);
};
var setCookieWithDefaultDomain = (name, value, time) => {
  var cookiePath = "path=/";
  var expires = '';
  if (time) {
    var d = new Date();
    d.setTime(d.getTime() + time * 60 * 1000);
    expires = 'expires='.concat(d.toUTCString());
  }
  document.cookie = "".concat(name, "=").concat(value, ";").concat(expires, ";").concat(cookiePath);
};
exports.setCookieWithDefaultDomain = setCookieWithDefaultDomain;
var deleteCookie = cookieName => {
  var expires = 'expires=Thu, 01 Jan 1970 00:00:01 GMT';
  var path = 'path=/';
  var domain = 'domain='.concat(window.location.hostname);
  document.cookie = "".concat(cookieName, "=;").concat(expires, ";").concat(path, ";").concat(domain);
};
exports.deleteCookie = deleteCookie;