"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = HotelThumbnail;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _react = require("@chakra-ui/react");
var _utils = require("@whitbread-eos/utils");
var _image = _interopRequireDefault(require("next/image"));
var _HotelBrandLogo = _interopRequireDefault(require("../HotelBrandLogo/HotelBrandLogo.component"));
var _jsxRuntime = require("react/jsx-runtime");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function HotelThumbnail(_ref) {
  var {
    imageData,
    brand,
    brandLogos,
    testId,
    styles
  } = _ref;
  return (0, _jsxRuntime.jsxs)(_react.Box, _objectSpread(_objectSpread({
    position: "relative"
  }, styles), {}, {
    "data-testid": testId,
    children: [(0, _jsxRuntime.jsx)(_image.default, {
      src: (0, _utils.formatAssetsUrl)(imageData.imageSrc),
      alt: imageData.imageAlt,
      fill: true,
      style: {
        objectFit: 'fill'
      },
      sizes: "(max-width: 575px) 575px, (min-width: 576px && max-width: 767px) 120px, (min-width: 768px) 188px",
      loader: (0, _utils.isIVMEnabled)() ? _utils.akamaiImageLoader : undefined
    }), (0, _jsxRuntime.jsx)(_HotelBrandLogo.default, {
      logos: brandLogos,
      brand: brand
    })]
  }));
}