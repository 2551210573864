"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = TextStats;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _react = require("@chakra-ui/react");
var _jsxRuntime = require("react/jsx-runtime");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function TextStats(_ref) {
  var {
    stats
  } = _ref;
  var items = stats.map((stat, index) => {
    var showDivider = index !== 0;
    var testId = stat.text.split(' ').join('').toLowerCase();
    return (0, _jsxRuntime.jsxs)(_react.Flex, {
      alignItems: "center",
      "data-testid": "textstats-element-".concat(testId),
      children: [showDivider && (0, _jsxRuntime.jsx)(_react.Box, _objectSpread({
        as: "span"
      }, verticalDividerStyle)), (0, _jsxRuntime.jsxs)(_react.Box, _objectSpread(_objectSpread({
        as: "span"
      }, statTextStyle), {}, {
        children: [stat.count, " ", stat.text]
      }))]
    }, "textStat-".concat(index * Math.random()));
  });
  return (0, _jsxRuntime.jsx)(_react.Flex, {
    direction: "row",
    flexWrap: "wrap",
    "data-testid": "textstats-summary",
    children: items
  });
}
var statTextStyle = {
  fontSize: {
    mobile: 'xs',
    sm: 'md'
  },
  lineHeight: {
    mobile: '2',
    sm: '3'
  },
  color: 'darkGrey1',
  fontWeight: {
    mobile: 'medium',
    sm: 'normal'
  },
  mr: {
    mobile: '0.375rem',
    sm: 'sm'
  }
};
var verticalDividerStyle = {
  mr: {
    mobile: '0.375rem',
    sm: 'sm'
  },
  width: '1px',
  height: '0.75rem',
  backgroundColor: 'darkGrey1'
};