"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _config = _interopRequireDefault(require("next/config"));
var _base = require("../helpers/base64");
var error = log => {
  var {
    publicRuntimeConfig = {}
  } = (0, _config.default)() || {};
  var sendLogsToOpenSearch = (publicRuntimeConfig === null || publicRuntimeConfig === void 0 ? void 0 : publicRuntimeConfig.NEXT_OPENSEARCH_LOGS) === 'true';
  try {
    logOnAppDynamics(log);
    sendLogsToOpenSearch && logOnOpenSearch(log);
  } catch (err) {
    console.log(err);
  }
};
var logOnAppDynamics = log => console.error(log);
var logOnOpenSearch = log => {
  var _publicRuntimeConfig$;
  var {
    publicRuntimeConfig = {}
  } = (0, _config.default)() || {};
  var endpoint = (_publicRuntimeConfig$ = publicRuntimeConfig === null || publicRuntimeConfig === void 0 ? void 0 : publicRuntimeConfig.NEXT_PUBLIC_LOG_ENDPOINT) !== null && _publicRuntimeConfig$ !== void 0 ? _publicRuntimeConfig$ : '';
  if (endpoint) {
    var _navigator;
    (_navigator = navigator) === null || _navigator === void 0 || _navigator.sendBeacon(endpoint, (0, _base.encodeToBase64)(JSON.stringify({
      log
    })));
  }
};
var _default = exports.default = {
  error,
  logOnAppDynamics,
  logOnOpenSearch
};