"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.EckohCountry = void 0;
var EckohCountry = exports.EckohCountry = function (EckohCountry) {
  EckohCountry["GB"] = "GB";
  EckohCountry["IE"] = "IE";
  EckohCountry["DE"] = "DE";
  return EckohCountry;
}({});