"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = HeaderBusinessVariantDefault;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _react = require("@chakra-ui/react");
var _api = require("@whitbread-eos/api");
var _atoms = require("@whitbread-eos/atoms");
var _utils = require("@whitbread-eos/utils");
var _router = require("next/router");
var _react2 = require("react");
var _helpers = require("../../helpers/helpers");
var _HeaderBusiness = require("../HeaderBusiness.style");
var _NavigationMenu = _interopRequireDefault(require("../NavigationMenu"));
var _NavigationMenuMobile = _interopRequireDefault(require("../NavigationMenuMobile"));
var _jsxRuntime = require("react/jsx-runtime");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function HeaderBusinessVariantDefault(_ref) {
  var _content$authenticati, _content$menu, _content$header;
  var {
    BBHeaderInfo
  } = _ref;
  var {
    content,
    config
  } = BBHeaderInfo;
  var {
    language,
    country
  } = (0, _utils.useCustomLocale)();
  var [origin, setOrigin] = (0, _react2.useState)('');
  var [languagesList, setLanguagesList] = (0, _react2.useState)([]);
  var [isLoading, setIsLoading] = (0, _react2.useState)(true);
  var router = (0, _router.useRouter)();
  var isWindowDefined = typeof window !== 'undefined';
  var baseTestId = 'HeaderBusiness';
  var [mappedNavigationLabels, setMappedNavigationLabels] = (0, _react2.useState)([]);
  var {
    isLoggedIn
  } = (0, _utils.useUserData)();
  var business = (0, _utils.useUserDetails)(true, isLoggedIn) || {};
  var idTokenCookie = (0, _utils.getAuthCookie)();
  var {
    accessLevel,
    cdhCompanyId,
    sessionId,
    cdhEmployeeId
  } = (0, _utils.getLoggedInUserInfo)(idTokenCookie);
  var {
    requestedCompany
  } = (0, _utils.useCompanyDetails)(cdhCompanyId, sessionId, cdhEmployeeId, isLoggedIn) || {};
  var logoutLabel = content === null || content === void 0 || (_content$authenticati = content.authentication) === null || _content$authenticati === void 0 ? void 0 : _content$authenticati.logoutButton;
  var isLogoutButton = title => title === logoutLabel;
  var homeBB = "".concat(origin, "/").concat(country, "/").concat(language, "/business-booker/home.html");
  var tickIconSrc = content === null || content === void 0 || (_content$menu = content.menu) === null || _content$menu === void 0 ? void 0 : _content$menu.tick;
  var tickIcon = (0, _jsxRuntime.jsx)(_atoms.Icon, {
    src: (0, _utils.formatAssetsUrl)(tickIconSrc),
    color: "var(--chakra-colors-primary)"
  });
  var isLanguageListLoaded = !isLoading && !!languagesList;
  var countries = content === null || content === void 0 ? void 0 : content.countries;
  (0, _react2.useEffect)(() => {
    setOrigin(window.location.origin);
  }, [isWindowDefined]);
  (0, _react2.useEffect)(() => {
    var handleMessages = message => {
      if ((message === null || message === void 0 ? void 0 : message.origin) === (0, _utils.getSecureTwoURL)()) {
        var _message$data;
        var action = typeof (message === null || message === void 0 ? void 0 : message.data) === 'string' ? message.data : (_message$data = message.data) === null || _message$data === void 0 ? void 0 : _message$data.action;
        if (action === 'userLoggedOut') {
          router.push(homeBB);
        }
      }
    };
    window.addEventListener('message', handleMessages);
    return () => {
      window.removeEventListener('message', handleMessages);
    };
  }, [homeBB, router]);
  (0, _react2.useEffect)(() => {
    if (!content || !config || !(0, _utils.isStringValid)(idTokenCookie) || !requestedCompany || !business) {
      return;
    }
    var [businessAccountOptions] = content.subNav.filter(item => item.title === _api.BB_MENU_IDS.BUSINESS_ACCOUNT);
    var businessAccountMenu = language !== 'de' ? {
      navOptions: businessAccountOptions === null || businessAccountOptions === void 0 ? void 0 : businessAccountOptions.navOptions.map(navOption => {
        if (!navOption.title.includes(_api.BB_MENU_IDS.ACCOUNT_DASHBOARD)) {
          return navOption;
        } else if (navOption.title.includes(_api.BB_MENU_IDS.ACCOUNT_DASHBOARD) && business !== null && business !== void 0 && business.tethered) {
          return {
            title: navOption.title,
            url: navOption.url,
            superRole: true
          };
        }
      }).filter(navOption => navOption),
      title: businessAccountOptions === null || businessAccountOptions === void 0 ? void 0 : businessAccountOptions.title
    } : null;
    var mappedCompanyMenu = {
      navOptions: [...config.authentication.accountLinks, ...config.authentication.business.businessAccountLinks.map(item => _objectSpread(_objectSpread({}, item), {}, {
        superRole: true
      })), {
        title: logoutLabel
      }],
      title: _api.BB_MENU_IDS.COMPANY_DETAILS
    };
    if (!mappedNavigationLabels.length) {
      var _content$menu2, _content$subNav, _requestedCompany$com;
      var navItems = [{
        navTitle: content === null || content === void 0 || (_content$menu2 = content.menu) === null || _content$menu2 === void 0 ? void 0 : _content$menu2.business,
        subNav: content === null || content === void 0 || (_content$subNav = content.subNav) === null || _content$subNav === void 0 ? void 0 : _content$subNav.filter(item => item.title === _api.BB_MENU_IDS.BUSINESS_CUSTOMERS),
        id: _api.BB_MENU_IDS.ABOUT
      }, {
        navTitle: businessAccountMenu === null || businessAccountMenu === void 0 ? void 0 : businessAccountMenu.title,
        subNav: (0, _utils.superRoleFilter)(accessLevel, [businessAccountMenu]),
        id: _api.BB_MENU_IDS.BUSINESS
      }, {
        navTitle: (requestedCompany === null || requestedCompany === void 0 || (_requestedCompany$com = requestedCompany.companyDetails) === null || _requestedCompany$com === void 0 ? void 0 : _requestedCompany$com.companyName) || '',
        subNav: (0, _utils.superRoleFilter)(accessLevel, [mappedCompanyMenu]),
        id: _api.BB_MENU_IDS.COMPANY
      }];
      setMappedNavigationLabels((0, _utils.langFilter)(language, navItems));
    }
  }, [accessLevel, content, config, idTokenCookie, requestedCompany, language, logoutLabel, isLoggedIn, mappedNavigationLabels, business]);
  (0, _react2.useEffect)(() => {
    if ((countries === null || countries === void 0 ? void 0 : countries.length) > 0) {
      setLanguagesList((0, _helpers.getListOfLanguagesForSwitcher)(countries));
      setIsLoading(false);
    }
  }, [countries]);
  var genericLabels = {
    language: content.menu.language,
    mobileMenuButton: content.menu.mobileMenuButton
  };
  return (0, _jsxRuntime.jsx)(_react.Box, _objectSpread(_objectSpread({}, _HeaderBusiness.headerWrapperStyles), {}, {
    "data-testid": baseTestId,
    children: (0, _jsxRuntime.jsx)(_react.Container, _objectSpread(_objectSpread({}, (0, _HeaderBusiness.containerWrapperStyles)(language)), {}, {
      children: (0, _jsxRuntime.jsxs)(_react.Grid, _objectSpread(_objectSpread({}, _HeaderBusiness.contentStyles), {}, {
        sx: {
          '@media print': {
            py: 'sm'
          }
        },
        children: [(0, _jsxRuntime.jsx)(_react.Flex, _objectSpread(_objectSpread({}, _HeaderBusiness.containerLogoStyle), {}, {
          "data-testid": "logo-container",
          children: (0, _jsxRuntime.jsx)(_atoms.Logo, {
            href: homeBB,
            src: (0, _utils.formatAssetsUrl)(content === null || content === void 0 || (_content$header = content.header) === null || _content$header === void 0 ? void 0 : _content$header.image),
            variant: 'pi-icon'
          })
        })), (0, _jsxRuntime.jsx)(_react.Grid, {
          templateColumns: "1fr auto",
          display: {
            mobile: 'none',
            lg: 'grid'
          },
          sx: {
            '@media print': {
              display: 'none'
            }
          },
          children: isLanguageListLoaded && (0, _jsxRuntime.jsx)(_NavigationMenu.default, {
            navigationLabels: mappedNavigationLabels,
            languageMenuLabels: languagesList,
            language: language,
            tickIcon: tickIcon,
            dataTestId: baseTestId,
            isLogoutButton: isLogoutButton
          })
        }), (0, _jsxRuntime.jsx)(_react.Grid, {
          display: {
            mobile: 'grid',
            lg: 'none'
          },
          justifyContent: 'end',
          children: isLanguageListLoaded && (0, _jsxRuntime.jsx)(_NavigationMenuMobile.default, {
            navigationLabels: (0, _utils.formatForMobile)(mappedNavigationLabels),
            languageMenuLabels: languagesList,
            language: language,
            tickIcon: tickIcon,
            isLogoutButton: isLogoutButton,
            genericLabels: genericLabels
          })
        })]
      }))
    }))
  }));
}