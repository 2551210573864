"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
var _roomClass = require("./roomClass.enum");
Object.keys(_roomClass).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _roomClass[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _roomClass[key];
    }
  });
});
var _hotelbrand = require("./hotelbrand.enum");
Object.keys(_hotelbrand).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _hotelbrand[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _hotelbrand[key];
    }
  });
});
var _standardRoomType = require("./standardRoomType.enum");
Object.keys(_standardRoomType).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _standardRoomType[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _standardRoomType[key];
    }
  });
});
var _header = require("./header.enum");
Object.keys(_header).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _header[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _header[key];
    }
  });
});
var _user = require("./user.enum");
Object.keys(_user).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _user[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _user[key];
    }
  });
});
var _cardName = require("./cardName.enum");
Object.keys(_cardName).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _cardName[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _cardName[key];
    }
  });
});
var _cardStatus = require("./cardStatus.enum");
Object.keys(_cardStatus).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _cardStatus[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _cardStatus[key];
    }
  });
});
var _ccuiCardType = require("./ccuiCardType.enum");
Object.keys(_ccuiCardType).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _ccuiCardType[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _ccuiCardType[key];
    }
  });
});
var _piCardType = require("./piCardType.enum");
Object.keys(_piCardType).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _piCardType[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _piCardType[key];
    }
  });
});
var _currency = require("./currency.enum");
Object.keys(_currency).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _currency[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _currency[key];
    }
  });
});
var _eckohCardPresence = require("./eckohCardPresence.enum");
Object.keys(_eckohCardPresence).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _eckohCardPresence[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _eckohCardPresence[key];
    }
  });
});
var _eckohCountry = require("./eckohCountry.enum");
Object.keys(_eckohCountry).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _eckohCountry[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _eckohCountry[key];
    }
  });
});
var _eckohPayMethod = require("./eckohPayMethod.enum");
Object.keys(_eckohPayMethod).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _eckohPayMethod[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _eckohPayMethod[key];
    }
  });
});
var _eckohStatus = require("./eckohStatus.enum");
Object.keys(_eckohStatus).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _eckohStatus[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _eckohStatus[key];
    }
  });
});
var _eckohTimeOfPay = require("./eckohTimeOfPay.enum");
Object.keys(_eckohTimeOfPay).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _eckohTimeOfPay[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _eckohTimeOfPay[key];
    }
  });
});
var _emailConfirmation = require("./emailConfirmation.enum");
Object.keys(_emailConfirmation).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _emailConfirmation[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _emailConfirmation[key];
    }
  });
});
var _isItTrue = require("./isItTrue.enum");
Object.keys(_isItTrue).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _isItTrue[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _isItTrue[key];
    }
  });
});
var _pibaType = require("./pibaType.enum");
Object.keys(_pibaType).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _pibaType[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _pibaType[key];
    }
  });
});
var _typeOfCaller = require("./typeOfCaller.enum");
Object.keys(_typeOfCaller).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _typeOfCaller[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _typeOfCaller[key];
    }
  });
});
var _language = require("./language.enum");
Object.keys(_language).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _language[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _language[key];
    }
  });
});
var _country = require("./country.enum");
Object.keys(_country).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _country[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _country[key];
    }
  });
});
var _shortCountry = require("./shortCountry.enum");
Object.keys(_shortCountry).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _shortCountry[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _shortCountry[key];
    }
  });
});
var _basketStatus = require("./basketStatus.enum");
Object.keys(_basketStatus).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _basketStatus[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _basketStatus[key];
    }
  });
});
var _area = require("./area.enum");
Object.keys(_area).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _area[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _area[key];
    }
  });
});
var _paymentOption = require("./paymentOption.enum");
Object.keys(_paymentOption).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _paymentOption[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _paymentOption[key];
    }
  });
});
var _purposeOfStay = require("./purposeOfStay.enum");
Object.keys(_purposeOfStay).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _purposeOfStay[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _purposeOfStay[key];
    }
  });
});
var _bookingSubChannel = require("./bookingSubChannel.enum");
Object.keys(_bookingSubChannel).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _bookingSubChannel[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _bookingSubChannel[key];
    }
  });
});
var _bookingChannel = require("./bookingChannel.enum");
Object.keys(_bookingChannel).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _bookingChannel[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _bookingChannel[key];
    }
  });
});
var _bookingType = require("./bookingType.enum");
Object.keys(_bookingType).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _bookingType[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _bookingType[key];
    }
  });
});
var _businessCardTypes = require("./businessCardTypes.enum");
Object.keys(_businessCardTypes).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _businessCardTypes[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _businessCardTypes[key];
    }
  });
});
var _paymentSteps = require("./paymentSteps.enum");
Object.keys(_paymentSteps).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _paymentSteps[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _paymentSteps[key];
    }
  });
});
var _guaranteeCode = require("./guaranteeCode.enum");
Object.keys(_guaranteeCode).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _guaranteeCode[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _guaranteeCode[key];
    }
  });
});
var _reservationStatus = require("./reservationStatus.enum");
Object.keys(_reservationStatus).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _reservationStatus[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _reservationStatus[key];
    }
  });
});
var _sourceSystem = require("./sourceSystem.enum");
Object.keys(_sourceSystem).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _sourceSystem[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _sourceSystem[key];
    }
  });
});
var _confirmAmendStatus = require("./confirmAmendStatus.enum");
Object.keys(_confirmAmendStatus).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _confirmAmendStatus[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _confirmAmendStatus[key];
    }
  });
});
var _pageName = require("./pageName.enum");
Object.keys(_pageName).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _pageName[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _pageName[key];
    }
  });
});
var _memoVariant = require("./memoVariant.enum");
Object.keys(_memoVariant).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _memoVariant[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _memoVariant[key];
    }
  });
});
var _offer = require("./offer.enum");
Object.keys(_offer).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _offer[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _offer[key];
    }
  });
});
var _memoType = require("./memoType.enum");
Object.keys(_memoType).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _memoType[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _memoType[key];
    }
  });
});
var _answerType = require("./answerType.enum");
Object.keys(_answerType).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _answerType[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _answerType[key];
    }
  });
});
var _companyReferenceQuestion = require("./companyReferenceQuestion.enum");
Object.keys(_companyReferenceQuestion).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _companyReferenceQuestion[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _companyReferenceQuestion[key];
    }
  });
});
var _questionLocation = require("./questionLocation.enum");
Object.keys(_questionLocation).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _questionLocation[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _questionLocation[key];
    }
  });
});
var _dateFormat = require("./dateFormat.enum");
Object.keys(_dateFormat).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _dateFormat[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _dateFormat[key];
    }
  });
});
var _tripadvisorAwardType = require("./tripadvisorAwardType.enum");
Object.keys(_tripadvisorAwardType).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _tripadvisorAwardType[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _tripadvisorAwardType[key];
    }
  });
});
var _searchAccount = require("./searchAccount");
Object.keys(_searchAccount).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _searchAccount[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _searchAccount[key];
    }
  });
});
var _rateCodes = require("./rateCodes.enum");
Object.keys(_rateCodes).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _rateCodes[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _rateCodes[key];
    }
  });
});
var _allowances = require("./allowances.enum");
Object.keys(_allowances).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _allowances[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _allowances[key];
    }
  });
});
var _extrasSection = require("./extrasSection.enum");
Object.keys(_extrasSection).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _extrasSection[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _extrasSection[key];
    }
  });
});
var _dispatchActionPaypal = require("./dispatchActionPaypal.enum");
Object.keys(_dispatchActionPaypal).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _dispatchActionPaypal[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _dispatchActionPaypal[key];
    }
  });
});
var _hashType = require("./hashType.enum");
Object.keys(_hashType).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _hashType[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _hashType[key];
    }
  });
});