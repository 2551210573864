"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = KeyHotelFactsComponent;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _react = require("@chakra-ui/react");
var _api = require("@whitbread-eos/api");
var _atoms = require("@whitbread-eos/atoms");
var _utils = require("@whitbread-eos/utils");
var _nextI18next = require("next-i18next");
var _react2 = require("react");
var _jsxRuntime = require("react/jsx-runtime");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function KeyHotelFactsComponent(_ref) {
  var {
    facts,
    isLoading,
    isError,
    error,
    channel
  } = _ref;
  var {
    t
  } = (0, _nextI18next.useTranslation)(['common']);
  var [faqItemList, setFaqItemList] = (0, _react2.useState)([]);
  var [isModalOpen, setIsModalOpen] = (0, _react2.useState)(false);
  (0, _react2.useEffect)(() => {
    var factItems = [];
    (facts === null || facts === void 0 ? void 0 : facts.factItems) && (facts === null || facts === void 0 ? void 0 : facts.factItems.length) > 0 && facts.factItems.map(factData => {
      var contentText = (0, _jsxRuntime.jsx)(_react.Text, {
        children: (0, _utils.renderSanitizedHtml)(factData.description)
      });
      factItems.push({
        title: factData.title,
        content: contentText
      });
    });
    setFaqItemList(factItems);
  }, []);
  if (isLoading) {
    return (0, _jsxRuntime.jsx)(_react.Text, {
      children: t('searchresults.list.hotel.loading')
    });
  }
  if (isError) {
    return (0, _jsxRuntime.jsx)(_react.Text, {
      children: error === null || error === void 0 ? void 0 : error.message
    });
  }
  function toggleModal() {
    setIsModalOpen(!isModalOpen);
  }
  var isFactObjectValid = () => {
    var _facts$factItems;
    return facts && (facts === null || facts === void 0 || (_facts$factItems = facts.factItems) === null || _facts$factItems === void 0 ? void 0 : _facts$factItems.length) > 0;
  };
  var isValidForRender = () => {
    return channel === _api.Channel.Ccui && isFactObjectValid();
  };
  return isValidForRender() ? (0, _jsxRuntime.jsxs)(_react.Box, {
    "data-testid": "keyHotelFacts",
    children: [(0, _jsxRuntime.jsx)(_atoms.Notification, _objectSpread(_objectSpread({
      title: t('hoteldetails.facts.title'),
      variant: "info",
      status: "info",
      svg: (0, _jsxRuntime.jsx)(_atoms.Info, {}),
      prefixDataTestId: "key-hotel-facts"
    }, notificationStyles), {}, {
      description: (0, _jsxRuntime.jsx)(_react.Text, _objectSpread(_objectSpread({}, seeDetailsLinkStyles), {}, {
        "data-testid": "hdp_hotelKeyFactsLink",
        onClick: toggleModal,
        children: t('hoteldetails.facts.seeNow')
      }))
    })), (0, _jsxRuntime.jsx)(_atoms.ModalVariants, {
      onClose: toggleModal,
      isOpen: isModalOpen,
      variant: "info",
      children: (0, _jsxRuntime.jsx)(_react.Box, _objectSpread(_objectSpread({}, modalStyles), {}, {
        children: (0, _jsxRuntime.jsx)(_react.Box, _objectSpread(_objectSpread({
          maxW: "full"
        }, containerStyles), {}, {
          children: (0, _jsxRuntime.jsx)(_react.Flex, _objectSpread(_objectSpread({}, accordianWrapperStyles), {}, {
            children: (0, _jsxRuntime.jsx)(_react.Box, _objectSpread(_objectSpread({
              "data-testid": "hdp-key-hotel-fact-list"
            }, factListBoxStyles), {}, {
              children: (0, _jsxRuntime.jsx)(_atoms.Accordion, {
                allowMultiple: false,
                accordionItems: faqItemList,
                bgColor: "var(--chakra-colors-baseWhite)"
              })
            }))
          }))
        }))
      }))
    })]
  }) : null;
}
var factListBoxStyles = {
  minWidth: {
    base: '100%',
    md: '90%'
  },
  m: {
    base: '4',
    sm: '1'
  }
};
var accordianWrapperStyles = {
  justifyContent: 'center',
  alignItems: 'center'
};
var containerStyles = {
  mb: {
    base: 'lg',
    sm: '2xl'
  }
};
var modalStyles = {
  w: '50.125rem',
  px: 'md',
  pb: 'lg'
};
var notificationStyles = {
  maxW: {
    base: 'full',
    md: 'full'
  },
  lineHeight: '2',
  backgroundColor: 'baseWhite',
  border: 'none'
};
var seeDetailsLinkStyles = {
  display: 'inline-block',
  fontSize: 'sm',
  lineHeight: '2',
  color: 'btnSecondaryEnabled',
  _hover: {
    cursor: 'pointer'
  }
};