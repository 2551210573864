"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = SearchSummary;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _react = require("@chakra-ui/react");
var _react2 = require("react");
var _icons = require("../../assets/icons");
var _Icon = _interopRequireDefault(require("../../components/Icon"));
var _jsxRuntime = require("react/jsx-runtime");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function SearchSummary(_ref) {
  var {
    handleEdit,
    isSummaryActive,
    location,
    dateInterval,
    roomSummary,
    numberOfNightsSummary,
    promotionCategorySummary,
    contractRateSummary,
    editText,
    isLessThanMd,
    isLessThanSm,
    isLessThanLg
  } = _ref;
  var showCanEdit = (0, _react2.useMemo)(() => isLessThanMd || isLessThanSm, [isLessThanMd, isLessThanSm]);
  var hoverObject = showCanEdit ? {
    _hover: {}
  } : {};
  return isSummaryActive ? (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsxs)(_react.Box, _objectSpread(_objectSpread(_objectSpread({
      role: !showCanEdit ? 'group' : '',
      "data-testid": "search-summary-container"
    }, styledSearchSummaryContainer), hoverObject), {}, {
      onClick: e => {
        e.preventDefault();
        if (!showCanEdit) {
          handleEdit();
        }
      },
      children: [(0, _jsxRuntime.jsx)(_react.Box, _objectSpread(_objectSpread({}, styledSearchIcon), {}, {
        children: (0, _jsxRuntime.jsx)(_Icon.default, {
          svg: (0, _jsxRuntime.jsx)(_icons.SearchIcon, {
            "data-testid": "search-icon"
          })
        })
      })), location && (0, _jsxRuntime.jsxs)(_react.Box, _objectSpread(_objectSpread(_objectSpread({}, styledSummaryInfoBox), styledLocation), {}, {
        children: [(0, _jsxRuntime.jsx)(_react.Text, _objectSpread(_objectSpread({}, styledText), {}, {
          "data-testid": "search-summary-location",
          children: location
        })), (isLessThanSm || isLessThanLg && !roomSummary) && (0, _jsxRuntime.jsx)(_react.Text, _objectSpread(_objectSpread({}, styledEditText), {}, {
          "data-testid": "search-summary-edit-link",
          onClick: e => {
            e.preventDefault();
            handleEdit();
          },
          children: editText
        }))]
      })), !isLessThanSm ? handleSummaryInfoBoxMobile({
        dateInterval,
        roomSummary,
        numberOfNightsSummary,
        promotionCategorySummary,
        contractRateSummary,
        isLessThanLg,
        handleEdit,
        editText
      }) : dateInterval && roomSummary && (0, _jsxRuntime.jsx)(_react.Box, _objectSpread(_objectSpread({}, _objectSpread(_objectSpread({}, styledSummaryInfoBox), styledSummaryInfoBoxMobile)), {}, {
        "data-testid": "search-summary-date-rooms",
        children: "".concat(dateInterval, ", ").concat(roomSummary)
      }))]
    })), (0, _jsxRuntime.jsx)(_react.Divider, {
      marginTop: "-1px"
    })]
  }) : null;
}
function handleSummaryInfoBoxMobile(_ref2) {
  var {
    dateInterval,
    roomSummary,
    numberOfNightsSummary,
    promotionCategorySummary,
    contractRateSummary,
    isLessThanLg,
    handleEdit,
    editText
  } = _ref2;
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [dateInterval && (0, _jsxRuntime.jsx)(_react.Box, _objectSpread(_objectSpread(_objectSpread({
      "data-testid": "search-summary-dates"
    }, styledSummaryInfoBox), styledSummaryInfoBoxMobile), {}, {
      children: (0, _jsxRuntime.jsx)(_react.Text, _objectSpread(_objectSpread({}, styledText), {}, {
        children: dateInterval
      }))
    })), numberOfNightsSummary && (0, _jsxRuntime.jsx)(_react.Box, _objectSpread(_objectSpread(_objectSpread({
      "data-testid": "search-summary-number-of-nights"
    }, styledSummaryInfoBox), styledSummaryInfoBoxMobile), {}, {
      children: (0, _jsxRuntime.jsx)(_react.Text, _objectSpread(_objectSpread({}, styledText), {}, {
        children: numberOfNightsSummary
      }))
    })), roomSummary && (0, _jsxRuntime.jsxs)(_react.Box, _objectSpread(_objectSpread(_objectSpread({
      "data-testid": "search-summary-rooms"
    }, styledSummaryInfoBox), styledSummaryInfoBoxMobile), {}, {
      children: [(0, _jsxRuntime.jsx)(_react.Text, _objectSpread(_objectSpread({}, styledText), {}, {
        children: roomSummary
      })), isLessThanLg && (0, _jsxRuntime.jsx)(_react.Text, _objectSpread(_objectSpread({}, styledEditText), {}, {
        onClick: e => {
          e.preventDefault();
          handleEdit();
        },
        children: editText
      }))]
    })), promotionCategorySummary && (0, _jsxRuntime.jsx)(_react.Box, _objectSpread(_objectSpread(_objectSpread({
      "data-testid": "search-summary-promotion-category"
    }, styledSummaryInfoBox), styledSummaryInfoBoxMobile), {}, {
      children: (0, _jsxRuntime.jsx)(_react.Text, _objectSpread(_objectSpread(_objectSpread({}, styledText), styledTextInactive), {}, {
        children: promotionCategorySummary
      }))
    })), contractRateSummary && (0, _jsxRuntime.jsx)(_react.Box, _objectSpread(_objectSpread(_objectSpread({
      "data-testid": "search-summary-contract-rate"
    }, styledSummaryInfoBox), styledSummaryInfoBoxMobile), {}, {
      children: (0, _jsxRuntime.jsx)(_react.Text, _objectSpread(_objectSpread({}, styledText), {}, {
        children: contractRateSummary
      }))
    }))]
  });
}
var styledSearchSummaryContainer = {
  width: '100%',
  display: 'flex',
  flexDirection: {
    mobile: 'initial',
    sm: 'row'
  },
  flexWrap: {
    mobile: 'wrap',
    sm: 'initial'
  },
  padding: {
    lg: '1.5rem 0',
    md: 'var(--chakra-space-md) 0',
    mobile: '1rem 0'
  },
  alignItems: 'center',
  border: '1px solid transparent',
  cursor: 'pointer',
  height: {
    sm: 'var(--chakra-space-4xl)',
    md: 'var(--chakra-space-6xl)'
  },
  _hover: {
    boxShadow: '0px 0px 8px var(--chakra-colors-lightGrey4)',
    padding: '0',
    border: '1px solid var(--chakra-colors-lightGrey4)'
  }
};
var styledSearchIcon = {
  display: 'flex',
  paddingLeft: {
    sm: 'var(--chakra-space-md)',
    mobile: '0'
  },
  _groupHover: {
    padding: {
      lg: 'var(--chakra-space-lg) 0 var(--chakra-space-lg) var(--chakra-space-md)',
      md: 'var(--chakra-space-md) 0 var(--chakra-space-md) var(--chakra-space-md)'
    }
  }
};
var styledLocation = {
  fontWeight: {
    mobile: 'semibold',
    sm: 'normal'
  },
  flex: {
    sm: 'initial',
    mobile: '1 1 calc(100% - 37px)'
  },
  _after: {
    content: '""'
  }
};
var styledSummaryInfoBox = {
  display: 'flex',
  overflow: 'hidden',
  borderWidth: {
    sm: '0 1px 0 0',
    mobile: '0 0 0 0'
  },
  borderColor: 'lightGrey4',
  padding: {
    lg: '0 var(--chakra-space-lg)',
    sm: '0 var(--chakra-space-md)',
    mobile: '0 var(--chakra-space-xmd)'
  },
  _last: {
    borderRight: 'none',
    flex: {
      md: '1',
      mobile: 'initial'
    }
  },
  _groupHover: {
    padding: {
      lg: 'var(--chakra-space-lg)',
      sm: 'var(--chakra-space-md)'
    },
    borderWidth: '0 1px 0 0',
    borderColor: 'lightGrey4',
    _last: {
      borderRight: '0'
    }
  }
};
var styledSummaryInfoBoxMobile = {
  _notLast: {
    _after: {
      content: {
        sm: '""',
        mobile: '","'
      }
    }
  },
  padding: {
    mobile: '0.25rem 0.125rem',
    lg: '0 var(--chakra-space-lg)',
    sm: '0 var(--chakra-space-md)'
  }
};
var styledEditText = {
  display: 'inline-flex',
  marginLeft: 'var(--chakra-space-md)',
  textDecoration: 'underline',
  color: 'darkGrey3',
  fontWeight: 'normal',
  fontSize: 'sm'
};
var styledText = {
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  padding: '0'
};
var styledTextInactive = {
  color: 'lightGrey4'
};