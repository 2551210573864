"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = ColorModeSwitchContainer;
var _ColorModeSwitcher = _interopRequireDefault(require("./ColorModeSwitcher.component"));
var _jsxRuntime = require("react/jsx-runtime");
function ColorModeSwitchContainer() {
  return (0, _jsxRuntime.jsx)(_ColorModeSwitcher.default, {});
}