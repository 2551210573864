"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "RoomFacilitiesModal", {
  enumerable: true,
  get: function get() {
    return _RoomFacilitiesModal.default;
  }
});
exports.default = void 0;
var _RoomFacilitiesList = _interopRequireDefault(require("./RoomFacilitiesList"));
var _RoomFacilitiesModal = _interopRequireDefault(require("./RoomFacilitiesModal"));
var _default = exports.default = _RoomFacilitiesList.default;