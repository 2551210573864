"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = FormLeadGuest;
exports.formErrorStyles = exports.errorTextStyles = exports.errorMessageStyles = void 0;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _react = require("@chakra-ui/react");
var _api = require("@whitbread-eos/api");
var _atoms = require("@whitbread-eos/atoms");
var _utils = require("@whitbread-eos/utils");
var _nextI18next = require("next-i18next");
var _router = require("next/router");
var _react2 = require("react");
var _reactHookForm = require("react-hook-form");
var _LeadGuestAdditionalFields = _interopRequireDefault(require("../AdditionalInformation/LeadGuestAdditionalFields"));
var _common = require("../AdditionalInformation/common");
var _CountriesDropdown = _interopRequireDefault(require("../Countries/CountriesDropdown"));
var _GuestInputs = _interopRequireDefault(require("../GuestInputs"));
var _PostcodeAddress = _interopRequireDefault(require("../PostcodeAddress/PostcodeAddress.component"));
var _jsxRuntime = require("react/jsx-runtime");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function FormLeadGuest(_ref) {
  var _formField$props, _errors$formField$nam, _ref2, _router$query, _formField$bkndData$r;
  var {
    control,
    formField,
    errors,
    getValues,
    handleSetValue,
    handleResetField
  } = _ref;
  var errorStyles = (_formField$props = formField.props) !== null && _formField$props !== void 0 && _formField$props.useTooltip && !!(errors !== null && errors !== void 0 && (_errors$formField$nam = errors[formField.name]) !== null && _errors$formField$nam !== void 0 && _errors$formField$nam.message) ? tooltipErrorStyles : {};
  var {
    showIcon,
    isLocationRequired,
    setIsLocationRequired,
    currentLang,
    isBookingForSomeoneElse,
    isGermanHotel,
    isMultiRoomRedesignEnabled,
    isSingleRoomRedesignEnabled,
    isAdditionalInformationEnabled,
    showCheckInInfo,
    setShowCheckInInfo,
    shouldAskForAccompanyingGuest
  } = formField.props;
  var [leadGuest, setLeadGuest] = (0, _react2.useState)(false);
  var [manualToggle, setManualToggle] = (0, _react2.useState)({});
  var leadGuestValues = (0, _reactHookForm.useWatch)({
    name: 'leadGuest',
    control
  });
  var bookingForSomeoneElseCheckbox = (0, _reactHookForm.useWatch)({
    name: 'bookingForSomeoneElse',
    control
  });
  var personalDetailsFirstname = (0, _reactHookForm.useWatch)({
    name: 'firstName',
    control
  });
  var personalDetailsLastname = (0, _reactHookForm.useWatch)({
    name: 'lastName',
    control
  });
  var {
    t
  } = (0, _nextI18next.useTranslation)();
  var roomNumber = number => {
    return number + 1;
  };
  var consentOptions = [{
    name: t('precheckin.button.yes'),
    value: 'true',
    dataTestID: "".concat((0, _utils.formatDataTestId)(formField.testid, 'consent'), "_consentYes"),
    type: "".concat((0, _utils.formatDataTestId)(formField.testid, 'consent'), "_consentYes")
  }, {
    name: t('precheckin.button.no'),
    value: 'false',
    dataTestID: "".concat((0, _utils.formatDataTestId)(formField.testid, 'consent'), "_consentYes"),
    type: "".concat((0, _utils.formatDataTestId)(formField.testid, 'consent'), "_consentNo")
  }];
  var [tempLeadGuests, setTempLeadGuests] = (0, _react2.useState)({});
  var [guestAddress, setGuestAddress] = (0, _react2.useState)([{
    postalCode: '',
    country: '',
    addressLine1: '',
    addressLine2: '',
    addressLine3: '',
    addressLine4: '',
    companyName: ''
  }]);
  var updateManualToggle = (key, value) => {
    setManualToggle(_objectSpread(_objectSpread({}, manualToggle), {}, {
      [key]: value
    }));
  };
  var formatText = (adultsNumber, childrenNumber) => {
    var childStr = childrenNumber > 1 ? t('account.dashboard.children') : t('account.dashboard.child');
    var adultStr = adultsNumber > 1 ? t('account.dashboard.adults') : t('account.dashboard.adult');
    return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
      children: [adultsNumber >= 1 && " ".concat(adultsNumber, " ").concat(adultStr), childrenNumber >= 1 && " ".concat(childrenNumber, "\n          ").concat(childStr)]
    });
  };
  var updateGuestRoomDetails = (key, title, firstName, lastName) => {
    handleSetValue("leadGuest[".concat(key, "].firstName."), firstName);
    handleResetField("leadGuest[".concat(key, "].firstName"), {
      defaultValue: firstName
    });
    handleSetValue("leadGuest[".concat(key, "].lastName."), lastName);
    handleResetField("leadGuest[".concat(key, "].lastName"), {
      defaultValue: lastName
    });
    handleSetValue("leadGuest[".concat(key, "].title."), title);
    handleResetField("leadGuest[".concat(key, "].title"), {
      defaultValue: title
    });
  };
  var setTemporaryGuestDetailsValues = (roomCount, formData) => {
    if (!roomCount) {
      return;
    }
    var updatedState = {};
    for (var _key = 0; _key < roomCount; _key++) {
      var _formData$leadGuest$_, _formData$leadGuest$_2, _formData$leadGuest$_3;
      var tempLeadGuest = {
        title: (formData === null || formData === void 0 || (_formData$leadGuest$_ = formData.leadGuest[_key]) === null || _formData$leadGuest$_ === void 0 ? void 0 : _formData$leadGuest$_.title) || '',
        firstName: (formData === null || formData === void 0 || (_formData$leadGuest$_2 = formData.leadGuest[_key]) === null || _formData$leadGuest$_2 === void 0 ? void 0 : _formData$leadGuest$_2.firstName) || '',
        lastName: (formData === null || formData === void 0 || (_formData$leadGuest$_3 = formData.leadGuest[_key]) === null || _formData$leadGuest$_3 === void 0 ? void 0 : _formData$leadGuest$_3.lastName) || ''
      };
      var isSameAsYourDetails = tempLeadGuest.title === (formData === null || formData === void 0 ? void 0 : formData.title) && tempLeadGuest.firstName === (formData === null || formData === void 0 ? void 0 : formData.firstName) && tempLeadGuest.lastName === (formData === null || formData === void 0 ? void 0 : formData.lastName);
      if (isSameAsYourDetails) {
        continue;
      }
      var leadGuestKey = "key".concat(_key);
      updatedState = _objectSpread(_objectSpread({}, updatedState), {}, {
        [leadGuestKey]: tempLeadGuest
      });
    }
    setTempLeadGuests(_objectSpread(_objectSpread({}, tempLeadGuests), updatedState));
  };
  var isSingleRoomBooking = formField.bkndData.rooms.length === 1;
  var updateOtherLeadGuestDetails = key => {
    formField.bkndData.rooms.map((value2, roomIndex) => {
      if (key !== roomIndex) {
        handleSetValue("leadGuest[".concat(roomIndex, "].stayInThisRoom."), false);
        handleResetField("leadGuest[".concat(roomIndex, "].stayInThisRoom"), {
          defaultValue: false
        });
        var tempLeadGuestKey = "key".concat(roomIndex);
        if (tempLeadGuests[tempLeadGuestKey]) {
          var tempLeadGuest = tempLeadGuests[tempLeadGuestKey];
          updateGuestRoomDetails(roomIndex, (tempLeadGuest === null || tempLeadGuest === void 0 ? void 0 : tempLeadGuest.title) || '', (tempLeadGuest === null || tempLeadGuest === void 0 ? void 0 : tempLeadGuest.firstName) || '', (tempLeadGuest === null || tempLeadGuest === void 0 ? void 0 : tempLeadGuest.lastName) || '');
        }
      }
    });
  };
  var handleStayInThisRoom = key => {
    var formData = getValues();
    setTemporaryGuestDetailsValues(formField.bkndData.rooms.length, formData);
    updateOtherLeadGuestDetails(key);
    var leadGuestKey = "key".concat(key);
    if ((formData === null || formData === void 0 ? void 0 : formData.leadGuest[key]['stayInThisRoom']) === false) {
      if (tempLeadGuests[leadGuestKey]) {
        var tempLeadGuest = tempLeadGuests[leadGuestKey];
        updateGuestRoomDetails(key, (tempLeadGuest === null || tempLeadGuest === void 0 ? void 0 : tempLeadGuest.title) || '', (tempLeadGuest === null || tempLeadGuest === void 0 ? void 0 : tempLeadGuest.firstName) || '', (tempLeadGuest === null || tempLeadGuest === void 0 ? void 0 : tempLeadGuest.lastName) || '');
      }
    } else {
      updateGuestRoomDetails(key, formData === null || formData === void 0 ? void 0 : formData.title, formData === null || formData === void 0 ? void 0 : formData.firstName, formData === null || formData === void 0 ? void 0 : formData.lastName);
    }
  };
  (0, _react2.useEffect)(() => {
    var formData = getValues();
    if (formData !== null && formData !== void 0 && formData.leadGuest) {
      for (var i = 0; i < formData.leadGuest.length; i++) {
        if (formData.leadGuest[i].stayInThisRoom === true) {
          updateGuestRoomDetails(i, formData.title, formData.firstName, formData.lastName);
        }
      }
    }
  }, [personalDetailsFirstname, personalDetailsLastname]);
  (0, _react2.useEffect)(() => {
    guestAddress === null || guestAddress === void 0 || guestAddress.forEach((address, index) => {
      handleSetValue("leadGuest[".concat(index, "].addressLine1"), address === null || address === void 0 ? void 0 : address.addressLine1);
      handleSetValue("leadGuest[".concat(index, "].addressLine2"), address === null || address === void 0 ? void 0 : address.addressLine2);
      handleSetValue("leadGuest[".concat(index, "].addressLine3"), address === null || address === void 0 ? void 0 : address.addressLine3);
      handleSetValue("leadGuest[".concat(index, "].addressLine4"), address === null || address === void 0 ? void 0 : address.addressLine4);
      handleSetValue("leadGuest[".concat(index, "].postcodeAddress"), address === null || address === void 0 ? void 0 : address.postalCode);
      handleSetValue("leadGuest[".concat(index, "].companyName"), address === null || address === void 0 ? void 0 : address.companyName);
      handleSetValue("leadGuest[".concat(index, "].country"), address === null || address === void 0 ? void 0 : address.country);
    });
  }, [guestAddress]);
  var isSilentFeatureFlagEnabled = (0, _utils.useFeatureSwitch)({
    featureSwitchKey: _api.FS_SILENT_SUBSTITUTION
  });
  var router = (0, _router.useRouter)();
  var reservationId = (_ref2 = router === null || router === void 0 || (_router$query = router.query) === null || _router$query === void 0 ? void 0 : _router$query.reservationId) !== null && _ref2 !== void 0 ? _ref2 : '';
  var currentReservationRoomData = isSilentFeatureFlagEnabled ? (0, _utils.getCurrentReservationStorageData)(reservationId) : {};
  var isSingleBookingRedesign = isSingleRoomRedesignEnabled && isSingleRoomBooking;
  var isSingleBookingRedesignForSomeoneElse = isSingleBookingRedesign && isBookingForSomeoneElse;
  var inputStyleFields = isSingleBookingRedesign ? singleBookingStyleFields : inputStyle;
  var inputStyleAddressFields = isSingleBookingRedesignForSomeoneElse && isGermanHotel ? singleBookingStyleFields : inputStyle;
  var boxParentStyleSwitch = isMultiRoomRedesignEnabled && !isSingleRoomBooking ? boxParentStyle : boxParentStyleDefault;
  var messageStyleSwitch = isMultiRoomRedesignEnabled || isSingleRoomBooking ? '' : messageStyles;
  var boxesContainerStyleSwitch = isMultiRoomRedesignEnabled && !isSingleRoomBooking ? boxesContainerStyle : boxesContainerStyleDefault;
  var singleBookingDDStyles = isSingleBookingRedesign ? singleBookingDropdownStyles : dropdownStyle;
  var singleMultiHeadingStyle = isSingleRoomRedesignEnabled || isMultiRoomRedesignEnabled ? heading3 : headingStyle;
  var singleBookingStyles = isSingleRoomBooking && isSingleRoomRedesignEnabled ? borderBottom : '';
  var guestDetailStyles = isSingleBookingRedesign ? singleBookingStyleFields : guestDetailStyle;
  var paddingHeader = isSingleRoomBooking && !isSingleRoomRedesignEnabled || !isSingleRoomBooking && !isMultiRoomRedesignEnabled;
  var additionalFields = key => {
    var _getValues;
    var leadGuest = (_getValues = getValues()) === null || _getValues === void 0 || (_getValues = _getValues.leadGuest) === null || _getValues === void 0 ? void 0 : _getValues[key];
    var nationality = leadGuest === null || leadGuest === void 0 ? void 0 : leadGuest.nationality;
    var consent = leadGuest === null || leadGuest === void 0 ? void 0 : leadGuest.consent;
    return _common.additionalDetailsFieldsBooker.map((_ref3, index) => {
      var _errors$leadGuest;
      var {
        label,
        testId,
        type,
        name
      } = _ref3;
      return (name !== 'passport' || nationality && (nationality === null || nationality === void 0 ? void 0 : nationality.value) !== 'DE') && (0, _jsxRuntime.jsx)(_LeadGuestAdditionalFields.default, {
        type: type,
        name: "leadGuest[".concat(key, "].").concat(name),
        styles: inputStyleFields,
        testId: testId,
        label: label,
        isDisabled: !(leadGuest !== null && leadGuest !== void 0 && leadGuest.stayInThisRoom) && !consent,
        control: control,
        formField: formField,
        getValues: getValues,
        errMsg: errors === null || errors === void 0 || (_errors$leadGuest = errors.leadGuest) === null || _errors$leadGuest === void 0 || (_errors$leadGuest = _errors$leadGuest[key]) === null || _errors$leadGuest === void 0 || (_errors$leadGuest = _errors$leadGuest[name]) === null || _errors$leadGuest === void 0 ? void 0 : _errors$leadGuest.message,
        handleSetValue: handleSetValue,
        fieldToClear: "leadGuest[".concat(key, "].passport")
      }, index);
    });
  };
  var isFieldDisabled = key => {
    var _getValues2;
    var leadGuest = (_getValues2 = getValues()) === null || _getValues2 === void 0 || (_getValues2 = _getValues2.leadGuest) === null || _getValues2 === void 0 ? void 0 : _getValues2[key];
    return !(leadGuest !== null && leadGuest !== void 0 && leadGuest.consent);
  };
  var toggleCheckInInfo = id => () => {
    if (!Object.prototype.hasOwnProperty.call(showCheckInInfo, id)) {
      setShowCheckInInfo(_objectSpread(_objectSpread({}, showCheckInInfo), {}, {
        [id]: true
      }));
    } else {
      setShowCheckInInfo(_objectSpread(_objectSpread({}, showCheckInInfo), {}, {
        [id]: !(showCheckInInfo !== null && showCheckInInfo !== void 0 && showCheckInInfo[id])
      }));
    }
  };
  return (0, _jsxRuntime.jsxs)(_react.Flex, _objectSpread(_objectSpread({
    direction: "column"
  }, _objectSpread(_objectSpread({}, formField.styles), errorStyles)), {}, {
    "data-testid": (0, _utils.formatDataTestId)(formField.testid, 'Container'),
    children: [!isSingleRoomRedesignEnabled && formField.bkndData.rooms.length === 1 && (0, _jsxRuntime.jsx)(_reactHookForm.Controller, {
      name: "bookingForSomeoneElse",
      control: control,
      render: _ref4 => {
        var _formField$props2;
        var {
          field: {
            onChange: _onChange
          }
        } = _ref4;
        return (0, _jsxRuntime.jsx)(_react.Box, {
          "data-testid": (0, _utils.formatDataTestId)(formField.testid, 'Checkbox'),
          children: (0, _jsxRuntime.jsx)(_atoms.Checkbox, _objectSpread(_objectSpread({}, formField.props), {}, {
            onChange: () => {
              _onChange(!leadGuest);
              setLeadGuest(!leadGuest);
            },
            isChecked: bookingForSomeoneElseCheckbox,
            children: (0, _jsxRuntime.jsx)(_react.Text, {
              "data-testid": (0, _utils.formatDataTestId)(formField.testid, 'CheckboxText'),
              children: ((_formField$props2 = formField.props) === null || _formField$props2 === void 0 ? void 0 : _formField$props2.area) === _api.Area.CCUI ? t('ccui.booking.leadGuest.iAmBookingForSomeoneElse') : t('booking.leadGuest.iAmBookingForSomeoneElse')
            })
          }))
        });
      }
    }), !isSingleRoomRedesignEnabled && bookingForSomeoneElseCheckbox && formField.bkndData.rooms.length === 1 && renderContent(), (isSingleRoomRedesignEnabled && isBookingForSomeoneElse || formField.bkndData.rooms.length > 1) && renderContent(), (!isBookingForSomeoneElse && isSingleRoomRedesignEnabled || !bookingForSomeoneElseCheckbox && !isSingleRoomRedesignEnabled) && formField.bkndData.rooms.length === 1 && shouldAskForAccompanyingGuest && ((_formField$bkndData$r = formField.bkndData.rooms) === null || _formField$bkndData$r === void 0 || (_formField$bkndData$r = _formField$bkndData$r[0]) === null || _formField$bkndData$r === void 0 || (_formField$bkndData$r = _formField$bkndData$r.roomStay) === null || _formField$bkndData$r === void 0 ? void 0 : _formField$bkndData$r.adultsNumber) === 2 && renderSingleRoomAccompanyingGuest()]
  }));
  function renderSingleRoomAccompanyingGuest() {
    return (0, _jsxRuntime.jsx)(_react.Box, _objectSpread(_objectSpread({}, singleBookingStyles), {}, {
      children: renderAccompanyingGuest(0)
    }));
  }
  function renderContent() {
    var _formField$props3, _formField$props4;
    var formData = getValues();
    return (0, _jsxRuntime.jsxs)(_react.Box, _objectSpread(_objectSpread({}, singleBookingStyles), {}, {
      children: [(0, _jsxRuntime.jsxs)(_react.Text, _objectSpread(_objectSpread(_objectSpread({}, heading2), paddingHeader && _objectSpread({}, paddingTopBottom)), {}, {
        "data-testid": (0, _utils.formatDataTestId)(formField.testid, 'Header'),
        children: [((_formField$props3 = formField.props) === null || _formField$props3 === void 0 ? void 0 : _formField$props3.area) === _api.Area.CCUI && !isSingleBookingRedesign && t('ccui.booking.leadGuest.labelWhoIsTheLead'), ((_formField$props4 = formField.props) === null || _formField$props4 === void 0 ? void 0 : _formField$props4.area) === _api.Area.PI && !isSingleBookingRedesign && t('booking.leadGuest.labelWhoIsTheLead')]
      })), formField.bkndData.rooms.map((value, key) => {
        var _leadGuestValues$key, _value$roomStay, _currentReservationRo, _value$roomStay2, _value$roomStay3, _value$roomStay4, _leadGuestValues$key2, _value$roomStay5, _errors$leadGuest2, _value$roomStay6, _leadGuestValues$key3, _leadGuestValues$key4, _value$roomStay7, _leadGuestValues$key5;
        return (0, _react2.createElement)(_react.Box, _objectSpread(_objectSpread({}, boxParentStyleSwitch), {}, {
          key: key
        }), formField.bkndData.rooms.length >= 2 && (0, _jsxRuntime.jsxs)(_react.Flex, _objectSpread(_objectSpread({}, boxesContainerStyleSwitch), {}, {
          "data-testid": (0, _utils.formatDataTestId)(formField.testid, "ContainerRoom-".concat(roomNumber(key))),
          mb: leadGuestValues !== null && leadGuestValues !== void 0 && (_leadGuestValues$key = leadGuestValues[key]) !== null && _leadGuestValues$key !== void 0 && _leadGuestValues$key.stayInThisRoom || shouldAskForAccompanyingGuest && !isSingleRoomBooking && (value === null || value === void 0 || (_value$roomStay = value.roomStay) === null || _value$roomStay === void 0 ? void 0 : _value$roomStay.adultsNumber) === 2 ? 0 : 'lg',
          children: [(0, _jsxRuntime.jsxs)(_react.Box, _objectSpread(_objectSpread({}, boxesStyles), {}, {
            children: [(0, _jsxRuntime.jsx)(_react.Text, _objectSpread(_objectSpread({}, singleMultiHeadingStyle), {}, {
              "data-testid": (0, _utils.formatDataTestId)(formField.testid, "HeaderRoom-".concat(roomNumber(key))),
              children: t('booking.leadGuest.ForRoom').replace('[roomNumber]', roomNumber(key))
            })), (0, _jsxRuntime.jsxs)(_react.Text, _objectSpread(_objectSpread({}, textStyle), {}, {
              "data-testid": (0, _utils.formatDataTestId)(formField.testid, "DetailsRoom"),
              children: [(0, _utils.displayStorageSubstitutionLabels)(currentReservationRoomData === null || currentReservationRoomData === void 0 || (_currentReservationRo = currentReservationRoomData.value) === null || _currentReservationRo === void 0 ? void 0 : _currentReservationRo[key], value === null || value === void 0 || (_value$roomStay2 = value.roomStay) === null || _value$roomStay2 === void 0 || (_value$roomStay2 = _value$roomStay2.roomExtraInfo) === null || _value$roomStay2 === void 0 ? void 0 : _value$roomStay2.roomName, isSilentFeatureFlagEnabled), formatText(value === null || value === void 0 || (_value$roomStay3 = value.roomStay) === null || _value$roomStay3 === void 0 ? void 0 : _value$roomStay3.adultsNumber, value === null || value === void 0 || (_value$roomStay4 = value.roomStay) === null || _value$roomStay4 === void 0 ? void 0 : _value$roomStay4.childrenNumber)]
            }))]
          })), (0, _jsxRuntime.jsx)(_react.Box, _objectSpread(_objectSpread({}, boxesStyles), {}, {
            children: (0, _jsxRuntime.jsx)(_reactHookForm.Controller, {
              name: "leadGuest[".concat(key, "][stayInThisRoom]"),
              control: control,
              render: _ref5 => {
                var {
                  field: {
                    onChange
                  }
                } = _ref5;
                return renderCheckboxComponent(key, onChange);
              }
            })
          }))]
        })), (leadGuestValues === null || leadGuestValues === void 0 || (_leadGuestValues$key2 = leadGuestValues[key]) === null || _leadGuestValues$key2 === void 0 ? void 0 : _leadGuestValues$key2.stayInThisRoom) !== true ? (0, _jsxRuntime.jsxs)(_react.Box, _objectSpread(_objectSpread({}, messageStyleSwitch), {}, {
          children: [isSingleBookingRedesign && isBookingForSomeoneElse && isSingleRoomBooking && !isAdditionalInformationEnabled && (0, _jsxRuntime.jsx)(_react.Box, _objectSpread(_objectSpread({}, inputStyleFields), {}, {
            children: (0, _jsxRuntime.jsx)(_react.Text, {
              children: t('booking.guestDetails.whoIsStayingInThisRoom')
            })
          })), !isMultiRoomRedesignEnabled && !isSingleRoomRedesignEnabled && (0, _jsxRuntime.jsx)(_atoms.Notification, {
            status: "info",
            svg: (0, _jsxRuntime.jsx)(_atoms.Info, {}),
            variant: "infoGrey",
            maxW: "full",
            description: t('booking.leadGuest.message'),
            "data-testid": (0, _utils.formatDataTestId)(formField.testid, 'Notification')
          }), isAdditionalInformationEnabled && !isMultiRoomRedesignEnabled && renderConsentFieldComponent(key), !isSingleBookingRedesign && shouldAskForAccompanyingGuest && !isSingleRoomBooking && (value === null || value === void 0 || (_value$roomStay5 = value.roomStay) === null || _value$roomStay5 === void 0 ? void 0 : _value$roomStay5.adultsNumber) === 2 && (0, _jsxRuntime.jsx)(_react.Box, {
            children: (0, _jsxRuntime.jsx)(_react.Text, _objectSpread(_objectSpread({}, titleStyle), {}, {
              children: t('booking.leadGuest.Details')
            }))
          }), (0, _jsxRuntime.jsx)(_GuestInputs.default, {
            control: control,
            formField: formField,
            errors: errors === null || errors === void 0 || (_errors$leadGuest2 = errors.leadGuest) === null || _errors$leadGuest2 === void 0 ? void 0 : _errors$leadGuest2[key],
            index: key,
            showIcon: showIcon,
            inputStyle: inputStyleFields,
            dropdownStyle: singleBookingDDStyles
          }), shouldAskForAccompanyingGuest && (value === null || value === void 0 || (_value$roomStay6 = value.roomStay) === null || _value$roomStay6 === void 0 ? void 0 : _value$roomStay6.adultsNumber) === 2 && renderAccompanyingGuest(key), isGermanHotel && isMultiRoomRedesignEnabled && isAdditionalInformationEnabled && renderCheckInInformation(value), isSingleBookingRedesign && isBookingForSomeoneElse && !isGermanHotel && (0, _jsxRuntime.jsx)(_react.Box, _objectSpread({}, singleBookingStyleBorders)), isMultiRoomRedesignEnabled && isGermanHotel && (showCheckInInfo === null || showCheckInInfo === void 0 ? void 0 : showCheckInInfo[value === null || value === void 0 ? void 0 : value.reservationId]) && (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
            children: [isAdditionalInformationEnabled && (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
              children: [(0, _jsxRuntime.jsx)(_react.Box, _objectSpread(_objectSpread({}, guestDetailStyles), {}, {
                "data-testid": (0, _utils.formatDataTestId)(formField.testid, 'guestDetailsSomeoneElseHeadingOptional')
              })), renderConsentFieldComponent(key), renderEmailComponent(key), isSingleBookingRedesign && (0, _jsxRuntime.jsx)(_react.Box, _objectSpread({}, singleBookingStyleBorders))]
            }), currentLang !== 'de' && (0, _jsxRuntime.jsx)(_react.Box, _objectSpread(_objectSpread({}, inputStyleAddressFields), {}, {
              children: (0, _jsxRuntime.jsx)(_reactHookForm.Controller, {
                name: "leadGuest[".concat(key, "].postcodeAddress"),
                control: control,
                render: _ref6 => {
                  var {
                    field
                  } = _ref6;
                  return (0, _jsxRuntime.jsx)(_PostcodeAddress.default, {
                    formField: _objectSpread(_objectSpread({}, formField), {}, {
                      label: t('booking.addressPostCode'),
                      type: _atoms.FORM_FIELD_TYPES.DYNAMIC_FIELD,
                      name: 'postcodeAddress',
                      isDisabled: isFieldDisabled(key),
                      testid: (0, _utils.formatDataTestId)("leadGuest[".concat(key, "]"), 'PostcodeAddress')
                    }),
                    field: field,
                    getValues: getValues(),
                    index: key,
                    setGuestAddress: setGuestAddress,
                    updateManualToggle: updateManualToggle
                  });
                }
              })
            })), currentLang !== 'de' && (0, _jsxRuntime.jsx)(_react.Box, _objectSpread(_objectSpread({}, inputStyleAddressFields), {}, {
              children: !(manualToggle[key] || leadGuestValues !== null && leadGuestValues !== void 0 && (_leadGuestValues$key3 = leadGuestValues[key]) !== null && _leadGuestValues$key3 !== void 0 && _leadGuestValues$key3.addressLine1) && (0, _jsxRuntime.jsx)(_react.Link, _objectSpread(_objectSpread({}, linkStyles), {}, {
                onClick: () => updateManualToggle(key, true),
                children: t('booking.enterManuallAddress')
              }))
            })), isGermanHotel && (manualToggle[key] || (leadGuestValues === null || leadGuestValues === void 0 || (_leadGuestValues$key4 = leadGuestValues[key]) === null || _leadGuestValues$key4 === void 0 ? void 0 : _leadGuestValues$key4.addressLine1) || showCheckInInfo[value.reservationId] && currentLang === _utils.GLOBALS.language.DE) && (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
              children: [(0, _jsxRuntime.jsx)(_react.Box, _objectSpread(_objectSpread({}, inputStyleAddressFields), {}, {
                children: (0, _jsxRuntime.jsx)(_reactHookForm.Controller, {
                  name: "leadGuest[".concat(key, "].addressLine1"),
                  control: control,
                  render: _ref7 => {
                    var {
                      field
                    } = _ref7;
                    return (0, _jsxRuntime.jsx)(_atoms.Input, _objectSpread(_objectSpread(_objectSpread({}, formField.props), field), {}, {
                      type: "text",
                      placeholderText: t('booking.guest.addressAddress1'),
                      label: t('booking.guest.addressAddress1'),
                      isDisabled: isFieldDisabled(key),
                      className: "sessioncamhidetext assist-no-show",
                      dataTestId: (0, _utils.formatDataTestId)(formField.testid, 'addressAddress1')
                    }));
                  }
                })
              })), (0, _jsxRuntime.jsx)(_react.Box, _objectSpread(_objectSpread({}, inputStyleAddressFields), {}, {
                children: (0, _jsxRuntime.jsx)(_reactHookForm.Controller, {
                  name: "leadGuest[".concat(key, "].addressLine2"),
                  control: control,
                  render: _ref8 => {
                    var {
                      field
                    } = _ref8;
                    return (0, _jsxRuntime.jsx)(_atoms.Input, _objectSpread(_objectSpread(_objectSpread({}, formField.props), field), {}, {
                      type: "text",
                      placeholderText: t('booking.guest.addressAddress2'),
                      label: t('booking.guest.addressAddress2'),
                      isDisabled: isFieldDisabled(key),
                      className: "sessioncamhidetext assist-no-show",
                      dataTestId: (0, _utils.formatDataTestId)(formField.testid, 'addressAddress2')
                    }));
                  }
                })
              })), (0, _jsxRuntime.jsx)(_react.Box, _objectSpread(_objectSpread({}, inputStyleAddressFields), {}, {
                children: (0, _jsxRuntime.jsx)(_reactHookForm.Controller, {
                  name: "leadGuest[".concat(key, "].addressLine3"),
                  control: control,
                  render: _ref9 => {
                    var {
                      field
                    } = _ref9;
                    return (0, _jsxRuntime.jsx)(_atoms.Input, _objectSpread(_objectSpread(_objectSpread({}, formField.props), field), {}, {
                      type: "text",
                      placeholderText: t('booking.guest.addressAddress3'),
                      label: t('booking.guest.addressAddress3'),
                      isDisabled: isFieldDisabled(key),
                      className: "sessioncamhidetext assist-no-show",
                      dataTestId: (0, _utils.formatDataTestId)(formField.testid, 'addressAddress3')
                    }));
                  }
                })
              })), !isLocationRequired && (0, _jsxRuntime.jsx)(_react.Box, _objectSpread(_objectSpread({}, inputStyleAddressFields), {}, {
                children: (0, _jsxRuntime.jsx)(_reactHookForm.Controller, {
                  name: "leadGuest[".concat(key, "].addressLine4"),
                  control: control,
                  render: _ref10 => {
                    var {
                      field
                    } = _ref10;
                    return (0, _jsxRuntime.jsx)(_atoms.Input, _objectSpread(_objectSpread(_objectSpread({}, formField.props), field), {}, {
                      type: "text",
                      placeholderText: t('booking.guest.addressAddress4'),
                      label: t('booking.guest.addressAddress4'),
                      isDisabled: isFieldDisabled(key),
                      className: "sessioncamhidetext assist-no-show",
                      dataTestId: (0, _utils.formatDataTestId)(formField.testid, 'addressAddress4')
                    }));
                  }
                })
              })), (0, _jsxRuntime.jsx)(_react.Box, _objectSpread(_objectSpread({}, inputStyleAddressFields), {}, {
                children: (0, _jsxRuntime.jsx)(_reactHookForm.Controller, {
                  name: "leadGuest[".concat(key, "].postcodeAddress"),
                  control: control,
                  render: _ref11 => {
                    var {
                      field
                    } = _ref11;
                    return (0, _jsxRuntime.jsx)(_atoms.Input, _objectSpread(_objectSpread(_objectSpread({}, formField.props), field), {}, {
                      id: 'homePostalCodes',
                      type: "text",
                      placeholderText: t('booking.guest.addressPostCode'),
                      label: t('booking.guest.addressPostCode'),
                      isDisabled: isFieldDisabled(key),
                      className: "sessioncamhidetext assist-no-show",
                      dataTestId: (0, _utils.formatDataTestId)(formField.testid, 'addressPostCode')
                    }));
                  }
                })
              })), isLocationRequired && (0, _jsxRuntime.jsx)(_react.Box, _objectSpread(_objectSpread({}, inputStyleAddressFields), {}, {
                children: (0, _jsxRuntime.jsx)(_reactHookForm.Controller, {
                  name: "leadGuest[".concat(key, "].addressLine4"),
                  control: control,
                  render: _ref12 => {
                    var {
                      field
                    } = _ref12;
                    return (0, _jsxRuntime.jsx)(_atoms.Input, _objectSpread(_objectSpread(_objectSpread({
                      id: 'homeAddressCityName'
                    }, formField.props), field), {}, {
                      type: "text",
                      placeholderText: t('booking.guest.addressCity'),
                      label: t('booking.guest.addressCity'),
                      isDisabled: isFieldDisabled(key),
                      className: "sessioncamhidetext assist-no-show",
                      dataTestId: (0, _utils.formatDataTestId)(formField.testid, 'cityName')
                    }));
                  }
                })
              })), (0, _jsxRuntime.jsx)(_react.Box, _objectSpread(_objectSpread({}, inputStyleAddressFields), {}, {
                children: (0, _jsxRuntime.jsx)(_reactHookForm.Controller, {
                  name: "leadGuest[".concat(key, "][countryCode]"),
                  control: control,
                  defaultValue: currentLang === 'en' ? 'GB' : 'DE',
                  render: _ref13 => {
                    var {
                      field
                    } = _ref13;
                    return (0, _jsxRuntime.jsx)(_CountriesDropdown.default, {
                      formField: _objectSpread(_objectSpread({}, formField), {}, {
                        label: 'Country',
                        type: _atoms.FORM_FIELD_TYPES.DYNAMIC_FIELD,
                        name: 'countryCode',
                        isDisabled: isFieldDisabled(key),
                        testid: (0, _utils.formatDataTestId)("leadGuest[".concat(key, "]"), 'CountrySelector'),
                        props: _objectSpread(_objectSpread({}, formField.props), {}, {
                          showIcon: false,
                          setIsLocationRequired
                        })
                      }),
                      getValues: getValues(),
                      field: field
                    });
                  }
                })
              }))]
            }), additionalFields(key)]
          }), isSingleBookingRedesignForSomeoneElse && isGermanHotel && !isAdditionalInformationEnabled && (0, _jsxRuntime.jsx)(_react.Box, _objectSpread({}, singleBookingStyleBorders)), isAdditionalInformationEnabled && !isMultiRoomRedesignEnabled && (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
            children: [(0, _jsxRuntime.jsxs)(_react.Box, _objectSpread(_objectSpread({}, guestDetailStyles), {}, {
              "data-testid": (0, _utils.formatDataTestId)(formField.testid, 'guestDetailsSomeoneElseHeadingOptional'),
              children: [(0, _jsxRuntime.jsx)(_react.Text, _objectSpread(_objectSpread({}, heading3), {}, {
                children: t('precheckin.guestdetails')
              })), (0, _jsxRuntime.jsx)(_react.Text, {
                children: t('precheckin.completedetails.statement')
              })]
            })), additionalFields(key), isSingleBookingRedesign && (0, _jsxRuntime.jsx)(_react.Box, _objectSpread({}, singleBookingStyleBorders))]
          })]
        })) : shouldAskForAccompanyingGuest && (value === null || value === void 0 || (_value$roomStay7 = value.roomStay) === null || _value$roomStay7 === void 0 ? void 0 : _value$roomStay7.adultsNumber) === 2 && (0, _jsxRuntime.jsxs)(_react.Box, _objectSpread(_objectSpread({}, messageStyleSwitch), {}, {
          children: [(0, _jsxRuntime.jsx)(_react.Box, {
            children: (0, _jsxRuntime.jsx)(_react.Text, _objectSpread(_objectSpread({}, titleStyle), {}, {
              children: t('booking.leadGuest.Details')
            }))
          }), (0, _jsxRuntime.jsx)(_react.Box, {
            children: (0, _jsxRuntime.jsxs)(_react.Text, _objectSpread(_objectSpread({}, subtitleStyle), {}, {
              children: [formData.title, " ", formData.firstName, " ", formData.lastName]
            }))
          }), renderAccompanyingGuest(key)]
        })), isGermanHotel && isMultiRoomRedesignEnabled && isAdditionalInformationEnabled && (leadGuestValues === null || leadGuestValues === void 0 || (_leadGuestValues$key5 = leadGuestValues[key]) === null || _leadGuestValues$key5 === void 0 ? void 0 : _leadGuestValues$key5.stayInThisRoom) && (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
          children: [renderCheckInInformation(value), (showCheckInInfo === null || showCheckInInfo === void 0 ? void 0 : showCheckInInfo[value === null || value === void 0 ? void 0 : value.reservationId]) && additionalFields(key)]
        }));
      })]
    }));
  }
  function renderAccompanyingGuest(key) {
    var _errors$leadGuest3;
    return (0, _jsxRuntime.jsxs)(_react.Box, {
      "data-testid": (0, _utils.formatDataTestId)(formField.testid, 'AccompanyingContainer'),
      children: [(0, _jsxRuntime.jsx)(_react.Box, _objectSpread(_objectSpread({}, inputStyleFields), {}, {
        children: (0, _jsxRuntime.jsx)(_react.Text, _objectSpread(_objectSpread({}, titleStyle), {}, {
          children: t('booking.contactDetails.accompanyingGuest.title')
        }))
      })), (0, _jsxRuntime.jsx)(_react.Box, _objectSpread(_objectSpread({}, inputStyleFields), {}, {
        children: (0, _jsxRuntime.jsx)(_react.Text, _objectSpread(_objectSpread({}, subtitleStyle), {}, {
          children: t('booking.contactDetails.accompanyingGuest.description')
        }))
      })), (0, _jsxRuntime.jsx)(_GuestInputs.default, {
        control: control,
        formField: formField,
        errors: errors === null || errors === void 0 || (_errors$leadGuest3 = errors.leadGuest) === null || _errors$leadGuest3 === void 0 ? void 0 : _errors$leadGuest3[key],
        index: key,
        showIcon: showIcon,
        inputStyle: inputStyleFields,
        dropdownStyle: singleBookingDDStyles,
        prefix: 'accompanying',
        fieldsRequired: false
      })]
    });
  }
  function renderCheckInInformation(value) {
    return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
      children: [(0, _jsxRuntime.jsxs)(_react.Box, _objectSpread(_objectSpread({}, inputStyleFields), {}, {
        maxW: "100%",
        pt: "xl",
        "data-testid": (0, _utils.formatDataTestId)(formField.testid, 'guestDetailsMyselfHeadingOptional'),
        children: [(0, _jsxRuntime.jsx)(_react.Text, _objectSpread(_objectSpread({}, heading3), {}, {
          children: t('precheckin.guestdetails')
        })), (0, _jsxRuntime.jsx)(_react.Text, {
          mt: "md",
          children: t('precheckin.completedetails.statement')
        })]
      })), (0, _jsxRuntime.jsx)(_react.Box, _objectSpread(_objectSpread({}, inputStyleFields), {}, {
        children: (0, _jsxRuntime.jsx)(_atoms.Button, _objectSpread(_objectSpread({
          variant: "tertiary",
          size: "full",
          onClick: toggleCheckInInfo(value.reservationId)
        }, checkinButtonStyles), {}, {
          "data-testId": (0, _utils.formatDataTestId)(formField.testid, 'CheckinButton'),
          children: showCheckInInfo !== null && showCheckInInfo !== void 0 && showCheckInInfo[value === null || value === void 0 ? void 0 : value.reservationId] ? (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
            children: [(0, _jsxRuntime.jsx)(_atoms.Icon, _objectSpread({
              svg: (0, _jsxRuntime.jsx)(_atoms.Rectangle, {
                color: 'var(--chakra-colors-darkGrey2)'
              })
            }, iconStyles)), " ".concat(t('booking.guestDetails.hideCheckIn'))]
          }) : (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
            children: [(0, _jsxRuntime.jsx)(_atoms.Icon, _objectSpread({
              svg: (0, _jsxRuntime.jsx)(_atoms.Path, {
                color: 'var(--chakra-colors-darkGrey2)'
              })
            }, iconStyles)), " ".concat(t('booking.guestDetails.addCheckIn'))]
          })
        }))
      }))]
    });
  }
  function renderCheckboxComponent(key, _onChange2) {
    var _leadGuestValues$key6;
    return (0, _jsxRuntime.jsx)(_react.Box, _objectSpread(_objectSpread({}, rightboxStyle), {}, {
      "data-testid": (0, _utils.formatDataTestId)(formField.testid, "RoomCheckbox-".concat(roomNumber(key))),
      children: (0, _jsxRuntime.jsx)(_atoms.Checkbox, {
        onChange: option => {
          _onChange2(option);
          handleStayInThisRoom(key);
        },
        isChecked: leadGuestValues === null || leadGuestValues === void 0 || (_leadGuestValues$key6 = leadGuestValues[key]) === null || _leadGuestValues$key6 === void 0 ? void 0 : _leadGuestValues$key6.stayInThisRoom,
        children: (0, _jsxRuntime.jsx)(_react.Text, _objectSpread(_objectSpread({}, checkboxLabelStyle), {}, {
          "data-testid": (0, _utils.formatDataTestId)(formField.testid, "RoomCheckboxText-".concat(roomNumber(key))),
          children: t('booking.leadGuest.ImStayingInThisRoom')
        }))
      })
    }));
  }
  function renderConsentFieldComponent(key) {
    var _errors$leadGuest4, _errors$leadGuest5;
    return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
      children: [(0, _jsxRuntime.jsx)(_react.Box, _objectSpread(_objectSpread({}, guestDetailStyles), {}, {
        pt: !isSingleRoomRedesignEnabled ? 'lg' : '',
        children: (0, _jsxRuntime.jsx)(_react.Text, _objectSpread(_objectSpread({}, textStyle), {}, {
          children: t('precheckin.consentstatement')
        }))
      })), (0, _jsxRuntime.jsx)(_react.Box, _objectSpread(_objectSpread({}, inputStyleFields), {}, {
        "data-testid": (0, _utils.formatDataTestId)(formField.testid, 'Consent'),
        children: (0, _jsxRuntime.jsx)(_reactHookForm.Controller, {
          name: "leadGuest[".concat(key, "][consent]"),
          control: control,
          render: _ref14 => {
            var {
              field: {
                onChange,
                value
              }
            } = _ref14;
            return renderConsentRadioGroupComponent(value, onChange);
          }
        })
      })), (errors === null || errors === void 0 || (_errors$leadGuest4 = errors.leadGuest) === null || _errors$leadGuest4 === void 0 || (_errors$leadGuest4 = _errors$leadGuest4[key]) === null || _errors$leadGuest4 === void 0 || (_errors$leadGuest4 = _errors$leadGuest4.consent) === null || _errors$leadGuest4 === void 0 ? void 0 : _errors$leadGuest4.message) && (0, _jsxRuntime.jsx)(_react.Box, _objectSpread(_objectSpread({}, guestDetailStyles), {}, {
        pb: "sm",
        children: (0, _jsxRuntime.jsx)(_react.Text, _objectSpread(_objectSpread({}, errorTextStyles), {}, {
          fontSize: "sm",
          mt: 0,
          dataTestId: (0, _utils.formatDataTestId)(formField.testid, 'ConsentErrorText'),
          children: errors === null || errors === void 0 || (_errors$leadGuest5 = errors.leadGuest) === null || _errors$leadGuest5 === void 0 || (_errors$leadGuest5 = _errors$leadGuest5[key]) === null || _errors$leadGuest5 === void 0 || (_errors$leadGuest5 = _errors$leadGuest5.consent) === null || _errors$leadGuest5 === void 0 ? void 0 : _errors$leadGuest5.message
        }))
      }))]
    });
  }
  function renderEmailComponent(key) {
    return (0, _jsxRuntime.jsx)(_react.Box, _objectSpread(_objectSpread({}, inputStyleFields), {}, {
      children: (0, _jsxRuntime.jsx)(_reactHookForm.Controller, {
        name: "leadGuest[".concat(key, "][email]"),
        control: control,
        render: _ref15 => {
          var _errors$leadGuest6;
          var {
            field
          } = _ref15;
          return (0, _jsxRuntime.jsx)(_atoms.Input, _objectSpread(_objectSpread(_objectSpread({}, formField.props), field), {}, {
            isDisabled: isFieldDisabled(key),
            type: "email",
            placeholderText: t('precheckin.additionalfields.email'),
            label: t('precheckin.additionalfields.email'),
            error: errors === null || errors === void 0 || (_errors$leadGuest6 = errors.leadGuest) === null || _errors$leadGuest6 === void 0 || (_errors$leadGuest6 = _errors$leadGuest6[key]) === null || _errors$leadGuest6 === void 0 || (_errors$leadGuest6 = _errors$leadGuest6.email) === null || _errors$leadGuest6 === void 0 ? void 0 : _errors$leadGuest6.message,
            className: "sessioncamhidetext assist-no-show",
            dataTestId: (0, _utils.formatDataTestId)(formField.testid, 'email')
          }));
        }
      })
    }));
  }
  function renderConsentRadioGroupComponent(value, _onChange3) {
    return (0, _jsxRuntime.jsx)(_react.RadioGroup, _objectSpread(_objectSpread({
      value: value !== undefined ? String(!!value) : '',
      onChange: val => _onChange3(Boolean(val === 'true')),
      "data-testid": "".concat((0, _utils.formatDataTestId)(formField.testid, 'Consent'), "_radio-group")
    }, consentRadioStyle), {}, {
      children: consentOptions.map(option => (0, _jsxRuntime.jsx)(_atoms.RadioButton, {
        value: option.value,
        "data-testid": option.dataTestID,
        type: option.type,
        variant: "borderless",
        width: "full",
        children: (0, _jsxRuntime.jsx)(_react.Text, _objectSpread(_objectSpread({}, consentRadioStyle), {}, {
          "data-testid": "".concat(option.dataTestID, "_text"),
          children: option.name
        }))
      }, option.type))
    }));
  }
}
var singleBookingStyleFields = {
  paddingLeft: '5',
  paddingRight: '5',
  paddingBottom: 'var(--chakra-space-md)',
  borderLeft: '1px solid var(--chakra-colors-lightGrey2)',
  borderRight: '1px solid var(--chakra-colors-lightGrey2)',
  maxW: {
    md: '100%',
    xl: '55rem'
  },
  sx: {
    '.chakra-input__group, .chakra-button, .chakra-radio-group, .chakra-menu__menu-button,  .chakra-alert': {
      maxW: {
        mobile: '100%',
        sm: '16.375rem',
        md: '21.75rem',
        lg: '24.5rem',
        xl: '26.25rem'
      }
    }
  }
};
var singleBookingStyleBorders = {
  maxW: {
    md: '100%',
    xl: '55rem'
  }
};
var singleBookingDropdownStyles = {
  maxW: '8.5rem !important'
};
var heading2 = {
  fontSize: 'xl',
  fontWeight: '600',
  lineHeight: '3',
  color: 'darkGrey1'
};
var heading3 = {
  fontSize: 'lg',
  fontWeight: '700',
  lineHeight: '3',
  color: 'darkGrey1'
};
var headingStyle = {
  color: 'var(--chakra-colors-darkGrey1)',
  fontSize: 'lg',
  fontWeight: '700',
  lineHeight: '3'
};
var textStyle = {
  color: 'var(--chakra-colors-darkGrey1)',
  fontSize: 'md',
  fontWeight: 'normal',
  lineHeight: '2'
};
var checkboxLabelStyle = {
  color: 'var(--chakra-colors-darkGrey1)',
  fontSize: 'md',
  fontWeight: 'bold',
  lineHeight: '3',
  pos: {
    mobile: 'relative',
    md: 'absolute'
  },
  right: {
    mobile: '0px',
    md: '45px'
  },
  top: {
    md: '-2px'
  },
  whiteSpace: {
    mobile: 'initial',
    md: 'nowrap'
  }
};
var boxParentStyle = {
  border: '1px solid var(--chakra-colors-lightGrey3)',
  p: {
    mobile: 'md',
    md: 'lg md lg md'
  },
  mb: '4xl',
  mt: 'md',
  _last: {
    mb: '0'
  }
};
var boxParentStyleDefault = {
  mb: 'xl',
  _last: {
    mb: '0'
  }
};
var boxesContainerStyle = {
  p: {
    mobile: 'md 0',
    md: 'lg 0'
  },
  mb: '0',
  direction: {
    mobile: 'column',
    md: 'row'
  }
};
var boxesContainerStyleDefault = {
  border: '1px solid var(--chakra-colors-lightGrey3)',
  padding: 'var(--chakra-space-md)'
};
var boxesStyles = {
  w: {
    mobile: '100%',
    md: '50%'
  }
};
var rightboxStyle = _objectSpread(_objectSpread({}, heading3), {}, {
  textAlign: {
    mobile: 'left',
    md: 'right'
  },
  fontStyle: 'md'
});
var messageStyles = {
  paddingTop: '2'
};
var dropdownStyle = {
  maxW: '8.5rem',
  mt: 'sm',
  mb: '0'
};
var inputStyle = {
  maxW: {
    mobile: '100%',
    sm: '16.375rem',
    md: '21.75rem',
    lg: '24.5rem',
    xl: '26.25rem'
  },
  marginBottom: 'var(--chakra-space-md)'
};
var tooltipErrorStyles = {
  marginBottom: 'var(--chakra-space-6xl)'
};
var formErrorStyles = exports.formErrorStyles = {
  height: 'var(--chakra-space-lg)',
  position: 'relative'
};
var errorMessageStyles = exports.errorMessageStyles = {
  position: 'absolute'
};
var errorTextStyles = exports.errorTextStyles = {
  color: 'error',
  fontSize: 'xs',
  marginLeft: 'md',
  marginTop: 'sm',
  whiteSpace: 'nowrap'
};
var linkStyles = {
  color: 'btnSecondaryEnabled',
  textDecoration: 'underline'
};
var consentRadioStyle = {
  alignItems: 'center',
  justifyContent: 'space-between',
  width: 'full',
  display: 'flex',
  fontSize: 'md'
};
var guestDetailStyle = _objectSpread(_objectSpread({}, singleBookingStyleFields), {}, {
  border: 'none',
  pl: 0,
  pt: 0
});
var iconStyles = {
  mr: '0.75rem',
  display: {
    base: 'none',
    xs: 'block'
  }
};
var checkinButtonStyles = {
  mt: '1rem'
};
var borderBottom = {
  borderBottom: '1px solid var(--chakra-colors-lightGrey2)'
};
var paddingTopBottom = {
  mt: 'md',
  mb: 'md'
};
var titleStyle = {
  fontSize: 'xl',
  fontWeight: 'semibold',
  lineHeight: '4',
  color: 'darkGrey1',
  pt: 'lg',
  pb: 'var(--chakra-space-3)'
};
var subtitleStyle = {
  fontSize: 'md',
  maxW: {
    mobile: '100%',
    sm: '16.375rem',
    md: '21.75rem',
    lg: '24.5rem',
    xl: '26.25rem'
  },
  marginBottom: 'var(--chakra-space-md)'
};