"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _react = require("@chakra-ui/react");
var _atoms = require("@whitbread-eos/atoms");
var _nextI18next = require("next-i18next");
var _react2 = _interopRequireDefault(require("react"));
var _tableConfig = require("./tableConfig");
var _jsxRuntime = require("react/jsx-runtime");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function CompanyList(_ref) {
  var {
    companyModalType,
    companies,
    tooManyResults,
    onCompanySelected
  } = _ref;
  var {
    t
  } = (0, _nextI18next.useTranslation)();
  if (companies.length === 0) {
    return null;
  }
  var columns = (0, _tableConfig.getTableColumns)(t, companyModalType);
  var rows = (0, _tableConfig.getTableRows)(companies, companyModalType);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [tooManyResults && (0, _jsxRuntime.jsx)(_react.Box, _objectSpread(_objectSpread({}, containerStyles), {}, {
      children: (0, _jsxRuntime.jsx)(_atoms.Notification, {
        maxWidth: "full",
        variant: "alert",
        status: "error",
        title: '',
        description: t('ccui.companyModals.tooManyResults'),
        svg: (0, _jsxRuntime.jsx)(_atoms.Alert, {})
      })
    })), (0, _jsxRuntime.jsx)(_atoms.TableList, {
      columns: columns,
      rows: rows,
      isExpandable: false,
      rowStyles: tableRowStyles,
      containerStyles: containerStyles,
      externalHeaderStyles: tableHeaderStyles,
      handleRowClicked: onCompanySelected,
      rowHoverStyles: rowHoverStyles
    })]
  });
}
var containerStyles = {
  marginInlineStart: 'var(--chakra-space-4)',
  marginInlineEnd: 'var(--chakra-space-4)',
  marginTop: 'var(--chakra-space-6)'
};
var tableRowStyles = {
  padding: {
    lg: 'var(--chakra-space-8) 0 var(--chakra-space-8) var(--chakra-space-6)'
  },
  verticalAlign: 'top'
};
var tableHeaderStyles = {
  padding: {
    lg: 'var(--chakra-space-6)'
  },
  verticalAlign: 'top'
};
var rowHoverStyles = {
  bg: 'lightGrey5',
  cursor: 'pointer'
};
var _default = exports.default = CompanyList;