"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.STANDARD_EXTRA_FACILITY_CODE = exports.PREMIER_PLUS_FACILITY_CODE = exports.PREMIER_EXTRA_CODE = exports.OPENING_SOON_MESSAGING_FLAG_TEXT = exports.NEW_ROOMS_MESSAGING_FLAG_TEXT = exports.NEW_HOTEL_MESSAGING_FLAG_TEXT = exports.BIGGER_ROOM_CODE = void 0;
var PREMIER_PLUS_FACILITY_CODE = exports.PREMIER_PLUS_FACILITY_CODE = 'PRR';
var STANDARD_EXTRA_FACILITY_CODE = exports.STANDARD_EXTRA_FACILITY_CODE = 'STE';
var BIGGER_ROOM_CODE = exports.BIGGER_ROOM_CODE = 'bigger-room';
var NEW_HOTEL_MESSAGING_FLAG_TEXT = exports.NEW_HOTEL_MESSAGING_FLAG_TEXT = 'New hotel';
var NEW_ROOMS_MESSAGING_FLAG_TEXT = exports.NEW_ROOMS_MESSAGING_FLAG_TEXT = 'New rooms';
var OPENING_SOON_MESSAGING_FLAG_TEXT = exports.OPENING_SOON_MESSAGING_FLAG_TEXT = 'Opening soon';
var PREMIER_EXTRA_CODE = exports.PREMIER_EXTRA_CODE = 'premier-extra';