"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = BookingDetailsExtrasComponent;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _react = require("@chakra-ui/react");
var _api = require("@whitbread-eos/api");
var _utils = require("@whitbread-eos/utils");
var _nextI18next = require("next-i18next");
var _jsxRuntime = require("react/jsx-runtime");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function BookingDetailsExtrasComponent(_ref) {
  var _extrasPackageRoom$pa;
  var {
    adultMealDescription,
    childrenMealDescription,
    currencyCode,
    language,
    noNights,
    bookingStatus,
    extrasPackageRoom,
    showEciLco,
    showMeals = true
  } = _ref;
  var {
    t
  } = (0, _nextI18next.useTranslation)();
  var nightTranslation = noNights > 1 ? t('dashboard.bookings.nights') : t('dashboard.bookings.night');
  var priceForExtras = extrasId => {
    if (extrasId === _api.ExtrasId.EARLY_CHECK_IN) {
      var _extrasPackageRoom$pr;
      return extrasPackageRoom === null || extrasPackageRoom === void 0 || (_extrasPackageRoom$pr = extrasPackageRoom.priceEci) === null || _extrasPackageRoom$pr === void 0 ? void 0 : _extrasPackageRoom$pr.toFixed(2);
    }
    if (extrasId === _api.ExtrasId.LATE_CHECK_OUT) {
      var _extrasPackageRoom$pr2;
      return extrasPackageRoom === null || extrasPackageRoom === void 0 || (_extrasPackageRoom$pr2 = extrasPackageRoom.priceLco) === null || _extrasPackageRoom$pr2 === void 0 ? void 0 : _extrasPackageRoom$pr2.toFixed(2);
    }
    if (extrasId === _api.ExtrasId.ULTIMATE_WIFI) {
      var _extrasPackageRoom$pr3;
      return extrasPackageRoom === null || extrasPackageRoom === void 0 || (_extrasPackageRoom$pr3 = extrasPackageRoom.priceWifi) === null || _extrasPackageRoom$pr3 === void 0 ? void 0 : _extrasPackageRoom$pr3.toFixed(2);
    }
    return '';
  };
  return (0, _jsxRuntime.jsxs)(_react.Flex, {
    flexDir: "column",
    width: "full",
    children: [showMeals && (adultMealDescription === null || adultMealDescription === void 0 ? void 0 : adultMealDescription.map((adultMeal, index) => {
      var _adultMeal$title, _adultMeal$noSelectio;
      return renderMeal((_adultMeal$title = adultMeal === null || adultMeal === void 0 ? void 0 : adultMeal.title) !== null && _adultMeal$title !== void 0 ? _adultMeal$title : '', (_adultMeal$noSelectio = adultMeal === null || adultMeal === void 0 ? void 0 : adultMeal.noSelections) !== null && _adultMeal$noSelectio !== void 0 ? _adultMeal$noSelectio : 0, true, index, adultMeal.price);
    })), showMeals && (childrenMealDescription === null || childrenMealDescription === void 0 ? void 0 : childrenMealDescription.length) > 0 && (childrenMealDescription === null || childrenMealDescription === void 0 ? void 0 : childrenMealDescription.map((childMeal, index) => {
      var _childMeal$title, _childMeal$noSelectio;
      return renderMeal((_childMeal$title = childMeal === null || childMeal === void 0 ? void 0 : childMeal.title) !== null && _childMeal$title !== void 0 ? _childMeal$title : '', (_childMeal$noSelectio = childMeal === null || childMeal === void 0 ? void 0 : childMeal.noSelections) !== null && _childMeal$noSelectio !== void 0 ? _childMeal$noSelectio : 0, false, index, childMeal.price);
    })), showEciLco && (extrasPackageRoom === null || extrasPackageRoom === void 0 || (_extrasPackageRoom$pa = extrasPackageRoom.packagesList) === null || _extrasPackageRoom$pa === void 0 ? void 0 : _extrasPackageRoom$pa.map((extrasPackageId, index) => (0, _jsxRuntime.jsxs)(_react.Flex, {
      flexDir: "row",
      justifyContent: "space-between",
      children: [(0, _jsxRuntime.jsx)(_react.Text, _objectSpread(_objectSpread({}, titleStyle), {}, {
        "data-testid": "extras-name",
        children: (0, _utils.extrasNamingCheck)(t, extrasPackageId)
      })), bookingStatus !== _api.BC_RESERVATION_STATUS.CANCELLED && (0, _jsxRuntime.jsx)(_react.Flex, {
        flexDir: "column",
        "data-testid": "extras-price",
        children: (0, _jsxRuntime.jsx)(_react.Text, _objectSpread(_objectSpread({}, priceStyle), {}, {
          children: (0, _utils.formatPrice)((0, _utils.formatCurrency)(currencyCode), priceForExtras(extrasPackageId), language)
        }))
      })]
    }, "".concat(extrasPackageRoom === null || extrasPackageRoom === void 0 ? void 0 : extrasPackageRoom.reservationId, "-").concat(index, " "))))]
  });
  function renderMeal(title, noSelections, isAdultMeal, index) {
    var price = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : 0;
    var calculatedPrice = (price * noSelections * noNights).toFixed(2);
    var guestTranslation = '';
    var childTranslation = '';
    if (isAdultMeal) {
      guestTranslation = t(noSelections > 1 ? 'dashboard.bookings.guests' : 'dashboard.bookings.guest');
    } else {
      childTranslation = t(noSelections > 1 ? 'dashboard.bookings.children' : 'dashboard.bookings.child');
    }
    return (0, _jsxRuntime.jsxs)(_react.Flex, {
      flexDir: "row",
      justifyContent: "space-between",
      children: [(0, _jsxRuntime.jsx)(_react.Text, _objectSpread(_objectSpread({}, titleStyle), {}, {
        "data-testid": "mealName",
        children: title
      })), bookingStatus !== _api.BC_RESERVATION_STATUS.CANCELLED && (0, _jsxRuntime.jsxs)(_react.Flex, {
        flexDir: "column",
        children: [(0, _jsxRuntime.jsx)(_react.Text, _objectSpread(_objectSpread({}, priceStyle), {}, {
          children: (0, _utils.formatPrice)((0, _utils.formatCurrency)(currencyCode), calculatedPrice, language)
        })), isAdultMeal ? (0, _jsxRuntime.jsx)(_react.Text, _objectSpread(_objectSpread({}, priceDescriptionStyle), {}, {
          children: "".concat(t('dashboard.bookings.priceFor'), " ").concat(noNights, " ").concat(nightTranslation, ", ").concat(noSelections, " ").concat(guestTranslation)
        })) : (0, _jsxRuntime.jsx)(_react.Text, _objectSpread(_objectSpread({}, priceDescriptionStyle), {}, {
          children: "".concat(t('dashboard.bookings.priceFor'), " ").concat(noSelections, " ").concat(childTranslation)
        }))]
      })]
    }, index + title);
  }
}
var titleStyle = {
  color: 'darkGrey2',
  fontSize: {
    mobile: 'md',
    sm: 'lg'
  },
  lineHeight: '3',
  fontWeight: 'normal',
  w: {
    mobile: '8.56rem',
    sm: 'auto'
  },
  as: 'h6'
};
var priceStyle = {
  fontWeight: 'medium',
  fontSize: {
    mobile: 'md',
    sm: 'lg'
  },
  color: 'darkGrey2',
  lineHeight: '3',
  as: 'h6',
  alignSelf: 'flex-end'
};
var priceDescriptionStyle = {
  fontWeight: 'normal',
  fontSize: 'sm',
  lineHeight: '2',
  color: 'darkGrey2',
  textAlign: 'right'
};