"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "PRE_AUTHORISED_CHARGES", {
  enumerable: true,
  get: function get() {
    return _preAuthorisedCharges.default;
  }
});
exports.default = void 0;
var _AccountToCompanyPreAuthorisedCharges = _interopRequireDefault(require("./AccountToCompanyPreAuthorisedCharges.component"));
var _preAuthorisedCharges = _interopRequireDefault(require("./preAuthorisedCharges"));
var _default = exports.default = _AccountToCompanyPreAuthorisedCharges.default;