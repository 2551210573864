"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = HotelNotification;
var _atoms = require("@whitbread-eos/atoms");
var _jsxRuntime = require("react/jsx-runtime");
function HotelNotification(_ref) {
  var {
    description
  } = _ref;
  return (0, _jsxRuntime.jsx)(_atoms.Notification, {
    variant: "alert",
    status: "warning",
    description: description,
    svg: (0, _jsxRuntime.jsx)(_atoms.Alert, {}),
    m: "auto",
    maxW: {
      mobile: '18rem',
      xs: '21.4375rem',
      sm: '33.75rem',
      md: '45rem',
      lg: '50.5rem',
      xl: '54rem'
    },
    w: "100%"
  });
}