"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = AuthContentManagerBBVariant;
var _atoms = require("@whitbread-eos/atoms");
var _react = require("react");
var _LogIn = require("../../LogIn");
var _ResetPassword = require("../../ResetPassword");
var _jsxRuntime = require("react/jsx-runtime");
function AuthContentManagerBBVariant(_ref) {
  var {
    isLoginModalOpen,
    showRegisterNotification,
    hasRegisteredSuccessfully,
    toggleLoginModal,
    labels,
    onGoBack,
    goBackButtonText
  } = _ref;
  var [isLoginForm, setIsLoginForm] = (0, _react.useState)(true);
  var [loginDefaultValues, setLoginDefaultValues] = (0, _react.useState)({
    email: '',
    password: ''
  });
  var [loginDefaultErrors, setLoginDefaultErrors] = (0, _react.useState)({});
  var getLoginFormState = (0, _react.useCallback)((state1, errors1) => {
    setLoginDefaultValues(state1);
    setLoginDefaultErrors(errors1);
  }, [setLoginDefaultValues, setLoginDefaultErrors]);
  var [resetPasswordDefaultValues, setResetPasswordDefaultValues] = (0, _react.useState)({
    email: ''
  });
  var [resetPasswordDefaultErrors, setResetPasswordDefaultErrors] = (0, _react.useState)({});
  var getResetPasswordFormState = (0, _react.useCallback)((state, errors) => {
    setResetPasswordDefaultValues(state);
    setResetPasswordDefaultErrors(errors);
  }, [setResetPasswordDefaultValues, setResetPasswordDefaultErrors]);
  return (0, _jsxRuntime.jsx)(_atoms.ModalVariants, {
    isOpen: isLoginModalOpen,
    onClose: toggleLoginModal,
    closeOnOverlayClick: false,
    variant: "login",
    variantProps: {
      title: '',
      delimiter: true,
      onGoBack,
      goBackButtonText
    },
    dataTestId: 'BB-Header-Auth',
    children: isLoginForm ? (0, _jsxRuntime.jsx)(_LogIn.LogInBBVariant, {
      setIsLoginForm: setIsLoginForm,
      defaultValues: loginDefaultValues,
      defaultErrors: loginDefaultErrors,
      getFormState: getLoginFormState,
      toggleLoginModal: toggleLoginModal,
      showRegisterNotification: showRegisterNotification,
      hasRegisteredSuccessfully: hasRegisteredSuccessfully,
      labels: labels
    }) : (0, _jsxRuntime.jsx)(_ResetPassword.ResetPasswordBBVariant, {
      setIsLoginForm: setIsLoginForm,
      defaultValues: resetPasswordDefaultValues,
      defaultErrors: resetPasswordDefaultErrors,
      getFormState: getResetPasswordFormState,
      labels: labels
    })
  });
}