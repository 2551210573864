"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = LocationDropdown;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));
var _react = require("@chakra-ui/react");
var _api = require("@whitbread-eos/api");
var _utils = require("@whitbread-eos/utils");
var _debounce = _interopRequireDefault(require("lodash/debounce"));
var _nextI18next = require("next-i18next");
var _react2 = require("react");
var _HotelDropdownModal = _interopRequireDefault(require("../HotelDropdownModal"));
var _SearchBookingsLocationPicker = _interopRequireDefault(require("../SearchBookingsLocationPicker"));
var _jsxRuntime = require("react/jsx-runtime");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
var MIN_LENGTH_SEARCH_TERM = 3;
var INITIAL_DATA = {
  managedPlaces: [],
  places: [],
  properties: []
};
function LocationDropdown(_ref) {
  var {
    formField,
    field,
    errors,
    handleSetValue,
    handleResetField,
    handleSetError,
    handleClearErrors
  } = _ref;
  var {
    t
  } = (0, _nextI18next.useTranslation)();
  var {
    language
  } = (0, _utils.useCustomLocale)();
  var locationErrorLabel = t('ccui.manageBooking.hotelLocation.error');
  var baseTestId = 'LocationDropdown';
  var [inputValue, setInputValue] = (0, _react2.useState)(field.value || '');
  var [items, setItems] = (0, _react2.useState)(null);
  var [isHotelsModalOpen, setIsHotelsModalOpen] = (0, _react2.useState)(false);
  var [paramsForQuery, setParamsForQuery] = (0, _react2.useState)({
    location: '',
    locationFormat: '',
    radius: language === 'en' ? _api.SR_FORMAT.EN_DIST : _api.SR_FORMAT.DIST,
    radiusUnit: (0, _utils.getDistanceUnitBasedOnLocaleSb)(language || 'en')
  });
  var suggestions = items !== null && items !== void 0 ? items : INITIAL_DATA;
  var {
    label,
    props
  } = formField;
  var {
    name,
    onChange
  } = field;
  var locationError = !!(errors !== null && errors !== void 0 && errors.hotelLocation);
  var clearHotelFields = props === null || props === void 0 ? void 0 : props.clearHotelFields;
  var {
    clearHotelLocation,
    setClearHotelLocation,
    setClearHotelName
  } = clearHotelFields !== null && clearHotelFields !== void 0 ? clearHotelFields : {};
  var onCloseHotelModal = () => {
    setIsHotelsModalOpen(false);
    resetFields();
  };
  var onSelectHotelFromModal = () => {
    setIsHotelsModalOpen(false);
  };
  (0, _react2.useEffect)(() => {
    if (clearHotelLocation) {
      setInputValue('');
      handleResetField === null || handleResetField === void 0 || handleResetField('hotelLocation', {
        defaultValue: ''
      });
      handleClearErrors === null || handleClearErrors === void 0 || handleClearErrors(field.name);
      setClearHotelLocation(false);
    }
  }, [clearHotelLocation]);
  var debouncedSearch = (0, _react2.useRef)((0, _debounce.default)(getLocationSuggestions, 300)).current;
  function handleInputChange(value) {
    setInputValue(value !== null && value !== void 0 ? value : '');
    debouncedSearch(value !== null && value !== void 0 ? value : '');
    if ((value === null || value === void 0 ? void 0 : value.trim()) === '') {
      resetFields();
    }
  }
  function getLocationSuggestions(_x) {
    return _getLocationSuggestions.apply(this, arguments);
  }
  function _getLocationSuggestions() {
    _getLocationSuggestions = (0, _asyncToGenerator2.default)(function* (value) {
      if (value && value.trim().length >= MIN_LENGTH_SEARCH_TERM) {
        var data = yield (0, _api.getSuggestions)(value);
        if (!data || hasNoMatchingLocations(data)) {
          handleSetError === null || handleSetError === void 0 || handleSetError(field.name, {
            type: 'custom',
            message: locationErrorLabel
          });
          setItems(INITIAL_DATA);
          return;
        }
        handleClearErrors === null || handleClearErrors === void 0 || handleClearErrors(field.name);
        setItems({
          managedPlaces: data === null || data === void 0 ? void 0 : data.managedPlaces,
          places: data === null || data === void 0 ? void 0 : data.places,
          properties: []
        });
      } else {
        setItems(INITIAL_DATA);
        handleResetField === null || handleResetField === void 0 || handleResetField('hotelLocation', {
          defaultValue: ''
        });
        setClearHotelName(true);
        handleClearErrors === null || handleClearErrors === void 0 || handleClearErrors(field.name);
      }
    });
    return _getLocationSuggestions.apply(this, arguments);
  }
  return (0, _jsxRuntime.jsxs)(_react.Box, _objectSpread(_objectSpread(_objectSpread({}, wrapperStyles), errorWrapperStyles(locationError)), {}, {
    "data-testid": (0, _utils.formatDataTestId)(baseTestId, 'Container'),
    children: [(0, _jsxRuntime.jsxs)(_react.FormControl, {
      isInvalid: locationError,
      children: [label && (0, _jsxRuntime.jsx)(_react.FormLabel, _objectSpread(_objectSpread({
        "data-testid": (0, _utils.formatDataTestId)(baseTestId, "".concat(name, "-label")),
        pos: "absolute"
      }, labelStyle(locationError, inputValue)), {}, {
        htmlFor: name,
        children: label
      })), (0, _jsxRuntime.jsx)(_SearchBookingsLocationPicker.default, {
        inputPlaceholder: label,
        inputvalue: inputValue,
        suggestions: suggestions,
        styles: getLocationPickerStyles(locationError),
        showError: locationError,
        errorLabel: locationErrorLabel,
        onSelectLocation: handleSelectLocation,
        onInputChange: handleInputChange,
        onClearInput: handleLocationInputClear,
        onInputFocus: handleLocationInputFocus,
        onInputBlur: handleLocationInputBlur
      })]
    }), (0, _jsxRuntime.jsx)(_HotelDropdownModal.default, {
      isOpen: isHotelsModalOpen,
      onClose: onCloseHotelModal,
      onHotelSelected: onSelectHotelFromModal,
      paramsForQuery: paramsForQuery,
      handleSetValue: handleSetValue
    })]
  }));
  function hasNoMatchingLocations(data) {
    var _data$places, _data$managedPlaces;
    return ((_data$places = data.places) === null || _data$places === void 0 ? void 0 : _data$places.length) === 0 && ((_data$managedPlaces = data.managedPlaces) === null || _data$managedPlaces === void 0 ? void 0 : _data$managedPlaces.length) === 0;
  }
  function handleSelectLocation(location) {
    if (location) {
      var _suggestion, _suggestion2;
      onChange((_suggestion = location === null || location === void 0 ? void 0 : location.suggestion) !== null && _suggestion !== void 0 ? _suggestion : '');
      updateParamsForQuery(location);
      setInputValue((_suggestion2 = location === null || location === void 0 ? void 0 : location.suggestion) !== null && _suggestion2 !== void 0 ? _suggestion2 : '');
      setIsHotelsModalOpen(true);
    }
  }
  function updateParamsForQuery(location) {
    var chosenLocation;
    var chosenFormat;
    if (location !== null && location !== void 0 && location.geometry) {
      chosenLocation = "".concat(location === null || location === void 0 ? void 0 : location.geometry.coordinates[1], ",").concat(location === null || location === void 0 ? void 0 : location.geometry.coordinates[0]);
      chosenFormat = _api.SR_FORMAT.LATLONG;
    } else if (location !== null && location !== void 0 && location.managedPlaceId) {
      chosenLocation = location === null || location === void 0 ? void 0 : location.managedPlaceId;
      chosenFormat = _api.SR_FORMAT.MANAGEDPLACEID;
    } else {
      chosenLocation = location === null || location === void 0 ? void 0 : location.placeId;
      chosenFormat = _api.SR_FORMAT.PLACEID;
    }
    setParamsForQuery(_objectSpread(_objectSpread({}, paramsForQuery), {}, {
      location: chosenLocation,
      locationFormat: chosenFormat
    }));
  }
  function handleLocationInputClear() {
    resetFields();
  }
  function handleLocationInputBlur() {
    resetFields();
  }
  function resetFields() {
    setClearHotelLocation(true);
    setClearHotelName(true);
  }
  function handleLocationInputFocus() {
    setItems(INITIAL_DATA);
  }
}
var wrapperStyles = {
  w: {
    lg: '24.5rem',
    xl: '26.25rem'
  },
  p: 0,
  height: 'var(--chakra-space-4xl)',
  _placeholder: {
    opacity: 0.7,
    color: 'var(--chakra-darkGrey1)'
  }
};
var inputStyles = {
  border: '0.063rem solid var(--chakra-colors-lightGrey1)',
  _hover: {},
  px: 'md',
  py: 0
};
var errorInputStyles = locationError => {
  return locationError ? {
    borderColor: 'var(--chakra-colors-error)',
    boxShadow: '0 0 0 1px var(--chakra-colors-error)',
    _hover: {
      borderColor: 'var(--chakra-colors-error)',
      boxShadow: '0 0 0 1px var(--chakra-colors-error)'
    },
    _focus: {
      borderWidth: '2px',
      borderColor: 'var(--chakra-colors-error)',
      boxShadow: '0 0 0 1px var(--chakra-colors-error)'
    }
  } : {};
};
var errorWrapperStyles = locationError => {
  return locationError ? {
    marginBottom: 'var(--chakra-space-4xl)'
  } : {};
};
var getLocationPickerStyles = locationError => {
  return {
    inputGroupStyles: wrapperStyles,
    inputElementStyles: inputStyles,
    errorInputElementStyles: errorInputStyles(locationError)
  };
};
var labelStyle = (error, value) => {
  return {
    h: '1.25rem',
    w: 'fit-content',
    fontSize: 'sm',
    fontWeight: 'normal',
    align: 'center',
    px: 'xs',
    ml: '0.750rem',
    top: '-0.625rem',
    backgroundColor: 'baseWhite',
    zIndex: '1',
    color: error ? 'error' : 'darkGrey1',
    display: value ? 'block' : 'none',
    _focus: {
      color: error ? 'error' : 'primary',
      display: 'block'
    }
  };
};