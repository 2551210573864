"use strict";
'use client';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.TranslationContext = void 0;
exports.TranslationProvider = TranslationProvider;
exports.useTranslation = useTranslation;
var _react = require("react");
var _i18n = require("./i18n");
var _jsxRuntime = require("react/jsx-runtime");
var TranslationContext = exports.TranslationContext = (0, _react.createContext)(undefined);
function TranslationProvider(_ref) {
  var {
    value,
    children
  } = _ref;
  return (0, _jsxRuntime.jsx)(TranslationContext.Provider, {
    value: value,
    children: children
  });
}
function useTranslation() {
  var namespace = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 'common';
  var translations = (0, _react.useContext)(TranslationContext);
  if (typeof namespace === 'string') {
    return {
      t: key => (0, _i18n.translate)(translations, key, namespace)
    };
  }
  return {
    t: key => (0, _i18n.translate)(translations, key)
  };
}