"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = TransportInformationComponent;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _react = require("@chakra-ui/react");
var _utils = require("@whitbread-eos/utils");
var _nextI18next = require("next-i18next");
var _react2 = require("react");
var _jsxRuntime = require("react/jsx-runtime");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function TransportInformationComponent(_ref) {
  var {
    data,
    isLoading,
    isError,
    error
  } = _ref;
  var [height, setHeight] = (0, _react2.useState)(0);
  var [readMore, setReadMore] = (0, _react2.useState)(false);
  var {
    t
  } = (0, _nextI18next.useTranslation)(['common']);
  var linkName = readMore ? t('hoteldetails.readless') : t('hoteldetails.readmore');
  if (isLoading) {
    return (0, _jsxRuntime.jsx)(_react.Text, {
      children: t('searchresults.list.hotel.loading')
    });
  }
  if (isError) {
    return (0, _jsxRuntime.jsx)(_react.Text, {
      children: error.message
    });
  }
  return (0, _jsxRuntime.jsxs)(_react.VStack, _objectSpread(_objectSpread({}, vStackProps), {}, {
    "data-testid": "transport-information-section",
    mb: "5",
    children: [(0, _jsxRuntime.jsx)(_react.Text, {
      fontWeight: "semibold",
      children: t('hoteldetails.localinfo.title')
    }), (0, _jsxRuntime.jsx)(_react.Collapse, {
      startingHeight: 95,
      in: readMore,
      children: (0, _jsxRuntime.jsx)(_react.List, {
        ref: node => (0, _utils.handleRefHeightChange)(node, setHeight),
        "data-testid": "transport-information-list",
        children: data === null || data === void 0 ? void 0 : data.map((step, index) => (0, _jsxRuntime.jsx)(_react.ListItem, {
          children: step && (0, _jsxRuntime.jsx)(_react.Text, {
            "data-testid": "transport-information-item-".concat(index),
            children: step
          })
        }, step))
      })
    }), height >= 95 && (0, _jsxRuntime.jsx)(_react.Link, _objectSpread(_objectSpread({}, linkStyles), {}, {
      "data-testid": "hdp_transportInformationReadMoreLink",
      onClick: () => {
        setReadMore(!readMore);
      },
      children: (0, _jsxRuntime.jsx)(_react.Text, {
        boxShadow: !readMore ? '0 0 2rem 1rem white' : 'none',
        children: t(linkName)
      })
    }))]
  }));
}
var vStackProps = {
  align: 'stretch',
  fontSize: 'md',
  lineHeight: '3',
  mt: '1.25'
};
var linkStyles = {
  fontSize: 'sm',
  color: 'btnSecondaryEnabled',
  textDecoration: 'underline'
};