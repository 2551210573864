"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.CONFIRM_AMEND_STATUS = void 0;
var CONFIRM_AMEND_STATUS = exports.CONFIRM_AMEND_STATUS = function (CONFIRM_AMEND_STATUS) {
  CONFIRM_AMEND_STATUS["success"] = "success";
  CONFIRM_AMEND_STATUS["error"] = "error";
  CONFIRM_AMEND_STATUS["payment"] = "payment";
  CONFIRM_AMEND_STATUS["paymentError"] = "payment-error";
  return CONFIRM_AMEND_STATUS;
}({});