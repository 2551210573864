"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = BartBookingCardDetails;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _react = require("@chakra-ui/react");
var _api = require("@whitbread-eos/api");
var _molecules = require("@whitbread-eos/molecules");
var _utils = require("@whitbread-eos/utils");
var _nextI18next = require("next-i18next");
var _jsxRuntime = require("react/jsx-runtime");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function BartBookingCardDetails(_ref) {
  var _bookingData$rooms, _bookingData$donation, _bookingData$rateMess, _bookingData$balanceO, _bookingData$prepaidA, _bookingData$hasCityT, _bookingData$paymentO;
  var {
    getExtrasBart,
    roomTypes,
    bookingData,
    baseDataTestId,
    sourcePms,
    bartId,
    isLoading,
    isError,
    error
  } = _ref;
  var {
    language
  } = (0, _utils.useCustomLocale)();
  var {
    t
  } = (0, _nextI18next.useTranslation)(['common']);
  if (isLoading) {
    return (0, _jsxRuntime.jsx)(_react.Text, {
      "data-testid": (0, _utils.formatDataTestId)(baseDataTestId, 'data-loading'),
      children: t('searchresults.list.hotel.loading')
    });
  }
  if (isError) {
    return (0, _jsxRuntime.jsx)(_react.Text, {
      children: error.message
    });
  }
  return (0, _jsxRuntime.jsxs)(_react.Flex, _objectSpread(_objectSpread({
    "data-testid": baseDataTestId
  }, containerStyle), {}, {
    sx: {
      '@media print': {
        width: '100%',
        marginLeft: 'md'
      }
    },
    children: [(0, _jsxRuntime.jsx)(_react.Box, {
      children: (0, _jsxRuntime.jsx)(_molecules.BartBookingDetailsReservationInformation, {
        baseDataTestId: "".concat(baseDataTestId, "-ReservationInformation"),
        bookingType: bookingData.bookingType,
        rateType: bookingData.rateName,
        sourcePms: sourcePms,
        isBart: !!bartId,
        t: t
      })
    }), bookingData === null || bookingData === void 0 || (_bookingData$rooms = bookingData.rooms) === null || _bookingData$rooms === void 0 ? void 0 : _bookingData$rooms.map((room, index) => {
      var _room$reservationGues, _room$reservationGues2, _room$reservationGues3, _room$reservationGues4, _room$totalRoomCost$a, _room$totalRoomCost, _room$totalRoomCost$c, _room$totalRoomCost2;
      var roomTypeLabelsBySourceSystem = (0, _utils.getRoomTypeLabelsBySourceSystem)(roomTypes, sourcePms === _api.FIND_BOOKING_SOURCE_PMS.BART ? _api.SOURCE_SYSTEM.BART : _api.SOURCE_SYSTEM.OPERA, t);
      var roomLabel = (0, _utils.getRoomDetailsForBartCard)(room, roomTypeLabelsBySourceSystem[room === null || room === void 0 ? void 0 : room.roomType]);
      var packages = room.packages ? getExtrasBart(room.packages, 'id') : null;
      return (0, _jsxRuntime.jsxs)(_react.Box, {
        mt: "md",
        "data-testid": (0, _utils.formatDataTestId)(baseDataTestId, "BartBookingsDetailsRoom-".concat(index)),
        children: [(0, _jsxRuntime.jsx)(_molecules.BartBookingDetailsRoomInformation, {
          roomNumber: index + 1,
          firstName: (_room$reservationGues = room === null || room === void 0 || (_room$reservationGues2 = room.reservationGuest) === null || _room$reservationGues2 === void 0 ? void 0 : _room$reservationGues2.givenName) !== null && _room$reservationGues !== void 0 ? _room$reservationGues : '',
          lastName: (_room$reservationGues3 = room === null || room === void 0 || (_room$reservationGues4 = room.reservationGuest) === null || _room$reservationGues4 === void 0 ? void 0 : _room$reservationGues4.surName) !== null && _room$reservationGues3 !== void 0 ? _room$reservationGues3 : '',
          roomName: roomLabel.roomType,
          noAdults: room === null || room === void 0 ? void 0 : room.adultsNumber,
          noKids: room === null || room === void 0 ? void 0 : room.childrenNumber,
          price: (_room$totalRoomCost$a = room === null || room === void 0 || (_room$totalRoomCost = room.totalRoomCost) === null || _room$totalRoomCost === void 0 ? void 0 : _room$totalRoomCost.amount) !== null && _room$totalRoomCost$a !== void 0 ? _room$totalRoomCost$a : 0,
          currency: (_room$totalRoomCost$c = room === null || room === void 0 || (_room$totalRoomCost2 = room.totalRoomCost) === null || _room$totalRoomCost2 === void 0 ? void 0 : _room$totalRoomCost2.currencyCode) !== null && _room$totalRoomCost$c !== void 0 ? _room$totalRoomCost$c : '',
          language: language,
          t: t
        }), !!packages && (0, _jsxRuntime.jsx)(_molecules.BartBookingDetailsExtras, {
          packages: packages,
          language: language,
          t: t
        })]
      }, "".concat(roomLabel, " - ").concat(room === null || room === void 0 ? void 0 : room.roomType));
    }), (0, _jsxRuntime.jsx)(_molecules.BartBookingDetailsTotalCost, {
      baseDataTestId: baseDataTestId,
      language: language,
      donationPkg: (_bookingData$donation = bookingData === null || bookingData === void 0 ? void 0 : bookingData.donations) !== null && _bookingData$donation !== void 0 ? _bookingData$donation : {},
      rateDescription: (_bookingData$rateMess = bookingData === null || bookingData === void 0 ? void 0 : bookingData.rateMessage) !== null && _bookingData$rateMess !== void 0 ? _bookingData$rateMess : '',
      balanceOutstanding: (_bookingData$balanceO = bookingData === null || bookingData === void 0 ? void 0 : bookingData.balanceOutstanding) !== null && _bookingData$balanceO !== void 0 ? _bookingData$balanceO : {},
      totalCost: bookingData === null || bookingData === void 0 ? void 0 : bookingData.totalCost,
      previousTotal: (_bookingData$prepaidA = bookingData === null || bookingData === void 0 ? void 0 : bookingData.prepaidAmount) !== null && _bookingData$prepaidA !== void 0 ? _bookingData$prepaidA : {},
      shouldDisplayCityTaxMessage: (_bookingData$hasCityT = bookingData === null || bookingData === void 0 ? void 0 : bookingData.hasCityTax) !== null && _bookingData$hasCityT !== void 0 ? _bookingData$hasCityT : false,
      paymentOption: (_bookingData$paymentO = bookingData === null || bookingData === void 0 ? void 0 : bookingData.paymentOption) !== null && _bookingData$paymentO !== void 0 ? _bookingData$paymentO : ''
    })]
  }));
}
var containerStyle = {
  ml: 'lg',
  w: {
    mobile: '100%'
  },
  color: 'darkGrey2',
  direction: 'column',
  fontSize: 'lg'
};