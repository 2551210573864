"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "getUnleashDefinitions", {
  enumerable: true,
  get: function get() {
    return _unleash.getUnleashDefinitions;
  }
});
exports.getUnleashToggles = getUnleashToggles;
var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));
var _cookies = _interopRequireDefault(require("cookies"));
var _tracing = require("../utils/tracing");
var _unleash = require("../utils/unleash");
function getUnleashToggles(_x, _x2, _x3) {
  return _getUnleashToggles.apply(this, arguments);
}
function _getUnleashToggles() {
  _getUnleashToggles = (0, _asyncToGenerator2.default)(function* (props, label, flagsWithFallback) {
    var _props$req$url, _props$req;
    var context = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : {};
    var ccuiSession = arguments.length > 4 ? arguments[4] : undefined;
    var cookies = new _cookies.default(props.req, props.res);
    var sessionTracing = (0, _tracing.getDefaultSessionTracing)(cookies);
    return (0, _unleash.getUnleashTogglesServerOrClient)(cookies, label, flagsWithFallback, (_props$req$url = props === null || props === void 0 || (_props$req = props.req) === null || _props$req === void 0 ? void 0 : _props$req.url) !== null && _props$req$url !== void 0 ? _props$req$url : '', props.query, sessionTracing, context, ccuiSession);
  });
  return _getUnleashToggles.apply(this, arguments);
}