"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "LoginFormFooter", {
  enumerable: true,
  get: function get() {
    return _Login.default;
  }
});
var _Login = _interopRequireDefault(require("./Login"));