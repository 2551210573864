"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = billingAddressFormValidation;
var _atoms = require("@whitbread-eos/atoms");
var yup = _interopRequireWildcard(require("yup"));
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && {}.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
function billingAddressFormValidation(_ref) {
  var {
    t,
    currentLang,
    isCompanyNameAdvanceEnabled
  } = _ref;
  var postcodeValidator = (postcode, countryCode) => {
    if (!postcode) {
      return {
        isValid: false,
        errorMessage: t('config.errorMessages.yourDetails.postcode.required')
      };
    }
    var regex = countryCode === 'GB' ? _atoms.FORM_VALIDATIONS.POSTAL_CODE.MATCHES_UK : _atoms.FORM_VALIDATIONS.POSTAL_CODE.MATCHES_DE;
    if (countryCode === 'GB' || countryCode === 'D') {
      if (!regex.test(postcode.trim().toUpperCase())) {
        return {
          isValid: false,
          errorMessage: t('config.errorMessages.yourDetails.postcode.invalid')
        };
      }
      return {
        isValid: true
      };
    } else {
      if (postcode.length > 12) {
        return {
          isValid: false,
          errorMessage: t('config.errorMessages.yourDetails.postcode.max')
        };
      }
      return {
        isValid: true
      };
    }
  };
  var COMPANY_NAME_ADVANCE_SPECIAL_CHARACTERS = isCompanyNameAdvanceEnabled ? _atoms.FORM_VALIDATIONS.COMPANY_NAME_ADVANCE_SPECIAL_CHARACTERS : _atoms.FORM_VALIDATIONS.COMPANY_NAME;
  var formValidationObject = {
    billingAddressSelection: yup.string().required(t('config.errorMessages.yourDetails.address.type')),
    addressLine1: yup.string().when('billingAddressSelection', {
      is: 'DifferentAddress',
      then: schema => {
        return schema.required(t('config.errorMessages.yourDetails.address1.required')).max(35, t('config.errorMessages.yourDetails.address1.max'));
      }
    }),
    addressLine2: yup.string().when('billingAddressSelection', {
      is: 'DifferentAddress',
      then: schema => {
        return schema.max(35, t('config.errorMessages.yourDetails.address2.max')).nullable();
      }
    }),
    addressLine3: yup.string().when('billingAddressSelection', {
      is: 'DifferentAddress',
      then: schema => {
        return schema.max(35, t('config.errorMessages.yourDetails.address3.max')).nullable();
      }
    }),
    addressLine4: yup.string().when('billingAddressSelection', {
      is: 'DifferentAddress',
      then: schema => {
        return schema.max(35, t('config.errorMessages.yourDetails.address4.max')).nullable();
      }
    }),
    postalCode: yup.string().when('billingAddressSelection', {
      is: 'DifferentAddress',
      then: schema => {
        return schema.when('countryCode', {
          is: countryCode => countryCode === 'GB' || countryCode === 'DE',
          then: schema => {
            return schema.test('postcode-validator', function (context) {
              var validation = postcodeValidator(context, this.parent.countryCode);
              if (!validation.isValid) {
                return this.createError({
                  path: this.path,
                  message: validation.errorMessage
                });
              }
              return true;
            });
          },
          otherwise: schema => {
            return schema.required(t('config.errorMessages.yourDetails.postcode.required')).max(_atoms.FORM_VALIDATIONS.POSTAL_CODE.MAX_OTHERS, t('config.errorMessages.yourDetails.postcode.max'));
          }
        });
      }
    }),
    cityName: yup.string().when(['countryCode', 'billingAddressSelection'], {
      is: (countryCode, billingAddressSelection) => (countryCode === 'DE' || currentLang !== 'en') && billingAddressSelection === 'DifferentAddress',
      then: schema => {
        return schema.required(t('config.errorMessages.yourDetails.city.required')).matches(_atoms.FORM_VALIDATIONS.ADDRESS.MATCHES, {
          message: t('config.errorMessages.yourDetails.city.required')
        }).max(_atoms.FORM_VALIDATIONS.CITY.MAX, t('config.errorMessages.yourDetails.city35max.required'));
      }
    }),
    companyName: yup.string().when('addressSelection', {
      is: 'BUSINESS',
      then: schema => {
        return schema.required(t('config.errorMessages.yourDetails.companyName.required')).max(COMPANY_NAME_ADVANCE_SPECIAL_CHARACTERS.MAX, t('config.errorMessages.yourDetails.companyName.max')).matches(COMPANY_NAME_ADVANCE_SPECIAL_CHARACTERS.MATCHES, t('config.errorMessages.yourDetails.companyName.invalid'));
      }
    })
  };
  var formValidationSchema = yup.object().shape(formValidationObject);
  return formValidationSchema;
}