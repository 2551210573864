"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = Section;
var _react = require("@chakra-ui/react");
var _formatters = require("../../utils/formatters");
var _jsxRuntime = require("react/jsx-runtime");
function Section(_ref) {
  var {
    children,
    dataTestId
  } = _ref;
  return (0, _jsxRuntime.jsx)(_react.Box, {
    as: "section",
    "data-testid": (0, _formatters.formatDataTestId)(dataTestId, 'Section'),
    borderBottom: "1px solid var(--chakra-colors-lightGrey4)",
    children: children
  });
}