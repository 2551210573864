"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.CcuiCardType = void 0;
var CcuiCardType = exports.CcuiCardType = function (CcuiCardType) {
  CcuiCardType["NEW_CARD"] = "NEW_CARD";
  CcuiCardType["NEW_PIBA"] = "NEW_PIBA";
  CcuiCardType["PIBA_UK"] = "NEW_PIBA_UK";
  CcuiCardType["PIBA_EU"] = "NEW_PIBA_EU";
  CcuiCardType["ACCOUNT_COMPANY"] = "ACCOUNT_COMPANY";
  CcuiCardType["NON_GUARANTEED"] = "RESERVE_WITHOUT_CARD";
  CcuiCardType["PIBAGB"] = "PIBAGB";
  CcuiCardType["PIBADE"] = "PIBADE";
  return CcuiCardType;
}({});