"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = BookingFlowSteps;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _react = require("@chakra-ui/react");
var _atoms = require("@whitbread-eos/atoms");
var _jsxRuntime = require("react/jsx-runtime");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function BookingFlowSteps(_ref) {
  var {
    steps,
    activeStep
  } = _ref;
  return (0, _jsxRuntime.jsx)(_react.Flex, {
    justifyContent: "flex-end",
    sx: {
      '@media print': {
        display: 'none'
      }
    },
    children: (0, _jsxRuntime.jsx)(_react.Flex, _objectSpread(_objectSpread({}, progressContainerStyle), {}, {
      "data-testid": "progress-indicator-wrapper",
      children: steps.map((step, index) => (0, _jsxRuntime.jsxs)(_react.Flex, _objectSpread(_objectSpread({}, stepWrapperStyles), {}, {
        "data-testid": "step-container",
        children: [step.id > 1 && (0, _jsxRuntime.jsx)(_react.Divider, _objectSpread(_objectSpread({}, dividerStyles(step.title)), {}, {
          "data-testid": "divider-".concat(index)
        })), (0, _jsxRuntime.jsxs)(_react.Flex, _objectSpread(_objectSpread({}, containerStepStyle), {}, {
          children: [(0, _jsxRuntime.jsx)(_react.Box, _objectSpread(_objectSpread({}, assignBoxStyles(step.id, activeStep)), {}, {
            children: (0, _jsxRuntime.jsx)(_react.Center, {
              h: {
                mobile: 'var(--chakra-space-lg)',
                sm: 'var(--chakra-space-xl)'
              },
              children: step.id < activeStep ? (0, _jsxRuntime.jsx)(_atoms.Icon, {
                svg: (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
                  children: [(0, _jsxRuntime.jsx)(_react.Box, {
                    display: {
                      mobile: 'none',
                      sm: 'block'
                    },
                    children: (0, _jsxRuntime.jsx)(_atoms.Tick24, {
                      color: "var(--chakra-colors-baseWhite)",
                      "data-testid": "progress-indicator-icon"
                    })
                  }), (0, _jsxRuntime.jsx)(_react.Box, {
                    display: {
                      mobile: 'block',
                      sm: 'none'
                    },
                    children: (0, _jsxRuntime.jsx)(_atoms.Tick, {
                      color: "var(--chakra-colors-baseWhite)",
                      "data-testid": "progress-indicator-icon"
                    })
                  })]
                })
              }) : (0, _jsxRuntime.jsx)(_react.Text, _objectSpread(_objectSpread({}, assignStepStyles(step.id, activeStep)), {}, {
                children: step.id
              }))
            })
          }), step.id), step.title && (0, _jsxRuntime.jsx)(_react.Text, _objectSpread(_objectSpread({
            "data-testid": "progress-indicator-title"
          }, assignTitleStyles(step.id, activeStep)), {}, {
            children: step.title
          }))]
        }))]
      }), step.id))
    }))
  });
}
function assignBoxStyles(currentIndex, activeStep) {
  return {
    h: {
      mobile: 'var(--chakra-space-lg)',
      sm: 'var(--chakra-space-xl)'
    },
    w: {
      mobile: 'var(--chakra-space-lg)',
      sm: 'var(--chakra-space-xl)'
    },
    borderRadius: 'full',
    bgColor: activeStep === currentIndex && 'darkGrey2' || activeStep > currentIndex && 'primary' || 'lightGrey4'
  };
}
function assignStepStyles(currentIndex, activeStep) {
  return {
    color: activeStep === currentIndex ? 'baseWhite' : 'darkGrey2',
    fontSize: {
      mobile: 'sm',
      sm: 'md'
    },
    fontWeight: 'medium'
  };
}
function assignTitleStyles(currentIndex, activeStep) {
  return {
    display: {
      mobile: 'none',
      md: 'block'
    },
    pt: 'var(--chakra-space-sm)',
    fontSize: 'sm',
    whiteSpace: 'nowrap',
    fontWeight: 'medium',
    color: activeStep >= currentIndex ? 'darkGrey1' : 'lightGrey2'
  };
}
var dividerStyles = title => {
  return {
    orientation: 'horizontal',
    bgColor: 'lightGrey4',
    h: '1px',
    mx: {
      mobile: 'var(--chakra-space-sm)',
      lg: 'var(--chakra-space-md)'
    },
    w: {
      mobile: 'var(--chakra-space-md)',
      sm: 'var(--chakra-space-lg)',
      md: 'var(--chakra-space-5xl)'
    },
    mb: {
      md: title ? 'lg' : ''
    }
  };
};
var progressContainerStyle = {
  justifyContent: 'center',
  alignItems: 'center',
  flexDir: 'row'
};
var containerStepStyle = {
  flexDir: 'column',
  justifyContent: 'center',
  alignItems: 'center'
};
var stepWrapperStyles = {
  flexDirection: 'row',
  justifyContent: 'flex-end',
  alignItems: 'center'
};