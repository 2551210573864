"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ROOM_TYPE_KEY = exports.INITIAL_ROOM_OCCUPANCY = exports.INITIAL_ROOM_DETAILS = exports.INITIAL_LEAD_GUEST_DETAILS = exports.HOTEL_AVAILABILITY_QUERY_KEY = exports.HOTEL_AVAILABILITY_DISCOUNT_RATE_QUERY_KEY = exports.HOTEL_AVAILABILITY_BB_QUERY_KEY = exports.CITYTAX_HOTEL_COUNTRY_EN = exports.CITYTAX_HOTEL_COUNTRY_DE = exports.CHILDREN_PARAM = exports.CHILDREN_NUMBER_KEY = exports.ADULTS_PARAM = exports.ADULTS_NUMBER_KEY = void 0;
var ROOM_TYPE_KEY = exports.ROOM_TYPE_KEY = 'roomType';
var ADULTS_NUMBER_KEY = exports.ADULTS_NUMBER_KEY = 'adultsNumber';
var CHILDREN_NUMBER_KEY = exports.CHILDREN_NUMBER_KEY = 'childrenNumber';
var CHILDREN_PARAM = exports.CHILDREN_PARAM = 'children';
var ADULTS_PARAM = exports.ADULTS_PARAM = 'adults';
var INITIAL_ROOM_DETAILS = exports.INITIAL_ROOM_DETAILS = {
  adults: 1,
  children: 0,
  roomType: 'Double',
  operaRoomType: '',
  roomTypeCode: 'DB'
};
var INITIAL_ROOM_OCCUPANCY = exports.INITIAL_ROOM_OCCUPANCY = {
  displayRoomOccupancy: false,
  price: '',
  guests: ''
};
var INITIAL_LEAD_GUEST_DETAILS = exports.INITIAL_LEAD_GUEST_DETAILS = {
  title: '',
  firstName: '',
  lastName: '',
  emailAddress: '',
  address: {
    addressLine1: '',
    addressLine2: '',
    addressLine3: '',
    postalCode: '',
    cityName: '',
    country: '',
    countryCode: ''
  }
};
var HOTEL_AVAILABILITY_QUERY_KEY = exports.HOTEL_AVAILABILITY_QUERY_KEY = 'hotelAvailability';
var HOTEL_AVAILABILITY_BB_QUERY_KEY = exports.HOTEL_AVAILABILITY_BB_QUERY_KEY = 'hotelAvailabilityBB';
var CITYTAX_HOTEL_COUNTRY_EN = exports.CITYTAX_HOTEL_COUNTRY_EN = 'Germany';
var CITYTAX_HOTEL_COUNTRY_DE = exports.CITYTAX_HOTEL_COUNTRY_DE = 'Deutschland';
var HOTEL_AVAILABILITY_DISCOUNT_RATE_QUERY_KEY = exports.HOTEL_AVAILABILITY_DISCOUNT_RATE_QUERY_KEY = 'hotelAvailabilityDiscountRate';