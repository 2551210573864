"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.BOOKING_STEPS_MAPPING = void 0;
var BOOKING_STEPS_MAPPING = exports.BOOKING_STEPS_MAPPING = {
  ancillaries: 'ancillaries',
  guestDetails: 'guest-details',
  payment: 'payment',
  spf: 'spf'
};