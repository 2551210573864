"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.twinRoomStandardSpecialRequests = exports.twinRoomSpecialRequests = exports.twinRoomImprovedSpecialRequests = exports.ROOM_TYPE = exports.PREMIER_PLUS_ROOM_TYPES = void 0;
var ROOM_TYPE = exports.ROOM_TYPE = {
  STANDARD: 'DBLWIN',
  STANDARD_EXTRA: 'EXTDBL',
  STANDARD_BIGGER: 'BIGWIN',
  PREMIER_PLUS: 'PPLDBL',
  PREMIER_PLUS_VIEW: 'VPPDBL',
  FAMILY_TRIPLE: 'FMTRPL',
  FAMILY_QUAD: 'FMQUAD',
  TRIPLE_SOFA_CHAIR: 'FMTRSC',
  FAMILY_SOFA_BUNK_BED: 'FMTBUNK',
  PREMIER_PLUS_FAMILY: 'PFAMIL',
  TWIN_RM: 'TWINRM',
  TWIN_DOUBLE: 'DBLDBL',
  TWIN_DOUBLE_ZIP_LINK: 'ZPLDBL',
  PDBZPL: 'PDBZPL',
  BRFTWN: 'BRFTWN',
  BRFZPL: 'BRFZPL',
  TWIN_TWO_BEDS: 'twobeds',
  TWIN_DOUBLE_SOFA: 'doublesofa',
  TWIN: 'TWIN',
  DOUBLE: 'DOUBLE',
  SINGLE: 'SINGLE',
  LOWERED_DOUBLE: 'LOWDBL',
  WET_DOUBLE: 'WETDBL',
  LOWERED_TWIN: 'LOWTWN',
  WET_TWIN: 'WETTWN',
  ACCESSIBLE_TWIN: 'ACCWIN',
  LOWERED: 'lowered',
  WET: 'wet'
};
var twinRoomImprovedSpecialRequests = exports.twinRoomImprovedSpecialRequests = ['TW2S'];
var twinRoomStandardSpecialRequests = exports.twinRoomStandardSpecialRequests = ['TWDS'];
var twinRoomSpecialRequests = exports.twinRoomSpecialRequests = [...twinRoomImprovedSpecialRequests, ...twinRoomStandardSpecialRequests];
var PREMIER_PLUS_ROOM_TYPES = exports.PREMIER_PLUS_ROOM_TYPES = [ROOM_TYPE.PREMIER_PLUS, ROOM_TYPE.PDBZPL, ROOM_TYPE.PREMIER_PLUS_FAMILY, ROOM_TYPE.PREMIER_PLUS_VIEW];