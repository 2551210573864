"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var Switch = {
  baseStyle: {
    track: {
      _checked: {
        bg: 'var(--chakra-colors-primary)',
        boxShadow: '0px 0px 8px var(--chakra-colors-btnPrimaryFocusBoxShadow)'
      }
    }
  },
  variants: {
    focusless: {
      track: {
        _focus: {
          boxShadow: 'none'
        }
      }
    }
  }
};
var _default = exports.default = Switch;