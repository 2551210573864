"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));
var _react = require("@chakra-ui/react");
var _reactQuery = require("@tanstack/react-query");
var _api = require("@whitbread-eos/api");
var _atoms = require("@whitbread-eos/atoms");
var _utils = require("@whitbread-eos/utils");
var _nextI18next = require("next-i18next");
var _react2 = require("react");
var _yup = require("yup");
var _jsxRuntime = require("react/jsx-runtime");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function PostcodeAddress(_ref) {
  var _formField$props, _formField$props2, _addressesPostalCode$, _addressesPostalCode$2;
  var {
    formField,
    field,
    reset,
    getValues,
    index,
    setGuestAddress,
    updateManualToggle
  } = _ref;
  var {
    t
  } = (0, _nextI18next.useTranslation)();
  var queryClient = (0, _reactQuery.useQueryClient)();
  var {
    language: currentLang
  } = (0, _utils.useCustomLocale)();
  var [postCodeInput, setPostCodeInput] = (0, _react2.useState)('');
  var [addressesPostalCode, setAddressesPostalCode] = (0, _react2.useState)();
  var [postcodeErrorMessage, setPostcodeErrorMessage] = (0, _react2.useState)('');
  var [isRequestAddressEnabled, setIsRequestAddressEnabled] = (0, _react2.useState)(false);
  var [selectedAddressPostCode, setSelectedAddressPostCode] = (0, _react2.useState)({
    id: '',
    addressText: ''
  });
  var [isAddressSelected, setIsAddressSelected] = (0, _react2.useState)(false);
  var [isDropdownOpen, setIsDropdownOpen] = (0, _react2.useState)(true);
  var [placeholderDropdown, setPlaceholderDropdown] = (0, _react2.useState)('');
  var [isFindBtnClicked, setIsFindBtnClicked] = (0, _react2.useState)(false);
  var baseDataTestId = 'PostcodeAddress';
  var showIcon = (_formField$props = formField.props) === null || _formField$props === void 0 ? void 0 : _formField$props.showIcon;
  var fieldName = ((_formField$props2 = formField.props) === null || _formField$props2 === void 0 ? void 0 : _formField$props2.fieldName) || '';
  var handleInputErrors = function () {
    var _ref2 = (0, _asyncToGenerator2.default)(function* (value) {
      var _validationResult$err, _validationResult$err2;
      var checkError = (0, _yup.string)().required(t('config.errorMessages.yourDetails.postcode.required')).matches(currentLang === 'en' ? _atoms.FORM_VALIDATIONS.POSTAL_CODE.MATCHES_UK : _atoms.FORM_VALIDATIONS.POSTAL_CODE.MATCHES_DE, t('config.errorMessages.yourDetails.postcode.invalid'));
      var validationResult = yield checkError.validate(value.toUpperCase()).catch(err => {
        return err;
      });
      setPostcodeErrorMessage((_validationResult$err = validationResult.errors) === null || _validationResult$err === void 0 ? void 0 : _validationResult$err[0]);
      return (_validationResult$err2 = validationResult.errors) === null || _validationResult$err2 === void 0 ? void 0 : _validationResult$err2[0];
    });
    return function handleInputErrors(_x) {
      return _ref2.apply(this, arguments);
    };
  }();
  var onChangeFn = val => {
    handleInputErrors(val);
    onChange(val);
    setPostCodeInput(val.trim());
    setIsRequestAddressEnabled(false);
    setIsAddressSelected(false);
    setIsFindBtnClicked(false);
  };
  var {
    name,
    value,
    onChange
  } = field;
  var {
    data: addressesData,
    isSuccess: addressesRequestSuccess
  } = (0, _utils.useQueryRequest)(['addresses', postCodeInput], _api.GET_ADDRESSES, {
    searchTerm: postCodeInput
  }, {
    enabled: isRequestAddressEnabled
  }, undefined, true);
  var {
    data: addressInfoData,
    isSuccess: addressInfoRequestSuccess
  } = (0, _utils.useQueryRequest)(['addresses-info', postCodeInput, selectedAddressPostCode.id], _api.GET_FORMATTED_ADDRESS, {
    identifier: encodeURIComponent(selectedAddressPostCode.id)
  }, {
    enabled: isAddressSelected
  }, undefined, true);
  var updateAddress = (0, _react2.useCallback)(data => {
    var {
      postalCode,
      country,
      addressLine1,
      addressLine2,
      addressLine3,
      addressLine4,
      companyName
    } = (data === null || data === void 0 ? void 0 : data.formattedAddress) || {};
    if (setGuestAddress && updateManualToggle && index !== undefined) {
      setGuestAddress(prevItems => {
        var updatedItems = [...prevItems];
        updatedItems[index] = {
          postalCode,
          country,
          addressLine1,
          addressLine2,
          addressLine3,
          addressLine4,
          companyName
        };
        return updatedItems;
      });
      updateManualToggle(index, true);
    }
    if (!reset) {
      return;
    }
    var resetObject = _objectSpread(_objectSpread({}, getValues()), {}, {
      ["".concat(fieldName, "manualAddressToggle")]: (fieldName === null || fieldName === void 0 ? void 0 : fieldName.length) > 0 ? 'billingAddress' : 'manualAddress',
      ["".concat(fieldName, "addressSelection")]: companyName !== null && companyName !== void 0 && companyName.length ? 'BUSINESS' : 'HOME',
      ["".concat(fieldName, "companyName")]: companyName !== null && companyName !== void 0 ? companyName : '',
      ["".concat(fieldName, "addressLine1")]: addressLine1 !== null && addressLine1 !== void 0 ? addressLine1 : '',
      ["".concat(fieldName, "addressLine2")]: addressLine2 !== null && addressLine2 !== void 0 ? addressLine2 : '',
      ["".concat(fieldName, "addressLine3")]: addressLine3 !== null && addressLine3 !== void 0 ? addressLine3 : '',
      ["".concat(fieldName, "addressLine4")]: addressLine4 !== null && addressLine4 !== void 0 ? addressLine4 : '',
      ["".concat(fieldName, "cityName")]: addressLine4,
      ["".concat(fieldName, "postalCode")]: postalCode,
      ["".concat(fieldName, "countryCode")]: country
    });
    reset(resetObject);
  }, [reset]);
  (0, _react2.useEffect)(() => {
    if (addressesPostalCode && addressesPostalCode.partialAddress.length > 0) {
      setPlaceholderDropdown(addressesPostalCode.partialAddress[0].addressText);
      setIsDropdownOpen(true);
    }
  }, [addressesPostalCode]);
  (0, _react2.useEffect)(() => {
    if (addressesRequestSuccess && isFindBtnClicked) {
      var _addressesData$partia;
      setAddressesPostalCode(addressesData);
      if ((addressesData === null || addressesData === void 0 || (_addressesData$partia = addressesData.partialAddress) === null || _addressesData$partia === void 0 ? void 0 : _addressesData$partia.length) === 0) {
        setPostcodeErrorMessage(t('config.errorMessages.yourDetails.postcode.invalid'));
      }
    }
  }, [addressesRequestSuccess, addressesData, addressesData === null || addressesData === void 0 ? void 0 : addressesData.partialAddress, t, isFindBtnClicked]);
  (0, _react2.useEffect)(() => {
    if (addressInfoRequestSuccess && selectedAddressPostCode) {
      updateAddress(addressInfoData);
    }
  }, [selectedAddressPostCode, addressInfoRequestSuccess, addressInfoData, updateAddress]);
  return (0, _jsxRuntime.jsxs)(_react.Box, {
    children: [(0, _jsxRuntime.jsx)(_atoms.Input, _objectSpread(_objectSpread({}, PostCodeStyle), {}, {
      my: "md",
      value: value,
      name: name,
      type: formField.type,
      placeholderText: formField.label,
      label: formField.label,
      error: postcodeErrorMessage,
      "data-testid": (0, _utils.formatDataTestId)(baseDataTestId, 'InputPostCode'),
      onChange: onChangeFn,
      showIcon: showIcon !== undefined ? showIcon : true,
      className: "sessioncamhidetext assist-no-show",
      isDisabled: formField.isDisabled
    })), (0, _jsxRuntime.jsx)(_atoms.Button, _objectSpread(_objectSpread({
      "data-testid": (0, _utils.formatDataTestId)(baseDataTestId, 'FindAddressBtn'),
      onClick: handleFindAddress,
      variant: "tertiary"
    }, buttonStyle), {}, {
      size: "full",
      isDisabled: formField.isDisabled,
      children: t('booking.findAddress')
    })), (addressesPostalCode === null || addressesPostalCode === void 0 ? void 0 : addressesPostalCode.partialAddress) && (addressesPostalCode === null || addressesPostalCode === void 0 || (_addressesPostalCode$ = addressesPostalCode.partialAddress) === null || _addressesPostalCode$ === void 0 ? void 0 : _addressesPostalCode$.length) > 0 && (0, _jsxRuntime.jsx)(_react.Box, {
      className: "assist-no-show",
      children: (0, _jsxRuntime.jsx)(_atoms.Dropdown, {
        dropdownStyles: {
          menuListStyles: menuListStyles
        },
        dataTestId: (0, _utils.formatDataTestId)(baseDataTestId, 'DropdownComp'),
        isOpenMenu: isDropdownOpen,
        placeholder: placeholderDropdown,
        onChange: address => {
          setSelectedAddressPostCode(address);
          setIsAddressSelected(true);
          setIsDropdownOpen(false);
        },
        options: (_addressesPostalCode$2 = addressesPostalCode.partialAddress) === null || _addressesPostalCode$2 === void 0 ? void 0 : _addressesPostalCode$2.map(_ref3 => {
          var {
            id,
            addressText
          } = _ref3;
          return {
            label: addressText,
            id,
            addressText
          };
        }),
        children: _atoms.DropdownCustomContent
      })
    })]
  });
  function handleFindAddress() {
    handleInputErrors(postCodeInput).then(res => {
      if (!res) {
        setIsRequestAddressEnabled(true);
        queryClient.invalidateQueries({
          queryKey: ['addresses']
        });
      }
    });
    setIsFindBtnClicked(true);
  }
}
var menuListStyles = {
  w: {
    mobile: 'full',
    xs: 'full',
    sm: '25.063rem',
    md: '27.563rem',
    xl: '26.25rem'
  },
  maxH: 'var(--chakra-space-52)'
};
var PostCodeStyle = {
  mb: 'xl'
};
var buttonStyle = {
  mt: 'md',
  mb: 'md'
};
var _default = exports.default = (0, _react2.memo)(PostcodeAddress);