"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.VIEW_TYPE = exports.URLParams = exports.ROOM_CODES = exports.MAX_ROOMS_ALLOWED = exports.MAX_NUMBER_OF_NIGHTS_IB = exports.MAX_NUMBER_OF_MONTHS = exports.DEFAULT_SORT_VALUE = exports.DEFAULT_NUMBER = void 0;
var MAX_ROOMS_ALLOWED = exports.MAX_ROOMS_ALLOWED = 4;
var MAX_NUMBER_OF_NIGHTS_IB = exports.MAX_NUMBER_OF_NIGHTS_IB = 14;
var DEFAULT_NUMBER = exports.DEFAULT_NUMBER = 1;
var MAX_NUMBER_OF_MONTHS = exports.MAX_NUMBER_OF_MONTHS = 12;
var ROOM_CODES = exports.ROOM_CODES = {
  single: 'SB',
  accessible: 'DIS',
  double: 'DB',
  family: 'FAM',
  twin: 'TWIN'
};
var DEFAULT_SORT_VALUE = exports.DEFAULT_SORT_VALUE = '1';
var VIEW_TYPE = exports.VIEW_TYPE = {
  mapView: '1',
  listView: '2'
};
var URLParams = exports.URLParams = {
  day: 'ARRdd',
  month: 'ARRmm',
  year: 'ARRyyyy',
  nights: 'NIGHTS',
  adult: 'ADULT',
  child: 'CHILD',
  cot: 'COT',
  roomType: 'INTTYP',
  rooms: 'ROOMS'
};