"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = CardSecurityCheck;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _react = require("@chakra-ui/react");
var _api = require("@whitbread-eos/api");
var _atoms = require("@whitbread-eos/atoms");
var _utils = require("@whitbread-eos/utils");
var _nextI18next = require("next-i18next");
var _react2 = require("react");
var _styles = require("../styles");
var _jsxRuntime = require("react/jsx-runtime");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function CardSecurityCheck(_ref) {
  var {
    billingAddress,
    basketReference,
    setIsSecurityCheckPassed,
    setHasErrors,
    cardHolderNames
  } = _ref;
  var [isModalOpen, setIsModalOpen] = (0, _react2.useState)(false);
  var [isSuccessOpen, setIsSuccessOpen] = (0, _react2.useState)(false);
  var [showSpinner, setShowSpinner] = (0, _react2.useState)(false);
  var {
    t
  } = (0, _nextI18next.useTranslation)(['common']);
  var baseTestId = 'cardSecurityCheck';
  var {
    mutation: fraudCheckMutation,
    isError: isFraudCheckError,
    error: fraudCheckErorr
  } = (0, _utils.useMutationRequest)(_api.FRAUD_SECURITY_CHECK, true);
  (0, _react2.useEffect)(() => {
    if (fraudCheckErorr) {
      setHasErrors(true);
    }
    if (!fraudCheckMutation.isPending && fraudCheckMutation.data) {
      var _fraudCheckMutation$d, _fraudCheckMutation$d2;
      if (((_fraudCheckMutation$d = fraudCheckMutation.data) === null || _fraudCheckMutation$d === void 0 || (_fraudCheckMutation$d = _fraudCheckMutation$d.initiateSecurityCheck) === null || _fraudCheckMutation$d === void 0 ? void 0 : _fraudCheckMutation$d.fraudCheckDecision) === 'REJECT' || ((_fraudCheckMutation$d2 = fraudCheckMutation.data) === null || _fraudCheckMutation$d2 === void 0 || (_fraudCheckMutation$d2 = _fraudCheckMutation$d2.initiateSecurityCheck) === null || _fraudCheckMutation$d2 === void 0 ? void 0 : _fraudCheckMutation$d2.fraudCheckDecision) === '') {
        setIsModalOpen(true);
      } else {
        setIsSuccessOpen(true);
        setIsSecurityCheckPassed(true);
      }
    }
    if (fraudCheckMutation.isPending) {
      var handler = setTimeout(() => {
        setShowSpinner(true);
      }, 4000);
      return () => {
        setShowSpinner(false);
        clearTimeout(handler);
      };
    }
  }, [fraudCheckMutation.data, fraudCheckMutation.isPending]);
  if (showSpinner) {
    return (0, _jsxRuntime.jsx)(_atoms.LoadingSpinner, {
      loadingText: t('booking.loading')
    });
  }
  if (isFraudCheckError) {
    return (0, _jsxRuntime.jsx)(_react.Text, {
      children: fraudCheckErorr.message
    });
  }
  var cardSecurityCheckCountry;
  if ((billingAddress.country || billingAddress.countryCode) === _api.ShortCountry.UK) {
    cardSecurityCheckCountry = _api.ShortCountry.GB;
  } else {
    var _billingAddress$count;
    cardSecurityCheckCountry = (_billingAddress$count = billingAddress.country) !== null && _billingAddress$count !== void 0 ? _billingAddress$count : billingAddress.countryCode;
  }
  return (0, _jsxRuntime.jsxs)(_react.Box, {
    "data-testid": "".concat(baseTestId, "_Component"),
    children: [(0, _jsxRuntime.jsx)(_react.Box, {
      pb: 8,
      children: (0, _jsxRuntime.jsx)(_react.Heading, _objectSpread(_objectSpread({
        as: "h3"
      }, _styles.headerStyles), {}, {
        "data-testid": "".concat(baseTestId, "_title"),
        children: t('ccui.cardSecurityCheck.btnLabel')
      }))
    }), (0, _jsxRuntime.jsx)(_react.Button, {
      size: "md",
      variant: "tertiary",
      onClick: checkFraudStatus,
      "data-testid": "".concat(baseTestId, "_launchButton"),
      w: {
        mobile: 'full',
        xs: 'full',
        sm: '16.63rem',
        md: '18rem',
        lg: '18rem',
        xl: '19.063rem'
      },
      children: t('ccui.cardSecurityCheck.btnLabel')
    }), (0, _jsxRuntime.jsx)(_atoms.ModalVariants, {
      onClose: () => setIsModalOpen(false),
      isOpen: isModalOpen,
      variant: "cookie",
      variantProps: {
        title: t('ccui.cardSecurityCheck.title'),
        delimiter: true
      },
      dataTestId: "".concat(baseTestId, "_Modal"),
      children: (0, _jsxRuntime.jsx)(_react.VStack, {
        w: {
          mobile: 'full',
          xs: 'full',
          sm: '25.063rem',
          md: '37.5rem',
          lg: '37.5rem',
          xl: '36rem'
        },
        children: (0, _jsxRuntime.jsx)(_react.Flex, {
          p: 6,
          w: "full",
          children: (0, _jsxRuntime.jsx)(_react.Button, {
            w: {
              mobile: 'full',
              xs: 'full',
              sm: '25.063rem',
              md: '34.5rem',
              lg: '34.5rem',
              xl: '33rem'
            },
            variant: "primary",
            onClick: () => setIsModalOpen(false),
            "data-testid": "".concat(baseTestId, "_cancel"),
            children: t('ccui.fraudCheck.btnCancel')
          })
        })
      })
    }), (0, _jsxRuntime.jsx)(_atoms.ModalVariants, {
      onClose: () => setIsSuccessOpen(false),
      isOpen: isSuccessOpen,
      variant: "cookie",
      variantProps: {
        title: t('ccui.cardSecurityCheck.titleSuccess'),
        delimiter: true
      },
      dataTestId: "".concat(baseTestId, "_Modal-Success"),
      children: (0, _jsxRuntime.jsx)(_react.VStack, {
        w: {
          mobile: 'full',
          xs: 'full',
          sm: '25.063rem',
          md: '37.5rem',
          lg: '37.5rem',
          xl: '36rem'
        },
        children: (0, _jsxRuntime.jsx)(_react.Flex, {
          p: 6,
          w: "full",
          children: (0, _jsxRuntime.jsx)(_react.Button, {
            variant: "primary",
            onClick: () => setIsSuccessOpen(false),
            "data-testid": "".concat(baseTestId, "_continue"),
            w: {
              mobile: 'full',
              xs: 'full',
              sm: '25.063rem',
              md: '34.5rem',
              lg: '34.5rem',
              xl: '31rem'
            },
            children: t('ccui.cardSecurityCheck.btnContinue')
          })
        })
      })
    })]
  });
  function checkFraudStatus() {
    var _billingAddress$addre, _billingAddress$addre2, _billingAddress$addre3;
    fraudCheckMutation.mutate({
      basketReference: basketReference,
      card: {
        cardHolderFirstName: cardHolderNames.firstName,
        cardHolderLastName: cardHolderNames.lastName,
        cardHolderAddress: {
          addressLine1: billingAddress.addressLine1,
          addressLine2: (_billingAddress$addre = billingAddress.addressLine2) !== null && _billingAddress$addre !== void 0 ? _billingAddress$addre : '',
          addressLine3: (_billingAddress$addre2 = billingAddress.addressLine3) !== null && _billingAddress$addre2 !== void 0 ? _billingAddress$addre2 : '',
          addressLine4: (_billingAddress$addre3 = billingAddress.addressLine4) !== null && _billingAddress$addre3 !== void 0 ? _billingAddress$addre3 : '',
          country: cardSecurityCheckCountry,
          postalCode: billingAddress.postalCode
        }
      }
    });
  }
}