"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.inputsMap = exports.getSearchFields = exports.default = void 0;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _api = require("@whitbread-eos/api");
var _getters = require("../../getters");
var _analytics = _interopRequireDefault(require("./analytics"));
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
var inputsMap = exports.inputsMap = {
  arrivalDate: 'arrival date',
  bookerLastName: 'booking surname',
  bookingReference: 'booking ref',
  thirdPartyBookingReferenceNumber: 'third party booking ref',
  guestLastName: 'guest surname',
  bookerPostcode: 'postcode',
  bookerEmail: 'email',
  bookerPhone: 'tel num',
  cancellationDate: 'cancellation date',
  companyName: 'company name'
};
var getSearchFields = inputs => {
  var searchFields = [];
  for (var key in inputs) {
    var _inputs$key;
    if ((_inputs$key = inputs[key]) !== null && _inputs$key !== void 0 && _inputs$key.length && inputsMap[key]) {
      searchFields.push(inputsMap[key]);
    }
  }
  return searchFields.join(', ');
};
exports.getSearchFields = getSearchFields;
var updateDashboardAnalytics = _ref => {
  var _window, _window2, _window$analyticsData, _window3;
  var {
    bookings,
    totalResults,
    inputs,
    newSearch
  } = _ref;
  var initialData = !newSearch && (_window = window) !== null && _window !== void 0 && (_window = _window.analyticsData) !== null && _window !== void 0 && _window.dashboard ? (_window2 = window) === null || _window2 === void 0 || (_window2 = _window2.analyticsData) === null || _window2 === void 0 ? void 0 : _window2.dashboard : {
    cancelledBookings: 0,
    futureBookings: 0,
    totalBookings: 0,
    totalOperaBookings: 0,
    totalBartBookings: 0,
    moreThanNineNights: 0,
    moreThanFourRooms: 0,
    searchBookingResults: '',
    bookingsReturned: 0,
    userAgentEmail: (_window$analyticsData = (_window3 = window) === null || _window3 === void 0 || (_window3 = _window3.analyticsData) === null || _window3 === void 0 || (_window3 = _window3.dashboard) === null || _window3 === void 0 ? void 0 : _window3.userAgentEmail) !== null && _window$analyticsData !== void 0 ? _window$analyticsData : ''
  };
  if (initialData.bookingsReturned !== undefined && bookings) {
    var dashboard = bookings.reduce((acc, entry) => {
      var _entry$arrivalDate, _entry$departureDate, _entry$stayingGuests;
      if (entry.status === _api.RESERVATION_STATUS.CANCELLED) {
        acc.cancelledBookings++;
      }
      if (entry.status === _api.RESERVATION_STATUS.UPCOMING) {
        acc.futureBookings++;
      }
      if (entry.sourcePms === _api.RESERVATION_SOURCE_PMS.OPERA) {
        acc.totalOperaBookings++;
      }
      if (entry.sourcePms === _api.RESERVATION_SOURCE_PMS.BART) {
        acc.totalBartBookings++;
      }
      if ((0, _getters.getNightsNumber)((_entry$arrivalDate = entry === null || entry === void 0 ? void 0 : entry.arrivalDate) !== null && _entry$arrivalDate !== void 0 ? _entry$arrivalDate : '', (_entry$departureDate = entry === null || entry === void 0 ? void 0 : entry.departureDate) !== null && _entry$departureDate !== void 0 ? _entry$departureDate : '') > 9) {
        acc.moreThanNineNights++;
      }
      if (((_entry$stayingGuests = entry.stayingGuests) === null || _entry$stayingGuests === void 0 ? void 0 : _entry$stayingGuests.length) > 4) {
        acc.moreThanFourRooms++;
      }
      return acc;
    }, _objectSpread(_objectSpread({}, initialData), {}, {
      totalBookings: totalResults,
      bookingsReturned: initialData.bookingsReturned + bookings.length,
      searchBookingResults: getSearchFields(inputs)
    }));
    _analytics.default.update({
      dashboard
    });
  }
};
var _default = exports.default = updateDashboardAnalytics;