"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.GET_HEADER_BOOKING_INFORMATION = void 0;
var _taggedTemplateLiteral2 = _interopRequireDefault(require("@babel/runtime/helpers/taggedTemplateLiteral"));
var _graphqlRequest = require("graphql-request");
var _templateObject;
var GET_HEADER_BOOKING_INFORMATION = exports.GET_HEADER_BOOKING_INFORMATION = (0, _graphqlRequest.gql)(_templateObject || (_templateObject = (0, _taggedTemplateLiteral2.default)(["\n  query GetBookingInformation(\n    $basketReference: String!\n    $language: String!\n    $country: String!\n    $bookingChannelCriteria: BookingChannelCriteria!\n  ) {\n    bookingInformation(\n      basketReference: $basketReference\n      language: $language\n      country: $country\n      bookingChannelCriteria: $bookingChannelCriteria\n    ) {\n      bookingFlowId\n      hotelId\n    }\n  }\n"])));