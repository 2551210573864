"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = FormInput;
var _objectWithoutProperties2 = _interopRequireDefault(require("@babel/runtime/helpers/objectWithoutProperties"));
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _react = require("@chakra-ui/react");
var _reactHookForm = require("react-hook-form");
var _Input = _interopRequireDefault(require("./../../Input"));
var _jsxRuntime = require("react/jsx-runtime");
var _excluded = ["ref"];
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function FormInput(_ref) {
  var _formField$props, _errors$formField$nam;
  var {
    control,
    formField,
    errors
  } = _ref;
  var errorStyles = (_formField$props = formField.props) !== null && _formField$props !== void 0 && _formField$props.useTooltip && !!(errors !== null && errors !== void 0 && (_errors$formField$nam = errors[formField.name]) !== null && _errors$formField$nam !== void 0 && _errors$formField$nam.message) ? tooltipErrorStyles : {};
  return (0, _jsxRuntime.jsx)(_react.Flex, _objectSpread(_objectSpread({
    direction: "column"
  }, _objectSpread(_objectSpread(_objectSpread({}, defaultStyles), formField.styles), errorStyles)), {}, {
    "data-testid": formField.testid,
    "data-fieldname": formField.name,
    children: (0, _jsxRuntime.jsx)(_reactHookForm.Controller, {
      name: formField.name,
      control: control,
      render: _ref2 => {
        var _errors$formField$nam2;
        var {
          field
        } = _ref2;
        var {
            ref: _
          } = field,
          extraPropsField = (0, _objectWithoutProperties2.default)(field, _excluded);
        return (0, _jsxRuntime.jsx)(_Input.default, _objectSpread(_objectSpread(_objectSpread({}, formField.props), extraPropsField), {}, {
          type: formField.type,
          value: extraPropsField.value || '',
          placeholderText: formField.label,
          label: formField.label,
          error: errors === null || errors === void 0 || (_errors$formField$nam2 = errors[formField.name]) === null || _errors$formField$nam2 === void 0 ? void 0 : _errors$formField$nam2.message
        }));
      }
    })
  }));
}
var tooltipErrorStyles = {
  marginBottom: 'var(--chakra-space-6xl)'
};
var defaultStyles = {
  marginBottom: 'var(--chakra-space-md)'
};