"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.SAVE_RESERVATION_ANCILLARIES = void 0;
var _taggedTemplateLiteral2 = _interopRequireDefault(require("@babel/runtime/helpers/taggedTemplateLiteral"));
var _graphqlRequest = require("graphql-request");
var _templateObject;
var SAVE_RESERVATION_ANCILLARIES = exports.SAVE_RESERVATION_ANCILLARIES = (0, _graphqlRequest.gql)(_templateObject || (_templateObject = (0, _taggedTemplateLiteral2.default)(["\n  mutation updateReservationPackagesByReservation(\n    $basketReferenceId: String!\n    $hotelId: String!\n    $arrivalDate: String!\n    $departureDate: String!\n    $roomsSelections: [RoomPackageSelectionByIdInput]\n    $previousRoomsSelections: [RoomPackageSelectionByIdInput]\n  ) {\n    updateReservationPackagesByReservation(\n      updateReservationPackagesRequest: {\n        basketReferenceId: $basketReferenceId\n        hotelId: $hotelId\n        arrivalDate: $arrivalDate\n        departureDate: $departureDate\n        roomsSelections: $roomsSelections\n        previousRoomsSelections: $previousRoomsSelections\n      }\n    )\n  }\n"])));