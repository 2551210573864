"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "BookingSummary", {
  enumerable: true,
  get: function get() {
    return _BookingSummary.default;
  }
});
Object.defineProperty(exports, "BookingSummaryWrapper", {
  enumerable: true,
  get: function get() {
    return _BookingSummary2.default;
  }
});
var _BookingSummary = _interopRequireDefault(require("./BookingSummary.component"));
var _BookingSummary2 = _interopRequireDefault(require("./BookingSummary.wrapper"));