"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.IDV_STATUS_KEY = exports.IDV_INITIAL_DATA = exports.BART_ROOM_TYPE_LABELS_OBJ = void 0;
var BART_ROOM_TYPE_LABELS_OBJ = exports.BART_ROOM_TYPE_LABELS_OBJ = {
  FMTRPL: 'dashboard.bookings.familyRoom',
  FMQUAD: 'dashboard.bookings.familyRoom',
  DOUBLE: 'dashboard.bookings.doubleRoom',
  LOWDBL: 'dashboard.bookings.doubleRoom',
  DIS: 'dashboard.bookings.doubleRoom',
  FAM: 'dashboard.bookings.familyRoom',
  TB: 'dashboard.bookings.familyRoom',
  TBT: 'dashboard.bookings.twinRoom',
  TWIN: 'dashboard.bookings.twinRoom',
  DB: 'dashboard.bookings.doubleRoom'
};
var IDV_INITIAL_DATA = exports.IDV_INITIAL_DATA = {
  passed: false,
  bookingReference: ''
};
var IDV_STATUS_KEY = exports.IDV_STATUS_KEY = 'idvData';