"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.axiosRequest = axiosRequest;
exports.graphQLRequest = graphQLRequest;
exports.useMutationRequest = useMutationRequest;
exports.useQueryRequest = useQueryRequest;
exports.useRestMutationRequest = useRestMutationRequest;
exports.useRestQueryRequest = useRestQueryRequest;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));
var _reactQuery = require("@tanstack/react-query");
var _axios = _interopRequireDefault(require("axios"));
var _cookiesNext = require("cookies-next");
var _config = _interopRequireDefault(require("next/config"));
var _client = require("../gql/client");
var _logger = require("../logger/logger");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function graphQLRequest(_x, _x2, _x3, _x4, _x5) {
  return _graphQLRequest.apply(this, arguments);
}
function _graphQLRequest() {
  _graphQLRequest = (0, _asyncToGenerator2.default)(function* (gqlTemplateString, options, accessToken, proxyOptions, gqlClient) {
    var _document;
    var {
      publicRuntimeConfig = {}
    } = (0, _config.default)() || {};
    var requestHeaders = {};
    var accessTokenAuthorization = accessToken !== null && accessToken !== void 0 ? accessToken : proxyOptions === null || proxyOptions === void 0 ? void 0 : proxyOptions.accessToken;
    if (accessTokenAuthorization) {
      requestHeaders.Authorization = "Bearer ".concat(accessTokenAuthorization);
    }
    if (options !== null && options !== void 0 && options.idToken) {
      requestHeaders.Authorization = "Bearer ".concat(options.idToken);
      delete options.idToken;
    }
    var useProxyAPICookie = (0, _cookiesNext.getCookie)('useProxyAPI');
    var hostURLCookie = String((0, _cookiesNext.getCookie)('hostURL'));
    var url = publicRuntimeConfig.NEXT_PUBLIC_GRAPHQL_ENDPOINT || 'http://localhost:3000/api/graphql';
    if (useProxyAPICookie === true && hostURLCookie === ((_document = document) === null || _document === void 0 || (_document = _document.location) === null || _document === void 0 ? void 0 : _document.origin)) {
      var _document$location$or, _document2;
      url = "".concat((_document$location$or = (_document2 = document) === null || _document2 === void 0 || (_document2 = _document2.location) === null || _document2 === void 0 ? void 0 : _document2.origin) !== null && _document$location$or !== void 0 ? _document$location$or : '', "/api/graphql");
    }
    var errorDetails = {
      errorMessage: '',
      params: {},
      endpoint: publicRuntimeConfig.NEXT_PUBLIC_GRAPHQL_ENDPOINT,
      host: proxyOptions === null || proxyOptions === void 0 ? void 0 : proxyOptions.host
    };
    try {
      var client = gqlClient !== null && gqlClient !== void 0 ? gqlClient : (0, _client.getGQLClient)();
      client.setEndpoint(url);
      return client.request(gqlTemplateString, options, requestHeaders);
    } catch (e) {
      var _e$request;
      errorDetails.errorMessage = e.response;
      errorDetails.params = (_e$request = e.request) === null || _e$request === void 0 ? void 0 : _e$request.variables;
      console.error(JSON.stringify(errorDetails));
    }
  });
  return _graphQLRequest.apply(this, arguments);
}
function useQueryRequest(queryKey, gqlTemplateString, options, reactQueryOptions, accessToken) {
  var returnError = arguments.length > 5 && arguments[5] !== undefined ? arguments[5] : false;
  var qOptions = (0, _reactQuery.queryOptions)(_objectSpread({
    queryKey,
    queryFn: () => graphQLRequest(gqlTemplateString, options, accessToken)
  }, reactQueryOptions));
  var {
    isLoading,
    isError,
    isSuccess,
    isFetching,
    data,
    error,
    refetch
  } = (0, _reactQuery.useQuery)(qOptions);
  if (isError) {
    var sessionTracing = (0, _logger.getClientDefaultSessionTracing)();
    _logger.logger.error({
      label: 'QUERY_REQUEST_ERROR',
      err: error,
      msg: _objectSpread({
        queryKey,
        accessToken
      }, sessionTracing)
    });
  }
  if (!returnError && isError) {
    throw error;
  }
  return {
    isLoading,
    isError,
    isSuccess,
    isFetching,
    data: data,
    error,
    refetch
  };
}
function useMutationRequest(gqlTemplateString) {
  var returnError = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
  var accessToken = arguments.length > 2 ? arguments[2] : undefined;
  var reactQueryOptions = arguments.length > 3 ? arguments[3] : undefined;
  var mutation = (0, _reactQuery.useMutation)(_objectSpread({
    mutationFn: options => graphQLRequest(gqlTemplateString, options, accessToken)
  }, reactQueryOptions));
  if (mutation.error) {
    var sessionTracing = (0, _logger.getClientDefaultSessionTracing)();
    _logger.logger.error({
      label: 'MUTATION_REQUEST_ERROR',
      err: mutation.error,
      msg: _objectSpread({
        accessToken
      }, sessionTracing)
    });
  }
  if (!returnError && mutation.isError) {
    throw mutation.error;
  }
  return {
    mutation: mutation,
    isLoading: mutation.isPending,
    isError: mutation.isError,
    isSuccess: mutation.isSuccess,
    isIdle: mutation.isIdle,
    data: mutation.data,
    error: mutation.error
  };
}
function axiosRequest(_x6) {
  return _axiosRequest.apply(this, arguments);
}
function _axiosRequest() {
  _axiosRequest = (0, _asyncToGenerator2.default)(function* (_ref) {
    var {
      method,
      url,
      data,
      params,
      headers
    } = _ref;
    var {
      publicRuntimeConfig = {}
    } = (0, _config.default)() || {};
    return (0, _axios.default)({
      method,
      url,
      params,
      data,
      headers: _objectSpread({
        Origin: publicRuntimeConfig.NEXT_PUBLIC_ASSETS_URL_WITHOUT_BASIC
      }, headers)
    }).then(response => response.data);
  });
  return _axiosRequest.apply(this, arguments);
}
function useRestQueryRequest(queryKey, method, url, headers, reactQueryOptions, body) {
  var qOptions = (0, _reactQuery.queryOptions)(_objectSpread({
    queryKey,
    queryFn: () => axiosRequest({
      method,
      url,
      headers,
      data: body
    })
  }, reactQueryOptions));
  var {
    isLoading,
    isError,
    isSuccess,
    isFetching,
    data,
    error,
    refetch
  } = (0, _reactQuery.useQuery)(qOptions);
  if (isError) {
    var sessionTracing = (0, _logger.getClientDefaultSessionTracing)();
    _logger.logger.error({
      label: 'REST_QUERY_REQUEST_ERROR',
      err: error,
      msg: _objectSpread({
        queryKey,
        url
      }, sessionTracing)
    });
  }
  return {
    isLoading,
    isError,
    isSuccess,
    isFetching,
    data: data,
    error,
    refetch
  };
}
function useRestMutationRequest(url, method, headers) {
  var mutation = (0, _reactQuery.useMutation)({
    mutationFn: data => axiosRequest({
      method,
      url,
      data,
      headers
    })
  });
  if (mutation.error) {
    var sessionTracing = (0, _logger.getClientDefaultSessionTracing)();
    _logger.logger.error({
      label: 'REST_MUTATION_REQUEST_ERROR',
      err: mutation.error,
      msg: _objectSpread({
        url
      }, sessionTracing)
    });
  }
  return {
    mutation,
    isLoading: mutation.isPending,
    isError: mutation.isError,
    isSuccess: mutation.isSuccess,
    isIdle: mutation.isIdle,
    data: mutation.data,
    error: mutation.error,
    status: mutation.status
  };
}