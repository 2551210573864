"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.createOptionsAdultsChildrenDropdown = createOptionsAdultsChildrenDropdown;
exports.getAdultsChildrenOptions = getAdultsChildrenOptions;
exports.getMappedRooms = getMappedRooms;
exports.getRoomTypeOptions = getRoomTypeOptions;
function createOptionsAdultsChildrenDropdown(arr, labelSingular, labelPlural) {
  return arr.map(option => {
    return option === 1 ? {
      id: option,
      label: "".concat(option, " ").concat(labelSingular)
    } : {
      id: option,
      label: "".concat(option, " ").concat(labelPlural)
    };
  });
}
function getAdultsChildrenOptions(roomOccupanciesObj, option) {
  var adultsChildrenNumberArr = roomOccupanciesObj.roomOccupancyLimitations.roomOccupancies.map(occupancy => occupancy[option]);
  return adultsChildrenNumberArr.filter((element, index, array) => array.indexOf(element) === index).sort((index, nextIndex) => index - nextIndex);
}
function getRoomTypeOptions(room, roomOccupancies, roomTypeOccupancies, roomCodes) {
  var roomOccupancy = roomOccupancies.find(occupancy => {
    return occupancy.adultsNumber === room.adults && occupancy.childrenNumber === room.children;
  });
  var roomTypesArr = (roomOccupancy === null || roomOccupancy === void 0 ? void 0 : roomOccupancy.acceptedRoomTypes) || [];
  return roomTypesArr === null || roomTypesArr === void 0 ? void 0 : roomTypesArr.map(option => {
    var roomType = roomTypeOccupancies[roomCodes[option]];
    var roomTypeOption = roomType.charAt(0).toUpperCase() + roomType.slice(1);
    return {
      id: roomTypeOption,
      label: roomTypeOption,
      code: option
    };
  });
}
function getMappedRooms(roomCodes, translations) {
  var mappedRoomLabelsArray = Object.keys(roomCodes).map(roomCode => {
    var key = translations[roomCode];
    return {
      ["".concat(key)]: roomCodes[roomCode]
    };
  });
  return Object.assign({}, ...mappedRoomLabelsArray);
}