"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
var _dashboardManageBooking = require("./dashboardManageBooking");
Object.keys(_dashboardManageBooking).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _dashboardManageBooking[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _dashboardManageBooking[key];
    }
  });
});
var _dashboardCancelReservation = require("./dashboardCancelReservation");
Object.keys(_dashboardCancelReservation).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _dashboardCancelReservation[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _dashboardCancelReservation[key];
    }
  });
});
var _dashboardOUpdateOverrideReservation = require("./dashboardOUpdateOverrideReservation");
Object.keys(_dashboardOUpdateOverrideReservation).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _dashboardOUpdateOverrideReservation[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _dashboardOUpdateOverrideReservation[key];
    }
  });
});
var _findBooking = require("./findBooking");
Object.keys(_findBooking).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _findBooking[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _findBooking[key];
    }
  });
});
var _getDashboardBasket = require("./getDashboardBasket");
Object.keys(_getDashboardBasket).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _getDashboardBasket[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _getDashboardBasket[key];
    }
  });
});
var _getDashboardBookingConfirmationAuthenticated = require("./getDashboardBookingConfirmationAuthenticated");
Object.keys(_getDashboardBookingConfirmationAuthenticated).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _getDashboardBookingConfirmationAuthenticated[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _getDashboardBookingConfirmationAuthenticated[key];
    }
  });
});
var _getDashboardBookingConfirmation = require("./getDashboardBookingConfirmation");
Object.keys(_getDashboardBookingConfirmation).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _getDashboardBookingConfirmation[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _getDashboardBookingConfirmation[key];
    }
  });
});
var _getOverrideReasons = require("./getOverrideReasons");
Object.keys(_getOverrideReasons).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _getOverrideReasons[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _getOverrideReasons[key];
    }
  });
});
var _getDashboardDonationsPackages = require("./getDashboardDonationsPackages");
Object.keys(_getDashboardDonationsPackages).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _getDashboardDonationsPackages[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _getDashboardDonationsPackages[key];
    }
  });
});
var _getBookingHistory = require("./getBookingHistory");
Object.keys(_getBookingHistory).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _getBookingHistory[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _getBookingHistory[key];
    }
  });
});
var _getBookingInfoCardDetails = require("./getBookingInfoCardDetails");
Object.keys(_getBookingInfoCardDetails).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _getBookingInfoCardDetails[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _getBookingInfoCardDetails[key];
    }
  });
});
var _bookingHistoryCancelBooking = require("./bookingHistoryCancelBooking");
Object.keys(_bookingHistoryCancelBooking).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _bookingHistoryCancelBooking[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _bookingHistoryCancelBooking[key];
    }
  });
});
var _resendInvoice = require("./resendInvoice");
Object.keys(_resendInvoice).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _resendInvoice[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _resendInvoice[key];
    }
  });
});
var _roomTypes = require("./roomTypes");
Object.keys(_roomTypes).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _roomTypes[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _roomTypes[key];
    }
  });
});
var _resendConfirmationEmail = require("./resendConfirmationEmail");
Object.keys(_resendConfirmationEmail).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _resendConfirmationEmail[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _resendConfirmationEmail[key];
    }
  });
});