"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = HeaderBusiness;
var _react = require("@chakra-ui/react");
var _api = require("@whitbread-eos/api");
var _utils = require("@whitbread-eos/utils");
var _nextI18next = require("next-i18next");
var _HeaderVariantStep = _interopRequireDefault(require("../HeaderLeisure/HeaderVariantStep/HeaderVariantStep.container"));
var _HeaderBusinessVariantDefault = _interopRequireDefault(require("./HeaderBusinessVariantDefault"));
var _jsxRuntime = require("react/jsx-runtime");
function HeaderBusiness(_ref) {
  var {
    variant,
    queryClient
  } = _ref;
  var {
    language,
    country
  } = (0, _utils.useCustomLocale)();
  var {
    t
  } = (0, _nextI18next.useTranslation)();
  var {
    data: BBHeaderInfo,
    isLoading
  } = (0, _utils.useQueryRequest)(['GetStaticContent', language, country], _api.GET_STATIC_CONTENT, {
    language,
    country,
    site: _api.SITE_BB,
    businessBooker: true
  });
  if (isLoading) {
    return (0, _jsxRuntime.jsx)(_react.Text, {
      style: {
        display: 'none'
      },
      children: t('searchresults.list.hotel.loading')
    });
  }
  return renderHeader(BBHeaderInfo, variant, queryClient);
}
function renderHeader(BBHeaderInfo, variant, queryClient) {
  switch (variant) {
    case 'business-step':
      {
        return (0, _jsxRuntime.jsx)(_HeaderVariantStep.default, {
          headerInfoData: BBHeaderInfo.headerInformation,
          queryClient: queryClient,
          bb: true
        });
      }
    case 'business-default':
    default:
      {
        return (0, _jsxRuntime.jsx)(_HeaderBusinessVariantDefault.default, {
          BBHeaderInfo: BBHeaderInfo.headerInformation
        });
      }
  }
}