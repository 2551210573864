"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = TotalCostPayment;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _api = require("@whitbread-eos/api");
var _molecules = require("@whitbread-eos/molecules");
var _utils = require("@whitbread-eos/utils");
var _jsxRuntime = require("react/jsx-runtime");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function TotalCostPayment(_ref) {
  var {
    hotelName,
    hotelId,
    rateCode,
    selectedPaymentDetail,
    ratePlan,
    isBillingAddressDisplayed,
    errorMessagePayment,
    bookingChannel,
    continueToNextStep,
    isAmendPage,
    setValidateQuestions,
    hasError,
    selectedPaymentType,
    paypalOptions,
    additionalAmountLabel
  } = _ref;
  var {
    language,
    country
  } = (0, _utils.useCustomLocale)();
  var {
    data,
    isError,
    error,
    isLoading
  } = (0, _utils.useQueryRequest)(['GetTermsAndConditions', hotelId, country, language, rateCode, bookingChannel], _api.GET_TERMS_AND_CONDITIONS_QUERY, {
    country,
    language,
    hotelId,
    rateCode,
    bookingChannel
  });
  return (0, _jsxRuntime.jsx)(_molecules.TotalCostCard, _objectSpread(_objectSpread(_objectSpread({
    data: data,
    isError,
    error,
    isLoading,
    hotelName: hotelName,
    selectedPaymentDetail: selectedPaymentDetail,
    ratePlan: ratePlan,
    isBillingAddressDisplayed: isBillingAddressDisplayed,
    errorMessagePayment: errorMessagePayment,
    continueToNextStep: continueToNextStep,
    isAmendPage: isAmendPage,
    setValidateQuestions: setValidateQuestions,
    hasError: hasError
  }, selectedPaymentType && {
    selectedPaymentType
  }), paypalOptions && {
    paypalOptions
  }), {}, {
    additionalAmountLabel: additionalAmountLabel
  }));
}