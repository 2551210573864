"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = KeyHotelFactsContainer;
var _utils = require("@whitbread-eos/utils");
var _KeyHotelFacts = _interopRequireDefault(require("./KeyHotelFacts.component"));
var _jsxRuntime = require("react/jsx-runtime");
function KeyHotelFactsContainer(_ref) {
  var {
    channel
  } = _ref;
  var {
    facts,
    isLoading,
    isError,
    error
  } = (0, _utils.useStaticHotelInformation)();
  return (0, _jsxRuntime.jsx)(_KeyHotelFacts.default, {
    facts,
    isLoading,
    isError,
    error,
    channel
  });
}