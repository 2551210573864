"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = AuthSideNav;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _react = require("@chakra-ui/react");
var _link = _interopRequireDefault(require("next/link"));
var _HeaderSideNav = _interopRequireDefault(require("../HeaderSideNav"));
var _NavigationMenuMobile = require("../NavigationMenuMobile.style");
var _jsxRuntime = require("react/jsx-runtime");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function AuthSideNav(_ref) {
  var {
    onClickHeaderTitle,
    header,
    logout,
    labels
  } = _ref;
  var accountLinks = labels === null || labels === void 0 ? void 0 : labels.accountLinks;
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_HeaderSideNav.default, {
      title: header,
      onClickTitle: onClickHeaderTitle,
      dataTestId: "Global-UserName-Mobile"
    }), (0, _jsxRuntime.jsxs)(_react.ModalBody, {
      p: "0",
      "data-testid": "authSideNav",
      children: [accountLinks === null || accountLinks === void 0 ? void 0 : accountLinks.map(accountLink => (0, _jsxRuntime.jsx)(_react.Box, _objectSpread(_objectSpread({}, _NavigationMenuMobile.containerItemStyle), {}, {
        children: (0, _jsxRuntime.jsx)(_link.default, {
          href: accountLink.url,
          legacyBehavior: true,
          children: (0, _jsxRuntime.jsx)(_react.Text, {
            "data-testid": "".concat(accountLink.title, "Button-Mobile"),
            children: accountLink.title
          })
        })
      }))), (0, _jsxRuntime.jsx)(_react.Box, _objectSpread(_objectSpread({}, _NavigationMenuMobile.containerItemStyle), {}, {
        onClick: logout,
        "data-testid": "Global-Logout-Mobile",
        children: labels === null || labels === void 0 ? void 0 : labels.logOut
      }))]
    })]
  });
}