"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.GET_DONATIONS_QUERY = void 0;
var _taggedTemplateLiteral2 = _interopRequireDefault(require("@babel/runtime/helpers/taggedTemplateLiteral"));
var _graphqlRequest = require("graphql-request");
var _templateObject;
var GET_DONATIONS_QUERY = exports.GET_DONATIONS_QUERY = (0, _graphqlRequest.gql)(_templateObject || (_templateObject = (0, _taggedTemplateLiteral2.default)(["\n  query getDonations(\n    $country: String!\n    $language: String!\n    $hotelId: String!\n    $rateCode: String!\n    $bookingChannel: String!\n  ) {\n    donations(\n      bookingFlowCriteria: {\n        country: $country\n        language: $language\n        hotelId: $hotelId\n        rateCode: $rateCode\n        bookingChannel: $bookingChannel\n      }\n    ) {\n      description\n      imageSrc\n      name\n      informationBox\n      donationPackages {\n        code\n        currency\n        unitPrice\n      }\n    }\n  }\n"])));