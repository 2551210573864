"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = Filters;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _react = require("@chakra-ui/react");
var _atoms = require("@whitbread-eos/atoms");
var _react2 = require("react");
var _jsxRuntime = require("react/jsx-runtime");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
var LIFT_ACCESS_CODE = 'LFT';
var CLEAR_FILTERS_ENABLER = 2;
function Filters(_ref) {
  var _code$freeParking, _info$lift, _info$lift2;
  var {
    defaultFilters,
    isDisabled = false,
    onChangeFilters,
    labels
  } = _ref;
  var [isFilterOpen, setIsFilterOpen] = (0, _react2.useState)(false);
  var [selectedFilters, setSelectedFilters] = (0, _react2.useState)(defaultFilters);
  var ref = (0, _react2.useRef)(null);
  (0, _react.useOutsideClick)({
    ref: ref,
    handler: () => setIsFilterOpen(false)
  });
  var {
    filterByButton,
    filters
  } = labels;
  var {
    label,
    code,
    info
  } = filters;
  var PARKING_FILTERS = [{
    name: label === null || label === void 0 ? void 0 : label.freeParking,
    code: code === null || code === void 0 || (_code$freeParking = code.freeParking) === null || _code$freeParking === void 0 ? void 0 : _code$freeParking[0]
  }, {
    name: label === null || label === void 0 ? void 0 : label.chargeableOnsiteParking,
    code: code === null || code === void 0 ? void 0 : code.chargeableOnsiteParking
  }, {
    name: label === null || label === void 0 ? void 0 : label.chargeableOffsiteParking,
    code: code === null || code === void 0 ? void 0 : code.chargeableOffsiteParking
  }];
  var FACILITIES_FILTERS = [{
    name: label === null || label === void 0 ? void 0 : label.airCon,
    code: code === null || code === void 0 ? void 0 : code.airCon
  }, {
    name: label === null || label === void 0 ? void 0 : label.lift,
    code: code === null || code === void 0 ? void 0 : code.lift
  }, {
    name: label === null || label === void 0 ? void 0 : label.meet,
    code: code === null || code === void 0 ? void 0 : code.meet
  }, {
    name: label === null || label === void 0 ? void 0 : label.restaurant,
    code: code === null || code === void 0 ? void 0 : code.restaurant
  }];
  (0, _react2.useEffect)(() => {
    document.body.style.overflow = isFilterOpen ? 'hidden' : 'auto';
  }, [isFilterOpen]);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_react.Slide, {
      direction: 'left',
      in: isFilterOpen,
      unmountOnExit: true,
      style: {
        zIndex: 999,
        background: 'rgba(0,0,0,0.5)'
      },
      children: (0, _jsxRuntime.jsxs)(_react.Box, _objectSpread(_objectSpread({}, panelStyles), {}, {
        "data-testid": "SRP-Filters-content",
        ref: ref,
        children: [(0, _jsxRuntime.jsxs)(_react.Flex, {
          justifyContent: "space-between",
          alignItems: "center",
          mt: "2.5rem",
          children: [(0, _jsxRuntime.jsx)(_react.Heading, _objectSpread(_objectSpread({
            as: "h2"
          }, headingTextStyles), {}, {
            children: label === null || label === void 0 ? void 0 : label.header
          })), (0, _jsxRuntime.jsx)(_atoms.Icon, {
            svg: (0, _jsxRuntime.jsx)(_atoms.Dismiss, {
              "data-testid": "SRP-Filters-Close-button"
            }),
            onClick: () => setIsFilterOpen(false),
            style: {
              cursor: 'pointer'
            }
          })]
        }), selectedFilters.length >= CLEAR_FILTERS_ENABLER && (0, _jsxRuntime.jsx)(_react.Text, _objectSpread(_objectSpread({}, clearFiltersStyles), {}, {
          onClick: handleClearFilters,
          "data-testid": "SRP-Clear-filters",
          children: label === null || label === void 0 ? void 0 : label.reset
        })), (0, _jsxRuntime.jsx)(_react.Divider, _objectSpread({}, dividerStyles)), (0, _jsxRuntime.jsx)(_react.Heading, _objectSpread(_objectSpread({
          as: "h3",
          mb: "sm"
        }, headingTextStyles), {}, {
          children: label === null || label === void 0 ? void 0 : label.parking
        })), createCheckboxList(PARKING_FILTERS, (_info$lift = info === null || info === void 0 ? void 0 : info.lift) !== null && _info$lift !== void 0 ? _info$lift : ''), (0, _jsxRuntime.jsx)(_react.Divider, _objectSpread({}, dividerStyles)), (0, _jsxRuntime.jsx)(_react.Heading, _objectSpread(_objectSpread({
          as: "h3",
          mb: "sm"
        }, headingTextStyles), {}, {
          children: label === null || label === void 0 ? void 0 : label.facilities
        })), createCheckboxList(FACILITIES_FILTERS, (_info$lift2 = info === null || info === void 0 ? void 0 : info.lift) !== null && _info$lift2 !== void 0 ? _info$lift2 : '')]
      }))
    }), (0, _jsxRuntime.jsx)(_react.Button, _objectSpread(_objectSpread({
      "data-testid": "SRP-controls-filter-by-button",
      size: "sm",
      variant: "genericSecondary",
      mr: {
        base: 'md',
        lg: 'lg'
      },
      isDisabled: isDisabled,
      onClick: () => setIsFilterOpen(true)
    }, filterButtonWidth), {}, {
      children: filterByButton
    }))]
  });
  function createCheckboxList(items, liftInfoMessage) {
    return items.map(item => (0, _jsxRuntime.jsx)(_atoms.Checkbox, {
      my: "xs",
      value: item.code,
      isChecked: isItemChecked(item),
      "data-testid": "SRP-Filters-checkbox-".concat(item.code),
      onChange: handleFilterChange,
      children: item.code === LIFT_ACCESS_CODE ? (0, _jsxRuntime.jsxs)(_react.Flex, {
        direction: "row",
        display: "inline-flex",
        children: [(0, _jsxRuntime.jsx)(_react.Text, {
          children: item.name
        }), (0, _jsxRuntime.jsx)(_atoms.Tooltip, _objectSpread(_objectSpread({
          description: liftInfoMessage,
          variant: "facilities",
          placement: "bottom-start",
          position: "relative",
          alertElementStyles: alertStyles,
          "data-testid": "SRP-Filters-Lift-access-info-tooltip"
        }, tooltipStyles), {}, {
          children: (0, _jsxRuntime.jsx)(_react.Text, {
            as: "div",
            children: (0, _jsxRuntime.jsx)(_atoms.Icon, {
              ml: "sm",
              mt: "xs",
              svg: (0, _jsxRuntime.jsx)(_atoms.Info, {})
            })
          })
        }))]
      }) : (0, _jsxRuntime.jsx)(_react.Text, {
        children: item.name
      })
    }, item.code));
  }
  function handleFilterChange(e) {
    var newState = e.target.checked ? [...selectedFilters, e.target.value] : selectedFilters.filter(elem => elem !== e.target.value);
    setSelectedFilters(newState);
    onChangeFilters(newState);
  }
  function handleClearFilters() {
    setSelectedFilters([]);
    onChangeFilters([]);
  }
  function isItemChecked(item) {
    return selectedFilters.some(filterCode => filterCode === item.code);
  }
}
var filterButtonWidth = {
  w: {
    mobile: '8.75rem',
    xs: '10.25rem',
    sm: '7.75rem',
    lg: '11.5rem'
  }
};
var panelStyles = {
  background: 'baseWhite',
  height: 'full',
  width: 'full',
  px: 'lg',
  maxWidth: {
    base: '19rem',
    sm: '21.875rem',
    md: '23.25rem',
    lg: '26.25rem',
    xl: '30.375rem'
  },
  overflow: 'hidden',
  zIndex: '9999'
};
var headingTextStyles = {
  fontSize: 'md',
  fontWeight: 'bold',
  lineHeight: '3',
  color: 'darkGrey1'
};
var dividerStyles = {
  borderColor: 'lightGrey2',
  my: 'lg'
};
var alertStyles = {
  py: 'sm',
  padding: 0,
  w: '11.625rem',
  h: '6.125rem'
};
var tooltipStyles = {
  marginLeft: '-1rem',
  fontSize: 'sm',
  lineHeight: '2',
  paddingLeft: 0,
  fontWeight: 'normal',
  top: 'xs'
};
var clearFiltersStyles = {
  fontSize: 'md',
  fontWeight: 'normal',
  lineHeight: '3',
  textDecoration: 'underline',
  color: 'btnSecondaryEnabled',
  marginTop: 'md',
  marginBottom: 'lg',
  _hover: {
    cursor: 'pointer'
  }
};