"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = BookingSummaryWrapper;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _react = require("@chakra-ui/react");
var _api = require("@whitbread-eos/api");
var _atoms = require("@whitbread-eos/atoms");
var _molecules = require("@whitbread-eos/molecules");
var _utils = require("@whitbread-eos/utils");
var _nextI18next = require("next-i18next");
var _react2 = _interopRequireWildcard(require("react"));
var _BookingSummaryCost = _interopRequireDefault(require("./BookingSummaryCost"));
var _index = require("./index");
var _jsxRuntime = require("react/jsx-runtime");
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && {}.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function BookingSummaryWrapper(_ref) {
  var _bookingInformation$r, _bookingInformation$r2;
  var {
    language,
    bookingInformation,
    roomsPackages,
    originalArrivalDate,
    originalDepartureDate,
    stayDatesLabels,
    roomsAndGuestsLabels,
    bookingSummaryLabels,
    summaryOfPayments,
    summaryOfPaymentsLabels,
    isConfirmButtonEnabled,
    onConfirmChanges,
    handleRedirectToAmendPayment,
    isRedirectToAmendPaymentEnabled,
    hideConfirmButton,
    variant,
    setEmailCallback,
    showEciLcoNotification,
    extrasItemsPrices
  } = _ref;
  var baseDataTestId = 'amend-booking-summary';
  var {
    t
  } = (0, _nextI18next.useTranslation)();
  var bookingSummaryComponent = (0, _jsxRuntime.jsx)(_index.BookingSummary, {
    language: language,
    baseDataTestId: baseDataTestId,
    bookingInformation: bookingInformation,
    roomsPackages: roomsPackages,
    originalArrivalDate: originalArrivalDate,
    originalDepartureDate: originalDepartureDate,
    stayDatesLabels: stayDatesLabels,
    bookingSummaryLabels: bookingSummaryLabels,
    roomsAndGuestsLabels: roomsAndGuestsLabels,
    extrasItemsPrices: extrasItemsPrices
  });
  var accordionItems = [{
    title: bookingSummaryLabels === null || bookingSummaryLabels === void 0 ? void 0 : bookingSummaryLabels.expandDetail,
    content: bookingSummaryComponent
  }];
  var {
    isLessThanLg
  } = (0, _utils.useScreenSize)();
  var isCCUI = variant === _api.Area.CCUI;
  var [isModalVisible, setIsModalVisible] = (0, _react2.useState)(false);
  var onModalClose = () => setIsModalVisible(prevState => !prevState);
  var handleAmendSubmit = () => {
    if (isCCUI) {
      setIsModalVisible(true);
      return;
    }
    onConfirmChanges();
  };
  return (0, _jsxRuntime.jsxs)(_react.Flex, {
    "data-testid": (0, _utils.formatDataTestId)(baseDataTestId, 'section'),
    direction: "column",
    children: [(0, _jsxRuntime.jsxs)(_react.Box, _objectSpread(_objectSpread({}, bookingSummaryStyle), {}, {
      children: [(0, _jsxRuntime.jsx)(_react.Text, _objectSpread(_objectSpread({
        "data-testid": (0, _utils.formatDataTestId)(baseDataTestId, 'title')
      }, titleTextStyle), {}, {
        children: bookingSummaryLabels.title
      })), isLessThanLg ? (0, _jsxRuntime.jsx)(_atoms.Accordion, {
        accordionItems: accordionItems,
        bgColor: 'baseWhite',
        accordionOverwriteStyles: accordionOverwriteStyles
      }) : bookingSummaryComponent, summaryOfPayments && (0, _jsxRuntime.jsx)(_BookingSummaryCost.default, {
        language: language,
        baseDataTestId: baseDataTestId,
        currency: bookingInformation.currencyCode,
        summaryOfPayments: summaryOfPayments,
        summaryOfPaymentsLabels: summaryOfPaymentsLabels
      }), isLessThanLg && !hideConfirmButton && (0, _jsxRuntime.jsx)(_atoms.Button, _objectSpread(_objectSpread({
        size: "sm",
        variant: "primary",
        name: "confirm-changes-button"
      }, buttonStyles), {}, {
        width: "full",
        isDisabled: !isConfirmButtonEnabled,
        onClick: isRedirectToAmendPaymentEnabled ? handleRedirectToAmendPayment : handleAmendSubmit,
        children: isRedirectToAmendPaymentEnabled ? bookingSummaryLabels.continueToPaymentLabel : bookingSummaryLabels.confirmChangesLabel
      }))]
    })), showEciLcoNotification && (0, _jsxRuntime.jsx)(_react.Box, _objectSpread(_objectSpread({
      mt: "lg",
      "data-testid": (0, _utils.formatDataTestId)(baseDataTestId, 'Notification-Info-EciLco')
    }, notificationInfoEciStyle), {}, {
      children: (0, _jsxRuntime.jsx)(_atoms.Notification, {
        variant: "info",
        status: "info",
        description: (0, _utils.renderSanitizedHtml)(t('ancillaries.amend.removal.notification')),
        svg: (0, _jsxRuntime.jsx)(_atoms.Info, {})
      })
    })), !isLessThanLg && !hideConfirmButton && (0, _jsxRuntime.jsx)(_atoms.Button, _objectSpread(_objectSpread({
      variant: "primary",
      size: "sm",
      name: "confirm-changes-button"
    }, buttonStyles), {}, {
      mb: "md",
      isDisabled: !isConfirmButtonEnabled,
      onClick: isRedirectToAmendPaymentEnabled ? handleRedirectToAmendPayment : handleAmendSubmit,
      children: isRedirectToAmendPaymentEnabled ? bookingSummaryLabels.continueToPaymentLabel : bookingSummaryLabels.confirmChangesLabel
    })), isCCUI && (0, _jsxRuntime.jsx)(_molecules.AmendEmailAddressModal, {
      isModalVisible: isModalVisible,
      bookingEmail: (_bookingInformation$r = (_bookingInformation$r2 = bookingInformation.reservationByIdList[0]) === null || _bookingInformation$r2 === void 0 || (_bookingInformation$r2 = _bookingInformation$r2.billing) === null || _bookingInformation$r2 === void 0 ? void 0 : _bookingInformation$r2.email) !== null && _bookingInformation$r !== void 0 ? _bookingInformation$r : '',
      onConfirmChanges: onConfirmChanges,
      onModalClose: onModalClose,
      setEmailCallback: setEmailCallback
    })]
  });
}
var accordionContainerStyle = {
  borderX: 'none'
};
var accordionItemButtonStyle = {
  p: 'var(--chakra-space-lg) 0 var(--chakra-space-xs) 0',
  borderBottom: '1px solid var(--chakra-colors-lightGrey4)'
};
var accordionItemTextStyle = {
  fontSize: 'md',
  fontWeight: 'bold',
  lineHeight: '3'
};
var accordionItemPanelStyle = {
  padding: 0
};
var accordionItemStyle = {
  border: 0
};
var accordionOverwriteStyles = {
  container: accordionContainerStyle,
  button: accordionItemButtonStyle,
  text: accordionItemTextStyle,
  panel: accordionItemPanelStyle,
  item: accordionItemStyle
};
var bookingSummaryStyle = {
  width: {
    base: 'full',
    lg: '18.063rem',
    xl: '19.313rem'
  },
  mt: {
    mobile: '0px',
    lg: '5xl'
  },
  borderRadius: '0.313rem',
  border: '1px solid var(--chakra-colors-lightGrey3)',
  pt: 'lg',
  pb: 'md',
  px: 'md',
  color: 'darkGrey1'
};
var buttonStyles = {
  w: 'full',
  mt: 'lg'
};
var titleTextStyle = {
  fontSize: '2xl',
  lineHeight: '4',
  color: 'darkGrey1',
  as: 'h3',
  fontWeight: 'bold'
};
var notificationInfoEciStyle = {
  width: {
    base: 'full',
    lg: '18.063rem',
    xl: '19.313rem'
  }
};