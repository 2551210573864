"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = AuthContentManagerPIVariant;
var _atoms = require("@whitbread-eos/atoms");
var _react = require("react");
var _LogIn = require("../../LogIn");
var _ResetPassword = require("../../ResetPassword");
var _jsxRuntime = require("react/jsx-runtime");
function AuthContentManagerPIVariant(_ref) {
  var {
    isLoginModalOpen,
    toggleLoginModal,
    labels
  } = _ref;
  var [isLoginForm, setIsLoginForm] = (0, _react.useState)(true);
  var [loginDefaultValues, setLoginDefaultValues] = (0, _react.useState)({
    email: '',
    password: ''
  });
  var [loginDefaultErrors, setLoginDefaultErrors] = (0, _react.useState)({});
  var getLoginFormState = (0, _react.useCallback)((state1, errors1) => {
    setLoginDefaultValues(state1);
    setLoginDefaultErrors(errors1);
  }, [setLoginDefaultValues, setLoginDefaultErrors]);
  var [resetPasswordDefaultValues, setResetPasswordDefaultValues] = (0, _react.useState)({
    email: ''
  });
  var [resetPasswordDefaultErrors, setResetPasswordDefaultErrors] = (0, _react.useState)({});
  var getResetPasswordFormState = (0, _react.useCallback)((state, errors) => {
    setResetPasswordDefaultValues(state);
    setResetPasswordDefaultErrors(errors);
  }, [setResetPasswordDefaultValues, setResetPasswordDefaultErrors]);
  return (0, _jsxRuntime.jsx)(_atoms.ModalVariants, {
    onClose: toggleLoginModal,
    isOpen: isLoginModalOpen,
    variant: "default",
    variantProps: {
      title: '',
      delimiter: true,
      sizeSm: 'full'
    },
    updatedWidth: {
      md: 'auto',
      sm: 'full'
    },
    dataTestId: 'Header-Auth',
    children: isLoginForm ? (0, _jsxRuntime.jsx)(_LogIn.LoginPIVariant, {
      setIsLoginForm: setIsLoginForm,
      defaultValues: loginDefaultValues,
      defaultErrors: loginDefaultErrors,
      getFormState: getLoginFormState,
      toggleLoginModal: toggleLoginModal,
      labels: labels
    }) : (0, _jsxRuntime.jsx)(_ResetPassword.ResetPasswordPIVariant, {
      setIsLoginForm: setIsLoginForm,
      defaultValues: resetPasswordDefaultValues,
      defaultErrors: resetPasswordDefaultErrors,
      getFormState: getResetPasswordFormState,
      labels: labels
    })
  });
}