"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = AgentMemoCard;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _react = require("@chakra-ui/react");
var _api = require("@whitbread-eos/api");
var _atoms = require("@whitbread-eos/atoms");
var _utils = require("@whitbread-eos/utils");
var _nextI18next = require("next-i18next");
var _react2 = _interopRequireDefault(require("react"));
var _jsxRuntime = require("react/jsx-runtime");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function AgentMemoCard(_ref) {
  var {
    memo: {
      modifiedOn,
      description,
      memoType
    }
  } = _ref;
  var baseTestId = 'AgentMemoCard';
  var {
    t
  } = (0, _nextI18next.useTranslation)();
  var {
    language
  } = (0, _utils.useCustomLocale)();
  var updateDescriptionByMemoType = (memoType, description) => {
    var userName = '';
    if (memoType === _api.MEMO_TYPE.OPERA) {
      userName = language === 'en' ? 'Opera user' : 'Opera-Benutzer';
    } else if (memoType === _api.MEMO_TYPE.SYSTEM) {
      userName = 'System';
    }
    if (userName.length) {
      return "".concat(t('ccui.agentMemo.createdBy', {
        name: userName
      }), ": ").concat(description);
    }
    return description;
  };
  return (0, _jsxRuntime.jsx)(_react.Box, {
    "data-testid": (0, _utils.formatDataTestId)(baseTestId, 'Container'),
    children: (0, _jsxRuntime.jsxs)(_atoms.Card, _objectSpread(_objectSpread({
      display: "grid"
    }, cardStyle(memoType)), {}, {
      children: [(0, _jsxRuntime.jsx)(_react.Box, _objectSpread(_objectSpread({
        "data-testid": (0, _utils.formatDataTestId)(baseTestId, 'Header')
      }, headerStyle), {}, {
        children: (0, _jsxRuntime.jsx)(_react.Text, _objectSpread(_objectSpread({
          align: "right",
          "data-testid": (0, _utils.formatDataTestId)(baseTestId, 'Date')
        }, createdOnStyle), {}, {
          children: (0, _utils.formatDate)(modifiedOn, 'dd/MM/yyyy - HH:mm', language)
        }))
      })), (0, _jsxRuntime.jsx)(_react.Divider, _objectSpread({}, deviderStyle)), (0, _jsxRuntime.jsx)(_atoms.CollapseExpandText, {
        startingHeight: 85,
        noOfLines: 3,
        baseTestId: baseTestId,
        expandButtonText: t('ccui.agentMemo.seeMore'),
        collapseButtonText: t('ccui.agentMemo.seeLess'),
        contentText: updateDescriptionByMemoType(memoType, description)
      }), (0, _jsxRuntime.jsx)(_react.Divider, _objectSpread({}, deviderStyle))]
    }))
  });
}
var cardStyle = memoType => {
  return {
    backgroundColor: memoType === _api.MEMO_TYPE.SYSTEM ? 'var(--chakra-colors-tooltipError)' : 'var(--chakra-colors-tooltipInfo)',
    border: '1px solid var(--chakra-colors-lightGrey2)',
    color: 'var(--chakra-colors-darkGrey1)',
    marginBottom: 'var(--chakra-space-xl)',
    padding: 'var(--chakra-space-xl)'
  };
};
var headerStyle = {
  marginBottom: 'var(--chakra-space-lg)'
};
var createdOnStyle = {
  fontSize: 'md',
  fontWeight: 'normal'
};
var deviderStyle = {
  color: 'var(--chakra-colors-lightGrey2)'
};