"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.EckohTimeOfPay = void 0;
var EckohTimeOfPay = exports.EckohTimeOfPay = function (EckohTimeOfPay) {
  EckohTimeOfPay["PAY_NOW"] = "paynow";
  EckohTimeOfPay["PAY_ON_ARRIVAL"] = "payonarrival";
  return EckohTimeOfPay;
}({});