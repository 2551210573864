"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.validateSearchData = validateSearchData;
var _api = require("@whitbread-eos/api");
var _utils = require("@whitbread-eos/utils");
var _differenceInDays = _interopRequireDefault(require("date-fns/differenceInDays"));
var _searchContainerHelpers = require("../utilities/searchContainerHelpers");
var isLocationValid = items => {
  var _items$properties, _items$places, _items$managedPlaces;
  return (0, _utils.logicalOrOperator)(items === null, (items === null || items === void 0 || (_items$properties = items.properties) === null || _items$properties === void 0 ? void 0 : _items$properties.length) !== 0, (items === null || items === void 0 || (_items$places = items.places) === null || _items$places === void 0 ? void 0 : _items$places.length) !== 0, (items === null || items === void 0 || (_items$managedPlaces = items.managedPlaces) === null || _items$managedPlaces === void 0 ? void 0 : _items$managedPlaces.length) !== 0);
};
var isArrivalDateAtLeastToday = (day, month, year) => {
  var today = new Date();
  var startDate = new Date(year, month - 1, day);
  return (0, _differenceInDays.default)(startDate, today) >= 0;
};
var isArrivalDateInLimits = (day, month, year, maxArrivalDate) => {
  var today = new Date();
  var startDate = new Date(year, month - 1, day);
  return (0, _differenceInDays.default)(startDate, today) < maxArrivalDate;
};
var isNumberOfNightsValid = (nights, maxNights) => {
  return nights >= 1 && nights <= maxNights;
};
var isRoomOccupancyValid = (rooms, roomCodes, roomTypes, maxNights, roomsOccupancyLimitation) => {
  var codes = Object.keys(roomCodes);
  if (!rooms) {
    return false;
  }
  if ((rooms === null || rooms === void 0 ? void 0 : rooms.length) < 1 || (rooms === null || rooms === void 0 ? void 0 : rooms.length) > roomsOccupancyLimitation) {
    return false;
  }
  var isValidRoomOccupancy = room => {
    if (room.children > 0 && room.roomType !== roomTypes.family && maxNights !== _api.MAX_NIGHTS_CCUI || room.children === 0 && room.roomType === roomTypes.family && maxNights !== _api.MAX_NIGHTS_CCUI || room.roomType === roomTypes.twin && room.adults < 2 && maxNights !== _api.MAX_NIGHTS_CCUI || room.roomType === roomTypes.single && room.adults > 1) {
      return false;
    }
    return (room.adults === 1 || room.adults === 2) && room.children >= 0 && room.children <= 2 && codes.includes(room.roomType);
  };
  return rooms === null || rooms === void 0 ? void 0 : rooms.every(isValidRoomOccupancy);
};
var areOccupancyParamsURLValid = (params, numberOfRooms, router, showMultipleRooms) => {
  var numberRegex = /-?\d+/g;
  var invalidParam = params.find(param => {
    var _param$match;
    var index = (_param$match = param.match(numberRegex)) === null || _param$match === void 0 ? void 0 : _param$match.join('');
    var value = Number(router.query[param]);
    var hasCotError = param.startsWith('COT') && (value < 0 || value > 1) || isNaN(value);
    var selfBookerOnlyOneRoom = showMultipleRooms && numberOfRooms > 1;
    return Number(index) > numberOfRooms || Number(index) < 1 || hasCotError || selfBookerOnlyOneRoom;
  });
  return invalidParam === undefined;
};
function validateSearchData(ARRdd, ARRmm, ARRyyyy, NIGHTS, items, dataTranslations, router, maxArrivalDate, maxNights, defaultRooms, showMultipleRooms, roomsOccupancyLimitation, isCcui) {
  var key;
  var field = '';
  var URL = router.asPath;
  var queryParams = Object.keys(router.query);
  if (!isLocationValid(items)) {
    key = _searchContainerHelpers.ERROR_KEYS.invalidLocation;
    field = _searchContainerHelpers.ERROR_FIELDS[key];
    return {
      errorKey: key && field ? [key, field] : [undefined, undefined],
      url: URL
    };
  }
  if (queryParams.includes('ARRdd') && queryParams.includes('ARRmm') && queryParams.includes('ARRyyyy')) {
    var _headerInformation$co;
    var headerInformation = dataTranslations === null || dataTranslations === void 0 ? void 0 : dataTranslations.headerInformation;
    var roomCodes = (headerInformation === null || headerInformation === void 0 || (_headerInformation$co = headerInformation.config) === null || _headerInformation$co === void 0 ? void 0 : _headerInformation$co.roomCodes) || {};
    var today = new Date();
    var numberOfRooms = router.query['ROOMS'];
    var roomOccupancyParams = queryParams.filter(param => param.startsWith('ADULT') || param.startsWith('CHILD') || param.startsWith('COT'));
    var validations = {
      ["".concat(_searchContainerHelpers.ERROR_KEYS.invalidLocation)]: isLocationValid(items),
      ["".concat(_searchContainerHelpers.ERROR_KEYS.invalidDate)]: (0, _utils.isDateValid)(ARRdd, ARRmm, ARRyyyy) && isCcui || (0, _utils.isDateValid)(ARRdd, ARRmm, ARRyyyy) && isNumberOfNightsValid(Number(NIGHTS), maxNights),
      ["".concat(_searchContainerHelpers.ERROR_KEYS.arrivalDateInThePast)]: isArrivalDateAtLeastToday(ARRdd, ARRmm, ARRyyyy),
      ["".concat(_searchContainerHelpers.ERROR_KEYS.arrivalDateInTheFuture)]: isArrivalDateInLimits(ARRdd, ARRmm, ARRyyyy, maxArrivalDate),
      ["".concat(_searchContainerHelpers.ERROR_KEYS.invalidNights)]: isNumberOfNightsValid(Number(NIGHTS), maxNights),
      ["".concat(_searchContainerHelpers.ERROR_KEYS.invalidOccupancy)]: areOccupancyParamsURLValid(roomOccupancyParams, Number(numberOfRooms), router, showMultipleRooms) && isRoomOccupancyValid(defaultRooms, (0, _utils.swapKeysAndValues)(roomCodes), roomCodes, maxNights, roomsOccupancyLimitation)
    };
    key = Object.keys(validations).find(k => !validations[k]);
    field = key && _searchContainerHelpers.ERROR_FIELDS[key];
    if (key) {
      var day = ARRdd;
      var month = ARRmm;
      var year = ARRyyyy;
      if (field === _searchContainerHelpers.FIELDS.datepicker) {
        day = today.getDate();
        month = today.getMonth() + 1;
        year = today.getFullYear();
      }
      URL = (0, _searchContainerHelpers.getRedirectWithDefaultsURL)(router, day, month, year, NIGHTS, showMultipleRooms);
    }
  }
  return {
    errorKey: key && field ? [key, field] : [undefined, undefined],
    url: URL
  };
}