"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.FIND_BOOKING = void 0;
var _taggedTemplateLiteral2 = _interopRequireDefault(require("@babel/runtime/helpers/taggedTemplateLiteral"));
var _graphqlRequest = require("graphql-request");
var _templateObject;
var FIND_BOOKING = exports.FIND_BOOKING = (0, _graphqlRequest.gql)(_templateObject || (_templateObject = (0, _taggedTemplateLiteral2.default)(["\n  query findBooking($findBookingCriteria: FindBookingCriteria!) {\n    findBooking(findBookingCriteria: $findBookingCriteria) {\n      cookieName\n      minutesTillExpiry\n      redirectBase\n      ref\n      sourcePms\n      token\n      basketReference\n      operaConfNumber\n    }\n  }\n"])));