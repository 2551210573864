"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = RoomsAndGuests;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _react = require("@chakra-ui/react");
var _api = require("@whitbread-eos/api");
var _utils = require("@whitbread-eos/utils");
var _uuid = require("uuid");
var _AddRoomCard = _interopRequireDefault(require("../AddRoomCard"));
var _RoomInfoCard = _interopRequireDefault(require("../RoomInfoCard"));
var _jsxRuntime = require("react/jsx-runtime");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function RoomsAndGuests(_ref) {
  var _business;
  var {
    baseDataTestId,
    data,
    labels,
    language,
    roomRules,
    hotelAvailabilityParams,
    onSaveNewRoom,
    onUpdateRoom,
    onRemoveRoom,
    maxRooms,
    variant,
    isCancellable,
    brand,
    hotelCountry
  } = _ref;
  var prefixDataTestId = 'rooms-and-guests';
  var {
    reservations,
    currencyCode
  } = data;
  var roomStyles = {
    roomWrapper: roomCardStyles
  };
  var addRoomCardsLength = maxRooms - reservations.length;
  var {
    isLoggedIn
  } = (0, _utils.useUserData)();
  var userData = (0, _utils.useUserDetails)(true, isLoggedIn);
  return (0, _jsxRuntime.jsxs)(_react.Box, _objectSpread(_objectSpread({}, wrapperStyles), {}, {
    "data-testid": "".concat(baseDataTestId, "-").concat(prefixDataTestId, "-section"),
    children: [renderReservationCards(reservations), (userData === null || userData === void 0 || (_business = userData.business) === null || _business === void 0 ? void 0 : _business.accessLevel) !== _api.BUSINESS_BOOKER_USER_ROLES.SELF && isCancellable && addRoomCardsLength > 0 && renderAddRoomCards(addRoomCardsLength)]
  }));
  function renderReservationCards(reservations) {
    return reservations.map((reservation, index) => (0, _jsxRuntime.jsx)(_RoomInfoCard.default, {
      styles: roomStyles,
      data: reservation,
      index: index,
      labels: labels,
      currencyCode: currencyCode,
      language: language,
      reservationsNumber: reservations.length,
      baseDataTestId: baseDataTestId,
      hotelAvailabilityParams: hotelAvailabilityParams,
      roomRules: roomRules,
      onUpdateRoom: onUpdateRoom,
      onRemoveRoom: onRemoveRoom,
      reservationId: reservation.reservationId,
      variant: variant,
      isCancellable: isCancellable,
      brand: brand,
      hotelCountry: hotelCountry
    }, "RoomCard-".concat(reservation.reservationId)));
  }
  function renderAddRoomCards(n) {
    return [...Array(n)].map(() => (0, _jsxRuntime.jsx)(_AddRoomCard.default, {
      styles: roomStyles,
      roomRules: roomRules,
      labels: labels,
      language: language,
      baseDataTestId: baseDataTestId,
      hotelAvailabilityParams: hotelAvailabilityParams,
      onSaveNewRoom: onSaveNewRoom,
      variant: variant,
      brand: brand,
      hotelCountry: hotelCountry
    }, "AddRoomCard-".concat((0, _uuid.v4)())));
  }
}
var wrapperStyles = {
  px: 'var(--chakra-space-lg)',
  py: 'var(--chakra-space-2xl)',
  cursor: 'default',
  display: 'grid',
  gridTemplateColumns: {
    mobile: 'none',
    sm: 'repeat(2, 1fr)',
    lg: 'repeat(4, 1fr)'
  },
  gap: 'md'
};
var roomCardStyles = {
  _odd: {
    justifySelf: {
      mobile: 'center',
      sm: 'end',
      lg: 'center'
    },
    alignSelf: {
      mobile: 'center',
      sm: 'end',
      lg: 'center'
    }
  },
  _even: {
    justifySelf: {
      mobile: 'center',
      sm: 'start',
      lg: 'center'
    },
    alignSelf: {
      mobile: 'center',
      sm: 'start',
      lg: 'center'
    }
  }
};