"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "ResetPasswordBBVariant", {
  enumerable: true,
  get: function get() {
    return _ResetPasswordBBVariant.default;
  }
});
Object.defineProperty(exports, "ResetPasswordPIVariant", {
  enumerable: true,
  get: function get() {
    return _ResetPasswordPIVariant.default;
  }
});
var _ResetPasswordBBVariant = _interopRequireDefault(require("./ResetPasswordBBVariant"));
var _ResetPasswordPIVariant = _interopRequireDefault(require("./ResetPasswordPIVariant"));