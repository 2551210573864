"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "FormPage", {
  enumerable: true,
  get: function get() {
    return _formPage.FormPage;
  }
});
var _formPage = require("./form-page");