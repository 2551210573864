"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.wrapperStyles = exports.modalContentStyle = exports.modalCloseButtonStyle = exports.modalBodyStyle = exports.modalBodyBoxStyle = exports.headerTitleStyle = exports.headerContentStyle = exports.descriptionStyle = exports.delimiterModalStyle = exports.closeButtonStyle = exports.buttonsStyle = void 0;
var wrapperStyles = exports.wrapperStyles = {
  alignItems: 'center',
  justifyContent: 'center',
  direction: 'column',
  width: {
    lg: '24.375rem'
  },
  maxWidth: '24.375rem',
  pt: 0
};
var descriptionStyle = exports.descriptionStyle = {
  color: 'darkGrey1',
  fontSize: 'md',
  fontWeight: 'normal'
};
var buttonsStyle = exports.buttonsStyle = {
  display: 'flex',
  flexFlow: 'row',
  height: 'auto',
  justifyContent: 'space-between',
  alignContent: 'baseline',
  flexDirection: 'row',
  width: '100%',
  mt: 'md',
  pb: 'md'
};
var modalBodyStyle = exports.modalBodyStyle = {
  overflow: {
    mobile: 'hidden',
    sm: 'auto'
  },
  pt: 'inherit',
  width: '100%'
};
var modalBodyBoxStyle = exports.modalBodyBoxStyle = {
  maxHeight: {
    sm: '66vh'
  }
};
var modalContentStyle = exports.modalContentStyle = {
  maxW: 'auto',
  maxH: 'auto',
  overflow: 'auto',
  height: {
    sm: 'auto'
  },
  my: 0,
  borderRadius: 'var(--xs, 0.25rem)',
  boxShadow: ' 0rem 0.25rem 0.5rem 0rem rgba(0, 0, 0, 0.35)',
  border: ' 1px solid var(--neutral-300-light-grey-4, #E0E0E0)',
  px: 0,
  position: 'fixed',
  bottom: {
    lg: 'lg',
    md: 'lg',
    sm: 'lg',
    mobile: 'md'
  },
  right: {
    lg: 'lg',
    md: 'lg',
    sm: 'lg',
    mobile: 'auto'
  },
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'flex-end'
};
var headerContentStyle = exports.headerContentStyle = {
  my: 'sm',
  gridTemplateColumns: '1fr auto',
  alignItems: 'center',
  width: '100%'
};
var delimiterModalStyle = exports.delimiterModalStyle = {
  w: 'full',
  h: '0.063rem',
  bgColor: 'lightGrey4'
};
var headerTitleStyle = exports.headerTitleStyle = {
  color: 'darkGrey1',
  justifyContent: 'flex-start',
  fontSize: 'xl',
  lineHeight: '3',
  fontFamily: 'header',
  padding: 0,
  fontWeight: 'bold'
};
var closeButtonStyle = exports.closeButtonStyle = {
  background: 'baseWhite',
  color: 'var(--chakra-colors-darkGrey3)',
  border: '1px solid var(--chakra-colors-darkGrey3)',
  _hover: {
    boxShadow: '0rem 0.25rem 0.5rem 0rem #ccc'
  }
};
var modalCloseButtonStyle = exports.modalCloseButtonStyle = {
  h: 'var(--chakra-space-xl)',
  w: 'var(--chakra-space-xl)',
  ml: 'auto',
  position: 'static',
  _focus: {
    boxShadow: 'none'
  },
  _hover: {
    bgColor: 'transparent'
  },
  _active: {
    bgColor: 'transparent'
  }
};