"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getFooterDetails = void 0;
var _taggedTemplateLiteral2 = _interopRequireDefault(require("@babel/runtime/helpers/taggedTemplateLiteral"));
var _graphqlRequest = require("graphql-request");
var _templateObject;
var getFooterDetails = () => (0, _graphqlRequest.gql)(_templateObject || (_templateObject = (0, _taggedTemplateLiteral2.default)(["\n  query footer($country: String!, $site: String!, $language: String!) {\n    footer(site: $site, country: $country, language: $language) {\n      newsletterSignup {\n        introViewTitle\n        introViewText\n        signUpButtonText\n      }\n      copyrightInfo\n      socialMediaIcons {\n        linkSrc\n        iconSrc\n        label\n      }\n      tabs {\n        intro {\n          name\n          description\n        }\n        columns {\n          name\n          linkItems {\n            linkSrc\n            openInNewTab\n            name\n          }\n        }\n        name\n      }\n      bottomLinks {\n        linkSrc\n        openInNewTab\n        name\n      }\n    }\n  }\n"])));
exports.getFooterDetails = getFooterDetails;