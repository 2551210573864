"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.mappingBookingStatus = mappingBookingStatus;
var _api = require("@whitbread-eos/api");
var _format = _interopRequireDefault(require("date-fns/format"));
function mappingBookingStatus(bookingStatus, departureDate) {
  switch (bookingStatus) {
    case _api.BC_RESERVATION_STATUS.PREPAID:
    case _api.BC_RESERVATION_STATUS.ARRIVED:
    case _api.BC_RESERVATION_STATUS.CHECKEDIN:
    case _api.BC_RESERVATION_STATUS.FUTURE:
      if (departureDate < (0, _format.default)(new Date(), 'yyyy-MM-dd')) {
        return _api.BOOKING_TYPE.PAST;
      }
      return _api.BOOKING_TYPE.UPCOMING;
    case _api.BC_RESERVATION_STATUS.UNARRIVED:
    case _api.BC_RESERVATION_STATUS.RESERVED:
      return _api.BOOKING_TYPE.UPCOMING;
    case _api.BC_RESERVATION_STATUS.NOSHOW:
    case _api.BC_RESERVATION_STATUS.RELEASED:
    case _api.BC_RESERVATION_STATUS.PAST:
      return _api.BOOKING_TYPE.PAST;
    case _api.BC_RESERVATION_STATUS.CANCELLED:
      return _api.BOOKING_TYPE.CANCELLED;
    default:
      return '';
  }
}