"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = useCustomLocale;
var _router = require("next/router");
function useCustomLocale() {
  var router = (0, _router.useRouter)();
  var localeLanguage = (router === null || router === void 0 ? void 0 : router.locale) === 'gb' ? 'en' : 'de';
  var language = router !== null && router !== void 0 && router.locale ? localeLanguage : 'en';
  var country = language === 'en' ? 'gb' : 'de';
  return {
    country,
    language
  };
}