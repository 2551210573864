"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.buttonVariants = exports.Button = void 0;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _objectWithoutProperties2 = _interopRequireDefault(require("@babel/runtime/helpers/objectWithoutProperties"));
var _reactSlot = require("@radix-ui/react-slot");
var _server = require("@whitbread-eos/utils/server");
var _classVarianceAuthority = require("class-variance-authority");
var React = _interopRequireWildcard(require("react"));
var _jsxRuntime = require("react/jsx-runtime");
var _excluded = ["className", "variant", "size", "asChild"];
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && {}.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
var buttonVariants = exports.buttonVariants = (0, _classVarianceAuthority.cva)((0, _server.cn)('inline-flex items-center justify-center whitespace-nowrap rounded-md text-sm font-medium', 'ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2', 'focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none', 'disabled:opacity-50'), {
  variants: {
    variant: {
      default: (0, _server.cn)('bg-secondaryColor text-lg font-semibold text-primary-foreground leading-5', 'hover:bg-secondaryColorHover'),
      alternativeDefault: 'bg-background text-lg text-secondaryColor font-semibold border border-secondaryColor hover:text-secondaryColorHover hover:border-secondaryColorHover',
      destructive: 'bg-destructive text-destructive-foreground hover:bg-destructive/90',
      outline: 'border border-input bg-background hover:bg-accent hover:text-accent-foreground',
      secondary: 'bg-secondary text-secondary-foreground hover:bg-secondary/80',
      ghost: 'hover:bg-accent hover:text-accent-foreground',
      link: 'text-primary underline-offset-4 hover:underline',
      defaultCalendar: 'bg-primary text-primary-foreground hover:bg-primary/90',
      downloadButton: 'bg-lightGrey5 text-lg font-semibold text-secondaryColor leading-5 hover:bg-baseWhite',
      calendarButton: 'rounded-full text-base font-semibold hover:border-solid hover:border-2 hover:border-primaryColor hover:bg-baseWhite transition-none duration-0',
      inputCalendarButton: 'border border-input border-lightGrey2 bg-background font-normal rounded-none justify-start focus-visible:border-none',
      roomOccupancyButton: 'border border-x-0 rounded-none border-input border-lightGrey2 bg-background text-base font-normal text-darkGrey1 border-lightGrey2 hover:outline hover:outline-darkGrey1 hover:outline-1 hover:-outline-offset-1',
      footerButtons: 'hover:bg-transparent bg-transparent text-secondaryColor font-semibold text-lg leading-6 w-full',
      desktopSearchButton: 'bg-baseWhite rounded-l-none rounded-r border border-l-0 border-lightGrey2 hover:outline hover:outline-darkGrey1 hover:outline-1 hover:-outline-offset-1',
      dialogDefault: 'flex-1 mobile:flex-none text-lg font-semibold rounded text-white bg-primaryColor disabled:bg-lightGrey3 disabled:text-lightGrey1',
      dialogOutline: 'flex-1 mobile:flex-none text-lg font-semibold rounded text-secondaryColor bg-white border border-secondaryColor disabled:border-lightGrey3 disabled:text-lightGrey1',
      dialogDestructive: 'flex-1 mobile:flex-none text-lg font-semibold rounded text-white bg-destructive disabled:bg-lightGrey3 disabled:text-lightGrey1'
    },
    size: {
      default: 'h-14 px-6 py-4 rounded-sm',
      sm: 'h-9 rounded-md px-3',
      lg: 'h-11 rounded-md px-8',
      icon: 'h-10 w-10',
      defaultCalendar: 'h-10 px-4 py-2',
      downloadButton: 'h-14 p-4 rounded-sm',
      calendarButton: 'h-10 w-10 p-0',
      inputCalendarButton: 'h-14 p-4',
      roomOccupancyButton: 'h-14 p-4 pr-4',
      footerButtons: '',
      desktopSearchButton: 'w-14 h-14 min-w-14'
    }
  },
  defaultVariants: {
    variant: 'default',
    size: 'default'
  }
});
var Button = exports.Button = React.forwardRef((_ref, ref) => {
  var {
      className,
      variant,
      size,
      asChild = false
    } = _ref,
    props = (0, _objectWithoutProperties2.default)(_ref, _excluded);
  var Comp = asChild ? _reactSlot.Slot : 'button';
  return (0, _jsxRuntime.jsx)(Comp, _objectSpread({
    className: (0, _server.cn)(buttonVariants({
      variant,
      size,
      className
    })),
    ref: ref
  }, props));
});
Button.displayName = 'Button';