"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.BOOKING_HISTORY_CANCEL_BOOKING = void 0;
var _taggedTemplateLiteral2 = _interopRequireDefault(require("@babel/runtime/helpers/taggedTemplateLiteral"));
var _graphqlRequest = require("graphql-request");
var _templateObject;
var BOOKING_HISTORY_CANCEL_BOOKING = exports.BOOKING_HISTORY_CANCEL_BOOKING = (0, _graphqlRequest.gql)(_templateObject || (_templateObject = (0, _taggedTemplateLiteral2.default)(["\n  mutation cancelBooking(\n    $basketReference: String\n    $bookingReference: String!\n    $hotelId: String!\n    $arrivalDate: String\n    $country: String\n    $language: String\n    $bookingChannel: BookingChannelCriteria!\n  ) {\n    cancelBooking(\n      cancelBookingRequest: {\n        basketReference: $basketReference\n        bookingReference: $bookingReference\n        hotelId: $hotelId\n        arrivalDate: $arrivalDate\n        country: $country\n        language: $language\n        bookingChannel: $bookingChannel\n      }\n    ) {\n      bookingReference\n      cancellationId\n    }\n  }\n"])));