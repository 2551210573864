"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "AgentOverrideModal", {
  enumerable: true,
  get: function get() {
    return _dashboard.AgentOverrideModal;
  }
});
Object.defineProperty(exports, "BookingDetails", {
  enumerable: true,
  get: function get() {
    return _dashboard.BookingDetails;
  }
});
Object.defineProperty(exports, "BookingDetailsComponent", {
  enumerable: true,
  get: function get() {
    return _dashboard.BookingDetailsComponent;
  }
});
Object.defineProperty(exports, "BookingDetailsController", {
  enumerable: true,
  get: function get() {
    return _dashboard.BookingDetailsController;
  }
});
Object.defineProperty(exports, "BookingDetailsControllerComponent", {
  enumerable: true,
  get: function get() {
    return _dashboard.BookingDetailsControllerComponent;
  }
});
Object.defineProperty(exports, "BookingHistoryCancelBookingModal", {
  enumerable: true,
  get: function get() {
    return _dashboard.BookingHistoryCancelBookingModal;
  }
});
Object.defineProperty(exports, "BookingHistoryInfoCardContainer", {
  enumerable: true,
  get: function get() {
    return _dashboard.BookingHistoryInfoCardContainer;
  }
});
Object.defineProperty(exports, "BookingInfoCard", {
  enumerable: true,
  get: function get() {
    return _dashboard.BookingInfoCard;
  }
});
Object.defineProperty(exports, "BookingInfoCardWrapper", {
  enumerable: true,
  get: function get() {
    return _dashboard.BookingInfoCardWrapper;
  }
});
Object.defineProperty(exports, "BookingsHistory", {
  enumerable: true,
  get: function get() {
    return _dashboard.BookingsHistory;
  }
});
Object.defineProperty(exports, "CancelBookingModal", {
  enumerable: true,
  get: function get() {
    return _dashboard.CancelBookingModal;
  }
});
Object.defineProperty(exports, "HotelDetails", {
  enumerable: true,
  get: function get() {
    return _dashboard.HotelDetails;
  }
});
Object.defineProperty(exports, "ResendConfirmationModal", {
  enumerable: true,
  get: function get() {
    return _dashboard.ResendConfirmationModal;
  }
});
var _dashboard = require("./dashboard");