"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = BartBookingDetailsTotalCostComponent;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _react = require("@chakra-ui/react");
var _api = require("@whitbread-eos/api");
var _utils = require("@whitbread-eos/utils");
var _nextI18next = require("next-i18next");
var _jsxRuntime = require("react/jsx-runtime");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function BartBookingDetailsTotalCostComponent(_ref) {
  var _donationPkg$amount;
  var {
    previousTotal,
    balanceOutstanding,
    shouldDisplayCityTaxMessage,
    totalCost,
    donationPkg,
    paymentOption,
    rateDescription,
    language
  } = _ref;
  var {
    t
  } = (0, _nextI18next.useTranslation)(['common']);
  var displayDonation = ((_donationPkg$amount = donationPkg === null || donationPkg === void 0 ? void 0 : donationPkg.amount) !== null && _donationPkg$amount !== void 0 ? _donationPkg$amount : 0) > 0;
  switch (paymentOption) {
    case _api.paymentOptions.PAY_ON_ARRIVAL:
    case _api.paymentOptions.RESERVE_WITHOUT_CARD:
      {
        return payOnArrivalTotalCost();
      }
    case _api.paymentOptions.PAY_NOW:
      {
        return payNowTotalCost();
      }
    default:
      {
        return (0, _jsxRuntime.jsx)(_jsxRuntime.Fragment, {});
      }
  }
  function payOnArrivalTotalCost() {
    var _totalCost$currencyCo;
    return (0, _jsxRuntime.jsxs)(_react.Flex, {
      flexDir: "column",
      mt: "4xl",
      children: [displayDonation && (0, _jsxRuntime.jsx)(_react.Box, {
        mb: "4xl",
        children: donation(donationPkg)
      }), (0, _jsxRuntime.jsxs)(_react.Flex, {
        justifyContent: "space-between",
        children: [(0, _jsxRuntime.jsx)(_react.Flex, {
          flexDir: "column",
          textAlign: "start",
          children: (0, _jsxRuntime.jsx)(_react.Text, _objectSpread(_objectSpread({
            "data-testid": "pay-on-arrival-total-cost-label"
          }, priceTitleStyle), {}, {
            children: t('dashboard.bookings.totalCost')
          }))
        }), (0, _jsxRuntime.jsxs)(_react.Flex, {
          flexDir: "column",
          textAlign: "end",
          children: [(0, _jsxRuntime.jsx)(_react.Text, _objectSpread(_objectSpread({
            "data-testid": "pay-on-arrival-total-cost"
          }, priceTitleStyle), {}, {
            children: (0, _utils.formatPrice)((0, _utils.formatCurrency)((_totalCost$currencyCo = totalCost === null || totalCost === void 0 ? void 0 : totalCost.currencyCode) !== null && _totalCost$currencyCo !== void 0 ? _totalCost$currencyCo : ''), Number(totalCost.amount).toFixed(2), language)
          })), (0, _jsxRuntime.jsx)(_react.Text, _objectSpread(_objectSpread({}, priceDescriptionStyle), {}, {
            children: t('dashboard.bookings.paymentOnArrival')
          }))]
        })]
      }), shouldDisplayCityTaxMessage && cityTaxMessage(), (0, _jsxRuntime.jsx)(_react.Flex, {
        flexDir: "column",
        textAlign: "end",
        children: (0, _jsxRuntime.jsx)(_react.Text, _objectSpread(_objectSpread({}, rateDescriptionStyle), {}, {
          children: (0, _utils.removeHtmlTags)(rateDescription)
        }))
      })]
    });
  }
  function payNowTotalCost() {
    var _previousTotal$curren, _balanceOutstanding$c, _totalCost$currencyCo2;
    return (0, _jsxRuntime.jsxs)(_react.Flex, {
      flexDir: "column",
      children: [displayDonation && (0, _jsxRuntime.jsx)(_react.Box, {
        mt: "4xl",
        children: donation(donationPkg)
      }), (0, _jsxRuntime.jsxs)(_react.Flex, {
        justifyContent: "space-between",
        mt: "4xl",
        children: [(0, _jsxRuntime.jsx)(_react.Text, _objectSpread(_objectSpread({
          "data-testid": "balancePaid"
        }, priceTitleStyle), {}, {
          children: t('dashboard.bookings.balancePaid')
        })), (0, _jsxRuntime.jsx)(_react.Text, _objectSpread(_objectSpread({}, priceTitleStyle), {}, {
          "data-testid": "balancePaidSumLabel",
          children: (0, _utils.formatPrice)((0, _utils.formatCurrency)((_previousTotal$curren = previousTotal === null || previousTotal === void 0 ? void 0 : previousTotal.currencyCode) !== null && _previousTotal$curren !== void 0 ? _previousTotal$curren : ''), Number(previousTotal.amount).toFixed(2), language)
        }))]
      }), (0, _jsxRuntime.jsxs)(_react.Flex, {
        justifyContent: "space-between",
        mt: 'lg',
        children: [(0, _jsxRuntime.jsxs)(_react.Flex, {
          flexDir: "column",
          textAlign: "left",
          children: [(0, _jsxRuntime.jsx)(_react.Text, _objectSpread(_objectSpread({
            "data-testid": "balanceOutstandingLabel"
          }, priceTitleStyle), {}, {
            children: t('dashboard.bookings.outstandingBalance')
          })), (0, _jsxRuntime.jsx)(_react.Text, _objectSpread(_objectSpread({}, priceDescriptionStyle), {}, {
            textAlign: "left",
            "data-testid": "balanceOutstandingDescriptionLabel",
            children: t('dashboard.bookings.toPayOnArrival')
          }))]
        }), (0, _jsxRuntime.jsx)(_react.Text, _objectSpread(_objectSpread({}, priceTitleStyle), {}, {
          alignSelf: "flex-start",
          "data-testid": "balanceOutstandingSumLabel",
          children: (0, _utils.formatPrice)((0, _utils.formatCurrency)((_balanceOutstanding$c = balanceOutstanding === null || balanceOutstanding === void 0 ? void 0 : balanceOutstanding.currencyCode) !== null && _balanceOutstanding$c !== void 0 ? _balanceOutstanding$c : ''), Number(balanceOutstanding.amount).toFixed(2), language)
        }))]
      }), (0, _jsxRuntime.jsx)(_react.Flex, {
        flexDir: "column",
        mt: "lg",
        children: (0, _jsxRuntime.jsxs)(_react.Flex, {
          justifyContent: "space-between",
          mt: displayDonation ? {
            mobile: 'xs',
            lg: '0',
            xl: 'xs'
          } : '0',
          children: [(0, _jsxRuntime.jsx)(_react.Flex, {
            flexDir: "column",
            textAlign: "start",
            children: (0, _jsxRuntime.jsx)(_react.Text, _objectSpread(_objectSpread({
              "data-testid": "totalCostLabel"
            }, priceTitleStyle), {}, {
              children: t('dashboard.bookings.totalCost')
            }))
          }), (0, _jsxRuntime.jsxs)(_react.Flex, {
            flexDir: "column",
            textAlign: "end",
            children: [(0, _jsxRuntime.jsx)(_react.Text, _objectSpread(_objectSpread({}, priceTitleStyle), {}, {
              "data-testid": "newTotalSumLabel",
              children: (0, _utils.formatPrice)((0, _utils.formatCurrency)((_totalCost$currencyCo2 = totalCost === null || totalCost === void 0 ? void 0 : totalCost.currencyCode) !== null && _totalCost$currencyCo2 !== void 0 ? _totalCost$currencyCo2 : ''), Number(totalCost.amount).toFixed(2), language)
            })), (0, _jsxRuntime.jsx)(_react.Text, _objectSpread(_objectSpread({}, priceDescriptionStyle), {}, {
              "data-testid": "balanceStatus",
              children: t('dashboard.bookings.balancePaid')
            }))]
          })]
        })
      }), shouldDisplayCityTaxMessage && cityTaxMessage(), (0, _jsxRuntime.jsx)(_react.Flex, {
        flexDir: "column",
        textAlign: "end",
        children: (0, _jsxRuntime.jsx)(_react.Text, _objectSpread(_objectSpread({}, rateDescriptionStyle), {}, {
          children: (0, _utils.removeHtmlTags)(rateDescription)
        }))
      })]
    });
  }
  function donation(donationPkg) {
    var _donationPkg$currency;
    return (0, _jsxRuntime.jsxs)(_react.Flex, {
      direction: "column",
      children: [(0, _jsxRuntime.jsx)(_react.Flex, {
        children: (0, _jsxRuntime.jsx)(_react.Text, _objectSpread(_objectSpread({}, titleStyle), {}, {
          children: t('ccui.manageBooking.extras')
        }))
      }), (0, _jsxRuntime.jsxs)(_react.Flex, {
        justifyContent: "space-between",
        mb: "lg",
        children: [(0, _jsxRuntime.jsx)(_react.Text, _objectSpread(_objectSpread({}, donationTitleStyle), {}, {
          "data-testid": "charityLabel",
          children: t('account.dashboard.gosh')
        })), (0, _jsxRuntime.jsx)(_react.Text, _objectSpread(_objectSpread({}, donationPriceStyle), {}, {
          "data-testid": "charitySumLabel",
          children: (0, _utils.formatPrice)((0, _utils.formatCurrency)((_donationPkg$currency = donationPkg === null || donationPkg === void 0 ? void 0 : donationPkg.currencyCode) !== null && _donationPkg$currency !== void 0 ? _donationPkg$currency : ''), Number(donationPkg.amount).toFixed(2), language)
        }))]
      })]
    });
  }
  function cityTaxMessage() {
    return (0, _jsxRuntime.jsx)(_react.Flex, {
      justifyContent: "flex-end",
      children: (0, _jsxRuntime.jsx)(_react.Text, _objectSpread(_objectSpread({}, priceDescriptionStyle), {}, {
        children: t('booking.overview.includeCityTax')
      }))
    });
  }
}
var donationTitleStyle = {
  color: 'darkGrey2',
  fontSize: {
    mobile: 'md',
    sm: 'lg'
  },
  lineHeight: '3',
  fontWeight: 'normal',
  w: {
    mobile: '8.56rem',
    sm: 'auto'
  },
  as: 'h6'
};
var donationPriceStyle = {
  fontWeight: 'medium',
  fontSize: {
    mobile: 'md',
    sm: 'lg'
  },
  color: 'darkGrey2',
  lineHeight: '3',
  as: 'h6'
};
var priceTitleStyle = {
  fontWeight: 'bold',
  fontSize: {
    mobile: 'lg',
    sm: 'xl'
  },
  lineHeight: '3',
  color: 'darkGrey1',
  as: 'h6'
};
var priceDescriptionStyle = {
  fontWeight: 'normal',
  fontSize: 'sm',
  lineHeight: '2',
  color: 'darkGrey2',
  textAlign: 'right'
};
var rateDescriptionStyle = {
  fontWeight: 'normal',
  fontSize: 'md',
  mt: 'sm',
  lineHeight: '3',
  color: 'darkGrey2',
  textAlign: 'right'
};
var titleStyle = {
  fontWeight: 'semibold',
  fontSize: 'md',
  lineHeight: '3',
  color: 'darkGrey1',
  as: 'span'
};