"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "DefaultModal", {
  enumerable: true,
  get: function get() {
    return _DefaultModal.default;
  }
});
var _DefaultModal = _interopRequireDefault(require("./DefaultModal.component"));