"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.SHOULD_INCLUDE_COT = exports.ROOM_TYPE_KEY = exports.CHILDREN_NUMBER_KEY = exports.CHILDREN_KEY = exports.ADULTS_NUMBER_KEY = exports.ADULTS_KEY = void 0;
var ADULTS_KEY = exports.ADULTS_KEY = 'adults';
var CHILDREN_KEY = exports.CHILDREN_KEY = 'children';
var ADULTS_NUMBER_KEY = exports.ADULTS_NUMBER_KEY = 'adultsNumber';
var CHILDREN_NUMBER_KEY = exports.CHILDREN_NUMBER_KEY = 'childrenNumber';
var ROOM_TYPE_KEY = exports.ROOM_TYPE_KEY = 'roomType';
var SHOULD_INCLUDE_COT = exports.SHOULD_INCLUDE_COT = 'shouldIncludeCot';