"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.manageBookingFormConfig = void 0;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _atoms = require("@whitbread-eos/atoms");
var _utils = require("@whitbread-eos/utils");
var _ArrivalDateInput = _interopRequireDefault(require("./ArrivalDateInput/ArrivalDateInput.component"));
var _formValidation = _interopRequireDefault(require("./formValidation"));
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
var manageBookingFormConfig = _ref => {
  var _labels$headerInforma, _labels$headerInforma2, _labels$headerInforma3, _labels$headerInforma4, _labels$headerInforma5;
  var {
    getFormState,
    defaultValues,
    onSubmit,
    baseTestId,
    resetForm,
    labels,
    isSubmitDisabled
  } = _ref;
  var {
    formValidationSchema
  } = (0, _formValidation.default)(labels);
  var config = {
    id: 'manageBookingForm',
    elements: {
      fields: [{
        type: _atoms.FORM_FIELD_TYPES.INPUT_TEXT,
        name: 'bookingReference',
        label: labels === null || labels === void 0 || (_labels$headerInforma = labels.headerInformation) === null || _labels$headerInforma === void 0 || (_labels$headerInforma = _labels$headerInforma.form) === null || _labels$headerInforma === void 0 ? void 0 : _labels$headerInforma.bookingReferenceLabel,
        testid: (0, _utils.formatDataTestId)(baseTestId, 'BookingReference'),
        styles: _objectSpread(_objectSpread({}, inputStyle), {}, {
          marginBottom: 'lg'
        }),
        props: {
          useTooltip: true
        }
      }, {
        type: _atoms.FORM_FIELD_TYPES.INPUT_TEXT,
        name: 'bookingSurname',
        label: labels === null || labels === void 0 || (_labels$headerInforma2 = labels.headerInformation) === null || _labels$headerInforma2 === void 0 || (_labels$headerInforma2 = _labels$headerInforma2.form) === null || _labels$headerInforma2 === void 0 ? void 0 : _labels$headerInforma2.bookingSurnameLabel,
        testid: (0, _utils.formatDataTestId)(baseTestId, 'BookingSurname'),
        styles: _objectSpread(_objectSpread({}, inputStyle), {}, {
          marginBottom: 'lg'
        }),
        props: {
          useTooltip: true
        }
      }, {
        type: _atoms.FORM_FIELD_TYPES.DYNAMIC_FIELD,
        name: 'arrivalDate',
        label: labels === null || labels === void 0 || (_labels$headerInforma3 = labels.headerInformation) === null || _labels$headerInforma3 === void 0 || (_labels$headerInforma3 = _labels$headerInforma3.form) === null || _labels$headerInforma3 === void 0 ? void 0 : _labels$headerInforma3.arrivalDateLabel,
        Component: _ArrivalDateInput.default,
        props: {
          todayLabel: labels === null || labels === void 0 || (_labels$headerInforma4 = labels.headerInformation) === null || _labels$headerInforma4 === void 0 || (_labels$headerInforma4 = _labels$headerInforma4.content) === null || _labels$headerInforma4 === void 0 || (_labels$headerInforma4 = _labels$headerInforma4.global) === null || _labels$headerInforma4 === void 0 ? void 0 : _labels$headerInforma4.today,
          tomorrowLabel: labels === null || labels === void 0 || (_labels$headerInforma5 = labels.headerInformation) === null || _labels$headerInforma5 === void 0 || (_labels$headerInforma5 = _labels$headerInforma5.content) === null || _labels$headerInforma5 === void 0 || (_labels$headerInforma5 = _labels$headerInforma5.global) === null || _labels$headerInforma5 === void 0 ? void 0 : _labels$headerInforma5.tomorrow
        },
        testid: (0, _utils.formatDataTestId)(baseTestId, 'ArrivalDate'),
        styles: inputStyle
      }],
      buttons: [{
        type: _atoms.FORM_BUTTON_TYPES.SUBMIT,
        label: 'Search',
        action: onSubmit,
        props: {
          variant: 'primary',
          size: 'full',
          disabled: isSubmitDisabled
        },
        styles: _objectSpread({}, searchButton),
        testid: (0, _utils.formatDataTestId)(baseTestId, 'Search')
      }]
    },
    defaultValues,
    validationSchema: formValidationSchema,
    getFormState,
    resetForm
  };
  return config;
};
exports.manageBookingFormConfig = manageBookingFormConfig;
var searchButton = {
  mb: 0
};
var inputStyle = {
  width: {
    mobile: '18rem',
    xs: '21.5rem',
    lg: '24.5rem',
    xl: '26.25rem'
  }
};