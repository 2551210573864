"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.AnalyticsProviderComponent = AnalyticsProviderComponent;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _config = _interopRequireDefault(require("next/config"));
var _react = require("react");
var _auth = require("../../getters/auth");
var _hooks = require("../../hooks");
var _UserContext = require("../../store/UserContext");
var _analytics = _interopRequireDefault(require("./analytics"));
var _jsxRuntime = require("react/jsx-runtime");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
var defaultAnalyticsData = {};
function AnalyticsProviderComponent(_ref) {
  var {
    children,
    initialAnalyticsData = defaultAnalyticsData,
    isBusinessBooker = false
  } = _ref;
  var {
    publicRuntimeConfig = {}
  } = (0, _config.default)() || {};
  var {
    isLoggedIn
  } = (0, _UserContext.useUserData)();
  if (typeof window !== 'undefined') {
    defaultAnalyticsData.browserTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    defaultAnalyticsData.environment = process.env.NODE_ENV === 'development' ? 'development' : 'production';
  }
  var idTokenCookie = '';
  var email = '';
  if (isLoggedIn) {
    idTokenCookie = (0, _auth.getAuthCookie)();
    ({
      email
    } = (0, _auth.decodeIdToken)(idTokenCookie));
  }
  var {
    isSuccess,
    isError
  } = (0, _hooks.useRestQueryRequest)(['userDetails', idTokenCookie], 'GET', "".concat(publicRuntimeConfig.NEXT_PUBLIC_REST_API, "/customers/hotels/").concat(email, "?business=").concat(isBusinessBooker), {
    Authorization: "Bearer ".concat(idTokenCookie)
  }, {
    enabled: isLoggedIn
  });
  var {
    employeeId
  } = (0, _auth.getLoggedInUserInfo)(idTokenCookie);
  (0, _react.useEffect)(() => {
    if (typeof window !== 'undefined') {
      window.analyticsData = _objectSpread(_objectSpread({}, window.analyticsData), initialAnalyticsData);
    }
  }, [initialAnalyticsData]);
  (0, _react.useEffect)(() => {
    if (isSuccess) {
      _analytics.default.update({
        userID: employeeId,
        userLoggedIn: 'Logged In'
      });
    }
    if (isError || !isLoggedIn) {
      _analytics.default.update({
        userLoggedIn: 'Not Logged In'
      });
      _analytics.default.remove(['userID']);
    }
  }, [isSuccess, isError, isLoggedIn]);
  return (0, _jsxRuntime.jsx)(_jsxRuntime.Fragment, {
    children: children
  });
}