"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.GET_RESOURCE_ID_BY_ROLES = void 0;
var _taggedTemplateLiteral2 = _interopRequireDefault(require("@babel/runtime/helpers/taggedTemplateLiteral"));
var _graphqlRequest = require("graphql-request");
var _templateObject;
var GET_RESOURCE_ID_BY_ROLES = exports.GET_RESOURCE_ID_BY_ROLES = (0, _graphqlRequest.gql)(_templateObject || (_templateObject = (0, _taggedTemplateLiteral2.default)(["\n  query searchResourceId($roleIdList: String!) {\n    searchResourceId(roleIdList: $roleIdList)\n  }\n"])));