"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = LoginBBVariant;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _react = require("@chakra-ui/react");
var _atoms = require("@whitbread-eos/atoms");
var _molecules = require("@whitbread-eos/molecules");
var _utils = require("@whitbread-eos/utils");
var _react2 = require("react");
var _loginFormConfig = require("./loginFormConfig");
var _jsxRuntime = require("react/jsx-runtime");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function LoginBBVariant(_ref) {
  var _labels$login, _labels$login2, _labels$login3;
  var {
    setIsLoginForm,
    getFormState,
    defaultValues,
    defaultErrors,
    toggleLoginModal,
    showRegisterNotification,
    hasRegisteredSuccessfully,
    labels
  } = _ref;
  var {
    country,
    language
  } = (0, _utils.useCustomLocale)();
  var [resetForm, setResetForm] = (0, _react2.useState)(0);
  var [isError, setIsError] = (0, _react2.useState)(false);
  var baseDataTestId = 'Login';
  var urlOrigin = typeof window !== 'undefined' ? window.location.origin : '';
  (0, _react2.useEffect)(() => {
    var handleMessages = message => {
      if ((message === null || message === void 0 ? void 0 : message.origin) === (0, _utils.getSecureTwoURL)()) {
        var data = typeof (message === null || message === void 0 ? void 0 : message.data) === 'string' ? JSON.parse(message.data) : message.data;
        var action = data.action;
        if (action === 'loginError') {
          setIsError(true);
        } else if (action === 'userLoggedIn') {
          setResetForm(prev => prev + 1);
          setIsError(false);
          toggleLoginModal();
        }
      }
    };
    window.addEventListener('message', handleMessages);
    return () => {
      window.removeEventListener('message', handleMessages);
    };
  }, [toggleLoginModal]);
  var onLinkClick = () => {
    setIsLoginForm(false);
  };
  var onSubmit = data => {
    var authIframe = document.getElementById('authIframe');
    if (authIframe !== null && authIframe !== void 0 && authIframe.contentWindow) {
      var message = JSON.stringify({
        action: 'login',
        username: data.email,
        password: data.password,
        isBusiness: true
      });
      authIframe.contentWindow.postMessage(message, (0, _utils.getSecureTwoURL)());
    }
  };
  var bbNotificationStyles = {
    mb: isError ? 'xlg' : '3xl'
  };
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsxs)(_react.Flex, _objectSpread(_objectSpread({
      "data-testid": (0, _utils.formatDataTestId)(baseDataTestId, 'Container'),
      direction: "column"
    }, containerStyle), {}, {
      children: [(0, _jsxRuntime.jsx)(_react.Text, _objectSpread(_objectSpread({}, headerStyle), {}, {
        "data-testid": (0, _utils.formatDataTestId)(baseDataTestId, 'Title'),
        children: labels.login.business.formLabel
      })), !showRegisterNotification && (0, _jsxRuntime.jsx)(_react.Flex, _objectSpread(_objectSpread({}, bbNotificationStyles), {}, {
        "data-testid": (0, _utils.formatDataTestId)(baseDataTestId, 'BBNotification'),
        children: (0, _jsxRuntime.jsx)(_atoms.Notification, {
          description: labels.login.business.loginInfoNotification,
          prefixDataTestId: "BBNotification",
          variant: "info",
          status: "info",
          svg: (0, _jsxRuntime.jsx)(_atoms.Info, {})
        })
      })), showRegisterNotification && hasRegisteredSuccessfully && (0, _jsxRuntime.jsx)(_react.Flex, {
        mb: "lg",
        "data-testid": (0, _utils.formatDataTestId)(baseDataTestId, 'BBSuccessNotification'),
        children: (0, _jsxRuntime.jsx)(_atoms.Notification, {
          title: labels.login.business.companyActivateSuccessTitle,
          description: labels.login.business.companyActivateSuccessBody,
          prefixDataTestId: "BBSuccessNotification",
          variant: "success",
          status: "success",
          svg: (0, _jsxRuntime.jsx)(_atoms.Success24, {})
        })
      }), showRegisterNotification && !hasRegisteredSuccessfully && (0, _jsxRuntime.jsx)(_react.Flex, {
        mb: "lg",
        "data-testid": (0, _utils.formatDataTestId)(baseDataTestId, 'BBErrorNotification'),
        children: (0, _jsxRuntime.jsx)(_atoms.Notification, {
          title: labels.login.business.companyActivateFailTitle,
          description: (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
            children: [(0, _jsxRuntime.jsx)(_react.Text, {
              "data-testid": (0, _utils.formatDataTestId)(baseDataTestId, 'BBErrorNotificationDescription'),
              children: labels === null || labels === void 0 || (_labels$login = labels.login) === null || _labels$login === void 0 || (_labels$login = _labels$login.business) === null || _labels$login === void 0 || (_labels$login = _labels$login.companyActivateFailBody) === null || _labels$login === void 0 ? void 0 : _labels$login.split('<span>', 2)[0]
            }), (0, _jsxRuntime.jsx)(_react.Link, {
              textDecoration: "underline",
              display: "block",
              "data-testid": (0, _utils.formatDataTestId)(baseDataTestId, 'BBSupportLink'),
              children: labels === null || labels === void 0 || (_labels$login2 = labels.login) === null || _labels$login2 === void 0 || (_labels$login2 = _labels$login2.business) === null || _labels$login2 === void 0 || (_labels$login2 = _labels$login2.companyActivateFailBody) === null || _labels$login2 === void 0 || (_labels$login2 = _labels$login2.split('<span>', 2)[1]) === null || _labels$login2 === void 0 ? void 0 : _labels$login2.split('</span>')[0]
            })]
          }),
          prefixDataTestId: "BBErrorNotification",
          variant: "error",
          status: "error",
          svg: (0, _jsxRuntime.jsx)(_atoms.Error, {})
        })
      }), isError && (0, _jsxRuntime.jsx)(_react.Flex, _objectSpread(_objectSpread({}, errorContainerStyles), {}, {
        children: (0, _jsxRuntime.jsx)(_atoms.Notification, {
          title: labels === null || labels === void 0 || (_labels$login3 = labels.login) === null || _labels$login3 === void 0 ? void 0 : _labels$login3.badCredentialsError,
          description: (0, _jsxRuntime.jsxs)(_react.Link, {
            textDecoration: "underline",
            display: "block",
            "data-testid": (0, _utils.formatDataTestId)(baseDataTestId, 'ResetPassLink'),
            onClick: onLinkClick,
            children: [' ', labels.login.forgotPassword]
          }),
          variant: "alert",
          status: "warning",
          svg: (0, _jsxRuntime.jsx)(_atoms.Alert, {})
        })
      })), (0, _jsxRuntime.jsx)(_atoms.Form, _objectSpread({}, (0, _loginFormConfig.loginFormConfig)({
        getFormState,
        defaultValues,
        defaultErrors,
        onSubmit,
        handleResetPass: onLinkClick,
        baseDataTestId,
        extraStyles: linkStyles,
        resetForm,
        fieldProps: {
          useCustomTooltip: true
        },
        labels
      }))), (0, _jsxRuntime.jsxs)(_react.Flex, {
        direction: "row",
        justifyContent: "center",
        children: [(0, _jsxRuntime.jsx)(_react.Text, {
          children: labels.login.signupMessage
        }), (0, _jsxRuntime.jsxs)(_react.Link, _objectSpread(_objectSpread({
          "data-testid": (0, _utils.formatDataTestId)(baseDataTestId, 'SignUpLink'),
          href: "".concat(urlOrigin, "/").concat(country, "/").concat(language, "/business-booker/account/register.html")
        }, signUpLinkStyles), {}, {
          children: [' ', labels.login.signupLink]
        }))]
      })]
    })), (0, _jsxRuntime.jsx)(_molecules.LoginFormFooter, {
      labels: labels
    })]
  });
}
var containerStyle = {
  margin: {
    mobile: '1.5rem 1rem',
    sm: '1.5rem 4rem',
    md: '1.5rem 6.875rem'
  },
  width: {
    mobile: 'auto',
    xs: 'auto',
    sm: '25rem',
    md: '26rem'
  }
};
var headerStyle = {
  fontStyle: 'normal',
  fontWeight: 'semibold',
  fontSize: 'lg',
  lineHeight: 'var(--chakra-lineHeights-3)',
  textAlign: 'center',
  color: 'darkGrey1',
  mb: 'xlg'
};
var linkStyles = {
  fontStyle: 'normal',
  fontWeight: 'normal',
  fontSize: 'md',
  lineHeight: 'var(--chakra-lineHeights-3)',
  color: 'btnSecondaryEnabled'
};
var signUpLinkStyles = {
  display: 'block',
  textDecoration: 'underline',
  pl: 'xs',
  color: 'btnSecondaryEnabled'
};
var errorContainerStyles = {
  marginBottom: 'xl'
};