"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = MobileNavitem;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _react = require("@chakra-ui/react");
var _api = require("@whitbread-eos/api");
var _atoms = require("@whitbread-eos/atoms");
var _utils = require("@whitbread-eos/utils");
var _link = _interopRequireDefault(require("next/link"));
var _NavigationItem = _interopRequireDefault(require("../../../HeaderLeisure/NavigationMenuMobile/NavigationItem"));
var _NavigationMenuMobile = require("../../../HeaderLeisure/NavigationMenuMobile/NavigationMenuMobile.style");
var _jsxRuntime = require("react/jsx-runtime");
var _react2 = require("react");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function MobileNavitem(_ref) {
  var {
    navTitleLabel,
    navigationLabels,
    baseDataTestId,
    toggleSideNav,
    isLogoutButton
  } = _ref;
  var findLabel = (title, labels) => {
    return labels.find(item => item.navTitle === title);
  };
  var isCompanyLabel = (title, labels) => {
    var labelItem = findLabel(title, labels);
    if (labelItem) {
      return labelItem.id === _api.BB_MENU_IDS.COMPANY || labelItem.id === _api.BB_MENU_IDS.COMPANY_NAME;
    }
  };
  var hasSubNav = (title, labels) => {
    var _labelItem$subNav;
    var labelItem = findLabel(title, labels);
    if ((labelItem === null || labelItem === void 0 || (_labelItem$subNav = labelItem.subNav) === null || _labelItem$subNav === void 0 ? void 0 : _labelItem$subNav.length) !== 0) {
      var _labelItem$subNav$0$n;
      return (labelItem === null || labelItem === void 0 ? void 0 : labelItem.subNav) && ((_labelItem$subNav$0$n = labelItem.subNav[0].navOptions) === null || _labelItem$subNav$0$n === void 0 ? void 0 : _labelItem$subNav$0$n.length) !== 0;
    }
  };
  var clickAction;
  var testId;
  var content;
  if (isLogoutButton(navTitleLabel)) {
    clickAction = _utils.logout;
    testId = 'MobileLogoutButton';
    content = (0, _jsxRuntime.jsx)(_react.Link, {
      style: {
        textDecoration: 'none'
      },
      children: (0, _jsxRuntime.jsx)(_react.Text, {
        children: navTitleLabel
      })
    });
  } else if (hasSubNav(navTitleLabel, navigationLabels)) {
    clickAction = () => toggleSideNav(navTitleLabel);
    testId = 'TriggerSideNav';
    content = (0, _jsxRuntime.jsx)(_NavigationItem.default, {
      title: navTitleLabel,
      icon: (0, _jsxRuntime.jsx)(_react.Box, {
        pt: "0.37rem",
        pl: "sm",
        children: (0, _jsxRuntime.jsx)(_atoms.Icon, {
          svg: (0, _jsxRuntime.jsx)(_atoms.ChevronRight, {})
        })
      })
    });
  } else {
    var _findLabel$url, _findLabel;
    testId = 'MobileLink';
    content = (0, _jsxRuntime.jsx)(_link.default, {
      href: (_findLabel$url = (_findLabel = findLabel(navTitleLabel, navigationLabels)) === null || _findLabel === void 0 ? void 0 : _findLabel.url) !== null && _findLabel$url !== void 0 ? _findLabel$url : '/',
      passHref: true,
      legacyBehavior: true,
      children: (0, _jsxRuntime.jsx)(_react.Text, {
        children: navTitleLabel
      })
    });
  }
  return (0, _react2.createElement)(_react.Box, _objectSpread(_objectSpread(_objectSpread({
    onClick: clickAction
  }, customContainerItemStyle), isCompanyLabel(navTitleLabel, navigationLabels) && customCompanyStyle), {}, {
    "data-testid": (0, _utils.formatDataTestId)(baseDataTestId, (0, _utils.formatTextWithSpace)("".concat(navTitleLabel, "-").concat(testId))),
    key: "navItem-".concat(navTitleLabel)
  }), content);
}
var customCompanyStyle = {
  bgColor: '#F8F8F8'
};
var customContainerItemStyle = _objectSpread(_objectSpread({}, _NavigationMenuMobile.containerItemStyle), {}, {
  flexDirection: 'column',
  ml: '0',
  pl: 'md',
  w: '100%'
});