"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "AccountToCompanyPreAuthorisedCharges", {
  enumerable: true,
  get: function get() {
    return _components.AccountToCompanyPreAuthorisedCharges;
  }
});
Object.defineProperty(exports, "PRE_AUTHORISED_CHARGES", {
  enumerable: true,
  get: function get() {
    return _components.PRE_AUTHORISED_CHARGES;
  }
});
exports.default = void 0;
var _AccountToCompany = _interopRequireDefault(require("./AccountToCompany.container"));
var _components = require("./components");
var _default = exports.default = _AccountToCompany.default;