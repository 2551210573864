"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getUserManagementLabels = void 0;
var _taggedTemplateLiteral2 = _interopRequireDefault(require("@babel/runtime/helpers/taggedTemplateLiteral"));
var _graphqlRequest = require("graphql-request");
var _templateObject;
var getUserManagementLabels = () => (0, _graphqlRequest.gql)(_templateObject || (_templateObject = (0, _taggedTemplateLiteral2.default)(["\n  query getPageData($country: String!, $language: String!) {\n    getPageData(country: $country, language: $language) {\n      userManagementEndpoint\n      commonIconsEndpoint\n    }\n  }\n"])));
exports.getUserManagementLabels = getUserManagementLabels;