"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.BART_BOOKING_INFORMATION = void 0;
var _taggedTemplateLiteral2 = _interopRequireDefault(require("@babel/runtime/helpers/taggedTemplateLiteral"));
var _graphqlRequest = require("graphql-request");
var _templateObject;
var BART_BOOKING_INFORMATION = exports.BART_BOOKING_INFORMATION = (0, _graphqlRequest.gql)(_templateObject || (_templateObject = (0, _taggedTemplateLiteral2.default)(["\n  query GetBartBookingInformation(\n    $bookingReference: String!\n    $bookerLastName: String!\n    $arrivalDate: String!\n    $language: String\n    $country: String\n  ) {\n    bartBookingInformation(\n      bartBookingInformationCriteria: {\n        bookingReference: $bookingReference\n        bookerLastName: $bookerLastName\n        arrivalDate: $arrivalDate\n        country: $country\n        language: $language\n      }\n    ) {\n      bookingChannel\n      bookingType\n      prepaid\n      rateName\n      rateMessage\n      prepaidAmount {\n        amount\n        currencyCode\n      }\n      hasCityTax\n      paymentOption\n      totalCost {\n        amount\n        currencyCode\n      }\n      donations {\n        amount\n        currencyCode\n      }\n      balanceOutstanding {\n        currencyCode\n        amount\n      }\n      rooms {\n        adultsNumber\n        arrivalDate\n        childrenNumber\n        departureDate\n        packages {\n          postingDate\n          quantity\n          description\n          id\n          imageSrc\n          name\n          unitCost {\n            amount\n            currencyCode\n          }\n        }\n        reservationGuest {\n          givenName\n          surName\n        }\n        totalRoomCost {\n          amount\n          currencyCode\n        }\n        roomType\n      }\n      totalCost {\n        amount\n        currencyCode\n      }\n    }\n  }\n"])));