"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = ChooseRoomContinueBtn;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _react = require("@chakra-ui/react");
var _atoms = require("@whitbread-eos/atoms");
var _utils = require("@whitbread-eos/utils");
var _nextI18next = require("next-i18next");
var _jsxRuntime = require("react/jsx-runtime");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function ChooseRoomContinueBtn(_ref) {
  var {
    dataTestId,
    bookRsvIsLoading,
    bookRsvIsError,
    bookRsvError,
    onBookReservation,
    isDisabledContinueBtn
  } = _ref;
  var {
    t
  } = (0, _nextI18next.useTranslation)(['common']);
  if (bookRsvIsError) {
    return (0, _jsxRuntime.jsx)(_react.Text, {
      children: bookRsvError.message
    });
  }
  return (0, _jsxRuntime.jsx)(_atoms.Button, {
    onClick: onBookReservation,
    isDisabled: bookRsvIsLoading || isDisabledContinueBtn,
    size: "full",
    variant: "primary",
    "data-testid": (0, _utils.formatDataTestId)(dataTestId, 'ContinueButton'),
    children: (0, _jsxRuntime.jsx)(_react.Text, _objectSpread(_objectSpread({}, continueTextStyle), {}, {
      children: t('booking.summary.continue')
    }))
  });
}
var continueTextStyle = {
  fontWeight: 'semibold',
  fontSize: 'lg',
  lineHeight: '3'
};