"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = RoomCardHeader;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _react = require("@chakra-ui/react");
var _utils = require("@whitbread-eos/utils");
var _ChevronDown = _interopRequireDefault(require("../../assets/icons/ChevronDown"));
var _ChevronUp = _interopRequireDefault(require("../../assets/icons/ChevronUp"));
var _Icon = _interopRequireDefault(require("../Icon"));
var _jsxRuntime = require("react/jsx-runtime");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function RoomCardHeader(_ref) {
  var {
    roomReservationStartDate,
    roomReservationEndDate,
    roomNumber,
    roomTotalPrice,
    currency,
    currentLang,
    showRoomCardInfo,
    handleExpandCollapse,
    t
  } = _ref;
  var baseDataTestId = (0, _utils.formatDataTestId)('RoomCardHeader', "room".concat(roomNumber));
  var {
    language
  } = (0, _utils.useCustomLocale)();
  return (0, _jsxRuntime.jsxs)(_react.Flex, _objectSpread(_objectSpread({
    "data-testid": baseDataTestId,
    onClick: () => handleExpandCollapse(roomNumber)
  }, roomNrContainerStyle), {}, {
    mb: showRoomCardInfo ? '2xl' : '0',
    pb: showRoomCardInfo ? '0' : 'lg',
    direction: showRoomCardInfo ? {
      mobile: 'column-reverse',
      xl: 'row'
    } : {
      mobile: 'column-reverse',
      xl: 'row'
    },
    children: [(0, _jsxRuntime.jsxs)(_react.Flex, {
      w: "full",
      direction: "row",
      justify: "flex-start",
      children: [(0, _jsxRuntime.jsx)(_react.Text, _objectSpread(_objectSpread({
        "data-testid": (0, _utils.formatDataTestId)(baseDataTestId, 'Label')
      }, roomNrStyle), {}, {
        children: t('booking.confirmation.room').replace('[roomNumber]', roomNumber.toString())
      })), (0, _jsxRuntime.jsxs)(_react.Text, {
        "data-testid": (0, _utils.formatDataTestId)(baseDataTestId, 'Dates'),
        fontSize: {
          mobile: 'xl',
          xs: '2xl'
        },
        children: [(0, _utils.formatDate)(roomReservationStartDate, 'd MMM', currentLang), " -", ' ', (0, _utils.formatDate)(roomReservationEndDate, 'd MMM', currentLang)]
      })]
    }), (0, _jsxRuntime.jsxs)(_react.Flex, _objectSpread(_objectSpread({}, roomPriceStyle), {}, {
      mb: showRoomCardInfo ? {
        xl: '0',
        mobile: 'md'
      } : {
        mobile: '0'
      },
      justifyContent: showRoomCardInfo ? {
        mobile: 'space-between',
        xl: 'flex-end'
      } : {
        mobile: 'space-between',
        xl: 'flex-end'
      },
      children: [(0, _jsxRuntime.jsx)(_react.Text, {
        "data-testid": (0, _utils.formatDataTestId)(baseDataTestId, 'Total-Label'),
        mr: "sm",
        children: t('booking.hotel.summary.roomTotal')
      }), (0, _jsxRuntime.jsxs)(_react.Flex, {
        alignItems: "center",
        children: [(0, _jsxRuntime.jsx)(_react.Text, {
          "data-testid": (0, _utils.formatDataTestId)(baseDataTestId, 'Total-Amount'),
          fontWeight: "bold",
          mr: "sm",
          children: (0, _utils.formatPrice)((0, _utils.formatCurrency)(currency), roomTotalPrice.toFixed(2), language)
        }), (0, _jsxRuntime.jsx)(_Icon.default, {
          svg: showRoomCardInfo ? (0, _jsxRuntime.jsx)(_ChevronUp.default, {
            color: "var(--chakra-colors-darkGrey2)"
          }) : (0, _jsxRuntime.jsx)(_ChevronDown.default, {
            color: "var(--chakra-colors-darkGrey2)"
          })
        })]
      })]
    }))]
  }));
}
var roomNrStyle = {
  fontWeight: 'semibold',
  fontSize: {
    mobile: 'xl',
    xs: '2xl'
  },
  mr: 'md',
  w: 'fit-content'
};
var roomNrContainerStyle = {
  fontSize: '2xl',
  alignItems: 'center',
  justifyContent: 'space-between',
  cursor: 'pointer',
  p: 'lg'
};
var roomPriceStyle = {
  fontSize: 'xl',
  w: 'full',
  alignItems: 'center'
};