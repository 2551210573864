"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = BBNavigationMenu;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _react = require("@chakra-ui/react");
var _api = require("@whitbread-eos/api");
var _atoms = require("@whitbread-eos/atoms");
var _utils = require("@whitbread-eos/utils");
var _link = _interopRequireDefault(require("next/link"));
var _NavItem = _interopRequireDefault(require("../../HeaderLeisure/NavItem"));
var _NavigationMenu = require("../../HeaderLeisure/NavigationMenu/NavigationMenu.style");
var _jsxRuntime = require("react/jsx-runtime");
var _react2 = require("react");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function BBNavigationMenu(_ref) {
  var {
    navigationLabels,
    language,
    languageMenuLabels,
    tickIcon,
    dataTestId,
    isLogoutButton
  } = _ref;
  var navStyle = language === 'en' ? stackStyle : stackStyleDE;
  return (0, _jsxRuntime.jsxs)(_react.Stack, _objectSpread(_objectSpread({
    as: "nav"
  }, navStyle), {}, {
    "data-testid": (0, _utils.formatDataTestId)(dataTestId, 'NavigationLinks'),
    children: [(0, _jsxRuntime.jsx)(_react.Box, {
      "data-testid": (0, _utils.formatDataTestId)(dataTestId, 'LanguageSelectorWrapper'),
      children: (0, _jsxRuntime.jsx)(_atoms.LanguageSelectorSwitcher, {
        currentLanguage: language,
        tickIcon: tickIcon,
        languagesList: languageMenuLabels,
        area: _api.Area.BB
      })
    }), navigationLabels.map((navigationLabel, index) => {
      return (0, _jsxRuntime.jsx)(_react.Box, _objectSpread(_objectSpread({}, _NavigationMenu.boxStyles), {}, {
        "data-testid": (0, _utils.formatDataTestId)(dataTestId, navigationLabel.navTitle),
        children: (0, _jsxRuntime.jsx)(_atoms.Popover, _objectSpread(_objectSpread({
          triggerItem: (0, _jsxRuntime.jsx)(_react.Box, {
            className: navigationLabel.id === _api.BB_MENU_IDS.COMPANY ? 'assist-no-show' : '',
            children: (0, _jsxRuntime.jsx)(_NavItem.default, {
              title: navigationLabel.navTitle
            })
          })
        }, _objectSpread({}, noBoxShadowStyle)), {}, {
          children: navigationLabel.subNav && (0, _jsxRuntime.jsx)(_react.Flex, {
            w: "17rem",
            children: renderSubNavItems(navigationLabel.subNav)
          })
        }))
      }), index);
    })]
  }));
  function renderListItem(isLogout, option, key, testId) {
    if (isLogout) {
      return (0, _react2.createElement)(_react.ListItem, _objectSpread(_objectSpread({}, _NavigationMenu.listItemStyles), {}, {
        key: key,
        "data-testid": (0, _utils.formatDataTestId)(dataTestId, "".concat(testId, "-").concat(option.title))
      }), (0, _jsxRuntime.jsx)(_react.Link, {
        onClick: _utils.logout,
        children: (0, _jsxRuntime.jsx)(_react.Text, _objectSpread(_objectSpread({}, _NavigationMenu.linkStyles), {}, {
          children: option.title
        }))
      }));
    } else {
      var _option$url;
      return (0, _react2.createElement)(_react.ListItem, _objectSpread(_objectSpread({}, _NavigationMenu.listItemStyles), {}, {
        key: key,
        "data-testid": (0, _utils.formatDataTestId)(dataTestId, "".concat(testId, "-").concat(option.title))
      }), (0, _jsxRuntime.jsx)(_link.default, {
        href: (_option$url = option.url) !== null && _option$url !== void 0 ? _option$url : '/',
        passHref: true,
        legacyBehavior: true,
        children: (0, _jsxRuntime.jsx)(_react.Text, _objectSpread(_objectSpread({}, _NavigationMenu.linkStyles), {}, {
          children: option.title
        }))
      }));
    }
  }
  function renderSubNavItemMenu(list) {
    return list.map((option, index) => {
      return renderListItem(false, option, index, 'subNavListItem');
    });
  }
  function renderSubNavItems(list) {
    return list.map(item => {
      return (0, _jsxRuntime.jsx)(_react.List, _objectSpread(_objectSpread({}, _NavigationMenu.listStyles), {}, {
        children: item.navOptions.map((option, index) => {
          return !option.subMenuLinks ? renderListItem(isLogoutButton(option.title), option, index, 'listItem') : (0, _jsxRuntime.jsx)(_atoms.Accordion, {
            "data-testid": (0, _utils.formatDataTestId)(dataTestId, "listItemWithMenu-".concat(option.title)),
            accordionItems: formatAccordionItemProps(option.title, renderSubNavItemMenu(option.subMenuLinks)),
            bgColor: "baseWhite",
            accordionOverwriteStyles: accordionOverwriteStyles
          });
        })
      }), item.title);
    });
  }
  function formatAccordionItemProps(title, content) {
    return [{
      title,
      content
    }];
  }
}
var accordionItemButtonStyle = {
  whiteSpace: 'nowrap',
  fontWeight: 'normal',
  padding: 0
};
var accordionItemTextStyle = {
  fontWeight: 'normal',
  mb: '2',
  mt: '1',
  lineHeight: '1',
  _hover: {
    textDecoration: 'underline'
  }
};
var accordionIconStyle = {
  position: 'absolute',
  right: '0.75rem',
  height: '0.5em',
  paddingRight: '0px',
  paddingLeft: '0px',
  fontSize: '3rem'
};
var accordionItemPanelStyle = {
  pl: 'lg',
  _last: {
    paddingBottom: '0px'
  }
};
var accordionItemStyle = {
  borderTopWidth: '0px',
  _last: {
    borderBottomWidth: '0px'
  }
};
var accordionOverwriteStyles = {
  button: accordionItemButtonStyle,
  text: accordionItemTextStyle,
  panel: accordionItemPanelStyle,
  item: accordionItemStyle,
  icon: accordionIconStyle
};
var stackStyle = {
  direction: 'row',
  spacing: {
    lg: 'xl',
    xl: '5xl'
  },
  justifyContent: 'flex-end'
};
var stackStyleDE = {
  flexDirection: 'row',
  justifyContent: 'flex-end',
  alignItems: 'center',
  gap: 8
};
var noBoxShadowStyle = {
  _focus: {
    boxShadow: 'none'
  }
};