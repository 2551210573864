"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _react = require("@chakra-ui/react");
var _utils = require("@whitbread-eos/utils");
var _image = _interopRequireDefault(require("next/image"));
var _reactSelect = _interopRequireDefault(require("react-select"));
var _jsxRuntime = require("react/jsx-runtime");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
var Multiselect = _ref => {
  var {
    name,
    dataTestId,
    options,
    styles,
    value,
    onChange,
    placeholder,
    isMulti,
    isClearable,
    closeMenuOnSelect,
    isDisabled
  } = _ref;
  return (0, _jsxRuntime.jsxs)(_react.Box, {
    position: "relative",
    children: [(0, _jsxRuntime.jsx)(_react.FormLabel, _objectSpread(_objectSpread({
      "data-testid": "".concat(dataTestId, "-label"),
      pos: "absolute"
    }, labelStyle(value)), {}, {
      htmlFor: name,
      children: placeholder
    })), (0, _jsxRuntime.jsx)(_reactSelect.default, {
      "data-testid": "".concat(dataTestId, "-multiSelect"),
      options: options,
      value: value,
      name: name,
      onChange: onChange,
      styles: styles,
      closeMenuOnSelect: closeMenuOnSelect,
      placeholder: placeholder,
      formatOptionLabel: CustomOption,
      isMulti: isMulti,
      isClearable: isClearable,
      isDisabled: isDisabled,
      hideSelectedOptions: true,
      components: {
        IndicatorSeparator: () => null,
        ClearIndicator: () => null
      }
    })]
  });
};
var _default = exports.default = Multiselect;
var CustomOption = _ref2 => {
  var {
    label,
    image,
    value
  } = _ref2;
  return (0, _jsxRuntime.jsxs)("div", {
    style: {
      display: 'flex',
      alignItems: 'center'
    },
    children: [image && (0, _jsxRuntime.jsx)(_image.default, {
      src: (0, _utils.formatAssetsUrl)(image),
      width: "24",
      height: "24",
      alt: "multi-select-img-".concat(value),
      loader: (0, _utils.isIVMEnabled)() ? _utils.akamaiImageLoader : undefined
    }), (0, _jsxRuntime.jsx)("label", {
      style: {
        marginLeft: '10px'
      },
      children: label
    })]
  });
};
var labelStyle = function labelStyle(value) {
  var error = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '';
  return {
    h: '1.25rem',
    w: 'fit-content',
    fontSize: 'sm',
    align: 'center',
    px: 'xs',
    fontWeight: 'normal',
    ml: '0.750rem',
    top: '-0.5rem',
    backgroundColor: 'baseWhite',
    zIndex: '1',
    color: error ? 'error' : 'darkGrey1',
    display: value ? 'block' : 'none',
    _focus: {
      color: error ? 'error' : 'primary',
      display: 'block'
    },
    lineHeight: '1'
  };
};