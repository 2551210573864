"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _molecules = require("@whitbread-eos/molecules");
var _FormFooter = _interopRequireDefault(require("./FormFooter"));
var _jsxRuntime = require("react/jsx-runtime");
function PreCheckInForm(_ref) {
  var {
    control,
    formField,
    errors,
    getValues,
    handleSetValue
  } = _ref;
  var {
    setSubmitType
  } = formField.props;
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_molecules.PersonalDetails, {
      formField: formField,
      control: control,
      errors: errors,
      getValues: getValues,
      handleSetValue: handleSetValue
    }), (0, _jsxRuntime.jsx)(_molecules.Dependents, {
      formField: formField,
      control: control,
      errors: errors,
      getValues: getValues,
      handleSetValue: handleSetValue
    }), (0, _jsxRuntime.jsx)(_molecules.PrivacyPolicy, {}), (0, _jsxRuntime.jsx)(_FormFooter.default, {
      setSubmitType: setSubmitType
    })]
  });
}
var _default = exports.default = PreCheckInForm;