"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = CancelBookingModalContainer;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));
var _reactQuery = require("@tanstack/react-query");
var _api = require("@whitbread-eos/api");
var _utils = require("@whitbread-eos/utils");
var _nextI18next = require("next-i18next");
var _react = require("react");
var _CancelBookingModal = _interopRequireDefault(require("./CancelBookingModal.component"));
var _jsxRuntime = require("react/jsx-runtime");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function CancelBookingModalContainer(_ref) {
  var _firstRoom$roomStay, _firstRoom$roomStay2, _firstRoom$roomStay3, _firstRoom$billing, _firstRoom$billing2;
  var {
    isModalVisible,
    onModalClose: _onModalClose,
    refetchManageBooking,
    basketReference,
    bookingReference,
    area
  } = _ref;
  var {
    language,
    country
  } = (0, _utils.useCustomLocale)();
  var {
    t
  } = (0, _nextI18next.useTranslation)(['common']);
  var [bookingInformation, setBookingInformation] = (0, _react.useState)(null);
  var [hotelName, setHotelName] = (0, _react.useState)('');
  var [isError, setIsError] = (0, _react.useState)(false);
  var [error, setError] = (0, _react.useState)(null);
  var [cancelReservationResponse, setCancelReservationResponse] = (0, _react.useState)(undefined);
  var idTokenCookie = (0, _utils.getAuthCookie)();
  var loggedOrCCUI = idTokenCookie || area === _api.Area.CCUI;
  var ANALYTICS_TRACKING_DASH_CANCEL = 'dashCancel';
  var [disabledCancelButton, setDisabledCancelButton] = (0, _react.useState)(false);
  var {
    mutation: cancelReservationMutation,
    isError: cancelReservationIsError,
    isSuccess: cancelReservationIsSuccess,
    data: cancelReservationData
  } = (0, _utils.useMutationRequest)(_api.DASHBOARD_CANCEL_RESERVATION);
  (0, _react.useEffect)(() => {
    if (cancelReservationIsError) {
      setCancelReservationResponse(null);
    }
  }, [cancelReservationIsError]);
  var cancelBooking = (0, _react.useCallback)(() => {
    setDisabledCancelButton(!disabledCancelButton);
    cancelReservationMutation.mutate({
      cancellationCriteria: {
        basketReference: basketReference,
        hotelId: bookingInformation ? bookingInformation.hotelId : '',
        reservationOverrideReason: bookingInformation === null || bookingInformation === void 0 ? void 0 : bookingInformation.reservationByIdList[0].reservationOverrideReasons,
        token: (0, _utils.getFindBookingToken)().token
      }
    });
  }, [basketReference, bookingInformation]);
  (0, _react.useEffect)(() => {
    if (cancelReservationIsSuccess) {
      updateAnalyticsDashboard(cancelReservationData.cancelReservation.basketReference);
      setCancelReservationResponse(cancelReservationData.cancelReservation.basketReference);
      refetchManageBooking();
    }
  }, [cancelReservationIsSuccess]);
  var queryClient = (0, _reactQuery.useQueryClient)();
  var getData = (0, _react.useCallback)(function () {
    var _ref2 = (0, _asyncToGenerator2.default)(function* (basketReference) {
      var idTokenCookie = (0, _utils.getAuthCookie)();
      var bookingConfirmationQuery = queryClient.fetchQuery({
        queryKey: [loggedOrCCUI ? 'getBookingConfirmationAuthenticated' : 'getBookingConfirmation', loggedOrCCUI ? bookingReference : basketReference, language, country],
        queryFn: () => (0, _utils.graphQLRequest)(loggedOrCCUI ? _api.GET_DASHBOARD_BOOKING_CONFIRMATION_AUTHENTICATED : _api.GET_DASHBOARD_BOOKING_CONFIRMATION, loggedOrCCUI ? {
          bookingReference,
          language,
          country,
          bookingChannel: area === null || area === void 0 ? void 0 : area.toUpperCase()
        } : {
          basketReference,
          language,
          country,
          bookingChannel: area === null || area === void 0 ? void 0 : area.toUpperCase()
        }, loggedOrCCUI ? idTokenCookie : undefined)
      });
      yield bookingConfirmationQuery.then(function () {
        var _ref3 = (0, _asyncToGenerator2.default)(function* (bookingConfirmationData) {
          var bookingData = loggedOrCCUI ? bookingConfirmationData === null || bookingConfirmationData === void 0 ? void 0 : bookingConfirmationData.bookingConfirmationAuthenticated : bookingConfirmationData === null || bookingConfirmationData === void 0 ? void 0 : bookingConfirmationData.bookingConfirmation;
          setBookingInformation(bookingData);
          var hotelId = bookingData === null || bookingData === void 0 ? void 0 : bookingData.hotelId;
          var getHotelDetailsRequest = queryClient.fetchQuery({
            queryKey: ['GetHotelInformation', hotelId, country, language],
            queryFn: () => (0, _utils.graphQLRequest)(_api.GET_HOTEL_INFORMATION, {
              hotelId,
              language,
              country
            })
          });
          yield getHotelDetailsRequest.then(data => {
            var _data$hotelInformatio;
            setHotelName(data === null || data === void 0 || (_data$hotelInformatio = data.hotelInformation) === null || _data$hotelInformatio === void 0 ? void 0 : _data$hotelInformatio.name);
            setIsError(false);
          }).catch(error => {
            setError(error);
            setIsError(true);
          });
        });
        return function (_x2) {
          return _ref3.apply(this, arguments);
        };
      }()).catch(error => {
        setError(error);
        setIsError(true);
      });
    });
    return function (_x) {
      return _ref2.apply(this, arguments);
    };
  }(), []);
  (0, _react.useEffect)(() => {
    if ((0, _utils.isStringValid)(basketReference)) {
      getData(basketReference);
    }
  }, [basketReference]);
  var onClickKeepBooking = (0, _react.useCallback)(() => _onModalClose(false), []);
  var firstRoom = bookingInformation === null || bookingInformation === void 0 ? void 0 : bookingInformation.reservationByIdList[0];
  var bookedFor = firstRoom ? "".concat(firstRoom === null || firstRoom === void 0 ? void 0 : firstRoom.reservationGuestList[0].givenName, " ").concat(firstRoom.reservationGuestList[0].surName) : '';
  var arrivalDate = firstRoom ? (0, _utils.formatDate)(firstRoom === null || firstRoom === void 0 || (_firstRoom$roomStay = firstRoom.roomStay) === null || _firstRoom$roomStay === void 0 ? void 0 : _firstRoom$roomStay.arrivalDate, 'EEEE d MMMM yyyy', language) : '';
  var noNights = firstRoom ? (0, _utils.getNightsNumber)(firstRoom === null || firstRoom === void 0 || (_firstRoom$roomStay2 = firstRoom.roomStay) === null || _firstRoom$roomStay2 === void 0 ? void 0 : _firstRoom$roomStay2.arrivalDate, firstRoom === null || firstRoom === void 0 || (_firstRoom$roomStay3 = firstRoom.roomStay) === null || _firstRoom$roomStay3 === void 0 ? void 0 : _firstRoom$roomStay3.departureDate) : 0;
  var bookedBy = firstRoom ? "".concat(firstRoom === null || firstRoom === void 0 || (_firstRoom$billing = firstRoom.billing) === null || _firstRoom$billing === void 0 ? void 0 : _firstRoom$billing.firstName, " ").concat(firstRoom === null || firstRoom === void 0 || (_firstRoom$billing2 = firstRoom.billing) === null || _firstRoom$billing2 === void 0 ? void 0 : _firstRoom$billing2.lastName) : '';
  function updateAnalyticsDashboard(basketReference) {
    var _window$analyticsData, _window;
    window.__satelliteLoaded && window._satellite.track(ANALYTICS_TRACKING_DASH_CANCEL);
    _utils.analytics.update({
      dashboard: _objectSpread(_objectSpread({}, (_window$analyticsData = (_window = window) === null || _window === void 0 || (_window = _window.analyticsData) === null || _window === void 0 ? void 0 : _window.dashboard) !== null && _window$analyticsData !== void 0 ? _window$analyticsData : {}), {}, {
        cancelBookingID: bookingReference !== null && bookingReference !== void 0 ? bookingReference : basketReference,
        cancelNights: noNights,
        cancelRooms: bookingInformation ? bookingInformation.reservationByIdList.length : 0
      })
    });
  }
  return (0, _jsxRuntime.jsx)(_CancelBookingModal.default, {
    cancelReservationData: cancelReservationResponse,
    onClickKeepBooking: onClickKeepBooking,
    bookedFor: bookedFor,
    arrivalDate: arrivalDate,
    noNights: noNights,
    hotelName: hotelName,
    isError: isError,
    error: error,
    isModalVisible: isModalVisible,
    onModalClose: () => _onModalClose(false),
    onClickCancelBooking: cancelBooking,
    area: area,
    bookedBy: bookedBy,
    backBtnText: t('amend.anonymousBackButtonText'),
    backBtnUrl: "home.html",
    bookingReference: bookingReference,
    isCancelDisabled: disabledCancelButton
  });
}