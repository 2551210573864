"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.newPasswordFormConfig = void 0;
var _atoms = require("@whitbread-eos/atoms");
var _utils = require("@whitbread-eos/utils");
var yup = _interopRequireWildcard(require("yup"));
var _NewPasswordDescription = _interopRequireDefault(require("./NewPasswordDescription.component"));
var _jsxRuntime = require("react/jsx-runtime");
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && {}.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
var newPasswordFormConfig = _ref => {
  var _labels$login$busines, _labels$login, _labels$login$busines2, _labels$login2, _labels$login$busines3, _labels$login3, _labels$resetPassword, _labels$resetPassword2, _labels$resetPassword3, _labels$resetPassword4, _labels$resetPassword5, _labels$resetPassword6, _labels$resetPassword7, _labels$resetPassword8, _labels$resetPassword9, _labels$resetPassword10, _labels$resetPassword11, _labels$resetPassword12;
  var {
    defaultValues,
    onSubmit,
    baseDataTestId,
    labels,
    isBusinessBooker,
    t
  } = _ref;
  var newPasswordValidationSchema = yup.object().shape({
    email: yup.string().required(isBusinessBooker ? (_labels$login$busines = labels === null || labels === void 0 || (_labels$login = labels.login) === null || _labels$login === void 0 || (_labels$login = _labels$login.business) === null || _labels$login === void 0 ? void 0 : _labels$login.bookingLoginRequiredText) !== null && _labels$login$busines !== void 0 ? _labels$login$busines : t('booking.login.required.text') : t('booking.login.required.text')).matches(_atoms.FORM_VALIDATIONS.EMAIL.MATCHES, isBusinessBooker ? (_labels$login$busines2 = labels === null || labels === void 0 || (_labels$login2 = labels.login) === null || _labels$login2 === void 0 || (_labels$login2 = _labels$login2.business) === null || _labels$login2 === void 0 ? void 0 : _labels$login2.bookingsInvalidEmailMsg) !== null && _labels$login$busines2 !== void 0 ? _labels$login$busines2 : labels.login.invalidEmail : labels.login.invalidEmail).max(_atoms.FORM_VALIDATIONS.EMAIL.MAX, isBusinessBooker ? (_labels$login$busines3 = labels === null || labels === void 0 || (_labels$login3 = labels.login) === null || _labels$login3 === void 0 || (_labels$login3 = _labels$login3.business) === null || _labels$login3 === void 0 ? void 0 : _labels$login3.bookingEmailMaxLengthMsg) !== null && _labels$login$busines3 !== void 0 ? _labels$login$busines3 : t('booking.login.email.emailMaxLength') : t('booking.login.email.emailMaxLength')),
    password: yup.string().required(isBusinessBooker ? (_labels$resetPassword = labels === null || labels === void 0 || (_labels$resetPassword2 = labels.resetPassword) === null || _labels$resetPassword2 === void 0 ? void 0 : _labels$resetPassword2.passwordRequired) !== null && _labels$resetPassword !== void 0 ? _labels$resetPassword : t('booking.login.required.text') : t('booking.login.required.text')).min(_atoms.FORM_VALIDATIONS.PASSWORD.MIN, labels === null || labels === void 0 || (_labels$resetPassword3 = labels.resetPassword) === null || _labels$resetPassword3 === void 0 ? void 0 : _labels$resetPassword3.passwordMin).matches(_atoms.FORM_VALIDATIONS.PASSWORD.MATCHES, labels === null || labels === void 0 || (_labels$resetPassword4 = labels.resetPassword) === null || _labels$resetPassword4 === void 0 ? void 0 : _labels$resetPassword4.invalidPassword),
    confirm_password: yup.string().required(labels === null || labels === void 0 || (_labels$resetPassword5 = labels.resetPassword) === null || _labels$resetPassword5 === void 0 ? void 0 : _labels$resetPassword5.passwordRequired).min(_atoms.FORM_VALIDATIONS.PASSWORD.MIN, labels === null || labels === void 0 || (_labels$resetPassword6 = labels.resetPassword) === null || _labels$resetPassword6 === void 0 ? void 0 : _labels$resetPassword6.passwordMin).matches(_atoms.FORM_VALIDATIONS.PASSWORD.MATCHES, labels === null || labels === void 0 || (_labels$resetPassword7 = labels.resetPassword) === null || _labels$resetPassword7 === void 0 ? void 0 : _labels$resetPassword7.invalidPassword).oneOf([yup.ref('password')], labels === null || labels === void 0 || (_labels$resetPassword8 = labels.resetPassword) === null || _labels$resetPassword8 === void 0 ? void 0 : _labels$resetPassword8.invalidPasswordConfirmation)
  });
  return {
    elements: {
      fieldsContainerStyles: {
        marginBottom: '1rem'
      },
      fields: [{
        type: _atoms.FORM_FIELD_TYPES.INPUT_TEXT,
        name: 'email',
        label: labels === null || labels === void 0 || (_labels$resetPassword9 = labels.resetPassword) === null || _labels$resetPassword9 === void 0 ? void 0 : _labels$resetPassword9.email,
        testid: (0, _utils.formatDataTestId)(baseDataTestId, 'Email'),
        props: {
          showIcon: true
        }
      }, {
        type: _atoms.FORM_FIELD_TYPES.INPUT_PASSWORD,
        name: 'password',
        label: labels === null || labels === void 0 || (_labels$resetPassword10 = labels.resetPassword) === null || _labels$resetPassword10 === void 0 ? void 0 : _labels$resetPassword10.password,
        testid: (0, _utils.formatDataTestId)(baseDataTestId, 'Password'),
        props: {
          showIcon: true
        }
      }, {
        type: _atoms.FORM_FIELD_TYPES.INPUT_PASSWORD,
        name: 'confirm_password',
        label: labels === null || labels === void 0 || (_labels$resetPassword11 = labels.resetPassword) === null || _labels$resetPassword11 === void 0 ? void 0 : _labels$resetPassword11.passwordConfirmation,
        styles: {
          marginBottom: 0
        },
        testid: (0, _utils.formatDataTestId)(baseDataTestId, 'ConfirmPassword'),
        props: {
          showIcon: true
        }
      }, {
        type: _atoms.FORM_FIELD_TYPES.NON_FIELD_CONTENT,
        name: 'help-text',
        content: (0, _jsxRuntime.jsx)(_NewPasswordDescription.default, {
          labels: labels
        }),
        testid: (0, _utils.formatDataTestId)(baseDataTestId, 'HelpText')
      }],
      buttons: [{
        type: _atoms.FORM_BUTTON_TYPES.SUBMIT,
        label: labels === null || labels === void 0 || (_labels$resetPassword12 = labels.resetPassword) === null || _labels$resetPassword12 === void 0 ? void 0 : _labels$resetPassword12.submitButton,
        action: onSubmit,
        styles: {
          marginBottom: '0'
        },
        props: {
          variant: 'primary',
          size: 'full'
        },
        testid: (0, _utils.formatDataTestId)(baseDataTestId, 'Submit')
      }]
    },
    defaultValues,
    validationSchema: newPasswordValidationSchema
  };
};
exports.newPasswordFormConfig = newPasswordFormConfig;