"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = AmendBillingAddress;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _react = require("@chakra-ui/react");
var _atoms = require("@whitbread-eos/atoms");
var _utils = require("@whitbread-eos/utils");
var _nextI18next = require("next-i18next");
var _jsxRuntime = require("react/jsx-runtime");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
var CURRENT_ADDRESS_VALUE = 'CurrentAddress';
var DIFFERENT_ADDRESS_VALUE = 'DifferentAddress';
var PAY_ON_ARRIVAL = 'PAY_ON_ARRIVAL';
function AmendBillingAddress(_ref) {
  var {
    billingAddress,
    selectedPaymentDetail
  } = _ref;
  var {
    t
  } = (0, _nextI18next.useTranslation)();
  var baseDataTestId = 'AmendBillingAddress';
  var options = getBillingAddressOptions();
  var selectedOption = options.find(o => o.isChecked);
  var isSectionDisabled = selectedPaymentDetail.type === PAY_ON_ARRIVAL;
  return (0, _jsxRuntime.jsxs)(_react.Box, _objectSpread(_objectSpread({
    "data-testid": (0, _utils.formatDataTestId)(baseDataTestId, 'section')
  }, containerStyle), {}, {
    children: [(0, _jsxRuntime.jsx)(_react.Text, _objectSpread(_objectSpread({
      "data-testid": (0, _utils.formatDataTestId)(baseDataTestId, 'title')
    }, titleStyle), {}, {
      children: t('billingAddress.title')
    })), (0, _jsxRuntime.jsx)(_react.RadioGroup, {
      value: isSectionDisabled ? '' : selectedOption === null || selectedOption === void 0 ? void 0 : selectedOption.value,
      children: options.map((option, index) => {
        var _option$isDisabled;
        var isLastOption = index === Number(options.length) - 1;
        return (0, _jsxRuntime.jsx)(_atoms.RadioButton, {
          name: option.value,
          value: option.value,
          "data-testid": option.testId,
          isChecked: option.isChecked && !isSectionDisabled,
          isDisabled: (_option$isDisabled = option.isDisabled) !== null && _option$isDisabled !== void 0 ? _option$isDisabled : isSectionDisabled,
          listIndex: isLastOption ? 'last' : index,
          children: option.value === CURRENT_ADDRESS_VALUE ? renderCurrentBillingAddressOption(option) : (0, _jsxRuntime.jsx)(_react.Text, {
            children: option.label
          })
        }, option.value);
      })
    })]
  }));
  function renderCurrentBillingAddressOption(option) {
    return (0, _jsxRuntime.jsxs)(_react.Flex, {
      direction: "column",
      children: [(0, _jsxRuntime.jsx)(_react.Text, _objectSpread(_objectSpread({
        "data-testid": (0, _utils.formatDataTestId)(baseDataTestId, 'BillingAddress-UseCurrentAdress-Title')
      }, addressStyle), {}, {
        fontWeight: option.isChecked ? 'semibold' : 'normal',
        children: t('billingAddress.current')
      })), billingAddress && (0, _jsxRuntime.jsx)(_react.Text, _objectSpread(_objectSpread({
        className: "sessioncamhidetext assist-no-show",
        "data-testid": (0, _utils.formatDataTestId)(baseDataTestId, 'BillingAddress-UseCurrentAdress-Label')
      }, addressStyle), {}, {
        children: billingAddress
      }))]
    });
  }
  function getBillingAddressOptions() {
    return [{
      value: CURRENT_ADDRESS_VALUE,
      label: t('billingAddress.current'),
      testId: (0, _utils.formatDataTestId)(baseDataTestId, 'CurrentAddress'),
      isChecked: true
    }, {
      value: DIFFERENT_ADDRESS_VALUE,
      label: t('billingAddress.different'),
      testId: (0, _utils.formatDataTestId)(baseDataTestId, 'DifferentAddress'),
      isChecked: false,
      isDisabled: true
    }];
  }
}
var addressStyle = {
  fontSize: 'md',
  lineHeight: '3'
};
var containerStyle = {
  w: {
    mobile: 'full',
    xs: 'full',
    sm: '25.063rem',
    md: '27.563rem',
    xl: '26.25rem'
  },
  mb: '5xl'
};
var titleStyle = {
  mb: 'xl',
  fontSize: '2xl',
  fontWeight: 'semibold',
  lineHeight: '4'
};