"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "AmendPaymentA2CDetails", {
  enumerable: true,
  get: function get() {
    return _AccountToCompanyDetails.default;
  }
});
var _AccountToCompanyDetails = _interopRequireDefault(require("./AccountToCompanyDetails.component"));