"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = OptionalAuthentication;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _react = require("@chakra-ui/react");
var _api = require("@whitbread-eos/api");
var _atoms = require("@whitbread-eos/atoms");
var _utils = require("@whitbread-eos/utils");
var _nextI18next = require("next-i18next");
var _react2 = require("react");
var _loginFormConfig = require("../LogIn/LogInPIVariant/loginFormConfig");
var _ResetPassword = require("../ResetPassword");
var _jsxRuntime = require("react/jsx-runtime");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function OptionalAuthentication(_ref) {
  var {
    labels,
    showIcon,
    setRegisterSectionSelected,
    isRegisterSelected
  } = _ref;
  var {
    t
  } = (0, _nextI18next.useTranslation)();
  var [isSignInSelected, setIsSignInSelected] = (0, _react2.useState)(false);
  var [isResetPasswordOpen, setIsResetPasswordOpen] = (0, _react2.useState)(false);
  var [resetPasswordDefaultValues, setResetPasswordDefaultValues] = (0, _react2.useState)({
    email: ''
  });
  var [resetPasswordDefaultErrors, setResetPasswordDefaultErrors] = (0, _react2.useState)({});
  var [resetForm, setResetForm] = (0, _react2.useState)(0);
  var {
    isLoggedIn
  } = (0, _utils.useUserData)();
  var [isError, setIsError] = (0, _react2.useState)(false);
  var {
    country
  } = (0, _utils.useCustomLocale)();
  var isRegisterGDPEnabled = (0, _utils.useFeatureSwitch)({
    featureSwitchKey: _api.FS_DISPLAY_REGISTER_GDP,
    fallbackValue: true,
    country: country
  });
  var onSubmit = data => {
    var authIframe = document.getElementById('authIframe');
    if (authIframe !== null && authIframe !== void 0 && authIframe.contentWindow) {
      var message = JSON.stringify({
        action: 'login',
        username: data.email,
        password: data.password
      });
      authIframe.contentWindow.postMessage(message, (0, _utils.getSecureTwoURL)());
    }
  };
  var [loginDefaultValues, setLoginDefaultValues] = (0, _react2.useState)({
    email: '',
    password: ''
  });
  var [loginDefaultErrors, setLoginDefaultErrors] = (0, _react2.useState)({});
  var getLoginFormState = (0, _react2.useCallback)((state1, errors1) => {
    setLoginDefaultValues(state1);
    setLoginDefaultErrors(errors1);
  }, [setLoginDefaultValues, setLoginDefaultErrors]);
  var baseDataTestId = 'GuestDetails-OptionalAuth';
  var setSectionSelected = (section, selected) => {
    setIsSignInSelected(false);
    setRegisterSectionSelected(false);
    if (selected) {
      if (section === 'signin') {
        setIsSignInSelected(true);
        setRegisterSectionSelected(false);
      } else {
        setRegisterSectionSelected(true);
        setIsSignInSelected(false);
      }
    }
  };
  var getResetPasswordFormState = (0, _react2.useCallback)((state, errors) => {
    setResetPasswordDefaultValues(state);
    setResetPasswordDefaultErrors(errors);
  }, [setResetPasswordDefaultValues, setResetPasswordDefaultErrors]);
  var handleResetPass = () => {
    setIsResetPasswordOpen(true);
  };
  var handleCloseModal = () => {
    setIsResetPasswordOpen(false);
  };
  (0, _react2.useEffect)(() => {
    var handleMessages = message => {
      if ((message === null || message === void 0 ? void 0 : message.origin) === (0, _utils.getSecureTwoURL)()) {
        var data = typeof (message === null || message === void 0 ? void 0 : message.data) === 'string' ? JSON.parse(message.data) : message.data;
        var action = data.action;
        if (action === 'loginError') {
          setIsError(true);
        } else if (action === 'userLoggedIn') {
          setResetForm(prev => prev + 1);
          setIsError(false);
        }
      }
    };
    window.addEventListener('message', handleMessages);
    return () => {
      window.removeEventListener('message', handleMessages);
    };
  }, []);
  if (!isLoggedIn) {
    var _labels$login;
    return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
      children: [(0, _jsxRuntime.jsxs)(_react.Flex, _objectSpread(_objectSpread({
        flexDirection: "column"
      }, containerStyles), {}, {
        "data-testid": (0, _utils.formatDataTestId)(baseDataTestId, 'Container'),
        children: [(0, _jsxRuntime.jsxs)(_react.Text, _objectSpread(_objectSpread({}, headerStyle), {}, {
          "data-testid": (0, _utils.formatDataTestId)(baseDataTestId, 'Header'),
          children: [t('booking.login.labelHaveAccount'), "\xA0", t('booking.login.labelOptional')]
        })), (0, _jsxRuntime.jsx)(_react.Text, _objectSpread(_objectSpread({}, contentTextStyle), {}, {
          "data-testid": (0, _utils.formatDataTestId)(baseDataTestId, 'Description'),
          children: t('booking.login.notRegisteredYetLabel')
        })), (0, _jsxRuntime.jsxs)(_react.Flex, {
          flexDirection: {
            mobile: 'column',
            md: 'row'
          },
          children: [(0, _jsxRuntime.jsxs)(_react.Box, {
            flex: "1",
            children: [(0, _jsxRuntime.jsxs)(_atoms.Button, _objectSpread(_objectSpread({
              variant: "generic",
              size: "full",
              "data-testid": (0, _utils.formatDataTestId)(baseDataTestId, 'SignIn-Option'),
              mr: "lg",
              mb: "lg",
              onClick: () => setSectionSelected('signin', !isSignInSelected)
            }, isSignInSelected && optionSelectedStyle), {}, {
              children: [(0, _jsxRuntime.jsx)(_react.Text, _objectSpread(_objectSpread({}, isSignInSelected && selectedTextColor), {}, {
                children: t('booking.login.labelHaveAccount')
              })), (0, _jsxRuntime.jsx)(_react.Box, {
                ml: "xmd",
                children: !isSignInSelected ? (0, _jsxRuntime.jsx)(_atoms.ChevronDown, {}) : (0, _jsxRuntime.jsx)(_atoms.ChevronUp, {})
              })]
            })), isSignInSelected && (0, _jsxRuntime.jsxs)(_react.Flex, _objectSpread(_objectSpread({
              "data-testid": (0, _utils.formatDataTestId)(baseDataTestId, 'Login-Container'),
              direction: "column"
            }, loginContainerStyles), {}, {
              children: [isError && (0, _jsxRuntime.jsx)(_react.Flex, _objectSpread(_objectSpread({}, errorContainerStyles), {}, {
                children: (0, _jsxRuntime.jsx)(_atoms.Notification, {
                  title: labels === null || labels === void 0 || (_labels$login = labels.login) === null || _labels$login === void 0 ? void 0 : _labels$login.badCredentialsError,
                  description: (0, _jsxRuntime.jsxs)(_react.Link, {
                    textDecoration: "underline",
                    display: "block",
                    "data-testid": (0, _utils.formatDataTestId)(baseDataTestId, 'ResetPassLink'),
                    onClick: handleResetPass,
                    children: [' ', labels.login.forgotPassword]
                  }),
                  variant: "alert",
                  status: "warning",
                  svg: (0, _jsxRuntime.jsx)(_atoms.Alert, {})
                })
              })), (0, _jsxRuntime.jsx)(_atoms.Form, _objectSpread({}, (0, _loginFormConfig.loginFormConfig)({
                getFormState: getLoginFormState,
                defaultValues: loginDefaultValues,
                defaultErrors: loginDefaultErrors,
                onSubmit,
                handleResetPass,
                baseDataTestId,
                extraStyles: linkStyles,
                buttonsContainerStyles,
                resetForm,
                fieldProps: {
                  showIcon: showIcon,
                  hideForgottenPassword: false
                },
                labels
              })))]
            }))]
          }), (0, _jsxRuntime.jsx)(_react.Box, {
            flex: "1",
            ml: {
              md: 'lg'
            },
            children: isRegisterGDPEnabled && (0, _jsxRuntime.jsxs)(_atoms.Button, _objectSpread(_objectSpread({
              variant: "generic",
              size: "full",
              "data-testid": (0, _utils.formatDataTestId)(baseDataTestId, 'Register-Option'),
              mb: "lg",
              onClick: () => setSectionSelected('register', !isRegisterSelected)
            }, isRegisterSelected && optionSelectedStyle), {}, {
              children: [(0, _jsxRuntime.jsx)(_react.Text, _objectSpread(_objectSpread({}, isRegisterSelected && selectedTextColor), {}, {
                children: t('booking.login.labelRegister')
              })), (0, _jsxRuntime.jsx)(_react.Box, {
                ml: "xmd",
                children: !isRegisterSelected ? (0, _jsxRuntime.jsx)(_atoms.ChevronDown, {}) : (0, _jsxRuntime.jsx)(_atoms.ChevronUp, {})
              })]
            }))
          })]
        })]
      })), (0, _jsxRuntime.jsx)(_atoms.ModalVariants, {
        onClose: handleCloseModal,
        isOpen: isResetPasswordOpen,
        variant: "default",
        variantProps: {
          title: '',
          delimiter: true,
          sizeSm: 'full'
        },
        updatedWidth: {
          md: 'auto',
          sm: 'full'
        },
        dataTestId: 'Header-Auth',
        children: (0, _jsxRuntime.jsx)(_ResetPassword.ResetPasswordPIVariant, {
          onClose: handleCloseModal,
          defaultValues: resetPasswordDefaultValues,
          defaultErrors: resetPasswordDefaultErrors,
          getFormState: getResetPasswordFormState,
          labels: labels,
          isBookingFlow: true
        })
      })]
    });
  }
  return null;
}
var headerStyle = {
  fontWeight: 'semibold',
  fontSize: '2xl',
  lineHeight: '4',
  color: 'darkGrey1',
  mb: 'md'
};
var contentTextStyle = {
  fontWeight: 'normal',
  fontSize: 'md',
  lineHeight: '3',
  color: 'darkGrey1',
  mb: 'lg'
};
var containerStyles = {
  mb: {
    mobile: 'md',
    lg: '0'
  },
  maxWidth: {
    md: '49rem',
    xl: '55rem'
  },
  px: {
    mobile: '0',
    sm: 'md',
    md: '0',
    lg: '0'
  },
  mt: {
    mobile: '2xl',
    sm: '2xl',
    lg: '0'
  }
};
var optionSelectedStyle = {
  borderColor: 'var(--chakra-colors-primary)'
};
var selectedTextColor = {
  color: 'var(--chakra-colors-primary)'
};
var loginContainerStyles = {
  maxWidth: {
    md: '20.75rem',
    lg: '23.75rem',
    xl: '25.344rem'
  }
};
var buttonsContainerStyles = {
  maxWidth: {
    lg: '19.313rem',
    xl: '19.313rem'
  },
  width: '100%'
};
var errorContainerStyles = {
  marginBottom: '2xl'
};
var linkStyles = {
  fontStyle: 'normal',
  fontWeight: 'normal',
  fontSize: 'md',
  lineHeight: 'var(--chakra-lineHeights-3)',
  color: 'btnSecondaryEnabled'
};