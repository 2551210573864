"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = AccessibleBathroomOptionsContainer;
var _api = require("@whitbread-eos/api");
var _nextI18next = require("next-i18next");
var _AccessibleBathroomOptions = _interopRequireDefault(require("./AccessibleBathroomOptions.component"));
var _jsxRuntime = require("react/jsx-runtime");
function AccessibleBathroomOptionsContainer(_ref) {
  var {
    data,
    bathroomSelections,
    roomTypeSelections,
    onRoomTypeSelection,
    onBathroomSelection,
    hotelInventoryResponse,
    selectedPMSRoomTypes
  } = _ref;
  var {
    t
  } = (0, _nextI18next.useTranslation)(['common']);
  if (!data) {
    return null;
  }
  var rooms = [];
  var bathrooms = [];
  rooms = data.map(room => {
    bathrooms = room.rooms.map(roomInfo => {
      switch (roomInfo.pmsRoomType) {
        case _api.ROOM_TYPE.WET_DOUBLE:
        case _api.ROOM_TYPE.WET_TWIN:
          return _api.ROOM_TYPE.WET;
        case _api.ROOM_TYPE.LOWERED_DOUBLE:
        case _api.ROOM_TYPE.LOWERED_TWIN:
          return _api.ROOM_TYPE.LOWERED;
      }
    });
    return {
      roomTypes: Array.from(new Set(room.rooms.map(roomInfo => {
        switch (roomInfo.pmsRoomType) {
          case _api.ROOM_TYPE.DOUBLE:
            return t('search.roomTypes.double');
          case _api.ROOM_TYPE.SINGLE:
          case _api.ROOM_TYPE.STANDARD:
            return t('hoteldetails.rates.standardroomstitle');
          case _api.ROOM_TYPE.PREMIER_PLUS:
            return t('hoteldetails.rates.premierplus');
          case _api.ROOM_TYPE.STANDARD_EXTRA:
            return t('hoteldetails.standardRoomExtra');
          case _api.ROOM_TYPE.STANDARD_BIGGER:
            return t('pihotelinfo.hubBiggerRoomTitle');
          case _api.ROOM_TYPE.FAMILY_TRIPLE:
            return t('search.roomTypes.family');
          case _api.ROOM_TYPE.WET_DOUBLE:
          case _api.ROOM_TYPE.LOWERED_DOUBLE:
            return t('accessible.double');
          case _api.ROOM_TYPE.WET_TWIN:
          case _api.ROOM_TYPE.LOWERED_TWIN:
          case _api.ROOM_TYPE.ACCESSIBLE_TWIN:
            return t('accessible.twin');
          default:
            return '';
        }
      }))),
      adults: room.adults,
      children: room.children,
      bathroomTypes: bathrooms.filter(item => item !== undefined).length > 0 ? bathrooms : undefined
    };
  });
  return (0, _jsxRuntime.jsx)(_AccessibleBathroomOptions.default, {
    rooms: rooms,
    roomTypeSelections: roomTypeSelections,
    bathroomSelections: bathroomSelections,
    onRoomTypeSelection: onRoomTypeSelection,
    onBathroomSelection: onBathroomSelection,
    hotelInventoryResponse: hotelInventoryResponse,
    selectedPMSRoomTypes: selectedPMSRoomTypes
  });
}