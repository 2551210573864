"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = BookingsSubmitButton;
var _objectWithoutProperties2 = _interopRequireDefault(require("@babel/runtime/helpers/objectWithoutProperties"));
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _react = require("@chakra-ui/react");
var _atoms = require("@whitbread-eos/atoms");
var _utils = require("@whitbread-eos/utils");
var _react2 = require("react");
var _reactHookForm = require("react-hook-form");
var _jsxRuntime = require("react/jsx-runtime");
var _excluded = ["action"];
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function BookingsSubmitButton(_ref) {
  var {
    formField,
    getValues,
    control,
    errors
  } = _ref;
  var fields = getValues();
  var checkErrors = Object.keys(errors).length;
  var [disabled, setDisabled] = (0, _react2.useState)(true);
  var bookingReference = (0, _reactHookForm.useWatch)({
    name: 'bookingReference',
    control
  });
  var bookerLastName = (0, _reactHookForm.useWatch)({
    name: 'bookerLastName',
    control
  });
  var arrivalDate = (0, _reactHookForm.useWatch)({
    name: 'arrivalDate',
    control
  });
  var guestLastName = (0, _reactHookForm.useWatch)({
    name: 'guestLastName',
    control
  });
  var bookerPostcode = (0, _reactHookForm.useWatch)({
    name: 'bookerPostcode',
    control
  });
  var hotelDetails = (0, _reactHookForm.useWatch)({
    name: 'hotelDetails',
    control
  });
  var bookerEmail = (0, _reactHookForm.useWatch)({
    name: 'bookerEmail',
    control
  });
  var bookerPhone = (0, _reactHookForm.useWatch)({
    name: 'bookerPhone',
    control
  });
  var cancellationDate = (0, _reactHookForm.useWatch)({
    name: 'cancellationDate',
    control
  });
  var companyName = (0, _reactHookForm.useWatch)({
    name: 'companyName',
    control
  });
  var thirdPartyBookingReferenceNumber = (0, _reactHookForm.useWatch)({
    name: 'thirdPartyBookingReferenceNumber',
    control
  });
  var testId = formField.testid || 'Submit';
  function isEmpty(formFields) {
    var formFieldsCopy = _objectSpread(_objectSpread({}, formFields), {}, {
      hotelId: hotelDetails.code
    });
    delete formFieldsCopy.extendedSearchCriteria;
    delete formFieldsCopy.bookingsSubmitButton;
    delete formFieldsCopy.resetSearchCriteriaButton;
    delete formFieldsCopy.hotelLocation;
    delete formFieldsCopy.hotelDetails;
    return Object.values(formFieldsCopy).every(x => !x);
  }
  function isFilled(formFields) {
    var formFieldsCopy = _objectSpread(_objectSpread({}, formFields), {}, {
      hotelId: hotelDetails.code
    });
    delete formFieldsCopy.extendedSearchCriteria;
    delete formFieldsCopy.bookingsSubmitButton;
    delete formFieldsCopy.resetSearchCriteriaButton;
    delete formFieldsCopy.hotelLocation;
    delete formFieldsCopy.hotelDetails;
    return Object.values(formFieldsCopy).filter(x => x).length > 2;
  }
  function validateEnhancedSearch() {
    var minRequiredFieldsFilled = isFilled(fields);
    var hasRefFieldFilled = (fields === null || fields === void 0 ? void 0 : fields.bookingReference) || (fields === null || fields === void 0 ? void 0 : fields.thirdPartyBookingReferenceNumber);
    var isRequiredFieldsFilled = hasRefFieldFilled || minRequiredFieldsFilled;
    if ((!isRequiredFieldsFilled || checkErrors > 0) && !disabled) {
      setDisabled(true);
    } else if (isRequiredFieldsFilled && disabled && checkErrors === 0) {
      setDisabled(false);
    }
  }
  function validateSearch() {
    var checkEmpty = isEmpty(fields);
    if ((checkEmpty === true || checkErrors > 0) && disabled !== true) {
      setDisabled(true);
    } else if (checkEmpty === false && disabled === true && checkErrors === 0) {
      setDisabled(false);
    }
  }
  (0, _react2.useEffect)(() => {
    formField.props.enhancedSearch ? validateEnhancedSearch() : validateSearch();
  }, [bookingReference, bookerLastName, arrivalDate, guestLastName, bookerPostcode, hotelDetails, bookerEmail, bookerPhone, cancellationDate, companyName, thirdPartyBookingReferenceNumber, checkErrors, formField.props.enhancedSearch]);
  var _formField$props = formField.props,
    {
      action
    } = _formField$props,
    extraPropsFields = (0, _objectWithoutProperties2.default)(_formField$props, _excluded);
  return (0, _jsxRuntime.jsx)(_react.Flex, {
    children: (0, _jsxRuntime.jsx)(_react.Box, {
      children: (0, _jsxRuntime.jsx)(_atoms.Button, _objectSpread(_objectSpread(_objectSpread(_objectSpread({
        type: "submit",
        "data-testid": (0, _utils.formatDataTestId)(testId, 'Button')
      }, extraPropsFields), {}, {
        isDisabled: disabled
      }, searchButtonStyle), searchBtnTextStyle), {}, {
        onClick: formField.props.action,
        children: formField.label
      }))
    })
  });
}
var searchButtonStyle = {
  w: {
    lg: '24.5rem',
    xl: '26.25rem'
  },
  mb: 'sm',
  backgroundColor: 'var(--chakra-colors-btnSecondaryEnabled)'
};
var searchBtnTextStyle = {
  fontWeight: 'semibold',
  fontSize: 'lg',
  lineHeight: '3',
  color: 'baseWhite'
};