"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = RestaurantUnavailableNotification;
var _react = require("@chakra-ui/react");
var _atoms = require("@whitbread-eos/atoms");
var _utils = require("@whitbread-eos/utils");
var _jsxRuntime = require("react/jsx-runtime");
function RestaurantUnavailableNotification(_ref) {
  var {
    messageDescription,
    messageTitle,
    prefixDataTestId
  } = _ref;
  var baseDataTestId = (0, _utils.formatDataTestId)(prefixDataTestId, 'RestaurantUnavailableNotification');
  return (0, _jsxRuntime.jsx)(_react.Box, {
    "data-testid": (0, _utils.formatDataTestId)(baseDataTestId, 'Wrapper'),
    children: (0, _jsxRuntime.jsx)(_atoms.Notification, {
      maxWidth: "full",
      variant: "info",
      status: "info",
      title: messageTitle,
      description: messageDescription,
      svg: (0, _jsxRuntime.jsx)(_atoms.Info, {})
    })
  });
}