"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = LanguageSelectorSideNav;
exports.renderLanguageItem = renderLanguageItem;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _react = require("@chakra-ui/react");
var _api = require("@whitbread-eos/api");
var _utils = require("@whitbread-eos/utils");
var _router = require("next/router");
var _HeaderSideNav = _interopRequireDefault(require("../HeaderSideNav"));
var _NavigationItem = _interopRequireDefault(require("../NavigationItem"));
var _NavigationMenuMobile = require("../NavigationMenuMobile.style");
var _jsxRuntime = require("react/jsx-runtime");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function LanguageSelectorSideNav(_ref) {
  var {
    currentLanguage,
    languagesList,
    onClickHeaderTitle,
    tickIcon,
    area = _api.Area.PI,
    labels
  } = _ref;
  var router = (0, _router.useRouter)();
  var path = router === null || router === void 0 ? void 0 : router.asPath;
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_HeaderSideNav.default, {
      title: labels.language,
      onClickTitle: onClickHeaderTitle
    }), (0, _jsxRuntime.jsx)(_react.ModalBody, {
      p: "0",
      "data-testid": "languageSelectorSideNav",
      children: languagesList.map(language => {
        var link = (0, _utils.formatNextLocaleLink)(path, language.locale, currentLanguage, area);
        return (0, _jsxRuntime.jsx)(_react.Box, {
          onClick: () => {
            window.location = link;
          },
          children: renderLanguageItem({
            languagesList: languagesList !== null && languagesList !== void 0 ? languagesList : [],
            fontWeight: currentLanguage === language.locale ? 'medium' : '',
            locale: language.locale,
            icon: currentLanguage == language.locale && tickIcon
          })
        }, language.locale);
      })
    })]
  });
}
function renderLanguageItem(_ref2) {
  var {
    languagesList,
    locale = '',
    fontWeight,
    icon,
    withStyleHover = false
  } = _ref2;
  return (0, _jsxRuntime.jsx)(_react.Box, _objectSpread(_objectSpread({}, customContainerItemStyle), {}, {
    _hover: {
      color: withStyleHover && 'primary'
    },
    children: (0, _jsxRuntime.jsx)(_NavigationItem.default, {
      title: (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
        children: [renderFlag(locale, languagesList), (0, _jsxRuntime.jsx)(_react.Box, {
          pl: "sm",
          children: (0, _jsxRuntime.jsx)(_react.Text, {
            fontWeight: fontWeight,
            color: "darkGrey1",
            children: findLanguageName(locale, languagesList)
          })
        })]
      }),
      icon: icon
    })
  }));
}
function renderFlag(language, languagesList) {
  var country = languagesList.find(languageObject => languageObject.locale == language);
  if (country) {
    return country.icon;
  }
}
function findLanguageName(language, languagesList) {
  var country = languagesList.find(languageObject => languageObject.locale == language);
  if (country) {
    return country.languageName;
  }
}
var customContainerItemStyle = _objectSpread(_objectSpread({}, _NavigationMenuMobile.containerItemStyle), {}, {
  ml: 0,
  w: '100%',
  pl: 'md',
  cursor: 'pointer'
});