"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = NewPasswordContainer;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _objectWithoutProperties2 = _interopRequireDefault(require("@babel/runtime/helpers/objectWithoutProperties"));
var _reactQuery = require("@tanstack/react-query");
var _api = require("@whitbread-eos/api");
var _utils = require("@whitbread-eos/utils");
var _react = require("react");
var _NewPassword = _interopRequireDefault(require("./NewPassword.component"));
var _jsxRuntime = require("react/jsx-runtime");
var _excluded = ["queryClient", "isBusinessBooker"];
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function NewPasswordContainer(_ref) {
  var _data$headerInformati3;
  var {
      queryClient,
      isBusinessBooker
    } = _ref,
    rest = (0, _objectWithoutProperties2.default)(_ref, _excluded);
  var {
    language,
    country
  } = (0, _utils.useCustomLocale)();
  var {
    data
  } = (0, _utils.useQueryRequest)(['GetStaticContent', language, country], _api.GET_STATIC_CONTENT, {
    language: language,
    country: country,
    site: isBusinessBooker ? _api.SITE_BB : _api.SITE_LEISURE,
    businessBooker: isBusinessBooker
  }, {
    enabled: isBusinessBooker
  });
  var [labels, setLabels] = (0, _react.useState)(_api.DEFAULT_RESET_PASSWORD_LABELS);
  (0, _react.useEffect)(() => {
    var _data$headerInformati;
    if (data !== null && data !== void 0 && (_data$headerInformati = data.headerInformation) !== null && _data$headerInformati !== void 0 && (_data$headerInformati = _data$headerInformati.content) !== null && _data$headerInformati !== void 0 && _data$headerInformati.authentication) {
      var _data$headerInformati2;
      setLabels(data === null || data === void 0 || (_data$headerInformati2 = data.headerInformation.content) === null || _data$headerInformati2 === void 0 ? void 0 : _data$headerInformati2.authentication);
    }
  }, [data === null || data === void 0 || (_data$headerInformati3 = data.headerInformation) === null || _data$headerInformati3 === void 0 || (_data$headerInformati3 = _data$headerInformati3.content) === null || _data$headerInformati3 === void 0 ? void 0 : _data$headerInformati3.authentication]);
  return (0, _jsxRuntime.jsx)(_reactQuery.QueryClientProvider, {
    client: queryClient,
    children: (0, _jsxRuntime.jsx)(_NewPassword.default, _objectSpread(_objectSpread({}, rest), {}, {
      isBusinessBooker: isBusinessBooker,
      labels: labels
    }))
  });
}