"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = GuestDetailsBBContainer;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _atoms = require("@whitbread-eos/atoms");
var _nextI18next = require("next-i18next");
var _guestDetailsBBFormConfig = require("./guestDetailsBBFormConfig");
var _jsxRuntime = require("react/jsx-runtime");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function GuestDetailsBBContainer(_ref) {
  var {
    numberOfRooms,
    reservationByIdList,
    validationLabels,
    onSubmit,
    labels,
    guestList,
    setGuestUser,
    getFormState,
    queryClient,
    isDynamicSearchVisible,
    isAccompanyingGuestDetailsEnabled,
    accessLevel,
    selfBookerDetails
  } = _ref;
  var {
    t
  } = (0, _nextI18next.useTranslation)();
  var baseDataTestIdGuestDetails = 'GuestDetailsBBContainer';
  var baseDataTestIdAccompayningGuestDetails = 'AccompanyingGuestDetailsBBContainer';
  return (0, _jsxRuntime.jsx)(_atoms.Form, _objectSpread({}, (0, _guestDetailsBBFormConfig.guestDetailsBBFormConfig)({
    getFormState,
    defaultValues: guestList,
    onSubmit,
    baseDataTestIdGuestDetails: baseDataTestIdGuestDetails,
    baseDataTestIdAccompayningGuestDetails: baseDataTestIdAccompayningGuestDetails,
    t,
    labels,
    validationLabels,
    reservationByIdList,
    numberOfRooms,
    queryClient,
    guestList,
    setGuestUser,
    isDynamicSearchVisible,
    autoComplete: 'off',
    isAccompanyingGuestDetailsEnabled,
    accessLevel,
    selfBookerDetails
  })));
}