"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.boldMatchCharacters = void 0;
exports.generateSuggestionList = generateSuggestionList;
exports.isTheSameGuest = isTheSameGuest;
var _validators = require("../validators");
function generateSuggestionList(data, searchCriteria, listSelections) {
  var excludeAlreadySelected = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : true;
  var desiredKeys = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : ['id', 'title', 'firstName', 'lastName', 'emailAddress'];
  var excludedIds = listSelections.map(selection => selection.id);
  var finalList = [];
  data.forEach(employee => {
    if (excludeAlreadySelected && !excludedIds.includes(employee.id)) {
      var text = "".concat(employee.title, " ").concat(employee.firstName, " ").concat(employee.lastName, " (").concat(employee.emailAddress, ")");
      var final = {
        employee: {
          title: '',
          lastName: '',
          id: '',
          firstName: '',
          emailAddress: '',
          composedName: text
        },
        prettyFormatDisplay: boldMatchCharacters({
          sentence: text,
          characters: searchCriteria
        })
      };
      desiredKeys.forEach(key => {
        if (Object.keys(employee).includes(key) && (0, _validators.isStringValid)(employee[key])) {
          final.employee[key] = employee[key];
        }
      });
      finalList.push(final);
    }
  });
  return finalList;
}
var boldMatchCharacters = _ref => {
  var {
    sentence = '',
    characters = ''
  } = _ref;
  var regEx = new RegExp(characters, 'gi');
  return sentence.replace(regEx, '<strong>$&</strong>');
};
exports.boldMatchCharacters = boldMatchCharacters;
function isTheSameGuest(actualGuest, prevGuest) {
  var {
    title,
    firstName,
    lastName,
    emailAddress
  } = prevGuest;
  var {
    title: newTitle,
    firstName: newFirstName,
    lastName: newLastName,
    emailAddress: newEmailAddress
  } = actualGuest;
  return title === newTitle && firstName === newFirstName && lastName === newLastName && emailAddress === newEmailAddress;
}