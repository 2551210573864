"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = AmendPaymentContainer;
var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _react = require("@chakra-ui/react");
var _reactQuery = require("@tanstack/react-query");
var _api = require("@whitbread-eos/api");
var _molecules = require("@whitbread-eos/molecules");
var _utils = require("@whitbread-eos/utils");
var _nextI18next = require("next-i18next");
var _router = require("next/router");
var _react2 = require("react");
var _constants = require("../constants");
var _helpers = require("../helpers");
var _jsxRuntime = require("react/jsx-runtime");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function AmendPaymentContainer(_ref) {
  var _paymentOptionsData$p, _allowances$values, _companyRef$value, _emailPreference$emai;
  var {
    summaryOfPayments,
    bookingReference,
    originalBasketReference,
    temporaryBasketReference,
    paymentOptionsData
  } = _ref;
  var {
    country,
    language
  } = (0, _utils.useCustomLocale)();
  var {
    t
  } = (0, _nextI18next.useTranslation)();
  var router = (0, _router.useRouter)();
  var queryClient = (0, _reactQuery.useQueryClient)();
  var {
    a2cDetails,
    companyRef,
    emailPreference,
    hotelName,
    hotelCode,
    brand,
    paymentType,
    cardPresent,
    allowances,
    companyId
  } = (_paymentOptionsData$p = paymentOptionsData === null || paymentOptionsData === void 0 ? void 0 : paymentOptionsData.paymentOptions) !== null && _paymentOptionsData$p !== void 0 ? _paymentOptionsData$p : {};
  var [, setTemporaryBasketVisited] = (0, _utils.useSessionStorage)(_api.TEMPORARY_BASKET_KEY, '');
  var [, setConfirmAmendErrorValue] = (0, _utils.useSessionStorage)(_api.INITIAL_CONFIRM_AMEND_ERROR_KEY, _api.INITIAL_CONFIRM_AMEND_ERROR_VALUE);
  var showTotalCost = hotelName && hotelCode && brand;
  var baseTestId = 'A2cPaymentContainer';
  var cardOptions = [_api.CcuiCardType.NEW_CARD, _api.CcuiCardType.NEW_PIBA, _api.CcuiCardType.NON_GUARANTEED, _api.CcuiCardType.ACCOUNT_COMPANY];
  var disabledCardOptions = cardOptions.filter(option => option !== paymentType);
  var a2cPaymentType = paymentType && paymentType === _api.PaymentType.AccountCompany ? 'Account to company' : '';
  var [selectedPaymentType, setSelectedPaymentType] = (0, _react2.useState)({
    name: a2cPaymentType,
    type: '',
    subType: '',
    order: 0,
    paymentOptions: [{
      type: '',
      order: 0,
      enabled: true
    }],
    enabled: false,
    cnpPreSelected: false,
    cnpOptionAvailable: false,
    reasons: []
  });
  var [selectedPaymentDetail, setSelectedPaymentDetail] = (0, _react2.useState)({
    type: '',
    order: 0,
    enabled: true
  });
  var initialAllowances = allowances === null || allowances === void 0 || (_allowances$values = allowances.values) === null || _allowances$values === void 0 ? void 0 : _allowances$values.map(value => {
    var _value$allowance;
    return (_value$allowance = value === null || value === void 0 ? void 0 : value.allowance) !== null && _value$allowance !== void 0 ? _value$allowance : '';
  });
  var [sendEmail, setSendEmail] = (0, _react2.useState)(true);
  var [acAllowances, setACAllowances] = (0, _react2.useState)(initialAllowances !== null && initialAllowances !== void 0 ? initialAllowances : []);
  var [companyReference, setCompanyReference] = (0, _react2.useState)((_companyRef$value = companyRef === null || companyRef === void 0 ? void 0 : companyRef.value) !== null && _companyRef$value !== void 0 ? _companyRef$value : '');
  var [emailAddress, setEmailAddress] = (0, _react2.useState)((_emailPreference$emai = emailPreference === null || emailPreference === void 0 ? void 0 : emailPreference.emailAddress) !== null && _emailPreference$emai !== void 0 ? _emailPreference$emai : '');
  var [emailError, setEmailError] = (0, _react2.useState)('');
  var companyReferenceError = (0, _react2.useRef)(false);
  var {
    token
  } = (0, _utils.getFindBookingToken)();
  var isWithoutEckoh = paymentType === _api.CcuiCardType.ACCOUNT_COMPANY;
  var intervalRef = (0, _react2.useRef)();
  var setPreAuthorisedCharges = charges => {
    var newCharges = charges.map(charge => charge.label);
    setACAllowances(newCharges);
  };
  var selectedCharges = _molecules.PRE_AUTHORISED_CHARGES === null || _molecules.PRE_AUTHORISED_CHARGES === void 0 ? void 0 : _molecules.PRE_AUTHORISED_CHARGES.filter(charge => initialAllowances === null || initialAllowances === void 0 ? void 0 : initialAllowances.includes(charge.label));
  var setCompanyReferenceError = value => {
    if (typeof value === 'boolean') {
      companyReferenceError.current = value;
    }
  };
  var {
    mutation: confirmAmendMutation,
    isLoading: confirmAmendIsLoading,
    isSuccess: confirmAmendIsSuccess
  } = (0, _utils.useMutationRequest)(_api.CONFIRM_AMEND, true);
  (0, _react2.useEffect)(() => {
    setTemporaryBasketVisited(temporaryBasketReference);
  }, []);
  (0, _react2.useEffect)(() => {
    return () => {
      var _intervalRef$current;
      clearInterval((_intervalRef$current = intervalRef.current) !== null && _intervalRef$current !== void 0 ? _intervalRef$current : '');
    };
  }, []);
  if (confirmAmendIsLoading || confirmAmendIsSuccess) {
    return (0, _jsxRuntime.jsx)(_molecules.PageLoader, {
      text: t('booking.loading')
    });
  }
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_react.Box, _objectSpread(_objectSpread({}, sectionStyles), {}, {
      "data-testid": baseTestId,
      children: (0, _jsxRuntime.jsx)(_molecules.PaymentDetails, {
        selectedPaymentDetail: selectedPaymentDetail,
        selectedPaymentType: selectedPaymentType,
        setSelectedPaymentDetail: setSelectedPaymentDetail,
        t: t,
        hideHeader: true,
        isCCUI: true,
        isA2cPaymentPage: true
      })
    })), (0, _jsxRuntime.jsx)(_react.Box, _objectSpread(_objectSpread({}, sectionStyles), {}, {
      children: (0, _jsxRuntime.jsx)(_molecules.PaymentTypeContainer, {
        onPaymentTypeClick: setSelectedPaymentType,
        selectedPaymentType: selectedPaymentType,
        selectedPaymentDetail: selectedPaymentDetail,
        styles: {
          containerStyles: {
            w: '100%'
          }
        },
        initialPaymentType: paymentType,
        disabledOptions: disabledCardOptions
      })
    })), (a2cDetails === null || a2cDetails === void 0 ? void 0 : a2cDetails.display) && (0, _jsxRuntime.jsx)(_react.Box, {
      pt: "xl",
      children: (0, _jsxRuntime.jsx)(_molecules.AmendPaymentA2CDetails, {
        data: {
          number: a2cDetails.number,
          name: a2cDetails.name,
          address: a2cDetails.address,
          postcode: a2cDetails.postcode
        }
      })
    }), (allowances === null || allowances === void 0 ? void 0 : allowances.display) && (0, _jsxRuntime.jsx)(_react.Box, {
      children: (0, _jsxRuntime.jsx)(_molecules.AccountToCompanyPreAuthorisedCharges, {
        setPreAuthorisedCharges: setPreAuthorisedCharges,
        setCompanyReferenceError: setCompanyReferenceError,
        setACCompanyReference: setCompanyReference,
        initialCharges: selectedCharges,
        initialCompanyReference: companyRef === null || companyRef === void 0 ? void 0 : companyRef.value
      })
    }), (0, _jsxRuntime.jsxs)(_react.Box, {
      children: [showTotalCost && (0, _jsxRuntime.jsx)(_molecules.TotalCost, {
        country: country,
        emailSection: {
          setEmailAddress,
          emailAddress,
          emailError,
          setEmailError
        },
        hotelBrand: brand.toLowerCase(),
        hotelId: hotelCode,
        hotelName: hotelName,
        previousTotalCost: summaryOfPayments.previousTotal,
        totalCost: summaryOfPayments.totalCost,
        setSendEmail: setSendEmail,
        hasAllChecks: !companyReferenceError.current,
        language: language,
        sendEmail: sendEmail,
        onConfirmClick: handleConfirmChanges,
        isCompWithoutEckoh: isWithoutEckoh
      }), (0, _jsxRuntime.jsx)(_molecules.BackToPage, {
        goBack: () => router.back(),
        linkText: t('booking.summary.back')
      })]
    })]
  });
  function handleConfirmChanges() {
    var params = {
      tempBookingRef: temporaryBasketReference,
      originalBookingRef: originalBasketReference,
      channel: _api.Channel.Ccui,
      subchannel: _api.BOOKING_SUBCHANNEL.WEB,
      language: language === null || language === void 0 ? void 0 : language.toUpperCase(),
      token,
      environment: window.location.origin,
      emailAddress: emailAddress,
      paymentOptionSelected: _constants.PAY_ON_ARRIVAL,
      paymentOption: paymentType,
      ccuiExtraItems: {
        sendMail: Boolean(emailPreference === null || emailPreference === void 0 ? void 0 : emailPreference.send),
        cardPresent: Boolean(cardPresent === null || cardPresent === void 0 ? void 0 : cardPresent.value),
        accountCompanyItems: {
          companyNumber: a2cDetails === null || a2cDetails === void 0 ? void 0 : a2cDetails.number,
          charges: acAllowances === null || acAllowances === void 0 ? void 0 : acAllowances.join(),
          companyId: companyId
        },
        businessItems: {
          purchaseOrderNumber: '',
          customReferenceNumber: companyReference,
          businessAllowances: (0, _utils.getA2CBusinessAllowances)(acAllowances)
        }
      },
      paymentRequest: {
        payment: {
          subType: 'MOTO',
          type: selectedPaymentType.name
        }
      }
    };
    var amendConfirmationURL = new URL("".concat(window.location.origin, "/").concat(country, "/").concat(language, "/amend/booking-confirmation"));
    amendConfirmationURL.searchParams.set('bookingReference', bookingReference);
    amendConfirmationURL.searchParams.set('tempBookingReference', temporaryBasketReference);
    amendConfirmationURL.searchParams.set('basketReference', originalBasketReference);
    confirmAmendMutation.mutateAsync(params).then(() => {
      intervalRef.current = setInterval((0, _asyncToGenerator2.default)(function* () {
        var {
          basketStatus,
          basketError
        } = yield (0, _helpers.getBasketStatus)(queryClient, temporaryBasketReference);
        if (basketStatus === _api.BASKET_STATUS.AMENDED) {
          var _intervalRef$current2;
          clearInterval((_intervalRef$current2 = intervalRef.current) !== null && _intervalRef$current2 !== void 0 ? _intervalRef$current2 : '');
          amendConfirmationURL.searchParams.set('status', _api.CONFIRM_AMEND_STATUS.success);
          yield router.push(amendConfirmationURL);
        } else if (basketStatus === _api.BASKET_STATUS.AMEND_FAILED || basketStatus === _api.BASKET_STATUS.AMENDING && basketError) {
          var _intervalRef$current3;
          clearInterval((_intervalRef$current3 = intervalRef.current) !== null && _intervalRef$current3 !== void 0 ? _intervalRef$current3 : '');
          amendConfirmationURL.searchParams.set('status', _api.CONFIRM_AMEND_STATUS.error);
          yield router.push(amendConfirmationURL);
          setConfirmAmendErrorValue(basketError.code);
        }
      }), 1000);
    }).catch(() => {
      amendConfirmationURL.searchParams.set('status', _api.CONFIRM_AMEND_STATUS.error);
      router.push(amendConfirmationURL);
    });
  }
}
var sectionStyles = {
  width: '24.5rem',
  pt: 'xl'
};