"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = ArrivalDate;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _react = require("@chakra-ui/react");
var _atoms = require("@whitbread-eos/atoms");
var _utils = require("@whitbread-eos/utils");
var _add = _interopRequireDefault(require("date-fns/add"));
var _reactHookForm = require("react-hook-form");
var _jsxRuntime = require("react/jsx-runtime");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function ArrivalDate(_ref) {
  var _formField$props, _formField$props2;
  var {
    formField,
    control
  } = _ref;
  var testId = formField === null || formField === void 0 ? void 0 : formField.testid;
  var defaultDate = new Date();
  var todayLabel = ((_formField$props = formField.props) === null || _formField$props === void 0 ? void 0 : _formField$props.todayLabel) || 'Today';
  var tomorrowLabel = ((_formField$props2 = formField.props) === null || _formField$props2 === void 0 ? void 0 : _formField$props2.tomorrowLabel) || 'Tomorrow';
  var maxDate = (0, _add.default)(new Date(), {
    days: 364
  });
  var {
    language
  } = (0, _utils.useCustomLocale)();
  var [isLargerThanSm] = (0, _react.useMediaQuery)('(min-width: 576px)');
  return (0, _jsxRuntime.jsx)(_react.Box, {
    "data-testid": (0, _utils.formatDataTestId)(testId, 'Container'),
    children: (0, _jsxRuntime.jsx)(_reactHookForm.Controller, {
      name: formField === null || formField === void 0 ? void 0 : formField.name,
      control: control,
      render: _ref2 => {
        var {
          field
        } = _ref2;
        var {
          onChange
        } = field;
        return (0, _jsxRuntime.jsx)(_atoms.SingleDatePicker, _objectSpread(_objectSpread({}, language === 'de' ? {
          locale: 'de'
        } : {
          locale: 'en'
        }), {}, {
          name: formField === null || formField === void 0 ? void 0 : formField.name,
          minDate: defaultDate,
          maxDate: maxDate,
          inputPlaceholder: formField === null || formField === void 0 ? void 0 : formField.label,
          inputLabel: formField === null || formField === void 0 ? void 0 : formField.label,
          defaultStartDate: defaultDate,
          isRightIcon: true,
          popperPlacement: isLargerThanSm ? 'bottom' : 'top',
          displayDateFormat: "EEE d MMMM yyyy",
          dateFormat: "dd MMM yyyy",
          labels: {
            todayLabel,
            tomorrowLabel
          },
          datepickerStyles: {
            inputGroupStyles: {},
            datepickerInputElementStyles: inputStyle,
            bookingDatepickerSize: {},
            iconStyles: inputIconStyles
          },
          onSelectDate: date => {
            onChange(date);
          }
        }));
      }
    })
  });
}
var inputStyle = {
  height: 'var(--chakra-space-4xl)',
  _placeholder: {
    opacity: 0.7,
    color: 'var(--chakra-darkGrey1)'
  }
};
var inputIconStyles = {
  top: 'var(--chakra-space-sm)'
};