"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = RateCards;
var _api = require("@whitbread-eos/api");
var _molecules = require("@whitbread-eos/molecules");
var _utils = require("@whitbread-eos/utils");
var _nextI18next = require("next-i18next");
var _jsxRuntime = require("react/jsx-runtime");
function RateCards(_ref) {
  var _data$hotelAvailabili;
  var {
    roomClassCodes,
    data,
    brand,
    channel,
    selectedRoomClassAndRate,
    setSelectedRoomClassAndRate,
    roomTypeInformationResponse,
    isLessThanSm,
    isLessThanMd,
    basketData,
    allRoomTypes
  } = _ref;
  var {
    language
  } = (0, _utils.useCustomLocale)();
  var {
    t
  } = (0, _nextI18next.useTranslation)(['common']);
  var roomRates = (data === null || data === void 0 || (_data$hotelAvailabili = data.hotelAvailability) === null || _data$hotelAvailabili === void 0 ? void 0 : _data$hotelAvailabili.roomRates) || [];
  var roomTypes = (0, _utils.getAvailableRoomTypes)(allRoomTypes);
  var renderAdditionalRateCardInfo = !((roomTypes === null || roomTypes === void 0 ? void 0 : roomTypes.length) > 1 || (basketData === null || basketData === void 0 ? void 0 : basketData.numberOfUnits) > 1 && (roomClassCodes === null || roomClassCodes === void 0 ? void 0 : roomClassCodes.length) > 1);
  return (0, _jsxRuntime.jsx)(_jsxRuntime.Fragment, {
    children: roomClassCodes.map(roomClassCode => {
      var _roomRatesByRoomClass, _roomRatesByRoomClass2;
      var byRoomClassCode = roomRate => {
        return roomRate.roomTypes.every(roomType => roomType.rooms.some(room => room.roomClass === roomClassCode));
      };
      var roomRatesByRoomClassCode = roomRates.filter(byRoomClassCode);
      var roomClass = (0, _utils.getRoomClassByRoomClassCode)(roomClassCode, language);
      var rateClassifications = roomRates.map(roomRate => {
        var _data$ratesInformatio;
        return (0, _utils.getRateClassification)(roomRate === null || roomRate === void 0 ? void 0 : roomRate.ratePlanCode, data === null || data === void 0 || (_data$ratesInformatio = data.ratesInformation) === null || _data$ratesInformatio === void 0 ? void 0 : _data$ratesInformatio.rateClassifications);
      });
      var pmsRoomType = (_roomRatesByRoomClass = roomRatesByRoomClassCode === null || roomRatesByRoomClassCode === void 0 || (_roomRatesByRoomClass2 = roomRatesByRoomClassCode[0]) === null || _roomRatesByRoomClass2 === void 0 || (_roomRatesByRoomClass2 = _roomRatesByRoomClass2.roomTypes) === null || _roomRatesByRoomClass2 === void 0 || (_roomRatesByRoomClass2 = _roomRatesByRoomClass2[0]) === null || _roomRatesByRoomClass2 === void 0 || (_roomRatesByRoomClass2 = _roomRatesByRoomClass2.rooms.find(room => room.roomClass === roomClassCode)) === null || _roomRatesByRoomClass2 === void 0 ? void 0 : _roomRatesByRoomClass2.pmsRoomType) !== null && _roomRatesByRoomClass !== void 0 ? _roomRatesByRoomClass : '';
      return (0, _jsxRuntime.jsx)(_molecules.RateCard, {
        brand: brand,
        channel: channel,
        roomClassCode: roomClassCode,
        roomClass: _api.RoomClassLabelByCode[roomClassCode] ? t(_api.RoomClassLabelByCode[roomClassCode]) : roomClass,
        pmsRoomType: pmsRoomType,
        roomRates: roomRates,
        rateClassifications: rateClassifications,
        selectedRoomClassAndRate: selectedRoomClassAndRate,
        setSelectedRoomClassAndRate: setSelectedRoomClassAndRate,
        roomTypeInformationResponse: roomTypeInformationResponse,
        isLessThanSm: isLessThanSm,
        isLessThanMd: isLessThanMd,
        numberOfNights: basketData.numberOfNights,
        numberOfUnits: basketData.numberOfUnits,
        roomTypes: roomTypes,
        showAdditionalInfo: renderAdditionalRateCardInfo,
        roomRatesThatMatchRoomClassifications: (0, _utils.getRoomRatesThatMatchRoomClassifications)(rateClassifications, roomRatesByRoomClassCode)
      }, roomClass);
    })
  });
}