"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = TotalCostConfirmation;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _react = require("@chakra-ui/react");
var _utils = require("@whitbread-eos/utils");
var _react2 = _interopRequireDefault(require("react"));
var _jsxRuntime = require("react/jsx-runtime");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function TotalCostConfirmation(_ref) {
  var {
    totalCostAmount,
    currency,
    language,
    t
  } = _ref;
  if (!totalCostAmount) return null;
  return (0, _jsxRuntime.jsx)(_react.Box, _objectSpread(_objectSpread({
    "data-testid": 'confirmation-totalCost'
  }, totalCostWrapperStyle), {}, {
    children: (0, _jsxRuntime.jsx)(_react.Box, {
      p: "lg",
      children: (0, _jsxRuntime.jsxs)(_react.Flex, {
        w: "full",
        children: [(0, _jsxRuntime.jsxs)(_react.Flex, {
          direction: "column",
          children: [(0, _jsxRuntime.jsx)(_react.Text, {
            as: "b",
            "data-testid": "totalCost-label",
            children: "".concat(t('booking.confirmation.totalCost'), ":")
          }), (0, _jsxRuntime.jsx)(_react.Text, {
            "data-testid": "totalCost-paymentMessage",
            children: t('booking.confirmation.paymentTaken')
          })]
        }), (0, _jsxRuntime.jsx)(_react.Spacer, {}), (0, _jsxRuntime.jsx)(_react.Text, _objectSpread(_objectSpread({
          "data-testid": 'totalCost-amount'
        }, totalCostAmountStyle), {}, {
          children: (0, _utils.formatPrice)((0, _utils.formatCurrency)(currency), totalCostAmount.toFixed(2), language)
        }))]
      })
    })
  }));
}
var totalCostWrapperStyle = {
  mb: '2xl',
  backgroundColor: 'lightGrey5'
};
var totalCostAmountStyle = {
  as: 'b',
  fontSize: '3xxl',
  color: 'darkGrey1'
};