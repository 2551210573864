"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var React = _interopRequireWildcard(require("react"));
var _jsxRuntime = require("react/jsx-runtime");
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && {}.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
var SvgComfortableArmchair = props => (0, _jsxRuntime.jsx)("svg", _objectSpread(_objectSpread({
  width: 18,
  height: 20,
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg"
}, props), {}, {
  children: (0, _jsxRuntime.jsx)("path", {
    fillRule: "evenodd",
    clipRule: "evenodd",
    d: "M11.32 0a4 4 0 0 1 3.986 3.668L15.917 11H16a2 2 0 0 1 2 2v3h-1.5l-.5 4h-1l-.5-4h-11L3 20H2l-.5-4H0v-3a2 2 0 0 1 2-2h.083l.611-7.332A4 4 0 0 1 6.681 0h4.638ZM16 12H2a1 1 0 0 0-.993.883L1 13v2h16v-2a1 1 0 0 0-.883-.993L16 12ZM11.32 1H6.68a3 3 0 0 0-2.969 2.573l-.02.178L3.086 11H5V9a4 4 0 1 1 8 0v2h1.913l-.604-7.25a3 3 0 0 0-2.81-2.745L11.318 1ZM9 6a3 3 0 0 0-2.995 2.824L6 9v2h6V9a3 3 0 0 0-2.824-2.995L9 6Z",
    fill: props.color || '#58595B'
  })
}));
var _default = exports.default = SvgComfortableArmchair;