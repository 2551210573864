"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = StayDates;
exports.getNightsLabel = getNightsLabel;
exports.getNightsOptions = getNightsOptions;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _react = require("@chakra-ui/react");
var _api = require("@whitbread-eos/api");
var _atoms = require("@whitbread-eos/atoms");
var _utils = require("@whitbread-eos/utils");
var _add = _interopRequireDefault(require("date-fns/add"));
var _format = _interopRequireDefault(require("date-fns/format"));
var _de = _interopRequireDefault(require("date-fns/locale/de"));
var _enGB = _interopRequireDefault(require("date-fns/locale/en-GB"));
var _react2 = require("react");
var _NumberOfNights = _interopRequireDefault(require("../../search/NumberOfNights"));
var _HotelNameInput = _interopRequireDefault(require("../HotelNameInput"));
var _jsxRuntime = require("react/jsx-runtime");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function StayDates(_ref) {
  var {
    data,
    labels,
    baseDataTestId,
    language,
    onAmendStayDates,
    isLoading,
    isCancellable,
    variant
  } = _ref;
  var prefixDataTestId = 'stay-dates';
  var {
    hotelName,
    arrivalDate,
    departureDate,
    maxNights,
    maxArrivalDate,
    originalArrivalDate
  } = data;
  var maxDate = (0, _add.default)(new Date(), {
    days: maxArrivalDate
  });
  var numberOfNights = (0, _utils.getNightsNumber)((0, _format.default)(arrivalDate, _api.DATE_TYPE.YEAR_MONTH_DAY), (0, _format.default)(departureDate, _api.DATE_TYPE.YEAR_MONTH_DAY));
  var nightsOptions = getNightsOptions(maxNights, labels);
  var [selectedNightOption, setSelectedNightOption] = (0, _react2.useState)({
    label: '',
    id: numberOfNights
  });
  var [arrival, setArrival] = (0, _react2.useState)(arrivalDate);
  var [nightsInput, setNightsInput] = (0, _react2.useState)(numberOfNights);
  var nightsValue = variant === _api.Area.CCUI ? Number(nightsInput) : Number(selectedNightOption.id);
  var checkOut = (0, _format.default)((0, _add.default)(arrival, {
    days: nightsValue
  }), _api.DATE_TYPE.EEE_DAY_MONTH_YEAR, {
    locale: language === _api.CountryCode.EN ? _enGB.default : _de.default
  });
  var noOfNightsError = nightsInput ? labels.numberOfNightsErrorMessage : labels.invalidNights;
  function handleNightsDropdown(option) {
    var nightsInput = option.id;
    var endDate = (0, _add.default)(arrival, {
      days: Number(nightsInput)
    });
    setSelectedNightOption(option);
    onAmendStayDates(arrival, endDate);
  }
  function handleNightsInput() {
    if (nightsInput > maxNights || nightsInput === numberOfNights || nightsInput === 0) return;
    var endDate = (0, _add.default)(arrival, {
      days: Number(nightsInput)
    });
    onAmendStayDates(arrival, endDate);
  }
  return (0, _jsxRuntime.jsx)(_jsxRuntime.Fragment, {
    children: isLoading ? (0, _jsxRuntime.jsx)(_atoms.LoadingSpinner, {}) : (0, _jsxRuntime.jsxs)(_react.Box, _objectSpread(_objectSpread({
      "data-testid": "".concat(baseDataTestId, "-").concat(prefixDataTestId, "-section")
    }, wrapperStyles), {}, {
      children: [(0, _jsxRuntime.jsxs)(_react.Box, {
        children: [(0, _jsxRuntime.jsx)(_react.Text, _objectSpread(_objectSpread({
          "data-testid": "".concat(baseDataTestId, "-").concat(prefixDataTestId, "-hotel-label")
        }, hotelLabelStyles), {}, {
          children: labels.hotel
        })), (0, _jsxRuntime.jsx)(_HotelNameInput.default, {
          hotelName: hotelName,
          onInputChange: () => {
            return;
          }
        })]
      }), (0, _jsxRuntime.jsxs)(_react.Flex, _objectSpread(_objectSpread({}, datesWrapperStyles), {}, {
        children: [(0, _jsxRuntime.jsxs)(_react.Box, _objectSpread(_objectSpread({}, arrivalDateWrapperStyles), {}, {
          children: [(0, _jsxRuntime.jsx)(_react.Text, _objectSpread(_objectSpread({
            "data-testid": "".concat(baseDataTestId, "-").concat(prefixDataTestId, "-arrival-date-label")
          }, dateLabelsStyles), {}, {
            children: labels.arrivalDate
          })), (0, _jsxRuntime.jsx)(_atoms.Datepicker, {
            locale: language,
            dateFormat: _api.DATE_TYPE.EEE_DAY_MONTH_YEAR,
            datepickerStyles: datepickerStyles,
            displayDateFormat: _api.DATE_TYPE.EEE_DAY_MONTH_YEAR,
            minDate: new Date(),
            maxDate: maxDate,
            defaultStartDate: arrival,
            closeCalendarOnSelectDate: true,
            onSelectDates: date => {
              var startDate = date || originalArrivalDate;
              var endDate = (0, _add.default)(startDate, {
                days: nightsValue
              });
              if ((0, _utils.isSameDate)(startDate, arrival)) return;
              setArrival(startDate);
              onAmendStayDates(startDate, endDate);
            }
          })]
        })), (0, _jsxRuntime.jsxs)(_react.Box, _objectSpread(_objectSpread({}, numberOfNightsWrapperStyles), {}, {
          children: [(0, _jsxRuntime.jsx)(_react.Text, _objectSpread(_objectSpread({
            "data-testid": "".concat(baseDataTestId, "-").concat(prefixDataTestId, "-nights-label")
          }, dateLabelsStyles), {}, {
            children: labels.nightsLabel
          })), variant === _api.Area.CCUI ? (0, _jsxRuntime.jsx)(_NumberOfNights.default, {
            inputValue: isNaN(nightsInput) ? 0 : nightsInput,
            handleOnChange: e => setNightsInput(Number(e.target.value)),
            handleOnBlur: handleNightsInput,
            noOfNightsError: noOfNightsError,
            maxNights: maxNights,
            styles: {
              inputElementStyles: noOfNightsInputElementStyles,
              iconStyles,
              tooltipStyles
            },
            isDisabled: !isCancellable
          }, "NumberOfNights") : (0, _jsxRuntime.jsx)(_atoms.Dropdown, {
            dataTestId: "".concat(baseDataTestId, "-").concat(prefixDataTestId, "-nights"),
            options: nightsOptions,
            selectedId: selectedNightOption === null || selectedNightOption === void 0 ? void 0 : selectedNightOption.id.toString(),
            dropdownStyles: dropdownStyles,
            disabled: !isCancellable,
            onChange: handleNightsDropdown,
            matchWidth: true
          })]
        })), (0, _jsxRuntime.jsxs)(_react.Flex, _objectSpread(_objectSpread({}, departureDateWrapperStyles), {}, {
          children: [(0, _jsxRuntime.jsx)(_react.Text, _objectSpread(_objectSpread({
            "data-testid": "".concat(baseDataTestId, "-").concat(prefixDataTestId, "-checkOutLabel")
          }, dateLabelsStyles), {}, {
            fontWeight: "400",
            paddingBottom: "0",
            paddingRight: {
              mobile: 'sm',
              md: '0'
            },
            children: labels.checkOut
          })), (0, _jsxRuntime.jsx)(_react.Text, _objectSpread(_objectSpread({
            "data-testid": "".concat(baseDataTestId, "-").concat(prefixDataTestId, "-checkOutDate")
          }, dateLabelsStyles), {}, {
            paddingBottom: "0",
            children: checkOut
          }))]
        }))]
      }))]
    }))
  });
}
function getNightsOptions(nights, labels) {
  var options = [];
  for (var idx = 0; idx < nights; idx++) {
    options.push(idx + 1);
  }
  return options.map(element => ({
    label: "".concat(element, " ").concat(getNightsLabel(element, labels)),
    id: element
  }));
}
function getNightsLabel(nights, labels) {
  if (nights === 1) {
    return labels.nightOption;
  }
  return labels.nightsOption;
}
var dropdownStyles = {
  menuButtonStyles: {
    borderColor: 'lightGrey1',
    color: 'darkGrey2'
  },
  menuListStyles: {
    h: '12.5rem',
    pt: 0,
    pb: 0
  },
  wrapperStyles: {
    zIndex: 3
  }
};
var wrapperStyles = {
  px: 'var(--chakra-space-lg)',
  py: 'var(--chakra-space-2xl)',
  cursor: 'default'
};
var hotelLabelStyles = {
  fontSize: 'md',
  paddingBottom: '0.563rem',
  fontWeight: 'bold',
  lineHeight: '3',
  color: 'darkGrey2',
  minW: {
    mobile: '15rem',
    xs: '18.438rem'
  }
};
var datesWrapperStyles = {
  width: '100%',
  paddingTop: 'var(--chakra-space-2xl)',
  flexDirection: {
    mobile: 'column',
    md: 'row'
  },
  minW: {
    mobile: '15rem',
    xs: '18.438rem'
  }
};
var arrivalDateWrapperStyles = {
  w: {
    mobile: '100%',
    md: '20.25rem',
    lg: '24.75rem'
  },
  marginRight: {
    mobile: 0,
    md: 'var(--chakra-space-lg)'
  },
  marginBottom: {
    mobile: 'var(--chakra-space-lg)',
    md: '0'
  }
};
var numberOfNightsWrapperStyles = {
  w: {
    mobile: '100%',
    md: '9rem'
  },
  marginRight: {
    mobile: 0,
    md: 'var(--chakra-space-lg)'
  },
  marginBottom: {
    mobile: 'var(--chakra-space-lg)',
    md: '0'
  }
};
var departureDateWrapperStyles = {
  alignSelf: {
    mobile: 'flex-start',
    md: 'flex-end'
  },
  flexDirection: {
    mobile: 'row',
    md: 'column'
  }
};
var dateLabelsStyles = {
  fontSize: 'md',
  fontWeight: 'bold',
  lineHeight: '3',
  color: 'darkGrey2',
  paddingBottom: 'var(--chakra-space-sm)'
};
var datepickerStyles = {
  datepickerInputElementStyles: {
    border: '1px solid',
    borderColor: 'lightGrey1',
    borderRight: '1px solid',
    borderRightColor: 'lightGrey1',
    borderRadius: 'var(--chakra-space-xs)',
    borderTopRightRadius: 'var(--chakra-space-xs)',
    borderBottomRightRadius: 'var(--chakra-space-xs)',
    height: 'var(--chakra-space-4xl)',
    minW: {
      mobile: '15rem',
      xs: '18.438rem'
    }
  },
  inputGroupStyles: {
    height: 'var(--chakra-space-4xl)',
    borderColor: {
      mobile: 'transparent'
    }
  },
  iconStyles: {
    top: 'var(--chakra-space-sm)'
  }
};
var noOfNightsInputElementStyles = {
  border: '1px solid',
  borderColor: 'lightGrey1',
  borderRadius: 'base',
  _hover: {
    border: '1px solid var(--chakra-colors-darkGrey1)',
    borderRadius: 'base'
  },
  _focus: {
    border: '2px solid var(--chakra-colors-primary)',
    cursor: 'auto',
    borderRadius: 'base'
  },
  padding: 'var(--chakra-space-lg) var(--chakra-space-lg) var(--chakra-space-lg) 3.7rem',
  w: 'var(--chakra-space-36)',
  h: 'var(--chakra-space-4xl)'
};
var iconStyles = {
  top: 'var(--chakra-space-sm)',
  left: ' var(--chakra-space-md)'
};
var tooltipStyles = {
  w: '17rem',
  top: '2xl',
  fontSize: 'sm',
  lineHeight: '3',
  fontWeight: 'normal'
};