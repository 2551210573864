"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = HotelLocationComponent;
var _react = require("@chakra-ui/react");
var _nextI18next = require("next-i18next");
var _jsxRuntime = require("react/jsx-runtime");
function HotelLocationComponent(_ref) {
  var {
    language,
    distanceFromSearch,
    referrer
  } = _ref;
  var {
    t
  } = (0, _nextI18next.useTranslation)(['common']);
  if (!distanceFromSearch || Number.isNaN(Number(distanceFromSearch)) || !(referrer !== null && referrer !== void 0 && referrer.includes('search.html'))) {
    return null;
  }
  return (0, _jsxRuntime.jsxs)(_react.Text, {
    "data-testid": "hdp_hotelDistanceFromSearch",
    children: [(0, _jsxRuntime.jsxs)("strong", {
      children: [Math.round(Number(distanceFromSearch) * 10) / 10, " ", language === 'en' ? 'miles' : 'km']
    }), " ".concat(t('searchresults.list.hotel.fromSearch'))]
  });
}