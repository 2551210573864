"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = ConsentNotificationModalContainer;
var _utils = require("@whitbread-eos/utils");
var _react = require("react");
var _ConsentNotificationModal = _interopRequireDefault(require("./ConsentNotificationModal.component"));
var _ConsentNotificationModal2 = require("./ConsentNotificationModal.constants");
var _jsxRuntime = require("react/jsx-runtime");
function ConsentNotificationModalContainer(_ref) {
  var _getCookie;
  var {
    handleNotificationPermission
  } = _ref;
  var [isModalOpen, setIsModalOpen] = (0, _react.useState)(false);
  var pushRequestClosedCount = Number((_getCookie = (0, _utils.getCookie)(_ConsentNotificationModal2.PUSH_REQUEST_CLOSED_COUNT)) !== null && _getCookie !== void 0 ? _getCookie : 0);
  var pushRequestClosedTimestamp = (0, _utils.getCookie)(_ConsentNotificationModal2.PUSH_REQUEST_CLOSED_TIMESTAMP);
  var setClosedCountCookie = count => {
    var closedCount = pushRequestClosedCount;
    closedCount = count !== null && count !== void 0 ? count : closedCount + 1;
    (0, _utils.setCookieWithDefaultDomain)(_ConsentNotificationModal2.PUSH_REQUEST_CLOSED_COUNT, closedCount, undefined);
  };
  var setClosedTimestampCookie = () => {
    var currentTimestamp = new Date().toString();
    var cookieExpiresTime = _ConsentNotificationModal2.ONE_DAY_IN_MINUTES;
    (0, _utils.setCookieWithDefaultDomain)(_ConsentNotificationModal2.PUSH_REQUEST_CLOSED_TIMESTAMP, currentTimestamp, cookieExpiresTime);
  };
  var handleAllowAction = () => {
    setClosedCountCookie();
    setClosedTimestampCookie();
    handleNotificationPermission(true);
    setIsModalOpen(false);
  };
  var handleDoNotAllowAction = () => {
    setClosedCountCookie(_ConsentNotificationModal2.MAX_CLOSE_COUNT);
    setClosedTimestampCookie();
    handleNotificationPermission(false);
    setIsModalOpen(false);
  };
  var handleCloseAction = () => {
    setClosedCountCookie();
    setClosedTimestampCookie();
    handleNotificationPermission(false);
    setIsModalOpen(false);
  };
  (0, _react.useEffect)(() => {
    var timer;
    if (pushRequestClosedCount < _ConsentNotificationModal2.MAX_CLOSE_COUNT && !pushRequestClosedTimestamp) {
      timer = setTimeout(() => setIsModalOpen(true), 2000);
    }
    return () => clearTimeout(timer);
  }, [pushRequestClosedCount, pushRequestClosedTimestamp]);
  return (0, _jsxRuntime.jsx)(_ConsentNotificationModal.default, {
    isModalVisible: isModalOpen,
    onConsentModalClose: handleCloseAction,
    onConsentModalAllow: handleAllowAction,
    onConsentModalDeny: handleDoNotAllowAction
  });
}