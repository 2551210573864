"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = LiveAssist;
var _atoms = require("@whitbread-eos/atoms");
var _utils = require("@whitbread-eos/utils");
var _react = require("react");
var _jsxRuntime = require("react/jsx-runtime");
function LiveAssist() {
  var {
    isLoggedIn
  } = (0, _utils.useUserData)();
  (0, _react.useEffect)(() => {
    isLoggedIn ? showLiveAssist() : hideLiveAssist();
  }, [isLoggedIn]);
  var liveAssistVisibility = visibility => {
    if (typeof window !== 'undefined') {
      var liveassistContainer = Array.from(document.getElementsByClassName('LPMcontainer'));
      setVisibility(liveassistContainer[0], visibility);
      var liveassistExpanded = document.getElementById('lpChat');
      setVisibility(liveassistExpanded, visibility);
    }
  };
  var setVisibility = (element, visibility) => {
    element && (element.style.visibility = visibility);
  };
  var hideLiveAssist = () => {
    liveAssistVisibility('hidden');
  };
  var showLiveAssist = () => {
    liveAssistVisibility('visible');
  };
  return (0, _jsxRuntime.jsx)(_jsxRuntime.Fragment, {
    children: isLoggedIn && (0, _jsxRuntime.jsx)(_atoms.LiveAssistScriptEmbed, {})
  });
}