"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = OurRates;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _react = require("@chakra-ui/react");
var _atoms = require("@whitbread-eos/atoms");
var _utils = require("@whitbread-eos/utils");
var _nextI18next = require("next-i18next");
var _react2 = require("react");
var _jsxRuntime = require("react/jsx-runtime");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function reOrderRatePLans(rateClassifications) {
  return rateClassifications.sort((a, b) => (a === null || a === void 0 ? void 0 : a.rateOrder) - (b === null || b === void 0 ? void 0 : b.rateOrder));
}
function OurRates(_ref) {
  var {
    brand,
    rateClassifications
  } = _ref;
  var {
    t
  } = (0, _nextI18next.useTranslation)(['common']);
  var [isModalOpen, setIsModalOpen] = (0, _react2.useState)(false);
  function toggleModal() {
    setIsModalOpen(!isModalOpen);
  }
  var corporateDiscountRatePlanCode = (0, _utils.getCorporateDiscountRatePlanCode)(rateClassifications);
  if (corporateDiscountRatePlanCode) {
    reOrderRatePLans(rateClassifications);
  }
  var additionalInformation = corporateDiscountRatePlanCode && (0, _jsxRuntime.jsxs)(_react.Box, {
    children: [(0, _utils.renderSanitizedHtml)(t("promotion.discountrate.".concat(corporateDiscountRatePlanCode, ".additionalinformation"))), (0, _jsxRuntime.jsx)(_react.Divider, _objectSpread({}, dividerStyle))]
  });
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_react.Box, {
      mt: "sm",
      children: (0, _jsxRuntime.jsx)(_atoms.Notification, {
        border: "none",
        status: "info",
        prefixDataTestId: "hdp_ratesExplainedLink",
        variant: "infoGrey",
        svg: (0, _jsxRuntime.jsx)(_atoms.Info, {}),
        textDecoration: "underline",
        isInnerHTML: true,
        description: (0, _jsxRuntime.jsx)(_react.Text, _objectSpread(_objectSpread({}, ourRatesExplainedLinkStyles), {}, {
          "data-testid": "hdp_ratesExplainedLinkText",
          onClick: toggleModal,
          children: t('pihotelinfo.ratesExplained')
        }))
      })
    }), (0, _jsxRuntime.jsx)(_atoms.ModalVariants, {
      onClose: toggleModal,
      isOpen: isModalOpen,
      variant: "info",
      variantProps: {
        title: t('pihotelinfo.ourRates'),
        delimiter: true
      },
      children: (0, _jsxRuntime.jsxs)(_react.Box, {
        p: 6,
        pt: 0,
        children: [additionalInformation, rateClassifications === null || rateClassifications === void 0 ? void 0 : rateClassifications.map((rate, index) => {
          if (rate) {
            return (0, _jsxRuntime.jsxs)(_react.Box, {
              children: [(0, _jsxRuntime.jsx)(_react.Text, {
                as: "b",
                children: "".concat(brand.toLowerCase() === 'hub' ? 'hub ' : '').concat(rate === null || rate === void 0 ? void 0 : rate.rateName)
              }), (0, _jsxRuntime.jsx)(_react.Text, {
                pb: "md",
                children: rate === null || rate === void 0 ? void 0 : rate.rateDescription
              })]
            }, index);
          }
        })]
      })
    })]
  });
}
var ourRatesExplainedLinkStyles = {
  fontSize: 'md',
  textDecoration: 'underline',
  _hover: {
    cursor: 'pointer'
  }
};
var dividerStyle = {
  my: 'md',
  border: 'border: 1px solid'
};