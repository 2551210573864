"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = AnalyticsProviderContainer;
var _reactQuery = require("@tanstack/react-query");
var _AnalyticsProvider = require("./AnalyticsProvider.component");
var _jsxRuntime = require("react/jsx-runtime");
function AnalyticsProviderContainer(_ref) {
  var {
    children,
    initialAnalyticsData,
    queryClient,
    isBusinessBooker
  } = _ref;
  return (0, _jsxRuntime.jsx)(_reactQuery.QueryClientProvider, {
    client: queryClient,
    children: (0, _jsxRuntime.jsx)(_AnalyticsProvider.AnalyticsProviderComponent, {
      initialAnalyticsData: initialAnalyticsData,
      isBusinessBooker: isBusinessBooker,
      children: children
    })
  });
}