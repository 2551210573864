"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.paymentOptions = void 0;
var paymentOptions = exports.paymentOptions = function (paymentOptions) {
  paymentOptions["PAY_NOW"] = "PAY_NOW";
  paymentOptions["PAY_ON_ARRIVAL"] = "PAY_ON_ARRIVAL";
  paymentOptions["RESERVE_WITHOUT_CARD"] = "RESERVE_WITHOUT_CARD";
  paymentOptions["ACCOUNT_TO_COMPANY"] = "ACCOUNT_COMPANY";
  return paymentOptions;
}({});