"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = BusinessSideNav;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _react = require("@chakra-ui/react");
var _HeaderSideNav = _interopRequireDefault(require("../HeaderSideNav"));
var _NavigationMenuMobile = require("../NavigationMenuMobile.style");
var _jsxRuntime = require("react/jsx-runtime");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function BusinessSideNav(_ref) {
  var _labels$businessSubNa, _labels$businessSubNa2;
  var {
    onClickHeaderTitle,
    labels
  } = _ref;
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_HeaderSideNav.default, {
      title: labels === null || labels === void 0 || (_labels$businessSubNa = labels.businessSubNav) === null || _labels$businessSubNa === void 0 ? void 0 : _labels$businessSubNa.title,
      onClickTitle: onClickHeaderTitle
    }), (0, _jsxRuntime.jsx)(_react.ModalBody, {
      p: "0",
      "data-testid": "businessSideNav",
      children: (labels === null || labels === void 0 || (_labels$businessSubNa2 = labels.businessSubNav) === null || _labels$businessSubNa2 === void 0 ? void 0 : _labels$businessSubNa2.navOptions) && renderItems(labels.businessSubNav.navOptions)
    })]
  });
  function renderItems(items) {
    if (!items) {
      return;
    }
    return (0, _jsxRuntime.jsx)(_jsxRuntime.Fragment, {
      children: items.map(option => {
        return (0, _jsxRuntime.jsx)(_react.Box, _objectSpread(_objectSpread({}, _NavigationMenuMobile.containerItemStyle), {}, {
          "data-testid": "listItem",
          children: option.title
        }), option.title);
      })
    });
  }
}