"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = BookingInfoCardHeaderContainer;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));
var _react = require("@chakra-ui/react");
var _reactQuery = require("@tanstack/react-query");
var _api = require("@whitbread-eos/api");
var _atoms = require("@whitbread-eos/atoms");
var _utils = require("@whitbread-eos/utils");
var _nextI18next = require("next-i18next");
var _react2 = require("react");
var _BookingInfoCardHeader = _interopRequireDefault(require("./BookingInfoCardHeader.component"));
var _jsxRuntime = require("react/jsx-runtime");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function BookingInfoCardHeaderContainer(_ref) {
  var _useMemo, _useMemo2;
  var {
    bookingReference,
    basketReference,
    isAmendPage,
    area
  } = _ref;
  var {
    t
  } = (0, _nextI18next.useTranslation)();
  var queryClient = (0, _reactQuery.useQueryClient)();
  var {
    language,
    country
  } = (0, _utils.useCustomLocale)();
  var [bookingData, setBookingData] = (0, _react2.useState)({
    hotelName: '',
    checkInTime: '',
    checkOutTime: '',
    arrivalDate: '',
    departureDate: ''
  });
  var [bookingState, setBookingState] = (0, _react2.useState)({
    isLoading: false,
    error: null
  });
  var idTokenCookie = (0, _utils.getAuthCookie)();
  var loggedOrCCUI = idTokenCookie || area === _api.Area.CCUI;
  (0, _react2.useEffect)(() => {
    if (isAmendPage) {
      queryClient.invalidateQueries({
        queryKey: [loggedOrCCUI ? 'getBookingConfirmationAuthenticated' : 'getBookingConfirmation', loggedOrCCUI ? bookingReference : basketReference, language, country]
      });
    }
  }, [isAmendPage]);
  var getDetails = (0, _react2.useCallback)(function () {
    var _ref2 = (0, _asyncToGenerator2.default)(function* (basketReference, bookingReference) {
      setBookingState(_objectSpread(_objectSpread({}, bookingState), {}, {
        isLoading: true
      }));
      var bookingConfirmationQuery = queryClient.fetchQuery({
        queryKey: [loggedOrCCUI ? 'getBookingConfirmationAuthenticated' : 'getBookingConfirmation', loggedOrCCUI ? bookingReference : basketReference, language, country],
        queryFn: () => (0, _utils.graphQLRequest)(loggedOrCCUI ? _api.GET_DASHBOARD_BOOKING_CONFIRMATION_AUTHENTICATED : _api.GET_DASHBOARD_BOOKING_CONFIRMATION, loggedOrCCUI ? {
          bookingReference,
          language,
          country,
          bookingChannel: area === null || area === void 0 ? void 0 : area.toUpperCase()
        } : {
          basketReference,
          language,
          country,
          bookingChannel: area === null || area === void 0 ? void 0 : area.toUpperCase()
        }, loggedOrCCUI ? idTokenCookie : undefined)
      });
      yield bookingConfirmationQuery.then(function () {
        var _ref3 = (0, _asyncToGenerator2.default)(function* (bookingConfirmationData) {
          var _bookingData$reservat;
          var bookingData = loggedOrCCUI ? bookingConfirmationData === null || bookingConfirmationData === void 0 ? void 0 : bookingConfirmationData.bookingConfirmationAuthenticated : bookingConfirmationData === null || bookingConfirmationData === void 0 ? void 0 : bookingConfirmationData.bookingConfirmation;
          var hotelId = bookingData === null || bookingData === void 0 ? void 0 : bookingData.hotelId;
          var {
            checkInTime,
            checkOutTime,
            arrivalDate,
            departureDate
          } = (bookingData === null || bookingData === void 0 || (_bookingData$reservat = bookingData.reservationByIdList[0]) === null || _bookingData$reservat === void 0 ? void 0 : _bookingData$reservat.roomStay) || {};
          var getHotelNameRequest = queryClient.fetchQuery({
            queryKey: ['GetHotelInformation', hotelId, country, language],
            queryFn: () => (0, _utils.graphQLRequest)(_api.GET_HOTEL_INFORMATION, {
              hotelId,
              language,
              country
            })
          });
          yield getHotelNameRequest.then(data => {
            setBookingData(() => {
              var _data$hotelInformatio;
              return {
                checkInTime,
                checkOutTime,
                arrivalDate,
                departureDate,
                hotelName: data === null || data === void 0 || (_data$hotelInformatio = data.hotelInformation) === null || _data$hotelInformatio === void 0 ? void 0 : _data$hotelInformatio.name
              };
            });
            setBookingState(_objectSpread(_objectSpread({}, bookingState), {}, {
              isLoading: false
            }));
          }).catch(error => {
            setBookingState({
              isLoading: false,
              error: error
            });
          }).catch(error => {
            setBookingState({
              isLoading: false,
              error: error
            });
          });
        });
        return function (_x3) {
          return _ref3.apply(this, arguments);
        };
      }());
    });
    return function (_x, _x2) {
      return _ref2.apply(this, arguments);
    };
  }(), []);
  (0, _react2.useEffect)(() => {
    if ((0, _utils.isStringValid)(bookingReference) && (0, _utils.isStringValid)(basketReference)) {
      getDetails(basketReference, bookingReference);
    }
  }, [basketReference, bookingReference]);
  var checkInLabel = (_useMemo = (0, _react2.useMemo)(() => {
    if ((0, _utils.isStringValid)(bookingData === null || bookingData === void 0 ? void 0 : bookingData.checkInTime) && (0, _utils.isStringValid)(bookingData === null || bookingData === void 0 ? void 0 : bookingData.arrivalDate)) {
      return (0, _utils.checkDateFormatter)(bookingData.checkInTime, bookingData.arrivalDate, language);
    }
  }, [bookingData === null || bookingData === void 0 ? void 0 : bookingData.arrivalDate, bookingData === null || bookingData === void 0 ? void 0 : bookingData.checkInTime, language])) !== null && _useMemo !== void 0 ? _useMemo : '';
  var checkOutLabel = (_useMemo2 = (0, _react2.useMemo)(() => {
    if ((0, _utils.isStringValid)(bookingData === null || bookingData === void 0 ? void 0 : bookingData.checkOutTime) && (0, _utils.isStringValid)(bookingData === null || bookingData === void 0 ? void 0 : bookingData.departureDate)) {
      return (0, _utils.checkDateFormatter)(bookingData.checkOutTime, bookingData.departureDate, language);
    }
  }, [bookingData.checkOutTime, bookingData.departureDate, language])) !== null && _useMemo2 !== void 0 ? _useMemo2 : '';
  if (bookingState.error) {
    return (0, _jsxRuntime.jsx)(_atoms.Notification, {
      status: "error",
      description: bookingState.error,
      variant: "alert",
      maxW: "full",
      svg: (0, _jsxRuntime.jsx)(_atoms.Alert, {})
    });
  }
  if (bookingState.isLoading) {
    return (0, _jsxRuntime.jsx)(_react.Flex, _objectSpread(_objectSpread({}, loadingStyle), {}, {
      "data-testid": "Loading-BookingInfoCardHeader",
      children: (0, _jsxRuntime.jsx)(_atoms.LoadingSpinner, {
        loadingText: t('booking.loading')
      })
    }));
  }
  return (0, _jsxRuntime.jsx)(_BookingInfoCardHeader.default, {
    checkInLabel: checkInLabel,
    checkOutLabel: checkOutLabel,
    hotelName: bookingData === null || bookingData === void 0 ? void 0 : bookingData.hotelName,
    shouldRenderDashboardButton: isAmendPage,
    area: area
  });
}
var loadingStyle = {
  height: '100%',
  width: '100%',
  left: 0,
  top: 0,
  position: 'fixed',
  zIndex: 999,
  backgroundColor: 'baseWhite',
  textAlign: 'center',
  lineHeight: 3,
  justifyContent: 'center',
  color: 'btnSecondaryEnabled',
  fontSize: 'xl',
  flex: 'none',
  flexGrow: 0,
  order: 1,
  alignSelf: 'stretch'
};