"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.INITIATE_PAYPAL_PAYMENT_MUTATION = void 0;
var _taggedTemplateLiteral2 = _interopRequireDefault(require("@babel/runtime/helpers/taggedTemplateLiteral"));
var _graphqlRequest = require("graphql-request");
var _templateObject;
var INITIATE_PAYPAL_PAYMENT_MUTATION = exports.INITIATE_PAYPAL_PAYMENT_MUTATION = (0, _graphqlRequest.gql)(_templateObject || (_templateObject = (0, _taggedTemplateLiteral2.default)(["\n  mutation createPaypalPaymentMutation(\n    $basketReference: String!\n    $createPaymentCriteria: CreatePaymentCriteria!\n  ) {\n    initiatePaypalPayment(\n      basketReference: $basketReference\n      createPaymentCriteria: $createPaymentCriteria\n    ) {\n      status\n      paymentRequiredDetails {\n        paymentRedirect\n        sessionId\n        template\n      }\n    }\n  }\n"])));