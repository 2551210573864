"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.DASHBOARD_CANCEL_RESERVATION = void 0;
var _taggedTemplateLiteral2 = _interopRequireDefault(require("@babel/runtime/helpers/taggedTemplateLiteral"));
var _graphqlRequest = require("graphql-request");
var _templateObject;
var DASHBOARD_CANCEL_RESERVATION = exports.DASHBOARD_CANCEL_RESERVATION = (0, _graphqlRequest.gql)(_templateObject || (_templateObject = (0, _taggedTemplateLiteral2.default)(["\n  mutation cancelReservation($cancellationCriteria: CancellationCriteria!) {\n    cancelReservation(cancellationCriteria: $cancellationCriteria) {\n      basketReference\n    }\n  }\n"])));