"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = ImportantNotificationContainer;
var _utils = require("@whitbread-eos/utils");
var _ImportantNotification = _interopRequireDefault(require("./ImportantNotification.component"));
var _jsxRuntime = require("react/jsx-runtime");
function ImportantNotificationContainer(_ref) {
  var {
    arrival,
    departure
  } = _ref;
  var {
    importantInfo,
    isLoading,
    isError,
    error
  } = (0, _utils.useStaticHotelInformation)();
  if (!arrival || !departure || !importantInfo) return null;
  return (0, _jsxRuntime.jsx)(_ImportantNotification.default, {
    isLoading,
    isError,
    error,
    importantInfo,
    arrival,
    departure
  });
}