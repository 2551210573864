"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.FormInput = void 0;
var _objectWithoutProperties2 = _interopRequireDefault(require("@babel/runtime/helpers/objectWithoutProperties"));
var _server = require("@whitbread-eos/utils/server");
var _image = _interopRequireDefault(require("next/image"));
var React = _interopRequireWildcard(require("react"));
var _index = require("../../Tooltip/index");
var _jsxRuntime = require("react/jsx-runtime");
var _excluded = ["className"];
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && {}.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
var FormInput = exports.FormInput = React.forwardRef((_ref, ref) => {
  var {
      className
    } = _ref,
    props = (0, _objectWithoutProperties2.default)(_ref, _excluded);
  var {
    name = 'defaultName',
    placeholder,
    id,
    disabled = false,
    type,
    value,
    onChange,
    errors,
    errorIcon,
    inputIcon,
    onBlur = () => {
      return;
    },
    onFocus = () => {
      return;
    }
  } = props;
  var error = errors === null || errors === void 0 ? void 0 : errors[name];
  var hasError = !!(error !== null && error !== void 0 && error.type);
  return (0, _jsxRuntime.jsxs)("div", {
    children: [(0, _jsxRuntime.jsxs)("div", {
      className: "relative flex justify-center items-center",
      children: [inputIcon && (0, _jsxRuntime.jsx)(_image.default, {
        className: (0, _server.cn)(inputIconStyle, disabled ? disabledStyle : ''),
        src: inputIcon,
        alt: 'Input Icon',
        width: 24,
        height: 24
      }), (0, _jsxRuntime.jsx)("input", {
        name: name,
        "data-testid": "".concat(id, "-Form-Input"),
        id: id,
        type: type,
        className: (0, _server.cn)(inputStyle, hasError ? '!outline !outline-2 !outline-error !-outline-offset-2' : '', inputIcon ? 'pl-14' : '', className),
        ref: ref,
        value: value,
        disabled: disabled,
        placeholder: placeholder,
        onChange: onChange,
        onBlur: onBlur,
        onFocus: onFocus
      }), (0, _jsxRuntime.jsx)("label", {
        htmlFor: id,
        className: (0, _server.cn)(labelStyle, hasError ? '!text-error' : '', !!value || hasError ? '!block' : ''),
        "data-testid": "".concat(id, "-Label"),
        children: placeholder
      })]
    }), (0, _jsxRuntime.jsx)(_index.ErrorTooltip, {
      icon: errorIcon,
      content: error === null || error === void 0 ? void 0 : error.message,
      open: hasError,
      testId: "".concat(id, "-Error-Tooltip"),
      className: "!flex",
      mobile: true
    })]
  });
});
FormInput.displayName = 'Form Input';
var inputIconStyle = 'absolute left-4 w-6 h-6 text-transparent z-40';
var labelStyle = 'hidden absolute peer-focus:!block text-darkGrey1 peer-focus:text-primaryColor -top-2.5 left-0 ml-3 text-sm px-1 bg-baseWhite';
var inputStyle = 'peer flex h-14 text-darkGrey1 border-lightGrey1 focus:outline-primaryColor focus:outline-2 hover:border-darkGrey1 w-full rounded border bg-transparent p-4 text-base transition-colors placeholder:text-darkGrey2 disabled:cursor-not-allowed disabled:opacity-50 disabled:hover:border-lightGrey1 bg-baseWhite';
var disabledStyle = 'opacity-50 cursor-not-allowed hover:border-lightGrey1';