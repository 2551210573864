"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = NewPassword;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _react = require("@chakra-ui/react");
var _api = require("@whitbread-eos/api");
var _atoms = require("@whitbread-eos/atoms");
var _utils = require("@whitbread-eos/utils");
var _nextI18next = require("next-i18next");
var _config = _interopRequireDefault(require("next/config"));
var _newPasswordFormConfig = require("./newPasswordFormConfig");
var _jsxRuntime = require("react/jsx-runtime");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function NewPassword(_ref) {
  var _labels$resetPassword, _labels$resetPassword2, _labels$resetPassword3;
  var {
    defaultValues,
    toggleLoginModal,
    token,
    isBusinessBooker,
    labels
  } = _ref;
  var baseDataTestId = 'NewPassword';
  var {
    t
  } = (0, _nextI18next.useTranslation)();
  var {
    publicRuntimeConfig = {}
  } = (0, _config.default)() || {};
  var {
    mutation,
    data,
    isError
  } = (0, _utils.useRestMutationRequest)("".concat(publicRuntimeConfig.NEXT_PUBLIC_REST_API, "/auth/hotels/forgot-password?business=").concat(isBusinessBooker), 'PUT', {
    'password-token': token,
    bookingchannel: _api.BOOKING_SUBCHANNEL.WEB
  });
  var onSubmit = data => {
    mutation.mutate({
      customerId: data.email,
      newPassword: data.password
    });
  };
  return (0, _jsxRuntime.jsx)(_jsxRuntime.Fragment, {
    children: (data === null || data === void 0 ? void 0 : data.passwordChanged) === true ? (0, _jsxRuntime.jsxs)(_react.Flex, {
      direction: "column",
      "data-testid": (0, _utils.formatDataTestId)(baseDataTestId, 'Container-Successful'),
      children: [(0, _jsxRuntime.jsx)(_atoms.Notification, {
        description: labels === null || labels === void 0 || (_labels$resetPassword = labels.resetPassword) === null || _labels$resetPassword === void 0 ? void 0 : _labels$resetPassword.successMessage,
        variant: "info",
        status: "info",
        svg: (0, _jsxRuntime.jsx)(_atoms.Info, {}),
        prefixDataTestId: (0, _utils.formatDataTestId)(baseDataTestId, 'Successful'),
        maxW: "full"
      }), (0, _jsxRuntime.jsx)(_atoms.Button, {
        size: "md",
        variant: "secondary",
        mt: "lg",
        onClick: toggleLoginModal,
        children: labels === null || labels === void 0 || (_labels$resetPassword2 = labels.resetPassword) === null || _labels$resetPassword2 === void 0 ? void 0 : _labels$resetPassword2.logInButton
      })]
    }) : (0, _jsxRuntime.jsxs)(_react.Flex, {
      direction: "column",
      "data-testid": (0, _utils.formatDataTestId)(baseDataTestId, 'Container'),
      children: [(0, _jsxRuntime.jsx)(_react.Text, _objectSpread(_objectSpread({}, titleStyle), {}, {
        children: labels === null || labels === void 0 || (_labels$resetPassword3 = labels.resetPassword) === null || _labels$resetPassword3 === void 0 ? void 0 : _labels$resetPassword3.resetPasswordTitle
      })), (0, _jsxRuntime.jsx)(_react.Flex, _objectSpread(_objectSpread({}, newPasswordStyles), {}, {
        children: (0, _jsxRuntime.jsx)(_atoms.Form, _objectSpread({}, (0, _newPasswordFormConfig.newPasswordFormConfig)({
          defaultValues,
          onSubmit,
          baseDataTestId,
          t,
          labels,
          isBusinessBooker
        })))
      })), isError && (0, _jsxRuntime.jsx)(_react.Flex, _objectSpread(_objectSpread({}, errorContainerStyles), {}, {
        children: (0, _jsxRuntime.jsx)(_atoms.Notification, {
          description: t('booking.login.changePassword.error'),
          variant: "alert",
          status: "warning",
          svg: (0, _jsxRuntime.jsx)(_atoms.Alert, {}),
          maxW: "full"
        })
      }))]
    })
  });
}
var newPasswordStyles = {
  marginTop: '2xl',
  width: {
    mobile: 'auto',
    xs: 'auto',
    sm: '25rem',
    md: '26rem'
  }
};
var titleStyle = {
  color: 'darkGrey3',
  fontSize: '3xxl',
  fontWeight: 'semibold',
  lineHeight: '4'
};
var errorContainerStyles = {
  mt: '2xl'
};