"use strict";
'use client';

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Toaster = Toaster;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _objectWithoutProperties2 = _interopRequireDefault(require("@babel/runtime/helpers/objectWithoutProperties"));
var _image = _interopRequireDefault(require("next/image"));
var _navigation = require("next/navigation");
var _toast = require("./toast");
var _useToast = require("./use-toast");
var _jsxRuntime = require("react/jsx-runtime");
var _excluded = ["id", "icon", "content"];
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function Toaster() {
  var {
    toasts
  } = (0, _useToast.useToast)();
  var pathname = (0, _navigation.usePathname)();
  return (0, _jsxRuntime.jsxs)(_toast.ToastProvider, {
    children: [toasts.map(function (_ref) {
      var {
          id,
          icon,
          content
        } = _ref,
        props = (0, _objectWithoutProperties2.default)(_ref, _excluded);
      return (0, _jsxRuntime.jsxs)(_toast.Toast, _objectSpread(_objectSpread({}, props), {}, {
        "data-testid": "Toast",
        children: [(0, _jsxRuntime.jsxs)("div", {
          className: toastStyle,
          children: [icon && (0, _jsxRuntime.jsx)(_image.default, {
            src: icon,
            alt: "toast-icon",
            width: 16,
            height: 16,
            className: "mt-0.5 mr-2"
          }), content]
        }), (0, _jsxRuntime.jsx)(_toast.ToastClose, {})]
      }), id);
    }), (0, _jsxRuntime.jsx)(_toast.ToastViewport, {
      className: pathname.includes('manage') ? viewportManageStyle : ''
    })]
  });
}
var toastStyle = 'flex items-start';
var viewportManageStyle = 'mobile:bottom-[129px]';