"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.UserRoleBasedAccess = exports.CCUI_ROLES = void 0;
var CCUI_ROLES = exports.CCUI_ROLES = 'https://ccui.opera.whitbread.digital/role';
var UserRoleBasedAccess = exports.UserRoleBasedAccess = {
  CC_ROLE03: {
    ROLE: 'CC_Role03',
    AGENT: true,
    MANAGER: true
  },
  CC_ROLE05: {
    ROLE: 'CC_Role05',
    AGENT: false,
    MANAGER: true
  },
  CC_ROLE12: {
    ROLE: 'CC_Role12',
    AGENT: true,
    MANAGER: true
  }
};
Object.freeze(UserRoleBasedAccess);