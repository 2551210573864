"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.createCustomLocale = void 0;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
var rearrangeDays = days => {
  var lastDay = days[days.length - 1];
  var remainingDays = days.slice(0, days.length - 1);
  return [lastDay, ...remainingDays];
};
var createCustomLocale = exports.createCustomLocale = function createCustomLocale(baseLocale, months, weekdaysShort) {
  var isShortMonth = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : false;
  var oldLocalize = baseLocale.localize;
  var rearrangeShortDays = rearrangeDays(weekdaysShort);
  var newLocalize = _objectSpread(_objectSpread({}, oldLocalize), {}, {
    month: value => {
      var monthName = months[value];
      return isShortMonth ? monthName === null || monthName === void 0 ? void 0 : monthName.slice(0, 3) : monthName;
    },
    day: _day => {
      return rearrangeShortDays[_day];
    }
  });
  return _objectSpread(_objectSpread({}, baseLocale), {}, {
    localize: newLocalize
  });
};