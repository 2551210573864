"use strict";
'use client';

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ToastViewport = exports.ToastProvider = exports.ToastClose = exports.Toast = void 0;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _objectWithoutProperties2 = _interopRequireDefault(require("@babel/runtime/helpers/objectWithoutProperties"));
var ToastPrimitives = _interopRequireWildcard(require("@radix-ui/react-toast"));
var _server = require("@whitbread-eos/utils/server");
var _classVarianceAuthority = require("class-variance-authority");
var _lucideReact = require("lucide-react");
var React = _interopRequireWildcard(require("react"));
var _jsxRuntime = require("react/jsx-runtime");
var _excluded = ["className"],
  _excluded2 = ["className", "variant"],
  _excluded3 = ["className"];
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && {}.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
var ToastProvider = exports.ToastProvider = ToastPrimitives.Provider;
var ToastViewport = exports.ToastViewport = React.forwardRef((_ref, ref) => {
  var {
      className
    } = _ref,
    props = (0, _objectWithoutProperties2.default)(_ref, _excluded);
  return (0, _jsxRuntime.jsx)(ToastPrimitives.Viewport, _objectSpread({
    ref: ref,
    className: (0, _server.cn)('fixed z-[100] flex max-h-screen mobile:w-full flex-col-reverse p-8 mobile:p-2', 'bottom-0 mobile:bottom-[73px] right-0 mobile:flex-col max-w-[420px]', className)
  }, props));
});
ToastViewport.displayName = ToastPrimitives.Viewport.displayName;
var toastVariants = (0, _classVarianceAuthority.cva)((0, _server.cn)('group pointer-events-auto relative flex w-full items-center justify-between space-x-4', 'overflow-hidden text-sm font-normal rounded-[3px] border p-4 pr-12 shadow-md transition-all', 'data-[swipe=cancel]:translate-x-0 data-[swipe=end]:translate-x-[var(--radix-toast-swipe-end-x)]', 'data-[swipe=move]:translate-x-[var(--radix-toast-swipe-move-x)] data-[swipe=move]:transition-none', 'data-[state=open]:animate-in data-[state=closed]:animate-out data-[swipe=end]:animate-out', 'data-[state=closed]:fade-out-80 data-[state=closed]:slide-out-to-right-full', 'data-[state=open]:slide-in-from-top-full data-[state=open]:mobile:slide-in-from-bottom-full'), {
  variants: {
    variant: {
      default: 'border-successTint2 bg-successTint',
      error: 'border-errorTint bg-tooltipError',
      warning: 'border-notificationAlertBorder bg-notificationAlertBg',
      info: 'border-tooltipInfoTint bg-tooltipInfo'
    }
  },
  defaultVariants: {
    variant: 'default'
  }
});
var Toast = exports.Toast = React.forwardRef((_ref2, ref) => {
  var {
      className,
      variant
    } = _ref2,
    props = (0, _objectWithoutProperties2.default)(_ref2, _excluded2);
  return (0, _jsxRuntime.jsx)(ToastPrimitives.Root, _objectSpread({
    ref: ref,
    className: (0, _server.cn)(toastVariants({
      variant
    }), className)
  }, props));
});
Toast.displayName = ToastPrimitives.Root.displayName;
var ToastClose = exports.ToastClose = React.forwardRef((_ref3, ref) => {
  var {
      className
    } = _ref3,
    props = (0, _objectWithoutProperties2.default)(_ref3, _excluded3);
  return (0, _jsxRuntime.jsx)(ToastPrimitives.Close, _objectSpread(_objectSpread({
    ref: ref,
    className: (0, _server.cn)('absolute right-4 top-4', className),
    "toast-close": ""
  }, props), {}, {
    children: (0, _jsxRuntime.jsx)(_lucideReact.X, {
      className: "stroke-darkGrey1 w-5 h-5"
    })
  }));
});
ToastClose.displayName = ToastPrimitives.Close.displayName;