"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.BookingSummaryRoomDetails = void 0;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _react = require("@chakra-ui/react");
var _api = require("@whitbread-eos/api");
var _utils = require("@whitbread-eos/utils");
var _nextI18next = require("next-i18next");
var _jsxRuntime = require("react/jsx-runtime");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
var BookingSummaryRoomDetails = props => {
  var _reservationGuestList, _reservationGuestList2, _reservationGuestList3, _roomStay$roomExtraIn, _roomPackages$selecte, _roomPackages$selecte2, _roomPackages$selecte3;
  var {
    language,
    baseDataTestId,
    bookingSummaryLabels,
    roomsAndGuestsLabels,
    reservation,
    roomNumber,
    currency,
    roomPackages,
    noNights,
    extrasItemsPrices
  } = props;
  var {
    roomStay,
    reservationGuestList
  } = reservation;
  var {
    t
  } = (0, _nextI18next.useTranslation)();
  var checkPriceForExtras = extrasId => {
    if (extrasId === _api.ExtrasId.EARLY_CHECK_IN) {
      return extrasItemsPrices === null || extrasItemsPrices === void 0 ? void 0 : extrasItemsPrices.eciPrice;
    }
    if (extrasId === _api.ExtrasId.LATE_CHECK_OUT) {
      return extrasItemsPrices === null || extrasItemsPrices === void 0 ? void 0 : extrasItemsPrices.lcoPrice;
    }
    if (extrasId === _api.ExtrasId.ULTIMATE_WIFI) {
      return extrasItemsPrices === null || extrasItemsPrices === void 0 ? void 0 : extrasItemsPrices.wifiPrice;
    }
    return '';
  };
  var showExtrasAndMeals = (roomPackages === null || roomPackages === void 0 ? void 0 : roomPackages.selectedMeals) && roomPackages.selectedMeals.adultsMeals.length > 0 || (roomPackages === null || roomPackages === void 0 ? void 0 : roomPackages.selectedExtrasList) && (roomPackages === null || roomPackages === void 0 ? void 0 : roomPackages.selectedExtrasList.packagesSelection.length) > 0;
  var getNumberOfChildren = () => {
    if ((roomStay === null || roomStay === void 0 ? void 0 : roomStay.childrenNumber) === 0) {
      return '';
    } else {
      return ", ".concat(roomStay.childrenNumber, " ").concat(roomStay.childrenNumber === 1 ? roomsAndGuestsLabels.roomModalLabels.roomAvailabilityLabels.child : roomsAndGuestsLabels.roomModalLabels.roomAvailabilityLabels.children);
    }
  };
  return (0, _jsxRuntime.jsxs)(_react.Flex, _objectSpread(_objectSpread({}, detailsWrapperStyle), {}, {
    children: [(0, _jsxRuntime.jsxs)(_react.Text, _objectSpread(_objectSpread({
      "data-testid": (0, _utils.formatDataTestId)(baseDataTestId, 'room-number')
    }, subtitleTextStyle), {}, {
      children: [roomsAndGuestsLabels.roomLabel, " ", roomNumber + 1]
    })), (0, _jsxRuntime.jsxs)(_react.Text, _objectSpread(_objectSpread({
      className: "sessioncamhidetext assist-no-show",
      "data-testid": (0, _utils.formatDataTestId)(baseDataTestId, 'room-lead-guest')
    }, infoTextStyle), {}, {
      children: [(0, _utils.upperOnlyFirst)(((_reservationGuestList = reservationGuestList[0]) === null || _reservationGuestList === void 0 ? void 0 : _reservationGuestList.nameTitle) || ''), ' ', (_reservationGuestList2 = reservationGuestList[0]) === null || _reservationGuestList2 === void 0 ? void 0 : _reservationGuestList2.givenName, " ", (_reservationGuestList3 = reservationGuestList[0]) === null || _reservationGuestList3 === void 0 ? void 0 : _reservationGuestList3.surName]
    })), (0, _jsxRuntime.jsxs)(_react.Text, _objectSpread(_objectSpread({
      "data-testid": (0, _utils.formatDataTestId)(baseDataTestId, 'room-occupancy')
    }, infoTextStyle), {}, {
      children: [roomStay === null || roomStay === void 0 ? void 0 : roomStay.adultsNumber, ' ', (roomStay === null || roomStay === void 0 ? void 0 : roomStay.adultsNumber) === 1 ? roomsAndGuestsLabels.roomModalLabels.roomAvailabilityLabels.adult : roomsAndGuestsLabels.roomModalLabels.roomAvailabilityLabels.adults, getNumberOfChildren()]
    })), (0, _jsxRuntime.jsxs)(_react.Flex, {
      children: [(0, _jsxRuntime.jsx)(_react.Text, {
        "data-testid": (0, _utils.formatDataTestId)(baseDataTestId, 'room-name'),
        maxW: "12.125rem",
        children: roomStay === null || roomStay === void 0 || (_roomStay$roomExtraIn = roomStay.roomExtraInfo) === null || _roomStay$roomExtraIn === void 0 ? void 0 : _roomStay$roomExtraIn.roomName
      }), (0, _jsxRuntime.jsx)(_react.Spacer, {}), (0, _jsxRuntime.jsx)(_react.Text, _objectSpread(_objectSpread({
        "data-testid": (0, _utils.formatDataTestId)(baseDataTestId, 'room-price')
      }, priceTextStyles), {}, {
        children: (0, _utils.formatPriceWithDecimal)(language, (0, _utils.formatCurrency)(currency), roomStay.roomPrice, true)
      }))]
    }), showExtrasAndMeals && (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
      children: [(0, _jsxRuntime.jsxs)(_react.Text, _objectSpread(_objectSpread({
        "data-testid": (0, _utils.formatDataTestId)(baseDataTestId, "".concat(roomNumber, "-meals-and-extras-label"))
      }, extrasTextStyle), {}, {
        children: [bookingSummaryLabels.mealsLabel, " & ", bookingSummaryLabels.extrasLabel]
      })), (0, _jsxRuntime.jsxs)(_react.Flex, {
        mt: "sm",
        flexDirection: "column",
        children: [roomPackages === null || roomPackages === void 0 || (_roomPackages$selecte = roomPackages.selectedMeals) === null || _roomPackages$selecte === void 0 || (_roomPackages$selecte = _roomPackages$selecte.adultsMeals) === null || _roomPackages$selecte === void 0 ? void 0 : _roomPackages$selecte.map(adultMeal => (0, _jsxRuntime.jsxs)(_react.Flex, {
          justifyContent: "space-between",
          children: [(0, _jsxRuntime.jsxs)(_react.Text, {
            "data-testid": (0, _utils.formatDataTestId)(baseDataTestId, "room-".concat(roomNumber, "-meals-").concat(adultMeal.id)),
            children: [adultMeal.noSelections, " x ", adultMeal.title]
          }), (0, _jsxRuntime.jsx)(_react.Text, _objectSpread(_objectSpread({
            "data-testid": (0, _utils.formatDataTestId)(baseDataTestId, "room-".concat(roomNumber, "-meal-price-").concat(adultMeal.id))
          }, priceTextStyles), {}, {
            children: adultMeal.price && (0, _utils.formatPriceWithDecimal)(language, (0, _utils.formatCurrency)(currency), adultMeal.price * adultMeal.noSelections * noNights, true)
          }))]
        }, adultMeal.id)), roomPackages === null || roomPackages === void 0 || (_roomPackages$selecte2 = roomPackages.selectedMeals) === null || _roomPackages$selecte2 === void 0 || (_roomPackages$selecte2 = _roomPackages$selecte2.childrenMeals) === null || _roomPackages$selecte2 === void 0 ? void 0 : _roomPackages$selecte2.map(childrenMeal => {
          var _childrenMeal$price;
          var mealPrice = (_childrenMeal$price = childrenMeal.price) !== null && _childrenMeal$price !== void 0 ? _childrenMeal$price : 0;
          return (0, _jsxRuntime.jsxs)(_react.Flex, {
            justifyContent: "space-between",
            children: [(0, _jsxRuntime.jsxs)(_react.Text, {
              "data-testid": (0, _utils.formatDataTestId)(baseDataTestId, "room-".concat(roomNumber, "-meals-").concat(childrenMeal.id)),
              children: [childrenMeal.noSelections, " x ", childrenMeal.title]
            }), (0, _jsxRuntime.jsx)(_react.Text, _objectSpread(_objectSpread({
              "data-testid": (0, _utils.formatDataTestId)(baseDataTestId, "room-".concat(roomNumber, "-meal-price-").concat(childrenMeal.id))
            }, priceTextStyles), {}, {
              children: (0, _utils.formatPriceWithDecimal)(language, (0, _utils.formatCurrency)(currency), mealPrice * childrenMeal.noSelections * noNights, true)
            }))]
          }, childrenMeal.id);
        }), roomPackages === null || roomPackages === void 0 || (_roomPackages$selecte3 = roomPackages.selectedExtrasList) === null || _roomPackages$selecte3 === void 0 || (_roomPackages$selecte3 = _roomPackages$selecte3.packagesSelection) === null || _roomPackages$selecte3 === void 0 ? void 0 : _roomPackages$selecte3.map(extrasPackage => {
          return (0, _jsxRuntime.jsxs)(_react.Flex, {
            justifyContent: "space-between",
            children: [(0, _jsxRuntime.jsx)(_react.Text, _objectSpread(_objectSpread({
              "data-testid": (0, _utils.formatDataTestId)(baseDataTestId, "room-".concat(roomNumber, "-extras-").concat(extrasPackage.id))
            }, extrasPackage.id !== _api.ExtrasId.ULTIMATE_WIFI ? eciLcoTextStyle : {}), {}, {
              children: (0, _utils.extrasNamingCheck)(t, extrasPackage === null || extrasPackage === void 0 ? void 0 : extrasPackage.id)
            })), (0, _jsxRuntime.jsx)(_react.Text, _objectSpread(_objectSpread(_objectSpread({
              "data-testid": (0, _utils.formatDataTestId)(baseDataTestId, "room-".concat(roomNumber, "-extras-price-").concat(extrasPackage.id))
            }, priceTextStyles), extrasPackage.id !== _api.ExtrasId.ULTIMATE_WIFI ? eciLcoTextStyle : {}), {}, {
              children: (0, _utils.formatPriceWithDecimal)(language, (0, _utils.formatCurrency)(currency), Number(checkPriceForExtras(extrasPackage === null || extrasPackage === void 0 ? void 0 : extrasPackage.id)), true)
            }))]
          }, "room-".concat(roomNumber, "-extras-").concat(extrasPackage.id));
        })]
      })]
    })]
  }));
};
exports.BookingSummaryRoomDetails = BookingSummaryRoomDetails;
var detailsWrapperStyle = {
  direction: 'column',
  pb: 'lg'
};
var subtitleTextStyle = {
  pt: 'lg',
  pb: 'sm',
  fontSize: 'xl',
  lineHeight: '3',
  fontWeight: 'semibold'
};
var extrasTextStyle = {
  fontWeight: 'semibold',
  pt: {
    mobile: 'lg',
    lg: 'md'
  }
};
var infoTextStyle = {
  lineHeight: '3',
  fontSize: 'md',
  color: 'darkGrey1',
  as: 'h6',
  fontWeight: 'normal',
  mb: 'sm'
};
var eciLcoTextStyle = {
  textDecoration: 'line-through',
  color: 'lightGrey1'
};
var priceTextStyles = {
  w: '5rem',
  textAlign: 'right'
};