"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = CreateAccount;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _react = require("@chakra-ui/react");
var _utils = require("@whitbread-eos/utils");
var _nextI18next = require("next-i18next");
var _router = require("next/router");
var _react2 = _interopRequireDefault(require("react"));
var _Button = _interopRequireDefault(require("../Button"));
var _jsxRuntime = require("react/jsx-runtime");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function CreateAccount(_ref) {
  var {
    basketReference
  } = _ref;
  var {
    t
  } = (0, _nextI18next.useTranslation)(['common']);
  var router = (0, _router.useRouter)();
  var {
    language,
    country
  } = (0, _utils.useCustomLocale)();
  var handleClick = () => {
    var encodedValue = (0, _utils.encodeToBase64)(basketReference);
    (0, _utils.setCookie)(_utils.REGISTER_BASKET_REF_COOKIE, encodedValue, undefined);
    router.push("/".concat(country, "/").concat(language, "/account/register?reservationId=").concat(basketReference));
  };
  return (0, _jsxRuntime.jsxs)(_react.Box, _objectSpread(_objectSpread({}, wrapperStyle), {}, {
    children: [(0, _jsxRuntime.jsx)(_react.Text, _objectSpread(_objectSpread({}, headingStyles), {}, {
      "data-testid": "createAccount-heading",
      children: t('booking.confirmation.createAccount.heading')
    })), (0, _jsxRuntime.jsx)(_react.Text, _objectSpread(_objectSpread({}, textStyles), {}, {
      "data-testid": "createAccount-text",
      children: t('booking.confirmation.createAccount.text')
    })), (0, _jsxRuntime.jsx)(_Button.default, _objectSpread(_objectSpread({}, buttonStyles), {}, {
      "data-testid": "createAccount-button",
      variant: "secondary",
      onClick: handleClick,
      children: (0, _jsxRuntime.jsx)(_react.Text, {
        children: t('booking.confirmation.createAccount.button')
      })
    }))]
  }));
}
var wrapperStyle = {
  w: 'full',
  border: '1px solid var(--chakra-colors-lightGrey1)',
  borderRadius: '3px',
  p: 'lg',
  mb: '2.5rem'
};
var headingStyles = {
  fontWeight: 'semibold',
  fontSize: '2xl',
  lineHeight: '4',
  mb: 'lg'
};
var textStyles = {
  mb: 'lg'
};
var buttonStyles = {
  width: '100%',
  maxWidth: '288px'
};