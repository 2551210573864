"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
var _auth = require("./auth");
Object.keys(_auth).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _auth[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _auth[key];
    }
  });
});
var _brands = require("./brands");
Object.keys(_brands).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _brands[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _brands[key];
    }
  });
});
var _hotelSearch = require("./hotelSearch");
Object.keys(_hotelSearch).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _hotelSearch[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _hotelSearch[key];
    }
  });
});
var _common = require("./common");
Object.keys(_common).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _common[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _common[key];
    }
  });
});
var _analyticsPageMapper = require("./analyticsPageMapper");
Object.keys(_analyticsPageMapper).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _analyticsPageMapper[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _analyticsPageMapper[key];
    }
  });
});
var _site = require("./site");
Object.keys(_site).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _site[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _site[key];
    }
  });
});
var _payment = require("./payment");
Object.keys(_payment).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _payment[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _payment[key];
    }
  });
});
var _userRoles = require("./userRoles");
Object.keys(_userRoles).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _userRoles[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _userRoles[key];
    }
  });
});
var _roomTypes = require("./roomTypes");
Object.keys(_roomTypes).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _roomTypes[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _roomTypes[key];
    }
  });
});
var _basket = require("./basket");
Object.keys(_basket).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _basket[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _basket[key];
    }
  });
});
var _bookingHistory = require("./bookingHistory");
Object.keys(_bookingHistory).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _bookingHistory[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _bookingHistory[key];
    }
  });
});
var _searchResults = require("./searchResults");
Object.keys(_searchResults).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _searchResults[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _searchResults[key];
    }
  });
});
var _amend = require("./amend");
Object.keys(_amend).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _amend[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _amend[key];
    }
  });
});
var _featureSwitch = require("./featureSwitch");
Object.keys(_featureSwitch).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _featureSwitch[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _featureSwitch[key];
    }
  });
});
var _featureToggle = require("./featureToggle");
Object.keys(_featureToggle).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _featureToggle[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _featureToggle[key];
    }
  });
});
var _marketingPreferences = require("./marketingPreferences");
Object.keys(_marketingPreferences).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _marketingPreferences[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _marketingPreferences[key];
    }
  });
});
var _cardTypes = require("./cardTypes");
Object.keys(_cardTypes).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _cardTypes[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _cardTypes[key];
    }
  });
});
var _findBooking = require("./findBooking");
Object.keys(_findBooking).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _findBooking[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _findBooking[key];
    }
  });
});
var _guestDetails = require("./guestDetails");
Object.keys(_guestDetails).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _guestDetails[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _guestDetails[key];
    }
  });
});
var _innBusiness = require("./innBusiness");
Object.keys(_innBusiness).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _innBusiness[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _innBusiness[key];
    }
  });
});
var _cookies = require("./cookies");
Object.keys(_cookies).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _cookies[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _cookies[key];
    }
  });
});