"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.PiCardType = exports.PiCardSubType = void 0;
var PiCardType = exports.PiCardType = function (PiCardType) {
  PiCardType["SAVED_CARD"] = "SAVED_CARD";
  PiCardType["NEW_CARD"] = "NEW_CARD";
  PiCardType["NEW_PIBA"] = "NEW_PIBA";
  PiCardType["ACCOUNT_COMPANY"] = "ACCOUNT_COMPANY";
  PiCardType["RESERVE_WITHOUT_CARD"] = "RESERVE_WITHOUT_CARD";
  PiCardType["AMEND_SAVED_CARD"] = "AMEND_SAVED_CARD";
  PiCardType["PAYPAL_PAYMENT"] = "PAYPAL";
  PiCardType["APGP"] = "APGP";
  return PiCardType;
}({});
var PiCardSubType = exports.PiCardSubType = function (PiCardSubType) {
  PiCardSubType["PIBA_UK"] = "PIBAGB";
  PiCardSubType["PIBA_EU"] = "PIBADE";
  return PiCardSubType;
}({});