"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.AMEND_EDIT_ROOM = void 0;
var _taggedTemplateLiteral2 = _interopRequireDefault(require("@babel/runtime/helpers/taggedTemplateLiteral"));
var _graphqlRequest = require("graphql-request");
var _templateObject;
var AMEND_EDIT_ROOM = exports.AMEND_EDIT_ROOM = (0, _graphqlRequest.gql)(_templateObject || (_templateObject = (0, _taggedTemplateLiteral2.default)(["\n  mutation AmendEditRoom(\n    $reservationId: String!\n    $tempBookingRef: String!\n    $roomType: String!\n    $channel: Channel!\n    $subchannel: String!\n    $language: String\n    $adultsNumber: Int!\n    $childrenNumber: Int!\n    $cotRequired: Boolean\n    $title: String!\n    $firstName: String!\n    $lastName: String!\n    $token: String!\n    $emailAddress: String\n    $addressLine1: String\n    $addressLine2: String\n    $addressLine3: String\n    $cityName: String\n    $postalCode: String\n    $countryCode: String\n  ) {\n    amendEditRoom(\n      editRoomCriteria: {\n        reservationId: $reservationId\n        tempBookingRef: $tempBookingRef\n        roomOccupancy: {\n          adultsNumber: $adultsNumber\n          childrenNumber: $childrenNumber\n          cotRequired: $cotRequired\n        }\n        leadGuest: {\n          title: $title\n          firstName: $firstName\n          lastName: $lastName\n          emailAddress: $emailAddress\n          address: {\n            addressLine1: $addressLine1\n            addressLine2: $addressLine2\n            addressLine3: $addressLine3\n            cityName: $cityName\n            postalCode: $postalCode\n            countryCode: $countryCode\n            addressType: \"HOME\"\n          }\n        }\n        roomType: $roomType\n        bookingChannel: { channel: $channel, subchannel: $subchannel, language: $language }\n        token: $token\n      }\n    ) {\n      tempBookingRef\n    }\n  }\n"])));