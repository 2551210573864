"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.RatePlanCodes = void 0;
var RatePlanCodes = exports.RatePlanCodes = function (RatePlanCodes) {
  RatePlanCodes["BUSIFLEX"] = "BUSIFLEX";
  RatePlanCodes["FLEXRATE"] = "FLEXRATE";
  return RatePlanCodes;
}({});