"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.EmailConfirmation = void 0;
var EmailConfirmation = exports.EmailConfirmation = function (EmailConfirmation) {
  EmailConfirmation["SEND_EMAIL"] = "SEND_EMAIL";
  EmailConfirmation["NO_SEND_EMAIL"] = "NO_SEND_EMAIL";
  return EmailConfirmation;
}({});