"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.UPDATE_ANCILLARIES_RATE_CODE = void 0;
var _taggedTemplateLiteral2 = _interopRequireDefault(require("@babel/runtime/helpers/taggedTemplateLiteral"));
var _graphqlRequest = require("graphql-request");
var _templateObject;
var UPDATE_ANCILLARIES_RATE_CODE = exports.UPDATE_ANCILLARIES_RATE_CODE = (0, _graphqlRequest.gql)(_templateObject || (_templateObject = (0, _taggedTemplateLiteral2.default)(["\n  mutation updateRateCode(\n    $basketReferenceId: String!\n    $rateCode: String!\n    $hotelId: String!\n    $startDate: String!\n    $endDate: String!\n    $roomType: [String!]!\n    $currency: String!\n    $adultsNumber: [Int]!\n    $childrenNumber: [Int]!\n  ) {\n    updateRateCode(\n      rateCodeCriteria: {\n        basketReferenceId: $basketReferenceId\n        rateCode: $rateCode\n        hotelId: $hotelId\n        startDate: $startDate\n        endDate: $endDate\n        roomType: $roomType\n        currency: $currency\n        adultsNumber: $adultsNumber\n        childrenNumber: $childrenNumber\n      }\n    )\n  }\n"])));