"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = HotelOpeningInformation;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _react = require("@chakra-ui/react");
var _utils = require("@whitbread-eos/utils");
var _react2 = require("react");
var _jsxRuntime = require("react/jsx-runtime");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function HotelOpeningInformation(_ref) {
  var {
    hotelOpeningDate,
    labels: {
      openingOn
    },
    testId
  } = _ref;
  var {
    language: currentLang
  } = (0, _utils.useCustomLocale)();
  var hotelOpeningLabel = (0, _react2.useMemo)(() => {
    return hotelOpeningDate ? (0, _utils.formatDate)(hotelOpeningDate, 'd LLLL y', currentLang) : null;
  }, [hotelOpeningDate, currentLang]);
  return (0, _jsxRuntime.jsx)(_jsxRuntime.Fragment, {
    children: hotelOpeningDate && (0, _jsxRuntime.jsxs)(_react.Box, _objectSpread(_objectSpread({}, boxStyles), {}, {
      "data-testid": testId,
      children: [(0, _jsxRuntime.jsx)(_react.Text, {
        children: openingOn
      }), (0, _jsxRuntime.jsx)(_react.Text, {
        children: hotelOpeningLabel
      })]
    }))
  });
}
var boxStyles = {
  color: 'info',
  fontWeight: 'semibold',
  fontSize: 'sm',
  margin: '0 0 auto 0',
  paddingBottom: {
    sm: 'md'
  }
};