"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = DropdownCustomContent;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _react = require("@chakra-ui/react");
var _utils = require("@whitbread-eos/utils");
var _react2 = require("react");
var _jsxRuntime = require("react/jsx-runtime");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function DropdownCustomContent(_ref) {
  var {
    isOpen,
    onChange,
    setSelectedValue,
    setIsOpen,
    options,
    baseDataTestId,
    dataTestId
  } = _ref;
  if (isOpen) {
    var _options$map;
    return (0, _jsxRuntime.jsx)(_react.Box, _objectSpread(_objectSpread({}, menuListStyles), {}, {
      "data-testid": (0, _utils.formatDataTestId)(baseDataTestId, "".concat(dataTestId, "-entireList")),
      children: options === null || options === void 0 || (_options$map = options.map) === null || _options$map === void 0 ? void 0 : _options$map.call(options, (option, index) => (0, _react2.createElement)(_react.Box, _objectSpread(_objectSpread({}, menuItemStyles), {}, {
        "data-testid": (0, _utils.formatDataTestId)(baseDataTestId, "".concat(dataTestId, "-").concat(index)),
        key: option.id,
        role: "menuitem",
        _hover: {
          background: 'var(--chakra-colors-lightGrey5)'
        },
        _focus: {
          background: 'var(--chakra-colors-lightGrey5)'
        },
        onClick: () => {
          if (onChange) {
            onChange(option);
          }
          setSelectedValue(option);
          setIsOpen(false);
        }
      }), renderDropdownOption(option)))
    }));
  }
  return null;
  function renderDropdownOption(option) {
    return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
      children: [(option === null || option === void 0 ? void 0 : option.icon) && (0, _jsxRuntime.jsx)(_react.Box, {
        pr: "md",
        children: option.icon
      }), option === null || option === void 0 ? void 0 : option.label]
    });
  }
}
var menuItemStyles = {
  alignItems: 'center',
  cursor: 'pointer',
  display: 'flex',
  textAlign: 'start',
  outline: 'transparent solid 2px',
  outlineOffset: '2px',
  paddingInline: '0.8rem',
  height: 'var(--chakra-space-2xl)',
  padding: '12px var(--chakra-space-md)',
  fontSize: 'var(--chakra-fontSizes-sm)',
  lineHeight: 'var(--chakra-lineHeights-1)'
};
var menuListStyles = {
  outline: 'transparent solid 2px',
  outlineOffset: '2px',
  background: '#ffffff',
  boxShadow: '0 2px 12px var(--chakra-colors-lightGrey2)',
  minWidth: 'var(--chakra-space-full)',
  paddingTop: 'var(--chakra-space-2)',
  paddingBottom: 'var(--chakra-space-2)',
  zIndex: 1000,
  borderWidth: '2px',
  overflowY: 'scroll',
  position: 'absolute',
  top: '0px',
  paddingInline: '0px',
  width: 'var(--chakra-space-full)',
  borderColor: 'lightGrey3',
  maxHeight: '200px',
  transformOrigin: 'top left',
  opacity: 1,
  visibility: 'visible',
  transform: 'translate(0px, 57px)'
};