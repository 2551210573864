"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "WorldlineLink", {
  enumerable: true,
  get: function get() {
    return _worldlineLink.WorldlineLink;
  }
});
var _worldlineLink = require("./worldline-link");