"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.FIND_BOOKING_SOURCE_PMS = exports.FIND_BOOKING_COOKIE_NAME_KEY = void 0;
var FIND_BOOKING_SOURCE_PMS = exports.FIND_BOOKING_SOURCE_PMS = {
  OPERA: 'Opera',
  BART: 'Bart'
};
var FIND_BOOKING_COOKIE_NAME_KEY = exports.FIND_BOOKING_COOKIE_NAME_KEY = 'findBookingCookieName';