"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var React = _interopRequireWildcard(require("react"));
var _jsxRuntime = require("react/jsx-runtime");
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && {}.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
var SvgBicycle = props => (0, _jsxRuntime.jsx)("svg", _objectSpread(_objectSpread({
  width: 24,
  height: 20,
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg"
}, props), {}, {
  children: (0, _jsxRuntime.jsx)("path", {
    fillRule: "evenodd",
    clipRule: "evenodd",
    d: "M4.5 11a4.5 4.5 0 1 1 0 9 4.5 4.5 0 0 1 0-9Zm15 0a4.5 4.5 0 1 1 0 9 4.5 4.5 0 0 1 0-9Zm-15 1a3.5 3.5 0 1 0 0 7 3.5 3.5 0 0 0 0-7Zm15 0a3.5 3.5 0 1 0 0 7 3.5 3.5 0 0 0 0-7Zm-6.135-7.419.085.112.074.12 1.627 2.93a.5.5 0 0 0 .348.249l.09.008H19v1h-3.412a1.5 1.5 0 0 1-1.233-.646l-.078-.126-1.627-2.93a.503.503 0 0 0-.684-.192l-.074.051-3.897 3.248a.5.5 0 0 0 .02.784l.076.048L12 11.19V18h-1v-6.191l-3.356-1.678a1.5 1.5 0 0 1-.73-1.88l.06-.132a1.5 1.5 0 0 1 .267-.377l.114-.105 3.897-3.248a1.5 1.5 0 0 1 2.113.192ZM16.5 0a2.5 2.5 0 1 1 0 5 2.5 2.5 0 0 1 0-5Zm0 1a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3Z",
    fill: props.color || '#58595B'
  })
}));
var _default = exports.default = SvgBicycle;