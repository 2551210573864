"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = TotalCostCard;
exports.renderPrice = renderPrice;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _react = require("@chakra-ui/react");
var _api = require("@whitbread-eos/api");
var _atoms = require("@whitbread-eos/atoms");
var _utils = require("@whitbread-eos/utils");
var _nextI18next = require("next-i18next");
var _react2 = require("react");
var _jsxRuntime = require("react/jsx-runtime");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function TotalCostCard(_ref) {
  var {
    hotelName,
    ratePlan,
    data,
    isError,
    isLoading,
    error,
    isBillingAddressDisplayed,
    selectedPaymentDetail,
    errorMessagePayment,
    continueToNextStep,
    isAmendPage,
    setValidateQuestions,
    hasError,
    selectedPaymentType,
    paypalOptions,
    additionalAmountLabel
  } = _ref;
  var {
    currency: currencyCode,
    amount: netTotal
  } = ratePlan.totalCost;
  var [termsAndConditions, setTermsAndConditions] = (0, _react2.useState)('');
  var {
    t
  } = (0, _nextI18next.useTranslation)(['common']);
  var {
    language: currentLang
  } = (0, _utils.useCustomLocale)();
  var baseDataTestId = 'TotalCost';
  (0, _react2.useEffect)(() => {
    if (!isLoading) {
      var _data$termsAndConditi;
      setTermsAndConditions((0, _utils.formatUrlTermsConditions)(data === null || data === void 0 || (_data$termsAndConditi = data.termsAndConditions) === null || _data$termsAndConditi === void 0 ? void 0 : _data$termsAndConditi.text));
    }
  }, [data, isLoading]);
  return (0, _jsxRuntime.jsx)(_react.Box, _objectSpread(_objectSpread({
    "data-testid": (0, _utils.formatDataTestId)(baseDataTestId, 'Container')
  }, totalCostBoxStyle), {}, {
    children: isError ? (0, _jsxRuntime.jsx)(_react.Text, {
      children: error.message
    }) : (0, _jsxRuntime.jsx)(_atoms.Card, {
      padding: "xmd",
      children: (0, _jsxRuntime.jsxs)(_react.Flex, _objectSpread(_objectSpread({}, totalCostFlexStyle), {}, {
        children: [(0, _jsxRuntime.jsx)(_react.Text, _objectSpread(_objectSpread({
          "data-testid": (0, _utils.formatDataTestId)(baseDataTestId, 'label')
        }, totalCostTitleStyle), {}, {
          children: isAmendPage ? additionalAmountLabel : t('booking.confirmation.totalCost')
        })), (0, _jsxRuntime.jsx)(_react.Text, _objectSpread(_objectSpread({}, totalCostValueStyle), {}, {
          "data-testid": (0, _utils.formatDataTestId)(baseDataTestId, 'Currency'),
          children: renderPrice({
            currentLanguage: currentLang,
            price: parseFloat(netTotal),
            currency: currencyCode
          })
        })), (0, _jsxRuntime.jsx)(_react.Text, _objectSpread(_objectSpread({
          "data-testid": (0, _utils.formatDataTestId)(baseDataTestId, 'HotelName')
        }, hotelNameStyle), {}, {
          children: hotelName
        })), (0, _jsxRuntime.jsx)(_react.Divider, _objectSpread({}, dividerStyle)), (0, _jsxRuntime.jsxs)(_react.Flex, {
          direction: "column",
          children: [(selectedPaymentDetail === null || selectedPaymentDetail === void 0 ? void 0 : selectedPaymentDetail.type) !== _api.paymentOptions.RESERVE_WITHOUT_CARD && (selectedPaymentType === null || selectedPaymentType === void 0 ? void 0 : selectedPaymentType.type) !== _api.paymentOptions.RESERVE_WITHOUT_CARD && (0, _jsxRuntime.jsx)(_react.Text, _objectSpread(_objectSpread({
            "data-testid": (0, _utils.formatDataTestId)(baseDataTestId, 'CVVNotification')
          }, informAboutCvvStyle), {}, {
            children: selectedPaymentType ? t("".concat((0, _utils.displayPaymentHelpText)(selectedPaymentType))) : t('cc.cvvRequired.info')
          })), (0, _jsxRuntime.jsx)(_react.Box, {
            "data-testid": "termsAndConditions",
            sx: termsAndConditionsStyle,
            className: "formatLinks",
            children: (0, _utils.renderSanitizedHtml)(termsAndConditions)
          }), _api.PAYPAL_PAYMENT === (selectedPaymentType === null || selectedPaymentType === void 0 ? void 0 : selectedPaymentType.name) ? (0, _jsxRuntime.jsx)(_atoms.PaypalWBButton, _objectSpread({}, paypalOptions)) : (0, _jsxRuntime.jsx)(_atoms.Button, _objectSpread(_objectSpread({
            type: "submit",
            form: "billingAddressForm",
            size: "md"
          }, buttonStyle), {}, {
            "data-testid": "submitButton",
            variant: "primary",
            onClick: onclickHandler,
            children: (selectedPaymentDetail === null || selectedPaymentDetail === void 0 ? void 0 : selectedPaymentDetail.type) === _api.paymentOptions.RESERVE_WITHOUT_CARD ? t('ccui.payment.confirmBooking.button') : isAmendPage ? t('amend.confirmChanges') : t('terms.continueText.paymentDetails')
          })), errorMessagePayment && (0, _jsxRuntime.jsx)(_atoms.Notification, {
            prefixDataTestId: "Payment-Error",
            variant: "error",
            status: "error",
            description: (0, _jsxRuntime.jsx)(_react.Box, {
              children: (0, _utils.renderSanitizedHtml)(errorMessagePayment)
            }),
            svg: (0, _jsxRuntime.jsx)(_atoms.Info, {
              color: "var(--chakra-colors-error)"
            }),
            wrapperStyles: errorNotificationStyle
          })]
        })]
      }))
    })
  }));
  function onclickHandler() {
    setValidateQuestions === null || setValidateQuestions === void 0 || setValidateQuestions(true);
    if (!isBillingAddressDisplayed) {
      !hasError && continueToNextStep();
    }
  }
}
function renderPrice(_ref2) {
  var {
    currentLanguage = 'en',
    currency,
    price
  } = _ref2;
  var GBP_CURRENCY = new Intl.NumberFormat('en-GB', {
    style: 'currency',
    currency: 'GBP'
  }).format(price);
  var EUR_CURRENCY = new Intl.NumberFormat('en-GB', {
    style: 'currency',
    currency: 'EUR'
  }).format(price);
  switch (currentLanguage) {
    case 'en':
      {
        switch (currency) {
          case 'GBP':
            {
              return GBP_CURRENCY;
            }
          case 'EUR':
            {
              return EUR_CURRENCY;
            }
          default:
            {
              return GBP_CURRENCY;
            }
        }
      }
    case 'de':
      {
        switch (currency) {
          case 'GBP':
            {
              return GBP_CURRENCY;
            }
          case 'EUR':
            {
              return new Intl.NumberFormat('de-DE', {
                style: 'currency',
                currency: 'EUR'
              }).format(price);
            }
          default:
            {
              return "".concat(currency).concat(price.toFixed(2));
            }
        }
      }
    default:
      {
        switch (currency) {
          case 'GBP':
            {
              return new Intl.NumberFormat('en-GB', {
                style: 'currency',
                currency: 'GBP'
              }).format(price);
            }
          case 'EUR':
            {
              return new Intl.NumberFormat('en-GB', {
                style: 'currency',
                currency: 'EUR'
              }).format(price);
            }
          default:
            {
              return GBP_CURRENCY;
            }
        }
      }
  }
}
var totalCostBoxStyle = {
  my: 'xl',
  w: {
    mobile: 'full',
    md: '45rem',
    lg: '50.5rem',
    xl: '54rem'
  }
};
var totalCostFlexStyle = {
  w: 'full',
  direction: 'column',
  p: {
    mobile: '0',
    md: 'xmd',
    lg: 'xmd',
    xl: 'xmd'
  }
};
var totalCostTitleStyle = {
  lineHeight: '3',
  fontWeight: 'semibold',
  fontSize: 'md',
  mb: 'xs'
};
var totalCostValueStyle = {
  lineHeight: '4',
  mb: 'sm',
  fontWeight: 'bold',
  fontSize: '3xl'
};
var hotelNameStyle = {
  lineHeight: '3',
  fontWeight: 'semibold',
  fontSize: 'lg'
};
var dividerStyle = {
  my: 'xl',
  borderColor: 'lightGrey2'
};
var informAboutCvvStyle = {
  fontSize: 'md',
  fontWeight: 'normal',
  lineHeight: '3',
  mb: 'lg'
};
var termsAndConditionsStyle = {
  p: {
    fontSize: 'md',
    fontWeight: 'normal',
    lineHeight: '3'
  },
  a: {
    textDecoration: 'underline',
    color: 'zipSecondary'
  }
};
var buttonStyle = {
  mt: 'lg',
  w: {
    mobile: 'full',
    lg: '18rem',
    xl: '19.3125rem'
  }
};
var errorNotificationStyle = {
  mt: 'lg',
  sx: {
    a: {
      fontWeight: 'bold'
    }
  }
};