"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.GET_AGENT_MEMOS = void 0;
var _taggedTemplateLiteral2 = _interopRequireDefault(require("@babel/runtime/helpers/taggedTemplateLiteral"));
var _graphqlRequest = require("graphql-request");
var _templateObject;
var GET_AGENT_MEMOS = exports.GET_AGENT_MEMOS = (0, _graphqlRequest.gql)(_templateObject || (_templateObject = (0, _taggedTemplateLiteral2.default)(["\n  query getMemos($basketReference: String!) {\n    getMemos(basketReference: $basketReference) {\n      memos {\n        ids {\n          reservationId\n          memoIds\n        }\n        description\n        createdOn\n        createdBy\n        modifiedOn\n        modifiedBy\n        memoType\n      }\n    }\n  }\n"])));