"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.decodeFromBase64 = decodeFromBase64;
exports.encodeToBase64 = encodeToBase64;
var _validators = require("../validators");
function decodeFromBase64(value) {
  try {
    if (!value || !(0, _validators.isStringValid)(value)) {
      return null;
    }
    var valueToString = value.toString();
    if (typeof window !== 'undefined') {
      return atob(valueToString);
    }
    return Buffer.from(valueToString, 'base64').toString('ascii');
  } catch (e) {
    return null;
  }
}
function encodeToBase64(value) {
  try {
    if (!value || !(0, _validators.isStringValid)(value)) {
      return null;
    }
    var valueToString = value.toString();
    if (typeof window !== 'undefined') {
      return btoa(valueToString);
    }
    return Buffer.from(valueToString, 'ascii').toString('base64');
  } catch (e) {
    return null;
  }
}