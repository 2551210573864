"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _react = require("@chakra-ui/react");
var _utils = require("@whitbread-eos/utils");
var _dateFns = require("date-fns");
var _components = require("./components");
var _jsxRuntime = require("react/jsx-runtime");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
var BookingInformation = _ref => {
  var {
    bookingInformation,
    t,
    inputValues,
    baseDataTestId
  } = _ref;
  return (0, _jsxRuntime.jsx)(_react.Box, _objectSpread(_objectSpread({}, cardStyle), {}, {
    children: (0, _jsxRuntime.jsxs)(_react.Flex, {
      flexDir: "column",
      children: [(0, _jsxRuntime.jsx)(_react.Heading, _objectSpread(_objectSpread({}, headingStyle), {}, {
        "data-testid": (0, _utils.formatDataTestId)(baseDataTestId, 'Heading'),
        children: t('ccui.idv.bookingInformation.heading')
      })), (0, _jsxRuntime.jsx)(_react.Divider, {
        mt: "1rem",
        mb: "2rem"
      }), (0, _jsxRuntime.jsx)(_components.IdvCheckbox, {
        value: bookingInformation.reservationNumber.value,
        partOfSearch: inputValues.bookingReference ? inputValues.bookingReference !== '' : false,
        text: t('ccui.idv.bookingInformation.reservationNumber'),
        dataTestId: (0, _utils.formatDataTestId)(baseDataTestId, 'ReservationNumber')
      }), (0, _jsxRuntime.jsx)(_react.Divider, {
        my: "1rem"
      }), (0, _jsxRuntime.jsx)(_components.IdvCheckbox, {
        value: bookingInformation.hotelName,
        partOfSearch: inputValues.hotelName ? inputValues.hotelName !== '' : false,
        text: t('ccui.idv.bookingInformation.hotelName'),
        dataTestId: (0, _utils.formatDataTestId)(baseDataTestId, 'HotelName')
      }), (0, _jsxRuntime.jsx)(_react.Divider, {
        my: "1rem"
      }), (0, _jsxRuntime.jsx)(_components.IdvCheckbox, {
        value: (0, _dateFns.format)((0, _dateFns.parse)(bookingInformation.arrivalDate, 'yyyy-MM-dd', new Date()), 'dd/MM/yyyy'),
        partOfSearch: inputValues.arrivalDate ? inputValues.arrivalDate !== '' : false,
        text: t('ccui.idv.bookingInformation.arrivalDate'),
        dataTestId: (0, _utils.formatDataTestId)(baseDataTestId, 'ArrivalDate')
      }), (0, _jsxRuntime.jsx)(_react.Divider, {
        my: "1rem"
      }), (0, _jsxRuntime.jsx)(_components.IdvCheckbox, {
        value: (0, _dateFns.format)((0, _dateFns.parse)(bookingInformation.departureDate, 'yyyy-MM-dd', new Date()), 'dd/MM/yyyy'),
        partOfSearch: inputValues.departureDate ? inputValues.departureDate !== '' : false,
        text: t('ccui.idv.bookingInformation.departureDate'),
        dataTestId: (0, _utils.formatDataTestId)(baseDataTestId, 'DepartureDate')
      }), (0, _jsxRuntime.jsx)(_react.Divider, {
        my: "1rem"
      }), (0, _jsxRuntime.jsx)(_components.IdvCheckbox, {
        value: bookingInformation.emailAddress,
        partOfSearch: inputValues.bookerEmail ? inputValues.bookerEmail !== '' : false,
        text: t('ccui.idv.bookingInformation.emailAddress'),
        dataTestId: (0, _utils.formatDataTestId)(baseDataTestId, 'EmailAddress')
      })]
    })
  }));
};
var cardStyle = {
  w: {
    mobile: 'full',
    xs: 'full',
    sm: 'full',
    md: 'full',
    lg: '311px',
    xl: '333px'
  },
  border: '1px solid var(--chakra-colors-lightGrey4)',
  p: '1.5rem',
  mr: {
    md: '18px',
    lg: '20px'
  }
};
var headingStyle = {
  color: 'darkGrey2',
  fontSize: '1.25rem',
  lineHeight: '1.5rem',
  fontWeight: '700',
  fontFamily: 'Proxima Nova Sans, helvetica, arial, sans-serif'
};
var _default = exports.default = BookingInformation;