"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "ResendConfirmationModal", {
  enumerable: true,
  get: function get() {
    return _ResendConfirmationModal.default;
  }
});
var _ResendConfirmationModal = _interopRequireDefault(require("./ResendConfirmationModal.container"));