"use strict";
'use client';

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.FormPage = FormPage;
var _server = require("@whitbread-eos/utils/server");
var _image = _interopRequireDefault(require("next/image"));
var _link = _interopRequireDefault(require("next/link"));
var _jsxRuntime = require("react/jsx-runtime");
function FormPage(_ref) {
  var {
    baseDataTestId,
    title,
    children,
    backIcon,
    backHref
  } = _ref;
  return (0, _jsxRuntime.jsx)("div", {
    className: (0, _server.cn)('form-page', pageStyle),
    "data-testid": "".concat(baseDataTestId, "-page"),
    children: (0, _jsxRuntime.jsxs)("div", {
      className: containerStyle,
      children: [backIcon && backHref && (0, _jsxRuntime.jsx)("span", {
        className: backStyle,
        children: (0, _jsxRuntime.jsx)(_link.default, {
          href: backHref,
          children: (0, _jsxRuntime.jsx)(_image.default, {
            alt: 'Back',
            src: backIcon,
            width: 48,
            height: 48,
            priority: true,
            "data-testid": "".concat(baseDataTestId, "-back-icon")
          })
        })
      }), (0, _jsxRuntime.jsx)("span", {
        className: h1Style,
        "data-testid": "".concat(baseDataTestId, "-title"),
        children: title
      }), children]
    })
  });
}
var pageStyle = 'bg-lightGrey5 border-b border-lightGrey3';
var containerStyle = 'relative w-[420px] mobile:w-full mx-auto my-12 mobile:m-0 mobile:p-6';
var backStyle = 'absolute mobile:static top-0 -left-16 w-12 h-12';
var h1Style = 'flex text-[2.5rem] font-black leading-[2.75rem] text-secondaryColor mobile:text-4xl';