"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.DASHBOARD_UPDATE_OVERRIDE_RESERVATION = void 0;
var _taggedTemplateLiteral2 = _interopRequireDefault(require("@babel/runtime/helpers/taggedTemplateLiteral"));
var _graphqlRequest = require("graphql-request");
var _templateObject;
var DASHBOARD_UPDATE_OVERRIDE_RESERVATION = exports.DASHBOARD_UPDATE_OVERRIDE_RESERVATION = (0, _graphqlRequest.gql)(_templateObject || (_templateObject = (0, _taggedTemplateLiteral2.default)(["\n  mutation updateReservationOverrideReasons(\n    $basketReference: String!\n    $hotelId: String!\n    $reasonCode: String!\n    $reasonName: String!\n    $callerName: String!\n    $managerName: String\n  ) {\n    updateReservationOverrideReasons(\n      updateReservationOverrideReasonsCriteria: {\n        basketReference: $basketReference\n        hotelId: $hotelId\n        reasonName: $reasonName\n        callerName: $callerName\n        managerName: $managerName\n        reasonCode: $reasonCode\n      }\n    ) {\n      basketReference\n    }\n  }\n"])));