"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = DynamicGeneralSearchEmployee;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _react = require("@chakra-ui/react");
var _atoms = require("@whitbread-eos/atoms");
var _utils = require("@whitbread-eos/utils");
var _nextI18next = require("next-i18next");
var _react2 = require("react");
var _jsxRuntime = require("react/jsx-runtime");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function DynamicGeneralSearchEmployee(_ref) {
  var {
    queryClient,
    listExclusion,
    setGuestUser,
    index,
    setDisplayDynamic,
    control,
    errors,
    baseDataTestId,
    isDisabled,
    bbEmployeeList = [],
    defaultGuest,
    isAmendPage,
    onEditBbInput,
    amendInputWidth,
    componentName
  } = _ref;
  var isDefaultGuestSelected = isAmendPage && !!defaultGuest;
  var defaultGuestValue = isDefaultGuestSelected ? defaultGuest : {
    emailAddress: '',
    firstName: '',
    id: '',
    lastName: '',
    title: '',
    composedName: ''
  };
  var defaultGuestComposedName = isDefaultGuestSelected ? "".concat(defaultGuest === null || defaultGuest === void 0 ? void 0 : defaultGuest.title, " ").concat(defaultGuest === null || defaultGuest === void 0 ? void 0 : defaultGuest.firstName, " ").concat(defaultGuest === null || defaultGuest === void 0 ? void 0 : defaultGuest.lastName) : '';
  var {
    t
  } = (0, _nextI18next.useTranslation)('');
  var [suggestions, setSuggestions] = (0, _react2.useState)(bbEmployeeList);
  var [activeKey, setActiveKey] = (0, _react2.useState)(0);
  var [selectedItem, setSelectedItem] = (0, _react2.useState)(defaultGuestValue);
  var [isDropDownVisible, setIsDropDownVisible] = (0, _react2.useState)(!isAmendPage);
  var [hasError, setHasError] = (0, _react2.useState)(false);
  var [value, setValue] = (0, _react2.useState)(defaultGuestComposedName);
  var refInput = (0, _react2.useRef)(null);
  var debouncedOnChange = (0, _utils.useDebounce)(debounceInput);
  (0, _react2.useEffect)(() => {
    setActiveKey(0);
  }, [suggestions]);
  (0, _react2.useEffect)(() => {
    var _errors$componentName;
    if (errors !== null && errors !== void 0 && (_errors$componentName = errors[componentName]) !== null && _errors$componentName !== void 0 && _errors$componentName[index] && Object.keys(errors === null || errors === void 0 ? void 0 : errors[componentName][index]).length > 0) {
      setHasError(true);
    }
  }, [errors, index]);
  return (0, _jsxRuntime.jsxs)(_react.Flex, _objectSpread(_objectSpread({}, inputWrapperStyle), {}, {
    width: isAmendPage ? amendInputWidth : inputWrapperStyle.width,
    children: [(0, _jsxRuntime.jsx)(_atoms.Input, {
      autoComplete: "off",
      value: value,
      onClick: onClickInput,
      name: "searchCriteria",
      "data-testid": (0, _utils.formatDataTestId)(baseDataTestId, 'Input-Dynamic-Search'),
      placeholderText: t('booking.searchCriteria'),
      label: t('booking.searchCriteria'),
      showIcon: !isAmendPage && !!selectedItem,
      error: hasError && t('config.errorMessages.yourDetails.empSearch.invalid'),
      onBlur: onChangeHandler,
      onChange: onChangeInput,
      onKeyDown: onKeyDownInput,
      inputRef: refInput,
      isDisabled: isDisabled,
      className: "sessioncamhidetext assist-no-show"
    }), suggestions.length > 0 && isDropDownVisible && (0, _jsxRuntime.jsx)(_atoms.Card, _objectSpread(_objectSpread({}, cardSuggestionsStyle), {}, {
      "data-testid": (0, _utils.formatDataTestId)(baseDataTestId, "SuggestionCard"),
      children: suggestions.map((suggestion, key) => {
        return (0, _react2.createElement)(_react.Box, _objectSpread(_objectSpread({}, suggestionItemStyle(activeKey === key)), {}, {
          key: suggestion.employee.composedName,
          id: "DropDownItem-".concat(key),
          "data-testid": (0, _utils.formatDataTestId)(baseDataTestId, "DropDownItem-".concat(key)),
          onMouseOver: () => setActiveKey(key),
          onClick: onChangeHandler,
          className: "assist-no-show"
        }), (0, _utils.renderSanitizedHtml)(suggestion.prettyFormatDisplay));
      })
    }))]
  }));
  function onKeyDownInput(event) {
    var key = event.key;
    if (key === 'Enter') {
      event.preventDefault();
      if (suggestions.length === 0 || selectedItem.id !== '') {
        setDisplayDynamic(false);
      } else {
        onChangeHandler();
      }
    }
    if (key === 'ArrowUp') {
      if (activeKey === 0) {
        scrollToElement(suggestions.length - 1);
        return setActiveKey(suggestions.length - 1);
      }
      scrollToElement(activeKey - 1);
      setActiveKey(activeKey - 1);
    }
    if (key === 'ArrowDown') {
      if (activeKey === suggestions.length - 1) {
        scrollToElement(0);
        return setActiveKey(0);
      }
      scrollToElement(activeKey + 1);
      setActiveKey(activeKey + 1);
    }
  }
  function onClickInput() {
    if (value !== '' && selectedItem.firstName !== '') {
      resetField();
      setHasError(true);
    } else {
      setIsDropDownVisible(true);
    }
  }
  function resetField() {
    var _control$_formValues, _control$_formValues2, _control$_formValues3, _control$_formValues4;
    setValue('');
    setSelectedItem(defaultGuestValue);
    setGuestUser(defaultGuestValue, index, componentName);
    setSuggestions([]);
    setActiveKey(0);
    if (componentName === 'bbGuestDetails') {
      control._formValues.bbGuestDetails[index] = defaultGuestValue;
    } else if (componentName === 'bbAccompanyingGuestDetails' && (_control$_formValues = control._formValues) !== null && _control$_formValues !== void 0 && _control$_formValues.bbAccompanyingGuestDetails && (_control$_formValues2 = control._formValues) !== null && _control$_formValues2 !== void 0 && _control$_formValues2.bbAccompanyingGuestDetails[index]) {
      control._formValues.bbAccompanyingGuestDetails[index] = defaultGuestValue;
    } else if (componentName === 'guestDetailsBBForm' && (_control$_formValues3 = control._formValues) !== null && _control$_formValues3 !== void 0 && _control$_formValues3.guestDetailsBBForm && (_control$_formValues4 = control._formValues) !== null && _control$_formValues4 !== void 0 && _control$_formValues4.guestDetailsBBForm[index]) {
      control._formValues.guestDetailsBBForm[index] = defaultGuestValue;
    }
    setIsDropDownVisible(true);
  }
  function debounceInput() {
    if (value.length > 2 && value.length <= 30) {
      searchEmployees(value);
    } else {
      setSuggestions([]);
    }
  }
  function onChangeInput(value) {
    setHasError(false);
    debouncedOnChange();
    setValue(value);
  }
  function onChangeHandler() {
    if (value === '' || value !== '' && suggestions.length === 0) {
      setHasError(true);
      resetField();
    }
    if (suggestions.length > 0 && isDropDownVisible) {
      var _suggestions$activeKe, _suggestions$activeKe2, _control$_formValues5, _control$_formValues6, _control$_formValues7, _control$_formValues8, _suggestions$activeKe6;
      var {
        title,
        firstName,
        lastName
      } = suggestions[activeKey].employee;
      var _value = "".concat(title, " ").concat(firstName, " ").concat(lastName);
      setValue(_value);
      setSelectedItem((_suggestions$activeKe = suggestions[activeKey]) === null || _suggestions$activeKe === void 0 ? void 0 : _suggestions$activeKe.employee);
      setGuestUser((_suggestions$activeKe2 = suggestions[activeKey]) === null || _suggestions$activeKe2 === void 0 ? void 0 : _suggestions$activeKe2.employee, index, componentName);
      if (componentName === 'bbGuestDetails') {
        var _suggestions$activeKe3;
        control._formValues.bbGuestDetails[index] = (_suggestions$activeKe3 = suggestions[activeKey]) === null || _suggestions$activeKe3 === void 0 ? void 0 : _suggestions$activeKe3.employee;
      } else if (componentName === 'bbAccompanyingGuestDetails' && (_control$_formValues5 = control._formValues) !== null && _control$_formValues5 !== void 0 && _control$_formValues5.bbAccompanyingGuestDetails && (_control$_formValues6 = control._formValues) !== null && _control$_formValues6 !== void 0 && _control$_formValues6.bbAccompanyingGuestDetails[index]) {
        var _suggestions$activeKe4;
        control._formValues.bbAccompanyingGuestDetails[index] = (_suggestions$activeKe4 = suggestions[activeKey]) === null || _suggestions$activeKe4 === void 0 ? void 0 : _suggestions$activeKe4.employee;
      } else if (componentName === 'guestDetailsBBForm' && (_control$_formValues7 = control._formValues) !== null && _control$_formValues7 !== void 0 && _control$_formValues7.guestDetailsBBForm && (_control$_formValues8 = control._formValues) !== null && _control$_formValues8 !== void 0 && _control$_formValues8.guestDetailsBBForm[index]) {
        var _suggestions$activeKe5;
        control._formValues.guestDetailsBBForm[index] = (_suggestions$activeKe5 = suggestions[activeKey]) === null || _suggestions$activeKe5 === void 0 ? void 0 : _suggestions$activeKe5.employee;
      }
      setIsDropDownVisible(false);
      onEditBbInput === null || onEditBbInput === void 0 || onEditBbInput((_suggestions$activeKe6 = suggestions[activeKey]) === null || _suggestions$activeKe6 === void 0 ? void 0 : _suggestions$activeKe6.employee);
    }
  }
  function scrollToElement(activeKey) {
    var element = document.getElementById("DropDownItem-".concat(activeKey));
    if (element) {
      element.scrollIntoView({
        block: 'nearest',
        behavior: 'smooth'
      });
    }
  }
  function searchEmployees(searchCriteria) {
    (0, _utils.getListOfEmployees)({
      awaitingApproval: false,
      bookingChannel: 'CBT',
      page: 1,
      searchCriteria: searchCriteria,
      size: 10,
      queryClient: queryClient
    }).then(data => {
      if (data) {
        setSuggestions((0, _utils.generateSuggestionList)(data, value, listExclusion));
      } else {
        setSuggestions([]);
      }
    }).catch(err => {
      console.log(err);
      setHasError(true);
      setSuggestions([]);
    });
  }
}
function suggestionItemStyle(isActiveKey) {
  return {
    bg: isActiveKey ? 'lightGrey5' : 'baseWhite',
    px: 'md',
    py: 'xmd',
    lineHeight: '1',
    fontFamily: 'body',
    alignItems: 'center'
  };
}
var cardSuggestionsStyle = {
  flexDirection: 'column',
  maxHeight: '12.625rem',
  overflowY: 'scroll',
  padding: '0'
};
var inputWrapperStyle = {
  width: {
    mobile: '100%',
    sm: '21.938rem',
    md: '21.75rem',
    lg: '24.5rem',
    xl: '26.25rem'
  },
  marginTop: 'md',
  borderColor: 'lightGrey1',
  flexDirection: 'column'
};