"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = ResultList;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _react = require("@chakra-ui/react");
var _atoms = require("@whitbread-eos/atoms");
var _utils = require("@whitbread-eos/utils");
var _ResultRow = _interopRequireDefault(require("./ResultRow.component"));
var _jsxRuntime = require("react/jsx-runtime");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function ResultList(_ref) {
  var {
    bartCard,
    operaCard,
    loading,
    isSuccess,
    rows,
    headerTitles,
    baseDataTestId,
    t,
    isError,
    queryClient,
    bookerLastName,
    arrivalDate,
    bartId,
    hasMore,
    changePage,
    bartHotelName,
    limitExceeded,
    updateStatusAfterCancel,
    enhancedSearch = false
  } = _ref;
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsxs)(_react.TableContainer, _objectSpread(_objectSpread({
      "data-testid": (0, _utils.formatDataTestId)(baseDataTestId, 'Table-Container')
    }, resultContainerStyle), {}, {
      children: [(0, _jsxRuntime.jsxs)(_react.Table, {
        size: "sm",
        children: [(0, _jsxRuntime.jsx)(_react.Thead, _objectSpread(_objectSpread({}, headerTableStyle), {}, {
          children: (0, _jsxRuntime.jsx)(_react.Tr, {
            children: headerTitles.map(headerTitle => {
              return (0, _jsxRuntime.jsx)(_react.Th, _objectSpread(_objectSpread({}, headerResultsTextStyle), {}, {
                "data-testid": (0, _utils.formatDataTestId)(baseDataTestId, "TableHeader-".concat(headerTitle.id)),
                children: headerTitle.text
              }), headerTitle.id);
            })
          })
        })), (0, _jsxRuntime.jsxs)(_react.Tbody, {
          children: [rows === null || rows === void 0 ? void 0 : rows.map((row, i) => {
            var _row$cells$find, _row$cells$find$value, _row$cells$find2, _row$isExpandedByDefa;
            if (i === 0) row.isExpandedByDefault = true;
            var sourcePms = (_row$cells$find = row.cells.find(cell => cell.id === 'SourcePms')) === null || _row$cells$find === void 0 ? void 0 : _row$cells$find.value;
            var rowBookerLastName = enhancedSearch ? row.bookerLastName : bookerLastName;
            var rowArrivalDate = enhancedSearch ? (_row$cells$find$value = (_row$cells$find2 = row.cells.find(cell => cell.id === 'Date')) === null || _row$cells$find2 === void 0 ? void 0 : _row$cells$find2.value) !== null && _row$cells$find$value !== void 0 ? _row$cells$find$value : '' : arrivalDate;
            var rowBartHotelId = enhancedSearch && (sourcePms === null || sourcePms === void 0 ? void 0 : sourcePms.toLowerCase()) === 'bart' ? row.hotelId : bartId;
            var rowBookingReference = enhancedSearch ? row.bookingReference.split('-')[0] : row.bookingReference;
            return (0, _jsxRuntime.jsx)(_ResultRow.default, {
              bartId: rowBartHotelId,
              bookerLastName: rowBookerLastName,
              arrivalDate: rowArrivalDate,
              bartCard: bartCard,
              operaCard: operaCard,
              cells: row.cells,
              baseDataTestId: baseDataTestId,
              queryClient: queryClient,
              bookingReference: rowBookingReference,
              rowNr: i,
              t: t,
              isExpandedByDefault: (_row$isExpandedByDefa = row.isExpandedByDefault) !== null && _row$isExpandedByDefa !== void 0 ? _row$isExpandedByDefa : false,
              bartHotelName: bartHotelName,
              updateStatusAfterCancel: updateStatusAfterCancel
            }, row.bookingReference);
          }), loading && (0, _jsxRuntime.jsx)(_react.Tr, {
            children: (0, _jsxRuntime.jsx)(_react.Td, {
              children: (0, _jsxRuntime.jsx)(_react.Text, {
                "data-testid": (0, _utils.formatDataTestId)(baseDataTestId, 'Table-loading-message'),
                children: t('searchresults.list.hotel.loading')
              })
            })
          })]
        })]
      }), limitExceeded && (0, _jsxRuntime.jsx)(_react.Box, _objectSpread(_objectSpread({
        "data-testid": (0, _utils.formatDataTestId)(baseDataTestId, 'LimitExceededNotification')
      }, notificationStyles), {}, {
        children: (0, _jsxRuntime.jsx)(_atoms.Notification, {
          maxWidth: "full",
          variant: "info",
          status: "info",
          title: '',
          description: t('ccui.manageBooking.search.maxResults'),
          svg: (0, _jsxRuntime.jsx)(_atoms.Info, {})
        })
      })), (rows === null || rows === void 0 ? void 0 : rows.length) === 0 && !loading && isSuccess && !limitExceeded && (0, _jsxRuntime.jsx)(_react.Box, _objectSpread(_objectSpread({
        "data-testid": (0, _utils.formatDataTestId)(baseDataTestId, 'NoBookingNotification')
      }, notificationStyles), {}, {
        children: (0, _jsxRuntime.jsx)(_atoms.Notification, {
          maxWidth: "full",
          variant: "info",
          status: "info",
          title: '',
          description: enhancedSearch ? t('ccui.manageBooking.enhancedSearch.notFound') : t('ccui.manageBooking.search.notFound'),
          svg: (0, _jsxRuntime.jsx)(_atoms.Info, {})
        })
      })), isError && (0, _jsxRuntime.jsx)(_react.Flex, {
        mt: "xl",
        children: (0, _jsxRuntime.jsx)(_atoms.Notification, {
          status: "error",
          description: t('ccui.manageBooking.resultList.errorMessage'),
          variant: "alert",
          maxW: "full",
          svg: (0, _jsxRuntime.jsx)(_atoms.Alert, {})
        })
      })]
    })), hasMore && (0, _jsxRuntime.jsx)(_react.Box, _objectSpread(_objectSpread({}, loadMoreContainerStyle), {}, {
      children: (0, _jsxRuntime.jsx)(_atoms.Button, {
        color: "btnSecondaryEnabled",
        variant: "tertiary",
        size: "md",
        onClick: () => changePage(),
        children: t('dashboard.bookings.loadMore')
      })
    }))]
  });
}
var loadMoreContainerStyle = {
  textAlign: 'center',
  mt: 'lg'
};
var resultContainerStyle = {
  mt: '3xl'
};
var headerTableStyle = {
  backgroundColor: 'lightGrey5',
  h: '4rem'
};
var headerResultsTextStyle = {
  fontWeight: 'medium',
  fontSize: 'lg',
  lineHeight: '3',
  color: 'baseBlack',
  textTransform: 'none',
  fontFamily: 'header'
};
var notificationStyles = {
  mt: 'xl',
  mb: 'lg'
};