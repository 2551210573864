"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = HeaderSideNav;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _react = require("@chakra-ui/react");
var _atoms = require("@whitbread-eos/atoms");
var _NavigationMenuMobile = require("../NavigationMenuMobile.style");
var _jsxRuntime = require("react/jsx-runtime");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function HeaderSideNav(_ref) {
  var {
    title,
    onClickTitle,
    dataTestId
  } = _ref;
  return (0, _jsxRuntime.jsx)(_react.ModalHeader, _objectSpread(_objectSpread({}, modalHeaderStyle), {}, {
    children: (0, _jsxRuntime.jsxs)(_react.Grid, {
      templateColumns: "1fr auto",
      alignItems: "center",
      children: [(0, _jsxRuntime.jsx)(_react.Box, {
        h: "var(--chakra-space-lg)",
        children: title && (0, _jsxRuntime.jsxs)(_react.Flex, {
          alignItems: "center",
          _hover: {
            cursor: 'pointer'
          },
          onClick: onClickTitle,
          "data-testid": "Global-SideNav-BackButton-Mobile",
          children: [(0, _jsxRuntime.jsx)(_atoms.Icon, {
            svg: (0, _jsxRuntime.jsx)(_atoms.ChevronLeft, {})
          }), (0, _jsxRuntime.jsx)(_react.Box, {
            py: "0",
            px: "md",
            "data-testid": dataTestId,
            children: title
          })]
        })
      }), (0, _jsxRuntime.jsx)(_react.ModalCloseButton, _objectSpread(_objectSpread({}, _NavigationMenuMobile.modalIconStyle), {}, {
        children: (0, _jsxRuntime.jsx)(_atoms.Icon, {
          svg: (0, _jsxRuntime.jsx)(_atoms.Dismiss, {
            "data-testid": "closeModal"
          })
        })
      }))]
    })
  }));
}
var modalHeaderStyle = {
  h: 'var(--chakra-space-14)',
  p: 'md',
  fontWeight: 'medium',
  lineHeight: '3',
  fontSize: 'md',
  alignItems: 'center',
  color: 'primary',
  borderBottom: 'var(--chakra-space-px) solid var(--chakra-colors-lightGrey2)'
};