"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.REGISTER_USER_ACCOUNT = void 0;
var _taggedTemplateLiteral2 = _interopRequireDefault(require("@babel/runtime/helpers/taggedTemplateLiteral"));
var _graphqlRequest = require("graphql-request");
var _templateObject;
var REGISTER_USER_ACCOUNT = exports.REGISTER_USER_ACCOUNT = (0, _graphqlRequest.gql)(_templateObject || (_templateObject = (0, _taggedTemplateLiteral2.default)(["\n  mutation createAccount(\n    $country: String\n    $language: String\n    $captcha: String\n    $password: String!\n    $companyName: String\n    $cityName: String\n    $emailAddress: String!\n    $firstName: String!\n    $lastName: String!\n    $title: String\n    $addressType: String\n    $postalCode: String\n    $mobile: String!\n    $addressLine1: String\n    $addressLine2: String\n    $addressLine3: String\n    $addressLine4: String\n    $countryCode: String\n    $basketReference: String\n  ) {\n    createAccount(\n      createAccountRequest: {\n        country: $country\n        language: $language\n        captcha: $captcha\n        password: $password\n        contactDetail: {\n          title: $title\n          firstName: $firstName\n          lastName: $lastName\n          emailAddress: $emailAddress\n          mobile: $mobile\n          address: {\n            companyName: $companyName\n            addressLine1: $addressLine1\n            addressLine2: $addressLine2\n            addressLine3: $addressLine3\n            addressLine4: $addressLine4\n            addressType: $addressType\n            countryCode: $countryCode\n            postalCode: $postalCode\n            cityName: $cityName\n          }\n        }\n        basketReference: $basketReference\n      }\n    ) {\n      success\n      customerId\n    }\n  }\n"])));