"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.GET_HEADER_BOOKING_FLOW_INFORMATION = void 0;
var _taggedTemplateLiteral2 = _interopRequireDefault(require("@babel/runtime/helpers/taggedTemplateLiteral"));
var _graphqlRequest = require("graphql-request");
var _templateObject;
var GET_HEADER_BOOKING_FLOW_INFORMATION = exports.GET_HEADER_BOOKING_FLOW_INFORMATION = (0, _graphqlRequest.gql)(_templateObject || (_templateObject = (0, _taggedTemplateLiteral2.default)(["\n  query GetBookingFlowInformation(\n    $bookingFlowId: String!\n    $hotelId: String!\n    $language: String!\n    $country: String!\n  ) {\n    bookingFlowInformation(\n      bookingFlowId: $bookingFlowId\n      hotelId: $hotelId\n      language: $language\n      country: $country\n    ) {\n      brand\n      bookingFlowSteps {\n        id\n        step\n        title\n      }\n    }\n  }\n"])));