"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = HotelLocationInformation;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _react = require("@chakra-ui/react");
var _nextI18next = require("next-i18next");
var _jsxRuntime = require("react/jsx-runtime");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function HotelLocationInformation(_ref) {
  var {
    isLoading,
    isError,
    error,
    address,
    satNavDirections,
    whatThreeWords
  } = _ref;
  var countryCode = (address === null || address === void 0 ? void 0 : address.country) !== undefined && (address.country === 'Germany' || address.country === 'Deutschland') ? 'DE' : 'GB';
  var {
    t
  } = (0, _nextI18next.useTranslation)(['common']);
  if (isLoading) {
    return (0, _jsxRuntime.jsx)(_react.Text, {
      children: t('searchresults.list.hotel.loading')
    });
  }
  if (isError) {
    return (0, _jsxRuntime.jsx)(_react.Text, {
      children: error.message
    });
  }
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_react.Box, _objectSpread(_objectSpread({}, boxStyles), {}, {
      children: countryCode === 'DE' ? (0, _jsxRuntime.jsx)(_react.Text, {
        "data-testid": "hdp_hotelLocationAddress",
        children: "".concat(address === null || address === void 0 ? void 0 : address.addressLine1, ", ").concat(address === null || address === void 0 ? void 0 : address.postalCode, " ").concat(address === null || address === void 0 ? void 0 : address.addressLine2)
      }) : (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
        children: [(0, _jsxRuntime.jsx)(_react.Text, {
          "data-testid": "hdp_hotelLocationAddress",
          children: "".concat(address === null || address === void 0 ? void 0 : address.addressLine1, ", ").concat(address === null || address === void 0 ? void 0 : address.addressLine2, ", ").concat(address === null || address === void 0 ? void 0 : address.addressLine3)
        }), (0, _jsxRuntime.jsx)(_react.Text, {
          "data-testid": "hdp_hotelLocationPostcode",
          children: address === null || address === void 0 ? void 0 : address.postalCode
        })]
      })
    })), (0, _jsxRuntime.jsxs)(_react.Box, {
      mb: 2.5,
      children: [satNavDirections && renderHotelLocationInformation('hoteldetails.satnav.title', satNavDirections, 'hdp_satNavDirections'), whatThreeWords && renderHotelLocationInformation('hoteldetails.whatthreewords.label', whatThreeWords, 'hdp_whatThreeWords')]
    })]
  });
  function renderHotelLocationInformation(title, description, dataTestId) {
    return (0, _jsxRuntime.jsxs)(_react.Box, {
      children: [(0, _jsxRuntime.jsx)(_react.Box, {
        as: "span",
        display: "inline",
        fontSize: "md",
        "data-testid": "".concat(dataTestId, "Title"),
        children: t(title)
      }), (0, _jsxRuntime.jsx)(_react.Box, {
        as: "span",
        display: "inline",
        fontWeight: "bold",
        ml: 1.5,
        "data-testid": "".concat(dataTestId, "Description"),
        children: description
      })]
    });
  }
}
var boxStyles = {
  fontWeight: 'semibold',
  fontSize: 'md',
  lineHeight: 'base',
  mb: 1.5
};