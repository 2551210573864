"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = EmailInputModal;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _react = require("@chakra-ui/react");
var _api = require("@whitbread-eos/api");
var _atoms = require("@whitbread-eos/atoms");
var _utils = require("@whitbread-eos/utils");
var _jsxRuntime = require("react/jsx-runtime");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function EmailInputModal(_ref) {
  var {
    modalContent,
    isModalVisible,
    closeOnOverlayClick = true,
    email,
    handleEmailChange,
    handleOnSubmit,
    showNotification,
    isLoading,
    checkIfValid,
    handleOnModalClose,
    hasError,
    hasCustomerContent,
    customerConsent,
    handleCustomerConsentChange,
    isEmailTriggeringSuccess = false,
    isEmailTriggeringError = false
  } = _ref;
  var {
    baseDataTestId
  } = modalContent;
  var isConfirmEmailAddressEnabled = (0, _utils.useFeatureSwitch)({
    featureSwitchKey: _api.FS_CONFIRM_EMAIL_ADDRESS_CCUI,
    fallbackValue: false
  });
  var isCustomerConsentCheckboxDisplayed = hasCustomerContent && handleCustomerConsentChange && isConfirmEmailAddressEnabled;
  var emailInputError = hasError && modalContent.emailErrorMsg;
  return (0, _jsxRuntime.jsx)(_atoms.ModalVariants, {
    onClose: handleOnModalClose,
    variant: "info",
    isOpen: isModalVisible,
    variantProps: {
      title: '',
      delimiter: false
    },
    closeOnOverlayClick: closeOnOverlayClick,
    headerStyles: modalHeaderStyles,
    headerContentStyles: modalHeaderContentStyles,
    children: (0, _jsxRuntime.jsx)(_react.Box, _objectSpread(_objectSpread({}, modalStyles), {}, {
      children: renderModalContent()
    }))
  });
  function renderModalContent() {
    return (0, _jsxRuntime.jsxs)(_react.Flex, {
      flexDir: "column",
      "data-testid": (0, _utils.formatDataTestId)(baseDataTestId, 'ModalContainer'),
      children: [(0, _jsxRuntime.jsx)(_react.Divider, _objectSpread({}, dividerStyle)), showNotification && renderEmailTriggeringNotification(), (0, _jsxRuntime.jsx)(_react.Text, _objectSpread(_objectSpread({}, titleStyle), {}, {
        mt: "0",
        children: modalContent.title
      })), (0, _jsxRuntime.jsx)(_react.Text, _objectSpread(_objectSpread({}, emailInputModalDescriptionStyle), {}, {
        children: modalContent.description
      })), (0, _jsxRuntime.jsx)(_react.Text, _objectSpread(_objectSpread({}, emailLabelStyle), {}, {
        children: modalContent.emailLabel
      })), (0, _jsxRuntime.jsx)(_react.Box, _objectSpread(_objectSpread({}, inputStyles), {}, {
        "data-testid": (0, _utils.formatDataTestId)(baseDataTestId, 'Email'),
        children: (0, _jsxRuntime.jsx)(_atoms.Input, {
          type: "text",
          placeholderText: modalContent.emailPlaceholder,
          name: "emailAddress",
          onBlur: checkIfValid,
          useTooltip: true,
          extraTooltipStyles: extraTooltipStyles,
          error: emailInputError,
          value: email,
          onChange: handleEmailChange,
          isDisabled: isEmailTriggeringSuccess,
          label: modalContent.inputLabel
        })
      })), isCustomerConsentCheckboxDisplayed && (0, _jsxRuntime.jsx)(_react.Box, {
        "data-testid": (0, _utils.formatDataTestId)(baseDataTestId, 'CustomerConsentCheckbox'),
        mt: emailInputError ? '74px' : 'md',
        children: (0, _jsxRuntime.jsx)(_atoms.Checkbox, {
          onChange: e => {
            handleCustomerConsentChange(e.target.checked);
          },
          isChecked: customerConsent,
          isDisabled: isEmailTriggeringSuccess,
          children: (0, _jsxRuntime.jsx)(_react.Text, _objectSpread(_objectSpread({}, customerConsentLabelStyle), {}, {
            children: modalContent.customerConsent
          }))
        })
      }), (0, _jsxRuntime.jsx)(_atoms.Button, _objectSpread(_objectSpread({}, confirmationButtonStyle), {}, {
        isDisabled: isButtonDisabled(),
        "data-testid": (0, _utils.formatDataTestId)(baseDataTestId, 'ModalButton'),
        onClick: handleOnSubmit,
        variant: "primary",
        children: modalContent.submitBtn
      }))]
    });
  }
  function isButtonDisabled() {
    var shouldDisableBecauseOfCustomerConsent = hasCustomerContent && !customerConsent;
    var shouldDisableBecauseOfEmailTriggeringSuccess = hasCustomerContent && customerConsent && isEmailTriggeringSuccess;
    return !(0, _utils.isEmailValid)(email) || isLoading || shouldDisableBecauseOfCustomerConsent || shouldDisableBecauseOfEmailTriggeringSuccess;
  }
  function renderEmailTriggeringNotification() {
    if (isEmailTriggeringSuccess) {
      return (0, _jsxRuntime.jsx)(_react.Box, {
        mb: "lg",
        "data-testid": (0, _utils.formatDataTestId)(baseDataTestId, 'SuccessNotification'),
        children: (0, _jsxRuntime.jsx)(_atoms.Notification, {
          maxWidth: "full",
          variant: "success",
          status: "success",
          description: (0, _jsxRuntime.jsx)(_react.Text, _objectSpread(_objectSpread({
            as: "span"
          }, successMessageNotificationStyle), {}, {
            children: modalContent.successNotif
          })),
          svg: (0, _jsxRuntime.jsx)(_atoms.Tick24, {})
        })
      });
    } else if (isEmailTriggeringError && modalContent.errorNotif) {
      return (0, _jsxRuntime.jsx)(_react.Box, {
        mb: "lg",
        "data-testid": (0, _utils.formatDataTestId)(baseDataTestId, 'ErrorNotification'),
        children: (0, _jsxRuntime.jsx)(_atoms.Notification, {
          maxWidth: "full",
          variant: "error",
          status: "error",
          description: modalContent.errorNotif,
          svg: (0, _jsxRuntime.jsx)(_atoms.Info, {
            color: "var(--chakra-colors-error)"
          })
        })
      });
    }
  }
}
var confirmationButtonStyle = {
  variant: 'secondary',
  w: '100%',
  mt: 'md'
};
var successMessageNotificationStyle = {
  fontSize: 'sm',
  lineHeight: '2',
  color: 'darkGrey1',
  fontFamily: 'body'
};
var customerConsentLabelStyle = {
  fontSize: 'sm',
  lineHeight: '2',
  color: 'darkGrey1',
  fontWeight: 'normal'
};
var emailLabelStyle = {
  fontSize: 'lg',
  lineHeight: '2',
  color: 'darkGrey1',
  fontWeight: 'semibold',
  mt: 'lg',
  mb: 'md'
};
var modalStyles = {
  w: '36rem',
  px: 'lg',
  pb: 'lg'
};
var titleStyle = {
  mt: 'md',
  color: 'darkGrey1',
  fontSize: '3xl',
  lineHeight: '4',
  fontWeight: 'semibold'
};
var emailInputModalDescriptionStyle = {
  mt: 'sm',
  color: 'darkGrey2',
  fontSize: 'sm',
  lineHeight: '2',
  fontWeight: 'normal'
};
var extraTooltipStyles = {
  width: 'full'
};
var inputStyles = {
  w: '420px'
};
var dividerStyle = {
  mb: 'lg',
  borderColor: 'lightGrey4'
};
var modalHeaderStyles = {
  padding: '0px'
};
var modalHeaderContentStyles = {
  mx: 'lg',
  mt: 'lg',
  mb: 'sm'
};