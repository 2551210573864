"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = HotelRestaurantQueryWrapper;
var _reactQuery = require("@tanstack/react-query");
var _HotelRestaurant = _interopRequireDefault(require("./HotelRestaurant.container"));
var _jsxRuntime = require("react/jsx-runtime");
function HotelRestaurantQueryWrapper(_ref) {
  var {
    queryClient
  } = _ref;
  return (0, _jsxRuntime.jsx)(_reactQuery.QueryClientProvider, {
    client: queryClient,
    children: (0, _jsxRuntime.jsx)(_HotelRestaurant.default, {})
  });
}