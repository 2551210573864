"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.DEFAULT_RESET_PASSWORD_LABELS = exports.DEFAULT_LOGIN_LABELS = void 0;
var DEFAULT_LOGIN_LABELS = exports.DEFAULT_LOGIN_LABELS = Object.freeze({
  login: {
    passwordPlaceholder: '',
    badCredentialsError: '',
    invalidEmail: '',
    forgotPassword: '',
    signupMessage: '',
    signupLink: '',
    leisure: {
      loginButton: '',
      formLabel: '',
      emailPlaceholder: ''
    },
    business: {
      bookingsInvalidEmailMsg: '',
      bookingLoginRequiredText: '',
      bookingEmailMaxLengthMsg: '',
      companyActivateFailTitle: '',
      signupButton: '',
      companyActivateFailBody: '',
      tab: '',
      doubleOptInSuccessMessage: '',
      companyActivateSuccessBody: '',
      companyActivateSuccessTitle: '',
      employeeActivationSuccessTitle: '',
      loginInfoNotification: '',
      tabMobile: '',
      doubleOptInFailMessage: '',
      loginButton: '',
      formLabel: '',
      emailPlaceholder: ''
    },
    businessAccountCard: {
      banner: {
        imagePath: ''
      },
      buttonLabel: '',
      tab: '',
      tabMobile: '',
      textBody: '',
      title: ''
    }
  },
  forgottenPassword: {
    backToLogin: '',
    backToYourDetails: '',
    genericError: '',
    leisure: {
      formLabel: '',
      emailPlaceholder: '',
      formTitle: '',
      submitButton: ''
    },
    business: {
      formLabel: '',
      emailPlaceholder: '',
      formTitle: '',
      submitButton: ''
    },
    cancel: '',
    emailSentHeader: '',
    emailSentMessage: ''
  },
  goBackButton: '',
  businessAccountCardRedirectPath: ''
});
var DEFAULT_RESET_PASSWORD_LABELS = exports.DEFAULT_RESET_PASSWORD_LABELS = Object.freeze({
  resetPassword: {
    criteria: '',
    criteriaDescription: '',
    email: '',
    invalidPassword: '',
    invalidPasswordConfirmation: '',
    logInButton: '',
    password: '',
    passwordConfirmation: '',
    passwordMin: '',
    passwordRequired: '',
    passwordRequirementsAllowed: '',
    passwordRequirementsIdentical: '',
    passwordRequirementsMin: '',
    resetPasswordTitle: '',
    submitButton: '',
    successMessage: ''
  },
  login: {
    business: {
      bookingsInvalidEmailMsg: '',
      bookingLoginRequiredText: '',
      bookingEmailMaxLengthMsg: ''
    },
    invalidEmail: ''
  }
});