"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.serverSideCookieOptions = exports.SILENT_SUBSTITUTION_STORAGE_KEY = exports.BASKET_DETAILS_STORAGE_KEY = exports.BASKET_DETAILS_STATE_INITIAL_VALUE = exports.BASIC_BASKET_DETAILS_INITIAL_VALUE = void 0;
var _config = _interopRequireDefault(require("next/config"));
var _enums = require("../enums");
var {
  publicRuntimeConfig = {}
} = (0, _config.default)() || {};
var serverSideCookieOptions = exports.serverSideCookieOptions = {
  httpOnly: false,
  domain: publicRuntimeConfig.NEXT_PUBLIC_COOKIES_DOMAIN,
  maxAge: 30 * 60 * 1000
};
var BASKET_DETAILS_STORAGE_KEY = exports.BASKET_DETAILS_STORAGE_KEY = 'BasketDetailsState';
var BASIC_BASKET_DETAILS_INITIAL_VALUE = exports.BASIC_BASKET_DETAILS_INITIAL_VALUE = {
  hotelId: '',
  nightsNumber: 0,
  adultsNumber: 0,
  childrenNumber: 0,
  endDate: '',
  startDate: '',
  rateCode: '',
  reservationId: '',
  bookingFlowId: ''
};
var BASKET_DETAILS_STATE_INITIAL_VALUE = exports.BASKET_DETAILS_STATE_INITIAL_VALUE = {
  hotelId: '',
  arrival: '',
  departure: '',
  numberOfUnits: 0,
  numberOfNights: 0,
  selectedRate: {
    ratePlanCode: '',
    roomTypes: [],
    rateCategory: '',
    cellCode: ''
  },
  roomClass: _enums.RoomClass.ST,
  rateName: '',
  roomTypeInformationResponse: {
    isLoadingRoomTypeInformation: false,
    dataRoomTypeInformation: {
      roomTypeInformation: {
        roomTypes: []
      }
    },
    isErrorRoomTypeInformation: false,
    errorRoomTypeInformation: null
  },
  bookingFlow: {
    bookingFlowItems: []
  },
  phoneNumber: '',
  brand: 'PI',
  silentSubstitutionLabels: []
};
var SILENT_SUBSTITUTION_STORAGE_KEY = exports.SILENT_SUBSTITUTION_STORAGE_KEY = 'SilentSubstitutionRoomLabels';