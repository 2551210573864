"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.INITIAL_GUEST_DETAILS_FORM_DATA = void 0;
var INITIAL_GUEST_DETAILS_FORM_DATA = exports.INITIAL_GUEST_DETAILS_FORM_DATA = {
  reasonForStay: '',
  title: '',
  firstName: '',
  lastName: '',
  email: '',
  phone: '',
  landline: '',
  companyName: '',
  addressLine1: '',
  addressLine2: '',
  addressLine3: '',
  addressLine4: '',
  postalCode: '',
  cityName: '',
  postcodeAddress: '',
  addressSelection: '',
  countryCode: '',
  manualAddressToggle: '',
  basketReferenceId: '',
  leadGuest: [],
  bookingForSomeoneElse: false,
  billing: {
    address: {
      countryCode: '',
      companyName: '',
      addressLine1: '',
      addressLine2: '',
      addressLine3: '',
      addressLine4: '',
      postalCode: '',
      addressType: ''
    },
    title: '',
    firstName: '',
    lastName: '',
    email: '',
    telephone: '',
    differentBillingAddress: false
  },
  whoBookerIsTabs: 'MYSELF',
  dateOfBirth: '',
  nationality: '',
  passport: ''
};