"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = Accordion;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _react = require("@chakra-ui/react");
var _jsxRuntime = require("react/jsx-runtime");
var _react2 = require("react");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function Accordion(props) {
  var {
    accordionItems,
    allowMultiple = true,
    bgColor = 'lightGrey5',
    accordionOverwriteStyles,
    allowToggle
  } = props;
  var textItemStyle = _objectSpread(_objectSpread({}, accordionTitleStyle), accordionOverwriteStyles === null || accordionOverwriteStyles === void 0 ? void 0 : accordionOverwriteStyles.text);
  var buttonStyle = _objectSpread(_objectSpread({}, getAccordionButtonStyle(bgColor)), accordionOverwriteStyles === null || accordionOverwriteStyles === void 0 ? void 0 : accordionOverwriteStyles.button);
  var panelStyle = _objectSpread(_objectSpread({}, accordionPanelStyle), accordionOverwriteStyles === null || accordionOverwriteStyles === void 0 ? void 0 : accordionOverwriteStyles.panel);
  var itemStyle = _objectSpread(_objectSpread({}, accordionItemStyle), accordionOverwriteStyles === null || accordionOverwriteStyles === void 0 ? void 0 : accordionOverwriteStyles.item);
  var iconStyle = _objectSpread(_objectSpread({}, accordionIconStyle), accordionOverwriteStyles === null || accordionOverwriteStyles === void 0 ? void 0 : accordionOverwriteStyles.icon);
  return (0, _jsxRuntime.jsx)(_react.Accordion, _objectSpread(_objectSpread({
    allowMultiple: allowMultiple,
    reduceMotion: true,
    allowToggle: allowToggle
  }, accordionOverwriteStyles === null || accordionOverwriteStyles === void 0 ? void 0 : accordionOverwriteStyles.container), {}, {
    children: accordionItems.map((item, index) => {
      var _item$title;
      var itemTitle = item === null || item === void 0 || (_item$title = item.title) === null || _item$title === void 0 ? void 0 : _item$title.toString().split(' ').join('_');
      return (0, _react2.createElement)(_react.AccordionItem, _objectSpread(_objectSpread({}, itemStyle), {}, {
        key: itemTitle,
        id: "heading-".concat(index)
      }), (0, _jsxRuntime.jsxs)(_react.AccordionButton, _objectSpread(_objectSpread({}, buttonStyle), {}, {
        "data-testid": "Button-".concat(itemTitle),
        onClick: item.onToggleSection,
        children: [(0, _jsxRuntime.jsx)(_react.Flex, _objectSpread(_objectSpread({}, textItemStyle), {}, {
          children: item.title
        })), (0, _jsxRuntime.jsx)(_react.AccordionIcon, _objectSpread({}, iconStyle))]
      })), (0, _jsxRuntime.jsx)(_react.AccordionPanel, _objectSpread(_objectSpread({}, panelStyle), {}, {
        bgColor: bgColor,
        children: item.content
      })));
    })
  }));
}
var accordionItemStyle = {
  cursor: 'pointer',
  fontSize: 'md',
  lineHeight: '3',
  borderBottom: '1px solid var(--chakra-colors-lightGrey4)',
  _hover: {
    bgColor: 'baseWhite'
  }
};
var accordionTitleStyle = {
  textAlign: 'left',
  color: 'darkGrey1',
  fontWeight: 600,
  flex: 1
};
var getAccordionButtonStyle = bgColor => ({
  p: 'var(--chakra-space-md) 0 var(--chakra-space-md) var(--chakra-space-sm)',
  bgColor: 'baseWhite',
  _focus: {
    boxShadow: 'none'
  },
  _expanded: {
    bgColor: bgColor
  },
  _hover: {
    bgColor: 'baseWhite'
  }
});
var accordionPanelStyle = {
  color: 'darkGrey1',
  p: '0 var(--chakra-space-3xl) var(--chakra-space-md) var(--chakra-space-sm)',
  pb: 'var(--chakra-space-md)',
  lineHeight: '3'
};
var accordionIconStyle = {
  color: 'darkGrey2',
  fontSize: '1.875rem'
};