"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.setBasketDetailsCookie = exports.setBasketCookie = void 0;
var _config = _interopRequireDefault(require("next/config"));
var _getters = require("../getters");
var _validators = require("../validators");
var _base = require("./base64");
var setBasketDetailsCookie = exports.setBasketDetailsCookie = function setBasketDetailsCookie(basket, reservationId) {
  var isBB = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
  if (reservationId && (0, _validators.isStringValid)(reservationId)) {
    var _basket$bookingFlow$b, _basket$bookingFlow;
    var bookingFlowId = (_basket$bookingFlow$b = basket === null || basket === void 0 || (_basket$bookingFlow = basket.bookingFlow) === null || _basket$bookingFlow === void 0 || (_basket$bookingFlow = _basket$bookingFlow.bookingFlowItems) === null || _basket$bookingFlow === void 0 || (_basket$bookingFlow = _basket$bookingFlow.find(bfi => {
      var _basket$selectedRate;
      return (bfi === null || bfi === void 0 ? void 0 : bfi.rateCategory) === (basket === null || basket === void 0 || (_basket$selectedRate = basket.selectedRate) === null || _basket$selectedRate === void 0 ? void 0 : _basket$selectedRate.rateCategory);
    })) === null || _basket$bookingFlow === void 0 ? void 0 : _basket$bookingFlow.bookingId) !== null && _basket$bookingFlow$b !== void 0 ? _basket$bookingFlow$b : '';
    if (isBB) {
      bookingFlowId = 'booking-business';
    }
    var basketToBeSet = {
      hotelId: basket.hotelId,
      startDate: basket.arrival,
      endDate: basket.departure,
      nightsNumber: (0, _getters.getNightsNumber)(basket.arrival, basket.departure),
      rateCode: basket.selectedRate.ratePlanCode,
      adultsNumber: (0, _getters.getMaxValueFromHDPRoomTypes)(basket.selectedRate.roomTypes, 'adults'),
      childrenNumber: (0, _getters.getMaxValueFromHDPRoomTypes)(basket.selectedRate.roomTypes, 'children'),
      reservationId: reservationId,
      bookingFlowId: bookingFlowId
    };
    setBasketCookie(reservationId, basketToBeSet);
  }
};
var setBasketCookie = (reservationId, basket) => {
  var _getConfig;
  var {
    publicRuntimeConfig = {}
  } = (_getConfig = (0, _config.default)()) !== null && _getConfig !== void 0 ? _getConfig : {};
  var d = new Date();
  d.setTime(d.getTime() + 30 * 60 * 1000);
  var expires = 'expires='.concat(d.toUTCString());
  var value = (0, _base.encodeToBase64)(JSON.stringify(basket));
  var path = 'path=/';
  var domain = 'domain='.concat(publicRuntimeConfig.NEXT_PUBLIC_COOKIES_DOMAIN);
  document.cookie = "".concat(reservationId, "=").concat(value, ";").concat(expires, ";").concat(path, ";").concat(domain);
};
exports.setBasketCookie = setBasketCookie;