"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.EckohStatus = void 0;
var EckohStatus = exports.EckohStatus = function (EckohStatus) {
  EckohStatus["FAILED"] = "FAILED";
  EckohStatus["SUCCESS"] = "SUCCESS";
  EckohStatus["PENDING"] = "PENDING";
  EckohStatus["NOT_FOUND"] = "NOT_FOUND";
  return EckohStatus;
}({});