"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.BB_PATHNAME_PREFIX = exports.ANALYTICS_PAGE_MAPPER = void 0;
var BB_PATHNAME_PREFIX = exports.BB_PATHNAME_PREFIX = /\/business-booker|\/booking-business/g;
var ANALYTICS_PAGE_MAPPER = exports.ANALYTICS_PAGE_MAPPER = {
  '/': {
    pageName: 'Premier Inn',
    pageType: 'look to book'
  },
  '/search': {
    pageName: 'premier inn search results',
    pageType: 'look to book'
  },
  '/hotels/[...slug]': {
    pageName: 'premier inn hotel details',
    pageType: 'look to book'
  },
  '/hotels/choose-bathroom': {
    pageName: 'premier inn hotel details',
    pageType: 'look to book'
  },
  '/hotels/choose-twinroom': {
    pageName: 'premier inn choose twin room',
    pageType: 'look to book'
  },
  '/account/dashboard': {
    pageName: 'premier inn dashboard bookings',
    pageType: 'look to book'
  },
  '/bookings': {
    pageName: 'premier inn dashboard bookings',
    pageType: 'look to book'
  },
  '/amend/details': {
    pageName: 'amend details',
    pageType: 'amend details'
  },
  '/amend/booking-confirmation': {
    pageName: 'amend confirmation',
    pageType: 'amend confirmation'
  },
  '/amend/payment': {
    pageName: 'amend payment',
    pageType: 'amend payment'
  },
  '/ancillaries': {
    pageName: 'extras',
    pageType: 'booking flow'
  },
  '/guest-details': {
    pageName: 'yourdetails',
    pageType: 'booking flow'
  },
  '/payment': {
    pageName: 'payment',
    pageType: 'booking flow'
  },
  '/confirmation': {
    pageName: 'confirmation',
    pageType: 'booking flow'
  },
  '/group-bookings': {
    pageName: 'Premier Inn: Group Booking Form Request',
    pageType: 'look to book'
  },
  '/account/register': {
    pageName: 'Premier Inn: Create an Account',
    pageType: 'look to book'
  }
};