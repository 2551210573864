"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.AMEND_STAY_DATES = void 0;
var _taggedTemplateLiteral2 = _interopRequireDefault(require("@babel/runtime/helpers/taggedTemplateLiteral"));
var _graphqlRequest = require("graphql-request");
var _templateObject;
var AMEND_STAY_DATES = exports.AMEND_STAY_DATES = (0, _graphqlRequest.gql)(_templateObject || (_templateObject = (0, _taggedTemplateLiteral2.default)(["\n  mutation AmendStayDates(\n    $tempBookingRef: String!\n    $newStartDate: String!\n    $newEndDate: String!\n    $channel: Channel!\n    $subchannel: String!\n    $token: String!\n    $language: String\n  ) {\n    changeBookingDates(\n      amendStayDatesCriteria: {\n        tempBookingRef: $tempBookingRef\n        newStartDate: $newStartDate\n        newEndDate: $newEndDate\n        bookingChannel: { channel: $channel, subchannel: $subchannel, language: $language }\n        token: $token\n      }\n    ) {\n      tempBasket\n    }\n  }\n"])));