"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.headerWrapperStyles = exports.contentStyles = exports.containerWrapperStyles = exports.containerLogoStyle = void 0;
var contentStyles = exports.contentStyles = {
  w: 'var(--chakra-sizes-full)',
  templateColumns: 'auto 1fr',
  alignItems: 'center'
};
var containerWrapperStyles = exports.containerWrapperStyles = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  maxW: 'var(--chakra-space-breakpoint-xl)',
  h: 'var(--chakra-sizes-full)',
  pl: {
    mobile: 'md',
    xs: '5',
    sm: 'md',
    md: 'lg',
    lg: '7',
    xl: '4.125rem'
  },
  pr: {
    mobile: 'md',
    md: '1.688rem',
    xl: '5xl'
  }
};
var containerLogoStyle = exports.containerLogoStyle = {
  justifyContent: 'center',
  alignItems: 'center'
};
var headerWrapperStyles = exports.headerWrapperStyles = {
  w: 'var(--chakra-sizes-full)',
  backgroundColor: 'var(--chakra-colors-baseWhite)',
  boxShadow: '0 0.125rem 0.75rem var(--chakra-colors-lightGrey2)'
};