"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = RoomSuccessNotification;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _atoms = require("@whitbread-eos/atoms");
var _jsxRuntime = require("react/jsx-runtime");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function RoomSuccessNotification(_ref) {
  var {
    description,
    dataTestId,
    styles
  } = _ref;
  var wrapperStyles = _objectSpread({
    width: 'auto',
    mt: '2xl',
    mb: 0,
    mx: 'var(--chakra-space-lg)'
  }, styles);
  return (0, _jsxRuntime.jsx)(_atoms.Notification, {
    variant: "success",
    status: "success",
    description: description,
    prefixDataTestId: dataTestId,
    svg: (0, _jsxRuntime.jsx)(_atoms.Success, {}),
    wrapperStyles: wrapperStyles
  });
}