"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = InfiniteScroller;
var _react = _interopRequireDefault(require("react"));
var _reactInfiniteScrollComponent = _interopRequireDefault(require("react-infinite-scroll-component"));
var _jsxRuntime = require("react/jsx-runtime");
function InfiniteScroller(_ref) {
  var {
    children,
    loader,
    dataLength,
    hasMore,
    next,
    scrollThreshold,
    style,
    endMessage
  } = _ref;
  return (0, _jsxRuntime.jsx)(_reactInfiniteScrollComponent.default, {
    loader: loader,
    dataLength: dataLength,
    hasMore: hasMore,
    next: next,
    scrollThreshold: scrollThreshold,
    style: style,
    endMessage: endMessage,
    children: children
  });
}