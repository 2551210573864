"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = FooterComponent;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _react = require("@chakra-ui/react");
var _atoms = require("@whitbread-eos/atoms");
var _utils = require("@whitbread-eos/utils");
var _react2 = require("react");
var _jsxRuntime = require("react/jsx-runtime");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function FooterComponent(_ref) {
  var {
    copyrightData,
    socialIcons,
    tabsInfo,
    isPI,
    language,
    baseDataTestId
  } = _ref;
  var footerWrapperStyles = () => {
    return {
      w: 'full',
      borderTop: '1px solid var(--chakra-colors-lightGrey1)',
      pt: language === 'en' ? {
        mobile: '0',
        md: 'lg',
        lg: isPI ? '3xl' : 'lg',
        xl: '3xl'
      } : {
        mobile: '0',
        md: '2xl',
        lg: isPI ? '3xl' : 'lg',
        xl: '3xl'
      },
      px: language === 'en' ? {
        mobile: 'md',
        md: 'md',
        lg: 'xl',
        xl: '5xl'
      } : {
        mobile: 'md',
        md: 'xl',
        lg: 'xl',
        xl: '5xl'
      },
      pb: isPI ? '0' : {
        lg: 'lg',
        xl: '3xl'
      }
    };
  };
  var acordionStyles = () => ({
    '.chakra-accordion__item': {
      padding: 'md'
    }
  });
  var tabsStyles = () => ({
    '.chakra-tabs__tablist': {
      display: isPI ? 'flex' : 'none'
    },
    '.chakra-tabs__tab-panel': {
      padding: 0
    },
    '.chakra-tabs__tab': (tabsInfo === null || tabsInfo === void 0 ? void 0 : tabsInfo.length) === 1 ? {
      display: 'none'
    } : {
      color: 'darkGrey1',
      width: 'full',
      borderBottom: '2px solid var(--chakra-colors-lightGrey4)',
      py: 'md'
    }
  });
  var columnWrapperStyles = () => {
    return isPI ? {
      marginTop: language === 'en' ? {
        mobile: 'md',
        md: 'lg'
      } : '0'
    } : {
      marginTop: {
        mobile: '2xl',
        md: 'lg',
        lg: '0'
      }
    };
  };
  var renderColumnItems = column => {
    return column.linkItems.map((item, index) => {
      return (0, _react2.createElement)(_react.Link, _objectSpread(_objectSpread({}, linkItemStyles), {}, {
        mt: index !== 0 ? {
          mobile: 'sm',
          md: 'xs'
        } : '0',
        key: item.name,
        href: item.linkSrc,
        isExternal: isPI ? item.openInNewTab : true,
        "data-testid": (0, _utils.formatDataTestId)(baseDataTestId, 'LinkItem')
      }), item.name);
    });
  };
  var renderGermanTabContent = tab => {
    var showSectionTitle = tab.columns.filter(column => column.name.length > 0).length === 0;
    return (0, _jsxRuntime.jsx)(_react.Box, {
      w: "full",
      children: !showSectionTitle ? (0, _jsxRuntime.jsx)(_react.Box, {
        "data-testid": (0, _utils.formatDataTestId)(baseDataTestId, 'MultipleTitlesTabContent'),
        children: (0, _jsxRuntime.jsx)(_react.Grid, _objectSpread(_objectSpread({}, gridWrapperStyles), {}, {
          children: tab.columns.map(column => {
            return (0, _react2.createElement)(_react.Box, _objectSpread(_objectSpread({}, columnWrapperStyles()), {}, {
              mb: isPI ? '5xl' : '0',
              key: column.name
            }), column.name && (0, _jsxRuntime.jsx)(_react.Text, _objectSpread(_objectSpread({}, columnTitleStyles), {}, {
              children: column.name
            })), (0, _jsxRuntime.jsx)(_react.Flex, _objectSpread(_objectSpread({}, columnInnerWrapperStyles), {}, {
              children: renderColumnItems(column)
            })));
          })
        }))
      }) : (0, _jsxRuntime.jsxs)(_react.Box, {
        mb: {
          md: '2xl',
          lg: '6xl'
        },
        "data-testid": (0, _utils.formatDataTestId)(baseDataTestId, 'SingleTitleTabContent'),
        children: [(0, _jsxRuntime.jsx)(_react.Text, _objectSpread(_objectSpread({}, columnTitleStyles), {}, {
          children: tab.name
        })), (0, _jsxRuntime.jsx)(_react.SimpleGrid, {
          columns: {
            md: 3,
            lg: 6
          },
          spacing: "lg",
          children: tab.columns.map(column => {
            return (0, _react2.createElement)(_react.Flex, _objectSpread(_objectSpread({}, columnInnerWrapperStyles), {}, {
              key: column.name
            }), renderColumnItems(column));
          })
        })]
      })
    });
  };
  var renderTabContent = tab => {
    var _tab$intro;
    return (0, _jsxRuntime.jsxs)(_react.Box, {
      w: "full",
      mb: {
        md: 'md',
        lg: isPI ? '3xl' : '0'
      },
      "data-testid": (0, _utils.formatDataTestId)(baseDataTestId, 'TabContent'),
      children: [isPI && ((_tab$intro = tab.intro) === null || _tab$intro === void 0 ? void 0 : _tab$intro.description) && (0, _jsxRuntime.jsx)(_react.Text, _objectSpread(_objectSpread({}, tabDescriptionStyles), {}, {
        className: "formatLinks",
        children: (0, _utils.renderSanitizedHtml)(tab.intro.description)
      })), (0, _jsxRuntime.jsx)(_react.Grid, _objectSpread(_objectSpread({
        w: "full"
      }, gridWrapperStyles), {}, {
        children: tab.columns.length > 0 && tab.columns.map(column => {
          var _column$linkItems;
          return (0, _react2.createElement)(_react.Box, _objectSpread(_objectSpread({}, columnWrapperStyles()), {}, {
            key: column.name || ((_column$linkItems = column.linkItems) === null || _column$linkItems === void 0 || (_column$linkItems = _column$linkItems[0]) === null || _column$linkItems === void 0 ? void 0 : _column$linkItems.name) || 'no-name'
          }), (0, _jsxRuntime.jsx)(_react.Text, _objectSpread(_objectSpread({}, columnTitleStyles), {}, {
            children: column.name
          })), column.linkItems.length > 0 && (0, _jsxRuntime.jsx)(_react.Flex, _objectSpread(_objectSpread({}, columnInnerWrapperStyles), {}, {
            children: renderColumnItems(column)
          })));
        })
      }))]
    });
  };
  var renderCopyrightAndSocialSection = () => {
    return (0, _jsxRuntime.jsxs)(_react.Flex, _objectSpread(_objectSpread({
      "data-testid": (0, _utils.formatDataTestId)(baseDataTestId, 'SocialSection')
    }, socialSectionStyles), {}, {
      children: [copyrightData && (0, _jsxRuntime.jsx)(_react.Text, _objectSpread(_objectSpread({}, copyrightStyles), {}, {
        className: "formatLinks",
        children: (0, _utils.renderSanitizedHtml)(copyrightData)
      })), socialIcons.length > 0 && (0, _jsxRuntime.jsx)(_react.Flex, {
        flexDir: "row",
        children: socialIcons.map(icon => {
          return (0, _jsxRuntime.jsx)(_react.Link, {
            pl: "md",
            href: icon.linkSrc,
            isExternal: true,
            children: (0, _jsxRuntime.jsx)(_atoms.Icon, {
              src: (0, _utils.formatAssetsUrl)(icon.iconSrc)
            })
          }, icon.iconSrc);
        })
      })]
    }));
  };
  function getAcordionItems() {
    var formattedItems = [];
    if (language === 'en') {
      formattedItems = tabsInfo === null || tabsInfo === void 0 ? void 0 : tabsInfo.map(tab => ({
        title: tab.name,
        content: renderTabContent(tab)
      }));
    } else {
      tabsInfo.forEach(tab => {
        if (tab.columns.filter(column => column.name.length > 0).length === 0) {
          formattedItems = [...formattedItems, {
            title: tab.name,
            content: tab.columns.map((column, index) => (0, _jsxRuntime.jsx)(_react.Flex, {
              direction: "column",
              pt: {
                mobile: index === 0 ? '0' : 'md',
                lg: 'sm'
              },
              "data-testid": (0, _utils.formatDataTestId)(baseDataTestId, 'AcordionItem'),
              children: renderColumnItems(column)
            }, column.name))
          }];
        } else {
          tab.columns.forEach(column => {
            formattedItems.push({
              title: column.name,
              content: (0, _jsxRuntime.jsx)(_react.Flex, {
                direction: "column",
                pt: {
                  mobile: '0',
                  lg: 'sm'
                },
                "data-testid": (0, _utils.formatDataTestId)(baseDataTestId, 'AcordionItem'),
                children: renderColumnItems(column)
              }, column.name)
            });
          });
        }
      });
    }
    return formattedItems;
  }
  return (0, _jsxRuntime.jsxs)(_react.Box, _objectSpread(_objectSpread({
    maxW: "var(--chakra-space-breakpoint-xl)"
  }, footerWrapperStyles()), {}, {
    "data-testid": (0, _utils.formatDataTestId)(baseDataTestId, 'Wrapper'),
    children: [(0, _jsxRuntime.jsx)(_react.Show, {
      above: "md",
      children: language === 'de' ? tabsInfo.map(tab => (0, _jsxRuntime.jsx)(_react.Box, {
        children: renderGermanTabContent(tab)
      }, tab.name)) : (0, _jsxRuntime.jsx)(_atoms.Tabs, {
        sx: _objectSpread({}, tabsStyles()),
        variant: "sm",
        options: tabsInfo.map((tab, index) => ({
          index,
          label: tab.name,
          content: renderTabContent(tab)
        }))
      })
    }), (0, _jsxRuntime.jsx)(_react.Hide, {
      above: "md",
      children: (0, _jsxRuntime.jsx)(_atoms.Accordion, {
        sx: _objectSpread({}, acordionStyles()),
        accordionItems: getAcordionItems(),
        bgColor: "baseWhite"
      })
    }), isPI && renderCopyrightAndSocialSection()]
  }));
}
var copyrightStyles = {
  fontSize: 'sm',
  fontWeight: 'normal',
  lineHeight: 1,
  color: 'darkGrey1',
  ml: 0
};
var socialSectionStyles = {
  justifyContent: 'space-between',
  alignItems: 'center',
  w: 'full',
  p: {
    mobile: 'md',
    md: 'lg'
  },
  borderTop: '1px solid var(--chakra-colors-lightGrey1)'
};
var tabDescriptionStyles = {
  pt: 'lg',
  pb: {
    mobile: 'sm',
    lg: 0
  },
  fontSize: 'sm',
  fontWeight: 'normal',
  lineHeight: 2,
  color: 'darkGrey1',
  pr: {
    mobile: 0,
    sm: '2xl',
    md: 0
  }
};
var columnTitleStyles = {
  fontSize: 'md',
  fontWeight: 'semibold',
  lineHeight: 3,
  color: 'darkGrey1'
};
var columnInnerWrapperStyles = {
  direction: 'column',
  pt: {
    mobile: 'md',
    md: 'sm'
  }
};
var linkItemStyles = {
  maxW: '11rem',
  fontSize: 'sm',
  fontWeight: 'normal',
  lineHeight: 2,
  color: 'darkGrey1'
};
var gridWrapperStyles = {
  justifyContent: 'space-between',
  gridTemplateColumns: {
    mobile: '6fr',
    md: 'repeat(3, 2fr)',
    lg: 'repeat(6, 1fr)'
  }
};