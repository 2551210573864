"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.GET_DASHBOARD_DONATIONS_PACKAGES = void 0;
var _taggedTemplateLiteral2 = _interopRequireDefault(require("@babel/runtime/helpers/taggedTemplateLiteral"));
var _graphqlRequest = require("graphql-request");
var _templateObject;
var GET_DASHBOARD_DONATIONS_PACKAGES = exports.GET_DASHBOARD_DONATIONS_PACKAGES = (0, _graphqlRequest.gql)(_templateObject || (_templateObject = (0, _taggedTemplateLiteral2.default)(["\n  query GetDashboardPackages(\n    $country: String!\n    $language: String!\n    $hotelId: String!\n    $rateCode: String!\n    $bookingChannel: String\n  ) {\n    donations(\n      bookingFlowCriteria: {\n        country: $country\n        language: $language\n        hotelId: $hotelId\n        rateCode: $rateCode\n        bookingChannel: $bookingChannel\n      }\n    ) {\n      donationPackages {\n        code\n        currency\n        unitPrice\n      }\n    }\n  }\n"])));