"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = BookingsHistory;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));
var _react = require("@chakra-ui/react");
var _api = require("@whitbread-eos/api");
var _atoms = require("@whitbread-eos/atoms");
var _molecules = require("@whitbread-eos/molecules");
var _utils = require("@whitbread-eos/utils");
var _nextI18next = require("next-i18next");
var _react2 = require("react");
var _BookingInfoCard = require("../BookingInfoCard");
var _jsxRuntime = require("react/jsx-runtime");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
var BASE_TESTID = 'MyDashboard';
function BookingsHistory(_ref) {
  var _bookingsData$booking6, _bookingsData$booking7, _bookingsData$totals$, _bookingsData$totals5, _bookingsData$totals$2, _bookingsData$totals6, _bookingsData$totals$3, _bookingsData$totals7, _bookingsData$totals$4, _bookingsData$totals8;
  var {
    tableConfig,
    screenSize,
    pageSize,
    onFetch,
    bookingChannel,
    area = _api.Area.PI,
    rowStyles
  } = _ref;
  var {
    t
  } = (0, _nextI18next.useTranslation)();
  var {
    country,
    language
  } = (0, _utils.useCustomLocale)();
  var [isLoading, setIsLoading] = (0, _react2.useState)(false);
  var [isError, setIsError] = (0, _react2.useState)(false);
  var [bookingsData, setBookingsData] = (0, _react2.useState)(null);
  var [bookingHasBeenCanceled, setBookingHasBeenCanceled] = (0, _react2.useState)(false);
  var [params, setParams] = (0, _react2.useState)({
    pageIndex: 1,
    filterType: '',
    filterValue: '',
    continuationToken: undefined
  });
  var [continuationToken, setContinuationToken] = (0, _react2.useState)(undefined);
  var [invoiceItemsSent, setInvoiceItemsSent] = (0, _react2.useState)([]);
  var getBookingHistory = function () {
    var _ref2 = (0, _asyncToGenerator2.default)(function* () {
      setIsLoading(true);
      try {
        var data = yield onFetch(params);
        if (data) {
          var _bookingsData$booking, _data$bookingHistory, _data$bookingHistory2, _data$bookingHistory3, _data$bookingHistory$, _data$bookingHistory4;
          var currentBookings = params !== null && params !== void 0 && params.pageIndex && (params === null || params === void 0 ? void 0 : params.pageIndex) > 1 && bookingsData !== null && bookingsData !== void 0 && (_bookingsData$booking = bookingsData.bookings) !== null && _bookingsData$booking !== void 0 && _bookingsData$booking.length ? bookingsData.bookings : [];
          var dataBookings = data !== null && data !== void 0 && (_data$bookingHistory = data.bookingHistory) !== null && _data$bookingHistory !== void 0 && (_data$bookingHistory = _data$bookingHistory.bookings) !== null && _data$bookingHistory !== void 0 && _data$bookingHistory.length ? data === null || data === void 0 || (_data$bookingHistory2 = data.bookingHistory) === null || _data$bookingHistory2 === void 0 ? void 0 : _data$bookingHistory2.bookings : [];
          setBookingsData(_objectSpread(_objectSpread({}, data.bookingHistory), {}, {
            bookings: [...currentBookings, ...dataBookings],
            totals: params !== null && params !== void 0 && params.pageIndex && (params === null || params === void 0 ? void 0 : params.pageIndex) > 1 && bookingsData !== null && bookingsData !== void 0 && bookingsData.totals ? bookingsData.totals : data === null || data === void 0 || (_data$bookingHistory3 = data.bookingHistory) === null || _data$bookingHistory3 === void 0 ? void 0 : _data$bookingHistory3.totals
          }));
          setContinuationToken((_data$bookingHistory$ = (_data$bookingHistory4 = data.bookingHistory) === null || _data$bookingHistory4 === void 0 ? void 0 : _data$bookingHistory4.continuationToken) !== null && _data$bookingHistory$ !== void 0 ? _data$bookingHistory$ : undefined);
          setIsLoading(false);
        }
      } catch (error) {
        setIsLoading(false);
        setIsError(true);
      }
    });
    return function getBookingHistory() {
      return _ref2.apply(this, arguments);
    };
  }();
  var {
    isLoading: isLoadingRoomTypeInformation,
    data: dataRoomTypeInformation
  } = (0, _utils.useQueryRequest)(['getRoomTypeInformation', language, country, bookingChannel.channel], _api.GET_ROOM_TYPE_BOOKING_HISTORY_INFORMATION_QUERY, {
    language,
    country,
    brand: 'PI'
  });
  (0, _react2.useEffect)(() => {
    getBookingHistory();
  }, [params]);
  (0, _react2.useEffect)(() => {
    if (bookingsData && !bookingHasBeenCanceled) {
      var _bookingsData$totals, _bookingsData$totals2, _bookingsData$booking2, _bookingsData$booking3, _bookingsData$booking4, _bookingsData$booking5, _bookingsData$totals3, _bookingsData$totals4;
      _utils.analytics.update({
        dashboard: {
          cancelledBookings: Number(bookingsData === null || bookingsData === void 0 || (_bookingsData$totals = bookingsData.totals) === null || _bookingsData$totals === void 0 ? void 0 : _bookingsData$totals.cancelled),
          futureBookings: Number(bookingsData === null || bookingsData === void 0 || (_bookingsData$totals2 = bookingsData.totals) === null || _bookingsData$totals2 === void 0 ? void 0 : _bookingsData$totals2.upcoming),
          totalBartBookings: bookingsData === null || bookingsData === void 0 || (_bookingsData$booking2 = bookingsData.bookings) === null || _bookingsData$booking2 === void 0 ? void 0 : _bookingsData$booking2.filter(booking => (booking === null || booking === void 0 ? void 0 : booking.sourceSystem) === 'BART' && (booking === null || booking === void 0 ? void 0 : booking.bookingStatus) !== _api.BOOKING_TYPE.CANCELLED).length,
          totalBookings: Number(bookingsData === null || bookingsData === void 0 ? void 0 : bookingsData.totalSize),
          moreThanNineNights: bookingsData === null || bookingsData === void 0 || (_bookingsData$booking3 = bookingsData.bookings) === null || _bookingsData$booking3 === void 0 ? void 0 : _bookingsData$booking3.filter(booking => Number(booking === null || booking === void 0 ? void 0 : booking.noOfNights) > 9 && (booking === null || booking === void 0 ? void 0 : booking.bookingStatus) !== _api.BOOKING_TYPE.CANCELLED).length,
          moreThanFourRooms: bookingsData === null || bookingsData === void 0 || (_bookingsData$booking4 = bookingsData.bookings) === null || _bookingsData$booking4 === void 0 ? void 0 : _bookingsData$booking4.filter(booking => Number(booking === null || booking === void 0 ? void 0 : booking.noOfRooms) > 4 && (booking === null || booking === void 0 ? void 0 : booking.bookingStatus) !== _api.BOOKING_TYPE.CANCELLED).length,
          totalOperaBookings: bookingsData === null || bookingsData === void 0 || (_bookingsData$booking5 = bookingsData.bookings) === null || _bookingsData$booking5 === void 0 ? void 0 : _bookingsData$booking5.filter(booking => (booking === null || booking === void 0 ? void 0 : booking.sourceSystem) === 'OPERA' && (booking === null || booking === void 0 ? void 0 : booking.bookingStatus) !== _api.BOOKING_TYPE.CANCELLED).length,
          checkedinBookings: Number(bookingsData === null || bookingsData === void 0 || (_bookingsData$totals3 = bookingsData.totals) === null || _bookingsData$totals3 === void 0 ? void 0 : _bookingsData$totals3.checkedIn),
          stayedBookings: Number(bookingsData === null || bookingsData === void 0 || (_bookingsData$totals4 = bookingsData.totals) === null || _bookingsData$totals4 === void 0 ? void 0 : _bookingsData$totals4.past)
        }
      });
    }
    setBookingHasBeenCanceled(false);
  }, [bookingsData]);
  var handleFind = filterParams => {
    setParams(_objectSpread(_objectSpread(_objectSpread({}, params), filterParams), {}, {
      pageIndex: 1,
      continuationToken: undefined
    }));
    setContinuationToken(undefined);
  };
  var handleClear = () => {
    setParams(_objectSpread(_objectSpread({}, params), {}, {
      pageIndex: 1,
      filterType: '',
      filterValue: '',
      continuationToken: undefined
    }));
    setContinuationToken(undefined);
  };
  var handleLoadMore = () => {
    if (!(params !== null && params !== void 0 && params.pageIndex)) {
      return;
    }
    setParams(_objectSpread(_objectSpread({}, params), {}, {
      pageIndex: params.pageIndex + 1,
      continuationToken
    }));
  };
  var createNotificationDescription = description => {
    var descriptionArray = description.split('.').filter(el => el.length !== 0);
    return (0, _jsxRuntime.jsx)(_jsxRuntime.Fragment, {
      children: descriptionArray.map(descriptionElem => {
        return (0, _jsxRuntime.jsx)(_react.Text, {
          children: "".concat(descriptionElem, '.')
        }, descriptionElem);
      })
    });
  };
  if (bookingsData === null && isLoading || isLoadingRoomTypeInformation) {
    return null;
  }
  if (bookingsData && !(bookingsData !== null && bookingsData !== void 0 && (_bookingsData$booking6 = bookingsData.bookings) !== null && _bookingsData$booking6 !== void 0 && _bookingsData$booking6.length) && params.filterValue === '' || isError) {
    return (0, _jsxRuntime.jsx)(_atoms.Notification, {
      svg: (0, _jsxRuntime.jsx)(_atoms.Info, {}),
      status: "info",
      description: t('dashboard.bookings.bookingListEmpty'),
      variant: "infoGrey"
    });
  }
  var updateCancelledBooking = bookingReference => {
    if (!(bookingsData !== null && bookingsData !== void 0 && bookingsData.bookings)) {
      return;
    }
    var bookings = [...bookingsData.bookings];
    setBookingsData(_objectSpread(_objectSpread({}, bookingsData), {}, {
      bookings: [...bookings.map(booking => {
        if ((booking === null || booking === void 0 ? void 0 : booking.bookingReference) === bookingReference) {
          return _objectSpread(_objectSpread({}, booking), {}, {
            bookingStatus: _api.BOOKING_TYPE.CANCELLED
          });
        }
        return booking;
      })]
    }));
    setBookingHasBeenCanceled(true);
  };
  var renderBookingCard = rowData => {
    var _ref3, _ref4, _dataRoomTypeInformat, _dataRoomTypeInformat2;
    return (0, _jsxRuntime.jsx)(_BookingInfoCard.BookingHistoryInfoCardContainer, {
      bookingReference: rowData.bookingReference,
      hotelId: rowData.hotelCode,
      arrival: rowData.arrivalDate,
      guestSurname: rowData.leadGuestSurname,
      bookingStatus: (_ref3 = rowData === null || rowData === void 0 ? void 0 : rowData.bookingStatus) !== null && _ref3 !== void 0 ? _ref3 : '',
      onCancel: updateCancelledBooking,
      hotelName: rowData.hotelName,
      invoiceRecordNumber: rowData.historyRecordNumber,
      invoiceItems: {
        sentInvoiceItems: invoiceItemsSent,
        setInvoiceItems: setInvoiceItemsSent
      },
      bookedBy: rowData.bookedBy,
      bookingChannel: bookingChannel,
      sourceSystem: (_ref4 = rowData === null || rowData === void 0 ? void 0 : rowData.sourceSystem) !== null && _ref4 !== void 0 ? _ref4 : undefined,
      roomTypeLabels: (_dataRoomTypeInformat = dataRoomTypeInformation === null || dataRoomTypeInformation === void 0 || (_dataRoomTypeInformat2 = dataRoomTypeInformation.roomTypeInformation) === null || _dataRoomTypeInformat2 === void 0 ? void 0 : _dataRoomTypeInformat2.roomTypes) !== null && _dataRoomTypeInformat !== void 0 ? _dataRoomTypeInformat : [],
      area: area
    });
  };
  var bookings = (_bookingsData$booking7 = bookingsData === null || bookingsData === void 0 ? void 0 : bookingsData.bookings) !== null && _bookingsData$booking7 !== void 0 ? _bookingsData$booking7 : [];
  var hasMore = bookingsData !== null && bookingsData !== void 0 && bookingsData.totalSize && bookingsData !== null && bookingsData !== void 0 && bookingsData.pageIndex ? bookingsData && bookingsData.totalSize > pageSize * (bookingsData === null || bookingsData === void 0 ? void 0 : bookingsData.pageIndex) : false;
  var stats = bookingsData ? [{
    count: Number((_bookingsData$totals$ = bookingsData === null || bookingsData === void 0 || (_bookingsData$totals5 = bookingsData.totals) === null || _bookingsData$totals5 === void 0 ? void 0 : _bookingsData$totals5.upcoming) !== null && _bookingsData$totals$ !== void 0 ? _bookingsData$totals$ : 0),
    text: t('dashboard.bookings.upcoming')
  }, {
    count: Number((_bookingsData$totals$2 = bookingsData === null || bookingsData === void 0 || (_bookingsData$totals6 = bookingsData.totals) === null || _bookingsData$totals6 === void 0 ? void 0 : _bookingsData$totals6.checkedIn) !== null && _bookingsData$totals$2 !== void 0 ? _bookingsData$totals$2 : 0),
    text: t('dashboard.bookings.checkedIn')
  }, {
    count: Number((_bookingsData$totals$3 = bookingsData === null || bookingsData === void 0 || (_bookingsData$totals7 = bookingsData.totals) === null || _bookingsData$totals7 === void 0 ? void 0 : _bookingsData$totals7.past) !== null && _bookingsData$totals$3 !== void 0 ? _bookingsData$totals$3 : 0),
    text: t('dashboard.bookings.past')
  }, {
    count: Number((_bookingsData$totals$4 = bookingsData === null || bookingsData === void 0 || (_bookingsData$totals8 = bookingsData.totals) === null || _bookingsData$totals8 === void 0 ? void 0 : _bookingsData$totals8.cancelled) !== null && _bookingsData$totals$4 !== void 0 ? _bookingsData$totals$4 : 0),
    text: t('dashboard.bookings.cancelled')
  }] : [];
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [stats.length > 0 && (0, _jsxRuntime.jsx)(_atoms.TextStats, {
      stats: stats
    }), bookingsData && dataRoomTypeInformation && (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
      children: [(0, _jsxRuntime.jsx)(_molecules.BookingHistoryFilter, {
        t: t,
        onClear: handleClear,
        onFind: handleFind,
        screenSize: screenSize,
        baseTestId: BASE_TESTID
      }), (0, _jsxRuntime.jsx)(_atoms.TableList, {
        columns: tableConfig,
        dataTestIdPrefix: BASE_TESTID,
        rows: bookings,
        expandBtnText: t('dashboard.bookings.showDetails'),
        collapseBtnText: t('dashboard.bookings.closeDetails'),
        screenSize: screenSize,
        loadMoreText: t('dashboard.bookings.loadMore'),
        onLoadMore: hasMore ? handleLoadMore : undefined,
        isLoadingMore: isLoading,
        renderExpandedContent: renderBookingCard,
        containerStyles: stylesTableContainer,
        rowStyles: rowStyles
      }), bookings.length === 0 && params.filterValue !== '' && (0, _jsxRuntime.jsx)(_react.Box, {
        mt: '3xl',
        children: (0, _jsxRuntime.jsx)(_atoms.Notification, {
          svg: (0, _jsxRuntime.jsx)(_atoms.Info, {}),
          status: "info",
          description: createNotificationDescription(t('dashboard.bookings.searchNoResults')),
          variant: "info"
        })
      })]
    })]
  });
}
var stylesTableContainer = {
  whiteSpace: 'normal'
};