"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = ConfirmationDetails;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _react = require("@chakra-ui/react");
var _utils = require("@whitbread-eos/utils");
var _formatDistance = _interopRequireDefault(require("date-fns/formatDistance"));
var _react2 = _interopRequireDefault(require("react"));
var _jsxRuntime = require("react/jsx-runtime");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function ConfirmationDetails(_ref) {
  var {
    data,
    t,
    currentLang
  } = _ref;
  var {
    confirmationDetails
  } = data;
  var baseDataTestId = 'BookingReferenceDetails';
  if (!confirmationDetails) {
    return (0, _jsxRuntime.jsx)(_jsxRuntime.Fragment, {});
  }
  var {
    bookingReference,
    hotelName,
    hotelAddress,
    hotelTel,
    roomReservationStartDate,
    roomReservationEndDate,
    leadGuestTitle,
    leadGuestName,
    rateType,
    paymentOption
  } = confirmationDetails;
  var getNrNights = () => {
    return parseInt((0, _formatDistance.default)(new Date(roomReservationStartDate), new Date(roomReservationEndDate)).replace(/^\D+/g, ''));
  };
  var getConfirmationBtnLabel = () => {
    switch (paymentOption) {
      case 'PAY_ON_ARRIVAL':
        return t('booking.confirmation.reservedWithCard');
      case 'PAY_NOW':
        return t('booking.confirmation.paymentTaken');
      case 'RESERVE_WITHOUT_CARD':
        return t('booking.confirmation.reservedWithoutCard');
      default:
        return '';
    }
  };
  return (0, _jsxRuntime.jsxs)(_react.Box, _objectSpread(_objectSpread({}, contentWrapperStyle), {}, {
    children: [(0, _jsxRuntime.jsx)(_react.Text, _objectSpread(_objectSpread({
      "data-testid": (0, _utils.formatDataTestId)(baseDataTestId, 'Label'),
      sx: {
        '@media print': {
          mt: 'md',
          fontsize: 'xxs'
        }
      }
    }, bookingRefLabelStyle), {}, {
      children: t('booking.confirmation.bookingReference')
    })), (0, _jsxRuntime.jsx)(_react.Text, _objectSpread(_objectSpread({
      "data-testid": (0, _utils.formatDataTestId)(baseDataTestId, 'Id'),
      sx: {
        '@media print': {
          mt: '0',
          fontsize: 'xxs'
        }
      }
    }, bookingRefStyle), {}, {
      children: bookingReference
    })), (0, _jsxRuntime.jsx)(_react.Text, _objectSpread(_objectSpread({
      "data-testid": (0, _utils.formatDataTestId)(baseDataTestId, 'Hotel')
    }, hotelNameStyle), {}, {
      sx: {
        '@media print': {
          fontWeight: 'semibold'
        }
      },
      children: hotelName
    })), (0, _jsxRuntime.jsx)(_react.Text, _objectSpread(_objectSpread({
      "data-testid": (0, _utils.formatDataTestId)(baseDataTestId, 'HotelAdress')
    }, hotelAddressStyle), {}, {
      sx: {
        '@media print': {
          fontWeight: 'semibold'
        }
      },
      children: hotelAddress
    })), (0, _jsxRuntime.jsxs)(_react.Text, _objectSpread(_objectSpread({
      "data-testid": (0, _utils.formatDataTestId)(baseDataTestId, 'HotelPhone')
    }, bookingTelStyle), {}, {
      sx: {
        '@media print': {
          mb: '0'
        }
      },
      children: [t('booking.confirmation.tel'), " ", hotelTel]
    })), (0, _jsxRuntime.jsxs)(_react.Box, {
      display: "none",
      sx: {
        '@media print': {
          display: 'block',
          fontSize: 'md'
        }
      },
      children: [(0, _jsxRuntime.jsxs)(_react.Text, {
        mb: "sm",
        children: [t('booking.hotel.summary.arriving'), ":", ' ', (0, _jsxRuntime.jsx)("strong", {
          children: (0, _utils.formatDate)(roomReservationStartDate, 'EEEE, d LLL yyyy', currentLang)
        }), ' ', t('booking.hotel.summary.after2pm')]
      }), (0, _jsxRuntime.jsxs)(_react.Text, {
        mb: "sm",
        children: [t('booking.hotel.summary.checkout'), ":", ' ', (0, _jsxRuntime.jsx)("strong", {
          children: (0, _utils.formatDate)(roomReservationEndDate, 'EEEE, d LLL yyyy', currentLang)
        }), ' ', t('booking.confirmation.checkoutTime')]
      }), (0, _jsxRuntime.jsxs)(_react.Text, {
        mb: "sm",
        children: [t('booking.summary.nights'), ": ", (0, _jsxRuntime.jsx)("strong", {
          children: getNrNights()
        })]
      }), (0, _jsxRuntime.jsxs)(_react.Text, {
        mb: "sm",
        children: [t('booking.summary.rate'), " ", (0, _jsxRuntime.jsx)("strong", {
          children: rateType
        })]
      }), (0, _jsxRuntime.jsxs)(_react.Text, {
        mb: "sm",
        className: "sessioncamhidetext assist-no-show",
        children: [t('booking.confirmation.bookedBy'), ":", ' ', (0, _jsxRuntime.jsxs)("strong", {
          children: [leadGuestTitle, " ", leadGuestName]
        })]
      }), (0, _jsxRuntime.jsxs)(_react.Text, {
        mb: "md",
        children: [t('booking.management.bookingReview.paymentStatusTitle'), ":", ' ', (0, _jsxRuntime.jsx)("strong", {
          children: getConfirmationBtnLabel()
        })]
      })]
    })]
  }));
}
var contentWrapperStyle = {
  w: 'full'
};
var bookingRefLabelStyle = {
  mb: 'xs',
  fontSize: 'lg',
  fontWeight: 'bold'
};
var bookingRefStyle = {
  mb: 'lg',
  fontSize: '2xl'
};
var hotelNameStyle = {
  mb: 'xs',
  fontSize: '2xl',
  fontWeight: 'bold'
};
var hotelAddressStyle = {
  mb: 'xs',
  fontSize: '2xl'
};
var bookingTelStyle = {
  mb: '2xl',
  fontSize: 'md'
};