"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.checkIfIsNotPiBrand = void 0;
exports.default = HotelBrandLogo;
exports.getBrandLogoURL = void 0;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _react = require("@chakra-ui/react");
var _utils = require("@whitbread-eos/utils");
var _react2 = require("react");
var _jsxRuntime = require("react/jsx-runtime");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
var ZIP_HOTEL = 'ZIP';
var HUB_HOTEL = 'HUB';
var NOT_PI_HOTEL_BRANDS = [ZIP_HOTEL, HUB_HOTEL];
function HotelBrandLogo(_ref) {
  var {
    brand,
    logos
  } = _ref;
  var isNotPiBrand = (0, _react2.useMemo)(() => checkIfIsNotPiBrand(brand), [brand]);
  return isNotPiBrand ? (0, _jsxRuntime.jsx)(_react.Box, _objectSpread(_objectSpread({}, hotelBrandLogoWrapperStyles), {}, {
    "data-testid": "srp_hotel-brand-logo",
    children: (0, _jsxRuntime.jsx)(_react.Image, {
      src: (0, _utils.formatAssetsUrl)(getBrandLogoURL(brand, logos)),
      alt: brand === 'HUB' ? 'hub' : 'zip'
    })
  })) : null;
}
var checkIfIsNotPiBrand = brand => {
  return NOT_PI_HOTEL_BRANDS.includes(brand === null || brand === void 0 ? void 0 : brand.toUpperCase());
};
exports.checkIfIsNotPiBrand = checkIfIsNotPiBrand;
var getBrandLogoURL = (brand, logos) => {
  if (brand === 'HUB') {
    return logos.hubLogo;
  }
  return brand === 'ZIP' ? logos.zipLogo : '';
};
exports.getBrandLogoURL = getBrandLogoURL;
var hotelBrandLogoWrapperStyles = {
  position: 'absolute',
  top: 'sm',
  left: 0
};