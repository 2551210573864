"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.BC_RESERVATION_STATUS = void 0;
var BC_RESERVATION_STATUS = exports.BC_RESERVATION_STATUS = function (BC_RESERVATION_STATUS) {
  BC_RESERVATION_STATUS["COMPLETED"] = "Reserved";
  BC_RESERVATION_STATUS["CANCELLED"] = "Cancelled";
  BC_RESERVATION_STATUS["PREPAID"] = "Prepaid";
  BC_RESERVATION_STATUS["ARRIVED"] = "Arrived";
  BC_RESERVATION_STATUS["CHECKEDIN"] = "Checkedin";
  BC_RESERVATION_STATUS["FUTURE"] = "Future";
  BC_RESERVATION_STATUS["UNARRIVED"] = "Unarrived";
  BC_RESERVATION_STATUS["RESERVED"] = "Reserved";
  BC_RESERVATION_STATUS["NOSHOW"] = "Noshow";
  BC_RESERVATION_STATUS["RELEASED"] = "Released";
  BC_RESERVATION_STATUS["PAST"] = "Past";
  return BC_RESERVATION_STATUS;
}({});