"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.CHECK_BASKET_STATUS = void 0;
var _taggedTemplateLiteral2 = _interopRequireDefault(require("@babel/runtime/helpers/taggedTemplateLiteral"));
var _graphqlRequest = require("graphql-request");
var _templateObject;
var CHECK_BASKET_STATUS = exports.CHECK_BASKET_STATUS = (0, _graphqlRequest.gql)(_templateObject || (_templateObject = (0, _taggedTemplateLiteral2.default)(["\n  query BasketStatus($basketReference: String!) {\n    basketStatus(basketReference: $basketReference) {\n      basketStatus\n      retryPayment\n      basketError {\n        code\n        description\n        type\n      }\n    }\n  }\n"])));