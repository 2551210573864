"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = FormDropdown;
var _objectWithoutProperties2 = _interopRequireDefault(require("@babel/runtime/helpers/objectWithoutProperties"));
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _react = require("@chakra-ui/react");
var _reactHookForm = require("react-hook-form");
var _formatters = require("../../../utils/formatters");
var _FormError = _interopRequireDefault(require("../FormError"));
var _Dropdown = _interopRequireDefault(require("./../../Dropdown"));
var _jsxRuntime = require("react/jsx-runtime");
var _excluded = ["onChange", "ref"];
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function FormDropdown(_ref) {
  var {
    control,
    formField,
    errors,
    handleSetValue,
    onChangeAction
  } = _ref;
  return (0, _jsxRuntime.jsxs)(_react.Flex, _objectSpread(_objectSpread({
    direction: "column"
  }, _objectSpread(_objectSpread({}, defaultStyles), formField.styles)), {}, {
    "data-testid": formField.testid,
    "data-fieldname": formField.name,
    children: [(0, _jsxRuntime.jsx)(_reactHookForm.Controller, {
      name: formField.name,
      control: control,
      render: _ref2 => {
        var _errors$formField$nam, _formField$props;
        var {
          field
        } = _ref2;
        var {
            onChange: _onChange,
            ref: _
          } = field,
          extraPropsField = (0, _objectWithoutProperties2.default)(field, _excluded);
        var hasError = Boolean(errors === null || errors === void 0 || (_errors$formField$nam = errors[formField.name]) === null || _errors$formField$nam === void 0 ? void 0 : _errors$formField$nam.message);
        return (0, _jsxRuntime.jsx)(_Dropdown.default, _objectSpread(_objectSpread(_objectSpread({}, formField.props), extraPropsField), {}, {
          onChange: o => {
            var _formField$onChange;
            (_formField$onChange = formField.onChange) === null || _formField$onChange === void 0 || _formField$onChange.call(formField, o === null || o === void 0 ? void 0 : o.id);
            _onChange(o === null || o === void 0 ? void 0 : o.id);
            onChangeAction === null || onChangeAction === void 0 || onChangeAction(o === null || o === void 0 ? void 0 : o.id, handleSetValue);
          },
          options: formField.dropdownOptions,
          label: formField.label,
          matchWidth: true,
          hasError: hasError,
          dataTestId: (0, _formatters.formatDataTestId)(formField.testid, 'InnerDropdown'),
          selectedId: field.value ? field.value : field.name,
          dropdownStyles: {
            menuListStyles: _objectSpread(_objectSpread({}, (_formField$props = formField.props) === null || _formField$props === void 0 || (_formField$props = _formField$props.dropdownStyles) === null || _formField$props === void 0 ? void 0 : _formField$props.menuListStyles), {}, {
              zIndex: 999
            }),
            menuButtonStyles: _objectSpread({}, hasError && {
              border: '2px solid var(--chakra-colors-error)',
              borderColor: 'var(--chakra-colors-error) !important',
              _focus: {
                border: '2px solid var(--chakra-colors-error)'
              },
              _hover: {
                border: '2px solid var(--chakra-colors-error)',
                borderColor: 'var(--chakra-colors-error)'
              }
            })
          }
        }));
      }
    }), (0, _jsxRuntime.jsx)(_FormError.default, {
      errors: errors,
      name: formField.name,
      extraStyles: formField.errorStyles
    })]
  }));
}
var defaultStyles = {
  marginBottom: 'var(--chakra-space-md)'
};