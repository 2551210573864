"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.FIELDS = exports.ERROR_KEYS = exports.ERROR_FIELDS = void 0;
exports.formatSummaryDateRange = formatSummaryDateRange;
exports.getNotificationMarginTop = void 0;
exports.getRedirectWithDefaultsURL = getRedirectWithDefaultsURL;
exports.getSearchParams = getSearchParams;
exports.mapRoomsForAvailabilityQuery = mapRoomsForAvailabilityQuery;
exports.mapSearchParamsForURL = mapSearchParamsForURL;
exports.setSearchLocationInLocalStorage = setSearchLocationInLocalStorage;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _api = require("@whitbread-eos/api");
var _molecules = require("@whitbread-eos/molecules");
var _utils = require("@whitbread-eos/utils");
var _format = _interopRequireDefault(require("date-fns/format"));
var _set = _interopRequireDefault(require("lodash/set"));
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function mapSearchParamsForURL(params) {
  var roomsInputDataMapped = params.rooms.map((room, idx) => {
    return {
      ["ADULT".concat(idx + 1)]: room.adults,
      ["CHILD".concat(idx + 1)]: room.children,
      ["COT".concat(idx + 1)]: room.shouldIncludeCot ? 1 : 0
    };
  });
  var roomInputDataObj = roomsInputDataMapped.reduce((prev, current) => _objectSpread(_objectSpread({}, prev), current), {});
  return _objectSpread(_objectSpread({}, params), roomInputDataObj);
}
function setSearchLocationInLocalStorage(queryParams, stayDetailsState) {
  var placeId = queryParams !== null && queryParams !== void 0 && queryParams.placeId ? queryParams.placeId : '';
  var location = queryParams !== null && queryParams !== void 0 && queryParams.location ? {
    longitude: queryParams.location[0],
    latitude: queryParams.location[1]
  } : {
    longitude: '',
    latitude: ''
  };
  var hotelId = queryParams !== null && queryParams !== void 0 && queryParams.code ? queryParams.code : '';
  (0, _set.default)(stayDetailsState.data.info, 'suggestion.placeId', placeId);
  (0, _set.default)(stayDetailsState.data.info, 'suggestion.location', location);
  return (0, _set.default)(stayDetailsState.data.info, 'suggestion.hotelId', hotelId);
}
function mapRoomsForAvailabilityQuery(rooms, mappedRoomLabels) {
  return rooms.map(room => {
    var roomCode = mappedRoomLabels[room.roomType];
    return {
      adultsNumber: room.adults,
      childrenNumber: room.children,
      roomType: roomCode,
      cotRequired: room.shouldIncludeCot
    };
  });
}
function formatSummaryDateRange(dateFormat, startDate, endDate, translationDateLanguage) {
  if (!startDate || !endDate) {
    return;
  }
  return "".concat((0, _format.default)(startDate, dateFormat, translationDateLanguage), " - ").concat((0, _format.default)(endDate, dateFormat, translationDateLanguage));
}
var getNotificationMarginTop = (searchSummaryActive, searchConsoleIsActive, breakPoint) => {
  var isMobile = breakPoint === 'mobile';
  if (searchSummaryActive) {
    return 'md';
  }
  if (searchConsoleIsActive) {
    return isMobile ? '-11rem' : '-xl';
  }
  return isMobile ? '-7rem' : '-2xl';
};
exports.getNotificationMarginTop = getNotificationMarginTop;
function getRedirectWithDefaultsURL(router, day, month, year, NIGHTS, showMultipleRooms) {
  var defaultQuery = {
    ARRdd: day,
    ARRmm: month,
    ARRyyyy: year,
    NIGHTS: !showMultipleRooms ? 1 : NIGHTS,
    ROOMS: 1,
    ADULT1: 1,
    CHILD1: 0,
    COT1: 0,
    INTTYP1: 'DB'
  };
  var [path, searchParams] = router.asPath.split('?');
  var shouldKeepKey = key => {
    return !(Object.keys(defaultQuery).includes(key) || key.startsWith('ADULT') || key.startsWith('CHILD') || key.startsWith('INTTYP') || key.startsWith('COT'));
  };
  var url = new URLSearchParams(searchParams);
  var newQueryParams = Array.from(url.entries()).filter(_ref => {
    var [key] = _ref;
    return shouldKeepKey(key);
  }).reduce((query, _ref2) => {
    var [key, value] = _ref2;
    query[key] = value;
    return query;
  }, defaultQuery);
  var newSearchParams = Object.entries(newQueryParams).map(_ref3 => {
    var [key, value] = _ref3;
    return "".concat(key, "=").concat(value);
  }).join('&');
  return "".concat(path, "?").concat(newSearchParams);
}
function getSearchParams(query) {
  var _SORT_TYPES$Number;
  var {
    ARRdd,
    ARRmm,
    ARRyyyy,
    NIGHTS,
    ROOMS,
    LOCATION,
    PLACEID,
    BOOKINGCHANNEL,
    FILTERS,
    CODE
  } = query;
  var location = query['searchModel.searchTerm'];
  var SORT = query === null || query === void 0 ? void 0 : query.SORT;
  var CELLCODES = query === null || query === void 0 ? void 0 : query.CELLCODES;
  var cellCodesArr = CELLCODES === null || CELLCODES === void 0 ? void 0 : CELLCODES.split(',');
  var sort = (_SORT_TYPES$Number = _molecules.SORT_TYPES[Number(SORT)]) !== null && _SORT_TYPES$Number !== void 0 ? _SORT_TYPES$Number : _molecules.SORT_TYPES[1];
  var corpId = query.CORPID && query.CORPID;
  var SRRooms = [];
  for (var idx = 0; idx < Number(ROOMS); idx++) {
    var room = {
      adultsNumber: Number(query["ADULT".concat(idx + 1)]),
      childrenNumber: Number(query["CHILD".concat(idx + 1)]),
      type: query["INTTYP".concat(idx + 1)]
    };
    SRRooms.push(room);
  }
  if (SRRooms.length === 0) {
    SRRooms.push(_api.DEFAULT_MULTI_SEARCH_ROOM);
  }
  var isArrivalValid = (0, _utils.isDateValid)(Number(ARRdd), Number(ARRmm), Number(ARRyyyy));
  var multiSearchParams = {
    arrivalDay: isArrivalValid ? Number(ARRdd) : null,
    arrivalMonth: isArrivalValid ? Number(ARRmm) : null,
    arrivalYear: isArrivalValid ? Number(ARRyyyy) : null,
    location: location,
    numberOfNights: isArrivalValid ? Number(NIGHTS) : null,
    rooms: SRRooms,
    code: CODE,
    placeId: PLACEID,
    coordinates: LOCATION,
    bookingChannel: BOOKINGCHANNEL ? BOOKINGCHANNEL : _api.DEFAULT_BOOKINGCHANNEL,
    sort: sort,
    filters: FILTERS || '',
    cellCodes: cellCodesArr,
    corpId
  };
  return multiSearchParams;
}
var FIELDS = exports.FIELDS = {
  datepicker: 'datepicker',
  location: 'location',
  occupancy: 'occupancy',
  numberOfNights: 'numberOfNights'
};
var ERROR_KEYS = exports.ERROR_KEYS = {
  invalidLocation: 'invalidLocation',
  invalidDate: 'invalidDate',
  arrivalDateInThePast: 'arrivalDateInThePast',
  arrivalDateInTheFuture: 'arrivalDateInTheFuture',
  invalidNights: 'invalidNights',
  invalidOccupancy: 'invalidOccupancy'
};
var ERROR_FIELDS = exports.ERROR_FIELDS = {
  invalidLocation: 'location',
  invalidDate: 'datepicker',
  arrivalDateInThePast: 'datepicker',
  arrivalDateInTheFuture: 'datepicker',
  invalidNights: 'numberOfNights',
  invalidOccupancy: 'occupancy'
};