"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "BIGGER_ROOM_CODE", {
  enumerable: true,
  get: function get() {
    return _badges.BIGGER_ROOM_CODE;
  }
});
Object.defineProperty(exports, "DATE_FORMAT", {
  enumerable: true,
  get: function get() {
    return _bookingHistory.DATE_FORMAT;
  }
});
Object.defineProperty(exports, "INITIAL_GUEST_DETAILS_FORM_DATA", {
  enumerable: true,
  get: function get() {
    return _guestDetails.INITIAL_GUEST_DETAILS_FORM_DATA;
  }
});
Object.defineProperty(exports, "NEW_HOTEL_MESSAGING_FLAG_TEXT", {
  enumerable: true,
  get: function get() {
    return _badges.NEW_HOTEL_MESSAGING_FLAG_TEXT;
  }
});
Object.defineProperty(exports, "NEW_ROOMS_MESSAGING_FLAG_TEXT", {
  enumerable: true,
  get: function get() {
    return _badges.NEW_ROOMS_MESSAGING_FLAG_TEXT;
  }
});
Object.defineProperty(exports, "OPENING_SOON_MESSAGING_FLAG_TEXT", {
  enumerable: true,
  get: function get() {
    return _badges.OPENING_SOON_MESSAGING_FLAG_TEXT;
  }
});
Object.defineProperty(exports, "PREMIER_EXTRA_CODE", {
  enumerable: true,
  get: function get() {
    return _badges.PREMIER_EXTRA_CODE;
  }
});
Object.defineProperty(exports, "PREMIER_PLUS_FACILITY_CODE", {
  enumerable: true,
  get: function get() {
    return _badges.PREMIER_PLUS_FACILITY_CODE;
  }
});
Object.defineProperty(exports, "STANDARD_EXTRA_FACILITY_CODE", {
  enumerable: true,
  get: function get() {
    return _badges.STANDARD_EXTRA_FACILITY_CODE;
  }
});
Object.defineProperty(exports, "TRIP_ADVISOR_RATING_IMAGE_URL_PART1", {
  enumerable: true,
  get: function get() {
    return _hotelDetails.TRIP_ADVISOR_RATING_IMAGE_URL_PART1;
  }
});
Object.defineProperty(exports, "TRIP_ADVISOR_RATING_IMAGE_URL_PART2", {
  enumerable: true,
  get: function get() {
    return _hotelDetails.TRIP_ADVISOR_RATING_IMAGE_URL_PART2;
  }
});
Object.defineProperty(exports, "TRIP_ADVISOR_SCHEMA_ORGANIZATION_URL", {
  enumerable: true,
  get: function get() {
    return _hotelDetails.TRIP_ADVISOR_SCHEMA_ORGANIZATION_URL;
  }
});
Object.defineProperty(exports, "TRIP_ADVISOR_SCHEMA_PERSON_URL", {
  enumerable: true,
  get: function get() {
    return _hotelDetails.TRIP_ADVISOR_SCHEMA_PERSON_URL;
  }
});
Object.defineProperty(exports, "TRIP_ADVISOR_SCHEMA_RATING_URL", {
  enumerable: true,
  get: function get() {
    return _hotelDetails.TRIP_ADVISOR_SCHEMA_RATING_URL;
  }
});
Object.defineProperty(exports, "TRIP_ADVISOR_SCHEMA_REVIEW_URL", {
  enumerable: true,
  get: function get() {
    return _hotelDetails.TRIP_ADVISOR_SCHEMA_REVIEW_URL;
  }
});
var _badges = require("./badges");
var _bookingHistory = require("./bookingHistory");
var _guestDetails = require("./guest-details");
var _hotelDetails = require("./hotel-details");