"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = ScriptsEmbed;
var _script = _interopRequireDefault(require("next/script"));
var _jsxRuntime = require("react/jsx-runtime");
function ScriptsEmbed(_ref) {
  var {
    isScriptEmbedAppDynamicsEnabled
  } = _ref;
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_script.default, {
      src: "//assets.adobedtm.com/launch-EN1f330bc46c5949b29c22bbf3f0573f75.min.js",
      strategy: "beforeInteractive",
      "data-testid": "adobe-script"
    }), isScriptEmbedAppDynamicsEnabled && (0, _jsxRuntime.jsx)(_script.default, {
      src: "//cdn.appdynamics.com/adrum/adrum-23.3.0.4265.js",
      strategy: "lazyOnload",
      "data-testid": "appdynamics-script"
    })]
  });
}