"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = HeaderVariantStepContainer;
var _api = require("@whitbread-eos/api");
var _utils = require("@whitbread-eos/utils");
var _router = require("next/router");
var _HeaderVariantStep = _interopRequireDefault(require("./HeaderVariantStep.component"));
var _jsxRuntime = require("react/jsx-runtime");
function HeaderVariantStepContainer(_ref) {
  var {
    headerInfoData,
    bb = false,
    queryClient
  } = _ref;
  var {
    query
  } = (0, _router.useRouter)() || {
    query: {},
    route: ''
  };
  var {
    language,
    country
  } = (0, _utils.useCustomLocale)();
  var basketReference = query !== null && query !== void 0 && query.reservationId ? String(query.reservationId) : '';
  var {
    brand,
    stepProgress
  } = (0, _utils.useHotelBrands)({
    basketReference,
    channel: bb ? _api.Channel.Bb : _api.Channel.Pi,
    queryClient
  });
  return (0, _jsxRuntime.jsx)(_HeaderVariantStep.default, {
    currentLang: language,
    currentCountry: country,
    headerInfoData: headerInfoData,
    propsStepProgress: stepProgress,
    hotelBrand: brand,
    bb: bb
  });
}