"use strict";
'use client';

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.SelectValue = exports.SelectTrigger = exports.SelectSeparator = exports.SelectScrollUpButton = exports.SelectScrollDownButton = exports.SelectLabel = exports.SelectItem = exports.SelectGroup = exports.SelectContent = exports.Select = void 0;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _objectWithoutProperties2 = _interopRequireDefault(require("@babel/runtime/helpers/objectWithoutProperties"));
var _reactIcons = require("@radix-ui/react-icons");
var SelectPrimitive = _interopRequireWildcard(require("@radix-ui/react-select"));
var _server = require("@whitbread-eos/utils/server");
var _image = _interopRequireDefault(require("next/image"));
var React = _interopRequireWildcard(require("react"));
var _jsxRuntime = require("react/jsx-runtime");
var _excluded = ["className", "children", "mainIcon", "label", "collapsedIcon", "expandedIcon"],
  _excluded2 = ["className"],
  _excluded3 = ["className"],
  _excluded4 = ["className", "children", "position"],
  _excluded5 = ["className"],
  _excluded6 = ["className", "children", "showCheck"],
  _excluded7 = ["className"];
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && {}.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
var Select = exports.Select = SelectPrimitive.Root;
var SelectGroup = exports.SelectGroup = SelectPrimitive.Group;
var SelectValue = exports.SelectValue = SelectPrimitive.Value;
var SelectTrigger = exports.SelectTrigger = React.forwardRef((_ref, ref) => {
  var {
      className,
      children,
      mainIcon,
      label,
      collapsedIcon,
      expandedIcon
    } = _ref,
    props = (0, _objectWithoutProperties2.default)(_ref, _excluded);
  return (0, _jsxRuntime.jsxs)(SelectPrimitive.Trigger, _objectSpread(_objectSpread({
    ref: ref,
    className: (0, _server.cn)('relative flex h-14 w-full text-darkGrey1 border-lightGrey1 aria-expanded:outline aria-expanded:-outline-offset-2 aria-expanded:ib-select-input-active aria-expanded:outline-primaryColor aria-expanded:outline-2 hover:border-darkGrey1 w-full rounded border bg-transparent p-4 text-base transition-colors', className)
  }, props), {}, {
    children: [mainIcon && (0, _jsxRuntime.jsx)(_image.default, {
      className: "w-6 h-6 mr-4",
      src: mainIcon,
      alt: "select main icon",
      width: 24,
      height: 24
    }), children, label && (0, _jsxRuntime.jsx)("span", {
      className: "ib-select-label absolute block text-darkGrey1 -top-2.5 left-0 ml-3 text-sm px-1 bg-baseWhite text-darkGrey1",
      "data-testid": "".concat(label, "-Label"),
      children: label
    }), (0, _jsxRuntime.jsx)(_image.default, {
      className: "ib-select-collapsed-icon ml-auto",
      src: collapsedIcon,
      alt: "select icon",
      width: 24,
      height: 24
    }), (0, _jsxRuntime.jsx)(_image.default, {
      className: "ib-select-expanded-icon hidden ml-auto",
      src: expandedIcon,
      alt: "select icon",
      width: 24,
      height: 24
    })]
  }));
});
SelectTrigger.displayName = SelectPrimitive.Trigger.displayName;
var SelectScrollUpButton = exports.SelectScrollUpButton = React.forwardRef((_ref2, ref) => {
  var {
      className
    } = _ref2,
    props = (0, _objectWithoutProperties2.default)(_ref2, _excluded2);
  return (0, _jsxRuntime.jsx)(SelectPrimitive.ScrollUpButton, _objectSpread(_objectSpread({
    ref: ref,
    className: (0, _server.cn)('flex cursor-default items-center justify-center py-1', className)
  }, props), {}, {
    children: (0, _jsxRuntime.jsx)(_reactIcons.ChevronUpIcon, {})
  }));
});
SelectScrollUpButton.displayName = SelectPrimitive.ScrollUpButton.displayName;
var SelectScrollDownButton = exports.SelectScrollDownButton = React.forwardRef((_ref3, ref) => {
  var {
      className
    } = _ref3,
    props = (0, _objectWithoutProperties2.default)(_ref3, _excluded3);
  return (0, _jsxRuntime.jsx)(SelectPrimitive.ScrollDownButton, _objectSpread(_objectSpread({
    ref: ref,
    className: (0, _server.cn)('flex cursor-default items-center justify-center py-1', className)
  }, props), {}, {
    children: (0, _jsxRuntime.jsx)(_reactIcons.ChevronDownIcon, {})
  }));
});
SelectScrollDownButton.displayName = SelectPrimitive.ScrollDownButton.displayName;
var SelectContent = exports.SelectContent = React.forwardRef((_ref4, ref) => {
  var {
      className,
      children,
      position = 'popper'
    } = _ref4,
    props = (0, _objectWithoutProperties2.default)(_ref4, _excluded4);
  return (0, _jsxRuntime.jsx)(SelectPrimitive.Portal, {
    children: (0, _jsxRuntime.jsxs)(SelectPrimitive.Content, _objectSpread(_objectSpread({
      ref: ref,
      className: (0, _server.cn)('max-h-60 relative z-50 min-w-[8rem] overflow-hidden rounded-md border bg-popover text-popover-foreground shadow-md data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2', position === 'popper' && 'data-[side=bottom]:translate-y-1 data-[side=left]:-translate-x-1 data-[side=right]:translate-x-1 data-[side=top]:-translate-y-1', className),
      position: position
    }, props), {}, {
      children: [(0, _jsxRuntime.jsx)(SelectScrollUpButton, {}), (0, _jsxRuntime.jsx)(SelectPrimitive.Viewport, {
        className: (0, _server.cn)(position === 'popper' && 'h-[var(--radix-select-trigger-height)] w-full min-w-[var(--radix-select-trigger-width)]'),
        children: children
      }), (0, _jsxRuntime.jsx)(SelectScrollDownButton, {})]
    }))
  });
});
SelectContent.displayName = SelectPrimitive.Content.displayName;
var SelectLabel = exports.SelectLabel = React.forwardRef((_ref5, ref) => {
  var {
      className
    } = _ref5,
    props = (0, _objectWithoutProperties2.default)(_ref5, _excluded5);
  return (0, _jsxRuntime.jsx)(SelectPrimitive.Label, _objectSpread({
    ref: ref,
    className: (0, _server.cn)('px-2 py-1.5 text-sm font-semibold', className)
  }, props));
});
SelectLabel.displayName = SelectPrimitive.Label.displayName;
var SelectItem = exports.SelectItem = React.forwardRef((_ref6, ref) => {
  var {
      className,
      children,
      showCheck
    } = _ref6,
    props = (0, _objectWithoutProperties2.default)(_ref6, _excluded6);
  return (0, _jsxRuntime.jsxs)(SelectPrimitive.Item, _objectSpread(_objectSpread({
    ref: ref,
    className: (0, _server.cn)('h-10 relative flex w-full cursor-pointer select-none items-center py-2 pl-4 pr-4 text-sm text-darkGrey5 hover:bg-lightGrey5 outline-none data-[disabled]:pointer-events-none data-[disabled]:opacity-50', className)
  }, props), {}, {
    children: [showCheck && (0, _jsxRuntime.jsx)("span", {
      className: "absolute right-2 flex h-3.5 w-3.5 items-center justify-center",
      children: (0, _jsxRuntime.jsx)(SelectPrimitive.ItemIndicator, {
        children: (0, _jsxRuntime.jsx)(_reactIcons.CheckIcon, {
          className: "h-4 w-4"
        })
      })
    }), (0, _jsxRuntime.jsx)(SelectPrimitive.ItemText, {
      children: children
    })]
  }));
});
SelectItem.displayName = SelectPrimitive.Item.displayName;
var SelectSeparator = exports.SelectSeparator = React.forwardRef((_ref7, ref) => {
  var {
      className
    } = _ref7,
    props = (0, _objectWithoutProperties2.default)(_ref7, _excluded7);
  return (0, _jsxRuntime.jsx)(SelectPrimitive.Separator, _objectSpread({
    ref: ref,
    className: (0, _server.cn)('-mx-1 my-1 h-px bg-muted', className)
  }, props));
});
SelectSeparator.displayName = SelectPrimitive.Separator.displayName;