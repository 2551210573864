"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.EMAIL_COOKIE = exports.CDH_EMPLOYEE_ID = exports.CDH_COMPANY_ID = exports.ACCESS_TOKEN_COOKIE = void 0;
Object.defineProperty(exports, "ID_TOKEN_COOKIE", {
  enumerable: true,
  get: function get() {
    return _globalConstants.ID_TOKEN_COOKIE;
  }
});
exports.OPERA_COMPANY_ID = exports.MAX_AGE = void 0;
Object.defineProperty(exports, "decodeIdToken", {
  enumerable: true,
  get: function get() {
    return _decodeIdToken.default;
  }
});
exports.getAccessTokenCookie = getAccessTokenCookie;
exports.getAuthCookie = getAuthCookie;
Object.defineProperty(exports, "getLoggedInUserInfo", {
  enumerable: true,
  get: function get() {
    return _getLoggedInUserInfo.default;
  }
});
exports.logout = logout;
var _globalConstants = require("../global-constants");
var _cookies = require("../helpers/cookies");
var _decodeIdToken = _interopRequireDefault(require("../utils/decodeIdToken"));
var _getLoggedInUserInfo = _interopRequireDefault(require("../utils/getLoggedInUserInfo"));
var _getters = require("./getters");
var ACCESS_TOKEN_COOKIE = exports.ACCESS_TOKEN_COOKIE = 'access_token';
var EMAIL_COOKIE = exports.EMAIL_COOKIE = 'email';
var OPERA_COMPANY_ID = exports.OPERA_COMPANY_ID = 'https://premierinn.com/operaCompanyId';
var CDH_COMPANY_ID = exports.CDH_COMPANY_ID = 'https://premierinn.com/companyAccountId';
var CDH_EMPLOYEE_ID = exports.CDH_EMPLOYEE_ID = 'https://premierinn.com/employeeAccountId';
var MAX_AGE = exports.MAX_AGE = 40 * 60 * 1000;
function getAuthCookie() {
  return (0, _cookies.getCookie)(_globalConstants.ID_TOKEN_COOKIE) || '';
}
function getAccessTokenCookie() {
  return (0, _cookies.getCookie)(ACCESS_TOKEN_COOKIE) || '';
}
function logout() {
  var authIframe = document.getElementById('authIframe');
  if (authIframe !== null && authIframe !== void 0 && authIframe.contentWindow) {
    var message = JSON.stringify({
      action: 'logout'
    });
    authIframe.contentWindow.postMessage(message, (0, _getters.getSecureTwoURL)());
  }
}