"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _react = require("@chakra-ui/react");
var _atoms = require("@whitbread-eos/atoms");
var _nextI18next = require("next-i18next");
var _react2 = _interopRequireWildcard(require("react"));
var _jsxRuntime = require("react/jsx-runtime");
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && {}.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function TableFilter(_ref) {
  var {
    popoverTrigger,
    filterKey,
    filterOptions,
    selectedFilters,
    onApplyFilters,
    externalStyles
  } = _ref;
  var [selection, setSelection] = (0, _react2.useState)({});
  var [searchTerm, setSearchTerm] = (0, _react2.useState)('');
  var sortedFilterOptions = (0, _react2.useMemo)(() => {
    return filterOptions.sort();
  }, [filterOptions]);
  var {
    t
  } = (0, _nextI18next.useTranslation)();
  var numberSelected = Object.keys(selection).filter(key => selection[key]).length;
  var multiSelectionLabel = numberSelected ? t('tableFilter.clearAll') : t('tableFilter.selectAll');
  var matchingFilters = searchTerm.length < 3 ? sortedFilterOptions : sortedFilterOptions.filter(option => option.toLowerCase().includes(searchTerm.toLowerCase()));
  var handleMultiSelection = () => {
    setSelection(current => {
      var updatedSelection = _objectSpread({}, current);
      var newValue = !numberSelected;
      Object.keys(updatedSelection).forEach(key => {
        updatedSelection[key] = newValue;
      });
      return updatedSelection;
    });
  };
  var handleCheckboxValueChanged = (event, option) => {
    setSelection(current => {
      var updatedSelection = _objectSpread({}, current);
      if (updatedSelection[option] !== undefined) {
        updatedSelection[option] = event.target.checked;
      }
      return updatedSelection;
    });
  };
  var handleApplyFilters = () => {
    var selectedFilters = Object.keys(selection).filter(key => selection[key]);
    onApplyFilters(selectedFilters, filterKey);
  };
  var clearAllSelection = () => {
    setSelection(current => {
      var updatedSelection = Object.keys(current).reduce((obj, current) => {
        return _objectSpread(_objectSpread({}, obj), {}, {
          [current]: false
        });
      }, {});
      return updatedSelection;
    });
  };
  var setFilterSelection = () => {
    var updatedSelection = filterOptions.reduce((obj, current) => {
      return _objectSpread(_objectSpread({}, obj), {}, {
        [current]: selectedFilters.includes(current)
      });
    }, {});
    setSelection(updatedSelection);
  };
  return (0, _jsxRuntime.jsx)(_react.Popover, {
    onOpen: setFilterSelection,
    onClose: () => {
      clearAllSelection();
      setSearchTerm('');
    },
    children: _ref2 => {
      var {
        isOpen,
        onClose
      } = _ref2;
      return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
        children: [(0, _jsxRuntime.jsx)(_react.PopoverTrigger, {
          children: popoverTrigger(isOpen, filterKey)
        }), (0, _jsxRuntime.jsxs)(_react.PopoverContent, _objectSpread(_objectSpread({}, _objectSpread(_objectSpread({}, contentStyles), externalStyles === null || externalStyles === void 0 ? void 0 : externalStyles.contentStyles)), {}, {
          children: [(0, _jsxRuntime.jsx)(_react.PopoverHeader, _objectSpread(_objectSpread({}, _objectSpread(_objectSpread({}, headerStyles), externalStyles === null || externalStyles === void 0 ? void 0 : externalStyles.headerStyles)), {}, {
            children: (0, _jsxRuntime.jsxs)(_react.Box, _objectSpread(_objectSpread({}, verticalFlexStyles), {}, {
              gap: 6,
              children: [(0, _jsxRuntime.jsxs)(_react.Box, _objectSpread(_objectSpread({}, headerTopSectionStyles), {}, {
                children: [(0, _jsxRuntime.jsxs)(_react.Box, _objectSpread(_objectSpread({}, verticalFlexStyles), {}, {
                  gap: 2,
                  alignItems: "flex-start",
                  children: [(0, _jsxRuntime.jsxs)(_react.Text, _objectSpread(_objectSpread({}, headerTextStyles), {}, {
                    children: ["".concat(numberSelected, " ").concat(t('tableFilter.selectionCount')), ' ']
                  })), (0, _jsxRuntime.jsx)(_react.Text, _objectSpread(_objectSpread({
                    onClick: handleMultiSelection
                  }, _objectSpread(_objectSpread({}, headerTextStyles), headerTextLinkStyles)), {}, {
                    children: multiSelectionLabel
                  }))]
                })), (0, _jsxRuntime.jsx)(_react.Box, _objectSpread(_objectSpread({
                  as: "button",
                  "data-testid": "TableFilter-PopoverCloseIcon"
                }, closeIconStyles), {}, {
                  onClick: onClose,
                  children: (0, _jsxRuntime.jsx)(_atoms.Icon, {
                    svg: (0, _jsxRuntime.jsx)(_atoms.Dismiss, {})
                  })
                }))]
              })), (0, _jsxRuntime.jsxs)(_react.InputGroup, {
                children: [(0, _jsxRuntime.jsx)(_react.InputLeftElement, _objectSpread(_objectSpread({}, inputLeftElementStyles), {}, {
                  pointerEvents: "none",
                  children: (0, _jsxRuntime.jsx)(_atoms.Icon, {
                    svg: (0, _jsxRuntime.jsx)(_atoms.SearchIcon, {})
                  })
                })), (0, _jsxRuntime.jsx)(_react.Input, _objectSpread({
                  name: "searchFilters",
                  placeholder: t('tableFilter.search.placeholder'),
                  value: searchTerm,
                  onChange: event => setSearchTerm(event.target.value)
                }, inputElementStyles))]
              })]
            }))
          })), (0, _jsxRuntime.jsxs)(_react.PopoverBody, _objectSpread(_objectSpread({}, _objectSpread(_objectSpread({}, bodyStyles), externalStyles === null || externalStyles === void 0 ? void 0 : externalStyles.bodyStyles)), {}, {
            children: [matchingFilters.length > 0 && (0, _jsxRuntime.jsx)(_react.Box, _objectSpread(_objectSpread({}, verticalFlexStyles), {}, {
              gap: 4,
              paddingBottom: 4,
              children: matchingFilters.map((filter, index) => {
                var _selection$filter;
                return (0, _jsxRuntime.jsx)(_atoms.Checkbox, _objectSpread(_objectSpread({
                  isChecked: (_selection$filter = selection[filter]) !== null && _selection$filter !== void 0 ? _selection$filter : false,
                  onChange: e => {
                    handleCheckboxValueChanged(e, filter);
                  },
                  checkboxWrapperStyles: {
                    margin: '0'
                  }
                }, checkBoxStyles), {}, {
                  children: (0, _jsxRuntime.jsxs)(_react.Text, _objectSpread(_objectSpread({}, checkBoxLabelStyles), {}, {
                    children: [filter, " "]
                  }))
                }), "".concat(filter, "-").concat(index));
              })
            })), !!filterOptions.length && !matchingFilters.length && (0, _jsxRuntime.jsx)(_atoms.Notification, {
              maxWidth: "full",
              variant: "info",
              status: "info",
              description: t('tableFilter.search.noResults'),
              svg: (0, _jsxRuntime.jsx)(_atoms.Info, {}),
              wrapperStyles: notificationStyles
            })]
          })), (0, _jsxRuntime.jsx)(_react.PopoverFooter, _objectSpread(_objectSpread({}, _objectSpread(_objectSpread({}, footerStyles), externalStyles === null || externalStyles === void 0 ? void 0 : externalStyles.footerStyles)), {}, {
            children: (0, _jsxRuntime.jsx)(_atoms.Button, _objectSpread(_objectSpread({
              onClick: () => {
                handleApplyFilters();
                onClose();
              },
              size: "full",
              variant: "tertiary"
            }, footerButtonStyles), {}, {
              children: t('tableFilter.apply')
            }))
          }))]
        }))]
      });
    }
  });
}
var contentStyles = {
  display: 'flex',
  inset: 'var(--chakra-space-4) auto auto 0px',
  boxShadow: '0px 2px 12px 0px var(--chakra-colors-lightGrey2)',
  padding: '0',
  borderRadius: 'var(--chakra-radii-base)',
  _focus: {
    boxShadow: '0px 2px 12px 0px var(--chakra-colors-lightGrey2)'
  },
  _focusVisible: {
    outline: 'none'
  }
};
var headerStyles = {
  border: 'none',
  padding: 'var(--chakra-space-6) '
};
var headerTopSectionStyles = {
  width: 'full',
  display: 'flex',
  alignItems: 'flex-start',
  justifyContent: 'space-between'
};
var bodyStyles = {
  padding: '0',
  margin: '0 var(--chakra-space-6) ',
  flex: '1',
  overflow: 'auto'
};
var footerStyles = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  borderTop: '1px solid var(--chakra-colors-lightGrey2)',
  padding: 'var(--chakra-space-6) 0'
};
var headerTextStyles = {
  color: 'darkGrey1',
  fontSize: 'md',
  lineHeight: '3',
  fontWeight: 'normal'
};
var headerTextLinkStyles = {
  textDecoration: 'underline',
  cursor: 'pointer',
  marginTop: '0'
};
var closeIconStyles = {
  paddingY: 'var(--chakra-space-1-5)'
};
var inputLeftElementStyles = {
  top: 'var(--chakra-space-4)',
  left: 'var(--chakra-space-4)',
  height: 'var(--chakra-sizes-6)',
  width: 'var(--chakra-sizes-6)'
};
var inputElementStyles = {
  fontSize: 'md',
  lineHeight: '3',
  color: 'darkGrey2',
  height: 'var(--chakra-sizes-14)',
  paddingLeft: 'var(--chakra-space-3xl)',
  paddingTop: 'var(--chakra-space-2)',
  paddingBottom: 'var(--chakra-space-2)',
  borderRadius: 'var(--chakra-radii-base)',
  border: '1px solid var(--chakra-colors-lightGrey1)',
  _placeholder: {
    color: 'darkGrey2'
  },
  _disabled: {
    opacity: '0.4',
    cursor: 'not-allowed'
  }
};
var checkBoxStyles = {
  display: 'flex',
  alignItems: 'center'
};
var checkBoxLabelStyles = {
  color: 'darkGrey1',
  fontSize: 'md',
  fontWeight: '400',
  lineHeight: '3',
  fontFamily: 'header'
};
var footerButtonStyles = {
  margin: '0 var(--chakra-space-6)'
};
var verticalFlexStyles = {
  display: 'flex',
  flexDirection: 'column'
};
var notificationStyles = {
  width: 'auto',
  margin: 0
};
var _default = exports.default = TableFilter;