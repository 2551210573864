"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = AddRoomCard;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _react = require("@chakra-ui/react");
var _react2 = require("react");
var _RoomModal = _interopRequireDefault(require("../RoomModal"));
var _jsxRuntime = require("react/jsx-runtime");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function AddRoomCard(_ref) {
  var {
    styles,
    roomRules,
    labels,
    language,
    baseDataTestId,
    hotelAvailabilityParams,
    onSaveNewRoom,
    variant,
    brand,
    hotelCountry
  } = _ref;
  var [isModalOpen, setIsModalOpen] = (0, _react2.useState)(false);
  var addARoomLabel = labels.roomModalLabels.roomAvailabilityLabels.addRoom;
  var cardStyles = _objectSpread(_objectSpread({}, cardContainerStyles), styles === null || styles === void 0 ? void 0 : styles.roomWrapper);
  return (0, _jsxRuntime.jsxs)(_react.Flex, _objectSpread(_objectSpread({}, cardStyles), {}, {
    "data-testid": "add-room-card-amend",
    onClick: () => setIsModalOpen(true),
    children: [(0, _jsxRuntime.jsx)(_RoomModal.default, {
      isOpen: isModalOpen,
      onClose: () => setIsModalOpen(false),
      title: addARoomLabel,
      roomRules: roomRules,
      labels: labels.roomModalLabels,
      baseDataTestId: baseDataTestId,
      hotelAvailabilityParams: hotelAvailabilityParams,
      onSaveNewRoom: onSaveNewRoom,
      variant: variant,
      brand: brand,
      hotelCountry: hotelCountry,
      language: language
    }), (0, _jsxRuntime.jsx)(_react.Heading, {
      as: "h3",
      textAlign: "center",
      style: cardTextStyles,
      children: "+ ".concat(addARoomLabel)
    })]
  }));
}
var cardContainerStyles = {
  cursor: 'pointer',
  alignItems: 'center',
  justifyContent: 'center',
  border: '1px dashed',
  borderRadius: 'var(--chakra-space-1)',
  borderColor: 'var(--chakra-colors-tertiary)',
  w: {
    mobile: '100%',
    sm: '14.8125rem',
    md: '20.4375rem',
    lg: '11.25rem',
    xl: '12.125rem'
  },
  minHeight: {
    base: '15.25rem',
    sm: '16.75rem',
    lg: '18.25rem',
    xl: '16.75rem'
  },
  h: '100%'
};
var cardTextStyles = {
  color: 'var(--chakra-colors-tertiary)',
  fontSize: 'var(--chakra-fontSizes-lg)',
  lineHeight: 'var(--chakra-lineHeights-3)',
  fontWeight: 'var(--chakra-fontWeights-semibold)'
};