"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = HotelContactInformationContainer;
var _utils = require("@whitbread-eos/utils");
var _HotelContactInformation = _interopRequireDefault(require("./HotelContactInformation.component"));
var _jsxRuntime = require("react/jsx-runtime");
function HotelContactInformationContainer() {
  var {
    language
  } = (0, _utils.useCustomLocale)();
  var {
    contactDetails,
    brand,
    isLoading,
    isError,
    error
  } = (0, _utils.useStaticHotelInformation)();
  return (0, _jsxRuntime.jsx)(_HotelContactInformation.default, {
    data: contactDetails === null || contactDetails === void 0 ? void 0 : contactDetails.phone,
    email: contactDetails === null || contactDetails === void 0 ? void 0 : contactDetails.email,
    isLoading,
    isError,
    error,
    language,
    brand
  });
}