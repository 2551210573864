"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.IsItTrue = void 0;
var IsItTrue = exports.IsItTrue = function (IsItTrue) {
  IsItTrue["YES"] = "YES";
  IsItTrue["NO"] = "NO";
  return IsItTrue;
}({});