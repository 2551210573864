"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = PaymentTypeLed;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _icons = require("@chakra-ui/icons");
var _react = require("@chakra-ui/react");
var _api = require("@whitbread-eos/api");
var _atoms = require("@whitbread-eos/atoms");
var _utils = require("@whitbread-eos/utils");
var _react2 = require("react");
var _jsxRuntime = require("react/jsx-runtime");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function PaymentTypeLed(_ref) {
  var {
    isLoading,
    isError,
    error,
    data,
    selectedPaymentType,
    onPaymentTypeClick,
    t,
    disabledOptions,
    hiddenPaymentMethodTypes,
    variant,
    amendPaymentCard,
    initialPaymentType,
    styles
  } = _ref;
  var baseDataTestId = 'PaymentTypeLed';
  var FT_ENABLE_PAYPAL = variant === _api.Area.BB ? _api.FT_BB_ENABLE_PAYPAL : _api.FT_PI_ENABLE_PAYPAL;
  var {
    [FT_ENABLE_PAYPAL]: isPayPalEnabled
  } = (0, _utils.useFeatureToggle)();
  var isApplePayGooglePayEnabled = (0, _utils.useFeatureSwitch)({
    featureSwitchKey: _api.FS_ENABLE_APPLEPAY_GOOGLEPAY,
    fallbackValue: false
  });
  var [paymentData, setPaymentData] = (0, _react2.useState)(data);
  (0, _react2.useEffect)(() => {
    var firstEnabledMethod = amendPaymentCard !== null && amendPaymentCard !== void 0 ? amendPaymentCard : paymentData === null || paymentData === void 0 ? void 0 : paymentData.paymentMethods.find(pm => pm.enabled);
    if (variant === _api.Area.PI || variant === _api.Area.BB) {
      firstEnabledMethod && onPaymentTypeClick(firstEnabledMethod);
    }
  }, [paymentData]);
  var isPaymentMethodEnabled = _ref2 => {
    var {
      type,
      enabled
    } = _ref2;
    switch (type) {
      case _api.PAYPAL_PAYMENT:
        return enabled && isPayPalEnabled;
      case _api.APGP_PAYMENT:
        return enabled && isApplePayGooglePayEnabled;
      default:
        return enabled;
    }
  };
  (0, _react2.useEffect)(() => {
    if (initialPaymentType && paymentData) {
      var getInitialPaymentType = paymentData.paymentMethods.find(pm => pm.type === initialPaymentType);
      getInitialPaymentType && onPaymentTypeClick(getInitialPaymentType);
    }
  }, []);
  function removeApplePayGoolgePay() {
    var values = [_api.APPLE, _api.GOOGLE];
    var updateData = paymentData.paymentMethods.filter(value => !values.includes(value.name));
    return updateData;
  }
  (0, _react2.useEffect)(() => {
    var applePay = paymentData.paymentMethods.find(method => (method === null || method === void 0 ? void 0 : method.name) === _api.APPLE);
    var googlePay = paymentData.paymentMethods.find(method => (method === null || method === void 0 ? void 0 : method.name) === _api.GOOGLE);
    var reserveWithoutCard = paymentData.paymentMethods.find(method => {
      var _method$paymentOption;
      return method === null || method === void 0 || (_method$paymentOption = method.paymentOptions) === null || _method$paymentOption === void 0 ? void 0 : _method$paymentOption.some(option => option.type === _api.paymentOptions.RESERVE_WITHOUT_CARD && option.enabled);
    });
    if (applePay || googlePay) {
      var _ref3, _applePay$order;
      var pageUrl = window.location.href;
      var walletConfigured = !pageUrl.includes('localhost') ? (0, _utils.isApplePayConfigured)() : true;
      var acceptedCardTypesAPGP = [];
      var getPaymentMethod = (payment, type) => {
        var _payment$logoSrc;
        if (!(payment !== null && payment !== void 0 && payment.enabled)) return null;
        return {
          type,
          name: type === 'AP' ? 'Apple Pay' : 'Google Pay',
          logoSrc: (_payment$logoSrc = payment === null || payment === void 0 ? void 0 : payment.logoSrc) !== null && _payment$logoSrc !== void 0 ? _payment$logoSrc : ''
        };
      };
      var applePayObj = getPaymentMethod(applePay, 'AP');
      var googlePayObj = getPaymentMethod(googlePay, 'GP');
      if (walletConfigured && applePayObj) {
        acceptedCardTypesAPGP.push(applePayObj);
      }
      if (googlePayObj) {
        acceptedCardTypesAPGP.push(googlePayObj);
      }
      var applePayGooglePayObject = {
        name: t('cc.APGP'),
        type: _api.APGP_PAYMENT,
        subType: '',
        order: (_ref3 = (_applePay$order = applePay === null || applePay === void 0 ? void 0 : applePay.order) !== null && _applePay$order !== void 0 ? _applePay$order : googlePay === null || googlePay === void 0 ? void 0 : googlePay.order) !== null && _ref3 !== void 0 ? _ref3 : 0,
        logoSrc: '',
        enabled: (applePay === null || applePay === void 0 ? void 0 : applePay.enabled) || (googlePay === null || googlePay === void 0 ? void 0 : googlePay.enabled) || false,
        cnpPreSelected: (applePay === null || applePay === void 0 ? void 0 : applePay.cnpPreSelected) || (googlePay === null || googlePay === void 0 ? void 0 : googlePay.cnpPreSelected) || false,
        cnpOptionAvailable: (applePay === null || applePay === void 0 ? void 0 : applePay.cnpOptionAvailable) || (googlePay === null || googlePay === void 0 ? void 0 : googlePay.cnpOptionAvailable) || false,
        acceptedCardTypes: acceptedCardTypesAPGP,
        card: (applePay === null || applePay === void 0 ? void 0 : applePay.card) || (googlePay === null || googlePay === void 0 ? void 0 : googlePay.card) || undefined,
        paymentOptions: (applePay === null || applePay === void 0 ? void 0 : applePay.paymentOptions) || (googlePay === null || googlePay === void 0 ? void 0 : googlePay.paymentOptions) || [],
        reasons: []
      };
      var updatedPayment = removeApplePayGoolgePay();
      setPaymentData(() => ({
        paymentMethods: [...(updatedPayment || []), applePayGooglePayObject]
      }));
    }
    if (reserveWithoutCard) {
      var _paymentData$paymentM;
      var reserveWithoutCardObject = {
        name: _api.paymentOptions.RESERVE_WITHOUT_CARD,
        type: _api.paymentOptions.RESERVE_WITHOUT_CARD,
        subType: '',
        order: (paymentData === null || paymentData === void 0 || (_paymentData$paymentM = paymentData.paymentMethods) === null || _paymentData$paymentM === void 0 ? void 0 : _paymentData$paymentM.length) + 1,
        logoSrc: '',
        enabled: (reserveWithoutCard === null || reserveWithoutCard === void 0 ? void 0 : reserveWithoutCard.enabled) || false,
        cnpPreSelected: (reserveWithoutCard === null || reserveWithoutCard === void 0 ? void 0 : reserveWithoutCard.cnpPreSelected) || false,
        cnpOptionAvailable: (reserveWithoutCard === null || reserveWithoutCard === void 0 ? void 0 : reserveWithoutCard.cnpOptionAvailable) || false,
        acceptedCardTypes: [],
        card: undefined,
        paymentOptions: [{
          type: _api.paymentOptions.RESERVE_WITHOUT_CARD,
          order: 1,
          enabled: true
        }],
        reasons: []
      };
      setPaymentData(prev => ({
        paymentMethods: [...prev.paymentMethods, reserveWithoutCardObject]
      }));
    }
  }, [data, t]);
  var disabledReasonsArray = (0, _react2.useMemo)(() => getDisabledReasonsArray(data === null || data === void 0 ? void 0 : data.paymentMethods, t, variant), [paymentData.paymentMethods, t]);
  if (isError) {
    return (0, _jsxRuntime.jsx)(_jsxRuntime.Fragment, {
      children: error.message
    });
  }
  var isPaymentMethodVisible = paymentMethod => {
    var isVisible = true;
    hiddenPaymentMethodTypes === null || hiddenPaymentMethodTypes === void 0 || hiddenPaymentMethodTypes.forEach(hiddenPaymentMethodType => {
      if ((hiddenPaymentMethodType === null || hiddenPaymentMethodType === void 0 ? void 0 : hiddenPaymentMethodType.type) === paymentMethod.type) {
        if (hiddenPaymentMethodType !== null && hiddenPaymentMethodType !== void 0 && hiddenPaymentMethodType.name) {
          if ((hiddenPaymentMethodType === null || hiddenPaymentMethodType === void 0 ? void 0 : hiddenPaymentMethodType.name) === paymentMethod.name) {
            isVisible = false;
          }
        } else {
          isVisible = false;
        }
      }
    });
    return isVisible;
  };
  var getPIBALabelInfo = subType => {
    var pibaLabelInfoObj = {
      [_api.PiCardSubType.PIBA_UK]: 'cc.NEW_PIBA.info',
      [_api.PiCardSubType.PIBA_EU]: 'cc.NEW_PIBA_EURO.info'
    };
    var pibaLabelText = subType ? pibaLabelInfoObj[subType] : '';
    return pibaLabelText;
  };
  return (0, _jsxRuntime.jsxs)(_react.Box, _objectSpread(_objectSpread(_objectSpread({
    "data-testid": (0, _utils.formatDataTestId)(baseDataTestId, 'Container')
  }, paymentTypeWrapperStyle), styles === null || styles === void 0 ? void 0 : styles.containerStyles), {}, {
    children: [(0, _jsxRuntime.jsx)(_react.Text, _objectSpread(_objectSpread({}, titleStyle), {}, {
      "data-testid": "payment-type-method_title",
      children: t('cc.title.selectPaymentMethod')
    })), (0, _jsxRuntime.jsx)(_react.Text, _objectSpread(_objectSpread({}, textColor), {}, {
      mb: "xl",
      "data-testid": "payment-type-method_description",
      children: t('cc.subTitle.ChooseHowToPay')
    })), isError && (0, _jsxRuntime.jsx)(_react.Text, {
      "data-testid": "payment-type-method_error",
      children: error.message
    }), isLoading ? (0, _jsxRuntime.jsx)(_react.Box, {
      "data-testid": "loading",
      children: (0, _jsxRuntime.jsx)(_react.Spinner, {})
    }) : (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
      children: [Boolean(disabledReasonsArray === null || disabledReasonsArray === void 0 ? void 0 : disabledReasonsArray.length) && disabledReasonsArray.map((reason, index) => (0, _jsxRuntime.jsx)(_react.Box, {
        "data-testid": (0, _utils.formatDataTestId)(baseDataTestId, "ReasonNotification-".concat(index)),
        mb: "md",
        children: (0, _jsxRuntime.jsx)(_atoms.Notification, {
          svg: (0, _jsxRuntime.jsx)(_icons.InfoOutlineIcon, {}),
          status: "info",
          description: reason,
          variant: "info",
          "data-testid": (0, _utils.formatDataTestId)(baseDataTestId, "ReasonNotificationModal-".concat(index)),
          className: "assist-no-show"
        })
      }, reason)), (0, _jsxRuntime.jsxs)(_react.Box, {
        "data-testid": "payment-type-method_option-wrapper",
        children: [amendPaymentCard && renderRadioBtn(amendPaymentCard, 0), paymentData === null || paymentData === void 0 ? void 0 : paymentData.paymentMethods.filter(pm => isPaymentMethodEnabled(pm)).map((method, index) => {
          return isPaymentMethodVisible(method) && method.enabled && (0, _jsxRuntime.jsx)(_react.Box, {
            "data-testid": (0, _utils.formatDataTestId)('payment-type-method_option', method.name.replace(/ /g, '_')),
            className: method.type === _api.PiCardType.SAVED_CARD ? 'sessioncamhidetext assist-no-show' : '',
            children: renderRadioBtn(method, index)
          }, method.name);
        })]
      })]
    })]
  }));
  function renderRadioBtn(method, index) {
    var _method$paymentOption2, _method$paymentOption4, _method$card, _method$card2, _method$card3, _method$card4;
    var isDisabled = !(method !== null && method !== void 0 && (_method$paymentOption2 = method.paymentOptions) !== null && _method$paymentOption2 !== void 0 && _method$paymentOption2.some(m => {
      return m.enabled;
    }));
    var visibleAndEnabledPaymentMethods = paymentData.paymentMethods.filter(pm => isPaymentMethodEnabled(pm) && isPaymentMethodVisible(pm));
    var uniquePaymentOptions = [];
    for (var _method of paymentData.paymentMethods) {
      var _method$paymentOption3;
      _method.enabled && (_method === null || _method === void 0 || (_method$paymentOption3 = _method.paymentOptions) === null || _method$paymentOption3 === void 0 ? void 0 : _method$paymentOption3.forEach(option => {
        if (option.type !== _api.paymentOptions.RESERVE_WITHOUT_CARD) {
          option.enabled === true && !uniquePaymentOptions.includes(option.type) && uniquePaymentOptions.push(option.type);
        }
      }));
    }
    var paymentOptionLeds = method === null || method === void 0 || (_method$paymentOption4 = method.paymentOptions) === null || _method$paymentOption4 === void 0 ? void 0 : _method$paymentOption4.filter(options => options.enabled).map(option => {
      return option.type;
    });
    var renderPaymentLeds = () => {
      return (uniquePaymentOptions === null || uniquePaymentOptions === void 0 ? void 0 : uniquePaymentOptions.length) === 1 ? null : (0, _jsxRuntime.jsxs)(_react.Flex, {
        direction: "column",
        gap: "xs",
        justifyContent: "center",
        alignItems: "end",
        height: "full",
        children: [(0, _jsxRuntime.jsx)(_react.Box, {
          children: (paymentOptionLeds === null || paymentOptionLeds === void 0 ? void 0 : paymentOptionLeds.includes(_api.paymentOptions.PAY_NOW)) && (0, _jsxRuntime.jsx)(_atoms.Badge, _objectSpread(_objectSpread({}, badgeStyles), {}, {
            variant: "secondary",
            children: t('paymentOptions.PAY_NOW.shortLabel')
          }))
        }), (0, _jsxRuntime.jsx)(_react.Box, {
          children: [_api.paymentOptions.PAY_ON_ARRIVAL, _api.paymentOptions.RESERVE_WITHOUT_CARD].some(option => paymentOptionLeds === null || paymentOptionLeds === void 0 ? void 0 : paymentOptionLeds.includes(option)) && (0, _jsxRuntime.jsx)(_atoms.Badge, _objectSpread(_objectSpread({}, badgeStyles), {}, {
            variant: "secondary",
            color: "primary",
            borderColor: "primary",
            children: t('paymentOptions.PAY_ON_ARRIVAL.shortLabel')
          }))
        })]
      });
    };
    var listIndex = index >= visibleAndEnabledPaymentMethods.length - 1 ? 'last' : index;
    var isRadioDisabled = amendPaymentCard && !(amendPaymentCard !== null && amendPaymentCard !== void 0 && amendPaymentCard.enabled) || amendPaymentCard && method !== amendPaymentCard || (disabledOptions === null || disabledOptions === void 0 ? void 0 : disabledOptions.includes(method.type)) || isDisabled;
    var isRadioChecked = isRadioDisabled ? false : selectedPaymentType && method.order === selectedPaymentType.order;
    return (0, _jsxRuntime.jsx)(_react.Box, {
      sx: {
        '.chakra-radio__label': {
          w: 'full'
        }
      },
      children: (0, _jsxRuntime.jsx)(_atoms.RadioButton, _objectSpread(_objectSpread({
        type: "payment-type-radio-".concat(index)
      }, radioBtnStyle), {}, {
        listIndex: listIndex,
        onChange: () => {
          onPaymentTypeClick(method);
        },
        isDisabled: isRadioDisabled,
        isChecked: isRadioChecked,
        children: (0, _jsxRuntime.jsxs)(_react.Flex, {
          justifyContent: 'space-between',
          children: [(0, _jsxRuntime.jsxs)(_react.Box, _objectSpread(_objectSpread({
            "data-testid": "payment-type-method_option-entire-subdiv-".concat(index)
          }, radioLabelWrapper), {}, {
            children: [(0, _jsxRuntime.jsxs)(_react.Flex, {
              py: "3px",
              justifyContent: "space-between",
              "data-testid": "payment-type-method_option-subdiv-with-text",
              w: 'full',
              children: [(0, _jsxRuntime.jsxs)(_react.Text, _objectSpread(_objectSpread({}, textColor), {}, {
                fontWeight: isRadioChecked ? 'semibold' : 'normal',
                "data-testid": "payment-type-method_option-text-".concat(method.name),
                children: [t("".concat((0, _utils.displayMethodType)(method, variant))), (method.type === 'SAVED_CARD' || method.type === 'AMEND_SAVED_CARD') && " (".concat((0, _utils.getCardEnding)(method === null || method === void 0 || (_method$card = method.card) === null || _method$card === void 0 ? void 0 : _method$card.cardNumber), ")")]
              })), ((_method$card2 = method.card) === null || _method$card2 === void 0 ? void 0 : _method$card2.logoSrc) && (0, _jsxRuntime.jsx)(_react.Image, _objectSpread(_objectSpread({}, radioImgStyle), {}, {
                src: (0, _utils.formatAssetsUrl)((_method$card3 = method.card) === null || _method$card3 === void 0 ? void 0 : _method$card3.logoSrc),
                alt: (_method$card4 = method.card) === null || _method$card4 === void 0 ? void 0 : _method$card4.cardType
              }))]
            }), method.type === _api.PiCardType.NEW_PIBA && (0, _jsxRuntime.jsx)(_react.Text, {
              children: t(getPIBALabelInfo(method.subType))
            }), method.card && (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
              children: [(0, _jsxRuntime.jsx)(_react.Text, _objectSpread(_objectSpread({}, textColor), {}, {
                children: method.card.cardHolderName
              })), (0, _jsxRuntime.jsxs)(_react.Text, _objectSpread(_objectSpread({}, textColor), {}, {
                mb: "sm",
                children: [t('booking.expiryDateShort'), " ", method.card.expiryMonth, "/", method.card.expiryYear]
              }))]
            }), method.type === 'SAVED_CARD' && variant === _api.Area.BB && (0, _jsxRuntime.jsx)(_atoms.Badge, _objectSpread(_objectSpread({}, badgeStyles), {}, {
              variant: "secondary",
              children: t("".concat((0, _utils.displayMethodType)(method, variant)))
            })), method.acceptedCardTypes && (0, _jsxRuntime.jsx)(_react.Flex, {
              dir: "column",
              flexWrap: "wrap",
              py: "1px",
              children: method.acceptedCardTypes.map(el => (0, _react2.createElement)(_react.Image, _objectSpread(_objectSpread({}, radioImgStyle), {}, {
                src: (0, _utils.isUrl)(el.logoSrc) ? el.logoSrc : (0, _utils.formatAssetsUrl)(el.logoSrc),
                alt: el.name,
                key: "".concat(method.name, "-").concat(el.logoSrc)
              })))
            })]
          })), (0, _jsxRuntime.jsxs)(_react.Box, {
            children: [" ", renderPaymentLeds()]
          })]
        })
      }))
    });
  }
}
function getDisabledReasonsArray(paymentMethods, t, variant) {
  return paymentMethods.filter(pm => {
    var _pm$reasons;
    return (pm === null || pm === void 0 || (_pm$reasons = pm.reasons) === null || _pm$reasons === void 0 ? void 0 : _pm$reasons.length) && pm.type !== _api.PiCardType.NEW_PIBA && pm.type !== _api.PiCardType.NEW_CARD;
  }).map(pm => {
    var _pm$reasons2;
    return [pm === null || pm === void 0 || (_pm$reasons2 = pm.reasons) === null || _pm$reasons2 === void 0 ? void 0 : _pm$reasons2[0], pm === null || pm === void 0 ? void 0 : pm.card, pm.reasons];
  }).reduce((acc, curr) => {
    var updated;
    var reasons = [...curr[2]];
    if (t("cc.na.".concat(curr[0])).includes('cardEnding')) {
      var _curr$;
      if (curr[0] === 'CARD_EXPIRED_BEFORE_DEPARTURE' && ((_curr$ = curr[1]) === null || _curr$ === void 0 ? void 0 : _curr$.cardType) === _api.BusinessCardType.BUSINESS_CENTRALLY_STORED_CARD) {
        var _getCardEnding, _curr$2;
        updated = t("cc.na.".concat(curr[0], ".bb")).replace('{cardEnding}', (_getCardEnding = (0, _utils.getCardEnding)((_curr$2 = curr[1]) === null || _curr$2 === void 0 ? void 0 : _curr$2.cardNumber)) !== null && _getCardEnding !== void 0 ? _getCardEnding : '');
      } else if (isPibaNotAllowedForHotel(reasons, variant)) {
        updated = getPibaNotificationText(curr, reasons, t);
      } else {
        var _getCardEnding2, _curr$3;
        updated = t("cc.na.".concat(curr[0])).replace('{cardEnding}', (_getCardEnding2 = (0, _utils.getCardEnding)((_curr$3 = curr[1]) === null || _curr$3 === void 0 ? void 0 : _curr$3.cardNumber)) !== null && _getCardEnding2 !== void 0 ? _getCardEnding2 : '');
      }
    } else {
      updated = t("cc.na.".concat(curr[0]));
    }
    acc.push(updated);
    return acc;
  }, []).filter((x, i, a) => x && a.indexOf(x) == i);
}
function isPibaNotAllowedForHotel(reasons, variant) {
  var pibaNotAllowedReasons = [_api.PIBA_NOT_ALLOWED_HOTEL_KEYS.PIBA_EU_ALLOWED_ONLY_IN_EU, _api.PIBA_NOT_ALLOWED_HOTEL_KEYS.PIBA_UK_ALLOWED_ONLY_IN_UK];
  return variant === _api.Area.BB && reasons.some(reason => pibaNotAllowedReasons.includes(reason));
}
function getPibaNotificationText(curr, reasons, t) {
  var pibaNotAllowedTextObj = {
    PIBA_EU_ALLOWED_ONLY_IN_EU: t("cc.na.".concat(_api.PIBA_NOT_ALLOWED_HOTEL_KEYS.PIBA_EU_ALLOWED_ONLY_IN_EU)),
    PIBA_UK_ALLOWED_ONLY_IN_UK: t("cc.na.".concat(_api.PIBA_NOT_ALLOWED_HOTEL_KEYS.PIBA_UK_ALLOWED_ONLY_IN_UK))
  };
  var updatedValue;
  reasons.forEach(reason => {
    var _pibaNotAllowedTextOb, _getCardEnding3, _curr$4;
    updatedValue = (_pibaNotAllowedTextOb = pibaNotAllowedTextObj[reason]) === null || _pibaNotAllowedTextOb === void 0 ? void 0 : _pibaNotAllowedTextOb.replace('{cardEnding}', (_getCardEnding3 = (0, _utils.getCardEnding)((_curr$4 = curr[1]) === null || _curr$4 === void 0 ? void 0 : _curr$4.cardNumber)) !== null && _getCardEnding3 !== void 0 ? _getCardEnding3 : '');
  });
  return updatedValue;
}
var titleStyle = {
  fontSize: '2xl',
  fontWeight: 'semibold',
  mb: 'md',
  color: 'darkGrey1',
  lineHeight: '2rem'
};
var radioBtnStyle = {
  h: 'fit-content',
  display: 'flex',
  w: 'full'
};
var radioImgStyle = {
  w: '10',
  h: '6',
  mr: 'xs',
  mb: 'xs',
  mt: 'xs'
};
var paymentTypeWrapperStyle = {
  w: {
    mobile: 'full',
    xs: 'full',
    sm: '26.3rem',
    md: '27.563rem',
    lg: '24.5rem',
    xl: '26.25rem'
  },
  mb: '5xl'
};
var radioLabelWrapper = {
  w: {
    mobile: '5.3rem',
    xs: '12rem',
    md: 'full'
  },
  ml: 'sm'
};
var badgeStyles = {
  lineHeight: '2',
  display: 'flex',
  width: 'fit-content',
  alignItems: 'center',
  borderColor: 'maroon',
  color: 'maroon'
};
var textColor = {
  color: 'darkGrey1'
};