"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var overrides = {
  global: {
    '.mapMarker': {
      background: 'var(--chakra-colors-baseWhite)!important',
      padding: '2px 8px',
      border: '1px solid #cccccc',
      boxShadow: '0 1px 2px rgba(204, 204, 204, 0.72)',
      borderRadius: '10px',
      height: '24px',
      width: 'auto',
      minW: '32px',
      fontSize: '14px',
      fontWeight: '700',
      lineHeight: '20px',
      color: 'var(--chakra-colors-darkGrey1) !important',
      transition: '0.5s',
      transform: 'scale(1)'
    },
    '.hoverMarker': {
      transform: 'scale(1.1)'
    },
    '.activeMarker': {
      background: 'var(--chakra-colors-primary)!important',
      color: 'var(--chakra-colors-baseWhite)!important'
    },
    '.activeHover': {
      background: 'var(--chakra-colors-primary)!important',
      color: 'var(--chakra-colors-baseWhite)!important'
    },
    '.soldOut': {
      backgroundColor: 'var(--chakra-colors-lightGrey5) !important'
    },
    '.bart': {
      backgroundColor: 'var(--chakra-colors-zipSecondary) !important'
    },
    '.gm-style-iw': {
      boxShadow: 'none',
      borderRadius: '0 !important',
      left: '0 !important',
      overflow: 'inherit !important',
      padding: '0 !important',
      width: 'auto',
      height: 'auto',
      fontFamily: 'Proxima Nova Sans, helvetica, arial, sans-serif'
    },
    '.gm-style-iw:after': {
      display: 'none'
    },
    '.gm-style-iw > div > div': {
      overflow: 'inherit !important',
      borderRadius: '0 !important'
    },
    '.gm-style-iw a': {
      textDecoration: 'none'
    },
    '.gm-style-iw .gm-style-iw-c': {
      padding: '0 !important',
      borderRadius: '0 !important',
      overflow: 'inherit !important'
    },
    '.gm-style .gm-style-iw-c': {
      minWidth: '288px !important'
    },
    '.gm-style-iw > div': {
      borderRadius: '0 !important'
    },
    '.gm-ui-hover-effect': {
      position: 'absolute !important',
      top: '-27px !important',
      right: '0 !important',
      backgroundColor: 'var(--chakra-colors-darkGrey1)!important',
      width: '24px !important',
      height: '24px !important',
      borderRadius: '100% !important ',
      opacity: '1 !important'
    },
    '.gm-ui-hover-effect > span': {
      backgroundColor: 'var(--chakra-colors-white)!important',
      width: '15px !important',
      height: '15px !important',
      margin: 'auto !important'
    },
    '.gm-style-iw .img_wrapper': {
      overflow: 'hidden',
      textAlign: 'center',
      margin: '0px auto'
    },
    '.gm-style-iw .img_wrapper > img': {
      height: 'auto'
    },
    '.gm-style-iw .property_content_wrap': {
      padding: '0px 20px'
    },
    '.gm-style-iw .property_title': {
      minHeight: 'auto'
    },
    '.gm-style-iw-tc': {
      display: 'none'
    },
    '.gm-style-iw-d': {
      overflow: 'hidden !important',
      maxHeight: 'auto !important'
    },
    '@media screen and (max-width: 768px)': {
      '.gm-style .gm-style-iw-t': {
        bottom: '-15rem !important',
        position: 'fixed !important'
      }
    },
    '.poi-info-window': {
      padding: '12px'
    },
    '.SDYZEU-keyboard-shortcuts-dialog-view': {
      '.gm-ui-hover-effect': {
        backgroundColor: 'var(--chakra-colors-white)!important',
        position: 'static !important',
        borderRadius: '0 !important ',
        opacity: '.6 !important',
        ':hover': {
          opacity: '1 !important'
        },
        '> span': {
          backgroundColor: 'var(--chakra-colors-black)!important',
          width: '24px !important',
          height: '24px !important',
          color: '#000'
        },
        '.focus-visible': {
          outline: '2px solid #007aff',
          outlineOffset: '2px',
          opacity: '1'
        }
      }
    }
  }
};
var _default = exports.default = overrides;