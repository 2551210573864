"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.GET_PROMOTION_PANEL = void 0;
var _taggedTemplateLiteral2 = _interopRequireDefault(require("@babel/runtime/helpers/taggedTemplateLiteral"));
var _graphqlRequest = require("graphql-request");
var _templateObject;
var GET_PROMOTION_PANEL = exports.GET_PROMOTION_PANEL = (0, _graphqlRequest.gql)(_templateObject || (_templateObject = (0, _taggedTemplateLiteral2.default)(["\n  query GetPromotionPanel(\n    $hotelId: String!\n    $language: String!\n    $country: String!\n    $rateCode: String!\n    $bookingChannel: String!\n  ) {\n    promotionPanel(\n      bookingFlowCriteria: {\n        hotelId: $hotelId\n        language: $language\n        country: $country\n        rateCode: $rateCode\n        bookingChannel: $bookingChannel\n      }\n    ) {\n      description\n      image\n      linkLabel\n      linkPath\n      name\n    }\n  }\n"])));