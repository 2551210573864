"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getGQLClient = getGQLClient;
var _graphqlRequest = require("graphql-request");
var _config = _interopRequireDefault(require("next/config"));
var _nodeFetch = require("./nodeFetch");
var cachedBrowserGqlClient = null;
var getGQLClientBrowser = () => {
  if (typeof window === 'undefined') {
    return null;
  }
  if (!cachedBrowserGqlClient) {
    var {
      publicRuntimeConfig = {}
    } = (0, _config.default)() || {};
    cachedBrowserGqlClient = new _graphqlRequest.GraphQLClient(publicRuntimeConfig.NEXT_PUBLIC_GRAPHQL_ENDPOINT || '', {});
    var sessionIdCookie = getCookie('WB-SESSION-ID');
    if (sessionIdCookie) {
      cachedBrowserGqlClient.setHeader('WB-SESSION-ID', sessionIdCookie.value);
    }
  }
  return cachedBrowserGqlClient;
};
var getGQLClientServer = () => {
  var {
    publicRuntimeConfig = {}
  } = (0, _config.default)() || {};
  return new _graphqlRequest.GraphQLClient(publicRuntimeConfig.NEXT_PUBLIC_GRAPHQL_ENDPOINT || '', {
    fetch: (0, _nodeFetch.getNodeFetch)()
  });
};
function getGQLClient() {
  return typeof window !== 'undefined' ? getGQLClientBrowser() : getGQLClientServer();
}
function getCookie(name) {
  var allCookies = document.cookie.split(';').map(cookie => {
    if (!cookie) {
      return null;
    }
    var [name, value] = cookie.split('=');
    return {
      name: name.trim(),
      value: value.trim()
    };
  }).filter(c => c !== null);
  var sessionIdCookie = allCookies.find(c => c && c.name === name);
  return sessionIdCookie;
}