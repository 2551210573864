"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = FreeFoodKidsNotification;
var _react = require("@chakra-ui/react");
var _atoms = require("@whitbread-eos/atoms");
var _utils = require("@whitbread-eos/utils");
var _nextI18next = require("next-i18next");
var _jsxRuntime = require("react/jsx-runtime");
function FreeFoodKidsNotification(_ref) {
  var {
    prefixDataTestId
  } = _ref;
  var {
    t
  } = (0, _nextI18next.useTranslation)(['common']);
  var baseDataTestId = (0, _utils.formatDataTestId)(prefixDataTestId, 'FreeFoodKidsNotification');
  return (0, _jsxRuntime.jsx)(_react.Box, {
    "data-testid": (0, _utils.formatDataTestId)(baseDataTestId, 'Wrapper'),
    children: (0, _jsxRuntime.jsx)(_atoms.Notification, {
      maxWidth: "full",
      variant: "info",
      status: "info",
      title: t('upsell.notification.kids.header'),
      description: t('upsell.notification.kids.subheader'),
      svg: (0, _jsxRuntime.jsx)(_atoms.Info, {})
    })
  });
}