"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "StaticTabsTrigger", {
  enumerable: true,
  get: function get() {
    return _tabs.StaticTabsTrigger;
  }
});
Object.defineProperty(exports, "Tabs", {
  enumerable: true,
  get: function get() {
    return _tabs.Tabs;
  }
});
Object.defineProperty(exports, "TabsContent", {
  enumerable: true,
  get: function get() {
    return _tabs.TabsContent;
  }
});
Object.defineProperty(exports, "TabsList", {
  enumerable: true,
  get: function get() {
    return _tabs.TabsList;
  }
});
Object.defineProperty(exports, "TabsTrigger", {
  enumerable: true,
  get: function get() {
    return _tabs.TabsTrigger;
  }
});
var _tabs = require("./tabs");