"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = AuthSubNav;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _react = require("@chakra-ui/react");
var _link = _interopRequireDefault(require("next/link"));
var _NavigationMenu = require("../NavigationMenu.style");
var _jsxRuntime = require("react/jsx-runtime");
var _react2 = require("react");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function AuthSubNav(_ref) {
  var {
    labels,
    onLogout
  } = _ref;
  var accountLinks = labels === null || labels === void 0 ? void 0 : labels.accountLinks;
  return (0, _jsxRuntime.jsx)(_react.Flex, _objectSpread(_objectSpread({}, _NavigationMenu.businessListStyles), {}, {
    children: (0, _jsxRuntime.jsxs)(_react.List, _objectSpread(_objectSpread({}, _NavigationMenu.listStyles), {}, {
      children: [accountLinks === null || accountLinks === void 0 ? void 0 : accountLinks.map(accountLink => (0, _react2.createElement)(_react.ListItem, _objectSpread(_objectSpread({}, _NavigationMenu.listItemStyles), {}, {
        "data-testid": "listItem",
        key: accountLink.title
      }), (0, _jsxRuntime.jsx)(_link.default, {
        href: accountLink.url,
        legacyBehavior: true,
        children: (0, _jsxRuntime.jsx)(_react.Text, _objectSpread(_objectSpread({}, _NavigationMenu.linkStyles), {}, {
          "data-testid": "".concat(accountLink.title, "Button"),
          children: accountLink.title
        }))
      }))), (0, _jsxRuntime.jsx)(_react.ListItem, _objectSpread(_objectSpread({}, _NavigationMenu.listItemStyles), {}, {
        "data-testid": "listItem",
        children: (0, _jsxRuntime.jsx)(_react.Link, {
          onClick: onLogout,
          "data-testid": "Global-Logout-Desktop",
          children: labels.logOut
        })
      }))]
    }))
  }));
}