"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.isArrivalDateWithinSetHours = isArrivalDateWithinSetHours;
exports.updateSilentSubstLocalStorage = updateSilentSubstLocalStorage;
var _api = require("@whitbread-eos/api");
var _differenceInHours = _interopRequireDefault(require("date-fns/differenceInHours"));
function isArrivalDateWithinSetHours(arrivalDate, setHours) {
  var currentDate = new Date();
  currentDate.setHours(0, 0, 0, 0);
  var arrival = new Date(arrivalDate);
  arrival.setHours(0, 0, 0, 0);
  return (0, _differenceInHours.default)(arrival, currentDate) <= setHours;
}
function updateSilentSubstLocalStorage(basketReference, roomsLabelsForSilentSubst) {
  var silentSubstitutionData = {};
  var storedData = window.localStorage.getItem(_api.SILENT_SUBSTITUTION_STORAGE_KEY);
  var currentTime = new Date().getTime();
  if (storedData) {
    silentSubstitutionData = JSON.parse(storedData);
    Object.keys(silentSubstitutionData).forEach(key => {
      var item = silentSubstitutionData[key];
      if (item.expire && item.expire < currentTime) {
        delete silentSubstitutionData[key];
      }
    });
  }
  var expirationTime = 30 * 60 * 1000;
  var newRecord = {
    value: roomsLabelsForSilentSubst,
    expire: currentTime + expirationTime
  };
  silentSubstitutionData[basketReference] = newRecord;
  window.localStorage.setItem(_api.SILENT_SUBSTITUTION_STORAGE_KEY, JSON.stringify(silentSubstitutionData));
}