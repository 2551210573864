"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _react = require("@chakra-ui/react");
var _atoms = require("@whitbread-eos/atoms");
var _nextI18next = require("next-i18next");
var _common = require("./common");
var _jsxRuntime = require("react/jsx-runtime");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function PreCheckInSuccessModal(_ref) {
  var {
    isOpen,
    onClose,
    messageTag = 'precheckin.complete.msg',
    overlayStyles = {}
  } = _ref;
  var {
    t
  } = (0, _nextI18next.useTranslation)();
  var baseDataTestId = 'PrecheckInSuccess';
  return (0, _jsxRuntime.jsx)(_atoms.ModalVariants, {
    isOpen: isOpen,
    onClose: onClose,
    variant: "default",
    variantProps: {
      title: '',
      delimiter: false
    },
    headerStyles: {
      textAlign: 'center',
      justifyContent: 'flex-end'
    },
    updatedWidth: {
      sm: '100%',
      md: '37.5rem',
      lg: '37.5rem',
      xl: '39.93rem'
    },
    overlayStyles: overlayStyles,
    children: (0, _jsxRuntime.jsxs)(_react.Flex, _objectSpread(_objectSpread({}, _common.modalContentStyles), {}, {
      children: [(0, _jsxRuntime.jsx)(_react.Heading, _objectSpread(_objectSpread({
        as: "h2"
      }, _common.headingStyles), {}, {
        children: t('precheckin.success.title')
      })), (0, _jsxRuntime.jsx)(_atoms.Notification, {
        status: "success",
        variant: "success",
        svg: (0, _jsxRuntime.jsx)(_atoms.Success, {
          style: {
            marginTop: 'md'
          }
        }),
        prefixDataTestId: "".concat(baseDataTestId, "-modal"),
        description: t(messageTag),
        wrapperStyles: {
          w: 'lg',
          m: 'md',
          borderRadius: '0.9'
        }
      }), (0, _jsxRuntime.jsx)(_react.Flex, _objectSpread(_objectSpread({}, _common.modalButtonsContainerStyles), {}, {
        children: (0, _jsxRuntime.jsx)(_react.Button, _objectSpread(_objectSpread({
          "data-testid": "PreCheckIn-ModalCloseButton",
          size: "xsm",
          marginTop: 'lg',
          onClick: onClose
        }, _common.modalButtonsStyles), {}, {
          children: t('precheckin.closebutton')
        }))
      }))]
    }))
  });
}
var _default = exports.default = PreCheckInSuccessModal;