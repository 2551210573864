"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.GET_AMEND_CONFIRMATION_PRICES = void 0;
var _taggedTemplateLiteral2 = _interopRequireDefault(require("@babel/runtime/helpers/taggedTemplateLiteral"));
var _graphqlRequest = require("graphql-request");
var _templateObject;
var GET_AMEND_CONFIRMATION_PRICES = exports.GET_AMEND_CONFIRMATION_PRICES = (0, _graphqlRequest.gql)(_templateObject || (_templateObject = (0, _taggedTemplateLiteral2.default)(["\n  query AmendConfirmationPrices(\n    $originalBookingRef: String!\n    $tempBookingRef: String!\n    $token: String!\n  ) {\n    amendConfirmationPrices(\n      amendConfirmationPricesRequest: {\n        originalBookingRef: $originalBookingRef\n        tempBookingRef: $tempBookingRef\n        token: $token\n      }\n    ) {\n      previousTotal\n      newTotalCost\n      outstandingBalance\n    }\n  }\n"])));