"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _cookies = _interopRequireDefault(require("cookies"));
var _getters = require("../getters");
var _logger = require("./logger");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
class QueriesLogger {
  constructor(queryClient, req, res, query, label, ccuiSession) {
    var _this = this;
    (0, _defineProperty2.default)(this, "queries", []);
    var cookies = new _cookies.default(req, res);
    var idTokenCookie = cookies.get(_getters.ID_TOKEN_COOKIE);
    var sessionTracing = (0, _logger.getDefaultSessionTracing)(cookies);
    this.queryClient = queryClient;
    this.query = query;
    this.label = label;
    this.loggingObject = {
      label: label,
      pageLink: req.url,
      params: query,
      idTokenCookie,
      ccuiSession,
      sessionTracing
    };
    var startExecutionTime = performance.now();
    this.prefetchQuery = function () {
      var fn = _this.instrument(queryClient.prefetchQuery.bind(queryClient));
      return fn({
        queryKey: arguments.length <= 0 ? undefined : arguments[0],
        queryFn: arguments.length <= 1 ? undefined : arguments[1]
      });
    };
    this.fetchQuery = function () {
      var fn = _this.instrument(queryClient.fetchQuery.bind(queryClient));
      return fn({
        queryKey: arguments.length <= 0 ? undefined : arguments[0],
        queryFn: arguments.length <= 1 ? undefined : arguments[1]
      });
    };
    this.logQueries = endExecutionTime => {
      var _this$loggingObject$c, _this$loggingObject;
      var {
        label,
        pageLink,
        params,
        idTokenCookie,
        sessionTracing
      } = this.loggingObject;
      var {
        name,
        profile: {
          sessionId,
          isBusiness,
          accessLevel,
          employeeId,
          companyId
        },
        cdhCompanyId,
        cdhEmployeeId,
        operaCompanyId
      } = (0, _getters.getLoggedInUserInfo)(idTokenCookie !== null && idTokenCookie !== void 0 ? idTokenCookie : '');
      var userInfo = {
        name: (_this$loggingObject$c = (_this$loggingObject = this.loggingObject) === null || _this$loggingObject === void 0 ? void 0 : _this$loggingObject.ccuiSession) !== null && _this$loggingObject$c !== void 0 ? _this$loggingObject$c : name,
        sessionId,
        isBusiness,
        accessLevel,
        employeeId,
        companyId,
        cdhCompanyId,
        cdhEmployeeId,
        operaCompanyId
      };
      return _logger.logger.info(_objectSpread({
        label,
        pageLink,
        params,
        userInfo,
        totalExecutionTime: endExecutionTime - startExecutionTime,
        queries: this.queries
      }, sessionTracing));
    };
  }
  instrument(fn) {
    var _this2 = this;
    return function () {
      var _ref = (0, _asyncToGenerator2.default)(function* (options) {
        var start = performance.now();
        var queryDetails = {
          executionTime: 0,
          error: ''
        };
        try {
          return fn(options);
        } catch (err) {
          queryDetails.error = err;
        } finally {
          queryDetails.executionTime = performance.now() - start;
          _this2.queries.push(_objectSpread({
            queryKey: options.queryKey
          }, queryDetails));
        }
      });
      return function (_x) {
        return _ref.apply(this, arguments);
      };
    }();
  }
}
exports.default = QueriesLogger;