"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = HotelRoomsContainer;
var _api = require("@whitbread-eos/api");
var _utils = require("@whitbread-eos/utils");
var _HotelRooms = _interopRequireDefault(require("./HotelRooms.component"));
var _jsxRuntime = require("react/jsx-runtime");
function PremierInnHotelRooms(_ref) {
  var {
    isPremierInn,
    isLessThanSm,
    isLessThanMd,
    isLessThanLg,
    isDisplayRates
  } = _ref;
  var {
    roomConfiguration,
    isLoading,
    isError,
    error
  } = (0, _utils.useStaticHotelInformation)();
  return (0, _jsxRuntime.jsx)(_HotelRooms.default, {
    isLoading,
    isError,
    error,
    data: roomConfiguration,
    isPremierInn,
    isLessThanSm,
    isLessThanMd,
    isLessThanLg,
    isDisplayRates
  });
}
function CCUIHotelRooms(_ref2) {
  var {
    isPremierInn,
    isLessThanSm,
    isLessThanMd,
    isLessThanLg,
    arrival: dateRangeStart,
    departure: dateRangeEnd
  } = _ref2;
  var {
    language,
    country
  } = (0, _utils.useCustomLocale)();
  var {
    roomConfiguration,
    hotelId,
    brand,
    isLoading,
    isError,
    error
  } = (0, _utils.useStaticHotelInformation)();
  var queryEnabled = dateRangeStart !== undefined && dateRangeEnd !== undefined;
  var {
    isLoading: isLoadingHotelInventory,
    isError: isErrorHotelInventory,
    data: dataHotelInventory,
    error: errorHotelInventory
  } = (0, _utils.useQueryRequest)(['getHotelInventory', hotelId, dateRangeEnd, dateRangeStart], _api.GET_HOTEL_INVENTORY_QUERY, {
    hotelId,
    dateRangeEnd,
    dateRangeStart
  }, {
    gcTime: 0,
    enabled: !!queryEnabled
  });
  var hotelInventoryResponse = {
    isLoadingHotelInventory,
    isErrorHotelInventory,
    dataHotelInventory: dataHotelInventory,
    errorHotelInventory
  };
  var {
    isLoading: isLoadingRoomTypeInformation,
    isError: isErrorRoomTypeInformation,
    data: dataRoomTypeInformation,
    error: errorRoomTypeInformation
  } = (0, _utils.useQueryRequest)(['getRoomTypeInformation', language, country, brand, hotelId], _api.GET_ROOM_TYPE_INFORMATION_QUERY, {
    language,
    country,
    brand,
    hotelId
  });
  var roomTypeInformationResponse = {
    isLoadingRoomTypeInformation,
    isErrorRoomTypeInformation,
    dataRoomTypeInformation: dataRoomTypeInformation,
    errorRoomTypeInformation
  };
  return (0, _jsxRuntime.jsx)(_HotelRooms.default, {
    isLoading,
    isError,
    error,
    data: roomConfiguration,
    hotelInventoryResponse,
    roomTypeInformationResponse,
    isPremierInn,
    isLessThanSm,
    isLessThanMd,
    isLessThanLg
  });
}
function HotelRoomsContainer(_ref3) {
  var {
    isPremierInn,
    isLessThanSm,
    isLessThanMd,
    isLessThanLg,
    arrival,
    departure,
    isDisplayRates
  } = _ref3;
  return isPremierInn ? (0, _jsxRuntime.jsx)(PremierInnHotelRooms, {
    isPremierInn,
    isLessThanSm,
    isLessThanMd,
    isLessThanLg,
    isDisplayRates
  }) : (0, _jsxRuntime.jsx)(CCUIHotelRooms, {
    isPremierInn,
    isLessThanSm,
    isLessThanMd,
    isLessThanLg,
    arrival,
    departure
  });
}