"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = useStaticHotelInformation;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _api = require("@whitbread-eos/api");
var _router = require("next/router");
var _useCustomLocale = _interopRequireDefault(require("./use-custom-locale"));
var _useRequest = require("./use-request");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function useStaticHotelInformation() {
  var router = (0, _router.useRouter)();
  var slug = ['/hotels', ...router.query.slug].join('/');
  var {
    country,
    language
  } = (0, _useCustomLocale.default)();
  var {
    isLoading,
    isError,
    data,
    error
  } = (0, _useRequest.useQueryRequest)(['staticHotelInformation', language, country, slug], _api.STATIC_HOTEL_INFORMATION_QUERY, {
    slug,
    language,
    country
  });
  var hotelInformationData = data === null || data === void 0 ? void 0 : data.hotelInformationBySlug;
  return _objectSpread(_objectSpread({
    isLoading,
    isError
  }, hotelInformationData), {}, {
    error
  });
}