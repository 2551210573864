"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.GET_COOKIE_CONSENT_INFO = void 0;
var _taggedTemplateLiteral2 = _interopRequireDefault(require("@babel/runtime/helpers/taggedTemplateLiteral"));
var _graphqlRequest = require("graphql-request");
var _templateObject;
var GET_COOKIE_CONSENT_INFO = exports.GET_COOKIE_CONSENT_INFO = (0, _graphqlRequest.gql)(_templateObject || (_templateObject = (0, _taggedTemplateLiteral2.default)(["\n  query getCookieConsentInfo($country: String!, $language: String!, $brand: String!) {\n    cookieConsent(brand: $brand, country: $country, language: $language) {\n      cookiePolicies {\n        brand\n        introView {\n          acceptAllButtonText\n          description\n          manageButtonText\n          title\n        }\n        manageView {\n          alwaysActiveText\n          description\n          saveSettingsButtonText\n          title\n          cookieGroup {\n            cookieName\n            description\n            isAlwaysActive\n            title\n            toggleLabel\n          }\n        }\n      }\n    }\n  }\n"])));