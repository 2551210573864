"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.setFindButtonStyles = exports.searchInputStyles = exports.paragraphStyles = exports.inputWithErrorStyles = exports.inputStyles = exports.inputIconStyles = exports.inputElementStyles = exports.datepickerInputStyle = exports.containerWithErrorStyles = exports.containerStyles = exports.clearTextStyle = exports.clearButton = exports.buttonsWrapperStyles = void 0;
var containerStyles = exports.containerStyles = {
  mb: {
    mobile: 'xl',
    sm: '3xl'
  }
};
var containerWithErrorStyles = exports.containerWithErrorStyles = {
  mb: 'lg'
};
var inputWithErrorStyles = exports.inputWithErrorStyles = {
  mb: 'md'
};
var inputStyles = exports.inputStyles = {
  height: 'var(--chakra-space-4xl)',
  borderColor: 'var(--chakra-colors-lightGrey1)',
  _placeholder: {
    opacity: 0.7,
    color: 'var(--chakra-darkGrey1)'
  },
  _active: {
    borderColor: 'var(--chakra-colors-darkGrey1)'
  },
  _hover: {
    borderColor: 'var(--chakra-colors-darkGrey1)'
  }
};
var inputIconStyles = exports.inputIconStyles = {
  top: 'var(--chakra-space-sm)'
};
var datepickerInputStyle = exports.datepickerInputStyle = {
  w: {
    mobile: '100%',
    lg: '27.25rem',
    xl: '30.5rem'
  },
  mr: {
    mobile: '0px',
    lg: '1.913rem',
    xl: 'lg'
  },
  h: '56px',
  mb: {
    mobile: 'lg',
    sm: '1.563rem',
    lg: 'unset'
  }
};
var clearTextStyle = exports.clearTextStyle = {
  color: 'btnSecondaryEnabled',
  fontWeight: 'semibold',
  fontSize: 'md',
  lineHeight: '3',
  _hover: {
    cursor: 'pointer'
  }
};
var clearButton = exports.clearButton = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  w: {
    mobile: '50%',
    lg: 'unset'
  },
  h: '3.5rem'
};
var setFindButtonStyles = language => {
  return {
    w: {
      mobile: '100%',
      xs: '100%',
      sm: '50%',
      md: '50%',
      lg: language === 'de' ? '9.625rem' : '10.625rem'
    },
    mr: {
      mobile: '0px',
      lg: '1.913rem',
      xl: 'lg'
    }
  };
};
exports.setFindButtonStyles = setFindButtonStyles;
var paragraphStyles = exports.paragraphStyles = {
  mt: 'md',
  mb: 'md'
};
var searchInputStyles = exports.searchInputStyles = {
  w: {
    mobile: '100%',
    lg: '27.25rem',
    xl: '30.5rem'
  },
  mr: {
    mobile: '0px',
    lg: '1.913rem',
    xl: 'lg'
  },
  mb: {
    mobile: 'lg',
    sm: '1.563rem',
    lg: 'unset'
  }
};
var buttonsWrapperStyles = exports.buttonsWrapperStyles = {
  display: 'flex',
  flexDirection: {
    mobile: 'column',
    sm: 'row'
  },
  alignItems: 'center',
  w: {
    mobile: '100%',
    lg: 'unset'
  },
  h: {
    sm: '3.5rem'
  }
};
var inputElementStyles = exports.inputElementStyles = {
  borderColor: 'var(--chakra-colors-lightGrey1)',
  _hover: {
    borderColor: 'var(--chakra-colors-darkGrey1)'
  },
  _active: {
    borderColor: 'var(--chakra-colors-darkGrey1)'
  }
};