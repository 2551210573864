"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = PasswordCheckOverlay;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _react = require("@chakra-ui/react");
var _atoms = require("@whitbread-eos/atoms");
var _nextI18next = require("next-i18next");
var _react2 = _interopRequireDefault(require("react"));
var _jsxRuntime = require("react/jsx-runtime");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function PasswordCheckOverlay(_ref) {
  var {
    isOpenOverlay,
    setIsOpenOverlay
  } = _ref;
  var {
    t
  } = (0, _nextI18next.useTranslation)('common');
  var baseTestId = 'passwordCheckOverlay';
  return (0, _jsxRuntime.jsx)(_react.Box, {
    children: (0, _jsxRuntime.jsx)(_atoms.ModalVariants, {
      variant: "cookie",
      isOpen: isOpenOverlay,
      closeOnOverlayClick: false,
      onClose: () => setIsOpenOverlay(isOpenOverlay),
      variantProps: {
        title: t('ccui.passwordCheckOverlay.title')
      },
      dataTestId: "".concat(baseTestId, "_passwordCheck"),
      hasAutoFocus: false,
      children: (0, _jsxRuntime.jsxs)(_react.Flex, _objectSpread(_objectSpread({}, modalWrapperStyles), {}, {
        children: [(0, _jsxRuntime.jsx)(_react.Flex, {
          color: "darkGrey1",
          "data-testid": "".concat(baseTestId, "_passwordCheck_description"),
          children: t('ccui.passwordCheckOverlay.text')
        }), (0, _jsxRuntime.jsx)(_react.Box, _objectSpread(_objectSpread({}, modalButtonWrapperStyles), {}, {
          children: (0, _jsxRuntime.jsx)(_react.Button, {
            width: "100%",
            variant: "primary",
            mr: "1rem",
            onClick: () => setIsOpenOverlay(false),
            "data-testid": "".concat(baseTestId, "_okButton"),
            children: t('ccui.passwordCheckOverlay.okButton')
          })
        }))]
      }))
    })
  });
}
var modalWrapperStyles = {
  mt: 'md',
  mb: 'lg',
  mx: 'lg',
  w: {
    mobile: 'full',
    xs: 'full',
    sm: '25.063rem',
    md: '24.5rem',
    lg: '34.5rem',
    xl: '33rem'
  },
  flexDirection: 'column'
};
var modalButtonWrapperStyles = {
  mt: 'lg',
  justifyContent: 'space-around',
  alignItems: 'center'
};