"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _react = require("@chakra-ui/react");
var _api = require("@whitbread-eos/api");
var _atoms = require("@whitbread-eos/atoms");
var _utils = require("@whitbread-eos/utils");
var _nextI18next = require("next-i18next");
var _react2 = require("react");
var _jsxRuntime = require("react/jsx-runtime");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function CountrySelector(_ref) {
  var _countries$find;
  var {
    onChange: _onChange,
    hasError,
    selectedId,
    showIcon = true,
    styles,
    isDisabled
  } = _ref;
  var {
    t
  } = (0, _nextI18next.useTranslation)();
  var {
    language: currentLang,
    country: currentCountry
  } = (0, _utils.useCustomLocale)();
  var emptyCountry = {
    countryCode: '',
    countryCodeLegacy: '',
    countryName: '',
    passportRequired: false,
    dialingCode: '',
    flagSrc: ''
  };
  var [countries, setCountries] = (0, _react2.useState)();
  var [selectedCountry, setSelectedCountry] = (0, _react2.useState)(emptyCountry);
  var {
    data: countriesData,
    isSuccess: countriesRequestSuccess
  } = (0, _utils.useQueryRequest)(['GetCountries', currentCountry, currentLang, 'leisure'], _api.GET_COUNTRIES, {
    country: currentCountry,
    language: currentLang,
    site: 'leisure'
  });
  var [isDropdownOpen, setIsDropdownOpen] = (0, _react2.useState)(false);
  (0, _react2.useEffect)(() => {
    if (countriesRequestSuccess && !(countries !== null && countries !== void 0 && countries.length)) {
      var _countriesData$countr;
      var sortedCountries = (0, _utils.getSortedCountriesByCurrentLang)(countriesData === null || countriesData === void 0 || (_countriesData$countr = countriesData.countries) === null || _countriesData$countr === void 0 ? void 0 : _countriesData$countr.countries, currentLang);
      setCountries(sortedCountries || []);
      if (selectedId) {
        setDefaultCountry(sortedCountries, selectedId);
      } else {
        setDefaultCountry(sortedCountries, currentLang === 'en' ? 'GB' : 'DE');
      }
    }
  }, [countriesRequestSuccess, countriesData, selectedId]);
  return (0, _jsxRuntime.jsx)(_atoms.Dropdown, {
    dropdownStyles: _objectSpread({
      menuListStyles
    }, styles),
    dataTestId: "Country",
    label: t('booking.country'),
    placeholder: countries === null || countries === void 0 || (_countries$find = countries.find(country => country.countryCode === selectedCountry.countryCode)) === null || _countries$find === void 0 ? void 0 : _countries$find.countryName,
    isOpenMenu: isDropdownOpen,
    showStatusIcon: showIcon,
    icon: (0, _jsxRuntime.jsx)(_react.Image, {
      alt: selectedCountry === null || selectedCountry === void 0 ? void 0 : selectedCountry.countryName,
      borderRadius: "full",
      boxSize: "1.5rem",
      src: (0, _utils.formatAssetsUrl)(selectedCountry === null || selectedCountry === void 0 ? void 0 : selectedCountry.flagSrc)
    }),
    onChange: country => {
      setSelectedCountry(country);
      setIsDropdownOpen(false);
      _onChange(country.id);
    },
    hasError: hasError,
    options: countries === null || countries === void 0 ? void 0 : countries.map(_ref2 => {
      var {
        countryCode,
        countryName,
        flagSrc
      } = _ref2;
      return {
        label: countryName,
        id: countryCode,
        icon: (0, _jsxRuntime.jsx)(_react.Image, {
          alt: countryName,
          borderRadius: "full",
          boxSize: "1.5rem",
          src: (0, _utils.formatAssetsUrl)(flagSrc)
        })
      };
    }),
    selectedId: selectedId,
    disabled: isDisabled,
    children: _atoms.DropdownCustomContent
  });
  function setDefaultCountry(countries, countryCode) {
    var country = countries === null || countries === void 0 ? void 0 : countries.find(country => country.countryCode === countryCode);
    if (!country && selectedId) {
      setDefaultCountryByLegacyCode(countries, selectedId);
    } else {
      setSelectedCountry(country || emptyCountry);
      _onChange(country ? country.countryCode : {});
    }
  }
  function setDefaultCountryByLegacyCode(countries, countryCode) {
    var country = countries === null || countries === void 0 ? void 0 : countries.find(country => country.countryCodeLegacy === countryCode);
    setSelectedCountry(country || emptyCountry);
    _onChange(country ? country.countryCode : {});
  }
}
var menuListStyles = {
  maxH: 'var(--chakra-space-52)'
};
var _default = exports.default = (0, _react2.memo)(CountrySelector);