"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = RoomModalContainer;
var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _reactQuery = require("@tanstack/react-query");
var _api = require("@whitbread-eos/api");
var _utils = require("@whitbread-eos/utils");
var _react = require("react");
var _utilities = require("../utilities");
var _RoomModal = _interopRequireDefault(require("./RoomModal.component"));
var _constants = require("./constants");
var _jsxRuntime = require("react/jsx-runtime");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function RoomModalContainer(_ref) {
  var _ref2, _userData$operaCompan, _reservationGuestList19, _reservationGuestList20, _reservationGuestList21, _reservationGuestList22, _reservationGuestList23, _reservationGuestList24, _roomDetailsEdit$adul;
  var {
    isOpen,
    onClose,
    title,
    roomRules,
    hotelAvailabilityParams,
    labels,
    baseDataTestId,
    onSaveNewRoom,
    isEdit = false,
    roomDetailsEdit,
    price,
    reservationGuestList,
    onUpdateRoom,
    reservationId,
    roomNumber,
    variant,
    brand,
    hotelCountry,
    language
  } = _ref;
  var queryClient = (0, _reactQuery.useQueryClient)();
  var idTokenCookie = (0, _utils.getAuthCookie)();
  var userData = (0, _utils.getLoggedInUserInfo)(idTokenCookie);
  var companyId = (_ref2 = (_userData$operaCompan = userData.operaCompanyId) !== null && _userData$operaCompan !== void 0 ? _userData$operaCompan : hotelAvailabilityParams.companyId) !== null && _ref2 !== void 0 ? _ref2 : '';
  var currentBookingReservationChannel = hotelAvailabilityParams.channel;
  var discountRateCompanyId = (0, _utils.useGetDiscountRateComapnyId)(hotelAvailabilityParams);
  var {
    roomDropdownLabels,
    roomAvailabilityLabels,
    leadGuestLabels,
    leadGuestValidationLabels,
    notificationLabels,
    roomDropdownRoomCodes
  } = labels;
  var {
    single,
    double,
    accessible,
    family,
    twin
  } = roomDropdownLabels;
  var ROOM_LABELS = {
    single,
    double,
    accessible,
    twin,
    family
  };
  var roomCodes = (0, _utils.swapKeysAndValues)(roomDropdownRoomCodes);
  var roomModalLabels = {
    roomAvailabilityLabels,
    leadGuestLabels,
    leadGuestValidationLabels,
    notificationLabels
  };
  var initialEditGuestDetails = (0, _react.useMemo)(() => {
    var _reservationGuestList, _reservationGuestList2, _reservationGuestList3, _reservationGuestList4, _reservationGuestList5, _reservationGuestList6, _reservationGuestList7, _reservationGuestList8, _reservationGuestList9, _reservationGuestList10, _reservationGuestList11, _reservationGuestList12, _reservationGuestList13, _reservationGuestList14, _reservationGuestList15, _reservationGuestList16, _reservationGuestList17, _reservationGuestList18;
    return {
      title: (0, _utils.upperOnlyFirst)((_reservationGuestList = reservationGuestList === null || reservationGuestList === void 0 ? void 0 : reservationGuestList.nameTitle) !== null && _reservationGuestList !== void 0 ? _reservationGuestList : ''),
      firstName: (_reservationGuestList2 = reservationGuestList === null || reservationGuestList === void 0 ? void 0 : reservationGuestList.givenName) !== null && _reservationGuestList2 !== void 0 ? _reservationGuestList2 : '',
      lastName: (_reservationGuestList3 = reservationGuestList === null || reservationGuestList === void 0 ? void 0 : reservationGuestList.surName) !== null && _reservationGuestList3 !== void 0 ? _reservationGuestList3 : '',
      emailAddress: (_reservationGuestList4 = reservationGuestList === null || reservationGuestList === void 0 ? void 0 : reservationGuestList.email) !== null && _reservationGuestList4 !== void 0 ? _reservationGuestList4 : '',
      addressLine1: (_reservationGuestList5 = reservationGuestList === null || reservationGuestList === void 0 || (_reservationGuestList6 = reservationGuestList.address) === null || _reservationGuestList6 === void 0 ? void 0 : _reservationGuestList6.addressLine1) !== null && _reservationGuestList5 !== void 0 ? _reservationGuestList5 : '',
      addressLine2: (_reservationGuestList7 = reservationGuestList === null || reservationGuestList === void 0 || (_reservationGuestList8 = reservationGuestList.address) === null || _reservationGuestList8 === void 0 ? void 0 : _reservationGuestList8.addressLine2) !== null && _reservationGuestList7 !== void 0 ? _reservationGuestList7 : '',
      addressLine3: (_reservationGuestList9 = reservationGuestList === null || reservationGuestList === void 0 || (_reservationGuestList10 = reservationGuestList.address) === null || _reservationGuestList10 === void 0 ? void 0 : _reservationGuestList10.addressLine3) !== null && _reservationGuestList9 !== void 0 ? _reservationGuestList9 : '',
      postalCode: (_reservationGuestList11 = reservationGuestList === null || reservationGuestList === void 0 || (_reservationGuestList12 = reservationGuestList.address) === null || _reservationGuestList12 === void 0 ? void 0 : _reservationGuestList12.postalCode) !== null && _reservationGuestList11 !== void 0 ? _reservationGuestList11 : '',
      city: (_reservationGuestList13 = reservationGuestList === null || reservationGuestList === void 0 || (_reservationGuestList14 = reservationGuestList.address) === null || _reservationGuestList14 === void 0 ? void 0 : _reservationGuestList14.cityName) !== null && _reservationGuestList13 !== void 0 ? _reservationGuestList13 : '',
      cityName: (_reservationGuestList15 = reservationGuestList === null || reservationGuestList === void 0 || (_reservationGuestList16 = reservationGuestList.address) === null || _reservationGuestList16 === void 0 ? void 0 : _reservationGuestList16.cityName) !== null && _reservationGuestList15 !== void 0 ? _reservationGuestList15 : '',
      countryCode: (_reservationGuestList17 = reservationGuestList === null || reservationGuestList === void 0 || (_reservationGuestList18 = reservationGuestList.address) === null || _reservationGuestList18 === void 0 ? void 0 : _reservationGuestList18.countryCode) !== null && _reservationGuestList17 !== void 0 ? _reservationGuestList17 : ''
    };
  }, [reservationGuestList === null || reservationGuestList === void 0 ? void 0 : reservationGuestList.email, reservationGuestList === null || reservationGuestList === void 0 ? void 0 : reservationGuestList.givenName, reservationGuestList === null || reservationGuestList === void 0 ? void 0 : reservationGuestList.nameTitle, reservationGuestList === null || reservationGuestList === void 0 ? void 0 : reservationGuestList.surName, reservationGuestList === null || reservationGuestList === void 0 || (_reservationGuestList19 = reservationGuestList.address) === null || _reservationGuestList19 === void 0 ? void 0 : _reservationGuestList19.addressLine1, reservationGuestList === null || reservationGuestList === void 0 || (_reservationGuestList20 = reservationGuestList.address) === null || _reservationGuestList20 === void 0 ? void 0 : _reservationGuestList20.addressLine2, reservationGuestList === null || reservationGuestList === void 0 || (_reservationGuestList21 = reservationGuestList.address) === null || _reservationGuestList21 === void 0 ? void 0 : _reservationGuestList21.addressLine3, reservationGuestList === null || reservationGuestList === void 0 || (_reservationGuestList22 = reservationGuestList.address) === null || _reservationGuestList22 === void 0 ? void 0 : _reservationGuestList22.postalCode, reservationGuestList === null || reservationGuestList === void 0 || (_reservationGuestList23 = reservationGuestList.address) === null || _reservationGuestList23 === void 0 ? void 0 : _reservationGuestList23.cityName, reservationGuestList === null || reservationGuestList === void 0 || (_reservationGuestList24 = reservationGuestList.address) === null || _reservationGuestList24 === void 0 ? void 0 : _reservationGuestList24.countryCode]);
  var roomDetails = (0, _react.useRef)(_constants.INITIAL_ROOM_DETAILS);
  var isAdultsDecreased = (0, _react.useRef)(false);
  var isAvailabilityButtonEnabled = (0, _react.useRef)(true);
  var leadGuestDetails = (0, _react.useRef)(_constants.INITIAL_LEAD_GUEST_DETAILS);
  var initialRoomType = (0, _react.useRef)(roomDetails.current.roomType);
  var initialGuestsNumber = (0, _react.useRef)((_roomDetailsEdit$adul = roomDetailsEdit === null || roomDetailsEdit === void 0 ? void 0 : roomDetailsEdit.adults) !== null && _roomDetailsEdit$adul !== void 0 ? _roomDetailsEdit$adul : roomDetails.current.adults);
  var bbEmployeeList = (0, _react.useRef)([]);
  var [guestDetails, setGuestDetails] = (0, _react.useState)(initialEditGuestDetails);
  var [isLocationRequired, setIsLocationRequired] = (0, _react.useState)(false);
  var [isHotelAvailable, setIsHotelAvailable] = (0, _react.useState)({
    displayNotification: false,
    available: false
  });
  var [roomOccupancyDetails, setRoomOccupancyDetails] = (0, _react.useState)(_constants.INITIAL_ROOM_OCCUPANCY);
  var [specialRequests, setSpecialRequests] = (0, _react.useState)([]);
  var [isBbGuestEdited, setIsBbGuestEdited] = (0, _react.useState)(false);
  var reservationRoom = {
    adults: roomDetails.current.adults,
    children: roomDetails.current.children
  };
  var roomTypeOptions = (0, _utils.getRoomTypeOptions)(reservationRoom, roomRules.roomOccupancyLimitations.roomOccupancies, ROOM_LABELS, roomCodes).flat();
  (0, _react.useEffect)(() => {
    if (variant === _api.Area.BB && isEdit) {
      (0, _utils.getListOfEmployees)({
        awaitingApproval: false,
        bookingChannel: 'CBT',
        page: 1,
        searchCriteria: '',
        size: 10,
        queryClient: queryClient
      }).then(data => {
        bbEmployeeList.current = (0, _utils.generateSuggestionList)(data, '', []);
      });
    }
  }, [isEdit, variant, queryClient]);
  (0, _react.useEffect)(() => {
    if (isEdit) {
      roomDetails.current = roomDetailsEdit !== null && roomDetailsEdit !== void 0 ? roomDetailsEdit : _constants.INITIAL_ROOM_DETAILS;
      setRoomOccupancyDetails({
        displayRoomOccupancy: true,
        price: price,
        guests: (0, _utilities.getGuestsPlaceholderString)(roomDetailsEdit === null || roomDetailsEdit === void 0 ? void 0 : roomDetailsEdit.adults, roomDetailsEdit === null || roomDetailsEdit === void 0 ? void 0 : roomDetailsEdit.children, labels.roomAvailabilityLabels)
      });
      isAvailabilityButtonEnabled.current = false;
      leadGuestDetails.current = initialEditGuestDetails;
    }
  }, [isEdit]);
  var handleChangeDropdown = (chosenOption, param) => {
    var extraChanges = {};
    if (param === _constants.CHILDREN_PARAM) {
      if (Number(chosenOption === null || chosenOption === void 0 ? void 0 : chosenOption.id) > 0) {
        extraChanges = {
          roomType: ROOM_LABELS.family,
          roomTypeCode: roomDropdownRoomCodes.family
        };
      } else {
        extraChanges = {
          roomType: ROOM_LABELS.double,
          roomTypeCode: roomDropdownRoomCodes.double
        };
      }
    }
    if (param === _constants.ADULTS_PARAM) {
      if (roomDetailsEdit && Number(chosenOption === null || chosenOption === void 0 ? void 0 : chosenOption.id) < (roomDetailsEdit === null || roomDetailsEdit === void 0 ? void 0 : roomDetailsEdit.adults)) {
        isAdultsDecreased.current = true;
      }
      if (roomDetailsEdit && Number(chosenOption === null || chosenOption === void 0 ? void 0 : chosenOption.id) === 2) {
        isAdultsDecreased.current = false;
      }
      if (Number(chosenOption === null || chosenOption === void 0 ? void 0 : chosenOption.id) > 0) {
        if (roomDetails.current.children === 0) {
          if (roomDetails.current.roomType === ROOM_LABELS.accessible) {
            extraChanges = {
              roomType: ROOM_LABELS.accessible,
              roomTypeCode: roomDropdownRoomCodes.accessible
            };
          } else {
            extraChanges = {
              roomType: ROOM_LABELS.double,
              roomTypeCode: roomDropdownRoomCodes.double
            };
          }
        } else if (roomDetails.current.children > 0) {
          extraChanges = {
            roomType: ROOM_LABELS.family,
            roomTypeCode: roomDropdownRoomCodes.family
          };
        }
      }
    }
    if (param === _constants.ROOM_TYPE_KEY) {
      extraChanges = {
        roomTypeCode: chosenOption === null || chosenOption === void 0 ? void 0 : chosenOption.code
      };
    }
    var updatedRoomDetails = _objectSpread(_objectSpread({}, roomDetails.current), {}, {
      [param]: chosenOption === null || chosenOption === void 0 ? void 0 : chosenOption.id
    }, extraChanges);
    roomDetails.current = _objectSpread({}, updatedRoomDetails);
    var shouldEnableCheckAvailabilityButton = updatedRoomDetails.adults !== (roomDetailsEdit === null || roomDetailsEdit === void 0 ? void 0 : roomDetailsEdit.adults) || updatedRoomDetails.children !== (roomDetailsEdit === null || roomDetailsEdit === void 0 ? void 0 : roomDetailsEdit.children) || updatedRoomDetails.roomTypeCode !== (roomDetailsEdit === null || roomDetailsEdit === void 0 ? void 0 : roomDetailsEdit.roomTypeCode) || updatedRoomDetails.operaRoomType !== (roomDetailsEdit === null || roomDetailsEdit === void 0 ? void 0 : roomDetailsEdit.operaRoomType);
    isAvailabilityButtonEnabled.current = shouldEnableCheckAvailabilityButton;
    setIsHotelAvailable({
      displayNotification: false,
      available: false
    });
    setRoomOccupancyDetails(_constants.INITIAL_ROOM_OCCUPANCY);
  };
  var handleAvailabilityCheck = function () {
    var _ref3 = (0, _asyncToGenerator2.default)(function* () {
      isAvailabilityButtonEnabled.current = false;
      var params = _objectSpread(_objectSpread({}, hotelAvailabilityParams), {}, {
        rooms: [{
          adultsNumber: roomDetails.current.adults,
          childrenNumber: roomDetails.current.children,
          cotRequired: false,
          roomType: roomDetails.current.roomTypeCode
        }],
        brand: brand
      });
      var bbParams = _objectSpread(_objectSpread({}, params), {}, {
        companyId: companyId
      });
      var HOTEL_AVAILABILITY_QUERY_KEY_PI = _constants.HOTEL_AVAILABILITY_QUERY_KEY;
      var GET_HOTEL_AVAILABILITY_QUERY_PI = _api.GET_HOTEL_AVAILABILITY_QUERY;
      if (discountRateCompanyId) {
        params.companyId = +discountRateCompanyId;
        HOTEL_AVAILABILITY_QUERY_KEY_PI = _constants.HOTEL_AVAILABILITY_DISCOUNT_RATE_QUERY_KEY;
        GET_HOTEL_AVAILABILITY_QUERY_PI = _api.HOTEL_AVAILABILITY_DISCOUNT_RATE_QUERY;
      }
      var getHotelAvailability = variant === _api.Area.BB || currentBookingReservationChannel === _api.BOOKING_CHANNEL.BB ? queryClient.fetchQuery(_objectSpread({
        queryKey: [_constants.HOTEL_AVAILABILITY_BB_QUERY_KEY, bbParams],
        queryFn: () => (0, _utils.graphQLRequest)(_api.HOTEL_AVAILABILITY_BB_QUERY, bbParams, idTokenCookie)
      }, {
        staleTime: 0
      })) : queryClient.fetchQuery(_objectSpread({
        queryKey: [HOTEL_AVAILABILITY_QUERY_KEY_PI, params],
        queryFn: () => (0, _utils.graphQLRequest)(GET_HOTEL_AVAILABILITY_QUERY_PI, params)
      }, {
        staleTime: 0
      }));
      yield getHotelAvailability.then(response => {
        var _response$hotelAvaila;
        var isHotelAvailable = (response === null || response === void 0 ? void 0 : response.hotelAvailability.available) && (response === null || response === void 0 ? void 0 : response.hotelAvailability.roomRates.length) > 0;
        setIsHotelAvailable({
          displayNotification: true,
          available: isHotelAvailable
        });
        if (response !== null && response !== void 0 && (_response$hotelAvaila = response.hotelAvailability) !== null && _response$hotelAvaila !== void 0 && (_response$hotelAvaila = _response$hotelAvaila.roomRates) !== null && _response$hotelAvaila !== void 0 && _response$hotelAvaila.length) {
          var roomRates = response === null || response === void 0 ? void 0 : response.hotelAvailability.roomRates.filter(item => item.ratePlanCode === hotelAvailabilityParams.rateCode || variant === _api.Area.PI && hotelAvailabilityParams.ratePlanCodes && (item === null || item === void 0 ? void 0 : item.cellCode) === hotelAvailabilityParams.ratePlanCodes[0]);
          var requiresPriceChange = roomDetailsEdit && [_api.ROOM_TYPE.STANDARD_BIGGER, _api.ROOM_TYPE.PREMIER_PLUS, _api.ROOM_TYPE.PREMIER_PLUS_FAMILY, _api.ROOM_TYPE.TWIN].includes(roomDetailsEdit === null || roomDetailsEdit === void 0 ? void 0 : roomDetailsEdit.operaRoomType);
          if (roomRates.length) {
            var _roomRates$0$roomType;
            var currencyCode = roomRates[0].roomTypes[0].rooms[0].roomPriceBreakdown.currencyCode;
            var {
              roomPriceBreakdown: {
                totalNetAmount
              },
              specialRequests: _specialRequests
            } = (_roomRates$0$roomType = roomRates[0].roomTypes[0].rooms.find(room => room.pmsRoomType.toUpperCase() === roomDetails.current.roomType.toUpperCase())) !== null && _roomRates$0$roomType !== void 0 ? _roomRates$0$roomType : roomRates[0].roomTypes[0].rooms[0];
            var roomPrice = isEdit && price && !requiresPriceChange ? price : (0, _utils.formatPriceWithDecimal)(hotelAvailabilityParams.language, (0, _utils.formatCurrency)(currencyCode), totalNetAmount, true);
            setRoomOccupancyDetails({
              displayRoomOccupancy: true,
              price: roomPrice,
              guests: (0, _utilities.getGuestsPlaceholderString)(roomDetails.current.adults, roomDetails.current.children, labels.roomAvailabilityLabels)
            });
            setSpecialRequests(_specialRequests);
          } else {
            setIsHotelAvailable({
              displayNotification: true,
              available: false
            });
          }
        }
      });
    });
    return function handleAvailabilityCheck() {
      return _ref3.apply(this, arguments);
    };
  }();
  var onAddNewRoom = guestDetails => {
    var newRoom = _objectSpread(_objectSpread({
      adultsNumber: roomDetails.current.adults,
      childrenNumber: roomDetails.current.children,
      roomType: roomDetails.current.roomTypeCode
    }, guestDetails), {}, {
      cotRequired: false,
      specialRequests: specialRequests
    });
    onSaveNewRoom === null || onSaveNewRoom === void 0 || onSaveNewRoom(newRoom);
  };
  var onEditRoom = guestDetails => {
    var room = _objectSpread(_objectSpread({}, guestDetails), {}, {
      cotRequired: false,
      adultsNumber: roomDetails.current.adults,
      childrenNumber: roomDetails.current.children,
      roomType: roomDetails.current.roomTypeCode
    });
    if (!isHotelAvailable.available && roomDetailsEdit) {
      room = _objectSpread(_objectSpread({}, room), {}, {
        adultsNumber: roomDetailsEdit.adults,
        childrenNumber: roomDetailsEdit.children,
        roomType: roomDetailsEdit.operaRoomType
      });
    }
    var analyticsRoomDetails = {};
    if (initialRoomType.current !== roomDetails.current.roomType) {
      analyticsRoomDetails = _objectSpread(_objectSpread({}, analyticsRoomDetails), {}, {
        isChanged: true,
        roomType: roomDetails.current.roomType
      });
    }
    if (initialGuestsNumber.current !== roomDetails.current.adults) {
      analyticsRoomDetails = _objectSpread(_objectSpread({}, analyticsRoomDetails), {}, {
        isChanged: true,
        changeAdults: "1 guest has been ".concat(roomDetails.current.adults === 2 ? 'added' : 'removed')
      });
    }
    reservationId && roomNumber && (onUpdateRoom === null || onUpdateRoom === void 0 ? void 0 : onUpdateRoom(room, reservationId, roomNumber, analyticsRoomDetails));
  };
  var onCloseModal = () => {
    queryClient.cancelQueries({
      queryKey: [variant === _api.Area.BB ? _constants.HOTEL_AVAILABILITY_BB_QUERY_KEY : _constants.HOTEL_AVAILABILITY_QUERY_KEY]
    });
    onClose();
    var isAddARoomSectionEnabled = !isEdit;
    isAvailabilityButtonEnabled.current = isAddARoomSectionEnabled;
    setIsHotelAvailable({
      displayNotification: false,
      available: false
    });
    setIsBbGuestEdited(false);
    setRoomOccupancyDetails(_constants.INITIAL_ROOM_OCCUPANCY);
    setGuestDetails(initialEditGuestDetails);
    leadGuestDetails.current = _objectSpread({}, initialEditGuestDetails);
    roomDetails.current = roomDetailsEdit !== null && roomDetailsEdit !== void 0 ? roomDetailsEdit : _constants.INITIAL_ROOM_DETAILS;
    isAdultsDecreased.current = false;
  };
  var isUpdateBtnDisabled = () => {
    var isGDSectionUpdated = leadGuestDetails.current.title !== guestDetails.title || leadGuestDetails.current.firstName !== guestDetails.firstName || leadGuestDetails.current.lastName !== guestDetails.lastName || leadGuestDetails.current.emailAddress !== guestDetails.emailAddress || leadGuestDetails.current.addressLine1 !== guestDetails.addressLine1 || leadGuestDetails.current.addressLine2 !== guestDetails.addressLine2 || leadGuestDetails.current.addressLine3 !== guestDetails.addressLine3;
    if (isEdit) {
      return !(isGDSectionUpdated || isHotelAvailable.available);
    } else {
      return !(isGDSectionUpdated && isHotelAvailable.available);
    }
  };
  return (0, _jsxRuntime.jsx)(_RoomModal.default, {
    isOpen: isOpen,
    onCloseModal: onCloseModal,
    title: title,
    roomRules: roomRules,
    onAvailabilityCheck: handleAvailabilityCheck,
    onChange: handleChangeDropdown,
    onAddNewRoom: onAddNewRoom,
    getFormState: () => {},
    roomTypeOptions: roomTypeOptions,
    isAvailabilityButtonEnabled: isAvailabilityButtonEnabled.current,
    isHotelAvailable: isHotelAvailable,
    roomDetails: roomDetails.current,
    labels: roomModalLabels,
    baseDataTestId: baseDataTestId,
    roomOccupancyDetails: roomOccupancyDetails,
    leadGuestDetails: leadGuestDetails.current,
    isEdit: isEdit,
    roomNumber: roomNumber,
    onUpdateRoom: onEditRoom,
    setGuestDetails: setGuestDetails,
    isLocationRequired: isLocationRequired,
    setIsLocationRequired: setIsLocationRequired,
    isUpdateBtnDisabled: isUpdateBtnDisabled(),
    variant: variant,
    isAdultsDecreased: isAdultsDecreased.current,
    bbEmployeeList: bbEmployeeList.current,
    hotelCountry: hotelCountry,
    isBbGuestEdited: isBbGuestEdited,
    setIsBbGuestEdited: setIsBbGuestEdited,
    language: language
  });
}