"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = CancelBookingModal;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _react = require("@chakra-ui/react");
var _api = require("@whitbread-eos/api");
var _atoms = require("@whitbread-eos/atoms");
var _utils = require("@whitbread-eos/utils");
var _dateFns = require("date-fns");
var _locale = require("date-fns/locale");
var _nextI18next = require("next-i18next");
var _react2 = require("react");
var _jsxRuntime = require("react/jsx-runtime");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function CancelBookingModal(_ref) {
  var {
    hotelName,
    bookedFor,
    arrivalDate,
    noNights,
    isModalVisible,
    onModalClose,
    isError,
    error,
    onClickKeepBooking,
    onClickCancelBooking,
    cancelReservationData,
    area,
    backBtnText,
    backBtnUrl,
    bookedBy,
    onClickBack,
    bookingReference,
    isCancelDisabled
  } = _ref;
  var {
    country,
    language
  } = (0, _utils.useCustomLocale)();
  var {
    t
  } = (0, _nextI18next.useTranslation)(['common']);
  var isWindowDefined = typeof window !== 'undefined';
  var [origin, setOrigin] = (0, _react2.useState)('');
  var fmtArrivalDate = arrivalDate;
  (0, _react2.useEffect)(() => {
    if (language === 'de' && !Number.isNaN(new Date(arrivalDate).getTime())) {
      fmtArrivalDate = (0, _dateFns.format)(new Date(arrivalDate), 'E dd MMM yyyy', {
        locale: _locale.de
      });
    }
  }, [arrivalDate]);
  (0, _react2.useEffect)(() => {
    setOrigin(window.location.origin);
  }, [isWindowDefined]);
  var noNightsText = "".concat(noNights, " ").concat(t(noNights > 1 ? 'dashboard.bookings.nights' : 'dashboard.bookings.night'));
  return (0, _jsxRuntime.jsx)(_atoms.ModalVariants, {
    onClose: () => onModalClose(!isModalVisible),
    variant: "info",
    isOpen: isModalVisible,
    variantProps: {
      title: '',
      delimiter: false
    },
    children: (0, _jsxRuntime.jsx)(_react.Box, _objectSpread(_objectSpread({}, modalStyles), {}, {
      children: renderModalContent()
    }))
  });
  function renderModalContent() {
    if (isError) {
      return (0, _jsxRuntime.jsx)(_react.Text, {
        children: error.message
      });
    }
    return (0, _jsxRuntime.jsxs)(_react.Flex, {
      flexDir: "column",
      "data-testid": "CancelBookingModalContainer",
      children: [renderCancelBookingNotification(), (0, _jsxRuntime.jsx)(_react.Text, _objectSpread(_objectSpread({}, titleStyle), {}, {
        mt: "0",
        children: t('dashboard.bookings.cancelModalTitle')
      })), (0, _jsxRuntime.jsx)(_react.Text, _objectSpread(_objectSpread({}, cancelModalDescriptionStyle), {}, {
        children: t('dashboard.bookings.cancelModalDescription')
      })), (0, _jsxRuntime.jsx)(_react.Text, _objectSpread(_objectSpread({
        className: "sessioncamhidetext assist-no-show"
      }, titleStyle), {}, {
        children: bookedFor
      })), (0, _jsxRuntime.jsx)(_react.Text, _objectSpread(_objectSpread({}, bookingDescriptionStyle), {}, {
        children: hotelName
      })), (0, _jsxRuntime.jsxs)(_react.Text, _objectSpread(_objectSpread({}, bookingDescriptionStyle), {}, {
        mt: "0",
        children: [fmtArrivalDate, ", ", noNightsText]
      })), (bookedBy === null || bookedBy === void 0 ? void 0 : bookedBy.length) > 0 && (0, _jsxRuntime.jsx)(_react.Text, _objectSpread(_objectSpread({}, titleStyle), {}, {
        children: "".concat(t('dashboard.bookings.bookerLabel'), ": ").concat(bookedBy)
      })), (0, _utils.isStringValid)(cancelReservationData) && area === _api.Area.PI && (0, _jsxRuntime.jsx)(_atoms.Button, _objectSpread(_objectSpread({
        "data-testid": "CancelBookingModalBackButton"
      }, backToHomePageButtonStyle), {}, {
        onClick: () => {
          if (onClickBack) {
            onClickBack();
            return;
          }
          window.location.href = "".concat(origin, "/").concat(country, "/").concat(language, "/").concat(backBtnUrl);
        },
        children: backBtnText
      })), (0, _utils.isStringValid)(cancelReservationData) && area === _api.Area.BB && (0, _jsxRuntime.jsx)(_atoms.Button, _objectSpread(_objectSpread({
        "data-testid": "CancelBookingModalBackButton"
      }, backToHomePageButtonStyle), {}, {
        onClick: () => onModalClose(!isModalVisible),
        children: backBtnText
      })), !(0, _utils.isStringValid)(cancelReservationData) && (0, _jsxRuntime.jsxs)(_react.Box, {
        children: [(0, _jsxRuntime.jsx)(_atoms.Button, _objectSpread(_objectSpread({}, cancelButtonStyle), {}, {
          onClick: onClickCancelBooking,
          isDisabled: isCancelDisabled,
          children: t('dashboard.bookings.cancelButton')
        })), (0, _jsxRuntime.jsx)(_atoms.Button, _objectSpread(_objectSpread({}, amendButtonStyle), {}, {
          onClick: onClickKeepBooking,
          isDisabled: isCancelDisabled,
          children: t('dashboard.bookings.keepBookingButton')
        }))]
      })]
    });
  }
  function renderCancelBookingNotification() {
    if (cancelReservationData === undefined) {
      return null;
    } else if (cancelReservationData != null) {
      return (0, _jsxRuntime.jsx)(_react.Box, {
        mb: "md",
        children: (0, _jsxRuntime.jsx)(_atoms.Notification, {
          maxWidth: "full",
          variant: "success",
          status: "success",
          description: (0, _jsxRuntime.jsxs)(_react.Text, _objectSpread(_objectSpread({
            as: "span"
          }, successMessageNotificationStyle), {}, {
            children: ["".concat(t('dashboard.bookings.bookingCancelledNotification'), " "), (0, _jsxRuntime.jsx)(_react.Text, {
              as: "span",
              fontWeight: "semibold",
              children: (0, _utils.isStringValid)(bookingReference) ? bookingReference : cancelReservationData
            })]
          })),
          svg: (0, _jsxRuntime.jsx)(_atoms.Tick24, {})
        })
      });
    } else {
      return (0, _jsxRuntime.jsx)(_react.Box, {
        mb: "md",
        children: (0, _jsxRuntime.jsx)(_atoms.Notification, {
          maxWidth: "full",
          variant: "error",
          status: "error",
          description: t('dashboard.bookings.bookingCancelledError'),
          title: t('dashboard.bookings.bookingCancelledErrorTitle'),
          svg: (0, _jsxRuntime.jsx)(_atoms.Info, {
            color: "var(--chakra-colors-error)"
          })
        })
      });
    }
  }
}
var successMessageNotificationStyle = {
  fontSize: 'sm',
  lineHeight: '2',
  color: 'darkGrey1',
  fontFamily: 'body'
};
var modalStyles = {
  w: {
    base: '100vw',
    sm: '18.125rem'
  },
  px: 'md',
  pb: 'lg'
};
var titleStyle = {
  mt: 'md',
  color: 'darkGrey1',
  fontSize: 'md',
  lineHeight: '3',
  fontWeight: 'semibold'
};
var cancelModalDescriptionStyle = {
  mt: 'sm',
  color: 'darkGrey2',
  fontSize: 'sm',
  lineHeight: '2',
  fontWeight: 'normal'
};
var bookingDescriptionStyle = {
  mt: 'sm',
  color: 'darkGrey2',
  fontSize: 'sm',
  lineHeight: '2',
  fontWeight: 'normal'
};
var cancelButtonStyle = {
  size: 'sm',
  variant: 'primary',
  w: '100%',
  mt: 'xl'
};
var backToHomePageButtonStyle = {
  size: 'sm',
  variant: 'secondary',
  w: '100%',
  mt: 'xl'
};
var amendButtonStyle = {
  size: 'sm',
  variant: 'tertiary',
  w: '100%',
  mt: 'md'
};