"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.handleInputChange = exports.default = exports.clearSignature = void 0;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _react = require("@chakra-ui/react");
var _atoms = require("@whitbread-eos/atoms");
var _nextI18next = require("next-i18next");
var _react2 = require("react");
var _common = require("../common");
var _common2 = require("./common");
var _jsxRuntime = require("react/jsx-runtime");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
var SignatureSection = _ref => {
  var {
    tabIndex,
    setTabIndex,
    canvasRef,
    typedCanvasRef,
    setShowSignatureEmptyError,
    showSignatureEmptyError
  } = _ref;
  var {
    t
  } = (0, _nextI18next.useTranslation)();
  var [isDrawing, setIsDrawing] = (0, _react2.useState)(false);
  var [typedSignature, setTypedSignature] = (0, _react2.useState)('');
  var [lightGrey2] = (0, _react.useToken)('colors', ['lightGrey2']);
  var [canvasWidth, setCanvasWidth] = (0, _react2.useState)(0);
  var [typedCanvasWidth, setTypedCanvasWidth] = (0, _react2.useState)(0);
  var [isLargerThanSm] = (0, _react.useMediaQuery)('(min-width: 576px)');
  var getOffsets = event => {
    var canvas = canvasRef.current;
    var ctx = canvas === null || canvas === void 0 ? void 0 : canvas.getContext('2d');
    var {
      offsetX,
      offsetY
    } = event.nativeEvent;
    if (ctx) return {
      ctx,
      offsetX,
      offsetY
    };
  };
  var startDrawing = event => {
    var offsets = getOffsets(event);
    if (offsets) {
      var {
        ctx,
        offsetX,
        offsetY
      } = offsets;
      ctx.beginPath();
      ctx.moveTo(offsetX, offsetY);
      setIsDrawing(true);
    }
  };
  var draw = event => {
    if (!isDrawing) return;
    var offsets = getOffsets(event);
    if (offsets) {
      var {
        ctx,
        offsetX,
        offsetY
      } = offsets;
      ctx.lineTo(offsetX, offsetY);
      ctx.stroke();
    }
  };
  var handleTouchEnd = event => {
    event.preventDefault();
    setIsDrawing(false);
  };
  var endDrawing = () => {
    setIsDrawing(false);
  };
  var resizeCanvas = canvas => {
    var {
      clientWidth
    } = canvas.parentNode;
    canvas.width = clientWidth;
    return canvas.width;
  };
  (0, _react2.useEffect)(() => {
    var canvas = canvasRef.current;
    var typedCanvas = typedCanvasRef.current;
    var activeCanvas = tabIndex === 0 ? canvas : typedCanvas;
    var id = setTimeout(() => {
      if (!(0, _common.isCanvasEmpty)(activeCanvas) && showSignatureEmptyError) setShowSignatureEmptyError(false);
    }, 20);
    if (!canvas.width) setCanvasWidth(resizeCanvas(canvas));
    if (!typedCanvas.width) setTypedCanvasWidth(resizeCanvas(canvas));
    return () => {
      clearTimeout(id);
    };
  }, [tabIndex]);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsxs)(_react.Box, _objectSpread(_objectSpread({}, titleWrapper), {}, {
      children: [(0, _jsxRuntime.jsx)(_react.Text, _objectSpread(_objectSpread({}, titleStyle), {}, {
        children: t('precheckin.addsignature')
      })), (0, _jsxRuntime.jsx)(_react.Text, {
        mb: 4,
        mt: 2,
        children: t('precheckin.addsignature.description')
      })]
    })), (0, _jsxRuntime.jsxs)(_react.Tabs, {
      onChange: setTabIndex,
      children: [(0, _jsxRuntime.jsxs)(_react.TabList, {
        children: [(0, _jsxRuntime.jsx)(_react.Tab, _objectSpread(_objectSpread({
          "data-testid": "precheckin.signature.draw",
          _selected: {
            color: 'var(--chakra-colors-primary)',
            borderColor: 'var(--chakra-colors-primary)',
            fontWeight: 'semibold'
          }
        }, tabStyles), {}, {
          children: t('precheckin.signature.draw')
        })), (0, _jsxRuntime.jsx)(_react.Tab, _objectSpread(_objectSpread({
          _selected: {
            color: 'var(--chakra-colors-primary)',
            borderColor: 'var(--chakra-colors-primary)',
            fontWeight: 'semibold'
          }
        }, tabStyles), {}, {
          "data-testid": "precheckin.signature.type",
          children: t('precheckin.signature.type')
        }))]
      }), (0, _jsxRuntime.jsxs)(_react.TabPanels, {
        children: [(0, _jsxRuntime.jsxs)(_react.TabPanel, {
          px: 0,
          children: [(0, _jsxRuntime.jsxs)(_react.Flex, {
            justifyContent: "space-between",
            alignItems: "center",
            mb: 4,
            children: [(0, _jsxRuntime.jsx)(_react.Text, _objectSpread(_objectSpread({}, signTextTitleStyle), {}, {
              mb: 0,
              children: t('precheckin.yoursignature.title')
            })), (0, _jsxRuntime.jsx)(_atoms.Button, {
              variant: "tertiary",
              size: 'sm',
              onClick: () => clearSignature(canvasRef),
              colorScheme: "#511E62",
              "data-testid": "signature-draw-reset-btn",
              children: t('precheckin.details.reset')
            })]
          }), showSignatureEmptyError && (0, _jsxRuntime.jsx)(_react.Box, {
            mb: "xl",
            mt: "xl",
            children: (0, _jsxRuntime.jsx)(_atoms.Notification, {
              variant: "error",
              status: "error",
              svg: (0, _jsxRuntime.jsx)(_atoms.Error, {}),
              prefixDataTestId: 'reg-card-draw-signature-error-notification',
              showCloseButton: true,
              id: "reg-card-draw-signature-error-notification",
              onClick: () => setShowSignatureEmptyError(false),
              description: t('precheckin.addsignature.errormsg')
            })
          }), (0, _jsxRuntime.jsx)(_react.Box, {
            width: "100%",
            height: "150px",
            children: (0, _jsxRuntime.jsx)("canvas", {
              ref: canvasRef,
              style: _objectSpread(_objectSpread({}, canvasStyle), {}, {
                border: "1px solid ".concat(lightGrey2)
              }),
              "data-testid": "signature-canvas",
              onMouseDown: startDrawing,
              onMouseMove: draw,
              onMouseUp: endDrawing,
              onMouseLeave: endDrawing,
              onTouchStart: event => (0, _common2.handleTouchEvent)({
                event,
                canvas: canvasRef.current,
                isDrawing,
                setIsDrawing
              }),
              onTouchMove: event => (0, _common2.handleTouchEvent)({
                event,
                canvas: canvasRef.current,
                isDrawing,
                setIsDrawing
              }),
              onTouchEnd: handleTouchEnd,
              width: canvasWidth,
              height: "150px"
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_react.TabPanel, {
          px: 0,
          children: [(0, _jsxRuntime.jsxs)(_react.Flex, {
            justifyContent: "space-between",
            alignItems: "center",
            mb: 4,
            children: [(0, _jsxRuntime.jsx)(_react.Box, {
              width: "50%",
              children: (0, _jsxRuntime.jsx)(_atoms.Input, {
                value: typedSignature,
                styles: {
                  inputElementStyles: {
                    height: isLargerThanSm ? '3.5rem' : '2.5rem',
                    px: isLargerThanSm ? 'md' : 'sm'
                  }
                },
                onChange: value => {
                  setTypedSignature(value);
                  handleInputChange(typedCanvasRef, value);
                },
                label: t('precheckin.yoursignature.title'),
                placeholderText: t('precheckin.yoursignature.title'),
                name: 'typed-signature'
              })
            }), (0, _jsxRuntime.jsx)(_atoms.Button, {
              variant: "tertiary",
              size: 'sm',
              onClick: () => {
                clearSignature(typedCanvasRef);
                setTypedSignature('');
              },
              colorScheme: "#511E62",
              "data-testid": "signature-typed-reset-btn",
              children: t('precheckin.details.reset')
            })]
          }), (0, _jsxRuntime.jsx)(_react.Text, _objectSpread(_objectSpread({}, signTextTitleStyle), {}, {
            mb: 4,
            children: t('precheckin.yoursignature.title')
          })), showSignatureEmptyError && (0, _jsxRuntime.jsx)(_react.Box, {
            mb: "xl",
            children: (0, _jsxRuntime.jsx)(_atoms.Notification, {
              variant: "error",
              status: "error",
              svg: (0, _jsxRuntime.jsx)(_atoms.Error, {}),
              prefixDataTestId: 'reg-card-typed-signature-error-notification',
              showCloseButton: true,
              id: "reg-card-typed-signature-error-notification",
              onClick: () => setShowSignatureEmptyError(false),
              description: t('precheckin.addsignature.errormsg')
            })
          }), (0, _jsxRuntime.jsx)(_react.Box, {
            width: "100%",
            height: "150px",
            children: (0, _jsxRuntime.jsx)("canvas", {
              ref: typedCanvasRef,
              "data-testid": "signature-typed-canvas",
              style: _objectSpread(_objectSpread({}, canvasStyle), {}, {
                border: "1px solid ".concat(lightGrey2)
              }),
              width: typedCanvasWidth,
              height: 150
            })
          })]
        })]
      })]
    })]
  });
};
var _default = exports.default = SignatureSection;
var tabStyles = {
  w: {
    xs: '50%',
    sm: '50%',
    md: '30%'
  }
};
var signTextTitleStyle = {
  fontSize: 'md',
  fontWeight: 'semibold',
  color: 'darkGrey1'
};
var titleStyle = {
  fontSize: 'xl',
  fontWeight: 'bold',
  color: 'darkGrey1'
};
var titleWrapper = {
  pos: 'relative',
  mt: 'xl'
};
var canvasStyle = {
  borderRadius: '8px'
};
var clearSignature = ref => {
  var canvas = ref.current;
  if (canvas) {
    var ctx = canvas.getContext('2d');
    ctx === null || ctx === void 0 || ctx.clearRect(0, 0, canvas.width, canvas.height);
  } else return;
};
exports.clearSignature = clearSignature;
var handleInputChange = (ref, value) => {
  var canvas = ref.current;
  if (canvas) {
    var ctx = canvas.getContext('2d');
    ctx === null || ctx === void 0 || ctx.clearRect(0, 0, canvas.width, canvas.height);
    if (ctx) {
      ctx.font = 'italic 24px "Times New Roman", Times, serif';
      ctx.fillStyle = 'black';
      ctx.fillText(value, 20, 35);
    }
  }
};
exports.handleInputChange = handleInputChange;