"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = HotelGalleryContainer;
var _utils = require("@whitbread-eos/utils");
var _HotelGallery = _interopRequireDefault(require("./HotelGallery.component"));
var _jsxRuntime = require("react/jsx-runtime");
function HotelGalleryContainer(_ref) {
  var {
    thumbnailSectionHeight,
    mobile,
    isLessThanMd,
    isLessThanLg
  } = _ref;
  var {
    galleryImages,
    isLoading,
    isError,
    error
  } = (0, _utils.useStaticHotelInformation)();
  return (0, _jsxRuntime.jsx)(_HotelGallery.default, {
    thumbnailSectionHeight,
    mobile,
    isLoading,
    isError,
    data: galleryImages,
    error,
    isLessThanMd,
    isLessThanLg
  });
}