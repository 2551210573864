"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = RestaurantConfiguration;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _react = require("@chakra-ui/react");
var _atoms = require("@whitbread-eos/atoms");
var _utils = require("@whitbread-eos/utils");
var _nextI18next = require("next-i18next");
var _react2 = require("react");
var _HotelRestaurantMenuContent = _interopRequireDefault(require("../HotelRestaurantMenuContent"));
var _jsxRuntime = require("react/jsx-runtime");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function RestaurantConfiguration(_ref) {
  var _data$menus, _data$logoSrc, _data$name, _data$menus2, _data$menus$tabIndex$, _data$menus3, _data$menus4, _data$menus5, _data$menus6;
  var {
    isLoading,
    isError,
    data,
    error
  } = _ref;
  var [tabIndex, setTabIndex] = (0, _react2.useState)(0);
  var {
    t
  } = (0, _nextI18next.useTranslation)(['common']);
  if (isLoading) {
    return (0, _jsxRuntime.jsx)(_react.Text, {
      children: t('searchresults.list.hotel.loading')
    });
  }
  if (isError) {
    return (0, _jsxRuntime.jsx)(_react.Text, {
      children: error.message
    });
  }
  if (!(data !== null && data !== void 0 && (_data$menus = data.menus) !== null && _data$menus !== void 0 && _data$menus.length)) {
    return null;
  }
  return (0, _jsxRuntime.jsx)(_atoms.Section, {
    dataTestId: "hdp_restaurant",
    children: (0, _jsxRuntime.jsxs)(_react.Box, {
      title: t('restaurant.title'),
      children: [(0, _jsxRuntime.jsx)(_react.Heading, _objectSpread(_objectSpread({
        as: "h3",
        "data-testid": "hotel-restaurant-section"
      }, headingStyles), {}, {
        children: t('restaurant.title')
      })), (0, _jsxRuntime.jsx)(_react.Box, _objectSpread(_objectSpread({}, imageContainerStyles), {}, {
        children: (data === null || data === void 0 ? void 0 : data.logoSrc) !== '' && (data === null || data === void 0 ? void 0 : data.logoSrc) !== null && (0, _jsxRuntime.jsx)(_react.Image, {
          src: (0, _utils.formatAssetsUrl)((_data$logoSrc = data === null || data === void 0 ? void 0 : data.logoSrc) !== null && _data$logoSrc !== void 0 ? _data$logoSrc : ''),
          alt: (_data$name = data === null || data === void 0 ? void 0 : data.name) !== null && _data$name !== void 0 ? _data$name : '',
          objectFit: "contain",
          maxHeight: {
            base: '40px',
            sm: '65px'
          },
          "data-testid": "hotel-restaurant-logo"
        })
      })), !!(data !== null && data !== void 0 && (_data$menus2 = data.menus) !== null && _data$menus2 !== void 0 && (_data$menus2 = _data$menus2[tabIndex]) !== null && _data$menus2 !== void 0 && (_data$menus2 = _data$menus2.disclaimer) !== null && _data$menus2 !== void 0 && _data$menus2.length) && (0, _jsxRuntime.jsx)(_atoms.Notification, _objectSpread({
        title: (_data$menus$tabIndex$ = data === null || data === void 0 || (_data$menus3 = data.menus) === null || _data$menus3 === void 0 || (_data$menus3 = _data$menus3[tabIndex]) === null || _data$menus3 === void 0 ? void 0 : _data$menus3.disclaimer) !== null && _data$menus$tabIndex$ !== void 0 ? _data$menus$tabIndex$ : '',
        status: "info",
        variant: "infoGrey",
        svg: (0, _jsxRuntime.jsx)(_atoms.Info, {}),
        description: "",
        prefixDataTestId: "hdp_restaurants"
      }, notificationStyles)), (data === null || data === void 0 || (_data$menus4 = data.menus) === null || _data$menus4 === void 0 ? void 0 : _data$menus4.length) === 1 ? (0, _jsxRuntime.jsx)(_HotelRestaurantMenuContent.default, {
        menu: data === null || data === void 0 || (_data$menus5 = data.menus) === null || _data$menus5 === void 0 ? void 0 : _data$menus5[0]
      }) : (0, _jsxRuntime.jsx)(_atoms.Tabs, {
        onChange: tabIndex => setTabIndex(tabIndex),
        pt: 2.5,
        sx: _objectSpread({}, tabsStyles()),
        variant: "tabsGroup",
        prefixDataTestId: "restaurantConfiguration",
        options: data === null || data === void 0 || (_data$menus6 = data.menus) === null || _data$menus6 === void 0 ? void 0 : _data$menus6.map((menu, index) => {
          var _menu$name;
          return {
            index,
            label: (_menu$name = menu === null || menu === void 0 ? void 0 : menu.name) !== null && _menu$name !== void 0 ? _menu$name : '',
            content: (0, _jsxRuntime.jsx)(_HotelRestaurantMenuContent.default, {
              menu: menu,
              isTabPanel: true
            })
          };
        })
      })]
    })
  });
}
var tabsStyles = () => ({
  '.chakra-tabs__tablist': {
    w: {
      xl: "calc(100% / 2 - 1.5rem + 1.5rem / 2)",
      lg: "calc(100% / 2 - 1.5rem + 1.5rem / 2)",
      md: '39.813rem',
      mobile: 'full'
    }
  },
  '.chakra-tabs__tab': {
    minWidth: '6rem',
    maxWidth: {
      xs: '12rem',
      mobile: '7rem'
    }
  }
});
var imageContainerStyles = {
  mb: 5,
  width: '100%',
  height: 'auto'
};
var notificationStyles = {
  maxWidth: {
    base: 'full',
    md: '70%'
  },
  borderColor: '#80CACE',
  fontWeight: 'bold',
  fontSize: 'sm',
  lineHeight: 2
};
var headingStyles = {
  fontSize: {
    base: 'xl',
    md: '2xl'
  },
  fontWeight: 'semibold',
  lineHeight: {
    base: '3',
    md: '4'
  },
  mb: '4',
  mt: '3xl',
  size: 'md'
};