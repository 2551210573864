"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _react = require("@chakra-ui/react");
var _atoms = require("@whitbread-eos/atoms");
var _jsxRuntime = require("react/jsx-runtime");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
var MAX_ITEMS_PER_SECTION = 5;
var SearchBookingsLocationPicker = _ref => {
  var {
    inputPlaceholder,
    inputvalue,
    suggestions,
    styles,
    showError,
    errorLabel,
    onInputChange,
    onSelectLocation,
    onClearInput,
    onInputFocus,
    onInputBlur
  } = _ref;
  var hasClearIcon = !!inputvalue;
  return (0, _jsxRuntime.jsx)(_atoms.Tooltip, _objectSpread(_objectSpread({}, tooltipStyle), {}, {
    description: errorLabel,
    variant: "inlineError",
    placement: "bottom-start",
    isDisabled: !showError,
    isOpen: showError,
    svg: (0, _jsxRuntime.jsx)(_atoms.Error24, {}),
    children: (0, _jsxRuntime.jsx)(_react.Box, _objectSpread(_objectSpread({}, styles.locationPickerStyles), {}, {
      children: (0, _jsxRuntime.jsx)(_atoms.AutocompleteLocation, {
        dataTestId: "SearchBookingsLocationPicker",
        items: mapItemsForAutocomplete(suggestions),
        inputPlaceholder: inputPlaceholder,
        inputSelectedValue: inputvalue,
        onSelectOption: handleLocationChange,
        onInputChange: onInputChange,
        onClearInput: onClearInput,
        onBlurInput: onInputBlur,
        onFocusInput: onInputFocus,
        hasClearIcon: hasClearIcon,
        openListOnFocus: false,
        disableInternalFilter: true,
        autocompleteStyles: _objectSpread({}, styles)
      })
    }))
  }));
  function handleLocationChange(location) {
    var selectedLocation = getSelectedItem(location);
    onSelectLocation === null || onSelectLocation === void 0 || onSelectLocation(selectedLocation);
  }
  function getSelectedItem(item) {
    var items = [...suggestions.managedPlaces, ...suggestions.places, ...suggestions.properties];
    var suggestion = items.find(element => {
      var _element$suggestion;
      return (element === null || element === void 0 || (_element$suggestion = element.suggestion) === null || _element$suggestion === void 0 ? void 0 : _element$suggestion.replace(/\//g, ' ')) === (item === null || item === void 0 ? void 0 : item.replace(/\//g, ' '));
    });
    return suggestion !== null && suggestion !== void 0 ? suggestion : items[0];
  }
  function getLogoByBrand(brand, code) {
    var logoStyle = {
      position: 'relative',
      transform: 'scale(0.45)'
    };
    var iconStyle = {
      width: 'var(--chakra-space-lg)',
      height: 'var(--chakra-space-lg)',
      marginRight: 'var(--chakra-space-md)'
    };
    if (brand === 'PI' || brand === 'PID') {
      return (0, _jsxRuntime.jsx)(_react.Box, {
        mr: "md",
        style: {
          position: 'relative',
          top: '-0.125rem'
        },
        children: (0, _jsxRuntime.jsx)(_atoms.PremierInnLogo, {})
      }, code);
    }
    if (brand === 'HUB') {
      return (0, _jsxRuntime.jsx)(_atoms.Icon, {
        style: iconStyle,
        svg: (0, _jsxRuntime.jsx)(_atoms.LogoHubSimple, {
          style: _objectSpread(_objectSpread({}, logoStyle), {}, {
            top: '-1.25rem',
            left: '-1.125rem'
          })
        })
      }, code);
    }
    if (brand === 'ZIP') {
      return (0, _jsxRuntime.jsx)(_atoms.Icon, {
        style: iconStyle,
        svg: (0, _jsxRuntime.jsx)(_atoms.LogoZipSimple, {
          style: _objectSpread(_objectSpread({}, logoStyle), {}, {
            top: '-0.375rem',
            left: '-1.5rem'
          })
        })
      }, code);
    }
  }
  function mapItemsForAutocomplete(items) {
    var _items$managedPlaces, _items$places, _items$properties;
    var mappedManagedPlaces = items === null || items === void 0 || (_items$managedPlaces = items.managedPlaces) === null || _items$managedPlaces === void 0 ? void 0 : _items$managedPlaces.map(managedPlace => {
      return {
        value: managedPlace.suggestion
      };
    });
    var mappedPlaces = items === null || items === void 0 || (_items$places = items.places) === null || _items$places === void 0 ? void 0 : _items$places.map(place => {
      return {
        value: place.suggestion
      };
    });
    var mappedProperties = items === null || items === void 0 || (_items$properties = items.properties) === null || _items$properties === void 0 ? void 0 : _items$properties.map(property => {
      return {
        value: property.suggestion,
        group: 'Hotels',
        component: getLogoByBrand(property.brand, property.code)
      };
    });
    var places = [...mappedManagedPlaces, ...mappedPlaces].slice(0, MAX_ITEMS_PER_SECTION);
    var hotels = [...mappedProperties].slice(0, MAX_ITEMS_PER_SECTION);
    return [...places, ...hotels];
  }
};
var _default = exports.default = SearchBookingsLocationPicker;
var tooltipStyle = {
  h: '2.25rem',
  display: 'flex',
  alignContent: 'center'
};