"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = HotelDescriptionInformationComponent;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _react = require("@chakra-ui/react");
var _utils = require("@whitbread-eos/utils");
var _nextI18next = require("next-i18next");
var _jsxRuntime = require("react/jsx-runtime");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function HotelDescriptionInformationComponent(_ref) {
  var {
    data,
    isLoading,
    isError,
    error,
    language,
    email,
    brand
  } = _ref;
  var {
    t
  } = (0, _nextI18next.useTranslation)(['common']);
  if (isLoading) {
    return (0, _jsxRuntime.jsx)(_react.Text, {
      children: t('searchresults.list.hotel.loading')
    });
  }
  if (isError) {
    return (0, _jsxRuntime.jsx)(_react.Text, {
      children: error.message
    });
  }
  if (!data) {
    return null;
  }
  var flexStyles = {
    direction: language === 'de' ? 'column' : {
      base: 'column',
      lg: 'row'
    },
    mt: language === 'de' ? '4' : {
      base: '2',
      sm: '1'
    }
  };
  var boxStyles = {
    maxW: language === 'de' ? 'full' : {
      base: 'full',
      lg: '1053px'
    },
    ml: language === 'de' ? '0' : {
      base: '0'
    },
    mt: language === 'de' ? '0' : {
      base: '0',
      lg: '1'
    }
  };
  return (0, _jsxRuntime.jsxs)(_react.Box, _objectSpread(_objectSpread({
    maxW: "full",
    "data-testid": "hotel-details-contact"
  }, containerStyles), {}, {
    children: [(0, _jsxRuntime.jsx)(_react.Heading, _objectSpread(_objectSpread({
      as: "h3"
    }, headingStyles), {}, {
      children: t('contactModule.contactLabel')
    })), (0, _jsxRuntime.jsx)(_react.Text, {
      mt: 4,
      children: "".concat(t('contactModule.phoneLabel'), " ").concat(data)
    }), brand === 'PID' && email && (0, _jsxRuntime.jsx)(_react.Text, {
      mt: 1,
      children: "".concat(t('booking.leadGuest.Email'), ": ").concat(email)
    }), (0, _jsxRuntime.jsx)(_react.Flex, _objectSpread(_objectSpread({}, flexStyles), {}, {
      children: (0, _jsxRuntime.jsx)(_react.Box, _objectSpread(_objectSpread({}, boxStyles), {}, {
        className: "formatLinks",
        children: (0, _utils.renderSanitizedHtml)(t('contactModule.Email'))
      }))
    }))]
  }));
}
var headingStyles = {
  fontWeight: 'semibold',
  fontSize: {
    base: 'xl',
    sm: '2xl'
  },
  lineHeight: {
    base: '3',
    sm: '4'
  }
};
var containerStyles = {
  mt: {
    base: 'lg',
    sm: '3xl'
  },
  mb: {
    base: 'lg',
    sm: '2xl'
  }
};