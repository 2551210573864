"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = useSilentRoomsMatch;
var _api = require("@whitbread-eos/api");
var _formatters = require("../formatters/formatters");
var _ancillaries = require("../selectors/ancillaries");
var _hotelDetails = require("../selectors/hotel-details");
var _useFeatureSwitch = _interopRequireDefault(require("./use-feature-switch"));
function useSilentRoomsMatch(basketReference, reservations) {
  var _substitutionsStorage;
  var isSilentFeatureFlagEnabled = (0, _useFeatureSwitch.default)({
    featureSwitchKey: _api.FS_SILENT_SUBSTITUTION
  });
  if (!isSilentFeatureFlagEnabled || !reservations.length || !basketReference) {
    return [];
  }
  var substitutionsStorage = (0, _ancillaries.getCurrentReservationStorageData)(basketReference);
  if (!(substitutionsStorage !== null && substitutionsStorage !== void 0 && (_substitutionsStorage = substitutionsStorage.value) !== null && _substitutionsStorage !== void 0 && _substitutionsStorage.length)) {
    return [];
  }
  var substitutions = substitutionsStorage.value;
  var alreadyMatched = substitutions.find(substitution => (substitution === null || substitution === void 0 ? void 0 : substitution.filtered) !== undefined);
  if (alreadyMatched) {
    return substitutions;
  }
  var matchedSubstitutions = (0, _formatters.matchSilentSubstitutions)(reservations, substitutions);
  (0, _hotelDetails.updateSilentSubstLocalStorage)(basketReference, matchedSubstitutions);
  return matchedSubstitutions;
}