"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.tripadvisorAwardtype = void 0;
var tripadvisorAwardtype = exports.tripadvisorAwardtype = function (tripadvisorAwardtype) {
  tripadvisorAwardtype["CHOICE"] = "choice";
  tripadvisorAwardtype["BEST"] = "best";
  return tripadvisorAwardtype;
}({});