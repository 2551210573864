"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = HotelThumbnailComponent;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _react = require("@chakra-ui/react");
var _utils = require("@whitbread-eos/utils");
var _nextI18next = require("next-i18next");
var _image = _interopRequireDefault(require("next/image"));
var _link = _interopRequireDefault(require("next/link"));
var _jsxRuntime = require("react/jsx-runtime");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
var imageResponsiveStyles = {
  height: {
    mobile: '8.44rem',
    sm: '17.44rem',
    md: '23.25rem',
    lg: '12.06rem'
  },
  width: 'full',
  position: 'relative'
};
function HotelThumbnailComponent(_ref) {
  var {
    image,
    url,
    alt,
    testId = 'hotel-thumbnail',
    brand
  } = _ref;
  var {
    t
  } = (0, _nextI18next.useTranslation)(['common']);
  return (0, _jsxRuntime.jsxs)(_react.Box, _objectSpread(_objectSpread({}, imageResponsiveStyles), {}, {
    sx: {
      '@media print': {
        width: '20.8125rem',
        height: '12.0625rem'
      }
    },
    children: [(0, _jsxRuntime.jsx)(_image.default, {
      src: image,
      alt: alt,
      fill: true,
      "data-testid": testId,
      loader: (0, _utils.isIVMEnabled)() ? _utils.akamaiImageLoader : undefined
    }), (0, _jsxRuntime.jsx)(_react.Box, _objectSpread(_objectSpread({}, adjustTextStyles(brand)), {}, {
      "data-testid": 'hotel-line',
      sx: {
        '@media print': {
          backgroundColor: 'btnSecondaryEnabled',
          color: 'baseWhite',
          opacity: '0.75',
          WebkitPrintColorAdjust: 'exact'
        }
      },
      children: (0, _jsxRuntime.jsx)(_link.default, {
        href: url,
        target: "_blank",
        children: t('dashboard.bookings.hotelDetails')
      })
    }))]
  }));
}
function adjustTextStyles(brand) {
  var textStyles = {
    position: 'absolute',
    color: 'baseWhite',
    bgColor: 'btnSecondaryEnabled',
    opacity: '0.75',
    textAlign: 'center',
    width: '100%',
    bottom: '0',
    cursor: 'pointer',
    fontSize: 'sm',
    lineHeight: '2',
    fontWeight: 'medium'
  };
  switch (brand) {
    case 'HUB':
      {
        return _objectSpread(_objectSpread({}, textStyles), {}, {
          bgColor: 'darkGrey2',
          color: 'hubPrimary'
        });
      }
    case 'ZIP':
      {
        return _objectSpread(_objectSpread({}, textStyles), {}, {
          bgColor: 'zipPrimary',
          color: 'baseWhite'
        });
      }
    case 'HPI':
      return _objectSpread(_objectSpread({}, textStyles), {}, {
        color: 'hubPrimary',
        bgColor: 'darkGrey2'
      });
    case 'ZPI':
      return _objectSpread(_objectSpread({}, textStyles), {}, {
        bgColor: 'zipPrimary'
      });
    default:
      return textStyles;
  }
}