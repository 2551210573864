"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = HotelLocationContainer;
var _utils = require("@whitbread-eos/utils");
var _constants = require("../../search/constants");
var _HotelLocation = _interopRequireDefault(require("./HotelLocation.component"));
var _jsxRuntime = require("react/jsx-runtime");
function HotelLocationContainer() {
  var _distanceFromSRPSearc, _searchReferrer$data;
  var [distanceFromSRPSearch] = (0, _utils.useLocalStorage)('DistanceFromSearch', _constants.DISTANCE_FROM_SEARCH_INITIAL_VALUE);
  var [searchReferrer] = (0, _utils.useLocalStorage)('SearchReferrer', _constants.SEARCH_REFERRER_INITIAL_VALUE);
  var {
    language
  } = (0, _utils.useCustomLocale)();
  var distanceFromSearch = distanceFromSRPSearch === null || distanceFromSRPSearch === void 0 || (_distanceFromSRPSearc = distanceFromSRPSearch.data) === null || _distanceFromSRPSearc === void 0 ? void 0 : _distanceFromSRPSearc.distance;
  var referrer = searchReferrer === null || searchReferrer === void 0 || (_searchReferrer$data = searchReferrer.data) === null || _searchReferrer$data === void 0 ? void 0 : _searchReferrer$data.referrer;
  return (0, _jsxRuntime.jsx)(_HotelLocation.default, {
    language,
    distanceFromSearch,
    referrer
  });
}