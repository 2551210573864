"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.GET_ROOM_OCCUPANCY_LIMITATIONS_QUERY = void 0;
var _taggedTemplateLiteral2 = _interopRequireDefault(require("@babel/runtime/helpers/taggedTemplateLiteral"));
var _graphqlRequest = require("graphql-request");
var _templateObject;
var GET_ROOM_OCCUPANCY_LIMITATIONS_QUERY = exports.GET_ROOM_OCCUPANCY_LIMITATIONS_QUERY = (0, _graphqlRequest.gql)(_templateObject || (_templateObject = (0, _taggedTemplateLiteral2.default)(["\n  query getRoomOccupancyLimitations($channel: Channel!, $brand: String) {\n    roomOccupancyLimitations(channel: $channel, brand: $brand) {\n      roomOccupancies {\n        adultsNumber\n        childrenNumber\n        acceptedRoomTypes\n      }\n    }\n  }\n"])));