"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.RETRIEVE_CHANGES_LOG = void 0;
var _taggedTemplateLiteral2 = _interopRequireDefault(require("@babel/runtime/helpers/taggedTemplateLiteral"));
var _graphqlRequest = require("graphql-request");
var _templateObject;
var RETRIEVE_CHANGES_LOG = exports.RETRIEVE_CHANGES_LOG = (0, _graphqlRequest.gql)(_templateObject || (_templateObject = (0, _taggedTemplateLiteral2.default)(["\n  query retrieveChangesLog($hotelId: String!, $reservationId: String!, $limit: Int, $offset: Int) {\n    retrieveChangesLog(\n      hotelId: $hotelId\n      reservationId: $reservationId\n      limit: $limit\n      offset: $offset\n    ) {\n      activityLog {\n        activityLog {\n          date\n          time\n          actionType\n          actionDescription\n          user\n        }\n        totalPages\n        offset\n        limit\n        hasMore\n        totalResults\n      }\n    }\n  }\n"])));