"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = ResendConfirmationModal;
var _react = require("@chakra-ui/react");
var _api = require("@whitbread-eos/api");
var _molecules = require("@whitbread-eos/molecules");
var _utils = require("@whitbread-eos/utils");
var _nextI18next = require("next-i18next");
var _react2 = require("react");
var _jsxRuntime = require("react/jsx-runtime");
function ResendConfirmationModal(_ref) {
  var {
    isModalVisible,
    onModalClose,
    basketReference,
    hotelId
  } = _ref;
  var {
    t
  } = (0, _nextI18next.useTranslation)(['common']);
  var [email, setEmail] = (0, _react2.useState)('');
  var [hasError, setHasError] = (0, _react2.useState)(false);
  var [showNotification, setShowNotification] = (0, _react2.useState)(false);
  var modalContent = {
    baseDataTestId: 'ResendConfirmationEmail',
    title: t('dashboard.bookings.resendConfirmationHeading'),
    description: t('dashboard.bookings.resendConfirmationInstructions'),
    emailLabel: t('dashboard.bookings.confirmationEmail'),
    emailPlaceholder: t('dashboard.bookings.emailPlaceholder'),
    emailErrorMsg: t('dashboard.bookings.resendConfirmationInstructions'),
    submitBtn: t('dashboard.bookings.sendConfirmation'),
    successNotif: t('dashboard.bookings.resendConfirmationSuccess')
  };
  var {
    mutation: resendConfirmationMutation,
    isSuccess: resendConfirmationIsSuccess,
    isLoading: resendConfirmationIsLoading
  } = (0, _utils.useMutationRequest)(_api.RESEND_CONFIRMATION_EMAIL);
  (0, _react2.useEffect)(() => {
    if (resendConfirmationIsSuccess) {
      setShowNotification(true);
    }
  }, [resendConfirmationIsSuccess]);
  (0, _react2.useEffect)(() => {
    setHasError(false);
  }, []);
  var handleEmailChange = email => {
    return setEmail(email);
  };
  var handleOnClose = () => {
    setEmail('');
    setHasError(false);
    setShowNotification(false);
    onModalClose();
  };
  var resendConfirmation = (0, _react2.useCallback)(() => {
    resendConfirmationMutation.mutate({
      resendConfirmationRequest: {
        email: email,
        hotelId: hotelId,
        bookingReference: basketReference,
        sessionId: '',
        sourceSystem: 'OPERA'
      }
    });
  }, [basketReference, email, hotelId, resendConfirmationMutation]);
  var handleOnChange = email => {
    hasError && setHasError(false);
    handleEmailChange(email);
  };
  var checkIfValid = () => {
    if (!(0, _utils.isEmailValid)(email)) {
      setHasError(true);
    }
  };
  return (0, _jsxRuntime.jsx)(_react.Box, {
    "data-testid": "ResendConfirmationContainer",
    children: (0, _jsxRuntime.jsx)(_molecules.EmailInputModal, {
      modalContent: modalContent,
      isModalVisible: isModalVisible,
      email: email,
      handleEmailChange: handleOnChange,
      handleOnSubmit: resendConfirmation,
      showNotification: showNotification,
      isLoading: resendConfirmationIsLoading,
      checkIfValid: checkIfValid,
      handleOnModalClose: handleOnClose,
      hasError: hasError,
      hasCustomerContent: false,
      isEmailTriggeringSuccess: resendConfirmationIsSuccess
    })
  });
}