"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = BBNavigationMenuMobile;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _react = require("@chakra-ui/react");
var _api = require("@whitbread-eos/api");
var _atoms = require("@whitbread-eos/atoms");
var _utils = require("@whitbread-eos/utils");
var _react2 = require("react");
var _HeaderSideNav = _interopRequireDefault(require("../../HeaderLeisure/NavigationMenuMobile/HeaderSideNav"));
var _LanguageSideNav = _interopRequireDefault(require("../../HeaderLeisure/NavigationMenuMobile/LanguageSideNav"));
var _NavigationItem = _interopRequireDefault(require("../../HeaderLeisure/NavigationMenuMobile/NavigationItem"));
var _MobileNavItem = _interopRequireDefault(require("./MobileNavItem"));
var _MobileSideNav = _interopRequireDefault(require("./MobileSideNav"));
var _jsxRuntime = require("react/jsx-runtime");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function BBNavigationMenuMobile(_ref) {
  var {
    navigationLabels,
    languageMenuLabels,
    language,
    tickIcon,
    isLogoutButton,
    genericLabels
  } = _ref;
  var baseDataTestId = 'BusinessNavMobile';
  var [isModalVisible, setIsModalVisible] = (0, _react2.useState)(false);
  var [selectedSideNav, setSelectedSideNav] = (0, _react2.useState)('default');
  var navTitleLabels = navigationLabels.map(navigationLabel => navigationLabel.navTitle);
  var indexOfSelectedSideNav = navTitleLabels.indexOf(selectedSideNav);
  var toggleSideNav = sideNav => {
    setSelectedSideNav(sideNav);
  };
  return (0, _jsxRuntime.jsxs)(_react.Flex, {
    flexDir: "column",
    "data-testid": baseDataTestId,
    children: [(0, _jsxRuntime.jsxs)(_react.Flex, _objectSpread(_objectSpread({}, burgerMenuContainerStyle), {}, {
      sx: {
        '@media print': {
          marginLeft: 'auto'
        }
      },
      "data-testid": (0, _utils.formatDataTestId)(baseDataTestId, 'toggleButton'),
      onClick: () => {
        setIsModalVisible(true);
      },
      children: [(0, _jsxRuntime.jsx)(_atoms.Icon, {
        _hover: {
          cursor: 'pointer'
        },
        svg: (0, _jsxRuntime.jsx)(_atoms.BurgerMenu, {
          "data-testid": "burgerMenu"
        })
      }), (0, _jsxRuntime.jsx)(_react.Text, _objectSpread(_objectSpread({}, burgerMenuTextStyle), {}, {
        children: genericLabels.mobileMenuButton
      }))]
    })), (0, _jsxRuntime.jsxs)(_react.Modal, {
      isOpen: isModalVisible,
      onClose: () => {
        setIsModalVisible(false);
      },
      size: "full",
      children: [(0, _jsxRuntime.jsx)(_react.ModalOverlay, {
        zIndex: "var(--chakra-zIndices-banner)",
        "data-testid": "modalOverlay"
      }), (0, _jsxRuntime.jsx)(_react.Slide, {
        direction: "left",
        in: isModalVisible,
        style: {
          zIndex: 'var(--chakra-zIndices-overlay)'
        },
        children: (0, _jsxRuntime.jsx)(_react.ModalContent, _objectSpread(_objectSpread({}, modalContentStyle), {}, {
          "data-testid": "modalSideBar",
          children: renderSideNav(selectedSideNav)
        }))
      })]
    })]
  });
  function renderSideNav(selectedSideNav) {
    switch (selectedSideNav) {
      case 'languageSelector':
        {
          var labels = {
            language: genericLabels.language
          };
          return (0, _jsxRuntime.jsx)(_LanguageSideNav.default, {
            currentLanguage: language,
            languagesList: languageMenuLabels,
            onClickHeaderTitle: () => toggleSideNav('default'),
            tickIcon: tickIcon,
            area: _api.Area.BB,
            labels: labels
          });
        }
      case navTitleLabels[indexOfSelectedSideNav]:
        return (0, _jsxRuntime.jsx)(_MobileSideNav.default, {
          title: navigationLabels[indexOfSelectedSideNav].navTitle,
          labels: navigationLabels[indexOfSelectedSideNav].subNav,
          onClickHeaderTitle: () => toggleSideNav('default'),
          baseTestId: (0, _utils.formatTextWithSpace)(navigationLabels[indexOfSelectedSideNav].navTitle)
        });
      default:
        return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
          children: [(0, _jsxRuntime.jsx)(_HeaderSideNav.default, {}), (0, _jsxRuntime.jsx)(_react.ModalBody, {
            p: "0",
            "data-testid": "defaultSideNav",
            children: (0, _jsxRuntime.jsxs)(_react.Flex, {
              flexDir: "column",
              children: [(0, _jsxRuntime.jsx)(_react.Box, {
                "data-testid": (0, _utils.formatDataTestId)(baseDataTestId, 'TriggerLanguageSideNav'),
                onClick: () => toggleSideNav('languageSelector'),
                children: (0, _jsxRuntime.jsx)(_react.Box, _objectSpread(_objectSpread({}, customContainerItemStyle), {}, {
                  _hover: {
                    color: 'primary'
                  },
                  children: (0, _jsxRuntime.jsx)(_NavigationItem.default, {
                    title: (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
                      children: [renderFlag(language), (0, _jsxRuntime.jsx)(_react.Box, {
                        pl: "sm",
                        children: (0, _jsxRuntime.jsx)(_react.Text, {
                          color: "darkGrey1",
                          children: findLanguageName(language)
                        })
                      })]
                    }),
                    icon: (0, _jsxRuntime.jsx)(_react.Box, {
                      pt: "0.37rem",
                      pl: "sm",
                      children: (0, _jsxRuntime.jsx)(_atoms.Icon, {
                        svg: (0, _jsxRuntime.jsx)(_atoms.ChevronRight, {})
                      })
                    })
                  })
                }))
              }), navTitleLabels.map(navTitleLabel => {
                return (0, _jsxRuntime.jsx)(_MobileNavItem.default, {
                  navTitleLabel: navTitleLabel,
                  navigationLabels: navigationLabels,
                  toggleSideNav: toggleSideNav,
                  baseDataTestId: baseDataTestId,
                  isLogoutButton: isLogoutButton
                }, navTitleLabel);
              })]
            })
          })]
        });
    }
  }
  function findLanguageName(language) {
    var country = languageMenuLabels.find(languageObject => languageObject.locale == language);
    if (country) {
      return country.languageName;
    }
  }
  function renderFlag(language) {
    var country = languageMenuLabels.find(languageObject => languageObject.locale == language);
    if (country) {
      return country.icon;
    }
  }
}
var containerItemStyle = {
  p: 'md',
  pl: 0,
  borderBottom: 'var(--chakra-space-px) solid var(--chakra-colors-lightGrey2)',
  w: {
    mobile: '16.87rem',
    xs: '20.31rem',
    sm: '20.75rem',
    md: '21.69rem'
  },
  ml: 'auto',
  _hover: {
    cursor: 'pointer'
  }
};
var burgerMenuContainerStyle = {
  pt: '0.5rem',
  flexDir: 'column',
  justifyContent: 'center',
  alignItems: 'center'
};
var burgerMenuTextStyle = {
  pt: '0.375rem',
  fontSize: 'sm',
  lineHeight: '2',
  fontWeight: 'normal'
};
var modalContentStyle = {
  borderRadius: 0,
  position: 'absolute',
  zIndex: 'var(--chakra-zIndices-overlay)',
  left: 0,
  display: {
    lg: 'none'
  },
  w: {
    mobile: '72',
    xs: '21.44rem',
    sm: '21.875rem',
    md: '23.25rem'
  }
};
var customContainerItemStyle = _objectSpread(_objectSpread({}, containerItemStyle), {}, {
  flexDirection: 'column',
  ml: '0',
  pl: 'md',
  w: '100%'
});