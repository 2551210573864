"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = OpeningSoonNotificationContainer;
var _utils = require("@whitbread-eos/utils");
var _OpeningSoonNotification = _interopRequireDefault(require("./OpeningSoonNotification.component"));
var _jsxRuntime = require("react/jsx-runtime");
function OpeningSoonNotificationContainer(_ref) {
  var {
    hotelAvailabilityResponse,
    isHotelOpeningSoon
  } = _ref;
  var {
    language: currentLang
  } = (0, _utils.useCustomLocale)();
  var {
    name,
    hotelOpeningDate
  } = (0, _utils.useStaticHotelInformation)();
  return (0, _jsxRuntime.jsx)(_OpeningSoonNotification.default, {
    isLoading: hotelAvailabilityResponse.isLoadingHotelAvailability,
    isError: hotelAvailabilityResponse.isErrorHotelAvailability,
    error: hotelAvailabilityResponse.errorHotelAvailability,
    hotelOpeningDate,
    name,
    currentLang,
    isHotelOpeningSoon
  });
}