"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _react = require("@chakra-ui/react");
var _atoms = require("@whitbread-eos/atoms");
var _nextI18next = require("next-i18next");
var _react2 = _interopRequireDefault(require("react"));
var _PaymentStatus = _interopRequireDefault(require("../PaymentStatus"));
var _common = require("../common");
var _jsxRuntime = require("react/jsx-runtime");
var ScaCard = _ref => {
  var _paymentData$initiate, _paymentData$initiate2;
  var {
    scaErrorVisible,
    basketReference,
    copyBookingLoading,
    paymentLoading,
    paymentData,
    isSuccess,
    isPaymentComplete,
    handleRetryPayment,
    handlePaymentComplete,
    setPaymentFailedError
  } = _ref;
  var {
    t
  } = (0, _nextI18next.useTranslation)();
  return (0, _jsxRuntime.jsxs)(_react.Box, {
    pt: "lg",
    height: "100%",
    children: [(0, _jsxRuntime.jsxs)(_react.Box, {
      px: "3xl",
      children: [(0, _jsxRuntime.jsx)(_react.Text, {
        width: {
          base: '100%'
        },
        fontSize: "medium",
        textAlign: "left",
        mb: "md",
        "data-testid": "pre-checkin-sca-description",
        children: t('precheckin.SCA.description')
      }), scaErrorVisible && (0, _jsxRuntime.jsx)(_atoms.Notification, {
        svg: (0, _jsxRuntime.jsx)(_atoms.Error, {}),
        status: "error",
        description: t('precheckin.SCA.error'),
        variant: "error",
        width: {
          base: '50%',
          md: '75%',
          sm: '100%'
        },
        prefixDataTestId: "reg-card-sca-error",
        wrapperStyles: {
          mb: 'sm',
          mt: 'md'
        }
      }), (copyBookingLoading || paymentLoading) && (0, _jsxRuntime.jsx)(_react.Flex, {
        height: "450px",
        justifyContent: "center",
        alignItems: "center",
        children: (0, _jsxRuntime.jsx)(_atoms.LoadingSpinner, {})
      }), basketReference && isPaymentComplete && (0, _jsxRuntime.jsx)(_PaymentStatus.default, {
        basketReference: basketReference,
        setPaymentFailedError: setPaymentFailedError,
        handleRetryPayment: handleRetryPayment,
        handlePaymentComplete: handlePaymentComplete
      })]
    }), isSuccess && !copyBookingLoading && (0, _jsxRuntime.jsx)(_react.Box, {
      height: "calc(100% - ".concat(scaErrorVisible ? '200px' : '100px', ")"),
      overflow: "scroll",
      px: "xl",
      children: (0, _jsxRuntime.jsx)(_atoms.IframeEmbed, {
        iframeId: "paymentFrame",
        iframeContent: paymentData === null || paymentData === void 0 || (_paymentData$initiate = paymentData.initiatePayment) === null || _paymentData$initiate === void 0 || (_paymentData$initiate = _paymentData$initiate.paymentRequiredDetails) === null || _paymentData$initiate === void 0 ? void 0 : _paymentData$initiate.paymentRedirect,
        onIframeLoad: _common.onIframeLoad,
        providerUrl: paymentData === null || paymentData === void 0 || (_paymentData$initiate2 = paymentData.initiatePayment) === null || _paymentData$initiate2 === void 0 || (_paymentData$initiate2 = _paymentData$initiate2.paymentRequiredDetails) === null || _paymentData$initiate2 === void 0 ? void 0 : _paymentData$initiate2.providerUrl
      })
    })]
  });
};
var _default = exports.default = ScaCard;