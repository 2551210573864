"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = BookingHistoryCancelBookingModalContainer;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _api = require("@whitbread-eos/api");
var _utils = require("@whitbread-eos/utils");
var _nextI18next = require("next-i18next");
var _react = require("react");
var _CancelBookingModal = _interopRequireDefault(require("./CancelBookingModal.component"));
var _jsxRuntime = require("react/jsx-runtime");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function BookingHistoryCancelBookingModalContainer(_ref) {
  var {
    isModalVisible,
    onModalClose: _onModalClose,
    refetchManageBooking,
    basketReference,
    bookingReference,
    area,
    hotelName,
    bookedFor,
    arrivalDate,
    noOfRooms,
    noNights,
    hotelId,
    bookedBy,
    bookingChannel
  } = _ref;
  var {
    country,
    language
  } = (0, _utils.useCustomLocale)();
  var {
    t
  } = (0, _nextI18next.useTranslation)(['common']);
  var ANALYTICS_TRACKING_DASH_CANCEL = 'dashCancel';
  var [cancelBookingResponse, setCancelBookingResponse] = (0, _react.useState)(undefined);
  var [disabledCancelButton, setDisabledCancelButton] = (0, _react.useState)(false);
  var idTokenCookie = (0, _utils.getAuthCookie)();
  var {
    mutation: cancelBookingMutation,
    isError: cancelBookingIsError,
    error: cancelBookingError,
    data: cancelBookingData
  } = (0, _utils.useMutationRequest)(_api.BOOKING_HISTORY_CANCEL_BOOKING, false, idTokenCookie);
  (0, _react.useEffect)(() => {
    if (cancelBookingIsError) {
      setCancelBookingResponse(null);
    }
  }, [cancelBookingIsError]);
  var cancelBooking = () => {
    setDisabledCancelButton(!disabledCancelButton);
    cancelBookingMutation.mutate({
      basketReference: basketReference,
      bookingReference: bookingReference,
      hotelId: hotelId,
      arrivalDate: arrivalDate,
      country: country,
      language: language,
      bookingChannel: bookingChannel
    });
  };
  (0, _react.useEffect)(() => {
    if (cancelBookingData) {
      if (cancelBookingData.cancelBooking.cancellationId) {
        setCancelBookingResponse(cancelBookingData.cancelBooking.cancellationId);
      } else if (cancelBookingData.cancelBooking.bookingReference) {
        setCancelBookingResponse(cancelBookingData.cancelBooking.bookingReference);
      }
      refetchManageBooking();
      updateAnalyticsDashboard(bookingReference);
    }
  }, [cancelBookingData]);
  function updateAnalyticsDashboard(bookingReference) {
    var _window$analyticsData, _window;
    window.__satelliteLoaded && window._satellite.track(ANALYTICS_TRACKING_DASH_CANCEL);
    _utils.analytics.update({
      dashboard: _objectSpread(_objectSpread({}, (_window$analyticsData = (_window = window) === null || _window === void 0 || (_window = _window.analyticsData) === null || _window === void 0 ? void 0 : _window.dashboard) !== null && _window$analyticsData !== void 0 ? _window$analyticsData : {}), {}, {
        cancelBookingID: bookingReference,
        cancelNights: noNights,
        cancelRooms: noOfRooms
      })
    });
  }
  var onClickKeepBooking = (0, _react.useCallback)(() => _onModalClose(false), []);
  return (0, _jsxRuntime.jsx)(_CancelBookingModal.default, {
    bookingReference: '',
    cancelReservationData: cancelBookingResponse,
    isError: cancelBookingIsError,
    error: cancelBookingError,
    onClickKeepBooking: onClickKeepBooking,
    hotelName: hotelName,
    bookedFor: bookedFor,
    arrivalDate: (0, _utils.formatDate)(arrivalDate, 'EEEE d MMMM yyyy', language),
    noNights: noNights,
    isModalVisible: isModalVisible,
    onModalClose: () => _onModalClose(false),
    onClickCancelBooking: cancelBooking,
    area: area,
    backBtnText: t('amend.backButtonText'),
    onClickBack: onClickKeepBooking,
    bookedBy: bookedBy,
    isCancelDisabled: disabledCancelButton
  });
}