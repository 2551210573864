"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ExtrasId = void 0;
var ExtrasId = exports.ExtrasId = function (ExtrasId) {
  ExtrasId["EARLY_CHECK_IN"] = "HSCKIN";
  ExtrasId["LATE_CHECK_OUT"] = "HSCOU2";
  ExtrasId["ULTIMATE_WIFI"] = "FI24HR";
  return ExtrasId;
}({});