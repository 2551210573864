"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.CREATE_MEMO = void 0;
var _taggedTemplateLiteral2 = _interopRequireDefault(require("@babel/runtime/helpers/taggedTemplateLiteral"));
var _graphqlRequest = require("graphql-request");
var _templateObject;
var CREATE_MEMO = exports.CREATE_MEMO = (0, _graphqlRequest.gql)(_templateObject || (_templateObject = (0, _taggedTemplateLiteral2.default)(["\n  mutation createMemo(\n    $basketReference: String!\n    $description: String!\n    $channel: Channel!\n    $subchannel: String!\n    $language: String\n  ) {\n    createMemo(\n      createMemoCriteria: {\n        basketReference: $basketReference\n        description: $description\n        bookingChannel: { channel: $channel, subchannel: $subchannel, language: $language }\n      }\n    ) {\n      memos {\n        ids {\n          reservationId\n          memoIds\n        }\n        description\n        createdOn\n        createdBy\n        modifiedOn\n        modifiedBy\n        memoType\n      }\n    }\n  }\n"])));