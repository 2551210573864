"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = FormField;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _ = require("..");
var _Button = _interopRequireDefault(require("../../Button"));
var _FormCheckbox = _interopRequireDefault(require("../FormCheckbox"));
var _FormDropdown = _interopRequireDefault(require("../FormDropdown/FormDropdown.component"));
var _FormDynamicField = _interopRequireDefault(require("../FormDynamicField"));
var _FormInput = _interopRequireDefault(require("../FormInput"));
var _FormRadioGroup = _interopRequireDefault(require("../FormRadioGroup"));
var _FormRelatedFields = _interopRequireDefault(require("../FormRelatedFields"));
var _FormTextArea = _interopRequireDefault(require("../FormTextArea"));
var _jsxRuntime = require("react/jsx-runtime");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function FormField(_ref) {
  var {
    control,
    formField,
    errors,
    getValues,
    handleSetValue,
    handleResetField,
    handleSetError,
    handleClearErrors,
    handleTriggerValidation,
    reset,
    btnProps,
    onChangeAction
  } = _ref;
  var {
    type
  } = formField;
  if (formField.hidden === true) {
    return null;
  }
  switch (type) {
    case _.FORM_FIELD_TYPES.BUTTON:
    case _.FORM_FIELD_TYPES.SUBMIT:
      return (0, _jsxRuntime.jsx)(_Button.default, _objectSpread(_objectSpread({
        "data-testid": formField.testid
      }, btnProps), {}, {
        children: btnProps === null || btnProps === void 0 ? void 0 : btnProps.label
      }));
    case _.FORM_FIELD_TYPES.INPUT_TEXT:
    case _.FORM_FIELD_TYPES.INPUT_PASSWORD:
      return (0, _jsxRuntime.jsx)(_FormInput.default, {
        control: control,
        formField: formField,
        errors: errors
      });
    case _.FORM_FIELD_TYPES.RADIO_GROUP:
      {
        return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
          children: [(0, _jsxRuntime.jsx)(_FormRadioGroup.default, {
            control: control,
            formField: formField,
            errors: errors,
            handleSetValue: handleSetValue,
            onChangeAction: onChangeAction
          }), !!formField.relatedFields && (0, _jsxRuntime.jsx)(_FormRelatedFields.default, {
            fieldName: formField.name,
            relatedFields: formField.relatedFields,
            control: control,
            errors: errors,
            handleSetValue: handleSetValue,
            handleResetField: handleResetField,
            getValues: getValues,
            reset: reset
          })]
        });
      }
    case _.FORM_FIELD_TYPES.CHECKBOX:
      {
        return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
          children: [(0, _jsxRuntime.jsx)(_FormCheckbox.default, {
            control: control,
            formField: formField,
            errors: errors
          }), !!formField.relatedFields && (0, _jsxRuntime.jsx)(_FormRelatedFields.default, {
            fieldName: formField.name,
            relatedFields: formField.relatedFields,
            control: control,
            errors: errors,
            handleSetValue: handleSetValue,
            handleResetField: handleResetField,
            getValues: getValues,
            reset: reset
          })]
        });
      }
    case _.FORM_FIELD_TYPES.NON_FIELD_CONTENT:
      return (0, _jsxRuntime.jsx)(_jsxRuntime.Fragment, {
        children: formField.content
      });
    case _.FORM_FIELD_TYPES.DROPDOWN:
      return (0, _jsxRuntime.jsx)(_FormDropdown.default, {
        formField: formField,
        control: control,
        errors: errors,
        handleSetValue: handleSetValue,
        onChangeAction: onChangeAction
      });
    case _.FORM_FIELD_TYPES.DYNAMIC_FIELD:
      return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
        children: [(0, _jsxRuntime.jsx)(_FormDynamicField.default, {
          control: control,
          formField: formField,
          errors: errors,
          handleSetValue: handleSetValue,
          handleResetField: handleResetField,
          handleSetError: handleSetError,
          handleClearErrors: handleClearErrors,
          handleTriggerValidation: handleTriggerValidation,
          getValues: getValues,
          reset: reset
        }), !!formField.relatedFields && (0, _jsxRuntime.jsx)(_FormRelatedFields.default, {
          fieldName: formField.name,
          relatedFields: formField.relatedFields,
          control: control,
          errors: errors,
          handleSetValue: handleSetValue,
          handleResetField: handleResetField,
          handleSetError: handleSetError,
          handleClearErrors: handleClearErrors,
          getValues: getValues,
          reset: reset
        })]
      });
    case _.FORM_FIELD_TYPES.TEXTAREA:
      return (0, _jsxRuntime.jsx)(_FormTextArea.default, {
        control: control,
        formField: formField,
        errors: errors
      });
    default:
      return null;
  }
}