"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = AmendEmailAddressModal;
var _react = require("@chakra-ui/react");
var _utils = require("@whitbread-eos/utils");
var _nextI18next = require("next-i18next");
var _react2 = _interopRequireWildcard(require("react"));
var _EmailInputModal = _interopRequireDefault(require("../../common/EmailInputModal"));
var _jsxRuntime = require("react/jsx-runtime");
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && {}.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
function AmendEmailAddressModal(_ref) {
  var {
    isModalVisible,
    bookingEmail,
    onConfirmChanges,
    onModalClose,
    setEmailCallback
  } = _ref;
  var {
    t
  } = (0, _nextI18next.useTranslation)();
  var [email, setEmail] = (0, _react2.useState)(bookingEmail);
  var [hasError, setHasError] = (0, _react2.useState)(false);
  var modalContent = {
    baseDataTestId: 'AmendEmailAddress',
    title: t('ccui.account.amendEmail.title'),
    description: t('ccui.account.confirmEmail.text'),
    emailLabel: t('ccui.account.emailConfirmation'),
    emailPlaceholder: t('amend.emailAddress'),
    emailErrorMsg: t('ccui.account.error.email.invalid'),
    submitBtn: t('amend.confirmChanges'),
    successNotif: t('dashboard.bookings.resendConfirmationSuccess'),
    inputLabel: t('ccui.account.emailInputLabel')
  };
  var checkIfValid = () => {
    if (!(0, _utils.isEmailValid)(email)) {
      setHasError(true);
    } else {
      setHasError(false);
    }
  };
  var handleOnClose = () => {
    setEmail(bookingEmail);
    setHasError(false);
    onModalClose();
  };
  var _handleEmailChange = value => {
    setEmail(value);
    setEmailCallback(value);
  };
  return (0, _jsxRuntime.jsx)(_react.Box, {
    "data-testId": "AmendEmailAddress",
    children: (0, _jsxRuntime.jsx)(_EmailInputModal.default, {
      modalContent: modalContent,
      isModalVisible: isModalVisible,
      email: email,
      handleEmailChange: email => _handleEmailChange(email),
      handleOnSubmit: onConfirmChanges,
      showNotification: false,
      isLoading: false,
      checkIfValid: checkIfValid,
      handleOnModalClose: handleOnClose,
      hasError: hasError,
      hasCustomerContent: false
    })
  });
}