"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = LoginFormFooter;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _react = require("@chakra-ui/react");
var _utils = require("@whitbread-eos/utils");
var _jsxRuntime = require("react/jsx-runtime");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function LoginFormFooter(_ref) {
  var _labels$login, _labels$login2, _labels$login3;
  var {
    labels,
    styles
  } = _ref;
  var {
    country
  } = (0, _utils.useCustomLocale)();
  var baseDataTestId = 'businessBookerLoginFooter';
  return (0, _jsxRuntime.jsx)(_react.Flex, _objectSpread(_objectSpread({
    "data-testid": (0, _utils.formatDataTestId)(baseDataTestId, 'businessAccountCardContainer')
  }, containerStyle), {}, {
    children: country === 'gb' && (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
      children: [(0, _jsxRuntime.jsx)(_react.Box, _objectSpread(_objectSpread({
        position: "relative"
      }, styles), {}, {
        "data-testid": (0, _utils.formatDataTestId)(baseDataTestId, 'businessAccountCardImg'),
        children: (0, _jsxRuntime.jsx)(_react.Image, {
          src: (0, _utils.formatAssetsUrl)(labels === null || labels === void 0 || (_labels$login = labels.login) === null || _labels$login === void 0 || (_labels$login = _labels$login.businessAccountCard) === null || _labels$login === void 0 || (_labels$login = _labels$login.banner) === null || _labels$login === void 0 ? void 0 : _labels$login.imagePath),
          objectFit: "fill",
          maxWidth: "64px"
        })
      })), (0, _jsxRuntime.jsx)(_react.Text, {
        pl: "2",
        "data-testid": (0, _utils.formatDataTestId)(baseDataTestId, 'businessAccountCardText'),
        children: labels === null || labels === void 0 || (_labels$login2 = labels.login) === null || _labels$login2 === void 0 || (_labels$login2 = _labels$login2.businessAccountCard) === null || _labels$login2 === void 0 ? void 0 : _labels$login2.textBody
      }), (0, _jsxRuntime.jsx)(_react.Link, _objectSpread(_objectSpread({
        "data-testid": (0, _utils.formatDataTestId)(baseDataTestId, 'logInSignUpLink'),
        href: labels === null || labels === void 0 ? void 0 : labels.businessAccountCardRedirectPath
      }, linkStyles), {}, {
        children: labels === null || labels === void 0 || (_labels$login3 = labels.login) === null || _labels$login3 === void 0 || (_labels$login3 = _labels$login3.businessAccountCard) === null || _labels$login3 === void 0 ? void 0 : _labels$login3.buttonLabel
      }))]
    })
  }));
}
var linkStyles = {
  display: 'block',
  fontStyle: 'normal',
  fontWeight: 'normal',
  fontSize: 'md',
  textDecoration: 'underline',
  lineHeight: 'var(--chakra-lineHeights-3)',
  pl: 'sm',
  color: 'btnSecondaryEnabled'
};
var containerStyle = {
  minHeight: '5rem',
  align: 'center',
  justify: 'center',
  paddingX: '1rem',
  paddingY: '1.75rem',
  backgroundColor: 'lightGrey5'
};