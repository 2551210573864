"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = MealSelection;
exports.scrollToMenus = scrollToMenus;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _react = require("@chakra-ui/react");
var _atoms = require("@whitbread-eos/atoms");
var _molecules = require("@whitbread-eos/molecules");
var _utils = require("@whitbread-eos/utils");
var _nextI18next = require("next-i18next");
var _image = _interopRequireDefault(require("next/image"));
var _react2 = require("react");
var _jsxRuntime = require("react/jsx-runtime");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function MealSelection(_ref) {
  var {
    adults = 0,
    kids = 0,
    nights,
    adultsMeals,
    childrenMeals,
    selectedRoom,
    selectedMeals,
    setSelectedMeals,
    showFreeFoodKids,
    prefixDataTestId,
    isForEntireStay = false,
    headingTitle,
    adultsPerRoom = [],
    kidsPerRoom = [],
    logoRestaurantUrl,
    hasMenus,
    onSaveReservation,
    onAddMeal,
    onRemoveMeal,
    reservationId,
    notification,
    showUpdateMealsButton = false,
    hasPromoMeal
  } = _ref;
  var {
    t
  } = (0, _nextI18next.useTranslation)(['common']);
  var {
    language: currentLang
  } = (0, _utils.useCustomLocale)();
  var baseDataTestId = (0, _utils.formatDataTestId)(prefixDataTestId, 'Meals');
  var [selectedRoomMeals, setSelectedRoomMeals] = (0, _react2.useState)({
    adults: [],
    children: [],
    reservationId: ''
  });
  var availableAdultsMeals = adults - (selectedRoomMeals === null || selectedRoomMeals === void 0 ? void 0 : selectedRoomMeals.adults.length);
  var maxAllowChildrenSelection = (0, _utils.freeBreakfastMaxSelectedAllowance)(selectedRoomMeals === null || selectedRoomMeals === void 0 ? void 0 : selectedRoomMeals.adults, adultsMeals);
  var maxChildrenNoSelections = kids > maxAllowChildrenSelection ? maxAllowChildrenSelection : kids;
  var isChildMealSelectionAvailable = adultsMeals.some(meal => meal.freeBreakfastOption && (selectedRoomMeals === null || selectedRoomMeals === void 0 ? void 0 : selectedRoomMeals.adults.some(id => id === meal.id))) && maxAllowChildrenSelection > 0;
  (0, _react2.useEffect)(() => {
    setSelectedRoomMeals(selectedMeals[selectedRoom]);
  }, [selectedMeals, selectedRoom, setSelectedRoomMeals]);
  var availableChildrenMeals = isChildMealSelectionAvailable ? maxChildrenNoSelections - selectedRoomMeals.children.length : 0;
  var updateSelectedMeals = (0, _react2.useCallback)(newRoomState => {
    var newSelectedMealsState = JSON.parse(JSON.stringify(selectedMeals));
    newSelectedMealsState[selectedRoom] = newRoomState;
    setSelectedMeals(newSelectedMealsState);
    onSaveReservation === null || onSaveReservation === void 0 || onSaveReservation(selectedMeals, newSelectedMealsState, selectedRoom);
  }, [setSelectedMeals, selectedRoom, selectedMeals, onSaveReservation]);
  var updateMealSelection = (0, _react2.useCallback)(() => {
    onSaveReservation === null || onSaveReservation === void 0 || onSaveReservation([], selectedMeals, selectedRoom);
  }, [onSaveReservation, selectedMeals, selectedRoom]);
  var handleOnAddAdultMeals = (0, _react2.useCallback)(meal => {
    var newSelectedMealsState = [];
    selectedMeals.forEach((room, index) => {
      newSelectedMealsState.push({
        adults: Array(adultsPerRoom[index]).fill(meal.id),
        children: meal.freeBreakfastOption ? selectedMeals[index].children : [],
        reservationId: room.reservationId
      });
    });
    setSelectedMeals(newSelectedMealsState);
  }, [selectedMeals, selectedRoomMeals]);
  var handleOnAddChildrenMeals = (0, _react2.useCallback)(meal => {
    var newSelectedMealsState = [];
    selectedMeals.forEach((room, index) => {
      newSelectedMealsState.push({
        adults: selectedMeals[index].adults,
        children: Array(kidsPerRoom[index]).fill(meal.id),
        reservationId: room.reservationId
      });
    });
    setSelectedMeals(newSelectedMealsState);
  }, [selectedMeals, selectedRoomMeals]);
  var handleRemoveAdultMeals = (0, _react2.useCallback)(() => {
    var newSelectedMealsState = [];
    selectedMeals.forEach(room => {
      newSelectedMealsState.push({
        adults: [],
        children: [],
        reservationId: room.reservationId
      });
    });
    setSelectedMeals(newSelectedMealsState);
  }, [selectedMeals, selectedRoomMeals]);
  var handleRemoveChildrenMeals = (0, _react2.useCallback)(() => {
    var newSelectedMealsState = [];
    selectedMeals.forEach(room => {
      newSelectedMealsState.push({
        adults: room.adults,
        children: [],
        reservationId: room.reservationId
      });
    });
    setSelectedMeals(newSelectedMealsState);
  }, [selectedMeals, selectedRoomMeals]);
  var isAvailableChildrenMealsForEntire = selectedMeals.some((room, index) => {
    return (0, _utils.freeBreakfastMaxSelectedAllowance)(room === null || room === void 0 ? void 0 : room.adults, adultsMeals) === 0 && kidsPerRoom[index] !== 0;
  });
  var isAdultMealForEntireStay = mealId => {
    return selectedMeals.every((room, index) => room.adults.filter(meal => meal === mealId).length >= adultsPerRoom[index]);
  };
  var isChildrenMealForEntireStay = mealId => {
    return selectedMeals.every((room, index) => room.children.filter(meal => meal === mealId).length >= kidsPerRoom[index]);
  };
  var handleOnSubtractForAdults = (0, _react2.useCallback)(mealId => {
    var index = selectedRoomMeals.adults.indexOf(mealId);
    var newAdultsArray = [...selectedRoomMeals.adults.slice(0, index), ...selectedRoomMeals.adults.slice(index + 1, selectedRoomMeals.adults.length + 1)];
    var maxAllowance = (0, _utils.freeBreakfastMaxSelectedAllowance)(newAdultsArray, adultsMeals);
    var newChildrenArray = selectedRoomMeals.children.slice(0, maxAllowance);
    var newRoomState = {
      adults: newAdultsArray,
      children: newChildrenArray,
      reservationId: reservationId
    };
    updateSelectedMeals(newRoomState);
  }, [adultsMeals, selectedRoomMeals, updateSelectedMeals]);
  var handleOnPlusForAdults = (0, _react2.useCallback)(mealId => {
    var newRoomState = {
      children: [...selectedRoomMeals.children],
      adults: [...selectedRoomMeals.adults, mealId],
      reservationId: reservationId
    };
    updateSelectedMeals(newRoomState);
  }, [selectedRoomMeals, updateSelectedMeals]);
  var handleOnSubtractForChildren = (0, _react2.useCallback)(mealId => {
    var index = selectedRoomMeals.children.indexOf(mealId);
    var newRoomState = _objectSpread(_objectSpread({}, selectedRoomMeals), {}, {
      children: [...selectedRoomMeals.children.slice(0, index), ...selectedRoomMeals.children.slice(index + 1, selectedRoomMeals.children.length + 1)],
      reservationId: reservationId
    });
    updateSelectedMeals(newRoomState);
  }, [selectedRoomMeals, updateSelectedMeals]);
  var handleOnPlusForChildren = (0, _react2.useCallback)(mealId => {
    var newRoomState = _objectSpread(_objectSpread({}, selectedRoomMeals), {}, {
      children: [...selectedRoomMeals.children, mealId],
      reservationId: reservationId
    });
    updateSelectedMeals(newRoomState);
  }, [selectedRoomMeals, updateSelectedMeals]);
  var noOfSelectedAdultsMeals = (0, _react2.useCallback)(mealId => selectedRoomMeals === null || selectedRoomMeals === void 0 ? void 0 : selectedRoomMeals.adults.filter(item => item === mealId).length, [selectedRoomMeals]);
  var noOfSelectedChildrensMeals = (0, _react2.useCallback)(mealId => selectedRoomMeals === null || selectedRoomMeals === void 0 ? void 0 : selectedRoomMeals.children.filter(item => item === mealId).length, [selectedRoomMeals]);
  var getAdultsPluralLabel = (0, _react2.useCallback)(value => value === 1 ? t('upsell.label.adult') : t('upsell.label.adults'), [t]);
  var getChildrenPluralLabel = (0, _react2.useCallback)(value => value === 1 ? t('upsell.label.child') : t('upsell.label.children'), [t]);
  var getNightsPluralLabel = nights > 1 ? t('upsell.label.nights') : t('upsell.label.night');
  if (!(adultsMeals !== null && adultsMeals !== void 0 && adultsMeals.length)) {
    return (0, _jsxRuntime.jsx)(_react.Box, {
      children: notification
    });
  }
  return (0, _jsxRuntime.jsxs)(_react.Box, {
    children: [(0, _jsxRuntime.jsxs)(_react.Flex, _objectSpread(_objectSpread({}, logoWrapperStyle), {}, {
      "data-testid": (0, _utils.formatDataTestId)(baseDataTestId, 'Heading-Wrapper'),
      children: [(0, _jsxRuntime.jsx)(_react.Text, _objectSpread(_objectSpread({}, mealsTitleStyle), {}, {
        "data-testid": (0, _utils.formatDataTestId)(baseDataTestId, 'Heading-Title'),
        children: headingTitle
      })), (0, _jsxRuntime.jsxs)(_react.Flex, {
        direction: "column",
        alignItems: {
          mobile: 'flex-start',
          sm: 'flex-end'
        },
        children: [(0, _jsxRuntime.jsx)(_react.Box, _objectSpread(_objectSpread({}, boxImageStyle), {}, {
          children: logoRestaurantUrl && (0, _jsxRuntime.jsx)(_image.default, {
            alt: "",
            src: logoRestaurantUrl,
            fill: true,
            style: {
              objectFit: 'contain'
            },
            "data-testid": (0, _utils.formatDataTestId)(baseDataTestId, 'Heading-RestaurantLogo'),
            loader: (0, _utils.isIVMEnabled)() ? _utils.akamaiImageLoader : undefined
          })
        })), hasMenus && (0, _jsxRuntime.jsx)(_react.Text, _objectSpread(_objectSpread({}, seeMenusLinkStyle), {}, {
          onClick: scrollToMenus,
          "data-testid": (0, _utils.formatDataTestId)(baseDataTestId, 'Heading-ScrollToMenus'),
          children: t('upsell.meals.see.menus')
        }))]
      })]
    })), showUpdateMealsButton && (0, _jsxRuntime.jsx)(_atoms.Button, {
      onClick: updateMealSelection,
      width: 250,
      size: "md",
      variant: "secondary",
      marginBottom: "lg",
      "data-testid": (0, _utils.formatDataTestId)(baseDataTestId, 'Update-Meal-Selection'),
      children: t('ccui.amend.updateMealSelection')
    }), notification, (0, _jsxRuntime.jsxs)(_react.Flex, _objectSpread(_objectSpread({}, reservationInfoStyle), {}, {
      "data-testid": (0, _utils.formatDataTestId)(baseDataTestId, 'Adults-Heading-Wrapper'),
      children: [(0, _jsxRuntime.jsx)(_react.Heading, _objectSpread(_objectSpread({}, h6HeadingStyle), {}, {
        "data-testid": (0, _utils.formatDataTestId)(baseDataTestId, 'Adults-Heading-Title'),
        children: t('upsell.meals.adult.title')
      })), (0, _jsxRuntime.jsx)(_react.Text, _objectSpread(_objectSpread({}, headingValueStyle), {}, {
        "data-testid": (0, _utils.formatDataTestId)(baseDataTestId, 'Adults-Heading-Values'),
        children: "(".concat(adults, " ").concat(getAdultsPluralLabel(adults), ", ").concat(nights, " ").concat(getNightsPluralLabel, ")")
      }))]
    })), adultsMeals.map(mealItem => {
      var controller = null;
      if (isForEntireStay) {
        var _mealItem$id;
        var isMealForEntireStay = isAdultMealForEntireStay((_mealItem$id = mealItem.id) !== null && _mealItem$id !== void 0 ? _mealItem$id : '');
        controller = renderButtonController({
          labelController: isMealForEntireStay ? t('upsell.extras.remove') : t('upsell.extras.add'),
          onClick: () => isMealForEntireStay ? handleRemoveAdultMeals() : handleOnAddAdultMeals(mealItem),
          dataTestId: (0, _utils.formatDataTestId)(baseDataTestId, 'Adults-MealItem-Button'),
          variant: isMealForEntireStay ? 'tertiary' : 'secondary'
        });
      } else {
        var _mealItem$id2, _mealItem$id3, _mealItem$id8;
        controller = renderAddSubtractController({
          prefixDataTestId: (0, _utils.formatDataTestId)(baseDataTestId, 'Adults-MealItem'),
          mealId: (_mealItem$id2 = mealItem.id) !== null && _mealItem$id2 !== void 0 ? _mealItem$id2 : '',
          labelController: getAdultsPluralLabel(noOfSelectedAdultsMeals((_mealItem$id3 = mealItem.id) !== null && _mealItem$id3 !== void 0 ? _mealItem$id3 : '')),
          onSubtract: () => {
            var _mealItem$id4, _mealItem$id5;
            handleOnSubtractForAdults((_mealItem$id4 = mealItem.id) !== null && _mealItem$id4 !== void 0 ? _mealItem$id4 : '');
            onRemoveMeal === null || onRemoveMeal === void 0 || onRemoveMeal((_mealItem$id5 = mealItem.id) !== null && _mealItem$id5 !== void 0 ? _mealItem$id5 : '', isForEntireStay);
          },
          onPlus: () => {
            var _mealItem$id6, _mealItem$id7;
            handleOnPlusForAdults((_mealItem$id6 = mealItem.id) !== null && _mealItem$id6 !== void 0 ? _mealItem$id6 : '');
            onAddMeal === null || onAddMeal === void 0 || onAddMeal((_mealItem$id7 = mealItem.id) !== null && _mealItem$id7 !== void 0 ? _mealItem$id7 : '', isForEntireStay);
          },
          availableMeals: availableAdultsMeals,
          numberMeals: noOfSelectedAdultsMeals((_mealItem$id8 = mealItem.id) !== null && _mealItem$id8 !== void 0 ? _mealItem$id8 : ''),
          hasPromoMeal
        });
      }
      return (0, _jsxRuntime.jsx)(_react.Box, {
        mt: "md",
        "data-testid": (0, _utils.formatDataTestId)(baseDataTestId, 'Adults-MealItem-Wrapper'),
        children: (0, _jsxRuntime.jsx)(_molecules.MealItem, {
          showFreeFoodKids: showFreeFoodKids,
          freeBreakfastOption: mealItem.freeBreakfastOption,
          allergyInfoSrc: mealItem.allergyInfoSrc,
          imageUrl: mealItem.imageSrc,
          prefixDataTestId: (0, _utils.formatDataTestId)(baseDataTestId, 'Adults-MealItem'),
          title: mealItem.name,
          price: mealItem.price,
          currentLanguage: currentLang,
          currency: mealItem.currency,
          numberNights: nights,
          description: mealItem.description,
          isForEntireStay: isForEntireStay,
          totalPrice: isForEntireStay ? mealItem.totalPriceForEntireStay : mealItem.totalPrice,
          allergyInfoLabel: mealItem.allergyInfoLabel,
          controller: controller,
          hasPromoMeal: hasPromoMeal
        })
      }, mealItem.id);
    }), showFreeFoodKids && childrenMeals.length > 0 && (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
      children: [(0, _jsxRuntime.jsxs)(_react.Flex, _objectSpread(_objectSpread({}, childrenReservationInfoStyle), {}, {
        "data-testid": (0, _utils.formatDataTestId)(baseDataTestId, 'Children-Heading-Wrapper'),
        children: [(0, _jsxRuntime.jsx)(_react.Heading, _objectSpread(_objectSpread({}, h6HeadingStyle), {}, {
          "data-testid": (0, _utils.formatDataTestId)(baseDataTestId, 'Children-Heading-Title'),
          children: t('upsell.meals.child.title')
        })), (0, _jsxRuntime.jsx)(_react.Text, _objectSpread(_objectSpread({}, headingValueStyle), {}, {
          "data-testid": (0, _utils.formatDataTestId)(baseDataTestId, 'Children-Heading-Values'),
          children: "(".concat(kids, " ").concat(getChildrenPluralLabel(kids), ", ").concat(nights, " ").concat(getNightsPluralLabel, ")")
        }))]
      })), childrenMeals.map(mealItem => {
        var controller = null;
        if (isForEntireStay) {
          var _mealItem$id9;
          var isMealForEntireStay = isChildrenMealForEntireStay((_mealItem$id9 = mealItem.id) !== null && _mealItem$id9 !== void 0 ? _mealItem$id9 : '');
          controller = renderButtonController({
            labelController: isMealForEntireStay ? t('upsell.extras.remove') : t('upsell.extras.add'),
            onClick: () => isMealForEntireStay ? handleRemoveChildrenMeals() : handleOnAddChildrenMeals(mealItem),
            isDisabled: isAvailableChildrenMealsForEntire,
            dataTestId: (0, _utils.formatDataTestId)(baseDataTestId, 'Children-MealItem-Button')
          });
        } else {
          var _mealItem$id10, _mealItem$id11, _mealItem$id16;
          controller = renderAddSubtractController({
            prefixDataTestId: (0, _utils.formatDataTestId)(baseDataTestId, 'Children-MealItem'),
            mealId: (_mealItem$id10 = mealItem.id) !== null && _mealItem$id10 !== void 0 ? _mealItem$id10 : '',
            labelController: getChildrenPluralLabel(noOfSelectedChildrensMeals((_mealItem$id11 = mealItem.id) !== null && _mealItem$id11 !== void 0 ? _mealItem$id11 : '')),
            onSubtract: () => {
              var _mealItem$id12, _mealItem$id13;
              handleOnSubtractForChildren((_mealItem$id12 = mealItem.id) !== null && _mealItem$id12 !== void 0 ? _mealItem$id12 : '');
              onRemoveMeal === null || onRemoveMeal === void 0 || onRemoveMeal((_mealItem$id13 = mealItem.id) !== null && _mealItem$id13 !== void 0 ? _mealItem$id13 : '', isForEntireStay);
            },
            onPlus: () => {
              var _mealItem$id14, _mealItem$id15;
              handleOnPlusForChildren((_mealItem$id14 = mealItem.id) !== null && _mealItem$id14 !== void 0 ? _mealItem$id14 : '');
              onAddMeal === null || onAddMeal === void 0 || onAddMeal((_mealItem$id15 = mealItem.id) !== null && _mealItem$id15 !== void 0 ? _mealItem$id15 : '', isForEntireStay);
            },
            availableMeals: availableChildrenMeals,
            numberMeals: noOfSelectedChildrensMeals((_mealItem$id16 = mealItem.id) !== null && _mealItem$id16 !== void 0 ? _mealItem$id16 : '')
          });
        }
        return (0, _jsxRuntime.jsx)(_react.Box, {
          mt: "md",
          "data-testid": (0, _utils.formatDataTestId)(baseDataTestId, 'Children-MealItem-Wrapper'),
          children: (0, _jsxRuntime.jsx)(_molecules.MealItem, {
            showFreeFoodKids: showFreeFoodKids,
            freeBreakfastOption: false,
            allergyInfoSrc: mealItem.allergyInfoSrc,
            allergyInfoLabel: mealItem.allergyInfoLabel,
            imageUrl: mealItem.imageSrc,
            title: mealItem.name,
            currentLanguage: currentLang,
            description: mealItem.description,
            prefixDataTestId: (0, _utils.formatDataTestId)(baseDataTestId, 'Children-MealItem'),
            controller: controller
          })
        }, mealItem.id);
      })]
    })]
  });
  function renderButtonController(_ref2) {
    var {
      labelController,
      onClick,
      isDisabled = false,
      dataTestId,
      variant = 'secondary'
    } = _ref2;
    return (0, _jsxRuntime.jsx)(_atoms.Button, {
      onClick: onClick,
      width: 32,
      variant: variant,
      size: "sm",
      isDisabled: isDisabled,
      "data-testid": dataTestId,
      children: labelController
    });
  }
  function renderAddSubtractController(_ref3) {
    var {
      prefixDataTestId,
      labelController,
      onPlus,
      onSubtract,
      availableMeals,
      numberMeals,
      hasPromoMeal = false
    } = _ref3;
    return (0, _jsxRuntime.jsx)(_atoms.AddSubtract, {
      prefixDataTestId: (0, _utils.formatDataTestId)(prefixDataTestId, 'AddSubtractControls'),
      onSubtract: onSubtract,
      onPlus: onPlus,
      isSubtractDisable: numberMeals === 0,
      value: numberMeals,
      label: labelController,
      isPlusDisable: availableMeals <= 0,
      isPlusHidden: hasPromoMeal,
      isSubtractHidden: hasPromoMeal
    });
  }
}
function scrollToMenus() {
  var element = document.getElementById('menus');
  if (element) {
    element.scrollIntoView({
      block: 'start',
      behavior: 'smooth'
    });
  }
}
var boxImageStyle = {
  mt: {
    mobile: 'md',
    sm: 0
  },
  position: 'relative',
  w: '6.75rem',
  h: '3rem'
};
var mealsTitleStyle = {
  fontWeight: 'semibold',
  fontSize: {
    mobile: 'xl',
    sm: '2xl'
  },
  lineHeight: {
    mobile: '3',
    sm: '4'
  },
  color: 'darkGrey1'
};
var logoWrapperStyle = {
  flexDir: {
    mobile: 'column',
    sm: 'row'
  },
  justifyContent: {
    mobile: 'center',
    sm: 'space-between'
  },
  alignItems: 'flex-start'
};
var h6HeadingStyle = {
  as: 'h6',
  fontSize: 'md',
  fontWeight: 'semibold',
  lineHeight: '3',
  pr: 'sm',
  color: 'darkGrey1',
  fontFamily: 'body'
};
var headingValueStyle = {
  fontSize: 'sm',
  fontWeight: 'normal',
  lineHeight: '2',
  color: 'darkGrey1',
  fontFamily: 'body'
};
var reservationInfoStyle = {
  mt: {
    mobile: 'lg',
    sm: 'md',
    md: '0'
  },
  mb: 'md',
  direction: 'row',
  alignItems: 'center',
  flexWrap: 'wrap'
};
var childrenReservationInfoStyle = _objectSpread(_objectSpread({}, reservationInfoStyle), {}, {
  mt: {
    mobile: 'xl',
    sm: '3xl',
    md: 'xl'
  }
});
var seeMenusLinkStyle = {
  textDecoration: 'underline',
  color: 'btnSecondaryEnabled',
  pt: 'xs',
  fontSize: {
    mobile: 'sm',
    md: 'md'
  },
  lineHeight: {
    mobile: '2',
    md: '3'
  },
  cursor: 'pointer',
  fontWeight: 'normal',
  whiteSpace: 'nowrap'
};