"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var React = _interopRequireWildcard(require("react"));
var _jsxRuntime = require("react/jsx-runtime");
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && {}.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
var SvgChargedParkingEu = props => (0, _jsxRuntime.jsx)("svg", _objectSpread(_objectSpread({
  width: 20,
  height: 20,
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg"
}, props), {}, {
  children: (0, _jsxRuntime.jsx)("path", {
    fillRule: "evenodd",
    clipRule: "evenodd",
    d: "M19 0a1 1 0 0 1 1 1v18a1 1 0 0 1-1 1H1a1 1 0 0 1-1-1V1a1 1 0 0 1 1-1h18Zm0 1H1v18h18V1Zm-3.544 8.793c.276 0 .582.032.919.097l.258.054-.098.791-.213-.061a3.436 3.436 0 0 0-.856-.11c-.534 0-.945.166-1.233.496-.252.29-.401.686-.448 1.19l-.014.224h1.87v.61h-1.874v.669h1.875v.605h-1.875l.01.207c.038.535.188.954.449 1.258.293.342.708.513 1.245.513.302 0 .59-.035.862-.106l.202-.06.098.781-.293.064a4.763 4.763 0 0 1-.879.083c-.807 0-1.443-.239-1.907-.716-.421-.433-.66-1.03-.714-1.79l-.01-.234h-.87v-.605h.87v-.669h-.87v-.61h.87l.015-.25c.069-.733.311-1.31.729-1.73.464-.467 1.091-.701 1.882-.701ZM7.5 4c2.61 0 4 1.39 4 4 0 2.53-1.307 3.915-3.766 3.996L7.5 12H5v5H4V4h3.5Zm0 1H5v6h2.5c2.057 0 3-.943 3-3 0-1.986-.879-2.934-2.791-2.997L7.5 5Z",
    fill: props.color || '#58595B'
  })
}));
var _default = exports.default = SvgChargedParkingEu;