"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = LocationComponent;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _react = require("@chakra-ui/react");
var _jsApiLoader = require("@googlemaps/js-api-loader");
var _atoms = require("@whitbread-eos/atoms");
var _molecules = require("@whitbread-eos/molecules");
var _nextI18next = require("next-i18next");
var _config = _interopRequireDefault(require("next/config"));
var _react2 = require("react");
var _jsxRuntime = require("react/jsx-runtime");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function LocationComponent(_ref) {
  var _data$coordinates, _data$coordinates2;
  var {
    isLoading,
    isError,
    error,
    data,
    queryClient
  } = _ref;
  var {
    t
  } = (0, _nextI18next.useTranslation)(['common']);
  var hotelMapRef = (0, _react2.useRef)(null);
  var {
    publicRuntimeConfig = {}
  } = (0, _config.default)() || {};
  var latitude = data === null || data === void 0 || (_data$coordinates = data.coordinates) === null || _data$coordinates === void 0 ? void 0 : _data$coordinates.latitude;
  var longitude = data === null || data === void 0 || (_data$coordinates2 = data.coordinates) === null || _data$coordinates2 === void 0 ? void 0 : _data$coordinates2.longitude;
  var brand = data === null || data === void 0 ? void 0 : data.brand;
  (0, _react2.useEffect)(() => {
    var loader = new _jsApiLoader.Loader({
      apiKey: publicRuntimeConfig.NEXT_PUBLIC_GOOGLE_MAPS_API_KEY,
      version: 'weekly',
      libraries: []
    });
    loader.load().then(() => {
      window.onunload = () => {
        sessionStorage.removeItem('scroller');
      };
      window.onscroll = () => {
        sessionStorage.scroller = document.documentElement.scrollTop;
      };
      if (hotelMapRef !== null && hotelMapRef !== void 0 && hotelMapRef.current && latitude && longitude) {
        var _google = window.google;
        var hotelMap = new _google.maps.Map(hotelMapRef.current, {
          center: {
            lat: latitude,
            lng: longitude
          },
          zoom: 13
        });
        new _google.maps.Marker({
          position: {
            lat: latitude,
            lng: longitude
          },
          map: hotelMap,
          icon: getMarkerIconByHotelType()
        });
        var panorama = hotelMap.getStreetView();
        _google.maps.event.addListener(panorama, 'closeclick', () => {
          panorama.setVisible(false);
          window.scrollTo(0, sessionStorage.scroller);
        });
      }
    });
    function getMarkerIconByHotelType() {
      var PIHotelMarker = _objectSpread(_objectSpread({}, defaultPinStyles), {}, {
        fillColor: _atoms.theme.colors.btnSecondaryEnabled,
        anchor: new google.maps.Point(22, 72)
      });
      var HUBHotelMarker = _objectSpread(_objectSpread({}, defaultPinStyles), {}, {
        fillColor: _atoms.theme.colors.hubPrimary,
        anchor: new google.maps.Point(22, 72)
      });
      var ZIPHotelMarker = _objectSpread(_objectSpread({}, defaultPinStyles), {}, {
        fillColor: _atoms.theme.colors.zipPrimary,
        anchor: new google.maps.Point(22, 72)
      });
      if ((brand === null || brand === void 0 ? void 0 : brand.toLowerCase()) === 'hub') {
        return HUBHotelMarker;
      }
      if ((brand === null || brand === void 0 ? void 0 : brand.toLowerCase()) === 'zip') {
        return ZIPHotelMarker;
      }
      return PIHotelMarker;
    }
  }, [brand, latitude, longitude, publicRuntimeConfig === null || publicRuntimeConfig === void 0 ? void 0 : publicRuntimeConfig.NEXT_PUBLIC_GOOGLE_MAPS_API_KEY]);
  if (isLoading) {
    return (0, _jsxRuntime.jsx)(_atoms.LoadingSpinner, {
      loadingText: t('searchresults.list.hotel.loading')
    });
  }
  if (isError) {
    return (0, _jsxRuntime.jsx)(_react.Text, {
      children: error.message
    });
  }
  if (!data) {
    return null;
  }
  return (0, _jsxRuntime.jsxs)(_atoms.Section, {
    dataTestId: "hdp_location",
    children: [(0, _jsxRuntime.jsx)(_react.Heading, _objectSpread(_objectSpread({
      as: "h3",
      "data-testid": "hotel-location-title"
    }, headingStyles), {}, {
      children: t('hoteldetails.location')
    })), (0, _jsxRuntime.jsxs)(_react.Flex, {
      direction: {
        base: 'column',
        md: 'row'
      },
      "data-testid": "hotel-location-section",
      children: [(0, _jsxRuntime.jsx)(_react.Box, _objectSpread(_objectSpread({}, mapBoxStyles), {}, {
        children: (0, _jsxRuntime.jsx)(_react.Box, {
          "data-testid": "hotel-google-map",
          h: "full",
          ref: hotelMapRef
        })
      })), (0, _jsxRuntime.jsx)(_react.Box, {
        ml: {
          base: 0,
          md: 4
        },
        w: {
          base: 'full',
          md: '40%',
          xl: '30%'
        },
        "data-testid": "hotel-location-information-section",
        children: (0, _jsxRuntime.jsxs)(_react.Flex, {
          direction: "column",
          "data-testid": "hotel-location-information-column",
          children: [(0, _jsxRuntime.jsx)(_molecules.HotelLocationInformation, {
            queryClient: queryClient
          }), (0, _jsxRuntime.jsx)(_molecules.DirectionsInformation, {
            queryClient: queryClient
          }), (0, _jsxRuntime.jsx)(_molecules.TransportInformation, {
            queryClient: queryClient
          })]
        })
      })]
    })]
  });
}
var MapPinPath = 'M45.0585 21C45.0585 32.598 22.8232 63.5 22.8232 63.5C22.8232 63.5 0.587891 32.598 0.587891 21C0.587891 9.40202 10.543 0 22.8232 0C35.1034 0 45.0585 9.40202 45.0585 21Z';
var defaultPinStyles = {
  path: MapPinPath,
  fillOpacity: 1,
  strokeWeight: 2,
  strokeColor: _atoms.theme.colors.baseWhite,
  rotation: 0,
  scale: 0.5
};
var mapBoxStyles = {
  h: {
    base: 80,
    sm: '21.188rem',
    md: '26.5rem',
    xl: '28rem'
  },
  w: {
    base: 'full',
    md: '66%',
    xl: '68%'
  },
  mb: 4
};
var headingStyles = {
  fontSize: '2xl',
  fontWeight: 'semibold',
  lineHeight: '4',
  mb: '4',
  mt: '3xl',
  size: 'md'
};