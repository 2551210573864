"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "BookingDetailsReservationInformation", {
  enumerable: true,
  get: function get() {
    return _BookingDetailsReservationInformation.default;
  }
});
Object.defineProperty(exports, "BookingDetailsRoomInformation", {
  enumerable: true,
  get: function get() {
    return _BookingDetailsRoomInformation.default;
  }
});
Object.defineProperty(exports, "BookingDetailsTotalCost", {
  enumerable: true,
  get: function get() {
    return _BookingDetailsTotalCost.default;
  }
});
var _BookingDetailsReservationInformation = _interopRequireDefault(require("./BookingDetailsReservationInformation"));
var _BookingDetailsRoomInformation = _interopRequireDefault(require("./BookingDetailsRoomInformation"));
var _BookingDetailsTotalCost = _interopRequireDefault(require("./BookingDetailsTotalCost"));