"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = ManageCookies;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _react = require("@chakra-ui/react");
var _atoms = require("@whitbread-eos/atoms");
var _utils = require("@whitbread-eos/utils");
var _jsxRuntime = require("react/jsx-runtime");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function ManageCookies(_ref) {
  var {
    labels,
    cookiePermissions,
    setCookiePermissions,
    setCookiePolicies,
    modalStyles
  } = _ref;
  var baseTestId = 'ManageCookieModal';
  var manageCookiesLabels = labels === null || labels === void 0 ? void 0 : labels.cookieConsent.cookiePolicies.manageView;
  var setCookiePermissionValue = (cookieName, status) => {
    var newCookiePermissions = cookiePermissions === null || cookiePermissions === void 0 ? void 0 : cookiePermissions.map(cookiePermission => {
      if (cookiePermission.name === cookieName) {
        return _objectSpread(_objectSpread({}, cookiePermission), {}, {
          value: status
        });
      }
      return cookiePermission;
    });
    if (setCookiePermissions) {
      setCookiePermissions(newCookiePermissions);
    }
  };
  return (0, _jsxRuntime.jsxs)(_react.Box, _objectSpread(_objectSpread(_objectSpread({}, modalStyles === null || modalStyles === void 0 ? void 0 : modalStyles.wrapperStyles), manageContainerStyle), {}, {
    "data-testid": (0, _utils.formatDataTestId)(baseTestId, 'Container'),
    children: [(0, _jsxRuntime.jsx)(_react.Text, _objectSpread(_objectSpread({}, modalStyles === null || modalStyles === void 0 ? void 0 : modalStyles.textStyles.description), {}, {
      "data-testid": (0, _utils.formatDataTestId)(baseTestId, 'Description'),
      children: (0, _utils.renderSanitizedHtml)(manageCookiesLabels === null || manageCookiesLabels === void 0 ? void 0 : manageCookiesLabels.description)
    })), (0, _jsxRuntime.jsx)(_react.Divider, _objectSpread(_objectSpread({}, dividerStyle), {}, {
      "data-testid": (0, _utils.formatDataTestId)(baseTestId, 'Divider')
    })), (0, _jsxRuntime.jsx)(_react.Flex, {
      flexDirection: "column",
      "data-testid": (0, _utils.formatDataTestId)(baseTestId, 'CookieGroup'),
      children: manageCookiesLabels === null || manageCookiesLabels === void 0 ? void 0 : manageCookiesLabels.cookieGroup.map(cookie => {
        return (0, _jsxRuntime.jsxs)(_react.Flex, {
          flexDirection: "column",
          "data-testid": (0, _utils.formatDataTestId)(baseTestId, cookie === null || cookie === void 0 ? void 0 : cookie.cookieName),
          mb: "lg",
          children: [(0, _jsxRuntime.jsxs)(_react.Flex, _objectSpread(_objectSpread({
            flexDirection: "row"
          }, cookieGroupTitleStyle.container), {}, {
            children: [(0, _jsxRuntime.jsx)(_react.Text, _objectSpread(_objectSpread({
              "data-testid": (0, _utils.formatDataTestId)(baseTestId, cookie === null || cookie === void 0 ? void 0 : cookie.title)
            }, cookieGroupTitleStyle.text), {}, {
              children: cookie.title
            })), cookie.cookieName === 'permissionEssential' ? (0, _jsxRuntime.jsxs)(_react.Text, _objectSpread(_objectSpread({}, alwaysActiveStyle), {}, {
              "data-testid": (0, _utils.formatDataTestId)(baseTestId, "".concat(cookie.cookieName, "-Text")),
              children: [manageCookiesLabels.alwaysActiveText, ' ']
            })) : (0, _jsxRuntime.jsx)(_atoms.Switcher, {
              size: "lg",
              isChecked: cookie.isAlwaysActive,
              onChange: status => setCookiePermissionValue(cookie.cookieName, status.isChecked),
              "data-testid": (0, _utils.formatDataTestId)(baseTestId, "".concat(cookie.cookieName, "-Switcher"))
            })]
          })), (0, _jsxRuntime.jsx)(_react.Text, _objectSpread(_objectSpread({}, modalStyles === null || modalStyles === void 0 ? void 0 : modalStyles.textStyles.description), {}, {
            "data-testid": (0, _utils.formatDataTestId)(baseTestId, "".concat(cookie === null || cookie === void 0 ? void 0 : cookie.title, "-Description")),
            children: (0, _utils.renderSanitizedHtml)(cookie === null || cookie === void 0 ? void 0 : cookie.description)
          }))]
        }, cookie.cookieName);
      })
    }), (0, _jsxRuntime.jsx)(_react.Flex, _objectSpread(_objectSpread(_objectSpread({}, modalStyles === null || modalStyles === void 0 ? void 0 : modalStyles.buttonsStyle), confirmBtnStyle), {}, {
      children: (0, _jsxRuntime.jsx)(_atoms.Button, {
        size: "full",
        mb: "lg",
        variant: "primary",
        "data-testid": (0, _utils.formatDataTestId)(baseTestId, manageCookiesLabels === null || manageCookiesLabels === void 0 ? void 0 : manageCookiesLabels.saveSettingsButtonText),
        onClick: () => setCookiePolicies(),
        children: manageCookiesLabels === null || manageCookiesLabels === void 0 ? void 0 : manageCookiesLabels.saveSettingsButtonText
      })
    }))]
  }));
}
var manageContainerStyle = {
  display: 'block',
  height: {
    sm: '35rem',
    md: '40rem',
    lg: '45rem'
  },
  width: {
    sm: '30.75rem',
    lg: '30.75rem'
  }
};
var dividerStyle = {
  mt: 'lg',
  mb: 'lg'
};
var cookieGroupTitleStyle = {
  container: {
    justifyContent: 'space-between',
    mb: 'md'
  },
  text: {
    color: 'darkGrey1',
    fontWeight: 'semibold',
    fontSize: '2xl',
    lineHeight: '4'
  }
};
var alwaysActiveStyle = {
  fontWeight: 'medium',
  fontSize: 'xl',
  lineHeight: '3'
};
var confirmBtnStyle = {
  width: 'full'
};