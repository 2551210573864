"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = ResetSearchCriteriaButton;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _react = require("@chakra-ui/react");
var _reactQuery = require("@tanstack/react-query");
var _utils = require("@whitbread-eos/utils");
var _jsxRuntime = require("react/jsx-runtime");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function ResetSearchCriteriaButton(_ref) {
  var _formField$testid;
  var {
    handleSetValue,
    handleResetField,
    formField
  } = _ref;
  var testId = (_formField$testid = formField.testid) !== null && _formField$testid !== void 0 ? _formField$testid : 'Reset';
  var queryClient = (0, _reactQuery.useQueryClient)();
  var handleClick = () => {
    var _formField$props$clea, _formField$props, _formField$props2, _formField$props3;
    if (!handleResetField || !handleSetValue) {
      return;
    }
    handleResetField("bookingReference", {
      defaultValue: ''
    });
    handleResetField("bookerLastName", {
      defaultValue: ''
    });
    handleResetField("arrivalDate", {
      defaultValue: ''
    });
    handleResetField("guestLastName", {
      defaultValue: ''
    });
    handleResetField('hotelDetails', {
      defaultValue: {
        name: '',
        code: ''
      }
    });
    handleResetField('hotelLocation', {
      defaultValue: ''
    });
    handleResetField("cancellationDate", {
      defaultValue: ''
    });
    handleResetField("companyName", {
      defaultValue: ''
    });
    handleResetField("bookerPostcode", {
      defaultValue: ''
    });
    handleResetField("bookerEmail", {
      defaultValue: ''
    });
    handleResetField("bookerPhone", {
      defaultValue: ''
    });
    handleResetField("thirdPartyBookingReferenceNumber", {
      defaultValue: ''
    });
    var {
      setClearHotelLocation,
      setClearHotelName
    } = (_formField$props$clea = formField === null || formField === void 0 || (_formField$props = formField.props) === null || _formField$props === void 0 ? void 0 : _formField$props.clearHotelFields) !== null && _formField$props$clea !== void 0 ? _formField$props$clea : {};
    setClearHotelLocation === null || setClearHotelLocation === void 0 || setClearHotelLocation(true);
    setClearHotelName === null || setClearHotelName === void 0 || setClearHotelName(true);
    var {
      setClearPhoneField
    } = (_formField$props2 = formField === null || formField === void 0 ? void 0 : formField.props) !== null && _formField$props2 !== void 0 ? _formField$props2 : {};
    setClearPhoneField === null || setClearPhoneField === void 0 || setClearPhoneField(true);
    queryClient.cancelQueries({
      queryKey: ['GetSearchBookingsResults']
    });
    queryClient.invalidateQueries({
      queryKey: ['GetSearchBookingsResults'],
      refetchType: 'none'
    });
    formField === null || formField === void 0 || (_formField$props3 = formField.props) === null || _formField$props3 === void 0 || _formField$props3.action();
  };
  return (0, _jsxRuntime.jsx)(_react.Flex, _objectSpread(_objectSpread({
    onClick: () => handleClick()
  }, buttonWrapper), {}, {
    "data-testid": (0, _utils.formatDataTestId)(testId, 'Button'),
    children: (0, _jsxRuntime.jsx)(_react.Text, _objectSpread(_objectSpread({}, textStyle), {}, {
      children: formField.label
    }))
  }));
}
var buttonWrapper = {
  alignItems: 'center',
  justifyContent: 'center',
  cursor: 'pointer',
  w: {
    lg: '24.5rem',
    xl: '26.25rem'
  },
  mt: 'lg',
  _focus: {
    boxShadow: 'none',
    textDecoration: 'underline'
  },
  _hover: {
    textDecoration: 'underline'
  }
};
var textStyle = {
  fontWeight: 'semibold',
  fontSize: 'lg',
  lineHeight: '3',
  color: 'btnSecondaryEnabled'
};