"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = FormDynamicField;
var _objectWithoutProperties2 = _interopRequireDefault(require("@babel/runtime/helpers/objectWithoutProperties"));
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _react = require("@chakra-ui/react");
var _reactHookForm = require("react-hook-form");
var _jsxRuntime = require("react/jsx-runtime");
var _excluded = ["ref"];
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function FormDynamicField(_ref) {
  var _formField$props, _errors$formField$nam;
  var {
    control,
    formField,
    errors,
    handleSetValue,
    handleResetField,
    handleSetError,
    handleClearErrors,
    handleTriggerValidation,
    getValues,
    reset
  } = _ref;
  var errorStyles = formField !== null && formField !== void 0 && (_formField$props = formField.props) !== null && _formField$props !== void 0 && _formField$props.useTooltip && !!(errors !== null && errors !== void 0 && (_errors$formField$nam = errors[formField.name]) !== null && _errors$formField$nam !== void 0 && _errors$formField$nam.message) ? tooltipErrorStyles : {};
  return (0, _jsxRuntime.jsx)(_react.Flex, _objectSpread(_objectSpread({
    direction: "column"
  }, _objectSpread(_objectSpread(_objectSpread({}, defaultStyles), formField.styles), errorStyles)), {}, {
    "data-fieldname": formField.name,
    children: (0, _jsxRuntime.jsx)(_reactHookForm.Controller, {
      name: formField.name,
      control: control,
      render: _ref2 => {
        var {
          field
        } = _ref2;
        var {
            ref: _
          } = field,
          extraPropsField = (0, _objectWithoutProperties2.default)(field, _excluded);
        return (0, _jsxRuntime.jsx)(_react.Box, {
          "data-testid": formField.testid,
          children: !!formField.Component && (0, _jsxRuntime.jsx)(formField.Component, {
            formField: formField,
            field: extraPropsField,
            control: control,
            errors: errors,
            handleSetValue: handleSetValue,
            handleResetField: handleResetField,
            handleSetError: handleSetError,
            handleClearErrors: handleClearErrors,
            handleTriggerValidation: handleTriggerValidation,
            getValues: getValues,
            reset: reset
          })
        });
      }
    })
  }));
}
var defaultStyles = {
  marginBottom: 'var(--chakra-space-md)'
};
var tooltipErrorStyles = {
  marginBottom: 'var(--chakra-space-6xl)'
};