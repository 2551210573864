"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = ResultsContainer;
var _reactQuery = require("@tanstack/react-query");
var _atoms = require("@whitbread-eos/atoms");
var _molecules = require("@whitbread-eos/molecules");
var _jsxRuntime = require("react/jsx-runtime");
function ResultsContainer(_ref) {
  var {
    handleClickRow,
    selectedRow,
    dataForUpdateForm,
    baseDataTestId,
    t,
    resultsData
  } = _ref;
  var queryClient = (0, _reactQuery.useQueryClient)();
  var headerTitles = [{
    id: 'GuestName',
    text: t('ccui.guestAccounts.col.guestName')
  }, {
    id: 'CompanyName',
    text: t('ccui.guestAccounts.col.companyName')
  }, {
    id: 'Email',
    text: t('ccui.guestAccounts.col.email')
  }, {
    id: 'PostCodeHome',
    text: t('ccui.guestAccounts.col.homePostcode')
  }, {
    id: 'PostCodeCompany',
    text: t('ccui.guestAccounts.col.companyPostcode')
  }];
  return (0, _jsxRuntime.jsx)(_atoms.ErrorBoundary, {
    children: (0, _jsxRuntime.jsx)(_molecules.SearchAccountResultList, {
      handleClickRow: handleClickRow,
      selectedRow: selectedRow,
      dataForUpdateForm: dataForUpdateForm,
      queryClient: queryClient,
      loading: resultsData.isLoading,
      baseDataTestId: baseDataTestId,
      rows: resultsData.guests,
      t: t,
      headerTitles: headerTitles
    })
  });
}