"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = OpeningSoonNotificationComponent;
var _react = require("@chakra-ui/react");
var _atoms = require("@whitbread-eos/atoms");
var _nextI18next = require("next-i18next");
var _jsxRuntime = require("react/jsx-runtime");
function OpeningSoonNotificationComponent(_ref) {
  var {
    isLoading,
    isError,
    error,
    name,
    hotelOpeningDate,
    currentLang,
    isHotelOpeningSoon
  } = _ref;
  var {
    t
  } = (0, _nextI18next.useTranslation)(['common']);
  if (isLoading) {
    return (0, _jsxRuntime.jsx)(_jsxRuntime.Fragment, {});
  }
  if (isError) {
    return (0, _jsxRuntime.jsx)(_react.Text, {
      children: error.message
    });
  }
  if (!isHotelOpeningSoon) {
    return null;
  }
  var formattedOpeningDate = hotelOpeningDate && new Date(hotelOpeningDate).toLocaleDateString(currentLang === 'en' ? 'en-GB' : 'de-DE', {
    day: 'numeric',
    month: 'long',
    year: 'numeric'
  });
  return (0, _jsxRuntime.jsx)(_react.Box, {
    mt: {
      base: 'md',
      lg: '6'
    },
    "data-testid": "opening-soon-notification",
    children: (0, _jsxRuntime.jsx)(_atoms.Notification, {
      title: "",
      description: "".concat(name, " ").concat(t('hoteldetails.openingDateText'), " ").concat(formattedOpeningDate),
      status: "info",
      variant: "info",
      svg: (0, _jsxRuntime.jsx)(_atoms.Info, {}),
      maxW: {
        base: 'full'
      },
      lineHeight: "2",
      prefixDataTestId: "opening-soon-notification"
    })
  });
}