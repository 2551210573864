"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = AuthContentManagerBBVariantContainer;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _api = require("@whitbread-eos/api");
var _utils = require("@whitbread-eos/utils");
var _router = require("next/router");
var _react = require("react");
var _AuthContentManagerBBVariant = _interopRequireDefault(require("./AuthContentManagerBBVariant.component"));
var _jsxRuntime = require("react/jsx-runtime");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function AuthContentManagerBBVariantContainer(_ref) {
  var _data$headerInformati5, _data$headerInformati6;
  var {
    isLoginModalOpen,
    showRegisterNotification,
    hasRegisteredSuccessfully,
    toggleLoginModal
  } = _ref;
  var router = (0, _router.useRouter)();
  var {
    redirect
  } = router.query;
  var {
    language: currentLanguage,
    country: currentCountry
  } = (0, _utils.useCustomLocale)();
  var {
    data
  } = (0, _utils.useQueryRequest)(['GetStaticContent', currentLanguage, currentCountry], _api.GET_STATIC_CONTENT, {
    language: currentLanguage,
    country: currentCountry,
    site: _api.SITE_BB,
    businessBooker: true
  });
  var [labels, setLabels] = (0, _react.useState)(_api.DEFAULT_LOGIN_LABELS);
  (0, _react.useEffect)(() => {
    var _data$headerInformati, _data$headerInformati2;
    if (data !== null && data !== void 0 && (_data$headerInformati = data.headerInformation) !== null && _data$headerInformati !== void 0 && (_data$headerInformati = _data$headerInformati.content) !== null && _data$headerInformati !== void 0 && _data$headerInformati.authentication && data !== null && data !== void 0 && (_data$headerInformati2 = data.headerInformation) !== null && _data$headerInformati2 !== void 0 && (_data$headerInformati2 = _data$headerInformati2.config) !== null && _data$headerInformati2 !== void 0 && _data$headerInformati2.authentication) {
      var _data$headerInformati3, _data$headerInformati4;
      setLabels(_objectSpread(_objectSpread({}, data === null || data === void 0 || (_data$headerInformati3 = data.headerInformation) === null || _data$headerInformati3 === void 0 || (_data$headerInformati3 = _data$headerInformati3.content) === null || _data$headerInformati3 === void 0 ? void 0 : _data$headerInformati3.authentication), data === null || data === void 0 || (_data$headerInformati4 = data.headerInformation) === null || _data$headerInformati4 === void 0 || (_data$headerInformati4 = _data$headerInformati4.config) === null || _data$headerInformati4 === void 0 ? void 0 : _data$headerInformati4.authentication));
    }
  }, [data === null || data === void 0 || (_data$headerInformati5 = data.headerInformation) === null || _data$headerInformati5 === void 0 || (_data$headerInformati5 = _data$headerInformati5.config) === null || _data$headerInformati5 === void 0 ? void 0 : _data$headerInformati5.authentication, data === null || data === void 0 || (_data$headerInformati6 = data.headerInformation) === null || _data$headerInformati6 === void 0 || (_data$headerInformati6 = _data$headerInformati6.content) === null || _data$headerInformati6 === void 0 ? void 0 : _data$headerInformati6.authentication]);
  return (0, _jsxRuntime.jsx)(_AuthContentManagerBBVariant.default, {
    isLoginModalOpen,
    toggleLoginModal,
    showRegisterNotification,
    hasRegisteredSuccessfully,
    labels,
    onGoBack: redirect ? () => router.back() : undefined,
    goBackButtonText: redirect ? labels === null || labels === void 0 ? void 0 : labels.goBackButton : undefined
  });
}