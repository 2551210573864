"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = AuthGuard;
var _utils = require("@whitbread-eos/utils");
var _router = require("next/router");
var _react = require("react");
var _AuthContentManagerBBVariant = _interopRequireDefault(require("../AuthContentManager/AuthContentManagerBBVariant"));
var _jsxRuntime = require("react/jsx-runtime");
var publicRoutes = ['/404', '/graphql', '/reset-password', '/business-booker/reset-password', '/business-booker/graphql', '/business-booker/404'];
function AuthGuard(_ref) {
  var {
    hasRegisteredSuccessfully
  } = _ref;
  var router = (0, _router.useRouter)();
  var {
    key
  } = router.query;
  var [isLoginModalOpen, setIsLoginModalOpen] = (0, _react.useState)(true);
  var {
    isLoggedIn
  } = (0, _utils.useUserData)();
  var toggleLoginModal = () => {
    setIsLoginModalOpen(!isLoginModalOpen);
  };
  return (0, _jsxRuntime.jsx)(_jsxRuntime.Fragment, {
    children: !isLoggedIn && !publicRoutes.includes(router.route) && (0, _jsxRuntime.jsx)(_AuthContentManagerBBVariant.default, {
      isLoginModalOpen: isLoginModalOpen,
      showRegisterNotification: !!key,
      hasRegisteredSuccessfully: hasRegisteredSuccessfully,
      toggleLoginModal: toggleLoginModal
    })
  });
}