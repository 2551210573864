"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = checkIsUnique;
var _atoms = require("@whitbread-eos/atoms");
var yup = _interopRequireWildcard(require("yup"));
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && {}.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
function checkIsUnique() {
  yup.addMethod(yup.array, 'unique', function (field, message) {
    return this.test('unique', message, function (array) {
      if (array) {
        var errors = [];
        array.filter(field => {
          var _field$firstName, _field$lastName;
          return ((_field$firstName = field.firstName) === null || _field$firstName === void 0 ? void 0 : _field$firstName.length) > _atoms.FORM_VALIDATIONS.FIRST_NAME.MIN && ((_field$lastName = field.lastName) === null || _field$lastName === void 0 ? void 0 : _field$lastName.length) > _atoms.FORM_VALIDATIONS.LAST_NAME.MIN;
        }).map(field => {
          return "".concat(field.title).concat(field.firstName).concat(field.lastName);
        }).forEach((value, index, array) => {
          var isDuplicated = array.indexOf(value) !== array.lastIndexOf(value);
          if (isDuplicated) {
            errors.push(this.createError({
              path: "".concat(this.path, ".").concat(index, ".").concat(field),
              message
            }));
          }
        });
        if (errors.length > 0) {
          return new yup.ValidationError(errors);
        }
        return true;
      }
      return true;
    });
  });
}