"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var React = _interopRequireWildcard(require("react"));
var _jsxRuntime = require("react/jsx-runtime");
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && {}.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
var SvgLanguageSelector = props => (0, _jsxRuntime.jsx)("svg", _objectSpread(_objectSpread({
  width: 20,
  height: 20,
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg"
}, props), {}, {
  children: (0, _jsxRuntime.jsx)("path", {
    fillRule: "evenodd",
    clipRule: "evenodd",
    d: "M10 0c5.523 0 10 4.477 10 10s-4.477 10-10 10S0 15.523 0 10 4.477 0 10 0Zm2.647 14.001H7.353C7.901 16.978 8.998 19 10 19c1.002 0 2.099-2.022 2.647-4.999Zm-6.314 0H1.936a9.016 9.016 0 0 0 6.161 4.797c-.781-1.058-1.406-2.749-1.764-4.797Zm11.73 0h-4.396c-.358 2.048-.983 3.739-1.764 4.797a9.015 9.015 0 0 0 6.16-4.797Zm-11.88-7H1.512A8.985 8.985 0 0 0 1 10c0 1.052.18 2.062.512 3h4.671A24.149 24.149 0 0 1 6 10c0-1.045.064-2.053.183-3Zm6.622 0h-5.61A22.865 22.865 0 0 0 7 10c0 1.055.07 2.064.195 3h5.61c.125-.936.195-1.945.195-3s-.07-2.064-.195-3Zm5.683 0h-4.671C13.936 7.946 14 8.954 14 10c0 1.046-.064 2.053-.183 3h4.67A8.985 8.985 0 0 0 19 10c0-1.052-.18-2.061-.512-3ZM8.098 1.2l-.162.037a9.018 9.018 0 0 0-6 4.762h4.397c.358-2.049.983-3.74 1.764-4.798ZM10 1C8.998 1 7.9 3.022 7.353 6h5.294c-.531-2.889-1.58-4.878-2.557-4.995L10 1Zm1.903.202.01.014c.777 1.06 1.398 2.745 1.754 4.784h4.397a9.016 9.016 0 0 0-6.161-4.798Z",
    fill: props.color || '#58595B'
  })
}));
var _default = exports.default = SvgLanguageSelector;