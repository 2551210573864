"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = BottomReviewSection;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _react = require("@chakra-ui/react");
var _atoms = require("@whitbread-eos/atoms");
var _utils = require("@whitbread-eos/utils");
var _nextI18next = require("next-i18next");
var _react2 = require("react");
var _constants = require("../../../utils/constants");
var _jsxRuntime = require("react/jsx-runtime");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function BottomReviewSection(_ref) {
  var _tripAdvisorData$numb, _tripAdvisorData$subR;
  var {
    tripAdvisorData
  } = _ref;
  var {
    t
  } = (0, _nextI18next.useTranslation)(['common']);
  var {
    language
  } = (0, _utils.useCustomLocale)();
  var [isReviewOpen, setIsReviewOpen] = (0, _react2.useState)(false);
  var {
    isLessThanSm
  } = (0, _utils.useScreenSize)();
  var onRedirectToTripAdvisor = url => {
    if (url) window.open(url, '_blank', 'noopener,noreferrer');
  };
  if (!tripAdvisorData || !Object.keys(tripAdvisorData).length) {
    return null;
  }
  var renderUserReviewSection = reviews => {
    return reviews === null || reviews === void 0 ? void 0 : reviews.map(reviewData => {
      var _reviewData$rating, _reviewData$published, _reviewData$user, _reviewData$user2;
      return (0, _jsxRuntime.jsxs)(_react.Box, {
        itemProp: "review",
        itemScope: true,
        itemType: _constants.TRIP_ADVISOR_SCHEMA_REVIEW_URL,
        marginY: 3,
        children: [(0, _jsxRuntime.jsx)(_react.Box, {
          itemProp: "publisher",
          itemScope: true,
          itemType: _constants.TRIP_ADVISOR_SCHEMA_ORGANIZATION_URL,
          children: (0, _jsxRuntime.jsx)("meta", {
            itemProp: "name",
            content: "Trip Advisor"
          })
        }), (0, _jsxRuntime.jsxs)(_react.Flex, {
          alignItems: 'center',
          children: [(0, _jsxRuntime.jsx)("span", {
            itemProp: "reviewRating",
            itemScope: true,
            itemType: _constants.TRIP_ADVISOR_SCHEMA_RATING_URL,
            children: (0, _jsxRuntime.jsx)(_react.Image, {
              itemProp: "image",
              src: "".concat(_constants.TRIP_ADVISOR_RATING_IMAGE_URL_PART1).concat(reviewData === null || reviewData === void 0 || (_reviewData$rating = reviewData.rating) === null || _reviewData$rating === void 0 ? void 0 : _reviewData$rating.toFixed(1)).concat(_constants.TRIP_ADVISOR_RATING_IMAGE_URL_PART2)
            })
          }), (0, _jsxRuntime.jsx)(_react.Text, {
            itemProp: "datePublished",
            marginLeft: '1',
            children: (0, _utils.formatDate)(new Date((_reviewData$published = reviewData === null || reviewData === void 0 ? void 0 : reviewData.publishedDate) !== null && _reviewData$published !== void 0 ? _reviewData$published : '').toDateString(), 'dd MMM yyyy', language)
          })]
        }), (0, _jsxRuntime.jsxs)(_react.Box, {
          itemProp: "author",
          itemScope: true,
          itemType: _constants.TRIP_ADVISOR_SCHEMA_PERSON_URL,
          children: [(0, _jsxRuntime.jsx)(_react.Text, {
            itemProp: "name",
            fontWeight: 'semibold',
            marginY: 1,
            children: reviewData === null || reviewData === void 0 || (_reviewData$user = reviewData.user) === null || _reviewData$user === void 0 ? void 0 : _reviewData$user.username
          }), (0, _jsxRuntime.jsx)(_react.Text, {
            marginY: 1,
            children: reviewData === null || reviewData === void 0 || (_reviewData$user2 = reviewData.user) === null || _reviewData$user2 === void 0 ? void 0 : _reviewData$user2.location
          }), (0, _jsxRuntime.jsxs)(_react.Flex, {
            marginY: 1,
            children: [(0, _jsxRuntime.jsx)(_react.Text, {
              fontWeight: 'bold',
              children: "".concat(t('hoteldetails.tripadvisorreviewstriptype'), ": ")
            }), (0, _jsxRuntime.jsx)(_react.Text, {
              marginLeft: 1,
              children: reviewData === null || reviewData === void 0 ? void 0 : reviewData.tripType
            })]
          })]
        }), (0, _jsxRuntime.jsx)(_react.Box, {
          itemProp: "name",
          fontStyle: 'italic',
          marginY: 1,
          children: "\"".concat(reviewData === null || reviewData === void 0 ? void 0 : reviewData.title, "\"")
        }), (0, _jsxRuntime.jsx)(_react.Box, {
          itemProp: "reviewBody",
          marginY: 1,
          children: reviewData === null || reviewData === void 0 ? void 0 : reviewData.text
        })]
      }, "reviews-".concat(reviewData === null || reviewData === void 0 ? void 0 : reviewData.publishedDate));
    });
  };
  return (0, _jsxRuntime.jsx)(_atoms.Section, {
    dataTestId: "tripadvisor-bottom-review-section-hdp",
    children: (0, _jsxRuntime.jsxs)(_react.Box, _objectSpread(_objectSpread({}, containerStyles), {}, {
      "data-testid": "tripadvisor-user-review-section",
      id: "tripadvisor-user-review-section",
      children: [(0, _jsxRuntime.jsx)(_react.Heading, _objectSpread(_objectSpread({}, headingStyles), {}, {
        as: "h3",
        children: t('hoteldetails.tripadvisorreviews.title')
      })), (0, _jsxRuntime.jsxs)(_react.Flex, _objectSpread(_objectSpread({}, reviewContainerFlexStyles), {}, {
        children: [!isLessThanSm && (0, _jsxRuntime.jsxs)(_react.Box, _objectSpread(_objectSpread({}, userReviewContainerStyles), {}, {
          children: [(0, _jsxRuntime.jsx)(_react.Text, {
            marginY: 3,
            children: "".concat(t('hoteldetails.tripadvisorreviewsonetofive'), "\n                 ").concat(tripAdvisorData !== null && tripAdvisorData !== void 0 && tripAdvisorData.numberOfReviews ? tripAdvisorData === null || tripAdvisorData === void 0 || (_tripAdvisorData$numb = tripAdvisorData.numberOfReviews) === null || _tripAdvisorData$numb === void 0 ? void 0 : _tripAdvisorData$numb.toLocaleString() : 0, "\n                ").concat(t('hoteldetails.tripadvisorreviews'))
          }), renderUserReviewSection(tripAdvisorData === null || tripAdvisorData === void 0 ? void 0 : tripAdvisorData.reviews), (0, _jsxRuntime.jsxs)(_react.Flex, {
            marginY: 4,
            alignItems: 'center',
            children: [(0, _jsxRuntime.jsx)(_react.Text, _objectSpread(_objectSpread({}, redirectLinkStyles), {}, {
              onClick: () => {
                var _tripAdvisorData$webU;
                return onRedirectToTripAdvisor((_tripAdvisorData$webU = tripAdvisorData === null || tripAdvisorData === void 0 ? void 0 : tripAdvisorData.webUrl) !== null && _tripAdvisorData$webU !== void 0 ? _tripAdvisorData$webU : '');
              },
              children: t('tripadvisor.see.reviews')
            })), (0, _jsxRuntime.jsx)(_react.Text, {
              children: t('tripadvisor.see.reviews.onTripadvisor')
            })]
          })]
        })), (0, _jsxRuntime.jsxs)(_react.Box, _objectSpread(_objectSpread({}, subReviewContainerStyles), {}, {
          children: [(0, _jsxRuntime.jsx)(_react.Text, {
            fontWeight: 'bold',
            fontSize: 'sm',
            children: t('hoteldetails.tripadvisorreviewsbroughtby')
          }), (0, _jsxRuntime.jsx)(_react.Image, _objectSpread(_objectSpread({}, logoStyle), {}, {
            src: t('tripadvisor.primarylogo')
          })), (0, _jsxRuntime.jsx)(_react.Box, {
            "data-testid": "subrating-section",
            children: tripAdvisorData === null || tripAdvisorData === void 0 || (_tripAdvisorData$subR = tripAdvisorData.subRatings) === null || _tripAdvisorData$subR === void 0 ? void 0 : _tripAdvisorData$subR.map(subRatingData => {
              var _subRatingData$value;
              return (0, _jsxRuntime.jsxs)(_react.Flex, {
                children: [(0, _jsxRuntime.jsx)(_react.Box, {
                  minWidth: '60%',
                  maxWidth: '60%',
                  children: (0, _jsxRuntime.jsx)(_react.Text, {
                    children: subRatingData === null || subRatingData === void 0 ? void 0 : subRatingData.localisedName
                  })
                }), (0, _jsxRuntime.jsx)(_react.Image, {
                  src: "".concat(_constants.TRIP_ADVISOR_RATING_IMAGE_URL_PART1).concat(subRatingData === null || subRatingData === void 0 || (_subRatingData$value = subRatingData.value) === null || _subRatingData$value === void 0 ? void 0 : _subRatingData$value.toFixed(1)).concat(_constants.TRIP_ADVISOR_RATING_IMAGE_URL_PART2),
                  marginRight: 2
                })]
              }, "sunbrating-".concat(subRatingData === null || subRatingData === void 0 ? void 0 : subRatingData.localisedName));
            })
          }), (0, _jsxRuntime.jsx)(_react.Box, {
            children: (0, _jsxRuntime.jsx)(_react.Text, _objectSpread(_objectSpread({}, redirectLinkStyles), {}, {
              marginTop: 4,
              onClick: () => {
                var _tripAdvisorData$writ;
                return onRedirectToTripAdvisor((_tripAdvisorData$writ = tripAdvisorData === null || tripAdvisorData === void 0 ? void 0 : tripAdvisorData.writeReview) !== null && _tripAdvisorData$writ !== void 0 ? _tripAdvisorData$writ : '');
              },
              children: t('tripadvisor.write.review')
            }))
          }), isLessThanSm && (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
            children: [isReviewOpen && renderUserReviewSection(tripAdvisorData === null || tripAdvisorData === void 0 ? void 0 : tripAdvisorData.reviews), (0, _jsxRuntime.jsx)(_react.Box, {
              children: (0, _jsxRuntime.jsxs)(_atoms.Button, _objectSpread(_objectSpread({}, buttonStyles), {}, {
                size: "md",
                variant: "tertiary",
                onClick: () => {
                  setIsReviewOpen(!isReviewOpen);
                },
                children: [isReviewOpen ? t('hoteldetails.tripadvisorreviewsclosereview') : t('hoteldetails.tripadvisorreviewsreadreviews'), (0, _jsxRuntime.jsx)(_atoms.Icon, {
                  svg: isReviewOpen ? (0, _jsxRuntime.jsx)(_atoms.ChevronUp, {
                    color: "#9f78a3"
                  }) : (0, _jsxRuntime.jsx)(_atoms.ChevronDown, {
                    color: "#9f78a3"
                  }),
                  marginLeft: 2
                })]
              }))
            })]
          })]
        }))]
      }))]
    }))
  });
}
var headingStyles = {
  fontWeight: 'semibold',
  fontSize: {
    base: 'xl',
    sm: '2xl'
  },
  lineHeight: {
    base: '3',
    sm: '4'
  }
};
var containerStyles = {
  mt: {
    base: 'lg',
    sm: '3xl'
  },
  mb: {
    base: 'lg',
    sm: '2xl'
  }
};
var reviewContainerFlexStyles = {
  flexDirection: {
    base: 'column-reverse',
    sm: 'row'
  },
  marginRight: '2'
};
var redirectLinkStyles = {
  display: 'inline-block',
  fontSize: 'md',
  lineHeight: '2',
  marginRight: '1',
  color: 'btnSecondaryEnabled',
  textDecoration: 'underline',
  _hover: {
    cursor: 'pointer'
  }
};
var subReviewContainerStyles = {
  mt: {
    base: '5',
    sm: '0'
  },
  ml: {
    base: '0',
    sm: '4'
  }
};
var buttonStyles = {
  mt: '4',
  mb: '1.188rem',
  w: 'full',
  minWidth: {
    sm: '14.3rem'
  },
  sx: {
    ':hover': {
      background: 'inherit',
      boxShadow: 'none',
      color: '#9f78a3'
    }
  }
};
var logoStyle = {
  height: '38px',
  marginY: '3',
  objectFit: 'cover'
};
var userReviewContainerStyles = {
  maxW: {
    base: '100%',
    sm: '70%'
  },
  w: '100%'
};