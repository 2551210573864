"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = HotelRestaurantMenuContent;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _react = require("@chakra-ui/react");
var _atoms = require("@whitbread-eos/atoms");
var _utils = require("@whitbread-eos/utils");
var _image = _interopRequireDefault(require("next/image"));
var _HotelRestaurantMenuDescription = _interopRequireDefault(require("./HotelRestaurantMenuDescription.component"));
var _jsxRuntime = require("react/jsx-runtime");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function HotelRestaurantMenuContent(_ref) {
  var {
    menu,
    isTabPanel
  } = _ref;
  var cardStyles = {
    flexWrap: 'wrap',
    m: isTabPanel ? '0.25rem -1rem -1rem -1rem' : 0,
    pt: isTabPanel ? 0 : 5
  };
  return (0, _jsxRuntime.jsxs)(_react.Flex, _objectSpread(_objectSpread({}, cardStyles), {}, {
    "data-testid": "".concat(menu.name, "-menu"),
    children: [renderMenuImage(), (0, _jsxRuntime.jsxs)(_react.Box, {
      w: {
        sm: 'full',
        md: '50%'
      },
      "data-testid": "hdp_restaurantMenuContent",
      children: [renderMenuDescription(), renderMenuButton()]
    })]
  }));
  function renderMenuImage() {
    var _menu$imageSrc, _menu$name;
    var imageContainerStyles = {
      position: 'relative',
      mr: {
        base: 0,
        md: 6
      },
      mb: 5,
      w: {
        base: 'full',
        md: 'calc(50% - var(--chakra-space-6))'
      },
      h: {
        base: '11.548rem',
        mobile: '10.393rem',
        sm: '19.488rem',
        md: '12.126rem',
        lg: '21.22rem',
        xl: '22.736rem'
      }
    };
    return (0, _jsxRuntime.jsx)(_react.Box, _objectSpread(_objectSpread({}, imageContainerStyles), {}, {
      "data-testid": "".concat(menu.name, "-menu-image"),
      children: (0, _jsxRuntime.jsx)(_image.default, {
        src: (0, _utils.formatAssetsUrl)((_menu$imageSrc = menu.imageSrc) !== null && _menu$imageSrc !== void 0 ? _menu$imageSrc : ''),
        alt: (_menu$name = menu.name) !== null && _menu$name !== void 0 ? _menu$name : '',
        fill: true,
        style: {
          objectFit: 'cover'
        },
        loader: (0, _utils.isIVMEnabled)() ? _utils.akamaiImageLoader : undefined
      })
    }));
  }
  function renderMenuDescription() {
    var _menu$description;
    return (0, _jsxRuntime.jsx)(_react.Box, {
      mb: "1.188rem",
      w: "full",
      children: (0, _jsxRuntime.jsx)(_HotelRestaurantMenuDescription.default, {
        menuDescription: (_menu$description = menu.description) !== null && _menu$description !== void 0 ? _menu$description : ''
      })
    });
  }
  function renderMenuButton() {
    var buttonStyles = {
      mb: '1.188rem',
      w: {
        base: 'full',
        mobile: 'full',
        xs: 'full',
        sm: '48.5%',
        md: '37%',
        lg: '32%',
        xl: '32%'
      },
      minWidth: {
        sm: '14.3rem',
        md: '16.6rem',
        lg: '24.5rem',
        xl: '26.25rem'
      },
      sx: {
        ':hover': {
          background: 'inherit',
          boxShadow: 'none',
          color: '#9f78a3'
        }
      }
    };
    if (!(menu !== null && menu !== void 0 && menu.menuSrc)) {
      return null;
    }
    return (0, _jsxRuntime.jsx)(_atoms.Button, _objectSpread(_objectSpread({
      size: "md",
      variant: "tertiary"
    }, buttonStyles), {}, {
      onClick: () => {
        var _menu$menuSrc;
        return window.open((0, _utils.formatAssetsUrl)((_menu$menuSrc = menu === null || menu === void 0 ? void 0 : menu.menuSrc) !== null && _menu$menuSrc !== void 0 ? _menu$menuSrc : ''), '_blank');
      },
      children: menu.menuLabel
    }));
  }
}