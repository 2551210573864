"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.WARNING_NOTIFICATION_TYPE = exports.INFO_NOTIFICATION_TYPE = void 0;
exports.default = AnnouncementNotificationComponent;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _react = require("@chakra-ui/react");
var _api = require("@whitbread-eos/api");
var _atoms = require("@whitbread-eos/atoms");
var _utils = require("@whitbread-eos/utils");
var _jsxRuntime = require("react/jsx-runtime");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
var INFO_NOTIFICATION_TYPE = exports.INFO_NOTIFICATION_TYPE = 'info';
var WARNING_NOTIFICATION_TYPE = exports.WARNING_NOTIFICATION_TYPE = 'warning';
function AnnouncementNotificationComponent(_ref) {
  var _announcement$bbText$, _announcement$bbText, _announcement$text$re, _announcement$text;
  var {
    isLoading,
    isError,
    error,
    announcement,
    styles,
    channel
  } = _ref;
  if (isLoading) {
    return (0, _jsxRuntime.jsx)(_react.Text, {
      "data-testid": "loading",
      children: " "
    });
  }
  if (isError) {
    return (0, _jsxRuntime.jsx)(_react.Text, {
      children: error.message
    });
  }
  if (!announcement || (announcement === null || announcement === void 0 ? void 0 : announcement.showAnnouncement) !== 'true' || !isDateBetweenStartAndEnd() || !(0, _utils.isStringValid)(announcement.text)) {
    return null;
  }
  return (0, _jsxRuntime.jsx)(_react.Box, {
    mt: {
      base: 'md',
      lg: 'lg'
    },
    "data-testid": "announcement-notification-".concat(announcement.type),
    children: (0, _jsxRuntime.jsx)(_atoms.Notification, _objectSpread(_objectSpread(_objectSpread({
      title: announcement.title,
      description: channel === _api.Channel.Bb ? (_announcement$bbText$ = announcement === null || announcement === void 0 || (_announcement$bbText = announcement.bbText) === null || _announcement$bbText === void 0 ? void 0 : _announcement$bbText.replace(/href="\//g, 'href="https://www.premierinn.com/')) !== null && _announcement$bbText$ !== void 0 ? _announcement$bbText$ : '' : (_announcement$text$re = announcement === null || announcement === void 0 || (_announcement$text = announcement.text) === null || _announcement$text === void 0 ? void 0 : _announcement$text.replace(/href="\//g, 'href="https://www.premierinn.com/')) !== null && _announcement$text$re !== void 0 ? _announcement$text$re : ''
    }, getNotificationPropsByType()), _objectSpread(_objectSpread({}, notificationStyles), styles)), {}, {
      prefixDataTestId: "announcement-notification-".concat(announcement.type),
      sx: {
        a: {
          color: 'darkGrey1',
          textDecoration: 'underline'
        }
      },
      isInnerHTML: true
    }))
  });
  function getNotificationPropsByType() {
    if ((announcement === null || announcement === void 0 ? void 0 : announcement.type) === INFO_NOTIFICATION_TYPE) {
      return {
        variant: 'info',
        status: 'info',
        svg: (0, _jsxRuntime.jsx)(_atoms.Info, {})
      };
    }
    if ((announcement === null || announcement === void 0 ? void 0 : announcement.type) === WARNING_NOTIFICATION_TYPE) {
      return {
        variant: 'alert',
        status: 'warning',
        svg: (0, _jsxRuntime.jsx)(_atoms.Alert, {})
      };
    }
    return {
      variant: 'info',
      status: 'info',
      svg: (0, _jsxRuntime.jsx)(_atoms.Info, {})
    };
  }
  function isDateBetweenStartAndEnd() {
    var _announcement$startDa, _announcement$startDa2, _announcement$endDate, _announcement$endDate2;
    if (!announcement) return false;
    var [startDateDay, startDateMonth, startDateYear] = (_announcement$startDa = announcement === null || announcement === void 0 || (_announcement$startDa2 = announcement.startDate) === null || _announcement$startDa2 === void 0 || (_announcement$startDa2 = _announcement$startDa2.split('/')) === null || _announcement$startDa2 === void 0 ? void 0 : _announcement$startDa2.map(s => +s)) !== null && _announcement$startDa !== void 0 ? _announcement$startDa : [];
    var [endDateDay, endDateMonth, endDateYear] = (_announcement$endDate = announcement === null || announcement === void 0 || (_announcement$endDate2 = announcement.endDate) === null || _announcement$endDate2 === void 0 || (_announcement$endDate2 = _announcement$endDate2.split('/')) === null || _announcement$endDate2 === void 0 ? void 0 : _announcement$endDate2.map(s => +s)) !== null && _announcement$endDate !== void 0 ? _announcement$endDate : [];
    var currentTime = new Date().getTime();
    var startTime = new Date(startDateYear, startDateMonth - 1, startDateDay).getTime();
    var endTime = new Date(endDateYear, endDateMonth - 1, endDateDay).getTime();
    return startTime <= currentTime && currentTime <= endTime;
  }
}
var notificationStyles = {
  maxW: {
    base: 'full'
  },
  lineHeight: '2'
};