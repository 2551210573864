"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = RegisterProfile;
var _utils = require("@whitbread-eos/utils");
var _react = require("react");
function RegisterProfile(_ref) {
  var _formField$props3;
  var {
    formField,
    handleSetValue
  } = _ref;
  var [triggerSetValues, setTriggerSetValues] = (0, _react.useState)(0);
  (0, _react.useEffect)(() => {
    if (handleSetValue && triggerSetValues) {
      var _formField$props;
      var profileData = (formField === null || formField === void 0 || (_formField$props = formField.props) === null || _formField$props === void 0 || (_formField$props = _formField$props.bkngData) === null || _formField$props === void 0 || (_formField$props = _formField$props.bookingConfirmation) === null || _formField$props === void 0 || (_formField$props = _formField$props.reservationByIdList[0]) === null || _formField$props === void 0 ? void 0 : _formField$props.reservationBooker) || {};
      var {
        firstName,
        lastName,
        email,
        mobile,
        landline,
        title,
        address
      } = profileData;
      var {
        addressType,
        companyName,
        addressLine1,
        addressLine2,
        addressLine3,
        addressLine4,
        cityName,
        countryCode,
        postalCode
      } = address || {};
      if (profileData) {
        var _formField$props2;
        handleSetValue("title", title);
        handleSetValue("firstName", firstName);
        handleSetValue("lastName", lastName);
        handleSetValue("email", email);
        handleSetValue("phone", mobile || landline);
        handleSetValue("addressSelection", (formField === null || formField === void 0 || (_formField$props2 = formField.props) === null || _formField$props2 === void 0 ? void 0 : _formField$props2.currentLang) === _utils.GLOBALS.language.DE ? _utils.GLOBALS.addressType.HOME : addressType);
        handleSetValue("companyName", companyName);
        handleSetValue("addressLine1", addressLine1);
        handleSetValue("addressLine2", addressLine2);
        handleSetValue("addressLine3", addressLine3);
        countryCode === _utils.GLOBALS.localeUpper.DE ? handleSetValue("cityName", cityName) : handleSetValue("addressLine4", addressLine4);
        handleSetValue("postalCode", postalCode);
        handleSetValue("countryCode", countryCode);
      }
    }
  }, [handleSetValue, triggerSetValues, formField === null || formField === void 0 || (_formField$props3 = formField.props) === null || _formField$props3 === void 0 ? void 0 : _formField$props3.bkngData]);
  (0, _react.useEffect)(() => {
    setTriggerSetValues(prev => prev + 1);
  }, []);
  return null;
}