"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.AMEND_FIND_BOOKING = void 0;
var _taggedTemplateLiteral2 = _interopRequireDefault(require("@babel/runtime/helpers/taggedTemplateLiteral"));
var _graphqlRequest = require("graphql-request");
var _templateObject;
var AMEND_FIND_BOOKING = exports.AMEND_FIND_BOOKING = (0, _graphqlRequest.gql)(_templateObject || (_templateObject = (0, _taggedTemplateLiteral2.default)(["\n  query amendFindBooking(\n    $arrivalDate: String!\n    $country: String\n    $language: String\n    $lastName: String!\n    $resNo: String!\n    $bookingChannel: BookingChannelCriteria\n  ) {\n    findBooking(\n      findBookingCriteria: {\n        arrivalDate: $arrivalDate\n        country: $country\n        language: $language\n        lastName: $lastName\n        resNo: $resNo\n        bookingChannel: $bookingChannel\n      }\n    ) {\n      cookieName\n      redirectBase\n      ref\n      sourcePms\n      token\n      minutesTillExpiry\n      basketReference\n      operaConfNumber\n    }\n  }\n"])));