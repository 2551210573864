"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = TotalCost;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _react = require("@chakra-ui/react");
var _react2 = _interopRequireDefault(require("react"));
var _formatters = require("../../utils/formatters");
var _jsxRuntime = require("react/jsx-runtime");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
var paymentOptions = {
  PAY_NOW: 'PAY_NOW',
  PAY_ON_ARRIVAL: 'PAY_ON_ARRIVAL',
  RESERVE_WITHOUT_CARD: 'RESERVE_WITHOUT_CARD',
  ACCOUNT_COMPANY: 'ACCOUNT_COMPANY'
};
function TotalCost(_ref) {
  var {
    totalCostAmount,
    currency,
    language,
    donation,
    selectedPaymentOption,
    t,
    isCCUI,
    taxesMessage
  } = _ref;
  var baseDataTestId = 'TotalCostConfirm';
  return (0, _jsxRuntime.jsx)(_react.Box, _objectSpread(_objectSpread({
    "data-testid": (0, _formatters.formatDataTestId)(baseDataTestId, 'container')
  }, totalCostWrapperStyle), {}, {
    sx: {
      '@media print': {
        display: 'none'
      }
    },
    children: (0, _jsxRuntime.jsxs)(_react.Box, {
      p: "lg",
      children: [donation && (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
        children: [(0, _jsxRuntime.jsxs)(_react.Flex, _objectSpread(_objectSpread({}, totalCostDonationContainerStyle), {}, {
          children: [(0, _jsxRuntime.jsx)(_react.Text, _objectSpread(_objectSpread({}, donationTextStyle), {}, {
            "data-testid": (0, _formatters.formatDataTestId)(baseDataTestId, 'donationLabel'),
            children: t('booking.confirmation.donationMessage')
          })), (0, _jsxRuntime.jsx)(_react.Text, _objectSpread(_objectSpread({}, donationAmountStyle), {}, {
            "data-testid": (0, _formatters.formatDataTestId)(baseDataTestId, 'donationAmount'),
            children: (0, _formatters.formatPrice)((0, _formatters.formatCurrency)(currency), donation.computedPrice.toFixed(2), language)
          }))]
        })), (0, _jsxRuntime.jsx)(_react.Divider, _objectSpread({}, donationDividerStyle))]
      }), (0, _jsxRuntime.jsxs)(_react.Flex, _objectSpread(_objectSpread({}, totalCostContainerStyle), {}, {
        children: [(0, _jsxRuntime.jsxs)(_react.Flex, _objectSpread(_objectSpread({}, totalCostLabelStyle), {}, {
          direction: "column",
          children: [(0, _jsxRuntime.jsx)(_react.Text, {
            as: "b",
            "data-testid": (0, _formatters.formatDataTestId)(baseDataTestId, 'label'),
            children: "".concat(t('booking.confirmation.totalCost'), ":")
          }), (0, _jsxRuntime.jsx)(_react.Text, {
            "data-testid": (0, _formatters.formatDataTestId)(baseDataTestId, 'paymentMessage'),
            children: t(renderPaymentConfirmationText(selectedPaymentOption, isCCUI))
          })]
        })), (0, _jsxRuntime.jsx)(_react.Flex, _objectSpread(_objectSpread({}, totalCostAmountStyle), {}, {
          children: (0, _jsxRuntime.jsx)(_react.Text, {
            "data-testid": (0, _formatters.formatDataTestId)(baseDataTestId, 'amount'),
            children: (0, _formatters.formatPrice)((0, _formatters.formatCurrency)(currency), totalCostAmount === null || totalCostAmount === void 0 ? void 0 : totalCostAmount.toFixed(2), language)
          })
        }))]
      })), !!(taxesMessage !== null && taxesMessage !== void 0 && taxesMessage.length) && (0, _jsxRuntime.jsx)(_react.Flex, _objectSpread(_objectSpread({
        "data-testid": (0, _formatters.formatDataTestId)(baseDataTestId, 'includeCityTax-wrapper')
      }, infoTextStyle), {}, {
        children: (0, _jsxRuntime.jsx)(_react.Text, {
          "data-testid": (0, _formatters.formatDataTestId)(baseDataTestId, 'TaxesMessage'),
          children: taxesMessage
        })
      }))]
    })
  }));
  function renderPaymentConfirmationText(paymentOption) {
    var isCCUI = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
    switch (paymentOption) {
      case paymentOptions.PAY_NOW:
        return isCCUI ? 'ccui.booking.confirmation.paymentTaken' : 'booking.confirmation.paymentTaken';
      case paymentOptions.PAY_ON_ARRIVAL:
        return isCCUI ? 'ccui.booking.confirmation.reservedWithCard' : 'booking.confirmation.reservedWithCard';
      case paymentOptions.RESERVE_WITHOUT_CARD:
        return isCCUI ? 'ccui.booking.confirmation.reservedWithoutCard' : 'booking.confirmation.reservedWithoutCard';
      case paymentOptions.ACCOUNT_COMPANY:
        return 'ccui.booking.confirmation.accountToCompany';
      default:
        return paymentOption;
    }
  }
}
var totalCostWrapperStyle = {
  mb: '2xl',
  color: 'darkGrey1',
  backgroundColor: 'lightGrey5'
};
var totalCostDonationContainerStyle = {
  w: 'full',
  justifyContent: 'space-between',
  lineHeight: 'var(--chakra-lineHeights-3)'
};
var donationTextStyle = {
  as: 'b',
  fontSize: 'md',
  flex: {
    mobile: 1,
    md: 'unset'
  }
};
var donationAmountStyle = {
  as: 'b',
  fontSize: 'xl',
  flex: {
    mobile: 1,
    md: 'unset'
  },
  display: {
    mobile: 'flex',
    md: 'inherit'
  },
  justifyContent: {
    mobile: 'flex-end',
    md: 'unset'
  },
  alignItems: {
    mobile: 'flex-end',
    md: 'center'
  }
};
var donationDividerStyle = {
  my: 'md',
  border: '1px solid var(--chakra-colors-lightGrey1)'
};
var totalCostContainerStyle = {
  w: 'full',
  justifyContent: 'space-between',
  lineHeight: 'var(--chakra-lineHeights-3)'
};
var totalCostLabelStyle = {
  w: 'full',
  lineHeight: 'var(--chakra-lineHeights-3)',
  flex: {
    mobile: 1,
    md: 'unset'
  },
  maxW: {
    mobile: '7.5rem',
    sm: 'none',
    md: 'none'
  }
};
var totalCostAmountStyle = {
  as: 'b',
  fontSize: '3xxl',
  lineHeight: 'var(--chakra-lineHeights-5)',
  flex: {
    mobile: 1,
    md: 'unset'
  },
  justifyContent: {
    mobile: 'flex-end',
    md: 'inherit'
  },
  alignItems: {
    mobile: 'flex-end',
    md: 'center'
  }
};
var infoTextStyle = {
  justifyContent: 'flex-end',
  fontWeight: 'medium',
  lineHeight: '2',
  color: 'darkGrey1',
  fontSize: 'sm'
};