"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = BookingSummaryRateInformation;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _react = require("@chakra-ui/react");
var _api = require("@whitbread-eos/api");
var _utils = require("@whitbread-eos/utils");
var _jsxRuntime = require("react/jsx-runtime");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function BookingSummaryRateInformation(_ref) {
  var {
    rate,
    noRooms,
    noNights,
    t,
    prefixDataTestId,
    brand
  } = _ref;
  var baseDataTestId = (0, _utils.formatDataTestId)(prefixDataTestId, 'RateInformation');
  var roomLabel = noRooms === 1 ? t('hoteldetails.bookingsummary.room') : t('hoteldetails.bookingsummary.rooms');
  var nightLabel = noNights === 1 ? t('booking.summary.night') : t('booking.summary.nights');
  return (0, _jsxRuntime.jsxs)(_react.Flex, _objectSpread(_objectSpread({}, rateContainerStyle), {}, {
    "data-testid": (0, _utils.formatDataTestId)(baseDataTestId, 'Wrapper'),
    children: [(0, _jsxRuntime.jsxs)(_react.Text, _objectSpread(_objectSpread({}, rateLabelStyle), {}, {
      fontWeight: "semibold",
      "data-testid": (0, _utils.formatDataTestId)(baseDataTestId, 'Label'),
      children: [t('booking.summary.tarif'), ": ", "".concat(brand === _api.HotelBrand.HUB ? 'hub' : '', " ").concat(rate)]
    })), (0, _jsxRuntime.jsx)(_react.Text, _objectSpread(_objectSpread({}, ratePeriodStyle), {}, {
      "data-testid": (0, _utils.formatDataTestId)(baseDataTestId, 'StayInfo'),
      children: "".concat(t('booking.header.summary.basedon'), " ").concat(noRooms, " ").concat(roomLabel, " ").concat(t('booking.header.summary.for'), " ").concat(noNights, " ").concat(nightLabel)
    }))]
  }));
}
var rateLabelStyle = {
  lineHeight: 3,
  color: 'darkGrey1',
  fontSize: 'md',
  fontWeight: 'semibold'
};
var ratePeriodStyle = {
  lineHeight: 2,
  color: 'darkGrey1',
  fontWeight: 'normal',
  fontSize: 'sm'
};
var rateContainerStyle = {
  pr: {
    md: 'md',
    lg: '0'
  },
  flexDirection: 'column'
};