"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = ConfirmationPromotion;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _react = require("@chakra-ui/react");
var _utils = require("@whitbread-eos/utils");
var _react2 = _interopRequireDefault(require("react"));
var _jsxRuntime = require("react/jsx-runtime");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function ConfirmationPromotion(_ref) {
  var {
    data,
    routerPush
  } = _ref;
  if (!data.promotionPanel || data.promotionPanel.length === 0) {
    return null;
  }
  var {
    image,
    name,
    description,
    linkLabel,
    linkPath
  } = data.promotionPanel[0];
  return (0, _jsxRuntime.jsxs)(_react.Flex, _objectSpread(_objectSpread({}, promoWrapperStyle), {}, {
    children: [image && (0, _jsxRuntime.jsx)(_react.Box, _objectSpread(_objectSpread({}, imageWrapperStyle), {}, {
      "data-testid": 'promo-image',
      children: (0, _jsxRuntime.jsx)(_react.Image, {
        src: (0, _utils.formatAssetsUrl)(image),
        alt: "Premier Inn Promo-Image",
        fit: "fill",
        objectFit: "cover"
      })
    })), (0, _jsxRuntime.jsx)(_react.Box, {
      p: "lg",
      children: (0, _jsxRuntime.jsxs)(_react.Flex, {
        direction: "column",
        children: [(0, _jsxRuntime.jsx)(_react.Text, _objectSpread(_objectSpread({}, promoTitleStyle), {}, {
          "data-testid": 'promo-title',
          children: name
        })), (0, _jsxRuntime.jsx)(_react.Text, _objectSpread(_objectSpread({}, promoDescriptionStyle), {}, {
          "data-testid": 'promo-description',
          children: (0, _utils.renderSanitizedHtml)(description)
        })), (0, _jsxRuntime.jsx)(_react.Button, _objectSpread(_objectSpread({}, promoButtonStyle), {}, {
          "data-testid": 'promo-link',
          onClick: () => {
            routerPush(linkPath);
          },
          children: linkLabel
        }))]
      })
    })]
  }));
}
var promoWrapperStyle = {
  direction: 'column',
  w: 'full',
  bgColor: 'lightPurple'
};
var imageWrapperStyle = {
  pos: 'relative',
  w: '100%',
  h: '14.75rem'
};
var promoTitleStyle = {
  fontSize: 'md',
  color: 'baseWhite',
  fontWeight: 'medium'
};
var promoDescriptionStyle = {
  fontSize: 'sm',
  color: 'baseWhite',
  fontWeight: 'normal'
};
var promoButtonStyle = {
  size: 'full',
  variant: 'tertiary',
  mt: 'lg'
};