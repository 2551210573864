"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = BookingHistoryFilter;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _react = require("@chakra-ui/react");
var _atoms = require("@whitbread-eos/atoms");
var _utils = require("@whitbread-eos/utils");
var _add = _interopRequireDefault(require("date-fns/add"));
var _format = _interopRequireDefault(require("date-fns/format"));
var _sub = _interopRequireDefault(require("date-fns/sub"));
var _react2 = require("react");
var _constants = require("../../../utils/constants");
var _BookingHistoryFilter = require("./BookingHistoryFilter.style");
var _jsxRuntime = require("react/jsx-runtime");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
var FILTER_OPTIONS = function (FILTER_OPTIONS) {
  FILTER_OPTIONS["ARRIVAL"] = "ARRIVAL_DATE";
  FILTER_OPTIONS["SURNAME"] = "NAME";
  FILTER_OPTIONS["BOOKING_REFERENCE"] = "CONFIRM_NUMBER";
  return FILTER_OPTIONS;
}(FILTER_OPTIONS || {});
var CHARS_LIMIT = 30;
function BookingHistoryFilter(_ref) {
  var {
    t,
    onClear,
    onFind,
    baseTestId,
    screenSize = {
      isLessThanMobile: false,
      isLessThanXs: false,
      isLessThanSm: false,
      isLessThanMd: false,
      isLessThanLg: false,
      isLessThanXl: false
    }
  } = _ref;
  var maxDate = (0, _add.default)(new Date(), {
    days: 365
  });
  var minDate = (0, _sub.default)(new Date(), {
    days: 90
  });
  var {
    isLessThanLg
  } = screenSize;
  var [date, setDate] = (0, _react2.useState)(null);
  var [inputValue, setInputValue] = (0, _react2.useState)('');
  var [inputError, setInputError] = (0, _react2.useState)('');
  var [searchWasMade, setSearchWasMade] = (0, _react2.useState)(false);
  var isFindEnabled = inputValue && inputValue.length > 0 || date !== null;
  var isDatepickerEnabled = inputValue.length <= 0;
  var isBookingRefInputEnabled = date === null;
  var {
    language
  } = (0, _utils.useCustomLocale)();
  var onInputChange = value => {
    if (inputError) {
      setInputError('');
    }
    setInputValue(value);
  };
  var triggerFind = () => {
    var trimmedInputValue = inputValue.trim();
    if (date !== null) {
      onFind({
        filterValue: (0, _format.default)(date, 'yyyy-MM-dd'),
        filterType: FILTER_OPTIONS.ARRIVAL
      });
    } else if ((0, _utils.isAlphabetic)(trimmedInputValue)) {
      onFind({
        filterValue: trimmedInputValue,
        filterType: FILTER_OPTIONS.SURNAME
      });
    } else {
      onFind({
        filterValue: trimmedInputValue,
        filterType: FILTER_OPTIONS.BOOKING_REFERENCE
      });
    }
  };
  var handleFind = () => {
    var trimmedInputvalue = inputValue.trim();
    if (inputValue && trimmedInputvalue.length > CHARS_LIMIT) {
      setInputError(t('dashboard.bookings.maximumCharactersPermitted'));
    } else if (inputValue && (0, _utils.isNumber)(trimmedInputvalue)) {
      setInputError(t('dashboard.bookings.invalidBookingReference'));
    } else if (inputValue && !(0, _utils.hasValidCharacters)(trimmedInputvalue)) {
      setInputError(t('dashboard.bookings.invalidCharacters'));
    } else {
      setSearchWasMade(true);
      triggerFind();
    }
  };
  var handleClear = () => {
    if (searchWasMade) {
      onClear();
    }
    setInputValue('');
    setInputError('');
    setDate(null);
  };
  var containerWrapperStyles = !isLessThanLg && inputError ? _objectSpread(_objectSpread({}, _BookingHistoryFilter.containerStyles), _BookingHistoryFilter.containerWithErrorStyles) : _objectSpread({}, _BookingHistoryFilter.containerStyles);
  var searchInputWrapperStyles = isLessThanLg && inputError ? _objectSpread(_objectSpread({}, _BookingHistoryFilter.searchInputStyles), _BookingHistoryFilter.inputWithErrorStyles) : _objectSpread({}, _BookingHistoryFilter.searchInputStyles);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_react.Text, _objectSpread(_objectSpread({
      as: "h6"
    }, _BookingHistoryFilter.paragraphStyles), {}, {
      "data-testid": "filters-description",
      children: t('dashboard.bookings.searchBarText')
    })), (0, _jsxRuntime.jsxs)(_react.Flex, _objectSpread(_objectSpread({
      flexDirection: 'row',
      flexWrap: 'wrap'
    }, containerWrapperStyles), {}, {
      children: [(0, _jsxRuntime.jsx)(_react.Box, _objectSpread(_objectSpread({}, searchInputWrapperStyles), {}, {
        children: (0, _jsxRuntime.jsx)(_atoms.Input, {
          type: "text",
          name: "bookingFilter",
          onChange: onInputChange,
          placeholderText: t('dashboard.bookings.bookingQuery'),
          label: t('dashboard.bookings.bookingQuery'),
          error: inputError,
          value: inputValue,
          isDisabled: !isBookingRefInputEnabled,
          "data-testid": (0, _utils.formatDataTestId)(baseTestId, 'bookingReferenceOrSurname'),
          styles: {
            inputElementStyles: _BookingHistoryFilter.inputElementStyles
          },
          isInputAriaRequired: true
        })
      })), (0, _jsxRuntime.jsx)(_react.Box, _objectSpread(_objectSpread({}, _BookingHistoryFilter.datepickerInputStyle), {}, {
        children: (0, _jsxRuntime.jsx)(_atoms.SingleDatePicker, {
          minDate: minDate,
          maxDate: maxDate,
          inputPlaceholder: t('dashboard.bookings.filterDatePlaceholder'),
          name: 'bookingHistoryDatepicker',
          inputLabel: '',
          datepickerStyles: {
            inputGroupStyles: {},
            datepickerInputElementStyles: _BookingHistoryFilter.inputStyles,
            bookingDatepickerSize: {},
            iconStyles: _BookingHistoryFilter.inputIconStyles
          },
          defaultStartDate: date !== null && date !== void 0 ? date : null,
          displayDateFormat: _constants.DATE_FORMAT,
          dateFormat: _constants.DATE_FORMAT,
          onSelectDate: date => {
            setDate(date);
          },
          labels: {
            todayLabel: '',
            tomorrowLabel: ''
          },
          skipFormatRules: true,
          locale: language,
          "data-testid": (0, _utils.formatDataTestId)(baseTestId, 'datePicker'),
          isDisabled: !isDatepickerEnabled
        })
      })), (0, _jsxRuntime.jsxs)(_react.Box, _objectSpread(_objectSpread({}, _BookingHistoryFilter.buttonsWrapperStyles), {}, {
        children: [(0, _jsxRuntime.jsx)(_atoms.Button, _objectSpread(_objectSpread({
          variant: "primary",
          size: "md",
          onClick: handleFind,
          isDisabled: !isFindEnabled,
          "data-testid": (0, _utils.formatDataTestId)(baseTestId, 'findButton')
        }, (0, _BookingHistoryFilter.setFindButtonStyles)(language)), {}, {
          children: t('dashboard.bookings.findButton')
        })), (0, _jsxRuntime.jsx)(_react.Box, _objectSpread(_objectSpread({
          title: "clear-search-button"
        }, _BookingHistoryFilter.clearButton), {}, {
          children: (0, _jsxRuntime.jsx)(_react.Text, _objectSpread(_objectSpread({
            as: "span"
          }, _BookingHistoryFilter.clearTextStyle), {}, {
            onClick: () => handleClear(),
            "data-testid": (0, _utils.formatDataTestId)(baseTestId, 'clearButton'),
            children: t('dashboard.bookings.clearSearchButton')
          }))
        }))]
      }))]
    }))]
  });
}