"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = validateForm;
var _atoms = require("@whitbread-eos/atoms");
var yup = _interopRequireWildcard(require("yup"));
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && {}.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
function validateForm(labels) {
  var {
    titleError,
    firstNameRequiredError,
    firstNameMinError,
    lastNameRequiredError,
    firstNameInvalidError,
    lastNameInvalidError,
    emailInvalidError
  } = labels;
  var formValidationSchema = yup.object().shape({
    title: yup.string().required(titleError),
    firstName: yup.string().transform(value => value.replace(/^(\s+|\s)+$/gm, '')).required(firstNameRequiredError).matches(_atoms.FORM_VALIDATIONS.FIRST_NAME.MATCHES, firstNameInvalidError).min(_atoms.FORM_VALIDATIONS.FIRST_NAME.MIN, firstNameMinError).max(_atoms.FORM_VALIDATIONS.FIRST_NAME.MAX, firstNameRequiredError),
    lastName: yup.string().transform(value => value.replace(/^(\s+|\s)+$/gm, '')).required(lastNameRequiredError).matches(_atoms.FORM_VALIDATIONS.LAST_NAME_AMEND_BOOKING_GUEST_DETAILS.MATCHES, lastNameInvalidError).min(_atoms.FORM_VALIDATIONS.LAST_NAME_AMEND_BOOKING_GUEST_DETAILS.MIN, lastNameInvalidError).max(_atoms.FORM_VALIDATIONS.LAST_NAME_AMEND_BOOKING_GUEST_DETAILS.MAX, lastNameRequiredError),
    emailAddress: yup.string().nullable().transform((v, o) => o === '' ? null : v === null || v === void 0 ? void 0 : v.replace(/^(\s+|\s)+$/gm, '')).matches(_atoms.FORM_VALIDATIONS.EMAIL_GD.MATCHES, emailInvalidError)
  });
  return formValidationSchema;
}