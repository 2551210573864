"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.checkDateFormatter = checkDateFormatter;
exports.createReservationDetails = createReservationDetails;
exports.filterAncillaryCloseOutData = filterAncillaryCloseOutData;
exports.formatAccompanyingGuest = formatAccompanyingGuest;
exports.formatAssetsUrl = formatAssetsUrl;
exports.formatBillingAddress = formatBillingAddress;
exports.formatCurrency = formatCurrency;
exports.formatDataTestId = formatDataTestId;
exports.formatDate = formatDate;
exports.formatFindBookingToken = formatFindBookingToken;
exports.formatForMobile = formatForMobile;
exports.formatGuestTitleOptions = formatGuestTitleOptions;
exports.formatGuests = formatGuests;
exports.formatInnerHTMLAssetUrls = formatInnerHTMLAssetUrls;
exports.formatNextLocaleLink = formatNextLocaleLink;
exports.formatPrice = formatPrice;
exports.formatPriceWithDecimal = formatPriceWithDecimal;
exports.formatRequiredString = formatRequiredString;
exports.formatTextWithSpace = formatTextWithSpace;
exports.formatUrlTermsConditions = formatUrlTermsConditions;
Object.defineProperty(exports, "getPathForLocale", {
  enumerable: true,
  get: function get() {
    return _getPathForLocale.getPathForLocale;
  }
});
exports.getPriceValueWithDecimal = getPriceValueWithDecimal;
exports.getYearsAgoFromNow = void 0;
exports.langFilter = langFilter;
exports.matchSilentSubstitutions = matchSilentSubstitutions;
exports.removeHtmlTags = removeHtmlTags;
exports.renderSanitizedHtml = renderSanitizedHtml;
exports.replaceWithEmptyString = replaceWithEmptyString;
exports.superRoleFilter = superRoleFilter;
exports.swapKeysAndValues = swapKeysAndValues;
exports.transformLabels = transformLabels;
exports.upperFirst = upperFirst;
exports.upperOnlyFirst = upperOnlyFirst;
exports.uppercaseAndReplace = uppercaseAndReplace;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _api = require("@whitbread-eos/api");
var _dateFns = require("date-fns");
var _format = _interopRequireDefault(require("date-fns/format"));
var _de = _interopRequireDefault(require("date-fns/locale/de"));
var _enGB = _interopRequireDefault(require("date-fns/locale/en-GB"));
var _parse = _interopRequireDefault(require("date-fns/parse"));
var _parseISO = _interopRequireDefault(require("date-fns/parseISO"));
var _htmlReactParser = _interopRequireDefault(require("html-react-parser"));
var _isomorphicDompurify = _interopRequireDefault(require("isomorphic-dompurify"));
var _config = _interopRequireDefault(require("next/config"));
var _validators = require("../validators");
var _getPathForLocale = require("./getPathForLocale");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function formatCurrency(currencyCode) {
  switch (currencyCode) {
    case 'GBP':
      return _api.Currency.GBP;
    case 'EUR':
      return _api.Currency.EUR;
    default:
      return '';
  }
}
function formatPrice(currency, price) {
  var language = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : _api.LanguageEnum.ENGLISH;
  if (language === _api.LanguageEnum.GERMAN && currency === _api.Currency.EUR) {
    return "".concat(price === null || price === void 0 ? void 0 : price.toString().replace('.', ',')).concat(currency);
  }
  return "".concat(currency).concat(price);
}
function formatPriceWithDecimal(language, currency, price, decimals) {
  var priceWithDecimal = price % 1 === 0 ? price : price.toFixed(2);
  if (decimals) priceWithDecimal = price.toFixed(2);
  if (currency === _api.Currency.GBP || language === _api.LanguageEnum.ENGLISH) {
    return "".concat(currency).concat(priceWithDecimal);
  }
  var updatedPrice = priceWithDecimal.toString().replace('.', ',');
  return "".concat(updatedPrice, " ").concat(currency);
}
function getPriceValueWithDecimal(price) {
  var _price$toString;
  var decimal = price === null || price === void 0 || (_price$toString = price.toString) === null || _price$toString === void 0 ? void 0 : _price$toString.call(price).replace(/[^0-9.]+/g, '');
  if (!price || isNaN(Number(decimal)) || !decimal) {
    return '0.0';
  }
  return Number(decimal).toFixed(1).toString();
}
function formatDataTestId() {
  var prefix = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
  var dataTestId = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '';
  var previousString = (0, _validators.isStringValid)(prefix) ? "".concat(prefix, "-") : '';
  var testId = (0, _validators.isStringValid)(dataTestId) ? dataTestId : '';
  return "".concat(previousString).concat(testId);
}
function formatAssetsUrl(path) {
  var useBasicAuth = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
  var {
    publicRuntimeConfig = {}
  } = (0, _config.default)() || {};
  if (!path) return '';
  if (useBasicAuth) {
    return "".concat(publicRuntimeConfig.NEXT_PUBLIC_ASSETS_URL).concat(path === null || path === void 0 ? void 0 : path.replace(publicRuntimeConfig.NEXT_PUBLIC_ASSETS_URL, ''));
  }
  return "".concat(publicRuntimeConfig.NEXT_PUBLIC_ASSETS_URL_WITHOUT_BASIC).concat(path === null || path === void 0 ? void 0 : path.replace(publicRuntimeConfig.NEXT_PUBLIC_ASSETS_URL_WITHOUT_BASIC, ''));
}
function formatInnerHTMLAssetUrls(innerHTML) {
  if (!innerHTML) {
    return '';
  }
  if (!innerHTML.includes('href="/')) {
    return innerHTML;
  }
  var hrefAttributePattern = /href="\/[^"]+?\.\w+?"/g;
  return innerHTML.replace(hrefAttributePattern, hrefAttribute => "href=\"".concat(formatAssetsUrl(hrefAttribute.substring(6, hrefAttribute.length - 1)), "\""));
}
function formatDate(date, formatDate, language) {
  var isoDate = date.includes('-') ? (0, _parseISO.default)(date) : (0, _parseISO.default)((0, _format.default)(new Date(date), _api.DATE_TYPE.YEAR_MONTH_DAY));
  return (0, _format.default)(isoDate, formatDate, {
    locale: language === _api.LanguageEnum.GERMAN ? _de.default : _enGB.default
  });
}
function checkDateFormatter(time, date, language) {
  if (language === _api.LanguageEnum.ENGLISH) {
    return (0, _format.default)((0, _parseISO.default)("".concat(date, "T").concat(time)), 'ha - EEE d LLL yyyy', {
      locale: _enGB.default
    }).replace('AM', 'am').replace('PM', 'pm');
  }
  return (0, _format.default)((0, _parseISO.default)("".concat(date, "T").concat(time)), 'H:mm - EEE d LLL yyyy', {
    locale: _de.default
  });
}
function formatNextLocaleLink(pathName, nextLocale, currentLocale, area) {
  var _pathName, _pathName2, _pathName3, _linkWithNextLocale$r;
  var homepage = "".concat(nextLocale === _api.LanguageEnum.ENGLISH ? '/gb/en/' : '/de/de/').concat(area === _api.Area.PI ? 'home.html' : '').concat(area === _api.Area.BB ? 'business-booker/home.html' : '');
  var nextLocaleLink = nextLocale === _api.LanguageEnum.ENGLISH ? '/gb' : '/de';
  pathName = nextLocale === _api.LanguageEnum.GERMAN ? (_pathName = pathName) === null || _pathName === void 0 ? void 0 : _pathName.replace('germany', 'deutschland') : (_pathName2 = pathName) === null || _pathName2 === void 0 ? void 0 : _pathName2.replace('deutschland', 'germany');
  var linkWithNextLocale = !((_pathName3 = pathName) !== null && _pathName3 !== void 0 && _pathName3.length) ? '/gb/en' : "".concat(nextLocaleLink).concat(pathName);
  if (currentLocale === nextLocale) {
    return linkWithNextLocale;
  }
  var pathPreservePages = new RegExp('home|search|pre-check-in');
  if (!pathName || !pathPreservePages.test(pathName)) {
    return homepage;
  }
  var linkWithNextCountry = linkWithNextLocale === null || linkWithNextLocale === void 0 || (_linkWithNextLocale$r = linkWithNextLocale.replace('/gb/de', '/gb/en')) === null || _linkWithNextLocale$r === void 0 ? void 0 : _linkWithNextLocale$r.replace('/de/en', '/de/de');
  return linkWithNextCountry;
}
function formatFindBookingToken(token) {
  if ((0, _validators.isStringValid)(token)) {
    return encodeURIComponent(token);
  }
  return token;
}
function formatUrlTermsConditions(text) {
  if (!text) return '';
  var regex = RegExp(/\/(.*?)\.html/i);
  var fileName = regex.exec(text);
  if (!fileName) return '';
  var link = fileName[0];
  link = formatAssetsUrl(link);
  return text.replace(/\/(.*?)\.html/i, link);
}
function formatGuestTitleOptions() {
  var title = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
  var words = String(title).replace(/'/g, '').split(',');
  return words.map(option => {
    return {
      id: option,
      label: option
    };
  });
}
function formatTextWithSpace(text) {
  return text.split(' ').join('-');
}
function replaceWithEmptyString(receivedString) {
  var _receivedString$repla;
  return (_receivedString$repla = receivedString === null || receivedString === void 0 ? void 0 : receivedString.replace(/[^\w]/g, '')) !== null && _receivedString$repla !== void 0 ? _receivedString$repla : '';
}
function uppercaseAndReplace(val, regex, replaceStr) {
  return val.toUpperCase().replace(regex, replaceStr);
}
function upperFirst(value) {
  return "".concat(value.charAt(0).toUpperCase()).concat(value.slice(1));
}
function upperOnlyFirst(value) {
  return "".concat(value.charAt(0).toUpperCase()).concat(value.slice(1).toLowerCase());
}
function swapKeysAndValues(obj) {
  var swapped = Object.entries(obj).map(_ref => {
    var [key, value] = _ref;
    return [value, key];
  });
  return Object.fromEntries(swapped);
}
function formatForMobile(list) {
  var firstLabels = [];
  var lastLabels = [];
  list.forEach(label => {
    if (label.id === _api.BB_MENU_IDS.COMPANY) {
      var _label$subNav$0$navOp;
      firstLabels.push({
        navTitle: label.navTitle,
        subNav: [],
        id: _api.BB_MENU_IDS.COMPANY_NAME
      });
      label.subNav && ((_label$subNav$0$navOp = label.subNav[0].navOptions) === null || _label$subNav$0$navOp === void 0 ? void 0 : _label$subNav$0$navOp.forEach(subLabel => {
        firstLabels.push(_objectSpread({
          navTitle: subLabel.title,
          subNav: [{
            navOptions: subLabel.subMenuLinks || [],
            title: subLabel.title
          }],
          id: _api.BB_MENU_IDS.COMPANY
        }, subLabel.url && {
          url: subLabel.url
        }));
      }));
    } else {
      lastLabels.push(label);
    }
  });
  return [...firstLabels, ...lastLabels];
}
function superRoleFilter(role, list) {
  if (role !== _api.BUSINESS_BOOKER_USER_ROLES.SUPER) {
    return list === null || list === void 0 ? void 0 : list.map(item => {
      var _item$navOptions;
      var filtered = item === null || item === void 0 || (_item$navOptions = item.navOptions) === null || _item$navOptions === void 0 ? void 0 : _item$navOptions.filter(label => {
        return !label.superRole;
      });
      return _objectSpread(_objectSpread({}, item), {}, {
        navOptions: filtered
      });
    });
  }
  return list;
}
function langFilter(language, list) {
  if (language === 'de') {
    return list === null || list === void 0 ? void 0 : list.filter(item => item.id !== _api.BB_MENU_IDS.BUSINESS);
  }
  return list;
}
function removeHtmlTags(inputString) {
  return inputString.replace(/<\/?[^>]+(>|$)/g, '');
}
function filterAncillaryCloseOutData(data) {
  var _data$items;
  if (!(data !== null && data !== void 0 && data.items)) {
    return [];
  }
  return data === null || data === void 0 || (_data$items = data.items) === null || _data$items === void 0 ? void 0 : _data$items.filter(item => {
    if (item !== null && item !== void 0 && item.startDate && item !== null && item !== void 0 && item.endDate) {
      return (0, _parse.default)(item.endDate, 'dd/MM/yyyy', new Date()) > (0, _parse.default)(item.startDate, 'dd/MM/yyyy', new Date());
    }
  });
}
function createReservationDetails(receivedArrivalDate, receivedDepartureDate, currencyCode, reservationByIdList, noNights) {
  var arrivalDate = (0, _validators.logicalOrOperator)(receivedArrivalDate, null);
  var departureDate = (0, _validators.logicalOrOperator)(receivedDepartureDate, null);
  var currency = currencyCode;
  var noRooms = reservationByIdList === null || reservationByIdList === void 0 ? void 0 : reservationByIdList.length;
  return {
    arrivalDate,
    departureDate,
    currency,
    noRooms,
    noNights
  };
}
function matchSilentSubstitutions(reservations, substitutions) {
  var matchedSubstitutions = [];
  var savedSubstitutions = [...substitutions];
  for (var room of reservations) {
    for (var i = 0; i < savedSubstitutions.length; i++) {
      var _savedSubstitutions$i, _room$roomStay, _savedSubstitutions$i2, _room$roomStay2, _savedSubstitutions$i3, _room$roomStay3, _savedSubstitutions$i4;
      var matched = ((_savedSubstitutions$i = savedSubstitutions[i]) === null || _savedSubstitutions$i === void 0 ? void 0 : _savedSubstitutions$i.filtered) !== true && (room === null || room === void 0 || (_room$roomStay = room.roomStay) === null || _room$roomStay === void 0 ? void 0 : _room$roomStay.adultsNumber) === ((_savedSubstitutions$i2 = savedSubstitutions[i]) === null || _savedSubstitutions$i2 === void 0 ? void 0 : _savedSubstitutions$i2.adults) && (room === null || room === void 0 || (_room$roomStay2 = room.roomStay) === null || _room$roomStay2 === void 0 ? void 0 : _room$roomStay2.childrenNumber) === ((_savedSubstitutions$i3 = savedSubstitutions[i]) === null || _savedSubstitutions$i3 === void 0 ? void 0 : _savedSubstitutions$i3.children) && (room === null || room === void 0 || (_room$roomStay3 = room.roomStay) === null || _room$roomStay3 === void 0 || (_room$roomStay3 = _room$roomStay3.roomExtraInfo) === null || _room$roomStay3 === void 0 ? void 0 : _room$roomStay3.roomType) === ((_savedSubstitutions$i4 = savedSubstitutions[i]) === null || _savedSubstitutions$i4 === void 0 ? void 0 : _savedSubstitutions$i4.pmsRoomType);
      if (matched) {
        var filteredSubstitution = _objectSpread(_objectSpread({}, savedSubstitutions[i]), {}, {
          filtered: true
        });
        matchedSubstitutions.push(filteredSubstitution);
        savedSubstitutions[i] = filteredSubstitution;
        break;
      }
    }
  }
  return matchedSubstitutions;
}
var getYearsAgoFromNow = year => (0, _dateFns.subYears)(new Date(), year);
exports.getYearsAgoFromNow = getYearsAgoFromNow;
function transformLabels(staticData) {
  var _staticData$labels, _staticData$labels2, _staticData$labels3, _staticData$labels4, _staticData$labels5, _staticData$labels6;
  var main = staticData !== null && staticData !== void 0 && (_staticData$labels = staticData.labels) !== null && _staticData$labels !== void 0 && _staticData$labels.main ? JSON.parse(staticData.labels.main) : {};
  var piBookings = staticData !== null && staticData !== void 0 && (_staticData$labels2 = staticData.labels) !== null && _staticData$labels2 !== void 0 && _staticData$labels2.piBookings ? JSON.parse(staticData.labels.piBookings) : {};
  var booking = staticData !== null && staticData !== void 0 && (_staticData$labels3 = staticData.labels) !== null && _staticData$labels3 !== void 0 && _staticData$labels3.booking ? JSON.parse(staticData.labels.booking) : {};
  var piPreCheckIn = staticData !== null && staticData !== void 0 && (_staticData$labels4 = staticData.labels) !== null && _staticData$labels4 !== void 0 && _staticData$labels4.piPreCheckIn ? JSON.parse(staticData.labels.piPreCheckIn) : {};
  var piGroupBooking = staticData !== null && staticData !== void 0 && (_staticData$labels5 = staticData.labels) !== null && _staticData$labels5 !== void 0 && _staticData$labels5.piGroupBooking ? JSON.parse(staticData.labels.piGroupBooking) : {};
  var extras = staticData !== null && staticData !== void 0 && (_staticData$labels6 = staticData.labels) !== null && _staticData$labels6 !== void 0 && _staticData$labels6.extras ? JSON.parse(staticData.labels.extras) : {};
  return _objectSpread(_objectSpread(_objectSpread(_objectSpread(_objectSpread(_objectSpread({}, main), piBookings), booking), piPreCheckIn), piGroupBooking), extras);
}
function renderSanitizedHtml(html) {
  var sanitizedHtml = _isomorphicDompurify.default.sanitize(html, {
    ADD_ATTR: ['target', 'style']
  });
  var formattedHtml = formatInnerHTMLAssetUrls(sanitizedHtml);
  return (0, _htmlReactParser.default)(formattedHtml);
}
function formatBillingAddress(address, companyName) {
  var _ref2, _address$line, _ref3, _address$line2, _ref4, _address$line3, _ref5, _address$line4, _ref6, _address$line5, _ref7, _address$postCode, _ref8, _address$addressType, _ref9, _address$countryCode;
  if (!address) {
    return null;
  }
  var addressObj = {
    addressLine1: (_ref2 = (_address$line = address === null || address === void 0 ? void 0 : address.line1) !== null && _address$line !== void 0 ? _address$line : address === null || address === void 0 ? void 0 : address.addressLine1) !== null && _ref2 !== void 0 ? _ref2 : '',
    addressLine2: (_ref3 = (_address$line2 = address === null || address === void 0 ? void 0 : address.line2) !== null && _address$line2 !== void 0 ? _address$line2 : address === null || address === void 0 ? void 0 : address.addressLine2) !== null && _ref3 !== void 0 ? _ref3 : '',
    addressLine3: (_ref4 = (_address$line3 = address === null || address === void 0 ? void 0 : address.line3) !== null && _address$line3 !== void 0 ? _address$line3 : address === null || address === void 0 ? void 0 : address.addressLine3) !== null && _ref4 !== void 0 ? _ref4 : '',
    addressLine4: (_ref5 = (_address$line4 = address === null || address === void 0 ? void 0 : address.line4) !== null && _address$line4 !== void 0 ? _address$line4 : address === null || address === void 0 ? void 0 : address.addressLine4) !== null && _ref5 !== void 0 ? _ref5 : '',
    cityName: (_ref6 = (_address$line5 = address === null || address === void 0 ? void 0 : address.line4) !== null && _address$line5 !== void 0 ? _address$line5 : address === null || address === void 0 ? void 0 : address.addressLine4) !== null && _ref6 !== void 0 ? _ref6 : '',
    postalCode: (_ref7 = (_address$postCode = address === null || address === void 0 ? void 0 : address.postCode) !== null && _address$postCode !== void 0 ? _address$postCode : address === null || address === void 0 ? void 0 : address.postalCode) !== null && _ref7 !== void 0 ? _ref7 : '',
    companyName: companyName !== null && companyName !== void 0 ? companyName : '',
    addressType: (_ref8 = (_address$addressType = address === null || address === void 0 ? void 0 : address.addressType) !== null && _address$addressType !== void 0 ? _address$addressType : address === null || address === void 0 ? void 0 : address.type) !== null && _ref8 !== void 0 ? _ref8 : 'BUSINESS'
  };
  return _objectSpread(_objectSpread({}, addressObj), {}, {
    country: (_ref9 = (_address$countryCode = address === null || address === void 0 ? void 0 : address.countryCode) !== null && _address$countryCode !== void 0 ? _address$countryCode : address === null || address === void 0 ? void 0 : address.country) !== null && _ref9 !== void 0 ? _ref9 : ''
  });
}
function formatRequiredString(value) {
  var _value$replace;
  return (_value$replace = value === null || value === void 0 ? void 0 : value.replace('*', '')) !== null && _value$replace !== void 0 ? _value$replace : '';
}
function formatGuestAddress(data) {
  var isSomeoneElseAndSingleRoomRedesign = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
  var leadGuest = data.bookingForSomeoneElse || isSomeoneElseAndSingleRoomRedesign ? data.leadGuest[0] : data;
  return {
    cityName: leadGuest === null || leadGuest === void 0 ? void 0 : leadGuest.addressLine4,
    countryCode: leadGuest === null || leadGuest === void 0 ? void 0 : leadGuest.countryCode,
    addressLine1: leadGuest === null || leadGuest === void 0 ? void 0 : leadGuest.addressLine1,
    addressLine2: leadGuest === null || leadGuest === void 0 ? void 0 : leadGuest.addressLine2,
    addressLine3: leadGuest === null || leadGuest === void 0 ? void 0 : leadGuest.addressLine3,
    addressLine4: leadGuest === null || leadGuest === void 0 ? void 0 : leadGuest.addressLine4,
    postalCode: leadGuest === null || leadGuest === void 0 ? void 0 : leadGuest.postcodeAddress
  };
}
function formatAccompanyingGuest(guest, data) {
  var {
    accompanyingfirstName = undefined,
    accompanyinglastName = undefined,
    accompanyingtitle = undefined
  } = data;
  return _objectSpread(_objectSpread({}, guest), {}, {
    accompanyingGuestDetails: {
      title: accompanyingtitle,
      firstName: accompanyingfirstName,
      lastName: accompanyinglastName
    }
  });
}
function formatLeadGuest(data, item) {
  var isAccompanyingGuestEnabled = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
  var guest = {
    sameAsBooker: !!item.stayInThisRoom,
    stayingGuestDetails: {
      title: item.stayInThisRoom ? data.title : item.title,
      firstName: item.stayInThisRoom ? data.firstName : item.firstName,
      lastName: item.stayInThisRoom ? data.lastName : item.lastName,
      address: {
        cityName: item.stayInThisRoom ? data.addressLine4 : item.addressLine4,
        countryCode: item.stayInThisRoom ? data.countryCode : item.countryCode,
        addressLine1: item.stayInThisRoom ? data.addressLine1 : item.addressLine1,
        addressLine2: item.stayInThisRoom ? data.addressLine2 : item.addressLine2,
        addressLine3: item.stayInThisRoom ? data.addressLine3 : item.addressLine3,
        addressLine4: item.stayInThisRoom ? data.addressLine4 : item.addressLine4,
        postalCode: item.stayInThisRoom ? data.postcodeAddress : item.postcodeAddress
      }
    }
  };
  if (isAccompanyingGuestEnabled && item) {
    guest = formatAccompanyingGuest(guest, item);
  }
  return guest;
}
function formatGuests(data) {
  var isSomeoneElseAndSingleRoomRedesign = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
  var isAccompanyingGuestEnabled = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
  var stayingGuests = [];
  if (data.leadGuest && data.leadGuest.length > 1) {
    data.leadGuest.map(item => {
      var guest = formatLeadGuest(data, item, isAccompanyingGuestEnabled);
      stayingGuests.push(guest);
    });
  } else {
    var _data$leadGuest;
    var guest = {
      sameAsBooker: data.bookingForSomeoneElse || isSomeoneElseAndSingleRoomRedesign ? false : true,
      stayingGuestDetails: {
        title: data.bookingForSomeoneElse || isSomeoneElseAndSingleRoomRedesign ? data.leadGuest[0].title : data.title,
        firstName: data.bookingForSomeoneElse || isSomeoneElseAndSingleRoomRedesign ? data.leadGuest[0].firstName : data.firstName,
        lastName: data.bookingForSomeoneElse || isSomeoneElseAndSingleRoomRedesign ? data.leadGuest[0].lastName : data.lastName,
        address: formatGuestAddress(data, isSomeoneElseAndSingleRoomRedesign)
      }
    };
    if (isAccompanyingGuestEnabled && data !== null && data !== void 0 && (_data$leadGuest = data.leadGuest) !== null && _data$leadGuest !== void 0 && _data$leadGuest[0]) {
      guest = formatAccompanyingGuest(guest, data.leadGuest[0]);
    }
    stayingGuests.push(guest);
  }
  return stayingGuests;
}