"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = HubZipNoticeQueryWrapper;
var _reactQuery = require("@tanstack/react-query");
var _HubZipNotice = _interopRequireDefault(require("./HubZipNotice.container"));
var _jsxRuntime = require("react/jsx-runtime");
function HubZipNoticeQueryWrapper(_ref) {
  var {
    queryClient,
    srcHubLogo
  } = _ref;
  return (0, _jsxRuntime.jsx)(_reactQuery.QueryClientProvider, {
    client: queryClient,
    children: (0, _jsxRuntime.jsx)(_HubZipNotice.default, {
      srcHubLogo: srcHubLogo
    })
  });
}