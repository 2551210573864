"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var Checkbox = {
  parts: ['control', 'icon'],
  baseStyle: {
    icon: {
      color: 'var(--chakra-colors-baseWhite)',
      _active: {
        color: 'var(--chakra-colors-baseWhite)'
      },
      _checked: {
        color: 'var(--chakra-colors-baseWhite)'
      }
    },
    control: {
      width: '1.25rem',
      minHeight: '1.25rem',
      _focus: {
        boxShadow: 'none'
      },
      border: '1px',
      borderColor: 'var(--chakra-colors-lightGrey1)',
      borderRadius: 'none',
      _disabled: {
        borderColor: 'var(--chakra-colors-lightGrey2)',
        bg: 'var(--chakra-colors-lightGrey4)'
      },
      _checked: {
        bg: 'primary',
        color: 'baseWhite'
      },
      _active: {
        bgColor: 'var(--chakra-colors-primary)',
        borderColor: 'var(--chakra-colors-primary)',
        borderRadius: '1px',
        _disabled: {
          borderColor: 'var(--chakra-colors-lightGrey2)',
          bg: 'var(--chakra-colors-lightGrey4)',
          borderRadius: '0'
        }
      }
    }
  }
};
var _default = exports.default = Checkbox;