"use strict";
'use client';

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Calendar = Calendar;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _objectWithoutProperties2 = _interopRequireDefault(require("@babel/runtime/helpers/objectWithoutProperties"));
var _server = require("@whitbread-eos/utils/server");
var React = _interopRequireWildcard(require("react"));
var _reactDayPicker = require("react-day-picker");
var _Button = require("../Button");
var _jsxRuntime = require("react/jsx-runtime");
var _excluded = ["className", "classNames", "showOutsideDays", "locale", "isCurrentMonth", "shouldHideNextMonthIcon", "isCheckoutDay365", "isInLastMonth"];
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && {}.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function Calendar(_ref) {
  var {
      className,
      classNames,
      showOutsideDays = false,
      locale,
      isCurrentMonth,
      shouldHideNextMonthIcon,
      isCheckoutDay365,
      isInLastMonth
    } = _ref,
    props = (0, _objectWithoutProperties2.default)(_ref, _excluded);
  return (0, _jsxRuntime.jsx)(_reactDayPicker.DayPicker, _objectSpread({
    locale: locale,
    showOutsideDays: showOutsideDays,
    className: (0, _server.cn)('pt-4 px-2 pb-6 mobile:flex mobile:justify-center', className),
    classNames: _objectSpread({
      months: 'flex flex-col sm:flex-row space-y-4 sm:space-x-4 sm:space-y-0',
      month: 'space-y-4',
      caption: 'flex justify-center pt-1 relative items-center',
      caption_label: 'leading-6 text-[1.125rem] font-semibold',
      nav: 'space-x-1 flex items-center',
      nav_button: (0, _server.cn)((0, _Button.buttonVariants)({
        variant: 'calendarButton'
      }), 'h-6 w-6 bg-transparent p-0 opacity-100  hover:!border-0'),
      nav_button_previous: isCurrentMonth || isInLastMonth ? 'hidden' : 'absolute left-1',
      nav_button_next: shouldHideNextMonthIcon ? 'hidden' : 'absolute right-1',
      nav_icon: 'w-[0.5rem] h-[0.813rem]',
      table: 'w-full border-collapse',
      head_row: 'flex gap-2',
      head_cell: 'text-muted-foreground font-medium text-sm h-10 w-10 flex justify-center items-center',
      row: 'flex w-full mt-2',
      cell: 'h-10 w-10 px-1 first:pl-0 last:pr-0 box-content [&:has([aria-selected].only-start)]:ib-transparent [&:has([aria-selected].day-range-start)]:ib-day-range-start [&:has([aria-selected].day-range-end)]:ib-day-range-end text-center text-base p-0 relative [&:has([aria-selected].day-range-start)]:rounded-l-full [&:has([aria-selected].day-range-end)]:rounded-r-full [&:has([aria-selected])]:bg-linearGradient [&:has(.in-hover-range)]:bg-linearGradient [&:has(.in-hover-range.only-start)]:bg-linearGradient [&:has(.in-hover-range.only-start)]:!rounded-r-none focus-within:relative z-20',
      day: (0, _server.cn)((0, _Button.buttonVariants)({
        variant: 'calendarButton',
        size: 'calendarButton'
      }), isCheckoutDay365 ? 'disabled:opacity-100' : 'disabled:opacity-50'),
      day_range_end: 'day-range-end hover:!bg-primaryColor hover:!text-primary-foreground',
      day_range_start: 'day-range-start hover:!bg-primaryColor hover:!text-primary-foreground',
      day_range_middle: 'aria-selected:text-darkGrey1 hover:!bg-white !bg-transparent',
      day_selected: 'bg-primaryColor text-primary-foreground',
      day_today: 'day-today',
      day_outside: 'day-outside',
      day_disabled: 'text-lightGrey2 disabled:opacity-100',
      day_hidden: 'invisible'
    }, classNames),
    "data-testid": 'IB-Date-Picker-Calendar'
  }, props));
}
Calendar.displayName = 'Calendar';