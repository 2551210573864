"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = AddMemo;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _react = require("@chakra-ui/react");
var _reactQuery = require("@tanstack/react-query");
var _api = require("@whitbread-eos/api");
var _atoms = require("@whitbread-eos/atoms");
var _utils = require("@whitbread-eos/utils");
var _nextI18next = require("next-i18next");
var _react2 = require("react");
var _jsxRuntime = require("react/jsx-runtime");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function AddMemo(_ref) {
  var {
    basketReference,
    onSave,
    user
  } = _ref;
  var queryClient = (0, _reactQuery.useQueryClient)();
  var [description, setDescription] = (0, _react2.useState)('');
  var {
    t
  } = (0, _nextI18next.useTranslation)();
  var {
    language
  } = (0, _utils.useCustomLocale)();
  var baseTestId = 'AddMemo';
  var handleOnChange = event => {
    var textAreaValue = event.target.value;
    setDescription(textAreaValue);
  };
  var {
    mutation,
    isLoading
  } = (0, _utils.useMutationRequest)(_api.CREATE_MEMO);
  var onSubmit = () => {
    if (!isLoading && description.trim().length) {
      var createdByLabel = t('ccui.agentMemo.createdBy', {
        name: user === null || user === void 0 ? void 0 : user.name
      });
      var concatenatedDescription = "".concat(createdByLabel, ": ").concat(description);
      var createMemoCriteria = {
        basketReference,
        description: concatenatedDescription,
        channel: _api.BOOKING_CHANNEL.CCUI,
        subchannel: _api.BOOKING_SUBCHANNEL.WEB,
        language
      };
      mutation.mutate(createMemoCriteria, {
        onSuccess: data => {
          queryClient.setQueriesData({
            queryKey: ['GetAgentMemos']
          }, data);
          onSave();
        }
      });
    }
  };
  return (0, _jsxRuntime.jsx)(_react.Box, {
    "data-testid": (0, _utils.formatDataTestId)(baseTestId, 'Container'),
    children: (0, _jsxRuntime.jsxs)(_atoms.Card, _objectSpread(_objectSpread({
      display: "grid"
    }, cardStyle), {}, {
      children: [(0, _jsxRuntime.jsx)(_atoms.Textarea, _objectSpread({
        "data-testid": (0, _utils.formatDataTestId)(baseTestId, 'Description'),
        placeholder: t('ccui.agentMemo.enterTextHere'),
        _placeholder: {
          color: 'darkGrey1'
        },
        resize: "none",
        value: description,
        onChange: handleOnChange,
        isDisabled: isLoading
      }, textareaStyle)), (0, _jsxRuntime.jsx)(_react.Box, _objectSpread(_objectSpread({}, linkWrapperStyle), {}, {
        "data-testid": (0, _utils.formatDataTestId)(baseTestId, 'SaveAction'),
        children: (0, _jsxRuntime.jsx)(_react.Link, _objectSpread(_objectSpread({}, linkStyle), {}, {
          onClick: onSubmit,
          children: t('ccui.agentMemo.save')
        }))
      }))]
    }))
  });
}
var textareaStyle = {
  variant: 'unstyled',
  color: 'darkGrey1',
  size: 'md'
};
var cardStyle = {
  border: '1px solid var(--chakra-colors-tertiary)',
  color: 'baseBlack'
};
var linkWrapperStyle = {
  display: 'grid',
  justifyContent: 'flex-end',
  marginTop: 'sm'
};
var linkStyle = {
  textDecoration: 'underline',
  color: 'tertiary',
  _focus: {
    outline: 'none'
  },
  fontSize: 'md',
  fontWeight: 'semibold'
};