"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.SEARCH_PARAM_LINK_ACTIVE = void 0;
exports.SearchParamLink = SearchParamLink;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _objectWithoutProperties2 = _interopRequireDefault(require("@babel/runtime/helpers/objectWithoutProperties"));
var _server = require("@whitbread-eos/utils/server");
var _link = _interopRequireDefault(require("next/link"));
var _jsxRuntime = require("react/jsx-runtime");
var _excluded = ["name", "value", "children", "clear", "toggle"];
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
var SEARCH_PARAM_LINK_ACTIVE = exports.SEARCH_PARAM_LINK_ACTIVE = '1';
function SearchParamLink(_ref) {
  var {
      name,
      value = '',
      children,
      clear = false,
      toggle = false
    } = _ref,
    props = (0, _objectWithoutProperties2.default)(_ref, _excluded);
  var getInitialSearchParams = () => {
    if (clear === true) {
      return new URLSearchParams();
    }
    if (Array.isArray(clear)) {
      var _params = new URLSearchParams((0, _server.getSearchParams)());
      clear.forEach(searchParam => _params.delete(searchParam));
      return _params;
    }
    return new URLSearchParams((0, _server.getSearchParams)());
  };
  var modifySearchParams = params => {
    if (toggle) {
      if (params.get(name)) {
        params.delete(name);
      } else {
        params.set(name, SEARCH_PARAM_LINK_ACTIVE);
      }
      return;
    }
    params.set(name, value);
  };
  var params = getInitialSearchParams();
  modifySearchParams(params);
  return (0, _jsxRuntime.jsx)(_link.default, _objectSpread(_objectSpread({
    href: "?".concat(params),
    prefetch: false,
    scroll: false,
    replace: true
  }, props), {}, {
    children: children
  }));
}