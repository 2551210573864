"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = Logo;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _objectWithoutProperties2 = _interopRequireDefault(require("@babel/runtime/helpers/objectWithoutProperties"));
var _react = require("@chakra-ui/react");
var _utils = require("@whitbread-eos/utils");
var _icons = require("../../assets/icons");
var _Icon = _interopRequireDefault(require("../Icon"));
var _jsxRuntime = require("react/jsx-runtime");
var _excluded = ["href", "variant", "src", "isHeaderLogo"];
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function Logo(_ref) {
  var {
      href,
      variant = 'pi',
      src,
      isHeaderLogo
    } = _ref,
    otherProps = (0, _objectWithoutProperties2.default)(_ref, _excluded);
  var svgMapper = {
    pi: (0, _jsxRuntime.jsx)(_icons.LogoPi, {}),
    pid: (0, _jsxRuntime.jsx)(_icons.LogoPi, {}),
    'pi-simple': (0, _jsxRuntime.jsx)(_icons.LogoPiSimple, {}),
    'pid-simple': (0, _jsxRuntime.jsx)(_icons.LogoPiIcon, {}),
    'pi-icon': (0, _jsxRuntime.jsx)(_icons.LogoPiIcon, {}),
    hub: isHeaderLogo ? (0, _jsxRuntime.jsx)(_icons.LogoHub, {}) : (0, _jsxRuntime.jsx)(_Icon.default, _objectSpread({
      src: src && (0, _utils.formatAssetsUrl)(src)
    }, iconLogoHubStyle)),
    'hub-simple': (0, _jsxRuntime.jsx)(_icons.LogoHubSimple, {}),
    zip: (0, _jsxRuntime.jsx)(_icons.LogoZip, {}),
    'zip-simple': (0, _jsxRuntime.jsx)(_icons.LogoZipSimple, {})
  };
  var resolution = (0, _react.useBreakpointValue)({
    mobile: 'smallResolution',
    sm: 'bigResolution'
  });
  return (0, _jsxRuntime.jsx)(_react.Box, {
    "data-testid": "logo-container-".concat(variant),
    transform: otherProps.transform ? otherProps.transform : {
      mobile: 'scale(0.667)',
      sm: 'scale(0.833)',
      lg: 'scale(1)'
    },
    children: href ? (0, _jsxRuntime.jsx)(_react.Link, {
      "data-testid": "logo-test-id",
      href: href,
      _focus: {
        boxShadow: 'none'
      },
      children: variant !== 'hub' && variant !== 'hub-simple' && variant !== 'zip' && variant !== 'zip-simple' && resolution !== 'smallResolution' ? (0, _jsxRuntime.jsx)(_Icon.default, {
        src: src,
        maxHeight: "var(--chakra-space-3xl)",
        maxWidth: "9.87rem"
      }) : svgMapper[variant]
    }) : svgMapper[variant]
  });
}
var iconLogoHubStyle = {
  height: '4rem',
  width: '10.75rem'
};