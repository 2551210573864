"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = useFeatureSwitch;
var _config = _interopRequireDefault(require("next/config"));
function useFeatureSwitch(_ref) {
  var {
    featureSwitchKey,
    country,
    fallbackValue = false
  } = _ref;
  var {
    publicRuntimeConfig = {}
  } = (0, _config.default)() || {};
  if (country) {
    featureSwitchKey += "_".concat(country.toUpperCase());
  }
  var isFeatureEnabled = publicRuntimeConfig[featureSwitchKey] ? publicRuntimeConfig[featureSwitchKey].toLowerCase() === 'true' : fallbackValue;
  return isFeatureEnabled;
}