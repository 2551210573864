"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = ResultList;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _react = require("@chakra-ui/react");
var _utils = require("@whitbread-eos/utils");
var _ResultRow = _interopRequireDefault(require("./ResultRow.component"));
var _jsxRuntime = require("react/jsx-runtime");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function ResultList(_ref) {
  var {
    handleClickRow,
    selectedRow,
    dataForUpdateForm,
    loading,
    rows,
    headerTitles,
    baseDataTestId,
    t
  } = _ref;
  return (0, _jsxRuntime.jsx)(_react.TableContainer, _objectSpread(_objectSpread({
    "data-testid": (0, _utils.formatDataTestId)(baseDataTestId, 'Table-Container')
  }, resultContainerStyle), {}, {
    children: (0, _jsxRuntime.jsxs)(_react.Table, _objectSpread(_objectSpread({
      size: "sm"
    }, tableStyles), {}, {
      children: [(0, _jsxRuntime.jsx)(_react.Thead, _objectSpread(_objectSpread({}, headerTableStyle), {}, {
        children: (0, _jsxRuntime.jsx)(_react.Tr, {
          children: headerTitles.map(headerTitle => {
            return (0, _jsxRuntime.jsx)(_react.Th, _objectSpread(_objectSpread({}, headerResultsTextStyle), {}, {
              "data-testid": (0, _utils.formatDataTestId)(baseDataTestId, "TableHeader-".concat(headerTitle.id)),
              children: headerTitle.text
            }), headerTitle.id);
          })
        })
      })), (0, _jsxRuntime.jsxs)(_react.Tbody, {
        children: [rows === null || rows === void 0 ? void 0 : rows.map((row, i) => (0, _jsxRuntime.jsx)(_ResultRow.default, {
          handleClickRow: handleClickRow,
          selectedRow: selectedRow,
          dataForUpdateForm: dataForUpdateForm,
          cells: row.cells,
          accountId: row.AccountId,
          baseDataTestId: baseDataTestId,
          rowNr: i,
          t: t
        }, row.AccountId)), loading && (0, _jsxRuntime.jsx)(_react.Tr, {
          children: (0, _jsxRuntime.jsx)(_react.Td, {
            children: (0, _jsxRuntime.jsx)(_react.Text, {
              "data-testid": (0, _utils.formatDataTestId)(baseDataTestId, 'Table-loading-message'),
              children: t('searchresults.list.hotel.loading')
            })
          })
        })]
      })]
    }))
  }));
}
var resultContainerStyle = {
  display: 'block',
  overflowY: 'visible',
  overflowX: 'visible',
  whiteSpace: 'normal'
};
var headerTableStyle = {
  backgroundColor: 'lightGrey5',
  h: '4rem'
};
var tableStyles = {
  width: '100%',
  maxW: '100%'
};
var headerResultsTextStyle = {
  fontWeight: 'medium',
  fontSize: 'lg',
  lineHeight: '3',
  color: 'baseBlack',
  textTransform: 'none',
  fontFamily: 'header'
};