"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = BookingDetailsReservationInformationComponent;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _react = require("@chakra-ui/react");
var _api = require("@whitbread-eos/api");
var _atoms = require("@whitbread-eos/atoms");
var _utils = require("@whitbread-eos/utils");
var _upperFirst = _interopRequireDefault(require("lodash/upperFirst"));
var _jsxRuntime = require("react/jsx-runtime");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function BookingDetailsReservationInformationComponent(_ref) {
  var {
    baseDataTestId,
    sourcePms,
    rateType,
    isBart,
    t,
    gdsReferenceNumber,
    distBookingChannel,
    reasonForStay,
    isReadOnly,
    paymentOption
  } = _ref;
  var badgeColorByPMS = isBart ? 'zipSecondary' : 'primary';
  var paymentTypeText = getPaymentTypeText(paymentOption, t);
  return (0, _jsxRuntime.jsxs)(_react.Flex, {
    direction: "column",
    mb: "lg",
    "data-testid": (0, _utils.formatDataTestId)(baseDataTestId, 'BartBookingdDetailsReservationInformation'),
    children: [(0, _jsxRuntime.jsx)(_react.Flex, {
      children: (0, _jsxRuntime.jsx)(_atoms.Badge, _objectSpread(_objectSpread({
        variant: "primary",
        badgecolor: badgeColorByPMS
      }, getBadgeStyles(isBart)), {}, {
        children: (0, _upperFirst.default)(sourcePms.toLowerCase())
      }))
    }), (0, _jsxRuntime.jsx)(_react.Flex, {
      justifyContent: "space-between",
      mt: "lg",
      children: (0, _jsxRuntime.jsxs)(_react.Text, _objectSpread(_objectSpread({}, getLabelTextStyle(isReadOnly)), {}, {
        children: [t('ccui.manageBooking.typeOfBooking'), (0, _jsxRuntime.jsx)(_react.Text, {
          as: "span",
          fontWeight: "normal",
          color: "darkGrey2",
          children: ": ".concat(reasonForStay === _api.PurposeOfStay.BUSINESS ? t('booking.reason.business') : t('booking.reason.leisure'))
        })]
      }))
    }), distBookingChannel && (0, _jsxRuntime.jsx)(_react.Flex, {
      justifyContent: "space-between",
      children: (0, _jsxRuntime.jsxs)(_react.Text, _objectSpread(_objectSpread({}, labelStyle), {}, {
        children: [t('ccui.manageBooking.bookingChannel'), (0, _jsxRuntime.jsx)(_react.Text, {
          as: "span",
          fontWeight: "normal",
          children: ": ".concat((0, _upperFirst.default)(distBookingChannel.toLowerCase()))
        })]
      }))
    }), (0, _jsxRuntime.jsx)(_react.Flex, {
      justifyContent: "space-between",
      children: (0, _jsxRuntime.jsxs)(_react.Text, _objectSpread(_objectSpread({}, getLabelTextStyle(isReadOnly)), {}, {
        children: [t('ccui.manageBooking.rateType'), (0, _jsxRuntime.jsx)(_react.Text, {
          as: "span",
          fontWeight: "normal",
          color: "darkGrey2",
          children: ": ".concat((0, _upperFirst.default)(rateType.toLowerCase()))
        })]
      }))
    }), gdsReferenceNumber && (0, _jsxRuntime.jsx)(_react.Flex, {
      justifyContent: "space-between",
      children: (0, _jsxRuntime.jsxs)(_react.Text, _objectSpread(_objectSpread({}, labelStyle), {}, {
        children: [t('ccui.manageBooking.3rdPartyBookingReference'), (0, _jsxRuntime.jsx)(_react.Text, {
          as: "span",
          fontWeight: "normal",
          children: ": ".concat((0, _upperFirst.default)(gdsReferenceNumber.toLowerCase()))
        })]
      }))
    }), (0, _jsxRuntime.jsx)(_react.Flex, {
      justifyContent: "space-between",
      children: (0, _jsxRuntime.jsxs)(_react.Text, _objectSpread(_objectSpread({}, getLabelTextStyle(isReadOnly)), {}, {
        children: [t('ccui.manageBooking.paymentType.label'), (0, _jsxRuntime.jsx)(_react.Text, {
          as: "span",
          fontWeight: "normal",
          color: "darkGrey2",
          children: ": ".concat(paymentTypeText)
        })]
      }))
    })]
  });
}
var getPaymentTypeText = (paymentType, t) => {
  switch (paymentType) {
    case _api.GuaranteeCodes.ACCOUNT_TO_COMPANY:
      return t('ccui.manageBooking.paymentType.accountToCompany');
    case _api.GuaranteeCodes.RESERVE_WITHOUT_CARD:
      return t('ccui.manageBooking.paymentType.nonGuaranteed');
    default:
      return t('ccui.manageBooking.paymentType.other');
  }
};
var getBadgeStyles = isBartReservation => {
  return {
    px: isBartReservation ? '1rem' : '0.5rem'
  };
};
var getLabelTextStyle = isReadOnly => isReadOnly ? {
  color: 'darkGrey1',
  fontSize: 'lg',
  lineHeight: '3',
  fontWeight: 'semibold',
  w: {
    mobile: '7.25rem',
    sm: 'full'
  }
} : labelStyle;
var labelStyle = {
  fontWeight: 'semibold',
  fontSize: 'md',
  lineHeight: '3',
  color: 'darkGrey1',
  as: 'span'
};