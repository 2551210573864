"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = UpdateAccountForm;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _react = require("@chakra-ui/react");
var _atoms = require("@whitbread-eos/atoms");
var _utils = require("@whitbread-eos/utils");
var _nextI18next = require("next-i18next");
var _router = require("next/router");
var _react2 = require("react");
var _updateAccountFormConfig = require("./updateAccountFormConfig");
var _jsxRuntime = require("react/jsx-runtime");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function UpdateAccountForm(_ref) {
  var _router$query;
  var {
    dataForUpdateForm,
    selectedRow
  } = _ref;
  var router = (0, _router.useRouter)();
  var {
    t
  } = (0, _nextI18next.useTranslation)();
  var {
    language,
    country
  } = (0, _utils.useCustomLocale)();
  var reservationId = (_router$query = router.query) === null || _router$query === void 0 ? void 0 : _router$query.reservationId;
  var reservationIdValue = typeof reservationId === 'string' ? reservationId : undefined;
  var onSubmit = () => {
    console.log('CTA-Submit-pressed');
  };
  var onGuestVerify = () => {
    setGuestNotVerified(false);
  };
  var onUnlock = () => {
    console.log('CTA-Reset-pressed');
  };
  var onResetPassword = () => {
    console.log('CTA-Reset-pressed');
  };
  var onReuseDetails = () => {
    var storedData = localStorage.getItem('formDetails');
    var defaultDataFromBooking = storedData ? JSON.parse(storedData) : null;
    if (reservationId) {
      if (defaultDataFromBooking) {
        try {
          window.localStorage.setItem('formDetails', JSON.stringify(_objectSpread(_objectSpread({}, defaultDataFromBooking), {}, {
            title: defaultValues.title,
            firstName: defaultValues.firstName,
            lastName: defaultValues.lastName,
            email: defaultValues.email,
            phone: defaultValues.mobileNumber,
            landline: defaultValues.landlineNumber,
            companyName: defaultValues.companyName || '',
            postcodeAddress: defaultValues.postalCode
          })));
        } catch (error) {
          console.log(error);
        }
      }
      window.location.href = "/".concat(country, "/").concat(language, "/guest-details?reservationId=").concat(reservationId);
    }
  };
  var [guestNotVerified, setGuestNotVerified] = (0, _react2.useState)(true);
  var [defaultValues, setDefaultValues] = (0, _react2.useState)({
    title: dataForUpdateForm.title,
    firstName: dataForUpdateForm.firstName,
    lastName: dataForUpdateForm.lastName,
    companyName: dataForUpdateForm.companyName,
    email: dataForUpdateForm.email,
    address: dataForUpdateForm.address,
    postalCode: dataForUpdateForm.postalCode,
    mobileNumber: dataForUpdateForm.mobileNumber,
    landlineNumber: dataForUpdateForm.landlineNumber
  });
  (0, _react2.useEffect)(() => {
    setDefaultValues(dataForUpdateForm);
    setGuestNotVerified(true);
  }, [selectedRow === null || selectedRow === void 0 ? void 0 : selectedRow.accountId]);
  var getFormState = (0, _react2.useCallback)(state => {
    setDefaultValues(state);
  }, [setDefaultValues]);
  var baseDataTestId = 'UpdateAccountPage';
  return (0, _jsxRuntime.jsx)(_react.GridItem, {
    "data-testid": (0, _utils.formatDataTestId)(baseDataTestId, 'wrapper'),
    children: (0, _jsxRuntime.jsx)(_atoms.Form, _objectSpread({}, (0, _updateAccountFormConfig.updateAccountFormConfig)({
      getFormState,
      defaultValues,
      onSubmit,
      onGuestVerify,
      onUnlock,
      onResetPassword,
      onReuseDetails,
      baseDataTestId,
      t,
      language,
      fieldsetDisabled: guestNotVerified,
      reservationId: reservationIdValue
    })))
  });
}