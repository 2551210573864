"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.VIEW_TYPE_CONSTANTS = void 0;
exports.default = Controls;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _react = require("@chakra-ui/react");
var _atoms = require("@whitbread-eos/atoms");
var _utils = require("@whitbread-eos/utils");
var _Filters = _interopRequireDefault(require("../Filters"));
var _SortBy = _interopRequireDefault(require("../SortBy"));
var _jsxRuntime = require("react/jsx-runtime");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
var VIEW_TYPE_CONSTANTS = exports.VIEW_TYPE_CONSTANTS = {
  mapView: '1',
  listView: '2'
};
function Controls(_ref) {
  var _buttonLabels$distanc, _buttonLabels$price, _buttonLabels$filters;
  var {
    viewType,
    onChangeViewType,
    onChangeSortValue,
    onChangeFilters,
    labels,
    sortValue,
    defaultFilters,
    visibility
  } = _ref;
  var {
    isLessThanMd
  } = (0, _utils.useScreenSize)();
  var {
    buttonLabels,
    filterLabels
  } = labels;
  var sortByLabels = {
    sortByDistance: (_buttonLabels$distanc = buttonLabels === null || buttonLabels === void 0 ? void 0 : buttonLabels.distance) !== null && _buttonLabels$distanc !== void 0 ? _buttonLabels$distanc : '',
    sortByPrice: (_buttonLabels$price = buttonLabels === null || buttonLabels === void 0 ? void 0 : buttonLabels.price) !== null && _buttonLabels$price !== void 0 ? _buttonLabels$price : ''
  };
  var filterByLabels = {
    filterByButton: (_buttonLabels$filters = buttonLabels === null || buttonLabels === void 0 ? void 0 : buttonLabels.filtersLong) !== null && _buttonLabels$filters !== void 0 ? _buttonLabels$filters : '',
    filters: filterLabels
  };
  return (0, _jsxRuntime.jsxs)(_react.Flex, _objectSpread(_objectSpread(_objectSpread({}, wrapperStyles), viewType === VIEW_TYPE_CONSTANTS.mapView && _objectSpread({}, mapViewWrapperStyles)), {}, {
    children: [(0, _jsxRuntime.jsxs)(_react.Flex, {
      mb: {
        mobile: 'sm',
        md: '0'
      },
      children: [(0, _jsxRuntime.jsx)(_Filters.default, {
        onChangeFilters: onChangeFilters,
        defaultFilters: defaultFilters,
        isDisabled: visibility === null || visibility === void 0 ? void 0 : visibility.isFilterDisabled,
        labels: filterByLabels
      }), viewType === VIEW_TYPE_CONSTANTS.listView && (0, _jsxRuntime.jsx)(_SortBy.default, {
        labels: sortByLabels,
        selectedOption: sortValue,
        isDisabled: !!(visibility !== null && visibility !== void 0 && visibility.isSortDisabled),
        onChange: onChangeSortValue
      })]
    }), !isLessThanMd && (0, _jsxRuntime.jsx)(_atoms.Button, _objectSpread(_objectSpread({
      "data-testid": "controls-map-or-list-button",
      size: "sm",
      variant: "genericSecondary",
      onClick: onChangeViewType
    }, buttonWidth), {}, {
      children: viewType === VIEW_TYPE_CONSTANTS.listView ? buttonLabels.mapLong : buttonLabels.listLong
    }))]
  }));
}
var wrapperStyles = {
  m: 'var(--chakra-space-lg) auto var(--chakra-space-md)',
  maxW: {
    mobile: 'full',
    md: '45rem',
    lg: '50.5rem',
    xl: '54rem'
  },
  w: 'full',
  flexWrap: {
    mobile: 'wrap',
    md: 'nowrap'
  }
};
var mapViewWrapperStyles = {
  position: 'absolute',
  zIndex: 2,
  left: {
    mobile: '1.1875rem',
    sm: 'var(--chakra-space-lg)',
    md: '2.375rem',
    lg: '1.75rem',
    xl: '4.8125rem'
  },
  maxW: {
    mobile: 'full',
    md: '21rem',
    lg: '25rem',
    xl: '25rem'
  },
  marginTop: {
    mobile: 'var(--chakra-space-sm)',
    sm: 'var(--chakra-space-xmd)',
    md: 'var(--chakra-space-md)'
  }
};
var buttonWidth = {
  maxW: '11.5rem',
  w: 'full',
  mr: {
    mobile: 'md',
    md: '0'
  }
};