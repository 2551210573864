"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = BookingSummary;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _utils = require("@whitbread-eos/utils");
var _react = require("react");
var _BookingSummaryCard = _interopRequireDefault(require("./BookingSummaryCard"));
var _BookingSummaryMobile = _interopRequireDefault(require("./BookingSummaryMobile"));
var _jsxRuntime = require("react/jsx-runtime");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function BookingSummary(_ref) {
  var {
    variant,
    bookingSummaryData,
    reservationDetails,
    t,
    language,
    prefixDataTestId,
    infoMessages,
    announcement,
    isDiscountApplied,
    taxesMessage,
    selectedPaymentType,
    paypalOptions,
    isExtrasDisplayed
  } = _ref;
  var baseDataTestId = (0, _utils.formatDataTestId)(prefixDataTestId, 'BookingSummary');
  var [totalCostAmount, setTotalCostAmount] = (0, _react.useState)(0);
  var calculateTotalCostWithoutDonation = (0, _react.useCallback)((totalCostData, noNights) => {
    var _totalCostData$initia;
    var initial = (_totalCostData$initia = totalCostData.initialTotalCost) !== null && _totalCostData$initia !== void 0 ? _totalCostData$initia : 0;
    var totalMealsPrice = totalCostData !== null && totalCostData !== void 0 && totalCostData.meals ? totalCostData.meals.reduce((prev, current) => {
      var adultsMealsPrice = current.adultsMeals.reduce((prev, current) => {
        var _current$price;
        var actualPrice = (_current$price = current.price) !== null && _current$price !== void 0 ? _current$price : 0;
        return prev + actualPrice * current.noSelections * noNights;
      }, 0);
      var childrenMealsPrice = current.childrenMeals.reduce((prev, current) => {
        var _current$price2;
        var actualPrice = (_current$price2 = current.price) !== null && _current$price2 !== void 0 ? _current$price2 : 0;
        return prev + actualPrice * current.noSelections * noNights;
      }, 0);
      return prev + adultsMealsPrice + childrenMealsPrice;
    }, 0) : 0;
    return initial + totalMealsPrice;
  }, [bookingSummaryData, reservationDetails]);
  (0, _react.useEffect)(() => {
    setTotalCostAmount(bookingSummaryData.totalCost ? calculateTotalCostWithoutDonation(bookingSummaryData.totalCost, reservationDetails.noNights) : 0);
  }, [bookingSummaryData, reservationDetails]);
  if (variant === 'mobile') {
    return (0, _jsxRuntime.jsx)(_BookingSummaryMobile.default, _objectSpread(_objectSpread({
      t: t,
      language: language,
      totalCostAmount: totalCostAmount,
      reservationDetails: reservationDetails,
      bookingSummaryData: bookingSummaryData,
      infoMessages: infoMessages,
      prefixDataTestId: "".concat(baseDataTestId, "-MobileVariant"),
      announcement: announcement,
      taxesMessage: taxesMessage,
      isExtrasDisplayed: isExtrasDisplayed
    }, selectedPaymentType && {
      selectedPaymentType
    }), paypalOptions && {
      paypalOptions
    }));
  }
  return (0, _jsxRuntime.jsx)(_BookingSummaryCard.default, {
    t: t,
    language: language,
    totalCostAmount: totalCostAmount,
    bookingSummaryData: bookingSummaryData,
    prefixDataTestId: "".concat(baseDataTestId, "-DesktopVariant"),
    currencyCode: reservationDetails.currency,
    isDiscountApplied: isDiscountApplied,
    taxesMessage: taxesMessage,
    isExtrasDisplayed: isExtrasDisplayed
  });
}