"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.GET_ROOM_RATE_POLICIES = void 0;
exports.default = RoomRatePolicies;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _taggedTemplateLiteral2 = _interopRequireDefault(require("@babel/runtime/helpers/taggedTemplateLiteral"));
var _react = require("@chakra-ui/react");
var _styled = _interopRequireDefault(require("@emotion/styled"));
var _atoms = require("@whitbread-eos/atoms");
var _utils = require("@whitbread-eos/utils");
var _graphqlRequest = require("graphql-request");
var _nextI18next = require("next-i18next");
var _react2 = require("react");
var _jsxRuntime = require("react/jsx-runtime");
var _templateObject, _templateObject2;
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
var GET_ROOM_RATE_POLICIES = exports.GET_ROOM_RATE_POLICIES = (0, _graphqlRequest.gql)(_templateObject || (_templateObject = (0, _taggedTemplateLiteral2.default)(["\n  query getRatesInformation(\n    $hotelBrand: String!\n    $hotelId: String!\n    $language: String!\n    $country: String!\n  ) {\n    ratesInformation(\n      brand: $hotelBrand\n      hotelId: $hotelId\n      language: $language\n      country: $country\n    ) {\n      rateClassifications {\n        rateClassification\n        rateOrder\n        rateNotes\n        rateName\n        rateLongDescription\n        rateDescription\n      }\n    }\n  }\n"])));
function RoomRatePolicies(_ref) {
  var _getRoomRateLabels, _getRoomRateLabels2, _getRoomRateLabels3, _getRoomRateLabels4, _getRoomRateLabels5, _getRoomRateLabels6, _getRoomRateLabels7, _getRoomRateLabels8, _getRoomRateLabels9, _getRoomRateLabels10;
  var {
    isRoomRatePoliciesChecked,
    onChange,
    language,
    country,
    hotelId,
    hotelBrand
  } = _ref;
  var [isModalOpen, setIsModalOpen] = (0, _react2.useState)(false);
  var [stateModal, setStateModal] = (0, _react2.useState)(false);
  var {
    t
  } = (0, _nextI18next.useTranslation)(['common']);
  var baseTestId = 'roomRatePolicies';
  var {
    data
  } = (0, _utils.useQueryRequest)('ratesInformation', GET_ROOM_RATE_POLICIES, {
    hotelBrand,
    hotelId,
    language,
    country
  });
  return (0, _jsxRuntime.jsxs)(_react.Box, {
    "data-testid": "".concat(baseTestId, "_Component"),
    children: [(0, _jsxRuntime.jsxs)(_react.Box, _objectSpread(_objectSpread({}, checkboxStyle), {}, {
      children: [(0, _jsxRuntime.jsx)(CustomCheckbox, {
        isChecked: isRoomRatePoliciesChecked,
        onChange: showModal,
        "data-testid": "".concat(baseTestId, "_checkbox")
      }), (0, _jsxRuntime.jsxs)(_react.Text, _objectSpread(_objectSpread({
        noOfLines: 1
      }, checkboxTextStyle), {}, {
        "data-testid": "".concat(baseTestId, "_checkbox-text"),
        children: [t('ccui.payment.confirmBooking.textPolicies.label1'), (0, _jsxRuntime.jsx)(_react.Text, _objectSpread(_objectSpread({
          as: "u",
          onClick: () => setIsModalOpen(true),
          "data-testid": "".concat(baseTestId, "_launchButton")
        }, roomratePoliciesLink), {}, {
          px: "0.5ch",
          children: t('ccui.payment.confirmBooking.textPolicies.label2')
        })), t('ccui.payment.confirmBooking.textPolicies.label3')]
      }))]
    })), (0, _jsxRuntime.jsx)(_atoms.ModalVariants, {
      onClose: () => setIsModalOpen(!isModalOpen),
      isOpen: isModalOpen,
      variant: "info",
      variantProps: {
        title: t('ccui.roomRate.title'),
        delimiter: true
      },
      dataTestId: "".concat(baseTestId),
      children: (0, _jsxRuntime.jsx)(_react.VStack, _objectSpread(_objectSpread({}, modalStyles), {}, {
        children: (0, _jsxRuntime.jsxs)(_react.Box, {
          px: 6,
          py: "xl",
          children: [(0, _jsxRuntime.jsx)(_react.Text, {
            as: "b",
            color: "darkGrey1",
            "data-testid": "".concat(baseTestId, "_flex-title"),
            children: (_getRoomRateLabels = getRoomRateLabels('flexrate')) === null || _getRoomRateLabels === void 0 ? void 0 : _getRoomRateLabels.rateName
          }), (0, _jsxRuntime.jsx)(_react.Text, _objectSpread(_objectSpread({
            pb: "md"
          }, description), {}, {
            "data-testid": "".concat(baseTestId, "_flex-description"),
            children: (_getRoomRateLabels2 = getRoomRateLabels('flexrate')) === null || _getRoomRateLabels2 === void 0 ? void 0 : _getRoomRateLabels2.rateDescription
          })), (0, _jsxRuntime.jsx)(_react.Text, {
            as: "b",
            color: "darkGrey1",
            "data-testid": "".concat(baseTestId, "_semiflex-title"),
            children: (_getRoomRateLabels3 = getRoomRateLabels('semiflex')) === null || _getRoomRateLabels3 === void 0 ? void 0 : _getRoomRateLabels3.rateName
          }), (0, _jsxRuntime.jsx)(_react.Text, _objectSpread(_objectSpread({
            pb: "md"
          }, description), {}, {
            "data-testid": "".concat(baseTestId, "_semiflex-description"),
            children: (_getRoomRateLabels4 = getRoomRateLabels('semiflex')) === null || _getRoomRateLabels4 === void 0 ? void 0 : _getRoomRateLabels4.rateDescription
          })), (0, _jsxRuntime.jsx)(_react.Text, {
            as: "b",
            color: "darkGrey1",
            "data-testid": "".concat(baseTestId, "_advance-title"),
            children: (_getRoomRateLabels5 = getRoomRateLabels('advance')) === null || _getRoomRateLabels5 === void 0 ? void 0 : _getRoomRateLabels5.rateName
          }), (0, _jsxRuntime.jsx)(_react.Text, _objectSpread(_objectSpread({
            pb: "md"
          }, description), {}, {
            "data-testid": "".concat(baseTestId, "_advance-description"),
            children: (_getRoomRateLabels6 = getRoomRateLabels('advance')) === null || _getRoomRateLabels6 === void 0 ? void 0 : _getRoomRateLabels6.rateDescription
          })), (0, _jsxRuntime.jsx)(_react.Text, {
            as: "b",
            color: "darkGrey1",
            "data-testid": "".concat(baseTestId, "_standard-title"),
            children: (_getRoomRateLabels7 = getRoomRateLabels('standard')) === null || _getRoomRateLabels7 === void 0 ? void 0 : _getRoomRateLabels7.rateName
          }), (0, _jsxRuntime.jsx)(_react.Text, _objectSpread(_objectSpread({
            pb: "md"
          }, description), {}, {
            "data-testid": "".concat(baseTestId, "_standard-description"),
            children: (_getRoomRateLabels8 = getRoomRateLabels('standard')) === null || _getRoomRateLabels8 === void 0 ? void 0 : _getRoomRateLabels8.rateDescription
          })), (0, _jsxRuntime.jsx)(_react.Text, {
            as: "b",
            color: "darkGrey1",
            "data-testid": "".concat(baseTestId, "_nonflex-title"),
            children: (_getRoomRateLabels9 = getRoomRateLabels('nonflex')) === null || _getRoomRateLabels9 === void 0 ? void 0 : _getRoomRateLabels9.rateName
          }), (0, _jsxRuntime.jsx)(_react.Text, _objectSpread(_objectSpread({
            pb: "md"
          }, description), {}, {
            "data-testid": "".concat(baseTestId, "_nonflex-description"),
            children: (_getRoomRateLabels10 = getRoomRateLabels('nonflex')) === null || _getRoomRateLabels10 === void 0 ? void 0 : _getRoomRateLabels10.rateDescription
          }))]
        })
      }))
    })]
  });
  function showModal() {
    if (!stateModal && !isRoomRatePoliciesChecked) {
      setStateModal(true);
      setIsModalOpen(true);
    }
    onChange();
  }
  function getRoomRateLabels(rateType) {
    var _data$ratesInformatio;
    return data === null || data === void 0 || (_data$ratesInformatio = data.ratesInformation) === null || _data$ratesInformatio === void 0 ? void 0 : _data$ratesInformatio.rateClassifications.find(rate => rate.rateClassification.toLowerCase() === rateType);
  }
}
var CustomCheckbox = (0, _styled.default)(_react.Checkbox)(_templateObject2 || (_templateObject2 = (0, _taggedTemplateLiteral2.default)(["\n  span.chakra-checkbox__control {\n    margin: 0;\n    width: 1.25rem;\n    height: 1.25rem;\n  }\n"])));
var description = {
  color: 'darkGrey2',
  fontSize: 'sm',
  lineHeight: 2
};
var modalStyles = {
  width: '30.375rem'
};
var checkboxStyle = {
  display: 'flex',
  flexDirection: 'row',
  overflow: 'hidden'
};
var checkboxTextStyle = {
  display: 'inline-flex',
  marginLeft: '0.6rem',
  color: 'darkGrey1'
};
var roomratePoliciesLink = {
  cursor: 'pointer',
  color: 'lightPurple'
};