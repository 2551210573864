"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = BookingDetailsRoomInformationComponent;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _react = require("@chakra-ui/react");
var _api = require("@whitbread-eos/api");
var _utils = require("@whitbread-eos/utils");
var _nextI18next = require("next-i18next");
var _react2 = require("react");
var _BookingDetailsExtras = require("../BookingDetailsExtras");
var _jsxRuntime = require("react/jsx-runtime");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function BookingDetailsRoomInformationComponent(_ref) {
  var _extrasPackageRoom$pa;
  var {
    leadGuestName,
    roomPrice,
    roomType,
    childrenMealDescription,
    noChildren,
    adultMealDescription,
    noAdults,
    noNights,
    currencyCode,
    bookingStatus,
    roomNumber,
    extrasPackageRoom,
    showExtras = true
  } = _ref;
  var {
    t
  } = (0, _nextI18next.useTranslation)(['common']);
  var {
    language
  } = (0, _utils.useCustomLocale)();
  var getAdultsPluralLabel = (0, _react2.useCallback)(value => t(value === 1 ? 'account.dashboard.adult' : 'account.dashboard.adults'), [t]);
  var getChildrenPluralLabel = (0, _react2.useCallback)(value => t(value === 1 ? 'account.dashboard.child' : 'account.dashboard.children'), [t]);
  var showMeals = showExtras && (adultMealDescription === null || adultMealDescription === void 0 ? void 0 : adultMealDescription.length) > 0;
  var renderExtrasSection = showMeals || (extrasPackageRoom === null || extrasPackageRoom === void 0 || (_extrasPackageRoom$pa = extrasPackageRoom.packagesList) === null || _extrasPackageRoom$pa === void 0 ? void 0 : _extrasPackageRoom$pa.length) > 0;
  return (0, _jsxRuntime.jsxs)(_react.Flex, {
    direction: "column",
    children: [(0, _jsxRuntime.jsxs)(_react.Text, _objectSpread(_objectSpread({}, roomNumberStyle), {}, {
      "data-testid": "roomNumber-".concat(roomNumber),
      children: [t('booking.summary.room'), " ", roomNumber, (0, _jsxRuntime.jsx)(_react.Text, _objectSpread(_objectSpread({}, leadNameStyle), {}, {
        "data-testid": "leadGuestName",
        className: "sessioncamhidetext assist-no-show",
        children: " ".concat(leadGuestName)
      }))]
    })), (0, _jsxRuntime.jsxs)(_react.Flex, {
      justifyContent: "space-between",
      children: [(0, _jsxRuntime.jsxs)(_react.Text, _objectSpread(_objectSpread({}, roomDetailsStyle), {}, {
        "data-testid": "roomType",
        children: [roomType, noAdults > 0 && (0, _jsxRuntime.jsx)(_react.Text, {
          as: "span",
          children: " - ".concat(noAdults, " ").concat(getAdultsPluralLabel(noAdults))
        }), noChildren > 0 && (0, _jsxRuntime.jsx)(_react.Text, {
          as: "span",
          children: ", ".concat(noChildren, " ").concat(getChildrenPluralLabel(noChildren))
        })]
      })), bookingStatus !== _api.BC_RESERVATION_STATUS.CANCELLED && (0, _jsxRuntime.jsx)(_react.Text, _objectSpread(_objectSpread({}, priceStyle), {}, {
        "data-testid": "roomPriceLabel",
        children: (0, _utils.formatPrice)(currencyCode && (0, _utils.formatCurrency)(currencyCode), roomPrice === null || roomPrice === void 0 ? void 0 : roomPrice.toFixed(2), language)
      }))]
    }), renderExtrasSection && (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
      children: [(0, _jsxRuntime.jsx)(_react.Text, _objectSpread(_objectSpread({
        mt: {
          mobile: 'md',
          lg: 'lg'
        }
      }, roomNumberStyle), {}, {
        children: t('account.dashboard.extras')
      })), (0, _jsxRuntime.jsx)(_BookingDetailsExtras.BookingDetailsExtrasComponent, {
        adultMealDescription: adultMealDescription,
        childrenMealDescription: childrenMealDescription,
        currencyCode: currencyCode,
        extrasPackageRoom: extrasPackageRoom,
        language: language,
        noNights: noNights,
        bookingStatus: bookingStatus,
        showEciLco: renderExtrasSection,
        showMeals: showMeals
      })]
    })]
  });
}
var leadNameStyle = {
  color: 'darkGrey2',
  as: 'span',
  fontWeight: 'normal',
  fontSize: 'md',
  lineHeight: '3'
};
var priceStyle = {
  fontWeight: 'medium',
  fontSize: {
    mobile: 'md',
    sm: 'lg'
  },
  color: 'darkGrey2',
  lineHeight: '3'
};
var roomDetailsStyle = {
  color: 'darkGrey2',
  fontSize: {
    mobile: 'md',
    sm: 'lg'
  },
  lineHeight: '3',
  fontWeight: 'normal',
  w: {
    mobile: '7.25rem',
    sm: 'full'
  }
};
var roomNumberStyle = {
  fontWeight: 'semibold',
  fontSize: {
    mobile: 'md',
    sm: 'lg'
  },
  lineHeight: '3',
  color: 'darkGrey1',
  as: 'span'
};