"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = useUserDetails;
var _config = _interopRequireDefault(require("next/config"));
var _auth = require("../getters/auth");
var _useRequest = require("./use-request");
function useUserDetails(isBusinessBooker, isLoggedIn) {
  var {
    publicRuntimeConfig = {}
  } = (0, _config.default)() || {};
  var idTokenCookie = (0, _auth.getAuthCookie)();
  var {
    email
  } = (0, _auth.decodeIdToken)(idTokenCookie);
  var {
    data
  } = (0, _useRequest.useRestQueryRequest)(['userDetails', idTokenCookie], 'GET', "".concat(publicRuntimeConfig.NEXT_PUBLIC_REST_API, "/customers/hotels/").concat(email, "?business=").concat(isBusinessBooker), {
    Authorization: "Bearer ".concat(idTokenCookie)
  }, {
    enabled: isLoggedIn
  });
  return data;
}