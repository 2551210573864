"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = BackButton;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _react = require("@chakra-ui/react");
var _atoms = require("@whitbread-eos/atoms");
var _utils = require("@whitbread-eos/utils");
var _nextI18next = require("next-i18next");
var _router = require("next/router");
var _jsxRuntime = require("react/jsx-runtime");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function BackButton(_ref) {
  var {
    prefixDataTestId
  } = _ref;
  var router = (0, _router.useRouter)();
  var {
    t
  } = (0, _nextI18next.useTranslation)(['common']);
  return (0, _jsxRuntime.jsxs)(_react.Flex, _objectSpread(_objectSpread({
    onClick: () => {
      router.back();
    }
  }, buttonWrapper), {}, {
    "data-testid": (0, _utils.formatDataTestId)(prefixDataTestId, 'BackToHDPButton'),
    children: [(0, _jsxRuntime.jsx)(_atoms.Icon, {
      svg: (0, _jsxRuntime.jsx)(_atoms.ChevronLeft24, {})
    }), (0, _jsxRuntime.jsx)(_react.Text, _objectSpread(_objectSpread({}, textStyle), {}, {
      children: t('booking.summary.back')
    }))]
  }));
}
var buttonWrapper = {
  mt: 'xl',
  alignItems: 'center',
  w: 'fit-content',
  justifyContent: 'flex-start',
  cursor: 'pointer'
};
var textStyle = {
  pl: 'sm',
  fontWeight: 'semibold',
  fontSize: 'md',
  color: 'darkGrey1',
  lineHeight: '3'
};