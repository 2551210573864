"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getRoomTotalprice = exports.getRoomGroups = exports.getRoomExtrasTotal = exports.getMealPrice = exports.getChildrenMealDescription = exports.getAdultMealDescription = void 0;
var _validators = require("../validators");
var getRoomGroups = (rez, t) => {
  var _rez$roomStay, _rez$roomStay2;
  var adults = rez === null || rez === void 0 || (_rez$roomStay = rez.roomStay) === null || _rez$roomStay === void 0 ? void 0 : _rez$roomStay.adultsNumber;
  var children = rez === null || rez === void 0 || (_rez$roomStay2 = rez.roomStay) === null || _rez$roomStay2 === void 0 ? void 0 : _rez$roomStay2.childrenNumber;
  var isAdults = Boolean(adults);
  var isChildren = Boolean(children);
  return (0, _validators.ternaryCondition)(isAdults, (0, _validators.ternaryCondition)(adults > 1, "".concat(adults, " ").concat(t('account.dashboard.adults')), "".concat(adults, " ").concat(t('account.dashboard.adult'))), '') + (isAdults && isChildren ? ', ' : '') + (0, _validators.ternaryCondition)(isChildren, (0, _validators.ternaryCondition)(children > 1, "".concat(children, " ").concat(t('account.dashboard.children')), "".concat(children, " ").concat(t('account.dashboard.child'))), '');
};
exports.getRoomGroups = getRoomGroups;
var getRoomTotalprice = rez => {
  var _rez$roomStay3;
  return (rez === null || rez === void 0 || (_rez$roomStay3 = rez.roomStay) === null || _rez$roomStay3 === void 0 ? void 0 : _rez$roomStay3.roomPrice) + getRoomExtrasTotal(rez);
};
exports.getRoomTotalprice = getRoomTotalprice;
var getRoomExtrasTotal = rez => {
  var _rez$reservationPacka;
  var extrasTotal = 0;
  rez === null || rez === void 0 || (_rez$reservationPacka = rez.reservationPackageList) === null || _rez$reservationPacka === void 0 || _rez$reservationPacka.forEach(pkg => {
    var _rez$roomStay4;
    var noPackages = !pkg.description.includes('Charity') && !pkg.description.includes('City') && !pkg.description.includes('Early Check In') && !pkg.description.includes('Late Check Out 2pm');
    var extrasPackages = !pkg.description.includes('Charity') && !pkg.description.includes('City') && (pkg.description.includes('Early Check In') || pkg.description.includes('Late Check Out 2pm'));
    return extrasTotal += noPackages ? pkg.computedPrice * (rez === null || rez === void 0 || (_rez$roomStay4 = rez.roomStay) === null || _rez$roomStay4 === void 0 || (_rez$roomStay4 = _rez$roomStay4.ratesPerNight) === null || _rez$roomStay4 === void 0 ? void 0 : _rez$roomStay4.length) : extrasPackages ? pkg.computedPrice : 0;
  });
  return isNaN(extrasTotal) ? 0 : extrasTotal;
};
exports.getRoomExtrasTotal = getRoomExtrasTotal;
var getMealPrice = mealsSelections => {
  var {
    adultsMeals = [],
    childrenMeals = []
  } = (0, _validators.ternaryCondition)(mealsSelections !== undefined, mealsSelections, {});
  var mealTotal = 0;
  adultsMeals === null || adultsMeals === void 0 || adultsMeals.forEach(meal => {
    var {
      noSelections,
      price
    } = meal;
    mealTotal += Number(noSelections) * (0, _validators.ternaryCondition)(price !== undefined, price, 0);
  });
  childrenMeals === null || childrenMeals === void 0 || childrenMeals.forEach(meal => {
    var {
      noSelections,
      price
    } = meal;
    mealTotal += Number(noSelections) * (0, _validators.ternaryCondition)(price !== undefined, price, 0);
  });
  return mealTotal;
};
exports.getMealPrice = getMealPrice;
var getAdultMealDescription = (adultMeals, t) => {
  var adultMealDescription = [];
  adultMeals === null || adultMeals === void 0 || adultMeals.forEach(meal => {
    var {
      noSelections,
      title
    } = meal;
    adultMealDescription.push("".concat(noSelections, " ").concat((0, _validators.ternaryCondition)(Number(noSelections) > 1, t('account.dashboard.adults'), t('account.dashboard.adult')), " ").concat(title));
  });
  return adultMealDescription;
};
exports.getAdultMealDescription = getAdultMealDescription;
var getChildrenMealDescription = (childrenMeals, t) => {
  var childrenMealDescription = [];
  childrenMeals === null || childrenMeals === void 0 || childrenMeals.forEach(meal => {
    var {
      noSelections,
      title
    } = meal;
    childrenMealDescription.push("".concat(noSelections, " ").concat((0, _validators.ternaryCondition)(Number(noSelections) > 1, t('account.dashboard.children'), t('account.dashboard.child')), " ").concat(title));
  });
  return childrenMealDescription;
};
exports.getChildrenMealDescription = getChildrenMealDescription;