"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = DefaultModal;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _objectWithoutProperties2 = _interopRequireDefault(require("@babel/runtime/helpers/objectWithoutProperties"));
var _react = require("@chakra-ui/react");
var _utils = require("@whitbread-eos/utils");
var _icons = require("../../../../assets/icons");
var _Icon = _interopRequireDefault(require("../../../Icon"));
var _jsxRuntime = require("react/jsx-runtime");
var _excluded = ["onClose", "isOpen", "children", "variantProps", "dataTestId", "updatedWidth", "overlayStyles", "contentContainerStyles", "headerStyles"];
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function DefaultModal(_ref) {
  var _variantProps$overflo;
  var {
      onClose,
      isOpen,
      children,
      variantProps,
      dataTestId,
      updatedWidth,
      overlayStyles,
      contentContainerStyles,
      headerStyles: externalHeaderStyles
    } = _ref,
    otherProps = (0, _objectWithoutProperties2.default)(_ref, _excluded);
  var prefix = dataTestId ? "".concat(dataTestId, "-") : '';
  var shouldAppearInFront = (_variantProps$overflo = variantProps === null || variantProps === void 0 ? void 0 : variantProps.overflowVisible) !== null && _variantProps$overflo !== void 0 ? _variantProps$overflo : false;
  var isModalCentered = !!(variantProps !== null && variantProps !== void 0 && variantProps.isCentered);
  var width = _objectSpread({
    mobile: 'full',
    sm: 'auto'
  }, updatedWidth);
  var {
    isLessThanMd
  } = (0, _utils.useScreenSize)();
  return (0, _jsxRuntime.jsxs)(_react.Modal, _objectSpread(_objectSpread({
    "data-testid": "".concat(prefix, "ChakraModal"),
    preserveScrollBarGap: true,
    isCentered: isModalCentered,
    scrollBehavior: "inside",
    isOpen: isOpen,
    onClose: onClose
  }, otherProps), {}, {
    size: isLessThanMd && variantProps !== null && variantProps !== void 0 && variantProps.sizeSm ? 'full' : 'md',
    children: [(0, _jsxRuntime.jsx)(_react.ModalOverlay, _objectSpread({}, overlayStyles)), (0, _jsxRuntime.jsxs)(_react.ModalContent, _objectSpread(_objectSpread({
      containerProps: contentContainerStyles,
      "data-testid": "".concat(prefix, "ModalContent")
    }, modalContentStyle(shouldAppearInFront, isModalCentered, variantProps === null || variantProps === void 0 ? void 0 : variantProps.sizeSm, isLessThanMd)), {}, {
      width: otherProps.size ? 'auto' : width,
      children: [(0, _jsxRuntime.jsxs)(_react.ModalHeader, _objectSpread(_objectSpread(_objectSpread({}, modalHeaderStyle), externalHeaderStyles), {}, {
        "data-testid": "".concat(prefix, "ModalHeader"),
        children: [variantProps && (0, _jsxRuntime.jsx)(_react.Flex, _objectSpread(_objectSpread(_objectSpread({}, headerTitleStyle), variantProps === null || variantProps === void 0 ? void 0 : variantProps.externalTitleStyling), {}, {
          "data-testid": "".concat(prefix, "ModalTitle"),
          children: variantProps.title
        })), (0, _jsxRuntime.jsx)(_react.ModalCloseButton, _objectSpread(_objectSpread({
          "data-testid": "".concat(prefix, "ModalCloseButton")
        }, modalCloseButtonStyle), {}, {
          children: (0, _jsxRuntime.jsx)(_Icon.default, {
            svg: (0, _jsxRuntime.jsx)(_icons.Dismiss, {
              transform: "scale(1.1)"
            })
          })
        }))]
      })), (0, _jsxRuntime.jsx)(_react.Box, _objectSpread({}, delimiterModalStyle)), (0, _jsxRuntime.jsx)(_react.ModalBody, _objectSpread(_objectSpread({
        "data-testid": "".concat(prefix, "ModalBody")
      }, modalBodyStyle), {}, {
        children: (0, _jsxRuntime.jsx)(_react.Box, {
          overflow: "auto",
          maxH: "calc(100vh - 4rem)",
          children: children
        })
      }))]
    }))]
  }));
}
var modalBodyStyle = {
  overflow: 'auto',
  p: 0
};
var modalContentStyle = (overflowVisible, isModalCentered, sizeSm, isLessThanMd) => {
  return {
    maxW: 'auto',
    maxH: 'auto',
    overflow: overflowVisible ? 'visible' : 'auto',
    height: {
      mobile: 'full',
      sm: 'fit-content'
    },
    my: 0,
    borderRadius: {
      mobile: '0',
      sm: isLessThanMd && sizeSm ? 0 : '0.875rem'
    },
    boxShadow: '0 0.125rem 0.75rem var(--chakra-colors-darkGrey2)',
    p: 0,
    top: isModalCentered ? 0 : {
      mobile: 0,
      sm: sizeSm ? 0 : '2.938rem',
      md: '3.75rem',
      xl: '6.375rem'
    }
  };
};
var modalHeaderStyle = {
  minH: 'var(--chakra-space-xxl)',
  display: 'grid',
  gridTemplateColumns: '1fr auto',
  alignItems: 'center',
  py: 'sm',
  px: {
    mobile: 'md',
    sm: 'lg'
  }
};
var delimiterModalStyle = {
  mx: {
    mobile: 'md',
    sm: 'lg'
  },
  h: '0.063rem',
  bgColor: 'lightGrey4'
};
var headerTitleStyle = {
  ml: 'xl',
  color: 'darkGrey1',
  justify: 'center',
  justifyContent: 'center',
  fontSize: 'lg',
  lineHeight: '3',
  fontFamily: 'header',
  padding: 0
};
var modalCloseButtonStyle = {
  h: 'var(--chakra-space-xl)',
  w: 'var(--chakra-space-xl)',
  position: 'static',
  alignSelf: 'flex-end',
  fontSize: 'xs',
  lineHeight: '3',
  fontWeight: '400',
  color: 'darkGrey2',
  _focus: {
    boxShadow: 'none'
  },
  _hover: {
    bgColor: 'transparent'
  },
  _active: {
    bgColor: 'transparent'
  }
};