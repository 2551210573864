"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.GET_FORMATTED_ADDRESS = void 0;
var _taggedTemplateLiteral2 = _interopRequireDefault(require("@babel/runtime/helpers/taggedTemplateLiteral"));
var _graphqlRequest = require("graphql-request");
var _templateObject;
var GET_FORMATTED_ADDRESS = exports.GET_FORMATTED_ADDRESS = (0, _graphqlRequest.gql)(_templateObject || (_templateObject = (0, _taggedTemplateLiteral2.default)(["\n  query formattedAddress($identifier: String!) {\n    formattedAddress(identifier: $identifier) {\n      companyName\n      addressLine1\n      addressLine2\n      addressLine3\n      addressLine4\n      companyName\n      label\n      postalCode\n      country\n    }\n  }\n"])));