"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.GuaranteeCodes = void 0;
var GuaranteeCodes = exports.GuaranteeCodes = function (GuaranteeCodes) {
  GuaranteeCodes["PAY_NOW"] = "DRV";
  GuaranteeCodes["PAY_ON_ARRIVAL"] = "CC";
  GuaranteeCodes["RESERVE_WITHOUT_CARD"] = "NON";
  GuaranteeCodes["ACCOUNT_TO_COMPANY"] = "CO";
  return GuaranteeCodes;
}({});