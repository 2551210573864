"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = LaunchEckoh;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _react = require("@chakra-ui/react");
var _api = require("@whitbread-eos/api");
var _atoms = require("@whitbread-eos/atoms");
var _utils = require("@whitbread-eos/utils");
var _nextI18next = require("next-i18next");
var _config = _interopRequireDefault(require("next/config"));
var _router = require("next/router");
var _react2 = require("react");
var _jsxRuntime = require("react/jsx-runtime");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function LaunchEckoh(_ref) {
  var {
    onSuccess,
    onFail,
    initiateIframe,
    eckohParameters,
    eckohStatus,
    setIsEnabledEckohQuery,
    disabledEckoh,
    onIframeLoad
  } = _ref;
  var {
    publicRuntimeConfig = {}
  } = (0, _config.default)() || {};
  var {
    t
  } = (0, _nextI18next.useTranslation)('common');
  var router = (0, _router.useRouter)();
  var [isConfirmOpen, setIsConfirmOpen] = (0, _react2.useState)(false);
  var [isSuccess, setIsSuccess] = (0, _react2.useState)(false);
  var [isCancelOpen, setIsCancelOpen] = (0, _react2.useState)(false);
  var [isOpened, setIsOpened] = (0, _react2.useState)(false);
  var timerRef = (0, _react2.useRef)(null);
  var baseTestId = 'launchEckoh';
  var {
    language,
    country
  } = (0, _utils.useCustomLocale)();
  (0, _react2.useEffect)(() => {
    switch (eckohStatus) {
      case _api.EckohStatus.FAILED:
        setIsOpened(false);
        setIsCancelOpen(true);
        onFail();
        setIsEnabledEckohQuery(false);
        break;
      case _api.EckohStatus.NOT_FOUND:
        onFail();
        setIsEnabledEckohQuery(false);
        break;
      case _api.EckohStatus.PENDING:
        onFail();
        setIsEnabledEckohQuery(true);
        setIsSuccess(false);
        break;
      case _api.EckohStatus.SUCCESS:
        onSuccess();
        setIsEnabledEckohQuery(false);
        setIsSuccess(true);
        break;
    }
  }, [eckohStatus]);
  (0, _react2.useEffect)(() => {
    if (eckohParameters) {
      setTimeout(showIframe);
    }
  }, [eckohParameters]);
  (0, _react2.useEffect)(() => {
    if (isOpened && timerRef.current === null) {
      timerRef.current = (0, _utils.timeTrackerSeconds)();
    } else {
      timerRef.current = null;
    }
  }, [isOpened]);
  var WB_ECKOH_IFRAME = 'wb-echo-iframe';
  var onLoad = () => {
    if (timerRef.current) {
      onIframeLoad(timerRef.current());
    }
  };
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_react.Box, {
      mt: 16,
      children: (0, _jsxRuntime.jsx)(_atoms.Button, _objectSpread(_objectSpread({
        size: "sm",
        variant: "tertiary",
        isDisabled: disabledEckoh,
        onClick: () => {
          setIsOpened(true);
          initiateIframe();
        },
        "data-testid": "".concat(baseTestId, "_launchButton")
      }, eckohButtonStyles), {}, {
        children: t('ccui.eckoh.button.title')
      }))
    }), (0, _jsxRuntime.jsx)(_atoms.ModalVariants, {
      onClose: () => {
        setIsOpened(false);
        setIsCancelOpen(!isSuccess);
      },
      closeOnOverlayClick: false,
      isOpen: isOpened,
      variant: "info",
      dataTestId: "".concat(baseTestId),
      variantProps: {
        title: 'Eckoh Iframe',
        delimiter: true
      },
      children: (0, _jsxRuntime.jsx)("iframe", {
        name: WB_ECKOH_IFRAME,
        width: "900",
        height: "405",
        onLoad: onLoad,
        title: "eckoh iframe"
      })
    }), (0, _jsxRuntime.jsx)(_atoms.ModalVariants, {
      variant: "cookie",
      isOpen: isCancelOpen,
      closeOnOverlayClick: false,
      onClose: () => setIsCancelOpen(false),
      variantProps: {
        title: t('ccui.eckoh.cancelBooking.title')
      },
      dataTestId: "".concat(baseTestId, "_cancel_booking"),
      hasAutoFocus: false,
      children: (0, _jsxRuntime.jsxs)(_react.Flex, _objectSpread(_objectSpread({}, modalWrapperStyles), {}, {
        children: [(0, _jsxRuntime.jsx)(_react.Flex, {
          color: "darkGrey1",
          children: t('ccui.eckoh.cancelBooking.description')
        }), (0, _jsxRuntime.jsxs)(_react.Flex, _objectSpread(_objectSpread({}, modalButtonWrapperStyles), {}, {
          children: [(0, _jsxRuntime.jsx)(_atoms.Button, {
            size: "md",
            variant: "primary",
            mr: "1rem",
            onClick: () => setIsCancelOpen(false),
            "data-testid": "".concat(baseTestId, "_retryButton"),
            children: t('ccui.eckoh.cancelBooking.retryButton')
          }), (0, _jsxRuntime.jsx)(_atoms.Button, {
            size: "md",
            variant: "tertiary",
            ml: "1rem",
            onClick: () => {
              setIsCancelOpen(false);
              setIsConfirmOpen(true);
            },
            "data-testid": "".concat(baseTestId, "_cancelButton"),
            children: t('ccui.eckoh.cancelBooking.cancelButton')
          })]
        }))]
      }))
    }), (0, _jsxRuntime.jsx)(_atoms.ModalVariants, {
      variant: "cookie",
      isOpen: isConfirmOpen,
      closeOnOverlayClick: false,
      onClose: () => setIsConfirmOpen(false),
      variantProps: {
        title: t('ccui.eckoh.confirmCancelBooking.title')
      },
      dataTestId: "".concat(baseTestId, "_confirm"),
      headerStyles: {
        maxW: '33rem'
      },
      hasAutoFocus: false,
      children: (0, _jsxRuntime.jsx)(_react.Flex, _objectSpread(_objectSpread({}, modalWrapperStyles), {}, {
        children: (0, _jsxRuntime.jsxs)(_react.Flex, _objectSpread(_objectSpread({}, modalButtonWrapperConfirmStyles), {}, {
          children: [(0, _jsxRuntime.jsx)(_atoms.Button, {
            size: "md",
            variant: "primary",
            mr: "1rem",
            onClick: () => setIsConfirmOpen(false),
            "data-testid": "".concat(baseTestId, "_confirm_retryButton"),
            children: t('ccui.eckoh.confirmCancelBooking.retryButton')
          }), (0, _jsxRuntime.jsx)(_atoms.Button, {
            size: "md",
            variant: "tertiary",
            ml: "1rem",
            onClick: () => {
              setIsConfirmOpen(false);
              router.push("/".concat(country, "/").concat(language));
            },
            "data-testid": "".concat(baseTestId, "_confirm_cancelButton"),
            children: t('ccui.eckoh.confirmCancelBooking.cancelButton')
          })]
        }))
      }))
    })]
  });
  function showIframe() {
    var form = document.createElement('form');
    form.action = publicRuntimeConfig.ECKOH_IFRAME_SRC;
    form.method = 'POST';
    form.target = WB_ECKOH_IFRAME;
    for (var prop in eckohParameters) {
      var input = document.createElement('input');
      input.type = 'hidden';
      input.name = prop;
      input.value = eckohParameters[prop];
      form.appendChild(input);
    }
    document.body.appendChild(form);
    form.submit();
  }
}
var modalWrapperStyles = {
  mt: 'md',
  mb: 'lg',
  mx: 'lg',
  w: {
    mobile: 'full',
    xs: 'full',
    sm: '25.063rem',
    md: '24.5rem',
    lg: '34.5rem',
    xl: '33rem'
  },
  flexDirection: 'column'
};
var eckohButtonStyles = {
  w: {
    mobile: 'full',
    xs: 'full',
    sm: '25.063rem',
    md: '18rem',
    lg: '18rem',
    xl: '19.313rem'
  },
  height: '3.5rem'
};
var modalButtonWrapperStyles = {
  mt: 'lg',
  justifyContent: 'space-around',
  alignItems: 'center'
};
var modalButtonWrapperConfirmStyles = {
  mt: 2,
  justifyContent: 'space-around',
  alignItems: 'center'
};