"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.listStyles = exports.listItemStyles = exports.linkStyles = exports.headingStyles = exports.businessListStyles = exports.boxStyles = void 0;
var listItemStyles = exports.listItemStyles = {
  w: '48',
  mb: '1',
  _focusVisible: {
    outline: 'none'
  },
  _hover: {
    a: {
      textDecoration: 'underline'
    }
  }
};
var businessListStyles = exports.businessListStyles = {
  w: '48',
  fontWeight: 'medium'
};
var listStyles = exports.listStyles = {
  w: '48',
  mr: '5',
  color: 'darkGrey1'
};
var headingStyles = exports.headingStyles = {
  _focusVisible: {
    outline: 'none'
  },
  fontSize: 'md',
  lineHeight: 3,
  mb: 'sm',
  fontWeight: 'semibold'
};
var linkStyles = exports.linkStyles = {
  cursor: 'pointer',
  _hover: {
    textDecoration: 'underline'
  },
  _focusVisible: {
    outline: 'none'
  }
};
var boxStyles = exports.boxStyles = {
  mr: {
    lg: '1.875rem',
    xl: '1.625rem'
  },
  _focusVisible: {
    outline: 'none'
  }
};