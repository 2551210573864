"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "getEmployeesQuery", {
  enumerable: true,
  get: function get() {
    return _getEmployees.getEmployeesQuery;
  }
});
Object.defineProperty(exports, "getUserManagementLabels", {
  enumerable: true,
  get: function get() {
    return _getUserManagementLabels.getUserManagementLabels;
  }
});
var _getEmployees = require("./getEmployees");
var _getUserManagementLabels = require("./getUserManagementLabels");