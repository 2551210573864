"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = HeaderVariantDefault;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _react = require("@chakra-ui/react");
var _api = require("@whitbread-eos/api");
var _atoms = require("@whitbread-eos/atoms");
var _utils = require("@whitbread-eos/utils");
var _react2 = _interopRequireWildcard(require("react"));
var _helpers = require("../../helpers/helpers");
var _HeaderLeisure = require("../HeaderLeisure.style");
var _NavigationMenu = _interopRequireDefault(require("../NavigationMenu"));
var _NavigationMenuMobile = _interopRequireDefault(require("../NavigationMenuMobile"));
var _jsxRuntime = require("react/jsx-runtime");
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && {}.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function HeaderVariantDefault(_ref) {
  var _content$header;
  var {
    headerInfoData,
    hotelBrand
  } = _ref;
  var {
    content,
    config
  } = headerInfoData;
  var {
    language: currentLanguage,
    country: currentCountry
  } = (0, _utils.useCustomLocale)();
  var isWindowDefined = typeof window !== 'undefined';
  var [origin, setOrigin] = (0, _react2.useState)('');
  var [tickIcon, setTickIcon] = (0, _react2.useState)((0, _jsxRuntime.jsx)(_jsxRuntime.Fragment, {}));
  var [isLoading, setIsLoading] = (0, _react2.useState)(true);
  var countries = content === null || content === void 0 ? void 0 : content.countries;
  (0, _react2.useEffect)(() => {
    setOrigin(window.location.origin);
  }, [isWindowDefined]);
  var [languagesList, setLanguagesList] = (0, _react2.useState)([]);
  var [navigationLabels, setNavigationLabels] = (0, _react2.useState)({
    business: '',
    businessSubNav: {
      title: '',
      navOptions: [{
        title: '',
        url: ''
      }]
    },
    logIn: '',
    logOut: '',
    findBooking: '',
    discoverPI: '',
    subNav: [{
      title: '',
      navOptions: [{
        title: '',
        url: ''
      }]
    }],
    accountLinks: [],
    mobileMenuButton: '',
    language: ''
  });
  (0, _react2.useEffect)(() => {
    if (countries !== null && countries !== void 0 && countries.length) {
      var _content$menu, _content$menu2, _content$menu3, _content$menu4, _content$menu5, _content$authenticati, _config$authenticatio, _config$authenticatio2, _content$menu6, _content$menu7;
      setLanguagesList((0, _helpers.getListOfLanguagesForSwitcher)(countries));
      setTickIcon((0, _jsxRuntime.jsx)(_atoms.Icon, {
        src: (0, _utils.formatAssetsUrl)(content === null || content === void 0 || (_content$menu = content.menu) === null || _content$menu === void 0 ? void 0 : _content$menu.tick),
        color: "var(--chakra-colors-primary)"
      }));
      setIsLoading(false);
      setNavigationLabels({
        discoverPI: content === null || content === void 0 || (_content$menu2 = content.menu) === null || _content$menu2 === void 0 ? void 0 : _content$menu2.discoverPI,
        business: content === null || content === void 0 || (_content$menu3 = content.menu) === null || _content$menu3 === void 0 ? void 0 : _content$menu3.business,
        businessSubNav: getBusinessOptions(content === null || content === void 0 ? void 0 : content.subNav),
        findBooking: content === null || content === void 0 || (_content$menu4 = content.menu) === null || _content$menu4 === void 0 ? void 0 : _content$menu4.findBooking,
        logIn: content === null || content === void 0 || (_content$menu5 = content.menu) === null || _content$menu5 === void 0 ? void 0 : _content$menu5.logIn,
        logOut: content === null || content === void 0 || (_content$authenticati = content.authentication) === null || _content$authenticati === void 0 ? void 0 : _content$authenticati.logoutButton,
        subNav: filterBusinessOptions(content === null || content === void 0 ? void 0 : content.subNav),
        accountLinks: (_config$authenticatio = config === null || config === void 0 || (_config$authenticatio2 = config.authentication) === null || _config$authenticatio2 === void 0 ? void 0 : _config$authenticatio2.accountLinks) !== null && _config$authenticatio !== void 0 ? _config$authenticatio : [],
        mobileMenuButton: content === null || content === void 0 || (_content$menu6 = content.menu) === null || _content$menu6 === void 0 ? void 0 : _content$menu6.mobileMenuButton,
        language: content === null || content === void 0 || (_content$menu7 = content.menu) === null || _content$menu7 === void 0 ? void 0 : _content$menu7.language
      });
    }
  }, [content, config]);
  return (0, _jsxRuntime.jsx)(_react.Box, _objectSpread(_objectSpread({}, customHeaderWrapperStyles), {}, {
    "data-testid": "common-header-wrapper",
    children: (0, _jsxRuntime.jsx)(_react.Container, _objectSpread(_objectSpread({}, _HeaderLeisure.containerWrapperStyles), {}, {
      children: (0, _jsxRuntime.jsxs)(_react.Grid, _objectSpread(_objectSpread({}, _HeaderLeisure.contentStyles), {}, {
        sx: {
          '@media print': {
            py: 'sm'
          }
        },
        children: [(0, _jsxRuntime.jsx)(_react.Flex, _objectSpread(_objectSpread({}, _HeaderLeisure.containerLogoStyle), {}, {
          "data-testid": "logo-container",
          children: !isLoading && (0, _jsxRuntime.jsx)(_atoms.Logo, {
            href: "".concat(origin, "/").concat(currentCountry, "/").concat(currentLanguage, "/home.html"),
            src: (0, _utils.formatAssetsUrl)(content === null || content === void 0 || (_content$header = content.header) === null || _content$header === void 0 ? void 0 : _content$header.image),
            variant: hotelBrand
          })
        })), (0, _jsxRuntime.jsxs)(_react.Grid, {
          templateColumns: "1fr auto",
          display: {
            mobile: 'none',
            lg: 'grid'
          },
          sx: {
            '@media print': {
              display: 'none'
            }
          },
          children: [!isLoading && (0, _jsxRuntime.jsx)(_atoms.LanguageSelectorSwitcher, {
            currentLanguage: currentLanguage,
            languagesList: languagesList,
            prefixDataTestId: "pi",
            tickIcon: tickIcon,
            area: _api.Area.PI
          }), (0, _jsxRuntime.jsx)(_NavigationMenu.default, {
            labels: navigationLabels
          })]
        }), (0, _jsxRuntime.jsx)(_react.Grid, {
          display: {
            mobile: 'grid',
            lg: 'none'
          },
          justifyContent: 'end',
          children: !isLoading && (0, _jsxRuntime.jsx)(_NavigationMenuMobile.default, {
            currentLanguage: currentLanguage,
            languagesList: languagesList,
            tickIcon: tickIcon,
            area: _api.Area.PI,
            labels: navigationLabels
          })
        })]
      }))
    }))
  }));
  function getBusinessOptions(list) {
    var _list$find;
    if (!list) {
      return {
        title: '',
        navOptions: [{
          title: '',
          url: ''
        }]
      };
    }
    return (_list$find = list.find(item => item.title.includes('Business'))) !== null && _list$find !== void 0 ? _list$find : {
      title: '',
      navOptions: [{
        title: '',
        url: ''
      }]
    };
  }
  function filterBusinessOptions(list) {
    if (!list) {
      return [];
    }
    return list.filter(item => !item.title.includes('Business') && item.title.trim().length > 0);
  }
}
var customHeaderWrapperStyles = _objectSpread(_objectSpread({}, _HeaderLeisure.headerWrapperStyles), {}, {
  h: {
    mobile: 'var(--chakra-space-4xl)',
    lg: 'var(--chakra-space-6xl)'
  }
});