"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = GuestInputs;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _objectWithoutProperties2 = _interopRequireDefault(require("@babel/runtime/helpers/objectWithoutProperties"));
var _react = require("@chakra-ui/react");
var _atoms = require("@whitbread-eos/atoms");
var _utils = require("@whitbread-eos/utils");
var _nextI18next = require("next-i18next");
var _reactHookForm = require("react-hook-form");
var _LeadGuestDetails = require("../LeadGuestDetails/LeadGuestDetails.component");
var _jsxRuntime = require("react/jsx-runtime");
var _excluded = ["onChange"];
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function GuestInputs(_ref) {
  var _errors$titleText2, _errors$titleText3;
  var {
    control,
    formField,
    errors,
    index,
    fieldsRequired = true,
    showIcon = false,
    prefix = '',
    inputStyle = {},
    dropdownStyle = {}
  } = _ref;
  var {
    name
  } = formField;
  var {
    t
  } = (0, _nextI18next.useTranslation)();
  var titleText = "".concat(prefix, "title");
  var firstNameText = "".concat(prefix, "firstName");
  var lastNameText = "".concat(prefix, "lastName");
  var firstNameLabel = fieldsRequired ? t('booking.leadGuest.FirstName') : t('booking.leadGuest.FirstName').replace(/\s\*$/, '');
  var lastNameLabel = fieldsRequired ? t('booking.leadGuest.LastName') : t('booking.leadGuest.LastName').replace(/\s\*$/, '');
  var renderDropdownTitle = field => {
    var _errors$titleText;
    var {
        onChange: _onChange
      } = field,
      restOfField = (0, _objectWithoutProperties2.default)(field, _excluded);
    return (0, _jsxRuntime.jsx)(_atoms.Dropdown, _objectSpread(_objectSpread({}, restOfField), {}, {
      showStatusIcon: showIcon,
      onChange: o => _onChange(o === null || o === void 0 ? void 0 : o.id),
      options: formField.dropdownOptions,
      placeholder: t('booking.leadGuest.title'),
      matchWidth: true,
      hasError: Boolean(errors === null || errors === void 0 || (_errors$titleText = errors[titleText]) === null || _errors$titleText === void 0 ? void 0 : _errors$titleText.message),
      dataTestId: (0, _utils.formatDataTestId)(formField.testid, "".concat(prefix, "TitleDropdown")),
      selectedId: field.value,
      dropdownStyles: {
        menuListStyles: {
          zIndex: 999
        },
        menuButtonStyles: _objectSpread({}, dropdownStyle)
      }
    }));
  };
  return (0, _jsxRuntime.jsxs)(_react.Box, {
    "data-testid": (0, _utils.formatDataTestId)(formField.testid, 'InputsContainer'),
    children: [(0, _jsxRuntime.jsxs)(_react.Box, _objectSpread(_objectSpread({}, inputStyle), {}, {
      children: [(0, _jsxRuntime.jsx)(_reactHookForm.Controller, {
        name: "".concat(name, "[").concat(index, "][").concat(titleText, "]"),
        control: control,
        render: _ref2 => {
          var {
            field
          } = _ref2;
          return renderDropdownTitle(field);
        }
      }), (errors === null || errors === void 0 || (_errors$titleText2 = errors[titleText]) === null || _errors$titleText2 === void 0 ? void 0 : _errors$titleText2.message) && (0, _jsxRuntime.jsx)(_react.Box, _objectSpread(_objectSpread({}, _LeadGuestDetails.formErrorStyles), {}, {
        dataTestId: (0, _utils.formatDataTestId)(formField.testid, 'ErrorContainer'),
        children: (0, _jsxRuntime.jsx)(_react.Box, _objectSpread(_objectSpread({}, _LeadGuestDetails.errorMessageStyles), {}, {
          children: (0, _jsxRuntime.jsx)(_react.Text, _objectSpread(_objectSpread({}, _LeadGuestDetails.errorTextStyles), {}, {
            dataTestId: (0, _utils.formatDataTestId)(formField.testid, 'ErrorText'),
            children: errors === null || errors === void 0 || (_errors$titleText3 = errors[titleText]) === null || _errors$titleText3 === void 0 ? void 0 : _errors$titleText3.message
          }))
        }))
      }))]
    })), (0, _jsxRuntime.jsx)(_react.Box, _objectSpread(_objectSpread({}, inputStyle), {}, {
      children: (0, _jsxRuntime.jsx)(_reactHookForm.Controller, {
        name: "".concat(name, "[").concat(index, "][").concat(firstNameText, "]"),
        control: control,
        render: _ref3 => {
          var _errors$firstNameText;
          var {
            field
          } = _ref3;
          return (0, _jsxRuntime.jsx)(_atoms.Input, _objectSpread(_objectSpread(_objectSpread({}, formField.props), field), {}, {
            type: "text",
            placeholderText: firstNameLabel,
            label: firstNameLabel,
            error: errors === null || errors === void 0 || (_errors$firstNameText = errors[firstNameText]) === null || _errors$firstNameText === void 0 ? void 0 : _errors$firstNameText.message,
            className: "sessioncamhidetext assist-no-show",
            dataTestId: (0, _utils.formatDataTestId)(formField.testid, "".concat(prefix, "FirstName"))
          }));
        }
      })
    })), (0, _jsxRuntime.jsx)(_react.Box, _objectSpread(_objectSpread({}, inputStyle), {}, {
      children: (0, _jsxRuntime.jsx)(_reactHookForm.Controller, {
        name: "".concat(name, "[").concat(index, "][").concat(lastNameText, "]"),
        control: control,
        render: _ref4 => {
          var _errors$lastNameText;
          var {
            field
          } = _ref4;
          return (0, _jsxRuntime.jsx)(_atoms.Input, _objectSpread(_objectSpread(_objectSpread({}, formField.props), field), {}, {
            type: "text",
            placeholderText: lastNameLabel,
            label: lastNameLabel,
            error: errors === null || errors === void 0 || (_errors$lastNameText = errors[lastNameText]) === null || _errors$lastNameText === void 0 ? void 0 : _errors$lastNameText.message,
            className: "sessioncamhidetext assist-no-show",
            dataTestId: (0, _utils.formatDataTestId)(formField.testid, "".concat(prefix, "LastName"))
          }));
        }
      })
    }))]
  });
}