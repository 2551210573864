"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = BartBookingDetailsExtras;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _react = require("@chakra-ui/react");
var _utils = require("@whitbread-eos/utils");
var _jsxRuntime = require("react/jsx-runtime");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function BartBookingDetailsExtras(_ref) {
  var {
    packages,
    language,
    t
  } = _ref;
  return (0, _jsxRuntime.jsxs)(_react.Flex, {
    direction: "column",
    children: [!!packages.length && (0, _jsxRuntime.jsx)(_react.Flex, {
      children: (0, _jsxRuntime.jsx)(_react.Text, _objectSpread(_objectSpread({}, titleStyle), {}, {
        children: t('ccui.manageBooking.meals')
      }))
    }), packages.map(extra => {
      var _extra$cost$currencyC, _extra$cost, _extra$cost$amount, _extra$cost2;
      return (0, _jsxRuntime.jsxs)(_react.Flex, {
        justifyContent: "space-between",
        children: [(0, _jsxRuntime.jsx)(_react.Text, _objectSpread(_objectSpread({}, extrasNameStyle), {}, {
          children: extra.name
        })), (0, _jsxRuntime.jsxs)(_react.Flex, {
          flexDir: "column",
          children: [(0, _jsxRuntime.jsx)(_react.Text, _objectSpread(_objectSpread({}, priceStyle), {}, {
            children: (0, _utils.formatPrice)((0, _utils.formatCurrency)((_extra$cost$currencyC = extra === null || extra === void 0 || (_extra$cost = extra.cost) === null || _extra$cost === void 0 ? void 0 : _extra$cost.currencyCode) !== null && _extra$cost$currencyC !== void 0 ? _extra$cost$currencyC : ''), ((_extra$cost$amount = extra === null || extra === void 0 || (_extra$cost2 = extra.cost) === null || _extra$cost2 === void 0 ? void 0 : _extra$cost2.amount) !== null && _extra$cost$amount !== void 0 ? _extra$cost$amount : 0) * extra.days * extra.qty, language)
          })), (0, _jsxRuntime.jsx)(_react.Text, _objectSpread(_objectSpread({}, priceDescriptionStyle), {}, {
            children: "".concat(t('ccui.manageBooking.priceFor'), " ").concat(extra.days, "  ").concat(t('ccui.manageBooking.nights'), ", ").concat(extra.qty, " ").concat(t('ccui.manageBooking.guests'), " ")
          }))]
        })]
      }, extra.id);
    })]
  });
}
var priceDescriptionStyle = {
  fontWeight: 'normal',
  fontSize: 'sm',
  lineHeight: '2',
  color: 'darkGrey2',
  textAlign: 'right'
};
var priceStyle = {
  fontWeight: 'medium',
  fontSize: 'md',
  color: 'darkGrey2',
  lineHeight: '3',
  alignSelf: 'flex-end'
};
var extrasNameStyle = {
  color: 'darkGrey2',
  fontSize: 'md',
  lineHeight: '3',
  fontWeight: 'normal',
  w: {
    mobile: '7.25rem',
    sm: 'full'
  }
};
var titleStyle = {
  fontWeight: 'semibold',
  fontSize: 'md',
  lineHeight: '3',
  color: 'darkGrey1',
  as: 'span'
};