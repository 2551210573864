"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.GET_STAY_RULES_QUERY = void 0;
var _taggedTemplateLiteral2 = _interopRequireDefault(require("@babel/runtime/helpers/taggedTemplateLiteral"));
var _graphqlRequest = require("graphql-request");
var _templateObject;
var GET_STAY_RULES_QUERY = exports.GET_STAY_RULES_QUERY = (0, _graphqlRequest.gql)(_templateObject || (_templateObject = (0, _taggedTemplateLiteral2.default)(["\n  query getStayRules($channel: Channel!) {\n    maxNightsLimitation(channel: $channel) {\n      maxNights\n    }\n    maxRoomsLimitation(channel: $channel) {\n      maxRooms\n    }\n    maxArrivalDateLimitation(channel: $channel) {\n      maxArrivalDate\n    }\n  }\n"])));