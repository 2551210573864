"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _react = require("@chakra-ui/react");
var _atoms = require("@whitbread-eos/atoms");
var _utils = require("@whitbread-eos/utils");
var _components = require("./components");
var _jsxRuntime = require("react/jsx-runtime");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
var DpaStatus = _ref => {
  var {
    dpaStatus,
    onDpaPassed,
    onDpaOverride,
    onReuseDetails,
    t,
    baseDataTestId
  } = _ref;
  return (0, _jsxRuntime.jsx)(_react.Box, _objectSpread(_objectSpread({}, cardStyle), {}, {
    children: (0, _jsxRuntime.jsxs)(_react.Flex, {
      flexDir: "column",
      children: [(0, _jsxRuntime.jsx)(_react.Heading, _objectSpread(_objectSpread({}, headingStyle), {}, {
        "data-testid": (0, _utils.formatDataTestId)(baseDataTestId, 'Heading'),
        children: t('ccui.idv.dpaStatus.heading')
      })), (0, _jsxRuntime.jsx)(_react.Divider, {
        mt: "1rem",
        mb: "2rem"
      }), (0, _jsxRuntime.jsx)(_components.IdvRadio, {
        value: dpaStatus.dpaPassed,
        onChange: onDpaPassed,
        dataTestId: (0, _utils.formatDataTestId)(baseDataTestId, 'DpaPassed'),
        text: t('ccui.idv.dpaStatus.dpaPassed'),
        options: [{
          text: t('ccui.idv.dpaStatus.dpaPassedYes'),
          value: true
        }, {
          text: t('ccui.idv.dpaStatus.dpaPassedNo'),
          value: false
        }]
      }), (0, _jsxRuntime.jsx)(_react.Divider, {
        my: "1rem"
      }), (0, _jsxRuntime.jsx)(_components.IdvRadio, {
        value: dpaStatus.dpaOverride,
        onChange: onDpaOverride,
        dataTestId: (0, _utils.formatDataTestId)(baseDataTestId, 'DpaOverride'),
        text: t('ccui.idv.dpaStatus.dpaOverride'),
        options: [{
          text: t('ccui.idv.dpaStatus.dpaOverrideYes'),
          value: true
        }, {
          text: t('ccui.idv.dpaStatus.dpaOverrideNo'),
          value: false
        }]
      }), (0, _jsxRuntime.jsx)(_react.Divider, {
        my: "1rem"
      }), (0, _jsxRuntime.jsx)(_components.IdvKV, {
        text: t('ccui.idv.dpaStatus.eCnpPassword'),
        value: dpaStatus.eCnpPassword,
        dataTestId: (0, _utils.formatDataTestId)(baseDataTestId, 'ECnpPassword')
      }), onReuseDetails && (0, _jsxRuntime.jsx)(_atoms.Button, {
        size: "md",
        variant: "primary",
        onClick: onReuseDetails,
        disabled: !dpaStatus.dpaPassed,
        children: t('ccui.idv.dpaStatus.reuseDetails')
      })]
    })
  }));
};
var cardStyle = {
  w: {
    mobile: 'full',
    xs: 'full',
    sm: 'full',
    md: 'full',
    lg: '311px',
    xl: '333px'
  },
  border: '1px solid var(--chakra-colors-lightGrey4)',
  p: '1.5rem'
};
var headingStyle = {
  color: 'darkGrey2',
  fontSize: '1.25rem',
  lineHeight: '1.5rem',
  fontWeight: '700',
  fontFamily: 'Proxima Nova Sans, helvetica, arial, sans-serif'
};
var _default = exports.default = DpaStatus;