"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var React = _interopRequireWildcard(require("react"));
var _jsxRuntime = require("react/jsx-runtime");
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && {}.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
var SvgChoiceOfPillows = props => (0, _jsxRuntime.jsx)("svg", _objectSpread(_objectSpread({
  width: 22,
  height: 20,
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg"
}, props), {}, {
  children: (0, _jsxRuntime.jsx)("path", {
    fillRule: "evenodd",
    clipRule: "evenodd",
    d: "M19.403 7a2 2 0 0 1 1.959 2.402 20.321 20.321 0 0 0-.42 4.098c0 1.366.14 2.732.42 4.098A2 2 0 0 1 19.402 20H6.598a2 2 0 0 1-1.959-2.402c.246-1.2.384-2.399.414-3.598h1l-.002.114a21.402 21.402 0 0 1-.432 3.685 1 1 0 0 0 .862 1.194l.117.007h12.806a1 1 0 0 0 .98-1.201 21.323 21.323 0 0 1-.442-4.299c0-1.434.147-2.867.441-4.299A1 1 0 0 0 19.402 8H18a19.1 19.1 0 0 1-.052-1h1.455ZM7.995 14.001c-.031 1.487-.203 2.704-.52 3.657a.5.5 0 1 1-.95-.316c.281-.843.44-1.957.47-3.34h1Zm11.163-4.975a.5.5 0 0 1 .316.632C19.16 10.6 19 11.883 19 13.5s.16 2.9.474 3.842a.5.5 0 1 1-.948.316C18.172 16.6 18 15.216 18 13.5c0-1.716.173-3.1.526-4.158a.5.5 0 0 1 .632-.316ZM15.403 0a2 2 0 0 1 1.959 2.402 20.321 20.321 0 0 0-.42 4.098c0 1.366.14 2.732.42 4.098a2 2 0 0 1-1.824 2.397l-.135.005H2.597a2 2 0 0 1-1.959-2.402c.28-1.366.42-2.732.42-4.098 0-1.366-.14-2.732-.42-4.098A2 2 0 0 1 2.598 0h12.805Zm0 1H2.597a1 1 0 0 0-.98 1.201A21.32 21.32 0 0 1 2.06 6.5a21.32 21.32 0 0 1-.441 4.299 1 1 0 0 0 .862 1.194l.117.007h12.806a1 1 0 0 0 .98-1.201A21.323 21.323 0 0 1 15.94 6.5c0-1.434.147-2.867.441-4.299A1 1 0 0 0 15.402 1ZM3.474 2.342C3.827 3.4 4 4.784 4 6.5c0 1.716-.173 3.1-.526 4.158a.5.5 0 1 1-.948-.316C2.84 9.4 3 8.117 3 6.5s-.16-2.9-.474-3.842a.5.5 0 0 1 .948-.316Zm11.684-.316a.5.5 0 0 1 .316.632C15.16 3.6 15 4.883 15 6.5s.16 2.9.474 3.842a.5.5 0 1 1-.949.316C14.173 9.6 14 8.216 14 6.5c0-1.716.173-3.1.525-4.158a.5.5 0 0 1 .633-.316Z",
    fill: props.color || '#58595B'
  })
}));
var _default = exports.default = SvgChoiceOfPillows;