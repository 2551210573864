"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.paymentSteps = void 0;
var paymentSteps = exports.paymentSteps = function (paymentSteps) {
  paymentSteps["PAYMENT_DETAILS"] = "PAYMENT_DETAILS";
  paymentSteps["CARD_DETAILS"] = "CARD_DETAILS";
  return paymentSteps;
}({});