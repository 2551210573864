"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = BackToDetails;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _react = require("@chakra-ui/react");
var _atoms = require("@whitbread-eos/atoms");
var _utils = require("@whitbread-eos/utils");
var _nextI18next = require("next-i18next");
var _jsxRuntime = require("react/jsx-runtime");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function BackToDetails(_ref) {
  var {
    goBack,
    prefixDataTestId,
    isPaymentsErrorPage
  } = _ref;
  var {
    t
  } = (0, _nextI18next.useTranslation)(['common']);
  var baseTestId = (0, _utils.formatDataTestId)(prefixDataTestId, 'backToDetails');
  return (0, _jsxRuntime.jsx)(_react.Box, {
    mt: "xl",
    children: (0, _jsxRuntime.jsx)(_react.Flex, {
      "data-testid": "".concat(baseTestId, "_leisure"),
      children: renderBackToDetailsText()
    })
  });
  function handleClick() {
    goBack();
  }
  function renderBackToDetailsText() {
    return (0, _jsxRuntime.jsxs)(_react.Flex, _objectSpread(_objectSpread({
      "data-testid": "".concat(baseTestId, "_back-entire")
    }, backToDetailsStyle), {}, {
      onClick: handleClick,
      children: [(0, _jsxRuntime.jsx)(_react.Center, _objectSpread(_objectSpread({
        "data-testid": "".concat(baseTestId, "_back-arrow")
      }, chevronLeftStyle), {}, {
        children: (0, _jsxRuntime.jsx)(_atoms.ChevronLeft, {})
      })), isPaymentsErrorPage && (0, _jsxRuntime.jsx)(_react.Text, _objectSpread(_objectSpread({
        "data-testid": "".concat(baseTestId, "_back-text")
      }, backToDetailsTextStyle), {}, {
        children: t('ccui.paymentErrorPage.hyperlink.backToPaymentsPage')
      })), !isPaymentsErrorPage && (0, _jsxRuntime.jsx)(_react.Text, _objectSpread(_objectSpread({
        "data-testid": "".concat(baseTestId, "_back-text")
      }, backToDetailsTextStyle), {}, {
        children: t('booking.submitBox.backToYourDetails')
      }))]
    }));
  }
}
var chevronLeftStyle = {
  mx: 'sm',
  h: '1.5rem',
  w: '1.5rem'
};
var backToDetailsStyle = {
  mb: 'xl',
  alignItems: 'flex-start',
  cursor: 'pointer'
};
var backToDetailsTextStyle = {
  lineHeight: '3',
  fontWeight: 'semibold',
  fontSize: 'md'
};