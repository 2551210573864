"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.HotelBrand = void 0;
var HotelBrand = exports.HotelBrand = function (HotelBrand) {
  HotelBrand["PI"] = "PI";
  HotelBrand["HUB"] = "HUB";
  HotelBrand["ZIP"] = "ZIP";
  HotelBrand["PID"] = "PID";
  return HotelBrand;
}({});