"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = RestaurantMessage;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _react = require("@chakra-ui/react");
var _utils = require("@whitbread-eos/utils");
var _jsxRuntime = require("react/jsx-runtime");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function RestaurantMessage(_ref) {
  var {
    messageDescription,
    messageTitle,
    prefixDataTestId
  } = _ref;
  var baseDataTestId = (0, _utils.formatDataTestId)(prefixDataTestId, 'RestaurantMessage');
  return (0, _jsxRuntime.jsxs)(_react.Box, {
    "data-testid": (0, _utils.formatDataTestId)(baseDataTestId, 'Wrapper'),
    children: [(0, _jsxRuntime.jsx)(_react.Text, _objectSpread(_objectSpread({}, titleStyle), {}, {
      "data-testid": (0, _utils.formatDataTestId)(baseDataTestId, 'Title'),
      children: messageTitle
    })), (0, _jsxRuntime.jsx)(_react.Text, _objectSpread(_objectSpread({}, bodyStyle), {}, {
      "data-testid": (0, _utils.formatDataTestId)(baseDataTestId, 'Description'),
      children: messageDescription
    }))]
  });
}
var titleStyle = {
  as: 'h1',
  fontWeight: 'semibold',
  color: 'darkGrey1',
  fontSize: {
    mobile: '3xl',
    sm: '3xxl'
  },
  lineHeight: {
    mobile: '4',
    sm: '5'
  }
};
var bodyStyle = {
  mt: 'md',
  as: 'h6',
  color: 'darkGrey1',
  fontSize: 'md',
  fontWeight: 'normal',
  lineHeight: '3'
};