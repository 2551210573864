"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _react = require("react");
function useElementDimensions(elementSelector) {
  var [dimensions, setDimensions] = (0, _react.useState)({
    width: 0,
    height: 0
  });
  (0, _react.useEffect)(() => {
    var element = document.querySelector(elementSelector);
    if (!element) {
      return;
    }
    var updateDimensions = () => {
      setDimensions({
        width: element.offsetWidth,
        height: element.offsetHeight
      });
    };
    updateDimensions();
    var resizeObserver = new ResizeObserver(() => {
      updateDimensions();
    });
    resizeObserver.observe(element);
    return () => {
      resizeObserver.unobserve(element);
    };
  }, [elementSelector]);
  return dimensions;
}
var _default = exports.default = useElementDimensions;