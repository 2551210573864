"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = HotelGalleryQueryWrapper;
var _reactQuery = require("@tanstack/react-query");
var _HotelGallery = _interopRequireDefault(require("./HotelGallery.container"));
var _jsxRuntime = require("react/jsx-runtime");
function HotelGalleryQueryWrapper(_ref) {
  var {
    queryClient,
    thumbnailSectionHeight,
    mobile,
    isLessThanMd,
    isLessThanLg
  } = _ref;
  return (0, _jsxRuntime.jsx)(_reactQuery.QueryClientProvider, {
    client: queryClient,
    children: (0, _jsxRuntime.jsx)(_HotelGallery.default, {
      thumbnailSectionHeight,
      mobile,
      isLessThanMd,
      isLessThanLg
    })
  });
}