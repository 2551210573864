"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = NewPasswordDescription;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _react = require("@chakra-ui/react");
var _jsxRuntime = require("react/jsx-runtime");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function NewPasswordDescription(_ref) {
  var _labels$resetPassword, _labels$resetPassword2, _labels$resetPassword3, _labels$resetPassword4;
  var {
    labels
  } = _ref;
  return (0, _jsxRuntime.jsxs)(_react.Flex, _objectSpread(_objectSpread({}, containerStyles), {}, {
    children: [(0, _jsxRuntime.jsx)(_react.Text, _objectSpread(_objectSpread({}, descStyles), {}, {
      children: labels === null || labels === void 0 || (_labels$resetPassword = labels.resetPassword) === null || _labels$resetPassword === void 0 ? void 0 : _labels$resetPassword.criteriaDescription
    })), (0, _jsxRuntime.jsxs)(_react.UnorderedList, _objectSpread(_objectSpread({}, listStyles), {}, {
      children: [(0, _jsxRuntime.jsx)(_react.ListItem, {
        children: labels === null || labels === void 0 || (_labels$resetPassword2 = labels.resetPassword) === null || _labels$resetPassword2 === void 0 ? void 0 : _labels$resetPassword2.passwordRequirementsMin
      }), (0, _jsxRuntime.jsx)(_react.ListItem, {
        children: labels === null || labels === void 0 || (_labels$resetPassword3 = labels.resetPassword) === null || _labels$resetPassword3 === void 0 ? void 0 : _labels$resetPassword3.passwordRequirementsIdentical
      }), (0, _jsxRuntime.jsx)(_react.ListItem, {
        children: labels === null || labels === void 0 || (_labels$resetPassword4 = labels.resetPassword) === null || _labels$resetPassword4 === void 0 ? void 0 : _labels$resetPassword4.passwordRequirementsAllowed
      })]
    }))]
  }));
}
var containerStyles = {
  marginY: '2xl',
  flexDirection: 'column'
};
var descStyles = {
  color: 'darkGrey1',
  fontSize: 'md',
  fontWeight: 'normal'
};
var listStyles = {
  mt: 'md',
  lineHeight: '3',
  w: 'full',
  fontSize: 'sm',
  fontWeight: 500,
  gap: '.5rem'
};