"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = ChangeLogTable;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _react = require("@chakra-ui/react");
var _atoms = require("@whitbread-eos/atoms");
var _molecules = require("@whitbread-eos/molecules");
var _utils = require("@whitbread-eos/utils");
var _nextI18next = require("next-i18next");
var _react2 = require("react");
var _tableFields = require("./tableFields.enum");
var _jsxRuntime = require("react/jsx-runtime");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function ChangeLogTable(_ref) {
  var {
    columns,
    rows
  } = _ref;
  var {
    t
  } = (0, _nextI18next.useTranslation)();
  var [appliedFilters, setAppliedFilters] = (0, _react2.useState)(() => {
    return columns.filter(col => col.isFilterable).reduce((obj, item) => {
      return _objectSpread(_objectSpread({}, obj), {}, {
        [item.key]: []
      });
    }, {});
  });
  var columnsWithFilterData = (0, _react2.useMemo)(() => {
    return columns.map(column => {
      if (!column.isFilterable) {
        return _objectSpread({}, column);
      }
      var filterOptions = rows.reduce((acc, current) => {
        var _current$column$key;
        var value = (_current$column$key = current[column.key]) !== null && _current$column$key !== void 0 ? _current$column$key : '';
        if (value && !acc.includes(value)) {
          return [...acc, value];
        }
        return [...acc];
      }, []);
      return _objectSpread(_objectSpread({}, column), {}, {
        filterOptions
      });
    });
  }, [columns, rows]);
  var filteredRows = (0, _react2.useMemo)(() => {
    return rows.filter(row => {
      return Object.keys(appliedFilters).filter(key => {
        var _appliedFilters$key;
        return (_appliedFilters$key = appliedFilters[key]) === null || _appliedFilters$key === void 0 ? void 0 : _appliedFilters$key.length;
      }).every(key => {
        var _row$key;
        var rowValue = (_row$key = row[key]) !== null && _row$key !== void 0 ? _row$key : '';
        return rowValue && appliedFilters[key].includes(rowValue);
      });
    });
  }, [appliedFilters, rows]);
  var handleFiltersApplied = (selectedFilters, filterKey) => {
    if (appliedFilters[filterKey] !== undefined) {
      setAppliedFilters(current => {
        return _objectSpread(_objectSpread({}, current), {}, {
          [filterKey]: selectedFilters
        });
      });
    }
  };
  var filterChevron = (isPopoverOpen, columnKey) => {
    var chevronSvg = isPopoverOpen ? (0, _jsxRuntime.jsx)(_atoms.ChevronUp24, {}) : (0, _jsxRuntime.jsx)(_atoms.ChevronDown24, {});
    return (0, _jsxRuntime.jsx)(_react.Box, {
      as: "button",
      "data-testid": "FilterExxpand-".concat(columnKey),
      children: (0, _jsxRuntime.jsx)(_atoms.Icon, {
        svg: chevronSvg
      })
    });
  };
  var tableHeaderRow = (0, _jsxRuntime.jsx)(_react.Tr, {
    children: columnsWithFilterData.map(column => {
      var _column$filterOptions, _appliedFilters$colum;
      return (0, _jsxRuntime.jsx)(_react.Th, _objectSpread(_objectSpread({}, _objectSpread(_objectSpread({}, cellStyles), tableHeaderCellStyles)), {}, {
        children: (0, _jsxRuntime.jsxs)(_react.Flex, {
          alignItems: "center",
          gap: 2,
          children: [column.title, column.isFilterable && (0, _jsxRuntime.jsx)(_molecules.TableFilter, {
            popoverTrigger: filterChevron,
            filterKey: column.key,
            filterOptions: (_column$filterOptions = column.filterOptions) !== null && _column$filterOptions !== void 0 ? _column$filterOptions : [],
            selectedFilters: (_appliedFilters$colum = appliedFilters[column.key]) !== null && _appliedFilters$colum !== void 0 ? _appliedFilters$colum : [],
            onApplyFilters: handleFiltersApplied,
            externalStyles: {
              contentStyles
            }
          })]
        })
      }), column.key);
    })
  });
  var createTableCell = (column, row, rowIndex) => {
    var cellValue = column.render ? column.render(row) : row[column.key];
    return (0, _jsxRuntime.jsx)(_react.Td, _objectSpread(_objectSpread({}, tableCellStyles(column.key)), {}, {
      children: column.clampOverflowingText ? (0, _jsxRuntime.jsx)(ExpandableText, {
        value: cellValue,
        startingHeight: 48,
        postFixtestId: "col-".concat(column.key, "-row-").concat(rowIndex)
      }) : cellValue
    }), column.key);
  };
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_react.TableContainer, _objectSpread(_objectSpread({}, tableContainerStyles), {}, {
      children: (0, _jsxRuntime.jsxs)(_react.Table, {
        size: "sm",
        children: [(0, _jsxRuntime.jsx)(_react.Thead, _objectSpread(_objectSpread({}, headerTableStyle), {}, {
          children: tableHeaderRow
        })), (0, _jsxRuntime.jsx)(_react.Tbody, {
          children: filteredRows.map((row, rowIndex) => {
            return (0, _jsxRuntime.jsx)(_react.Tr, {
              children: columnsWithFilterData.map(column => createTableCell(column, row, rowIndex))
            }, rowIndex);
          })
        })]
      })
    })), !!rows.length && !filteredRows.length && (0, _jsxRuntime.jsx)(_atoms.Notification, {
      maxWidth: "full",
      variant: "info",
      status: "info",
      description: t('ccui.changeLogModal.noResults'),
      svg: (0, _jsxRuntime.jsx)(_atoms.Info, {}),
      wrapperStyles: notificationStyles
    })]
  });
}
var ExpandableText = _ref2 => {
  var {
    value,
    startingHeight,
    postFixtestId = ''
  } = _ref2;
  var {
    isOpen,
    onToggle
  } = (0, _react.useDisclosure)();
  var [hasOverflow, setHasOverflow] = (0, _react2.useState)(false);
  var [contentHeight, setContentHeight] = (0, _react2.useState)(startingHeight);
  var contentRef = (0, _react2.useRef)(null);
  (0, _react2.useLayoutEffect)(() => {
    var updateContentHeight = () => {
      if (contentRef.current) {
        var {
          scrollHeight
        } = contentRef.current;
        setContentHeight(Math.min(scrollHeight, startingHeight));
        setHasOverflow(scrollHeight > startingHeight);
      }
    };
    window.addEventListener('resize', updateContentHeight);
    updateContentHeight();
    return () => {
      window.removeEventListener('resize', updateContentHeight);
    };
  }, [contentRef, startingHeight, value]);
  return (0, _jsxRuntime.jsxs)(_react.HStack, {
    overflow: "hidden",
    height: isOpen ? 'fit-content' : "".concat(contentHeight, "px"),
    alignItems: "flex-start",
    children: [(0, _jsxRuntime.jsx)(_react.Box, {
      ref: contentRef,
      children: value
    }), hasOverflow && (0, _jsxRuntime.jsx)(_react.Box, {
      as: "button",
      onClick: onToggle,
      "data-testid": (0, _utils.formatDataTestId)('Expand-Collapse-Chevron', postFixtestId),
      children: (0, _jsxRuntime.jsx)(_atoms.Icon, {
        svg: isOpen ? (0, _jsxRuntime.jsx)(_atoms.ChevronUp24, {}) : (0, _jsxRuntime.jsx)(_atoms.ChevronDown24, {})
      })
    })]
  });
};
var headerTableStyle = {
  backgroundColor: 'lightGrey5'
};
var cellStyles = {
  borderBottom: 'var(--chakra-borders-1px)',
  borderColor: 'var(--chakra-colors-lightGrey2)',
  color: 'darkGrey1',
  fontWeight: 'normal',
  fontSize: {
    mobile: 'sm',
    md: 'md'
  },
  lineHeight: {
    mobile: '2',
    sm: '3'
  },
  padding: {
    mobile: 'var(--chakra-space-10) var(--chakra-space-1) var(--chakra-space-6)',
    xs: 'var(--chakra-space-8) var(--chakra-space-1) var(--chakra-space-6)',
    sm: 'var(--chakra-space-12) var(--chakra-space-1) var(--chakra-space-6)',
    md: 'var(--chakra-space-12) var(--chakra-space-4) var(--chakra-space-6)',
    lg: 'var(--chakra-space-6) var(--chakra-space-2) var(--chakra-space-6) var(--chakra-space-6)',
    xl: 'var(--chakra-space-6) var(--chakra-space-4) var(--chakra-space-6) var(--chakra-space-6)'
  },
  verticalAlign: 'top',
  _first: {
    paddingLeft: {
      lg: 'var(--chakra-space-10)',
      xl: 'var(--chakra-space-5xl)'
    }
  },
  _last: {
    paddingRight: {
      lg: ' var(--chakra-space-10)',
      xl: ' var(--chakra-space-5xl)'
    }
  }
};
var tableCellStyles = columnKey => {
  var cellWidthStyles = columnKey === _tableFields.TABLE_FIELDS.ACTION_DESCRIPTION ? {
    width: "33%"
  } : {};
  return _objectSpread(_objectSpread({}, cellStyles), cellWidthStyles);
};
var tableContainerStyles = {
  whiteSpace: 'normal'
};
var tableHeaderCellStyles = {
  color: 'darkGrey1',
  fontWeight: 'semibold',
  fontSize: {
    lg: 'lg'
  },
  textTransform: 'none',
  fontFamily: 'header',
  letterSpacing: 'initial'
};
var contentStyles = {
  width: {
    mobile: 'full',
    lg: '365px'
  },
  height: {
    mobile: 'full',
    lg: '536px'
  }
};
var notificationStyles = {
  width: 'auto',
  margin: {
    mobile: 'var(--chakra-space-10) var(--chakra-space-2)',
    lg: 'var(--chakra-space-10) var(--chakra-space-10)',
    xl: 'var(--chakra-space-10) var(--chakra-space-5xl)'
  }
};