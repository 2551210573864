"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.GET_TERMS_AND_CONDITIONS_QUERY = void 0;
var _taggedTemplateLiteral2 = _interopRequireDefault(require("@babel/runtime/helpers/taggedTemplateLiteral"));
var _graphqlRequest = require("graphql-request");
var _templateObject;
var GET_TERMS_AND_CONDITIONS_QUERY = exports.GET_TERMS_AND_CONDITIONS_QUERY = (0, _graphqlRequest.gql)(_templateObject || (_templateObject = (0, _taggedTemplateLiteral2.default)(["\n  query getTermsAndConditions(\n    $country: String!\n    $language: String!\n    $hotelId: String!\n    $rateCode: String!\n    $bookingChannel: String!\n  ) {\n    termsAndConditions(\n      bookingFlowCriteria: {\n        hotelId: $hotelId\n        language: $language\n        country: $country\n        rateCode: $rateCode\n        bookingChannel: $bookingChannel\n      }\n    ) {\n      text\n    }\n  }\n"])));