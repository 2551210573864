"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = HotelRoomContentComponent;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _react = require("@chakra-ui/react");
var _atoms = require("@whitbread-eos/atoms");
var _RoomFacilities = _interopRequireDefault(require("../Facilities/RoomFacilities"));
var _HotelRoomImages = _interopRequireDefault(require("../HotelRoomImages"));
var _jsxRuntime = require("react/jsx-runtime");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function HotelRoomContentComponent(_ref) {
  var {
    tabItems,
    isLessThanSm,
    isLessThanMd,
    isLessThanLg
  } = _ref;
  var roomNameStyles = {
    fontSize: {
      base: 'md',
      sm: '1.188rem'
    },
    lineHeight: 3,
    fontWeight: 'semibold'
  };
  return (0, _jsxRuntime.jsx)(_react.Flex, {
    overflowX: "auto",
    margin: -4,
    children: tabItems === null || tabItems === void 0 ? void 0 : tabItems.map((tabItem, idx) => (0, _jsxRuntime.jsxs)(_react.Flex, _objectSpread(_objectSpread({}, cardStyles(idx)), {}, {
      children: [(0, _jsxRuntime.jsx)(_react.Box, {
        flex: 1,
        children: (0, _jsxRuntime.jsx)(_HotelRoomImages.default, {
          images: tabItem === null || tabItem === void 0 ? void 0 : tabItem.images,
          thumbnailHeight: getThumbnailHeight(),
          isLessThanSm: isLessThanSm,
          isLessThanLg: isLessThanLg
        })
      }), (0, _jsxRuntime.jsxs)(_react.Box, {
        flex: 1,
        p: 6,
        children: [(0, _jsxRuntime.jsx)(_react.Text, _objectSpread(_objectSpread({}, roomNameStyles), {}, {
          "data-testid": "hdp_roomName-".concat(tabItem === null || tabItem === void 0 ? void 0 : tabItem.roomName),
          children: tabItem === null || tabItem === void 0 ? void 0 : tabItem.roomName
        })), (0, _jsxRuntime.jsx)(_react.Text, {
          mt: 2,
          mb: "lg",
          color: _atoms.theme.colors.darkGrey2,
          "data-testid": "hdp_roomDescription-".concat(tabItem === null || tabItem === void 0 ? void 0 : tabItem.roomName),
          children: tabItem === null || tabItem === void 0 ? void 0 : tabItem.roomDescription
        }), (0, _jsxRuntime.jsx)(_RoomFacilities.default, {
          facilities: tabItem.facilities,
          isLessThanSm: isLessThanSm,
          isLessThanMd: isLessThanMd
        })]
      })]
    }), tabItem === null || tabItem === void 0 ? void 0 : tabItem.roomName))
  });
  function cardStyles(tabItemIdx) {
    return {
      border: "1px solid ".concat(_atoms.theme.colors.lightGrey3),
      borderRadius: 'base',
      mt: 6,
      mb: 3,
      width: 'full',
      direction: {
        base: 'column',
        mobile: 'column',
        sm: 'column',
        md: 'column',
        lg: tabItems && tabItems.length > 1 ? 'column' : 'row',
        xl: tabItems && tabItems.length > 1 ? 'column' : 'row'
      },
      mr: tabItems && (tabItems === null || tabItems === void 0 ? void 0 : tabItems.length) > 1 && tabItemIdx < (tabItems === null || tabItems === void 0 ? void 0 : tabItems.length) - 1 ? 6 : 0,
      minWidth: getCardMinWidth(),
      maxWidth: {
        base: 'full',
        md: tabItems && (tabItems === null || tabItems === void 0 ? void 0 : tabItems.length) > 1 ? 'full' : '38.875rem',
        lg: 'full'
      }
    };
    function getCardMinWidth() {
      switch (true) {
        case tabItems && tabItems.length > 1 && tabItems.length <= 4:
          return {
            xl: "calc(100% / ".concat(tabItems === null || tabItems === void 0 ? void 0 : tabItems.length, " - 1.5rem + 1.5rem / ").concat(tabItems === null || tabItems === void 0 ? void 0 : tabItems.length, ")"),
            lg: "calc(100% / ".concat(tabItems === null || tabItems === void 0 ? void 0 : tabItems.length, " - 1.5rem + 1.5rem / ").concat(tabItems === null || tabItems === void 0 ? void 0 : tabItems.length, ")"),
            md: '39.813rem',
            sm: '29.438rem',
            mobile: '19.438rem',
            base: '16rem'
          };
        case tabItems && (tabItems === null || tabItems === void 0 ? void 0 : tabItems.length) > 4:
          return {
            xl: "calc(100% / 4 - 1.5rem + 1.5rem / 4)",
            lg: "calc(100% / 4 - 1.5rem + 1.5rem / 4)",
            md: '39.813rem',
            sm: '29.438rem',
            mobile: '19.438rem',
            base: '16rem'
          };
        default:
          return {
            xl: 'full',
            lg: 'full',
            md: '38.875rem',
            sm: 'full',
            mobile: 'full',
            base: 'full'
          };
      }
    }
  }
  function getThumbnailHeight() {
    switch (true) {
      case (tabItems === null || tabItems === void 0 ? void 0 : tabItems.length) === 2:
        return {
          xl: '22.563rem',
          lg: '21.125rem',
          md: '22.063rem',
          sm: '16.563rem',
          mobile: '10.625rem',
          base: '10.625rem'
        };
      case (tabItems === null || tabItems === void 0 ? void 0 : tabItems.length) === 3:
        return {
          xl: '14.75rem',
          lg: '13rem',
          md: '22.063rem',
          sm: '16.563rem',
          mobile: '10.625rem',
          base: '10.625rem'
        };
      case tabItems && (tabItems === null || tabItems === void 0 ? void 0 : tabItems.length) >= 4:
        return {
          xl: '10.875rem',
          lg: '10rem',
          md: '22.063rem',
          sm: '16.563rem',
          mobile: '10.625rem',
          base: '10.625rem'
        };
      default:
        return {
          xl: '22.563rem',
          lg: '22rem',
          md: '24rem',
          sm: '16.563rem',
          mobile: '10.625rem',
          base: '10.625rem'
        };
    }
  }
}