"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.GET_ACCOUNT_LIST = void 0;
var _taggedTemplateLiteral2 = _interopRequireDefault(require("@babel/runtime/helpers/taggedTemplateLiteral"));
var _graphqlRequest = require("graphql-request");
var _templateObject;
var GET_ACCOUNT_LIST = exports.GET_ACCOUNT_LIST = (0, _graphqlRequest.gql)(_templateObject || (_templateObject = (0, _taggedTemplateLiteral2.default)(["\n  query getAccountList($viewAll: Boolean) {\n    getAccountList(viewAll: $viewAll) {\n      accounts {\n        accountName\n        accountNumber\n        schemeCustomerId\n        tetheredGuid\n        registrationRoles\n        errorCode\n        scheme\n      }\n      totalRecordCount\n    }\n  }\n"])));