"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _react = require("@chakra-ui/react");
var _Alert = _interopRequireDefault(require("./components/Alert"));
var _Badge = _interopRequireDefault(require("./components/Badge"));
var _Breadcrumb = _interopRequireDefault(require("./components/Breadcrumb"));
var _Button = _interopRequireDefault(require("./components/Button"));
var _Checkbox = _interopRequireDefault(require("./components/Checkbox"));
var _Datepicker = _interopRequireDefault(require("./components/Datepicker"));
var _Drawer = _interopRequireDefault(require("./components/Drawer"));
var _Menu = _interopRequireDefault(require("./components/Menu"));
var _Radio = _interopRequireDefault(require("./components/Radio"));
var _Switch = _interopRequireDefault(require("./components/Switch"));
var _Tabs = _interopRequireDefault(require("./components/Tabs"));
var _Tooltip = _interopRequireDefault(require("./components/Tooltip"));
var _breakpoints = require("./foundations/breakpoints");
var _colors = require("./foundations/colors");
var _space = require("./foundations/space");
var _typography = require("./foundations/typography");
var _styles = _interopRequireDefault(require("./styles"));
var theme = {
  styles: _styles.default,
  colors: _colors.colors,
  breakpoints: _breakpoints.breakpoints,
  fonts: _typography.typography.fonts,
  fontSizes: _typography.typography.fontSizes,
  lineHeights: _typography.typography.lineHeights,
  space: _space.space,
  components: {
    Tabs: _Tabs.default,
    Button: _Button.default,
    Menu: _Menu.default,
    Alert: _Alert.default,
    Tooltip: _Tooltip.default,
    Switch: _Switch.default,
    Datepicker: _Datepicker.default,
    Drawer: _Drawer.default,
    Radio: _Radio.default,
    Badge: _Badge.default,
    Breadcrumb: _Breadcrumb.default,
    Checkbox: _Checkbox.default
  }
};
var _default = exports.default = (0, _react.extendTheme)(theme);