"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Area = void 0;
var Area = exports.Area = function (Area) {
  Area["PI"] = "pi";
  Area["CCUI"] = "ccui";
  Area["BB"] = "bb";
  return Area;
}({});