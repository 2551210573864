"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = HotelRoomsComponent;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _react = require("@chakra-ui/react");
var _api = require("@whitbread-eos/api");
var _atoms = require("@whitbread-eos/atoms");
var _nextI18next = require("next-i18next");
var _HotelRoomContent = _interopRequireDefault(require("../HotelRoomContent"));
var _jsxRuntime = require("react/jsx-runtime");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function HotelRoomsComponent(_ref) {
  var {
    isLoading,
    isError,
    data,
    error,
    isPremierInn,
    isLessThanSm,
    isLessThanMd,
    isLessThanLg,
    hotelInventoryResponse,
    roomTypeInformationResponse,
    isDisplayRates
  } = _ref;
  var {
    t
  } = (0, _nextI18next.useTranslation)(['common']);
  var headingStyles = {
    size: 'md',
    fontWeight: 'semibold',
    fontSize: {
      base: '2xl',
      md: '3xl'
    },
    lineHeight: '4'
  };
  var tabsStyles = () => ({
    '.chakra-tabs__tablist': {
      w: {
        xl: "calc(100% / 2 - 1.5rem + 1.5rem / 2)",
        lg: "calc(100% / 2 - 1.5rem + 1.5rem / 2)",
        md: '39.813rem',
        mobile: 'full'
      },
      borderBottom: 'none'
    },
    '.chakra-tabs__tab': {
      w: 'full',
      maxWidth: {
        sm: '15rem',
        xs: '8.4rem',
        mobile: '4.65rem'
      },
      borderBottom: '2px solid var(--chakra-colors-lightGrey2)',
      _selected: {
        fontWeight: 'semibold',
        borderBottom: '2px solid var(--chakra-colors-primary)'
      }
    },
    '.chakra-tabs__tab h4': {
      maxWidth: {
        sm: '10rem',
        xs: '5.5rem',
        mobile: '4.65rem'
      },
      textOverflow: {
        xs: 'inherit',
        mobile: 'ellipsis'
      },
      whiteSpace: {
        xs: 'normal',
        mobile: 'nowrap'
      },
      overflow: {
        xs: 'inherit',
        mobile: 'hidden'
      },
      fontSize: {
        sm: '1rem',
        mobile: '.9375rem'
      }
    }
  });
  return (0, _jsxRuntime.jsx)(_atoms.Section, {
    dataTestId: "hdp_ourRooms",
    children: (0, _jsxRuntime.jsxs)(_react.Box, {
      pb: "4",
      pt: "3xl",
      id: "hotel-details-our-rooms",
      children: [(0, _jsxRuntime.jsx)(_react.Heading, _objectSpread(_objectSpread({
        as: "h3",
        "data-testid": "hdp_ourRooms-title"
      }, headingStyles), {}, {
        children: t('hoteldetails.roomtypes.title')
      })), renderTabs(), renderSeeRatesButton(isDisplayRates)]
    })
  });
  function renderTabs() {
    var _data$tabGroups;
    if (isLoading) {
      return (0, _jsxRuntime.jsx)(_react.Text, {
        children: t('searchresults.list.hotel.loading')
      });
    }
    if (isError) {
      return (0, _jsxRuntime.jsx)(_react.Text, {
        children: error.message
      });
    }
    if (!data) {
      return null;
    }
    if (hotelInventoryResponse) {
      if (hotelInventoryResponse !== null && hotelInventoryResponse !== void 0 && hotelInventoryResponse.isLoadingHotelInventory) {
        return (0, _jsxRuntime.jsx)(_react.Text, {
          "data-testid": "hotel-inventory-loading-message",
          children: t('searchresults.list.hotel.loading')
        });
      }
      if (hotelInventoryResponse !== null && hotelInventoryResponse !== void 0 && hotelInventoryResponse.isErrorHotelInventory) {
        return (0, _jsxRuntime.jsx)(_react.Text, {
          children: hotelInventoryResponse.errorHotelInventory.message
        });
      }
    }
    if (roomTypeInformationResponse) {
      if (roomTypeInformationResponse !== null && roomTypeInformationResponse !== void 0 && roomTypeInformationResponse.isLoadingRoomTypeInformation) {
        return (0, _jsxRuntime.jsx)(_react.Text, {
          "data-testid": "room-types-loading-message",
          children: t('searchresults.list.hotel.loading')
        });
      }
      if (roomTypeInformationResponse !== null && roomTypeInformationResponse !== void 0 && roomTypeInformationResponse.isErrorRoomTypeInformation) {
        return (0, _jsxRuntime.jsx)(_react.Text, {
          children: roomTypeInformationResponse.errorRoomTypeInformation.message
        });
      }
    }
    return (0, _jsxRuntime.jsx)(_atoms.Tabs, {
      mt: 6,
      pt: 4,
      showRoomInventory: !isPremierInn,
      prefixDataTestId: "roomConfiguration",
      sx: _objectSpread({}, tabsStyles()),
      options: data === null || data === void 0 || (_data$tabGroups = data.tabGroups) === null || _data$tabGroups === void 0 ? void 0 : _data$tabGroups.filter(tabGroup => {
        var _data$tabItems;
        return data === null || data === void 0 || (_data$tabItems = data.tabItems) === null || _data$tabItems === void 0 ? void 0 : _data$tabItems.some(tabItem => (tabItem === null || tabItem === void 0 ? void 0 : tabItem.roomType) === (tabGroup === null || tabGroup === void 0 ? void 0 : tabGroup.groupId));
      }).map((tabGroup, index) => {
        var _tabGroup$groupName, _data$tabItems2, _tabGroup$groupId, _tabGroup$groupId2;
        return _objectSpread({
          index,
          label: (_tabGroup$groupName = tabGroup === null || tabGroup === void 0 ? void 0 : tabGroup.groupName) !== null && _tabGroup$groupName !== void 0 ? _tabGroup$groupName : '',
          content: (0, _jsxRuntime.jsx)(_HotelRoomContent.default, {
            tabItems: data === null || data === void 0 || (_data$tabItems2 = data.tabItems) === null || _data$tabItems2 === void 0 ? void 0 : _data$tabItems2.filter(tabItem => (tabItem === null || tabItem === void 0 ? void 0 : tabItem.roomType) === (tabGroup === null || tabGroup === void 0 ? void 0 : tabGroup.groupId)),
            isLessThanSm,
            isLessThanMd,
            isLessThanLg
          })
        }, !isPremierInn && {
          roomTypeInventoryCount: getTotalInventoryAvailCountPerTabRoomType((_tabGroup$groupId = tabGroup === null || tabGroup === void 0 ? void 0 : tabGroup.groupId) !== null && _tabGroup$groupId !== void 0 ? _tabGroup$groupId : ''),
          roomTypeInventoryRoomTypesWithCount: getInventoryRoomTypeLabelsWithCount((_tabGroup$groupId2 = tabGroup === null || tabGroup === void 0 ? void 0 : tabGroup.groupId) !== null && _tabGroup$groupId2 !== void 0 ? _tabGroup$groupId2 : '')
        });
      })
    });
  }
  function renderSeeRatesButton(isDisplayRates) {
    var seeRatesBtnStyles = {
      mt: 'sm',
      fontSize: 'md',
      height: 'var(--chakra-space-xl)',
      width: {
        mobile: 'full',
        sm: '48',
        lg: '40',
        xl: '12%'
      },
      display: isDisplayRates ? 'block' : 'none'
    };
    var handleClickScroll = () => {
      var rateCardElement = document.querySelector('[data-testid="hdp_rateCard"]');
      if (rateCardElement) {
        rateCardElement.scrollIntoView({
          behavior: 'smooth'
        });
      }
    };
    if (isPremierInn) {
      return (0, _jsxRuntime.jsx)(_atoms.Button, _objectSpread(_objectSpread({
        variant: "secondary",
        size: "md",
        "data-testid": "hdp_roomsSeeRates"
      }, seeRatesBtnStyles), {}, {
        onClick: handleClickScroll,
        children: t('hoteldetails.roomtypes.rates.button')
      }));
    }
  }
  function mapRoomTypeInfoToHotelInvViaRoomTypeCode(tabGroupId) {
    var _roomTypeInformationR;
    if (!(roomTypeInformationResponse !== null && roomTypeInformationResponse !== void 0 && roomTypeInformationResponse.dataRoomTypeInformation) || !(hotelInventoryResponse !== null && hotelInventoryResponse !== void 0 && hotelInventoryResponse.dataHotelInventory)) {
      return null;
    }
    var matchedRoomTypeCodes = roomTypeInformationResponse === null || roomTypeInformationResponse === void 0 || (_roomTypeInformationR = roomTypeInformationResponse.dataRoomTypeInformation) === null || _roomTypeInformationR === void 0 || (_roomTypeInformationR = _roomTypeInformationR.roomTypeInformation) === null || _roomTypeInformationR === void 0 ? void 0 : _roomTypeInformationR.roomTypes.filter(item => {
      var _hotelInventoryRespon;
      return hotelInventoryResponse === null || hotelInventoryResponse === void 0 || (_hotelInventoryRespon = hotelInventoryResponse.dataHotelInventory) === null || _hotelInventoryRespon === void 0 || (_hotelInventoryRespon = _hotelInventoryRespon.hotelInventory) === null || _hotelInventoryRespon === void 0 ? void 0 : _hotelInventoryRespon.roomTypeInventories.find(_ref2 => {
        var {
          code
        } = _ref2;
        return item.roomTypeCode === code || item.roomTypeCode.includes(code);
      });
    });
    var matchedRoomTypeGroupIdToTabsGroupId = matchedRoomTypeCodes === null || matchedRoomTypeCodes === void 0 ? void 0 : matchedRoomTypeCodes.filter(item => matchRoomTypeGroupIdToTabsGroupId(tabGroupId, item.roomTypeCode, item === null || item === void 0 ? void 0 : item.groupId));
    return matchedRoomTypeGroupIdToTabsGroupId;
  }
  function matchRoomTypeGroupIdToTabsGroupId(tabGroupId, roomTypeCode, roomTypeGroupId) {
    var _roomTypeGroupId;
    var doubleRoomClassRoomCodes = [_api.ROOM_TYPE.STANDARD, _api.ROOM_TYPE.STANDARD_BIGGER, _api.ROOM_TYPE.STANDARD_EXTRA, _api.ROOM_TYPE.PREMIER_PLUS];
    if ((doubleRoomClassRoomCodes.indexOf(String(roomTypeCode)) !== -1 || doubleRoomClassRoomCodes.some(el => roomTypeCode.indexOf(el) !== -1)) && roomTypeGroupId !== 'double') {
      roomTypeGroupId = 'double';
    }
    return (_roomTypeGroupId = roomTypeGroupId) === null || _roomTypeGroupId === void 0 ? void 0 : _roomTypeGroupId.toLowerCase().includes(tabGroupId.toLowerCase());
  }
  function getInventoryRoomTypeLabelsWithCount(tabGroupId) {
    var mappedRoomTypeDataPerTab = mapRoomTypeInfoToHotelInvViaRoomTypeCode(tabGroupId);
    var isFamilyRoom = tabGroupId.toLowerCase() === 'family' || tabGroupId.toLowerCase() === 'familie';
    if (!mappedRoomTypeDataPerTab) return null;
    if (isFamilyRoom) {
      var inventoryRoomTypeLabels = mappedRoomTypeDataPerTab.map(item => {
        var label = '';
        if (item.roomTypeCode === _api.ROOM_TYPE.FAMILY_QUAD || item.roomTypeCode.includes(_api.ROOM_TYPE.FAMILY_QUAD)) {
          label = t('hoteldetails.roomtypes.quad');
        }
        if (item.roomTypeCode === _api.ROOM_TYPE.FAMILY_TRIPLE || item.roomTypeCode.includes(_api.ROOM_TYPE.FAMILY_TRIPLE)) {
          label = t('hoteldetails.roomtypes.triple');
        }
        return label;
      });
      var inventoryAvailCountsPerRoomType = getInventoryAvailCountPerRoomType(tabGroupId);
      if (!inventoryAvailCountsPerRoomType) return null;
      var inventoryRoomTypeLabelsWithCount = inventoryRoomTypeLabels.map(function (item, i) {
        return item + " (".concat(inventoryAvailCountsPerRoomType[i], ") ");
      }).join('').toString();
      return inventoryRoomTypeLabelsWithCount;
    } else {
      var _inventoryRoomTypeLabelsWithCount = '';
      return _inventoryRoomTypeLabelsWithCount;
    }
  }
  function getInventoryAvailCountPerRoomType(tabGroupId) {
    var _hotelInventoryRespon2;
    if (!hotelInventoryResponse) return null;
    var mappedRoomTypeDataPerTab = mapRoomTypeInfoToHotelInvViaRoomTypeCode(tabGroupId);
    if (!mappedRoomTypeDataPerTab) return null;
    return hotelInventoryResponse === null || hotelInventoryResponse === void 0 || (_hotelInventoryRespon2 = hotelInventoryResponse.dataHotelInventory) === null || _hotelInventoryRespon2 === void 0 || (_hotelInventoryRespon2 = _hotelInventoryRespon2.hotelInventory) === null || _hotelInventoryRespon2 === void 0 ? void 0 : _hotelInventoryRespon2.roomTypeInventories.filter(item => mappedRoomTypeDataPerTab.find(_ref3 => {
      var {
        roomTypeCode
      } = _ref3;
      return item.code === roomTypeCode || roomTypeCode.includes(item.code);
    })).map(roomTypeMatch => roomTypeMatch.availableCount);
  }
  function getTotalInventoryAvailCountPerTabRoomType(tabGroupId) {
    var inventoryAvailCountsPerRoomType = getInventoryAvailCountPerRoomType(tabGroupId);
    if (!inventoryAvailCountsPerRoomType) return null;
    var totalInventoryAvailCountPerRoomType = inventoryAvailCountsPerRoomType.reduce((a, b) => a + b, 0) || 0;
    return totalInventoryAvailCountPerRoomType;
  }
}