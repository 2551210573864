"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = BookingSummaryStayDatesInformation;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _react = require("@chakra-ui/react");
var _utils = require("@whitbread-eos/utils");
var _jsxRuntime = require("react/jsx-runtime");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function BookingSummaryStayDatesInformation(_ref) {
  var {
    arrivalDate,
    departureDate,
    noNights,
    t,
    prefixDataTestId,
    language = 'en'
  } = _ref;
  var baseDataTestId = (0, _utils.formatDataTestId)(prefixDataTestId, 'StayDatesInformation');
  return (0, _jsxRuntime.jsxs)(_react.Grid, _objectSpread(_objectSpread({}, detailsWrapperStyle), {}, {
    "data-testid": (0, _utils.formatDataTestId)(baseDataTestId, 'Wrapper'),
    children: [(0, _jsxRuntime.jsxs)(_react.Box, {
      children: [(0, _jsxRuntime.jsx)(_react.Text, _objectSpread(_objectSpread({}, infoTextStyle), {}, {
        fontWeight: "semibold",
        children: t('booking.summary.arrival')
      })), (0, _jsxRuntime.jsx)(_react.Text, _objectSpread(_objectSpread({}, infoTextStyle), {}, {
        fontWeight: "normal",
        "data-testid": (0, _utils.formatDataTestId)(baseDataTestId, 'ArrivalDate'),
        children: (0, _utils.formatDate)(arrivalDate, 'E dd MMM yyyy', language)
      }))]
    }), (0, _jsxRuntime.jsxs)(_react.Box, {
      pt: "md",
      children: [(0, _jsxRuntime.jsx)(_react.Text, _objectSpread(_objectSpread({}, infoTextStyle), {}, {
        fontWeight: "semibold",
        children: t('booking.summary.departure')
      })), (0, _jsxRuntime.jsx)(_react.Text, _objectSpread(_objectSpread({}, infoTextStyle), {}, {
        fontWeight: "normal",
        "data-testid": (0, _utils.formatDataTestId)(baseDataTestId, 'DepartureDate'),
        children: (0, _utils.formatDate)(departureDate, 'E dd MMM yyyy', language)
      }))]
    }), (0, _jsxRuntime.jsxs)(_react.Box, {
      pt: "md",
      children: [(0, _jsxRuntime.jsx)(_react.Text, _objectSpread(_objectSpread({}, infoTextStyle), {}, {
        fontWeight: "semibold",
        children: t('booking.summary.totalNights')
      })), (0, _jsxRuntime.jsx)(_react.Text, _objectSpread(_objectSpread({}, infoTextStyle), {}, {
        fontWeight: "normal",
        "data-testid": (0, _utils.formatDataTestId)(baseDataTestId, 'NightsNumber'),
        children: "".concat(noNights, " ").concat(noNights === 1 ? "".concat(t('booking.summary.night')) : "".concat(t('booking.summary.nights')))
      }))]
    })]
  }));
}
var detailsWrapperStyle = {
  direction: 'column',
  gridTemplateColumns: 'auto',
  pt: {
    mobile: 'md',
    lg: 'lg'
  }
};
var infoTextStyle = {
  lineHeight: '3',
  color: 'darkGrey1'
};