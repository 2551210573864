"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = OpeningSoonNotificationQueryWrapper;
var _reactQuery = require("@tanstack/react-query");
var _OpeningSoonNotification = _interopRequireDefault(require("./OpeningSoonNotification.container"));
var _jsxRuntime = require("react/jsx-runtime");
function OpeningSoonNotificationQueryWrapper(_ref) {
  var {
    queryClient,
    hotelAvailabilityResponse,
    isHotelOpeningSoon
  } = _ref;
  return (0, _jsxRuntime.jsx)(_reactQuery.QueryClientProvider, {
    client: queryClient,
    children: (0, _jsxRuntime.jsx)(_OpeningSoonNotification.default, {
      hotelAvailabilityResponse,
      isHotelOpeningSoon
    })
  });
}