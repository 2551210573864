"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.GET_HOTEL_INFORMATION = void 0;
var _taggedTemplateLiteral2 = _interopRequireDefault(require("@babel/runtime/helpers/taggedTemplateLiteral"));
var _graphqlRequest = require("graphql-request");
var _templateObject;
var GET_HOTEL_INFORMATION = exports.GET_HOTEL_INFORMATION = (0, _graphqlRequest.gql)(_templateObject || (_templateObject = (0, _taggedTemplateLiteral2.default)(["\n  query GetHotelInformation($hotelId: String!, $country: String!, $language: String!) {\n    hotelInformation(hotelId: $hotelId, country: $country, language: $language) {\n      address {\n        addressLine1\n        addressLine2\n        addressLine3\n        addressLine4\n        postalCode\n        country\n      }\n      hotelId\n      hotelOpeningDate\n      name\n      brand\n      parkingDescription\n      directions\n      county\n      contactDetails {\n        phone\n        hotelNationalPhone\n        email\n      }\n      coordinates {\n        latitude\n        longitude\n      }\n      links {\n        detailsPage\n      }\n      galleryImages {\n        alt\n        thumbnailSrc\n      }\n      announcement {\n        endDate\n        showAnnouncement\n        startDate\n        text\n        title\n        type\n      }\n      importantInfo {\n        title\n        infoItems {\n          text\n          priority\n          startDate\n          endDate\n        }\n      }\n      ancillaryCloseout {\n        items {\n          endDate\n          serviceCode\n          startDate\n          text\n          upsellCodes\n        }\n      }\n    }\n  }\n"])));