"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = HotelDropdownModal;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _react = require("@chakra-ui/react");
var _api = require("@whitbread-eos/api");
var _atoms = require("@whitbread-eos/atoms");
var _utils = require("@whitbread-eos/utils");
var _nextI18next = require("next-i18next");
var _jsxRuntime = require("react/jsx-runtime");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
var NO_MAX_HOTELS = 10;
function HotelDropdownModal(_ref) {
  var {
    isOpen,
    onClose,
    onHotelSelected,
    paramsForQuery,
    handleSetValue
  } = _ref;
  var {
    t
  } = (0, _nextI18next.useTranslation)();
  var baseTestId = 'HotelDropdownModal';
  var modalTitle = t('ccui.manageBooking.hotelsModalTitle');
  var modalNoResults = t('ccui.manageBooking.hotelsModalNoResults');
  var modalError = t('ccui.manageBooking.hotelsModalError');
  var {
    data,
    isError,
    isFetching
  } = (0, _utils.useQueryRequest)(['hotelsLocations', paramsForQuery === null || paramsForQuery === void 0 ? void 0 : paramsForQuery.location, paramsForQuery === null || paramsForQuery === void 0 ? void 0 : paramsForQuery.locationFormat, paramsForQuery === null || paramsForQuery === void 0 ? void 0 : paramsForQuery.radius, paramsForQuery === null || paramsForQuery === void 0 ? void 0 : paramsForQuery.radiusUnit], _api.HOTELS_BY_LOCATION, _objectSpread({}, paramsForQuery), {
    enabled: isOpen
  }, undefined, true);
  var handleOnClose = () => {
    onClose();
  };
  var handleHotelSelection = hotelDetails => {
    handleSetValue === null || handleSetValue === void 0 || handleSetValue('hotelDetails', hotelDetails);
    onHotelSelected();
  };
  var mocalContent = () => {
    if (isFetching) {
      return (0, _jsxRuntime.jsx)(_atoms.LoadingSpinner, {
        loadingText: t('searchresults.list.hotel.loading')
      });
    }
    if (isError) {
      return (0, _jsxRuntime.jsx)(_atoms.Notification, {
        maxWidth: "full",
        variant: "error",
        status: "error",
        title: '',
        description: modalError,
        svg: (0, _jsxRuntime.jsx)(_atoms.Error, {})
      });
    }
    var results = data === null || data === void 0 ? void 0 : data.hotelsLocations;
    if (!results || results.length <= 0) {
      return (0, _jsxRuntime.jsx)(_atoms.Notification, {
        maxWidth: "full",
        variant: "alert",
        status: "error",
        title: '',
        description: modalNoResults,
        svg: (0, _jsxRuntime.jsx)(_atoms.Alert, {})
      });
    }
    return mapHotels(results).map(result => {
      var _result$value;
      var hotelDetails = {
        name: (_result$value = result === null || result === void 0 ? void 0 : result.value) !== null && _result$value !== void 0 ? _result$value : '',
        code: result.code
      };
      return (0, _jsxRuntime.jsxs)(_react.Box, _objectSpread(_objectSpread({
        "data-testid": (0, _utils.formatDataTestId)(baseTestId, 'ListItem')
      }, itemStyles), {}, {
        onClick: () => handleHotelSelection(hotelDetails),
        children: [result.component, (0, _jsxRuntime.jsx)(_react.Text, _objectSpread(_objectSpread({
          as: "span"
        }, itemValueStyles), {}, {
          children: result === null || result === void 0 ? void 0 : result.value
        }))]
      }), result.code);
    });
  };
  return (0, _jsxRuntime.jsx)(_atoms.ModalVariants, {
    isOpen: isOpen,
    onClose: handleOnClose,
    dataTestId: (0, _utils.formatDataTestId)(baseTestId, 'Container'),
    variant: "default",
    headerStyles: headerStyles,
    overlayStyles: zIndexStyles,
    contentContainerStyles: zIndexStyles,
    variantProps: {
      title: modalTitle,
      closeOnOverlayClick: false,
      overflowVisible: false,
      isCentered: true,
      externalTitleStyling: titleStyles
    },
    children: (0, _jsxRuntime.jsx)(_react.Flex, _objectSpread(_objectSpread({
      "data-testid": (0, _utils.formatDataTestId)(baseTestId, 'ListContent')
    }, containerStyles), {}, {
      children: mocalContent()
    }))
  });
  function mapHotels(items) {
    var configuredItems = items.length > NO_MAX_HOTELS ? items.slice(0, NO_MAX_HOTELS) : items;
    return configuredItems === null || configuredItems === void 0 ? void 0 : configuredItems.map(item => {
      return {
        value: item.name,
        code: item.hotelId,
        component: (0, _atoms.getLogoByBrand)(item === null || item === void 0 ? void 0 : item.brand, item === null || item === void 0 ? void 0 : item.hotelId)
      };
    });
  }
}
var titleStyles = {
  justifyContent: 'flex-start',
  marginLeft: 0
};
var headerStyles = {
  py: 'var(--chakra-space-4)'
};
var containerStyles = {
  direction: 'column',
  paddingTop: 'var(--chakra-space-6)',
  paddingInline: 'var(--chakra-space-6)',
  height: '27rem',
  width: {
    mobile: 'full',
    lg: '24.5rem',
    xl: '27.75rem'
  }
};
var itemStyles = {
  cursor: 'pointer',
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
  paddingY: 'var(--chakra-space-4)',
  paddingInline: 'var(--chakra-space-1)',
  _last: {
    marginBottom: 'var(--chakra-space-4)'
  },
  _hover: {
    bg: 'var(--chakra-colors-lightGrey5)'
  }
};
var itemValueStyles = {
  display: 'block',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  fontWeight: 'medium',
  fontSize: 'md',
  lineHeight: '3',
  color: 'darkGrey1'
};
var zIndexStyles = {
  zIndex: 2000
};