"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getSearchStyles = getSearchStyles;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _utils = require("@whitbread-eos/utils");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function getSearchStyles(isLessThanSm, isError, locale, items, validationField) {
  var searchWrapper = {
    minW: {
      base: 'full',
      md: '45rem',
      xl: '81.75rem'
    },
    h: {
      base: 'var(--chakra-space-4xl)',
      lg: 'var(--chakra-space-6xl)'
    },
    flexWrap: {
      base: 'wrap',
      sm: 'nowrap'
    },
    borderRadius: 'var(--chakra-space-xs)',
    border: {
      base: 'none',
      sm: '1px solid var(--chakra-colors-lightGrey2)'
    },
    boxShadow: {
      base: 'none',
      sm: '0px 0.125rem 0.75rem var(--chakra-colors-lightGrey2)'
    }
  };
  var locationPickerStyles = {
    w: {
      base: 'full',
      lg: '25.25rem',
      xl: '26.25rem'
    },
    flex: {
      base: '1 1 auto',
      lg: 'none'
    },
    marginBottom: {
      sm: 0,
      xs: 'md',
      mobile: 'md'
    }
  };
  var inputGroupStyles = {
    w: 'full',
    h: {
      base: 'var(--chakra-space-4xl)',
      lg: 'var(--chakra-space-6xl)'
    },
    border: {
      base: '1px solid var(--chakra-colors-lightGrey1)',
      sm: 0
    },
    borderRadius: {
      base: 'var(--chakra-space-xs)',
      sm: 0
    }
  };
  var inputElementStyles = {
    borderRight: '1px solid var(--chakra-colors-lightGrey4)',
    borderTopRightRadius: (0, _utils.ternaryCondition)(isLessThanSm, 'var(--chakra-space-xs)', 0),
    borderBottomRightRadius: (0, _utils.ternaryCondition)(isLessThanSm, 'var(--chakra-space-xs)', 0),
    color: 'darkGrey1'
  };
  var bookingDatepickerSize = {
    w: {
      base: '49%',
      sm: 'full',
      lg: '19.5rem',
      xl: '19.781rem'
    }
  };
  var datepickerInputElementStyles = {
    h: '100%',
    flex: {
      base: '1 1 50%',
      sm: '1 1 auto'
    },
    mr: {
      base: 'xs',
      xs: 'sm',
      sm: '0'
    },
    _placeholder: {
      color: 'var(--chakra-colors-darkGrey1)'
    }
  };
  var iconStyles = {
    top: {
      base: 'var(--chakra-space-sm)',
      lg: 'var(--chakra-space-md)'
    }
  };
  var errorMarginBottom = {
    mobile: (0, _utils.ternaryCondition)(isError, locale === 'de' ? '5rem' : 'sm', 'sm'),
    xs: (0, _utils.ternaryCondition)(isError, 'md', 'md'),
    sm: '0'
  };
  var errorInputGroupStyles = _objectSpread(_objectSpread({}, inputGroupStyles), isError ? {
    border: (items === null || items === void 0 ? void 0 : items.length) === 0 || validationField !== 'location' ? '2px solid var(--chakra-colors-error)' : '1px solid var(--chakra-colors-lightGrey4)',
    borderRadius: 'var(--chakra-space-radiusSmall)',
    _hover: {
      border: '2px solid var(--chakra-colors-primary)',
      borderRadius: 'var(--chakra-space-radiusSmall)'
    },
    _focus: {
      border: '2px solid var(--chakra-colors-primary)',
      borderRadius: 'var(--chakra-space-radiusSmall)'
    }
  } : {});
  var errorInputElementStyles = _objectSpread(_objectSpread({}, inputElementStyles), isError ? {
    border: 'none',
    borderRight: 'none',
    _hover: {
      border: 'none'
    },
    _focus: {
      border: 'none'
    },
    _active: {
      border: 'none'
    }
  } : {});
  var buttonStyles = {
    w: {
      base: 'full',
      sm: '3xl',
      md: '8.875rem',
      lg: '11.75rem'
    },
    h: {
      xs: 'var(--chakra-space-4xl)',
      sm: 'var(--chakra-space-2xl)',
      lg: 'var(--chakra-space-4xl)'
    },
    ml: {
      sm: 'sm',
      lg: '0.8rem',
      xl: '3.5631rem'
    },
    mr: {
      sm: 'sm'
    },
    flex: {
      sm: '1 1 20%',
      md: '1 1 40%',
      lg: 'none'
    }
  };
  var roomPickerSize = {
    w: {
      base: '49%',
      sm: 'full',
      lg: '18.563rem',
      xl: '19.781rem'
    }
  };
  var roomPickerWrapperStyles = {
    mt: 0,
    h: {
      base: 'var(--chakra-space-4xl)',
      lg: 'var(--chakra-space-6xl)'
    }
  };
  var roomPickerInputElementStyles = {
    w: '100%',
    h: '100%',
    flex: {
      base: '1 1 50%',
      sm: '1 1 auto'
    },
    ml: {
      base: 'xs',
      xs: 'sm',
      sm: '0'
    },
    border: {
      base: '1px solid var(--chakra-colors-lightGrey1)',
      sm: 'none'
    },
    _placeholder: {
      color: 'var(--chakra-colors-darkGrey1)'
    }
  };
  return {
    searchWrapper,
    locationPickerStyles,
    inputGroupStyles,
    inputElementStyles,
    iconStyles,
    bookingDatepickerSize,
    datepickerInputElementStyles,
    errorInputGroupStyles,
    errorInputElementStyles,
    errorMarginBottom,
    buttonStyles,
    roomPickerSize,
    roomPickerWrapperStyles,
    roomPickerInputElementStyles
  };
}