"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = BookingInfoCard;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));
var _react = require("@chakra-ui/react");
var _reactQuery = require("@tanstack/react-query");
var _api = require("@whitbread-eos/api");
var _atoms = require("@whitbread-eos/atoms");
var _utils = require("@whitbread-eos/utils");
var _format = _interopRequireDefault(require("date-fns/format"));
var _formatISO = _interopRequireDefault(require("date-fns/formatISO"));
var _nextI18next = require("next-i18next");
var _router = require("next/router");
var _react2 = require("react");
var _constants = require("../../../amend/ChangePaymentCCUI/constants");
var _AgentOverrideModal = _interopRequireDefault(require("./AgentOverrideModal"));
var _BookingInfoCard = _interopRequireDefault(require("./BookingInfoCard.component"));
var _ResendConfirmationModal = require("./ResendConfirmationModal");
var _jsxRuntime = require("react/jsx-runtime");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function BookingInfoCard(_ref) {
  var _firstRoom$roomStay, _firstRoom$roomStay2, _getBasketStatus$bask, _getBasketStatus$bask2, _firstRoom$billing2, _getBasketStatus$bask3, _OVData$cancellationR;
  var {
    basketReference,
    tempBookingReference,
    operaConfNumber,
    bookingReference,
    area,
    updateStatusAfterCancel,
    inputValues,
    bookingType,
    isAmendPage = false,
    isAmendSuccessful = false,
    setInputValuesInSessionStorage
  } = _ref;
  var {
    t
  } = (0, _nextI18next.useTranslation)();
  var {
    language,
    country
  } = (0, _utils.useCustomLocale)();
  var shouldShowTypeOfBooking = area === _api.Area.CCUI;
  var baseDataTestId = 'BookingInfoCard';
  var router = (0, _router.useRouter)();
  var isErrorFindOrCopyBooking = (0, _react2.useRef)(false);
  var [isChangedPaymentApplied, seIsChangePaymentApplied] = (0, _react2.useState)(false);
  var [dpaInfo, setDpaInfo] = (0, _react2.useState)({
    dpaPassed: false,
    dpaOverride: false
  });
  var [overridenUserInfo, setOverridenUserInfo] = (0, _react2.useState)({
    reservationOverrideReasons: {
      reasonName: '',
      callerName: '',
      managerName: '',
      reasonCode: ''
    },
    reservationOverridden: false
  });
  var [isAgentOverrideModalVisible, setIsAgentOverrideModalVisible] = (0, _react2.useState)(false);
  var [isBackFlag, setIsBackFlag] = (0, _utils.useLocalStorage)(_constants.IS_BACK_FROM_CHANGE_PAYMENT_PAGE_CCUI, false);
  var {
    mutation: copyBookingMutation
  } = (0, _utils.useMutationRequest)(_api.COPY_BOOKING, true);
  var copyBookingAndRedirectToAmendPayment = function () {
    var _ref2 = (0, _asyncToGenerator2.default)(function* (bookingToken) {
      var channel = _api.BOOKING_CHANNEL.CCUI;
      var bookingChannel = {
        channel,
        subchannel: _api.BOOKING_SUBCHANNEL.WEB,
        language: language === null || language === void 0 ? void 0 : language.toUpperCase()
      };
      var temporaryBasketReference = '';
      try {
        var {
          copyBooking
        } = yield copyBookingMutation.mutateAsync({
          originalBasketReference: bookingData.basketReference,
          bookingChannel,
          token: bookingToken
        });
        temporaryBasketReference = copyBooking === null || copyBooking === void 0 ? void 0 : copyBooking.copyBasketReference;
        var redirectToAmendPayment = "/".concat(country, "/").concat(language, "/amend/payment?tempBasketReference=").concat(temporaryBasketReference, "&basketReference=").concat(bookingData.basketReference, "&bookingReference=").concat(bookingReference, "&changePaymentBIC=true");
        temporaryBasketReference && router.push(redirectToAmendPayment);
      } catch (_unused) {
        isErrorFindOrCopyBooking.current = true;
      }
    });
    return function copyBookingAndRedirectToAmendPayment(_x) {
      return _ref2.apply(this, arguments);
    };
  }();
  var handleChangePayment = () => {
    var _firstRoom$billing;
    if (paymentOption !== _api.GuaranteeCodes.RESERVE_WITHOUT_CARD) return;
    var queryClient = new _reactQuery.QueryClient();
    var findBookingCriteria = {
      country,
      language,
      arrivalDate: (0, _format.default)(new Date(firstRoom.roomStay.arrivalDate), _api.DATE_TYPE.YEAR_MONTH_DAY),
      lastName: firstRoom === null || firstRoom === void 0 || (_firstRoom$billing = firstRoom.billing) === null || _firstRoom$billing === void 0 ? void 0 : _firstRoom$billing.lastName,
      resNo: String(bookingReference).toUpperCase()
    };
    queryClient.fetchQuery({
      queryKey: ['FindBooking', country, language, findBookingCriteria.arrivalDate, findBookingCriteria.lastName, findBookingCriteria.resNo],
      queryFn: () => (0, _utils.graphQLRequest)(_api.FIND_BOOKING, {
        findBookingCriteria
      })
    }).then(response => {
      if (!(response !== null && response !== void 0 && response.findBooking)) {
        return;
      }
      var {
        ref,
        basketReference,
        cookieName,
        token,
        minutesTillExpiry
      } = response.findBooking;
      var cookieValue = {
        token,
        basketReference,
        bookingReference: ref
      };
      (0, _utils.setBookingCookie)(cookieName, cookieValue, minutesTillExpiry);
      copyBookingAndRedirectToAmendPayment(response.findBooking.token);
    }).catch(() => isErrorFindOrCopyBooking.current = true);
  };
  var {
    bookingData,
    bookingError,
    bookingIsError,
    bookingIsLoading,
    bookingIsSuccess,
    bookingRefetch
  } = (0, _utils.useBookingConfimationData)(area, basketReference !== null && basketReference !== void 0 ? basketReference : '', bookingReference, language, country);
  var areAllReservationsCancelled = (bookingData === null || bookingData === void 0 ? void 0 : bookingData.reservationByIdList.filter(reservation => (reservation === null || reservation === void 0 ? void 0 : reservation.reservationStatus) === _api.BOOKING_TYPE.CANCELLED).length) === (bookingData === null || bookingData === void 0 ? void 0 : bookingData.reservationByIdList.length);
  var firstRoom = areAllReservationsCancelled ? bookingData === null || bookingData === void 0 ? void 0 : bookingData.reservationByIdList[0] : bookingData === null || bookingData === void 0 ? void 0 : bookingData.reservationByIdList.find(reservation => (reservation === null || reservation === void 0 ? void 0 : reservation.reservationStatus) !== _api.BOOKING_TYPE.CANCELLED);
  var basketReferenceValue = basketReference !== null && basketReference !== void 0 ? basketReference : bookingData === null || bookingData === void 0 ? void 0 : bookingData.basketReference;
  var gdsReferenceNumber = firstRoom === null || firstRoom === void 0 ? void 0 : firstRoom.gdsReferenceNumber;
  var reservationOverriddenStatus = firstRoom === null || firstRoom === void 0 ? void 0 : firstRoom.reservationOverridden;
  var distBookingChannel = firstRoom === null || firstRoom === void 0 || (_firstRoom$roomStay = firstRoom.roomStay) === null || _firstRoom$roomStay === void 0 ? void 0 : _firstRoom$roomStay.bookingChannel;
  var bookingStatus = firstRoom === null || firstRoom === void 0 ? void 0 : firstRoom.reservationStatus;
  var paymentOption = firstRoom === null || firstRoom === void 0 ? void 0 : firstRoom.guaranteeCode;
  var departureDate = firstRoom === null || firstRoom === void 0 || (_firstRoom$roomStay2 = firstRoom.roomStay) === null || _firstRoom$roomStay2 === void 0 ? void 0 : _firstRoom$roomStay2.departureDate;
  var {
    data: OVData,
    error: OVError
  } = (0, _utils.useQueryRequest)(['getOverrideReasons', bookingData === null || bookingData === void 0 ? void 0 : bookingData.hotelId], _api.GET_OVERRIDE_REASONS, {
    hotelId: bookingData === null || bookingData === void 0 ? void 0 : bookingData.hotelId
  }, {
    enabled: !!(bookingData !== null && bookingData !== void 0 && bookingData.hotelId) && isAgentOverrideModalVisible
  });
  var date = (0, _formatISO.default)(Date.now());
  var {
    data = {
      manageBooking: {
        isCancellable: false,
        isAmendable: false,
        isRuleCompliant: true
      }
    },
    isError,
    isLoading,
    error
  } = (0, _utils.useQueryRequest)(['manageBookingDashBoard', basketReferenceValue, bookingData === null || bookingData === void 0 ? void 0 : bookingData.hotelId], _api.DASHBOARD_MANAGE_BOOKING, {
    cancelInformationCriteria: {
      userDateTime: date,
      bookingChannel: {
        channel: area === _api.Area.PI ? _api.BOOKING_CHANNEL.PI : _api.BOOKING_CHANNEL.CCUI,
        subchannel: _api.BOOKING_SUBCHANNEL.WEB,
        language: language
      },
      basketReference: basketReferenceValue,
      hotelId: bookingData === null || bookingData === void 0 ? void 0 : bookingData.hotelId,
      token: (0, _utils.formatFindBookingToken)((0, _utils.getFindBookingToken)().token)
    }
  }, {
    enabled: (area === _api.Area.PI && bookingStatus !== _api.BC_RESERVATION_STATUS.CANCELLED || area === _api.Area.CCUI && (bookingType === _api.BOOKING_TYPE.UPCOMING || isAmendPage)) && !!(bookingData !== null && bookingData !== void 0 && bookingData.hotelId),
    staleTime: 0,
    cacheTime: 0
  });
  var {
    data: getBasketStatus
  } = (0, _utils.useQueryRequest)(['basket'], _api.GET_DASHBOARD_BASKET, {
    basketReference: basketReferenceValue
  }, {
    enabled: !!basketReferenceValue,
    staleTime: 0,
    cacheTime: 0
  });
  var manageBookingParams = {
    data,
    isError,
    isLoading,
    error
  };
  var [isModalVisible, setIsModalVisible] = (0, _react2.useState)(false);
  var onModalClose = () => setIsModalVisible(prevState => !prevState);
  (0, _react2.useEffect)(() => {
    if (bookingIsSuccess && reservationOverriddenStatus) {
      setOverridenUserInfo({
        reservationOverrideReasons: firstRoom === null || firstRoom === void 0 ? void 0 : firstRoom.reservationOverrideReasons,
        reservationOverridden: reservationOverriddenStatus
      });
    }
  }, [bookingIsSuccess, setOverridenUserInfo, bookingData === null || bookingData === void 0 ? void 0 : bookingData.reservationByIdList]);
  (0, _react2.useEffect)(() => {
    if (bookingData && isBackFlag) {
      seIsChangePaymentApplied(!!isBackFlag);
    }
    var cleanupBackFlag = () => {
      setIsBackFlag(false);
    };
    return () => {
      cleanupBackFlag();
    };
  }, [bookingData, isBackFlag, getBasketStatus === null || getBasketStatus === void 0 || (_getBasketStatus$bask = getBasketStatus.basket) === null || _getBasketStatus$bask === void 0 ? void 0 : _getBasketStatus$bask.paymentOption]);
  if (bookingIsError) {
    return (0, _jsxRuntime.jsx)(_react.Box, {
      "data-testid": (0, _utils.formatDataTestId)(baseDataTestId, 'ErrorContainer'),
      children: (0, _jsxRuntime.jsx)(_atoms.Notification, {
        status: "error",
        description: String(bookingError),
        variant: "alert",
        maxW: "full",
        svg: (0, _jsxRuntime.jsx)(_atoms.Alert, {})
      })
    });
  }
  if (bookingIsLoading) {
    return (0, _jsxRuntime.jsx)(_react.Flex, _objectSpread(_objectSpread({}, loadingStyle), {}, {
      "data-testid": "Loading-BookingInfoCard",
      children: (0, _jsxRuntime.jsx)(_atoms.LoadingSpinner, {
        loadingText: t('booking.loading')
      })
    }));
  }
  return (0, _jsxRuntime.jsxs)(_react.Box, {
    "data-testid": (0, _utils.formatDataTestId)(baseDataTestId, 'Container'),
    children: [(0, _jsxRuntime.jsx)(_BookingInfoCard.default, {
      bookingReference: bookingReference,
      basketReference: basketReferenceValue,
      operaConfNumber: operaConfNumber,
      tempBookingReference: tempBookingReference,
      area: area,
      shouldShowTypeOfBooking: shouldShowTypeOfBooking,
      bookingType: bookingType !== null && bookingType !== void 0 ? bookingType : (0, _utils.mappingBookingStatus)(bookingStatus, departureDate),
      bookingStatus: bookingStatus,
      getBookingStatus: () => {
        bookingRefetch();
        area === 'ccui' && updateStatusAfterCancel && updateStatusAfterCancel(bookingReference);
      },
      paymentOption: getBasketStatus === null || getBasketStatus === void 0 || (_getBasketStatus$bask2 = getBasketStatus.basket) === null || _getBasketStatus$bask2 === void 0 ? void 0 : _getBasketStatus$bask2.paymentOption,
      dpaInfo: dpaInfo,
      setDpaInfo: setDpaInfo,
      setIsAgentOverrideModalVisible: setIsAgentOverrideModalVisible,
      overridenUserInfo: overridenUserInfo,
      inputValues: inputValues,
      gdsReferenceNumber: gdsReferenceNumber,
      distBookingChannel: distBookingChannel,
      isAmendPage: isAmendPage,
      isAmendSuccessful: isAmendSuccessful,
      handleResendConfirmationAction: onModalClose,
      arrivalDate: firstRoom.roomStay.arrivalDate,
      bookingSurname: firstRoom === null || firstRoom === void 0 || (_firstRoom$billing2 = firstRoom.billing) === null || _firstRoom$billing2 === void 0 ? void 0 : _firstRoom$billing2.lastName,
      handleRepeatBookingAction: () => _handleRepeatBookingAction(setInputValuesInSessionStorage),
      manageBookingParams: manageBookingParams,
      handleChangePayment: handleChangePayment,
      isErrorFindOrCopyBooking: isErrorFindOrCopyBooking.current,
      basketStatus: getBasketStatus === null || getBasketStatus === void 0 || (_getBasketStatus$bask3 = getBasketStatus.basket) === null || _getBasketStatus$bask3 === void 0 ? void 0 : _getBasketStatus$bask3.status,
      isChangedPaymentApplied: isChangedPaymentApplied
    }), area === _api.Area.PI && basketReference && (0, _jsxRuntime.jsx)(_ResendConfirmationModal.ResendConfirmationModal, {
      isModalVisible: isModalVisible,
      onModalClose: onModalClose,
      basketReference: basketReference,
      hotelId: bookingData === null || bookingData === void 0 ? void 0 : bookingData.hotelId
    }), area === _api.Area.CCUI && (0, _jsxRuntime.jsxs)(_react.Box, {
      "data-testid": (0, _utils.formatDataTestId)(baseDataTestId, 'AgentOverrideInfo'),
      children: [bookingData && OVData && (0, _jsxRuntime.jsx)(_atoms.ErrorBoundary, {
        children: (0, _jsxRuntime.jsx)(_AgentOverrideModal.default, {
          basketReference: basketReferenceValue,
          hotelId: bookingData === null || bookingData === void 0 ? void 0 : bookingData.hotelId,
          reasons: OVData === null || OVData === void 0 || (_OVData$cancellationR = OVData.cancellationReasons) === null || _OVData$cancellationR === void 0 ? void 0 : _OVData$cancellationR.cancellationReasons,
          error: OVError,
          isVisible: isAgentOverrideModalVisible,
          onClose: handleAgentOverrideModalClose,
          getBookingInfo: () => {
            bookingRefetch();
          },
          overridenUserInfo: overridenUserInfo
        })
      }), bookingType === _api.BOOKING_TYPE.UPCOMING && reservationOverriddenStatus && (0, _jsxRuntime.jsx)(_react.Box, {
        mt: "2xl",
        "data-testid": (0, _utils.formatDataTestId)(baseDataTestId, 'OverridenNotification'),
        children: (0, _jsxRuntime.jsx)(_atoms.Notification, {
          title: '',
          maxWidth: "full",
          description: t('ccui.manageBooking.options.agentOverrideModal.notification.message'),
          status: 'success',
          variant: 'success',
          svg: (0, _jsxRuntime.jsx)(_atoms.Success, {})
        })
      })]
    })]
  });
  function handleAgentOverrideModalClose() {
    setIsAgentOverrideModalVisible(false);
  }
  function _handleRepeatBookingAction(setInputValuesInSessionStorage) {
    if (setInputValuesInSessionStorage) {
      setInputValuesInSessionStorage(inputValues, bookingReference);
    }
    router.push("/".concat(country, "/").concat(language, "/repeat-booking?reservationId=").concat(basketReferenceValue));
  }
}
var loadingStyle = {
  height: '100%',
  width: '100%',
  left: 0,
  top: 0,
  position: 'fixed',
  zIndex: 999,
  backgroundColor: 'baseWhite',
  textAlign: 'center',
  lineHeight: 3,
  justifyContent: 'center',
  color: 'btnSecondaryEnabled',
  fontSize: 'xl',
  flex: 'none',
  flexGrow: 0,
  order: 1,
  alignSelf: 'stretch'
};