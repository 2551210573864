"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.MemoModalVariants = void 0;
var MemoModalVariants = exports.MemoModalVariants = function (MemoModalVariants) {
  MemoModalVariants["PAGE"] = "default";
  MemoModalVariants["CARD"] = "card";
  return MemoModalVariants;
}({});