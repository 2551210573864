"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = ChangePaymentContainer;
var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _react = require("@chakra-ui/react");
var _api = require("@whitbread-eos/api");
var _molecules = require("@whitbread-eos/molecules");
var _utils = require("@whitbread-eos/utils");
var _nextI18next = require("next-i18next");
var _config = _interopRequireDefault(require("next/config"));
var _router = require("next/router");
var _react2 = require("react");
var _constants = require("./constants");
var _jsxRuntime = require("react/jsx-runtime");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function ChangePaymentContainer(_ref) {
  var _getConfig, _paymentOptionsData$p, _allowances$values, _emailPreference$emai, _bcData$reservationBy, _eckohData$eckohRecor;
  var {
    summaryOfPayments,
    paymentOptionsData,
    basketReference,
    user,
    hiData,
    bcData
  } = _ref;
  var {
    country,
    language
  } = (0, _utils.useCustomLocale)();
  var publicRuntimeConfig = (_getConfig = (0, _config.default)()) === null || _getConfig === void 0 ? void 0 : _getConfig.publicRuntimeConfig;
  var {
    t
  } = (0, _nextI18next.useTranslation)();
  var router = (0, _router.useRouter)();
  var {
    changePaymentBIC
  } = router.query;
  var {
    a2cDetails,
    emailPreference,
    hotelName,
    hotelCode,
    brand,
    paymentType,
    companyId: compId,
    allowances
  } = (_paymentOptionsData$p = paymentOptionsData === null || paymentOptionsData === void 0 ? void 0 : paymentOptionsData.paymentOptions) !== null && _paymentOptionsData$p !== void 0 ? _paymentOptionsData$p : {};
  var [showTotalCost, setShowTotalCost] = (0, _react2.useState)(!!(hotelName && hotelCode && brand));
  var [companyId, setCompanyId] = (0, _react2.useState)(compId || '');
  var [companyDetails, setCompanyDetails] = (0, _react2.useState)(a2cDetails);
  var baseTestId = 'ChangePaymentContainer';
  var cardOptions = [_api.CcuiCardType.NEW_CARD, _api.CcuiCardType.NEW_PIBA, _api.CcuiCardType.NON_GUARANTEED, _api.CcuiCardType.ACCOUNT_COMPANY];
  var defaultPaymentInfo = {
    type: '',
    order: 0,
    enabled: true
  };
  var disabledCardOptions = cardOptions.filter(option => option === _api.CcuiCardType.NEW_PIBA);
  var [selectedPaymentType, setSelectedPaymentType] = (0, _utils.usePaymentMethod)();
  var [selectedPaymentInfo, setSelectedPaymentInfo] = (0, _react2.useState)(defaultPaymentInfo);
  var defaultAllowances = allowances === null || allowances === void 0 || (_allowances$values = allowances.values) === null || _allowances$values === void 0 ? void 0 : _allowances$values.map(value => {
    var _value$allowance;
    return (_value$allowance = value === null || value === void 0 ? void 0 : value.allowance) !== null && _value$allowance !== void 0 ? _value$allowance : '';
  });
  var [sendEmailApproval, setSendEmailApproval] = (0, _react2.useState)(true);
  var [acCompanyReference, setACCompanyReference] = (0, _react2.useState)('');
  var [emailAddress, setEmailAddress] = (0, _react2.useState)((_emailPreference$emai = emailPreference === null || emailPreference === void 0 ? void 0 : emailPreference.emailAddress) !== null && _emailPreference$emai !== void 0 ? _emailPreference$emai : '');
  var [emailError, setEmailError] = (0, _react2.useState)('');
  var isEckohRequired = selectedPaymentType.type !== _api.CcuiCardType.ACCOUNT_COMPANY && selectedPaymentType.type !== _api.CcuiCardType.NON_GUARANTEED;
  var [hasErrors] = (0, _react2.useState)(false);
  var intervalRef = (0, _react2.useRef)();
  var isA2ContainerDisplayed = (selectedPaymentInfo === null || selectedPaymentInfo === void 0 ? void 0 : selectedPaymentInfo.type) !== _api.paymentOptions.PAY_NOW && selectedPaymentType.type === _api.CcuiCardType.ACCOUNT_COMPANY;
  var isA2CPayment = selectedPaymentType.type === _api.CcuiCardType.ACCOUNT_COMPANY;
  var companyReferenceError = (0, _react2.useRef)(false);
  var setCompanyReferenceError = value => {
    if (typeof value === 'boolean') {
      companyReferenceError.current = value;
    }
  };
  var [acCharges, setACCharges] = (0, _react2.useState)(defaultAllowances !== null && defaultAllowances !== void 0 ? defaultAllowances : []);
  var [companyNumber, setCompanyNumber] = (0, _react2.useState)('');
  var [typeOfCaller, setTypeOfCaller] = (0, _react2.useState)('');
  var [cardType, setCardType] = (0, _react2.useState)('');
  var [disabledCardType, setDisabledCardType] = (0, _react2.useState)('');
  var [eckohCheckPassed, setEckohCheckPassed] = (0, _react2.useState)(false);
  var [isEnabledEckohQuery, setIsEnabledEckohQuery] = (0, _react2.useState)(true);
  var [isEnabledPaymentStatus, setIsEnabledPaymentStatus] = (0, _react2.useState)(false);
  var [confirmationStarted, setConfirmationStarted] = (0, _react2.useState)(false);
  var [eckohParameters, setEckohParameters] = (0, _react2.useState)();
  var [confAnalytics, setConfAnalytics] = (0, _utils.useSessionStorage)(_api.PAYMENT_ANALYTICS_KEY, {});
  var [hasChecks, setHasChecks] = (0, _react2.useState)(false);
  var [cardHolderNames, setCardHolderNames] = (0, _react2.useState)({
    firstName: '',
    lastName: ''
  });
  var [, setHasCardHolderNameError] = (0, _react2.useState)(false);
  var [, setIsBackFlag] = (0, _utils.useLocalStorage)(_constants.IS_BACK_FROM_CHANGE_PAYMENT_PAGE_CCUI, false);
  var [companyProfile] = (0, _utils.useLocalStorage)('CompanyProfile', undefined);
  var firstRoom = (0, _utils.logicalOrOperator)(bcData === null || bcData === void 0 || (_bcData$reservationBy = bcData.reservationByIdList) === null || _bcData$reservationBy === void 0 ? void 0 : _bcData$reservationBy[0], {});
  var isCardHolderNameCompleted = isEckohRequired ? cardHolderNames.firstName !== '' && cardHolderNames.lastName !== '' && eckohCheckPassed : true;
  var billing = firstRoom === null || firstRoom === void 0 ? void 0 : firstRoom.billing;
  var [billingAddress, setBillingAddress] = (0, _react2.useState)({
    addressLine1: '',
    addressLine2: undefined,
    addressLine3: undefined,
    addressLine4: undefined,
    cityName: undefined,
    postalCode: '',
    countryCode: '',
    companyName: undefined,
    billingAddressSelection: ''
  });
  var [businessAllowances, setBusinessAllowances] = (0, _react2.useState)({
    totalDinnerBudgetPersonNight: '',
    isAlcoholDinner: false,
    carParking: false,
    ultimateWifi: false,
    mealDeal: false,
    premierInnBreakfast: false,
    continentalBreakfast: false
  });
  var [bookerReferencesDetails, setBookerReferencesDetails] = (0, _react2.useState)({
    purchaseOrderNumber: '',
    companyReference: ''
  });
  var hotelInformationDetails = hiData === null || hiData === void 0 ? void 0 : hiData.hotelInformation;
  var {
    data: eckohData
  } = (0, _utils.useQueryRequest)(['GetEckohStatus'], _api.GET_ECKOH_STATUS, {
    basketReference
  }, {
    enabled: isEnabledEckohQuery,
    gcTime: 0,
    refetchInterval: 3000,
    retry: 3,
    refetchIntervalInBackground: true
  });
  var {
    data: paymentStatus
  } = (0, _utils.useQueryRequest)(['GetPaymentStatus'], _api.GET_PAYMENT_STATUS, {
    basketReference
  }, {
    enabled: isEnabledPaymentStatus,
    gcTime: 0,
    refetchInterval: 3000,
    retry: 3,
    refetchIntervalInBackground: true
  });
  var {
    mutation: initiateIframeMutation
  } = (0, _utils.useMutationRequest)(_api.INITIATE_ECKOH_IFRAME);
  var {
    mutation: updateEmailMutation
  } = (0, _utils.useMutationRequest)(_api.UPDATE_EMAIL, true);
  var {
    mutation: mutationInitiatePaymentProcess,
    error: dataErrorInitiatePaymentProcess,
    isSuccess: mutationInitiatePaymentProcessIsSuccesful
  } = (0, _utils.useMutationRequest)(_api.CCUI_INITIATE_PAYMENT_PROCESS, true);
  var onIframeLoad = time => {
    setConfAnalytics(_objectSpread(_objectSpread({}, confAnalytics), {}, {
      paymentLoadTime: time,
      paymentSessionID: '',
      paymentTemplateID: ''
    }));
    _utils.analytics.update({
      paymentLoadTime: time,
      paymentSessionID: '',
      paymentTemplateID: ''
    });
  };
  var isLoading = (0, _utils.logicalOrOperator)(confirmationStarted, (paymentStatus === null || paymentStatus === void 0 ? void 0 : paymentStatus.basket.status) === _api.BASKET_STATUS.COMPLETED, (paymentStatus === null || paymentStatus === void 0 ? void 0 : paymentStatus.basket.status) === _api.BASKET_STATUS.PROCESSING, confirmationStarted && (paymentStatus === null || paymentStatus === void 0 ? void 0 : paymentStatus.basket.status) === _api.BASKET_STATUS.PAY_PENDING, dataErrorInitiatePaymentProcess, hasErrors);
  var hasCompanyReferenceErrors = selectedPaymentType.type === _api.CcuiCardType.ACCOUNT_COMPANY && companyReferenceError.current;
  (0, _react2.useEffect)(() => {
    return () => {
      var _intervalRef$current;
      clearInterval((_intervalRef$current = intervalRef.current) !== null && _intervalRef$current !== void 0 ? _intervalRef$current : '');
    };
  }, []);
  (0, _react2.useEffect)(() => {
    router.beforePopState(_ref2 => {
      var {
        as
      } = _ref2;
      if (as !== router.asPath) {
        setIsBackFlag(true);
      }
      return true;
    });
    return () => {
      router.beforePopState(() => true);
    };
  }, [router]);
  (0, _react2.useEffect)(() => {
    if ((billingAddress === null || billingAddress === void 0 ? void 0 : billingAddress.billingAddressSelection) === 'CurrentAddress') {
      var _bcData$reservationBy2;
      setBillingAddress((_bcData$reservationBy2 = bcData.reservationByIdList) === null || _bcData$reservationBy2 === void 0 || (_bcData$reservationBy2 = _bcData$reservationBy2[0]) === null || _bcData$reservationBy2 === void 0 || (_bcData$reservationBy2 = _bcData$reservationBy2.billing) === null || _bcData$reservationBy2 === void 0 ? void 0 : _bcData$reservationBy2.address);
    }
  }, [billingAddress]);
  (0, _react2.useEffect)(() => {
    var _selectedPaymentType$, _selectedPaymentType$2;
    typeOfCaller === '' ? setHasChecks(false) : setHasChecks(true);
    var defaultBusinessAllowances = {
      totalDinnerBudgetPersonNight: '',
      isAlcoholDinner: false,
      carParking: false,
      ultimateWifi: false,
      mealDeal: false,
      premierInnBreakfast: false,
      continentalBreakfast: false
    };
    switch (selectedPaymentType.type) {
      case _api.CcuiCardType.NEW_CARD:
        setHasChecks(true);
        setTypeOfCaller('');
        setCardType(_api.CardStatus.CARD_PRESENT);
        if (selectedPaymentType !== null && selectedPaymentType !== void 0 && (_selectedPaymentType$ = selectedPaymentType.paymentOptions) !== null && _selectedPaymentType$ !== void 0 && (_selectedPaymentType$ = _selectedPaymentType$[0]) !== null && _selectedPaymentType$ !== void 0 && _selectedPaymentType$.enabled || selectedPaymentType !== null && selectedPaymentType !== void 0 && (_selectedPaymentType$2 = selectedPaymentType.paymentOptions) !== null && _selectedPaymentType$2 !== void 0 && (_selectedPaymentType$2 = _selectedPaymentType$2[1]) !== null && _selectedPaymentType$2 !== void 0 && _selectedPaymentType$2.enabled) {
          setDisabledCardType(_api.CardStatus.CARD_NOT_PRESENT);
        }
        setEckohCheckPassed(false);
        setCardHolderNames({
          firstName: '',
          lastName: ''
        });
        setBusinessAllowances(defaultBusinessAllowances);
        setBookerReferencesDetails({
          purchaseOrderNumber: '',
          companyReference: ''
        });
        setShowTotalCost(true);
        break;
      case _api.CcuiCardType.ACCOUNT_COMPANY:
        setTypeOfCaller('');
        setHasChecks(true);
        setShowTotalCost(false);
        setEckohParameters(undefined);
        setIsEnabledEckohQuery(false);
        setCardHolderNames({
          firstName: '',
          lastName: ''
        });
        setBusinessAllowances(defaultBusinessAllowances);
        setBookerReferencesDetails({
          purchaseOrderNumber: '',
          companyReference: ''
        });
        break;
      case _api.CcuiCardType.NON_GUARANTEED:
        setShowTotalCost(true);
        setHasChecks(false);
        setEckohParameters(undefined);
        setIsEnabledEckohQuery(false);
        setCardHolderNames({
          firstName: '',
          lastName: ''
        });
        setTypeOfCaller(_api.TypeOfCaller.ANY_CUSTOMER);
        break;
    }
  }, [selectedPaymentType]);
  (0, _react2.useEffect)(() => {
    if (cardType === _api.CardStatus.CARD_PRESENT || cardType === _api.CardStatus.CARD_NOT_PRESENT) {
      var defaultBusinessAllowances = {
        totalDinnerBudgetPersonNight: '',
        isAlcoholDinner: false,
        carParking: false,
        ultimateWifi: false,
        mealDeal: false,
        premierInnBreakfast: false,
        continentalBreakfast: false
      };
      setEckohCheckPassed(false);
      setCardHolderNames({
        firstName: '',
        lastName: ''
      });
      setBusinessAllowances(defaultBusinessAllowances);
      setBookerReferencesDetails({
        purchaseOrderNumber: '',
        companyReference: ''
      });
    }
  }, [cardType]);
  (0, _react2.useEffect)(() => {
    if (hasErrors || dataErrorInitiatePaymentProcess) {
      router.push("/".concat(country, "/").concat(language, "/payment-errors?reservationId=").concat(basketReference));
    }
  }, [hasErrors, dataErrorInitiatePaymentProcess]);
  (0, _react2.useEffect)(() => {
    setConfAnalytics(_objectSpread(_objectSpread({}, confAnalytics), {}, {
      basketReference
    }));
  }, [paymentStatus]);
  (0, _react2.useEffect)(() => {
    if ((paymentStatus === null || paymentStatus === void 0 ? void 0 : paymentStatus.basket.status) === _api.BASKET_STATUS.COMPLETED && mutationInitiatePaymentProcessIsSuccesful) {
      setIsEnabledPaymentStatus(false);
      router.back();
    } else if ((paymentStatus === null || paymentStatus === void 0 ? void 0 : paymentStatus.basket.status) === _api.BASKET_STATUS.FAILED && confirmationStarted) {
      router.push("/".concat(country, "/").concat(language, "/payment-errors?reservationId=").concat(basketReference));
      setIsEnabledPaymentStatus(false);
    }
  }, [paymentStatus, confirmationStarted]);
  if (isLoading) {
    return (0, _jsxRuntime.jsx)(_molecules.PageLoader, {
      text: t('booking.loading')
    });
  }
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_react.Box, _objectSpread(_objectSpread({}, sectionStyles), {}, {
      "data-testid": baseTestId,
      children: (0, _jsxRuntime.jsx)(_molecules.PaymentDetails, {
        selectedPaymentDetail: selectedPaymentInfo,
        selectedPaymentType: selectedPaymentType,
        setSelectedPaymentDetail: setSelectedPaymentInfo,
        t: t,
        hideHeader: true,
        isCCUI: true
      })
    })), (0, _jsxRuntime.jsxs)(_react.Box, _objectSpread(_objectSpread({}, sectionStyles), {}, {
      children: [(0, _jsxRuntime.jsx)(_molecules.PaymentTypeContainer, {
        onPaymentTypeClick: setSelectedPaymentType,
        selectedPaymentType: selectedPaymentType,
        selectedPaymentDetail: selectedPaymentInfo,
        styles: {
          containerStyles: {
            w: '100%'
          }
        },
        initialPaymentType: paymentType,
        disabledOptions: disabledCardOptions,
        isFromChangePaymentBIC: !!changePaymentBIC
      }), isA2ContainerDisplayed && (0, _jsxRuntime.jsx)(_molecules.AccountToCompanyContainer, {
        hotelId: hotelCode !== null && hotelCode !== void 0 ? hotelCode : '',
        selectedPaymentDetail: selectedPaymentInfo === null || selectedPaymentInfo === void 0 ? void 0 : selectedPaymentInfo.type,
        setIsTotalCostVisible: setShowTotalCost,
        setACCharges: setACCharges,
        setCompanyReferenceError: setCompanyReferenceError,
        setCompanyNumber: setCompanyNumber,
        setCompanyId: setCompanyId,
        setCompanyDetails: setCompanyDetails,
        setACCompanyReference: setACCompanyReference,
        isFromChangePaymentBIC: !!changePaymentBIC
      }), isEckohRequired && (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
        children: [(0, _jsxRuntime.jsx)(_molecules.CardPresentSection, {
          value: cardType,
          setValue: onCardChange,
          disabledOption: disabledCardType,
          cardType: selectedPaymentType.type
        }), (0, _jsxRuntime.jsx)(_molecules.LaunchEckoh, {
          onSuccess: () => setEckohCheckPassed(true),
          onFail: () => setEckohCheckPassed(false),
          setIsEnabledEckohQuery: setIsEnabledEckohQuery,
          initiateIframe: initiateIframe,
          eckohParameters: eckohParameters,
          eckohStatus: eckohData === null || eckohData === void 0 || (_eckohData$eckohRecor = eckohData.eckohRecordingStatus) === null || _eckohData$eckohRecor === void 0 ? void 0 : _eckohData$eckohRecor.status,
          disabledEckoh: !cardType || (selectedPaymentInfo === null || selectedPaymentInfo === void 0 ? void 0 : selectedPaymentInfo.type) === 'default',
          onIframeLoad: onIframeLoad
        }), eckohCheckPassed && (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
          children: [(0, _jsxRuntime.jsx)(_molecules.CardHolderName, {
            cardHolderNames: cardHolderNames,
            setCardHolderNames: setCardHolderNames,
            setHasError: setHasCardHolderNameError
          }), (0, _jsxRuntime.jsx)(_molecules.BillingAddress, {
            currentBillingAddress: billing === null || billing === void 0 ? void 0 : billing.address,
            continueToNextStep: continueToNextStep,
            t: t,
            currentLang: language,
            setBillingAddress: setBillingAddress,
            companyProfile: companyProfile
          })]
        })]
      })]
    })), (0, _jsxRuntime.jsxs)(_react.Box, {
      children: [showTotalCost && (0, _jsxRuntime.jsx)(_molecules.TotalCost, {
        country: country,
        currencyCode: bcData === null || bcData === void 0 ? void 0 : bcData.currencyCode,
        emailSection: {
          setEmailAddress,
          emailAddress,
          emailError,
          setEmailError
        },
        hotelBrand: brand.toLowerCase(),
        hotelId: hotelCode,
        hotelName: hotelName,
        previousTotalCost: summaryOfPayments.previousTotal,
        totalCost: summaryOfPayments.totalCost,
        setSendEmail: setSendEmailApproval,
        hasAllChecks: hasChecks && isCardHolderNameCompleted && !hasCompanyReferenceErrors,
        language: language,
        sendEmail: sendEmailApproval,
        onConfirmClick: continueWithoutEckoh,
        isCompWithoutEckoh: !isEckohRequired,
        isFromChangePaymentBIC: !!changePaymentBIC
      }), (0, _jsxRuntime.jsx)(_molecules.BackToPage, {
        goBack: () => router.back(),
        linkText: t('booking.summary.back')
      })]
    })]
  });
  function continueWithoutEckoh() {
    onConfirmBooking(billingAddress);
  }
  function continueToNextStep(billingAddressNew) {
    if (!hasErrors) {
      onConfirmBooking(billingAddressNew !== null && billingAddressNew !== void 0 ? billingAddressNew : billingAddress);
    } else {
      router.push("/".concat(country, "/").concat(language, "/payment-errors?reservationId=").concat(basketReference));
    }
  }
  function onEmailUpdate() {
    return _onEmailUpdate.apply(this, arguments);
  }
  function _onEmailUpdate() {
    _onEmailUpdate = (0, _asyncToGenerator2.default)(function* () {
      try {
        yield updateEmailMutation.mutateAsync({
          basketReference,
          updateEmailCriteria: {
            email: emailAddress
          }
        });
        return true;
      } catch (_unused) {
        setEmailError(t('ccui.payment.emailAddress.error'));
        return false;
      }
    });
    return _onEmailUpdate.apply(this, arguments);
  }
  function getBillingData() {
    var _bcData$reservationBy3, _bcData$reservationBy4, _bcData$reservationBy5, _bcData$reservationBy6, _bcData$reservationBy7, _bcData$reservationBy8, _bcData$reservationBy9, _bcData$reservationBy10, _bcData$reservationBy11, _bcData$reservationBy12, _bcData$reservationBy13, _bcData$reservationBy14, _bcData$reservationBy15;
    var billingAddressCountry = bcData.reservationByIdList[0].billing.address.country || bcData.reservationByIdList[0].billing.address.countryCode;
    var paymentProcessCountryFromGD = billingAddressCountry === _api.ShortCountry.UK ? _api.ShortCountry.GB : billingAddressCountry;
    var billingData = {
      address: {
        addressLine1: (_bcData$reservationBy3 = bcData === null || bcData === void 0 || (_bcData$reservationBy4 = bcData.reservationByIdList[0]) === null || _bcData$reservationBy4 === void 0 || (_bcData$reservationBy4 = _bcData$reservationBy4.billing) === null || _bcData$reservationBy4 === void 0 || (_bcData$reservationBy4 = _bcData$reservationBy4.address) === null || _bcData$reservationBy4 === void 0 ? void 0 : _bcData$reservationBy4.addressLine1) !== null && _bcData$reservationBy3 !== void 0 ? _bcData$reservationBy3 : '',
        addressLine2: (_bcData$reservationBy5 = bcData === null || bcData === void 0 || (_bcData$reservationBy6 = bcData.reservationByIdList[0]) === null || _bcData$reservationBy6 === void 0 || (_bcData$reservationBy6 = _bcData$reservationBy6.billing) === null || _bcData$reservationBy6 === void 0 || (_bcData$reservationBy6 = _bcData$reservationBy6.address) === null || _bcData$reservationBy6 === void 0 ? void 0 : _bcData$reservationBy6.addressLine2) !== null && _bcData$reservationBy5 !== void 0 ? _bcData$reservationBy5 : '',
        addressLine3: (_bcData$reservationBy7 = bcData === null || bcData === void 0 || (_bcData$reservationBy8 = bcData.reservationByIdList[0]) === null || _bcData$reservationBy8 === void 0 || (_bcData$reservationBy8 = _bcData$reservationBy8.billing) === null || _bcData$reservationBy8 === void 0 || (_bcData$reservationBy8 = _bcData$reservationBy8.address) === null || _bcData$reservationBy8 === void 0 ? void 0 : _bcData$reservationBy8.addressLine3) !== null && _bcData$reservationBy7 !== void 0 ? _bcData$reservationBy7 : '',
        addressLine4: (_bcData$reservationBy9 = bcData === null || bcData === void 0 || (_bcData$reservationBy10 = bcData.reservationByIdList[0]) === null || _bcData$reservationBy10 === void 0 || (_bcData$reservationBy10 = _bcData$reservationBy10.billing) === null || _bcData$reservationBy10 === void 0 || (_bcData$reservationBy10 = _bcData$reservationBy10.address) === null || _bcData$reservationBy10 === void 0 ? void 0 : _bcData$reservationBy10.addressLine4) !== null && _bcData$reservationBy9 !== void 0 ? _bcData$reservationBy9 : '',
        cityName: (_bcData$reservationBy11 = bcData === null || bcData === void 0 || (_bcData$reservationBy12 = bcData.reservationByIdList[0]) === null || _bcData$reservationBy12 === void 0 || (_bcData$reservationBy12 = _bcData$reservationBy12.billing) === null || _bcData$reservationBy12 === void 0 || (_bcData$reservationBy12 = _bcData$reservationBy12.address) === null || _bcData$reservationBy12 === void 0 ? void 0 : _bcData$reservationBy12.cityName) !== null && _bcData$reservationBy11 !== void 0 ? _bcData$reservationBy11 : '',
        postalCode: bcData === null || bcData === void 0 || (_bcData$reservationBy13 = bcData.reservationByIdList[0]) === null || _bcData$reservationBy13 === void 0 || (_bcData$reservationBy13 = _bcData$reservationBy13.billing) === null || _bcData$reservationBy13 === void 0 ? void 0 : _bcData$reservationBy13.address.postalCode,
        country: paymentProcessCountryFromGD,
        companyName: '',
        addressType: ''
      },
      firstName: (0, _utils.logicalOrOperator)(bcData === null || bcData === void 0 || (_bcData$reservationBy14 = bcData.reservationByIdList[0]) === null || _bcData$reservationBy14 === void 0 || (_bcData$reservationBy14 = _bcData$reservationBy14.billing) === null || _bcData$reservationBy14 === void 0 ? void 0 : _bcData$reservationBy14.firstName, ''),
      lastName: (0, _utils.logicalOrOperator)(bcData === null || bcData === void 0 || (_bcData$reservationBy15 = bcData.reservationByIdList[0]) === null || _bcData$reservationBy15 === void 0 || (_bcData$reservationBy15 = _bcData$reservationBy15.billing) === null || _bcData$reservationBy15 === void 0 ? void 0 : _bcData$reservationBy15.lastName, ''),
      email: (0, _utils.logicalOrOperator)(billing.email, ''),
      title: (0, _utils.logicalOrOperator)(billing.title, ''),
      telephone: (0, _utils.logicalOrOperator)(billing.telephone, ''),
      differentBillingAddress: billing.differentBillingAddress || (billingAddress === null || billingAddress === void 0 ? void 0 : billingAddress.billingAddressSelection) === 'DifferentAddress' || isA2CPayment
    };
    if (isA2CPayment && companyDetails !== null && companyDetails !== void 0 && companyDetails.address) {
      var _companyDetails$name;
      billingData.address = _objectSpread(_objectSpread({}, companyDetails.address), {}, {
        companyName: (_companyDetails$name = companyDetails === null || companyDetails === void 0 ? void 0 : companyDetails.name) !== null && _companyDetails$name !== void 0 ? _companyDetails$name : '',
        addressType: 'BUSINESS'
      });
    }
    return billingData;
  }
  function getPaymentRequest(bA) {
    var _cardHolderNames$last, _cardHolderNames$firs, _bA$addressLine, _bA$addressLine2, _bA$addressLine3, _bA$cityName, _bA$postalCode, _hotelInformationDeta, _hotelInformationDeta2, _hotelInformationDeta3;
    var arrayRandom = new Uint32Array(1);
    var randomInt = window.crypto.getRandomValues(arrayRandom)[0];
    var paymentProcessCountry = (billingAddress.country || billingAddress.countryCode) === _api.ShortCountry.UK ? _api.ShortCountry.GB : (0, _utils.logicalOrOperator)(billingAddress.country, billingAddress.countryCode);
    return {
      requestId: randomInt,
      payment: {
        type: selectedPaymentType.name,
        subType: 'MOTO',
        billing: getBillingData(),
        card: {
          cardHolderLastName: (_cardHolderNames$last = cardHolderNames.lastName) !== null && _cardHolderNames$last !== void 0 ? _cardHolderNames$last : '',
          cardHolderFirstName: (_cardHolderNames$firs = cardHolderNames.firstName) !== null && _cardHolderNames$firs !== void 0 ? _cardHolderNames$firs : '',
          cardHolderAddress: {
            addressLine1: bA.addressLine1,
            addressLine2: (_bA$addressLine = bA.addressLine2) !== null && _bA$addressLine !== void 0 ? _bA$addressLine : null,
            addressLine3: (_bA$addressLine2 = bA.addressLine3) !== null && _bA$addressLine2 !== void 0 ? _bA$addressLine2 : null,
            addressLine4: (_bA$addressLine3 = bA.addressLine4) !== null && _bA$addressLine3 !== void 0 ? _bA$addressLine3 : null,
            cityName: (_bA$cityName = bA.cityName) !== null && _bA$cityName !== void 0 ? _bA$cityName : null,
            postalCode: (_bA$postalCode = bA.postalCode) !== null && _bA$postalCode !== void 0 ? _bA$postalCode : null,
            country: String(paymentProcessCountry)
          }
        }
      },
      booking: {
        type: selectedPaymentInfo === null || selectedPaymentInfo === void 0 ? void 0 : selectedPaymentInfo.type,
        journey: 'BOOKING',
        channel: 'CCC',
        language: language,
        businessSite: {
          identifier: bcData.hotelId,
          type: 'HOTEL',
          name: (_hotelInformationDeta = hotelInformationDetails === null || hotelInformationDetails === void 0 ? void 0 : hotelInformationDetails.name) !== null && _hotelInformationDeta !== void 0 ? _hotelInformationDeta : '',
          location: (_hotelInformationDeta2 = hotelInformationDetails === null || hotelInformationDetails === void 0 || (_hotelInformationDeta3 = hotelInformationDetails.address) === null || _hotelInformationDeta3 === void 0 ? void 0 : _hotelInformationDeta3.addressLine1) !== null && _hotelInformationDeta2 !== void 0 ? _hotelInformationDeta2 : ''
        }
      }
    };
  }
  function onConfirmBooking(_x) {
    return _onConfirmBooking.apply(this, arguments);
  }
  function _onConfirmBooking() {
    _onConfirmBooking = (0, _asyncToGenerator2.default)(function* (bA) {
      var _bA$companyName, _firstRoom$billing;
      var hasNoCardPresentOption = selectedPaymentType.type !== _api.CcuiCardType.ACCOUNT_COMPANY && selectedPaymentType.type !== _api.CcuiCardType.NON_GUARANTEED;
      var cardPresentStatus = hasNoCardPresentOption && cardType === _api.CardStatus.CARD_PRESENT;
      var selectedPaymentTypeName = selectedPaymentType.name === _api.CcuiCardType.PIBA_EU ? _api.EckohPayMethod.PIBA_DE : _api.EckohPayMethod.PIBA_GB;
      var subPaymentType = selectedPaymentType.type === _api.CcuiCardType.NEW_PIBA ? selectedPaymentTypeName : null;
      var isA2CPayment = selectedPaymentType.type === _api.CcuiCardType.ACCOUNT_COMPANY;
      var accountCompanyItems = isA2CPayment ? {
        companyNumber,
        companyId,
        charges: acCharges.join(','),
        businessItems: {
          businessAllowances: (0, _utils.getA2CBusinessAllowances)(acCharges),
          customReferenceNumber: acCompanyReference,
          purchaseOrderNumber: ''
        }
      } : {
        companyNumber: '',
        companyId: '',
        charges: '',
        businessItems: null
      };
      var bookingDetails = {
        paymentOption: selectedPaymentType.type,
        subPaymentType: subPaymentType,
        ccuiExtraItems: {
          sendMail: sendEmailApproval !== null && sendEmailApproval !== void 0 ? sendEmailApproval : false,
          cardPresent: cardPresentStatus,
          accountCompanyItems,
          nonguaranteedItems: {
            typeOfCaller: typeOfCaller !== null && typeOfCaller !== void 0 ? typeOfCaller : null
          },
          addressCompanyName: (_bA$companyName = bA.companyName) !== null && _bA$companyName !== void 0 ? _bA$companyName : null,
          businessItems: {
            businessAllowances: [{
              allowance: 'carParking',
              budget: 0,
              isAuthorised: businessAllowances.carParking
            }, {
              allowance: 'ultimateWifi',
              budget: 0,
              isAuthorised: businessAllowances.ultimateWifi
            }, {
              allowance: 'mealDeal',
              budget: 0,
              isAuthorised: businessAllowances.mealDeal
            }, {
              allowance: 'premierInnBreakfast',
              budget: 0,
              isAuthorised: businessAllowances.premierInnBreakfast
            }, {
              allowance: 'continentalBreakfast',
              budget: 0,
              isAuthorised: businessAllowances.continentalBreakfast
            }, {
              allowance: 'alcohol',
              budget: 0,
              isAuthorised: businessAllowances.isAlcoholDinner
            }, {
              allowance: 'dinner',
              budget: +businessAllowances.totalDinnerBudgetPersonNight,
              isAuthorised: +businessAllowances.totalDinnerBudgetPersonNight > 0
            }],
            customReferenceNumber: bookerReferencesDetails.companyReference,
            purchaseOrderNumber: bookerReferencesDetails.purchaseOrderNumber
          }
        },
        paymentRequest: getPaymentRequest(bA)
      };
      var businessItems = null;
      if (isA2CPayment) {
        var _bookingDetails$ccuiE8;
        businessItems = (_bookingDetails$ccuiE8 = bookingDetails.ccuiExtraItems) === null || _bookingDetails$ccuiE8 === void 0 || (_bookingDetails$ccuiE8 = _bookingDetails$ccuiE8.accountCompanyItems) === null || _bookingDetails$ccuiE8 === void 0 ? void 0 : _bookingDetails$ccuiE8.businessItems;
      }
      if (emailAddress && emailAddress !== (firstRoom === null || firstRoom === void 0 || (_firstRoom$billing = firstRoom.billing) === null || _firstRoom$billing === void 0 ? void 0 : _firstRoom$billing.email) && !emailError) {
        if (yield onEmailUpdate()) {
          handleInitiatePaymentProcess(bookingDetails, businessItems);
        }
      } else {
        handleInitiatePaymentProcess(bookingDetails, businessItems);
      }
    });
    return _onConfirmBooking.apply(this, arguments);
  }
  function handleInitiatePaymentProcess(bookingDetails, businessItems) {
    var _bookingDetails$ccuiE, _bookingDetails$ccuiE2, _bookingDetails$ccuiE3, _bookingDetails$ccuiE4, _bookingDetails$ccuiE5, _bookingDetails$ccuiE6, _bookingDetails$ccuiE7;
    var cardHolderLastName = bookingDetails.paymentRequest.payment.card.cardHolderLastName;
    var cardHolderFirstName = bookingDetails.paymentRequest.payment.card.cardHolderFirstName;
    var addressLine1 = bookingDetails.paymentRequest.payment.card.cardHolderAddress.addressLine1;
    var addressLine2 = bookingDetails.paymentRequest.payment.card.cardHolderAddress.addressLine2;
    var addressLine3 = bookingDetails.paymentRequest.payment.card.cardHolderAddress.addressLine3;
    var addressLine4 = bookingDetails.paymentRequest.payment.card.cardHolderAddress.addressLine4;
    var cityName = bookingDetails.paymentRequest.payment.card.cardHolderAddress.cityName;
    var postalCode = bookingDetails.paymentRequest.payment.card.cardHolderAddress.postalCode;
    var country = bookingDetails.paymentRequest.payment.card.cardHolderAddress.country;
    mutationInitiatePaymentProcess.mutateAsync({
      basketReference: basketReference,
      paymentOption: bookingDetails.paymentOption,
      subPaymentType: bookingDetails.subPaymentType,
      sendMail: (_bookingDetails$ccuiE = bookingDetails.ccuiExtraItems) === null || _bookingDetails$ccuiE === void 0 ? void 0 : _bookingDetails$ccuiE.sendMail,
      cardPresent: (_bookingDetails$ccuiE2 = bookingDetails.ccuiExtraItems) === null || _bookingDetails$ccuiE2 === void 0 ? void 0 : _bookingDetails$ccuiE2.cardPresent,
      companyNumber: (_bookingDetails$ccuiE3 = bookingDetails.ccuiExtraItems) === null || _bookingDetails$ccuiE3 === void 0 || (_bookingDetails$ccuiE3 = _bookingDetails$ccuiE3.accountCompanyItems) === null || _bookingDetails$ccuiE3 === void 0 ? void 0 : _bookingDetails$ccuiE3.companyNumber,
      companyId: (_bookingDetails$ccuiE4 = bookingDetails.ccuiExtraItems) === null || _bookingDetails$ccuiE4 === void 0 || (_bookingDetails$ccuiE4 = _bookingDetails$ccuiE4.accountCompanyItems) === null || _bookingDetails$ccuiE4 === void 0 ? void 0 : _bookingDetails$ccuiE4.companyId,
      charges: (_bookingDetails$ccuiE5 = bookingDetails.ccuiExtraItems) === null || _bookingDetails$ccuiE5 === void 0 || (_bookingDetails$ccuiE5 = _bookingDetails$ccuiE5.accountCompanyItems) === null || _bookingDetails$ccuiE5 === void 0 ? void 0 : _bookingDetails$ccuiE5.charges,
      typeOfCaller: (_bookingDetails$ccuiE6 = bookingDetails.ccuiExtraItems) === null || _bookingDetails$ccuiE6 === void 0 || (_bookingDetails$ccuiE6 = _bookingDetails$ccuiE6.nonguaranteedItems) === null || _bookingDetails$ccuiE6 === void 0 ? void 0 : _bookingDetails$ccuiE6.typeOfCaller,
      addressCompanyName: (_bookingDetails$ccuiE7 = bookingDetails.ccuiExtraItems) === null || _bookingDetails$ccuiE7 === void 0 ? void 0 : _bookingDetails$ccuiE7.addressCompanyName,
      requestId: bookingDetails.paymentRequest.requestId.toString(),
      type: bookingDetails.paymentRequest.payment.type,
      subType: bookingDetails.paymentRequest.payment.subType,
      firstName: bookingDetails.paymentRequest.payment.billing.firstName,
      lastName: bookingDetails.paymentRequest.payment.billing.lastName,
      title: bookingDetails.paymentRequest.payment.billing.title,
      email: emailAddress !== null && emailAddress !== void 0 ? emailAddress : bookingDetails.paymentRequest.payment.billing.email,
      telephone: bookingDetails.paymentRequest.payment.billing.telephone,
      differentBillingAddress: bookingDetails.paymentRequest.payment.billing.differentBillingAddress,
      addressLine1: bookingDetails.paymentRequest.payment.billing.address.addressLine1,
      addressLine2: bookingDetails.paymentRequest.payment.billing.address.addressLine2,
      addressLine3: bookingDetails.paymentRequest.payment.billing.address.addressLine3,
      addressLine4: bookingDetails.paymentRequest.payment.billing.address.addressLine4,
      cityName: bookingDetails.paymentRequest.payment.billing.address.cityName,
      postalCode: bookingDetails.paymentRequest.payment.billing.address.postalCode,
      country: bookingDetails.paymentRequest.payment.billing.address.country,
      companyName: bookingDetails.paymentRequest.payment.billing.address.companyName,
      addressType: bookingDetails.paymentRequest.payment.billing.address.addressType,
      card: {
        cardHolderLastName,
        cardHolderFirstName,
        cardHolderAddress: {
          addressLine1,
          addressLine2,
          addressLine3,
          addressLine4,
          cityName,
          postalCode,
          country
        }
      },
      bookingType: bookingDetails.paymentRequest.booking.type,
      journey: bookingDetails.paymentRequest.booking.journey,
      channel: bookingDetails.paymentRequest.booking.channel,
      language: bookingDetails.paymentRequest.booking.language,
      identifier: bookingDetails.paymentRequest.booking.businessSite.identifier,
      bookingBusinessSiteType: bookingDetails.paymentRequest.booking.businessSite.type,
      name: bookingDetails.paymentRequest.booking.businessSite.name,
      location: bookingDetails.paymentRequest.booking.businessSite.location,
      businessItems
    }).then(() => {
      setIsEnabledPaymentStatus(true);
    });
    setEckohParameters(undefined);
    setConfirmationStarted(true);
  }
  function initiateIframe() {
    var _hotelInformationDeta4, _hotelInformationDeta5;
    var arrayRandom = new Uint32Array(1);
    var randomInt = window.crypto.getRandomValues(arrayRandom)[0];
    var requestId = randomInt;
    var paymentTypeAPI = selectedPaymentType.name;
    var selectedPaymentMethod = getPaymentMethodType(selectedPaymentType);
    initiateIframeMutation.mutateAsync({
      basketReference,
      agentEmail: user.email,
      agentName: user.name,
      country,
      identifier: hotelCode,
      location: (_hotelInformationDeta4 = hotelInformationDetails === null || hotelInformationDetails === void 0 || (_hotelInformationDeta5 = hotelInformationDetails.address) === null || _hotelInformationDeta5 === void 0 ? void 0 : _hotelInformationDeta5.addressLine1) !== null && _hotelInformationDeta4 !== void 0 ? _hotelInformationDeta4 : '',
      hotelName: (0, _utils.replaceWithEmptyString)(hotelInformationDetails === null || hotelInformationDetails === void 0 ? void 0 : hotelInformationDetails.name),
      reservationType: 'HOTEL',
      channel: 'PI',
      journey: 'BOOKING',
      language,
      paymentType: selectedPaymentInfo === null || selectedPaymentInfo === void 0 ? void 0 : selectedPaymentInfo.type,
      requestId,
      paymentMethod: paymentTypeAPI
    }).then(data => {
      var _data$initiateEckohPa;
      var paymentId = (_data$initiateEckohPa = data.initiateEckohPayment) === null || _data$initiateEckohPa === void 0 ? void 0 : _data$initiateEckohPa.paymentId;
      setEckohParameters(new _api.EckohParameters((0, _utils.replaceWithEmptyString)(hotelInformationDetails === null || hotelInformationDetails === void 0 ? void 0 : hotelInformationDetails.name) || '', (publicRuntimeConfig === null || publicRuntimeConfig === void 0 ? void 0 : publicRuntimeConfig.ECKOH_CLIENT_ID) || '', user.email, language, paymentId !== null && paymentId !== void 0 ? paymentId : '', selectedPaymentInfo === null || selectedPaymentInfo === void 0 ? void 0 : selectedPaymentInfo.type, selectedPaymentMethod, cardType, hotelInformationDetails === null || hotelInformationDetails === void 0 ? void 0 : hotelInformationDetails.country, (publicRuntimeConfig === null || publicRuntimeConfig === void 0 ? void 0 : publicRuntimeConfig.ECKOH_PROD) === 'true' ? "mWB-".concat(hotelCode) : "mWB-DEFAULT", publicRuntimeConfig === null || publicRuntimeConfig === void 0 ? void 0 : publicRuntimeConfig.ECKOH_ENV));
      setIsEnabledEckohQuery(true);
      setConfAnalytics(_objectSpread(_objectSpread({}, confAnalytics), {}, {
        echoID: paymentId !== null && paymentId !== void 0 ? paymentId : ''
      }));
      _utils.analytics.update({
        echoID: paymentId !== null && paymentId !== void 0 ? paymentId : ''
      });
    });
  }
  function getPaymentMethodType(selectedPaymentType) {
    if (selectedPaymentType.subType != null) {
      return selectedPaymentType.subType;
    } else return selectedPaymentType.type;
  }
  function onCardChange(type) {
    setCardType(type);
  }
}
var sectionStyles = {
  width: '24.5rem',
  pt: 'xl'
};