"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.CardStatus = void 0;
var CardStatus = exports.CardStatus = function (CardStatus) {
  CardStatus["CARD_PRESENT"] = "CARD_PRESENT";
  CardStatus["CARD_NOT_PRESENT"] = "CARD_NOT_PRESENT";
  return CardStatus;
}({});