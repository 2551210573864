"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
var _donation = require("./donation");
Object.keys(_donation).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _donation[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _donation[key];
    }
  });
});
var _paymentInfoMessages = require("./paymentInfoMessages");
Object.keys(_paymentInfoMessages).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _paymentInfoMessages[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _paymentInfoMessages[key];
    }
  });
});
var _paymentTypes = require("./paymentTypes");
Object.keys(_paymentTypes).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _paymentTypes[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _paymentTypes[key];
    }
  });
});
var _termsAndConditions = require("./termsAndConditions");
Object.keys(_termsAndConditions).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _termsAndConditions[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _termsAndConditions[key];
    }
  });
});
var _getBookingInformation = require("./getBookingInformation");
Object.keys(_getBookingInformation).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _getBookingInformation[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _getBookingInformation[key];
    }
  });
});
var _createPayment = require("./createPayment");
Object.keys(_createPayment).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _createPayment[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _createPayment[key];
    }
  });
});
var _fraudSecurityCheck = require("./fraudSecurityCheck");
Object.keys(_fraudSecurityCheck).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _fraudSecurityCheck[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _fraudSecurityCheck[key];
    }
  });
});
var _getEckohStatus = require("./getEckohStatus");
Object.keys(_getEckohStatus).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _getEckohStatus[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _getEckohStatus[key];
    }
  });
});
var _getPaymentStatus = require("./getPaymentStatus");
Object.keys(_getPaymentStatus).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _getPaymentStatus[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _getPaymentStatus[key];
    }
  });
});
var _initiateEckohIframe = require("./initiateEckohIframe");
Object.keys(_initiateEckohIframe).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _initiateEckohIframe[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _initiateEckohIframe[key];
    }
  });
});
var _initiatePaymentProcess = require("./initiatePaymentProcess");
Object.keys(_initiatePaymentProcess).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _initiatePaymentProcess[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _initiatePaymentProcess[key];
    }
  });
});
var _searchAccountCompany = require("./searchAccountCompany");
Object.keys(_searchAccountCompany).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _searchAccountCompany[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _searchAccountCompany[key];
    }
  });
});
var _getResourceIdByRoles = require("./getResourceIdByRoles");
Object.keys(_getResourceIdByRoles).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _getResourceIdByRoles[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _getResourceIdByRoles[key];
    }
  });
});
var _updateDiscount = require("./updateDiscount");
Object.keys(_updateDiscount).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _updateDiscount[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _updateDiscount[key];
    }
  });
});
var _basketStatus = require("./basketStatus");
Object.keys(_basketStatus).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _basketStatus[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _basketStatus[key];
    }
  });
});
var _createPaypalPayment = require("./createPaypalPayment");
Object.keys(_createPaypalPayment).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _createPaypalPayment[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _createPaypalPayment[key];
    }
  });
});
var _updateEmailResponse = require("./updateEmailResponse");
Object.keys(_updateEmailResponse).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _updateEmailResponse[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _updateEmailResponse[key];
    }
  });
});