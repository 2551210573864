"use strict";
'use client';

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.DatePickerWithRange = DatePickerWithRange;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _api = require("@whitbread-eos/api");
var _utils = require("@whitbread-eos/utils");
var _server = require("@whitbread-eos/utils/server");
var _dateFns = require("date-fns");
var _locale = require("date-fns/locale");
var _image = _interopRequireDefault(require("next/image"));
var _react = _interopRequireWildcard(require("react"));
var React = _react;
var _Button = require("../Button");
var _Calendar = require("../Calendar");
var _Popover = require("../Popover");
var _Tooltip = require("../Tooltip");
var _createCustomLocale = require("./createCustomLocale");
var _jsxRuntime = require("react/jsx-runtime");
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && {}.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
var today = new Date();
var isFirstDayInMonth = day => {
  var date = new Date(day);
  var firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
  return date.getDate() === firstDay.getDate();
};
var isLastDayInMonth = day => {
  var date = new Date(day);
  var lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
  return date.getDate() === lastDay.getDate();
};
var isCurrentMonth = (currentMonth, today) => {
  return currentMonth.getFullYear() === today.getFullYear() && currentMonth.getMonth() === today.getMonth();
};
var isBeforeToday = day => {
  today.setHours(0, 0, 0, 0);
  return day < today;
};
var isAfter364Days = day => {
  var oneYearLater = new Date(today);
  oneYearLater.setDate(today.getDate() + 364);
  return day > oneYearLater;
};
var shouldHideNextMonthIcon = currentMonth => {
  var lastVisibleDayOfMonth = new Date(currentMonth.getFullYear(), currentMonth.getMonth() + 1, 0);
  return isAfter364Days(lastVisibleDayOfMonth);
};
var textSeparator = (firstText, secondText) => {
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [firstText, (0, _jsxRuntime.jsx)("div", {
      className: separatorStyle,
      children: "|"
    }), secondText]
  });
};
function DatePickerWithRange(_ref) {
  var _formLabels$calendarI;
  var {
    className,
    locale,
    formLabels,
    chevronIcons,
    notificationIcons,
    mobile = false,
    onDateChange,
    dateFromUrl,
    showError,
    setShowError,
    onOpenChange
  } = _ref;
  var {
    months = [],
    weekdaysShort = []
  } = (formLabels === null || formLabels === void 0 ? void 0 : formLabels.datePicker) || {};
  var localeFormatted = isShortMonth => {
    return locale === _api.LOCALES.EN ? (0, _createCustomLocale.createCustomLocale)(_locale.enGB, months, weekdaysShort, isShortMonth) : (0, _createCustomLocale.createCustomLocale)(_locale.de, months, weekdaysShort, isShortMonth);
  };
  var {
    t
  } = (0, _server.useTranslation)();
  var [date, setDate] = (0, _react.useState)({
    from: undefined,
    to: undefined
  });
  (0, _react.useEffect)(() => {
    setDate({
      from: dateFromUrl === null || dateFromUrl === void 0 ? void 0 : dateFromUrl.from,
      to: dateFromUrl === null || dateFromUrl === void 0 ? void 0 : dateFromUrl.to
    });
  }, [dateFromUrl]);
  var [isResetDisabled, setIsResetDisabled] = (0, _react.useState)(true);
  var [hoverDate, setHoverDate] = (0, _react.useState)(undefined);
  var [popoverOpen, setPopoverOpen] = (0, _react.useState)(false);
  var [currentMonth, setCurrentMonth] = (0, _react.useState)(new Date());
  var find364DayOfPeriod = new Date(today);
  find364DayOfPeriod.setDate(today.getDate() + 364);
  var find365DayOfPeriod = new Date(find364DayOfPeriod.getTime() + 1000 * 60 * 60 * 24);
  var last14DaysStart = new Date(find364DayOfPeriod);
  last14DaysStart.setDate(find364DayOfPeriod.getDate() - 13);
  function resetCalendar() {
    setDate({
      from: undefined,
      to: undefined
    });
    setShowError(false);
    onDateChange({
      from: undefined,
      to: undefined
    });
    setIsResetDisabled(true);
    setCurrentMonth(today);
  }
  var handleDayClick = newDate => {
    setHoverDate(undefined);
    setIsResetDisabled(false);
    if (date !== null && date !== void 0 && date.to && newDate.getTime() === find365DayOfPeriod.getTime()) return;
    if (!(date !== null && date !== void 0 && date.from) || date !== null && date !== void 0 && date.to) {
      setDate({
        from: newDate,
        to: undefined
      });
      onDateChange({
        from: newDate,
        to: undefined
      });
      setShowError(false);
      return;
    }
    if (date !== null && date !== void 0 && date.from && !(date !== null && date !== void 0 && date.to) && newDate < (date === null || date === void 0 ? void 0 : date.from)) {
      setDate({
        from: undefined,
        to: undefined
      });
      onDateChange({
        from: undefined,
        to: undefined
      });
      setIsResetDisabled(true);
      setShowError(false);
      return;
    }
    if (date !== null && date !== void 0 && date.from && !(date !== null && date !== void 0 && date.to) && newDate > (date === null || date === void 0 ? void 0 : date.from)) {
      setDate(_objectSpread(_objectSpread({}, date), {}, {
        to: newDate
      }));
      onDateChange(_objectSpread(_objectSpread({}, date), {}, {
        to: newDate
      }));
      setShowError(false);
      return;
    }
    if (!(date !== null && date !== void 0 && date.from) || !(date !== null && date !== void 0 && date.to)) {
      setDate({
        from: undefined,
        to: undefined
      });
      onDateChange({
        from: undefined,
        to: undefined
      });
      setIsResetDisabled(true);
      setShowError(false);
      return;
    }
    setDate({
      from: newDate,
      to: undefined
    });
    onDateChange({
      from: newDate,
      to: undefined
    });
    setShowError(false);
  };
  var handleDayEnter = hoverDay => {
    if (!(date !== null && date !== void 0 && date.from) || date !== null && date !== void 0 && date.to) {
      return;
    }
    if (hoverDay.getTime() === (date === null || date === void 0 ? void 0 : date.from.getTime())) {
      setHoverDate(undefined);
      return;
    }
    setHoverDate(hoverDay);
  };
  var _isInHoverRange = day => {
    if (!(date !== null && date !== void 0 && date.from) || !hoverDate) {
      return false;
    }
    var checkedDay = new Date(day);
    return checkedDay >= (date === null || date === void 0 ? void 0 : date.from) && checkedDay <= hoverDate;
  };
  var renderInputInfo = () => {
    var todayFormatted = (0, _dateFns.format)(new Date(), _api.DATE_TYPE.D_MMM_YY, {
      locale: localeFormatted(true)
    });
    var tomorrowFormatted = (0, _dateFns.format)((0, _dateFns.addDays)(new Date(), 1), _api.DATE_TYPE.D_MMM_YY, {
      locale: localeFormatted(true)
    });
    var dateFromFormatted = (date === null || date === void 0 ? void 0 : date.from) && (0, _dateFns.format)(date === null || date === void 0 ? void 0 : date.from, _api.DATE_TYPE.D_MMM_YY, {
      locale: localeFormatted(true)
    });
    var dateToFormatted = (date === null || date === void 0 ? void 0 : date.to) && (0, _dateFns.format)(date === null || date === void 0 ? void 0 : date.to, _api.DATE_TYPE.D_MMM_YY, {
      locale: localeFormatted(true)
    });
    if (dateFromFormatted === todayFormatted && dateToFormatted === tomorrowFormatted) {
      return textSeparator(t('content.global.today'), t('content.global.tomorrow'));
    }
    if (dateFromFormatted && !dateToFormatted && dateFromFormatted === todayFormatted) {
      return textSeparator(t('content.global.today'), t('content.form.datePicker.checkOut'));
    }
    if (dateFromFormatted && dateToFormatted && dateFromFormatted === todayFormatted) {
      return textSeparator(t('content.global.today'), dateToFormatted);
    }
    if (dateFromFormatted && !dateToFormatted && dateFromFormatted === tomorrowFormatted) {
      return textSeparator(t('content.global.tomorrow'), t('content.form.datePicker.checkOut'));
    }
    if (dateFromFormatted && !dateToFormatted && dateFromFormatted !== tomorrowFormatted) {
      return textSeparator(dateFromFormatted, t('content.form.datePicker.checkOut'));
    }
    if (dateFromFormatted && dateToFormatted && dateFromFormatted === tomorrowFormatted) {
      return textSeparator(t('content.global.tomorrow'), dateToFormatted);
    }
    if (dateFromFormatted && dateToFormatted && dateFromFormatted !== tomorrowFormatted) {
      return textSeparator(dateFromFormatted, dateToFormatted);
    }
    if (!dateFromFormatted && !dateToFormatted) {
      return textSeparator(t('content.global.today'), t('content.global.tomorrow'));
    }
    return dateFromFormatted && dateToFormatted && textSeparator(dateFromFormatted, dateToFormatted);
  };
  var handleMonthChange = month => {
    setCurrentMonth(month);
  };
  var isInTheNextNDays = (day, numberOfDays) => {
    if (!(date !== null && date !== void 0 && date.from)) return;
    var dayDifference = (day.getTime() - (date === null || date === void 0 ? void 0 : date.from.getTime())) / (1000 * 3600 * 24);
    return dayDifference >= 0 && dayDifference <= numberOfDays;
  };
  var disabledDays = day => {
    if (date !== null && date !== void 0 && date.to && day.getTime() === find365DayOfPeriod.getTime()) return true;
    if (date !== null && date !== void 0 && date.to && day > (date === null || date === void 0 ? void 0 : date.to)) return isAfter364Days(day);
    if (date !== null && date !== void 0 && date.to) return isBeforeToday(day);
    if (date !== null && date !== void 0 && date.from && (date === null || date === void 0 ? void 0 : date.from) >= last14DaysStart && (date === null || date === void 0 ? void 0 : date.from) <= find364DayOfPeriod && day.getTime() === find365DayOfPeriod.getTime()) return false;
    return isBeforeToday(day) || (date === null || date === void 0 ? void 0 : date.from) && !isInTheNextNDays(day, 14) || isAfter364Days(day);
  };
  var isInLastEligibleMonth = checkInDate => {
    var lastEligibleMonthStart = new Date(find364DayOfPeriod);
    lastEligibleMonthStart.setMonth(find364DayOfPeriod.getMonth());
    lastEligibleMonthStart.setDate(1);
    var lastEligibleMonthEnd = new Date(find364DayOfPeriod);
    lastEligibleMonthEnd.setMonth(find364DayOfPeriod.getMonth() + 1);
    lastEligibleMonthEnd.setDate(0);
    return checkInDate >= lastEligibleMonthStart && checkInDate <= lastEligibleMonthEnd;
  };
  var {
    height: headerHeight
  } = (0, _utils.useElementDimensions)('header');
  var renderButton = (0, _jsxRuntime.jsxs)(_Button.Button, {
    "data-testid": "IB-Date-Picker-Input",
    id: "date",
    variant: "inputCalendarButton",
    className: (0, _server.cn)((0, _Button.buttonVariants)({
      variant: 'inputCalendarButton',
      size: 'inputCalendarButton'
    }), 'hover:border-darkGrey1 mobile:rounded w-full', !date && 'text-muted-foreground', showError && '-outline-offset-2 outline outline-2 outline-error', popoverOpen && 'outline outline-2 outline-primaryColor hover:outline-primaryColor outline-offset-[-2px] border-transparent'),
    children: [(0, _jsxRuntime.jsx)(_image.default, {
      className: "mr-3 h-6 w-6",
      loading: "eager",
      src: (0, _server.formatIBAssetsUrl)((_formLabels$calendarI = formLabels === null || formLabels === void 0 ? void 0 : formLabels.calendarIcon) !== null && _formLabels$calendarI !== void 0 ? _formLabels$calendarI : ''),
      alt: "CalendarIcon",
      width: 24,
      height: 24,
      "data-testid": "IB-Date-Picker-Calendar-Icon"
    }), (0, _jsxRuntime.jsx)("div", {
      className: textInput,
      "data-testid": "IB-Date-Picker-Text",
      children: renderInputInfo()
    })]
  });
  (0, _react.useEffect)(() => {
    onOpenChange(popoverOpen);
  }, [popoverOpen]);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)("div", {
      className: (0, _server.cn)('flex gap-2', className),
      "data-testid": 'IB-Date-Picker-Wrapper',
      children: (0, _jsxRuntime.jsxs)(_Popover.Popover, {
        open: popoverOpen,
        onOpenChange: setPopoverOpen,
        children: [showError ? (0, _jsxRuntime.jsx)(_Tooltip.ErrorTooltip, {
          icon: (0, _server.formatIBAssetsUrl)(notificationIcons === null || notificationIcons === void 0 ? void 0 : notificationIcons.error),
          className: errorTooltipStyle,
          content: t('content.form.invalidNights'),
          open: showError,
          testId: "IB-Date-Picker-ErrorTooltip",
          children: (0, _jsxRuntime.jsx)(_Popover.PopoverTrigger, {
            asChild: true,
            children: renderButton
          })
        }) : (0, _jsxRuntime.jsx)(_Popover.PopoverTrigger, {
          asChild: true,
          children: renderButton
        }), (0, _jsxRuntime.jsxs)(_Popover.PopoverContent, {
          className: containerMobileStyle,
          align: "start",
          "data-testid": 'IB-Date-Picker-Container',
          popoverStyling: mobile ? {
            height: "calc(100svh - ".concat(headerHeight, "px)")
          } : {},
          children: [(0, _jsxRuntime.jsx)(_Calendar.Calendar, {
            onDayMouseEnter: handleDayEnter,
            mode: "range",
            month: currentMonth,
            onMonthChange: handleMonthChange,
            defaultMonth: date === null || date === void 0 ? void 0 : date.from,
            selected: date,
            onDayClick: handleDayClick,
            numberOfMonths: 1,
            locale: localeFormatted(),
            modifiers: {
              fadedEndOfMonth: date => isLastDayInMonth(date),
              fadedStartOfMonth: date => isFirstDayInMonth(date),
              onlyFromSelected: () => !!(date !== null && date !== void 0 && date.from) && !(date !== null && date !== void 0 && date.to),
              isInHoverRange: date => _isInHoverRange(date)
            },
            modifiersClassNames: {
              fadedEndOfMonth: 'ib-faded-end-of-month',
              fadedStartOfMonth: 'ib-faded-start-of-month',
              onlyFromSelected: 'only-start',
              isInHoverRange: 'in-hover-range',
              checkinLastMonth: 'day-checkin-last-month'
            },
            isCurrentMonth: isCurrentMonth(currentMonth, today),
            shouldHideNextMonthIcon: shouldHideNextMonthIcon(currentMonth),
            isCheckoutDay365: true,
            isInLastMonth: (date === null || date === void 0 ? void 0 : date.from) && isInLastEligibleMonth(date === null || date === void 0 ? void 0 : date.from),
            disabled: disabledDays,
            components: {
              IconLeft: () => (0, _jsxRuntime.jsx)(_image.default, {
                className: iconStyle,
                src: (0, _server.formatIBAssetsUrl)(chevronIcons === null || chevronIcons === void 0 ? void 0 : chevronIcons.left),
                alt: "DatePicker Icon Left",
                width: 24,
                height: 24
              }),
              IconRight: () => (0, _jsxRuntime.jsx)(_image.default, {
                className: iconStyle,
                src: (0, _server.formatIBAssetsUrl)(chevronIcons === null || chevronIcons === void 0 ? void 0 : chevronIcons.right),
                alt: "DatePicker Icon Right",
                width: 24,
                height: 24
              })
            }
          }), (0, _jsxRuntime.jsxs)("div", {
            "data-testid": "IB-Date-Picker-Calendar-buttons",
            className: "mx-2 mb-2 flex justify-between gap-5",
            children: [(0, _jsxRuntime.jsx)(_Button.Button, {
              "data-testid": "IB-Date-Picker-Calendar-buttons-Reset",
              className: (0, _server.cn)((0, _Button.buttonVariants)({
                variant: 'footerButtons',
                size: 'footerButtons'
              }), 'border-none hover:underline disabled:text-lightGrey2'),
              onClick: () => resetCalendar(),
              disabled: isResetDisabled,
              children: t('content.form.datePicker.reset')
            }), (0, _jsxRuntime.jsx)(_Button.Button, {
              "data-testid": "IB-Date-Picker-Calendar-buttons-Done",
              className: (0, _server.cn)((0, _Button.buttonVariants)({
                variant: 'footerButtons',
                size: 'footerButtons'
              }), 'border border-secondaryColor hover:shadow hover:shadow-lightGrey2'),
              onClick: () => setPopoverOpen(false),
              children: t('content.form.datePicker.done')
            })]
          })]
        })]
      })
    }), (0, _jsxRuntime.jsx)(_Tooltip.ErrorTooltip, {
      icon: (0, _server.formatIBAssetsUrl)(notificationIcons === null || notificationIcons === void 0 ? void 0 : notificationIcons.error),
      content: t('content.form.invalidNights'),
      open: showError,
      testId: "IB-Date-Picker-ErrorTooltip-Mobile",
      mobile: true
    })]
  });
}
var separatorStyle = 'inline-flex w-px h-6 mx-4 bg-lightGrey2 text-transparent';
var textInput = 'text-base font-normal text-left text-darkGrey2 truncate flex';
var iconStyle = 'h-6 w-6';
var containerMobileStyle = 'mobile:left-0 mobile:top-[100%] mobile:border-0 mobile:border-t mobile:border-lightGrey3 mobile:w-full mobile:rounded-none mobile:overflow-y-auto mobile:justify-center mobile:grid';
var errorTooltipStyle = 'max-w-[90%]';