"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = TripAdvisorReview;
var _react = require("@chakra-ui/react");
var _api = require("@whitbread-eos/api");
var _utils = require("@whitbread-eos/utils");
var _nextI18next = require("next-i18next");
var _BottomReviewSection = _interopRequireDefault(require("./BottomReviewSection"));
var _TopRatingSection = _interopRequireDefault(require("./TopRatingSection"));
var _jsxRuntime = require("react/jsx-runtime");
function TripAdvisorReview(_ref) {
  var {
    tripAdvisorReviews,
    isLoading,
    isError,
    error,
    isTopSection = true
  } = _ref;
  var {
    t
  } = (0, _nextI18next.useTranslation)(['common']);
  var {
    [_api.FT_PI_BB_CCUI_TRIP_ADVISOR]: isTripAdvisorReviewEnabled
  } = (0, _utils.useFeatureToggle)();
  if (isLoading) {
    return (0, _jsxRuntime.jsx)(_react.Text, {
      children: t('searchresults.list.hotel.loading')
    });
  }
  if (isError) {
    return (0, _jsxRuntime.jsx)(_react.Text, {
      children: error === null || error === void 0 ? void 0 : error.message
    });
  }
  return isTripAdvisorReviewEnabled ? (0, _jsxRuntime.jsx)(_jsxRuntime.Fragment, {
    children: isTopSection ? (0, _jsxRuntime.jsx)(_TopRatingSection.default, {
      tripAdvisorData: tripAdvisorReviews
    }) : (0, _jsxRuntime.jsx)(_BottomReviewSection.default, {
      tripAdvisorData: tripAdvisorReviews
    })
  }) : null;
}