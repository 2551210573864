"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.handleRefHeightChange = handleRefHeightChange;
function handleRefHeightChange(node, setHeight) {
  if (node !== null && node !== void 0 && node.clientHeight) {
    setHeight(node.clientHeight);
  }
}