"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = HubZipNoticeComponent;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _react = require("@chakra-ui/react");
var _atoms = require("@whitbread-eos/atoms");
var _nextI18next = require("next-i18next");
var _jsxRuntime = require("react/jsx-runtime");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function HubZipNoticeComponent(_ref) {
  var {
    isLoading,
    isError,
    error,
    data,
    srcHubLogo
  } = _ref;
  var {
    t
  } = (0, _nextI18next.useTranslation)(['common']);
  if (isLoading) {
    return (0, _jsxRuntime.jsx)(_react.Text, {
      children: t('searchresults.list.hotel.loading')
    });
  }
  if (isError) {
    return (0, _jsxRuntime.jsx)(_react.Text, {
      children: error.message
    });
  }
  if (!data || !['HUB', 'ZIP'].includes(data)) {
    return null;
  }
  var notice = {
    title: data === 'HUB' ? t('hoteldetails.hub.alertText') : t('hoteldetails.priceFighter.alertText'),
    subtitle: t('hoteldetails.hub.tagline1'),
    description: t('hoteldetails.hub.tagline2')
  };
  var titleStyles = {
    bg: data === 'HUB' ? _atoms.theme.colors.hubPrimary : _atoms.theme.colors.zipPrimary,
    py: '.3125rem',
    mb: 'lg',
    color: data === 'HUB' ? 'darkGrey1' : 'white',
    fontWeight: data === 'HUB' ? 'bold' : '',
    lineHeight: 3,
    mx: {
      mobile: '-1.5rem',
      lg: '0'
    },
    fontSize: 'md'
  };
  var subtitleStyles = {
    fontSize: 'md',
    fontWeight: '600',
    mb: 'sm',
    color: 'darkGrey1',
    lineHeight: 3
  };
  var descriptionStyles = {
    fontSize: 'md',
    lineHeight: 3,
    color: 'darkGrey1'
  };
  return (0, _jsxRuntime.jsxs)(_react.Box, {
    mt: "md",
    "data-testid": "hub-zip-notice",
    children: [(0, _jsxRuntime.jsx)(_react.Center, _objectSpread(_objectSpread({}, titleStyles), {}, {
      "data-testid": "hdp_hubZipNoticeTitle",
      children: notice.title
    })), (0, _jsxRuntime.jsxs)(_react.Flex, {
      direction: {
        base: 'column',
        sm: 'inherit'
      },
      alignItems: {
        base: 'baseline',
        sm: 'center'
      },
      mb: {
        base: 12,
        sm: 0
      },
      children: [(0, _jsxRuntime.jsx)(_react.Box, {
        mb: {
          base: 6,
          sm: 0
        },
        children: (0, _jsxRuntime.jsx)(_atoms.Logo, {
          variant: data === 'HUB' ? 'hub' : 'zip',
          transform: "scale(1)",
          src: srcHubLogo
        })
      }), (0, _jsxRuntime.jsxs)(_react.Flex, {
        direction: "column",
        pl: {
          sm: '3.125rem'
        },
        "data-testid": "hdp_hubZipNoticeInformation",
        children: [(0, _jsxRuntime.jsx)(_react.Text, _objectSpread(_objectSpread({}, subtitleStyles), {}, {
          children: notice.subtitle
        })), (0, _jsxRuntime.jsx)(_react.Text, _objectSpread(_objectSpread({}, descriptionStyles), {}, {
          children: notice.description
        }))]
      })]
    })]
  });
}