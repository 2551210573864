"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = HotelHeadlineComponent;
var _react = require("@chakra-ui/react");
var _jsxRuntime = require("react/jsx-runtime");
function HotelHeadlineComponent(_ref) {
  var {
    headline
  } = _ref;
  if (!headline) {
    return null;
  }
  return (0, _jsxRuntime.jsx)(_react.Text, {
    mt: {
      base: 0,
      md: 'md'
    },
    "data-testid": "hdp_hotelHeadline",
    children: headline
  });
}