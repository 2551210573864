"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = HotelContactInformationQueryWrapper;
var _reactQuery = require("@tanstack/react-query");
var _HotelContactInformation = _interopRequireDefault(require("./HotelContactInformation.container"));
var _jsxRuntime = require("react/jsx-runtime");
function HotelContactInformationQueryWrapper(_ref) {
  var {
    queryClient
  } = _ref;
  return (0, _jsxRuntime.jsx)(_reactQuery.QueryClientProvider, {
    client: queryClient,
    children: (0, _jsxRuntime.jsx)(_HotelContactInformation.default, {})
  });
}