"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = SEOContainer;
var _api = require("@whitbread-eos/api");
var _utils = require("@whitbread-eos/utils");
var _SEO = _interopRequireDefault(require("./SEO.component"));
var _jsxRuntime = require("react/jsx-runtime");
function SEOContainer(_ref) {
  var {
    page,
    hotelId,
    bookingFlowId,
    displayMeta
  } = _ref;
  var {
    language,
    country
  } = (0, _utils.useCustomLocale)();
  var queryKey = ['seoInformation', language, country, page];
  var queryParams = {
    language,
    country,
    page
  };
  if (hotelId) {
    queryKey.push(hotelId);
    queryParams['hotelId'] = hotelId;
  }
  if (bookingFlowId) {
    queryKey.push(bookingFlowId);
    queryParams['bookingFlowId'] = bookingFlowId;
  }
  var {
    data,
    isLoading,
    isError,
    error
  } = (0, _utils.useQueryRequest)(queryKey, _api.GET_SEO_INFORMATION, queryParams);
  var {
    seoInformation = {}
  } = data || {};
  return (0, _jsxRuntime.jsx)(_SEO.default, {
    data: seoInformation,
    isLoading,
    isError,
    error,
    displayMeta
  });
}