"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _add = _interopRequireDefault(require("date-fns/add"));
var _differenceInDays = _interopRequireDefault(require("date-fns/differenceInDays"));
var _format3 = _interopRequireDefault(require("date-fns/format"));
var _validators = require("../../validators");
var _analytics = _interopRequireDefault(require("./analytics"));
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
var updateHotelDisplayPageAnalytics = _ref => {
  var _format, _format2, _Object$keys, _searchData$roomType;
  var {
    multiSearchParams,
    hotelName,
    hotelId,
    hotelLabel,
    hotelAvailability,
    hotelFacilityIcons
  } = _ref;
  var isStartDateValid = (0, _validators.isDateValid)(Number(multiSearchParams.ARRdd), Number(multiSearchParams.ARRmm), Number(multiSearchParams.ARRyyyy));
  var startDate = isStartDateValid ? new Date(Number(multiSearchParams.ARRyyyy), Number(multiSearchParams.ARRmm) - 1, Number(multiSearchParams.ARRdd)) : new Date();
  var endDate = (0, _add.default)(startDate, {
    days: Number(multiSearchParams.NIGHTS)
  });
  var searchWeekdayFrom = (_format = (0, _format3.default)(startDate, 'eee')) === null || _format === void 0 ? void 0 : _format.toLowerCase();
  var searchWeekdayTo = (_format2 = (0, _format3.default)(endDate, 'eee')) === null || _format2 === void 0 ? void 0 : _format2.toLowerCase();
  var searchData = (_Object$keys = Object.keys(multiSearchParams)) === null || _Object$keys === void 0 ? void 0 : _Object$keys.reduce((acc, currentValue) => {
    if (currentValue.includes('CHILD')) {
      return _objectSpread(_objectSpread({}, acc), {}, {
        numberOfChildren: acc.numberOfChildren + Number(multiSearchParams[currentValue])
      });
    }
    if (currentValue.includes('ADULT')) {
      return _objectSpread(_objectSpread({}, acc), {}, {
        numberOfAdults: acc.numberOfAdults + Number(multiSearchParams[currentValue])
      });
    }
    if (currentValue.includes('INTTYP')) {
      var _acc$roomType;
      var value = acc.roomType.length ? "".concat(acc.roomType, ":").concat(multiSearchParams[currentValue]) : multiSearchParams[currentValue];
      return _objectSpread(_objectSpread({}, acc), {}, {
        roomType: !((_acc$roomType = acc.roomType) !== null && _acc$roomType !== void 0 && _acc$roomType.includes(multiSearchParams[currentValue])) ? value : acc.roomType
      });
    }
    return _objectSpread({}, acc);
  }, {
    numberOfAdults: 0,
    numberOfChildren: 0,
    roomType: ''
  });
  _analytics.default.update({
    analyticsDataSearchResult: {
      searchCheckInDate: (0, _format3.default)(startDate, 'dd/MM/yyyy'),
      searchCheckOutDate: (0, _format3.default)(endDate, 'dd/MM/yyyy'),
      searchDaysToCheckIn: (0, _differenceInDays.default)(new Date(startDate), new Date()),
      searchNumberOfAdults: searchData.numberOfAdults,
      searchNumberOfChildren: searchData.numberOfChildren,
      searchNumberOfGuests: searchData.numberOfAdults + searchData.numberOfChildren,
      searchNumberOfNights: Number(multiSearchParams.NIGHTS),
      searchNumberOfRooms: Number(multiSearchParams === null || multiSearchParams === void 0 ? void 0 : multiSearchParams.ROOMS),
      searchResults: 1,
      searchResultsDisplayed: [{
        bookingSystem: 'Opera',
        hotelAvailability: hotelAvailability !== null && hotelAvailability !== void 0 ? hotelAvailability : '',
        hotelCode: hotelId.toLowerCase(),
        hotelDistance: 0,
        hotelFacilityIcons: hotelFacilityIcons !== null && hotelFacilityIcons !== void 0 ? hotelFacilityIcons : [],
        hotelLabel: hotelLabel !== null && hotelLabel !== void 0 ? hotelLabel : '',
        imageLabel: '',
        priceFrom: '',
        voucherCode: ''
      }],
      searchRoomType: (_searchData$roomType = searchData.roomType) === null || _searchData$roomType === void 0 ? void 0 : _searchData$roomType.toLowerCase(),
      searchTerm: hotelName,
      searchType: 'list view',
      searchWeekdayFrom,
      searchWeekdayFromTo: "".concat(searchWeekdayFrom, "-").concat(searchWeekdayTo),
      searchWeekdayTo
    }
  });
};
var _default = exports.default = updateHotelDisplayPageAnalytics;