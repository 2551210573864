"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.inputStyle = exports.default = void 0;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _react = require("@chakra-ui/react");
var _atoms = require("@whitbread-eos/atoms");
var _utils = require("@whitbread-eos/utils");
var _nextI18next = require("next-i18next");
var _react2 = require("react");
var _common = require("./common");
var _jsxRuntime = require("react/jsx-runtime");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
var FieldController = _ref => {
  var {
    field,
    label,
    name,
    type,
    testId,
    errMsg,
    formField,
    nationalities,
    isDisabled,
    handleSetValue,
    fieldToClear
  } = _ref;
  var {
    t
  } = (0, _nextI18next.useTranslation)();
  var [toggleDobInfo, setToggleDobInfo] = (0, _react2.useState)(false);
  var [lightGrey1, primary] = (0, _react.useToken)('colors', ['lightGrey1', 'primary']);
  var [spacexl, space4xl] = (0, _react.useToken)('space', ['xl', '4xl']);
  var [fontMd] = (0, _react.useToken)('fontSizes', ['md']);
  var [darkGrey2] = (0, _react.useToken)('colors', ['darkGrey2']);
  var {
    language
  } = (0, _utils.useCustomLocale)();
  var [isLargerThanSm] = (0, _react.useMediaQuery)('(min-width: 576px)');
  var minDate = (0, _utils.getYearsAgoFromNow)(100);
  var multiSelectStyles = {
    control: (baseStyles, state) => _objectSpread(_objectSpread({}, baseStyles), {}, {
      borderColor: state !== null && state !== void 0 && state.menuIsOpen ? primary : lightGrey1,
      minHeight: space4xl,
      fontSize: fontMd,
      boxShadow: 'none',
      borderWidth: state !== null && state !== void 0 && state.menuIsOpen ? '2px' : '1px',
      borderRadius: '0.375rem',
      ':hover': {
        borderColor: lightGrey1
      }
    }, state.isDisabled && {
      backgroundColor: '#fff'
    }),
    multiValue: baseStyles => _objectSpread(_objectSpread({}, baseStyles), {}, {
      minHeight: spacexl,
      alignItems: 'center'
    }),
    multiValueRemove: baseStyles => _objectSpread(_objectSpread({}, baseStyles), {}, {
      ':hover': {
        backgroundColor: 'none',
        color: lightGrey1
      }
    }),
    placeholder: baseStyles => _objectSpread(_objectSpread({}, baseStyles), {}, {
      color: darkGrey2
    }),
    valueContainer: baseStyles => _objectSpread(_objectSpread({}, baseStyles), {}, {
      padding: '2px 16px'
    }),
    container: (baseStyles, state) => _objectSpread(_objectSpread({}, baseStyles), {}, {
      '&:hover': {
        borderColor: primary
      }
    }, state.isDisabled && {
      opacity: '0.4'
    })
  };
  (0, _react2.useEffect)(() => {
    var timeoutId;
    if (toggleDobInfo) timeoutId = setTimeout(() => setToggleDobInfo(false), 3000);
    return () => clearTimeout(timeoutId);
  }, [toggleDobInfo]);
  (0, _react2.useEffect)(() => {
    if (handleSetValue && isDisabled) {
      handleSetValue(name, '');
    }
  }, [isDisabled, handleSetValue]);
  switch (type) {
    case 'input':
      {
        var inputLabel = label === 'email' ? t('precheckin.additionalfields.email') : t("precheckin.details.".concat(label));
        return (0, _jsxRuntime.jsx)(_atoms.Input, _objectSpread(_objectSpread(_objectSpread({}, formField.props), (0, _common.fieldProps)(field)), {}, {
          type: "text",
          isDisabled: isDisabled,
          showIcon: false,
          placeholderText: inputLabel,
          label: inputLabel,
          error: field !== null && field !== void 0 && field.value ? errMsg !== null && errMsg !== void 0 ? errMsg : '' : '',
          className: "sessioncamhidetext assist-no-show"
        }));
      }
    case 'datePicker':
      return (0, _jsxRuntime.jsxs)(_react.Box, {
        position: "relative",
        mb: toggleDobInfo ? '3xl' : '',
        maxW: multiSelectMaxW,
        children: [(0, _jsxRuntime.jsx)(_atoms.SingleDatePicker, _objectSpread(_objectSpread({
          minDate: minDate,
          maxDate: new Date()
        }, language === 'de' ? {
          locale: 'de'
        } : {
          locale: 'en'
        }), {}, {
          name: name,
          inputPlaceholder: t("precheckin.additionalfields.".concat(label)),
          inputLabel: t("precheckin.additionalfields.".concat(label)),
          defaultStartDate: field.value ? new Date(field.value) : null,
          isRightIcon: true,
          popperPlacement: isLargerThanSm ? 'bottom' : 'top',
          displayDateFormat: "dd MMM yyyy",
          dataTestId: (0, _utils.formatDataTestId)(formField.testid, testId),
          dateFormat: "dd MMM yyyy",
          customHeader: true,
          isDisabled: isDisabled,
          isClearable: true,
          labels: {
            todayLabel: _common.todayLabel,
            tomorrowLabel: _common.tomorrowLabel
          },
          datepickerStyles: {
            inputGroupStyles: {},
            datepickerInputElementStyles: inputStyle,
            iconStyles: _common.inputIconStyles
          },
          onSelectDate: field.onChange
        })), errMsg && (0, _jsxRuntime.jsx)(_react.Text, {
          style: _common.dataErrorText,
          mt: "2",
          children: errMsg
        })]
      });
    case 'autoComplete':
      return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
        children: [(0, _jsxRuntime.jsx)(_react.Box, {
          maxW: multiSelectMaxW,
          "data-testid": (0, _utils.formatDataTestId)(formField.testid, testId),
          children: (0, _jsxRuntime.jsx)(_atoms.MultiSelect, {
            options: nationalities,
            dataTestId: (0, _utils.formatDataTestId)(formField.testid, testId),
            value: field.value,
            onChange: fieldProp => (0, _common.handleNationalityChange)(fieldProp, field, fieldToClear, handleSetValue),
            placeholder: t("precheckin.additionalfields.nationalities"),
            styles: multiSelectStyles,
            isClearable: true,
            isDisabled: isDisabled,
            closeMenuOnSelect: true
          })
        }), errMsg && (0, _jsxRuntime.jsx)(_react.Text, {
          style: _common.dataErrorText,
          mt: "2",
          children: errMsg
        })]
      });
    default:
      return (0, _jsxRuntime.jsx)(_jsxRuntime.Fragment, {});
  }
};
var _default = exports.default = FieldController;
var multiSelectMaxW = {
  mobile: '100%',
  sm: '16.375rem',
  md: '21.75rem',
  lg: '24.5rem',
  xl: '26.25rem'
};
var inputStyle = exports.inputStyle = {
  height: 'var(--chakra-space-4xl)',
  borderColor: 'lightGrey1',
  borderRadius: 'var(--chakra-radii-md)'
};