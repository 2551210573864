"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = LocationContainer;
var _utils = require("@whitbread-eos/utils");
var _Location = _interopRequireDefault(require("./Location.component"));
var _jsxRuntime = require("react/jsx-runtime");
function LocationContainer(_ref) {
  var {
    queryClient
  } = _ref;
  var {
    brand,
    coordinates,
    isLoading,
    isError,
    error
  } = (0, _utils.useStaticHotelInformation)();
  return (0, _jsxRuntime.jsx)(_Location.default, {
    isLoading,
    isError,
    error,
    data: {
      coordinates,
      brand
    },
    queryClient
  });
}