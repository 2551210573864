"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = PaymentTypeContainer;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _api = require("@whitbread-eos/api");
var _molecules = require("@whitbread-eos/molecules");
var _utils = require("@whitbread-eos/utils");
var _nextI18next = require("next-i18next");
var _router = require("next/router");
var _react = require("react");
var _jsxRuntime = require("react/jsx-runtime");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function PaymentTypeContainer(_ref) {
  var _query$reservationId, _query$reservationId2;
  var {
    selectedPaymentDetail,
    selectedPaymentType,
    onPaymentTypeClick,
    userType,
    variant,
    amendBasketReference,
    disabledCardOptions,
    amendPaymentCard,
    isLedDesign,
    isUsedWithTabs,
    onlyShowPayNow
  } = _ref;
  var router = (0, _router.useRouter)();
  var {
    query
  } = router;
  var {
    language,
    country
  } = (0, _utils.useCustomLocale)();
  var idTokenCookie = (0, _utils.getAuthCookie)();
  var paymentMethodsQueryInput = _objectSpread({
    basketReference: (_query$reservationId = query.reservationId) !== null && _query$reservationId !== void 0 ? _query$reservationId : amendBasketReference,
    language,
    country,
    userType: idTokenCookie ? userType : undefined
  }, variant && variant === _api.Area.PI && {
    clientChannel: variant.toUpperCase()
  });
  var {
    data,
    isError,
    error,
    isLoading
  } = (0, _utils.useQueryRequest)(['getPaymentMethods', language, country, (_query$reservationId2 = query.reservationId) !== null && _query$reservationId2 !== void 0 ? _query$reservationId2 : amendBasketReference], _api.GET_PAYMENT_METHODS_QUERY, _objectSpread({}, paymentMethodsQueryInput), {
    gcTime: 0
  }, idTokenCookie);
  (0, _react.useEffect)(() => {
    _utils.analytics.update({
      paymentCards: '0',
      paymentCardTypes: ''
    });
  }, []);
  (0, _react.useEffect)(() => {
    if (!isLoading && data !== null && data !== void 0 && data.paymentMethods) {
      var validCards = data === null || data === void 0 ? void 0 : data.paymentMethods.filter(method => method.card && method.enabled);
      var paymentCardTypes = validCards.map(method => {
        var _method$card$type, _method$card;
        return (_method$card$type = (_method$card = method.card) === null || _method$card === void 0 ? void 0 : _method$card.type) !== null && _method$card$type !== void 0 ? _method$card$type : '';
      }).filter((type, index, self) => type && self.indexOf(type) === index).join(',');
      _utils.analytics.update({
        paymentCards: "".concat(validCards.length),
        paymentCardTypes
      });
    }
  }, [isLoading, data]);
  data === null || data === void 0 || data.paymentMethods.forEach(method => {
    var _method$acceptedCardT;
    return (_method$acceptedCardT = method.acceptedCardTypes) === null || _method$acceptedCardT === void 0 ? void 0 : _method$acceptedCardT.forEach(cardType => {
      cardType && (cardType.logoSrc = (0, _utils.formatAssetsUrl)(cardType === null || cardType === void 0 ? void 0 : cardType.logoSrc));
    });
  });
  var {
    t
  } = (0, _nextI18next.useTranslation)(['common']);
  var SelectedStyle = isLedDesign ? _molecules.PaymentTypeLed : _molecules.PaymentType;
  return data ? (0, _jsxRuntime.jsx)(SelectedStyle, {
    data: data,
    isLoading,
    isError,
    error,
    t,
    selectedPaymentDetail: selectedPaymentDetail,
    selectedPaymentType: selectedPaymentType,
    onPaymentTypeClick: onPaymentTypeClick,
    variant: variant,
    disabledOptions: disabledCardOptions,
    amendPaymentCard: amendPaymentCard,
    isUsedWithTabs: isUsedWithTabs,
    onlyShowPayNow: onlyShowPayNow
  }) : null;
}