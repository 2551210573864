"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.EckohParameters = void 0;
var _enums = require("../enums");
class EckohParameters {
  constructor(hotelName, clientId, agentEmail, language, reservationId, timeOfPay, paymentMethod, cardPresence, hotelCountry, merchantId, environment) {
    this.property = hotelName;
    this.cId = clientId;
    this.aId = agentEmail;
    this.lang = language;
    this.reservationId = reservationId;
    this.merchantId = merchantId;
    this.env = environment;
    switch (timeOfPay) {
      case 'PAY_ON_ARRIVAL':
        this.timeofpay = _enums.EckohTimeOfPay.PAY_ON_ARRIVAL;
        break;
      case 'PAY_NOW':
      default:
        this.timeofpay = _enums.EckohTimeOfPay.PAY_NOW;
        break;
    }
    switch (paymentMethod) {
      case _enums.CcuiCardType.PIBAGB:
        this.paymethod = _enums.EckohPayMethod.PIBA_GB;
        break;
      case _enums.CcuiCardType.PIBADE:
        this.paymethod = _enums.EckohPayMethod.PIBA_DE;
        break;
      case _enums.CcuiCardType.NEW_CARD:
      default:
        this.paymethod = _enums.EckohPayMethod.CREDIT_CARD_DEBIT_CARD;
        break;
    }
    switch (cardPresence) {
      case _enums.CardStatus.CARD_PRESENT:
        this.cardpresence = _enums.EckohCardPresence.CARD_PRESENT;
        break;
      case _enums.CardStatus.CARD_NOT_PRESENT:
        this.cardpresence = _enums.EckohCardPresence.CARD_NOT_PRESENT;
        break;
    }
    switch (hotelCountry) {
      case _enums.CountryEnum.GB:
        this.hotelCountry = _enums.EckohCountry.GB;
        break;
      case _enums.CountryEnum.DE:
        this.hotelCountry = _enums.EckohCountry.DE;
        break;
      default:
        this.hotelCountry = _enums.EckohCountry.IE;
        break;
    }
  }
}
exports.EckohParameters = EckohParameters;