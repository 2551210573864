"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.GET_HOTEL_INVENTORY_QUERY = void 0;
var _taggedTemplateLiteral2 = _interopRequireDefault(require("@babel/runtime/helpers/taggedTemplateLiteral"));
var _graphqlRequest = require("graphql-request");
var _templateObject;
var GET_HOTEL_INVENTORY_QUERY = exports.GET_HOTEL_INVENTORY_QUERY = (0, _graphqlRequest.gql)(_templateObject || (_templateObject = (0, _taggedTemplateLiteral2.default)(["\n  query getHotelInventory($hotelId: String!, $dateRangeEnd: String!, $dateRangeStart: String!) {\n    hotelInventory(\n      hotelId: $hotelId\n      dateRangeEnd: $dateRangeEnd\n      dateRangeStart: $dateRangeStart\n    ) {\n      roomTypeInventories {\n        availableCount\n        code\n      }\n    }\n  }\n"])));