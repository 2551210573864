"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.PRE_CHECK_IN_STATUS = void 0;
var _taggedTemplateLiteral2 = _interopRequireDefault(require("@babel/runtime/helpers/taggedTemplateLiteral"));
var _graphqlRequest = require("graphql-request");
var _templateObject;
var PRE_CHECK_IN_STATUS = exports.PRE_CHECK_IN_STATUS = (0, _graphqlRequest.gql)(_templateObject || (_templateObject = (0, _taggedTemplateLiteral2.default)(["\n  mutation preCheckIn($hotelId: String!, $reservationId: String!, $arrivalTime: String!) {\n    preCheckInStatus(\n      preCheckInCriteria: {\n        hotelId: $hotelId\n        reservationId: $reservationId\n        arrivalTime: $arrivalTime\n      }\n    ) {\n      status\n      message\n    }\n  }\n"])));