"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = DiscoverSideNav;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _react = require("@chakra-ui/react");
var _link = _interopRequireDefault(require("next/link"));
var _HeaderSideNav = _interopRequireDefault(require("../HeaderSideNav"));
var _NavigationMenuMobile = require("../NavigationMenuMobile.style");
var _jsxRuntime = require("react/jsx-runtime");
var _react2 = require("react");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function DiscoverSideNav(_ref) {
  var _labels$subNav, _subNavItems$map;
  var {
    onClickHeaderTitle,
    labels
  } = _ref;
  var subNavItems = labels === null || labels === void 0 || (_labels$subNav = labels.subNav) === null || _labels$subNav === void 0 ? void 0 : _labels$subNav.filter(item => {
    var _item$title;
    return !(item !== null && item !== void 0 && (_item$title = item.title) !== null && _item$title !== void 0 && _item$title.includes('Business'));
  });
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_HeaderSideNav.default, {
      title: labels.discoverPI,
      onClickTitle: onClickHeaderTitle
    }), (0, _jsxRuntime.jsx)(_react.ModalBody, {
      p: "0",
      "data-testid": "discoverSideNav",
      children: (0, _jsxRuntime.jsx)(_react.Flex, {
        flexDir: "column",
        children: subNavItems === null || subNavItems === void 0 || (_subNavItems$map = subNavItems.map) === null || _subNavItems$map === void 0 ? void 0 : _subNavItems$map.call(subNavItems, category => {
          return (0, _react2.createElement)(_react.Flex, _objectSpread(_objectSpread({}, customContainerItemStyle), {}, {
            key: "category-".concat(category.title)
          }), (0, _jsxRuntime.jsx)(_react.Box, {
            children: category.title
          }), (0, _jsxRuntime.jsx)(_react.Flex, {
            flexDir: "column",
            pl: "0.81rem",
            children: category.navOptions.map(option => {
              var _option$url;
              return (0, _jsxRuntime.jsx)(_react.Box, {
                lineHeight: "2.5rem",
                children: (0, _jsxRuntime.jsx)(_link.default, {
                  href: (_option$url = option.url) !== null && _option$url !== void 0 ? _option$url : '/',
                  passHref: true,
                  legacyBehavior: true,
                  children: option.title
                })
              }, option.title);
            })
          }));
        })
      })
    })]
  });
}
var customContainerItemStyle = _objectSpread(_objectSpread({}, _NavigationMenuMobile.containerItemStyle), {}, {
  flexDirection: 'column',
  ml: '0',
  pl: 'md',
  w: '100%'
});