"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.BUSINESS_TETHER_LOGIN = void 0;
var _taggedTemplateLiteral2 = _interopRequireDefault(require("@babel/runtime/helpers/taggedTemplateLiteral"));
var _graphqlRequest = require("graphql-request");
var _templateObject;
var BUSINESS_TETHER_LOGIN = exports.BUSINESS_TETHER_LOGIN = (0, _graphqlRequest.gql)(_templateObject || (_templateObject = (0, _taggedTemplateLiteral2.default)(["\n  mutation businessTetherLogin($loginCriteria: LoginCriteria!) {\n    businessTetherLogin(loginCriteria: $loginCriteria) {\n      errorCode\n      errorDescription\n      hash\n      sessionId\n      sharedSecret\n      nonce\n      timestamp\n    }\n  }\n"])));