"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.RESEND_INVOICE_BOOKING_INFORMATION_CARD = void 0;
var _taggedTemplateLiteral2 = _interopRequireDefault(require("@babel/runtime/helpers/taggedTemplateLiteral"));
var _graphqlRequest = require("graphql-request");
var _templateObject;
var RESEND_INVOICE_BOOKING_INFORMATION_CARD = exports.RESEND_INVOICE_BOOKING_INFORMATION_CARD = (0, _graphqlRequest.gql)(_templateObject || (_templateObject = (0, _taggedTemplateLiteral2.default)(["\n  mutation ResendInvoice(\n    $hotelId: String!\n    $email: String!\n    $bookingReference: String!\n    $invoiceRecordNumber: String!\n    $bookingChannel: BookingChannelCriteria!\n  ) {\n    resendInvoiceEmail(\n      resendInvoiceRequest: {\n        hotelId: $hotelId\n        email: $email\n        bookingReference: $bookingReference\n        invoiceRecordNumber: $invoiceRecordNumber\n        bookingChannel: $bookingChannel\n      }\n    )\n  }\n"])));