"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.subHeaderStyles = exports.headerStyles = exports.descriptionStyles = void 0;
var headerStyles = exports.headerStyles = {
  fontSize: '2xl',
  lineHeight: 4,
  fontStyle: 'normal',
  fontWeight: 'semibold',
  color: 'darkGrey1'
};
var subHeaderStyles = exports.subHeaderStyles = {
  fontSize: 'xl',
  lineHeight: 3,
  fontStyle: 'normal',
  fontWeight: 'semibold',
  color: 'darkGrey1'
};
var descriptionStyles = exports.descriptionStyles = {
  fontWeight: 'normal',
  fontStyle: 'normal',
  fontSize: 'md',
  lineHeight: 3,
  color: 'darkGrey1'
};