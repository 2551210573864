"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = FormError;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _react = require("@chakra-ui/react");
var _jsxRuntime = require("react/jsx-runtime");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function FormError(_ref) {
  var _errors$name;
  var {
    errors,
    name,
    extraStyles
  } = _ref;
  if (errors !== null && errors !== void 0 && (_errors$name = errors[name]) !== null && _errors$name !== void 0 && (_errors$name = _errors$name.message) !== null && _errors$name !== void 0 && _errors$name.length) {
    var _errors$name2;
    return (0, _jsxRuntime.jsx)(_react.Box, _objectSpread(_objectSpread({}, _objectSpread(_objectSpread({}, formErrorStyles), extraStyles === null || extraStyles === void 0 ? void 0 : extraStyles.containerStyle)), {}, {
      children: (0, _jsxRuntime.jsx)(_react.Box, _objectSpread(_objectSpread({}, errorMessageStyles), {}, {
        children: (0, _jsxRuntime.jsx)(_react.Text, _objectSpread(_objectSpread({}, _objectSpread(_objectSpread({}, textStyles), extraStyles === null || extraStyles === void 0 ? void 0 : extraStyles.textStyle)), {}, {
          children: errors === null || errors === void 0 || (_errors$name2 = errors[name]) === null || _errors$name2 === void 0 ? void 0 : _errors$name2.message
        }))
      }))
    }));
  }
  return null;
}
var formErrorStyles = {
  height: 'var(--chakra-space-lg)',
  position: 'relative'
};
var errorMessageStyles = {
  position: 'absolute'
};
var textStyles = {
  color: 'error',
  fontSize: 'xs',
  marginLeft: 'md',
  marginTop: 'sm',
  whiteSpace: 'nowrap'
};