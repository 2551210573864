"use strict";
'use client';

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.WorldlineLink = WorldlineLink;
var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));
var _utils = require("@whitbread-eos/utils");
var _server = require("@whitbread-eos/utils/server");
var _navigation = require("next/navigation");
var _react = require("react");
var _jsxRuntime = require("react/jsx-runtime");
function WorldlineLink(_ref) {
  var {
    tetheredGuid,
    children,
    className,
    worldlinePostUrl,
    worldlineRequestedPage,
    worldlineReturnUrl
  } = _ref;
  var pathname = (0, _navigation.usePathname)();
  var [showError, setShowError] = (0, _react.useState)(false);
  var locale = (0, _server.getLocaleByPathname)(pathname);
  var formRef = (0, _react.useRef)(null);
  var token = (0, _utils.getCookie)(_utils.ID_TOKEN_COOKIE);
  var returnUrlText = 'Back to InnBusiness';
  var handleLinkClick = function () {
    var _ref2 = (0, _asyncToGenerator2.default)(function* (e) {
      e.preventDefault();
      if (!formRef.current) {
        return;
      }
      try {
        var data = yield (0, _server.businessTetherLogin)(token, tetheredGuid, locale);
        formRef.current['TokenSessionId'].value = data.sessionId;
        formRef.current['TokenTimestamp'].value = data.timestamp;
        formRef.current['TokenNonce'].value = data.nonce;
        formRef.current['TokenHash'].value = data.hash;
        formRef.current.submit();
      } catch (error) {
        setShowError(true);
      }
    });
    return function handleLinkClick(_x) {
      return _ref2.apply(this, arguments);
    };
  }();
  return (0, _jsxRuntime.jsxs)("form", {
    method: "POST",
    action: worldlinePostUrl,
    ref: formRef,
    children: [(0, _jsxRuntime.jsx)("input", {
      name: "TokenSessionId",
      type: "hidden"
    }), (0, _jsxRuntime.jsx)("input", {
      name: "TokenTimestamp",
      type: "hidden"
    }), (0, _jsxRuntime.jsx)("input", {
      name: "TokenNonce",
      type: "hidden"
    }), (0, _jsxRuntime.jsx)("input", {
      name: "TokenHash",
      type: "hidden"
    }), (0, _jsxRuntime.jsx)("input", {
      name: "EndWebSessionOnReturn",
      type: "hidden",
      value: "true"
    }), (0, _jsxRuntime.jsx)("input", {
      name: "RequestedPage",
      type: "hidden",
      defaultValue: worldlineRequestedPage
    }), (0, _jsxRuntime.jsx)("input", {
      name: "ReturnUrlText",
      type: "hidden",
      defaultValue: returnUrlText
    }), (0, _jsxRuntime.jsx)("input", {
      name: "ReturnUrl",
      type: "hidden",
      defaultValue: worldlineReturnUrl
    }), (0, _jsxRuntime.jsx)("button", {
      type: "button",
      className: className,
      onClick: e => handleLinkClick(e),
      children: children
    }), showError && (0, _jsxRuntime.jsx)("p", {
      "data-testid": "error",
      children: "Error logging in"
    })]
  });
}