"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.timeTrackerSeconds = exports.default = exports.analyticsTrackings = exports.analyticsConfirmation = void 0;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
var analytics = {
  update: payload => {
    window.analyticsData = _objectSpread(_objectSpread({}, window.analyticsData), payload);
  },
  remove: payload => {
    payload.forEach(item => {
      if (Object.keys(window.analyticsData).includes(item)) {
        delete window.analyticsData[item];
      }
    });
  }
};
var analyticsConfirmation = exports.analyticsConfirmation = {
  update: payload => {
    window.analyticsDataCartConfirmation = _objectSpread(_objectSpread({}, window.analyticsDataCartConfirmation), payload);
  }
};
var timeTrackerSeconds = () => {
  var beforeTime = new Date();
  return () => {
    var nowTime = new Date();
    return ((nowTime - beforeTime) / 1000).toString();
  };
};
exports.timeTrackerSeconds = timeTrackerSeconds;
var analyticsTrackings = exports.analyticsTrackings = function (analyticsTrackings) {
  analyticsTrackings["HOTEL"] = "Hotel";
  analyticsTrackings["DATE"] = "Date";
  analyticsTrackings["FOOD"] = "Food";
  analyticsTrackings["ADDON"] = "Addon";
  analyticsTrackings["RATE_PLAN"] = "ratePlan";
  return analyticsTrackings;
}({});
var _default = exports.default = analytics;