"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.CompanyReferencesQuestions = void 0;
var CompanyReferencesQuestions = exports.CompanyReferencesQuestions = function (CompanyReferencesQuestions) {
  CompanyReferencesQuestions["CUSTOMER_REFERENCE"] = "Customer Reference";
  CompanyReferencesQuestions["PURCHASE_ORDER_NUMBER"] = "Purchase Order Number";
  return CompanyReferencesQuestions;
}({});