"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = FormButtons;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _react = require("@chakra-ui/react");
var _ = require("../");
var _Button = _interopRequireDefault(require("../../Button"));
var _jsxRuntime = require("react/jsx-runtime");
var _react2 = require("react");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function FormButtons(_ref) {
  var {
    buttonsContainerStyles,
    buttons
  } = _ref;
  return (0, _jsxRuntime.jsx)(_react.Flex, _objectSpread(_objectSpread({}, _objectSpread(_objectSpread({}, defaultButtonsContainerStyles), buttonsContainerStyles)), {}, {
    children: buttons.map(button => {
      return (0, _react2.createElement)(_react.Box, _objectSpread(_objectSpread({}, button.styles), {}, {
        key: button.label
      }), (0, _jsxRuntime.jsx)(_Button.default, _objectSpread(_objectSpread({
        type: button.type,
        "data-testid": button.testid
      }, button.props), {}, {
        onClick: () => {
          if (button.type !== _.FORM_BUTTON_TYPES.SUBMIT) {
            button.action();
          }
        },
        children: button.label
      })));
    })
  }));
}
var defaultButtonsContainerStyles = {
  direction: 'column',
  justifyContent: 'flex-start',
  marginBottom: 'var(--chakra-space-lg)',
  width: '100%'
};