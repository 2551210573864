"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.GET_ADDRESSES = void 0;
var _taggedTemplateLiteral2 = _interopRequireDefault(require("@babel/runtime/helpers/taggedTemplateLiteral"));
var _graphqlRequest = require("graphql-request");
var _templateObject;
var GET_ADDRESSES = exports.GET_ADDRESSES = (0, _graphqlRequest.gql)(_templateObject || (_templateObject = (0, _taggedTemplateLiteral2.default)(["\n  query addressSearch($searchTerm: String!, $countryCode: String) {\n    partialAddress(partialAddressCriteria: { searchTerm: $searchTerm, countryCode: $countryCode }) {\n      id\n      addressText\n    }\n  }\n"])));