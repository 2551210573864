"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = MobileCarousel;
var _react = require("@chakra-ui/react");
var _react2 = require("react");
var _reactSlick = _interopRequireDefault(require("react-slick"));
var _CarouselGlobalStyles = _interopRequireDefault(require("../../theme/components/CarouselGlobalStyles"));
var _jsxRuntime = require("react/jsx-runtime");
function MobileCarousel(_ref) {
  var {
    children,
    thumbnails,
    activeSlide,
    setActiveSlide
  } = _ref;
  var [nav1, setNav1] = (0, _react2.useState)();
  var [nav2, setNav2] = (0, _react2.useState)();
  (0, _react2.useEffect)(() => {
    focusSlider(nav1);
  }, [nav1]);
  return (0, _jsxRuntime.jsxs)(_react.Box, {
    "data-testid": "mobileCarousel-".concat(activeSlide + 1, "/").concat(children === null || children === void 0 ? void 0 : children.length),
    sx: {
      '& .carousel-thumbnails': {
        marginTop: 'md',
        cursor: 'pointer',
        '.slick-slide': {
          width: 'calc(100% - 1rem)',
          padding: '0 0.5rem'
        },
        '.slick-current > div > *': {
          border: '2px solid var(--chakra-colors-darkGrey1)',
          padding: '2px'
        }
      }
    },
    children: [(0, _jsxRuntime.jsx)(_CarouselGlobalStyles.default, {}), (0, _jsxRuntime.jsx)(_reactSlick.default, {
      asNavFor: nav2,
      ref: slider => setNav1(slider),
      initialSlide: activeSlide,
      infinite: false,
      arrows: false,
      speed: 300,
      afterChange: index => setActiveSlide(index),
      children: children
    }), (0, _jsxRuntime.jsx)(_reactSlick.default, {
      className: "carousel-thumbnails",
      asNavFor: nav1,
      ref: slider => setNav2(slider),
      initialSlide: activeSlide,
      speed: 300,
      infinite: false,
      arrows: false,
      slidesToShow: 3,
      focusOnSelect: true,
      swipeToSlide: true,
      children: thumbnails === null || thumbnails === void 0 ? void 0 : thumbnails.map((thumbnail, index) => (0, _react2.cloneElement)(thumbnail, {
        key: index
      }, null))
    })]
  });
}
function focusSlider(sliderRef) {
  if (sliderRef !== null && sliderRef !== void 0 && sliderRef.innerSlider) {
    var _sliderRef$innerSlide;
    var track = (_sliderRef$innerSlide = sliderRef.innerSlider.list) === null || _sliderRef$innerSlide === void 0 ? void 0 : _sliderRef$innerSlide.querySelector('.slick-track');
    var slide = track === null || track === void 0 ? void 0 : track.querySelector('.slick-slide');
    slide === null || slide === void 0 || slide.focus();
  }
}