"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.GET_REGISTER_BOOKING_CONFIRMATION = void 0;
var _taggedTemplateLiteral2 = _interopRequireDefault(require("@babel/runtime/helpers/taggedTemplateLiteral"));
var _graphqlRequest = require("graphql-request");
var _templateObject;
var GET_REGISTER_BOOKING_CONFIRMATION = exports.GET_REGISTER_BOOKING_CONFIRMATION = (0, _graphqlRequest.gql)(_templateObject || (_templateObject = (0, _taggedTemplateLiteral2.default)(["\n  query GetRegisterPageBookingConfirmation(\n    $basketReference: String!\n    $country: String!\n    $language: String!\n    $bookingChannel: String!\n  ) {\n    bookingConfirmation(\n      basketReference: $basketReference\n      country: $country\n      language: $language\n      bookingChannel: $bookingChannel\n    ) {\n      reservationByIdList {\n        reservationBooker {\n          title\n          firstName\n          lastName\n          address {\n            companyName\n            addressType\n            addressLine1\n            addressLine2\n            addressLine3\n            addressLine4\n            cityName\n            countryCode\n            postalCode\n          }\n          email\n          mobile\n          landline\n        }\n      }\n    }\n  }\n"])));