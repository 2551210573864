"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = AnonRFS;
exports.extractFormDetailsFlags = void 0;
var _api = require("@whitbread-eos/api");
var _utils = require("@whitbread-eos/utils");
var _react = require("react");
var extractFormDetailsFlags = (basketReferenceId, hotelBrand) => {
  var defaultFlags = {
    rfs: _api.HotelBrand.HUB === hotelBrand ? _api.PurposeOfStay.LEISURE : '',
    basketReferenceId: '',
    updated: false
  };
  if (typeof window === 'undefined') {
    return defaultFlags;
  }
  try {
    var formDetails = window.localStorage.getItem('formDetails');
    var formDetailsData = formDetails ? JSON.parse(formDetails) : {};
    if ((formDetailsData === null || formDetailsData === void 0 ? void 0 : formDetailsData.basketReferenceId) === basketReferenceId) {
      return {
        rfs: formDetailsData.reasonForStay,
        basketReferenceId,
        updated: formDetailsData.updated
      };
    }
    return defaultFlags;
  } catch (error) {
    return defaultFlags;
  }
};
exports.extractFormDetailsFlags = extractFormDetailsFlags;
function AnonRFS(_ref) {
  var _formField$props4, _formField$props5;
  var {
    formField
  } = _ref;
  var [rfsRequested, setRfsRequested] = (0, _react.useState)(false);
  var idTokenCookie = (0, _utils.getAuthCookie)();
  (0, _react.useEffect)(() => {
    var _formField$props, _formField$props2;
    if (formField !== null && formField !== void 0 && (_formField$props = formField.props) !== null && _formField$props !== void 0 && _formField$props.basketReferenceId && formField !== null && formField !== void 0 && (_formField$props2 = formField.props) !== null && _formField$props2 !== void 0 && _formField$props2.hotelBrand && !rfsRequested) {
      var formDetailsFlags = extractFormDetailsFlags(formField.props.basketReferenceId, formField.props.hotelBrand);
      if (!idTokenCookie || idTokenCookie && formDetailsFlags.updated) {
        if (formDetailsFlags.rfs === _api.PurposeOfStay.BUSINESS) {
          _utils.analytics.update({
            bookingReasonForStay: _api.PurposeOfStayAnalytics.BUSINESS
          });
        } else if (formDetailsFlags.rfs === _api.PurposeOfStay.LEISURE) {
          _utils.analytics.update({
            bookingReasonForStay: _api.PurposeOfStayAnalytics.LEISURE
          });
        }
        if (formDetailsFlags.rfs !== '') {
          var _formField$props3, _formField$props3$upd;
          formField === null || formField === void 0 || (_formField$props3 = formField.props) === null || _formField$props3 === void 0 || (_formField$props3$upd = _formField$props3.updateReasonForStay) === null || _formField$props3$upd === void 0 || _formField$props3$upd.call(_formField$props3, formDetailsFlags.rfs);
          setRfsRequested(true);
        }
      }
    }
  }, [formField === null || formField === void 0 || (_formField$props4 = formField.props) === null || _formField$props4 === void 0 ? void 0 : _formField$props4.basketReferenceId, formField === null || formField === void 0 || (_formField$props5 = formField.props) === null || _formField$props5 === void 0 ? void 0 : _formField$props5.hotelBrand, idTokenCookie, rfsRequested]);
  return null;
}