"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.COMPANY_MODAL_TYPE = void 0;
var COMPANY_MODAL_TYPE = exports.COMPANY_MODAL_TYPE = function (COMPANY_MODAL_TYPE) {
  COMPANY_MODAL_TYPE["CONTRACT_RATE_CODE"] = "CONTRACT_RATE_CODE";
  COMPANY_MODAL_TYPE["ACCOUNT_TO_COMPANY"] = "ACCOUNT_TO_COMPANY";
  return COMPANY_MODAL_TYPE;
}({});