"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.COPY_BOOKING = void 0;
var _taggedTemplateLiteral2 = _interopRequireDefault(require("@babel/runtime/helpers/taggedTemplateLiteral"));
var _graphqlRequest = require("graphql-request");
var _templateObject;
var COPY_BOOKING = exports.COPY_BOOKING = (0, _graphqlRequest.gql)(_templateObject || (_templateObject = (0, _taggedTemplateLiteral2.default)(["\n  mutation copyBooking(\n    $originalBasketReference: String!\n    $bookingChannel: BookingChannelCriteria!\n    $token: String!\n  ) {\n    copyBooking(\n      copyBookingCriteria: {\n        originalBasketReference: $originalBasketReference\n        bookingChannel: $bookingChannel\n        token: $token\n      }\n    ) {\n      copyBasketReference\n    }\n  }\n"])));