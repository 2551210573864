"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.guestDetailsBBFormConfig = void 0;
var _atoms = require("@whitbread-eos/atoms");
var _molecules = require("@whitbread-eos/molecules");
var _utils = require("@whitbread-eos/utils");
var _formValidation = _interopRequireDefault(require("./formValidation"));
var guestDetailsBBFormConfig = _ref => {
  var {
    autoComplete,
    getFormState,
    defaultValues,
    onSubmit,
    baseDataTestIdGuestDetails,
    baseDataTestIdAccompayningGuestDetails,
    t,
    labels,
    validationLabels,
    numberOfRooms,
    reservationByIdList,
    queryClient,
    guestList,
    setGuestUser,
    isDynamicSearchVisible,
    isAccompanyingGuestDetailsEnabled,
    accessLevel,
    selfBookerDetails
  } = _ref;
  var {
    formValidationSchema
  } = (0, _formValidation.default)(validationLabels);
  var config = {
    autoComplete: autoComplete,
    id: 'guestDetailsBBForm',
    elements: {
      fieldsContainerStyles: {
        marginBottom: 0
      },
      buttonsContainerStyles: {
        marginBottom: 0
      },
      fields: [{
        type: _atoms.FORM_FIELD_TYPES.DYNAMIC_FIELD,
        dropdownOptions: (0, _utils.formatGuestTitleOptions)(t('booking.guest.nameTitles')),
        name: 'bbGuestDetails',
        label: '',
        testid: (0, _utils.formatDataTestId)(baseDataTestIdGuestDetails, 'Form'),
        Component: _molecules.BBAllGuestsDetailsForm,
        props: {
          numberOfRooms,
          labels,
          reservationByIdList,
          defaultValues,
          queryClient,
          guestList,
          setGuestUser,
          isDynamicSearchVisible,
          isAccompanyingGuestDetailsEnabled,
          accessLevel,
          selfBookerDetails,
          baseDataTestIdAccompayningGuestDetails
        }
      }],
      buttons: [{
        type: _atoms.FORM_BUTTON_TYPES.SUBMIT,
        action: onSubmit,
        label: 'Submit',
        styles: {
          display: 'none'
        },
        props: {
          variant: 'tertiary',
          size: 'full'
        }
      }]
    },
    defaultValues,
    validationSchema: formValidationSchema,
    getFormState
  };
  return config;
};
exports.guestDetailsBBFormConfig = guestDetailsBBFormConfig;