"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.UPDATE_DISCOUNT = void 0;
var _taggedTemplateLiteral2 = _interopRequireDefault(require("@babel/runtime/helpers/taggedTemplateLiteral"));
var _graphqlRequest = require("graphql-request");
var _templateObject;
var UPDATE_DISCOUNT = exports.UPDATE_DISCOUNT = (0, _graphqlRequest.gql)(_templateObject || (_templateObject = (0, _taggedTemplateLiteral2.default)(["\n  mutation updateDiscountMutation($basketReference: String!, $discountAmount: Float) {\n    updateDiscount(\n      updateDiscountRequest: { basketReference: $basketReference, discountAmount: $discountAmount }\n    )\n  }\n"])));