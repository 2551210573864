"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "DataModal", {
  enumerable: true,
  get: function get() {
    return _DataModal.default;
  }
});
var _DataModal = _interopRequireDefault(require("./DataModal.component"));