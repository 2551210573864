"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = setAnalyticsUser;
var _api = require("@whitbread-eos/api");
var _analytics = _interopRequireDefault(require("./analytics"));
function setAnalyticsUser(user, locale) {
  if (!user) {
    return;
  }
  var analyticsUserLevel = '';
  var userRole = user['https://ccui.opera.whitbread.digital/role'][0];
  if (userRole !== _api.UserRoles.MANAGER) {
    analyticsUserLevel = locale === 'en' ? 'CCUI Agent' : 'CCUI DE Agent';
  } else {
    analyticsUserLevel = locale === 'en' ? 'CCUI Manager' : 'CCUI DE Manager';
  }
  _analytics.default.update({
    userLevel: analyticsUserLevel,
    userID: 'CCUI_User',
    userLoggedIn: 'Logged in',
    CCUI: true,
    dashboard: {
      userAgentEmail: user.email
    }
  });
}