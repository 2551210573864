"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.INVALID_LOCATION_ERROR_MESSAGE = exports.IGNORED_KEYS_FOR_HDP_URL = exports.APP_VARIANT = void 0;
var IGNORED_KEYS_FOR_HDP_URL = exports.IGNORED_KEYS_FOR_HDP_URL = ['searchModel.searchTerm', 'PLACEID', 'LOCATION', 'BOOKINGCHANNEL', 'SORT', 'VIEW', 'FILTERS'];
var APP_VARIANT = exports.APP_VARIANT = {
  CCUI: 'CCUI',
  PI: 'PI',
  BB: 'BB'
};
var INVALID_LOCATION_ERROR_MESSAGE = exports.INVALID_LOCATION_ERROR_MESSAGE = 'invalid.location.exception';