"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var Breadcrumb = {
  parts: ['item', 'link', 'container', 'separator'],
  baseStyle: {
    container: {
      fontSize: 'xxs'
    },
    item: {
      _focus: {
        outline: 'none'
      }
    },
    link: {
      _hover: {
        textDecoration: 'initial'
      },
      _focus: {
        outline: 'none',
        boxShadow: 'none'
      }
    }
  },
  sizes: {
    sm: {
      separator: {
        marginLeft: '1',
        marginRight: '1'
      }
    }
  },
  variants: {
    mobile: {
      container: {
        width: 'var(--chakra-space-breakpoint-m)'
      }
    }
  }
};
var _default = exports.default = Breadcrumb;