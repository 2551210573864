"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = HotelGalleryComponent;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _react = require("@chakra-ui/react");
var _atoms = require("@whitbread-eos/atoms");
var _utils = require("@whitbread-eos/utils");
var _nextI18next = require("next-i18next");
var _image = _interopRequireDefault(require("next/image"));
var _react2 = require("react");
var _jsxRuntime = require("react/jsx-runtime");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function HotelGalleryComponent(_ref) {
  var {
    thumbnailSectionHeight,
    isLoading,
    isError,
    data,
    error,
    isLessThanMd,
    isLessThanLg
  } = _ref;
  var {
    t
  } = (0, _nextI18next.useTranslation)(['common']);
  var [activeThumbnail, setActiveThumbnail] = (0, _react2.useState)(0);
  var [isModalVisible, setIsModalVisible] = (0, _react2.useState)(false);
  var [activeSlide, setActiveSlide] = (0, _react2.useState)(0);
  var imageLoader = (0, _utils.isIVMEnabled)() ? _utils.akamaiImageLoader : undefined;
  if (isLoading) {
    return (0, _jsxRuntime.jsx)(_react.Text, {
      children: t('searchresults.list.hotel.loading')
    });
  }
  if (isError) {
    return (0, _jsxRuntime.jsx)(_react.Text, {
      children: error.message
    });
  }
  if (!data) {
    return null;
  }
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [isLessThanLg ? renderSingleThumbnail() : renderThumbnailsGrid(), renderModal()]
  });
  function renderSingleThumbnail() {
    var singleThumbnailStyles = {
      position: 'relative',
      padding: '0 !important',
      marginLeft: {
        mobile: '-1.9rem !important'
      },
      width: {
        mobile: 'calc(100% + var(--chakra-space-3xl))',
        md: 'calc(100% + var(--chakra-space-4xl))'
      },
      h: {
        sm: '25.313rem',
        xs: '18.938rem',
        mobile: '11.25rem',
        base: '11.25rem'
      },
      mb: 'lg',
      cursor: 'pointer'
    };
    if (!(data !== null && data !== void 0 && data.length)) {
      return null;
    }
    return (0, _jsxRuntime.jsxs)(_react.Box, {
      position: "relative",
      "data-testid": "singleThumbnail",
      onClick: () => setIsModalVisible(false),
      children: [(0, _jsxRuntime.jsx)(_react.Box, _objectSpread(_objectSpread({}, singleThumbnailStyles), {}, {
        children: (0, _jsxRuntime.jsx)(_atoms.Carousel, {
          activeSlide: activeSlide,
          setActiveSlide: setActiveSlide,
          children: renderImages()
        }, activeThumbnail)
      })), renderImagesCountBadge()]
    });
  }
  function renderImagesCountBadge() {
    var imagesCountBadgeStyles = {
      display: 'inline',
      color: 'baseWhite',
      fontSize: 'xs',
      lineHeight: 'md',
      position: 'absolute',
      bottom: 'md',
      right: 0,
      bg: 'darkGrey1',
      opacity: 0.75,
      px: 'sm',
      borderRadius: 4
    };
    return (0, _jsxRuntime.jsxs)(_react.Box, _objectSpread(_objectSpread({}, imagesCountBadgeStyles), {}, {
      "data-testid": "hdp_hotelGalleryImagesCount",
      children: [activeSlide + 1, " / ", data === null || data === void 0 ? void 0 : data.length]
    }));
  }
  function renderThumbnailsGrid() {
    var _data$map;
    var thumbnailGridStyles = {
      templateRows: 'repeat(2, 1fr)',
      templateColumns: 'repeat(4, 1fr)',
      gap: 3,
      w: '100%'
    };
    var firstThreeThumbnails = (data === null || data === void 0 || (_data$map = data.map((item, index) => {
      var _item$thumbnailSrc, _item$alt;
      var sizes = index === 0 ? '(max-width: 1280px) 480px, (min-width: 1281px) 514px' : '164px';
      return (0, _jsxRuntime.jsx)(Thumbnail, {
        src: (0, _utils.formatAssetsUrl)((_item$thumbnailSrc = item === null || item === void 0 ? void 0 : item.thumbnailSrc) !== null && _item$thumbnailSrc !== void 0 ? _item$thumbnailSrc : ''),
        alt: (_item$alt = item === null || item === void 0 ? void 0 : item.alt) !== null && _item$alt !== void 0 ? _item$alt : '',
        onClick: () => {
          setActiveThumbnail(index);
          setIsModalVisible(true);
          setActiveSlide(index);
        },
        imageLoader: imageLoader,
        loadWithPriority: index === 0,
        sizes: sizes
      }, item.alt);
    })) === null || _data$map === void 0 ? void 0 : _data$map.slice(0, 3)) || [];
    return (0, _jsxRuntime.jsxs)(_react.Box, {
      position: "relative",
      "data-testid": "thumbnails",
      children: [(0, _jsxRuntime.jsxs)(_react.Grid, _objectSpread(_objectSpread({}, thumbnailGridStyles), {}, {
        h: thumbnailSectionHeight,
        children: [(0, _jsxRuntime.jsx)(_react.GridItem, {
          rowSpan: 2,
          colSpan: 3,
          children: firstThreeThumbnails[0] || (0, _jsxRuntime.jsx)(EmptyThumbnail, {})
        }), (0, _jsxRuntime.jsx)(_react.GridItem, {
          colSpan: 1,
          children: firstThreeThumbnails[1] || (0, _jsxRuntime.jsx)(EmptyThumbnail, {})
        }), (0, _jsxRuntime.jsx)(_react.GridItem, {
          colSpan: 1,
          children: firstThreeThumbnails[2] || (0, _jsxRuntime.jsx)(EmptyThumbnail, {})
        })]
      })), data && (data === null || data === void 0 ? void 0 : data.length) > 3 && renderSeeAllPhotosButton()]
    });
  }
  function renderSeeAllPhotosButton() {
    var seeAllPhotosButtonStyles = {
      position: 'absolute',
      bottom: 'sm',
      right: 'sm',
      width: 'auto',
      height: 'var(--chakra-space-xl)',
      fontSize: 'sm'
    };
    return (0, _jsxRuntime.jsx)(_atoms.Button, _objectSpread(_objectSpread({
      variant: "tertiary",
      size: "sm",
      "data-testid": "hdp_hotelGallerySeeAllPhotos"
    }, seeAllPhotosButtonStyles), {}, {
      onClick: () => {
        setActiveThumbnail(0);
        setIsModalVisible(true);
        setActiveSlide(0);
      },
      children: t('hoteldetails.seeallphotos')
    }));
  }
  function renderModal() {
    var _renderImages;
    var mobileGalleryThumbnails = data === null || data === void 0 ? void 0 : data.map(item => {
      var _item$thumbnailSrc2, _item$alt2;
      return (0, _jsxRuntime.jsx)(_react.Image, {
        src: (0, _utils.formatAssetsUrl)((_item$thumbnailSrc2 = item === null || item === void 0 ? void 0 : item.thumbnailSrc) !== null && _item$thumbnailSrc2 !== void 0 ? _item$thumbnailSrc2 : ''),
        alt: (_item$alt2 = item === null || item === void 0 ? void 0 : item.alt) !== null && _item$alt2 !== void 0 ? _item$alt2 : ''
      }, item.alt);
    });
    return (0, _jsxRuntime.jsx)(_atoms.ModalVariants, {
      isOpen: isModalVisible,
      onClose: () => {
        setIsModalVisible(false);
        setActiveThumbnail(0);
        setActiveSlide(0);
      },
      dataTestId: "hdp_hotelGallery",
      variant: "gallery",
      variantProps: {
        title: "".concat(activeSlide + 1, "/").concat((_renderImages = renderImages()) === null || _renderImages === void 0 ? void 0 : _renderImages.length)
      },
      children: isLessThanLg ? (0, _jsxRuntime.jsx)(_atoms.MobileCarousel, {
        activeSlide: activeSlide,
        setActiveSlide: setActiveSlide,
        thumbnails: mobileGalleryThumbnails,
        children: renderImages()
      }, activeThumbnail) : (0, _jsxRuntime.jsx)(_react.Box, {
        w: "41.775rem",
        px: "lg",
        children: (0, _jsxRuntime.jsx)(_atoms.Carousel, {
          activeSlide: activeSlide,
          setActiveSlide: setActiveSlide,
          children: renderImages()
        }, activeThumbnail)
      })
    });
  }
  function renderImages() {
    var iconStyles = isLessThanMd ? {
      position: 'absolute',
      left: 'sm',
      bottom: '6xl',
      bgColor: 'baseWhite',
      borderRadius: '4px',
      p: '6px 6px 6px 8px'
    } : {
      mr: 'sm'
    };
    var captionFlexStyles = {
      position: 'relative',
      alignItems: 'center',
      justify: 'center',
      minH: '2rem'
    };
    var sizes;
    if (!isLessThanLg) {
      sizes = '620px';
    }
    return data === null || data === void 0 ? void 0 : data.map(item => {
      var _item$imageSrc;
      return (0, _jsxRuntime.jsxs)(_react.Box, {
        children: [(0, _jsxRuntime.jsx)(_react.Box, {
          position: "relative",
          w: "full",
          h: {
            sm: '22.356rem',
            xs: '18.938rem',
            mobile: '11.25rem',
            base: '11.25rem'
          },
          children: (0, _jsxRuntime.jsx)(_image.default, {
            src: (0, _utils.formatAssetsUrl)((_item$imageSrc = item === null || item === void 0 ? void 0 : item.imageSrc) !== null && _item$imageSrc !== void 0 ? _item$imageSrc : ''),
            alt: "Hotel Image",
            fill: true,
            style: {
              objectFit: 'cover'
            },
            priority: false,
            loader: imageLoader,
            sizes: sizes
          })
        }), (0, _jsxRuntime.jsxs)(_react.Flex, _objectSpread(_objectSpread({}, captionFlexStyles), {}, {
          "data-testid": "carousel_hotel-image-caption",
          children: [item.iconSrc && (0, _jsxRuntime.jsx)(_atoms.Icon, _objectSpread({
            src: (0, _utils.formatAssetsUrl)(item.iconSrc)
          }, iconStyles)), (0, _jsxRuntime.jsx)(_react.Text, {
            children: item.caption
          })]
        }))]
      }, item.alt);
    });
  }
}
function Thumbnail(_ref2) {
  var {
    src,
    alt,
    onClick,
    loadWithPriority,
    imageLoader,
    sizes = ''
  } = _ref2;
  var [isHovering, setIsHovering] = (0, _react2.useState)(false);
  var thumbnailStyes = {
    width: '100%',
    height: '100%',
    position: 'relative',
    cursor: 'pointer'
  };
  return (0, _jsxRuntime.jsx)(_react.Box, _objectSpread(_objectSpread({}, thumbnailStyes), {}, {
    onMouseEnter: () => setIsHovering(true),
    onMouseLeave: () => setIsHovering(false),
    sx: {
      '& img': {
        transition: 'filter 0.3s ease-in-out',
        '&.darken': {
          filter: 'brightness(90%)'
        }
      }
    },
    children: (0, _jsxRuntime.jsx)(_image.default, {
      src: src,
      alt: alt,
      fill: true,
      style: {
        objectFit: 'cover'
      },
      onClick: onClick,
      className: isHovering ? 'darken' : '',
      priority: loadWithPriority,
      loader: imageLoader,
      sizes: sizes
    })
  }));
}
function EmptyThumbnail() {
  return (0, _jsxRuntime.jsx)(_react.Box, {
    "data-testid": "emptyThumbnail",
    bgColor: "lightGrey4",
    h: "100%",
    w: "100%"
  });
}