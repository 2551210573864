"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = CookiePolicies;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _react = require("@chakra-ui/react");
var _atoms = require("@whitbread-eos/atoms");
var _utils = require("@whitbread-eos/utils");
var _jsxRuntime = require("react/jsx-runtime");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function CookiePolicies(_ref) {
  var _labels$cookieConsent, _cookiePolicies$intro, _cookiePolicies$intro2, _cookiePolicies$intro3;
  var {
    labels,
    cookiePermissions,
    setCookiePolicies,
    manageCookies,
    modalStyles
  } = _ref;
  var baseTestId = 'CookiePoliciesModal';
  var cookiePolicies = labels === null || labels === void 0 || (_labels$cookieConsent = labels.cookieConsent) === null || _labels$cookieConsent === void 0 ? void 0 : _labels$cookieConsent.cookiePolicies;
  var acceptAllCookies = () => {
    var newCookiePermissions = cookiePermissions === null || cookiePermissions === void 0 ? void 0 : cookiePermissions.map(cookiePermission => {
      if (cookiePermission.name !== 'permissionEssential') {
        return _objectSpread(_objectSpread({}, cookiePermission), {}, {
          value: true
        });
      }
      return cookiePermission;
    });
    setCookiePolicies(newCookiePermissions);
  };
  return (0, _jsxRuntime.jsxs)(_react.Flex, _objectSpread(_objectSpread(_objectSpread({}, modalStyles === null || modalStyles === void 0 ? void 0 : modalStyles.wrapperStyles), cookieIntroStyle), {}, {
    "data-testid": (0, _utils.formatDataTestId)(baseTestId, 'Container'),
    children: [(0, _jsxRuntime.jsx)(_react.Flex, {
      children: (0, _jsxRuntime.jsx)(_react.Text, _objectSpread(_objectSpread({}, modalStyles === null || modalStyles === void 0 ? void 0 : modalStyles.textStyles.description), {}, {
        "data-testid": (0, _utils.formatDataTestId)(baseTestId, 'Description'),
        children: (0, _utils.renderSanitizedHtml)(cookiePolicies === null || cookiePolicies === void 0 || (_cookiePolicies$intro = cookiePolicies.introView) === null || _cookiePolicies$intro === void 0 ? void 0 : _cookiePolicies$intro.description)
      }))
    }), (0, _jsxRuntime.jsxs)(_react.Flex, _objectSpread(_objectSpread({
      mt: "lg"
    }, modalStyles === null || modalStyles === void 0 ? void 0 : modalStyles.buttonsStyle), {}, {
      children: [(0, _jsxRuntime.jsx)(_atoms.Button, {
        size: "full",
        variant: "tertiary",
        "data-testid": (0, _utils.formatDataTestId)(baseTestId, 'ManageButton'),
        onClick: manageCookies,
        children: cookiePolicies === null || cookiePolicies === void 0 || (_cookiePolicies$intro2 = cookiePolicies.introView) === null || _cookiePolicies$intro2 === void 0 ? void 0 : _cookiePolicies$intro2.manageButtonText
      }), (0, _jsxRuntime.jsx)(_atoms.Button, _objectSpread(_objectSpread({
        size: "full",
        variant: "primary",
        "data-testid": (0, _utils.formatDataTestId)(baseTestId, 'AcceptAllButton'),
        onClick: acceptAllCookies
      }, acceptAllBtnStyle), {}, {
        children: cookiePolicies === null || cookiePolicies === void 0 || (_cookiePolicies$intro3 = cookiePolicies.introView) === null || _cookiePolicies$intro3 === void 0 ? void 0 : _cookiePolicies$intro3.acceptAllButtonText
      }))]
    }))]
  }));
}
var cookieIntroStyle = {
  width: {
    sm: '33.75rem',
    md: '33.375rem',
    lg: '39rem',
    xl: '36rem'
  }
};
var acceptAllBtnStyle = {
  mt: {
    mobile: 'md',
    sm: 0
  },
  ml: {
    md: 'xl',
    sm: 'xl',
    lg: 'xl'
  }
};