"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "getClientDefaultSessionTracing", {
  enumerable: true,
  get: function get() {
    return _tracing.getClientDefaultSessionTracing;
  }
});
Object.defineProperty(exports, "getDefaultSessionTracing", {
  enumerable: true,
  get: function get() {
    return _tracing.getDefaultSessionTracing;
  }
});
exports.logger = exports.instrumentQueryClient = exports.instrument = exports.getLogger = void 0;
Object.defineProperty(exports, "tracingCookie", {
  enumerable: true,
  get: function get() {
    return _tracing.tracingCookie;
  }
});
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));
var _objectWithoutProperties2 = _interopRequireDefault(require("@babel/runtime/helpers/objectWithoutProperties"));
var _config = _interopRequireDefault(require("next/config"));
var _pino = _interopRequireDefault(require("pino"));
var _tracing = require("../utils/tracing");
var _excluded = ["label"];
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
var getLogger = () => {
  var {
    publicRuntimeConfig = {}
  } = (0, _config.default)() || {};
  var logger = (0, _pino.default)({
    level: publicRuntimeConfig.NEXT_PUBLIC_LOG_LEVEL || 'info'
  });
  return logger;
};
exports.getLogger = getLogger;
var defaultLogger = exports.logger = getLogger();
var instrument = (_ref, fn) => {
  var {
      label
    } = _ref,
    msg = (0, _objectWithoutProperties2.default)(_ref, _excluded);
  return (0, _asyncToGenerator2.default)(function* () {
    defaultLogger.info({
      label: "".concat(label, "_START"),
      msg
    });
    var resp = yield fn(...arguments);
    defaultLogger.info({
      label: "".concat(label, "_END"),
      msg
    });
    return resp;
  });
};
exports.instrument = instrument;
var instrumentQueryClient = exports.instrumentQueryClient = function instrumentQueryClient(queryClient) {
  var msg = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  function prefetchQuery() {
    var fn = instrument(_objectSpread({
      label: 'PREFETCH_QUERY',
      queryKey: arguments.length <= 0 ? undefined : arguments[0]
    }, msg), queryClient.prefetchQuery.bind(queryClient));
    return fn({
      queryKey: arguments.length <= 0 ? undefined : arguments[0],
      queryFn: arguments.length <= 1 ? undefined : arguments[1]
    });
  }
  function fetchQuery() {
    var fn = instrument(_objectSpread({
      label: 'FETCH_QUERY',
      queryKey: arguments.length <= 0 ? undefined : arguments[0]
    }, msg), queryClient.fetchQuery.bind(queryClient));
    return fn({
      queryKey: arguments.length <= 0 ? undefined : arguments[0],
      queryFn: arguments.length <= 1 ? undefined : arguments[1]
    });
  }
  return {
    prefetchQuery: prefetchQuery.bind(queryClient),
    fetchQuery: fetchQuery.bind(queryClient)
  };
};