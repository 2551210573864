"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = TransportInformationQueryWrapper;
var _reactQuery = require("@tanstack/react-query");
var _TransportInformation = _interopRequireDefault(require("./TransportInformation.container"));
var _jsxRuntime = require("react/jsx-runtime");
function TransportInformationQueryWrapper(_ref) {
  var {
    queryClient
  } = _ref;
  return (0, _jsxRuntime.jsx)(_reactQuery.QueryClientProvider, {
    client: queryClient,
    children: (0, _jsxRuntime.jsx)(_TransportInformation.default, {})
  });
}