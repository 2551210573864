"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "getCardManagementLabels", {
  enumerable: true,
  get: function get() {
    return _getCardManagementLabels.getCardManagementLabels;
  }
});
var _getCardManagementLabels = require("./getCardManagementLabels");