"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.UPDATE_EMAIL = void 0;
var _taggedTemplateLiteral2 = _interopRequireDefault(require("@babel/runtime/helpers/taggedTemplateLiteral"));
var _graphqlRequest = require("graphql-request");
var _templateObject;
var UPDATE_EMAIL = exports.UPDATE_EMAIL = (0, _graphqlRequest.gql)(_templateObject || (_templateObject = (0, _taggedTemplateLiteral2.default)(["\n  mutation updateEmailMutation(\n    $basketReference: String!\n    $updateEmailCriteria: UpdateEmailCriteria!\n  ) {\n    updateEmail(basketReference: $basketReference, updateEmailCriteria: $updateEmailCriteria)\n  }\n"])));