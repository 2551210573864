"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = NoPaymentMethodsNotification;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _react = require("@chakra-ui/react");
var _atoms = require("@whitbread-eos/atoms");
var _utils = require("@whitbread-eos/utils");
var _nextI18next = require("next-i18next");
var _jsxRuntime = require("react/jsx-runtime");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function NoPaymentMethodsNotification() {
  var {
    t
  } = (0, _nextI18next.useTranslation)(['common']);
  var baseDataTestId = 'NoPaymentOptions';
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_react.Text, _objectSpread(_objectSpread({
      "data-testid": (0, _utils.formatDataTestId)(baseDataTestId, 'HeaderText')
    }, headerProps), {}, {
      children: t('booking.header.payment')
    })), (0, _jsxRuntime.jsx)(_atoms.Notification, {
      variant: "error",
      status: "error",
      description: (0, _jsxRuntime.jsxs)(_react.Box, {
        "data-testid": (0, _utils.formatDataTestId)(baseDataTestId, 'ErrorBox'),
        children: [(0, _jsxRuntime.jsx)(_react.Text, _objectSpread(_objectSpread({
          "data-testid": (0, _utils.formatDataTestId)(baseDataTestId, 'TitleText')
        }, titleTextProps), {}, {
          children: t('cc.noMethods')
        })), (0, _jsxRuntime.jsx)(_react.Text, _objectSpread(_objectSpread({
          "data-testid": (0, _utils.formatDataTestId)(baseDataTestId, 'DescriptionText')
        }, descriptionTextProps), {}, {
          children: t('cc.noMethodsAuthorised')
        }))]
      }),
      svg: (0, _jsxRuntime.jsx)(_atoms.Error, {}),
      wrapperStyles: paymentTypeInfoMsgStyle
    })]
  });
}
var paymentTypeInfoMsgStyle = {
  w: {
    mobile: 'full',
    xs: 'full',
    md: 'full',
    lg: 'full',
    xl: 'full'
  }
};
var headerProps = {
  fontSize: '3xxl',
  fontWeight: 'semibold',
  lineHeight: 5,
  marginBottom: 'lg'
};
var titleTextProps = {
  fontSize: 'sm',
  fontWeight: 'semibold',
  lineHeight: 2
};
var descriptionTextProps = {
  fontSize: 'sm',
  lineHeight: 2
};