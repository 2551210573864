"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Notification = void 0;
var _server = require("@whitbread-eos/utils/server");
var _image = _interopRequireDefault(require("next/image"));
var React = _interopRequireWildcard(require("react"));
var _jsxRuntime = require("react/jsx-runtime");
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && {}.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
var Notification = _ref => {
  var {
    title,
    icon,
    message,
    type = 'default',
    className
  } = _ref;
  var getNotificationStyle = () => {
    if (type === 'error') {
      return errorStyle;
    }
    if (type === 'warning') {
      return warningStyle;
    }
    if (type === 'info') {
      return infoStyle;
    }
    return defaultStyle;
  };
  return (0, _jsxRuntime.jsxs)("div", {
    className: (0, _server.cn)(notificationStyle, getNotificationStyle(), className),
    children: [(0, _jsxRuntime.jsx)(_image.default, {
      className: iconStyle,
      src: icon,
      alt: 'notification icon',
      width: 16,
      height: 16
    }), (0, _jsxRuntime.jsxs)("div", {
      className: textStyle,
      children: [title && (0, _jsxRuntime.jsx)("span", {
        className: titleStyle,
        children: title
      }), message && (0, _jsxRuntime.jsx)("span", {
        children: message
      })]
    })]
  });
};
exports.Notification = Notification;
var notificationStyle = 'flex p-4 border rounded gap-2 w-full';
var iconStyle = 'w-4 h-4 overflow-hidden shrink-0';
var textStyle = 'flex flex-col text-sm';
var titleStyle = 'font-semibold';
var defaultStyle = 'border-successTint2 bg-successTint';
var errorStyle = 'border-errorTint bg-tooltipError';
var warningStyle = 'bg-notificationAlertBg border-notificationAlertBorder';
var infoStyle = 'border-tooltipInfoTint bg-tooltipInfo';