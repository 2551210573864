"use strict";
'use client';

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.DropdownMenuTrigger = exports.DropdownMenuSubTrigger = exports.DropdownMenuSubContent = exports.DropdownMenuSub = exports.DropdownMenuShortcut = exports.DropdownMenuSeparator = exports.DropdownMenuRadioItem = exports.DropdownMenuRadioGroup = exports.DropdownMenuPortal = exports.DropdownMenuLabel = exports.DropdownMenuItem = exports.DropdownMenuGroup = exports.DropdownMenuContent = exports.DropdownMenuCheckboxItem = exports.DropdownMenu = void 0;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _objectWithoutProperties2 = _interopRequireDefault(require("@babel/runtime/helpers/objectWithoutProperties"));
var DropdownMenuPrimitive = _interopRequireWildcard(require("@radix-ui/react-dropdown-menu"));
var _server = require("@whitbread-eos/utils/server");
var _lucideReact = require("lucide-react");
var React = _interopRequireWildcard(require("react"));
var _jsxRuntime = require("react/jsx-runtime");
var _excluded = ["className", "inset", "children"],
  _excluded2 = ["className"],
  _excluded3 = ["className", "sideOffset"],
  _excluded4 = ["className", "inset"],
  _excluded5 = ["className", "children", "checked", "indicatorPosition"],
  _excluded6 = ["className", "children"],
  _excluded7 = ["className", "inset"],
  _excluded8 = ["className"],
  _excluded9 = ["className"];
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && {}.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
var DropdownMenu = exports.DropdownMenu = DropdownMenuPrimitive.Root;
var DropdownMenuTrigger = exports.DropdownMenuTrigger = DropdownMenuPrimitive.Trigger;
var DropdownMenuGroup = exports.DropdownMenuGroup = DropdownMenuPrimitive.Group;
var DropdownMenuPortal = exports.DropdownMenuPortal = DropdownMenuPrimitive.Portal;
var DropdownMenuSub = exports.DropdownMenuSub = DropdownMenuPrimitive.Sub;
var DropdownMenuRadioGroup = exports.DropdownMenuRadioGroup = DropdownMenuPrimitive.RadioGroup;
var DropdownMenuSubTrigger = exports.DropdownMenuSubTrigger = React.forwardRef((_ref, ref) => {
  var {
      className,
      inset,
      children
    } = _ref,
    props = (0, _objectWithoutProperties2.default)(_ref, _excluded);
  return (0, _jsxRuntime.jsxs)(DropdownMenuPrimitive.SubTrigger, _objectSpread(_objectSpread({
    ref: ref,
    className: (0, _server.cn)('flex cursor-default select-none items-center rounded-sm px-2 py-1.5 text-sm outline-none focus:bg-accent data-[state=open]:bg-accent', inset && 'pl-8', className)
  }, props), {}, {
    children: [children, (0, _jsxRuntime.jsx)(_lucideReact.ChevronRight, {
      className: "ml-auto h-4 w-4"
    })]
  }));
});
DropdownMenuSubTrigger.displayName = DropdownMenuPrimitive.SubTrigger.displayName;
var DropdownMenuSubContent = exports.DropdownMenuSubContent = React.forwardRef((_ref2, ref) => {
  var {
      className
    } = _ref2,
    props = (0, _objectWithoutProperties2.default)(_ref2, _excluded2);
  return (0, _jsxRuntime.jsx)(DropdownMenuPrimitive.SubContent, _objectSpread({
    ref: ref,
    className: (0, _server.cn)('z-50 min-w-[8rem] overflow-hidden rounded-md border bg-popover p-1 text-popover-foreground shadow-lg data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2', className)
  }, props));
});
DropdownMenuSubContent.displayName = DropdownMenuPrimitive.SubContent.displayName;
var DropdownMenuContent = exports.DropdownMenuContent = React.forwardRef((_ref3, ref) => {
  var {
      className,
      sideOffset = 4
    } = _ref3,
    props = (0, _objectWithoutProperties2.default)(_ref3, _excluded3);
  return (0, _jsxRuntime.jsx)(DropdownMenuPrimitive.Portal, {
    children: (0, _jsxRuntime.jsx)(DropdownMenuPrimitive.Content, _objectSpread({
      ref: ref,
      sideOffset: sideOffset,
      className: (0, _server.cn)('z-50 min-w-[8rem] overflow-hidden rounded-md border bg-popover p-0 text-popover-foreground shadow-md data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2', className)
    }, props))
  });
});
DropdownMenuContent.displayName = DropdownMenuPrimitive.Content.displayName;
var DropdownMenuItem = exports.DropdownMenuItem = React.forwardRef((_ref4, ref) => {
  var {
      className,
      inset
    } = _ref4,
    props = (0, _objectWithoutProperties2.default)(_ref4, _excluded4);
  return (0, _jsxRuntime.jsx)(DropdownMenuPrimitive.Item, _objectSpread({
    ref: ref,
    className: (0, _server.cn)('relative flex cursor-default select-none items-center px-2 py-1.5 text-sm outline-none transition-colors focus:bg-accent focus:text-accent-foreground data-[disabled]:pointer-events-none data-[disabled]:opacity-50', inset && 'pl-8', className)
  }, props));
});
DropdownMenuItem.displayName = DropdownMenuPrimitive.Item.displayName;
var DropdownMenuCheckboxItem = exports.DropdownMenuCheckboxItem = React.forwardRef((_ref5, ref) => {
  var {
      className,
      children,
      checked,
      indicatorPosition = 'left'
    } = _ref5,
    props = (0, _objectWithoutProperties2.default)(_ref5, _excluded5);
  return (0, _jsxRuntime.jsxs)(DropdownMenuPrimitive.CheckboxItem, _objectSpread(_objectSpread({
    ref: ref,
    className: (0, _server.cn)('relative flex cursor-default select-none items-center rounded-sm py-1.5 pl-8 pr-2 text-sm outline-none transition-colors focus:bg-accent focus:text-accent-foreground data-[disabled]:pointer-events-none data-[disabled]:opacity-50', className),
    checked: checked
  }, props), {}, {
    children: [indicatorPosition === 'left' && (0, _jsxRuntime.jsx)("span", {
      className: "absolute left-4 flex h-3.5 w-3.5 items-center justify-center",
      children: (0, _jsxRuntime.jsx)(DropdownMenuPrimitive.ItemIndicator, {
        children: (0, _jsxRuntime.jsx)(_lucideReact.Check, {
          className: "h-4 w-4"
        })
      })
    }), children, indicatorPosition === 'right' && (0, _jsxRuntime.jsx)("span", {
      className: "absolute right-4 flex h-3.5 w-3.5 items-center justify-center",
      children: (0, _jsxRuntime.jsx)(DropdownMenuPrimitive.ItemIndicator, {
        children: (0, _jsxRuntime.jsx)(_lucideReact.Check, {
          className: "h-4 w-4"
        })
      })
    })]
  }));
});
DropdownMenuCheckboxItem.displayName = DropdownMenuPrimitive.CheckboxItem.displayName;
var DropdownMenuRadioItem = exports.DropdownMenuRadioItem = React.forwardRef((_ref6, ref) => {
  var {
      className,
      children
    } = _ref6,
    props = (0, _objectWithoutProperties2.default)(_ref6, _excluded6);
  return (0, _jsxRuntime.jsxs)(DropdownMenuPrimitive.RadioItem, _objectSpread(_objectSpread({
    ref: ref,
    className: (0, _server.cn)('relative flex cursor-default select-none items-center rounded-sm py-1.5 pl-8 pr-2 text-sm outline-none transition-colors focus:bg-accent focus:text-accent-foreground data-[disabled]:pointer-events-none data-[disabled]:opacity-50', className)
  }, props), {}, {
    children: [(0, _jsxRuntime.jsx)("span", {
      className: "absolute left-2 flex h-3.5 w-3.5 items-center justify-center",
      children: (0, _jsxRuntime.jsx)(DropdownMenuPrimitive.ItemIndicator, {
        children: (0, _jsxRuntime.jsx)(_lucideReact.Circle, {
          className: "h-2 w-2 fill-current"
        })
      })
    }), children]
  }));
});
DropdownMenuRadioItem.displayName = DropdownMenuPrimitive.RadioItem.displayName;
var DropdownMenuLabel = exports.DropdownMenuLabel = React.forwardRef((_ref7, ref) => {
  var {
      className,
      inset
    } = _ref7,
    props = (0, _objectWithoutProperties2.default)(_ref7, _excluded7);
  return (0, _jsxRuntime.jsx)(DropdownMenuPrimitive.Label, _objectSpread({
    ref: ref,
    className: (0, _server.cn)('px-2 py-1.5 text-sm font-semibold', inset && 'pl-8', className)
  }, props));
});
DropdownMenuLabel.displayName = DropdownMenuPrimitive.Label.displayName;
var DropdownMenuSeparator = exports.DropdownMenuSeparator = React.forwardRef((_ref8, ref) => {
  var {
      className
    } = _ref8,
    props = (0, _objectWithoutProperties2.default)(_ref8, _excluded8);
  return (0, _jsxRuntime.jsx)(DropdownMenuPrimitive.Separator, _objectSpread({
    ref: ref,
    className: (0, _server.cn)('-mx-1 my-1 h-px bg-muted', className)
  }, props));
});
DropdownMenuSeparator.displayName = DropdownMenuPrimitive.Separator.displayName;
var DropdownMenuShortcut = _ref9 => {
  var {
      className
    } = _ref9,
    props = (0, _objectWithoutProperties2.default)(_ref9, _excluded9);
  return (0, _jsxRuntime.jsx)("span", _objectSpread({
    className: (0, _server.cn)('ml-auto text-xs tracking-widest opacity-60', className)
  }, props));
};
exports.DropdownMenuShortcut = DropdownMenuShortcut;
DropdownMenuShortcut.displayName = 'DropdownMenuShortcut';