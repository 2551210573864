"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.SELECTION_STEP = void 0;
var SELECTION_STEP = exports.SELECTION_STEP = function (SELECTION_STEP) {
  SELECTION_STEP["LIST"] = "LIST";
  SELECTION_STEP["PROFILE"] = "PROFILE";
  return SELECTION_STEP;
}({});