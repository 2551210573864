"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.HashType = void 0;
var HashType = exports.HashType = function (HashType) {
  HashType["SHA256"] = "SHA-256";
  HashType["SHA384"] = "SHA-384";
  HashType["SHA512"] = "SHA-512";
  return HashType;
}({});