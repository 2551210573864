"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = usePackages;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _api = require("@whitbread-eos/api");
var _react = require("react");
var _useCustomLocale = _interopRequireDefault(require("./use-custom-locale"));
var _useRequest = require("./use-request");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function usePackages(_ref) {
  var {
    adultsNumber,
    childrenNumber,
    nightsNumber,
    basketReferenceId,
    hotelId,
    startDate,
    endDate,
    bookingFlowId,
    options,
    upsellItemsAllowed,
    channel
  } = _ref;
  var {
    country,
    language
  } = (0, _useCustomLocale.default)();
  var pckQuery = {
    adultsNumber: adultsNumber,
    childrenNumber: childrenNumber,
    nightsNumber: nightsNumber,
    basketReferenceId: basketReferenceId,
    hotelId: hotelId,
    startDate: startDate,
    endDate: endDate,
    bookingFlowId: bookingFlowId,
    language: language,
    country: country,
    channel
  };
  var {
    data,
    isError,
    isLoading,
    error
  } = (0, _useRequest.useQueryRequest)(['GetPackages', language, country, hotelId, bookingFlowId, startDate, endDate, nightsNumber, adultsNumber, childrenNumber, basketReferenceId, channel], _api.GET_PACKAGES, _objectSpread({}, pckQuery), _objectSpread({}, options));
  return (0, _react.useMemo)(() => {
    var packageResponse = data ? _objectSpread(_objectSpread({}, data.packages), {}, {
      packages: _objectSpread(_objectSpread({}, data.packages.packages), {}, {
        meals: upsellItemsAllowed ? data.packages.packages.meals.filter(meal => upsellItemsAllowed.includes(meal.bartId)) : data.packages.packages.meals
      })
    }) : {
      packages: {},
      restaurant: {},
      privacyPolicy: {},
      hotelHasCityTaxForBusiness: false,
      hotelHasCityTaxForLeisure: false
    };
    return _objectSpread(_objectSpread({
      isLoading,
      isError
    }, packageResponse), {}, {
      error
    });
  }, [data, isError, isLoading, error, upsellItemsAllowed]);
}