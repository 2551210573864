"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = BookingHistoryInfoCardComponent;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _react = require("@chakra-ui/react");
var _api = require("@whitbread-eos/api");
var _atoms = require("@whitbread-eos/atoms");
var _utils = require("@whitbread-eos/utils");
var _nextI18next = require("next-i18next");
var _BookingActions = _interopRequireDefault(require("./BookingActions"));
var _BookingDetails = require("./BookingDetails");
var _HotelDetails = _interopRequireDefault(require("./HotelDetails"));
var _jsxRuntime = require("react/jsx-runtime");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function BookingHistoryInfoCardComponent(props) {
  var status = props.basketStatus === 'FUTURE' ? _api.BOOKING_TYPE.UPCOMING : _api.BOOKING_TYPE[props.basketStatus];
  var {
    t
  } = (0, _nextI18next.useTranslation)();
  var showHotelDetails = props.basketStatus !== _api.BASKET_STATUS.CANCELLED || (props === null || props === void 0 ? void 0 : props.isReadOnly);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsxs)(_atoms.Card, _objectSpread(_objectSpread({}, cardWrapperStyle(props.basketStatus, props === null || props === void 0 ? void 0 : props.isReadOnly)), {}, {
      children: [(0, _jsxRuntime.jsxs)(_react.Flex, _objectSpread(_objectSpread({}, hotelDetailsWrapperStyle(props === null || props === void 0 ? void 0 : props.isReadOnly)), {}, {
        children: [!(props !== null && props !== void 0 && props.isReadOnly) ? (0, _jsxRuntime.jsx)(_BookingActions.default, _objectSpread(_objectSpread({}, props), {}, {
          bookingStatus: props.basketStatus,
          hideBookingStatus: true,
          role: "",
          bookingType: status,
          shouldRenderStatusAndActions: props.isAmendPage
        })) : (0, _jsxRuntime.jsxs)(_react.Box, _objectSpread(_objectSpread({}, bookerDetailsStyles), {}, {
          children: [(0, _jsxRuntime.jsxs)(_react.Box, {
            children: [(0, _jsxRuntime.jsx)(_react.Text, _objectSpread(_objectSpread({}, labelTextStyle), {}, {
              children: t('ccui.manageBooking.hotelName')
            })), (0, _jsxRuntime.jsxs)(_react.Text, _objectSpread(_objectSpread({}, textStyle), {}, {
              children: [" ", props === null || props === void 0 ? void 0 : props.hotelName]
            }))]
          }), (0, _jsxRuntime.jsxs)(_react.Box, {
            children: [(0, _jsxRuntime.jsx)(_react.Text, _objectSpread(_objectSpread({}, labelTextStyle), {}, {
              children: t('ccui.idv.personalInformation.bookerName')
            })), (0, _jsxRuntime.jsx)(_react.Text, _objectSpread(_objectSpread({}, textStyle), {}, {
              children: props === null || props === void 0 ? void 0 : props.leadGuestName
            }))]
          })]
        })), showHotelDetails && (0, _jsxRuntime.jsx)(_HotelDetails.default, _objectSpread(_objectSpread({}, props), {}, {
          basketReference: props.basketReference,
          bookingReference: props.bookingReference
        }))]
      })), (0, _jsxRuntime.jsx)(_BookingDetails.BookingDetailsComponent, {
        bookingReference: props.bookingReference,
        basketReference: props.basketReference,
        bookingStatus: (0, _utils.upperOnlyFirst)(props.basketStatus),
        bookingDetails: props.bookingDetails,
        noOfRooms: props.noOfRooms,
        skipContainerRendering: props.skipContainerRendering,
        baseDataTestId: props.baseDataTestId,
        getBookingStatus: props.onCancelBooking,
        bookingChannel: props.bookingChannel,
        sourceSystem: props.sourceSystem,
        area: props.area,
        showExtras: props.sourceSystem !== _api.SOURCE_SYSTEM.OPERA || !!(props !== null && props !== void 0 && props.isReadOnly),
        bookingSurname: props.bookingSurname,
        shouldShowTypeOfBooking: props === null || props === void 0 ? void 0 : props.shouldShowTypeOfBooking,
        isReadOnly: props === null || props === void 0 ? void 0 : props.isReadOnly
      })]
    })), props.invoiceSentMsg.displaySentInvoiceMsg && (0, _jsxRuntime.jsx)(_react.Box, {
      mt: "lg",
      "data-testid": (0, _utils.formatDataTestId)(props.baseDataTestId, 'Notification-Invoice'),
      children: (0, _jsxRuntime.jsx)(_atoms.Notification, {
        svg: (0, _jsxRuntime.jsx)(_atoms.Success, {}),
        status: "success",
        description: props.invoiceSentMsg.notificationMessage,
        variant: "success"
      })
    })]
  });
}
var textStyle = {
  color: 'darkGrey2',
  fontSize: 'lg',
  lineHeight: '3'
};
var bookerDetailsStyles = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  gap: '1.43rem'
};
var labelTextStyle = {
  fontSize: 'md',
  fontWeight: 'semibold',
  lineHeight: '3',
  color: 'darkGrey1'
};
var hotelDetailsWrapperStyle = isReadOnly => isReadOnly ? {
  flexDirection: {
    mobile: 'row'
  },
  columnGap: '4.12rem',
  minWidth: 'max-content'
} : {
  flexDirection: {
    mobile: 'column',
    lg: 'row'
  }
};
var cardWrapperStyle = (basketStatus, isReadOnly) => isReadOnly ? {
  backgroundColor: '#F9F9F9',
  display: 'grid',
  flexDirection: 'column',
  boxShadow: 'none',
  gridTemplateColumns: {
    lg: '1fr 1fr'
  },
  gap: '4.12rem',
  border: 'none',
  padding: '2.1rem 0rem 0rem 2.5rem',
  minHeight: '28rem'
} : {
  display: basketStatus === _api.BASKET_STATUS.CANCELLED ? {
    mobile: 'flex',
    lg: 'grid'
  } : 'flex',
  gridTemplateColumns: {
    lg: '1fr 1fr'
  },
  boxShadow: 'none',
  justifyContent: 'space-between',
  flexDirection: {
    mobile: 'column',
    lg: 'row'
  },
  border: 'none',
  padding: '0'
};