"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = DatePickerFormFieldComponent;
var _react = _interopRequireWildcard(require("react"));
var _Datepicker = _interopRequireDefault(require("../Datepicker"));
var _FormError = _interopRequireDefault(require("../Form/FormError"));
var _jsxRuntime = require("react/jsx-runtime");
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && {}.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
function DatePickerFormFieldComponent(_ref) {
  var _props$labels, _props$labels2, _props$labels3, _props$labels4, _props$labels5, _props$labels6;
  var {
    field,
    handleSetValue,
    errors = {},
    formField: {
      props,
      errorStyles
    }
  } = _ref;
  var [isError, setIsError] = (0, _react.useState)(false);
  (0, _react.useEffect)(() => {
    setIsError(!!errors[field.name]);
  }, [errors[field.name]]);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_Datepicker.default, {
      locale: props === null || props === void 0 ? void 0 : props.locale,
      minDate: new Date(),
      datepickerStyles: datepickerStyles,
      dateFormat: 'dd MMM yyyy',
      displayDateFormat: "dd MMM yyyy",
      selectsRange: true,
      labels: {
        resetButtonLabel: props === null || props === void 0 || (_props$labels = props.labels) === null || _props$labels === void 0 ? void 0 : _props$labels.resetButtonLabel,
        doneButtonLabel: props === null || props === void 0 || (_props$labels2 = props.labels) === null || _props$labels2 === void 0 ? void 0 : _props$labels2.doneButtonLabel,
        todayLabel: props === null || props === void 0 || (_props$labels3 = props.labels) === null || _props$labels3 === void 0 ? void 0 : _props$labels3.todayLabel,
        tomorrowLabel: props === null || props === void 0 || (_props$labels4 = props.labels) === null || _props$labels4 === void 0 ? void 0 : _props$labels4.tomorrowLabel,
        checkoutLabel: props === null || props === void 0 || (_props$labels5 = props.labels) === null || _props$labels5 === void 0 ? void 0 : _props$labels5.checkoutLabel,
        checkInLabel: props === null || props === void 0 || (_props$labels6 = props.labels) === null || _props$labels6 === void 0 ? void 0 : _props$labels6.checkInLabel
      },
      hasFooter: true,
      onSelectDates: value => handleSetValue && handleSetValue(field.name, value),
      displayDatesNotification: true,
      onReset: () => handleSetValue && handleSetValue(field.name, []),
      onDone: value => value.includes(null) ? setIsError(true) : setIsError(false)
    }), isError && (0, _jsxRuntime.jsx)(_FormError.default, {
      errors: errors,
      name: field.name,
      extraStyles: errorStyles
    })]
  });
}
var inputGroupStyles = {
  w: 'full',
  h: 'var(--chakra-space-4xl)',
  border: {
    base: '1px solid var(--chakra-colors-lightGrey1)',
    sm: 0
  },
  borderRadius: {
    base: 'var(--chakra-space-xs)',
    sm: 0
  }
};
var bookingDatepickerSize = {
  w: {
    base: '49%',
    sm: 'full',
    lg: '19.5rem',
    xl: '19.781rem'
  }
};
var datepickerInputElementStyles = {
  h: '100%',
  w: '100%',
  flex: {
    base: '1 1 50%',
    sm: '1 1 auto'
  },
  mr: '0',
  _placeholder: {
    color: 'var(--chakra-colors-darkGrey1)'
  },
  borderRight: '0.063rem solid var(--chakra-colors-lightGrey1)',
  border: '0.063rem solid var(--chakra-colors-lightGrey1)',
  borderRadius: 'var(--chakra-space-xs)',
  borderColor: 'var(--chakra-colors-lightGrey1)',
  borderTopRightRadius: 'var(--chakra-space-xs)',
  borderBottomRightRadius: 'var(--chakra-space-xs)'
};
var iconStyles = {
  top: 'var(--chakra-space-sm)'
};
var datepickerStyles = {
  inputGroupStyles,
  bookingDatepickerSize,
  datepickerInputElementStyles,
  iconStyles
};